import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchLogout } from '../../actions/logout';
import { fetchReferNotificationsRead } from '../../actions/ReferNotificationsRead';

// functions
import { shortHospName } from '../../functions/FuncPerjer';
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// components
import WithHooksHOC from '../Utility/WithHooksHOC';

// mui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Badge from '@material-ui/core/Badge';

const session = orm.session();

class BarNotify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NotifyStartStatus: false,
      NotifyData: [],
      NotifyReadFalse: [],
      AppNotifyAnchorEl: null,
    };
    this.AppNotifyHandleClick = this.AppNotifyHandleClick.bind(this);
    this.AppNotifyHandleClose = this.AppNotifyHandleClose.bind(this);
    this.setNotifyData = this.setNotifyData.bind(this);
    this.NotifyCheckTimer = this.NotifyCheckTimer.bind(this);
  }

  AppNotifyHandleClick = event => {
    this.setState({ AppNotifyAnchorEl: event.currentTarget });
  };

  AppNotifyHandleClose = () => {
    this.setState({ AppNotifyAnchorEl: null });
  };

  setNotifyData = () => {
    const { getAll } = this.props.useIndexedDB('notifications');

    getAll().then(FromDB => {
      session.TempData.delete({
        where(record) {
          return record.id !== '';
        },
      });

      for (let val of FromDB) {
        session.TempData.upsert(val);
      }

      let NotifyData = [];
      let NotifyFalseData = [];

      if (this.props.appData.loginData) {
        NotifyData = session.TempData.all()
          .filter(TempData => TempData.id !== '')
          .filter(TempData => TempData.key1 === this.props.appData.loginData.hospCode)
          .orderBy(TempData => TempData.notifyDateTime, 'desc')
          .toRefArray();

        NotifyFalseData = session.TempData.all()
          .filter(TempData => TempData.id !== '')
          .filter(TempData => TempData.key1 === this.props.appData.loginData.hospCode)
          .filter(TempData => TempData.notifyRead === false)
          .toRefArray();
      }

      this.setState({ NotifyReadFalse: NotifyFalseData });
      this.setState({ NotifyData: NotifyData });
    });
  };

  ClickViewNotifyData(id) {
    const { update, getByIndex } = this.props.useIndexedDB('notifications');

    this.AppNotifyHandleClose();
    let NotifyData = session.TempData.all()
      .filter(TempData => TempData.id === id)
      .toRefArray();

    if (NotifyData) {
      getByIndex('keyId', id).then(NotifyFromDB => {
        if (NotifyFromDB) {
          let dataRequest = {
            idToken: this.props.appData.idToken,
            bid: NotifyFromDB.datas.bid,
            hospCode: this.props.appData.loginData.hospCode,
          };

          let res = this.props.fetchReferNotificationsRead(dataRequest);

          let obj = this;
          res.then(function (v) {
            update({
              id: NotifyFromDB.id,
              keyId: NotifyFromDB.keyId,
              key1: NotifyFromDB.key1,
              key2: NotifyFromDB.key2,
              notifyDateTime: NotifyFromDB.notifyDateTime,
              notifyType: NotifyFromDB.notifyType,
              notifyLink: NotifyFromDB.notifyLink,
              datas: NotifyFromDB.datas,
              notifyRead: true,
            }).then(event => {
              obj.setNotifyData();
            });
          });
        }
      });
    }

    this.props.history.push(NotifyData[0].notifyLink);
  }

  NotifyCheckTimer() {
    if (this.props.appData.idToken) {
      this.setNotifyData();
    }
    setTimeout(() => {
      this.NotifyCheckTimer();
    }, 30000);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setNotifyData();
    }, 1500);
    this.NotifyCheckTimer();
  }

  componentDidUpdate() {
    if (this.props.appData.loginData && this.state.NotifyStartStatus === false) {
      this.setState({ NotifyStartStatus: true });
      this.setNotifyData();
    }
  }

  render() {
    return (
      <div>
        <IconButton className='icon-button' onClick={this.AppNotifyHandleClick}>
          <Tooltip title='Notifications'>
            {this.state.NotifyReadFalse.length > 0 ? (
              <Badge badgeContent={this.state.NotifyReadFalse.length} color='error'>
                <NotificationsIcon color='action' />
              </Badge>
            ) : (
              <NotificationsIcon color='action' />
            )}
          </Tooltip>
        </IconButton>
        <Menu
          id='app-notify'
          anchorEl={this.state.AppNotifyAnchorEl}
          keepMounted
          open={Boolean(this.state.AppNotifyAnchorEl)}
          onClose={this.AppNotifyHandleClose}
          className='app-notify'
          TransitionComponent={Fade}
        >
          <div className='app-notify'>
            <List component='nav' aria-label='notify'>
              {this.state.NotifyData &&
                this.props.appData.loginData &&
                this.state.NotifyData.map((items1, index2) => (
                  <div key={index2}>
                    {items1.notifyType === 'refer' && (
                      <div>
                        <ListItem
                          button
                          className={
                            items1.notifyRead ? 'MuiListItem-button-bg-default' : 'MuiListItem-button-bg-alert'
                          }
                          onClick={() => this.ClickViewNotifyData(items1.id)}
                        >
                          <ListItemIcon
                            title={
                              items1.datas.fromHospCode === this.props.appData.loginData.hospCode
                                ? 'Refer Out'
                                : 'Refer In'
                            }
                          >
                            {items1.datas.fromHospCode === this.props.appData.loginData.hospCode ? (
                              <AssignmentReturnIcon className='refer-out-icon-color' title='Refer Out' />
                            ) : (
                              <AssignmentReturnedIcon className='refer-in-icon-color' title='Refer In' />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={shortHospName(
                              items1.datas.fromHospCode === this.props.appData.loginData.hospCode
                                ? items1.datas.toHospName
                                : items1.datas.fromHospName
                            )}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className='inline'
                                  color={items1.notifyRead ? 'textPrimary' : 'textSecondary'}
                                >
                                  {items1.datas.icd + ' | ' + ThaiDateTime(items1.notifyDateTime)}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    )}
                  </div>
                ))}
            </List>
          </div>
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchLogout: appData => dispatch(fetchLogout(appData)),
    fetchReferNotificationsRead: dataRequest => dispatch(fetchReferNotificationsRead(dataRequest)),
  };
}

export default WithHooksHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(BarNotify)));
