import React from 'react';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default function ReferRejectDialog(props) {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        style: { borderRadius: '16px' },
      }}
    >
      <DialogTitle id='alert-dialog-title'>
        <WarningIcon
          style={{
            color: '#F44336',
            marginRight: '16px',
            backgroundColor: '#FEECEB',
            borderRadius: '100px',
            boxShadow: '0 0 0 3px #FEECEB',
          }}
        />
        ยืนยันการปฏิเสธ Refer
        <IconButton className='float-right' onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.cid && <DialogContentText id='alert-dialog-description'>CID : {props.cid}</DialogContentText>}
        {props.ptname && <DialogContentText id='alert-dialog-description'>ผู้ป่วย : {props.ptname}</DialogContentText>}
        {props.hospName && <DialogContentText id='alert-dialog-description'>จาก : {props.hospName}</DialogContentText>}

        <DialogContentText>
          <TextField
            fullWidth
            multiline
            id='outlined-multiline-static'
            label='เหตุผล'
            rows={3}
            value={props.reason}
            variant='outlined'
            onChange={e => props.onChange(e)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onSubmit()}
          style={{ backgroundColor: '#F44336', color: 'white' }}
          variant='contained'
          fullWidth
        >
          ยืนยันการปฏิเสธ Refer
        </Button>
      </DialogActions>
      <DialogActions>
        <Button
          onClick={() => props.onClose()}
          autoFocus
          style={{ backgroundColor: 'white', color: '#3F98D2' }}
          fullWidth
        >
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}
