import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBillingsCharge } from '../../actions/BillingsCharge';
import { Container, TableRow, TableCell, Status } from './styles';
import { shortHospName, getBillingStatusConfig, formatNum } from '../../functions/FuncPerjer';

const BillingsCharge = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appData } = useSelector(state => state);
  const { idToken, hospmains, hospCode, selectedHospmain, billings, FetchingStatus } = appData;
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchBillingsCharge({ hospCode, idToken, asProvider }));
    };
    fetchData();
  }, [dispatch, idToken, asProvider, hospCode]);

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5>
            {asProvider ? 'เรียกเก็บ ' : 'ตามจ่าย '}
            {new Date().toLocaleDateString('th-TH', { year: 'numeric' })}
          </h5>
        </div>
        {!FetchingStatus && hospmains && selectedHospmain && (
          <div className='table-head'>
            <h4>
              <b>{shortHospName(selectedHospmain.hospName)}</b>
            </h4>
            <Select
              variant='outlined'
              value={selectedHospmain}
              onChange={e =>
                dispatch(
                  fetchBillingsCharge({
                    hospCode,
                    idToken,
                    asProvider,
                    hospmain: e.target.value,
                  })
                )
              }
            >
              {hospmains.map(hosp => (
                <MenuItem value={hosp}>{`${hosp.hospCode}: ${hosp.hospName}`}</MenuItem>
              ))}
            </Select>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>เดือน</TableCell>
              <TableCell align='right'>จำนวนผู้ป่วย</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿)</TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus &&
              billings &&
              billings.map(row => (
                <TableRow
                  key={row._id}
                  onClick={() => {
                    history.push(`${asProvider ? 'to' : 'from'}/${selectedHospmain.hospCode}/${row.monthIndex + 1}`);
                  }}
                >
                  <TableCell align='center' component='th' scope='row'>
                    {new Date(row.billingDate).toLocaleDateString('th-TH', {
                      month: 'short',
                      year: 'numeric',
                    })}
                  </TableCell>
                  <TableCell align='right'>{row.totalPatients}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalCalcPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalFinalPrice)}</TableCell>
                  <TableCell align='right'>
                    <Status color={getBillingStatusConfig(row.status).color}>
                      {getBillingStatusConfig(row.status).text}
                    </Status>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Container>
    </>
  );
};

export default BillingsCharge;
