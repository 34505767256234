import axios from 'axios';
import config from 'react-global-configuration';

export default async ({ idToken, bid, dateTime }) => {
  const url = `${config.get('ROOT_API')}/v1/referral/recordDateTime`;

  const body = {
    type: 'contactDoctor',
    dateTime,
    bid,
  };

  return await axios
    .post(url, body, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log('contact doctor axios error');
      return [];
    });
};
