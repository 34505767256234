import axios from 'axios';
import config from 'react-global-configuration';

export default async ({ idToken, visitBid, dateTime }) => {
  const url = `${config.get('ROOT_API')}/v1/referral/recordDateTime`;

  const body = {
    type: 'read',
    dateTime,
    visitBid,
  };

  return await axios
    .post(url, body, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log('nurse read axios error');
      return [];
    });
};
