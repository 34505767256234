import _ from 'lodash';

// types
import { IReferral } from '../types/referral';

// functions
import { calculateAge } from './FuncPerjer';

export declare enum CategoryType {
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
}

export const setReportHeight = (barCount: number) => {
  if (barCount === 1) return 160;
  else if (barCount === 2) return 220;
  else if (barCount > 2 && barCount < 10) return barCount * 80;
  else return barCount * 40;
};

// export const createBranchByIcd = ({ referData }: { referData: IReferral }) => {
//   let branchs = {};
//   console.log(referData);

//   // create branch
//   // if (
//   //   (referData.data.icd.toUpperCase() >= 'S00' && referData.data.icd.toUpperCase() <= 'T98') ||
//   //   (referData.data.icd.toUpperCase() >= 'V01' && referData.data.icd.toUpperCase() <= 'Y9899')
//   // ) {
//   //   branchs = {
//   //     id: referData._id,
//   //     branchDx: 'b1',
//   //     groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
//   //     icd: referData.data.icd,
//   //     icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
//   //     orderBy: 1,
//   //   };
//   // }

//   // if (
//   //   (referData.data.icd.toUpperCase() >= 'I20' && referData.data.icd.toUpperCase() <= 'I2599') ||
//   //   (referData.data.icd.toUpperCase() >= 'I30' && referData.data.icd.toUpperCase() <= 'I5299')
//   // ) {
//   //   branchs = {
//   //     id: referData._id,
//   //     branchDx: 'b2',
//   //     groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
//   //     icd: referData.data.icd,
//   //     icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
//   //     orderBy: 2,
//   //   };
//   // }

//   // if (
//   //   (referData.data.icd.toUpperCase() >= 'C00' && referData.data.icd.toUpperCase() <= 'C9799') ||
//   //   (referData.data.icd.toUpperCase() >= 'D0' && referData.data.icd.toUpperCase() <= 'D0999')
//   // ) {
//   //   branchs = {
//   //     id: referData._id,
//   //     branchDx: 'b3',
//   //     groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
//   //     icd: referData.data.icd,
//   //     icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
//   //     orderBy: 3,
//   //   };
//   // }
//   // if (
//   //   (referData.data.icd.toUpperCase() >= 'P00' && referData.data.icd.toUpperCase() <= 'P9699') ||
//   //   (referData.data.icd.toUpperCase() >= 'Q00' && referData.data.icd.toUpperCase() <= 'Q9999')
//   // ) {
//   //   branchs = {
//   //     id: referData._id,
//   //     branchDx: 'b4',
//   //     groupDx: referData.data.icd.substring(0, 1).toUpperCase(),
//   //     icd: referData.data.icd,
//   //     icdName: referData.data.icdName ? referData.data.icdName.replace(',', '.') : referData.data.icd,
//   //     orderBy: 4,
//   //   };

//   if (
//     (referData.icd10?.toUpperCase() >= 'S00' && referData.icd10?.toUpperCase() <= 'T98') ||
//     (referData.icd10?.toUpperCase() >= 'V01' && referData.icd10?.toUpperCase() <= 'Y9899')
//   ) {
//     branchs = {
//       id: referData._id,
//       branchDx: 'b1',
//       groupDx: referData.icd10.substring(0, 1)?.toUpperCase(),
//       icd10: referData.icd10,
//       icd10Name: referData.icd10Name ? referData.icd10Name.replace(',', '.') : referData.icd10,
//       orderBy: 1,
//     };
//   }

//   if (
//     (referData.icd10?.toUpperCase() >= 'I20' && referData.icd10?.toUpperCase() <= 'I2599') ||
//     (referData.icd10?.toUpperCase() >= 'I30' && referData.icd10?.toUpperCase() <= 'I5299')
//   ) {
//     branchs = {
//       id: referData._id,
//       branchDx: 'b2',
//       groupDx: referData.icd10.substring(0, 1)?.toUpperCase(),
//       icd10: referData.icd10,
//       icd10Name: referData.icd10Name ? referData.icd10Name.replace(',', '.') : referData.icd10,
//       orderBy: 2,
//     };
//   }

//   if (
//     (referData.icd10?.toUpperCase() >= 'C00' && referData.icd10?.toUpperCase() <= 'C9799') ||
//     (referData.icd10?.toUpperCase() >= 'D0' && referData.icd10?.toUpperCase() <= 'D0999')
//   ) {
//     branchs = {
//       id: referData._id,
//       branchDx: 'b3',
//       groupDx: referData.icd10.substring(0, 1)?.toUpperCase(),
//       icd10: referData.icd10,
//       icd10Name: referData.icd10Name ? referData.icd10Name.replace(',', '.') : referData.icd10,
//       orderBy: 3,
//     };
//   }

//   if (
//     (referData.icd10?.toUpperCase() >= 'P00' && referData.icd10?.toUpperCase() <= 'P9699') ||
//     (referData.icd10?.toUpperCase() >= 'Q00' && referData.icd10?.toUpperCase() <= 'Q9999')
//   ) {
//     branchs = {
//       id: referData._id,
//       branchDx: 'b4',
//       groupDx: referData.icd10.substring(0, 1)?.toUpperCase(),
//       icd10: referData.icd10,
//       icd10Name: referData.icd10Name ? referData.icd10Name.replace(',', '.') : referData.icd10,
//       orderBy: 4,
//     };
//   }

//   return branchs;
// };

// export const innerTableDataByCategory = ({
//   referDatas,
//   category,
// }: {
//   referDatas: IReferral[];
//   category: CategoryType;
// }) => {
//   let filteredReferDatas: any[] = [];

//   switch (category) {
//     case 'b1':
//       filteredReferDatas = referDatas.filter(
//         item =>
//           (item.icd10.toUpperCase() >= 'S00' && item.icd10.toUpperCase() <= 'T98') ||
//           (item.icd10.toUpperCase() >= 'V01' && item.icd10.toUpperCase() <= 'Y9899')
//       );
//       break;
//     case 'b2':
//       filteredReferDatas = referDatas.filter(
//         item =>
//           (item.icd10.toUpperCase() >= 'I20' && item.icd10.toUpperCase() <= 'I2599') ||
//           (item.icd10.toUpperCase() >= 'I30' && item.icd10.toUpperCase() <= 'I5299')
//       );
//       break;
//     case 'b3':
//       filteredReferDatas = referDatas.filter(
//         item =>
//           (item.icd10.toUpperCase() >= 'C00' && item.icd10.toUpperCase() <= 'C9799') ||
//           (item.icd10.toUpperCase() >= 'D0' && item.icd10.toUpperCase() <= 'D0999')
//       );
//       break;
//     case 'b4':
//       filteredReferDatas = referDatas.filter(
//         item =>
//           (item.icd10.toUpperCase() >= 'P00' && item.icd10.toUpperCase() <= 'P9699') ||
//           (item.icd10.toUpperCase() >= 'Q00' && item.icd10.toUpperCase() <= 'Q9999')
//       );
//       break;

//     default:
//       break;
//   }

//   const result = filteredReferDatas.map(item => {
//     return {
//       fromHospName: item.fromHospName,
//       toHospName: item.toHospName,
//       ptname: item.ptname,
//       ptAge: calculateAge(item.ptDOB),
//       ptSexName: item.ptSexName,
//       referPoint: item.data.referPoint,
//       icd: item.icd10 + ' ' + item.icd10Name,
//       refername: item.data.refername,
//     };
//   });

//   return result;
// };
