import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  width: 600px;
  .hosp-head {
    text-align: center;
    font-size: 20px;
    margin-top: 8px;
  }
  .total-price {
    text-align: center;
    font-size: 18px;
    margin: 16px;
  }
  .top {
    border-top: 1px solid #f0f0f0;
  }
  .description {
    padding: 16px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
  .item {
    padding: 8px;
    display: flex;
    flex: 1;
  }
  .first {
    background-color: #fafafa;
    border-right: 1px solid #f0f0f0;
  }
  .btn-container {
    display: flex;
    justify-content: center;
  }
  .cancel {
    margin-right: 16px;
  }
  .alert-container {
    margin-bottom: 16px;
  }
  .text-label {
    color: rgba(207, 37, 37, 0.9);
    text-align: center;
  }
`;
