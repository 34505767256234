import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
    MUIDataTableToolbar: {
      left: {
        textAlign: 'left',
      },
      icon: {
        '&:hover': {
          color: '#3F98D2',
        },
      },
    },
    MuiButton: {
      root: {
        '&:disabled': {
          color: '#3F98D2',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-child(even)': {
          background: '#f5f5f5',
        },
      },
    },
  },
});
