import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

// constants
import { roles } from '../../../constants/Users/userRole';

// actions
import { fetchUsers, fetchUserByHospCode } from '../../../actions/UserManagement';

// apis
import DeleteUser from '../../../api/UserManagement/DeleteUser';

//style
import { Container } from './UserManagement.style';

//mui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';

export default function UserManagement() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);

  const { appData } = useSelector(state => state);
  const { idToken, users, loginData, permission } = appData;

  useEffect(() => {
    handleFetchUsers({ idToken });
  }, []);

  const handleFetchUsers = ({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) => {
    dispatch(fetchUsers({ idToken, page, limit, sortColumn, sortType, filterText, filterList }));
  };

  const handleDelete = async () => {
    setLoading(true);
    await DeleteUser({ idToken, username: modal.username });
    handleFetchUsers({ idToken });
    setLoading(false);
    setModal(false);
  };

  const columns = [
    {
      name: 'hospCode',
      label: 'รหัส รพ.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'ชื่อผู้ใช้',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'role',
      label: 'สิทธิ์',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'สถานะ',
      options: {
        customBodyRender: (_value, tableMeta) => {
          return (
            <div
              style={
                _value
                  ? {
                      backgroundColor: '#F5FAFD',
                      color: '#3F98D2',
                      border: '1px solid #79B7DF',
                      textAlign: 'center',
                      borderRadius: '4px',
                    }
                  : {
                      backgroundColor: '#FFF5E5',
                      color: '#FF9800',
                      border: '1px solid #FFB74C',
                      textAlign: 'center',
                      borderRadius: '4px',
                    }
              }
            >
              {_value ? 'Active' : 'Waiting'}
            </div>
          );
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (_value, tableMeta) => {
          return (
            <div className='button-wrapper'>
              <Tooltip title={'Edit This User'}>
                <IconButton
                  onClick={() =>
                    history.push(`/user-management/add-edit-user/${tableMeta?.rowData[1]}`, {
                      user: users?.docs?.find(item => item.username === tableMeta?.rowData[1]),
                    })
                  }
                >
                  <EditIcon style={{ color: '#3F98D2' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete This User'}>
                <IconButton onClick={() => setModal({ username: tableMeta?.rowData[1] })}>
                  <DeleteIcon style={{ color: '#F77B72' }} />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scroll',
    serverSide: true,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: [20, 50, 100],
    count: users?.totalDocs,
    selectableRows: false,
    download: false,
    print: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      const query = {
        idToken,
        page: tableState?.page + 1,
        limit: tableState?.rowsPerPage,
        sortColumn: tableState?.sortOrder?.name,
        sortType: tableState?.sortOrder?.direction,
        filterText: tableState?.searchText,
        filterList: tableState?.filterList,
      };

      switch (action) {
        case 'changePage':
          handleFetchUsers(query);
          break;
        case 'sort':
          handleFetchUsers(query);
          break;
        case 'search':
          handleFetchUsers(query);
          break;
        case 'filterChange':
        case 'resetFilters':
          handleFetchUsers(query);
          break;
        case 'changeRowsPerPage':
          setRowPerPage(tableState?.rowsPerPage);
          handleFetchUsers(query);
          break;
      }
    },
  };

  return (
    <Container>
      {permission?.userManagement ? (
        <MUIDataTable
          title={
            <div className='table-title'>
              <PersonIcon color='action' /> จัดการผู้ใช้งาน
            </div>
          }
          data={users?.docs?.map(user => ({
            ...user,
            role: roles?.find(role => role?.value === user?.role?.toUpperCase())?.label,
          }))}
          columns={columns}
          options={options}
        />
      ) : (
        <div className='text-center'>
          <h3>Can't access this page</h3>
          <p>Your current account can't access this page. Try a different account?</p>
        </div>
      )}

      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>ลบผู้ใช้ {modal.username}</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setModal(false)} color='primary'>
            ยกเลิก
          </Button>
          <Button disabled={loading} onClick={handleDelete} color='primary'>
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </Container>
  );
}
