import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

//styles
import { StyledTextField, theme, useStyles } from './styles';

//actions
import { fetchPttype, getVisit, searchVisit, submit } from '../../../actions/Refers/ReferForm';
import { fetchHospitalList } from '../../../actions/Commons';

// components
import { ListboxComponent } from '../../Utility/ListboxComponent';

// constants
import {
  icd10List,
  titleNameList,
  reasonList,
  referTypeList,
  priorityList,
  acuityLevelList,
  departmentList,
  hospitalTypeList,
} from '../../../constants/referForm';

// functions
import { titleNameAndSex } from '../../../functions/ReferForm';

//mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  FormGroup,
  MenuItem,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Snackbar,
  useMediaQuery,
  Dialog,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ReferForm() {
  const history = useHistory();
  const classes = useStyles();
  const { handleSubmit, register, watch, setValue, errors, control } = useForm();

  const [bgColor, setBgColor] = useState('#79d6f2');
  const [visitDateTime, setVisitDateTime] = useState(new Date());
  const [visitChunkIndex, setVisitChunkIndex] = useState(0);
  const [visitChunk, setVisitChunk] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [arrVisits, setArrVisits] = useState([]);
  const [expireDateTime, setExpireDateTime] = useState(new Date());
  const [beginDateTime, setBeginDateTime] = useState(new Date());
  const [icd10, setIcd10] = useState([]);
  const [currentSection, setCurrentSection] = useState('1');
  const [showAlert, setShowAlert] = useState(false);
  const [doctorName, setDoctorName] = useState('');
  const [pttype, setPttype] = useState({});
  const [selectedHospital, setSelectedHospital] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const { appData } = useSelector(state => state);
  const { idToken, referForm, alert, loginData, pttypeList, hospitalList, searchHospital } = appData;

  useEffect(() => {
    // NOTE: maybe must change hospCode later
    handleFetchPttype({ hospCode: '11535' });
    handleFetchHospitalList({ idToken });
  }, []);

  useEffect(() => {
    pttypeList?.length > 0 && setPttype(pttypeList[0]);
  }, [pttypeList]);

  useEffect(() => {
    if (referForm) {
      if (referForm.visitList?.length > 0) {
        setVisitChunk(_.chunk(referForm.visitList, 3));
        setVisitData(referForm.visitList);
        setShowAlert(false);
        setCurrentSection('2');
      } else {
        setShowAlert(true);
        setArrVisits([]);
      }
    }
  }, [referForm]);

  useEffect(() => {
    visitChunkIndex !== 0 ? setArrVisits([...arrVisits, ...visitChunk[visitChunkIndex]]) : setArrVisits(visitChunk[0]);
  }, [visitChunkIndex, visitData]);

  useEffect(() => {
    if (alert?.type === 'success') {
      const today = moment(new Date()).format('YYYY-MM-DD');
      history.push(`refer-out/${today}/${today}`);
    }
  }, [alert]);

  useEffect(() => {
    searchHospital && setValue('toHospCode', searchHospital?.hospCode + ' - ' + searchHospital?.hospName);
  }, [searchHospital]);

  const handleSearchVisit = async ({ patient__hn, patient__cid, visitDateTime }) => {
    await dispatch(
      searchVisit({
        idToken,
        hn: patient__hn,
        cid: patient__cid,
        vstdate: moment(visitDateTime).format('yyyy-MM-DD'),
      })
    );
  };

  const handleFetchPttype = async ({ hospCode }) => {
    await dispatch(fetchPttype({ hospCode, idToken }));
  };

  const handleFetchHospitalList = ({ idToken }) => {
    dispatch(fetchHospitalList({ idToken }));
  };

  const onSubmit = async ({
    patient__hn,
    priority,
    beginDateTime,
    expireDateTime,
    note,
    prediagnosis,
    sendReason,
    sendReasonOther,
    referType,
    department,
    departmentOther,
    attachments,
    letterAttachments,
    bmaHospital,
    trauma,
    acuityLevel,
    hospType,
  }) => {
    // prevent user press enter button but input invalid option in autocomplete
    if (!pttype || pttype.length <= 0) return false;

    const body = {
      hn: patient__hn ? patient__hn : referForm?.patientData.hn.toString(),
      vstdate: referForm?.visitList[0]?.vstdate.toString() || '',
      vsttimeId: referForm?.visitList[0]?.vsttimeId.toString() || '',
      vn: referForm?.visitList[0]?.vn.toString() || '',
      cliniclct: referForm?.visitList[0]?.cliniclct.toString() || '',
      data: {
        cid: referForm?.visitList[0]?.cid || '',
        hn: patient__hn ? patient__hn : referForm?.patientData?.hn?.toString(),
        hospCode: loginData?.hospCode,
        doctorName,
        pttype: pttype?.bid,
        prediagnosis,
        icd10: icd10?.map(item => item?.label) || [],
        sendReason,
        sendReasonOther,
        referType,
        department,
        departmentOther,
        priority,
        fromHospCode: loginData?.hospCode,
        fromHospName: loginData?.hospName,
        toHospCode: selectedHospital?.hospCode,
        toHospName: selectedHospital?.hospName,
        beginDateTime,
        expireDateTime,
        note,
        bmaHospital,
        trauma,
        acuityLevel,
        hospType,
      },
    };

    await dispatch(submit({ idToken, body, attachments, letterAttachments }));
  };

  const chooseVisit = visit => {
    setDoctorName(visit.doctorName);
    setIcd10([
      ...icd10,
      ...visit.icd10.map(i => {
        return { key: uuid(), label: i };
      }),
    ]);
    setCurrentSection('3');
  };

  const handleDeleteICD10 = chipToDelete => {
    setIcd10(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const confirmButton = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth='md'>
        <Typography variant='h6' align='left' gutterBottom>
          ใบส่งต่อผู้ป่วย
        </Typography>

        {/* ANCHOR: 1 - search visit */}
        <form onSubmit={handleSubmit(handleSearchVisit)}>
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      required={!watch('patient__cid')}
                      fullWidth
                      label='รหัส HN'
                      placeholder='โปรดใส่รหัส HN ของผู้ป่วยที่จะค้นหาประวัติเพื่อทำการส่งต่อ'
                      onFocus={e => setCurrentSection('1')}
                      variant='outlined'
                    />
                  }
                  defaultValue=''
                  control={control}
                  name='patient__hn'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      required={!watch('patient__hn')}
                      fullWidth
                      label='เลข CID'
                      placeholder='โปรดใส่เลข CID ของผู้ป่วยที่จะค้นหาประวัติเพื่อทำการส่งต่อ'
                      onFocus={e => setCurrentSection('1')}
                      variant='outlined'
                    />
                  }
                  defaultValue=''
                  control={control}
                  name='patient__cid'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      autoOk
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      label='วันที่ของ Visit ท่ีจะส่งต่อ :'
                      onChange={e => setVisitDateTime(e)}
                      onClick={e => setCurrentSection('1')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  }
                  control={control}
                  name='visitDateTime'
                  defaultValue={visitDateTime}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.formBtn}>
                <Button
                  fullWidth={useMediaQuery(theme.breakpoints.down('xs'))}
                  color='primary'
                  variant='outlined'
                  type='submit'
                >
                  ค้นหาประวัติผู้ป่วย
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Alert hidden={!showAlert} className={classes.alert} severity='error'>
                  <AlertTitle>ไม่พบ visit</AlertTitle>
                  กรุณาตรวจสอบรหัส nh หรือ เลข cid หรือ วันที่ของ visit ให้ถูกต้อง
                </Alert>
              </Grid>
            </Grid>
          </div>
        </form>

        <Divider />

        {/* ANCHOR: 2 - select visit*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Controller
                  as={<TextField disabled select fullWidth variant='outlined' />}
                  control={control}
                  name='title'
                  defaultValue={
                    titleNameAndSex({ titleNameValue: referForm?.patientData?.data?.fullname?.title })?.label
                  }
                  key={referForm?.patientData?.data?.fullname?.title}
                >
                  {titleNameList?.map(item => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  as={<TextField disabled fullWidth label='ชื่อ - นามสกุล' variant='outlined' />}
                  name='name'
                  control={control}
                  defaultValue={
                    (referForm?.patientData?.data?.fullname?.firstName ||
                      referForm?.patientData?.data?.fullname?.lastName) &&
                    `${referForm?.patientData?.data?.fullname?.firstName} ${referForm?.patientData?.data?.fullname?.lastName}`
                  }
                  key={`${referForm?.patientData?.data?.fullname?.firstName} ${referForm?.patientData?.data?.fullname?.lastName}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  as={<TextField disabled fullWidth label='เลขบัตรประชาชน / บัตรต่างด้าว' variant='outlined' />}
                  control={control}
                  name='citizenship'
                  defaultValue={referForm?.patientData?.data?.cid}
                  key={referForm?.patientData?.data?.cid}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={6}>
                <Controller
                  as={<TextField disabled fullWidth label='เลข HN' />}
                  control={control}
                  name='hn'
                  defaultValue={referForm?.patientData?.data?.hn[0]?.hn}
                  key={referForm?.patientData?.data?.hn[0]?.hn}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} sm={7} md={6}>
                <Controller
                  as={<RadioGroup row />}
                  control={control}
                  name='sex'
                  defaultValue={referForm?.patientData?.data?.sex || '1'}
                  key={referForm?.patientData?.data?.sex}
                >
                  <FormControlLabel disabled value='1' control={<Radio color='primary' />} label='ชาย' />
                  <FormControlLabel disabled value='2' control={<Radio color='primary' />} label='หญิง' />
                  <FormControlLabel disabled value='3' control={<Radio color='primary' />} />
                  <Controller
                    as={<TextField fullWidth={useMediaQuery(theme.breakpoints.down('xs'))} disabled label='อื่น ๆ' />}
                    control={control}
                    variant='outlined'
                    name='sexname'
                    defaultValue={
                      referForm?.patientData?.data?.sex === '3' ? referForm?.patientData?.data?.sexname : ''
                    }
                    key={referForm?.patientData?.data?.sex === '3' ? referForm?.patientData?.data?.sexname : ''}
                  />
                </Controller>
              </Grid>

              {arrVisits?.length > 0 && (
                <>
                  <Divider />

                  <Grid item xs={12}>
                    <Typography align='left'>เลือก Visit ที่จะทำการส่งต่อ:</Typography>
                  </Grid>

                  {arrVisits.map((visit, index) => (
                    <>
                      <Grid item xs={12}>
                        <Typography align='left'>Visit {index + 1}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Controller
                          as={<TextField disabled fullWidth label='ชื่อแพทย์' />}
                          control={control}
                          name={`visit__doctor__name__${index + 1}`}
                          defaultValue={visit.doctorName}
                          key={visit.doctorName}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Controller
                          as={<TextField disabled fullWidth label='ห้องตรวจ' />}
                          control={control}
                          name={`visit__room__${index + 1}`}
                          defaultValue={visit.room}
                          key={visit.room}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Controller
                          as={<TextField disabled fullWidth label='รหัส ICD 10 ของโรค' />}
                          control={control}
                          name={`visit__icd__code__${index + 1}`}
                          defaultValue={`${visit.icd10[0]} - ${visit.icd10Name[0]}`}
                          key={`${visit.icd10[0]} - ${visit.icd10Name[0]}`}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} className={classes.formBtn}>
                        <Button fullWidth color='primary' variant='contained' onClick={e => chooseVisit(visit)}>
                          เลือกประวัติ
                        </Button>
                      </Grid>
                    </>
                  ))}
                </>
              )}
            </Grid>

            {visitChunkIndex < visitChunk.length - 1 && (
              <>
                <Divider />

                <Button color='secondary' variant='contained' onClick={e => setVisitChunkIndex(visitChunkIndex + 1)}>
                  ดู Visit เพิ่มเติมของวันที่เลือก <ArrowDropDownIcon />
                </Button>
              </>
            )}
          </div>
          <Divider />

          {/* ANCHOR: 3 - submit form */}
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      autoOk
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      label='วันหมดอายุใบส่งต่อ'
                      onChange={e => setExpireDateTime(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onClick={e => setCurrentSection('3')}
                    />
                  }
                  control={control}
                  name='expireDateTime'
                  defaultValue={expireDateTime}
                  key={expireDateTime}
                />

                <Controller
                  as={
                    <TextField
                      fullWidth
                      margin='normal'
                      label='การวินิจฉัยโรคขั้นต้น'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='prediagnosis'
                />

                <div className={classes.chipWrapper}>
                  {icd10?.map(item => (
                    <Chip
                      label={item.label}
                      size='small'
                      color='secondary'
                      variant='outlined'
                      onDelete={e => handleDeleteICD10(item)}
                      className={classes.chip}
                    />
                  ))}
                </div>

                <Autocomplete
                  onChange={(option, value) => value && setIcd10([...icd10, { key: uuid(), label: value.name }])}
                  ListboxComponent={ListboxComponent}
                  options={icd10List}
                  getOptionLabel={option => `${option.name} - ${option.description}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      margin='normal'
                      label='รหัส ICD 10 ของโรค'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  )}
                />

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='เหตุผลการส่งต่อ'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='sendReason'
                  defaultValue='01'
                >
                  {reasonList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      fullWidth
                      required={watch('sendReason') === '99'}
                      hidden={watch('sendReason') !== '99'}
                      margin='normal'
                      label='ระบุเหตุผลการส่งต่อ'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='sendReasonOther'
                  defaultValue=''
                />

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='ประเภทการส่งตัว'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='referType'
                  defaultValue='OPD'
                >
                  {referTypeList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Autocomplete
                  onChange={(option, value) => setPttype(value)}
                  options={pttypeList}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      margin='normal'
                      label='สิทธิการรักษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  )}
                  value={pttype}
                />

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='ความรุนแรงของผู้ป่วย'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='acuityLevel'
                  defaultValue='N'
                >
                  {acuityLevelList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='สาขาที่ปรึกษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='department'
                  defaultValue='419192003'
                >
                  {departmentList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      fullWidth
                      required={watch('department') === '74964007'}
                      hidden={watch('department') !== '74964007'}
                      margin='normal'
                      label='ระบุสาขาที่ปรึกษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='departmentOther'
                />

                <FormGroup row>
                  <FormControlLabel
                    inputRef={register}
                    label='สังกัด กทม.'
                    control={<Checkbox />}
                    name='bmaHospital'
                    onFocus={e => setCurrentSection('3')}
                  />
                  <FormControlLabel
                    inputRef={register}
                    label='trauma'
                    control={<Checkbox />}
                    name='trauma'
                    onFocus={e => setCurrentSection('3')}
                  />
                </FormGroup>

                <div className={classes.hospitalContainer}>
                  <Controller
                    as={
                      <TextField
                        select
                        fullWidth
                        margin='normal'
                        label='ประเภทโรงพยาบาลปลายทาง'
                        onFocus={e => setCurrentSection('3')}
                        variant='outlined'
                      />
                    }
                    control={control}
                    name='hospType'
                    defaultValue='08'
                  >
                    {hospitalTypeList.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Controller>

                  <Autocomplete
                    disableListWrap
                    loading={!hospitalList[0]}
                    ListboxComponent={ListboxComponent}
                    options={hospitalList}
                    onChange={(option, value) => setSelectedHospital(value)}
                    getOptionLabel={option => option.hospName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        label='โรงพยาบาลปลายทาง'
                        onFocus={e => setCurrentSection('3')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {!hospitalList[0] ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  <Controller
                    as={
                      <KeyboardDatePicker
                        disableToolbar
                        fullWidth
                        autoOk
                        variant='inline'
                        format='dd/MM/yyyy'
                        margin='normal'
                        label='วันที่เริ่มใช้สิทธิ'
                        onChange={e => setBeginDateTime(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        onClick={e => setCurrentSection('3')}
                      />
                    }
                    control={control}
                    name='beginDateTime'
                    defaultValue={beginDateTime}
                    key={beginDateTime}
                  />

                  <Controller
                    as={
                      <StyledTextField
                        select
                        fullWidth
                        margin='normal'
                        label='ระดับความเร่งด่วน'
                        bgColor={bgColor}
                        onChange={e => setBgColor(priorityList?.filter(item => item.value === e.target.value)[0].color)}
                        onFocus={e => setCurrentSection('3')}
                      />
                    }
                    control={control}
                    name='priority'
                    defaultValue='5'
                  >
                    {priorityList.map(item => (
                      <MenuItem key={item.value} style={{ backgroundColor: item.color }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Controller>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      rows={8}
                      variant='outlined'
                      label='หมายเหตุ'
                      onFocus={e => setCurrentSection('3')}
                    />
                  }
                  control={control}
                  name='note'
                  defaultValue=''
                  rules={{ maxLength: 255 }}
                />

                {errors.note && (
                  <Typography align='right' color='error'>
                    Max length exceeded. (255)
                  </Typography>
                )}

                <Typography className={classes.uploadLabel}>รูปหนังสือรับผิดชอบค่าใช้จ่าย</Typography>

                <Controller
                  as={
                    <DropzoneArea
                      maxFileSize={8388608}
                      filesLimit={5}
                      classes={currentSection !== '3' && { root: classes.unfocus }}
                      acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                    />
                  }
                  control={control}
                  name='attachments'
                />
                <Typography className={classes.uploadLabel}>รูปเพิ่มเติมอื่น ๆ</Typography>
                <Controller
                  as={
                    <DropzoneArea
                      maxFileSize={8388608}
                      filesLimit={5}
                      classes={currentSection !== '3' && { root: classes.unfocus }}
                      acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                    />
                  }
                  control={control}
                  name='letterAttachments'
                />
              </Grid>
            </Grid>

            <Grid container className={classes.footer}>
              <Grid item xs={12} sm={12}>
                <Button
                  // fullWidth={useMediaQuery(theme.breakpoints.down('xs'))}
                  type='submit'
                  // color='primary'
                  variant='contained'
                  className={classes.submitBtn}
                  onClick={confirmButton}
                  fullWidth
                >
                  ส่งต่อผู้ป่วย
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button fullWidth className={classes.cancelBtn}>
                  ยกเลิก
                </Button>
              </Grid>
            </Grid>

            <Dialog open={openModal} close={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth='xs'>
              <DialogTitle id='form-dialog-title'>{'ส่งต่อผู้ป่วย'}</DialogTitle>
              <DialogContent>
                <DialogContentText>คุณต้องการส่งต่อผู้ป่วยหรือไม่ ?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color='primary' type='submit'>
                  ยืนยัน
                </Button>
                <Button color='secondary' onClick={handleClose}>
                  ยกเลิก
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </form>

        {alert?.show && alert?.type === 'error' && alert?.page === 'referForm' && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoHideDuration={6000}
            open={alert.show}
          >
            <Alert severity={alert.type}>{alert.msg}</Alert>
          </Snackbar>
        )}
      </Container>
    </MuiThemeProvider>
  );
}
