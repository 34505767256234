import { APPDATA, APPDATA_FULFILLED } from '../constants/appData';
import BillingsCharge from '../api/Billings/BillingsCharge';
import SubmitCharge from '../api/Billings/SubmitCharge';
import PayerFinishBilling from '../api/Billings/PayerFinishBilling';
import PayerFinishReviewingBill from '../api/Billings/PayerFinishReviewingBill';
import BillingItemsByPatient from '../api/Billings/BillingItemsByPatient';

export function fetchBillingsCharge(dataRequest) {
  return {
    type: APPDATA,
    payload: BillingsCharge(dataRequest),
  };
}

export function changeHospital(selectedHospmain) {
  return {
    type: APPDATA,
    payload: {
      selectedHospmain,
    },
  };
}

export function openChargeModal(category) {
  return {
    type: APPDATA_FULFILLED,
    payload: {
      chargeModal: category,
      alert: { show: false, msg: '', type: null },
    },
  };
}

export function closeChargeModal() {
  return {
    type: APPDATA_FULFILLED,
    payload: {
      chargeModal: false,
      alert: { show: false, msg: '', type: null },
    },
  };
}

export function confirmCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: SubmitCharge(dataRequest),
  };
}

export function finishCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: PayerFinishBilling(dataRequest),
  };
}

export function finishReviewCharged(dataRequest) {
  return {
    type: APPDATA,
    payload: PayerFinishReviewingBill(dataRequest),
  };
}

export function fetchBillingItemsByPatient(dataRequest) {
  return {
    type: APPDATA,
    payload: BillingItemsByPatient(dataRequest),
  };
}
