import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// components
import VisitsDetail from './VisitsDetail';
import VisitsReferrals from './VisitsReferrals';
import PtScreen from './PtScreen';
import PtPhysicalExam from './PtPhysicalExam';
import PtDiag from './PtDiag';
import PtRxOrder from './PtRxOrder';
import AdmitData from './AdmitData';
import PtXray from './PtXray';
import PtLab from './PtLab';
import PtDental from './PtDental';
import PtAppointment from './PtAppointment';
import PtDoctorNote from './PtDoctorNote';
import TabPanel from '../Utility/TabPanel';

// api
import RetrievePatientData from '../../api/RetrievePatientData';

// mui
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import AirlineSeatFlat from '@material-ui/icons/AirlineSeatFlat';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PrintIcon from '@material-ui/icons/Print';
import { referrals } from '../../actions/Refers/Referrals';
import { Button, IconButton } from '@material-ui/core';
import { printReferral } from '../../assets/pdf/printReferral';

const session = orm.session();

function VisitsTypeTabs({ bid, appData }) {
  const [value, setValue] = useState(0);
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [visitAdmitData, setVisitAdmitData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  useEffect(() => {
    appData.referrals.length > 0 &&
      setvisitData({ visitsData: [{ referrals: appData.referrals, ...visitData?.visitsData[0] }] });
  }, [appData.referrals]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = async bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      // .filter((Visits) => Visits.class === 'AMB')
      .toRefArray();

    let visitsAdmitData = [];

    if (visitsData.length > 0 && visitsData[0].an) {
      visitsAdmitData = session.Visits.all()
        .filter(Visits => Visits.an === visitsData[0].an)
        // .filter((Visits) => Visits.class === 'IMP')
        .toRefArray();
    }

    setVisitAdmitData(visitsAdmitData);

    setValue(0);

    if (visitsData?.length > 0) {
      setvisitData({ visitsData });
    } else {
      await dispatch(
        referrals({
          idToken: appData.idToken,
          cid: appData?.emrData?.patients?.bid,
        })
      );
    }
  };

  const updatePatientData = ({ idToken, hn, cid }) => {
    RetrievePatientData({
      idToken,
      hn,
      cid,
    });

    alert('เริ่มการอัพเดทประวัติผู้ป่วยแล้ว');
  };
  const calculateAge = birthday => {
    // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday)?.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const viewReferralPdf = bid => {
    let visitsDatas = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();
    if (visitsDatas.length === 0) {
      return alert('ไม่พบ visit bid นี้');
    }
    const {
      cid,
      data: {
        DOB,
        address,
        fullname,
        contactInfo: { mobileNumber },
      },
    } = appData?.emrData?.patients;
    const { diagnoses, referrals } = visitsDatas[0];
    if (referrals.length === 0) return alert('ไม่พบ referral');

    const age = calculateAge(DOB);
    const data = {
      patient: {
        cid,
        age,
        address,
        fullname,
        mobileNumber,
      },
      diagnoses,
      referrals,
    };
    printReferral(data);
  };

  return (
    <Paper square>
      {visitData && (
        <div>
          <Button
            variant='contained'
            style={{ backgroundColor: '#3F98D2', color: 'white' }}
            onClick={e =>
              updatePatientData({
                idToken: appData?.idToken,
                hn: appData?.emrData?.patients?.hn,
                cid: appData?.emrData?.patients?.cid,
              })
            }
          >
            อัพเดทประวัติผู้ป่วย
          </Button>
          <div className='float-right'>
            {appData?.loginData?.hospCode === '11535' && (
              <IconButton onClick={() => viewReferralPdf(bid)}>
                <PrintIcon />
              </IconButton>
            )}
          </div>

          {visitData.visitsData.map((itemsV, indexV) => (
            <div key={indexV}>
              <Tabs
                value={value}
                indicatorColor='primary'
                textColor='secondary'
                onChange={handleChange}
                centered
                className='MuiTabs-centered-custom'
              >
                <Tab label='OPD' className='icon-button' icon={<DirectionsWalk />} />
                <Tab
                  label='IPD'
                  className={itemsV.an ? 'icon-button' : 'icon-button object-hidden'}
                  icon={<AirlineSeatFlat />}
                />
              </Tabs>
            </div>
          ))}
          <div className='margin-bottom-8px'>
            <TabPanel className='TabPanel-custom' value={value} index={0}>
              <div className='margin-bottom-8px'>
                <VisitsDetail bid={bid} />
              </div>

              {visitData.visitsData.map((items, index) => (
                <div key='index'>
                  <div className='margin-bottom-8px'>
                    {items.referrals?.length > 0 && <VisitsReferrals bid={bid} />}
                  </div>
                  <div className='margin-bottom-8px' key='index'>
                    {items.doctorNotes?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDoctorNote bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}

                    {items.appointments?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtAppointment bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col lg={12} className='margin-bottom-8px'>
                        <PtScreen bid={bid} />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className='margin-bottom-8px'>
                        <PtPhysicalExam bid={bid} />
                      </Col>
                      <Col lg={6} className='margin-bottom-8px'>
                        <PtDiag bid={bid} dataType={'OPD'} />
                      </Col>
                    </Row>

                    {items.dentals?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDental bid={bid} />
                        </Col>
                      </Row>
                    )}

                    {items.xrays?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtXray bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                    {items.billingItems?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtRxOrder bid={bid} cid={items.cid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                    {items.labResults?.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtLab bid={bid} cid={items.cid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              ))}
            </TabPanel>
            <TabPanel className='TabPanel-custom' value={value} index={1}>
              {visitAdmitData.map((items, index) => (
                <div key={index}>
                  {items.an && (
                    <div className='margin-bottom-8px'>
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <AdmitData bid={bid} an={items.an} />
                        </Col>
                      </Row>

                      {items.appointments?.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtAppointment bid={bid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDiag bid={bid} an={items.an} dataType={'IPD'} />
                        </Col>
                      </Row>

                      {items.xrays?.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtXray bid={bid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                      {items.billingItems?.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtRxOrder bid={bid} an={items.an} cid={items.cid} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                      {items.labResults?.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtLab bid={bid} cid={items.cid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </TabPanel>
          </div>
        </div>
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsTypeTabs));
