import { APPDATA_PENDING, APPDATA_FULFILLED, APPDATA_REJECTED } from '../constants/appData';

const initialState = {
  FetchingStatus: false,
  loginData: null,
  idToken: '',
  emrData: {
    patients: null,
    visits: [],
    referIn: [],
    referOut: [],
    referDatas: [],
    referReportChwBwHosp: [],
  },
  onlineGatewayHospital: [],
  referAccept: null,
  referReject: null,
  categories: null,
  participatingHospitals: null,
  billings: null,
  hospmains: null,
  hospCode: null,
  selectedHospmain: null,
  billingSummary: null,
  chargeModal: false,
  calculateSummary: null,
  billlingPatients: null,
  patientData: null,
  alert: {
    show: false,
    msg: '',
    type: null,
    page: null,
  },
  previousUser: null,
  referForm: null,
  referInForm: null,
  visitData: null,
  pttypeList: [],
  hospitalList: [],
  searchHospital: null,
  referrals: [],
  permission: {
    viewHospitalReport: false,
    viewProvinceReport: false,
    viewRefer: false,
    actionRefer: false,
    userManagement: false,
    hospitalManagement: false,
    addUserMember: false,
    viewReferForm: false,
    viewTimeReport: false,
  },
  users: [],
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case APPDATA_PENDING:
      return {
        ...state,
        FetchingStatus: true,
      };
    case APPDATA_FULFILLED:
      return {
        ...state,
        ...action.payload,
      };
    case APPDATA_REJECTED:
      return {
        ...state,
        FetchingStatus: false,
      };

    default:
      return state;
  }
}
