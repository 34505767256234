import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, hospCode, hospmain, asProvider, year } = dataRequest;

      const hospmains = await axios.get(`${config.get('ROOT_API')}/v1/billing/getcachehospmain?hospCode=${hospCode}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const {
        data: { result },
      } = hospmains;
      const filteredHospmains = [...result].filter(i => i.hospCode !== hospCode);
      let selectedHospmain = filteredHospmains[0];
      if (hospmain) selectedHospmain = [...result].find(item => item.hospCode === hospmain.hospCode);

      const provider = asProvider ? hospCode : selectedHospmain.hospCode;
      const payer = !asProvider ? hospCode : selectedHospmain.hospCode;
      const currentYear = year || new Date().getFullYear();
      const billings = await axios.get(
        `${config.get('ROOT_API')}/v1/billingsummary?provider=${provider}&payer=${payer}&year=${currentYear}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      const billingData = {
        hospmains: filteredHospmains,
        selectedHospmain,
        billings: billings.data.result ? billings.data.result.data : [],
        FetchingStatus: false,
        provider,
      };

      return resolve(billingData);
    }, 200);
  });
};
