import React, { Component } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import 'moment/locale/th';
import orm from '../../models/index';

//actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefersAccept } from '../../actions/ReferAccept';
import { fetchRefersReject } from '../../actions/ReferReject';
import { fetchRefers } from '../../actions/ReferDatas';

//react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//functions
import { DateTimeToStrDate, StrToDateTime, ThaiDateTime, delDays } from '../../functions/FuncDateTimes';
import { StrEncrypt, shortHospName } from '../../functions/FuncPerjer';

// components
import ReferAcceptDialog from '../Utility/ReferAcceptDialog';
import ReferRejectDialog from '../Utility/ReferRejectDialog';

// constants
import { PENDING } from '../../constants/Refers/referralStatus';

//mui
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-thai-datepickers';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Link from '@material-ui/core/Link';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EmergencyBadge from './EmergencyBadge';

const session = orm.session();

class ReferIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: '',
      DialogAcceptOpen: [],
      DialogRejectOpen: [],
      anchorEl: [],
      hospCodeData: [],
      ReferDatas: [],
      hospCode: 'all',
      selectedDate: null,
      datePickerChang: false,
      referPoint: 'all',
      MenuClose: false,
      startDate: new Date(),
      endDate: new Date(),
      SearchType: '',
      justClicked: null,
      reason: [],
      acceptReason: [],
      Scid: '',
      Sname: '',
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.hospCodeChange = this.hospCodeChange.bind(this);
    this.getReferDatas = this.getReferDatas.bind(this);
    this.getReferCidDatas = this.getReferCidDatas.bind(this);
    this.setHospCodeData = this.setHospCodeData.bind(this);
    this.setReferDatas = this.setReferDatas.bind(this);
    this.setReferPointFc = this.setReferPointFc.bind(this);
    this.emrViews = this.emrViews.bind(this);
    this.setAppData = this.setAppData.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onCidChange = this.onCidChange.bind(this);
    this.DialogAcceptClose = this.DialogAcceptClose.bind(this);
    this.DialogAcceptOpen = this.DialogAcceptOpen.bind(this);

    this.DialogRejectClose = this.DialogRejectClose.bind(this);
    this.DialogRejectOpen = this.DialogRejectOpen.bind(this);

    this.ReferAcceptSubmit = this.ReferAcceptSubmit.bind(this);
    this.ReferRejectSubmit = this.ReferRejectSubmit.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
  }

  onCidChange(e) {
    const re = /^[0-9\b]+$/;

    if (re.test(e.target.value)) {
      this.setState({ Scid: e.target.value });
      this.setState({ Sname: '' });
    } else {
      this.setState({ Scid: '' });
      this.setState({ Sname: e.target.value });
    }

    this.setState({ cid: e.target.value });
  }

  onReasonChange(e, id) {
    this.setState({
      reason: {
        [id]: e.target.value,
      },
    });
  }

  DialogAcceptOpen(id) {
    this.setState({
      DialogAcceptOpen: {
        [id]: true,
      },
    });
    this.handleClose(id);
  }

  DialogAcceptClose(id) {
    this.setState({
      DialogAcceptOpen: {
        [id]: false,
      },
    });
  }

  DialogRejectOpen(id) {
    this.setState({
      DialogRejectOpen: {
        [id]: true,
      },
    });

    this.setState({
      reason: {
        [id]: '',
      },
    });

    this.handleClose(id);
  }

  DialogRejectClose(id) {
    this.setState({
      DialogRejectOpen: {
        [id]: false,
      },
    });
  }

  handleClick(event, id) {
    this.setState({
      anchorEl: {
        [id]: event.currentTarget,
      },
    });
  }

  handleClose(id) {
    this.setState({
      anchorEl: {
        [id]: null,
      },
    });
  }

  handleDateChange(date) {
    this.setState({ datePickerChang: true });
    this.setState({ selectedDate: date });
    this.setState({ SearchType: 'date' });
  }

  hospCodeChange(e) {
    this.setState({ referPoint: 'all' });
    this.setState({ hospCode: e.target.value });
    let referData = [];

    if (e.target.value !== 'all') {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas.fromHospCode === e.target.value)
        .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
        .toRefArray();
    } else {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas._id !== '')
        .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  setReferPointFc(e) {
    this.setState({ hospCode: 'all' });
    this.setState({ referPoint: e.target.value });

    let referData = [];

    if (e.target.value !== 'all') {
      if (e.target.value === 'er') {
        referData = session.ReferDatas.all()
          .filter(ReferDatas => ReferDatas.referPoint === 'ER')
          .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
          .toRefArray();
      } else {
        referData = session.ReferDatas.all()
          .filter(ReferDatas => ReferDatas.referPoint !== 'ER')
          .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
          .toRefArray();
      }
    } else {
      referData = session.ReferDatas.all()
        .filter(ReferDatas => ReferDatas._id !== '')
        .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  setCreateStatusFc() {
    this.setState({ createStatus: true });
  }

  setHospCodeData() {
    const referDb = this.props.appData.emrData.referDatas;

    session.ReferHosp.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferHosp.upsert({
        id: val.fromHospCode,
        hospName: val.fromHospName ? shortHospName(val.fromHospName) : val.fromHospCode,
      });
    }

    let hospCodeData2 = session.ReferHosp.all()
      .filter(ReferHosp => ReferHosp.id !== '')
      .orderBy(ReferHosp => ReferHosp.hospName, 'asc')
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });
  }

  setReferDatas() {
    const referDb = this.props.appData.emrData.referDatas;

    session.ReferDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferDatas.upsert(val);
    }

    let referData = session.ReferDatas.all()
      .filter(ReferDatas => ReferDatas._id !== '')
      .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
      .toRefArray();

    this.setState({ ReferDatas: referData });
  }

  getReferDatas(startDate, endDate) {
    let dataRequest = {
      idToken: this.props.appData.idToken,
      startDate: startDate,
      endDate: endDate,
      fromHospCode: '',
      toHospCode: this.props.appData.loginData.hospCode,
      limit: this.props.appProperty.visitLimit,
    };

    let res = this.props.fetchRefers(dataRequest);

    let obj = this;
    res.then(
      function (v) {
        obj.setState({ hospCode: 'all' });
        obj.setState({ referPoint: 'all' });

        obj.setHospCodeData();
        obj.setReferDatas();

        const appPropertyData = {
          visitSelect: '',
          FetchingStatus: false,
        };

        obj.setAppData(appPropertyData);
      },
      function (e) {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
        } else {
        }
      }
    );
  }

  getReferCidDatas(e) {
    e.preventDefault();

    if (this.state.Scid !== '' || this.state.Sname !== '') {
      let cid = '';
      let name = '';

      if (this.state.Scid.trim().length === 13) {
        cid = this.state.Scid;
      }

      if (this.state.Sname.trim().length > 0 && this.state.Sname !== '') {
        name = this.state.Sname;
      }

      if (cid <= '' && name <= '') {
        alert('กรุณากรอก CID / ชื่อ สกุล ไม่ถูกต้อง !');
      } else {
        let std = DateTimeToStrDate(delDays(new Date(), 366));
        let ste = DateTimeToStrDate(new Date());

        let dataRequest = {
          idToken: this.props.appData.idToken,
          startDate: std,
          endDate: ste,
          fromHospCode: '',
          toHospCode: this.props.appData.loginData.hospCode,
          cid: cid,
          limit: this.props.appProperty.visitLimit,
        };

        this.setState({ SearchType: 'cid' });

        let res = this.props.fetchRefers(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            obj.setState({ hospCode: 'all' });
            obj.setState({ referPoint: 'all' });

            obj.setHospCodeData();
            obj.setReferDatas();

            const appPropertyData = {
              visitSelect: '',
              FetchingStatus: false,
            };

            obj.setAppData(appPropertyData);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  }

  ReferAcceptSubmit(appointmentData, id, bid) {
    if (
      !appointmentData?.reason ||
      appointmentData?.reason === '' ||
      appointmentData?.reason === undefined ||
      appointmentData?.reason === null
    ) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการตอบรับ !');
    } else {
      if (bid) {
        let dataRequest = {
          ...appointmentData,
          idToken: this.props.appData.idToken,
          bid: bid,
        };

        let res = this.props.fetchRefersAccept(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            if (obj.state.SearchType === 'cid') {
              obj.getReferCidDatas();
            } else {
              obj.getReferDatas(DateTimeToStrDate(obj.state.selectedDate), DateTimeToStrDate(obj.state.selectedDate));
            }
            obj.DialogAcceptClose(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  }

  ReferRejectSubmit(id, bid) {
    if (
      !this.state.reason[id] ||
      this.state.reason[id] === '' ||
      this.state.reason[id] === undefined ||
      this.state.reason[id] === null
    ) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !');
    } else {
      if (bid) {
        let dataRequest = {
          idToken: this.props.appData.idToken,
          bid: bid,
          reason: this.state.reason[id],
        };

        let res = this.props.fetchRefersReject(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            if (obj.state.SearchType === 'cid') {
              obj.getReferCidDatas();
            } else {
              obj.getReferDatas(DateTimeToStrDate(obj.state.selectedDate), DateTimeToStrDate(obj.state.selectedDate));
            }

            obj.setState({
              reason: {
                [id]: '',
              },
            });

            obj.DialogRejectClose(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  }

  setAppData(appPropertyData) {
    this.props.appPropertySet(appPropertyData);
  }

  emrViews(bid, cid, visitBid) {
    let cidEncrypted2 = StrEncrypt(cid, this.props.appProperty.ScKey);

    localStorage.setItem('from-page', 'refer-in');

    if (visitBid) {
      // in case refer in network
      localStorage.setItem('refer-type', 'network');

      this.props.history.push('/patient-emr/' + cidEncrypted2 + '/' + visitBid);
    } else {
      // in case refer out of network
      localStorage.setItem('refer-type', 'out-of-network');

      this.props.history.push('/patient-emr/' + cidEncrypted2 + '/' + bid);
    }
  }

  componentDidMount() {
    const { params } = this.props.match;

    if (!params.startDate && this.props.appData.loginData) {
      this.setState({ selectedDate: new Date() });
      this.setState({ startDate: new Date() });
      this.setState({ endDate: new Date() });
      this.props.history.push('/refer-in/' + DateTimeToStrDate(new Date()) + '/' + DateTimeToStrDate(new Date()));
      this.getReferDatas(DateTimeToStrDate(new Date()), DateTimeToStrDate(new Date()));
    }

    if (
      (params.startDate !== this.state.startDate || params.endDate !== this.state.endDate) &&
      params.startDate &&
      params.endDate &&
      this.props.appData.loginData
    ) {
      this.setState({ selectedDate: StrToDateTime(params.startDate) });
      this.setState({ startDate: StrToDateTime(params.startDate) });
      this.setState({ endDate: StrToDateTime(params.startDate) });
      this.props.history.push('/refer-in/' + params.startDate + '/' + params.endDate);
      this.getReferDatas(params.startDate, params.endDate);
    }
  }

  componentDidUpdate() {
    const { params } = this.props.match;

    if (
      this.state.selectedDate !== this.state.startDate &&
      this.state.selectedDate &&
      this.state.datePickerChang &&
      this.props.appData.loginData
    ) {
      this.setState({ startDate: this.state.selectedDate });
      this.setState({ datePickerChang: false });

      this.props.history.push(
        '/refer-in/' + DateTimeToStrDate(this.state.selectedDate) + '/' + DateTimeToStrDate(this.state.selectedDate)
      );
      this.getReferDatas(DateTimeToStrDate(this.state.selectedDate), DateTimeToStrDate(this.state.selectedDate));
    }

    if (!this.state.datePickerChang && this.props.appData.loginData) {
      if (params.startDate && params.startDate !== DateTimeToStrDate(this.state.selectedDate)) {
        this.setState({ selectedDate: StrToDateTime(params.startDate) });
        this.props.history.push('/refer-in/' + params.startDate + '/' + params.startDate);
        this.getReferDatas(params.startDate, params.startDate);
      }
    }
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom '
          style={{ top: '55px', height: '74px' }}
        >
          <Toolbar variant='dense' className='refer-bar' style={{ paddingTop: '15px' }}>
            <div className='text-align-left' style={{ width: '100%' }}>
              <Row>
                <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={3}>
                      <h6 className='margin-bottom-0px margin-top-8px' style={{ minWidth: '100px', color: '#3F98D2' }}>
                        Refer In
                      </h6>
                    </Grid>

                    <Grid container item xs={9}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
                        <DatePicker
                          className='date-time-picker icon-button'
                          autoOk
                          value={this.state.selectedDate}
                          onChange={date => this.handleDateChange(date._d)}
                          disableToolbar
                          variant='inline'
                          maxDate={new Date()}
                          views={['year', 'month', 'date']}
                          format='DD/MM/yyyy'
                          yearOffset={543}
                          style={{
                            borderRadius: '20px',
                            border: '1px solid #79B7DF',
                            padding: '0px 14px 0px 14px',
                            height: '42px',
                          }}
                          InputProps={{
                            endAdornment: <CalendarTodayIcon color='disabled' />,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <IconButton
                        className='icon-button avatar-button'
                        title='Refresh'
                        onClick={() =>
                          this.getReferDatas(
                            DateTimeToStrDate(this.state.selectedDate),
                            DateTimeToStrDate(this.state.selectedDate)
                          )
                        }
                      >
                        <RefreshIcon style={{ color: '#3F98D2' }} />
                      </IconButton>
                    </Grid>
                  </Row>
                </Col>
                <Col lg={2} />
                <Col lg={4} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={8}>
                      <FormControl fullWidth variant='outlined'>
                        <Select
                          labelId='hospcode-select'
                          id='hospcode-select'
                          displayEmpty
                          value={this.state.hospCode}
                          onChange={this.hospCodeChange}
                          style={{ borderRadius: '20px', height: '42px' }}
                        >
                          <MenuItem value='all'>โรงพยาบาลทั้งหมด</MenuItem>
                          {this.state.hospCodeData.map((items, index) => (
                            <MenuItem key={index} value={items.id}>
                              {items.hospName ? items.hospName : items.hospCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={4}>
                      <FormControl
                        fullWidth
                        style={{
                          marginLeft: '8px',
                        }}
                        variant='outlined'
                      >
                        <Select
                          labelId='hospcode-select'
                          id='hospcode-select'
                          displayEmpty
                          value={this.state.referPoint}
                          onChange={this.setReferPointFc}
                          style={{ borderRadius: '20px', height: '42px' }}
                        >
                          <MenuItem value='all'>ทั้งหมด</MenuItem>
                          <MenuItem value='er'>- ER -</MenuItem>
                          <MenuItem value='opd'>- OPD -</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Row>
                </Col>
                <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <form style={{ maxWidth: '300px' }} onSubmit={this.getReferCidDatas}>
                    <InputBase
                      fullWidth
                      type='text'
                      id='cid'
                      name='cid'
                      value={this.state.cid}
                      onChange={this.onCidChange}
                      placeholder='CID / ชื่อ สกุล'
                      style={{ borderRadius: '20px', backgroundColor: 'whitesmoke', height: '42px' }}
                      startAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            id='SearchPtReferBt'
                            type='submit'
                            className='icon-button text-color'
                            aria-label='toggle password visibility'
                            edge='end'
                          >
                            <SearchIcon color='action' />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </form>
                </Col>
                <Col />
              </Row>
            </div>
          </Toolbar>
        </AppBar>
        {this.state.ReferDatas.length > 0 && (
          <div
            className='text-align-left'
            style={{
              marginTop: this.props.width <= 991 ? '170px' : '120px',
              marginBottom: '25px',
            }}
          >
            <Row>
              {this.state.ReferDatas?.filter(item =>
                this.state.Sname !== '' && this.state.Sname?.trim()?.length > 0
                  ? new RegExp(this.state.Sname?.toUpperCase())?.test(item.ptname?.toUpperCase())
                  : item
              )?.map((items2, index2) => (
                <Col key={index2} lg={4} className='margin-bottom-8px'>
                  <Card
                    variant='outlined'
                    style={{ maxHeight: '256px', height: '100%' }}
                    className={` card-refer 
										${items2?.status?.toUpperCase() === 'REJECTED' && 'referrals-card-rejected'} 
										${items2?.status?.toUpperCase() === 'ACCEPTED' && 'referrals-card-complete'}
                    ${items2?.ptDeceasedBoolean && 'referrals-card-deceased'}
                    `}
                  >
                    <CardHeader
                      style={{ backgroundColor: 'white', border: '0.2px solid #C2C9D1' }}
                      avatar={
                        <Avatar title={'Type : ' + items2.priority} aria-label='recipe'>
                          {this.state.ReferDatas.length - index2}
                        </Avatar>
                      }
                      action={
                        <div style={{ display: 'flex' }}>
                          <EmergencyBadge priority={items2?.priority} />
                          <div>
                            {items2.status.toUpperCase() === PENDING && this.props.appData?.permission?.actionRefer && (
                              <div>
                                <IconButton aria-label='settings' onClick={e => this.handleClick(e, index2)}>
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  key={index2}
                                  id={'refer-action-menu' + index2}
                                  anchorEl={this.state.anchorEl[index2]}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl[index2])}
                                  onClose={() => this.handleClose(index2)}
                                >
                                  <MenuItem onClick={() => this.DialogAcceptOpen(index2)}>
                                    Accept (ยอมรับ Refer)
                                  </MenuItem>
                                  <MenuItem onClick={() => this.DialogRejectOpen(index2)}>
                                    Reject (ปฏิเสธ Refer)
                                  </MenuItem>
                                </Menu>

                                <ReferAcceptDialog
                                  open={Boolean(this.state.DialogAcceptOpen[index2])}
                                  cid={items2?.cid}
                                  ptname={items2?.ptname}
                                  hospName={shortHospName(items2?.fromHospName)}
                                  clinicName={items2?.destinationClinic}
                                  appointmentDateTime={items2?.appointmentDateTime}
                                  onClose={() => this.DialogAcceptClose(index2)}
                                  onSubmit={appointmentData =>
                                    this.ReferAcceptSubmit(appointmentData, index2, items2.bid)
                                  }
                                />

                                <ReferRejectDialog
                                  open={Boolean(this.state.DialogRejectOpen[index2])}
                                  cid={items2?.cid}
                                  ptname={items2?.ptname}
                                  hospName={shortHospName(items2?.fromHospName)}
                                  reason={this.state.reason[index2]}
                                  onClose={() => this.DialogRejectClose(index2)}
                                  onChange={e => this.onReasonChange(e, index2)}
                                  onSubmit={() => this.ReferRejectSubmit(index2, items2.bid)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      title={'From : ' + shortHospName(items2.fromHospName)}
                      subheader={'To : ' + shortHospName(items2.toHospName)}
                    />
                    <CardContent className='card-content'>
                      <Row>
                        <Col lg={12}>
                          <Typography component='h2' variant='h5' className='cursor-pointer'>
                            <Link
                              component='button'
                              onClick={() => this.emrViews(items2.bid, items2.cid, items2.visitBid)}
                              style={{ color: '#3F98D2' }}
                            >
                              {items2.ptname}
                            </Link>
                          </Typography>
                        </Col>
                        <Col lg={12}>
                          <Typography variant='body1' component='p' color='textSecondary'>
                            CID : {items2.cid}
                          </Typography>
                        </Col>

                        <Col lg={12}>
                          <Typography variant='body2' component='p' color='textSecondary'>
                            Pre Diag : {items2.prediagnosis}
                          </Typography>
                        </Col>
                        <Col lg={12}>
                          <Typography variant='body2' component='p' color='textSecondary'>
                            ICD : {items2.icd10.map(item => `${item} `)}
                          </Typography>
                        </Col>
                        <Col lg={12}>
                          <Typography variant='body2' component='p' className='font-size-12px' color='textSecondary'>
                            Refer Date : {ThaiDateTime(items2.referDateTime)}
                          </Typography>
                        </Col>
                      </Row>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
    fetchRefersAccept: dataRequest => dispatch(fetchRefersAccept(dataRequest)),
    fetchRefersReject: dataRequest => dispatch(fetchRefersReject(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferIn);
