import { APPDATA } from '../constants/appData';
import GetUserByHospCode from '../api/UserManagement/GetUserByHospCode';
import FetchUsers from '../api/UserManagement/FetchUsers';
import AddUser from '../api/UserManagement/AddUser';
import EditUser from '../api/UserManagement/EditUser';

export function fetchUsers({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) {
  return {
    type: APPDATA,
    payload: FetchUsers({ idToken, page, limit, sortColumn, sortType, filterText, filterList }),
  };
}

export function fetchUserByHospCode(req) {
  return {
    type: APPDATA,
    payload: GetUserByHospCode(req),
  };
}

export function addUser(req) {
  return {
    type: APPDATA,
    payload: AddUser(req),
  };
}

export function editUser(req) {
  return {
    type: APPDATA,
    payload: EditUser(req),
  };
}
