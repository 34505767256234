export enum ReferralStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  RECORDED_BY_DEST = 'RECORDED_BY_DEST',
}

export enum ReferralType {
  REFER_IN = 'REFER_IN',
  REFER_OUT = 'REFER_OUT',
}

export interface IReferral {
  _id: string;
  pttype: string;
  fromHospCode: string;
  toHospCode: string;
  department: string;
  referNumber: string;
  cid: string;
  hn: string;
  referoutId: string;
  an: string;
  doctorName: string;
  visitDateTime: Date;
  referDateTime: Date;
  ptname: string;
  pttypeName: string;
  refername: string;
  referPoint: string;
  preDiagnosis: string;
  icd10: string[];
  icd10Name: string;
  spcltyName: string;
  departmentName: string;
  referoutTypeName: string;
  referoutEmergencyTypeName: string;
  treatmentText: string;
  dueDateTime: Date;
  expireDateTime: Date;
  reason: string;
  incomplete: boolean;
}
