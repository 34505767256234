import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

//styles
import { StyledTextField, theme, useStyles } from './styles';

//actions
import { fetchPttype, searchPatient, submit } from '../../../actions/Refers/ReferInForm';
import { fetchHospitalList } from '../../../actions/Commons';

// utilities
import { ListboxComponent } from '../../Utility/ListboxComponent';

// constants
import {
  icd10List,
  titleNameList,
  reasonList,
  referTypeList,
  priorityList,
  acuityLevelList,
  departmentList,
  hospitalTypeList,
} from '../../../constants/referForm';

// functions
import { titleNameAndSex } from '../../../functions/ReferForm';

//mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  FormGroup,
  MenuItem,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Snackbar,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

export default function ReferInForm() {
  const history = useHistory();
  const classes = useStyles();
  const { handleSubmit, register, watch, setValue, errors, control } = useForm();

  const [bgColor, setBgColor] = useState('#79d6f2');
  const [referDateTime, setReferDateTime] = useState(new Date());
  const [expireDateTime, setExpireDateTime] = useState(new Date());
  const [beginDateTime, setBeginDateTime] = useState(new Date());
  const [icd10, setIcd10] = useState([]);
  const [currentSection, setCurrentSection] = useState('1');
  const [showAlert, setShowAlert] = useState(false);
  const [pttype, setPttype] = useState({});
  const [selectedHospital, setSelectedHospital] = useState({});

  const dispatch = useDispatch();
  const { appData } = useSelector(state => state);
  const { idToken, referInForm, alert, loginData, pttypeList, hospitalList, searchHospital } = appData;

  useEffect(() => {
    // NOTE: maybe must change hospCode later
    handleFetchPttype({ hospCode: '11535' });
    handleFetchHospitalList({ idToken });
  }, []);

  useEffect(() => {
    pttypeList?.length > 0 && setPttype(pttypeList[0]);
  }, [pttypeList]);

  useEffect(() => {
    if (referInForm) {
      if (referInForm.length !== 0) {
        setShowAlert(false);
        setCurrentSection('3');
      } else {
        setShowAlert(true);
        setCurrentSection('2');
      }
    } else {
      setShowAlert(false);
      setCurrentSection('1');
    }
  }, [referInForm]);

  useEffect(() => {
    if (alert?.type === 'success') {
      const today = moment(new Date()).format('YYYY-MM-DD');
      history.push(`refer-in/${today}/${today}`);
    }
  }, [alert]);

  useEffect(() => {
    searchHospital && setValue('fromHospCode', searchHospital.hospCode + ' - ' + searchHospital.hospName);
  }, [searchHospital]);

  const handleSearchPatient = async ({ patient__cid }) => {
    setValue('citizenship', patient__cid);
    await dispatch(searchPatient({ idToken, cid: patient__cid }));
  };

  const handleFetchPttype = async ({ hospCode }) => {
    await dispatch(fetchPttype({ hospCode, idToken }));
  };

  const handleFetchHospitalList = ({ idToken }) => {
    dispatch(fetchHospitalList({ idToken }));
  };

  const onSubmit = async ({
    title,
    firstName,
    lastName,
    citizenship,
    priority,
    beginDateTime,
    expireDateTime,
    note,
    prediagnosis,
    sendReason,
    sendReasonOther,
    referType,
    department,
    departmentOther,
    attachments,
    letterAttachments,
    bmaHospital,
    trauma,
    acuityLevel,
    hospType,
    referDateTime,
  }) => {
    // prevent user press enter button but input invalid option in autocomplete
    if (!pttype || pttype.length <= 0) return false;

    const body = {
      patient: {
        bid: citizenship,
        data: referInForm?.data || {
          fullname: {
            title,
            firstName,
            lastName,
          },
          hn: [],
          drugAllergies: [],
        },
      },
      data: {
        cid: citizenship,
        hospCode: loginData.hospCode,
        hospName: loginData.hospName,
        referDateTime,
        pttype: pttype.bid,
        prediagnosis,
        icd10: icd10?.map(item => item?.label) || [],
        sendReason,
        sendReasonOther,
        referType,
        department,
        departmentOther,
        priority,
        fromHospCode: selectedHospital?.hospCode,
        fromHospName: selectedHospital?.hospName,
        beginDateTime,
        expireDateTime,
        note,
        bmaHospital,
        trauma,
        acuityLevel,
        hospType,
      },
    };

    await dispatch(submit({ idToken, body, attachments, letterAttachments }));
  };

  const handleDeleteICD10 = chipToDelete => {
    setIcd10(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth='md'>
        <Typography variant='h6' align='left' gutterBottom>
          บันทึกประวัติส่งต่อ
        </Typography>

        {/* ANCHOR: 1 - search visit */}
        <form onSubmit={handleSubmit(handleSearchPatient)}>
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      required
                      fullWidth
                      label='เลข CID'
                      placeholder='โปรดใส่เลข CID ของผู้ป่วยที่จะค้นหาประวัติเพื่อทำการบันทึก'
                      onFocus={e => setCurrentSection('1')}
                      variant='outlined'
                    />
                  }
                  defaultValue=''
                  control={control}
                  name='patient__cid'
                />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.formBtn}>
                <Button
                  fullWidth={useMediaQuery(theme.breakpoints.down('xs'))}
                  color='primary'
                  variant='outlined'
                  type='submit'
                >
                  ค้นหาประวัติผู้ป่วย
                </Button>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Alert hidden={!showAlert} className={classes.alert} severity='error'>
                <AlertTitle>ไม่พบ patient</AlertTitle>
                กรุณากรอกเลข cid ให้ถูกต้องหรือกรอกประวัติผู้ป่วยด้านล่าง
              </Alert>
            </Grid>
          </Grid>
        </form>

        <Divider />

        {/* ANCHOR: 2 - select visit*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      disabled={referInForm && referInForm.length !== 0}
                      onFocus={e => setCurrentSection('2')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='title'
                  defaultValue={titleNameAndSex({ titleNameValue: referInForm?.data?.fullname?.title })?.label}
                  key={referInForm?.data?.fullname?.title}
                >
                  {titleNameList?.map(item => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Controller>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  as={
                    <TextField
                      required
                      fullWidth
                      disabled={referInForm && referInForm.length !== 0}
                      label='ชื่อ'
                      onFocus={e => setCurrentSection('2')}
                      variant='outlined'
                    />
                  }
                  name='firstName'
                  control={control}
                  defaultValue={referInForm?.data?.fullname?.firstName || ''}
                  key={referInForm?.data?.fullname?.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  as={
                    <TextField
                      required
                      fullWidth
                      disabled={referInForm && referInForm.length !== 0}
                      label='นามสกุล'
                      onFocus={e => setCurrentSection('2')}
                      variant='outlined'
                    />
                  }
                  name='lastName'
                  control={control}
                  defaultValue={referInForm?.data?.fullname?.lastName || ''}
                  key={referInForm?.data?.fullname?.lastName}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Controller
                  as={<RadioGroup row />}
                  control={control}
                  name='sex'
                  defaultValue={referInForm?.data?.sex || '1'}
                  key={referInForm?.data?.sex}
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio color='primary' />}
                    label='ชาย'
                    disabled={referInForm && referInForm.length !== 0}
                    onFocus={e => setCurrentSection('2')}
                  />
                  <FormControlLabel
                    value='2'
                    control={<Radio color='primary' />}
                    label='หญิง'
                    disabled={referInForm && referInForm.length !== 0}
                    onFocus={e => setCurrentSection('2')}
                  />
                  <FormControlLabel
                    value='3'
                    control={<Radio color='primary' />}
                    disabled={referInForm && referInForm.length !== 0}
                    onFocus={e => setCurrentSection('2')}
                  />
                  <Controller
                    as={
                      <TextField
                        fullWidth={useMediaQuery(theme.breakpoints.down('xs'))}
                        required={watch('sex') === '3'}
                        disabled={watch('sex') !== '3' || (referInForm && referInForm.length !== 0)}
                        label='อื่น ๆ'
                        onFocus={e => setCurrentSection('2')}
                        variant='outlined'
                      />
                    }
                    control={control}
                    name='sexname'
                    defaultValue={referInForm?.data?.sex === '3' ? referInForm?.data?.sexname : ''}
                    key={referInForm?.data?.sex === '3' ? referInForm?.data?.sexname : ''}
                  />
                </Controller>
              </Grid>

              <Grid item xs={12} sm={5}>
                <Controller
                  as={
                    <TextField
                      required
                      fullWidth
                      disabled
                      // type='number'
                      label={watch('citizenship', '') !== '' && 'เลขบัตรประชาชน / บัตรต่างด้าว'}
                      placeholder='เลขบัตรประชาชน / บัตรต่างด้าว'
                      InputLabelProps={{ shrink: true }}
                      onFocus={e => setCurrentSection('2')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='citizenship'
                />
              </Grid>
            </Grid>
          </div>
          <Divider />

          {/* ANCHOR: 3 - submit form */}
          <div className={classes.unfocus}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  onChange={(option, value) => setPttype(value)}
                  options={pttypeList}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      margin='normal'
                      label='สิทธิการรักษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  )}
                  value={pttype}
                />

                <Controller
                  as={
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      autoOk
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      label='วันที่ของ Visit ที่จะส่งต่อ :'
                      onChange={e => setReferDateTime(e)}
                      onClick={e => setCurrentSection('3')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  }
                  control={control}
                  name='referDateTime'
                  defaultValue={referDateTime}
                />

                <Controller
                  as={
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      autoOk
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      label='วันหมดอายุใบส่งต่อ'
                      onChange={e => setExpireDateTime(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onClick={e => setCurrentSection('3')}
                    />
                  }
                  control={control}
                  name='expireDateTime'
                  defaultValue={expireDateTime}
                  key={expireDateTime}
                />
                <Controller
                  as={
                    <TextField
                      fullWidth
                      margin='normal'
                      label='การวินิจฉัยโรคขั้นต้น'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='prediagnosis'
                />

                <div className={classes.chipWrapper}>
                  {icd10?.map(item => (
                    <Chip
                      label={item.label}
                      size='small'
                      color='secondary'
                      variant='outlined'
                      onDelete={e => handleDeleteICD10(item)}
                      className={classes.chip}
                    />
                  ))}
                </div>

                <Autocomplete
                  onChange={(option, value) => value && setIcd10([...icd10, { key: uuid(), label: value.name }])}
                  ListboxComponent={ListboxComponent}
                  options={icd10List}
                  getOptionLabel={option => `${option.name} - ${option.description}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      margin='normal'
                      label='รหัส ICD 10 ของโรค'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  )}
                />

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='เหตุผลการส่งต่อ'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='sendReason'
                  defaultValue='01'
                >
                  {reasonList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      fullWidth
                      required={watch('sendReason') === '99'}
                      hidden={watch('sendReason') !== '99'}
                      margin='normal'
                      label='ระบุเหตุผลการส่งต่อ'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='sendReasonOther'
                  defaultValue=''
                />

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='ประเภทการส่งตัว'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='referType'
                  defaultValue='OPD'
                >
                  {referTypeList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='ความรุนแรงของผู้ป่วย'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='acuityLevel'
                  defaultValue='N'
                >
                  {acuityLevelList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>
                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      margin='normal'
                      label='สาขาที่ปรึกษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='department'
                  defaultValue='419192003'
                >
                  {departmentList?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Controller>

                <Controller
                  as={
                    <TextField
                      fullWidth
                      required={watch('department') === '74964007'}
                      hidden={watch('department') !== '74964007'}
                      margin='normal'
                      label='ระบุสาขาที่ปรึกษา'
                      onFocus={e => setCurrentSection('3')}
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='departmentOther'
                />

                <FormGroup row>
                  <FormControlLabel
                    inputRef={register}
                    label='สังกัด กทม.'
                    control={<Checkbox />}
                    name='bmaHospital'
                    onFocus={e => setCurrentSection('3')}
                  />
                  <FormControlLabel
                    inputRef={register}
                    label='trauma'
                    control={<Checkbox />}
                    name='trauma'
                    onFocus={e => setCurrentSection('3')}
                  />
                </FormGroup>

                <div className={classes.hospitalContainer}>
                  <Controller
                    as={
                      <TextField
                        select
                        fullWidth
                        margin='normal'
                        label='ประเภทโรงพยาบาลต้นทาง'
                        onFocus={e => setCurrentSection('3')}
                        variant='outlined'
                      />
                    }
                    control={control}
                    name='hospType'
                    defaultValue='08'
                  >
                    {hospitalTypeList?.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Controller>

                  <Autocomplete
                    disableListWrap
                    loading={!hospitalList[0]}
                    ListboxComponent={ListboxComponent}
                    options={hospitalList}
                    onChange={(option, value) => setSelectedHospital(value)}
                    getOptionLabel={option => option.hospName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        margin='normal'
                        variant='outlined'
                        label='โรงพยาบาลต้นทาง'
                        onFocus={e => setCurrentSection('3')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {!hospitalList[0] ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  <Controller
                    as={
                      <KeyboardDatePicker
                        disableToolbar
                        fullWidth
                        autoOk
                        variant='inline'
                        format='dd/MM/yyyy'
                        margin='normal'
                        label='วันที่เริ่มใช้สิทธิ'
                        onChange={e => setBeginDateTime(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        onClick={e => setCurrentSection('3')}
                      />
                    }
                    control={control}
                    name='beginDateTime'
                    defaultValue={beginDateTime}
                    key={beginDateTime}
                  />
                  <Controller
                    as={
                      <StyledTextField
                        select
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        label='ระดับความเร่งด่วน'
                        bgColor={bgColor}
                        onChange={e => setBgColor(priorityList?.filter(item => item.value === e.target.value)[0].color)}
                        onFocus={e => setCurrentSection('3')}
                      />
                    }
                    control={control}
                    name='priority'
                    defaultValue='5'
                  >
                    {priorityList?.map(item => (
                      <MenuItem key={item.value} style={{ backgroundColor: item.color }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Controller>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      rows={8}
                      variant='outlined'
                      label='หมายเหตุ'
                      onFocus={e => setCurrentSection('3')}
                    />
                  }
                  control={control}
                  name='note'
                  defaultValue=''
                  rules={{ maxLength: 255 }}
                />

                {errors.note && (
                  <Typography align='right' color='error'>
                    Max length exceeded. (255)
                  </Typography>
                )}

                <Typography className={classes.uploadLabel}>รูปหนังสือรับผิดชอบค่าใช้จ่าย</Typography>

                <Controller
                  as={
                    <DropzoneArea
                      maxFileSize={8388608}
                      filesLimit={5}
                      classes={{ root: classes.unfocus }}
                      acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                    />
                  }
                  control={control}
                  name='attachments'
                />

                <Typography className={classes.uploadLabel}>รูปเพิ่มเติมอื่น ๆ</Typography>

                <Controller
                  as={
                    <DropzoneArea
                      maxFileSize={8388608}
                      filesLimit={5}
                      classes={{ root: classes.unfocus }}
                      acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                    />
                  }
                  control={control}
                  name='letterAttachments'
                />
              </Grid>
            </Grid>

            <Grid container className={classes.footer}>
              <Grid item xs={12} sm={12}>
                <Button
                  // fullWidth={useMediaQuery(theme.breakpoints.down('xs'))}
                  type='submit'
                  // color='primary'
                  variant='contained'
                  className={classes.submitBtn}
                  fullWidth
                >
                  บันทึกประวัติ
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button fullWidth  variant='contained' className={classes.cancelBtn}>
                  ยกเลิก
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>

        {alert?.show && alert?.type === 'error' && alert?.page === 'referInForm' && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoHideDuration={6000}
            open={alert.show}
          >
            <Alert severity={alert.type}>{alert.msg}</Alert>
          </Snackbar>
        )}
      </Container>
    </MuiThemeProvider>
  );
}
