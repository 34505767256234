import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// functions
import { valueToTextDepartment } from '../../../functions/ReferForm';

// actions
import { fetchRefers } from '../../../actions/ReferDatas';

// styles
import { theme } from './styles';

// mui
import { Button, Container, CssBaseline, Grid, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AlarmIcon from '@material-ui/icons/Alarm';

const columns = [
  { name: 'index', label: 'ลำดับ', options: { filter: false } },
  'วันที่',
  'รับจาก',
  'ประเภท รพ',
  'Refer',
  'OPD/IPD',
  'ในเครือ/นอกเครือ',
  'ชื่อ-สกุล',
  'CID',
  'HN',
  'สิทธิการรักษา',
  {
    name: 'nurseReceiveTime',
    label: 'เวลาที่ Nurse รับเรื่อง',
    options: { filter: false },
  },
  {
    name: 'receiveDocsTime',
    label: 'เวลารับเอกสาร',
    options: { filter: false },
  },
  {
    name: 'contactDoctorTime',
    label: 'เวลาติดต่อแพทย์',
    options: { filter: false },
  },
  {
    name: 'docterReceiveTime',
    label: 'เวลาแพทย์ตอบรับ',
    options: { filter: false },
  },
  {
    name: 'nurseReceiveToReceiveDocsTime',
    label: 'nurse รับเรื่องถึงรับเอกสาร',
    options: { filter: false },
  },
  {
    name: 'receiveDocsToContactDoctorTime',
    label: 'รับเอกสารถึงติดต่อแพทย์',
    options: { filter: false },
  },
  {
    name: 'doctorConsiderTime',
    label: 'เวลาแพทย์พิจารณาเคสถึงตอบกลับ',
    options: { filter: false },
  },
  {
    name: 'totalTime',
    label: 'รวมระยะเวลาทั้งหมด',
    options: { filter: false },
  },
  'รับเคส/ไม่รับเคส',
  'สถานะ',
  'แพทย์ผู้รับเรื่อง',
  'Dx',
  'สาขา',
  'Trauma',
  { name: 'note', label: 'หมายเหตุ', options: { filter: false } },
];

const options = {
  selectableRows: 'none',
  onDownload: (buildHead, buildBody, columns, data) => {
    const tmpData = data.map(item => ({
      index: item.index,
      data: item.data.map(item2 =>
        typeof item2 === 'object' ? (item2.type.displayName === 'CheckIcon' ? 'True' : 'False') : item2
      ),
    }));

    return '\uFEFF' + buildHead(columns) + buildBody(tmpData);
  },
};

export default function TimeReport() {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  // actually don't use it yet, prepare for future user can select source hospital and destination hospital to view report.
  const [fromHospCode, setFromHospCode] = useState('');
  const [toHospCode, setToHospCode] = useState('');
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();
  const { appData } = useSelector(state => state);
  const { emrData, idToken, loginData } = appData;

  useEffect(() => {
    getRefers();
  }, [startDate, endDate, fromHospCode, toHospCode]);

  useEffect(() => {
    if (emrData.referDatas?.length > 0 && loginData) {
      const data = _(emrData.referDatas)
        .filter(item => item.fromHospCode === loginData.hospCode || item.toHospCode === loginData.hospCode)
        .map((item, index) => [
          index + 1,
          moment(item.referDateTime).format('DD/MM/YYYY'),
          item.fromHospCode === loginData.hospCode ? '-' : item.fromHospName,
          item.fromHospCode === loginData.hospCode ? '-' : item.hospType,
          item.fromHospCode === loginData.hospCode ? 'OUT' : 'IN',
          item.referType,
          item.bmaHospital ? 'ในเครือ' : 'นอกเครือ',
          item.ptname,
          item.cid,
          item.hn || '-',
          item.pttype,
          item.readDateTime ? moment(item.readDateTime).format('HH:mm:ss') : '-',
          item.readDateTime ? moment(item.readDateTime).format('HH:mm:ss') : '-',
          item.contactDoctorDateTime ? moment(item.contactDoctorDateTime).format('HH:mm:ss') : '-',
          item.acceptRejectDateTime ? moment(item.acceptRejectDateTime).format('HH:mm:ss') : '-',
          diffTime(item.readDateTime, item.readDateTime),
          diffTime(item.contactDoctorDateTime, item.readDateTime),
          diffTime(item.acceptRejectDateTime, item.contactDoctorDateTime),
          sumTime(
            diffTime(item.contactDoctorDateTime, item.readDateTime),
            diffTime(item.acceptRejectDateTime, item.contactDoctorDateTime)
          ),
          item.status === 'PENDING' ? 'รอ' : item.status === 'ACCEPTED' ? 'รับ' : 'ไม่รับ',
          item.receiveReason || '-',
          item.doctorName || '-',
          item.prediagnosis,
          item.department !== '74964007' ? valueToTextDepartment(item.department) : item.departmentOther,
          item.trauma ? <CheckIcon color='primary' /> : <ClearIcon color='secondary' />,
          item.note?.length > 50 ? `${item.note.slice(0, 50)}...` : item.note,
        ])
        .value();

      setTableData(data);
    } else setTableData([]);
  }, [emrData.referDatas]);

  const diffTime = (time2, time1) => {
    // check time 1 can subtract by time 2
    if (moment(time2).isBefore(moment(time1))) return '00:00:00';

    if (time2 && time1) {
      return moment.utc(moment.duration(moment(time2).diff(moment(time1))).as('milliseconds')).format('HH:mm:ss');
    } else if (time2) return moment(time2).format('HH:mm:ss');
    else return '-';
  };

  const sumTime = (receiveDocsToContactDoctorTime, doctorConsiderTime) => {
    var sumDateTime = moment.duration(receiveDocsToContactDoctorTime).add(moment.duration(doctorConsiderTime));
    return moment.utc(sumDateTime.as('milliseconds')).format('HH:mm:ss');
  };

  const getRefers = async () => {
    const data = {
      idToken,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      fromHospCode,
      toHospCode,
      limit: '1000',
    };

    await dispatch(fetchRefers(data));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth='false'>
        <Grid
          container
          style={{
            backgroundColor: '#fff',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            padding: '14px',
          }}
        >
          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              autoOk
              variant='inline'
              format='dd/MM/yyyy'
              margin='normal'
              id='date-picker-inline'
              label='วันที่เริ่มต้น'
              value={startDate}
              onChange={e => setStartDate(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{
                borderRadius: '18px',
                border: '1px solid #79B7DF',
                padding: '0px 14px 0px 14px',
                height: 'auto',
                marginRight: '10px',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              autoOk
              variant='inline'
              format='dd/MM/yyyy'
              margin='normal'
              id='date-picker-inline'
              label='วันที่สิ้นสุด'
              value={endDate}
              onChange={e => setEndDate(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{
                borderRadius: '18px',
                border: '1px solid #79B7DF',
                padding: '0px 14px 0px 14px',
                height: 'auto',
                marginLeft:'10px'
              }}
            />
          </Grid>
        </Grid>

        <MUIDataTable
          title={
            <Button disabled startIcon={<AlarmIcon />}>
              {' '}
              Time Report
            </Button>
          }
          data={tableData}
          columns={columns}
          options={options}
        />
      </Container>
    </MuiThemeProvider>
  );
}
