import { createMuiTheme, makeStyles, TextField, withStyles } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiDivider: {
      root: {
        width: '100%',
        margin: '20px 0 !important',
      },
    },
    MuiGrid: {
      container: {
        paddingBottom: '16px',
      },
    },
    MuiFormControl: {
      root: {
        textAlign: 'left',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginTop: '15px',
      },
    },
    MuiDropzoneArea: {
      root: {
        padding: '20px',
      },
      icon: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      text: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
});

export const useStyles = makeStyles(({ breakpoints }) => ({
  formBtn: {
    alignSelf: 'flex-end',
    textAlign: 'end',
  },
  submitBtn: {
    minWidth: breakpoints.up('md') && 140,
    marginRight: '10px',
    marginBottom: '10px',
    borderRadius: 10,
    color: 'white',
    backgroundColor: '#6CC4A1',
    '&:hover': {
      backgroundColor: '#27A06D',
    },
  },
  cancelBtn: {
    borderRadius: 10,
    backgroundColor: 'white',
    color: '#2F729D',
  },
  footer: {
    textAlign: 'right',
    marginBottom: '25px',
  },
  uploadLabel: {
    marginTop: '20px',
    marginBottom: '5px',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  hospitalContainer: {
    border: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '0 10px',
  },
  unfocus: {
    borderRadius: 16,
    backgroundColor: '#fff',
    padding: '20px',
  },
  alert: {
    textAlign: 'left',
  },
  chipWrapper: {
    textAlign: 'left',
  },
  chip: {
    marginTop: 16,
    marginRight: 8,
  },
}));

export const StyledTextField = withStyles({
  root: {
    textAlign: 'left',
    backgroundColor: props => props.bgColor,
  },
})(TextField);
