import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';
import { appPropertySet } from '../../actions/AppProperty';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';

const session = orm.session();

function AdmitData({ bid, an, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.an === an)
      .filter(Visits => Visits.an !== '')
      // .filter((Visits) => Visits.class === 'IMP')
      .toRefArray();

    setvisitData({ visitsData });
  };

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              <Card variant='outlined' className='visit-detail-card'>
                <Row>
                  <Col lg={12}>
                    <h5>AN : {items.an}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <h6>WARD : {items.wardName}</h6>
                  </Col>

                  <Col lg={4}>
                    <h6>วันที่รับ : {items.regDateTime && ThaiDateTime(items.regDateTime)}</h6>
                  </Col>

                  <Col lg={4}>
                    <h6>วันที่จำหน่วย : {items.dchDateTime && ThaiDateTime(items.dchDateTime)}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <h6>แผนก : {items.spcltyName}</h6>
                  </Col>

                  <Col lg={4}>
                    <h6>สิทธการรักษา : {items.pttypeName}</h6>
                  </Col>

                  <Col lg={4}>
                    <h6>แพทย์ : {items.inchargeDoctorName}</h6>
                  </Col>
                </Row>
                {items.prediag && (
                  <Row>
                    <Col lg={12}>
                      <h6>Pre Diag : {items.prediag}</h6>
                    </Col>
                  </Row>
                )}
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdmitData));
