import { APPDATA } from '../constants/appData';
import { FetchHospitalList } from '../api/FetchHospitalList';

export function fetchHospitalList({
  idToken,
  page,
  limit,
  sortColumn,
  sortType,
  filterText,
  filterList,
  fetchHospListByMember,
}) {
  return {
    type: APPDATA,
    payload: FetchHospitalList({
      idToken,
      page,
      limit,
      sortColumn,
      sortType,
      filterText,
      filterList,
      fetchHospListByMember,
    }),
  };
}
