import React, { Component } from 'react';
import { connect } from 'react-redux';
import orm from '../../../models/index';

// actions
import { appPropertySet } from '../../../actions/AppProperty';
import { fetchRefers } from '../../../actions/ReferDatas';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// components
import ReferOutPercentChart from '../ReferOutPercentChart';
import ReferOutQtyChart from '../ReferOutQtyChart';
import ReferOutDiagQtyChart from '../ReferOutDiagQtyChart';
import ReferOutDiag4BranchChart from '../ReferOutDiag4BranchChart';

// functions
import { shortHospName } from '../../../functions/FuncPerjer';

// styles
import { Container } from './ReferOutReport.style';

// types
import { IReferral, ReferralStatus, ReferralType } from '../../../types/referral';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GridOnIcon from '@material-ui/icons/GridOn';
import Tooltip from '@material-ui/core/Tooltip';

interface IState {
  hospCodeData: [];
  ReferDatas: any;
  ReferPendingData: [];
  ReferAcceptData: [];
  ReferRejectData: [];
  ReferPtData: [];
}

interface IProps {
  StartDate: Date;
  EndDate: Date;
  ReferDatas: IReferral[];
  filterDeathPatient: boolean;
}

const session = orm.session();

class ReferOutReport extends Component<IProps, IState> {
  state: IState = {
    hospCodeData: [],
    ReferDatas: [],
    ReferPtData: [],
    ReferPendingData: [],
    ReferAcceptData: [],
    ReferRejectData: [],
  };

  constructor(props: IProps | Readonly<IProps>) {
    super(props);

    this.ReferProcess = this.ReferProcess.bind(this);
  }

  ReferProcess() {
    const referDb = this.state.ReferDatas;

    session.ReferOutDatas.delete({
      where(record: { _id: string }) {
        return record._id !== '';
      },
    });

    session.ReferHosp.delete({
      where(record: { id: string }) {
        return record.id !== '';
      },
    });

    session.TempData.delete({
      where(record: { id: string }) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferOutDatas.upsert(val);

      session.ReferHosp.upsert({
        id: val?.data?.toHospCode,
        hospName: val?.toHospName ? shortHospName(val?.toHospName) : val?.data?.toHospCode,
      });

      session.TempData.upsert({
        id: val?.data?.toHospCode + val?.data?.hn,
        ptname: val?.data?.ptname,
      });
    }

    let hospPtData2 = session.TempData.all()
      .filter((TempData: { id: string }) => TempData.id !== '')
      .toRefArray();

    this.setState({ ReferPtData: hospPtData2 });

    let hospCodeData2 = session.ReferHosp.all()
      .filter((ReferHosp: { id: string }) => ReferHosp.id !== '')
      .orderBy((ReferHosp: { hospName: any }) => ReferHosp.hospName, 'asc')
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });

    let referPendingData = session.ReferOutDatas.all()
      .filter((ReferOutDatas: { status: string }) => ReferOutDatas.status === ReferralStatus.PENDING)
      .toRefArray();

    this.setState({ ReferPendingData: referPendingData });

    let referAcceptData = session.ReferOutDatas.all()
      .filter((ReferOutDatas: { status: string }) => ReferOutDatas.status === ReferralStatus.ACCEPTED)
      .toRefArray();

    this.setState({ ReferAcceptData: referAcceptData });

    let referRejectData = session.ReferOutDatas.all()
      .filter((ReferOutDatas: { status: string }) => ReferOutDatas.status === ReferralStatus.REJECTED)
      .toRefArray();

    this.setState({ ReferRejectData: referRejectData });
  }

  componentDidMount() {
    if (this.state.ReferDatas !== this.props.ReferDatas) {
      this.setState({ ReferDatas: this.props.ReferDatas });
      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  componentDidUpdate() {
    if (this.state.ReferDatas !== this.props.ReferDatas) {
      this.setState({
        ReferDatas: this.props.ReferDatas,
      });

      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  render() {
    return (
      <Container>
        {this.props.ReferDatas.length > 0 && (
          <div>
            <Card className='card-refer margin-bottom-8px'>
              <CardContent>
                <Row>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='info'>
                      <h6>
                        <strong>
                          Refer Out{this.props.filterDeathPatient ? ' (Death)' : ''} :{' '}
                          {this.state.ReferDatas && this.state.ReferDatas.length} ครั้ง /{' '}
                          {this.state.ReferPtData.length} คน
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='warning'>
                      <h6>
                        <strong>
                          Pending : {this.state.ReferPendingData && this.state.ReferPendingData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='success'>
                      <h6>
                        <strong>
                          Accept : {this.state.ReferAcceptData && this.state.ReferAcceptData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='error'>
                      <h6>
                        <strong>
                          Reject : {this.state.ReferRejectData && this.state.ReferRejectData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            <Row>
              <Col lg={6} className='margin-bottom-8px'>
                <ReferOutQtyChart
                  ReferOutData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                  filterDeathPatient={this.props.filterDeathPatient}
                />
              </Col>
              <Col lg={6} className='margin-bottom-8px'>
                <ReferOutPercentChart
                  ReferOutData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                  filterDeathPatient={this.props.filterDeathPatient}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} className={`margin-bottom-8px refer-4-branch-chart`}>
                <ReferOutDiag4BranchChart
                  ReferOutData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                  filterDeathPatient={this.props.filterDeathPatient}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} className='margin-bottom-8px'>
                <ReferOutDiagQtyChart
                  ReferOutData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                  filterDeathPatient={this.props.filterDeathPatient}
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: { appProperty: any; appData: any }) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch: (arg0: { type: string; payload: Promise<any> }) => any) {
  return {
    appPropertySet: (appPropertyData: any) => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: (dataRequest: any) => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferOutReport);
