import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route, useHistory, withRouter } from 'react-router-dom';
import { Online } from 'react-detect-offline';
import io from 'socket.io-client';

// components
import Home from '../components/Homes/Home';
import ComingSoon from '../components/Homes/ComingSoon';
import UserLogin from '../components/Authentications/UserLogin';
import AppBackdrop from '../components/AppBackdrop';
import PatientEmrMain from '../components/PatientEmr/PatientEmrMain';
import ReferIn from '../components/Refers/ReferIn';
import ReferOut from '../components/Refers/ReferOut';
import OnlineGatewayHosp from '../components/Refers/OnlineGatewayHosp';
import EmtDashboard from '../components/EmtDashboard/EmtDashboard';
import GeospatialOutbreak from '../components/GeospatialOutbreak/GeospatialOutbreak';
import Settings from './Settings/Settings';
import EditCategory from './Settings/EditCategory/EditCategory';
import BillingsCharge from './BillingsCharge/BillingsCharge';
import ChargeSummary from './BillingsCharge/ChargeSummary/ChargeSummary';
import ChargePatients from './BillingsCharge/ChargePatients/ChargePatients';
import PatientBilling from './PatientBilling/PatientBilling';
import ReferForm from './Refers/ReferForm/ReferForm';
import ReferInForm from './Refers/ReferInForm/ReferInForm';
import TimeReport from './Refers/TimeReport/TimeReport';
import { UserManagement, UserForm } from './UserManagement';
import ReferOutForm from './Refers/ReferOutForm/ReferOutForm';
import { ReferReportMain, ReferReportChwMain } from '../components/Report';

// actions
import { unFetchHome } from '../actions/Home';

// functions
import { ThaiDateTime } from '../functions/FuncDateTimes';

// sounds
import UIfx from 'uifx';
import bellAudio from '../assets/sounds/bellAudio.mp3';

// mui
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

function ContainerMain({ appData }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [newReferral, setNewReferral] = useState(false);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    newReferral && playAlertSound();
  }, [newReferral]);

  useEffect(() => {
    appData.loginData && triggerIncomingReferral({ hospCode: appData.loginData?.hospCode });

    //redirect to home page if switch account
    const redirect = async () => {
      if (appData?.loginData) {
        if (appData.loginData.username !== appData.previousUser) {
          dispatch(unFetchHome());
          history.push('/');
        }
      }
    };

    redirect();
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  }, [appData.loginData]);

  const triggerIncomingReferral = ({ hospCode }) => {
    const socket = io(`${process.env.REACT_APP_ROOT_API}`);

    socket.on(`new-referral-${hospCode}`, referData => {
      setNewReferral(referData);
    });
  };

  const playAlertSound = () => {
    const bell = new UIfx(bellAudio, {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100,
    });

    // playback
    bell.play();
  };

  const renderAlert = ({ referral, ownHospCode }) => {
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') return;

      setNewReferral(false);
    };

    if (referral?.toHospName && referral?.fromHospCode === ownHospCode) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={10000}
          open={referral}
          onClose={e => handleCloseAlert()}
        >
          <Alert icon={<AssignmentReturnIcon color='secondary' />} severity='info' onClose={e => handleCloseAlert()}>
            <AlertTitle>{referral?.toHospName}</AlertTitle>
            {ThaiDateTime(referral?.date) || '-'}
          </Alert>
        </Snackbar>
      );
    } else {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={10000}
          open={referral}
          onClose={e => handleCloseAlert()}
        >
          <Alert icon={<AssignmentReturnedIcon color='primary' />} severity='info' onClose={e => handleCloseAlert()}>
            <AlertTitle>{referral?.fromHospName}</AlertTitle>
            {ThaiDateTime(referral?.date) || '-'}
          </Alert>
        </Snackbar>
      );
    }
  };

  return (
    <div className='container-main'>
      <div>
        <AppBackdrop />
        <UserLogin />
      </div>
      <div className='ContainerMainCustom'>
        {appData.idToken && (
          <>
            <Switch>
              <Route exact path='/' render={props => <Home {...props} Layout={Home} />} />

              <Route
                exact
                path='/patient-emr/:cid?/:bid?'
                render={props => <PatientEmrMain {...props} width={width} height={height} Layout={PatientEmrMain} />}
              />
              <Route
                exact
                path='/refer-in/:startDate?/:endDate?'
                render={props => <ReferIn {...props} width={width} height={height} Layout={ReferIn} />}
              />
              <Route
                exact
                path='/refer-out/:startDate?/:endDate?'
                render={props => <ReferOut {...props} width={width} height={height} Layout={ReferOut} />}
              />

              <Route
                exact
                path='/refer-time-report'
                render={props => <TimeReport {...props} width={width} height={height} Layout={TimeReport} />}
              />

              {/* Coming Soon */}

              <Route
                exact
                path='/register'
                render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
              />

              <Route
                exact
                path='/screening'
                render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
              />

              <Route
                exact
                path='/examination'
                render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
              />

              <Route
                exact
                path='/round-ward'
                render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
              />

              <Route
                exact
                path='/hosp-refer-report'
                render={props => <ReferReportMain {...props} width={width} height={height} Layout={ReferReportMain} />}
              />

              <Route
                exact
                path='/chw-refer-report'
                render={props => (
                  <ReferReportChwMain {...props} width={width} height={height} Layout={ReferReportChwMain} />
                )}
              />

              <Route
                exact
                path='/chw-refer-report'
                render={props => (
                  <ReferReportChwMain {...props} width={width} height={height} Layout={ReferReportChwMain} />
                )}
              />

              <Route
                exact
                path='/chw-refer-report'
                render={props => <ComingSoon {...props} width={width} height={height} Layout={ComingSoon} />}
              />
              <Route
                exact
                path='/emt-dashboard'
                render={props => <EmtDashboard {...props} width={width} height={height} Layout={EmtDashboard} />}
              />
              <Route
                exact
                path='/geospatial-outbreak'
                render={props => (
                  <GeospatialOutbreak {...props} width={width} height={height} Layout={GeospatialOutbreak} />
                )}
              />

              <Route
                exact
                path='/online-hospital'
                render={props => (
                  <OnlineGatewayHosp {...props} width={width} height={height} Layout={OnlineGatewayHosp} />
                )}
              />

              <Route
                exact
                path='/billing-charge/to'
                render={props => <BillingsCharge {...props} width={width} height={height} Layout={BillingsCharge} />}
              />

              <Route
                exact
                path='/billing-charge/from'
                render={props => <BillingsCharge {...props} width={width} height={height} Layout={BillingsCharge} />}
              />

              <Route
                exact
                path='/billing-charge/to/:selectedHosp/:monthIndex'
                render={props => <ChargeSummary {...props} width={width} height={height} Layout={ChargeSummary} />}
              />

              <Route
                exact
                path='/billing-charge/from/:selectedHosp/:monthIndex'
                render={props => <ChargeSummary {...props} width={width} height={height} Layout={ChargeSummary} />}
              />

              <Route
                exact
                path='/billing-charge/to/:selectedHosp/:monthIndex/:category/patients'
                render={props => <ChargePatients {...props} width={width} height={height} Layout={ChargePatients} />}
              />

              <Route
                exact
                path='/billing-charge/from/:selectedHosp/:monthIndex/:category/patients'
                render={props => <ChargePatients {...props} width={width} height={height} Layout={ChargePatients} />}
              />

              <Route
                exact
                path='/billing-charge/to/:selectedHosp/:monthIndex/:category/patients/:cid/'
                render={props => <PatientBilling {...props} width={width} height={height} Layout={PatientBilling} />}
              />

              <Route
                exact
                path='/billing-charge/from/:selectedHosp/:monthIndex/:category/patients/:cid/'
                render={props => <PatientBilling {...props} width={width} height={height} Layout={PatientBilling} />}
              />

              <Route
                exact
                path='/settings'
                render={props => <Settings {...props} width={width} height={height} Layout={Settings} />}
              />

              <Route
                exact
                path='/settings/edit-category/'
                render={props => <EditCategory {...props} width={width} height={height} Layout={EditCategory} />}
              />

              <Route
                exact
                path='/settings/edit-category/:id'
                render={props => <EditCategory {...props} width={width} height={height} Layout={EditCategory} />}
              />

              {/* ANCHOR: Refer Form */}
              <Route
                exact
                path='/refer-form'
                render={props => <ReferForm {...props} width={width} height={height} Layout={ReferForm} />}
              />

              <Route
                exact
                path='/refer-in-form'
                render={props => <ReferInForm {...props} width={width} height={height} Layout={ReferInForm} />}
              />

              <Route
                exact
                path='/refer-out-form'
                render={props => <ReferOutForm {...props} width={width} height={height} Layout={ReferOutForm} />}
              />

              <Route
                exact
                path='/user-management/add-edit-user'
                render={props => <UserForm {...props} width={width} height={height} Layout={UserForm} />}
              />

              <Route
                exact
                path='/user-management/add-edit-user/:id'
                render={props => <UserForm {...props} width={width} height={height} Layout={UserForm} />}
              />

              <Route
                exact
                path='/user-management'
                render={props => <UserManagement {...props} width={width} height={height} Layout={UserManagement} />}
              />
            </Switch>

            {newReferral && renderAlert({ referral: newReferral, ownHospCode: appData?.loginData?.hospCode })}
          </>
        )}
      </div>
      <div>
        <Online>
          {appData.loginData && (
            <div
              style={{
                top: appData?.loginData?.hospName?.length <= 24 ? height - 25 + 'px' : height - 48 + 'px',
                left: width - 210 + 'px',
              }}
              className='user-online'
            >
              <div>{appData?.loginData?.hospName}</div>
            </div>
          )}
        </Online>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

export default withRouter(connect(mapStateToProps)(ContainerMain));
