import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../fonts/vfs_fonts';

import { appointmentDataUrl } from '../dataUrl';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
};

export const printAppointment = ({
  cid,
  hn,
  ptName,
  doctorName,
  appointmentDate,
  appointmentTime,
  clinicName,
  icd10,
  pttypeName,
  reason,
  userName,
}) => {
  var docDefinition = {
    content: [
      {
        columns: [
          {
            image: appointmentDataUrl(),
            width: 60,
            height: 50,
          },
          [
            {
              text: `\nคณะแพทยศาสตร์วชิรพยาบาล`,
              style: 'logoText',
            },
            {
              text: `มหาวิทยาลัยนวมินทราธิราช`,
              style: 'logoText',
            },
          ],
        ],
      },
      {
        text: `ใบนัดผู้ป่วย`,
        style: 'header',
      },
      {
        columns: [
          {
            text: [{ text: `\nCID `, style: 'bold' }, cid],
          },
          {
            text: [{ text: `\nชื่อ-สกุล `, style: 'bold' }, ptName],
          },
          {
            text: [{ text: `\nHN `, style: 'bold' }, hn],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: `วันที่นัด `, style: 'bold' },
              appointmentDate,
              { text: [{ text: ` เวลา `, style: 'logoText' }, appointmentTime, { text: ` น.`, style: 'bold' }],margin:[10,0,0,0] },
            ],
          },
          {
            text: [{ text: `นัดพบแพทย์ `, style: 'bold' }, doctorName],
          },
          {
            text: [{ text: `คลินิก `, style: 'bold' }, clinicName],
          },
        ],
      },
      {
        columns: [
          {
            text: [{ text: `สิทธิการรักษาล่าสุด `, style: 'bold' }, pttypeName],
          },
          {
            text: [{ text: `ICD10 `, style: 'bold' }, icd10],
          },
        ],
      },
      {
        columns: [
          {
            text: [{ text: `\nนัดมาเพื่อ `, style: 'bold' }, reason],
          },
          [
            {
              text: `\nขั้นตอนมารับบริการ/การเตรียมตัว`,
              style: 'bold',
            },
            {
              text: `1. วัดความดัน ช่างน้ำหนัก ตามจุดบริการ`,
            },
            {
              text: `2. พบพยาบาลเพื่อประเมิน คัดกรอง ตามคิว และช่วงเวลานัด`,
            },
            {
              text: `(หากมีกรณีฉุกเฉิน โปรดแจ้งพยาบาลทันที)`,
            },
          ],
        ],
      },
      {
        text: `\nหมายเหตุ:`,
        style: 'bold',
      },
      {
        text: `1. กรณีที่แพทย์ให้เจาะเลือด ควรมาเจาะเลือดล่วงหน้าภายใน 7 วัน ก่อนวันนัดพบแพทย์`,
      },
      {
        text: `2. กรณีต้องการเลื่อนนัด กรุณาโทรแจ้งก่อนวันนัดอย่างน้อย 1 วันทำการ ระหว่างเวลา 14:00-16:00 น.`,
      },
      {
        
        columns: [
          // [
          //   {
          //     text: '\nเบอร์ติดต่อ',
          //   },
          //   {
          //     text: '02-xxx-xxxx',
          //   },
          // ],
          [
            {
              text: `\nลงชื่อ ................................... ผู้นัด`,
              alignment:'right',
              margin:[0,0,50,0]
            },
            {
              text: `( ${userName || '.................................................'} )`,
              alignment:'right',
              // margin:[0,0,0,0]
            },
          ],
        ],
      },
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: 13,
    },
    styles: {
      logoText: { bold: true, fontSize: 12, margin: [10, 0, 0, 0] },
      header: { bold: true, alignment: 'center', fontSize: 13 },
      bold: { bold: true },
      center: { alignment: 'center' },
    },
    pageSize: 'A5',
    pageOrientation: 'landscape',
  };
  pdfMake.createPdf(docDefinition).open();
};
