import axios from 'axios';
import config from 'react-global-configuration';
import UploadFile from './UploadFile';

export default async ({ idToken, body, attachments, letterAttachments }) => {
  let dispatch = {};
  let tmpAttachment = [];
  let tmpLetterAttachments = [];
  const url = `${config.get('ROOT_API')}/v1/referInForm/submit`;

  if (attachments.length) tmpAttachment = await UploadFile({ idToken, file: attachments });

  if (letterAttachments.length)
    tmpLetterAttachments = await UploadFile({
      idToken,
      file: letterAttachments,
    });

  const tmpBody = {
    ...body,
    data: {
      ...body.data,
      attachments: tmpAttachment,
      letterAttachments: tmpLetterAttachments,
    },
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .post(url, tmpBody, {
          headers: { authorization: `Bearer ${idToken}` },
        })
        .then(res => {
          dispatch = {
            FetchingStatus: false,
            alert: {
              show: true,
              msg: 'ส่งต่อผู้ป่วยสำเร็จ',
              type: 'success',
              page: 'referInForm',
            },
            referInForm: null,
            visitData: null,
          };
          return resolve(dispatch);
        })
        .catch(err => {
          console.log('axios error');
          dispatch = {
            FetchingStatus: false,
            alert: {
              show: true,
              msg: 'ส่งต่อผู้ป่วยไม่สำเร็จ กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
              type: 'error',
              page: 'referInForm',
            },
          };
          return resolve(dispatch);
        });
    }, 200);
  });
};
