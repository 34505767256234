import axios from 'axios';
import config from 'react-global-configuration';

export default ({ hn, cid, vstdate, idToken }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/referForm/searchVisit?hn=${hn}&cid=${cid}&vstdate=${vstdate}`;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .get(url, {
          headers: { authorization: `Bearer ${idToken}` },
        })
        .then(res => {
          dispatch = {
            referForm: res.data,
            FetchingStatus: false,
          };

          return resolve(dispatch);
        })
        .catch(err => {
          console.log('axios error');
          dispatch = {
            FetchingStatus: false,
            alert: {
              show: true,
              msg: 'ไม่สามารถค้นหา visit ได้ในขณะนี้ กรุณาติดต่อผู้ที่เกี่ยวข้อง',
              type: 'error',
              page: 'referForm',
            },
          };
          return resolve(dispatch);
        });
    }, 200);
  });
};
