import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../fonts/vfs_fonts';
import bahttext from './BAHTTEXT.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
};
function thaiNumber(num) {
  var array = {
    1: '๑',
    2: '๒',
    3: '๓',
    4: '๔',
    5: '๕',
    6: '๖',
    7: '๗',
    8: '๘',
    9: '๙',
    0: '๐',
  };
  var str = num.toString();
  for (var val in array) {
    str = str.split(val).join(array[val]);
  }
  return str;
}

function printAliens(data, hosname, datepdf) {
  var thismonth = thaiNumber(new Date().toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var billmonthandyear = thaiNumber(datepdf.toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var totalpatient = thaiNumber(data.totalPatients);
  var finalprice = thaiNumber(
    Number(data.totalFinalPrice)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
  var bahttexteiei = bahttext(data.totalFinalPrice);

  var docDefinition = {
    pageSize: 'A4',
    content: [
      {
        stack: [
          '\n\n\nที่  สป ๐๐๓๒.๒/…………..                                                                              โรงพยาบาลสมุทรปราการ',
          { text: '๗๑ ถนนจักกะพาก อำเภอเมืองฯ', style: 'subheader1' },
          { text: 'จังหวัดสมุทรปราการ ๑๐๒๗๐', style: 'subheader2' },
        ],
        style: 'header',
      },
      {
        text: thismonth,
        style: 'date',
      },
      {
        stack: [
          'เรื่อง ขอรับเงินชดเชยค่ารักษาพยาบาลบัตรประกันสุขภาพแรงงานต่างด้าว',
          { text: 'เรียน ผู้อำนวยการ' + hosname + '', style: 'to' },
          {
            text: 'ด้วยโรงพยาบาลสมุทรปราการ ได้ให้บริการผู้ป่วยแรงงานต่างด้าว ตามโครงการบัตรประกันสุขภาพ',
            style: 'tab',
          },
          {
            text:
              'แรงงานต่างด้าว ที่มีเกณฑ์การเบิกเงินชดเชยค่าบริการทางการแพทย์ ประจำเดือน ' +
              billmonthandyear +
              ' จำนวน ' +
              totalpatient +
              ' ราย รวมเป็นจำนวนเงิน ' +
              finalprice +
              ' บาท (' +
              bahttexteiei +
              ')',
            style: 'main',
          },
          {
            text: 'โปรดสั่งจ่ายเงินค่ารักษาพยาบาลผ่านธนาคารกรุงไทย สาขาปากน้ำบัญชีประกันสุขภาพแรงงานต่างด้าว',
            style: 'tab',
          },
          {
            text: 'หลบหนีเข้าเมือง เลขที่บัญชี  ๒๑๙-๑-๖๐๕๗๓-๗ ',
            style: 'main2',
          },
        ],
        style: 'main',
      },
      {
        text: 'จึงเรียนมาเพื่อโปรดทราบและพิจารณาดำเนินการต่อไป',
        style: 'tab',
      },
      { text: '\n\nขอแสดงความนับถือ', style: 'main4' },
      { text: '\n\n(นายปฏิวัติ วงศ์งาม)', style: 'main5' },
      { text: 'รองผู้อำนวยการฝ่ายการแพทย์คนที่ ๒', style: 'main6' },
      {
        text: 'ปฏิบัติราชการแทนผู้อำนวยการโรงพยาบาลสมุทรปราการ',
        style: 'main7',
      },

      {
        stack: [
          '\n\n\n\n\n\nศูนย์ประกันสุขภาพ',
          {
            text:
              'โทร. ๐-๒๑๗๓-๘๓๖๑-๕  ต่อ ๓๒๑๕                                                                              ...................../  ร่าง',
          },
          {
            text:
              'โทรสาร. ๐-๒๑๗๓-๘๓๖๗                                                                                           ...................../ พิมพ์',
          },
          {
            text:
              'สำเนาส่ง ฝ่ายบัญชี                                                                                                    ...................../ ตรวจ',
          },
        ],
        style: 'footer',
      },
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: 16,
    },
    styles: {
      header: { alignment: 'left' },
      subheader1: { alignment: 'right' },
      subheader2: { alignment: 'right', margin: [0, 0, 11, 0] },
      date: { alignment: 'right', margin: [0, 0, 122, 0] },
      tab: { margin: [60, 0, 0, 0] },
      main4: { alignment: 'center' },
      main5: { alignment: 'center' },
      main6: { alignment: 'center' },
      main7: { alignment: 'center' },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}

export default printAliens;
