import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//assets
import printUC from '../../../assets/pdf/genUC';
import printAliens from '../../../assets/pdf/genAliens';
import printSS from '../../../assets/pdf/genSS';

//functions
import { ThaiDate } from '../../../functions/FuncDateTimes';
import moment from 'moment';
import { shortHospName, getBillingStatusConfig, formatNum, sameStatus } from '../../../functions/FuncPerjer';

//actions
import { openChargeModal } from '../../../actions/BillingsCharge';
import { fetchChargeSummary } from '../../../actions/BillingSummary';

//styles
import { Container, TableRow, TableCell, Status } from '../styles';

//components
import ChargeModal from '../ChargeModal/ChargeModal';

//mui
import PrintIcon from '@material-ui/icons/Print';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

const ChargeSummary = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appData } = useSelector(state => state);
  const { idToken, hospCode, billingSummary, FetchingStatus, selectedHospmain, chargeModal } = appData;
  const { monthIndex, selectedHosp } = params;
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        fetchChargeSummary({
          idToken,
          hospCode,
          selectedHospmain: selectedHosp,
          month: Number(monthIndex) - 1,
          asProvider,
        })
      );
    };
    fetchData();
  }, [dispatch, idToken, hospCode, monthIndex, selectedHosp, chargeModal, asProvider]);

  const getPatients = row => {
    history.push({
      pathname: `${monthIndex}/${row.categoryCode}/patients`,
      search: `?categoryName=${row.categoryName}`,
    });
  };

  const checkCategory = data => {
    const datepdf = new Date(new Date().getFullYear(), monthIndex, 1);
    const hosname = selectedHospmain.hospName;
    switch (data.categoryCode) {
      case 'UC':
        return printUC(data, hosname, datepdf);
      case 'AL':
        return printAliens(data, hosname, datepdf);
      case 'SS':
        return printSS(data, hosname, datepdf);
      default:
        console.log(`data.category of "${data.category}" does not matched`);
    }
  };

  const buildButton = row => {
    const isAccepted = row.status === 'ACCEPTED' ? true : false;
    const isRejected = row.status === 'REJECTED' ? true : false;
    const isComplete = row.status === 'COMPLETE' ? true : false;
    const isEdited = row.status === 'EDITED' ? true : false;
    const isProcessing = row.status === 'PROCESSING' || row.status === 'PROCESSING_EDITED' ? true : false;

    if (asProvider) {
      if (isAccepted || isComplete || isEdited) {
        return '-';
      } else if (isRejected) {
        return (
          <Button
            onClick={() => dispatch(openChargeModal(row.categoryCode))}
            variant='outlined'
            size='small'
            disabled={!isRejected}
          >
            จบการเบิกจ่าย
          </Button>
        );
      } else {
        return (
          <Button
            onClick={() => dispatch(openChargeModal(row.categoryCode))}
            variant='outlined'
            size='small'
            disabled={!isProcessing}
          >
            ส่งการเบิกจ่ายเงิน
          </Button>
        );
      }
    } else {
      if (isAccepted || isComplete || isEdited || isProcessing) {
        return '-';
      } else {
        return (
          <Button
            onClick={() => dispatch(openChargeModal(row.categoryCode))}
            variant='outlined'
            size='small'
            disabled={isRejected}
          >
            ตอบรับการเบิกจ่าย
          </Button>
        );
      }
    }
  };

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5>
            {asProvider ? 'เรียกเก็บ' : 'ตามจ่าย'} - {ThaiDate(moment([moment().year(), monthIndex - 1])).substr(2, 10)}
          </h5>
        </div>
        {!FetchingStatus && selectedHospmain && (
          <div className='table-head'>
            <h4>
              <b>{shortHospName(selectedHospmain.hospName)}</b>
            </h4>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>สิทธิหลัก</TableCell>
              <TableCell align='right'>จำนวนผู้ป่วย</TableCell>
              <TableCell align='right'>จำนวน visit</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿)</TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus &&
              billingSummary &&
              billingSummary.categories.map(row => {
                const billingIsAfterProcessing = asProvider || (row.status && !sameStatus(row.status, 'PROCESSING'));

                if (billingIsAfterProcessing) {
                  return (
                    <TableRow key={row._id}>
                      <TableCell align='center' component='th' scope='row' onClick={() => getPatients(row)}>
                        {row.categoryName}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {row.totalPatients}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {row.totalVisits}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalCalcPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalFinalPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        <Status color={getBillingStatusConfig(row.status).color}>
                          {getBillingStatusConfig(row.status).text}
                        </Status>
                      </TableCell>
                      <TableCell align='center'>{buildButton(row)}</TableCell>
                      <TableCell align='center'>
                        {asProvider ? (
                          <IconButton onClick={() => checkCategory(row)}>
                            <PrintIcon />
                          </IconButton>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={row._id}>
                      <TableCell align='center' component='th' scope='row'>
                        {row.categoryName}
                      </TableCell>
                      <TableCell align='right'>{row.totalPatients}</TableCell>
                      <TableCell align='right'>{row.totalVisits}</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>
                        <Status color={getBillingStatusConfig(row.status).color}>
                          {getBillingStatusConfig(row.status).text}
                        </Status>
                      </TableCell>
                      <TableCell align='center'>-</TableCell>
                      <TableCell align='center'>-</TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </Container>
      {chargeModal && (
        <ChargeModal
          selectedHosp={selectedHosp}
          monthIndex={monthIndex}
          hospName={selectedHospmain && selectedHospmain.hospName}
          asProvider={asProvider}
        />
      )}
    </>
  );
};

export default ChargeSummary;
