import axios from 'axios';
import config from 'react-global-configuration';
import UploadFile from './UploadFile';

export default async ({ idToken, body, attachments, letterAttachments }) => {
  let dispatch = {};
  let tmpAttachment = [];
  let tmpLetterAttachments = [];
  const url = `${config.get('ROOT_API')}/v1/referForm/submit`;

  try {
    if (attachments.length) {
      for (let i = 0; i < attachments.length; i++)
        tmpAttachment.push(await UploadFile({ idToken, file: attachments[i] }));
    }

    if (letterAttachments.length)
      for (let i = 0; i < letterAttachments.length; i++)
        tmpLetterAttachments.push(
          await UploadFile({
            idToken,
            file: letterAttachments[i],
          })
        );

    const tmpBody = {
      ...body,
      data: {
        ...body.data,
        attachments: tmpAttachment,
        letterAttachments: tmpLetterAttachments,
      },
    };

    return axios
      .post(url, tmpBody, {
        headers: { authorization: `Bearer ${idToken}` },
      })
      .then(res => {
        dispatch = {
          FetchingStatus: false,
          alert: {
            show: true,
            msg: 'ส่งต่อผู้ป่วยสำเร็จ',
            type: 'success',
            page: 'referForm',
          },
          referForm: null,
          visitData: null,
        };
        return dispatch;
      })
      .catch(err => {
        console.log('submit axios error');
        dispatch = {
          FetchingStatus: false,
          alert: {
            show: true,
            msg: 'ส่งต่อผู้ป่วยไม่สำเร็จ กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
            type: 'error',
            page: 'referForm',
          },
        };
        return dispatch;
      });
  } catch (error) {
    dispatch = {
      FetchingStatus: false,
      alert: {
        show: true,
        msg: 'ส่งต่อผู้ป่วยไม่สำเร็จ กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
        type: 'error',
        page: 'referForm',
      },
    };
    return dispatch;
  }
};
