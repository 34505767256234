import axios from 'axios';
import config from 'react-global-configuration';

export default req => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const { idToken, hospCode } = req;
      axios
        .get(`${config.get('ROOT_API')}/v1/user/${hospCode}`, {
          headers: { authorization: `Bearer ${idToken}` },
        })
        .then(res => {
          let data = {
            users: res.data,
            FetchingStatus: false,
            alert: {
              show: false,
              msg: '',
              type: null,
            },
          };
          return resolve(data);
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          return reject(err.response);
        });
    }, 200);
  });
};
