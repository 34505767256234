import axios from 'axios';
import config from 'react-global-configuration';

export default ({ cid, idToken }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/referral/${cid}`;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return axios
        .get(url, {
          headers: { authorization: `Bearer ${idToken}` },
        })
        .then(res => {
          dispatch = {
            referrals: res.data.result,
            FetchingStatus: false,
          };

          return resolve(dispatch);
        })
        .catch(err => {
          console.log('get referral axios error');

          dispatch = {
            FetchingStatus: false,
          };

          return resolve(dispatch);
        });
    }, 200);
  });
};
