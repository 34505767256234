import {
  titleNameList,
  priorityList,
  reasonList,
  acuityLevelList,
  departmentList,
  hospitalTypeList,
} from '../constants/referForm';

export const titleNameAndSex = ({ titleNameValue }) => {
  if (!titleNameValue) return titleNameList.filter(item => item.value === 'นาย')[0];
  else {
    switch (titleNameValue?.toUpperCase()) {
      case 'MR.':
      case 'ส.ต.':
        return titleNameList.filter(item => item.value === 'นาย')[0];
      case 'MS.':
        return titleNameList.filter(item => item.value === 'นาง')[0];
      case 'MRS.':
        return titleNameList.filter(item => item.value === 'น.ส.')[0];

      default:
        return (
          titleNameList.filter(item => item.value === titleNameValue)[0] ||
          titleNameList.filter(item => item.value === 'นาย')[0]
        );
    }
  }
};

export const valueToTextPriority = value => {
  const filter = priorityList?.filter(item => item.value === value);

  if (filter.length > 0) return filter[0].text;
  else return '';
};

export const valueToTextSendReason = value => {
  const filter = reasonList?.filter(item => item.value === value);

  if (filter.length > 0) return filter[0].text;
  else return '';
};

export const valueToTextAcuityLevel = value => {
  const filter = acuityLevelList?.filter(item => item.value === value);

  if (filter.length > 0) return filter[0].text;
  else return '';
};

export const valueToTextDepartment = value => {
  const filter = departmentList?.filter(item => item.value === value);

  if (filter.length > 0) return filter[0].text;
  else return '';
};

export const valueToTextHospType = value => {
  const filter = hospitalTypeList?.filter(item => item.value === value);

  if (filter.length > 0) return filter[0].text;
  else return '';
};
