import { SUPERADMIN, ADMIN, USER, DOCTOR } from '../constants/Users/userRole';

export const getPermission = ({ role }) => {
  const permission = {
    viewHospitalReport: false,
    viewProvinceReport: false,
    viewRefer: false,
    actionRefer: false,
    userManagement: false,
    hospitalManagement: false,
    addUserMember: false,
    viewReferForm: false,
    viewTimeReport: false,
  };

  switch (role?.toUpperCase()) {
    case SUPERADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = true;
      permission.viewRefer = false;
      permission.actionRefer = false;
      permission.userManagement = true;
      permission.hospitalManagement = true;
      permission.addUserMember = true;
      permission.viewReferForm = false;
      permission.viewTimeReport = false;
      break;
    case ADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = true;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewReferForm = true;
      permission.viewTimeReport = true;
      break;
    case USER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewReferForm = true;
      permission.viewTimeReport = true;
      break;
    case DOCTOR:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = false;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewReferForm = false;
      permission.viewTimeReport = true;
      break;
    default:
      break;
  }

  return permission;
};
