import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ChipInput from 'material-ui-chip-input';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, Form } from './styles';
import AddCategories from '../../../api/Billings/AddCategories';
import EditCategories from '../../../api/Billings/EditCategories';
import BillingRules from '../../../api/Billings/BillingRules';
import { FormControlLabel } from '@material-ui/core';

const EditCategory = props => {
  const [pttype, setPttype] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cap, setCap] = useState(null);
  const history = useHistory();
  const { location } = props;
  const category = location?.state?.category;
  const { appData } = useSelector(state => state);
  const { idToken, loginData } = appData;
  const RULE = () => ({ id: uuid(), icode: '', price: '', capExclude: false });
  const { control, handleSubmit, setValue } = useForm();
  const [rules, setRules] = useState([RULE()]);
  const [billing, setBilling] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (category) {
        setLoading(true);
        const result = await BillingRules({
          idToken,
          category: category.code,
          hospCode: loginData.hospCode,
        });
        if (result.billingItemRules) {
          setRules(result.billingItemRules.map(rule => ({ ...rule, id: uuid() })));
        }
        if (result.billingRules && result.billingRules[0]) {
          setCap(result.billingRules[0]);
        }
        setBilling({
          category: category.code,
          billingRules: result.billingRules,
          billingItemRules: result.billingItemRules,
        });
        setLoading(false);
      }
    };
    if (category) {
      setPttype(category.members);
    }
    fetchData();
  }, [category, loginData.hospCode, idToken]);

  const onSubmit = async data => {
    setLoading(true);
    if (category) {
      const submissionBody = {
        code: data.code,
        name: data.name,
        members: pttype,
      };
      const rulesSubmissionBody = {
        category: data.code,
        billingItemRules: data.rule?.map(item => ({
          ...item,
          capExclude: item.capExclude ? true : false,
          category: data.code,
        })),
        billingRules: [{ category: data.code, cap: data.cap }],
      };
      const body = {
        idToken,
        hospCode: loginData.hospCode,
        submissionBody,
        rulesSubmissionBody,
        category: data.code,
      };

      //update new billing rules if can't update then rollback
      try {
        await EditCategories(body);
      } catch (error) {
        await EditCategories({ ...body, rulesSubmissionBody: billing });
      }
    } else {
      const submissionBody = {
        code: data.code,
        name: data.name,
        members: pttype,
      };
      const rulesSubmissionBody = {
        category: data.code,
        billingItemRules: data.rule.map(item => ({
          ...item,
          capExclude: item.capExclude ? true : false,
          category: data.code,
        })),
        billingRules: [{ category: data.code, cap: data.cap }],
      };
      const body = {
        idToken,
        hospCode: loginData.hospCode,
        submissionBody,
        rulesSubmissionBody,
      };
      await AddCategories(body);
    }
    setLoading(false);
    setRules([]);
    setPttype([]);
    history.push('/settings');
  };

  return (
    <Container>
      <Form elevation={2}>
        <h4 className='title'>{category ? 'แก้ไขกลุ่มสิทธิ์' : 'กลุ่มสิทธิ์ใหม่'}</h4>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            required
            as={TextField}
            control={control}
            name='code'
            rules={{ required: true }}
            className='input'
            label='รหัส'
            variant='outlined'
            hidden='true'
            disabled={loading || category}
            defaultValue={category ? category.code : uuid()}
          />
          <Controller
            required
            as={TextField}
            control={control}
            name='name'
            rules={{ required: true }}
            className='input'
            label='ชื่อสิทธิ์หลัก'
            variant='outlined'
            disabled={loading}
            defaultValue={category?.name}
          />
          <ChipInput
            name='pttype'
            className='input'
            label='กรอกรหัสสิทธิ์ pttype และกด enter'
            variant='outlined'
            value={pttype}
            onAdd={chip => setPttype(olds => [...olds, chip])}
            onDelete={(chip, index) => setPttype(olds => olds.filter(old => old !== chip))}
            disabled={loading}
          />
          {!loading && (
            <Controller
              as={TextField}
              control={control}
              name='cap'
              className='input'
              label='ยอดไม่เกิน'
              variant='outlined'
              defaultValue={cap?.cap}
              disabled={loading}
              type='number'
            />
          )}
          <Divider className='divider' />
          <h5>รายการยาและบริการ</h5>
          <div className='special'>เก็บเกินได้</div>
          {rules.map((rule, index) => (
            <div key={rule.id} className='billing' id={rule.id}>
              <IconButton>
                <DeleteIcon onClick={() => setRules(oldRules => oldRules.filter(oldRule => oldRule.id !== rule.id))} />
              </IconButton>
              <div className='billing-item'>
                <Controller
                  required
                  as={TextField}
                  control={control}
                  name={`rule[${index}].icode`}
                  rules={{ required: true }}
                  className='input'
                  label='รหัส icode'
                  variant='outlined'
                  size='small'
                  defaultValue={rule?.icode}
                />
              </div>
              <div className='billing-item'>
                <Controller
                  as={TextField}
                  control={control}
                  name={`rule[${index}].price`}
                  type='number'
                  className='input'
                  label='ราคา'
                  variant='outlined'
                  size='small'
                  defaultValue={rule?.price}
                />
              </div>
              <FormControlLabel
                control={
                  <Controller
                    name={`rule[${index}].capExclude`}
                    render={() => {
                      return (
                        <Checkbox
                          defaultChecked={rule ? rule.capExclude : false}
                          onChange={e => {
                            setValue(`rule[${index}].capExclude`, e.target.checked);
                          }}
                          color='primary'
                        />
                      );
                    }}
                    defaultValue={rule ? rule.capExclude : false}
                    control={control}
                  />
                }
                className='checkbox'
                key={`rule[${index}].capExclude`}
              />
            </div>
          ))}
          <Button onClick={() => setRules(oldRule => [...oldRule, RULE()])} className='add-rule'>
            + เพิ่มรายการยาและบริการ
          </Button>
          <div className='action'>
            <Button disabled={loading} className='cancel' variant='contained' onClick={() => history.push('/settings')}>
              ยกเลิก
            </Button>
            <Button disabled={loading} type='submit' color='primary' variant='contained'>
              ยืนยัน
            </Button>
          </div>
        </form>
        {loading && <LinearProgress />}
      </Form>
    </Container>
  );
};

export default EditCategory;
