import React, { useEffect, useState } from 'react';
import orm from '../../models/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const session = orm.session();

function PtAppointment({ bid, dataType, an, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        // .filter((Visits) => Visits.class === 'AMB')
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        // .filter((Visits) => Visits.class === 'IMP')
        .toRefArray();
    }

    setvisitData({ visitsData });
  };

  return (
    <div>
      {visitData?.visitsData?.map((items, index) => (
        <div key={index}>
          {/* {items?.dentals && ( */}
          <Card variant='outlined' className='visit-detail-card'>
            <ExpansionPanel style={{ width: '100%' }} className='MuiAppBar-color-custom '>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className='app-text-color' />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography className='font-size-20px'>Appointment (นัดหมาย)</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: 'block' }}>
                {items?.appointments?.map((itemsAppoin, indexAppoin) => (
                  <div key={indexAppoin}>
                    <Alert variant='primary' className='font-size-14px'>
                      <Row>
                        <Col lg={6}>
                          <h6>วันที่พบแพทย์ : {ThaiDateTime(itemsAppoin?.appointmentDateTime)}</h6>
                        </Col>
                        <Col lg={6}>
                          <h6>คลินิก : {itemsAppoin?.clinicName}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div>พบแพทย์ : {itemsAppoin?.doctorName}</div>
                        </Col>
                        <Col lg={6}>
                          <div>ผู้ทำนัด : {itemsAppoin?.appUser} </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>{itemsAppoin?.note && <div>Note : {itemsAppoin?.note}</div>}</Col>
                      </Row>
                    </Alert>
                  </div>
                ))}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/* <Row>
                  <Col lg={12}>
                    <h5>Appointment (นัดหมาย)</h5>
                  </Col>
                </Row>
                {items?.appointments.map((itemsAppoin, indexAppoin) => (
                  <Alert
                    variant='primary'
                    key={indexAppoin}
                    className='font-size-14px'
                  >
                    <Row>
                      <Col lg={6}>
                        <h6>
                          วันที่พบแพทย์ :{' '}
                          {ThaiDateTime(itemsAppoin?.appointmentDateTime)}
                        </h6>
                      </Col>
                      <Col lg={6}>
                        <h6>คลินิก : {itemsAppoin?.clinicName}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div>พบแพทย์ : {itemsAppoin?.doctorName}</div>
                      </Col>
                      <Col lg={6}>
                        <div>ผู้ทำนัด : {itemsAppoin?.appUser} </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        {itemsAppoin?.note && (
                          <div>Note : {itemsAppoin?.note}</div>
                        )}
                      </Col>
                    </Row>
                  </Alert>
                ))} */}
          </Card>
          {/* )} */}
        </div>
      ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtAppointment));
