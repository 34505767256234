import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';

// mui
import Card from '@material-ui/core/Card';

const session = orm.session();

function VisitsDetail({ bid, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    setvisitData({ visitsData });
  };

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <Card variant='outlined' className='visit-detail-card' key={index}>
              <Row>
                <Col lg={12}>
                  <h5>วันที่ตรวจ {ThaiDateTime(items.visitDateTime)}</h5>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>{shortHospName(items.hospName)}</Col>
              </Row>
              <Row>
                <Col lg={12}>ห้องตรวจ : {items.departmentName}</Col>
              </Row>
              <Row>
                <Col lg={12}>VN : {items.vn}</Col>
              </Row>
              <hr />
              <Row>
                <Col lg={12} className='font-size-12px'>
                  <div>สิทธิการรักษาในวันนี้ : {(items.pttype || '-') + ' ' + items.pttypeName}</div>
                  <div>
                    สถานพยาบาลหลัก :{' '}
                    {items.hospmain && items.hospmainName
                      ? items.hospmain + ' ' + shortHospName(items.hospmainName)
                      : ''}
                  </div>
                  <div>
                    สถานพยาบาลรอง :{' '}
                    {items.hospsub && items.hospsubName ? items.hospsub + ' ' + shortHospName(items.hospsubName) : ''}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={12} className='font-size-14px'>
                  <div>
                    แพทย์ผู้ทำการรักษา :{' '}
                    <Badge pill variant='primary'>
                      {items.doctorName}
                    </Badge>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsDetail));
