import axios from 'axios';
import config from 'react-global-configuration';

const DeleteUser = async ({ idToken, username }) => {
  try {
    const result = await axios.post(
      `${config.get('ROOT_API')}/v1/user/delete/${username}`,
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
};

export default DeleteUser;
