import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      const {
        bid,
        reason,
        appointmentDateTimeStart,
        appointmentDateTimeEnd,
        appointmentClinicName,
        appointmentDoctorName,
      } = dataRequest;

      let bodyData = {
        bid,
        reason,
        appointmentDateTimeStart,
        appointmentDateTimeEnd,
        appointmentClinicName,
        appointmentDoctorName,
      };

      axios
        .post(`${config.get('ROOT_API')}/v1/referral/accept/${bid}`, bodyData, {
          headers: { Authorization: AuthStr },
        })
        .then(res => {
          if (res.status === 200) {
            let data = {
              referAccept: {
                referAcceptData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              referAccept: {
                referAcceptData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
