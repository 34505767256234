import React, { Component } from 'react';
import { connect } from 'react-redux';
import vajiraLogo from '../../images/vajira-logo.png';
import { version } from '../../../package.json';

// components
import UserOnlineTime from './UserOnlineTime';
import ReferNotifications from '../notifications/ReferNotifications';

// actions
import { fetchLogin } from '../../actions/Login';
import { fetchAccountInfo } from '../../actions/AccountInfo';

// functions
import { DateTimeToStrDatetime } from '../../functions/FuncDateTimes';
import { StrEncrypt, StrDecrypt } from '../../functions/FuncPerjer';

// mui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { Language } from '@material-ui/icons';

class UserLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      idToken: '',
      open: false,
      checkToken: false,
      loginErrorShow: false,
      loginErrorMsg: '',
    };
    this.LoginSetState = this.LoginSetState.bind(this);
    this.LoginAction = this.LoginAction.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.AccountInfoAction = this.AccountInfoAction.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  LoginSetState(e) {
    e.preventDefault();
    if (e.target.name === 'username') {
      this.setState({ username: e.target.value });
    }
    if (e.target.name === 'password') {
      this.setState({ password: e.target.value });
    }
  }

  LoginAction(e) {
    e.preventDefault();
    let { username, password } = this.state;
    let res = this.props.fetchLogin(username, password);
    let obj = this;

    res.then(
      function (v) {
        // not called
        obj.setState({ loginErrorShow: false });
        obj.setState({ loginErrorMsg: '' });
        setTimeout(() => {
          obj.setState({ checkToken: true });
          obj.AccountInfoAction();
        }, 100);
      },
      function (e) {
        // TypeError: Throwing

        if (
          e &&
          ((e.status === 400 &&
            e.data.errors &&
            (e.data.errors.msg === 'Password is required' || e.data.errors.msg === 'Password is required')) ||
            (e.status === 400 && e.data.message && e.data.message[0].msg === 'Password incorrect') ||
            (e.status === 400 && e.data.errors && e.data.errors[0].msg === 'Password is required') ||
            (e.status === 404 && e.data.message && e.data.message[0].msg === 'User not found'))
        ) {
          obj.setState({ loginErrorShow: true });
          obj.setState({
            loginErrorMsg: '1',
          });
          // alert('Username หรือ Password ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง !')
        } else {
          if (e && e.status === 404 && e.status === 400) {
            // alert('การ Login เข้าใช้งานมีปัญหา กรุณาลองใหม่อีกครั้ง !')
            obj.setState({ loginErrorShow: true });
            obj.setState({
              loginErrorMsg: '2',
            });
          } else {
            // alert('การ Login เข้าใช้งานมีปัญหา กรุณาลองใหม่อีกครั้ง !')
            obj.setState({ loginErrorShow: true });
            obj.setState({
              loginErrorMsg: '2',
            });
          }
        }
      }
    );
  }

  AccountInfoAction() {
    let res = this.props.fetchAccountInfo(StrDecrypt(localStorage.getItem('Token'), 'CrIRWrUNC'));

    res.then(
      function (v) {
        // not called
      },
      function (e) {
        // TypeError: Throwing

        if (
          e &&
          e.status === 401 &&
          (e.data.msg === 'No token, authorization denied' || e.data.msg === 'Token is not valid')
        ) {
          sessionStorage.setItem('Token', '');
          localStorage.setItem('Token', '');
          localStorage.setItem('LoginTime', '');
        } else {
          if (e && e.status === 401) {
            sessionStorage.setItem('Token', '');
            localStorage.setItem('Token', '');
            localStorage.setItem('LoginTime', '');
          } else {
            sessionStorage.setItem('Token', '');
            localStorage.setItem('Token', '');
            localStorage.setItem('LoginTime', '');
          }
        }
      }
    );
  }

  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  render() {
    let { username, password } = this.state;
    let { nameLogin } = this.props;

    if (this.props.appData.idToken && this.props.appData.loginStatus) {
      if (this.state.open === true) {
        setTimeout(() => {
          this.handleClose();
          sessionStorage.setItem('Token', StrEncrypt(this.props.appData.idToken, 'WfAdmzohC'));
          localStorage.setItem('Token', StrEncrypt(this.props.appData.idToken, 'CrIRWrUNC'));
          localStorage.setItem('LoginTime', StrEncrypt(DateTimeToStrDatetime(new Date()), 'TpkoHksVbd'));
          this.setState({ username: '' });
          this.setState({ password: '' });
        }, 100);
      }
    } else {
      if (this.state.open !== true) {
        setTimeout(() => {
          if (!this.props.appData.loginData && !this.props.appData.loginStatus) {
            this.handleClickOpen();
          }
        }, 1000);
      }

      if (
        localStorage.getItem('Token') !== '' &&
        localStorage.getItem('Token') !== 'undefined' &&
        localStorage.getItem('Token') !== null &&
        localStorage.getItem('Token') !== 'null' &&
        !this.props.appData.loginData
      ) {
        if (!this.state.checkToken) {
          setTimeout(() => {
            this.setState({ checkToken: true });
            this.AccountInfoAction();
          }, 100);
        }
      }
    }

    return (
      <div>
        <header>
          <UserOnlineTime />
          {this.props.appData.loginData && <ReferNotifications />}
          <Dialog open={this.state.open} aria-labelledby='form-dialog-title' fullWidth>
            <Grid container>
              <Grid
                xs={6}
                style={{
                  background: 'linear-gradient(153.84deg, #A5D0FF -8.46%, #C9E3FF 6.91%, #F7FBFF 109.58%)',
                  height: '420px',
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(/townImage.svg)`,
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '14px',
                  }}
                >
                  <img src={vajiraLogo} alt='logo' width='64px' height='54px' />
                  <div>
                    Powered by
                    <img
                      src='/ever_logo.png'
                      alt='ever_logo'
                      width='56px'
                      height='18px'
                      style={{ marginLeft: '4px' }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid xs={6}>
                <form style={{ width: '100%' }} onSubmit={this.LoginAction}>
                  <DialogContent className='Login-dialog-custom'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Language
                        style={{ backgroundColor: '#3F98D2', color: 'white', borderRadius: '6px', margin: '4px' }}
                      />
                    </div>
                    {this.state.loginErrorShow && (
                      <div>
                        {this.state.loginErrorMsg === '1' && (
                          <Alert variant='filled' severity='warning' className='margin-bottom-2px'>
                            <div>Username หรือ Password ไม่ถูกต้อง</div>
                            <div>กรุณาลองใหม่อีกครั้ง !</div>
                          </Alert>
                        )}
                        {this.state.loginErrorMsg === '2' && (
                          <Alert variant='filled' severity='error' className='margin-bottom-2px'>
                            <div>การ Login เข้าใช้งานมีปัญหา</div>
                            <div>กรุณาลองใหม่อีกครั้ง !</div>
                          </Alert>
                        )}
                      </div>
                    )}

                    <h4 style={{ color: '#3F98D2' }}>Login</h4>
                    <TextField
                      autoFocus
                      margin='dense'
                      id={nameLogin + 'username'}
                      name='username'
                      label='Username :'
                      type='text'
                      value={username}
                      onChange={this.LoginSetState}
                      fullWidth
                      variant='outlined'
                    />
                    <TextField
                      margin='dense'
                      id={nameLogin + 'password'}
                      name='password'
                      label='Password :'
                      type='password'
                      value={password}
                      onChange={this.LoginSetState}
                      fullWidth
                      variant='outlined'
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      style={{ backgroundColor: '#9FCCE8', color: 'white', margin: '5px 14px' }}
                    >
                      Login
                    </Button>
                  </DialogActions>
                </form>
                <div className='version-text'>v{version}</div>
              </Grid>
            </Grid>
          </Dialog>
        </header>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLogin: (username, password) => dispatch(fetchLogin(username, password)),
    fetchAccountInfo: idToken => dispatch(fetchAccountInfo(idToken)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
