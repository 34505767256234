import axios from 'axios';
import config from 'react-global-configuration';

export default ({ idToken, hn, cid }) => {
  const url = `${config.get('ROOT_API')}/v1/patients/retrieve`;

  const body = {
    hn,
    cid,
  };

  return axios
    .post(url, body, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
      timeout: 1000 * 60 * 15,
    })
    .then(res => {
      console.log(res.data);
      //   return res.data;
    })
    .catch(err => {
      console.log('axios error');
      //   return [];
    });
};
