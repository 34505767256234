import axios from 'axios';
import config from 'react-global-configuration';

export default ({ hospCode, idToken }) => {
  let dispatch = {};
  const url = `${config.get('ROOT_API')}/v1/lists/pttype/${hospCode}`;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .get(url, {
          headers: { authorization: `Bearer ${idToken}` },
        })
        .then(res => {
          dispatch = {
            referOutForm: null,
            pttypeList: res.data,
            FetchingStatus: false,
          };

          return resolve(dispatch);
        })
        .catch(err => {
          console.log('axios error');

          dispatch = {
            FetchingStatus: false,
          };

          return resolve(dispatch);
        });
    }, 200);
  });
};
