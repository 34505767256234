import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      let startDate = dataRequest.startDate;
      let endDate = dataRequest.endDate;
      let fromHospCode = dataRequest.fromHospCode;
      let toHospCode = dataRequest.toHospCode;
      let limit = dataRequest.limit;
      let cid = dataRequest.cid;
      let startDateSet = startDate + ' 00:00:00';
      let endDateSet = endDate + ' 23:59:59';

      let cid_parm = '';
      if (cid !== '' && cid !== undefined && cid) {
        cid_parm = '&cid=' + cid;
      } else {
        cid_parm = '';
      }

      axios
        .get(
          `${config.get(
            'ROOT_API'
          )}/v1/referral?startDate=${startDateSet}&endDate=${endDateSet}&fromHospCode=${fromHospCode}&toHospCode=${toHospCode}&limit=${limit}${cid_parm}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then(res => {
          let data = {
            emrData: {
              referDatas: res.data.result.referrals,
            },
            FetchingStatus: false,
            alert: {
              show: false,
              msg: '',
              type: null,
              page: null,
            },
          };

          return resolve(data);
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
