export const SUPERADMIN = 'SUPERADMIN';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const DOCTOR = 'DOCTOR';

export const roles = [
  { label: 'Provincial Health Office', value: SUPERADMIN },
  { label: 'Admin', value: ADMIN },
  { label: 'User', value: USER },
  { label: 'Doctor', value: DOCTOR },
];
