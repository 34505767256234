import axios from 'axios';
import config from 'react-global-configuration';

export default async req => {
  const { idToken, body } = req;
  let dispatch = {};

  await axios
    .post(`${config.get('ROOT_API')}/v1/user`, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: false,
          msg: '',
          type: 'success',
        },
      };
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: true,
          msg: err.response.data.msg,
          type: 'error',
        },
      };
    });

  return dispatch;
};
