import React, { Component } from 'react';
import vajiraLogo from '../../images/vajira-logo.png';
import everLogo from '../../images/ever_logo.8c71ea29.png';
import { NavLink } from 'react-router-dom';

// mui
import Tooltip from '@material-ui/core/Tooltip';
import { ListItem, Typography } from '@material-ui/core';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      theme: 'light',
      cards: [
        {
          pathImage: '/refer_in.png',
          link: '/refer-in',
          description: 'Refer in patients for further treatment',
          title: 'Refer In',
        },
        {
          pathImage: '/refer_out.png',
          link: '/refer-out',
          description: 'ส่งต่อผู้ป่วยเพื่อไปรับรักษา ต่อยังสถานพยาบาลอื่น',
          title: 'Refer Out',
        },
        {
          pathImage: '/report.png',
          link: '/hosp-refer-report',
          description: 'ภาพรวมแสดงการใช้งาน',
          title: 'Report Hospital',
        },
        {
          pathImage: '/user_management.png',
          link: '/user-management',
          description: 'จัดการผู้ใช้งาน',
          title: 'Manage User',
        },
      ],
    };
    this.changTheme = this.changTheme.bind(this);
  }

  changTheme(tt) {
    if (tt === 'light') {
      this.setState({ dark: false });
      this.setState({ theme: 'light' });
    } else {
      this.setState({ dark: true });
      this.setState({ theme: 'dark' });
    }
  }

  render() {
    if (this.state.theme !== localStorage.getItem('theme')) {
      setTimeout(() => {
        this.changTheme(localStorage.getItem('theme'));
      }, 100);
    }

    return (
      <div>
        <div>
          <div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: 'auto auto' }}>
            {this.state.cards.map(card => (
              <ListItem button component={NavLink} to={card.link} exact>
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    width: '180px',
                    height: '170px',
                    margin: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign:'center'
                  }}
                >
                  <img src={card.pathImage} alt='logo' style={{ width: '60px', height: '60px' }} />

                  <Typography variant='subtitle1'>{card.title}</Typography>
                  <Typography variant='caption'>{card.description}</Typography>
                </div>
              </ListItem>
            ))}
          </div>
        </div>

        <div className='powered-by'>
          <div>
            <img src={everLogo} className='powered-by-logo' alt='logo' /> Powered By Ever
          </div>
        </div>

        <Tooltip title='Powered By Ever'>
          <img src={everLogo} className='powered-by-logo-mobile' alt='logo' />
        </Tooltip>
      </div>
    );
  }
}

export default Home;
