import React from 'react';
import { connect } from 'react-redux';
import { appPropertySet } from '../../actions/AppProperty';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import Menu from '@material-ui/core/Menu';

import { fetchLogout } from '../../actions/logout';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  MuiButtonCustom: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
}));

function BarApp({ appProperty, appData, appPropertySet, fetchLogout, location }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [AppMenuAnchorEl, setAppMenuAnchorEl] = React.useState(null);

  const AppMenuHandleClick = event => {
    setAppMenuAnchorEl(event.currentTarget);
  };

  const AppMenuHandleClose = () => {
    setAppMenuAnchorEl(null);
  };

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const NavigationBarApp = (
    <div>
      <IconButton className='icon-button' onClick={AppMenuHandleClick}>
        <Tooltip title='App'>
          <AppsIcon color='action' />
        </Tooltip>
      </IconButton>
      <Menu
        id='app-menu'
        anchorEl={AppMenuAnchorEl}
        keepMounted
        open={Boolean(AppMenuAnchorEl)}
        onClose={AppMenuHandleClose}
        className='app-menu'
        TransitionComponent={Fade}
      >
        <div className='app-menu' style={{ padding: '8px' }}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Paper style={{ textAlign: 'center' }}>
                <ListItem
                  button
                  component={NavLink}
                  to='/patient-emr/'
                  exact
                  className={classes.MuiButtonCustom}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <IconButton className='icon-button avatar-button' onClick={AppMenuHandleClose} title='Patient (EMR)'>
                    <div style={{ display: 'block' }}>
                      <Avatar className='avatar-app-icon'>
                        <AssignmentIndIcon />
                      </Avatar>

                      <div
                        className='font-size-12px'
                        style={{
                          paddingTop: '4px',
                          whiteSpace: 'nowrap',
                          width: '50px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        EMR
                      </div>
                    </div>
                  </IconButton>
                </ListItem>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper style={{ textAlign: 'center' }}>
                <ListItem
                  button
                  component={NavLink}
                  to='/refer-in/'
                  exact
                  className={classes.MuiButtonCustom}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <IconButton className='icon-button avatar-button' onClick={AppMenuHandleClose} title='Refer In'>
                    <div style={{ display: 'block' }}>
                      <Avatar className='avatar-app-icon'>
                        <AssignmentReturnedIcon />
                      </Avatar>

                      <div
                        className='font-size-12px'
                        style={{
                          paddingTop: '4px',
                          whiteSpace: 'nowrap',
                          width: '50px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Refer In
                      </div>
                    </div>
                  </IconButton>
                </ListItem>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper style={{ textAlign: 'center' }}>
                <ListItem
                  button
                  component={NavLink}
                  to='/refer-out/'
                  exact
                  className={classes.MuiButtonCustom}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <IconButton className='icon-button avatar-button' onClick={AppMenuHandleClose} title='Refer Out'>
                    <div style={{ display: 'block' }}>
                      <Avatar className='avatar-app-icon'>
                        <AssignmentReturnIcon />
                      </Avatar>

                      <div
                        className='font-size-12px'
                        style={{
                          paddingTop: '4px',
                          whiteSpace: 'nowrap',
                          width: '50px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Refer Out
                      </div>
                    </div>
                  </IconButton>
                </ListItem>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={1} />
          <Grid container spacing={1} />
        </div>
      </Menu>
    </div>
  );

  return <div>{NavigationBarApp}</div>;
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchLogout: appData => dispatch(fetchLogout(appData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarApp));
