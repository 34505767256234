import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(dataRequest.idToken);
      let hn = dataRequest.hn;
      let hospCode = dataRequest.hospCode;
      let cid = dataRequest.cid;
      let limit = dataRequest.limit;

      if (hn !== '') {
      }

      axios
        .get(
          `${config.get(
            'ROOT_API'
          )}/v1/patients/visits/details?cid=${cid}&limit=${limit}&hospCode=${hospCode}&hn=${hn}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then(res => {
          if (res.status === 200) {
            let data = {
              emrData: {
                patients: res.data.result.patient,
                visits: res.data.result.visits,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              emrData: {
                patients: res.data.result.patient,
                visits: res.data.result.visits,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
