export const titleNameList = [
  { label: 'นาย', value: 'นาย', sex: '1' },
  { label: 'นาง', value: 'นาง', sex: '2' },
  { label: 'น.ส.', value: 'น.ส.', sex: '2' },
  { label: 'ด.ช.', value: 'ด.ช.', sex: '1'},
  { label: 'ด.ญ.', value: 'ด.ญ.', sex: '2' },
];

export const priorityList = [
    { color: '#79d6f2', text: 'ระดับ 0 - Fast Track', value: '0' },
    { color: '#dd7878', text: 'ระดับ 1 - ผู้ป่วยฉุกเฉินวิกฤต', value: '1' },
    { color: '#d80a79', text: 'ระดับ 2 - ผู้ป่วยฉุกเฉินเร่งด่วน', value: '2' },
    { color: '#fbef70', text: 'ระดับ 3 - ผู้ป่วยฉุกเฉินปานกลาง', value: '3' },
    { color: '#66ae31', text: 'ระดับ 4 - ผู้ป่วยฉุกเฉินเล็กน้อย', value: '4' },
    { color: '#e4e4e4', text: 'ระดับ 5 - ผู้ป่วยฉุกเฉินทั่วไป', value: '5' },
    { color: '#f1f1f1', text: 'ระดับ N - ส่งต่อทั่วไป', value: '6' },
  ];
  
  export const reasonList = [
    { text: 'ตามสิทธิการรักษา', value: '01' },
    { text: 'เกินศักยภาพ', value: '02' },
    { text: 'ปัญหาค่าใช้จ่าย', value: '03' },
    { text: 'ญาติต้องการ', value: '04' },
    { text: 'ประเมินประจำปีคนไข้เบาหวาน', value: '05' },
    { text: 'ประเมินประจำปีเบาหวานพร้อม CXR, EKG', value: '06' },
    { text: 'อื่นๆ', value: '99' },
  ];
  
  export const referTypeList = [
    { text: 'IPD', value: 'IPD' },
    { text: 'OPD', value: 'OPD' },
  ];
  
  export const acuityLevelList = [
    { text: 'U: Unstable', value: 'U' },
    { text: 'H: Stable with High risk of deterioration', value: 'H' },
    { text: 'M: Stable with Medium risk of deterioration', value: 'M' },
    { text: 'L: Stable with Low risk of deterioration', value: 'L' },
    { text: 'N: Stable with No risk of deterioration', value: 'N' },
  ];
  
  export const departmentList = [
    { text: 'Internal medecine: อายุรกรรม', value: '419192003' },
    { text: 'Pediatrics: กุมารเวชกรรม', value: '394537008' },
    { text: 'Gynecology: นรีเวชกรรม', value: '394586005' },
    { text: 'Trauma and orthopedics: ศัลยกรรมกระดูก', value: '394801008' },
    { text: 'General surgery: ศัลยกรรมทั่วไป', value: '394609007' },
    { text: 'Pediatric surgery: ศัลยกรรมเด็ก', value: '394539006' },
    {
      text: 'Cardiothoracic surgery: ศัลยกรรมทรวงอกและหัวใจ',
      value: '394603008',
    },
    { text: 'Urology: ศัลยกรรมทางเดินปัสสาวะ', value: '394612005' },
    { text: 'Neurosurgery: ศัลยกรรมประสาท', value: '394610002' },
    { text: 'Burn unit: ศัลยกรรมไฟไหม้และน้ำร้อนลวก', value: '309906004' },
    {
      text: 'Colorectal surgery: ศัลยกรรมลำไส้ใหญ่และทวารหนัก',
      value: '408464004',
    },
    { text: 'Obstetrics: สูติกรรม', value: '408470005' },
    { text: 'Otolaryngology: โสต ศอ นาสิก', value: '418960008' },
    { text: 'Ophthalmology: จักษุวิทยา', value: '394594003' },
    { text: 'Other (adjective): อื่นๆ', value: '74964007' },
  ];
  
  export const hospitalTypeList = [
    { text: 'โรงเรียนแพทย์', value: '12a' },
    { text: 'โรงพยาบาลศูนย์', value: '05' },
    { text: 'โรงพยาบาลทั่วไป', value: '06' },
    { text: 'โรงพยาบาลชุมชน', value: '07' },
    { text: 'ศูนย์บริการสาธารณสุข', value: '08' },
    { text: 'โรงพยาบาลเอกชน', value: '15' },
  ];

export const icd10List = [
    {
      "name": "A000",
      "description": "Cholera due to Vibrio cholerae 01, biovar cholerae"
    },
    {
      "name": "A001",
      "description": "Cholera due to Vibrio cholerae 01, biovar eltor"
    },
    {
      "name": "A009",
      "description": "Cholera, unspecified"
    },
    {
      "name": "A010",
      "description": "Typhoid fever"
    },
    {
      "name": "A011",
      "description": "Paratyphoid fever A"
    },
    {
      "name": "A012",
      "description": "Paratyphoid fever B"
    },
    {
      "name": "A013",
      "description": "Paratyphoid fever C"
    },
    {
      "name": "A014",
      "description": "Paratyphoid fever, unspecified"
    },
    {
      "name": "A020",
      "description": "Salmonella enteritis"
    },
    {
      "name": "A021",
      "description": "Salmonella sepsis"
    },
    {
      "name": "A022",
      "description": "Localized salmonella infections"
    },
    {
      "name": "A028",
      "description": "Other specified salmonella infections"
    },
    {
      "name": "A029",
      "description": "Salmonella infection, unspecified"
    },
    {
      "name": "A030",
      "description": "Shigellosis due to Shigella dysenteriae"
    },
    {
      "name": "A031",
      "description": "Shigellosis due to Shigella flexneri"
    },
    {
      "name": "A032",
      "description": "Shigellosis due to Shigella boydii"
    },
    {
      "name": "A033",
      "description": "Shigellosis due to Shigella sonnei"
    },
    {
      "name": "A038",
      "description": "Other shigellosis"
    },
    {
      "name": "A039",
      "description": "Shigellosis, unspecified"
    },
    {
      "name": "A040",
      "description": "Enteropathogenic Escherichia coli infection"
    },
    {
      "name": "A041",
      "description": "Enterotoxigenic Escherichia coli infection"
    },
    {
      "name": "A042",
      "description": "Enteroinvasive Escherichia coli infection"
    },
    {
      "name": "A043",
      "description": "Enterohaemorrhagic Escherichia coli infection"
    },
    {
      "name": "A044",
      "description": "Other intestinal Escherichia coli infections"
    },
    {
      "name": "A045",
      "description": "Campylobacter enteritis"
    },
    {
      "name": "A046",
      "description": "Enteritis due to Yersinia enterocolitica"
    },
    {
      "name": "A047",
      "description": "Enterocolitis due to Clostridium difficile\n"
    },
    {
      "name": "A048",
      "description": "Other specified bacterial intestinal infections"
    },
    {
      "name": "A049",
      "description": "Bacterial intestinal infection, unspecified"
    },
    {
      "name": "A050",
      "description": "Foodborne staphylococcal intoxication"
    },
    {
      "name": "A051",
      "description": "Botulism"
    },
    {
      "name": "A052",
      "description": "Foodborne Clostridium perfringens [Clostridium welchii] intoxication"
    },
    {
      "name": "A053",
      "description": "Foodborne Vibrio parahaemolyticus intoxication"
    },
    {
      "name": "A054",
      "description": "Foodborne Bacillus cereus intoxication"
    },
    {
      "name": "A058",
      "description": "Other specified bacterial foodborne intoxications"
    },
    {
      "name": "A059",
      "description": "Bacterial foodborne intoxication, unspecified"
    },
    {
      "name": "A060",
      "description": "Acute amoebic dysentery"
    },
    {
      "name": "A061",
      "description": "Chronic intestinal amoebiasis"
    },
    {
      "name": "A062",
      "description": "Amoebic nondysenteric colitis"
    },
    {
      "name": "A063",
      "description": "Amoeboma of intestine"
    },
    {
      "name": "A064",
      "description": "Amoebic liver abscess (K77.0*)"
    },
    {
      "name": "A065",
      "description": "Amoebic lung abscess (J99.8*)"
    },
    {
      "name": "A066",
      "description": "Amoebic brain abscess (G07*)"
    },
    {
      "name": "A067",
      "description": "Cutaneous amoebiasis"
    },
    {
      "name": "A068",
      "description": "Amoebic infection of other sites"
    },
    {
      "name": "A069",
      "description": "Amoebiasis, unspecified"
    },
    {
      "name": "A070",
      "description": "Balantidiasis"
    },
    {
      "name": "A071",
      "description": "Giardiasis [lambliasis]"
    },
    {
      "name": "A072",
      "description": "Cryptosporidiosis"
    },
    {
      "name": "A073",
      "description": "Isosporiasis"
    },
    {
      "name": "A078",
      "description": "Other specified protozoal intestinal diseases"
    },
    {
      "name": "A079",
      "description": "Protozoal intestinal disease, unspecified"
    },
    {
      "name": "A080",
      "description": "Rotaviral enteritis"
    },
    {
      "name": "A081",
      "description": "Acute gastroenteropathy due to Norwalk agent"
    },
    {
      "name": "A082",
      "description": "Adenoviral enteritis"
    },
    {
      "name": "A083",
      "description": "Other viral enteritis"
    },
    {
      "name": "A084",
      "description": "Viral intestinal infection, unspecified"
    },
    {
      "name": "A085",
      "description": "Other specified intestinal infections"
    },
    {
      "name": "A090",
      "description": "Other and unspecified gastroenteritis and colitis of infectious origin "
    },
    {
      "name": "A099",
      "description": "Gastroenteritis and colitis of unspecified origin "
    },
    {
      "name": "A150",
      "description": "Tuberculosis of lung, confirmed by sputum microscopy or bacteriologically with or without \nculture"
    },
    {
      "name": "A151",
      "description": "Tuberculosis of lung, confirmed by culture only"
    },
    {
      "name": "A152",
      "description": "Tuberculosis of lung, confirmed histologically"
    },
    {
      "name": "A153",
      "description": "Tuberculosis of lung, confirmed by unspecified means"
    },
    {
      "name": "A154",
      "description": "Tuberculosis of intrathoracic lymph nodes, confirmed bacteriologically and histologically"
    },
    {
      "name": "A155",
      "description": "Tuberculosis of larynx, trachea and bronchus, confirmed bacteriologically and histologically"
    },
    {
      "name": "A156",
      "description": "Tuberculous pleurisy, confirmed bacteriologically and histologically"
    },
    {
      "name": "A157",
      "description": "Primary respiratory tuberculosis, confirmed bacteriologically and histologically"
    },
    {
      "name": "A158",
      "description": "Other respiratory tuberculosis, confirmed bacteriologically and histologically"
    },
    {
      "name": "A159",
      "description": "Respiratory tuberculosis unspecified, confirmed bacteriologically and histologically"
    },
    {
      "name": "A160",
      "description": "Tuberculosis of lung, bacteriologically and histologically negative"
    },
    {
      "name": "A161",
      "description": "Tuberculosis of lung ,bacteriological and histological examination not done"
    },
    {
      "name": "A162",
      "description": "Tuberculosis of lung, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A163",
      "description": "Tuberculosis of intrathoracic lymph nodes, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A164",
      "description": "Tuberculosis of larynx, trachea and bronchus, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A165",
      "description": "Tuberculous pleurisy, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A167",
      "description": "Primary respiratory tuberculosis without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A168",
      "description": "Other respiratory tuberculosis, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A169",
      "description": "Respiratory tuberculosis unspecified, without mention of bacteriological or histological confirmation"
    },
    {
      "name": "A170",
      "description": "Tuberculous meningitis (G01*)"
    },
    {
      "name": "A171",
      "description": "Meningeal tuberculoma (G07*)"
    },
    {
      "name": "A178",
      "description": "Other tuberculosis of nervous system"
    },
    {
      "name": "A179",
      "description": "Tuberculosis of nervous system, unspecified (G99.8*)"
    },
    {
      "name": "A180",
      "description": "Tuberculosis of bones and joints"
    },
    {
      "name": "A181",
      "description": "Tuberculosis of genitourinary system"
    },
    {
      "name": "A182",
      "description": "Tuberculous peripheral lymphadenopathy"
    },
    {
      "name": "A183",
      "description": "Tuberculosis of intestines, peritoneum and mesenteric glands"
    },
    {
      "name": "A184",
      "description": "Tuberculosis of skin and subcutaneous tissue"
    },
    {
      "name": "A185",
      "description": "Tuberculosis of eye"
    },
    {
      "name": "A186",
      "description": "Tuberculosis of ear"
    },
    {
      "name": "A187",
      "description": "Tuberculosis of adrenal glands [E35.1*]"
    },
    {
      "name": "A188",
      "description": "Tuberculosis of other specified organs"
    },
    {
      "name": "A190",
      "description": "Acute miliary tuberculosis of a single specified site"
    },
    {
      "name": "A191",
      "description": "Acute miliary tuberculosis of multiple sites"
    },
    {
      "name": "A192",
      "description": "Acute miliary tuberculosis, unspecified"
    },
    {
      "name": "A198",
      "description": "Other miliary tuberculosis"
    },
    {
      "name": "A199",
      "description": "Miliary tuberculosis, unspecified"
    },
    {
      "name": "A200",
      "description": "Bubonic plague"
    },
    {
      "name": "A201",
      "description": "Cellulocutaneous plague"
    },
    {
      "name": "A202",
      "description": "Pneumonic plague"
    },
    {
      "name": "A203",
      "description": "Plague meningitis"
    },
    {
      "name": "A207",
      "description": "Septicaemic plague"
    },
    {
      "name": "A208",
      "description": "Other forms of plague"
    },
    {
      "name": "A209",
      "description": "Plague, unspecified"
    },
    {
      "name": "A210",
      "description": "Ulceroglandular tularaemia"
    },
    {
      "name": "A211",
      "description": "Oculoglandular tularaemia"
    },
    {
      "name": "A212",
      "description": "Pulmonary tularaemia"
    },
    {
      "name": "A213",
      "description": "Gastrointestinal tularaemia"
    },
    {
      "name": "A217",
      "description": "Generalized tularaemia"
    },
    {
      "name": "A218",
      "description": "Other forms of tularaemia"
    },
    {
      "name": "A219",
      "description": "Tularaemia, unspecified"
    },
    {
      "name": "A220",
      "description": "Cutaneous anthrax"
    },
    {
      "name": "A221",
      "description": "Pulmonary anthrax"
    },
    {
      "name": "A222",
      "description": "Gastrointestinal anthrax"
    },
    {
      "name": "A227",
      "description": "Anthrax sepsis"
    },
    {
      "name": "A228",
      "description": "Other forms of anthrax"
    },
    {
      "name": "A229",
      "description": "Anthrax, unspecified"
    },
    {
      "name": "A230",
      "description": "Brucellosis due to Brucella melitensis"
    },
    {
      "name": "A231",
      "description": "Brucellosis due to Brucella abortus"
    },
    {
      "name": "A232",
      "description": "Brucellosis due to Brucella suis"
    },
    {
      "name": "A233",
      "description": "Brucellosis due to Brucella canis"
    },
    {
      "name": "A238",
      "description": "Other brucellosis"
    },
    {
      "name": "A239",
      "description": "Brucellosis, unspecified"
    },
    {
      "name": "A240",
      "description": "Glanders"
    },
    {
      "name": "A241",
      "description": "Acute and fulminating melioidosis"
    },
    {
      "name": "A242",
      "description": "Subacute and chronic melioidosis"
    },
    {
      "name": "A243",
      "description": "Other melioidosis"
    },
    {
      "name": "A244",
      "description": "Melioidosis, unspecified"
    },
    {
      "name": "A250",
      "description": "Spirillosis"
    },
    {
      "name": "A251",
      "description": "Streptobacillosis"
    },
    {
      "name": "A259",
      "description": "Rat-bite fever,unspecified"
    },
    {
      "name": "A260",
      "description": "Cutaneous erysipeloid"
    },
    {
      "name": "A267",
      "description": "Erysipelothrix sepsis"
    },
    {
      "name": "A268",
      "description": "Other forms of erysipeloid"
    },
    {
      "name": "A269",
      "description": "Erysipeloid, unspecified"
    },
    {
      "name": "A270",
      "description": "Leptospirosis icterohaemorrhagica"
    },
    {
      "name": "A278",
      "description": "Other forms of leptospirosis"
    },
    {
      "name": "A279",
      "description": "Leptospirosis ,unspecified"
    },
    {
      "name": "A280",
      "description": "Pasteurellosis"
    },
    {
      "name": "A281",
      "description": "Cat-scratch disease"
    },
    {
      "name": "A282",
      "description": "Extraintestinal yersiniosis"
    },
    {
      "name": "A288",
      "description": "Other specified zoonotic bacterial diseases,not elsewhere classified"
    },
    {
      "name": "A289",
      "description": "Zoonotic bacterial disease,unspecified"
    },
    {
      "name": "A300",
      "description": "Indeterminate leprosy"
    },
    {
      "name": "A301",
      "description": "Tuberculoid leprosy"
    },
    {
      "name": "A302",
      "description": "Borderline tuberculoid leprosy"
    },
    {
      "name": "A303",
      "description": "Borderline leprosy"
    },
    {
      "name": "A304",
      "description": "Borderline lepromatous leprosy"
    },
    {
      "name": "A305",
      "description": "Lepromatous leprosy"
    },
    {
      "name": "A308",
      "description": "Other form of leprosy"
    },
    {
      "name": "A309",
      "description": "Leprosy ,unspecified"
    },
    {
      "name": "A310",
      "description": "Pulmonary mycobacterial infection"
    },
    {
      "name": "A311",
      "description": "Cutaneous mycobacterial infection"
    },
    {
      "name": "A318",
      "description": "Other mycobacterial infections"
    },
    {
      "name": "A319",
      "description": "Mycobacterial infection, unspecified"
    },
    {
      "name": "A320",
      "description": "Cutaneous listeriosis"
    },
    {
      "name": "A321",
      "description": "Listerial meningitis and meningoencephalitis"
    },
    {
      "name": "A327",
      "description": "Listerial sepsis"
    },
    {
      "name": "A328",
      "description": "Other forms of listeriosis"
    },
    {
      "name": "A329",
      "description": "Listeriosis ,unspecified"
    },
    {
      "name": "A33",
      "description": "Tetanus neonatorum"
    },
    {
      "name": "A34",
      "description": "Obstetrical tetanus"
    },
    {
      "name": "A35",
      "description": "Other tetanus"
    },
    {
      "name": "A360",
      "description": "Pharyngeal diphtheria"
    },
    {
      "name": "A361",
      "description": "Nasopharyngeal diphtheria"
    },
    {
      "name": "A362",
      "description": "Laryngeal diphtheria"
    },
    {
      "name": "A363",
      "description": "Cutaneous diphtheria"
    },
    {
      "name": "A368",
      "description": "Other diphtheria"
    },
    {
      "name": "A369",
      "description": "Diphtheria ,unspecified"
    },
    {
      "name": "A370",
      "description": "Whooping cough due to Bordetella pertussis"
    },
    {
      "name": "A371",
      "description": "Whooping cough due to Bordetella parapertussis"
    },
    {
      "name": "A378",
      "description": "Whooping cough due to other Bordetella species"
    },
    {
      "name": "A379",
      "description": "Whooping cough,unspecified"
    },
    {
      "name": "A38",
      "description": "Scarlet fever"
    },
    {
      "name": "A390",
      "description": "Meningococcal meningitis [G01*]"
    },
    {
      "name": "A391",
      "description": "Waterhouse-Friderichsen syndrome [E35.1*]"
    },
    {
      "name": "A392",
      "description": "Acute meningococcaemia"
    },
    {
      "name": "A393",
      "description": "Chronic meningococcaemia"
    },
    {
      "name": "A394",
      "description": "Meningococcaemia ,unspecified"
    },
    {
      "name": "A395",
      "description": "Meningococcal heart disease"
    },
    {
      "name": "A398",
      "description": "Other meningococcal infections"
    },
    {
      "name": "A399",
      "description": "Meningococcal infection,unspecified"
    },
    {
      "name": "A400",
      "description": "Sepsis due to streptococcus, group A"
    },
    {
      "name": "A401",
      "description": "Sepsis due to streptococcus, group B"
    },
    {
      "name": "A402",
      "description": "Sepsis due to streptococcus, group D"
    },
    {
      "name": "A403",
      "description": "Sepsis due to streptococcus pneumoniae"
    },
    {
      "name": "A408",
      "description": "Other streptococcal Sepsis"
    },
    {
      "name": "A409",
      "description": "Streptococcal Sepsis, unspecified"
    },
    {
      "name": "A410",
      "description": "Sepsis due to Staphylococcus aureus"
    },
    {
      "name": "A411",
      "description": "Sepsis due to other specified staphylococcus"
    },
    {
      "name": "A412",
      "description": "Sepsis due to unspecified staphylococcus"
    },
    {
      "name": "A413",
      "description": "Sepsis due to Haemophilus influenzae"
    },
    {
      "name": "A414",
      "description": "Sepsis due to anaerobes"
    },
    {
      "name": "A415",
      "description": "Sepsis due to other Gram-negative organisms"
    },
    {
      "name": "A418",
      "description": "Other specified Sepsis"
    },
    {
      "name": "A419",
      "description": "Sepsis, unspecified"
    },
    {
      "name": "A420",
      "description": "Pulmonary actinomycosis"
    },
    {
      "name": "A421",
      "description": "Abdominal actinomycosis"
    },
    {
      "name": "A422",
      "description": "Cervicofacial actinomycosis"
    },
    {
      "name": "A427",
      "description": "Actinomycotic sepsis"
    },
    {
      "name": "A428",
      "description": "Other forms of actinomycosis"
    },
    {
      "name": "A429",
      "description": "Actinomycosis, unspecified"
    },
    {
      "name": "A430",
      "description": "Pulmonary nocardiosis"
    },
    {
      "name": "A431",
      "description": "Cutaneous nocardiosis"
    },
    {
      "name": "A438",
      "description": "Other forms of nocardiosis"
    },
    {
      "name": "A439",
      "description": "Nocardiosis, unspecified"
    },
    {
      "name": "A440",
      "description": "Systemic bartonellosis"
    },
    {
      "name": "A441",
      "description": "Cutaneous and mucocutaneous bartonellosis"
    },
    {
      "name": "A448",
      "description": "Other forms of bartonellosis"
    },
    {
      "name": "A449",
      "description": "Bartonellosis, unspecified"
    },
    {
      "name": "A46",
      "description": "Erysipelas"
    },
    {
      "name": "A480",
      "description": "Gas gangrene"
    },
    {
      "name": "A481",
      "description": "Legionnaires' disease"
    },
    {
      "name": "A482",
      "description": "Nonpneumonic Legionnaires' disease [Pontiac fever]"
    },
    {
      "name": "A483",
      "description": "Tixic shock syndrome"
    },
    {
      "name": "A484",
      "description": "Brazilian purpuric fever"
    },
    {
      "name": "A488",
      "description": "Other specified bacterial diseases"
    },
    {
      "name": "A490",
      "description": "Staphylococcal infection,unspecified site"
    },
    {
      "name": "A491",
      "description": "Streptococcal infection,unspecified site"
    },
    {
      "name": "A492",
      "description": "Haemophilus influenzae infection,unspecified site"
    },
    {
      "name": "A493",
      "description": "Mycoplasma infection, unspecified site"
    },
    {
      "name": "A498",
      "description": "Other bacterial infections of unspecified site"
    },
    {
      "name": "A499",
      "description": "Bacterial infection, unspecified"
    },
    {
      "name": "A500",
      "description": "Early congenital syphilis, symptomatic"
    },
    {
      "name": "A501",
      "description": "Early congenital syphilis, latent"
    },
    {
      "name": "A502",
      "description": "Early congenital syphilis, unspecified"
    },
    {
      "name": "A503",
      "description": "Late congenital syphilitic oculopathy"
    },
    {
      "name": "A504",
      "description": "Late congenital neurosyphilis [juvenile neurosyphilis]"
    },
    {
      "name": "A505",
      "description": "Other late congenital syphilis, symptomatic"
    },
    {
      "name": "A506",
      "description": "Late congenital syphilis, latent"
    },
    {
      "name": "A507",
      "description": "Late congenital syphilis, unspecified"
    },
    {
      "name": "A509",
      "description": "Congenital syphilis,  unspecified"
    },
    {
      "name": "A510",
      "description": "Primary genital syphilis"
    },
    {
      "name": "A511",
      "description": "Primary anal syphilis"
    },
    {
      "name": "A512",
      "description": "Primary syphilis of other sites"
    },
    {
      "name": "A513",
      "description": "Secondary syphilis of skin and mucous membranes"
    },
    {
      "name": "A514",
      "description": "Other secondary syphilis"
    },
    {
      "name": "A515",
      "description": "Early syphilis, latent"
    },
    {
      "name": "A519",
      "description": "Early syphilis, unspecified"
    },
    {
      "name": "A520",
      "description": "Cardiovascular syphilis"
    },
    {
      "name": "A521",
      "description": "Symptomatic neurosyphilis"
    },
    {
      "name": "A522",
      "description": "Asymptomatic neurosyphilis"
    },
    {
      "name": "A523",
      "description": "Neurosyphilis, unspecified"
    },
    {
      "name": "A527",
      "description": "Other symptomatic late syphilis"
    },
    {
      "name": "A528",
      "description": "Late syphilis, latent"
    },
    {
      "name": "A529",
      "description": "Late syphilis, unspecified"
    },
    {
      "name": "A530",
      "description": "Latent syphilis,unspecified as early or late"
    },
    {
      "name": "A539",
      "description": "Syphilis,unspecified"
    },
    {
      "name": "A540",
      "description": "Gonococcal infection of lower genitourinary tract without periurethral or accessory gland abscess"
    },
    {
      "name": "A541",
      "description": "Gonococcal infection of lower genitourinary tract with periurethral and accessory gland abscess"
    },
    {
      "name": "A542",
      "description": "Gonococcal pelviperitonitis and other gonococcal genitourinary infections"
    },
    {
      "name": "A543",
      "description": "Gonococcal infection of eye"
    },
    {
      "name": "A544",
      "description": "Gonococcal infection of musculoskeletal system"
    },
    {
      "name": "A545",
      "description": "Gonococcal pharyngitis"
    },
    {
      "name": "A546",
      "description": "Gonococcal infection of anus and rectum"
    },
    {
      "name": "A548",
      "description": "Other gonococcal infections"
    },
    {
      "name": "A549",
      "description": "Gonococcal infection,unspecified"
    },
    {
      "name": "A55",
      "description": "Chlamydial lymphogranuloma (venereum)"
    },
    {
      "name": "A560",
      "description": "Chlamydial infection of lower genitourinary tract"
    },
    {
      "name": "A561",
      "description": "Chlamydial infection of pelviperitoneum and other genitourinary organs"
    },
    {
      "name": "A562",
      "description": "Chlamydial infection of genitourinary tract,unspecified"
    },
    {
      "name": "A563",
      "description": "Chlamydial infection of anus and rectum"
    },
    {
      "name": "A564",
      "description": "Chlamydial infection of pharynx"
    },
    {
      "name": "A568",
      "description": "Sexually transmitted chlamydial infection of other sites"
    },
    {
      "name": "A57",
      "description": "Chancroid"
    },
    {
      "name": "A58",
      "description": "Granuloma inguinale"
    },
    {
      "name": "A590",
      "description": "Urogential trichomoniasis"
    },
    {
      "name": "A598",
      "description": "Trichomoniasis of other sites"
    },
    {
      "name": "A599",
      "description": "Trichomoniasis, unspecified"
    },
    {
      "name": "A600",
      "description": "Herpesviral infection of genitalia and urogenital tract"
    },
    {
      "name": "A601",
      "description": "Herpesviral infection of perianal skin and rectum"
    },
    {
      "name": "A609",
      "description": "Anogenital herpesviral infection, unspecified"
    },
    {
      "name": "A630",
      "description": "Anogential (venereal) warts"
    },
    {
      "name": "A638",
      "description": "Other specified predominantly sexually transmitted diseases"
    },
    {
      "name": "A64",
      "description": "Unspecified sexually transmitted diseases"
    },
    {
      "name": "A65",
      "description": "Nonvenereal syphilis"
    },
    {
      "name": "A660",
      "description": "Initial lesions of yaws"
    },
    {
      "name": "A661",
      "description": "Multiple papillomata and wet crab yaws"
    },
    {
      "name": "A662",
      "description": "Other eary skin lesions of yaws"
    },
    {
      "name": "A663",
      "description": "Hyperkeratosis of yaws"
    },
    {
      "name": "A664",
      "description": "Gummata and ulcers of yaws"
    },
    {
      "name": "A665",
      "description": "Gangosa"
    },
    {
      "name": "A666",
      "description": "Bone and joint lesions of yaws"
    },
    {
      "name": "A667",
      "description": "Oher manifestations of yaws"
    },
    {
      "name": "A668",
      "description": "Latent yaws"
    },
    {
      "name": "A669",
      "description": "Yaws, unspecified"
    },
    {
      "name": "A670",
      "description": "Primary lesions of pinta"
    },
    {
      "name": "A671",
      "description": "Intermediate lesions of pinta"
    },
    {
      "name": "A672",
      "description": "Late lesions of pinta"
    },
    {
      "name": "A673",
      "description": "Mixed lesions of pinta"
    },
    {
      "name": "A679",
      "description": "Pinta, unspecified"
    },
    {
      "name": "A680",
      "description": "Louse-borne relapsing fever"
    },
    {
      "name": "A681",
      "description": "Tick-borne relapsing fever"
    },
    {
      "name": "A689",
      "description": "Relapsing fever, unspecified"
    },
    {
      "name": "A690",
      "description": "Necrotizing ulcerative stomatitis"
    },
    {
      "name": "A691",
      "description": "Other Vincent's infections"
    },
    {
      "name": "A692",
      "description": "Lyme disease"
    },
    {
      "name": "A698",
      "description": "Other specified spirochaetal infections"
    },
    {
      "name": "A699",
      "description": "Spirochaetal infection, unspecified"
    },
    {
      "name": "A70",
      "description": "Chlamydia psittaci infection"
    },
    {
      "name": "A710",
      "description": "Initial stage of trachoma"
    },
    {
      "name": "A711",
      "description": "Active stage of trachoma"
    },
    {
      "name": "A719",
      "description": "Trachoma, unspecified"
    },
    {
      "name": "A740",
      "description": "Chlamydial conjunctivitis (H13.1*)"
    },
    {
      "name": "A748",
      "description": "Other chlamydial diseases"
    },
    {
      "name": "A749",
      "description": "Chlamydial infection, unspecified"
    },
    {
      "name": "A750",
      "description": "Epidemic louse-borne typhus fever due to Rickettsia prowazekii"
    },
    {
      "name": "A751",
      "description": "Recrudescent typhus [Brill's disease ]"
    },
    {
      "name": "A752",
      "description": "Typhus fever due to Rickettsia typhi"
    },
    {
      "name": "A753",
      "description": "Typhus fever due to Rickettsia tsutsugamushi"
    },
    {
      "name": "A759",
      "description": "Typhus fever, unspecified"
    },
    {
      "name": "A770",
      "description": "Spotted fever due to Rickettsia rickettsii"
    },
    {
      "name": "A771",
      "description": "Spotted fever due to Rickettsia conorii"
    },
    {
      "name": "A772",
      "description": "Spotted fever due to Rickettsia siberica"
    },
    {
      "name": "A773",
      "description": "Spotted fever due to Rickettsia australis"
    },
    {
      "name": "A778",
      "description": "Other spotted fevers"
    },
    {
      "name": "A779",
      "description": "Spotted fever, unspecified"
    },
    {
      "name": "A78",
      "description": "Q fever"
    },
    {
      "name": "A790",
      "description": "Trench fever"
    },
    {
      "name": "A791",
      "description": "Rickettsialpox due to Rickettsia akari"
    },
    {
      "name": "A798",
      "description": "Other specified rickettsioses"
    },
    {
      "name": "A799",
      "description": "Rickettsiosis, unspecified"
    },
    {
      "name": "A800",
      "description": "Acute paralytic poliomyelitis, vaccine-associated"
    },
    {
      "name": "A801",
      "description": "Acute paralytic poliomyelitis, wild virus, imported"
    },
    {
      "name": "A802",
      "description": "Acute paralytic poliomyelitis, wild virus ,indigenous"
    },
    {
      "name": "A803",
      "description": "Acute paralytic poliomyelitis, other and unspecified"
    },
    {
      "name": "A804",
      "description": "Acute nonparalytic poliomyelitis"
    },
    {
      "name": "A809",
      "description": "Acute poliomyelitis, unspecified"
    },
    {
      "name": "A810",
      "description": "Creutzfeldt-Jakob disease"
    },
    {
      "name": "A811",
      "description": "Subacute sclerosing panencephalitis"
    },
    {
      "name": "A812",
      "description": "Progressive  multifocal leukoencephalopathy"
    },
    {
      "name": "A818",
      "description": "Other atypical virus infections of central nervous system"
    },
    {
      "name": "A819",
      "description": "Atypical virus infection of central nervous system, unspecified"
    },
    {
      "name": "A820",
      "description": "Sylvatic rabies"
    },
    {
      "name": "A821",
      "description": "Urban rabies"
    },
    {
      "name": "A829",
      "description": "Rabies, unspecified"
    },
    {
      "name": "A830",
      "description": "Japanese encephalitis"
    },
    {
      "name": "A831",
      "description": "Western equine encephalitis"
    },
    {
      "name": "A832",
      "description": "Eastern equine encephalitis"
    },
    {
      "name": "A833",
      "description": "St Louis encephalitis"
    },
    {
      "name": "A834",
      "description": "Australian encephalitis"
    },
    {
      "name": "A835",
      "description": "California encephalitis"
    },
    {
      "name": "A836",
      "description": "Rocio virus disease"
    },
    {
      "name": "A838",
      "description": "Other mosquito-borne viral encephalitis"
    },
    {
      "name": "A839",
      "description": "Mosquito-borne viral encephalitis, unspecified"
    },
    {
      "name": "A840",
      "description": "Far Eastern tick-borne encephalitis [Russian spring-summer encephalitis]"
    },
    {
      "name": "A841",
      "description": "Central European tick-borne encephalitis"
    },
    {
      "name": "A848",
      "description": "Other tick-borne viral encephalitis"
    },
    {
      "name": "A849",
      "description": "Tick-borne viral encephalitis, unspecified"
    },
    {
      "name": "A850",
      "description": "Enteroviral encephalitis (G05.1*)"
    },
    {
      "name": "A851",
      "description": "Adenoviral encephalitis  (G05.1*)"
    },
    {
      "name": "A852",
      "description": "Arthropod-borne viral encephalitis, unspecified"
    },
    {
      "name": "A858",
      "description": "Other specified viral encephalitis"
    },
    {
      "name": "A86",
      "description": "Unspecified viral encephalitis"
    },
    {
      "name": "A870",
      "description": "Enteroviral meningitis (G02.0)"
    },
    {
      "name": "A871",
      "description": "Adenoviral meningitis (G02.0)"
    },
    {
      "name": "A872",
      "description": "Lymphocytic choriomeningitis"
    },
    {
      "name": "A878",
      "description": "Other viral meningitis"
    },
    {
      "name": "A879",
      "description": "Viral meningitis, unspecified"
    },
    {
      "name": "A880",
      "description": "Enteroviral exanthematous fever [Boston exanthem]"
    },
    {
      "name": "A881",
      "description": "Epidemic vertigo"
    },
    {
      "name": "A888",
      "description": "Other specified viral infections of central nervous system"
    },
    {
      "name": "A89",
      "description": "Unspecified viral infection of central nervous system"
    },
    {
      "name": "A920",
      "description": "Chikungunya virus disease"
    },
    {
      "name": "A921",
      "description": "O'nyong-nyong fever"
    },
    {
      "name": "A922",
      "description": "Venezuelan equine fever"
    },
    {
      "name": "A923",
      "description": "West Nile virus infection"
    },
    {
      "name": "A924",
      "description": "Rift Valley fever"
    },
    {
      "name": "A928",
      "description": "Other specified mosquito-borne viral fevers"
    },
    {
      "name": "A929",
      "description": "Mosquito-borne viral fever, unspecified"
    },
    {
      "name": "A930",
      "description": "Oropouche virus disease"
    },
    {
      "name": "A931",
      "description": "Sandfly fever"
    },
    {
      "name": "A932",
      "description": "Colorado tick fever"
    },
    {
      "name": "A938",
      "description": "Other specified arthropod-borne viral fevers"
    },
    {
      "name": "A94",
      "description": "Unspecified arthropod-borne viral fever"
    },
    {
      "name": "A950",
      "description": "Sylvatic yellow fever"
    },
    {
      "name": "A951",
      "description": "Urban yellow fever"
    },
    {
      "name": "A959",
      "description": "Yellow fever, unspecified"
    },
    {
      "name": "A960",
      "description": "Junin haemorrhagic fever"
    },
    {
      "name": "A961",
      "description": "Machupo haemorrhagic fever"
    },
    {
      "name": "A962",
      "description": "Lassa fever"
    },
    {
      "name": "A968",
      "description": "Other arenaviral haemorrhagic fevers"
    },
    {
      "name": "A969",
      "description": "Arenaviral haemorrhagic fever, unspecified"
    },
    {
      "name": "A970",
      "description": "Dengue without warning signs"
    },
    {
      "name": "A971",
      "description": "Dengue with warning signs"
    },
    {
      "name": "A972",
      "description": "Severe dengue"
    },
    {
      "name": "A979",
      "description": "Dengue, unspecified"
    },
    {
      "name": "A980",
      "description": "Crimean-Congo haemorrhagic fever"
    },
    {
      "name": "A981",
      "description": "Omsk haemorrhagic fever"
    },
    {
      "name": "A982",
      "description": "Kyasanur Forest disease"
    },
    {
      "name": "A983",
      "description": "Marburg virus disease"
    },
    {
      "name": "A984",
      "description": "Ebola virus disease"
    },
    {
      "name": "A985",
      "description": "Haemorrhagic fever with renal syndrome"
    },
    {
      "name": "A988",
      "description": "Other specified viral haemorrhagic fevers"
    },
    {
      "name": "A99",
      "description": "Unspecified viral haemorrhagic fever"
    },
    {
      "name": "B000",
      "description": "Eczema herpeticum"
    },
    {
      "name": "B001",
      "description": "Herpesviral vesicular dermatitis"
    },
    {
      "name": "B002",
      "description": "Herpesviral gingivostomatitis and pharyngotonsillitis"
    },
    {
      "name": "B003",
      "description": "Herpesviral meningitis (G02.0)"
    },
    {
      "name": "B004",
      "description": "Herpesviral encephalitis (G05.1)"
    },
    {
      "name": "B005",
      "description": "Herpesviral ocular disease"
    },
    {
      "name": "B007",
      "description": "Disseminated herpesviral disease"
    },
    {
      "name": "B008",
      "description": "Other forms of herpesviral infection"
    },
    {
      "name": "B009",
      "description": "Herpesviral infection, unspecified"
    },
    {
      "name": "B010",
      "description": "Varicella meningitis (G02.0)"
    },
    {
      "name": "B011",
      "description": "Varicella encephalitis (G05.1)"
    },
    {
      "name": "B012",
      "description": "Varicella  pneumonia (J17.1*)"
    },
    {
      "name": "B018",
      "description": "Varicella with other complications"
    },
    {
      "name": "B019",
      "description": "Varicella without complication"
    },
    {
      "name": "B020",
      "description": "Zoster encephalitis (G05.1*)"
    },
    {
      "name": "B021",
      "description": "Zoster meningitis (G02.0*)"
    },
    {
      "name": "B022",
      "description": "Zoster with other nervous system involvement"
    },
    {
      "name": "B023",
      "description": "Zoster ocular disease"
    },
    {
      "name": "B027",
      "description": "Disseminated zoster"
    },
    {
      "name": "B028",
      "description": "Zoster with other complications"
    },
    {
      "name": "B029",
      "description": "Zoster without complication"
    },
    {
      "name": "B03",
      "description": "Smallpox1"
    },
    {
      "name": "B04",
      "description": "Monkeypox"
    },
    {
      "name": "B050",
      "description": "Measles complicated by  encephalitis (G05.1*)"
    },
    {
      "name": "B051",
      "description": "Measles complicated by meningitis (G02.0*)"
    },
    {
      "name": "B052",
      "description": "Measles complicated by pneumonia (J17.1*)"
    },
    {
      "name": "B053",
      "description": "Measles complicated by otitis media (H67.1*)"
    },
    {
      "name": "B054",
      "description": "Measles with intestinal complications"
    },
    {
      "name": "B058",
      "description": "Measles with other complications"
    },
    {
      "name": "B059",
      "description": "Measles without complication"
    },
    {
      "name": "B060",
      "description": "Rubella with neurological complications"
    },
    {
      "name": "B068",
      "description": "Rubella with other complications"
    },
    {
      "name": "B069",
      "description": "Rubella without complication"
    },
    {
      "name": "B07",
      "description": "Viral warts"
    },
    {
      "name": "B080",
      "description": "Other orthopoxvirus infections"
    },
    {
      "name": "B081",
      "description": "Molluscum contagiosum"
    },
    {
      "name": "B082",
      "description": "Exanthema subitum [sixth disease]"
    },
    {
      "name": "B083",
      "description": "Erythema infectiosum [fifth disease]"
    },
    {
      "name": "B084",
      "description": "Enteroviral vesicular stomatitis with exanthem"
    },
    {
      "name": "B085",
      "description": "Enteroviral vesicular pharyngitis"
    },
    {
      "name": "B088",
      "description": "Other specified viral infections characterized by skin and mucous membrane lesions"
    },
    {
      "name": "B09",
      "description": "Unspecifeid viral infection characterized by skin and mucous  membrane lesions"
    },
    {
      "name": "B150",
      "description": "Hepatitis A with hepatic coma"
    },
    {
      "name": "B159",
      "description": "Hepatitis A without hepatic coma"
    },
    {
      "name": "B160",
      "description": "Acute hepatitis B with delta-agent (coinfection) with hepatic coma"
    },
    {
      "name": "B161",
      "description": "Acute hepatitis B with delta-agent (coinfection) without hepatic coma"
    },
    {
      "name": "B162",
      "description": "Acute hepatitis B without delta-agent with hepatic coma"
    },
    {
      "name": "B169",
      "description": "Acute hepatitis B without delta-agent and without hepatic coma"
    },
    {
      "name": "B170",
      "description": "Acute delta-(super) infection of hepatitis B carrier"
    },
    {
      "name": "B171",
      "description": "Acute hepatitis C"
    },
    {
      "name": "B172",
      "description": "Acute hepatitis E"
    },
    {
      "name": "B178",
      "description": "Other specified acute viral hepatitis"
    },
    {
      "name": "B179",
      "description": "Acute viral hepatitis, unspecified"
    },
    {
      "name": "B180",
      "description": "Chronic viral hepatitis B with delta-agent"
    },
    {
      "name": "B1810",
      "description": "Chronic viral hepatitis B without delta-agent"
    },
    {
      "name": "B1819",
      "description": "Chronic viral hepatitis B, unspecified delta-agent status"
    },
    {
      "name": "B182",
      "description": "Chronic viral hepatitis C"
    },
    {
      "name": "B188",
      "description": "Other chronic viral hepatitis"
    },
    {
      "name": "B189",
      "description": "Chronic viral hepatitis, unspecified"
    },
    {
      "name": "B190",
      "description": "Unspecified viral hepatitis with hepatic coma"
    },
    {
      "name": "B199",
      "description": "Unspecified viral hepatitis without hepatic coma"
    },
    {
      "name": "B200",
      "description": "HIV disease  resulting in mycobacterial infection"
    },
    {
      "name": "B201",
      "description": "HIV disease  resulting in other bacterial infections"
    },
    {
      "name": "B202",
      "description": "HIV disease  resulting in cytomegaloviral disaese"
    },
    {
      "name": "B203",
      "description": "HIV disease  resulting in other viral infections"
    },
    {
      "name": "B204",
      "description": "HIV disease  resulting in candidiasis"
    },
    {
      "name": "B205",
      "description": "HIV disease  resulting in other mycoses"
    },
    {
      "name": "B206",
      "description": "HIV disease resulting in Pneumocystis jirovecii pneumonia"
    },
    {
      "name": "B207",
      "description": "HIV disease  resulting in multiple infections"
    },
    {
      "name": "B208",
      "description": "HIV disease  resulting in other infectious and parasitic diseases"
    },
    {
      "name": "B209",
      "description": "HIV disease  resulting in unspecified infectious or parasitic disease"
    },
    {
      "name": "B210",
      "description": "HIV disease  resulting in Kaposi sarcoma"
    },
    {
      "name": "B211",
      "description": "HIV disease  resulting in Burkitt lymphoma"
    },
    {
      "name": "B212",
      "description": "HIV disease  resulting in other types of non-Hodgkin's lymphoma"
    },
    {
      "name": "B213",
      "description": "HIV disease  resulting in other malignant neoplasms of lymphoid, haematopoietic and related tissue"
    },
    {
      "name": "B217",
      "description": "HIV disease  resulting in multiple malignant neoplasms"
    },
    {
      "name": "B218",
      "description": "HIV disease  resulting in other malignant neoplasms"
    },
    {
      "name": "B219",
      "description": "HIV disease  resulting in unspecified malignant neoplasm"
    },
    {
      "name": "B220",
      "description": "HIV disease  resulting in encephalopathy"
    },
    {
      "name": "B221",
      "description": "HIV disease  resulting in lymphoid interstitial pneumonitis"
    },
    {
      "name": "B222",
      "description": "HIV disease  resulting in wasting syndrome"
    },
    {
      "name": "B227",
      "description": "HIV disease resulting in multiple diseases classified elsewhere"
    },
    {
      "name": "B230",
      "description": "Acute HIV infection syndrome"
    },
    {
      "name": "B231",
      "description": "HIV disease resulting in (persistent) generalized lymphadenopathy"
    },
    {
      "name": "B232",
      "description": "HIV disease resulting in haematological and immunological abnormalities ,not elsewhere classified"
    },
    {
      "name": "B238",
      "description": "HIV disease resulting in other specified conditions"
    },
    {
      "name": "B24",
      "description": "Unspecified human immunodeficiency virus [HIV] disease"
    },
    {
      "name": "B250",
      "description": "Cytomegaloviral pneumonitis [J17.1*]"
    },
    {
      "name": "B251",
      "description": "Cytomegaloviral hepatitis [K77.0*]"
    },
    {
      "name": "B252",
      "description": "Cytomegaloviral pancreatitis (K87.1*)"
    },
    {
      "name": "B258",
      "description": "Other cytomegalovial diseases"
    },
    {
      "name": "B259",
      "description": "Cytomegaloviral diseases, unspecified"
    },
    {
      "name": "B260",
      "description": "Mumps orchitis (N51.1*)"
    },
    {
      "name": "B261",
      "description": "Mumps meningitis (G02.0*)"
    },
    {
      "name": "B262",
      "description": "Mumps encephalitis (G05.1*)"
    },
    {
      "name": "B263",
      "description": "Mumps pancreatitis (K87.1)"
    },
    {
      "name": "B268",
      "description": "Mumps with other complications"
    },
    {
      "name": "B269",
      "description": "Mumps without complication"
    },
    {
      "name": "B270",
      "description": "Gammaherpesviral mononucleosis"
    },
    {
      "name": "B271",
      "description": "Cytomegaloviral mononucleosis"
    },
    {
      "name": "B278",
      "description": "Other infectious mononucleosis"
    },
    {
      "name": "B279",
      "description": "Infectious mononucleosis, unspecified"
    },
    {
      "name": "B300",
      "description": "Keratoconjunctivitis due to adenovirus (H19.2)"
    },
    {
      "name": "B301",
      "description": "Conjunctivitis due to adenovirus (H13.1)"
    },
    {
      "name": "B302",
      "description": "Viral pharyngoconjunctivitis"
    },
    {
      "name": "B303",
      "description": "Acute epidemic haemorrhagic conjunctivitis (enteroviral) (H13.1)"
    },
    {
      "name": "B308",
      "description": "Other viral conjunctivitis (H13.1)"
    },
    {
      "name": "B309",
      "description": "Viral conjunctivitis, unspecified"
    },
    {
      "name": "B330",
      "description": "Epidemic myalgia"
    },
    {
      "name": "B331",
      "description": "Ross River disease"
    },
    {
      "name": "B332",
      "description": "Viral carditis"
    },
    {
      "name": "B333",
      "description": "Retrovirus infections, not elsewhere classified"
    },
    {
      "name": "B334",
      "description": "Hantavirus (cardio)-pulmonary syndrome [HPS] [HCPS] (J17.1)"
    },
    {
      "name": "B338",
      "description": "Other specified viral diseases"
    },
    {
      "name": "B340",
      "description": "Adenovirus infection, unspecified site"
    },
    {
      "name": "B341",
      "description": "Enterovirus  infection, unspecified site"
    },
    {
      "name": "B342",
      "description": "Coronavirus  infection, unspecified site"
    },
    {
      "name": "B343",
      "description": "Parvovirus  infection, unspecified site"
    },
    {
      "name": "B344",
      "description": "Papovavirus  infection, unspecified site"
    },
    {
      "name": "B348",
      "description": "Other viral  infections of unspecified site"
    },
    {
      "name": "B349",
      "description": "Viral infection, unpecified"
    },
    {
      "name": "B350",
      "description": "Tinea barbae and tinea capitis"
    },
    {
      "name": "B351",
      "description": "Tinea unguium"
    },
    {
      "name": "B352",
      "description": "Tinea manuum"
    },
    {
      "name": "B353",
      "description": "Tinea pedis"
    },
    {
      "name": "B354",
      "description": "Tinea corporis"
    },
    {
      "name": "B355",
      "description": "Tinea imbricata"
    },
    {
      "name": "B356",
      "description": "Tinea inguinalis [Tinea cruris]"
    },
    {
      "name": "B357",
      "description": "Tinea faciei"
    },
    {
      "name": "B358",
      "description": "Other dermatophytoses"
    },
    {
      "name": "B359",
      "description": "Dermatophytosis, unspecified"
    },
    {
      "name": "B360",
      "description": "Pityriasis versicolor"
    },
    {
      "name": "B361",
      "description": "Tinea nigra"
    },
    {
      "name": "B362",
      "description": "White piedra"
    },
    {
      "name": "B363",
      "description": "Black piedra"
    },
    {
      "name": "B368",
      "description": "Other specified superficial mycoses"
    },
    {
      "name": "B369",
      "description": "Superficial mycosis, unspecified"
    },
    {
      "name": "B3700",
      "description": "Acute pseudomembranous candidal stomatitis"
    },
    {
      "name": "B3701",
      "description": "Acute  erythematous (atrophic) candidal stomatitis"
    },
    {
      "name": "B3702",
      "description": "Chronic hyperplastic candidal stomatitis"
    },
    {
      "name": "B3703",
      "description": "Chronic erythematous (atrophic) candidal stomatitis"
    },
    {
      "name": "B3704",
      "description": "Mucocutaneous candidiasis"
    },
    {
      "name": "B3705",
      "description": "Oral candidal granuloma"
    },
    {
      "name": "B3708",
      "description": "Other specified , candidal stomatitis"
    },
    {
      "name": "B3709",
      "description": "Candidal stomatitis, unspecified"
    },
    {
      "name": "B371",
      "description": "Pulmonary candidiasis"
    },
    {
      "name": "B372",
      "description": "Candidiasis of skin and nail"
    },
    {
      "name": "B373",
      "description": "Candidiasis of vulva and vagina (N77.1*)"
    },
    {
      "name": "B374",
      "description": "Candidiasis of other urogenital sites"
    },
    {
      "name": "B375",
      "description": "Candidal meningitis (G02.1*)"
    },
    {
      "name": "B376",
      "description": "Candidal endocarditis (I39.8*)"
    },
    {
      "name": "B377",
      "description": "Candidal sepsis"
    },
    {
      "name": "B378",
      "description": "Candidiasis of other sites"
    },
    {
      "name": "B379",
      "description": "Candidiasis, unspecified"
    },
    {
      "name": "B380",
      "description": "Acute pulmonary coccidioidomycosis"
    },
    {
      "name": "B381",
      "description": "Chronic  pulmonary coccidioidomycosis"
    },
    {
      "name": "B382",
      "description": "Pulmonary coccidioidomycosis, unspecified"
    },
    {
      "name": "B383",
      "description": "Cutaneous coccidioidomycosis"
    },
    {
      "name": "B384",
      "description": "Coccidioidomycosis meningitis (G02.1*)"
    },
    {
      "name": "B387",
      "description": "Disseminated coccidioidomycosis"
    },
    {
      "name": "B388",
      "description": "Other forms of coccidioidomycosis"
    },
    {
      "name": "B389",
      "description": "Coccidioidomycosis, unspecified"
    },
    {
      "name": "B390",
      "description": "Acute pulmonary histoplasmosis capsulati"
    },
    {
      "name": "B391",
      "description": "Chronic  pulmonary histoplasmosis capsulati"
    },
    {
      "name": "B392",
      "description": "Pulmonary histoplasmosis capsulati, unspecified"
    },
    {
      "name": "B393",
      "description": "Disseminated histoplasmosis capsulati"
    },
    {
      "name": "B394",
      "description": "Histoplasmosis capsulati, unspecified"
    },
    {
      "name": "B395",
      "description": "Histoplasmosis duboisii"
    },
    {
      "name": "B399",
      "description": "Histoplasmosis, unspecified"
    },
    {
      "name": "B400",
      "description": "Acute pulmonary blastomycosis"
    },
    {
      "name": "B401",
      "description": "Chronic pulmonary blastomycosis"
    },
    {
      "name": "B402",
      "description": "Pulmonary blastomycosis, unspecified"
    },
    {
      "name": "B403",
      "description": "Cutaneous blastomycosis"
    },
    {
      "name": "B407",
      "description": "Disseminated blastomycosis"
    },
    {
      "name": "B408",
      "description": "Other forms of blastomycosis"
    },
    {
      "name": "B409",
      "description": "Blastomycosis, unspecified"
    },
    {
      "name": "B410",
      "description": "Pulmonary paracoccidioidomycosis"
    },
    {
      "name": "B417",
      "description": "Disseminated paracoccidioidomycosis"
    },
    {
      "name": "B418",
      "description": "Other forms of paracoccidioidomycosis"
    },
    {
      "name": "B419",
      "description": "Paracoccidioidomycosis, unspecified"
    },
    {
      "name": "B420",
      "description": "Pulmonary sporotrichosis [J99.8*]"
    },
    {
      "name": "B421",
      "description": "Lymphocutaneous sporotrichosis"
    },
    {
      "name": "B427",
      "description": "Disseminated sporotrichosis"
    },
    {
      "name": "B428",
      "description": "Other forms of sporotrichosis"
    },
    {
      "name": "B429",
      "description": "Sporotrichosis, unspecified"
    },
    {
      "name": "B430",
      "description": "Cutaneous chromomycosis"
    },
    {
      "name": "B431",
      "description": "Phaeomycotic brain abscess"
    },
    {
      "name": "B432",
      "description": "Subcutaneous phaeomycotic abscess and cyst"
    },
    {
      "name": "B438",
      "description": "Other forms of chromomycosis"
    },
    {
      "name": "B439",
      "description": "Chromomycosis, unspecified"
    },
    {
      "name": "B440",
      "description": "Invasive pulmonary aspergillosis"
    },
    {
      "name": "B441",
      "description": "Other pulmonary aspergillosis"
    },
    {
      "name": "B442",
      "description": "Tonsillar aspergillosis"
    },
    {
      "name": "B447",
      "description": "Disseminated aspergillosis"
    },
    {
      "name": "B448",
      "description": "Other forms of aspergillosis"
    },
    {
      "name": "B449",
      "description": "Aspergillosis, unspecified"
    },
    {
      "name": "B450",
      "description": "Pulmonary cryptococcosis"
    },
    {
      "name": "B451",
      "description": "Cerebral cryptococcosis"
    },
    {
      "name": "B452",
      "description": "Cutaneous cryptococcosis"
    },
    {
      "name": "B453",
      "description": "Osseous cryptococcosis"
    },
    {
      "name": "B457",
      "description": "Disseminated cryptococcosis"
    },
    {
      "name": "B458",
      "description": "Other forms of cryptococcosis"
    },
    {
      "name": "B459",
      "description": "Cryptococcosis, unspecified"
    },
    {
      "name": "B460",
      "description": "Pulmonary mucormycosis"
    },
    {
      "name": "B461",
      "description": "Rhinocerebral mucormycosis"
    },
    {
      "name": "B462",
      "description": "Gastrointestinal mucormycosis"
    },
    {
      "name": "B463",
      "description": "Cutaneous mucormycosis"
    },
    {
      "name": "B464",
      "description": "Disseminated mucormycosis"
    },
    {
      "name": "B465",
      "description": "Mucormycosis, unspecified"
    },
    {
      "name": "B468",
      "description": "Other zygomycoses"
    },
    {
      "name": "B469",
      "description": "Zygomycosis, unspecified"
    },
    {
      "name": "B470",
      "description": "Eumycetoma"
    },
    {
      "name": "B471",
      "description": "Actinomycetoma"
    },
    {
      "name": "B479",
      "description": "Mycetoma, unspecified"
    },
    {
      "name": "B480",
      "description": "Lobomycosis"
    },
    {
      "name": "B481",
      "description": "Rhinosporidiosis"
    },
    {
      "name": "B482",
      "description": "Allescheriasis"
    },
    {
      "name": "B483",
      "description": "Geotrichosis"
    },
    {
      "name": "B484",
      "description": "Penicillosis"
    },
    {
      "name": "B487",
      "description": "Opportunistic mycoses"
    },
    {
      "name": "B488",
      "description": "Other specified mycoses"
    },
    {
      "name": "B49",
      "description": "Unspecified mycosis"
    },
    {
      "name": "B500",
      "description": "Plasmodium falciparum malaria with cerebral complications"
    },
    {
      "name": "B508",
      "description": "Other severe and complicated Plasmodium falciparum malaria"
    },
    {
      "name": "B509",
      "description": "Plasmodium falciparum malaria, unspecified"
    },
    {
      "name": "B510",
      "description": "Plasmodium vivax malaria with rupture of spleen"
    },
    {
      "name": "B518",
      "description": "Plasmodium vivax malaria with other complications"
    },
    {
      "name": "B519",
      "description": "Plasmodium vivax malaria without complication"
    },
    {
      "name": "B520",
      "description": "Plasmodium malariae malaria with nephropathy"
    },
    {
      "name": "B528",
      "description": "Plasmodium malariae malaria with other complications"
    },
    {
      "name": "B529",
      "description": "Plasmodium malariae malaria without complication"
    },
    {
      "name": "B530",
      "description": "Plasmodium ovale malaria"
    },
    {
      "name": "B531",
      "description": "Malaria due to simian plasmodia"
    },
    {
      "name": "B538",
      "description": "Other parasitologically confirmed malaria, not elsewhere classified"
    },
    {
      "name": "B54",
      "description": "Unspecified malaria"
    },
    {
      "name": "B550",
      "description": "Visceral leishmaniasis"
    },
    {
      "name": "B551",
      "description": "Cutaneous leishamaniasis"
    },
    {
      "name": "B552",
      "description": "Mucocutaneous leishamaniasis"
    },
    {
      "name": "B559",
      "description": "Leishamaniasis, unspecified"
    },
    {
      "name": "B560",
      "description": "Gambiense trypanosomiasis"
    },
    {
      "name": "B561",
      "description": "Rhodesiense trypanosomiasis"
    },
    {
      "name": "B569",
      "description": "African trypanosomiasis, unspecified"
    },
    {
      "name": "B570",
      "description": "Acute Chagas' disease with heart involvement (I41.2*, I98.1*)"
    },
    {
      "name": "B571",
      "description": "Acute Chagas' disease without heart involvement"
    },
    {
      "name": "B572",
      "description": "Chagas' disease (chronic) with heart involvement "
    },
    {
      "name": "B573",
      "description": "Chagas' disease (chronic) with digestive system involvement"
    },
    {
      "name": "B574",
      "description": "Chagas' disease (chronic) with nervous system involvement"
    },
    {
      "name": "B575",
      "description": "Chagas' disease (chronic) with other organ involvement"
    },
    {
      "name": "B580",
      "description": "Toxoplasma oculopathy"
    },
    {
      "name": "B581",
      "description": "Toxoplasma hepatitis (K77.0*)"
    },
    {
      "name": "B582",
      "description": "Toxoplasma meningoencephalitis (G05.2*)"
    },
    {
      "name": "B583",
      "description": "Pulmonary toxoplasmosis (J17.3*)"
    },
    {
      "name": "B588",
      "description": "Toxoplasmosis with other organ involvement"
    },
    {
      "name": "B589",
      "description": "Toxoplasmosis, unspecified"
    },
    {
      "name": "B59",
      "description": "Pneumocystosis (J17.3*)"
    },
    {
      "name": "B600",
      "description": "Babesiosis"
    },
    {
      "name": "B601",
      "description": "Acanthamoebiasis"
    },
    {
      "name": "B602",
      "description": "Naegleriasis"
    },
    {
      "name": "B608",
      "description": "Other specified protozoal diseases"
    },
    {
      "name": "B64",
      "description": "Unspecified protozoal disease"
    },
    {
      "name": "B650",
      "description": "Schistosomiasis due to Schistosoma haematobium [urinary schistosomiasis]"
    },
    {
      "name": "B651",
      "description": "Schistosomiasis due to Schistosoma mansoni [intestinal schistosomiasis]"
    },
    {
      "name": "B652",
      "description": "Schistosomiasis due to Schistosoma japonicum"
    },
    {
      "name": "B653",
      "description": "Cercarial dermatitis"
    },
    {
      "name": "B658",
      "description": "Other schistosomiases"
    },
    {
      "name": "B659",
      "description": "Schistosomiasis, unspecified"
    },
    {
      "name": "B660",
      "description": "Opisthorchiasis"
    },
    {
      "name": "B661",
      "description": "Clonorchiasis"
    },
    {
      "name": "B662",
      "description": "Dicrocoeliasis"
    },
    {
      "name": "B663",
      "description": "Fascioliasis"
    },
    {
      "name": "B664",
      "description": "Paragonimiasis"
    },
    {
      "name": "B665",
      "description": "Fasciolopsiasis"
    },
    {
      "name": "B668",
      "description": "Other specified fluke infections"
    },
    {
      "name": "B669",
      "description": "Fluke infection, unspecified"
    },
    {
      "name": "B670",
      "description": "Echinococcus granulosus infection of liver"
    },
    {
      "name": "B671",
      "description": "Echinococcus granulosus infection of lung"
    },
    {
      "name": "B672",
      "description": "Echinococcus granulosus infection of bone"
    },
    {
      "name": "B673",
      "description": "Echinococcus granulosus infection, other and multiple sites"
    },
    {
      "name": "B674",
      "description": "Echinococcus granulosus infection, unspecified"
    },
    {
      "name": "B675",
      "description": "Echinococcus multilocularis infection of liver"
    },
    {
      "name": "B676",
      "description": "Echinococcus multilocularis infection, other and multiple sites"
    },
    {
      "name": "B677",
      "description": "Echinococcus multilocularis infection, unspecified"
    },
    {
      "name": "B678",
      "description": "Echinococcosis, unspcified, of liver"
    },
    {
      "name": "B679",
      "description": "Echinococcosis, other and unspecified"
    },
    {
      "name": "B680",
      "description": "Taenia solium taeniasis"
    },
    {
      "name": "B681",
      "description": "Taenia saginata taeniasis"
    },
    {
      "name": "B689",
      "description": "Taeniasis, unspecified"
    },
    {
      "name": "B690",
      "description": "Cysticercosis of central nervous system"
    },
    {
      "name": "B691",
      "description": "Cysticercosis of eye"
    },
    {
      "name": "B692",
      "description": "Cysticercosis of skin"
    },
    {
      "name": "B698",
      "description": "Cysticercosis of other sites"
    },
    {
      "name": "B699",
      "description": "Cysticercosis, unspecified"
    },
    {
      "name": "B700",
      "description": "Diphyllobothriasis"
    },
    {
      "name": "B701",
      "description": "Sparganosis"
    },
    {
      "name": "B710",
      "description": "Hymenolepiasis"
    },
    {
      "name": "B711",
      "description": "Dipylidiasis"
    },
    {
      "name": "B718",
      "description": "Other specified cestode infections"
    },
    {
      "name": "B719",
      "description": "Cestode infection, unspecified"
    },
    {
      "name": "B72",
      "description": "Dracunculiasis"
    },
    {
      "name": "B73",
      "description": "Onchocerciasis"
    },
    {
      "name": "B740",
      "description": "Filariasis due to Wuchereria bancrofti"
    },
    {
      "name": "B741",
      "description": "Filariasis due to Brugia malayi"
    },
    {
      "name": "B742",
      "description": "Filariasis due to Brugia timori"
    },
    {
      "name": "B743",
      "description": "Loiasis"
    },
    {
      "name": "B744",
      "description": "Mansonelliasis"
    },
    {
      "name": "B748",
      "description": "Other filariases"
    },
    {
      "name": "B749",
      "description": "Filariasis, unspecified"
    },
    {
      "name": "B75",
      "description": "Trichinellosis"
    },
    {
      "name": "B760",
      "description": "Ancylostomiasis"
    },
    {
      "name": "B761",
      "description": "Necatoriasis"
    },
    {
      "name": "B768",
      "description": "Other hookworm diseases"
    },
    {
      "name": "B769",
      "description": "Hookworm diseases, unspcified"
    },
    {
      "name": "B770",
      "description": "Ascariasis with intestinal complications"
    },
    {
      "name": "B778",
      "description": "Ascariasis with other complications"
    },
    {
      "name": "B779",
      "description": "Ascariasis unspecified"
    },
    {
      "name": "B780",
      "description": "Intestinal strongyloidiasis"
    },
    {
      "name": "B781",
      "description": "Cutaneous strongyloidiasis"
    },
    {
      "name": "B787",
      "description": "Disseminated strongyloidiasis"
    },
    {
      "name": "B788",
      "description": "Other strongyloidiasis"
    },
    {
      "name": "B789",
      "description": "Strongyloidiasis, unspecified"
    },
    {
      "name": "B79",
      "description": "Trichuriasis"
    },
    {
      "name": "B80",
      "description": "Enterobiasis"
    },
    {
      "name": "B810",
      "description": "Anisakiasis"
    },
    {
      "name": "B811",
      "description": "Intestinal capillariasis"
    },
    {
      "name": "B812",
      "description": "Trichostrongyliasis"
    },
    {
      "name": "B813",
      "description": "Intestinal angiostrongyliasis"
    },
    {
      "name": "B814",
      "description": "Mixed intestinal helminthiases"
    },
    {
      "name": "B818",
      "description": "Other specified intestinal helminthiases"
    },
    {
      "name": "B820",
      "description": "Intestinal helminthiasis, unspecified"
    },
    {
      "name": "B829",
      "description": "Intestinal parasitism, unspecified"
    },
    {
      "name": "B830",
      "description": "Visceral larva migrans"
    },
    {
      "name": "B8310",
      "description": "Gnathostomiasis of central nervous system"
    },
    {
      "name": "B8311",
      "description": "Gnathostomiasis of eye"
    },
    {
      "name": "B8312",
      "description": "Gnathostomiasis of skin"
    },
    {
      "name": "B8318",
      "description": "Gnathostomiasis of other sites"
    },
    {
      "name": "B8319",
      "description": "Gnathostomiasis, unspecified"
    },
    {
      "name": "B832",
      "description": "Angiostrongyliasis due to Parastrongylus cantonensis"
    },
    {
      "name": "B833",
      "description": "Syngamiasis"
    },
    {
      "name": "B834",
      "description": "Internal hirudiniasis"
    },
    {
      "name": "B838",
      "description": "Other specified helminthiases"
    },
    {
      "name": "B839",
      "description": "Helminthiasis, unspecified"
    },
    {
      "name": "B850",
      "description": "Pediculosis due to Pediculus humanus capitis"
    },
    {
      "name": "B851",
      "description": "Pediculosis due to Pediculus humanus corporis"
    },
    {
      "name": "B852",
      "description": "Pediculosis, unspecified"
    },
    {
      "name": "B853",
      "description": "Phthiriasis"
    },
    {
      "name": "B854",
      "description": "Mixed pediculosis and phthiriasis"
    },
    {
      "name": "B860",
      "description": "Scabies due to Sarcoptes scabiei"
    },
    {
      "name": "B868",
      "description": "Other scabies"
    },
    {
      "name": "B869",
      "description": "Scabies, unspecified"
    },
    {
      "name": "B870",
      "description": "Cutaneous myiasis"
    },
    {
      "name": "B871",
      "description": "Wound myiasis"
    },
    {
      "name": "B872",
      "description": "Ocular myiasis"
    },
    {
      "name": "B873",
      "description": "Nasopharyngeal myiasis"
    },
    {
      "name": "B874",
      "description": "Aural myiasis"
    },
    {
      "name": "B878",
      "description": "Myiasis of other sites"
    },
    {
      "name": "B879",
      "description": "Myiasis, unspecified"
    },
    {
      "name": "B880",
      "description": "Other acariasis"
    },
    {
      "name": "B881",
      "description": "Tungiasis [sandflea infestation]"
    },
    {
      "name": "B882",
      "description": "Other arthropod infestations"
    },
    {
      "name": "B883",
      "description": "External hirudiniasis"
    },
    {
      "name": "B888",
      "description": "Other specified infestations"
    },
    {
      "name": "B889",
      "description": "Infestation, unspecified"
    },
    {
      "name": "B89",
      "description": "Unspecified parasitic disease"
    },
    {
      "name": "B900",
      "description": "Sequelae of central nervous system tuberculosis"
    },
    {
      "name": "B901",
      "description": "Sequelae of genitourinary tuberculosis"
    },
    {
      "name": "B902",
      "description": "Sequelae of tuberculosis of bones and joints"
    },
    {
      "name": "B908",
      "description": "Sequelae of tuberculosis of other organs"
    },
    {
      "name": "B909",
      "description": "Sequelae of respiratory and unspecified tuberculosis"
    },
    {
      "name": "B91",
      "description": "Sequelae of poliomyelitis"
    },
    {
      "name": "B92",
      "description": "Sequelae of leprosy"
    },
    {
      "name": "B940",
      "description": "Sequelae of trachoma"
    },
    {
      "name": "B941",
      "description": "Sequelae of viral encephalitis"
    },
    {
      "name": "B942",
      "description": "Sequelae of viral hepatitis"
    },
    {
      "name": "B948",
      "description": "Sequelae of other spcified infectious and parasitic diseases"
    },
    {
      "name": "B949",
      "description": "Sequelae of unspecified infectious or parasitic disease"
    },
    {
      "name": "B950",
      "description": "Streptococcus, group A, as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B951",
      "description": "Streptococcus, group B, as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B952",
      "description": "Streptococcus, group D and enterococcus, as the cause of diseases classified to other chapters"
    },
    {
      "name": "B953",
      "description": "Streptococcus pneumoniae as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B954",
      "description": "Other streptococcus as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B955",
      "description": "Unspecified streptococcus as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B956",
      "description": "Staphylococcus aureus as the cause of diseases  classified to other chapters"
    },
    {
      "name": "B957",
      "description": "Other staphylococcus as the cause of diseases classified to other chapters"
    },
    {
      "name": "B958",
      "description": "Unspecified staphylococcus as the cause of diseases classified to other chapters"
    },
    {
      "name": "B960",
      "description": "Mycoplasma pneumoniae [M. pneumoniae] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B961",
      "description": "Klebsiella pneumoniae [K. pneumoniae] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B962",
      "description": "Escherichia coli [E.coli] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B963",
      "description": "Haemophilus influenzae [H. influenzae] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B964",
      "description": "Proteus (mirabilis)(morganii) as the cause of diseases classified to other chapters"
    },
    {
      "name": "B965",
      "description": "Pseudomonas (aeruginosa) as the cause of diseases classified to other chapters"
    },
    {
      "name": "B966",
      "description": "Bacillus fragilis [B.fragilis] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B967",
      "description": "Clostridium perfringens [C. perfringens] as the cause of diseases classified to other chapters"
    },
    {
      "name": "B968",
      "description": "Other specified bacterial agents as the cause of diseases classified to other chapters"
    },
    {
      "name": "B970",
      "description": "Adenovirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B971",
      "description": "Enterovirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B972",
      "description": "Coronavirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B973",
      "description": "Retrovirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B974",
      "description": "Respiratory syncytial virus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B975",
      "description": "Reovirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B976",
      "description": "Parvovirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B977",
      "description": "Papillomavirus as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B978",
      "description": "Other viral agents as  the cause of diseases classified to other chapters"
    },
    {
      "name": "B980",
      "description": "Helicobacter pylori [H. pylori] as the cause of diseases classified to other chapters "
    },
    {
      "name": "B981",
      "description": "Vibrio vulnificus as the cause of diseases classified to other chapters "
    },
    {
      "name": "B99",
      "description": "Other and unspecified infectious diseases"
    },
    {
      "name": "C000",
      "description": "External upper lip "
    },
    {
      "name": "C001",
      "description": "External lower lip  "
    },
    {
      "name": "C002",
      "description": "External lip, unspecified "
    },
    {
      "name": "C003",
      "description": "Upper lip, inner aspect "
    },
    {
      "name": "C004",
      "description": "Lower lip, inner aspect"
    },
    {
      "name": "C005",
      "description": "Lip, unspecified, inner aspect "
    },
    {
      "name": "C006",
      "description": "Commissure of lip"
    },
    {
      "name": "C008",
      "description": "Overlapping lesion of lip "
    },
    {
      "name": "C009",
      "description": "Lip, unspecified"
    },
    {
      "name": "C01",
      "description": "Malignant neoplasm of base of tongue"
    },
    {
      "name": "C020",
      "description": "Dorsal surface of tongue "
    },
    {
      "name": "C0210",
      "description": "Tip of tongue "
    },
    {
      "name": "C0211",
      "description": "Lateral border of tongue"
    },
    {
      "name": "C0219",
      "description": "Border of tongue, unspecified"
    },
    {
      "name": "C022",
      "description": "Ventral surface of tongue  "
    },
    {
      "name": "C023",
      "description": "Anterior two-thirds of tongue, part unspecified "
    },
    {
      "name": "C024",
      "description": "Lingual tonsil  "
    },
    {
      "name": "C028",
      "description": "Overlapping lesion of tongue "
    },
    {
      "name": "C029",
      "description": "Tongue, unspecified"
    },
    {
      "name": "C030",
      "description": "Upper gum"
    },
    {
      "name": "C031",
      "description": "Lower gum"
    },
    {
      "name": "C039",
      "description": "Gum, unspecified"
    },
    {
      "name": "C040",
      "description": "Anterior floor of mouth"
    },
    {
      "name": "C041",
      "description": "Lateral floor of mouth "
    },
    {
      "name": "C048",
      "description": "Overlapping lesion of floor of mouth "
    },
    {
      "name": "C049",
      "description": "Floor of mouth, unspecified"
    },
    {
      "name": "C050",
      "description": "Hard palate "
    },
    {
      "name": "C051",
      "description": "Soft palate "
    },
    {
      "name": "C052",
      "description": "Uvula "
    },
    {
      "name": "C058",
      "description": "Overlapping lesion of palate "
    },
    {
      "name": "C059",
      "description": "Palate, unspecified"
    },
    {
      "name": "C060",
      "description": "Cheek mucosa"
    },
    {
      "name": "C0610",
      "description": "Upper  labial sulcus "
    },
    {
      "name": "C0611",
      "description": "Upper buccal  sulcus "
    },
    {
      "name": "C0612",
      "description": "Lower labial  sulcus "
    },
    {
      "name": "C0613",
      "description": "Lower buccal  sulcus "
    },
    {
      "name": "C0614",
      "description": "Upper sulcus, unspecified "
    },
    {
      "name": "C0615",
      "description": "Lower sulcus, unspecified"
    },
    {
      "name": "C0619",
      "description": "Vestibule of mouth, unspecified "
    },
    {
      "name": "C0620",
      "description": "Maxillary tuberosity "
    },
    {
      "name": "C0621",
      "description": "Mandibular retromolar area"
    },
    {
      "name": "C0629",
      "description": "Retromolar area, unspecified "
    },
    {
      "name": "C068",
      "description": "Overlapping lesion of other and unspecified parts of mouth "
    },
    {
      "name": "C069",
      "description": "Mouth, unspecified"
    },
    {
      "name": "C07",
      "description": "Malignant neoplasm of parotid gland"
    },
    {
      "name": "C080",
      "description": "Submandibular gland"
    },
    {
      "name": "C081",
      "description": "Sublingual gland "
    },
    {
      "name": "C088",
      "description": "Overlapping lesion of major salivary glands "
    },
    {
      "name": "C089",
      "description": "Major salivary gland, unspecified"
    },
    {
      "name": "C090",
      "description": "Tonsillar fossa "
    },
    {
      "name": "C091",
      "description": "Tonsillar pillar (anterior) (posterior) "
    },
    {
      "name": "C098",
      "description": "Overlapping lesion of tonsil "
    },
    {
      "name": "C099",
      "description": "Tonsil, unspecified"
    },
    {
      "name": "C100",
      "description": "Vallecula "
    },
    {
      "name": "C101",
      "description": "Anterior surface of epiglottis "
    },
    {
      "name": "C102",
      "description": "Lateral wall of oropharynx "
    },
    {
      "name": "C103",
      "description": "Posterior wall of oropharynx "
    },
    {
      "name": "C104",
      "description": "Branchial cleft "
    },
    {
      "name": "C108",
      "description": "Overlapping lesion of of oropharynx "
    },
    {
      "name": "C109",
      "description": "Oropharynx, unspecified"
    },
    {
      "name": "C110",
      "description": "Superior wall of nasopharynx "
    },
    {
      "name": "C111",
      "description": "Posterior wall of nasopharynx "
    },
    {
      "name": "C112",
      "description": "Lateral wall of nasopharynx "
    },
    {
      "name": "C113",
      "description": "Anterior wall of nasopharynx "
    },
    {
      "name": "C118",
      "description": "Overlapping lesion of nasopharynx "
    },
    {
      "name": "C119",
      "description": "Nasopharynx, unspecified"
    },
    {
      "name": "C120",
      "description": "Lateral wall"
    },
    {
      "name": "C121",
      "description": "Medial wall"
    },
    {
      "name": "C122",
      "description": "Apex"
    },
    {
      "name": "C128",
      "description": "Overlapping lesion of pyriform sinus"
    },
    {
      "name": "C129",
      "description": "Pyriform sinus, unspecified"
    },
    {
      "name": "C130",
      "description": "Postcricoid region "
    },
    {
      "name": "C131",
      "description": "Aryepiglottic fold, hypopharyngeal aspect"
    },
    {
      "name": "C132",
      "description": "Posterior wall of hypopharynx "
    },
    {
      "name": "C138",
      "description": "Overlapping lesion of hypopharynx "
    },
    {
      "name": "C139",
      "description": "Hypopharynx, unspecified"
    },
    {
      "name": "C140",
      "description": "Pharynx, unspecified"
    },
    {
      "name": "C142",
      "description": "Waldeyer's ring "
    },
    {
      "name": "C148",
      "description": "Overlapping lesion of lip, oral cavity and pharynx "
    },
    {
      "name": "C150",
      "description": "Cervical part of oesophagus "
    },
    {
      "name": "C151",
      "description": "Thoracic part of oesophagus "
    },
    {
      "name": "C152",
      "description": "Abdominal  part of oesophagus"
    },
    {
      "name": "C153",
      "description": "Upper third of oesophagus "
    },
    {
      "name": "C154",
      "description": "Middle third of oesophagus "
    },
    {
      "name": "C155",
      "description": "Lower third  of oesophagus "
    },
    {
      "name": "C158",
      "description": "Overlapping lesion  of oesophagus "
    },
    {
      "name": "C159",
      "description": "Oesophagus, unspecified"
    },
    {
      "name": "C160",
      "description": "Cardia "
    },
    {
      "name": "C161",
      "description": "Fundus of stomach "
    },
    {
      "name": "C162",
      "description": "Body of stomach "
    },
    {
      "name": "C163",
      "description": "Pyloric antrum "
    },
    {
      "name": "C164",
      "description": "Pylorus "
    },
    {
      "name": "C165",
      "description": "Lesser curvature of stomach, unspecified"
    },
    {
      "name": "C166",
      "description": "Greater curvature of stomach, unspecified"
    },
    {
      "name": "C168",
      "description": "Overlapping lesion of stomach "
    },
    {
      "name": "C169",
      "description": "Stomach, unspecified"
    },
    {
      "name": "C170",
      "description": "Duodenum "
    },
    {
      "name": "C171",
      "description": "Jejunum "
    },
    {
      "name": "C172",
      "description": "IIeum "
    },
    {
      "name": "C173",
      "description": "Meckel's diverticulum"
    },
    {
      "name": "C178",
      "description": "Overlapping lesion of small intestine"
    },
    {
      "name": "C179",
      "description": "Small intestine unspecified"
    },
    {
      "name": "C180",
      "description": "Caecum "
    },
    {
      "name": "C181",
      "description": "Appendix "
    },
    {
      "name": "C182",
      "description": "Ascending colon "
    },
    {
      "name": "C183",
      "description": "Hepatic flexure "
    },
    {
      "name": "C184",
      "description": "Transverse colon "
    },
    {
      "name": "C185",
      "description": "Splenic flexure"
    },
    {
      "name": "C186",
      "description": "Descending colon "
    },
    {
      "name": "C187",
      "description": "Sigmoid colon "
    },
    {
      "name": "C188",
      "description": "Overlapping lesion of colon "
    },
    {
      "name": "C189",
      "description": "Colon, unspecified"
    },
    {
      "name": "C19",
      "description": "Malignant neoplasm of rectosigmoid junction"
    },
    {
      "name": "C20",
      "description": "Malignant neoplasm of rectum"
    },
    {
      "name": "C210",
      "description": "Anus, unspecified"
    },
    {
      "name": "C211",
      "description": "Anal canal"
    },
    {
      "name": "C212",
      "description": "Cloacogenic zone "
    },
    {
      "name": "C218",
      "description": "Overlapping lesion of rectum, anus and anal canal "
    },
    {
      "name": "C220",
      "description": "Liver cell carcinoma"
    },
    {
      "name": "C221",
      "description": "Intrahepatic bile duct carcinoma"
    },
    {
      "name": "C222",
      "description": "Hepatoblastoma"
    },
    {
      "name": "C223",
      "description": "Angiosarcoma of liver"
    },
    {
      "name": "C224",
      "description": "Other sarcomas of liver"
    },
    {
      "name": "C227",
      "description": "Other specified carcinomas of liver"
    },
    {
      "name": "C229",
      "description": "Liver,  unspecified"
    },
    {
      "name": "C23",
      "description": "Malignant neoplasm of gallbladder"
    },
    {
      "name": "C240",
      "description": "Extrahepatic bile duct "
    },
    {
      "name": "C241",
      "description": "Ampulla of Vater "
    },
    {
      "name": "C248",
      "description": "Overlapping lesion of biliary tract "
    },
    {
      "name": "C249",
      "description": "Biliary tract,  unspecified"
    },
    {
      "name": "C250",
      "description": "Head of  pancreas "
    },
    {
      "name": "C251",
      "description": "Body of  pancreas "
    },
    {
      "name": "C252",
      "description": "Tail of  pancreas "
    },
    {
      "name": "C253",
      "description": "Pancreatic duct "
    },
    {
      "name": "C254",
      "description": "Endocrine  pancreas "
    },
    {
      "name": "C257",
      "description": "Other parts of  pancreas"
    },
    {
      "name": "C258",
      "description": "Overlapping lesion of  pancreas "
    },
    {
      "name": "C259",
      "description": "Pancreas, unspecified"
    },
    {
      "name": "C260",
      "description": "Intestinal tract part, unspecified"
    },
    {
      "name": "C261",
      "description": "Spleen "
    },
    {
      "name": "C268",
      "description": "Overlapping lesion of digestive system "
    },
    {
      "name": "C269",
      "description": "Ill-defined sites within the digestive system "
    },
    {
      "name": "C300",
      "description": "Nasal cavity "
    },
    {
      "name": "C301",
      "description": "Middle ear "
    },
    {
      "name": "C310",
      "description": "Maxillary sinus"
    },
    {
      "name": "C311",
      "description": "Ethmoidal sinus "
    },
    {
      "name": "C312",
      "description": "Frontal sinus "
    },
    {
      "name": "C313",
      "description": "Sphenoidal sinus"
    },
    {
      "name": "C318",
      "description": "Overlapping lesion of accessory sinuses "
    },
    {
      "name": "C319",
      "description": "Accessory sinus unspecified"
    },
    {
      "name": "C320",
      "description": "Glottis "
    },
    {
      "name": "C321",
      "description": "Supraglottis "
    },
    {
      "name": "C322",
      "description": "Subglottis "
    },
    {
      "name": "C323",
      "description": "Laryngeal cartilage "
    },
    {
      "name": "C328",
      "description": "Overalapping lesion of larynx "
    },
    {
      "name": "C329",
      "description": "Larynx, unspecified"
    },
    {
      "name": "C33",
      "description": "Malignant neoplasm of trachea"
    },
    {
      "name": "C340",
      "description": "Main bronchus "
    },
    {
      "name": "C341",
      "description": "Upper lobe,  bronchus or lung"
    },
    {
      "name": "C342",
      "description": "Middle lobe,  bronchus or lung "
    },
    {
      "name": "C343",
      "description": "Lower lobe, bronchus or lung "
    },
    {
      "name": "C348",
      "description": "Overlapping lesion of  bronchus and lung"
    },
    {
      "name": "C349",
      "description": "Bronchus or lung, unspecified"
    },
    {
      "name": "C37",
      "description": "Malignant neoplasm of thymus"
    },
    {
      "name": "C380",
      "description": "Heart "
    },
    {
      "name": "C381",
      "description": "Anterior mediastinum "
    },
    {
      "name": "C382",
      "description": "Posterior mediastinum "
    },
    {
      "name": "C383",
      "description": "Mediastinum,  part unspecified"
    },
    {
      "name": "C384",
      "description": "Pleura "
    },
    {
      "name": "C388",
      "description": "Overlapping lesion of heart, mediastinum and pleura"
    },
    {
      "name": "C390",
      "description": "Upper respiratory tract, part unspecified"
    },
    {
      "name": "C398",
      "description": "Overlapping lesion of respiratory and intrathoracic organs "
    },
    {
      "name": "C399",
      "description": "Ill-defined sites within the respiratory system "
    },
    {
      "name": "C400",
      "description": "Scapula and long bones of upper limb "
    },
    {
      "name": "C401",
      "description": "Short bones of upper limb "
    },
    {
      "name": "C402",
      "description": "Long bones of lower limb "
    },
    {
      "name": "C403",
      "description": "Short bones of lower limb"
    },
    {
      "name": "C408",
      "description": "Overlapping lesion of bone and articular cartilage of limbs "
    },
    {
      "name": "C409",
      "description": "Bone and articular cartilage of limb unspecified"
    },
    {
      "name": "C410",
      "description": "Bones of skull and face"
    },
    {
      "name": "C411",
      "description": "Mandible"
    },
    {
      "name": "C412",
      "description": "Vertebral column "
    },
    {
      "name": "C413",
      "description": "Ribs, sternum and clavicle "
    },
    {
      "name": "C414",
      "description": "Pelvic bones, sacrum and coccyx "
    },
    {
      "name": "C418",
      "description": "Overlapping lesion of bone and articular cartilage "
    },
    {
      "name": "C419",
      "description": "Bone and articular cartilage, unspecified"
    },
    {
      "name": "C430",
      "description": "Malignant melanoma of lip"
    },
    {
      "name": "C431",
      "description": "Malignant melanoma of eyelid, including canthus"
    },
    {
      "name": "C432",
      "description": "Malignant melanoma of ear and external auricular canal"
    },
    {
      "name": "C433",
      "description": "Malignant melanoma of other and unspecified parts of face"
    },
    {
      "name": "C434",
      "description": "Malignant melanoma of scalp and neck"
    },
    {
      "name": "C435",
      "description": "Malignant melanoma of trunk"
    },
    {
      "name": "C436",
      "description": "Malignant melanoma of upper limb, inculuding shoulder"
    },
    {
      "name": "C437",
      "description": "Malignant melanoma of lower limb, including hip"
    },
    {
      "name": "C438",
      "description": "Overlapping malignant melanoma of skin"
    },
    {
      "name": "C439",
      "description": "Malignant melanoma of skin , unspecified"
    },
    {
      "name": "C440",
      "description": "Skin of lip "
    },
    {
      "name": "C441",
      "description": "Skin of eyelid, including canthus "
    },
    {
      "name": "C442",
      "description": "Skin of ear and external auricular canal "
    },
    {
      "name": "C443",
      "description": "Skin of other and unspecified parts of face "
    },
    {
      "name": "C444",
      "description": "Skin of scalp and neck "
    },
    {
      "name": "C445",
      "description": "Skin of trunk "
    },
    {
      "name": "C446",
      "description": "Skin of upper limb, including shoulder"
    },
    {
      "name": "C447",
      "description": "Skin of lower limb, including hip "
    },
    {
      "name": "C448",
      "description": "Overlapping lesion of skin "
    },
    {
      "name": "C449",
      "description": "Malignant neoplasm of skin, unspecified"
    },
    {
      "name": "C450",
      "description": "Mesothelioma of pleura"
    },
    {
      "name": "C451",
      "description": "Mesothelioma of peritoneum"
    },
    {
      "name": "C452",
      "description": "Mesothelioma of pericardium"
    },
    {
      "name": "C457",
      "description": "Mesothelioma of other sites"
    },
    {
      "name": "C459",
      "description": "Mesothelioma, unspecified"
    },
    {
      "name": "C460",
      "description": "Kaposi's sarcoma of skin"
    },
    {
      "name": "C461",
      "description": "Kaposi's sarcoma of soft tissue"
    },
    {
      "name": "C462",
      "description": "Kaposi's sarcoma of palate"
    },
    {
      "name": "C463",
      "description": "Kaposi's sarcoma of lymph nodes"
    },
    {
      "name": "C467",
      "description": "Kaposi's sarcoma of other sites"
    },
    {
      "name": "C468",
      "description": "Kaposi's sarcoma of multiple organs"
    },
    {
      "name": "C469",
      "description": "Kaposi's sarcoma, unspecified"
    },
    {
      "name": "C470",
      "description": "Peripheral nerves of head, face and neck "
    },
    {
      "name": "C471",
      "description": "Peripheral nerves of upper limb,  including shoulder"
    },
    {
      "name": "C472",
      "description": "Peripheral nerves of of lower limb, inculiding hip "
    },
    {
      "name": "C473",
      "description": "Peripheral nerves of of thorax "
    },
    {
      "name": "C474",
      "description": "Peripheral nerves of of abdomen "
    },
    {
      "name": "C475",
      "description": "Peripheral nerves of pelvis "
    },
    {
      "name": "C476",
      "description": "Peripheral nerves of trunk, unspecified"
    },
    {
      "name": "C478",
      "description": "Overlapping lesion of peripheral nerves and autonomic nervous system "
    },
    {
      "name": "C479",
      "description": "Peripheral nerves and autonomic nervous system unspecified"
    },
    {
      "name": "C480",
      "description": "Retroperitoneum "
    },
    {
      "name": "C481",
      "description": "Specified parts of peritoneum "
    },
    {
      "name": "C482",
      "description": "Peritoneum, unspecified"
    },
    {
      "name": "C488",
      "description": "Overlapping lesion of retroperitoneum and peritoneum  "
    },
    {
      "name": "C490",
      "description": "Connective and soft tissue of head, face and neck "
    },
    {
      "name": "C491",
      "description": "Connective and soft tissue of upper limb, including shoulder "
    },
    {
      "name": "C492",
      "description": "Connective and soft tissue of lower limb, including hip "
    },
    {
      "name": "C493",
      "description": "Connective and soft tissue of thorax  "
    },
    {
      "name": "C494",
      "description": "Connective and soft tissue of abdomen "
    },
    {
      "name": "C495",
      "description": "Connective and soft tissue of pelvis "
    },
    {
      "name": "C496",
      "description": "Connective and soft tissue of trunk, unspecified"
    },
    {
      "name": "C498",
      "description": "Overlapping lesion of connective and soft tissue  "
    },
    {
      "name": "C499",
      "description": "Connective and soft tissue, unspecified"
    },
    {
      "name": "C500",
      "description": "Nipple and areola "
    },
    {
      "name": "C501",
      "description": "Central portion of breast "
    },
    {
      "name": "C502",
      "description": "Upper-inner quadrant of breast "
    },
    {
      "name": "C503",
      "description": "Lower-inner quadrant of breast "
    },
    {
      "name": "C504",
      "description": "Upper-outer quadrant of breast"
    },
    {
      "name": "C505",
      "description": "Lower-outer quadrant of breast "
    },
    {
      "name": "C506",
      "description": "Axillary tail of breast "
    },
    {
      "name": "C508",
      "description": "Overlapping lesion of breast "
    },
    {
      "name": "C509",
      "description": "Breast, unspecified"
    },
    {
      "name": "C510",
      "description": "Labium majus"
    },
    {
      "name": "C511",
      "description": "Labium minus"
    },
    {
      "name": "C512",
      "description": "Clitoris "
    },
    {
      "name": "C518",
      "description": "Overlapping lesion of vulva"
    },
    {
      "name": "C519",
      "description": "Vulva, unspecified"
    },
    {
      "name": "C52",
      "description": "Malignant neoplasm of vagina"
    },
    {
      "name": "C530",
      "description": "Endocervix "
    },
    {
      "name": "C531",
      "description": "Exocervix"
    },
    {
      "name": "C538",
      "description": "Overlapping lesion of cervix uteri "
    },
    {
      "name": "C539",
      "description": "Cervix uteri, unspecified"
    },
    {
      "name": "C540",
      "description": "Isthmus uteri "
    },
    {
      "name": "C541",
      "description": "Endometrium "
    },
    {
      "name": "C542",
      "description": "Myometrium "
    },
    {
      "name": "C543",
      "description": "Fundus uteri"
    },
    {
      "name": "C548",
      "description": "Overlapping lesion of corpus uteri"
    },
    {
      "name": "C549",
      "description": "Corpus uteri, unspecified"
    },
    {
      "name": "C55",
      "description": "Malignant neoplasm of uterus, part unspecified"
    },
    {
      "name": "C56",
      "description": "Malignant neoplasm of ovary"
    },
    {
      "name": "C570",
      "description": "Fallopian tube "
    },
    {
      "name": "C571",
      "description": "Broad ligament "
    },
    {
      "name": "C572",
      "description": "Round ligament "
    },
    {
      "name": "C573",
      "description": "Parametrium "
    },
    {
      "name": "C574",
      "description": "Uterine adnexa, unspecified"
    },
    {
      "name": "C577",
      "description": "Other specified female genital organs "
    },
    {
      "name": "C578",
      "description": "Overlapping lesion of female genital organs"
    },
    {
      "name": "C579",
      "description": "Female genital organ, unspecified"
    },
    {
      "name": "C58",
      "description": "Malignant neoplasm of placenta"
    },
    {
      "name": "C600",
      "description": "Prepuce "
    },
    {
      "name": "C601",
      "description": "Glans penis "
    },
    {
      "name": "C602",
      "description": "Body of penis"
    },
    {
      "name": "C608",
      "description": "Overlapping lesion of penis "
    },
    {
      "name": "C609",
      "description": "Penis unspecified"
    },
    {
      "name": "C61",
      "description": "Malignant neoplasm of prostate"
    },
    {
      "name": "C620",
      "description": "Undescended testis "
    },
    {
      "name": "C621",
      "description": "Descended testis "
    },
    {
      "name": "C629",
      "description": "Testis, unspecified"
    },
    {
      "name": "C630",
      "description": "Epididymis "
    },
    {
      "name": "C631",
      "description": "Spermatic cord "
    },
    {
      "name": "C632",
      "description": "Scrotum "
    },
    {
      "name": "C637",
      "description": "Other specified male genital organs"
    },
    {
      "name": "C638",
      "description": "Overlapping lesion of male genital organs"
    },
    {
      "name": "C639",
      "description": "Male genital organ, unspecified"
    },
    {
      "name": "C64",
      "description": "Malignant neoplasm of kidney,except renal pelvis"
    },
    {
      "name": "C65",
      "description": "Malignant neoplasm of renal pelvis"
    },
    {
      "name": "C66",
      "description": "Malignant neoplasm of ureter"
    },
    {
      "name": "C670",
      "description": "Trigone of bladder "
    },
    {
      "name": "C671",
      "description": "Dome of bladder "
    },
    {
      "name": "C672",
      "description": "Lateral wall of bladder "
    },
    {
      "name": "C673",
      "description": "Anterior wall of bladder"
    },
    {
      "name": "C674",
      "description": "Posterior wall of bladder "
    },
    {
      "name": "C675",
      "description": "Bladder neck"
    },
    {
      "name": "C676",
      "description": "Ureteric orifice "
    },
    {
      "name": "C677",
      "description": "Urachus "
    },
    {
      "name": "C678",
      "description": "Overlapping lesion of bladder"
    },
    {
      "name": "C679",
      "description": "Bladder, unspecified"
    },
    {
      "name": "C680",
      "description": "Urethra"
    },
    {
      "name": "C681",
      "description": "Paraurethral gland "
    },
    {
      "name": "C688",
      "description": "Overlapping lesion of urinary organs "
    },
    {
      "name": "C689",
      "description": "Urinary organ, unspecified"
    },
    {
      "name": "C690",
      "description": "Conjunctiva "
    },
    {
      "name": "C691",
      "description": "Cornea "
    },
    {
      "name": "C692",
      "description": "Retina "
    },
    {
      "name": "C693",
      "description": "Choroid "
    },
    {
      "name": "C694",
      "description": "Ciliary body "
    },
    {
      "name": "C695",
      "description": "Lacrimal gland and duct "
    },
    {
      "name": "C696",
      "description": "Orbit "
    },
    {
      "name": "C698",
      "description": "Overlapping lesion of eye and adnexa "
    },
    {
      "name": "C699",
      "description": "Eye, unspecified"
    },
    {
      "name": "C700",
      "description": "Cerebral meninges "
    },
    {
      "name": "C701",
      "description": "Spinal meninges "
    },
    {
      "name": "C709",
      "description": "Meninges, unspecified"
    },
    {
      "name": "C710",
      "description": "Cerebrum, except lobes and ventricles"
    },
    {
      "name": "C711",
      "description": "Frontal lobe "
    },
    {
      "name": "C712",
      "description": "Temporal lobe "
    },
    {
      "name": "C713",
      "description": "Parietal lobe"
    },
    {
      "name": "C714",
      "description": "Occipital lobe "
    },
    {
      "name": "C715",
      "description": "Cerebral ventricle"
    },
    {
      "name": "C716",
      "description": "Cerebellum "
    },
    {
      "name": "C717",
      "description": "Brain stem "
    },
    {
      "name": "C718",
      "description": "Overlapping lesion of brain "
    },
    {
      "name": "C719",
      "description": "Brain  unspecified"
    },
    {
      "name": "C720",
      "description": "Spinal cord"
    },
    {
      "name": "C721",
      "description": "Cauda equina "
    },
    {
      "name": "C722",
      "description": "Olfactory nerve "
    },
    {
      "name": "C723",
      "description": "Optic nerve"
    },
    {
      "name": "C724",
      "description": "Acoustic nerve"
    },
    {
      "name": "C725",
      "description": "Other and unspecified cranial nerves "
    },
    {
      "name": "C728",
      "description": "Overlapping lesion of brain and other parts of central nervous system"
    },
    {
      "name": "C729",
      "description": "Central nervous system, unspecified"
    },
    {
      "name": "C73",
      "description": "Malignant neoplasm of thyroid gland"
    },
    {
      "name": "C740",
      "description": "Cortex of adrenal gland"
    },
    {
      "name": "C741",
      "description": "Medulla of adrenal gland "
    },
    {
      "name": "C749",
      "description": "Adrenal gland  unspecified"
    },
    {
      "name": "C750",
      "description": "Parathyroid gland "
    },
    {
      "name": "C751",
      "description": "Pituitary gland"
    },
    {
      "name": "C752",
      "description": "Craniopharyngeal duct "
    },
    {
      "name": "C753",
      "description": "Pineal gland "
    },
    {
      "name": "C754",
      "description": "Carotid body "
    },
    {
      "name": "C755",
      "description": "Aortic body and other paraganglia "
    },
    {
      "name": "C758",
      "description": "Pluriglandular  involvement unspecified"
    },
    {
      "name": "C759",
      "description": "Endocrine gland  unspecified"
    },
    {
      "name": "C760",
      "description": "Head, face and neck "
    },
    {
      "name": "C761",
      "description": "Thorax "
    },
    {
      "name": "C762",
      "description": "Abdomen "
    },
    {
      "name": "C763",
      "description": "Pelvis "
    },
    {
      "name": "C764",
      "description": "Upper limb "
    },
    {
      "name": "C765",
      "description": "Lower limb "
    },
    {
      "name": "C767",
      "description": "Other iII-defined sites "
    },
    {
      "name": "C768",
      "description": "Overlapping lesion of other and iII-defined sites "
    },
    {
      "name": "C770",
      "description": "Lymph nodes of head, face and neck"
    },
    {
      "name": "C771",
      "description": "Intrathoracic lymph nodes "
    },
    {
      "name": "C772",
      "description": "Intra-abdominal lymph nodes "
    },
    {
      "name": "C773",
      "description": "Axillary and upper limb lymph nodes"
    },
    {
      "name": "C774",
      "description": "Inguinal and lower limb lymph nodes"
    },
    {
      "name": "C775",
      "description": "Intrapelvic lymph nodes "
    },
    {
      "name": "C778",
      "description": "Lymph nodes of multiple regions "
    },
    {
      "name": "C779",
      "description": "Lymph nodes  unspecified"
    },
    {
      "name": "C780",
      "description": "Secondary malignant neoplasm of lung"
    },
    {
      "name": "C781",
      "description": "Secondary malignant neoplasm of mediastinum"
    },
    {
      "name": "C782",
      "description": "Secondary malignant neoplasm of pleura"
    },
    {
      "name": "C783",
      "description": "Secondary malignant neoplasm of other and unspecified respiratory organs"
    },
    {
      "name": "C784",
      "description": "Secondary malignant neoplasm of small intestine"
    },
    {
      "name": "C785",
      "description": "Secondary malignant neoplasm of large intestine and rectum"
    },
    {
      "name": "C786",
      "description": "Secondary malignant neoplasm of retroperitoneum and peritoneum"
    },
    {
      "name": "C787",
      "description": "Secondary malignant neoplasm of liver and intrahepatic bile duct"
    },
    {
      "name": "C788",
      "description": "Secondary malignant neoplasm of other and unspecified digestive organs"
    },
    {
      "name": "C790",
      "description": "Secondary malignant neoplasm of kidney and renal pelvis"
    },
    {
      "name": "C791",
      "description": "Secondary malignant neoplasm of bladder and other and unspecified urinary organs"
    },
    {
      "name": "C792",
      "description": "Secondary malignant neoplasm of skin"
    },
    {
      "name": "C793",
      "description": "Secondary malignant neoplasm of brain and cerebral meninges"
    },
    {
      "name": "C794",
      "description": "Secondary malignant neoplasm of other and unspecified parts of nervous system"
    },
    {
      "name": "C795",
      "description": "Secondary malignant neoplasm of bone and bone marrow"
    },
    {
      "name": "C796",
      "description": "Secondary malignant neoplasm of ovary"
    },
    {
      "name": "C797",
      "description": "Secondary malignant neoplasm of adrenal gland"
    },
    {
      "name": "C798",
      "description": "Secondary malignant neoplasm of other specified sites"
    },
    {
      "name": "C799",
      "description": "Secondary malignant neoplasm, unspecified site "
    },
    {
      "name": "C800",
      "description": "Malignant neoplasm, primary site unknown, so stated "
    },
    {
      "name": "C809",
      "description": "Malignant neoplasm, primary site unspecified"
    },
    {
      "name": "C810",
      "description": "Nodular lymphocyte predominant Hodgkin lymphoma"
    },
    {
      "name": "C811",
      "description": "Nodular sclerosis (classical) Hodgkin lymphoma"
    },
    {
      "name": "C812",
      "description": "Mixed cellularity (classical) Hodgkin lymphoma"
    },
    {
      "name": "C813",
      "description": "Lymphocytic depletion (classical) Hodgkin lymphoma"
    },
    {
      "name": "C814",
      "description": "Lymphocyte-rich (classical) Hodgkin lymphoma"
    },
    {
      "name": "C817",
      "description": "Other (classical) Hodgkin lymphoma"
    },
    {
      "name": "C819",
      "description": "Hodgkin lymphoma unspecified"
    },
    {
      "name": "C820",
      "description": "Follicular lymphoma grade I"
    },
    {
      "name": "C821",
      "description": "Follicular lymphoma grade II"
    },
    {
      "name": "C822",
      "description": "Follicular lymphoma grade III, unspecified"
    },
    {
      "name": "C823",
      "description": "Follicular lymphoma grade IIIa "
    },
    {
      "name": "C824",
      "description": "Follicular lymphoma grade IIIb "
    },
    {
      "name": "C825",
      "description": "Diffuse follicle centre lymphoma "
    },
    {
      "name": "C826",
      "description": "Cutaneous follicle centre lymphoma "
    },
    {
      "name": "C827",
      "description": "Other types  of follicular lymphoma"
    },
    {
      "name": "C829",
      "description": "Follicular  lymphoma, unspecified"
    },
    {
      "name": "C830",
      "description": "Small cell B-cell  lymphoma"
    },
    {
      "name": "C831",
      "description": "Mantle cell lymphoma"
    },
    {
      "name": "C833",
      "description": " Diffuse large B-cell lymphoma"
    },
    {
      "name": "C835",
      "description": "Lymphoblastic (diffuse) lymphoma"
    },
    {
      "name": "C837",
      "description": "Burkitt's lymphoma"
    },
    {
      "name": "C838",
      "description": "Other non-follicular lymphoma "
    },
    {
      "name": "C839",
      "description": "Non-follicular Diffuse non-Hodgkin's lymphoma, unspecified"
    },
    {
      "name": "C840",
      "description": "Mycosis fungoides"
    },
    {
      "name": "C841",
      "description": "Sezary's disease"
    },
    {
      "name": "C844",
      "description": "Peripheral T-cell lymphoma, not elsewhere  classified"
    },
    {
      "name": "C845",
      "description": "Other mature T/NK-cell lymphoma"
    },
    {
      "name": "C846",
      "description": "Anaplastic large cell lymphoma, ALK-positive "
    },
    {
      "name": "C847",
      "description": "Anaplastic large cell lymphoma, ALK-negative "
    },
    {
      "name": "C848",
      "description": "Cutaneous T-cell lymphoma, unspecified "
    },
    {
      "name": "C849",
      "description": "Mature T/NK-cell lymphoma, unspecified"
    },
    {
      "name": "C851",
      "description": "B-cell lymphoma, unspecified"
    },
    {
      "name": "C852",
      "description": "Mediastinal (thymic) large B-cell lymphoma "
    },
    {
      "name": "C857",
      "description": "Other specified types of non-Hodgkin's lymphoma"
    },
    {
      "name": "C859",
      "description": "Non-Hodgkin's lymphoma, unspecified"
    },
    {
      "name": "C860",
      "description": "Extranodal NK/T-cell lymphoma, nasal type "
    },
    {
      "name": "C861",
      "description": "Hepatosplenic T-cell lymphoma "
    },
    {
      "name": "C862",
      "description": "Enteropathy-type (intestinal) T-cell lymphoma "
    },
    {
      "name": "C863",
      "description": "Subcutaneous panniculitis-like T-cell lymphoma "
    },
    {
      "name": "C864",
      "description": "Blastic NK-cell lymphoma "
    },
    {
      "name": "C865",
      "description": "Angioimmunoblastic T-cell lymphoma "
    },
    {
      "name": "C866",
      "description": "Primary cutaneous CD30-positive T-cell proliferations"
    },
    {
      "name": "C880",
      "description": "Waldenstrom macroglobulinaemia"
    },
    {
      "name": "C882",
      "description": "Other heavy chain disease"
    },
    {
      "name": "C883",
      "description": "Immunoproliferative small intestinal disease"
    },
    {
      "name": "C884",
      "description": "Extranodal marginal zone B-cell lymphoma of mucosa-associated lymphoid tissue [MALT-lymphoma]"
    },
    {
      "name": "C887",
      "description": "Other malignant immunoproliferative diseases"
    },
    {
      "name": "C889",
      "description": "Malignant immunoproliferative disease, unspecified"
    },
    {
      "name": "C900",
      "description": "Multiple myeloma"
    },
    {
      "name": "C901",
      "description": "Plasma cell leukaemia"
    },
    {
      "name": "C902",
      "description": "Extramedullary plasmacytoma"
    },
    {
      "name": "C903",
      "description": "Solitary plasmacytoma  "
    },
    {
      "name": "C910",
      "description": "Acute lymphoblastic leukaemia [ALL]"
    },
    {
      "name": "C911",
      "description": "Chronic lymphocytic leukaemia of B-cell type"
    },
    {
      "name": "C913",
      "description": "Prolymphocytic leukaemia of B-cell type"
    },
    {
      "name": "C914",
      "description": "Hairy-cell leukaemia"
    },
    {
      "name": "C915",
      "description": "Adult T-cell lymphoma  / leukaemia [HTLV-1-associated]"
    },
    {
      "name": "C916",
      "description": "Prolymphocytic leukaemia of T-cell type"
    },
    {
      "name": "C917",
      "description": "Other lymphoid leukaemia"
    },
    {
      "name": "C918",
      "description": "Mature B-cell leukaemia, Burkitt-type"
    },
    {
      "name": "C919",
      "description": "Lymphoid leukaemia, unspecified"
    },
    {
      "name": "C920",
      "description": "Acute myeloid leukaemia [AML]"
    },
    {
      "name": "C921",
      "description": "Chronic myeloid leukaemia [CML], BCR/ABL –positive"
    },
    {
      "name": "C922",
      "description": "Atypical chronic myeloid leukaemia, BCR/ABL - negative"
    },
    {
      "name": "C923",
      "description": "Myeloid sarcoma"
    },
    {
      "name": "C924",
      "description": "Acute promyelocytic leukaemia [PML]"
    },
    {
      "name": "C925",
      "description": "Acute myelomonocytic leukaemia"
    },
    {
      "name": "C926",
      "description": "Acute myeloid leukaemia with 11q23-abnormality"
    },
    {
      "name": "C927",
      "description": "Other myeloid leukaemia"
    },
    {
      "name": "C928",
      "description": "Acute myeloid leukaemia with multilineage dysplasia"
    },
    {
      "name": "C929",
      "description": "Myeloid leukaemia, unspecified"
    },
    {
      "name": "C930",
      "description": "Acute monoblastic/monocytic leukaemia"
    },
    {
      "name": "C931",
      "description": "Chronic myelomonocytic leukaemia"
    },
    {
      "name": "C933",
      "description": "Juvenile myelomonocytic leukaemia "
    },
    {
      "name": "C937",
      "description": "Other monocytic leukaemia"
    },
    {
      "name": "C939",
      "description": "Monocytic leukaemia, unspecified"
    },
    {
      "name": "C940",
      "description": "Acute erythroid leukaemia"
    },
    {
      "name": "C942",
      "description": "Acute megakaryoblastic leukaemia"
    },
    {
      "name": "C943",
      "description": "Mast cell leukaemia"
    },
    {
      "name": "C944",
      "description": "Acute panmyelosis with myelofibrosis"
    },
    {
      "name": "C946",
      "description": "Myelodysplastic and myeloproliferative disease, not elsewhere classified"
    },
    {
      "name": "C947",
      "description": "Other specified leukaemias"
    },
    {
      "name": "C950",
      "description": "Acute leukaemia of unspecified cell type"
    },
    {
      "name": "C951",
      "description": "Chronic leukaemia of unspecified cell type"
    },
    {
      "name": "C957",
      "description": "Other  leukaemia of unspecified cell type"
    },
    {
      "name": "C959",
      "description": "Leukaemia, unspecified"
    },
    {
      "name": "C960",
      "description": "Multifocal and multisystemic (disseminated) Langerhans-cell histiocytosis [Letterer-Siwe disease]"
    },
    {
      "name": "C962",
      "description": "Malignant mast cell tumour"
    },
    {
      "name": "C964",
      "description": "Sarcoma of dendritic cells (accessory cells)"
    },
    {
      "name": "C965",
      "description": "Multifocal and unisystemic Langerhans-cell histiocytosis"
    },
    {
      "name": "C966",
      "description": "Unifocal Langerhans-cell histiocytosis "
    },
    {
      "name": "C967",
      "description": "Other specified malignant neoplasms of lymphoid, haematopoietic and  related tissue"
    },
    {
      "name": "C968",
      "description": "Histiocytic sarcoma "
    },
    {
      "name": "C969",
      "description": "Malignant neoplasm of lymphoid, haematopoietic and  related tissue, unspecified"
    },
    {
      "name": "C97",
      "description": "Malignant neoplasms of independent (primary) multiple sites"
    },
    {
      "name": "D000",
      "description": "Lip, oral cavity and pharynx"
    },
    {
      "name": "D001",
      "description": "Oesophagus "
    },
    {
      "name": "D002",
      "description": "Stomach"
    },
    {
      "name": "D010",
      "description": "Colon "
    },
    {
      "name": "D011",
      "description": "Rectosigmoid junction "
    },
    {
      "name": "D012",
      "description": "Rectum "
    },
    {
      "name": "D013",
      "description": "Anus and anal canal "
    },
    {
      "name": "D014",
      "description": "Other and unspecified parts of intestine"
    },
    {
      "name": "D015",
      "description": "Liver, gallbladder and bile ducts "
    },
    {
      "name": "D017",
      "description": "Other specified digestive organs"
    },
    {
      "name": "D019",
      "description": "Digestive organ, unspecified"
    },
    {
      "name": "D020",
      "description": "Larynx "
    },
    {
      "name": "D021",
      "description": "Trachea "
    },
    {
      "name": "D022",
      "description": "Bronchus and lung"
    },
    {
      "name": "D023",
      "description": "Other parts of respiratory system"
    },
    {
      "name": "D024",
      "description": "Respiratory system, unspecified"
    },
    {
      "name": "D030",
      "description": "Melanoma in situ of lip"
    },
    {
      "name": "D031",
      "description": "Melanoma in situ of eyelid, including canthus"
    },
    {
      "name": "D032",
      "description": "Melanoma in situ ear and external auricular canal"
    },
    {
      "name": "D0330",
      "description": "Skin of lip "
    },
    {
      "name": "D0338",
      "description": "Other facial skin "
    },
    {
      "name": "D0339",
      "description": "Facial skin, unspecified "
    },
    {
      "name": "D034",
      "description": "Melanoma in situ of scalp and neck"
    },
    {
      "name": "D035",
      "description": "Melanoma in situ of trunk"
    },
    {
      "name": "D036",
      "description": "Melanoma in situ of upper limb, including shoulder"
    },
    {
      "name": "D037",
      "description": "Melanoma in situ of lower limb, including hip"
    },
    {
      "name": "D038",
      "description": "Melanoma in situ of other sites"
    },
    {
      "name": "D039",
      "description": "Melanoma in situ, unspecified"
    },
    {
      "name": "D040",
      "description": "Skin of lip"
    },
    {
      "name": "D041",
      "description": "Skin of eyelid, including canthus"
    },
    {
      "name": "D042",
      "description": "Skin of ear and external auricular canal "
    },
    {
      "name": "D043",
      "description": "Skin of other and unspecified parts of face"
    },
    {
      "name": "D044",
      "description": "Skin of scalp and neck "
    },
    {
      "name": "D045",
      "description": "Skin of trunk "
    },
    {
      "name": "D046",
      "description": "Skin of upper limb, including shoulder"
    },
    {
      "name": "D047",
      "description": "Skin of lower limb, including hip"
    },
    {
      "name": "D048",
      "description": "Skin of other sites"
    },
    {
      "name": "D049",
      "description": "Skin, unspecified"
    },
    {
      "name": "D050",
      "description": "Lobular carcinoma in situ"
    },
    {
      "name": "D051",
      "description": "Intraductal carcinoma in situ"
    },
    {
      "name": "D057",
      "description": "Other carcinoma in situ of breast"
    },
    {
      "name": "D059",
      "description": "Carcinoma in situ of breast, unspecified"
    },
    {
      "name": "D060",
      "description": "Endocervix "
    },
    {
      "name": "D061",
      "description": "Exocervix"
    },
    {
      "name": "D067",
      "description": "Other parts of cervix "
    },
    {
      "name": "D069",
      "description": "Cervix, unspecified"
    },
    {
      "name": "D070",
      "description": "Endometrium "
    },
    {
      "name": "D071",
      "description": "Vulva "
    },
    {
      "name": "D072",
      "description": "Vagina "
    },
    {
      "name": "D073",
      "description": "Other and unspecified female genital organs "
    },
    {
      "name": "D074",
      "description": "Penis "
    },
    {
      "name": "D075",
      "description": "Prostate "
    },
    {
      "name": "D076",
      "description": "Other and unspecified male genital organs"
    },
    {
      "name": "D090",
      "description": "Bladder "
    },
    {
      "name": "D091",
      "description": "Other and unspecified urinary organs "
    },
    {
      "name": "D092",
      "description": "Eye "
    },
    {
      "name": "D093",
      "description": "Thyroid and other endocrine glands "
    },
    {
      "name": "D097",
      "description": "Carcinoma in situ of other specified sites"
    },
    {
      "name": "D099",
      "description": "Carcinoma in situ, unspecified"
    },
    {
      "name": "D1000",
      "description": "Upper lip, vermilion border"
    },
    {
      "name": "D1001",
      "description": "Upper lip, labial mucosa"
    },
    {
      "name": "D1002",
      "description": "Upper lip, vermilion border with mucosa"
    },
    {
      "name": "D1003",
      "description": "Lower lip,  vermilion border"
    },
    {
      "name": "D1004",
      "description": "Lower lip, labial mucosa"
    },
    {
      "name": "D1005",
      "description": "Lower lip, vermilion border with mucosa"
    },
    {
      "name": "D1006",
      "description": "Both lips, vermilion border"
    },
    {
      "name": "D1007",
      "description": "Both lips, labial mucosa"
    },
    {
      "name": "D1008",
      "description": "Both lips, vermilion border with mucosa"
    },
    {
      "name": "D1009",
      "description": "Lip, unspecified"
    },
    {
      "name": "D1010",
      "description": "Base of tongue (posterior to terminal sulcus) "
    },
    {
      "name": "D1011",
      "description": "Dorsal surface of tongue"
    },
    {
      "name": "D1012",
      "description": "Border and tip of tongue "
    },
    {
      "name": "D1013",
      "description": "Ventral surface of tongue "
    },
    {
      "name": "D1014",
      "description": "Lingual tonsil "
    },
    {
      "name": "D1019",
      "description": "Tongue, unspecified"
    },
    {
      "name": "D102",
      "description": "Floor of mouth"
    },
    {
      "name": "D1030",
      "description": "Buccal mucosa"
    },
    {
      "name": "D1031",
      "description": "Buccal mucosal commissure"
    },
    {
      "name": "D1032",
      "description": "Buccal sulcus"
    },
    {
      "name": "D1033",
      "description": "Gingiva and edentulous alveolar ridge"
    },
    {
      "name": "D1034",
      "description": "Hard palate "
    },
    {
      "name": "D1035",
      "description": "Soft palate "
    },
    {
      "name": "D1036",
      "description": "Uvula"
    },
    {
      "name": "D1037",
      "description": "Retromolar area "
    },
    {
      "name": "D1038",
      "description": "Tuberosity"
    },
    {
      "name": "D1039",
      "description": "Mouth, unspecified"
    },
    {
      "name": "D104",
      "description": "Tonsil "
    },
    {
      "name": "D105",
      "description": "Other parts of oropharynx"
    },
    {
      "name": "D106",
      "description": "Nasopharynx "
    },
    {
      "name": "D107",
      "description": "Hypopharynx"
    },
    {
      "name": "D109",
      "description": "Pharynx, unspecified"
    },
    {
      "name": "D110",
      "description": "Parotid gland"
    },
    {
      "name": "D117",
      "description": "Other major salivary glands "
    },
    {
      "name": "D119",
      "description": "Major salivary glands, unspecified"
    },
    {
      "name": "D120",
      "description": "Caecum"
    },
    {
      "name": "D121",
      "description": "Appendix "
    },
    {
      "name": "D122",
      "description": "Ascending colon "
    },
    {
      "name": "D123",
      "description": "Transverse colon "
    },
    {
      "name": "D124",
      "description": "Descending colon"
    },
    {
      "name": "D125",
      "description": "Sigmoid colon "
    },
    {
      "name": "D126",
      "description": "Colon, unspecified"
    },
    {
      "name": "D127",
      "description": "Rectosigmoid junction"
    },
    {
      "name": "D128",
      "description": "Rectum "
    },
    {
      "name": "D129",
      "description": "Anus and anal canal"
    },
    {
      "name": "D130",
      "description": "Oesophagus "
    },
    {
      "name": "D131",
      "description": "Stomach "
    },
    {
      "name": "D132",
      "description": "Duodenum "
    },
    {
      "name": "D133",
      "description": "Other and unspecified parts of small intestine"
    },
    {
      "name": "D134",
      "description": "Liver"
    },
    {
      "name": "D135",
      "description": "Extrahepatic bile ducts"
    },
    {
      "name": "D136",
      "description": "Pancreas "
    },
    {
      "name": "D137",
      "description": "Endocrine pancreas "
    },
    {
      "name": "D139",
      "description": "III-defined sites within digestive system "
    },
    {
      "name": "D140",
      "description": "Middle ear, nasal cavity and accessory sinuses "
    },
    {
      "name": "D141",
      "description": "Larynx "
    },
    {
      "name": "D142",
      "description": "Trachea "
    },
    {
      "name": "D143",
      "description": "Bronchus and lung"
    },
    {
      "name": "D144",
      "description": "Respiratory system, unspecified"
    },
    {
      "name": "D150",
      "description": "Thymus "
    },
    {
      "name": "D151",
      "description": "Heart "
    },
    {
      "name": "D152",
      "description": "Mediastinum"
    },
    {
      "name": "D157",
      "description": "Other specified intrathoracic organs "
    },
    {
      "name": "D159",
      "description": "Intrathoracic organ, unspecified"
    },
    {
      "name": "D160",
      "description": "Scapula and long bones of upper limb "
    },
    {
      "name": "D161",
      "description": "Short bones of upper limb "
    },
    {
      "name": "D162",
      "description": "Long bones of lower limb "
    },
    {
      "name": "D163",
      "description": "Short bones of lower limb "
    },
    {
      "name": "D1640",
      "description": "Maxilla, bone and cartilage"
    },
    {
      "name": "D1641",
      "description": "Maxilla, odontogenic tissue"
    },
    {
      "name": "D1648",
      "description": "Other bones of skull and face"
    },
    {
      "name": "D1649",
      "description": "Maxilla, unspecified"
    },
    {
      "name": "D1650",
      "description": "Mandible, bone and cartilage"
    },
    {
      "name": "D1651",
      "description": "Mandible, odontogenic tissue"
    },
    {
      "name": "D1659",
      "description": "Mandible, unspecified"
    },
    {
      "name": "D166",
      "description": "Vertebral column "
    },
    {
      "name": "D167",
      "description": "Ribs, sternum and clavicle "
    },
    {
      "name": "D168",
      "description": "Pelvic bones, sacrum and coccyx"
    },
    {
      "name": "D169",
      "description": "Bone and articular cartilage, unspecified"
    },
    {
      "name": "D170",
      "description": "Benign lipomatous neoplasm of skin and subcutaneous tissue of head, face and neck"
    },
    {
      "name": "D171",
      "description": "Benign lipomatous neoplasm of skin and subcutaneous tissue of trunk"
    },
    {
      "name": "D172",
      "description": "Benign lipomatous neoplasm of skin and subcutaneous tissue of limbs"
    },
    {
      "name": "D173",
      "description": "Benign lipomatous neoplasm of skin and subcutaneous tissue of other and unspecified sites"
    },
    {
      "name": "D174",
      "description": "Benign lipomatous neoplasm of intrathoracic organs"
    },
    {
      "name": "D175",
      "description": "Benign lipomatous neoplasm of intra-abdominal organs"
    },
    {
      "name": "D176",
      "description": "Benign lipomatous neoplasm of spermatic cord"
    },
    {
      "name": "D177",
      "description": "Benign lipomatous neoplasm of other sites"
    },
    {
      "name": "D179",
      "description": "Benign lipomatous neoplasm, unspecified"
    },
    {
      "name": "D1800",
      "description": "skin and \tsubcutaneous tissue"
    },
    {
      "name": "D1801",
      "description": "intracranial \tstructure"
    },
    {
      "name": "D1802",
      "description": "liver"
    },
    {
      "name": "D1803",
      "description": "intestine"
    },
    {
      "name": "D1804",
      "description": "ear, nose, mouth and throat"
    },
    {
      "name": "D1807",
      "description": "multiple sites"
    },
    {
      "name": "D1808",
      "description": "other specified sites"
    },
    {
      "name": "D1809",
      "description": "Haemangioma, unspecified site"
    },
    {
      "name": "D181",
      "description": "Lymphangioma, any site"
    },
    {
      "name": "D190",
      "description": "Mesothelial tissue of pleura"
    },
    {
      "name": "D191",
      "description": "Mesothelial tissue of peritoneum"
    },
    {
      "name": "D197",
      "description": "Mesothelial tissue of other sites"
    },
    {
      "name": "D199",
      "description": "Mesothelial tissue, unspecified"
    },
    {
      "name": "D200",
      "description": "Retroperitoneum"
    },
    {
      "name": "D201",
      "description": "Peritoneum"
    },
    {
      "name": "D210",
      "description": "Connective and other soft tissue of head, face and neck"
    },
    {
      "name": "D211",
      "description": "Connective and other soft tissue of upper limb, including shoulder"
    },
    {
      "name": "D212",
      "description": "Connective and other soft tissue of lower limb, including hip"
    },
    {
      "name": "D213",
      "description": "Connective and other soft tissue of thorax"
    },
    {
      "name": "D214",
      "description": "Connective and other soft tissue of abdomen"
    },
    {
      "name": "D215",
      "description": "Connective and other soft tissue of pelvis"
    },
    {
      "name": "D216",
      "description": "Connective and other soft tissue of trunk, unspecified"
    },
    {
      "name": "D219",
      "description": "Connective and other soft tissue, unspecified"
    },
    {
      "name": "D220",
      "description": "Melanocytic naevi of lip"
    },
    {
      "name": "D221",
      "description": "Melanocytic naevi of eyelid, including canthus"
    },
    {
      "name": "D222",
      "description": "Melanocytic naevi of ear and external auricular canal"
    },
    {
      "name": "D223",
      "description": "Melanocytic naevi of other and unspecified parts of face"
    },
    {
      "name": "D224",
      "description": "Melanocytic naevi of scalp and neck"
    },
    {
      "name": "D225",
      "description": "Melanocytic naevi of trunk"
    },
    {
      "name": "D226",
      "description": "Melanocytic naevi of upper limb, including shoulder"
    },
    {
      "name": "D227",
      "description": "Melanocytic naevi of lower limb, including hip"
    },
    {
      "name": "D229",
      "description": "Melanocytic naevi, unspecified"
    },
    {
      "name": "D230",
      "description": "Skin of lip"
    },
    {
      "name": "D231",
      "description": "Skin of eyelid, including canthus"
    },
    {
      "name": "D232",
      "description": "Skin of ear and external auricular canal"
    },
    {
      "name": "D233",
      "description": "Skin of other and unspecified parts of face"
    },
    {
      "name": "D234",
      "description": "Skin of scalp and neck"
    },
    {
      "name": "D235",
      "description": "Skin of trunk"
    },
    {
      "name": "D236",
      "description": "Skin of upper limb, including shoulder"
    },
    {
      "name": "D237",
      "description": "Skin of lower limb,  including hip"
    },
    {
      "name": "D239",
      "description": "Skin, unspecified"
    },
    {
      "name": "D24",
      "description": "Benign neoplasm of breast"
    },
    {
      "name": "D250",
      "description": "Submucous leiomyoma of uterus"
    },
    {
      "name": "D251",
      "description": "Intramural leiomyoma of uterus"
    },
    {
      "name": "D252",
      "description": "Subserosal leiomyoma of uterus"
    },
    {
      "name": "D259",
      "description": "Leiomyoma of uterus, unspecified"
    },
    {
      "name": "D260",
      "description": "Cervix uteri"
    },
    {
      "name": "D261",
      "description": "Corpus uteri"
    },
    {
      "name": "D267",
      "description": "Other parts of uterus"
    },
    {
      "name": "D269",
      "description": "Uterus, unspecified"
    },
    {
      "name": "D27",
      "description": "Benign neoplasm of ovary"
    },
    {
      "name": "D280",
      "description": "Vulva "
    },
    {
      "name": "D281",
      "description": "Vagina"
    },
    {
      "name": "D282",
      "description": "Uterine tubes and ligaments"
    },
    {
      "name": "D287",
      "description": "Other specified female genital organs"
    },
    {
      "name": "D289",
      "description": "female genital organ, unspecified"
    },
    {
      "name": "D290",
      "description": "Penis "
    },
    {
      "name": "D291",
      "description": "Prostate "
    },
    {
      "name": "D292",
      "description": "Testis"
    },
    {
      "name": "D293",
      "description": "Epididymis"
    },
    {
      "name": "D294",
      "description": "Scrotum "
    },
    {
      "name": "D297",
      "description": "Other male genital organs "
    },
    {
      "name": "D299",
      "description": "Male genital organ, unspecified"
    },
    {
      "name": "D300",
      "description": "Kidney"
    },
    {
      "name": "D301",
      "description": "Renal pelvis"
    },
    {
      "name": "D302",
      "description": "Ureter "
    },
    {
      "name": "D303",
      "description": "Bladder"
    },
    {
      "name": "D304",
      "description": "Urethra "
    },
    {
      "name": "D307",
      "description": "Other urinary organs "
    },
    {
      "name": "D309",
      "description": "Urinary organ, unspecified"
    },
    {
      "name": "D310",
      "description": "Conjunctiva"
    },
    {
      "name": "D311",
      "description": "Cornea "
    },
    {
      "name": "D312",
      "description": "Retina"
    },
    {
      "name": "D313",
      "description": "Choroid "
    },
    {
      "name": "D314",
      "description": "Ciliary body "
    },
    {
      "name": "D315",
      "description": "Lacrimal gland and duct "
    },
    {
      "name": "D316",
      "description": "Orbit, unspecified"
    },
    {
      "name": "D319",
      "description": "Eye, unspecified"
    },
    {
      "name": "D320",
      "description": "Cerebral meninges"
    },
    {
      "name": "D321",
      "description": "Spinal meninges "
    },
    {
      "name": "D329",
      "description": "Meninges, unspecified"
    },
    {
      "name": "D330",
      "description": "Brain, supratentorial "
    },
    {
      "name": "D331",
      "description": "Brain, infratentorial "
    },
    {
      "name": "D332",
      "description": "Brain unspecified"
    },
    {
      "name": "D333",
      "description": "Cranial nerves "
    },
    {
      "name": "D334",
      "description": "Spinal cord "
    },
    {
      "name": "D337",
      "description": "Other specified parts of central nervous system"
    },
    {
      "name": "D339",
      "description": "Central nervous system, unspecified"
    },
    {
      "name": "D34",
      "description": "Benign neoplasm of thyroid gland"
    },
    {
      "name": "D350",
      "description": "Adrenal gland"
    },
    {
      "name": "D351",
      "description": "Parathyroid gland "
    },
    {
      "name": "D352",
      "description": "Pituitary gland "
    },
    {
      "name": "D353",
      "description": "Craniopharyngeal duct "
    },
    {
      "name": "D354",
      "description": "Pineal gland "
    },
    {
      "name": "D355",
      "description": "Carotid body "
    },
    {
      "name": "D356",
      "description": "Aortic body and other paraganglia "
    },
    {
      "name": "D357",
      "description": "Other specified endocrine glands"
    },
    {
      "name": "D358",
      "description": "Pluriglandular  involvement"
    },
    {
      "name": "D359",
      "description": "Endocrine gland, unspecified"
    },
    {
      "name": "D360",
      "description": "Lymph nodes"
    },
    {
      "name": "D361",
      "description": "Peripheral nerves and autonomic nervous system"
    },
    {
      "name": "D367",
      "description": "Other specified sites "
    },
    {
      "name": "D369",
      "description": "Benign neoplasm of unspecified site"
    },
    {
      "name": "D370",
      "description": "Lip, oral cavity and pharynx "
    },
    {
      "name": "D371",
      "description": "Stomach"
    },
    {
      "name": "D372",
      "description": "Small intestine "
    },
    {
      "name": "D373",
      "description": "Appendix "
    },
    {
      "name": "D374",
      "description": "Colon "
    },
    {
      "name": "D375",
      "description": "Rectum "
    },
    {
      "name": "D376",
      "description": "Liver, gallbladder and bile ducts"
    },
    {
      "name": "D377",
      "description": "Other digestive organs "
    },
    {
      "name": "D379",
      "description": "Digestive organ, unspecified"
    },
    {
      "name": "D380",
      "description": "Larynx "
    },
    {
      "name": "D381",
      "description": "Trachea, bronchus and lung "
    },
    {
      "name": "D382",
      "description": "Pleura "
    },
    {
      "name": "D383",
      "description": "Mediastinum "
    },
    {
      "name": "D384",
      "description": "Thymus "
    },
    {
      "name": "D385",
      "description": "Other respiratory organs "
    },
    {
      "name": "D386",
      "description": "Respiratory organ, unspecified"
    },
    {
      "name": "D390",
      "description": "Uterus "
    },
    {
      "name": "D391",
      "description": "Ovary "
    },
    {
      "name": "D392",
      "description": "Placenta"
    },
    {
      "name": "D397",
      "description": "Other female genital organs "
    },
    {
      "name": "D399",
      "description": "Female genital organ, unspecified"
    },
    {
      "name": "D400",
      "description": "Prostate "
    },
    {
      "name": "D401",
      "description": "Testis "
    },
    {
      "name": "D407",
      "description": "Other male genital organs "
    },
    {
      "name": "D409",
      "description": "Male genital organ, unspecified"
    },
    {
      "name": "D410",
      "description": "Kidney "
    },
    {
      "name": "D411",
      "description": "Renal pelvis "
    },
    {
      "name": "D412",
      "description": "Ureter"
    },
    {
      "name": "D413",
      "description": "Urethra "
    },
    {
      "name": "D414",
      "description": "Bladder "
    },
    {
      "name": "D417",
      "description": "Other urinary organs "
    },
    {
      "name": "D419",
      "description": "Urinary organ, unspecified"
    },
    {
      "name": "D420",
      "description": "Cerebral meninges"
    },
    {
      "name": "D421",
      "description": "Spinal meninges"
    },
    {
      "name": "D429",
      "description": "Meninges, unspecified"
    },
    {
      "name": "D430",
      "description": "Brain, supratentorial"
    },
    {
      "name": "D431",
      "description": "Brain, infratentorial"
    },
    {
      "name": "D432",
      "description": "Brain, unspecified"
    },
    {
      "name": "D433",
      "description": "Cranial nerves "
    },
    {
      "name": "D434",
      "description": "Spinal cord "
    },
    {
      "name": "D437",
      "description": "Other specified parts of central nervous system"
    },
    {
      "name": "D439",
      "description": "Central nervous system, unspecified"
    },
    {
      "name": "D440",
      "description": "Thyroid gland "
    },
    {
      "name": "D441",
      "description": "Adrenal gland "
    },
    {
      "name": "D442",
      "description": "Parathyroid gland "
    },
    {
      "name": "D443",
      "description": "Pituitary  gland"
    },
    {
      "name": "D444",
      "description": "Craniopharyngeal duct "
    },
    {
      "name": "D445",
      "description": "Pineal  gland "
    },
    {
      "name": "D446",
      "description": "Carotid body "
    },
    {
      "name": "D447",
      "description": "Aortic body and other paraganglia "
    },
    {
      "name": "D448",
      "description": "Pluriglandular involvement "
    },
    {
      "name": "D449",
      "description": "Endocrine gland, unspecified"
    },
    {
      "name": "D45",
      "description": "Polycythaemia vera"
    },
    {
      "name": "D460",
      "description": "Refractory anaemia without ring sideroblasts, so stated"
    },
    {
      "name": "D461",
      "description": "Refractory anaemia with ring sideroblasts"
    },
    {
      "name": "D462",
      "description": "Refractory anaemia with excess of blasts [RAEB]"
    },
    {
      "name": "D464",
      "description": "Refractory anaemia, unspecified"
    },
    {
      "name": "D465",
      "description": "Refractory anaemia with multi-lineage dysplasia"
    },
    {
      "name": "D466",
      "description": "Myelodysplastic syndrome with isolated del(5q) chromosomal abnormality"
    },
    {
      "name": "D467",
      "description": "Other myelodysplastic syndromes"
    },
    {
      "name": "D469",
      "description": "Myelodysplastic syndrome, unspecified"
    },
    {
      "name": "D470",
      "description": "Histiocytic and mast cell tumours of uncertain and unknow behaviour"
    },
    {
      "name": "D471",
      "description": "Chronic myeloproliferative disease"
    },
    {
      "name": "D472",
      "description": "Monoclonal gammopathy of undetermined  significance [MGUS]"
    },
    {
      "name": "D473",
      "description": "Essential (haemorrhagic) thrombocythaemia"
    },
    {
      "name": "D474",
      "description": "Osteomyelofibrosis "
    },
    {
      "name": "D475",
      "description": "Chronic eosinophilic leukaemia [hypereosinophilic syndrome]"
    },
    {
      "name": "D477",
      "description": "Other specified neoplasms of uncertain or unknown behaviour of lymphoid, haematopoietic and related tissue"
    },
    {
      "name": "D479",
      "description": "Neoplasm of uncertain or unknow behaviour of lymphoid, haematopoietic and related tissue, unspecified"
    },
    {
      "name": "D480",
      "description": "Bone and articular cartilage "
    },
    {
      "name": "D481",
      "description": "Connective and other soft tissue "
    },
    {
      "name": "D482",
      "description": "Peripheral nerves and autonomic nervous system"
    },
    {
      "name": "D483",
      "description": "Retroperitoneum "
    },
    {
      "name": "D484",
      "description": "Peritoneum "
    },
    {
      "name": "D485",
      "description": "Skin "
    },
    {
      "name": "D486",
      "description": "Breast "
    },
    {
      "name": "D487",
      "description": "Other specified sites"
    },
    {
      "name": "D489",
      "description": "Neoplasm of uncertain or unknown behaviour, unspecified"
    },
    {
      "name": "D500",
      "description": "Iron deficiency anaemia secondary to blood loss (chronic)"
    },
    {
      "name": "D501",
      "description": "Sideropenic dysphagia"
    },
    {
      "name": "D508",
      "description": "Other iron deficiency anaemias"
    },
    {
      "name": "D509",
      "description": "Iron deficiency anaemia, unspecified"
    },
    {
      "name": "D510",
      "description": "Vitamin B12 deficiency anaemia  due to intrinsic factor deficiency"
    },
    {
      "name": "D511",
      "description": "Vitamin B12 deficiency anaemia  due to selective vitamin B12 malabsorption with proteinuria"
    },
    {
      "name": "D512",
      "description": "Transcobalamin II deficiency"
    },
    {
      "name": "D513",
      "description": "Other dietary vitamin B12 deficiency anaemia"
    },
    {
      "name": "D518",
      "description": "Other vitamin B12 deficiency anaemias"
    },
    {
      "name": "D519",
      "description": "Vitamin B12 deficiency anaemia, unspecified"
    },
    {
      "name": "D520",
      "description": "Dietary folate deficiency anaemia"
    },
    {
      "name": "D521",
      "description": "Drug-induced folate deficiency anaemia"
    },
    {
      "name": "D528",
      "description": "Other folate deficiency anaemias"
    },
    {
      "name": "D529",
      "description": "Folate deficiency anaemia , unspecified"
    },
    {
      "name": "D530",
      "description": "Protein deficiency anaemia"
    },
    {
      "name": "D531",
      "description": "Other megaloblastic anaemias, not elsewhere classified"
    },
    {
      "name": "D532",
      "description": "Scorbutic anaemia"
    },
    {
      "name": "D538",
      "description": "Other specified nutritional anaemias"
    },
    {
      "name": "D539",
      "description": "Nutritional anaemia, unspecified"
    },
    {
      "name": "D550",
      "description": "Anaemia due to glucoses-6-phosphate dehydrogenase [G6PD] deficiency"
    },
    {
      "name": "D551",
      "description": "Anaemia due to other disorders of glutathione metabolism"
    },
    {
      "name": "D552",
      "description": "Anaemia due to disorders of glycolytic enzymes"
    },
    {
      "name": "D553",
      "description": "Anaemia due to disorders of nucleotide metabolism"
    },
    {
      "name": "D558",
      "description": "Other anaemias due to enzyme disorders"
    },
    {
      "name": "D559",
      "description": "Anaemia due to  enzyme disorder, unspecified"
    },
    {
      "name": "D560",
      "description": "Alpha thalassaemia"
    },
    {
      "name": "D561",
      "description": "Beta thalassaemia"
    },
    {
      "name": "D562",
      "description": "Delta-beta thalassaemia"
    },
    {
      "name": "D563",
      "description": "Thalassaemia trait"
    },
    {
      "name": "D564",
      "description": "Hereditary persistence of fetal haemoglobin [HPFH]"
    },
    {
      "name": "D568",
      "description": "Other thalassaemias"
    },
    {
      "name": "D569",
      "description": "Thalassaemia, unspecified"
    },
    {
      "name": "D570",
      "description": "Sickle-cell anaemia with crisis"
    },
    {
      "name": "D571",
      "description": "Sickle-cell anaemia  without crisis"
    },
    {
      "name": "D572",
      "description": "Double heterozygous sickling disorders"
    },
    {
      "name": "D573",
      "description": "Sickle-cell trait"
    },
    {
      "name": "D578",
      "description": "Other sickle-cell disorders"
    },
    {
      "name": "D580",
      "description": "Hereditary spherocytosis"
    },
    {
      "name": "D581",
      "description": "Hereditary elliptocytosis"
    },
    {
      "name": "D582",
      "description": "Other haemoglobinopathies"
    },
    {
      "name": "D588",
      "description": "Other specified hereditary haemolytic anaemias"
    },
    {
      "name": "D589",
      "description": "Hereditary haemolytic anaemia, unspecified"
    },
    {
      "name": "D590",
      "description": "Drug-induced autoimmune haemolytic anaemia"
    },
    {
      "name": "D591",
      "description": "Other  autoimmune haemolytic anaemias"
    },
    {
      "name": "D592",
      "description": "Drug-induced nonautoimmune haemolytic anaemia"
    },
    {
      "name": "D593",
      "description": "Haemolytic-uraemic syndrome"
    },
    {
      "name": "D5940",
      "description": "Chemical"
    },
    {
      "name": "D5941",
      "description": "Infectious "
    },
    {
      "name": "D5942",
      "description": "Microangiopathic "
    },
    {
      "name": "D5943",
      "description": "Physical"
    },
    {
      "name": "D5948",
      "description": "Other nonautoimmune haemolytic anaemias"
    },
    {
      "name": "D595",
      "description": "Paroxysmal nocturnal haemoglobinuria [Marchiafava-Micheli]"
    },
    {
      "name": "D596",
      "description": "Haemoglobinuria due to haemolysis from other external causes"
    },
    {
      "name": "D598",
      "description": "Other acquired haemolytic anaemias"
    },
    {
      "name": "D599",
      "description": "Acquired haemolytic anaemia, unspecified"
    },
    {
      "name": "D600",
      "description": "Chronic acquired  pure red cell aplasia"
    },
    {
      "name": "D601",
      "description": "Transient acquired  pure red cell aplasia"
    },
    {
      "name": "D608",
      "description": "Other acquired  pure red cell aplasias"
    },
    {
      "name": "D609",
      "description": "Acquired  pure red cell aplasia, unspecified"
    },
    {
      "name": "D610",
      "description": "Constitutional aplastic anaemia"
    },
    {
      "name": "D611",
      "description": "Drug-induced aplastic anaemia"
    },
    {
      "name": "D612",
      "description": "Aplastic anaemia due to other external agents"
    },
    {
      "name": "D613",
      "description": "Idiopathic aplastic anaemia"
    },
    {
      "name": "D618",
      "description": "Other specified aplastic anaemias"
    },
    {
      "name": "D619",
      "description": "Aplastic anaemia, unspecified"
    },
    {
      "name": "D62",
      "description": "Acute posthaemorrhagic anaemia"
    },
    {
      "name": "D630",
      "description": "Anaemia in neoplastic disease (C00-D48)"
    },
    {
      "name": "D638",
      "description": "Anaemia in other chronic diseases classified elsewhere"
    },
    {
      "name": "D640",
      "description": "Hereditary sideroblastic anaemia"
    },
    {
      "name": "D641",
      "description": "Secondary sideroblastic anaemia due to disease"
    },
    {
      "name": "D642",
      "description": "Secondary sideroblastic anaemia due to drugs and toxins"
    },
    {
      "name": "D643",
      "description": "Other sideroblastic anaemias"
    },
    {
      "name": "D644",
      "description": "Congenital dyserythropoietic anaemia"
    },
    {
      "name": "D648",
      "description": "Other specified anaemias"
    },
    {
      "name": "D649",
      "description": "Anaemia, unspecified"
    },
    {
      "name": "D65",
      "description": "Disseminated intravascular coagulation [defibrination syndrome]"
    },
    {
      "name": "D66",
      "description": "Hereditary factor VIII deficiency"
    },
    {
      "name": "D67",
      "description": "Hereditary factor IX deficiency"
    },
    {
      "name": "D680",
      "description": "Von Willebrand's disease"
    },
    {
      "name": "D681",
      "description": "Hereditary factor XI deficiency"
    },
    {
      "name": "D682",
      "description": "Hereditary deficiency of other clotting factors"
    },
    {
      "name": "D683",
      "description": "Haemorrhagic disorder due to circulating anticoagulants"
    },
    {
      "name": "D684",
      "description": "Acquired coagulation factor deficiency"
    },
    {
      "name": "D685",
      "description": "Primary thrombophilia"
    },
    {
      "name": "D686",
      "description": "Other thrombophilia"
    },
    {
      "name": "D688",
      "description": "Other specified coagulation defects"
    },
    {
      "name": "D689",
      "description": "Coagulation defect,unspecified"
    },
    {
      "name": "D690",
      "description": "Allergic purpura"
    },
    {
      "name": "D691",
      "description": "Qualitative platelet defects"
    },
    {
      "name": "D692",
      "description": "Other nonthrombocytopenic purpura"
    },
    {
      "name": "D693",
      "description": "Idiopathic thrombocytopenic purpura"
    },
    {
      "name": "D694",
      "description": "Other primary thrombocytopenia"
    },
    {
      "name": "D695",
      "description": "Secondary thrombocytopenia"
    },
    {
      "name": "D696",
      "description": "Thrombocytopenia, unspecified"
    },
    {
      "name": "D698",
      "description": "Other specified haemorrhagic conditions"
    },
    {
      "name": "D699",
      "description": "Haemorrhagic condition, unspecified"
    },
    {
      "name": "D70",
      "description": "Agranulocytosis"
    },
    {
      "name": "D71",
      "description": "Functional disorders of polymorphonuclear neutrophils"
    },
    {
      "name": "D720",
      "description": "Genetic anomalies of leukocytes"
    },
    {
      "name": "D721",
      "description": "Eosinophilia"
    },
    {
      "name": "D728",
      "description": "Other specified disorders of white blood cells"
    },
    {
      "name": "D729",
      "description": "Disorder of white blood cells, unspecified"
    },
    {
      "name": "D730",
      "description": "Hyposplenism"
    },
    {
      "name": "D731",
      "description": "Hypersplenism"
    },
    {
      "name": "D732",
      "description": "Chronic congestive splenomegaly"
    },
    {
      "name": "D733",
      "description": "Abscess of spleen"
    },
    {
      "name": "D734",
      "description": "Cyst of spleen"
    },
    {
      "name": "D735",
      "description": "Infarction of spleen"
    },
    {
      "name": "D738",
      "description": "Other diseases of spleen"
    },
    {
      "name": "D739",
      "description": "Diseases of spleen, unspecified"
    },
    {
      "name": "D740",
      "description": "Congenital methaemoglobinaemia"
    },
    {
      "name": "D748",
      "description": "Other methaemoglobinaemias"
    },
    {
      "name": "D749",
      "description": "Methaemoglobinaemia, unspecified"
    },
    {
      "name": "D750",
      "description": "Familial erythrocytosis"
    },
    {
      "name": "D751",
      "description": "Secondary polycythaemia"
    },
    {
      "name": "D758",
      "description": "Other specified diseases of blood and blood-forming organs"
    },
    {
      "name": "D759",
      "description": "Disease of blood and blood-forming organs, unspecified"
    },
    {
      "name": "D761",
      "description": "Haemophagocytic lymphohistiocytosis"
    },
    {
      "name": "D762",
      "description": "Haemophagocytic syndrome, infection-associated"
    },
    {
      "name": "D763",
      "description": "Other histiocytosis syndromes"
    },
    {
      "name": "D77",
      "description": "Other disorders of blood and blood-forming organs in diseases classified elsewhere"
    },
    {
      "name": "D800",
      "description": "Hereditary hypogammaglobulinaemia"
    },
    {
      "name": "D801",
      "description": "Nonfamilial hypogammaglobulinaemia"
    },
    {
      "name": "D802",
      "description": "Selective deficiency of immunoglobulin A [IgA]"
    },
    {
      "name": "D803",
      "description": "Selective deficiency of immunoglobulin G [IgG] subclasses"
    },
    {
      "name": "D804",
      "description": "Selective deficiency of immunoglobulin M [IgM]"
    },
    {
      "name": "D805",
      "description": "Immunodeficiency with increased immunoglobulin M [IgM]"
    },
    {
      "name": "D806",
      "description": "Antibody deficiency with near-normal immunoglobulins or with hyperimmunoglobulinaemia"
    },
    {
      "name": "D807",
      "description": "Transient hypogammaglobulinaemia of infancy"
    },
    {
      "name": "D808",
      "description": "Other immunodeficiencies with predominantly antibody defects"
    },
    {
      "name": "D809",
      "description": "Immunodeficiency with predominantly antibody defects, unspecified"
    },
    {
      "name": "D810",
      "description": "Severe combined immunodeficiency [SCID] with reticular dysgenesis"
    },
    {
      "name": "D811",
      "description": "Severe combined immunodeficiency [SCID] with low T-and B-cell numbers"
    },
    {
      "name": "D812",
      "description": "Severe combined immunodeficiency [SCID] with low or normal B-cell numbers"
    },
    {
      "name": "D813",
      "description": "Adenosine deaminase [ADA] deficiency"
    },
    {
      "name": "D814",
      "description": "Nezelof's syndrome"
    },
    {
      "name": "D815",
      "description": "Purine nucleoside phosphorylase [PNP] deficiency"
    },
    {
      "name": "D816",
      "description": "Major histocompatibility complex class I deficiency"
    },
    {
      "name": "D817",
      "description": "Major histocompatibility complex class II deficiency"
    },
    {
      "name": "D818",
      "description": "Other combined immunodeficiencies"
    },
    {
      "name": "D819",
      "description": "Combined immunodeficiency, unspecified"
    },
    {
      "name": "D820",
      "description": "Wiskott-AIdrich syndrome"
    },
    {
      "name": "D821",
      "description": "Di George's syndrome"
    },
    {
      "name": "D822",
      "description": "Immunodeficiency with short-limbed stature"
    },
    {
      "name": "D823",
      "description": "Immunodeficiency following hereditary defective response to Epstein-Barr virus"
    },
    {
      "name": "D824",
      "description": "Hyperimmunoglobulin E [lgE] syndrome"
    },
    {
      "name": "D828",
      "description": "Immunodeficiency associated with other specified major defects"
    },
    {
      "name": "D829",
      "description": "Immunodeficiency associated with major defects, unspecified"
    },
    {
      "name": "D830",
      "description": "Common variable immunodeficiency with predominant abnormalities of B-cell numbers and function"
    },
    {
      "name": "D831",
      "description": "Common variable immunodeficiency with predominant immunoregulatory T-cell disorders"
    },
    {
      "name": "D832",
      "description": "Common variable immunodeficiency with autoantibodies to B- or T- cells"
    },
    {
      "name": "D838",
      "description": "Other common variable immunodeficiencies"
    },
    {
      "name": "D839",
      "description": "Common variable immunodeficiency, unspecified"
    },
    {
      "name": "D840",
      "description": "Lymphocyte function antigen-1 [LFA-1] defect"
    },
    {
      "name": "D841",
      "description": "Defects in the complement system"
    },
    {
      "name": "D848",
      "description": "Other specified immunodeficiencies"
    },
    {
      "name": "D849",
      "description": "Immunodeficiency, unspecified"
    },
    {
      "name": "D860",
      "description": "Sarcoidosis of lung"
    },
    {
      "name": "D861",
      "description": "Sarcoidosis of lymph nodes"
    },
    {
      "name": "D862",
      "description": "Sarcoidosis of lung with sarcoidosis of lymph nodes"
    },
    {
      "name": "D863",
      "description": "Sarcoidosis of skin"
    },
    {
      "name": "D868",
      "description": "Sarcoidosis of other and combined sites"
    },
    {
      "name": "D869",
      "description": "Sarcoidosis, unspecified"
    },
    {
      "name": "D890",
      "description": "Polyclonal hypergammaglobulinaemia"
    },
    {
      "name": "D891",
      "description": "Cryoglobulinaemia"
    },
    {
      "name": "D892",
      "description": "Hypergammaglobulinaemia, unspecified"
    },
    {
      "name": "D893",
      "description": "Immune reconstitution syndrome"
    },
    {
      "name": "D898",
      "description": "Other specified disorders involving the immune mechanism, not elsewhere classified"
    },
    {
      "name": "D899",
      "description": "Disorders involving the immune mechanism, unspecified"
    },
    {
      "name": "E000",
      "description": "Congenital iodine-deficiency syndrome, neurological type"
    },
    {
      "name": "E001",
      "description": "Congenital iodine-deficiency syndrome, myxoedematous type"
    },
    {
      "name": "E002",
      "description": "Congenital iodine-deficiency syndrome, mixed type"
    },
    {
      "name": "E009",
      "description": "Congenital iodine-deficiency syndrome, unspecified"
    },
    {
      "name": "E010",
      "description": "Iodine-deficiency-related diffuse (endemic) goitre"
    },
    {
      "name": "E011",
      "description": "Iodine-deficiency-related multinodular (endemic) goitre"
    },
    {
      "name": "E012",
      "description": "Iodine-deficiency-related (endemic) goitre, unspecified"
    },
    {
      "name": "E018",
      "description": "Other iodine-deficiency-related thryroid disorders and allied conditions"
    },
    {
      "name": "E02",
      "description": "Subclinical iodine-deficiency hypothyroidism"
    },
    {
      "name": "E030",
      "description": "Congenital hypothyroidism with diffuse goitre"
    },
    {
      "name": "E031",
      "description": "Congenital hypothyroidism without  diffuse goitre"
    },
    {
      "name": "E032",
      "description": "Hypothyroidism due to medicaments and other exogenous substances"
    },
    {
      "name": "E033",
      "description": "Postinfectious hypothyroidism"
    },
    {
      "name": "E034",
      "description": "Atrophy of thyroid (acquired)"
    },
    {
      "name": "E035",
      "description": "Myxoedema coma"
    },
    {
      "name": "E036",
      "description": "Hypothyroidism from Hashimoto’s thyroiditis"
    },
    {
      "name": "E038",
      "description": "Other specified hypothyroidism"
    },
    {
      "name": "E039",
      "description": "Hypothyroidism, unspecified"
    },
    {
      "name": "E040",
      "description": "Nontoxic diffuse goitre"
    },
    {
      "name": "E041",
      "description": "Nontoxic single thyroid nodule"
    },
    {
      "name": "E042",
      "description": "Nontoxic multinodular goitre"
    },
    {
      "name": "E048",
      "description": "Other specified nontoxic goitre"
    },
    {
      "name": "E049",
      "description": "Nontoxic goitre, unspecified"
    },
    {
      "name": "E050",
      "description": "Thyrotoxicosis with diffuse goitre"
    },
    {
      "name": "E051",
      "description": "Thyrotoxicosis with toxic single thyroid nodule"
    },
    {
      "name": "E052",
      "description": "Thyrotoxicosis with toxic multinodular goitre"
    },
    {
      "name": "E053",
      "description": "Thyrotoxicosis from ectopic thyroid tissue"
    },
    {
      "name": "E054",
      "description": "Thyrotoxicosis factitia"
    },
    {
      "name": "E055",
      "description": "Thyroid crisis or storm"
    },
    {
      "name": "E058",
      "description": "Other thyrotoxicosis"
    },
    {
      "name": "E059",
      "description": "Thyrotoxicosis, unspecified"
    },
    {
      "name": "E060",
      "description": "Acute thyroiditis"
    },
    {
      "name": "E061",
      "description": "Subacute thyroiditis"
    },
    {
      "name": "E062",
      "description": "Chronic thyroiditis with transient  thyrotoxicosis"
    },
    {
      "name": "E063",
      "description": "Autoimmune thyroiditis"
    },
    {
      "name": "E064",
      "description": "Drug-induced thyroiditis"
    },
    {
      "name": "E065",
      "description": "Other chronic  thyroiditis"
    },
    {
      "name": "E066",
      "description": "Transient painless thyroiditis, sporadic"
    },
    {
      "name": "E069",
      "description": "Thyroiditis, unspecified"
    },
    {
      "name": "E070",
      "description": "Hypersecretion of calcitionin"
    },
    {
      "name": "E071",
      "description": "Dyshormogenetic goitre"
    },
    {
      "name": "E072",
      "description": "Thyroid hormone resistant syndrome"
    },
    {
      "name": "E078",
      "description": "Other specified disorders of thyroid"
    },
    {
      "name": "E079",
      "description": "Disorder of thyroid, unspecified"
    },
    {
      "name": "E100",
      "description": "Type 1 diabetes mellitus at with coma"
    },
    {
      "name": "E101",
      "description": "Type 1 diabetes mellitus at with ketoacidosis"
    },
    {
      "name": "E102",
      "description": "Type 1 diabetes mellitus at with renal complications"
    },
    {
      "name": "E103",
      "description": "Type 1 diabetes mellitus at with ophthalmic complication"
    },
    {
      "name": "E104",
      "description": "Type 1 diabetes mellitus at with neurological complications"
    },
    {
      "name": "E105",
      "description": "Type 1 diabetes mellitus at with peripheral circulatory complications"
    },
    {
      "name": "E106",
      "description": "Type 1 diabetes mellitus at with other specified complications"
    },
    {
      "name": "E107",
      "description": "Type 1 diabetes mellitus at with multiple complications"
    },
    {
      "name": "E108",
      "description": "Type 1 diabetes mellitus at with unspecified complications"
    },
    {
      "name": "E109",
      "description": "Type 1 diabetes mellitus at without complications"
    },
    {
      "name": "E110",
      "description": "Type 2 diabetes mellitus at with coma"
    },
    {
      "name": "E111",
      "description": "Type 2 diabetes mellitusat with ketoacidosis"
    },
    {
      "name": "E112",
      "description": "Type 2 diabetes mellitus at with renal complications"
    },
    {
      "name": "E113",
      "description": "Type 2 diabetes mellitus at with ophthalmic complications"
    },
    {
      "name": "E114",
      "description": "Type 2 diabetes mellitus at with neurological complications"
    },
    {
      "name": "E115",
      "description": "Type 2 diabetes mellitus at with peripheral circulatory complications"
    },
    {
      "name": "E116",
      "description": "Type 2 diabetes mellitus at with other specified complications"
    },
    {
      "name": "E117",
      "description": "Type 2 diabetes mellitus at with multiple complications"
    },
    {
      "name": "E118",
      "description": "Type 2 diabetes mellitus at with unspecified complications"
    },
    {
      "name": "E119",
      "description": "Type 2 diabetes mellitus at without complications"
    },
    {
      "name": "E120",
      "description": "Malnutrition-related diabetes mellitus at with coma"
    },
    {
      "name": "E121",
      "description": "Malnutrition-related diabetes mellitus at with ketoacidosis"
    },
    {
      "name": "E122",
      "description": "Malnutrition-related diabetes mellitus at with renal complications"
    },
    {
      "name": "E123",
      "description": "Malnutrition-related diabetes mellitus at with  ophthalmic  complications"
    },
    {
      "name": "E124",
      "description": "Malnutrition-related diabetes mellitus at with neurological complications"
    },
    {
      "name": "E125",
      "description": "Malnutrition-related diabetes mellitus at with peripheral circulatory complications"
    },
    {
      "name": "E126",
      "description": "Malnutrition-related diabetes mellitus at with other specified complications"
    },
    {
      "name": "E127",
      "description": "Malnutrition-related diabetes mellitus at with multiple complications"
    },
    {
      "name": "E128",
      "description": "Malnutrition-related diabetes mellitus at with unspecified complications"
    },
    {
      "name": "E129",
      "description": "Malnutrition-related diabetes mellitus at without complications"
    },
    {
      "name": "E130",
      "description": "Other specified diabetes mellitus at with coma"
    },
    {
      "name": "E131",
      "description": "Other specified diabetes mellitus at with ketoacidosis"
    },
    {
      "name": "E132",
      "description": "Other specified diabetes mellitus at with renal complications"
    },
    {
      "name": "E133",
      "description": "Other specified diabetes mellitus at with ophthalmic complications"
    },
    {
      "name": "E134",
      "description": "Other specified diabetes mellitus at with neurological complications"
    },
    {
      "name": "E135",
      "description": "Other specified diabetes mellitus at with peripheral circulatory complications"
    },
    {
      "name": "E136",
      "description": "Other specified diabetes mellitus at with other specified complications"
    },
    {
      "name": "E137",
      "description": "Other specified diabetes mellitus at with multiple complications"
    },
    {
      "name": "E138",
      "description": "Other specified diabetes mellitus at with unspecified complications"
    },
    {
      "name": "E139",
      "description": "Other specified diabetes mellitus at without complications"
    },
    {
      "name": "E140",
      "description": "Unspecified diabetes mellitus at with coma"
    },
    {
      "name": "E141",
      "description": "Unspecified diabetes mellitus at with ketoacidosis"
    },
    {
      "name": "E142",
      "description": "Unspecified diabetes mellitus at with renal complications"
    },
    {
      "name": "E143",
      "description": "Unspecified diabetes mellitus at with ophthalmic complications"
    },
    {
      "name": "E144",
      "description": "Unspecified diabetes mellitus at with neurological complications"
    },
    {
      "name": "E145",
      "description": "Unspecified diabetes mellitus at with peripheral circulatory complications"
    },
    {
      "name": "E146",
      "description": "Unspecified diabetes mellitus at with other specified complications"
    },
    {
      "name": "E147",
      "description": "Unspecified diabetes mellitus at with multiple complications"
    },
    {
      "name": "E148",
      "description": "Unspecified diabetes mellitus at with unspecified complications"
    },
    {
      "name": "E149",
      "description": "Unspecified diabetes mellitus at without complications"
    },
    {
      "name": "E15",
      "description": "Nondiabetic hypoglycaemic coma"
    },
    {
      "name": "E160",
      "description": "Drug-induced hypoglycaemia without coma"
    },
    {
      "name": "E161",
      "description": "Other hypoglycaemia"
    },
    {
      "name": "E162",
      "description": "Hypoglycaemia, unspecified"
    },
    {
      "name": "E163",
      "description": "Increased secretion of glucagon"
    },
    {
      "name": "E164",
      "description": "Abnormal secretion of gastrin"
    },
    {
      "name": "E168",
      "description": "Other specified disorders of pancreatic internal secretion"
    },
    {
      "name": "E169",
      "description": "Disorder of pancreatic internal secretion, unspecified"
    },
    {
      "name": "E200",
      "description": "Idiopathic hypoparathyroidism"
    },
    {
      "name": "E201",
      "description": "Pseudohypoparathyroidism"
    },
    {
      "name": "E208",
      "description": "Other hypoparathyroidism"
    },
    {
      "name": "E209",
      "description": "Hypoparathyroidism, unspecified"
    },
    {
      "name": "E210",
      "description": "Primary hyperparathyroidism"
    },
    {
      "name": "E211",
      "description": "Secondary hyperparathyroidism, not elsewhere classified"
    },
    {
      "name": "E212",
      "description": "Other hyperparathyroidism"
    },
    {
      "name": "E213",
      "description": "Hyperparathyroidism, unspecified"
    },
    {
      "name": "E214",
      "description": "Other specified disorders of parathyroid gland"
    },
    {
      "name": "E215",
      "description": "Disorder of parathyroid gland, unspecified"
    },
    {
      "name": "E220",
      "description": "Acromegaly and pituitary gigantism"
    },
    {
      "name": "E221",
      "description": "Hyperprolactinaemia"
    },
    {
      "name": "E222",
      "description": "Syndrome of inappropriate secretion of antidiuretic hormone"
    },
    {
      "name": "E228",
      "description": "Other hyperfunction of pituitary gland"
    },
    {
      "name": "E229",
      "description": "Hyperfunction of pituitary gland, unspecified"
    },
    {
      "name": "E2300",
      "description": "Hypogonadotropic hypogonadism"
    },
    {
      "name": "E2301",
      "description": "Idiopathic growth hormone deficiency"
    },
    {
      "name": "E2302",
      "description": "Isolated deficiency of adrenocorticotrophic hormone [ACTH]"
    },
    {
      "name": "E2303",
      "description": "Isolated deficiency of thyroid stimulating-hormone [TSH]"
    },
    {
      "name": "E2304",
      "description": "Necrosis of pituitary gland (postpartum)"
    },
    {
      "name": "E2305",
      "description": "Panhypopituitarism"
    },
    {
      "name": "E2306",
      "description": "Partial deficiency of anterior pituitary hormone other than isolated hormone deficiency"
    },
    {
      "name": "E2308",
      "description": "Other hypopituitarism"
    },
    {
      "name": "E2309",
      "description": "Hypopituitarism, unspecified"
    },
    {
      "name": "E231",
      "description": "Drug-induced hypopituitarism"
    },
    {
      "name": "E232",
      "description": "Diabetes insipidus"
    },
    {
      "name": "E233",
      "description": "Hypothalamic dysfunction, not elsewhere classified"
    },
    {
      "name": "E236",
      "description": "Other disorders of pituitary gland"
    },
    {
      "name": "E237",
      "description": "Disorder of pituitary gland, unspecified"
    },
    {
      "name": "E240",
      "description": "Pituitary-dependent Cushing's disease"
    },
    {
      "name": "E241",
      "description": "Nelson's syndrome"
    },
    {
      "name": "E242",
      "description": "Drug-induced Cushing's syndrome"
    },
    {
      "name": "E243",
      "description": "Ectopic ACTH syndrome"
    },
    {
      "name": "E244",
      "description": "Alcohol-induced pseudo-Cushing's syndrome"
    },
    {
      "name": "E248",
      "description": "Other Cushing's syndrome"
    },
    {
      "name": "E249",
      "description": "Cushing' s syndrome, unspecified"
    },
    {
      "name": "E250",
      "description": "Congenital adrenogenital disorders associated with enzyme deficiency"
    },
    {
      "name": "E258",
      "description": "Other adrenogenital disorders"
    },
    {
      "name": "E259",
      "description": "Adrenogenital disorder, unspecified"
    },
    {
      "name": "E260",
      "description": "Primary hyperaldosteronism"
    },
    {
      "name": "E261",
      "description": "Secondary hyperaldosteronism"
    },
    {
      "name": "E268",
      "description": "Other hyperaldosteronism"
    },
    {
      "name": "E269",
      "description": "Hyperaldosteronism, unspecified"
    },
    {
      "name": "E270",
      "description": "Other adrenocortical overactivity"
    },
    {
      "name": "E271",
      "description": "Primary adrenocortical insufficiency"
    },
    {
      "name": "E272",
      "description": "Addisonian crisis"
    },
    {
      "name": "E273",
      "description": "Drug-induced adrenocortical insufficiency"
    },
    {
      "name": "E274",
      "description": "Other and unspecified adrenocortical insufficiency"
    },
    {
      "name": "E275",
      "description": "Adrenomedullary hyperfunction"
    },
    {
      "name": "E278",
      "description": "Other specified disorders of adrenal gland"
    },
    {
      "name": "E279",
      "description": "Disorder of adrenal gland, unspecified"
    },
    {
      "name": "E280",
      "description": "Estrogen excess"
    },
    {
      "name": "E281",
      "description": "Androgen excess"
    },
    {
      "name": "E282",
      "description": "Polycystic ovarian syndrome"
    },
    {
      "name": "E283",
      "description": "Primary ovarian failure"
    },
    {
      "name": "E288",
      "description": "Other ovarian dysfuction"
    },
    {
      "name": "E289",
      "description": "Ovarian dysfunction, unspecified"
    },
    {
      "name": "E290",
      "description": "Testicular hyperfunction"
    },
    {
      "name": "E291",
      "description": "Testicular hypofunction"
    },
    {
      "name": "E298",
      "description": "Other testicular dysfunction"
    },
    {
      "name": "E299",
      "description": "Testicular dysfunction,unspecified"
    },
    {
      "name": "E300",
      "description": "Delayed puberty"
    },
    {
      "name": "E301",
      "description": "Precocious puberty"
    },
    {
      "name": "E308",
      "description": "Other disorders of puberty"
    },
    {
      "name": "E309",
      "description": "Disorder of puberty, unspecified"
    },
    {
      "name": "E310",
      "description": "Autoimmune polyglandular failure"
    },
    {
      "name": "E311",
      "description": "Polyglandular hyperfunction"
    },
    {
      "name": "E318",
      "description": "Other polyglandular dysfunction"
    },
    {
      "name": "E319",
      "description": "Polyglandular dysfunction, unspecified"
    },
    {
      "name": "E320",
      "description": "Persistent hyperplasia of thymus"
    },
    {
      "name": "E321",
      "description": "Abscess of thymus"
    },
    {
      "name": "E328",
      "description": "Other diseases of thymus"
    },
    {
      "name": "E329",
      "description": "Disease of thymus, unspecified"
    },
    {
      "name": "E340",
      "description": "Carcinoid syndrome"
    },
    {
      "name": "E341",
      "description": "Other hypersecretion of intestinal hormones"
    },
    {
      "name": "E342",
      "description": "Ectopic hormone secretion, not elsewhere classified"
    },
    {
      "name": "E343",
      "description": "Short stature, not elsewhere classified"
    },
    {
      "name": "E344",
      "description": "Constituional tall stature"
    },
    {
      "name": "E345",
      "description": "Androgen resistance syndrome"
    },
    {
      "name": "E348",
      "description": "Other specified endocrine disorders"
    },
    {
      "name": "E349",
      "description": "Endocrine disorder, unspecified"
    },
    {
      "name": "E350",
      "description": "Disorders of thyroid gland in diseases classified elsewhere"
    },
    {
      "name": "E351",
      "description": "Disorders of adrenal gland in diseases classified elsewhere"
    },
    {
      "name": "E358",
      "description": "Disorders of other endocrine glands in diseases classified elsewhere"
    },
    {
      "name": "E40",
      "description": "Kwashiorkor"
    },
    {
      "name": "E41",
      "description": "Nutritional marsmus"
    },
    {
      "name": "E42",
      "description": "Marasmic kwashiorkor"
    },
    {
      "name": "E43",
      "description": "Unspecified severe protein-energy malnutrition"
    },
    {
      "name": "E440",
      "description": "Moderate protein-energy malnutrition"
    },
    {
      "name": "E441",
      "description": "Mild protein-energy malnutrition"
    },
    {
      "name": "E45",
      "description": "Retarded development following protein-energy malnutrition"
    },
    {
      "name": "E46",
      "description": "Unspecified protein-energy malnutrition"
    },
    {
      "name": "E500",
      "description": "Vitamin A deficiency with conjunctival xerosis"
    },
    {
      "name": "E501",
      "description": "Vitamin A deficiency with Bitot's spot and conjunctival xerosis"
    },
    {
      "name": "E502",
      "description": "Vitamin A deficiency with corneal xerosis"
    },
    {
      "name": "E503",
      "description": "Vitamin A deficiency with corneal ulceration and xerosis"
    },
    {
      "name": "E504",
      "description": "Vitamin A deficiency with keratomalacia"
    },
    {
      "name": "E505",
      "description": "Vitamin A deficiency with night blindness"
    },
    {
      "name": "E506",
      "description": "Vitamin A deficiency with xerophthalmic scars of cornea"
    },
    {
      "name": "E507",
      "description": "Other ocular manifestations of vitamin A deficiency"
    },
    {
      "name": "E508",
      "description": "Other manifestations of vitamin A deficiency"
    },
    {
      "name": "E509",
      "description": "Vitamin A deficiency, unspecified"
    },
    {
      "name": "E511",
      "description": "Beriberi"
    },
    {
      "name": "E512",
      "description": "Wernicke's encephalopathy"
    },
    {
      "name": "E518",
      "description": "Other manifestations of thiamine deficiency"
    },
    {
      "name": "E519",
      "description": "Thiamine deficiency, unspecified"
    },
    {
      "name": "E52",
      "description": "Niacin deficiency [pellagra]"
    },
    {
      "name": "E530",
      "description": "Riboflavin deficiency"
    },
    {
      "name": "E531",
      "description": "Pyridoxine deficiency"
    },
    {
      "name": "E538",
      "description": "Deficiency of other specified B group vitamins"
    },
    {
      "name": "E539",
      "description": "Vitamin B deficiency, unspecified"
    },
    {
      "name": "E54",
      "description": "Ascorbic acid deficiency"
    },
    {
      "name": "E550",
      "description": "Rickets, active"
    },
    {
      "name": "E559",
      "description": "Vitamin D deficiency, unspecified"
    },
    {
      "name": "E560",
      "description": "Deficiency of vitamin E"
    },
    {
      "name": "E561",
      "description": "Deficiency of vitamin K"
    },
    {
      "name": "E568",
      "description": "Deficiency of other vitamins"
    },
    {
      "name": "E569",
      "description": "Vitamin deficiency, unspecified"
    },
    {
      "name": "E58",
      "description": "Dietary calcium deficiency"
    },
    {
      "name": "E59",
      "description": "Dietary selenium deficiency"
    },
    {
      "name": "E60",
      "description": "Dietary zinc deficiency"
    },
    {
      "name": "E610",
      "description": "Copper deficiency"
    },
    {
      "name": "E611",
      "description": "Iron deficiency"
    },
    {
      "name": "E612",
      "description": "Magnesium deficiency"
    },
    {
      "name": "E613",
      "description": "Manganese deficiency"
    },
    {
      "name": "E614",
      "description": "Chromium deficiency"
    },
    {
      "name": "E615",
      "description": "Molybdenum deficiency"
    },
    {
      "name": "E616",
      "description": "Vanadium deficiency"
    },
    {
      "name": "E617",
      "description": "Deficiency of multiple nutrient elements"
    },
    {
      "name": "E618",
      "description": "Deficiency of other specified nutrient elements"
    },
    {
      "name": "E619",
      "description": "Deficiency of nutrient element, unspecified"
    },
    {
      "name": "E630",
      "description": "Essential fatty acid [EFA] deficiency"
    },
    {
      "name": "E631",
      "description": "Imbalance of constituents of food intake"
    },
    {
      "name": "E638",
      "description": "Other specified nutritional deficiencies"
    },
    {
      "name": "E639",
      "description": "Nutritional deficiency, unspecified"
    },
    {
      "name": "E640",
      "description": "Sequelae of protein-energy malnutrition"
    },
    {
      "name": "E641",
      "description": "Sequelae of vitamin A deficiency"
    },
    {
      "name": "E642",
      "description": "Sequelae of Vitamin C deficiency"
    },
    {
      "name": "E643",
      "description": "Sequelae of rickets"
    },
    {
      "name": "E648",
      "description": "Sequelae of other nutritional deficiencies"
    },
    {
      "name": "E649",
      "description": "Sequelae of unspecified nutritional deficirncy"
    },
    {
      "name": "E65",
      "description": "Localized adiposity"
    },
    {
      "name": "E660",
      "description": "Obesity due to excess calories"
    },
    {
      "name": "E661",
      "description": "Drug-induced obesity"
    },
    {
      "name": "E662",
      "description": "Extreme obesity with alveolar hypoventilation"
    },
    {
      "name": "E668",
      "description": "Other obesity"
    },
    {
      "name": "E669",
      "description": "Obesity, unspecified"
    },
    {
      "name": "E670",
      "description": "Hypervitaminosis A"
    },
    {
      "name": "E671",
      "description": "Hypercaroteaemia"
    },
    {
      "name": "E672",
      "description": "Megavitamin-B6 syndrome"
    },
    {
      "name": "E673",
      "description": "Hypervitaminosis D"
    },
    {
      "name": "E678",
      "description": "Other specified hyperalimentation"
    },
    {
      "name": "E68",
      "description": "Sequelae of hyperalimentation"
    },
    {
      "name": "E700",
      "description": "Classical phenylketonuria"
    },
    {
      "name": "E701",
      "description": "Other hyperphenylalaninaemias"
    },
    {
      "name": "E702",
      "description": "Disorders of tyrosine metabolism"
    },
    {
      "name": "E703",
      "description": "Albinism"
    },
    {
      "name": "E708",
      "description": "Other disorders of aromatic mino-acid metabolism"
    },
    {
      "name": "E709",
      "description": "Disorder of aromatic amino-acid metabolism, unspecified"
    },
    {
      "name": "E710",
      "description": "Maple-syrup-urine disease"
    },
    {
      "name": "E711",
      "description": "Other disorders of branched-chain amino-acid metabolism"
    },
    {
      "name": "E712",
      "description": "Disorder of branched-chain amino-acid metabolism, unspecified"
    },
    {
      "name": "E713",
      "description": "Disorders of fatty-acid metabolsim"
    },
    {
      "name": "E720",
      "description": "Disorders of amino-acid transport"
    },
    {
      "name": "E721",
      "description": "Disorders of sulfur-bearing amino-acid metabolism"
    },
    {
      "name": "E722",
      "description": "Disorders of urea cycle metabolism"
    },
    {
      "name": "E723",
      "description": "Disorders of lysine and hydroxylysine metabolism"
    },
    {
      "name": "E724",
      "description": "Disorders of ornithine metabolism"
    },
    {
      "name": "E725",
      "description": "Disorders of glycine metabolism"
    },
    {
      "name": "E728",
      "description": "Other specified disorders of amino-acid metabolism"
    },
    {
      "name": "E729",
      "description": "Disorder of amino-acid metabolism, unspecified"
    },
    {
      "name": "E730",
      "description": "Congenital lactase deficiency"
    },
    {
      "name": "E731",
      "description": "Secondary lactase deficiency"
    },
    {
      "name": "E738",
      "description": "Other lactose intolerance"
    },
    {
      "name": "E739",
      "description": "Lactose intolerance, unspecified"
    },
    {
      "name": "E740",
      "description": "Glycogen storage disease"
    },
    {
      "name": "E741",
      "description": "Disorders of fructose metabolism"
    },
    {
      "name": "E742",
      "description": "Disorders of galactose metabolism"
    },
    {
      "name": "E7430",
      "description": "Glucose malabsorption"
    },
    {
      "name": "E7438",
      "description": "Other disorders of intestinal carbohydrate metabolism"
    },
    {
      "name": "E744",
      "description": "Disorders of pyruvate metabolism and gluconeogenesis"
    },
    {
      "name": "E748",
      "description": "Other specified disorders of carbohydrate metabolism"
    },
    {
      "name": "E749",
      "description": "Disorder of carbohydrate metabolism, unspecified"
    },
    {
      "name": "E750",
      "description": "GM2 gangliosidosis"
    },
    {
      "name": "E751",
      "description": "Other gangliosidosis"
    },
    {
      "name": "E752",
      "description": "Other sphingolipidosis"
    },
    {
      "name": "E753",
      "description": "Sphingolipidosis, unspecified"
    },
    {
      "name": "E754",
      "description": "Neuronal ceroid lipofuscinosis"
    },
    {
      "name": "E755",
      "description": "Other lipid storage disorders"
    },
    {
      "name": "E756",
      "description": "Lipid storage disorder, unsprecified"
    },
    {
      "name": "E760",
      "description": "Mucopolysaccharidosis, type I"
    },
    {
      "name": "E761",
      "description": "Mucopolysaccharidosis, type II"
    },
    {
      "name": "E762",
      "description": "Other mucopolysaccharidosis"
    },
    {
      "name": "E763",
      "description": "Mucopolysaccharidosis, unspecified"
    },
    {
      "name": "E768",
      "description": "Other disorders of glucosaminoglycan metabolism"
    },
    {
      "name": "E769",
      "description": "Disorder of glucosaminoglycan metabolism,unspecified"
    },
    {
      "name": "E770",
      "description": "Defects in post-translational modification of lysosomal enzymes"
    },
    {
      "name": "E771",
      "description": "Defects in glycoprotein degradation"
    },
    {
      "name": "E778",
      "description": "Other disorders of glycoprotein metabolism"
    },
    {
      "name": "E779",
      "description": "Disorder of glycoprotein metabolism, unspecified"
    },
    {
      "name": "E780",
      "description": "Pure hypercholesterolaemia"
    },
    {
      "name": "E781",
      "description": "Pure hyperglyceridaemia"
    },
    {
      "name": "E782",
      "description": "Mixed hyperlipidaemia"
    },
    {
      "name": "E783",
      "description": "Hyperchylomicronaemia"
    },
    {
      "name": "E784",
      "description": "Other hyperlipidaemia"
    },
    {
      "name": "E785",
      "description": "Hyperlipidaemia, unspecified"
    },
    {
      "name": "E786",
      "description": "Lipoprotein deficiency"
    },
    {
      "name": "E788",
      "description": "Other disorders of lipoprotein metabolism"
    },
    {
      "name": "E789",
      "description": "Disorder of lipoprotein metabolism,unspecified"
    },
    {
      "name": "E790",
      "description": "Hyperuricaemia without signs of inflammatory arthritis and tophaceous disease"
    },
    {
      "name": "E791",
      "description": "Lesch-Nyhan syndrome"
    },
    {
      "name": "E798",
      "description": "Other disorders of purine and pyrimidine metabolism"
    },
    {
      "name": "E799",
      "description": "Disorder of purine and pyrimidine metabolism, unspecified"
    },
    {
      "name": "E800",
      "description": "Hereditary erythropoietic porphyria"
    },
    {
      "name": "E801",
      "description": "Porphyria cutanea tarda"
    },
    {
      "name": "E802",
      "description": "Other porphyria"
    },
    {
      "name": "E803",
      "description": "Defects of catalase and peroxidase"
    },
    {
      "name": "E804",
      "description": "Gilbert's syndrome"
    },
    {
      "name": "E805",
      "description": "Crigler-Najjar syndrome"
    },
    {
      "name": "E806",
      "description": "Other disorders of bilirubin metabolism"
    },
    {
      "name": "E807",
      "description": "Disorder of bilirubin metabolism, unspecified"
    },
    {
      "name": "E830",
      "description": "Disorders of copper metabolism"
    },
    {
      "name": "E831",
      "description": "Disorders of iron metabolism"
    },
    {
      "name": "E832",
      "description": "Disorders of zinc metabolism"
    },
    {
      "name": "E833",
      "description": "Disorders of phosphorus metabolism and phosphatase"
    },
    {
      "name": "E834",
      "description": "Disorders of magnesium metabolism"
    },
    {
      "name": "E835",
      "description": "Disorders of calcium metabolism"
    },
    {
      "name": "E838",
      "description": "Other disorders of mineral metabolism"
    },
    {
      "name": "E839",
      "description": "Disorders of mineral metabolism, unspecified"
    },
    {
      "name": "E840",
      "description": "Cystic fibrosis with pulmonary manifestations"
    },
    {
      "name": "E841",
      "description": "Cystic fibrosis with intestinal manifestations"
    },
    {
      "name": "E848",
      "description": "Cystic fibrosis with other manifestations"
    },
    {
      "name": "E849",
      "description": "Cystic fibrosis, unspecified"
    },
    {
      "name": "E850",
      "description": "Non-neuropathic heredofamilial amyloidosis"
    },
    {
      "name": "E851",
      "description": "Neuropathic heredofamilial amyloidosis"
    },
    {
      "name": "E852",
      "description": "Heredofamilial amyloidosis, unspecified"
    },
    {
      "name": "E853",
      "description": "Secondary systemic amyloidosis"
    },
    {
      "name": "E854",
      "description": "Organ-limited amyloidosis"
    },
    {
      "name": "E858",
      "description": "Other amyloidosis"
    },
    {
      "name": "E859",
      "description": "Amyloidosis, unspecified"
    },
    {
      "name": "E86",
      "description": "Volume depletion"
    },
    {
      "name": "E870",
      "description": "Hyperosmolality and hypernatraemia"
    },
    {
      "name": "E871",
      "description": "Hypo-osmolality and hyponatraemia"
    },
    {
      "name": "E872",
      "description": "Acidosis"
    },
    {
      "name": "E873",
      "description": "Alkalosis"
    },
    {
      "name": "E874",
      "description": "Mixed disorder of acid-base balance"
    },
    {
      "name": "E875",
      "description": "Hyperkalaemia"
    },
    {
      "name": "E876",
      "description": "Hypokalaemia"
    },
    {
      "name": "E877",
      "description": "Fluid overload"
    },
    {
      "name": "E878",
      "description": "Other disorders of electrolyte and fluid balance, not elsewhere classified"
    },
    {
      "name": "E880",
      "description": "Disorders of plasma-protein metabolism, not elsewhere classified"
    },
    {
      "name": "E881",
      "description": "Lipodystrophy, not elsewhere classified"
    },
    {
      "name": "E882",
      "description": "Lipomatosis, not elsewhere classified"
    },
    {
      "name": "E883",
      "description": "Tumour lysis syndrome "
    },
    {
      "name": "E884",
      "description": "Metabolic syndrome"
    },
    {
      "name": "E888",
      "description": "Other specified metabolic disorders"
    },
    {
      "name": "E889",
      "description": "Metabolic disorder, unspecified"
    },
    {
      "name": "E890",
      "description": "Postprocedural hypothyroidism"
    },
    {
      "name": "E891",
      "description": "Postprocedural hypoinsulinaemia"
    },
    {
      "name": "E892",
      "description": "Postprocedural hypoparathyroidism"
    },
    {
      "name": "E893",
      "description": "Postprocedural hypopituitarism"
    },
    {
      "name": "E894",
      "description": "Postprocedural ovarian failure"
    },
    {
      "name": "E895",
      "description": "Postprocedural testicular hypofunction"
    },
    {
      "name": "E896",
      "description": "Postprocedural adrenocortical[-medullary] hypofunction"
    },
    {
      "name": "E898",
      "description": "Other postprocedural endocrine and metabolic disorders"
    },
    {
      "name": "E899",
      "description": "Postprocedural endocrine and metabolic disorder, unspecified"
    },
    {
      "name": "E90",
      "description": "Nutritional and metabolic disorders in diseases classified elsewhere"
    },
    {
      "name": "F000",
      "description": "Dementia in Alzheimer's disease with early onset (G30.0*)"
    },
    {
      "name": "F001",
      "description": "Dementia in Alzheimer's disease with late onset (G30.1*)"
    },
    {
      "name": "F002",
      "description": "Dementia in Alzheimer's disease, atypical or mixed type (G30.8*)"
    },
    {
      "name": "F009",
      "description": "Dementia in Alzheimer's disease, unspecified (G30.9*)"
    },
    {
      "name": "F010",
      "description": "Vascular dementia of acute onset"
    },
    {
      "name": "F011",
      "description": "Multi-infarct dementia"
    },
    {
      "name": "F012",
      "description": "Subcortical vascular dementia"
    },
    {
      "name": "F013",
      "description": "Mixed cortical and subcortical vascular dementia"
    },
    {
      "name": "F018",
      "description": "Other vascular dementia"
    },
    {
      "name": "F019",
      "description": "Vasclar dementia, uspecified"
    },
    {
      "name": "F020",
      "description": "Dementia in Pick's disease (G31.0*)"
    },
    {
      "name": "F021",
      "description": "Dementia in Creutzfedt-Jakob disease (A81.0*)"
    },
    {
      "name": "F022",
      "description": "Dementia in Huntington's disease (G10*)"
    },
    {
      "name": "F023",
      "description": "Dementia in Parkinson's disease (G20*)"
    },
    {
      "name": "F024",
      "description": "Dementia in human immunodeficiency virus [HIV] disease (B22.0*)"
    },
    {
      "name": "F028",
      "description": "Dementia in other specified diseases classified elsewhere"
    },
    {
      "name": "F03",
      "description": "Unspecified dementia"
    },
    {
      "name": "F04",
      "description": "Organic amnesic syndrome, not induced by alcohol and other psychoactive substances"
    },
    {
      "name": "F050",
      "description": "Delirium not superimposed on dementia, so described"
    },
    {
      "name": "F051",
      "description": "Delirium superimposed on dementia"
    },
    {
      "name": "F058",
      "description": "Other delirium"
    },
    {
      "name": "F059",
      "description": "Delirium, unspecified"
    },
    {
      "name": "F060",
      "description": "Organic hallucinosis"
    },
    {
      "name": "F061",
      "description": "Organic catatonic disorder"
    },
    {
      "name": "F062",
      "description": "Organic delusional [schizophrenia-like] disorder"
    },
    {
      "name": "F063",
      "description": "Organic mood [affective] disorders"
    },
    {
      "name": "F064",
      "description": "Organic anxiety disorder"
    },
    {
      "name": "F065",
      "description": "Organic dissociative disorder"
    },
    {
      "name": "F066",
      "description": "Organic emotionally labile [asthenic] disorder"
    },
    {
      "name": "F067",
      "description": "Mild cognitive disorder"
    },
    {
      "name": "F068",
      "description": "Other specified mental disorders due to brain damage and dysfunction and to physical disease"
    },
    {
      "name": "F069",
      "description": "Unspecified mental disorder due to brain damage and dysfunction and to physical disease"
    },
    {
      "name": "F070",
      "description": "Organic personality disorder"
    },
    {
      "name": "F071",
      "description": "Postencephalitic syndrome"
    },
    {
      "name": "F072",
      "description": "Postconcussional syndrome"
    },
    {
      "name": "F078",
      "description": "Other organic personality and behavioural disorders due to brain disease, damage and dysfunction"
    },
    {
      "name": "F079",
      "description": "Unspecified organic personality and behavioural disorder due to brain disease, damage and dysfunction"
    },
    {
      "name": "F09",
      "description": "Unspecified organic or symptomatic mental disorder"
    },
    {
      "name": "F100",
      "description": "Mental and behavioural disorders due to use of alcohol at acute intoxication"
    },
    {
      "name": "F101",
      "description": "Mental and behavioural disorders due to use of alcohol at harmful  use"
    },
    {
      "name": "F102",
      "description": "Mental and behavioural disorders due to use of alcohol at dependence  syndrome"
    },
    {
      "name": "F103",
      "description": "Mental and behavioural disorders due to use of alcohol at withdrawal  state"
    },
    {
      "name": "F104",
      "description": "Mental and behavioural disorders due to use of alcohol at withdrawal  state with delirium"
    },
    {
      "name": "F105",
      "description": "Mental and behavioural disorders due to use of alcohol at psychotic disorder"
    },
    {
      "name": "F106",
      "description": "Mental and behavioural disorders due to use of alcohol at amnesic syndrome"
    },
    {
      "name": "F107",
      "description": "Mental and behavioural disorders due to use of alcohol at residual and late-onset psychotic disorder"
    },
    {
      "name": "F108",
      "description": "Mental and behavioural disorders due to use of alcohol at other mental and behavioural disorders"
    },
    {
      "name": "F109",
      "description": "Mental and behavioural disorders due to use of alcohol at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F110",
      "description": "Mental and behavioural disorders due to use of opioids at acute intoxication"
    },
    {
      "name": "F111",
      "description": "Mental and behavioural disorders due to use of opioids at harmful  use"
    },
    {
      "name": "F112",
      "description": "Mental and behavioural disorders due to use of opioids at dependence  syndrome"
    },
    {
      "name": "F113",
      "description": "Mental and behavioural disorders due to use of opioids at withdrawal  state"
    },
    {
      "name": "F114",
      "description": "Mental and behavioural disorders due to use of opioids at withdrawal  state with delirium"
    },
    {
      "name": "F115",
      "description": "Mental and behavioural disorders due to use of opioids at psychotic disorder"
    },
    {
      "name": "F116",
      "description": "Mental and behavioural disorders due to use of opioids at amnesic syndrome"
    },
    {
      "name": "F117",
      "description": "Mental and behavioural disorders due to use of opioids at residual and late-onset psychotic disorder"
    },
    {
      "name": "F118",
      "description": "Mental and behavioural disorders due to use of opioids at other mental and behavioural disorders"
    },
    {
      "name": "F119",
      "description": "Mental and behavioural disorders due to use of opioids at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F120",
      "description": "Mental and behavioural disorders due to use of cannabinoids at acute intoxication"
    },
    {
      "name": "F121",
      "description": "Mental and behavioural disorders due to use of cannabinoids at harmful  use"
    },
    {
      "name": "F122",
      "description": "Mental and behavioural disorders due to use of cannabinoids at dependence  syndrome"
    },
    {
      "name": "F123",
      "description": "Mental and behavioural disorders due to use of cannabinoids at withdrawal  state"
    },
    {
      "name": "F124",
      "description": "Mental and behavioural disorders due to use of cannabinoids at withdrawal  state with delirium"
    },
    {
      "name": "F125",
      "description": "Mental and behavioural disorders due to use of cannabinoids at psychotic disorder"
    },
    {
      "name": "F126",
      "description": "Mental and behavioural disorders due to use of cannabinoids at amnesic syndrome"
    },
    {
      "name": "F127",
      "description": "Mental and behavioural disorders due to use of cannabinoids at residual and late-onset psychotic disorder"
    },
    {
      "name": "F128",
      "description": "Mental and behavioural disorders due to use of cannabinoids at other mental and behavioural disorders"
    },
    {
      "name": "F129",
      "description": "Mental and behavioural disorders due to use of cannabinoids at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F130",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at acute intoxication"
    },
    {
      "name": "F131",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at harmful  use"
    },
    {
      "name": "F132",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at dependence  syndrome"
    },
    {
      "name": "F133",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at withdrawal  state"
    },
    {
      "name": "F134",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at withdrawal  state with delirium"
    },
    {
      "name": "F135",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at psychotic disorder"
    },
    {
      "name": "F136",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at amnesic syndrome"
    },
    {
      "name": "F137",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at residual and late-onset psychotic disorder"
    },
    {
      "name": "F138",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at other mental and behavioural disorders"
    },
    {
      "name": "F139",
      "description": "Mental and behavioural disorders due to use of sedatives or hypnotics at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F140",
      "description": "Mental and behavioural disorders due to use of cocaine at acute intoxication"
    },
    {
      "name": "F141",
      "description": "Mental and behavioural disorders due to use of cocaine at harmful  use"
    },
    {
      "name": "F142",
      "description": "Mental and behavioural disorders due to use of cocaine at dependence  syndrome"
    },
    {
      "name": "F143",
      "description": "Mental and behavioural disorders due to use of cocaine at withdrawal  state"
    },
    {
      "name": "F144",
      "description": "Mental and behavioural disorders due to use of cocaine at withdrawal  state with delirium"
    },
    {
      "name": "F145",
      "description": "Mental and behavioural disorders due to use of cocaine at psychotic disorder"
    },
    {
      "name": "F146",
      "description": "Mental and behavioural disorders due to use of cocaine at amnesic syndrome"
    },
    {
      "name": "F147",
      "description": "Mental and behavioural disorders due to use of cocaine at residual and late-onset psychotic disorder"
    },
    {
      "name": "F148",
      "description": "Mental and behavioural disorders due to use of cocaine at other mental and behavioural disorders"
    },
    {
      "name": "F149",
      "description": "Mental and behavioural disorders due to use of cocaine at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F150",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at acute intoxication"
    },
    {
      "name": "F151",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at harmful  use"
    },
    {
      "name": "F152",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at dependence  syndrome"
    },
    {
      "name": "F153",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at withdrawal  state"
    },
    {
      "name": "F154",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at withdrawal  state with delirium"
    },
    {
      "name": "F155",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at psychotic disorder"
    },
    {
      "name": "F156",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at amnesic syndrome"
    },
    {
      "name": "F157",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at residual and late-onset psychotic disorder"
    },
    {
      "name": "F158",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at other mental and behavioural disorders"
    },
    {
      "name": "F159",
      "description": "Mental and behavioural disorders due to use of other stimulants, including caffeine at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F160",
      "description": "Mental and behavioural disorders due to use of hallucinogens at acute intoxication"
    },
    {
      "name": "F161",
      "description": "Mental and behavioural disorders due to use of hallucinogens at harmful  use"
    },
    {
      "name": "F162",
      "description": "Mental and behavioural disorders due to use of hallucinogens at dependence  syndrome"
    },
    {
      "name": "F163",
      "description": "Mental and behavioural disorders due to use of hallucinogens at withdrawal  state"
    },
    {
      "name": "F164",
      "description": "Mental and behavioural disorders due to use of hallucinogens at withdrawal  state with delirium"
    },
    {
      "name": "F165",
      "description": "Mental and behavioural disorders due to use of hallucinogens at psychotic disorder"
    },
    {
      "name": "F166",
      "description": "Mental and behavioural disorders due to use of hallucinogens at amnesic syndrome"
    },
    {
      "name": "F167",
      "description": "Mental and behavioural disorders due to use of hallucinogens at residual and late-onset psychotic disorder"
    },
    {
      "name": "F168",
      "description": "Mental and behavioural disorders due to use of hallucinogens at other mental and behavioural disorders"
    },
    {
      "name": "F169",
      "description": "Mental and behavioural disorders due to use of hallucinogens at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F170",
      "description": "Mental and behavioural disorders due to use of tobacco at acute intoxication"
    },
    {
      "name": "F171",
      "description": "Mental and behavioural disorders due to use of tobacco at harmful  use"
    },
    {
      "name": "F172",
      "description": "Mental and behavioural disorders due to use of tobacco at dependence  syndrome"
    },
    {
      "name": "F173",
      "description": "Mental and behavioural disorders due to use of tobacco at withdrawal  state"
    },
    {
      "name": "F174",
      "description": "Mental and behavioural disorders due to use of tobacco at withdrawal  state with delirium"
    },
    {
      "name": "F175",
      "description": "Mental and behavioural disorders due to use of tobacco at psychotic disorder"
    },
    {
      "name": "F176",
      "description": "Mental and behavioural disorders due to use of tobacco at amnesic syndrome"
    },
    {
      "name": "F177",
      "description": "Mental and behavioural disorders due to use of tobacco at residual and late-onset psychotic disorder"
    },
    {
      "name": "F178",
      "description": "Mental and behavioural disorders due to use of tobacco at other mental and behavioural disorders"
    },
    {
      "name": "F179",
      "description": "Mental and behavioural disorders due to use of tobacco at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F180",
      "description": "Mental and behavioural disorders due to use of volatile solvents at acute intoxication"
    },
    {
      "name": "F181",
      "description": "Mental and behavioural disorders due to use of volatile solvents at harmful  use"
    },
    {
      "name": "F182",
      "description": "Mental and behavioural disorders due to use of volatile solvents at dependence  syndrome"
    },
    {
      "name": "F183",
      "description": "Mental and behavioural disorders due to use of volatile solvents at withdrawal  state"
    },
    {
      "name": "F184",
      "description": "Mental and behavioural disorders due to use of volatile solvents at withdrawal  state with delirium"
    },
    {
      "name": "F185",
      "description": "Mental and behavioural disorders due to use of volatile solvents at psychotic disorder"
    },
    {
      "name": "F186",
      "description": "Mental and behavioural disorders due to use of volatile solvents at amnesic syndrome"
    },
    {
      "name": "F187",
      "description": "Mental and behavioural disorders due to use of volatile solvents at residual and late-onset psychotic disorder"
    },
    {
      "name": "F188",
      "description": "Mental and behavioural disorders due to use of volatile solvents at other mental and behavioural disorders"
    },
    {
      "name": "F189",
      "description": "Mental and behavioural disorders due to use of volatile solvents at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F190",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at acute intoxication"
    },
    {
      "name": "F191",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at harmful  use"
    },
    {
      "name": "F192",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at dependence  syndrome"
    },
    {
      "name": "F193",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at withdrawal  state"
    },
    {
      "name": "F194",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at withdrawal  state with delirium"
    },
    {
      "name": "F195",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at psychotic disorder"
    },
    {
      "name": "F196",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at amnesic syndrome"
    },
    {
      "name": "F197",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at residual and late-onset psychotic disorder"
    },
    {
      "name": "F198",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at other mental and behavioural disorders"
    },
    {
      "name": "F199",
      "description": "Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances at unspecified  mental and behavioural disorders"
    },
    {
      "name": "F200",
      "description": "Paranoid schizophrenia"
    },
    {
      "name": "F2000",
      "description": "Paranoid schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2001",
      "description": "Paranoid schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2002",
      "description": "Paranoid schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2003",
      "description": "Paranoid schizophrenia,episodic remittent"
    },
    {
      "name": "F2004",
      "description": "Paranoid schizophrenia,incomplete remission"
    },
    {
      "name": "F2005",
      "description": "Paranoid schizophrenia,complete remission"
    },
    {
      "name": "F2008",
      "description": "Paranoid schizophrenia,other"
    },
    {
      "name": "F2009",
      "description": "Paranoid schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F201",
      "description": "Hebephrenic schizophrenia"
    },
    {
      "name": "F2010",
      "description": "Hebephrenic schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2011",
      "description": "Hebephrenic schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2012",
      "description": "Hebephrenic schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2013",
      "description": "Hebephrenic schizophrenia,episodic remittent"
    },
    {
      "name": "F2014",
      "description": "Hebephrenic schizophrenia,incomplete remission"
    },
    {
      "name": "F2015",
      "description": "Hebephrenic schizophrenia,complete remission"
    },
    {
      "name": "F2018",
      "description": "Hebephrenic schizophrenia,other"
    },
    {
      "name": "F2019",
      "description": "Hebephrenic schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F202",
      "description": "Catatonic schizophrenia"
    },
    {
      "name": "F2020",
      "description": "Catatonic schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2021",
      "description": "Catatonic schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2022",
      "description": "Catatonic schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2023",
      "description": "Catatonic schizophrenia,episodic remittent"
    },
    {
      "name": "F2024",
      "description": "Catatonic schizophrenia,incomplete remission"
    },
    {
      "name": "F2025",
      "description": "Catatonic schizophrenia,complete remission"
    },
    {
      "name": "F2028",
      "description": "Catatonic schizophrenia,other"
    },
    {
      "name": "F2029",
      "description": "Catatonic schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F203",
      "description": "Undifferentiated schizophrenia"
    },
    {
      "name": "F2030",
      "description": "Undifferentiated schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2031",
      "description": "Undifferentiated schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2032",
      "description": "Undifferentiated schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2033",
      "description": "Undifferentiated schizophrenia,episodic remittent"
    },
    {
      "name": "F2034",
      "description": "Undifferentiated schizophrenia,incomplete remission"
    },
    {
      "name": "F2035",
      "description": "Undifferentiated schizophrenia,complete remission"
    },
    {
      "name": "F2038",
      "description": "Undifferentiated schizophrenia,other"
    },
    {
      "name": "F2039",
      "description": "Undifferentiated schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F204",
      "description": "Post-schizophrenic depression"
    },
    {
      "name": "F2040",
      "description": "Post-schizophrenic depression,continuous (including treatment resistant)"
    },
    {
      "name": "F2041",
      "description": "Post-schizophrenic depression,episodic with progressive deficit"
    },
    {
      "name": "F2042",
      "description": "Post-schizophrenic depression,episodic with stable deficit"
    },
    {
      "name": "F2043",
      "description": "Post-schizophrenic depression,episodic remittent"
    },
    {
      "name": "F2044",
      "description": "Post-schizophrenic depression,incomplete remission"
    },
    {
      "name": "F2045",
      "description": "Post-schizophrenic depression,complete remission"
    },
    {
      "name": "F2048",
      "description": "Post-schizophrenic depression,other"
    },
    {
      "name": "F2049",
      "description": "Post-schizophrenic depression,course uncertain,period of observation is too short"
    },
    {
      "name": "F205",
      "description": "Residual schizophrenia"
    },
    {
      "name": "F2050",
      "description": "Residual schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2051",
      "description": "Residual schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2052",
      "description": "Residual schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2053",
      "description": "Residual schizophrenia,episodic remittent"
    },
    {
      "name": "F2054",
      "description": "Residual schizophrenia,incomplete remission"
    },
    {
      "name": "F2055",
      "description": "Residual schizophrenia,complete remission"
    },
    {
      "name": "F2058",
      "description": "Residual schizophrenia,other"
    },
    {
      "name": "F2059",
      "description": "Residual schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F206",
      "description": "Simple schizophrenia"
    },
    {
      "name": "F2060",
      "description": "Simple schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2061",
      "description": "Simple schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2062",
      "description": "Simple schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2063",
      "description": "Simple schizophrenia,episodic remittent"
    },
    {
      "name": "F2064",
      "description": "Simple schizophrenia,incomplete remission"
    },
    {
      "name": "F2065",
      "description": "Simple schizophrenia,complete remission"
    },
    {
      "name": "F2068",
      "description": "Simple schizophrenia,other"
    },
    {
      "name": "F2069",
      "description": "Simple schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F208",
      "description": "Other schizophrenia"
    },
    {
      "name": "F2080",
      "description": "Other schizophrenia,continuous (including treatment resistant)"
    },
    {
      "name": "F2081",
      "description": "Other schizophrenia,episodic with progressive deficit"
    },
    {
      "name": "F2082",
      "description": "Other schizophrenia,episodic with stable deficit"
    },
    {
      "name": "F2083",
      "description": "Other schizophrenia,episodic remittent"
    },
    {
      "name": "F2084",
      "description": "Other schizophrenia,incomplete remission"
    },
    {
      "name": "F2085",
      "description": "Other schizophrenia,complete remission"
    },
    {
      "name": "F2088",
      "description": "Other schizophrenia,other"
    },
    {
      "name": "F2089",
      "description": "Other schizophrenia,course uncertain,period of observation is too short"
    },
    {
      "name": "F209",
      "description": "Schizophrenia,unspecified"
    },
    {
      "name": "F2090",
      "description": "Schizophrenia,unspecified,continuous (including treatment resistant)"
    },
    {
      "name": "F2091",
      "description": "Schizophrenia,unspecified,episodic with progressive deficit"
    },
    {
      "name": "F2092",
      "description": "Schizophrenia,unspecified,episodic with stable deficit"
    },
    {
      "name": "F2093",
      "description": "Schizophrenia,unspecified,episodic remittent"
    },
    {
      "name": "F2094",
      "description": "Schizophrenia,unspecified,incomplete remission"
    },
    {
      "name": "F2095",
      "description": "Schizophrenia,unspecified,complete remission"
    },
    {
      "name": "F2098",
      "description": "Schizophrenia,unspecified,other"
    },
    {
      "name": "F2099",
      "description": "Schizophrenia,unspecified,course uncertain,period of observation is too short"
    },
    {
      "name": "F21",
      "description": "Schizotypal disorder"
    },
    {
      "name": "F220",
      "description": "Delusional disorder"
    },
    {
      "name": "F228",
      "description": "Other persistent delusional disorders"
    },
    {
      "name": "F229",
      "description": "Persistent delusional disorder, unspecified"
    },
    {
      "name": "F230",
      "description": "Acute polymorphic psychotic disorder without sympotoms of schizophrenia"
    },
    {
      "name": "F231",
      "description": "Acute polymorphic psychotic disorder with symptoms of schizophrenia"
    },
    {
      "name": "F232",
      "description": "Acute schizophrenia-like psychotic disorder"
    },
    {
      "name": "F233",
      "description": "Other acute predominatly delusional psychotic disorders"
    },
    {
      "name": "F238",
      "description": "Other acute and transient psychotic disorders"
    },
    {
      "name": "F239",
      "description": "Acute and transient psychotic disorder, unspecified"
    },
    {
      "name": "F24",
      "description": "Induced delusional disorder"
    },
    {
      "name": "F250",
      "description": "Schizoaffective disorder, manic type"
    },
    {
      "name": "F251",
      "description": "Schizoaffective disorder, depressive type"
    },
    {
      "name": "F252",
      "description": "Schizoaffective disorder, mixed type"
    },
    {
      "name": "F258",
      "description": "Other schizoaffective disorders"
    },
    {
      "name": "F259",
      "description": "Schizoaffective disorder, unspecified"
    },
    {
      "name": "F28",
      "description": "Other nonorganic psychotic disorders"
    },
    {
      "name": "F29",
      "description": "Unspecified nonorganic psychosis"
    },
    {
      "name": "F300",
      "description": "Hypomania"
    },
    {
      "name": "F301",
      "description": "Mania without psychotic symptoms"
    },
    {
      "name": "F302",
      "description": "Mania with psychotic symptoms"
    },
    {
      "name": "F308",
      "description": "Other manic episodes"
    },
    {
      "name": "F309",
      "description": "Manic episode, unspecified"
    },
    {
      "name": "F310",
      "description": "Bipolar affective disorder, current episode hypomanic"
    },
    {
      "name": "F311",
      "description": "Bipolar affective disorder, current episode mainc without psychotic symptoms"
    },
    {
      "name": "F312",
      "description": "Bipolar affective disorder, current episode mainc witht psychotic symptoms"
    },
    {
      "name": "F313",
      "description": "Bipolar affective disorder, current episode mild or moderate depression"
    },
    {
      "name": "F314",
      "description": "Bipolar affective disorder, current episode  severe depression without psychotic symptoms"
    },
    {
      "name": "F315",
      "description": "Bipolar affective disorder, current episode  severe depression with psychotic symptoms"
    },
    {
      "name": "F316",
      "description": "Bipolar affective disorder, current episode  mixed"
    },
    {
      "name": "F317",
      "description": "Bipolar affective disorder, currently in remission"
    },
    {
      "name": "F318",
      "description": "Other bipolar affective disorders"
    },
    {
      "name": "F319",
      "description": "Bipolar affective disorder, unspecified"
    },
    {
      "name": "F320",
      "description": "Mild depressive episode"
    },
    {
      "name": "F321",
      "description": "Moderate depressive episode"
    },
    {
      "name": "F322",
      "description": "Severe depressive episode without psychotic symptoms"
    },
    {
      "name": "F323",
      "description": "Severe depressive episode with psychotic symptoms"
    },
    {
      "name": "F328",
      "description": "Other depressive episodes"
    },
    {
      "name": "F329",
      "description": "Depressive epsiode, unspecified"
    },
    {
      "name": "F330",
      "description": "Recurrent depressive disorder, current episode mild"
    },
    {
      "name": "F331",
      "description": "Recurrent depressive disorder, current epsiode moderate"
    },
    {
      "name": "F332",
      "description": "Recurrent depressive disorder, current epsiode severe without psychotic symptoms"
    },
    {
      "name": "F333",
      "description": "Recurrent depressive disorder, current epsiode severe with psychotic symptoms"
    },
    {
      "name": "F334",
      "description": "Recurrent depressive disorder, currently in remission"
    },
    {
      "name": "F338",
      "description": "Other recurrent depressive disorders"
    },
    {
      "name": "F339",
      "description": "Recurrent depressive disorders, unspecified"
    },
    {
      "name": "F340",
      "description": "Cyclothymia"
    },
    {
      "name": "F341",
      "description": "Dysthymia"
    },
    {
      "name": "F348",
      "description": "Other persistent mood [affective] disorders"
    },
    {
      "name": "F349",
      "description": "Persistent mood [affective] disorder, unspecified"
    },
    {
      "name": "F380",
      "description": "Other single mood [affective] disorders"
    },
    {
      "name": "F381",
      "description": "Other recurrent mood [affective] disorders"
    },
    {
      "name": "F388",
      "description": "Other specified mood  [affective] disorders"
    },
    {
      "name": "F39",
      "description": "Unspecified mood  [affective] disorder"
    },
    {
      "name": "F400",
      "description": "Agoraphobia"
    },
    {
      "name": "F401",
      "description": "Social phobias"
    },
    {
      "name": "F402",
      "description": "Specific (isolated) phobias"
    },
    {
      "name": "F408",
      "description": "Other phobic anxiety disorders"
    },
    {
      "name": "F409",
      "description": "Phobic anxiety disorder, unspecified"
    },
    {
      "name": "F410",
      "description": "Panic disorder [episodic  paroxysmal anxiety]"
    },
    {
      "name": "F411",
      "description": "Generalized anxiety disorder"
    },
    {
      "name": "F412",
      "description": "Mixed anxiety and depressive disorder"
    },
    {
      "name": "F413",
      "description": "Other mixed anxiety disorders"
    },
    {
      "name": "F418",
      "description": "Other specified anxiety disorders"
    },
    {
      "name": "F419",
      "description": "Anxiety disorder, unspecified"
    },
    {
      "name": "F420",
      "description": "Predominantly obsessional thoughts or ruminations"
    },
    {
      "name": "F421",
      "description": "Predominantly compulsive acts [obsessional rituals]"
    },
    {
      "name": "F422",
      "description": "Mixde obsessional thoughts and acts"
    },
    {
      "name": "F428",
      "description": "Other obsessive-compulsive disorders"
    },
    {
      "name": "F429",
      "description": "Obsessive-compulsive disorder, unspecified"
    },
    {
      "name": "F430",
      "description": "Acute stress reaction"
    },
    {
      "name": "F431",
      "description": "Post-traumatic stress disorder"
    },
    {
      "name": "F432",
      "description": "Adjustment disorders"
    },
    {
      "name": "F438",
      "description": "Other reactions to severe stress"
    },
    {
      "name": "F439",
      "description": "Reaction to severe stress, unspecified"
    },
    {
      "name": "F440",
      "description": "Dissociative amnesia"
    },
    {
      "name": "F441",
      "description": "Dissociative fugue"
    },
    {
      "name": "F442",
      "description": "Dissociative stupor"
    },
    {
      "name": "F443",
      "description": "Trance and possession disorders"
    },
    {
      "name": "F444",
      "description": "Dissociative motor disorders"
    },
    {
      "name": "F445",
      "description": "Dissociative convulsions"
    },
    {
      "name": "F446",
      "description": "Dissociative anaesthesia and sensory loss"
    },
    {
      "name": "F447",
      "description": "Mixed dissociative [conversion] disorders"
    },
    {
      "name": "F4480",
      "description": "Ganser's syndrome"
    },
    {
      "name": "F4481",
      "description": "Multiple personality "
    },
    {
      "name": "F4482",
      "description": "Transient dissociative [conversion] disorders occurring in childhood and adolescence"
    },
    {
      "name": "F4488",
      "description": "Other specified dissociative  [conversion] disorders"
    },
    {
      "name": "F449",
      "description": "Dissociative [conversion] disorder, unspecified"
    },
    {
      "name": "F450",
      "description": "Somatization disorder"
    },
    {
      "name": "F451",
      "description": "Undifferentiated somatoform disorder"
    },
    {
      "name": "F452",
      "description": "Hypchondriacal disorder"
    },
    {
      "name": "F4530",
      "description": "Cardiovascular system"
    },
    {
      "name": "F4531",
      "description": "Upper gastrointestinal tract"
    },
    {
      "name": "F4532",
      "description": "Lower gastrointestinal tract"
    },
    {
      "name": "F4533",
      "description": "Respiratory system"
    },
    {
      "name": "F4534",
      "description": "Genitourinary system"
    },
    {
      "name": "F4538",
      "description": "Other organ and system"
    },
    {
      "name": "F4539",
      "description": "Unspecified organs or systems"
    },
    {
      "name": "F454",
      "description": "Persistent somatoform pain disorder"
    },
    {
      "name": "F4580",
      "description": "Psychogenic dysphagia, including “globus hystericus”"
    },
    {
      "name": "F4581",
      "description": "Psychogenic torticollis"
    },
    {
      "name": "F4582",
      "description": "Teeth-grinding [bruxism]"
    },
    {
      "name": "F4588",
      "description": "Other somatoform disorders"
    },
    {
      "name": "F459",
      "description": "Somatoform disorder, unspecified"
    },
    {
      "name": "F480",
      "description": "Neurasthenia"
    },
    {
      "name": "F481",
      "description": "Depersonalization-derealization syndrome"
    },
    {
      "name": "F488",
      "description": "Other specified neurotic disorders"
    },
    {
      "name": "F489",
      "description": "Neurotic disorder, unspecified"
    },
    {
      "name": "F500",
      "description": "Anorexia nervosa"
    },
    {
      "name": "F501",
      "description": "Atypical anorexia nervosa"
    },
    {
      "name": "F502",
      "description": "Bulimia nervosa"
    },
    {
      "name": "F503",
      "description": "Atypical bulimia nervosa"
    },
    {
      "name": "F504",
      "description": "Overeating associated with other psychological disturbances"
    },
    {
      "name": "F505",
      "description": "Vomiting associated with other psychological disturances"
    },
    {
      "name": "F508",
      "description": "Other eating disorders"
    },
    {
      "name": "F509",
      "description": "Eating disorder, unspecified"
    },
    {
      "name": "F510",
      "description": "Nonorganic insomnia"
    },
    {
      "name": "F511",
      "description": "Nonorganic hypersomnia"
    },
    {
      "name": "F512",
      "description": "Nonorganic disorder of the sleep-wake schedule"
    },
    {
      "name": "F513",
      "description": "Sleepwalking [somnambulism]"
    },
    {
      "name": "F514",
      "description": "Sleep terrors [night terrors]"
    },
    {
      "name": "F515",
      "description": "Nightmares"
    },
    {
      "name": "F518",
      "description": "Other nonorganic sleep disorders"
    },
    {
      "name": "F519",
      "description": "Nonorganic sleep disorder, unspecified"
    },
    {
      "name": "F520",
      "description": "Lack or loss of sexual desire"
    },
    {
      "name": "F521",
      "description": "Sexual aversion and lack of sexual enjoyment"
    },
    {
      "name": "F522",
      "description": "Failure of genital response"
    },
    {
      "name": "F523",
      "description": "Orgasmic dysfunction"
    },
    {
      "name": "F524",
      "description": "Premature ejaculation"
    },
    {
      "name": "F525",
      "description": "Nonorganic vaginismus"
    },
    {
      "name": "F526",
      "description": "Nonorganic dyspareunia"
    },
    {
      "name": "F527",
      "description": "Excessive  sexual drive"
    },
    {
      "name": "F528",
      "description": "Other sexual dysfunction, not caused by organic disorder or disease"
    },
    {
      "name": "F529",
      "description": "Unspecified sexual dysfunction, not caused by organic disorder or disease"
    },
    {
      "name": "F530",
      "description": "Mild mental and behavioural disorders associated with the puerperium, not elsewhere classified"
    },
    {
      "name": "F531",
      "description": "Severe mental and behavioural disorders associated with the  puerperium, not elsewhere classified"
    },
    {
      "name": "F538",
      "description": "Other mental and behavioural disorders associated with the puerperium, not elsewhere classified"
    },
    {
      "name": "F539",
      "description": "Puerperal mental disorder, unspecified"
    },
    {
      "name": "F54",
      "description": "Psychological and behavioural factors associated with  disorders or diseases classified elsewhere"
    },
    {
      "name": "F550",
      "description": "Antidepressants"
    },
    {
      "name": "F551",
      "description": "Laxatives"
    },
    {
      "name": "F552",
      "description": "Analgesics"
    },
    {
      "name": "F553",
      "description": "Antacids"
    },
    {
      "name": "F554",
      "description": "Vitamins"
    },
    {
      "name": "F555",
      "description": "Steroids or hormones"
    },
    {
      "name": "F556",
      "description": "Specific herbal or folk remedies"
    },
    {
      "name": "F557",
      "description": "Multiple substances"
    },
    {
      "name": "F558",
      "description": "Other non-dependence-producing substances"
    },
    {
      "name": "F559",
      "description": "Unspecified non-dependence-producing substance"
    },
    {
      "name": "F59",
      "description": "Unspecified behavioural syndromes associated with physiological disturbances and physical factors"
    },
    {
      "name": "F600",
      "description": "Paranoid personality disorder"
    },
    {
      "name": "F601",
      "description": "Schizoid  personality disorder"
    },
    {
      "name": "F602",
      "description": "Dissocial personality disorder"
    },
    {
      "name": "F6030",
      "description": "Impulsive type"
    },
    {
      "name": "F6031",
      "description": "Borderline type"
    },
    {
      "name": "F6038",
      "description": "Other emotionally unstable personality disorder"
    },
    {
      "name": "F6039",
      "description": "Emotionally unstable personality disorder, unspecified"
    },
    {
      "name": "F604",
      "description": "Histrionic personality disorder"
    },
    {
      "name": "F605",
      "description": "Anankastic personality disorder"
    },
    {
      "name": "F606",
      "description": "Anxious [avoidant] personality disorder"
    },
    {
      "name": "F607",
      "description": "Dependent personality disorder"
    },
    {
      "name": "F608",
      "description": "Other specific personality disorders"
    },
    {
      "name": "F609",
      "description": "Personality disorder, unspecified"
    },
    {
      "name": "F61",
      "description": "Mixed and other personality disorders"
    },
    {
      "name": "F620",
      "description": "Enduring personality change after catastrophic experience"
    },
    {
      "name": "F621",
      "description": "Enduring personality change after psychiatric illness"
    },
    {
      "name": "F628",
      "description": "Other enduring personality changes"
    },
    {
      "name": "F629",
      "description": "Enduring personality change, unspecified"
    },
    {
      "name": "F630",
      "description": "Pathological gambling"
    },
    {
      "name": "F631",
      "description": "Pathological fire-setting [pyromania]"
    },
    {
      "name": "F632",
      "description": "Pathological stealing [kleptomania]"
    },
    {
      "name": "F633",
      "description": "Trichotillomania"
    },
    {
      "name": "F638",
      "description": "Other habit and impulse disorders"
    },
    {
      "name": "F639",
      "description": "Habit and impulse disorder, unspecified"
    },
    {
      "name": "F640",
      "description": "Transsexualism"
    },
    {
      "name": "F641",
      "description": "Dual-role transvestism"
    },
    {
      "name": "F642",
      "description": "Gender identity disorder of childhood"
    },
    {
      "name": "F648",
      "description": "Other gender identity disorders"
    },
    {
      "name": "F649",
      "description": "Gender  identity disorder , unspecified"
    },
    {
      "name": "F650",
      "description": "Fetishism"
    },
    {
      "name": "F651",
      "description": "Fetishistic transvestism"
    },
    {
      "name": "F652",
      "description": "Exhibitionism"
    },
    {
      "name": "F653",
      "description": "Voyeurism"
    },
    {
      "name": "F654",
      "description": "Paedophilia"
    },
    {
      "name": "F655",
      "description": "Sadomasochism"
    },
    {
      "name": "F656",
      "description": "Multiple disorders of sexual preference"
    },
    {
      "name": "F658",
      "description": "Other disorders of sexual preference"
    },
    {
      "name": "F659",
      "description": "Disorder of sexual preference, unspecified"
    },
    {
      "name": "F660",
      "description": "Sexual maturation disorder"
    },
    {
      "name": "F661",
      "description": "Egodystonic sexual orientation"
    },
    {
      "name": "F662",
      "description": "Sexual relationship disorder"
    },
    {
      "name": "F668",
      "description": "Other psychosexual development disorders"
    },
    {
      "name": "F669",
      "description": "Psychosexual development disorder, unspecified"
    },
    {
      "name": "F680",
      "description": "Elaboration of physical symptoms for psychological reasons"
    },
    {
      "name": "F681",
      "description": "Intentional production or feigning of symptoms or disabilities,either physical or psychological [factitious disorder]"
    },
    {
      "name": "F688",
      "description": "Other specified disorders of adult personality and behaviour"
    },
    {
      "name": "F69",
      "description": "Unspecified disorder of adult personality and behaviour"
    },
    {
      "name": "F700",
      "description": "Mild mental retardation at with the statement of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F701",
      "description": "Mild mental retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F708",
      "description": "Mild mental retardation at other  impairments of behaviour"
    },
    {
      "name": "F709",
      "description": "Mild mental retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F710",
      "description": "Moderate mental  retardation at with the statment of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F711",
      "description": "Moderate mental  retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F718",
      "description": "Moderate mental  retardation at other  impairment of behaviour"
    },
    {
      "name": "F719",
      "description": "Moderate mental  retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F720",
      "description": "Severe mental retardation at with the statment of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F721",
      "description": "Severe mental retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F728",
      "description": "Severe mental retardation at other  impairment of behaviour"
    },
    {
      "name": "F729",
      "description": "Severe mental retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F730",
      "description": "profound mental retardation at with the statment of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F731",
      "description": "profound mental retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F738",
      "description": "profound mental retardation at other  impairment of behaviour"
    },
    {
      "name": "F739",
      "description": "profound mental retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F780",
      "description": "Other mental retardation at with the statment of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F781",
      "description": "Other mental retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F788",
      "description": "Other mental retardation at other  impairment of behaviour"
    },
    {
      "name": "F789",
      "description": "Other mental retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F790",
      "description": "Unspecified mental retardation at with the statment of no,or minimal,impairment of behaviour"
    },
    {
      "name": "F791",
      "description": "Unspecified mental retardation at significant impairment of behaviour requiring attention or treatment"
    },
    {
      "name": "F798",
      "description": "Unspecified mental retardation at other  impairment of behaviour"
    },
    {
      "name": "F799",
      "description": "Unspecified mental retardation at without mention of impairment of behaviour"
    },
    {
      "name": "F800",
      "description": "Specified speech articulation disorder"
    },
    {
      "name": "F801",
      "description": "Expressive language disorder"
    },
    {
      "name": "F802",
      "description": "Receptive language disorder"
    },
    {
      "name": "F803",
      "description": "Acquired aphasia with epilepsy [Landau-Kleffner]"
    },
    {
      "name": "F808",
      "description": "Other developmental disorders of speech and language"
    },
    {
      "name": "F809",
      "description": "Developmental disorder of speech and language unspecified"
    },
    {
      "name": "F810",
      "description": "Specific reading disorder"
    },
    {
      "name": "F811",
      "description": "Specific spelling disorder"
    },
    {
      "name": "F812",
      "description": "Specific disorder of arithmetical skills"
    },
    {
      "name": "F813",
      "description": "Mixed disorder of scholastic skills"
    },
    {
      "name": "F818",
      "description": "Other developmental disorders of scholastic skills"
    },
    {
      "name": "F819",
      "description": "Developmental disorder of scholastic skills, unspecified"
    },
    {
      "name": "F82",
      "description": "Specific developmental disorder of motor function"
    },
    {
      "name": "F83",
      "description": "Mixed specific developmental disorders"
    },
    {
      "name": "F840",
      "description": "Childhood autism"
    },
    {
      "name": "F841",
      "description": "Atypical autism"
    },
    {
      "name": "F842",
      "description": "Rett's syndrome"
    },
    {
      "name": "F843",
      "description": "Other childhood disintegrative disorder"
    },
    {
      "name": "F844",
      "description": "Overactive disorder associated with mental retardation and stereotyped movements"
    },
    {
      "name": "F845",
      "description": "Asperger's syndrome"
    },
    {
      "name": "F848",
      "description": "Other pervasive developmental disorders"
    },
    {
      "name": "F849",
      "description": "Pervasive developmental disorder, unspecified"
    },
    {
      "name": "F88",
      "description": "Other disorders of psychological development"
    },
    {
      "name": "F89",
      "description": "Unspecified disorder of psychological development"
    },
    {
      "name": "F900",
      "description": "Disturbance of activity and attention"
    },
    {
      "name": "F901",
      "description": "Hyperkinetic conduct disorder"
    },
    {
      "name": "F908",
      "description": "Other hyperkinetic disorders"
    },
    {
      "name": "F909",
      "description": "Hyperkinetic disorder, unspecified"
    },
    {
      "name": "F910",
      "description": "Conduct disorders confined to the family context"
    },
    {
      "name": "F911",
      "description": "Unsocialized conduct disorder"
    },
    {
      "name": "F912",
      "description": "Socialized conduct disorder"
    },
    {
      "name": "F913",
      "description": "Oppositional defiant disorder"
    },
    {
      "name": "F918",
      "description": "Other conduct disorders"
    },
    {
      "name": "F919",
      "description": "Conduct disorder, unspecified"
    },
    {
      "name": "F920",
      "description": "Depressive conduct disorder"
    },
    {
      "name": "F928",
      "description": "Other mixed disorders of conduct and emotions"
    },
    {
      "name": "F929",
      "description": "Mixed disorder of conduct and emotions, unspecified"
    },
    {
      "name": "F930",
      "description": "Separation anxiety disorder of childhood"
    },
    {
      "name": "F931",
      "description": "Phobic anxiety disorder of childhood"
    },
    {
      "name": "F932",
      "description": "Social anxiety disorder of childhood"
    },
    {
      "name": "F933",
      "description": "Sibling rivalry disorder"
    },
    {
      "name": "F938",
      "description": "Other childhood emotional disorders"
    },
    {
      "name": "F939",
      "description": "Childhood emotional disorder, unspecified"
    },
    {
      "name": "F940",
      "description": "Elective mutism"
    },
    {
      "name": "F941",
      "description": "Reactive attachment disorder of chlidhood"
    },
    {
      "name": "F942",
      "description": "Disinhibited attachment disorder of childhood"
    },
    {
      "name": "F948",
      "description": "Other childhood  disorders of social functioning"
    },
    {
      "name": "F949",
      "description": "Chlidhood  disorder of social functioning, unspecified"
    },
    {
      "name": "F950",
      "description": "Transient tic disorder"
    },
    {
      "name": "F951",
      "description": "Chronic motor or vocal tic disorder"
    },
    {
      "name": "F952",
      "description": "Combined vocal and multiple motor tic disorder [de la Tourette]"
    },
    {
      "name": "F958",
      "description": "Other tic disorders"
    },
    {
      "name": "F959",
      "description": "Tic disorder, unspecified"
    },
    {
      "name": "F980",
      "description": "Nonorganic enuresis"
    },
    {
      "name": "F981",
      "description": "Nonorganic encopresis"
    },
    {
      "name": "F982",
      "description": "Feeding disorder of infancy and childhood"
    },
    {
      "name": "F983",
      "description": "Pica of infancy and childhood"
    },
    {
      "name": "F984",
      "description": "Stereotyped movement disorders"
    },
    {
      "name": "F985",
      "description": "Stuttering [stammering]"
    },
    {
      "name": "F986",
      "description": "Cluttering"
    },
    {
      "name": "F988",
      "description": "Other specified behavioural and emotional disorders with onset usually occurring in childhood and adolescence"
    },
    {
      "name": "F989",
      "description": "Unspecified  behavioural and emotional disorders with onset usually occurring in childhood and adolescence"
    },
    {
      "name": "F99",
      "description": "Mental disorder, not otherwise specified"
    },
    {
      "name": "G000",
      "description": "Haemophilus meningitis"
    },
    {
      "name": "G001",
      "description": "Pneumococcal meningitis"
    },
    {
      "name": "G002",
      "description": "Streptococcal meningitis"
    },
    {
      "name": "G003",
      "description": "Staphylococcal meningitis"
    },
    {
      "name": "G008",
      "description": "Other bacterial meningitis"
    },
    {
      "name": "G009",
      "description": "Bacterial meningitis, unspecified"
    },
    {
      "name": "G01",
      "description": "Meningitis in bacterial diseases classified elsewhere"
    },
    {
      "name": "G020",
      "description": "Meningitis in viral diseases classified elsewhere"
    },
    {
      "name": "G021",
      "description": "Meningitis in mycoses"
    },
    {
      "name": "G028",
      "description": "Meningitis in other specified infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "G030",
      "description": "Nonpyogenic meningitis"
    },
    {
      "name": "G031",
      "description": "Chronic  meningitis"
    },
    {
      "name": "G032",
      "description": "Benign recurrent  meningitis [Mollaret]"
    },
    {
      "name": "G038",
      "description": "Meningitis due to other specified causes"
    },
    {
      "name": "G039",
      "description": "Meningitis, unspecified"
    },
    {
      "name": "G040",
      "description": "Acute disseminated encephalitis"
    },
    {
      "name": "G041",
      "description": "Tropical spastic paraplegia"
    },
    {
      "name": "G042",
      "description": "Bacterial meningoencephalitis and meningomyelitis, not elsewhere classified"
    },
    {
      "name": "G043",
      "description": "Viral transverse myelitis, unspecified"
    },
    {
      "name": "G048",
      "description": "Other encephalitis, myelitis and encephalomyelitis"
    },
    {
      "name": "G049",
      "description": "Encephalitis, myelitis and encephalomyelitis, unspecified"
    },
    {
      "name": "G050",
      "description": "Encephalitis, myelitis and encephalomyelitis in bacterial diseases classified elsewhere"
    },
    {
      "name": "G051",
      "description": "Encephalitis, myelitis and encephalomyelitis in viral diseases classified elsewhere"
    },
    {
      "name": "G052",
      "description": "Encephalitis, myelitis  and encephalomyelitis in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "G058",
      "description": "Encephalitis, myelitis and encephalomyelitis in other  diseases classified elsewhere"
    },
    {
      "name": "G060",
      "description": "Intracranial abscess and granuloma"
    },
    {
      "name": "G061",
      "description": "Intraspinal abscess and granuloma"
    },
    {
      "name": "G062",
      "description": "Extradural and subdural abscess, unspecified"
    },
    {
      "name": "G07",
      "description": "Intracranial and intraspinal abscess and glanuloma in diseases classified elsewhere"
    },
    {
      "name": "G08",
      "description": "Intracranial and intraspinal phlebitis and thrombophlebitis"
    },
    {
      "name": "G09",
      "description": "Sequelae of inflammatory diseases of central nervous system"
    },
    {
      "name": "G10",
      "description": "Huntington's disease"
    },
    {
      "name": "G110",
      "description": "Congenital nonprogressive ataxia"
    },
    {
      "name": "G111",
      "description": "Early-onset cerebellar ataxia"
    },
    {
      "name": "G112",
      "description": "Late-onset celebellar ataxia"
    },
    {
      "name": "G113",
      "description": "Celebellar ataxia with defective DNA repair"
    },
    {
      "name": "G114",
      "description": "Hereditary spastic paraplegia"
    },
    {
      "name": "G118",
      "description": "Other hereditary ataxias"
    },
    {
      "name": "G119",
      "description": "Hereditary ataxia, unspecified"
    },
    {
      "name": "G120",
      "description": "Infantile spinal muscular atrophy, type I [werdnig-Hoffman]"
    },
    {
      "name": "G121",
      "description": "Other inherited spinal muscular atrophy"
    },
    {
      "name": "G122",
      "description": "Motor neuron disease"
    },
    {
      "name": "G128",
      "description": "Other spinal muscular atrophies and related syndromes"
    },
    {
      "name": "G129",
      "description": "Spinal muscular atrophy, unspecified"
    },
    {
      "name": "G130",
      "description": "Paraneoplastic neuromyopathy and neuropathy"
    },
    {
      "name": "G131",
      "description": "Other systemic atrophy primarily affecting central nervous system in neoplastic disease"
    },
    {
      "name": "G132",
      "description": "Systemic atrophy primarily affecting central nervous system in myxoedema [E00.1*, E03.-*]"
    },
    {
      "name": "G138",
      "description": "Systemic atrophy primarily affecting central nervous system in other diseases classified elsewhere"
    },
    {
      "name": "G14",
      "description": "Postpolio syndrome "
    },
    {
      "name": "G20",
      "description": "Parkinson's disease"
    },
    {
      "name": "G210",
      "description": "Malignant neuroleptic syndrome"
    },
    {
      "name": "G211",
      "description": "Other drug-induced secondary parkinsonism"
    },
    {
      "name": "G212",
      "description": "Secondary parkinsonism due to other external agents"
    },
    {
      "name": "G213",
      "description": "Postencephalitic parkinsonism"
    },
    {
      "name": "G214",
      "description": "Vascular parkinsonism"
    },
    {
      "name": "G218",
      "description": "Other secondary parkinsonism"
    },
    {
      "name": "G219",
      "description": "Secondary parkinsonism, unspecified"
    },
    {
      "name": "G22",
      "description": "Parkinsonism in diseases classified elsewhere"
    },
    {
      "name": "G230",
      "description": "Hallervorden-Spatz disease"
    },
    {
      "name": "G231",
      "description": "Progressive supranuclear ophthalmoplegia [Steele Richardson-Olszewski]"
    },
    {
      "name": "G232",
      "description": "Multiple system atrophy, parkinsonian type [MSA-P]"
    },
    {
      "name": "G233",
      "description": "Multiple system atrophy, cerebellar type [MSA-C]"
    },
    {
      "name": "G238",
      "description": "Other specified degenerative diseases of basal ganglia"
    },
    {
      "name": "G239",
      "description": "Degenerative diseases of basal  ganglia, unspecified"
    },
    {
      "name": "G240",
      "description": "Drug-induced dystonia"
    },
    {
      "name": "G241",
      "description": "Idiopathic familial dystonia"
    },
    {
      "name": "G242",
      "description": "Idiopathic nonfamilial dystonia"
    },
    {
      "name": "G243",
      "description": "Spasmodic torticollis"
    },
    {
      "name": "G244",
      "description": "Idiopathic orofacial dystonia"
    },
    {
      "name": "G245",
      "description": "Blepharospasm"
    },
    {
      "name": "G248",
      "description": "Other dystonia"
    },
    {
      "name": "G249",
      "description": "Dystonia, unspecified"
    },
    {
      "name": "G250",
      "description": "Essential tremor"
    },
    {
      "name": "G251",
      "description": "Drug-induced tremor"
    },
    {
      "name": "G252",
      "description": "Other specified forms of tremor"
    },
    {
      "name": "G253",
      "description": "Myoclonus"
    },
    {
      "name": "G254",
      "description": "Drug-induced chorea"
    },
    {
      "name": "G255",
      "description": "Other chorea"
    },
    {
      "name": "G256",
      "description": "Drug-induced tics and other tics of organic origin"
    },
    {
      "name": "G258",
      "description": "Other specified extrapyramidal and movement disorders"
    },
    {
      "name": "G259",
      "description": "Extrapyramidal and  movement disorder, unspecified"
    },
    {
      "name": "G26",
      "description": "Extrapyramidal and  movement disorders in diseases classified elsewhere"
    },
    {
      "name": "G300",
      "description": "Alzheimer's disease with early onset"
    },
    {
      "name": "G301",
      "description": "Alzheimer's disease with late onset"
    },
    {
      "name": "G308",
      "description": "Other Alzheimer's disease"
    },
    {
      "name": "G309",
      "description": "Alzheimer's disease,unspecified"
    },
    {
      "name": "G310",
      "description": "Circumscribed brain atrophy"
    },
    {
      "name": "G311",
      "description": "Senile degeneration of brain, not elsewhere classified"
    },
    {
      "name": "G312",
      "description": "Degeneration of nervous system due to alcohol"
    },
    {
      "name": "G318",
      "description": "Other specified degenerative diseases of nervous system"
    },
    {
      "name": "G319",
      "description": "Degenerative disease of nervous system, unspecified"
    },
    {
      "name": "G320",
      "description": "Subacute combined degeneration of spinal cord in diseases classified elsewhere"
    },
    {
      "name": "G328",
      "description": "Other specified degenerative disorders of nervous system in diseases classified elsewhere"
    },
    {
      "name": "G35",
      "description": "Multiple sclerosis"
    },
    {
      "name": "G360",
      "description": "Neuromyelitis optica [Devic]"
    },
    {
      "name": "G361",
      "description": "Acute and subacute haemorrhagic leukoencephalitis [Hurst]"
    },
    {
      "name": "G368",
      "description": "Other specified acute disseminated demyelination"
    },
    {
      "name": "G369",
      "description": "Acute disseminated demyelination, unspecified"
    },
    {
      "name": "G370",
      "description": "Diffuse sclerosis"
    },
    {
      "name": "G371",
      "description": "Central demyelination of corpus callosum"
    },
    {
      "name": "G372",
      "description": "Central pontine myelionolysis"
    },
    {
      "name": "G373",
      "description": "Acute transverse myelitis in demyelinating disease of central nervous system"
    },
    {
      "name": "G374",
      "description": "Subacute necrotizing myelitis"
    },
    {
      "name": "G375",
      "description": "Concentric sclerosis [Balo]"
    },
    {
      "name": "G378",
      "description": "Other specified demyelinating disease of central nervous system"
    },
    {
      "name": "G379",
      "description": "Demyelinating disease of central nervous system, unspecified"
    },
    {
      "name": "G400",
      "description": "Localization-related (focal)(partial) idiopathic epilepsy and epileptic syndromes with seizures of localized onset"
    },
    {
      "name": "G401",
      "description": "Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with simple partial seizures"
    },
    {
      "name": "G402",
      "description": "Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with complex partial seizures"
    },
    {
      "name": "G403",
      "description": "Generalized idiopathic epilepsy and epileptic syndromes"
    },
    {
      "name": "G404",
      "description": "Other generalized epilepsy and epileptic syndrome"
    },
    {
      "name": "G405",
      "description": "Special epileptic syndromes"
    },
    {
      "name": "G406",
      "description": "Grand mal seizures, unspecified (with of without petit mal )"
    },
    {
      "name": "G407",
      "description": "Petit mal, unspecified, without grand mal seizures"
    },
    {
      "name": "G408",
      "description": "Other epilepsy"
    },
    {
      "name": "G409",
      "description": "Epilepsy, unspecified"
    },
    {
      "name": "G410",
      "description": "Grand mal status epilepticus"
    },
    {
      "name": "G411",
      "description": "Petit mal status epilepticus"
    },
    {
      "name": "G412",
      "description": "Complex partial status epilepticus"
    },
    {
      "name": "G418",
      "description": "Other status epilepticus"
    },
    {
      "name": "G419",
      "description": "Status epilepticus, unspecified"
    },
    {
      "name": "G430",
      "description": "Migraine without aura [common migraine]"
    },
    {
      "name": "G431",
      "description": "Migraine with aura [classical migraine]"
    },
    {
      "name": "G432",
      "description": "Status migrainosus"
    },
    {
      "name": "G433",
      "description": "Complicated migraine"
    },
    {
      "name": "G438",
      "description": "Other migraine"
    },
    {
      "name": "G439",
      "description": "Migraine, unspecified"
    },
    {
      "name": "G440",
      "description": "Cluster headache syndrome"
    },
    {
      "name": "G441",
      "description": "Vascular headache, not elsewhere classified"
    },
    {
      "name": "G442",
      "description": "Tension-type headache"
    },
    {
      "name": "G443",
      "description": "Chronic post-traumatic headache"
    },
    {
      "name": "G444",
      "description": "Drug-induced headache, not elsewhere classified"
    },
    {
      "name": "G448",
      "description": "Other specified headache syndromes"
    },
    {
      "name": "G450",
      "description": "Vertebro-basilar artery syndrome"
    },
    {
      "name": "G451",
      "description": "Carotid artery syndrome (hemispheric)"
    },
    {
      "name": "G452",
      "description": "Multiple and bilateral precerebral artery syndromes"
    },
    {
      "name": "G453",
      "description": "Amaurosis fugax"
    },
    {
      "name": "G454",
      "description": "Transient global amnesia"
    },
    {
      "name": "G458",
      "description": "Other transient cerebral ischaemic attacks and related syndromes"
    },
    {
      "name": "G459",
      "description": "Transient cerebral ischaemic attack, unspecified"
    },
    {
      "name": "G460",
      "description": "Middle cerebral artery syndrome (I66.0)"
    },
    {
      "name": "G461",
      "description": "Anterior cerebral artery syndrome (I66.1)"
    },
    {
      "name": "G462",
      "description": "Posterior cerebral artery syndrome (I66.2)"
    },
    {
      "name": "G463",
      "description": "Brain stem stroke syndrome (I60-I67)"
    },
    {
      "name": "G464",
      "description": "Cerebellar stroke syndrome (I60-I67)"
    },
    {
      "name": "G465",
      "description": "Pure motor lacunar syndrome (I60-I67)"
    },
    {
      "name": "G466",
      "description": "Pure sensory lacunar syndrome (I60-I67)"
    },
    {
      "name": "G467",
      "description": "Other lacunar syndromes (I60-I67)"
    },
    {
      "name": "G468",
      "description": "Other vascular syndrome of brain in cerebrovascular diseases (l60-l67)"
    },
    {
      "name": "G470",
      "description": "Disorders of initiating and maintaining sleep [insomnias]"
    },
    {
      "name": "G471",
      "description": "Disorders of excessive somnolence [hypersomnias]"
    },
    {
      "name": "G472",
      "description": "Disorders of the sleep-wake schedule"
    },
    {
      "name": "G473",
      "description": "Sleep apnoea"
    },
    {
      "name": "G474",
      "description": "Narcolepsy and cataplexy"
    },
    {
      "name": "G478",
      "description": "Other sleep disorders"
    },
    {
      "name": "G479",
      "description": "Sleep disorder, unspecified"
    },
    {
      "name": "G500",
      "description": "Trigeminal neuralgia"
    },
    {
      "name": "G501",
      "description": "Atypical facial pain"
    },
    {
      "name": "G508",
      "description": "Other disorders fo trigeminal nerve"
    },
    {
      "name": "G509",
      "description": "Disorder of trigeminal nerve, unspecified"
    },
    {
      "name": "G510",
      "description": "Bell's palsy"
    },
    {
      "name": "G511",
      "description": "Geniculate ganglionitis"
    },
    {
      "name": "G512",
      "description": "Melkersson 's syndrome"
    },
    {
      "name": "G513",
      "description": "Clonic hemifacial spasm"
    },
    {
      "name": "G514",
      "description": "Facial myokymia"
    },
    {
      "name": "G518",
      "description": "Other disorders of facial nerve"
    },
    {
      "name": "G519",
      "description": "Disorder fo facial nerve, unspecified"
    },
    {
      "name": "G520",
      "description": "Disorders of olfactory nerve"
    },
    {
      "name": "G521",
      "description": "Disorders of glossopharyngeal nerve"
    },
    {
      "name": "G522",
      "description": "Disorders of vagus nerve"
    },
    {
      "name": "G523",
      "description": "Disorders of hypoglossal nerve"
    },
    {
      "name": "G527",
      "description": "Disorders of multiple cranial nerves"
    },
    {
      "name": "G528",
      "description": "Disorders of other specified cranial nerves"
    },
    {
      "name": "G529",
      "description": "Cranial nerve disorder, unspecified"
    },
    {
      "name": "G530",
      "description": "Postzoster neuralgia (B02.2)"
    },
    {
      "name": "G531",
      "description": "Multiple cranial nerve palsies in infectious and parasitic diseases classified elsewhere [A00-B99]"
    },
    {
      "name": "G532",
      "description": "Multiple cranial nerve palsies in sarcoidosis (D86.8)"
    },
    {
      "name": "G533",
      "description": "Multiple cranial nerve palsies in neoplastic disease (C00-D48)"
    },
    {
      "name": "G538",
      "description": "Other cranial nerve disorders in other diseases classified elsewhere"
    },
    {
      "name": "G540",
      "description": "Brachial plexus disorders"
    },
    {
      "name": "G541",
      "description": "Lumbosacral plexus disorders"
    },
    {
      "name": "G542",
      "description": "Cervical root disorders, not elsewhere classified"
    },
    {
      "name": "G543",
      "description": "Thoracic root disorders, not elsewhere classified"
    },
    {
      "name": "G544",
      "description": "Lumbosacral root disorders, not elsewhere classified"
    },
    {
      "name": "G545",
      "description": "Neuralgic amyotrophy"
    },
    {
      "name": "G546",
      "description": "Phantom limb syndrome with pain"
    },
    {
      "name": "G547",
      "description": "Phantom limb syndrome without pain"
    },
    {
      "name": "G548",
      "description": "Other nerve root and plexus disorders"
    },
    {
      "name": "G549",
      "description": "Nerve root and plexus disorder, unspecified"
    },
    {
      "name": "G550",
      "description": "Nerve root and plexus compressions in neoplastic diseases (C00-D48)"
    },
    {
      "name": "G551",
      "description": "Nerve root and plexus compressions in intervertebral disc disorders (M50-M51)"
    },
    {
      "name": "G552",
      "description": "Nerve root and plexus compressions in spondylosis (M47.-)"
    },
    {
      "name": "G553",
      "description": "Nerve root and plexus compressions in other dorsopathies (M45-M46, M48-,M53-M54)"
    },
    {
      "name": "G558",
      "description": "Nerve root and plexus compressions in other diseases classified elsewhere"
    },
    {
      "name": "G560",
      "description": "Carpal tunnel syndrome"
    },
    {
      "name": "G561",
      "description": "Other lesions of median nerve"
    },
    {
      "name": "G562",
      "description": "Lesion of ulnar nerve"
    },
    {
      "name": "G563",
      "description": "Lesion of radial nerve"
    },
    {
      "name": "G564",
      "description": "Causalgia"
    },
    {
      "name": "G568",
      "description": "Other mononeuropathies of upper limb"
    },
    {
      "name": "G569",
      "description": "Mononeuropathy of upper limb, unspecified"
    },
    {
      "name": "G570",
      "description": "Lesion of sciatic nerve"
    },
    {
      "name": "G571",
      "description": "Meralgia paraesthetica"
    },
    {
      "name": "G572",
      "description": "Lesion of femoral nerve"
    },
    {
      "name": "G573",
      "description": "Lesion of lateral popliteal nerve"
    },
    {
      "name": "G574",
      "description": "Lesion of medial popiteal nerve"
    },
    {
      "name": "G575",
      "description": "Tarsal tunnel syndrome"
    },
    {
      "name": "G576",
      "description": "Lesion of plantar nerve"
    },
    {
      "name": "G578",
      "description": "Other mononeuropathies of lower limb"
    },
    {
      "name": "G579",
      "description": "Mononeuropathy of lower limb, unspecified"
    },
    {
      "name": "G580",
      "description": "Intercostal neuropathy"
    },
    {
      "name": "G587",
      "description": "Mononeuritis multiplex"
    },
    {
      "name": "G588",
      "description": "Other specified mononeuropathies"
    },
    {
      "name": "G589",
      "description": "Mononeuropathy, unspecified"
    },
    {
      "name": "G590",
      "description": "Diabetic mononeuropathy (E10-E14 with common fourth character.4)"
    },
    {
      "name": "G598",
      "description": "Other mononeuropathies in diseases classified elsewhere"
    },
    {
      "name": "G600",
      "description": "Hereditary motor and sensory neuropathy"
    },
    {
      "name": "G601",
      "description": "Refsum's disease"
    },
    {
      "name": "G602",
      "description": "Neuropathy in association with hereditary ataxia"
    },
    {
      "name": "G603",
      "description": "Idiopathic progressive neuropathy"
    },
    {
      "name": "G608",
      "description": "Other hereditary and idiopathic neuropathies"
    },
    {
      "name": "G609",
      "description": "Hereditary and idiopathic neuropathy, unspecified"
    },
    {
      "name": "G610",
      "description": "Guillain-Barre syndrome"
    },
    {
      "name": "G611",
      "description": "Serum neuropathy"
    },
    {
      "name": "G618",
      "description": "Other inflammatory polyneuropathies"
    },
    {
      "name": "G619",
      "description": "Inflammatory polyneuropathy, unspecified"
    },
    {
      "name": "G620",
      "description": "Drug-induced polyneuropathy"
    },
    {
      "name": "G621",
      "description": "Alcoholic polyneuropathy"
    },
    {
      "name": "G622",
      "description": "Polyneuropathy due to other toxic agents"
    },
    {
      "name": "G623",
      "description": "Crow-Fukase / POEMS [polyneuropathy, organomegaly, endocrinopathy, M-protein and skin change] syndrome"
    },
    {
      "name": "G628",
      "description": "Other specified polyneuropathies"
    },
    {
      "name": "G629",
      "description": "Polyneuropathy, unspecified"
    },
    {
      "name": "G630",
      "description": "Polyneuropathy in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "G631",
      "description": "Polyneuropathy in neoplastic disease (C00-D48)"
    },
    {
      "name": "G632",
      "description": "Diabetic polyneuropathy (E10-E14 with common fourth character .4)"
    },
    {
      "name": "G633",
      "description": "Polyneuropathy in other endocrine and metabolic diseases [E00-E07,E15-E16, E20-E34, E70-E89]"
    },
    {
      "name": "G634",
      "description": "Polyneuropathy in nutritional deficiency (E40-E64)"
    },
    {
      "name": "G635",
      "description": "Polyneuropathy in systemic connective tissue disorders (M30-M35)"
    },
    {
      "name": "G636",
      "description": "Polyneuropathy in other musculoskeletal disorders (M00-M25, M40-M96)"
    },
    {
      "name": "G638",
      "description": "Polyneuropathy in other diseases classified elsewhere"
    },
    {
      "name": "G64",
      "description": "Other disorders of peripheral nervous system"
    },
    {
      "name": "G700",
      "description": "Myasthenia gravis"
    },
    {
      "name": "G701",
      "description": "Toxic myoneural disorders"
    },
    {
      "name": "G702",
      "description": "Congenital and developmental myasthenia"
    },
    {
      "name": "G708",
      "description": "Other specified myoneural disorders"
    },
    {
      "name": "G709",
      "description": "Myoneural disorder, unspecified"
    },
    {
      "name": "G710",
      "description": "Muscular dystrophy"
    },
    {
      "name": "G711",
      "description": "Myotonic disorders"
    },
    {
      "name": "G712",
      "description": "Congenital myopathies"
    },
    {
      "name": "G713",
      "description": "Mitochondrial myopathy, not elsewhere classified"
    },
    {
      "name": "G718",
      "description": "Other primary disorders of muscles"
    },
    {
      "name": "G719",
      "description": "Primary disorder of muscle, unspecified"
    },
    {
      "name": "G720",
      "description": "Drug-induced myopathy"
    },
    {
      "name": "G721",
      "description": "Alcoholic myopathy"
    },
    {
      "name": "G722",
      "description": "Myopathy due to other toxic agents"
    },
    {
      "name": "G723",
      "description": "Periodic paralysis"
    },
    {
      "name": "G724",
      "description": "Inflammatory myopathy, not elsewhere classified"
    },
    {
      "name": "G728",
      "description": "Other specified myopathies"
    },
    {
      "name": "G729",
      "description": "Myopathy, unspecified"
    },
    {
      "name": "G730",
      "description": "Myasthenic syndrome in endocrine diseases"
    },
    {
      "name": "G731",
      "description": "Lambert-Eaton syndrome (C80)"
    },
    {
      "name": "G732",
      "description": "Other myasthenic syndromes in neoplastic diseases (C00-D48)"
    },
    {
      "name": "G733",
      "description": "Myasthenic syndromes in other diseases classified elsewhere"
    },
    {
      "name": "G734",
      "description": "Myopathy in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "G735",
      "description": "Myopathy in endocrine diseases"
    },
    {
      "name": "G736",
      "description": "Myopathy in metabolic diseases"
    },
    {
      "name": "G737",
      "description": "Myopathy in other diseases classified elsewhere"
    },
    {
      "name": "G800",
      "description": "Spastic quadriplegic cerebral palsy "
    },
    {
      "name": "G801",
      "description": "Spastic diplegic cerebral palsy "
    },
    {
      "name": "G802",
      "description": "Spastic hemiplegic cerebral palsy "
    },
    {
      "name": "G803",
      "description": "Dyskinetic cerebral palsy"
    },
    {
      "name": "G804",
      "description": "Ataxic cerebral palsy"
    },
    {
      "name": "G808",
      "description": "Other cerebral palsy"
    },
    {
      "name": "G809",
      "description": "Cerebral palsy, unspecified"
    },
    {
      "name": "G810",
      "description": "Flaccid hemiplegia"
    },
    {
      "name": "G811",
      "description": "Spastic hemiplegia"
    },
    {
      "name": "G819",
      "description": "Hemiplegia, unspecified"
    },
    {
      "name": "G820",
      "description": "Flaccid paraplegia"
    },
    {
      "name": "G821",
      "description": "Spastic paraplegia"
    },
    {
      "name": "G822",
      "description": "Paraplegia, unspecified"
    },
    {
      "name": "G823",
      "description": "Flaccid tetraplegia"
    },
    {
      "name": "G824",
      "description": "Spastic tetraplegia"
    },
    {
      "name": "G825",
      "description": "Tetraplegia, unspcified"
    },
    {
      "name": "G830",
      "description": "Diplegia of upper limbs"
    },
    {
      "name": "G831",
      "description": "Monoplegia of lower limb"
    },
    {
      "name": "G832",
      "description": "Monoplegia of upper limb"
    },
    {
      "name": "G833",
      "description": "Monoplegia, unspecified"
    },
    {
      "name": "G834",
      "description": "Cauda equina syndrome"
    },
    {
      "name": "G835",
      "description": "Locked-in syndrome"
    },
    {
      "name": "G838",
      "description": "Other specified paralytic syndromes"
    },
    {
      "name": "G839",
      "description": "Paralytic syndrome, unspecified"
    },
    {
      "name": "G900",
      "description": "Idiopathic peripheral autonomic neuropathy"
    },
    {
      "name": "G901",
      "description": "Familial dysautonomia [Riley-Day]"
    },
    {
      "name": "G902",
      "description": "Horner's syndrome"
    },
    {
      "name": "G904",
      "description": "Autonomic dysreflexia "
    },
    {
      "name": "G908",
      "description": "Other disorders of autonomic nervous system"
    },
    {
      "name": "G909",
      "description": "Disorder of autonomic nervous system, unspecified"
    },
    {
      "name": "G910",
      "description": "Communicating hydrocephalus"
    },
    {
      "name": "G911",
      "description": "Obstructive hydrocephalus"
    },
    {
      "name": "G912",
      "description": "Normal-pressure hydrocephalus"
    },
    {
      "name": "G918",
      "description": "Other hydrocephalus"
    },
    {
      "name": "G919",
      "description": "Hydrocephalus, unspecified"
    },
    {
      "name": "G92",
      "description": "Toxic encephalpathy"
    },
    {
      "name": "G930",
      "description": "Cerebral cysts"
    },
    {
      "name": "G931",
      "description": "Anoxic brain damage, not elsewhere classified"
    },
    {
      "name": "G932",
      "description": "Benign intracranial hypertension"
    },
    {
      "name": "G933",
      "description": "Postviral fatigue syndrome"
    },
    {
      "name": "G934",
      "description": "Encephalopathy, unspecified"
    },
    {
      "name": "G935",
      "description": "Compression of brain"
    },
    {
      "name": "G936",
      "description": "Cerebral oedema"
    },
    {
      "name": "G937",
      "description": "Reye's syndrome"
    },
    {
      "name": "G938",
      "description": "Other specified disorders of brain"
    },
    {
      "name": "G939",
      "description": "Disorder of brain, unspecified"
    },
    {
      "name": "G940",
      "description": "Hydrocephalus in infectious and parasitic diseases classified elsewhere (A00-B99)"
    },
    {
      "name": "G941",
      "description": "Hydrocephalus in neoplastic disease (C00-D48)"
    },
    {
      "name": "G942",
      "description": "Hydrocephalus in other diseases classified elsewhere"
    },
    {
      "name": "G948",
      "description": "Other specified disorders of brain in diseases classified elsewhere"
    },
    {
      "name": "G950",
      "description": "Syringomyelia and syringobulbia"
    },
    {
      "name": "G951",
      "description": "Vascular myelopathies"
    },
    {
      "name": "G952",
      "description": "Cord compression, unspecified"
    },
    {
      "name": "G958",
      "description": "Other specified diseases of spinal cord"
    },
    {
      "name": "G959",
      "description": "Disease of spinal cord, unspecified"
    },
    {
      "name": "G960",
      "description": "Cerebrospinal fluid leak"
    },
    {
      "name": "G961",
      "description": "Disorders of meninges, not elsewhere classified"
    },
    {
      "name": "G968",
      "description": "Other specified disorders of central nervous system"
    },
    {
      "name": "G969",
      "description": "Disorder of central nervous system, unspecified"
    },
    {
      "name": "G970",
      "description": "Cerebrospinal fluid leak from spinal puncture"
    },
    {
      "name": "G971",
      "description": "Other reaction to spinal and lumbar puncture"
    },
    {
      "name": "G972",
      "description": "Intracranial hypotension following ventricular shunting"
    },
    {
      "name": "G978",
      "description": "Other postprocedural disorders of nervous system"
    },
    {
      "name": "G979",
      "description": "Postprocedural disorder of nervous system, unspecified"
    },
    {
      "name": "G98",
      "description": "Other disorders of nervous system, not elsewhere classified"
    },
    {
      "name": "G990",
      "description": "Autonomic neuropathy in endocrine and metabolic diseases"
    },
    {
      "name": "G991",
      "description": "Other disorders of autonomic nervous system in other diseases classified elsewhere"
    },
    {
      "name": "G992",
      "description": "Myelopathy in diseases classified elsewhere"
    },
    {
      "name": "G998",
      "description": "Other specified disorders of nervous system in diseases classified elsewhere"
    },
    {
      "name": "H000",
      "description": "Hordeolum and other deep inflammation of eyelid"
    },
    {
      "name": "H001",
      "description": "Chalazion"
    },
    {
      "name": "H010",
      "description": "Blepharitis"
    },
    {
      "name": "H011",
      "description": "Noninfectious dermatoses of eyelid"
    },
    {
      "name": "H018",
      "description": "Other specified inflammation of eyelid"
    },
    {
      "name": "H019",
      "description": "Inflammation of eyelid, unspecified"
    },
    {
      "name": "H020",
      "description": "Entropion and trichiasis of eyelid"
    },
    {
      "name": "H021",
      "description": "Ectropion of eyelid"
    },
    {
      "name": "H022",
      "description": "Lagophthalmos"
    },
    {
      "name": "H023",
      "description": "Blepharochalasis"
    },
    {
      "name": "H024",
      "description": "Ptosis of eyelid"
    },
    {
      "name": "H025",
      "description": "Other disorders affecting eyelid function"
    },
    {
      "name": "H026",
      "description": "Xanthelasma of eyelid"
    },
    {
      "name": "H027",
      "description": "Other degenerative disorders of eyelid and periocular area"
    },
    {
      "name": "H028",
      "description": "Other specified disorders of eyelid"
    },
    {
      "name": "H029",
      "description": "Disorder of eyelid, unspecified"
    },
    {
      "name": "H030",
      "description": "Parasitic infestation of eyelid in diseases classified elsewhere"
    },
    {
      "name": "H031",
      "description": "Involvement of eyelid in other infectious diseases classified elsewhere"
    },
    {
      "name": "H038",
      "description": "Involvement of eyelid in other diseases classified elsewhere"
    },
    {
      "name": "H040",
      "description": "Dacryoadenitis"
    },
    {
      "name": "H041",
      "description": "Other disorders of lacrimal gland"
    },
    {
      "name": "H042",
      "description": "Epiphora"
    },
    {
      "name": "H043",
      "description": "Acute and unspecified inflammation of lacrimal passages"
    },
    {
      "name": "H044",
      "description": "Chronic inflammation of lacrimal passages"
    },
    {
      "name": "H045",
      "description": "Stenosis and insufficiency of lacrimal passages"
    },
    {
      "name": "H046",
      "description": "Other changes in lacrimal passages"
    },
    {
      "name": "H048",
      "description": "Other disorders of lacrimal system"
    },
    {
      "name": "H049",
      "description": "Disorder of lacrimal system, unsepcified"
    },
    {
      "name": "H050",
      "description": "Acute inflammation of orbit"
    },
    {
      "name": "H051",
      "description": "Chronic inflammatory disorders of orbit"
    },
    {
      "name": "H052",
      "description": "Exophthalmic conditions"
    },
    {
      "name": "H053",
      "description": "Deformity of orbit"
    },
    {
      "name": "H054",
      "description": "Enophthalmos"
    },
    {
      "name": "H055",
      "description": "Retained (old) foreign body following penetrating wound of orbit"
    },
    {
      "name": "H058",
      "description": "Other disorders of orbit"
    },
    {
      "name": "H059",
      "description": "Disorder of orbit, unspecified"
    },
    {
      "name": "H060",
      "description": "Disorders of lacrimal system in diseases classified elsewhere"
    },
    {
      "name": "H061",
      "description": "Parasitic infestation of orbit in diseases classified elsewhere"
    },
    {
      "name": "H062",
      "description": "Dysthyroid exophthalmos (E05.-)"
    },
    {
      "name": "H063",
      "description": "Other disorders of orbit in diseases classified elsewhere"
    },
    {
      "name": "H100",
      "description": "Mucopurulent conjunctivitis"
    },
    {
      "name": "H101",
      "description": "Acute atopic conjunctivitis"
    },
    {
      "name": "H102",
      "description": "Other acute conjunctivitis"
    },
    {
      "name": "H103",
      "description": "Acute conjunctivitis, unspecified"
    },
    {
      "name": "H104",
      "description": "Chronic conjunctivitis"
    },
    {
      "name": "H105",
      "description": "Blepharoconjunctivitis"
    },
    {
      "name": "H108",
      "description": "Other conjunctivitis"
    },
    {
      "name": "H109",
      "description": "Conjunctivitis, unspecified"
    },
    {
      "name": "H110",
      "description": "Pterygium"
    },
    {
      "name": "H111",
      "description": "Conjunctival degenerations and deposits"
    },
    {
      "name": "H112",
      "description": "Conjunctival scars"
    },
    {
      "name": "H113",
      "description": "Conjunctival haemorrhage"
    },
    {
      "name": "H114",
      "description": "Other conjunctival vascular disorders and cysts"
    },
    {
      "name": "H118",
      "description": "Other specified disorders of conjunctiva"
    },
    {
      "name": "H119",
      "description": "Disorder of conjunctiva, unspecified"
    },
    {
      "name": "H130",
      "description": "Filarial infection of conjunctiva (B74.-)"
    },
    {
      "name": "H131",
      "description": "Conjunctivitis in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "H132",
      "description": "Conjunctivitis in other diseases classified elsewhere"
    },
    {
      "name": "H133",
      "description": "Ocular pemphigoid (L12.-)"
    },
    {
      "name": "H138",
      "description": "Other disorders of conjunctiva in diseases classified elsewhere"
    },
    {
      "name": "H150",
      "description": "Scleritis"
    },
    {
      "name": "H151",
      "description": "Episcleritis"
    },
    {
      "name": "H158",
      "description": "Other disorders of sclera"
    },
    {
      "name": "H159",
      "description": "Disorder of sclera, unspecified"
    },
    {
      "name": "H160",
      "description": "Corneal ulcer"
    },
    {
      "name": "H161",
      "description": "Other superficial keratitis without conjunctivitis"
    },
    {
      "name": "H162",
      "description": "Keratoconjunctivitis"
    },
    {
      "name": "H163",
      "description": "Interstitial and deep keratitis"
    },
    {
      "name": "H164",
      "description": "Corneal neovascularization"
    },
    {
      "name": "H168",
      "description": "Other keratitis"
    },
    {
      "name": "H169",
      "description": "Keratitis, unspecified"
    },
    {
      "name": "H170",
      "description": "Adherent leukoma"
    },
    {
      "name": "H171",
      "description": "Other central corneal opacity"
    },
    {
      "name": "H178",
      "description": "Other corneal scars and opacities"
    },
    {
      "name": "H179",
      "description": "Corneal scar and opacity,unspecified"
    },
    {
      "name": "H180",
      "description": "Corneal pigmentations and deposits"
    },
    {
      "name": "H181",
      "description": "Bullous keratopathy"
    },
    {
      "name": "H182",
      "description": "Other  corneal oedema"
    },
    {
      "name": "H183",
      "description": "Changes in corneal membranes"
    },
    {
      "name": "H184",
      "description": "Corneal degeneration"
    },
    {
      "name": "H185",
      "description": "Hereditary corneal dystrophies"
    },
    {
      "name": "H186",
      "description": "Keratoconus"
    },
    {
      "name": "H187",
      "description": "Other corneal deformities"
    },
    {
      "name": "H188",
      "description": "Other specified disorders of cornea"
    },
    {
      "name": "H189",
      "description": "Disorder of cornea, unspecified"
    },
    {
      "name": "H190",
      "description": "Scleritis and episcleritis in diseases classified elsewhere"
    },
    {
      "name": "H191",
      "description": "Herpesviral keratitis and keratoconjunctivitis (B00.5)"
    },
    {
      "name": "H192",
      "description": "Keratitis and keratoconjunctivitis in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "H193",
      "description": "Karatitis and keratoconjunctivitis in other diseases classified elsewhere"
    },
    {
      "name": "H198",
      "description": "Other disorders of sclera and cornea in diseases classified elsewhere"
    },
    {
      "name": "H200",
      "description": "Acute and subacute iridocyclitis"
    },
    {
      "name": "H201",
      "description": "Chronic iridocyclitis"
    },
    {
      "name": "H202",
      "description": "Lens-induced iridocyclitis"
    },
    {
      "name": "H208",
      "description": "Other iridocyclitis"
    },
    {
      "name": "H209",
      "description": "Iridocyclitis, unspecified"
    },
    {
      "name": "H210",
      "description": "Hyphaema"
    },
    {
      "name": "H211",
      "description": "Other vascular disorders of iris and ciliary body"
    },
    {
      "name": "H212",
      "description": "Degeneration of iris and ciliary body"
    },
    {
      "name": "H213",
      "description": "Cyst of iris, ciliary body and anterior chamber"
    },
    {
      "name": "H214",
      "description": "Pupillary membranes"
    },
    {
      "name": "H215",
      "description": "Other adhesions and disruptions of iris and ciliary body"
    },
    {
      "name": "H218",
      "description": "Other spcified disorders of iris and ciliary body"
    },
    {
      "name": "H219",
      "description": "Disorder of iris and ciliary body, unspecified"
    },
    {
      "name": "H220",
      "description": "Iridocyclitis in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "H221",
      "description": "Iridocyclitis in other diseases classified elsewhere"
    },
    {
      "name": "H228",
      "description": "Other disorders of iris and ciliary body in diseases classified elsewhere"
    },
    {
      "name": "H250",
      "description": "Senile incipient cataract"
    },
    {
      "name": "H251",
      "description": "Senile nuclear cataract"
    },
    {
      "name": "H252",
      "description": "Senile cataract, morgagnian type"
    },
    {
      "name": "H258",
      "description": "Other senile cataract"
    },
    {
      "name": "H259",
      "description": "Senile cataract, unspecified"
    },
    {
      "name": "H260",
      "description": "Infantile, juvenile and presenile cataract"
    },
    {
      "name": "H261",
      "description": "Traumatic cataract"
    },
    {
      "name": "H262",
      "description": "Complicated cataract"
    },
    {
      "name": "H263",
      "description": "Drug-induced cataract"
    },
    {
      "name": "H264",
      "description": "After-cataract"
    },
    {
      "name": "H268",
      "description": "Other specified cataract"
    },
    {
      "name": "H269",
      "description": "Cataract, unspecified"
    },
    {
      "name": "H270",
      "description": "Aphakia"
    },
    {
      "name": "H271",
      "description": "Dislocation of lens"
    },
    {
      "name": "H278",
      "description": "Other specified disorders of lens"
    },
    {
      "name": "H279",
      "description": "Disorder of lens, unspecified"
    },
    {
      "name": "H280",
      "description": "Diabetic cataract (E10-E14 with common fourth character .3)"
    },
    {
      "name": "H281",
      "description": "Cataract in other endocrine, nutritional and metabolic disrases"
    },
    {
      "name": "H282",
      "description": "Cataract in other diseases classified elsewhere"
    },
    {
      "name": "H288",
      "description": "Other disorders of lens in diseases classified elsewhere"
    },
    {
      "name": "H300",
      "description": "Focal chorioretinal inflammation"
    },
    {
      "name": "H301",
      "description": "Disseminated chorioretinal inflammation"
    },
    {
      "name": "H302",
      "description": "Posterior cyclitis"
    },
    {
      "name": "H308",
      "description": "Other chorioretinal inflammation"
    },
    {
      "name": "H309",
      "description": "Chorioretinal inflammation, unsepcified"
    },
    {
      "name": "H310",
      "description": "Chorioretinal scars"
    },
    {
      "name": "H311",
      "description": "Choroidal degeneration"
    },
    {
      "name": "H312",
      "description": "Hereditary choridal dystrophy"
    },
    {
      "name": "H313",
      "description": "Choroidal haemorrhage and rupture"
    },
    {
      "name": "H314",
      "description": "Choroidal detachment"
    },
    {
      "name": "H318",
      "description": "Other specified disorders of choroid"
    },
    {
      "name": "H319",
      "description": "Disorder of choroids, unspecified"
    },
    {
      "name": "H320",
      "description": "Chorioretinal inflammation in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "H328",
      "description": "Other chroioretinal disorders in diseases classified elsewhere"
    },
    {
      "name": "H330",
      "description": "Retinal detachment with rerinal break"
    },
    {
      "name": "H331",
      "description": "Retinoschisis and retinal cysts"
    },
    {
      "name": "H332",
      "description": "Serous retinal detachment"
    },
    {
      "name": "H333",
      "description": "Retinal breaks without detachment"
    },
    {
      "name": "H334",
      "description": "Traction detachment of retina"
    },
    {
      "name": "H335",
      "description": "Other retinal detachments"
    },
    {
      "name": "H340",
      "description": "Tranient retinal artery occlusion"
    },
    {
      "name": "H341",
      "description": "Central retinal artery occlusion"
    },
    {
      "name": "H342",
      "description": "Other retinal artery occlusions"
    },
    {
      "name": "H348",
      "description": "Other retinal vascular occlusions"
    },
    {
      "name": "H349",
      "description": "Retinal vascular occlusion, unspecified"
    },
    {
      "name": "H350",
      "description": "Background retinopathy and retinal vascular changes"
    },
    {
      "name": "H351",
      "description": "Retinopathy of prematurity"
    },
    {
      "name": "H352",
      "description": "Other proliferative retiopathy"
    },
    {
      "name": "H353",
      "description": "Degeneration of macula and posterior pole"
    },
    {
      "name": "H354",
      "description": "Peripheral retinal degeneration"
    },
    {
      "name": "H355",
      "description": "Hereditary retinal dystrophy"
    },
    {
      "name": "H356",
      "description": "Retinal haemorrhage"
    },
    {
      "name": "H357",
      "description": "Separation of retinal layers"
    },
    {
      "name": "H358",
      "description": "Other specified retinal disorders"
    },
    {
      "name": "H359",
      "description": "Retinal disorders, unspecified"
    },
    {
      "name": "H3600",
      "description": "Nonproliferative diabetic retinopathy (NPDR)"
    },
    {
      "name": "H3601",
      "description": "Nonproliferative diabetic retinopathy (NPDR) with maculopathy"
    },
    {
      "name": "H3602",
      "description": "Proliferative diabetic retinopathy (PDR)"
    },
    {
      "name": "H3609",
      "description": "Diabetic retinopathy, unspecified"
    },
    {
      "name": "H368",
      "description": "Other retinal disorders in diseases classified elsewhere"
    },
    {
      "name": "H400",
      "description": "Glaucoma suspect"
    },
    {
      "name": "H401",
      "description": "Primary open-angle glaucoma"
    },
    {
      "name": "H402",
      "description": "Primary angle-closure glaucoma"
    },
    {
      "name": "H403",
      "description": "Glaucoma secondary to eye trauma"
    },
    {
      "name": "H404",
      "description": "Glaucoma secondary to eye inflammation"
    },
    {
      "name": "H405",
      "description": "Glaucoma secondary to other eye disorders"
    },
    {
      "name": "H406",
      "description": "Glaucoma secondary to drugs"
    },
    {
      "name": "H408",
      "description": "Other glaucoma"
    },
    {
      "name": "H409",
      "description": "Glaucoma, unspecified"
    },
    {
      "name": "H420",
      "description": "Glaucoma in endocrine, nutritional and metabolic diseases"
    },
    {
      "name": "H428",
      "description": "Glaucoma in other diseases classified elsewhere"
    },
    {
      "name": "H430",
      "description": "Vitreous prolapse"
    },
    {
      "name": "H431",
      "description": "Vitreous haemorrhage"
    },
    {
      "name": "H432",
      "description": "Crystalline deposits in vitreous body"
    },
    {
      "name": "H433",
      "description": "Other vitreous opacities"
    },
    {
      "name": "H438",
      "description": "Other disorders of vitreous body"
    },
    {
      "name": "H439",
      "description": "Disorder of vitreous body, unspecified"
    },
    {
      "name": "H440",
      "description": "Purulent endophthalmitis"
    },
    {
      "name": "H441",
      "description": "Other endophthalmitis"
    },
    {
      "name": "H442",
      "description": "Degenerative myopia"
    },
    {
      "name": "H443",
      "description": "Other degenerative disorders of globe"
    },
    {
      "name": "H444",
      "description": "Hypotony of eye"
    },
    {
      "name": "H445",
      "description": "Degenerated conditions of globe"
    },
    {
      "name": "H446",
      "description": "Retined (old) intraocular foreign body, magnetic"
    },
    {
      "name": "H447",
      "description": "Ratained (old) intraocular foreign body, nonmagnetic"
    },
    {
      "name": "H448",
      "description": "Other disorders of globe"
    },
    {
      "name": "H449",
      "description": "Disorder fo globe, unspecified"
    },
    {
      "name": "H450",
      "description": "Vitreous haemorrhage in disorders classified elsewhere"
    },
    {
      "name": "H451",
      "description": "Endophthalmitis in diseases classified elsewhere"
    },
    {
      "name": "H458",
      "description": "Other disorders of vitreous body and globe in diseases classified elsewhere"
    },
    {
      "name": "H46",
      "description": "Optic neuritis"
    },
    {
      "name": "H470",
      "description": "Disorders of optic nerve, not elsewhere classified"
    },
    {
      "name": "H471",
      "description": "Papilloedema, unspecified"
    },
    {
      "name": "H472",
      "description": "Optic atrophy"
    },
    {
      "name": "H473",
      "description": "Other disorders of optic disc"
    },
    {
      "name": "H474",
      "description": "Disorders of optic chiasm"
    },
    {
      "name": "H475",
      "description": "Disorders of other visual pathways"
    },
    {
      "name": "H476",
      "description": "Disorders of virual cortex"
    },
    {
      "name": "H477",
      "description": "Disorder of visual pathways, unspecified"
    },
    {
      "name": "H480",
      "description": "Optic atrophy in diseases classified elsewhere"
    },
    {
      "name": "H481",
      "description": "Retrobulbar neuritis in diseases classified elsewhere"
    },
    {
      "name": "H488",
      "description": "Other disorders of optic nerve and visual pathways in diseases classified elsewhere"
    },
    {
      "name": "H490",
      "description": "Third [oculomotor] nerve palsy"
    },
    {
      "name": "H491",
      "description": "Fourth [trochlear] nerve palsy"
    },
    {
      "name": "H492",
      "description": "Sixth [abducent] nerve palsy"
    },
    {
      "name": "H493",
      "description": "Total (external) ophthalmoplegia"
    },
    {
      "name": "H494",
      "description": "Progressive external ophthalmoplegia"
    },
    {
      "name": "H498",
      "description": "Other paralytic strabismus"
    },
    {
      "name": "H499",
      "description": "Paralytic strabismus, unspecified"
    },
    {
      "name": "H500",
      "description": "Convergent concomitant strabismus"
    },
    {
      "name": "H501",
      "description": "Divergent concomitant strabismus"
    },
    {
      "name": "H502",
      "description": "Vertical strabismus"
    },
    {
      "name": "H503",
      "description": "Intermittent heterotropia"
    },
    {
      "name": "H504",
      "description": "Other and unspecified heterotropia"
    },
    {
      "name": "H505",
      "description": "Heterophoria"
    },
    {
      "name": "H506",
      "description": "Mechanical strabismus"
    },
    {
      "name": "H508",
      "description": "Other specified strabismus"
    },
    {
      "name": "H509",
      "description": "Strabismus, unspecified"
    },
    {
      "name": "H510",
      "description": "Palsy of conjugate gaze"
    },
    {
      "name": "H511",
      "description": "Convergence insufficiency and excess"
    },
    {
      "name": "H512",
      "description": "Internuclear ophthalomoplegia"
    },
    {
      "name": "H518",
      "description": "Other specified disorders of binocular movement"
    },
    {
      "name": "H519",
      "description": "Disorder of binocular movement, unjspecified"
    },
    {
      "name": "H520",
      "description": "Hypermetropia"
    },
    {
      "name": "H521",
      "description": "Myopia"
    },
    {
      "name": "H522",
      "description": "Astigmatism"
    },
    {
      "name": "H523",
      "description": "Anisometropia and aniseikonia"
    },
    {
      "name": "H524",
      "description": "Prebyopia"
    },
    {
      "name": "H525",
      "description": "Disorders of accommodation"
    },
    {
      "name": "H526",
      "description": "Other disorders of refaction"
    },
    {
      "name": "H527",
      "description": "Disorder fo refaction, unspecified"
    },
    {
      "name": "H530",
      "description": "Amblyopia ex anopsia"
    },
    {
      "name": "H531",
      "description": "Subjective visual disturbances"
    },
    {
      "name": "H532",
      "description": "Diplopia"
    },
    {
      "name": "H533",
      "description": "Other disorders of binocular vison"
    },
    {
      "name": "H534",
      "description": "Visual field defects"
    },
    {
      "name": "H535",
      "description": "Colour vison deficiencies"
    },
    {
      "name": "H536",
      "description": "Night blindness"
    },
    {
      "name": "H538",
      "description": "Other visual disturbances"
    },
    {
      "name": "H539",
      "description": "Visual disturbance, unspecifeid"
    },
    {
      "name": "H540",
      "description": "Blindness, binocular"
    },
    {
      "name": "H541",
      "description": "Severe visual impairment, binocular"
    },
    {
      "name": "H542",
      "description": "Moderate visual impairment, binocular"
    },
    {
      "name": "H543",
      "description": "Mild or no visual impairment, binocular"
    },
    {
      "name": "H544",
      "description": "Blindness, monocular"
    },
    {
      "name": "H545",
      "description": "Severe visual impairment, monocular"
    },
    {
      "name": "H546",
      "description": "Moderate visual impairment, monocular"
    },
    {
      "name": "H549",
      "description": "Unspecified visual impairment (binocular)"
    },
    {
      "name": "H55",
      "description": "Nystagmus and other irregular eye movements"
    },
    {
      "name": "H570",
      "description": "Anomalies of pupillary function"
    },
    {
      "name": "H571",
      "description": "Ocular pain"
    },
    {
      "name": "H578",
      "description": "Other specified disorders of eye and adnexa"
    },
    {
      "name": "H579",
      "description": "Disorder of eye and adnexa, unsepcified"
    },
    {
      "name": "H580",
      "description": "Anomalies of pupilary function in diseases classified elsewhere"
    },
    {
      "name": "H581",
      "description": "Visual disturbances in diseases classified elsewhere"
    },
    {
      "name": "H588",
      "description": "Other specified disorders of eye and adnexa in diseases classified elsewhere"
    },
    {
      "name": "H590",
      "description": "Keratopathy (bullous aphakia) following cataract surgery"
    },
    {
      "name": "H598",
      "description": "Other postprocedural disorders of eye and adnexa"
    },
    {
      "name": "H599",
      "description": "Postprocedural disorders of eye and adnexa, unspecified"
    },
    {
      "name": "H600",
      "description": "Abscess fo external ear"
    },
    {
      "name": "H601",
      "description": "Cellulitis of external ear"
    },
    {
      "name": "H602",
      "description": "Malignant otitis externa"
    },
    {
      "name": "H603",
      "description": "Other infective otitis externa"
    },
    {
      "name": "H604",
      "description": "Cholesteatoma of external ear"
    },
    {
      "name": "H605",
      "description": "Acute otitis externa, noninfective"
    },
    {
      "name": "H608",
      "description": "Other otitis externa"
    },
    {
      "name": "H609",
      "description": "Otitis externa, unspecified"
    },
    {
      "name": "H610",
      "description": "Perichondritis of external ear"
    },
    {
      "name": "H611",
      "description": "Noninfective disorders of pinna"
    },
    {
      "name": "H612",
      "description": "Impacted cerumen"
    },
    {
      "name": "H613",
      "description": "Acquired stenosis of external ear canal"
    },
    {
      "name": "H614",
      "description": "Hematoma or seroma of external ear"
    },
    {
      "name": "H618",
      "description": "Other specified disorders of external ear"
    },
    {
      "name": "H619",
      "description": "Disorder of external ear, unspecified"
    },
    {
      "name": "H620",
      "description": "Otitis externa in bacterial diseases classified elsewhere"
    },
    {
      "name": "H621",
      "description": "Otitis externa in viral diseases classified elsewhere"
    },
    {
      "name": "H622",
      "description": "Otitis enterna in mycoses"
    },
    {
      "name": "H623",
      "description": "Otitis externa in other infectious and parasitic diseases diseases classified elsewhere"
    },
    {
      "name": "H624",
      "description": "Otitis externa in other diseases classified elsewhere"
    },
    {
      "name": "H628",
      "description": "Other disorders of external ear in diseases classified elsewhere"
    },
    {
      "name": "H650",
      "description": "Acute serous otitis media"
    },
    {
      "name": "H651",
      "description": "Other acute nonsuppurative otitis media"
    },
    {
      "name": "H652",
      "description": "Chronic serous otitis media"
    },
    {
      "name": "H653",
      "description": "Chronic mucoid otitis media"
    },
    {
      "name": "H654",
      "description": "Other chronic nonsuppurative otitis media"
    },
    {
      "name": "H659",
      "description": "Nonsuppurative otitis media, unspecified"
    },
    {
      "name": "H660",
      "description": "Acute suppurative otitis media"
    },
    {
      "name": "H661",
      "description": "Chronic tubotympanic suppurative otitis media"
    },
    {
      "name": "H662",
      "description": "Chronic atticoantral suppurative otitis media"
    },
    {
      "name": "H663",
      "description": "Other chronic suppurative otitis media"
    },
    {
      "name": "H664",
      "description": "Suppurative otitis media, unspecified"
    },
    {
      "name": "H669",
      "description": "Otitis media, unspecified"
    },
    {
      "name": "H670",
      "description": "Otitis media in bacterial diseases  classified elsewhere"
    },
    {
      "name": "H671",
      "description": "Otitis media in viral diseases  classified elsewhere"
    },
    {
      "name": "H678",
      "description": "Otitis media in other diseases  classified elsewhere"
    },
    {
      "name": "H680",
      "description": "Eustachian salpingitis"
    },
    {
      "name": "H681",
      "description": "Obstruction of Eustachian tube"
    },
    {
      "name": "H690",
      "description": "Patulous  Eustachian tube"
    },
    {
      "name": "H698",
      "description": "Other specified disorders of  Eustachian tube"
    },
    {
      "name": "H699",
      "description": "Eustachian tube disorder, unspecified"
    },
    {
      "name": "H700",
      "description": "Acute mastoiditis"
    },
    {
      "name": "H701",
      "description": "Chronic mastoiditis"
    },
    {
      "name": "H702",
      "description": "Petrositis"
    },
    {
      "name": "H708",
      "description": "Other mastoiditis and related conditions"
    },
    {
      "name": "H709",
      "description": "Mastoiditis, unspecified"
    },
    {
      "name": "H71",
      "description": "Cholesteatoma of middle ear"
    },
    {
      "name": "H720",
      "description": "Central perforation of tympanic membrane"
    },
    {
      "name": "H721",
      "description": "Attic perforation of tympanic membrane"
    },
    {
      "name": "H722",
      "description": "Other marginal perforations of tympanic membrane"
    },
    {
      "name": "H728",
      "description": "Other perforations of tympanic membrane"
    },
    {
      "name": "H729",
      "description": "Perforation tympanic membrane, unspecified"
    },
    {
      "name": "H730",
      "description": "Acute myringitis"
    },
    {
      "name": "H731",
      "description": "Chronic myringitis"
    },
    {
      "name": "H738",
      "description": "Other specified disorders of tympanic membrane"
    },
    {
      "name": "H739",
      "description": "Disorder of tympanic membrane, unspecified"
    },
    {
      "name": "H740",
      "description": "Typanosclerosis"
    },
    {
      "name": "H741",
      "description": "Adhesive middle ear disease"
    },
    {
      "name": "H742",
      "description": "Discontinuity and dislocation of ear ossicles"
    },
    {
      "name": "H743",
      "description": "Other acquired abnormalities of ear ossicles"
    },
    {
      "name": "H744",
      "description": "Polyp of middle ear"
    },
    {
      "name": "H748",
      "description": "Other specified disorders of middle ear and mastoid"
    },
    {
      "name": "H749",
      "description": "Disorder of midle ear and mastoid, unspeciffied"
    },
    {
      "name": "H750",
      "description": "Mastoiditis in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "H758",
      "description": "Other specified disorders of middle ear and mastoid in diseases classified elsewhere"
    },
    {
      "name": "H800",
      "description": "Otosclerosis involving oval window, nonobliterative"
    },
    {
      "name": "H801",
      "description": "Otosclerosis involving oval window, oblitertive"
    },
    {
      "name": "H802",
      "description": "Cochlear otosclerosis"
    },
    {
      "name": "H808",
      "description": "Other otosclerosis"
    },
    {
      "name": "H809",
      "description": "Otosclerosis, unspecified"
    },
    {
      "name": "H810",
      "description": "Meniere's disease"
    },
    {
      "name": "H811",
      "description": "Benign paroxysmal vertigo"
    },
    {
      "name": "H812",
      "description": "Vestibular neuronitis"
    },
    {
      "name": "H813",
      "description": "Other peripheral vertigo"
    },
    {
      "name": "H814",
      "description": "Vertigo of central origin"
    },
    {
      "name": "H818",
      "description": "Other disorders of vestibular function"
    },
    {
      "name": "H819",
      "description": "Disorder of verstibular function, unspecified"
    },
    {
      "name": "H82",
      "description": "Vertignous syndrome in diseases classified elsewhere"
    },
    {
      "name": "H830",
      "description": "Labyrinthitis"
    },
    {
      "name": "H831",
      "description": "Labyrinthine fistula"
    },
    {
      "name": "H832",
      "description": "Labyrinthine dysfunction"
    },
    {
      "name": "H833",
      "description": "Noise effects on inner ear"
    },
    {
      "name": "H838",
      "description": "Other specified diseases of inner ear"
    },
    {
      "name": "H839",
      "description": "Disease of inner ear, unspecified"
    },
    {
      "name": "H900",
      "description": "Conductive hearing loss, bilateral"
    },
    {
      "name": "H901",
      "description": "Conductive hearing loss, unilateral with unrestricted hearing on the contralateral side"
    },
    {
      "name": "H902",
      "description": "Conductive hearing loss, unspecified"
    },
    {
      "name": "H903",
      "description": "Sensorineural hearing loss, bilateral"
    },
    {
      "name": "H904",
      "description": "Sensorineural hearing loss, unilateral with unrestricted hearing on the contralateral side"
    },
    {
      "name": "H905",
      "description": "Sensorineural hearing loss, unspecified"
    },
    {
      "name": "H906",
      "description": "Mixed conductive and sensorineural hearing loss, bilateral"
    },
    {
      "name": "H907",
      "description": "Mixed conductive and sensorineural hearing loss, unilateral with unrestricted hearing on the contralateral side"
    },
    {
      "name": "H908",
      "description": "Mixed conductive and sensorineural hearing loss, unspecified"
    },
    {
      "name": "H910",
      "description": "Ototoxic heating loss"
    },
    {
      "name": "H911",
      "description": "Presbycusis"
    },
    {
      "name": "H912",
      "description": "Sudden idiopathic heating loss"
    },
    {
      "name": "H913",
      "description": "Deaf mutism, not elsewhere classified"
    },
    {
      "name": "H918",
      "description": "Other specified heating loss"
    },
    {
      "name": "H919",
      "description": "Hearing loss, unspecified"
    },
    {
      "name": "H920",
      "description": "Otagia"
    },
    {
      "name": "H921",
      "description": "Otorrhoea"
    },
    {
      "name": "H922",
      "description": "Otorrhagia"
    },
    {
      "name": "H930",
      "description": "Degenerative and vascular disorders of ear"
    },
    {
      "name": "H931",
      "description": "Tinnitus"
    },
    {
      "name": "H932",
      "description": "Other abnormal auditory perceptions"
    },
    {
      "name": "H933",
      "description": "Disorders of acoustic nerve"
    },
    {
      "name": "H938",
      "description": "Other specified disorders of ear"
    },
    {
      "name": "H939",
      "description": "Disorder of ear, unspecified"
    },
    {
      "name": "H940",
      "description": "Acoustic neuritis in infectioun and parasitic diseases classified elsewhere"
    },
    {
      "name": "H948",
      "description": "Other specified disorders of ear in diseases classified elsewhere"
    },
    {
      "name": "H950",
      "description": "Recurrent cholesteatoma of postmastoidectomy cavity"
    },
    {
      "name": "H951",
      "description": "Other disorders following mastoidectomy"
    },
    {
      "name": "H958",
      "description": "Other postprocedural disorders of ear and  mastoid process"
    },
    {
      "name": "H959",
      "description": "Postprocedural disorder of ear and mastoid process, unspecified"
    },
    {
      "name": "I00",
      "description": "Rheumatic fever without mention of heart involvement"
    },
    {
      "name": "I010",
      "description": "Acute rheumatic pericarditis"
    },
    {
      "name": "I011",
      "description": "Acute rheumatic endocarditis"
    },
    {
      "name": "I012",
      "description": "Acute rheumatic myocarditis"
    },
    {
      "name": "I018",
      "description": "Other acute rheumatic heart disease"
    },
    {
      "name": "I019",
      "description": "Acute rheumatic heart disease, unspecified"
    },
    {
      "name": "I020",
      "description": "Rheumatic chorea with herat involvement"
    },
    {
      "name": "I029",
      "description": "Rheumatic chorea without herat involvement"
    },
    {
      "name": "I050",
      "description": "Mitral stenosis"
    },
    {
      "name": "I051",
      "description": "Rheumatic mitral insufficiency"
    },
    {
      "name": "I052",
      "description": "Mitral stenosis with insufficiency"
    },
    {
      "name": "I053",
      "description": "Other mitral valve diseases"
    },
    {
      "name": "I059",
      "description": "Mitral valve disease, unspecified"
    },
    {
      "name": "I060",
      "description": "Rheumatic aortic stenosis"
    },
    {
      "name": "I061",
      "description": "Rheumatic aortic insufficiency"
    },
    {
      "name": "I062",
      "description": "Rheumatic aortic stenosis with insufficiency"
    },
    {
      "name": "I068",
      "description": "Other rheumatic aortic valve diseases"
    },
    {
      "name": "I069",
      "description": "Rheumatic aortic valve disease, unspecified"
    },
    {
      "name": "I070",
      "description": "Tricuspid stenosis"
    },
    {
      "name": "I071",
      "description": "Tricuspid insufficiency"
    },
    {
      "name": "I072",
      "description": "Tricuspid stenosis with insufficiency"
    },
    {
      "name": "I078",
      "description": "Other tricuspid valve diseases"
    },
    {
      "name": "I079",
      "description": "Tricuspid valve disease, unspecified"
    },
    {
      "name": "I080",
      "description": "Disorders of both mitral and aortic valves"
    },
    {
      "name": "I081",
      "description": "Disorders of both mitral and tricuspid valves"
    },
    {
      "name": "I082",
      "description": "Disorders of both aortic and tricuspid valves"
    },
    {
      "name": "I083",
      "description": "Combine disorders of both mitral, aortic and tricuspid valves"
    },
    {
      "name": "I088",
      "description": "Other multiple valve diseases"
    },
    {
      "name": "I089",
      "description": "Multiple valve disease, unspecified"
    },
    {
      "name": "I090",
      "description": "Rheumatic myocarditis"
    },
    {
      "name": "I091",
      "description": "Rheumatic diseases of endocardium, valve unspecified"
    },
    {
      "name": "I092",
      "description": "Chronic rheumatic pericarditis"
    },
    {
      "name": "I098",
      "description": "Other specified rheumatic heart diseases"
    },
    {
      "name": "I099",
      "description": "Rheumatic heart disease, unspecified"
    },
    {
      "name": "I10",
      "description": "Essential (primary) hypertension"
    },
    {
      "name": "I110",
      "description": "Hypertensive hert diseases with (cogestive) heart failure"
    },
    {
      "name": "I119",
      "description": "Hypertensive hert diseases without (cogestive) heart failure"
    },
    {
      "name": "I120",
      "description": "Hypertensive renal disease with renal failure"
    },
    {
      "name": "I129",
      "description": "Hypertensive renal disease without renal failure"
    },
    {
      "name": "I130",
      "description": "Hypertensive heart and renal disease with (congestive) heart failure"
    },
    {
      "name": "I131",
      "description": "Hypertensive heart and renal disease with renal failure"
    },
    {
      "name": "I132",
      "description": "Hypertensive heart and renal disease with both (congestive) heart failure and renal failure"
    },
    {
      "name": "I139",
      "description": "Hypertensive heart and renal disease, unspecified"
    },
    {
      "name": "I150",
      "description": "Renovascular hypertension"
    },
    {
      "name": "I151",
      "description": "Hypertension secondary to other renal disorders"
    },
    {
      "name": "I152",
      "description": "Hypertension secondary to endocrine disorders"
    },
    {
      "name": "I158",
      "description": "Other secondary hypertension"
    },
    {
      "name": "I159",
      "description": "Secondary hypertension, unspecified"
    },
    {
      "name": "I200",
      "description": "Unstable angina"
    },
    {
      "name": "I201",
      "description": "Angina pectoris with documented spasm"
    },
    {
      "name": "I208",
      "description": "Other formas of angina pectoris"
    },
    {
      "name": "I209",
      "description": "Angina pectoris, unspecified"
    },
    {
      "name": "I210",
      "description": "Acute transmural myocardial infarction of anterior wall"
    },
    {
      "name": "I211",
      "description": "Acute transmural myocardial infarction of inferior wall"
    },
    {
      "name": "I212",
      "description": "Acute transmuural myocardial infarction of other sites"
    },
    {
      "name": "I213",
      "description": "Acute transmuural myocardial infarction of unspecified site"
    },
    {
      "name": "I214",
      "description": "Acute subendocardial myocardial infarction"
    },
    {
      "name": "I219",
      "description": "Acute myocardial infarction,unspecified"
    },
    {
      "name": "I220",
      "description": "Subsequent myocardial infarction of anterior wall"
    },
    {
      "name": "I221",
      "description": "Subsequent myocardial infarction of inferior wall"
    },
    {
      "name": "I228",
      "description": "Subsequent myocardial infarction of other sites"
    },
    {
      "name": "I229",
      "description": "Subsequent myocardial infarction of unspecified site"
    },
    {
      "name": "I230",
      "description": "Haemopericardium as current complication following acute myocardial infarction"
    },
    {
      "name": "I231",
      "description": "Atrial septal defect as current complication following acute myocardial infarction"
    },
    {
      "name": "I232",
      "description": "Ventricular spetal defect as current complication following acute myocardial infarction"
    },
    {
      "name": "I233",
      "description": "Rupture of cardiac wall without haemopericardium as current complication following acute myocardial infarction"
    },
    {
      "name": "I234",
      "description": "Rupture of chordae tendineae as current complication following acute myocardial infarction"
    },
    {
      "name": "I235",
      "description": "Rupture of papillary muscle as current complication following acute myocardial infarction"
    },
    {
      "name": "I236",
      "description": "Thrombosis of atrium, auricular appendage, and ventricle as current complications following acute myocardial infarction"
    },
    {
      "name": "I238",
      "description": "Other current complications following acute myocardial infarction"
    },
    {
      "name": "I240",
      "description": "Coronary thrombosis not resulting in myocardial infarction"
    },
    {
      "name": "I241",
      "description": "Dressler's syndrome"
    },
    {
      "name": "I248",
      "description": "Other forms of acute ischaemic heart disease"
    },
    {
      "name": "I249",
      "description": "Acute ischaemic heart disease, unspecified"
    },
    {
      "name": "I250",
      "description": "Atherosclerotic cardiovascular disease, so described"
    },
    {
      "name": "I251",
      "description": "Atherosclerotic heart disease"
    },
    {
      "name": "I252",
      "description": "Old myocardial infarction"
    },
    {
      "name": "I253",
      "description": "Aneurysm of heart"
    },
    {
      "name": "I254",
      "description": "Coronary artery aneurysm and dissection"
    },
    {
      "name": "I255",
      "description": "Ischaemic cardiomyopathy"
    },
    {
      "name": "I256",
      "description": "Silent myocardial ischaemia"
    },
    {
      "name": "I258",
      "description": "Other forms of chronic ischaemic heart disease"
    },
    {
      "name": "I259",
      "description": "Chronic ischaemic heart disease, unspecified"
    },
    {
      "name": "I260",
      "description": "Pulmonary embolism with mention of acute cor pulmonale"
    },
    {
      "name": "I269",
      "description": "Pulmonary embolism without mintion of acute cor pulmonale"
    },
    {
      "name": "I270",
      "description": "Primary pulmonary hypertension"
    },
    {
      "name": "I271",
      "description": "Kyphoscoliotic heart disease"
    },
    {
      "name": "I272",
      "description": "Other secondary pulmonary hypertention"
    },
    {
      "name": "I278",
      "description": "Other specified pulmonary heart diseases"
    },
    {
      "name": "I279",
      "description": "Pulmonary heart disease ,unspecified"
    },
    {
      "name": "I280",
      "description": "Arteriovenous fistula of pulmonary vessels"
    },
    {
      "name": "I281",
      "description": "Aneurysm of pulmonary artery"
    },
    {
      "name": "I288",
      "description": "Other specified diseases of pulmonary vessels"
    },
    {
      "name": "I289",
      "description": "Disease of pulmonary vessels, unspcified"
    },
    {
      "name": "I300",
      "description": "Acute nonspecific idiopathic pericarditis"
    },
    {
      "name": "I301",
      "description": "Infective pericarditis"
    },
    {
      "name": "I308",
      "description": "Other forms of acute pericarditis"
    },
    {
      "name": "I309",
      "description": "Acute pericarditis, unspecified"
    },
    {
      "name": "I310",
      "description": "Chronic adhesive pericarditis"
    },
    {
      "name": "I311",
      "description": "Chronic constrictive pericarditis"
    },
    {
      "name": "I312",
      "description": "Haemopericardium, not elsewhere classified"
    },
    {
      "name": "I313",
      "description": "Pericardial effusion (noninflammatory)"
    },
    {
      "name": "I318",
      "description": "Other specified diseases of pericardium"
    },
    {
      "name": "I319",
      "description": "Disease of pericardium, unspecified"
    },
    {
      "name": "I320",
      "description": "Pericarditis in bacterial diseases classified elsewhere"
    },
    {
      "name": "I321",
      "description": "Pericarditis in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "I328",
      "description": "Pericarditis in other diseases classified elsewhere"
    },
    {
      "name": "I330",
      "description": "Acute and subacute infective endocarditis"
    },
    {
      "name": "I339",
      "description": "Acute endocarditis, unspecified"
    },
    {
      "name": "I340",
      "description": "Mitral (valve) insufficiency"
    },
    {
      "name": "I341",
      "description": "Mitral (valve) prolapse"
    },
    {
      "name": "I342",
      "description": "Nonrheumatic mitral (valve) stenosis"
    },
    {
      "name": "I348",
      "description": "Other nonrheumatic mitral valve disorders"
    },
    {
      "name": "I349",
      "description": "Nonrheumatic mitral valve disorder, unspecified"
    },
    {
      "name": "I350",
      "description": "Aortic (valve) stenosis"
    },
    {
      "name": "I351",
      "description": "Aortic (valve) insufficiency"
    },
    {
      "name": "I352",
      "description": "Aortic (valve) stenosis with insufficiency"
    },
    {
      "name": "I358",
      "description": "Other aortic valve disorders"
    },
    {
      "name": "I359",
      "description": "Aortc valve disorder, unspecified"
    },
    {
      "name": "I360",
      "description": "Nonrheumatic tricuspid (valve) stenosis"
    },
    {
      "name": "I361",
      "description": "Nonrheumatic tricuspid (valve) insufficiency"
    },
    {
      "name": "I362",
      "description": "Nonrheumatic tricuspid (valve) stenosis  with insufficiency"
    },
    {
      "name": "I368",
      "description": "Other nonrheumatic tricuspid valve disorders"
    },
    {
      "name": "I369",
      "description": "Nonrheumatic tricuspid valve disorder, unspecified"
    },
    {
      "name": "I370",
      "description": "Pulmonary valve stenosis"
    },
    {
      "name": "I371",
      "description": "Pulmonary valve insufficiency"
    },
    {
      "name": "I372",
      "description": "Pulmonary valve stenosis with insufficiency"
    },
    {
      "name": "I378",
      "description": "Other pulmonary valve disorders"
    },
    {
      "name": "I379",
      "description": "Pulmonary valve disorder, unspecified"
    },
    {
      "name": "I38",
      "description": "Endocarditis, valve unspecified"
    },
    {
      "name": "I390",
      "description": "Mitral valve disorders in diseases classified elsewhere"
    },
    {
      "name": "I391",
      "description": "Aortic valve disorders in diseases classified elsewhere"
    },
    {
      "name": "I392",
      "description": "Tricuspid valve disorders in diseases classified elsewhere"
    },
    {
      "name": "I393",
      "description": "Pulmonary valve disorders in diseases classified elsewhere"
    },
    {
      "name": "I394",
      "description": "Multiple valve disorders in diseases classified elsewhere"
    },
    {
      "name": "I398",
      "description": "Endocarditis, valve unspecified, in diseases classified elsewhere"
    },
    {
      "name": "I400",
      "description": "Infective myocarditis"
    },
    {
      "name": "I401",
      "description": "Isolated  myocarditis"
    },
    {
      "name": "I408",
      "description": "Other acute myocarditis"
    },
    {
      "name": "I409",
      "description": "Acute myocarditis, unspecified"
    },
    {
      "name": "I410",
      "description": "Myocarditis in bacterial diseases classified elsewhere"
    },
    {
      "name": "I411",
      "description": "Myocarditis in vral diseases classified elsewhere"
    },
    {
      "name": "I412",
      "description": "Myocarditis in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "I418",
      "description": "Myocarditis in other diseases classified elsewhere"
    },
    {
      "name": "I420",
      "description": "Dilated cardiomyopathy"
    },
    {
      "name": "I421",
      "description": "Obstructive hypertrophic cardiomyopathy"
    },
    {
      "name": "I422",
      "description": "Other hypertrophic cardiomyopathy"
    },
    {
      "name": "I423",
      "description": "Endomyocardial (eosinophilic) disease"
    },
    {
      "name": "I424",
      "description": "Endocardial fibroelastosis"
    },
    {
      "name": "I425",
      "description": "Other restrictive cardiomyopathy"
    },
    {
      "name": "I426",
      "description": "Alcoholic  cardiomyopathy"
    },
    {
      "name": "I427",
      "description": "Caradiomyopathy due to drugs and other external agents"
    },
    {
      "name": "I428",
      "description": "Other cardiomyopathies"
    },
    {
      "name": "I429",
      "description": "Cardiomyopathy, unspecified"
    },
    {
      "name": "I430",
      "description": "Cardiomyopathy in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "I431",
      "description": "Cardiomyopathy in metabolic diseases"
    },
    {
      "name": "I432",
      "description": "Cardiomyopathy in nutritional diseases"
    },
    {
      "name": "I438",
      "description": "Cardiomyopathy in other diseases classified elsewhere"
    },
    {
      "name": "I440",
      "description": "Atrioventricular block, first degree"
    },
    {
      "name": "I441",
      "description": "Atrioventricular block, second degree"
    },
    {
      "name": "I442",
      "description": "Atrioventricular block, complete"
    },
    {
      "name": "I443",
      "description": "Other and unspecified atrioventricular block"
    },
    {
      "name": "I444",
      "description": "Left anterior fascicular block"
    },
    {
      "name": "I445",
      "description": "Left posterior fasciculr block"
    },
    {
      "name": "I446",
      "description": "Other and unspecified fascicular block"
    },
    {
      "name": "I447",
      "description": "Left bundle-branch block, unspecified"
    },
    {
      "name": "I450",
      "description": "Right fascicular block"
    },
    {
      "name": "I451",
      "description": "Other and uncpecified right bundle-branch block"
    },
    {
      "name": "I452",
      "description": "Bifascicular block"
    },
    {
      "name": "I453",
      "description": "Trifascicular block"
    },
    {
      "name": "I454",
      "description": "Nonspecific intraventricular block"
    },
    {
      "name": "I455",
      "description": "Other spcified heart block"
    },
    {
      "name": "I456",
      "description": "Per-excitiation syndrome"
    },
    {
      "name": "I458",
      "description": "Other specified conduction disorders"
    },
    {
      "name": "I459",
      "description": "Conduction disorder, unspecified"
    },
    {
      "name": "I460",
      "description": "Cardiac arrest with successful resuscitation"
    },
    {
      "name": "I461",
      "description": "Sudden cardiac death, so described"
    },
    {
      "name": "I469",
      "description": "Cardiac arrest, unspecified"
    },
    {
      "name": "I470",
      "description": "Re-entry ventricular arrhythmia"
    },
    {
      "name": "I471",
      "description": "Supraventricular tachycardia"
    },
    {
      "name": "I472",
      "description": "Ventricular tachycardia"
    },
    {
      "name": "I479",
      "description": "Paroxysmal tachycardia, unspecified"
    },
    {
      "name": "I480",
      "description": "Paroxysmal atrial fibrillation"
    },
    {
      "name": "I481",
      "description": "Persistent atrial fibrillation"
    },
    {
      "name": "I482",
      "description": "Chronic atrial fibrillation"
    },
    {
      "name": "I483",
      "description": "Typical atrial flutter"
    },
    {
      "name": "I484",
      "description": "Atypical atrial flutter"
    },
    {
      "name": "I489",
      "description": "Atrial fibrillation and atrial flutter, unspecified"
    },
    {
      "name": "I4900",
      "description": "Ventricular fibrillation and flutter with structural heart diseases"
    },
    {
      "name": "I4901",
      "description": "Ventricular fibrillation and flutter without structural heart diseases \n(idiopathic)(Brugada’s syndrome)"
    },
    {
      "name": "I4909",
      "description": "Ventricular fibrillation and flutter, unspecified"
    },
    {
      "name": "I491",
      "description": "Atrial premature depolarization"
    },
    {
      "name": "I492",
      "description": "Junctional premature depolarization"
    },
    {
      "name": "I493",
      "description": "Ventricular premature depolarization"
    },
    {
      "name": "I494",
      "description": "Other and unspecifeid premature depolarization"
    },
    {
      "name": "I495",
      "description": "Sick sinus syndrome"
    },
    {
      "name": "I498",
      "description": "Other specified cardiac arrhythmias"
    },
    {
      "name": "I499",
      "description": "Cardiac arrhythmia, unspecified"
    },
    {
      "name": "I500",
      "description": "Congestive heart failure"
    },
    {
      "name": "I501",
      "description": "Left ventricular failure"
    },
    {
      "name": "I509",
      "description": "Heart failure, unspecified"
    },
    {
      "name": "I510",
      "description": "Cardiac septal defect"
    },
    {
      "name": "I511",
      "description": "Rupture of chordae tendineae, not elsewhere classified"
    },
    {
      "name": "I512",
      "description": "Rupture of papillary muscle, not elsewhere classified"
    },
    {
      "name": "I513",
      "description": "Intracardiac thrombosis, not  elsewhere classified"
    },
    {
      "name": "I514",
      "description": "Myocarditis, unspecified"
    },
    {
      "name": "I515",
      "description": "Myocardial degeneration"
    },
    {
      "name": "I516",
      "description": "Cardiovascular disease, unspecified"
    },
    {
      "name": "I517",
      "description": "Cardiomegaly"
    },
    {
      "name": "I518",
      "description": "Other Ill-defined heart diseases"
    },
    {
      "name": "I519",
      "description": "Heart disease, unspecified"
    },
    {
      "name": "I520",
      "description": "Other heart disorders in bacterial diseases classified elsewhere"
    },
    {
      "name": "I521",
      "description": "Other heart disorders in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "I528",
      "description": "Other heart disorders in other diseases classified elsewhere"
    },
    {
      "name": "I600",
      "description": "Subarachnoid haemorrhage from carotid siphon and bifurcation"
    },
    {
      "name": "I601",
      "description": "Subarachnoid haemorrhage from middle cerebral artery"
    },
    {
      "name": "I602",
      "description": "Subarachnoid haemorrhage from anterior communicating artery"
    },
    {
      "name": "I603",
      "description": "Subarachnoid haemorrhage from posterior communicating artery"
    },
    {
      "name": "I604",
      "description": "Subarachnoid haemorrhage from basilar artery"
    },
    {
      "name": "I605",
      "description": "Subarachnoid haemorrhage from vertebral artery"
    },
    {
      "name": "I606",
      "description": "Subarachnoid haemorrhage from other intracranial arteries"
    },
    {
      "name": "I607",
      "description": "Subarachnoid haemorrhage from intracranial artery, unspecified"
    },
    {
      "name": "I608",
      "description": "Other subarachnoid haemorrhage"
    },
    {
      "name": "I609",
      "description": "Subarachnoid haemorrhage, unspecified"
    },
    {
      "name": "I610",
      "description": "Intracerebral haemorrhage in hemisphere, subcortical"
    },
    {
      "name": "I611",
      "description": "Intracerebral haemorrhage in hemisphere, cortical"
    },
    {
      "name": "I612",
      "description": "Intracerebral haemorrhage in hemisphere, unspecified"
    },
    {
      "name": "I613",
      "description": "Intracerebral haemorrhage in brain stem"
    },
    {
      "name": "I614",
      "description": "Intracerebral haemorrhage in cerebellum"
    },
    {
      "name": "I615",
      "description": "Intracerebral haemorrhage, intraventricular"
    },
    {
      "name": "I616",
      "description": "Intracerebral haemorrhage, multiple localized"
    },
    {
      "name": "I618",
      "description": "Other intracerebral haemorrhage"
    },
    {
      "name": "I619",
      "description": "Intracerebral haemorrhage, unspecified"
    },
    {
      "name": "I620",
      "description": "Subdural haemorrhage (acute)(nontraumatic)"
    },
    {
      "name": "I621",
      "description": "Nontraumatic extradural haemorrhage"
    },
    {
      "name": "I629",
      "description": "Intracranial haemorrhage (nontraumatic), unspecified"
    },
    {
      "name": "I630",
      "description": "Cerebral infarction due to thrombosis of precerebral arteries"
    },
    {
      "name": "I631",
      "description": "Cerebral infarction due to embolism of precerebral arteries"
    },
    {
      "name": "I632",
      "description": "Cerebral infarction due to unspecified occlusion or stenosis of precerebral arteries"
    },
    {
      "name": "I633",
      "description": "Cerebral infarction due to thrombosis of cerebral arteries"
    },
    {
      "name": "I634",
      "description": "Cerebral infarction due to embolism of cerebral arteries"
    },
    {
      "name": "I635",
      "description": "Cerebral infarction due to unspecified occlusion or stenosis of cerebral arteries"
    },
    {
      "name": "I636",
      "description": "Cerebral infarction due to cerebral venous thrombosis,nonpyogenic"
    },
    {
      "name": "I638",
      "description": "Other cerebral infarction"
    },
    {
      "name": "I639",
      "description": "Cerebral infarction,unspecified"
    },
    {
      "name": "I64",
      "description": "Stroke,not specified as haemorrhage or infarction"
    },
    {
      "name": "I650",
      "description": "Occlusion and stenosis of vertebral artery"
    },
    {
      "name": "I651",
      "description": "Occlusion and stenosis of basilar artery"
    },
    {
      "name": "I652",
      "description": "Occlusion and stenosis of carotid artery"
    },
    {
      "name": "I653",
      "description": "Occlusion and stenosis of multiple and bilateral precerebral arteries"
    },
    {
      "name": "I658",
      "description": "Occlusion and stenosis of other precerebral arteries"
    },
    {
      "name": "I659",
      "description": "Occlusion and stenosis of unspecified precerebral artery"
    },
    {
      "name": "I660",
      "description": "Occlusion and stenosis of middle cerebral artery"
    },
    {
      "name": "I661",
      "description": "Occlusion and stenosis of anterior cerebral artery"
    },
    {
      "name": "I662",
      "description": "Occlusion and stenosis of posterior cerebral artery"
    },
    {
      "name": "I663",
      "description": "Occlusion and stenosis of cerebellar arteries"
    },
    {
      "name": "I664",
      "description": "Occlusion and stenosis of multiple and bilateral cerebral arteries"
    },
    {
      "name": "I668",
      "description": "Occlusion and stenosis of other cerebral arteries"
    },
    {
      "name": "I669",
      "description": "Occlusion and stenosis of unspecified cerebral artery"
    },
    {
      "name": "I670",
      "description": "Dissection of cerebral arteries, nonruptured"
    },
    {
      "name": "I671",
      "description": "Cerebral aneurysm, nonruptured"
    },
    {
      "name": "I672",
      "description": "Cerebral atherosclerosis"
    },
    {
      "name": "I673",
      "description": "Progressive vascular leukoencephalopathy"
    },
    {
      "name": "I674",
      "description": "Hypertensive encephalopathy"
    },
    {
      "name": "I675",
      "description": "Moyamoya disease"
    },
    {
      "name": "I676",
      "description": "Nonpyogenic thrombosis of intracranial venous system"
    },
    {
      "name": "I677",
      "description": "Cerebral arteritis, not elsewhere classified"
    },
    {
      "name": "I678",
      "description": "Other specified cerebrovascular diseases"
    },
    {
      "name": "I679",
      "description": "Cerebrovascular disease, unspecified"
    },
    {
      "name": "I680",
      "description": "Cerebral amyloid angiopathy (E85.-*]"
    },
    {
      "name": "I681",
      "description": "Cerebral arteristis in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "I682",
      "description": "Cerebral arteristis in other diseases classified elsewhere"
    },
    {
      "name": "I688",
      "description": "Other cerebrovascular disorders in diseases classified elsewhere"
    },
    {
      "name": "I690",
      "description": "Sequelae of subarachnoid haemorrhage"
    },
    {
      "name": "I691",
      "description": "Sequelae of intracerebral haemorrhage"
    },
    {
      "name": "I692",
      "description": "Sequelae of other nontraumatic intracranial haemorrhage"
    },
    {
      "name": "I693",
      "description": "Sequelae of cerebral infarction"
    },
    {
      "name": "I694",
      "description": "Sequelae of stroke, not specified as haemorrhage or infarction"
    },
    {
      "name": "I698",
      "description": "Sequelae of other and unspecified cerebrovascular diseases"
    },
    {
      "name": "I7000",
      "description": "Atherosclerosis of aorta without gangrene"
    },
    {
      "name": "I7001",
      "description": "Atherosclerosis of aorta with gangrene"
    },
    {
      "name": "I7010",
      "description": "Atherosclerosis of renal artery without gangrene"
    },
    {
      "name": "I7011",
      "description": "Atherosclerosis of renal artery with gangrene"
    },
    {
      "name": "I70200",
      "description": "Atherosclerosis of arteries of upper extremities without gangrene"
    },
    {
      "name": "I70201",
      "description": "Atherosclerosis of arteries of lower extremities without gangrene"
    },
    {
      "name": "I70202",
      "description": "Atherosclerosis of arteries of both extremities without gangrene"
    },
    {
      "name": "I70209",
      "description": "Atherosclerosis of arteries of extremities without gangrene, unspecified"
    },
    {
      "name": "I70210",
      "description": "Atherosclerosis of arteries of upper extremities with gangrene"
    },
    {
      "name": "I70211",
      "description": "Atherosclerosis of arteries of lower extremities with gangrene"
    },
    {
      "name": "I70212",
      "description": "Atherosclerosis of arteries of both extremities with gangrene"
    },
    {
      "name": "I70219",
      "description": "Atherosclerosis of arteries of extremities with gangrene, unspecified"
    },
    {
      "name": "I7030",
      "description": "Atherosclerosis of carotid artery without gangrene"
    },
    {
      "name": "I7031",
      "description": "Atherosclerosis of carotid artery with gangrene"
    },
    {
      "name": "I7080",
      "description": "Atherosclerosis of other arteries without gangrene"
    },
    {
      "name": "I7081",
      "description": "Atherosclerosis of other arteries with gangrene"
    },
    {
      "name": "I709",
      "description": "Generalized and unsepcified atherosclerosis"
    },
    {
      "name": "I7090",
      "description": "Generalized and unspecified atherosclerosis without gangrene"
    },
    {
      "name": "I7091",
      "description": "Generalized and unspecified atherosclerosis with gangrene"
    },
    {
      "name": "I710",
      "description": "Dissection of aorta [any part]"
    },
    {
      "name": "I711",
      "description": "Thoracic aortic aneurysm, ruptured"
    },
    {
      "name": "I712",
      "description": "Thoracic aortic aneurysm, without mention of rupture"
    },
    {
      "name": "I713",
      "description": "Abdominal aortic aneurysm, ruptured"
    },
    {
      "name": "I714",
      "description": "Abdominal aortic aneurysm, without mention of rupture"
    },
    {
      "name": "I715",
      "description": "Thoracoabdominal aortic,aneurysm, ruptured"
    },
    {
      "name": "I716",
      "description": "Thoracoabdominal aortic aneurysm, without mention of rupture"
    },
    {
      "name": "I718",
      "description": "Aortic aneurysm of unspecified site, ruptured"
    },
    {
      "name": "I719",
      "description": "Aortic aneurysm of unspecified site, without mention of rupture"
    },
    {
      "name": "I720",
      "description": "Aneurysm and dissection of carotid artery"
    },
    {
      "name": "I721",
      "description": "Aneurysm and dissection of artery of upper  extremity"
    },
    {
      "name": "I722",
      "description": "Aneurysm and dissection of renal artery"
    },
    {
      "name": "I723",
      "description": "Aneurysm and dissection of iliac artery"
    },
    {
      "name": "I724",
      "description": "Aneurysm and dissection of artery of lower  extremity "
    },
    {
      "name": "I725",
      "description": "Aneurysm and dissection of other precerebral arteries"
    },
    {
      "name": "I726",
      "description": "Aneurysm and dissection of vertebral artery"
    },
    {
      "name": "I728",
      "description": "Aneurysm and dissection of other specified arteries"
    },
    {
      "name": "I729",
      "description": "Aneurysm and dissection of unspecified site"
    },
    {
      "name": "I730",
      "description": "Raynaud's syndrome"
    },
    {
      "name": "I731",
      "description": "Thromboangiitis obliterans [Buerger]"
    },
    {
      "name": "I738",
      "description": "Other specified peripheral vascular diseases"
    },
    {
      "name": "I739",
      "description": "Peripheral vascular disease, unspecified"
    },
    {
      "name": "I740",
      "description": "Embolism and thrombosis of abdominal aorta"
    },
    {
      "name": "I741",
      "description": "Embolism and thrombosis of other and unspecified parts of aotra"
    },
    {
      "name": "I742",
      "description": "Embolism and thrombosis of arteries of upper extremities"
    },
    {
      "name": "I743",
      "description": "Embolism and thrombosis of arteries of lower extermities"
    },
    {
      "name": "I744",
      "description": "Embolism and thrombosis of arteries of extermities, unspecified"
    },
    {
      "name": "I745",
      "description": "Embolism and thrombosis of iliac artery"
    },
    {
      "name": "I748",
      "description": "Embolism and thrombosis of other arteries"
    },
    {
      "name": "I749",
      "description": "Embolism and thrombosis of unspecified artery"
    },
    {
      "name": "I770",
      "description": "Arteriovenous fistula, acquired"
    },
    {
      "name": "I771",
      "description": "Stricture of artery"
    },
    {
      "name": "I772",
      "description": "Rupture of artery"
    },
    {
      "name": "I773",
      "description": "Arterial fibromuscular dysplasia"
    },
    {
      "name": "I774",
      "description": "Coeliac artery compression syndrome"
    },
    {
      "name": "I775",
      "description": "Necrosis of artery"
    },
    {
      "name": "I776",
      "description": "Arteritis, unspecified"
    },
    {
      "name": "I778",
      "description": "Other specified disorders of arteries and arterioles"
    },
    {
      "name": "I779",
      "description": "Disorder of arteries and arterioles, unspecified"
    },
    {
      "name": "I780",
      "description": "Hereditary haemorrhagic telangiectasia"
    },
    {
      "name": "I781",
      "description": "Naevus, non-neoplastic"
    },
    {
      "name": "I788",
      "description": "Other diseases of capillaries"
    },
    {
      "name": "I789",
      "description": "Disease of capillaries, unspecified"
    },
    {
      "name": "I790",
      "description": "Aneurysm of aorta in diseases classified elsewhere"
    },
    {
      "name": "I791",
      "description": "Aortitis in diseases classified elsewhere"
    },
    {
      "name": "I792",
      "description": "Peripheral angiopathy in diseases classified elsewhere"
    },
    {
      "name": "I798",
      "description": "Other disorders of arteries, arterioles and capillaries in diseases classified elsewhere"
    },
    {
      "name": "I800",
      "description": "Phlebitis and thrombophlebitis of superficial vessels of lower extremities"
    },
    {
      "name": "I801",
      "description": "Phlebitis and thrombophlebitis of femoral vein"
    },
    {
      "name": "I802",
      "description": "Phlebitis and thrombophlebitis of other deep vessels of lower extremities"
    },
    {
      "name": "I803",
      "description": "Phlebitis and thrombophlebitis of lower extremities,unspecified"
    },
    {
      "name": "I808",
      "description": "Phlebitis and thrombophlebitis of other sites"
    },
    {
      "name": "I809",
      "description": "Phlebitis and thrombophlebitis of unspecified site"
    },
    {
      "name": "I81",
      "description": "Portal vein thrombosis"
    },
    {
      "name": "I820",
      "description": "Budd-Chiari syndrome"
    },
    {
      "name": "I821",
      "description": "Thrombophlebitis migrans"
    },
    {
      "name": "I822",
      "description": "Embolism and thrombosis of vena cava"
    },
    {
      "name": "I823",
      "description": "Embolism and thrombosis of renal vein"
    },
    {
      "name": "I828",
      "description": "Embolism and thrombosis of other specified veins"
    },
    {
      "name": "I829",
      "description": "Embolism and thrombosis of unspecified vein"
    },
    {
      "name": "I830",
      "description": "Varicose veins of lower extremities with ulcer"
    },
    {
      "name": "I831",
      "description": "Varicose veins of lower extremities with inflammation"
    },
    {
      "name": "I832",
      "description": "Varicose veins of lower extremities with both ulcer and inflammation"
    },
    {
      "name": "I839",
      "description": "Varicose veins of lower extremities without ulcer or inflammation"
    },
    {
      "name": "I850",
      "description": "Oesophageal varices with bleeding"
    },
    {
      "name": "I859",
      "description": "Oesophageal varices without bleeding"
    },
    {
      "name": "I860",
      "description": "Sublingual varices"
    },
    {
      "name": "I861",
      "description": "Scrotal varices"
    },
    {
      "name": "I862",
      "description": "Pelvic varices"
    },
    {
      "name": "I863",
      "description": "Vulval varices"
    },
    {
      "name": "I8640",
      "description": "Gastric varices with bleeding"
    },
    {
      "name": "I8649",
      "description": "Gastric varices without bleeding"
    },
    {
      "name": "I868",
      "description": "Varicose veins of other specified sites"
    },
    {
      "name": "I870",
      "description": "Postthrombotic syndrome"
    },
    {
      "name": "I871",
      "description": "Compression of vein"
    },
    {
      "name": "I872",
      "description": "Venous insufficiency (chronic)(peripheral)"
    },
    {
      "name": "I878",
      "description": "Other specified disorders of veins"
    },
    {
      "name": "I879",
      "description": "Disorder of vein, unspecified"
    },
    {
      "name": "I880",
      "description": "Nonspecific mesenteric  lymphadenitis"
    },
    {
      "name": "I881",
      "description": "Chronic  lymphadenitis, except mesenteric"
    },
    {
      "name": "I888",
      "description": "Other nonspecific  lymphadenitis"
    },
    {
      "name": "I889",
      "description": "Nonspecific  lymphadenitis , unspecified"
    },
    {
      "name": "I890",
      "description": "Lymphoedema, not elsewhere classified"
    },
    {
      "name": "I891",
      "description": "Lymphangitis"
    },
    {
      "name": "I898",
      "description": "Other specified noninfective disorders of lymphatic vessels and lymph nodes"
    },
    {
      "name": "I899",
      "description": "Noninfective disorder of lymphatic vessels and lymph nodes, unspecified"
    },
    {
      "name": "I950",
      "description": "Idiopathic hypotension"
    },
    {
      "name": "I951",
      "description": "Orthostatic hypotension"
    },
    {
      "name": "I952",
      "description": "Hypotension due to drugs"
    },
    {
      "name": "I958",
      "description": "Other hypotension"
    },
    {
      "name": "I959",
      "description": "Hypotension, unspecified"
    },
    {
      "name": "I970",
      "description": "Postcardiotomy syndrome"
    },
    {
      "name": "I971",
      "description": "Other functional disturbances following cardiac surgery"
    },
    {
      "name": "I972",
      "description": "Postmastectomy lymphoedema syndrome"
    },
    {
      "name": "I978",
      "description": "Other postprocedural disorders of circulatory system, not elsewhere classified"
    },
    {
      "name": "I979",
      "description": "Postprocedural disorder of circulatory system, unspecified"
    },
    {
      "name": "I980",
      "description": "Cardiovascular syphilis"
    },
    {
      "name": "I981",
      "description": "Cardiovascular disorders in other infectious and parasitic diseases  classified elsewhere"
    },
    {
      "name": "I982",
      "description": "Oesophageal varices without bleeding in  diseases classified elsewhere"
    },
    {
      "name": "I983",
      "description": "Oesophageal varices with bleeding in diseases classified elsewhere"
    },
    {
      "name": "I988",
      "description": "Other specified disorders of circulatory system in diseases  classified elsewhere"
    },
    {
      "name": "I99",
      "description": "Other and unspecified disorders circulatory system"
    },
    {
      "name": "J00",
      "description": "Acute nasopharyngitis [common cold]"
    },
    {
      "name": "J010",
      "description": "Acute maxillary sinusitis"
    },
    {
      "name": "J011",
      "description": "Acute frontal sinusitis"
    },
    {
      "name": "J012",
      "description": "Acute ethmoidal sinusitis"
    },
    {
      "name": "J013",
      "description": "Acute sphenoidal sinusitis"
    },
    {
      "name": "J014",
      "description": "Acute pansinusitis"
    },
    {
      "name": "J018",
      "description": "Other acute sinusitis"
    },
    {
      "name": "J019",
      "description": "Acute sinusitis, unspecified"
    },
    {
      "name": "J020",
      "description": "Streptococcal pharyngitis"
    },
    {
      "name": "J028",
      "description": "Acute pharyngitis due to other specified organisms"
    },
    {
      "name": "J029",
      "description": "Acute pharyngitis, unspecified"
    },
    {
      "name": "J030",
      "description": "Streptococcal tonsillitis"
    },
    {
      "name": "J038",
      "description": "Acute tonsillitis due to other specified organisms"
    },
    {
      "name": "J039",
      "description": "Acute tonsillitis, unspecified"
    },
    {
      "name": "J040",
      "description": "Acute laryngitis"
    },
    {
      "name": "J041",
      "description": "Acute tracheitis"
    },
    {
      "name": "J042",
      "description": "Acute laryngotracheitis"
    },
    {
      "name": "J050",
      "description": "Acute obstructive laryngitis [croup]"
    },
    {
      "name": "J051",
      "description": "Acute epiglottitis"
    },
    {
      "name": "J060",
      "description": "Acute laryngopharyngitis"
    },
    {
      "name": "J068",
      "description": "Other acute upper resiratory infection of multiple sites"
    },
    {
      "name": "J069",
      "description": "Acute upper respiratory infection, unspecified"
    },
    {
      "name": "J09",
      "description": "Influenza due to identified zoonotic or pandemic influenza virus"
    },
    {
      "name": "J100",
      "description": "Influenza with pneumonia, seasonal influenza virus identified"
    },
    {
      "name": "J101",
      "description": "Influenza with other respiratory manifestations, seasonal  influenza virus identified"
    },
    {
      "name": "J108",
      "description": "Influenza with other manifestations, seasonal  influenza virus identified"
    },
    {
      "name": "J110",
      "description": "Influenza with pneumonia, virus not  identified"
    },
    {
      "name": "J111",
      "description": "Influenza with other respiratory manifestations,virus not identified"
    },
    {
      "name": "J118",
      "description": "Influenza with other manifestations,  virus not  identified"
    },
    {
      "name": "J120",
      "description": "Adenoviral pneumonia"
    },
    {
      "name": "J121",
      "description": "Respiratory syncytial virus pneumonia"
    },
    {
      "name": "J122",
      "description": "Parainfluenza virus pneumonia"
    },
    {
      "name": "J123",
      "description": "Human metapneumovirus pneumonia"
    },
    {
      "name": "J128",
      "description": "Other viral pneumonia"
    },
    {
      "name": "J129",
      "description": "Viral pneumonia, unspecified"
    },
    {
      "name": "J13",
      "description": "Pneumonia due to Streptococcus pneumoniae"
    },
    {
      "name": "J14",
      "description": "Pneumonia due to Haemophilus influenzae"
    },
    {
      "name": "J150",
      "description": "Pneumonia due to Klebsiella pneumoniae"
    },
    {
      "name": "J151",
      "description": "Pneumonia due to Pseudomonas"
    },
    {
      "name": "J152",
      "description": "Pneumonia due to staphylococcus"
    },
    {
      "name": "J153",
      "description": "Pneumonia due to streptococcus, group B"
    },
    {
      "name": "J154",
      "description": "Pneumonia due to other streptococci"
    },
    {
      "name": "J155",
      "description": "Pneumonia due to Escherichia coli"
    },
    {
      "name": "J156",
      "description": "Pneumonia due to other aerobic Gram-negative bacteria"
    },
    {
      "name": "J157",
      "description": "Pneumonia due to Mycoplasma pneumoniae"
    },
    {
      "name": "J1581",
      "description": "Pneumonia due to Rhodococcus equi"
    },
    {
      "name": "J1588",
      "description": "Other bacterial pneumonia"
    },
    {
      "name": "J159",
      "description": "Bacterial penumonia, unspecified"
    },
    {
      "name": "J160",
      "description": "Chlamydial pneumonia"
    },
    {
      "name": "J168",
      "description": "Pneumonia due to other specified infectious organisms"
    },
    {
      "name": "J170",
      "description": "Pneumonia in bacterial diseases classified elsewhere"
    },
    {
      "name": "J171",
      "description": "Pneumonia in viral diseases classified elsewhere"
    },
    {
      "name": "J172",
      "description": "Pneumonia in mycoses"
    },
    {
      "name": "J173",
      "description": "Pneumonia in parasitic diseases"
    },
    {
      "name": "J178",
      "description": "Pneumonia in other diseases classified elsewhere"
    },
    {
      "name": "J180",
      "description": "Bronchopneumonia, unspecified"
    },
    {
      "name": "J181",
      "description": "Lobar pneumonia, unspecified"
    },
    {
      "name": "J182",
      "description": "Hypostatic pneumonia, unspecified"
    },
    {
      "name": "J188",
      "description": "Other pneumonia, organism unspecified"
    },
    {
      "name": "J189",
      "description": "Pneumonia, unspecified"
    },
    {
      "name": "J200",
      "description": "Acute bronchitis due to Mycoplasma penumoniae"
    },
    {
      "name": "J201",
      "description": "Acute bronchitis due to Haemophilus influenzae"
    },
    {
      "name": "J202",
      "description": "Acute bronchitis due to streptococcus"
    },
    {
      "name": "J203",
      "description": "Acute bronchitis due to coxsackievirus"
    },
    {
      "name": "J204",
      "description": "Acute bronchitis due to parainfluenza virus"
    },
    {
      "name": "J205",
      "description": "Acute bronchitis due to respiratory syncytial virus"
    },
    {
      "name": "J206",
      "description": "Acute bronchitis due to rhinovirus"
    },
    {
      "name": "J207",
      "description": "Acute bronchitis due to echovirus"
    },
    {
      "name": "J208",
      "description": "Acute bronchitis due to other specified organisms"
    },
    {
      "name": "J209",
      "description": "Acute bronchitis, unspecified"
    },
    {
      "name": "J210",
      "description": "Acute bronchiolitis due to respiratory syncytial virus"
    },
    {
      "name": "J211",
      "description": "Acute bronchiolitis due to human metapneumovirus"
    },
    {
      "name": "J218",
      "description": "Acute bronchiolitis due to other specified organisms"
    },
    {
      "name": "J219",
      "description": "Acute bronchiolitis, unsepcified"
    },
    {
      "name": "J22",
      "description": "Unspecified acute lower respiratory infection"
    },
    {
      "name": "J300",
      "description": "Vasomotor rhinitis"
    },
    {
      "name": "J301",
      "description": "Allergic rhinitis due to pollen"
    },
    {
      "name": "J302",
      "description": "Other seasonal allergic rhinitis"
    },
    {
      "name": "J303",
      "description": "Other allergic rhinitis"
    },
    {
      "name": "J304",
      "description": "Allergic rhinitis, unspecified"
    },
    {
      "name": "J310",
      "description": "Chronic rhinitis"
    },
    {
      "name": "J311",
      "description": "Chronic nasopharyngitis"
    },
    {
      "name": "J312",
      "description": "Chronic pharyngitis"
    },
    {
      "name": "J320",
      "description": "Chronic maxillary sinusitis"
    },
    {
      "name": "J321",
      "description": "Chronic frontal sinusitis"
    },
    {
      "name": "J322",
      "description": "Chronic ethmoidal sinusitis"
    },
    {
      "name": "J323",
      "description": "Chronic sphenoidal sinusitis"
    },
    {
      "name": "J324",
      "description": "Chronic pansinusitis"
    },
    {
      "name": "J328",
      "description": "Other chronic sinusitis"
    },
    {
      "name": "J329",
      "description": "Chronic sinusitis, unspecified"
    },
    {
      "name": "J330",
      "description": "Polyp of nasal cavity"
    },
    {
      "name": "J331",
      "description": "Polypoid sinus degeneration"
    },
    {
      "name": "J338",
      "description": "Other polyp of sinus"
    },
    {
      "name": "J339",
      "description": "Nasal polyp, unspecified"
    },
    {
      "name": "J340",
      "description": "Abscess, furuncle and carbuncle of nose"
    },
    {
      "name": "J341",
      "description": "Cyst and mucocele of nose and nasal sinus"
    },
    {
      "name": "J342",
      "description": "Deviated nasal septum"
    },
    {
      "name": "J343",
      "description": "Hypertrophy fo nasal turbinates"
    },
    {
      "name": "J348",
      "description": "Other specified disorders of nose and nasal sinuses"
    },
    {
      "name": "J350",
      "description": "Chronic tonsillitis"
    },
    {
      "name": "J351",
      "description": "Hypertrophy of tonsils"
    },
    {
      "name": "J352",
      "description": "Hypertrophy of adenoids"
    },
    {
      "name": "J353",
      "description": "Hypertrophy of tonsils with hypertrophy of adenoids"
    },
    {
      "name": "J358",
      "description": "Other chronic diseases of tonsils and adenoids"
    },
    {
      "name": "J359",
      "description": "Chronic disease of tonsils and adenoids, unspecified"
    },
    {
      "name": "J36",
      "description": "Peritonsillar abscess"
    },
    {
      "name": "J370",
      "description": "Chronic laryngitis"
    },
    {
      "name": "J371",
      "description": "Chronic larygotracheitis"
    },
    {
      "name": "J380",
      "description": "Paralysis of vocal cords and larynx"
    },
    {
      "name": "J381",
      "description": "Polyp of vocal cord and larynx"
    },
    {
      "name": "J382",
      "description": "Nodules of vocal cords"
    },
    {
      "name": "J383",
      "description": "Other diseases of vocal cords"
    },
    {
      "name": "J384",
      "description": "Oedema of larynx"
    },
    {
      "name": "J385",
      "description": "Laryngeal spasm"
    },
    {
      "name": "J386",
      "description": "Stenosis of larynx"
    },
    {
      "name": "J387",
      "description": "Other diseases of larynx"
    },
    {
      "name": "J390",
      "description": "Retropharyngeal and parapharyngeal abscess"
    },
    {
      "name": "J391",
      "description": "Other abscess of pharynx"
    },
    {
      "name": "J392",
      "description": "Other diseases of pharynx"
    },
    {
      "name": "J393",
      "description": "Upper respiratory tract hypersensitivity reaction, site unspecified"
    },
    {
      "name": "J394",
      "description": "Upper airway resistance syndrome"
    },
    {
      "name": "J398",
      "description": "Other specified diseases of upper respiratory tract"
    },
    {
      "name": "J399",
      "description": "Disease of upper respiratory tract, unspecified"
    },
    {
      "name": "J40",
      "description": "Bronchitis, not specified as acute or chronic"
    },
    {
      "name": "J410",
      "description": "Simple chronic bronchitis"
    },
    {
      "name": "J411",
      "description": "Mucopurulent chronic bronchitis"
    },
    {
      "name": "J418",
      "description": "Mixed simple and mucopurulent chronic bronchitis"
    },
    {
      "name": "J42",
      "description": "Unspecified chronic bronchitis"
    },
    {
      "name": "J430",
      "description": "MacLeod's syndrome"
    },
    {
      "name": "J431",
      "description": "Panlobular emphysema"
    },
    {
      "name": "J432",
      "description": "Centrilobular emphysema"
    },
    {
      "name": "J438",
      "description": "Other emphysema"
    },
    {
      "name": "J439",
      "description": "Emphysema, unspecified"
    },
    {
      "name": "J440",
      "description": "Chronic obstructive pulmonary disease with acute lower respiratory infection"
    },
    {
      "name": "J441",
      "description": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified"
    },
    {
      "name": "J448",
      "description": "Other specified chronic obstructive pulmonary disease"
    },
    {
      "name": "J449",
      "description": "Chronic obstructive pulmonary disease, unspecified"
    },
    {
      "name": "J450",
      "description": "Predominantly allergic asthma"
    },
    {
      "name": "J451",
      "description": "Nonallergic asthma"
    },
    {
      "name": "J452",
      "description": "Bronchial hyperresponsiveness"
    },
    {
      "name": "J458",
      "description": "Mixed asthma"
    },
    {
      "name": "J459",
      "description": "Asthma, unspecified"
    },
    {
      "name": "J46",
      "description": "Status asthmaticus"
    },
    {
      "name": "J47",
      "description": "Bronchiectasis"
    },
    {
      "name": "J60",
      "description": "Coalworker's pneumoconiosis"
    },
    {
      "name": "J61",
      "description": "Pneumoconiosis due to asbestos and other mineral fibres"
    },
    {
      "name": "J620",
      "description": "Pneumoconiosis due to talc dust"
    },
    {
      "name": "J628",
      "description": "Pneumoconiosis due to other dust containing silica"
    },
    {
      "name": "J630",
      "description": "Aluminosis (of lung)"
    },
    {
      "name": "J631",
      "description": "Bauxite fibrosis (of lung)"
    },
    {
      "name": "J632",
      "description": "Berylliosis"
    },
    {
      "name": "J633",
      "description": "Graphite fibrosis (of lung)"
    },
    {
      "name": "J634",
      "description": "Siderosis"
    },
    {
      "name": "J635",
      "description": "Stannosis"
    },
    {
      "name": "J638",
      "description": "Pneumoconiosis due to other specified inorganic dusts"
    },
    {
      "name": "J64",
      "description": "Unspecified pneumoconiosis"
    },
    {
      "name": "J65",
      "description": "Pneumoconiosis associated with tuberculosis"
    },
    {
      "name": "J660",
      "description": "Byssinosis"
    },
    {
      "name": "J661",
      "description": "Flax-dresser's disease"
    },
    {
      "name": "J662",
      "description": "Cannabinosis"
    },
    {
      "name": "J668",
      "description": "Airway disease due to other specific organic dusts"
    },
    {
      "name": "J670",
      "description": "Farmer's lung"
    },
    {
      "name": "J671",
      "description": "Bagassosis"
    },
    {
      "name": "J672",
      "description": "Bird fancier's lung"
    },
    {
      "name": "J673",
      "description": "Suberosis"
    },
    {
      "name": "J674",
      "description": "Maltworker's lung"
    },
    {
      "name": "J675",
      "description": "Mushroom-worker's lung"
    },
    {
      "name": "J676",
      "description": "Maple-bark-stripper's lung"
    },
    {
      "name": "J677",
      "description": "Air-conditioner and humidifier lung"
    },
    {
      "name": "J678",
      "description": "Hypersensitivity pneumonitis due to other organic dusts"
    },
    {
      "name": "J679",
      "description": "Hypersensitivity pneumonitis due to unspecified organic dust"
    },
    {
      "name": "J680",
      "description": "Bronchitis and pneumonitis due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J681",
      "description": "Pulmonary oedema due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J682",
      "description": "Upper respiratory inflammation due to chemicals, gases, fumes and vapours not elsewhere classified"
    },
    {
      "name": "J683",
      "description": "Other acute and subacute respiratory conditions due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J684",
      "description": "Chronic respiratory conditions due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J688",
      "description": "Other respiratory conditions due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J689",
      "description": "Unspecified respiratory condition due to chemicals, gases, fumes and vapours"
    },
    {
      "name": "J690",
      "description": "Pneumonitis due to food and vomit"
    },
    {
      "name": "J691",
      "description": "Pneumonitis due to oils and essences"
    },
    {
      "name": "J698",
      "description": "Pneumonitis due to other solids and liquids"
    },
    {
      "name": "J700",
      "description": "Acute pulmonary manifestations due to radiation"
    },
    {
      "name": "J701",
      "description": "Chronic and other pulmonary manifestations due to radiation"
    },
    {
      "name": "J702",
      "description": "Acute drug-induced interstitial lung disorders"
    },
    {
      "name": "J703",
      "description": "Chronic drug-induced interstitial lung disorders"
    },
    {
      "name": "J704",
      "description": "Drug-induced interstitial lung disorders, unspecified"
    },
    {
      "name": "J708",
      "description": "Respiratory conditions due to other specified external  agents"
    },
    {
      "name": "J709",
      "description": "Respiratory conditions due to unspecified external  agents"
    },
    {
      "name": "J80",
      "description": "Adult respiratory distress syndrome"
    },
    {
      "name": "J81",
      "description": "Pulmonary oedema"
    },
    {
      "name": "J82",
      "description": "Pulmonary eosinophilia, not elsewhere classified"
    },
    {
      "name": "J840",
      "description": "Alveolar and parietoalvepolar conditions"
    },
    {
      "name": "J841",
      "description": "Other interstitial pulmonary diseases with fibrosis"
    },
    {
      "name": "J848",
      "description": "Other specified interstitial pulmonary diseases"
    },
    {
      "name": "J849",
      "description": "Interstitial pulmonary diseases, unspecified"
    },
    {
      "name": "J850",
      "description": "Gangrene and necrosis of lung"
    },
    {
      "name": "J851",
      "description": "Abscess of lung with pneumonia"
    },
    {
      "name": "J852",
      "description": "Abscess of lung without pneumonia"
    },
    {
      "name": "J853",
      "description": "Abscess of mediastinum"
    },
    {
      "name": "J860",
      "description": "Pyothorax with fistula"
    },
    {
      "name": "J869",
      "description": "Pyothorax without fistula"
    },
    {
      "name": "J90",
      "description": "Pleural effusion, not elsewhere classified"
    },
    {
      "name": "J91",
      "description": "Pleural effusion in conditions classified elsewhere"
    },
    {
      "name": "J920",
      "description": "Pleural plaque with presence of asbestos"
    },
    {
      "name": "J929",
      "description": "Pleural plaque without asbestos"
    },
    {
      "name": "J930",
      "description": "Spontaneous tension pneumothorax"
    },
    {
      "name": "J931",
      "description": "Other spontaneous pneumothorax"
    },
    {
      "name": "J938",
      "description": "Other pneumothorax"
    },
    {
      "name": "J939",
      "description": "Pneumothorax, unspecified"
    },
    {
      "name": "J940",
      "description": "Chylous effusion"
    },
    {
      "name": "J941",
      "description": "Fibrothorax"
    },
    {
      "name": "J942",
      "description": "Haemothorax"
    },
    {
      "name": "J948",
      "description": "Other specified pleural conditions"
    },
    {
      "name": "J949",
      "description": "Pleural condition, unspecified"
    },
    {
      "name": "J950",
      "description": "Tracheostomy malfunction"
    },
    {
      "name": "J951",
      "description": "Acute pulmonary insufficiency following thoracic surgery"
    },
    {
      "name": "J952",
      "description": "Acute pulmonary insufficiency following nonthoracic surgery"
    },
    {
      "name": "J953",
      "description": "Chronic  pulmonary insufficiency following surgery"
    },
    {
      "name": "J954",
      "description": "Mendelson's syndrome"
    },
    {
      "name": "J955",
      "description": "Postprocedural subglottic stenosis"
    },
    {
      "name": "J956",
      "description": "Postprocedural chylothorax"
    },
    {
      "name": "J958",
      "description": "Other postprocedural respiratory disorders"
    },
    {
      "name": "J959",
      "description": "Postprocedural respiratory disorder, unspecified"
    },
    {
      "name": "J960",
      "description": "Acute respiratory failure"
    },
    {
      "name": "J961",
      "description": "Chronic respiratory failure"
    },
    {
      "name": "J969",
      "description": "Respiratory failure, unspecified"
    },
    {
      "name": "J980",
      "description": "Diseases of bronchus, not elsewhere classified"
    },
    {
      "name": "J981",
      "description": "Pulmonary collapse"
    },
    {
      "name": "J982",
      "description": "Interstitial emphysema"
    },
    {
      "name": "J983",
      "description": "Compensatory emphysema"
    },
    {
      "name": "J984",
      "description": "Other disorders of lung"
    },
    {
      "name": "J985",
      "description": "Diseases of mediastinum, not elsewhere classified"
    },
    {
      "name": "J986",
      "description": "Disorders of diaphragm"
    },
    {
      "name": "J988",
      "description": "Other specified respiratory disorders"
    },
    {
      "name": "J989",
      "description": "Respiratory disorder, unspecified"
    },
    {
      "name": "J990",
      "description": "Rheumatoid lung disease (M05.1)"
    },
    {
      "name": "J991",
      "description": "Respiratory disorders in other diffuse connective tissue disorders"
    },
    {
      "name": "J998",
      "description": "Respiratory disorders in other diseases classified elsewhere"
    },
    {
      "name": "K0000",
      "description": "Partial anodontia [hypodontia] [oligodontia]"
    },
    {
      "name": "K0001",
      "description": "Total anodontia"
    },
    {
      "name": "K0009",
      "description": "Anodontia, unspecified"
    },
    {
      "name": "K0010",
      "description": "Incisor and canine regions"
    },
    {
      "name": "K0011",
      "description": "Premolar region"
    },
    {
      "name": "K0012",
      "description": "Molar region"
    },
    {
      "name": "K0019",
      "description": "Supernumerary teeth, unspecified"
    },
    {
      "name": "K0020",
      "description": "Macrodontia"
    },
    {
      "name": "K0021",
      "description": "Microdontia"
    },
    {
      "name": "K0022",
      "description": "Concrescence"
    },
    {
      "name": "K0023",
      "description": "Fusion and gemination"
    },
    {
      "name": "K0024",
      "description": "Dens evaginatus, [occlusal tuberculum]"
    },
    {
      "name": "K0025",
      "description": "Dens invaginatus [dens in dente] [dilated odonoma] and incisor  anomalies"
    },
    {
      "name": "K0026",
      "description": "Premolarization"
    },
    {
      "name": "K0027",
      "description": "Abnormal tubercula and enamel pearls [enameloma]"
    },
    {
      "name": "K0028",
      "description": "Taurodontism"
    },
    {
      "name": "K0029",
      "description": "Other and unspecified abnormalities of size and form of teeth"
    },
    {
      "name": "K0030",
      "description": "Endemic (fluoride) mottling of enamel [dental fluorosis]"
    },
    {
      "name": "K0031",
      "description": "Non-endemic mottling of enamel [non-fluoride enamel opacities]"
    },
    {
      "name": "K0039",
      "description": "Mottled teeth, unspecified"
    },
    {
      "name": "K0040",
      "description": "Enamel hypoplasia"
    },
    {
      "name": "K0041",
      "description": "Prenatal enamel hypoplasia"
    },
    {
      "name": "K0042",
      "description": "Neonatal enamel hypoplasia"
    },
    {
      "name": "K0043",
      "description": "Aplasia and hypoplasia of cementum"
    },
    {
      "name": "K0044",
      "description": "Dilaceration"
    },
    {
      "name": "K0045",
      "description": "Odontodysplasia [regional odontodysplasia]"
    },
    {
      "name": "K0046",
      "description": "Turner’s tooth"
    },
    {
      "name": "K0048",
      "description": "Other specified disturbances in tooth formation"
    },
    {
      "name": "K0049",
      "description": "Disturbances in tooth formation, unspecified"
    },
    {
      "name": "K0050",
      "description": "Amelogenesis imperfecta"
    },
    {
      "name": "K0051",
      "description": "Dentinogenesis imperfecta"
    },
    {
      "name": "K0052",
      "description": "Odontogenesis imperfecta"
    },
    {
      "name": "K0058",
      "description": "Other hereditary disturbances in tooth structure"
    },
    {
      "name": "K0059",
      "description": "Hereditary disturbances in tooth structure, unspecified"
    },
    {
      "name": "K0060",
      "description": "Natal teeth"
    },
    {
      "name": "K0061",
      "description": "Neonatal teeth"
    },
    {
      "name": "K0062",
      "description": "Premature eruption[dentia praecox]"
    },
    {
      "name": "K0063",
      "description": "Retained [persistent] primary [deciduous] teeth"
    },
    {
      "name": "K0064",
      "description": "Late eruption"
    },
    {
      "name": "K0065",
      "description": "Premature shedding of primary [deciduous] teeth"
    },
    {
      "name": "K0068",
      "description": "Other specified disturbances in tooth eruption"
    },
    {
      "name": "K0069",
      "description": "Disturbances in tooth eruption, unspecified"
    },
    {
      "name": "K007",
      "description": "Teething syndrome"
    },
    {
      "name": "K0080",
      "description": "Colour changes during tooth formation due to blood type  incompatibility"
    },
    {
      "name": "K0081",
      "description": "Colour changes during tooth formation due to malformation of biliary system"
    },
    {
      "name": "K0082",
      "description": "Colour changes during tooth formation due to porphyria"
    },
    {
      "name": "K0083",
      "description": "Colour changes during tooth formation due to tetracyclines"
    },
    {
      "name": "K0088",
      "description": "Other specified disorders of tooth development"
    },
    {
      "name": "K009",
      "description": "Disorder of tooth development, unsepcified"
    },
    {
      "name": "K010",
      "description": "Embeded teeth"
    },
    {
      "name": "K0110",
      "description": "Impacted tooth maxillary incisor"
    },
    {
      "name": "K0111",
      "description": "Impacted tooth mandibular incisor"
    },
    {
      "name": "K0112",
      "description": "Impacted tooth maxillary canine"
    },
    {
      "name": "K0113",
      "description": "Impacted tooth mandibular canine"
    },
    {
      "name": "K0114",
      "description": "Impacted tooth maxillary premolar"
    },
    {
      "name": "K0115",
      "description": "Impacted tooth mandibular premolar"
    },
    {
      "name": "K0116",
      "description": "Impacted tooth maxillary molar"
    },
    {
      "name": "K0117",
      "description": "Impacted tooth mandibular molar"
    },
    {
      "name": "K0118",
      "description": "Impacted tooth, supernumerary tooth"
    },
    {
      "name": "K0119",
      "description": "Impacted tooth, unspecified"
    },
    {
      "name": "K020",
      "description": "Caries limited to enamel"
    },
    {
      "name": "K021",
      "description": "Caries of dentine"
    },
    {
      "name": "K022",
      "description": "Caries of cementum"
    },
    {
      "name": "K023",
      "description": "Arrested dental caries"
    },
    {
      "name": "K024",
      "description": "Odontoclasia"
    },
    {
      "name": "K025",
      "description": "Caries with pulp exposure"
    },
    {
      "name": "K028",
      "description": "Other dental caries"
    },
    {
      "name": "K029",
      "description": "Dental caries, unspecified"
    },
    {
      "name": "K0300",
      "description": "Occlusal"
    },
    {
      "name": "K0301",
      "description": "Approximal"
    },
    {
      "name": "K0308",
      "description": "Other specified attrition of teeth"
    },
    {
      "name": "K0309",
      "description": "Attrition of teeth, unspecified"
    },
    {
      "name": "K0310",
      "description": "Dentrifice"
    },
    {
      "name": "K0311",
      "description": "Habitual abrasion of teeth"
    },
    {
      "name": "K0312",
      "description": "Occupational abrasion of teeth"
    },
    {
      "name": "K0313",
      "description": "Traditioanal abrasion of teeth"
    },
    {
      "name": "K0318",
      "description": "Other specified abrasion of teeth"
    },
    {
      "name": "K0319",
      "description": "Abrasion of teeth, unspecified"
    },
    {
      "name": "K0320",
      "description": "Occupational erosion of teeth"
    },
    {
      "name": "K0321",
      "description": "Eorsion of teeth due to persistent regurgitating or vomiting"
    },
    {
      "name": "K0322",
      "description": "Eorsion of teeth due to diet"
    },
    {
      "name": "K0323",
      "description": "Eorsion of teeth due to drugs and medicaments"
    },
    {
      "name": "K0324",
      "description": "Idiopathic erosion of teeth"
    },
    {
      "name": "K0328",
      "description": "Other specified erosion of teeth"
    },
    {
      "name": "K0329",
      "description": "Erosion of teeth, unspecified"
    },
    {
      "name": "K0330",
      "description": "External resorption of teeth"
    },
    {
      "name": "K0331",
      "description": "Internal [internal granuloma] [pink spot] resorption of teeth"
    },
    {
      "name": "K0339",
      "description": "Pathological  resorption of teeth, unspecified"
    },
    {
      "name": "K034",
      "description": "Hypercementosis"
    },
    {
      "name": "K035",
      "description": "Ankylosis of teeth"
    },
    {
      "name": "K0360",
      "description": "Pigmented film"
    },
    {
      "name": "K0361",
      "description": "Deposits on teeth due to tobacco habit"
    },
    {
      "name": "K0362",
      "description": "Deposits on teeth due to betel-chewing habit"
    },
    {
      "name": "K0363",
      "description": "Other gross soft deposits"
    },
    {
      "name": "K0364",
      "description": "Supragingival calculus"
    },
    {
      "name": "K0365",
      "description": "Subgingival caluculus"
    },
    {
      "name": "K0366",
      "description": "Dental plaque"
    },
    {
      "name": "K0368",
      "description": "Other specified deposits on teeth"
    },
    {
      "name": "K0369",
      "description": "Deposit [accretions] on teeth, unspecified"
    },
    {
      "name": "K0370",
      "description": "Posteruptive colour changes of dental hard tissues due to metals and metallic compounds"
    },
    {
      "name": "K0371",
      "description": "Posteruptive colour changes of dental hard tissues due to pulpal bleeding"
    },
    {
      "name": "K0372",
      "description": "Posteruptive colour changes of dental hard tissues due to chewing habit"
    },
    {
      "name": "K0378",
      "description": "Other specified posteruptive colour changes of dental hard tissues "
    },
    {
      "name": "K0379",
      "description": "Posteruptive colour changes of dental hard tissues, unspecified"
    },
    {
      "name": "K0380",
      "description": "Sensitive dentine"
    },
    {
      "name": "K0381",
      "description": "Changes of enamel due to irradiation"
    },
    {
      "name": "K0388",
      "description": "Other specified diseases of hard tissues of teeth"
    },
    {
      "name": "K039",
      "description": "Disease of hard tissues of teeth, unspecified"
    },
    {
      "name": "K0400",
      "description": "Initial pulpitis [hyperraemia] "
    },
    {
      "name": "K0401",
      "description": "Acute pulpitis"
    },
    {
      "name": "K0402",
      "description": "Suppurative pulpitis [pulpal abscess]"
    },
    {
      "name": "K0403",
      "description": "Chronic pulpitis "
    },
    {
      "name": "K0404",
      "description": "Chronic pulpitis, ulcerative"
    },
    {
      "name": "K0405",
      "description": "Chronic pulpitis, hyperplastic [pulpal polyp]"
    },
    {
      "name": "K0408",
      "description": "Other specified pulpitis"
    },
    {
      "name": "K0409",
      "description": "Pulpitis, unspecified"
    },
    {
      "name": "K041",
      "description": "Necrosis of pulp"
    },
    {
      "name": "K042",
      "description": "Pulp degeneration"
    },
    {
      "name": "K043",
      "description": "Abnormal hard tissue formation in pulp"
    },
    {
      "name": "K044",
      "description": "Acute apical periodontitis of pulpal origin"
    },
    {
      "name": "K045",
      "description": "Chronic apical periodontitis"
    },
    {
      "name": "K0460",
      "description": "Periapical abscess with sinus to maxillary antrum"
    },
    {
      "name": "K0461",
      "description": "Periapical abscess with sinus to nasal cavity"
    },
    {
      "name": "K0462",
      "description": "Periapical abscess with sinus to oral cavity"
    },
    {
      "name": "K0463",
      "description": "Periapical abscess with sinus to skin"
    },
    {
      "name": "K0469",
      "description": "Periapical abscess with sinus, unspecified"
    },
    {
      "name": "K047",
      "description": "Periapical abscess without sinus"
    },
    {
      "name": "K0480",
      "description": "Apical and lateral redicular cyst"
    },
    {
      "name": "K0481",
      "description": "Residual redicular cyst"
    },
    {
      "name": "K0482",
      "description": "Inflammatory paradental redicular cyst"
    },
    {
      "name": "K0489",
      "description": "Radicular cyst, unspecified"
    },
    {
      "name": "K049",
      "description": "Other and unspecified diseases of pulp and periapical tissues"
    },
    {
      "name": "K0500",
      "description": "Acute streoptococcal gingivostomatitis"
    },
    {
      "name": "K0508",
      "description": "Other specified acute gingivitis"
    },
    {
      "name": "K0509",
      "description": "Acute gingivitis, unspecified"
    },
    {
      "name": "K0510",
      "description": "Simple marginal"
    },
    {
      "name": "K0511",
      "description": "Hyperplastic"
    },
    {
      "name": "K0512",
      "description": "Ulcerative"
    },
    {
      "name": "K0513",
      "description": "Desquamative"
    },
    {
      "name": "K0518",
      "description": "Other specified chronic gingivitis"
    },
    {
      "name": "K0519",
      "description": "Chronic gingivitis, unspecified"
    },
    {
      "name": "K0520",
      "description": "Periodontal abscess [parodontal abscess] of gingival origin without sinus"
    },
    {
      "name": "K0521",
      "description": "Periodontal abscess [parodontal abscess] of gingival origin with sinus"
    },
    {
      "name": "K0522",
      "description": "Acute pericoronitis"
    },
    {
      "name": "K0528",
      "description": "Other specified acute periodontitis"
    },
    {
      "name": "K0529",
      "description": "Acute periodontitis, unspecified"
    },
    {
      "name": "K0530",
      "description": "Simplex"
    },
    {
      "name": "K0531",
      "description": "Complex"
    },
    {
      "name": "K0532",
      "description": "Chronic pericoronitis"
    },
    {
      "name": "K0533",
      "description": "Thickened follicle"
    },
    {
      "name": "K0538",
      "description": "Other specified chronic periodontitis"
    },
    {
      "name": "K0539",
      "description": "Chronic periodontitis,unspecified"
    },
    {
      "name": "K054",
      "description": "Periodontosis"
    },
    {
      "name": "K055",
      "description": "Other periodontal diseases"
    },
    {
      "name": "K056",
      "description": "Periodontal disease, unspecified"
    },
    {
      "name": "K0600",
      "description": "Localized gingival recession"
    },
    {
      "name": "K0601",
      "description": "Ceneralized gingival recession"
    },
    {
      "name": "K0609",
      "description": "Gingival recession, unspecified"
    },
    {
      "name": "K0610",
      "description": "Gingival fibromatosis"
    },
    {
      "name": "K0618",
      "description": "Other specified gingival enlargement"
    },
    {
      "name": "K0619",
      "description": "Gingival enlargement, unspecified"
    },
    {
      "name": "K0620",
      "description": "Due to traumatic occlusion"
    },
    {
      "name": "K0621",
      "description": "Due to toothbrushing"
    },
    {
      "name": "K0622",
      "description": "Frictional [functional] keratosis"
    },
    {
      "name": "K0628",
      "description": "Other specified gingival and edentulous alveolar ridge lesions associated with trauma"
    },
    {
      "name": "K0629",
      "description": "Gingival and edentulous alveolar ridge lesions associated with trauma, unspecified"
    },
    {
      "name": "K0680",
      "description": "Gingival cyst of adult"
    },
    {
      "name": "K0681",
      "description": "Peripheral giant cell granuloma [giant cell epulis]"
    },
    {
      "name": "K0682",
      "description": "Fibrous epulis"
    },
    {
      "name": "K0683",
      "description": "Pyogenic granuloma"
    },
    {
      "name": "K0684",
      "description": "Flabby ridge"
    },
    {
      "name": "K0688",
      "description": "Other specified disorders of gingival and edentulous alveolar ridge"
    },
    {
      "name": "K069",
      "description": "Disorder of gingiva and edentulous alveolar ridge, unspecified"
    },
    {
      "name": "K0700",
      "description": "Maxillary macrognthism [maxillary hyperplasia]"
    },
    {
      "name": "K0701",
      "description": "Mandibular macrognathism [mandibular hyperplasia]"
    },
    {
      "name": "K0702",
      "description": "Macrognathism, both jaws"
    },
    {
      "name": "K0703",
      "description": "Maxillary micrognathism [maxiliary hypoplasia]"
    },
    {
      "name": "K0704",
      "description": "Mandibular micrognathism [mandibular hypoplasia]"
    },
    {
      "name": "K0705",
      "description": "Micrognathism, both jaws"
    },
    {
      "name": "K0708",
      "description": "Other specified major anomalies of jaw size"
    },
    {
      "name": "K0709",
      "description": "Anomaly of jaw size, unspecified"
    },
    {
      "name": "K0710",
      "description": "Asymmetry of jaw"
    },
    {
      "name": "K0711",
      "description": "Mandibular prognathism"
    },
    {
      "name": "K0712",
      "description": "Maxillary prognathism"
    },
    {
      "name": "K0713",
      "description": "Mandibular retrognathism"
    },
    {
      "name": "K0714",
      "description": "Maxillary retrognathism"
    },
    {
      "name": "K0718",
      "description": "Other specified anomalies of jaw-cranial base relationship"
    },
    {
      "name": "K0719",
      "description": "Anomaly of jaw-cranial base relationship, unspecified"
    },
    {
      "name": "K0720",
      "description": "Disto-occlusion"
    },
    {
      "name": "K0721",
      "description": "Mesio-occlusion"
    },
    {
      "name": "K0722",
      "description": "Excessive overjet [horizontal overbite]"
    },
    {
      "name": "K0723",
      "description": "Excessive overbite [vertical overbite]"
    },
    {
      "name": "K0724",
      "description": "Openbite"
    },
    {
      "name": "K0725",
      "description": "Crossbite (anterior, posterior)"
    },
    {
      "name": "K0726",
      "description": "Midline deviation"
    },
    {
      "name": "K0727",
      "description": "Posterior lingual occlusion of mandibular arch"
    },
    {
      "name": "K0728",
      "description": "Other specified anomalies of dental arch relationship"
    },
    {
      "name": "K0729",
      "description": "Anomaly of dental arch relationship, unspecified"
    },
    {
      "name": "K0730",
      "description": "Crowding"
    },
    {
      "name": "K0731",
      "description": "Displacement"
    },
    {
      "name": "K0732",
      "description": "Rotation"
    },
    {
      "name": "K0733",
      "description": "Spacing"
    },
    {
      "name": "K0734",
      "description": "Transposition"
    },
    {
      "name": "K0735",
      "description": "Embedded or impacted teeth in abnormal position"
    },
    {
      "name": "K0738",
      "description": "Other specified anomalies of tooth position"
    },
    {
      "name": "K0739",
      "description": "Anomaly of tooth position, unspecified"
    },
    {
      "name": "K074",
      "description": "Malocclusion, unspecified"
    },
    {
      "name": "K0750",
      "description": "Abnormal law closure"
    },
    {
      "name": "K0751",
      "description": "Maloclusion due to abnormal swallowing"
    },
    {
      "name": "K0754",
      "description": "Malocclusion due to mouth breathing"
    },
    {
      "name": "K0755",
      "description": "Malocclusion due to tongue, lip or finger habits"
    },
    {
      "name": "K0758",
      "description": "Other specified dentofacial functional abnormalities"
    },
    {
      "name": "K0759",
      "description": "Dentofacial functional abnormality, unspecified"
    },
    {
      "name": "K0760",
      "description": "Temporomandibular joint-pain-dysfunction [Costen's] syndrome "
    },
    {
      "name": "K0761",
      "description": "Clicking (snapping) jaws"
    },
    {
      "name": "K0762",
      "description": "Recurrent dislocation and subluxation of temporomandibular joint"
    },
    {
      "name": "K0763",
      "description": "Pain in temporomandibular joint, not elsewhere classified"
    },
    {
      "name": "K0764",
      "description": "Stiffness of temporomandibular joint, not elsewhere classified"
    },
    {
      "name": "K0765",
      "description": "Osteophyte of temporomandibular joint"
    },
    {
      "name": "K0768",
      "description": "Other specified temporomandibular joint disorders"
    },
    {
      "name": "K0769",
      "description": "Temporomandibular joint disorder, unspecified"
    },
    {
      "name": "K078",
      "description": "Other dentofacial anomalies"
    },
    {
      "name": "K079",
      "description": "Dentofacial anomaly, unspecified"
    },
    {
      "name": "K080",
      "description": "Exfollation of teeth due to systemic causes"
    },
    {
      "name": "K081",
      "description": "Loss of teeth due to accident, extraction or local periodontal disease"
    },
    {
      "name": "K082",
      "description": "Atrophy of edentulous alveolar ridge"
    },
    {
      "name": "K083",
      "description": "Retained dental root"
    },
    {
      "name": "K0880",
      "description": "Toothache"
    },
    {
      "name": "K0881",
      "description": "Irregular alveolar process"
    },
    {
      "name": "K0882",
      "description": "Enlargement of alveolar ridge "
    },
    {
      "name": "K0883",
      "description": "Alveolar (process) cleft"
    },
    {
      "name": "K0888",
      "description": "Other specified disorders of teeth and supporting structures"
    },
    {
      "name": "K089",
      "description": "Disorder of teeth and supporting structures, unspecified"
    },
    {
      "name": "K0900",
      "description": "Eruption cyst"
    },
    {
      "name": "K0901",
      "description": "Gingival cyst"
    },
    {
      "name": "K0902",
      "description": "Keratocyst [primordial] cyst"
    },
    {
      "name": "K0903",
      "description": "Follicular [dentigerous] cyst"
    },
    {
      "name": "K0904",
      "description": "Lateral periodontal cyst"
    },
    {
      "name": "K0908",
      "description": "Other specified developmental odontogenic cysts"
    },
    {
      "name": "K0909",
      "description": "Developmental odontogenic cyst, unspecified"
    },
    {
      "name": "K0910",
      "description": "Nasolabial [nasoalveolar] cyst"
    },
    {
      "name": "K0912",
      "description": "Cyst of nasopalatine duct [incisive canal]"
    },
    {
      "name": "K0918",
      "description": "Other specified developmental (nonodontogenic) cysts of oral origin"
    },
    {
      "name": "K0919",
      "description": "Developmental cyst of oral region, unspecified"
    },
    {
      "name": "K0920",
      "description": "Aneurysmal bone cyst"
    },
    {
      "name": "K0921",
      "description": "Solitary bone [traumatic] [haemorrhagic] cyst"
    },
    {
      "name": "K0922",
      "description": "Epithelial jaw cyst not idenifiable as odontogenic or nonodontogenic"
    },
    {
      "name": "K0928",
      "description": "Other specified cysts of jaw"
    },
    {
      "name": "K0929",
      "description": "Cyst of jaw, unspecified"
    },
    {
      "name": "K0980",
      "description": "Dermoid cyst"
    },
    {
      "name": "K0981",
      "description": "Epidermoid cyst"
    },
    {
      "name": "K0982",
      "description": "Gingival cyst of newborn"
    },
    {
      "name": "K0983",
      "description": "Palatal cyst of newborn"
    },
    {
      "name": "K0985",
      "description": "Lymphoepidermoid cyst"
    },
    {
      "name": "K0988",
      "description": "Other specified cysts of oral region"
    },
    {
      "name": "K099",
      "description": "Cyst of oral region, unspecified"
    },
    {
      "name": "K1000",
      "description": "Torus mandibularis"
    },
    {
      "name": "K1001",
      "description": "Torus palatinus"
    },
    {
      "name": "K1002",
      "description": "Latent bone cyst"
    },
    {
      "name": "K1008",
      "description": "Other specified developmental disorders of jaws"
    },
    {
      "name": "K1009",
      "description": "Developmental disorder of jaws, unspecified"
    },
    {
      "name": "K101",
      "description": "Giant cell granuloma, central"
    },
    {
      "name": "K1020",
      "description": "Osteitis of jaw"
    },
    {
      "name": "K1021",
      "description": "Osteomyelitis of jaw"
    },
    {
      "name": "K1022",
      "description": "Periostitis of jaw"
    },
    {
      "name": "K1023",
      "description": "Chronic periostitis of jaw"
    },
    {
      "name": "K1024",
      "description": "Neonatal osteomyelitis of maxilla [neonatal maxillitis]"
    },
    {
      "name": "K1025",
      "description": "Sequestrum"
    },
    {
      "name": "K1026",
      "description": "Osteoradionecrosis"
    },
    {
      "name": "K1028",
      "description": "Other specified inflammatory conditions of jaws"
    },
    {
      "name": "K1029",
      "description": "Inflammatory conditions of jaws, unspecified"
    },
    {
      "name": "K103",
      "description": "Alveolitis of jaws"
    },
    {
      "name": "K1080",
      "description": "Cherubism"
    },
    {
      "name": "K1081",
      "description": "Unilateral condylar hyperplasia of mandible"
    },
    {
      "name": "K1082",
      "description": "Unilateral condylar hypoplasia of mandible"
    },
    {
      "name": "K1083",
      "description": "Fibrous dysplasia of jaw"
    },
    {
      "name": "K1088",
      "description": "Other specified disease of jaws"
    },
    {
      "name": "K109",
      "description": "Disease of jaws, unspecified"
    },
    {
      "name": "K110",
      "description": "Atrophy of salivary gland"
    },
    {
      "name": "K111",
      "description": "Hypertrophy of salivary gland"
    },
    {
      "name": "K112",
      "description": "Sialoadenitis"
    },
    {
      "name": "K113",
      "description": "Abscess of salivary gland"
    },
    {
      "name": "K114",
      "description": "Fistula of salivary gland"
    },
    {
      "name": "K115",
      "description": "Sialolithiasis"
    },
    {
      "name": "K1160",
      "description": "Mucous retention cyst"
    },
    {
      "name": "K1161",
      "description": "Mucous extravasation cyst"
    },
    {
      "name": "K1169",
      "description": "Mucocele of salivary gland, unspecified"
    },
    {
      "name": "K1170",
      "description": "Hyposecretion"
    },
    {
      "name": "K1171",
      "description": "Xerostomia"
    },
    {
      "name": "K1172",
      "description": "Hypersecretion [ptyalism]"
    },
    {
      "name": "K1178",
      "description": "Other specified disturbances of salivary secretion"
    },
    {
      "name": "K1179",
      "description": "Disturbance of salivary secretion, unspecified"
    },
    {
      "name": "K1180",
      "description": "Benign lymphoepithelial lesion of salivary gland"
    },
    {
      "name": "K1181",
      "description": "Mikulicz, disease"
    },
    {
      "name": "K1182",
      "description": "Stenosis [stricture] of salivary duct"
    },
    {
      "name": "K1183",
      "description": "Sialectasia"
    },
    {
      "name": "K1185",
      "description": "Necrotizing sialometaplasia"
    },
    {
      "name": "K1188",
      "description": "Other specified disease of salivary glands"
    },
    {
      "name": "K119",
      "description": "Disease of salivary gland, unspecified"
    },
    {
      "name": "K1200",
      "description": "Recurrent (minor) aphthae"
    },
    {
      "name": "K1201",
      "description": "Periadenitis mucosa necrotica recurrens"
    },
    {
      "name": "K1202",
      "description": "Stomatis herpetiformis [herpetiform eruption]"
    },
    {
      "name": "K1203",
      "description": "Bednar’s aphthae"
    },
    {
      "name": "K1204",
      "description": "Traumatic ulcer"
    },
    {
      "name": "K1208",
      "description": "Other specified recurrent oral aphthae"
    },
    {
      "name": "K1209",
      "description": "Recurrent oral aphthae, unspecified"
    },
    {
      "name": "K1210",
      "description": "Stomatitis artifacta"
    },
    {
      "name": "K1211",
      "description": "Geographic stomatitis"
    },
    {
      "name": "K1212",
      "description": "Denture stomatitis"
    },
    {
      "name": "K1213",
      "description": "Papillary hyperplasia of palate"
    },
    {
      "name": "K1214",
      "description": "Contact stomatitis"
    },
    {
      "name": "K1218",
      "description": "Other specified forms of stomatitis"
    },
    {
      "name": "K1219",
      "description": "Stomatitis, unspecified"
    },
    {
      "name": "K122",
      "description": "Cellulitis and abscess of mouth"
    },
    {
      "name": "K123",
      "description": "Oral mucositis (ulcerative)"
    },
    {
      "name": "K1300",
      "description": "Angular cheilitis"
    },
    {
      "name": "K1301",
      "description": "Cheilitis glandularis apostematosa"
    },
    {
      "name": "K1302",
      "description": "Cheilitis, exfoliative"
    },
    {
      "name": "K1303",
      "description": "Cheilitis, unspecified  "
    },
    {
      "name": "K1304",
      "description": "Cheilodynia"
    },
    {
      "name": "K1308",
      "description": "Other specified disease of lips"
    },
    {
      "name": "K1309",
      "description": "Disease of lips, unspecified"
    },
    {
      "name": "K131",
      "description": "Cheek and lip biting"
    },
    {
      "name": "K1320",
      "description": "Leukoplakia, idiopathic"
    },
    {
      "name": "K1321",
      "description": "Leukoplakia, tobacco-associated"
    },
    {
      "name": "K1322",
      "description": "Erythroplakia"
    },
    {
      "name": "K1323",
      "description": "Leukoedema"
    },
    {
      "name": "K1324",
      "description": "Smoker’s palate [leukokeratosis nicotina palati] [nicotina palati]"
    },
    {
      "name": "K1328",
      "description": "Other specified leukoplakia and disturbances of oral epithelium,including tongue"
    },
    {
      "name": "K1329",
      "description": "Leukoplakia and disturbances of oral epithelium,unspecified"
    },
    {
      "name": "K133",
      "description": "Hairy leukoplakia"
    },
    {
      "name": "K1340",
      "description": "Pyogenic granuloma"
    },
    {
      "name": "K1341",
      "description": "Eosinophilic granuloma of oral mucosa"
    },
    {
      "name": "K1342",
      "description": "Verrucous xanthoma [histiocytosis Y]"
    },
    {
      "name": "K1348",
      "description": "Other specified granuloma and granuloma-like lesions of oral mucosa"
    },
    {
      "name": "K1349",
      "description": "Granuloma and granuloma-like lesions of oral mucosa, unspecified"
    },
    {
      "name": "K135",
      "description": "Oral submucous fibrosis"
    },
    {
      "name": "K136",
      "description": "Irritative hyperplasia of oral mucosa"
    },
    {
      "name": "K1370",
      "description": "Excessive melanin pigmentation"
    },
    {
      "name": "K1371",
      "description": "Oral fistula"
    },
    {
      "name": "K1372",
      "description": "Deliberate tattoo"
    },
    {
      "name": "K1373",
      "description": "Focal oral mucinosis"
    },
    {
      "name": "K1378",
      "description": "Other specified lesions of oral mucosa"
    },
    {
      "name": "K1379",
      "description": "Lesion of oral mucosa, unspecified"
    },
    {
      "name": "K1400",
      "description": "Abscess of tongue"
    },
    {
      "name": "K1401",
      "description": "Traumatic ulceration of tongue"
    },
    {
      "name": "K1408",
      "description": "Other specified glossitis"
    },
    {
      "name": "K1409",
      "description": "Glossitis, unspecified"
    },
    {
      "name": "K141",
      "description": "Geographic tongue"
    },
    {
      "name": "K142",
      "description": "Median rhomboid glossitis"
    },
    {
      "name": "K1430",
      "description": "Coated tongue"
    },
    {
      "name": "K1431",
      "description": "Hairy tongue"
    },
    {
      "name": "K1432",
      "description": "Hypertrophy of foliate papillae"
    },
    {
      "name": "K1438",
      "description": "Other specified hypertrophy of tongue papillae"
    },
    {
      "name": "K1439",
      "description": "Hypertrophy of tongue papillae, unspecified"
    },
    {
      "name": "K1440",
      "description": "Atrophy of tongue papillae due to tongue cleaning habit"
    },
    {
      "name": "K1441",
      "description": "Atrophy of tongue papillae due to systemic disorder"
    },
    {
      "name": "K1442",
      "description": "Atrophic glossitis, unspecified "
    },
    {
      "name": "K1448",
      "description": "Other specified atrophy of tongue papillae"
    },
    {
      "name": "K1449",
      "description": "Atrophy of tongue papillae, unspecified"
    },
    {
      "name": "K145",
      "description": "Plicated tongue"
    },
    {
      "name": "K1460",
      "description": "Glossopyrosis [burning tongue]"
    },
    {
      "name": "K1461",
      "description": "Glossodynia [painful tongue]"
    },
    {
      "name": "K1468",
      "description": "Other specified glossodynia"
    },
    {
      "name": "K1469",
      "description": "Glossodynia, unspecified"
    },
    {
      "name": "K1480",
      "description": "Crenated tongue [lingua indentata]"
    },
    {
      "name": "K1481",
      "description": "Hypertrophy of tongue"
    },
    {
      "name": "K1488",
      "description": "Other specified diesease of tongue"
    },
    {
      "name": "K149",
      "description": "Disease of tongue, unspecified"
    },
    {
      "name": "K20",
      "description": "Oesophagitis"
    },
    {
      "name": "K210",
      "description": "Gastro-oesophageal reflux disease with oesophagitis"
    },
    {
      "name": "K219",
      "description": "Gastro-oesophageal reflux disease without  oesophagitis"
    },
    {
      "name": "K220",
      "description": "Achalasia of cardia"
    },
    {
      "name": "K221",
      "description": "Ulcer of oesophagus"
    },
    {
      "name": "K222",
      "description": "Oesophageal obstruction"
    },
    {
      "name": "K223",
      "description": "Perforation of oesophagus"
    },
    {
      "name": "K224",
      "description": "Dyskinesia of oesophagus"
    },
    {
      "name": "K225",
      "description": "Diverticulum of oesophagus, acquired"
    },
    {
      "name": "K226",
      "description": "Gastro-oesophageal laceration-haemorrhage syndrome"
    },
    {
      "name": "K227",
      "description": "Barrett's esophagus"
    },
    {
      "name": "K228",
      "description": "Other specified diseases of oesophagus"
    },
    {
      "name": "K229",
      "description": "Disease of oesophagus, unspecified"
    },
    {
      "name": "K230",
      "description": "Tuberculous oesophagitis (A18.8)"
    },
    {
      "name": "K231",
      "description": "Megaoesophagus in Chagas' disease [B57.3]"
    },
    {
      "name": "K238",
      "description": "Disorders of oesophagus in other diseases classified elsewhere"
    },
    {
      "name": "K250",
      "description": "Gastric ulcer Acute with haemorrhage"
    },
    {
      "name": "K251",
      "description": "Gastric ulcer Acute with perforation"
    },
    {
      "name": "K252",
      "description": "Gastric ulcer Acute with both haemorrhage and perforation"
    },
    {
      "name": "K253",
      "description": "Gastric ulcer Acute without haemorrhage or perforation"
    },
    {
      "name": "K254",
      "description": "Gastric ulcer Chronic or unspecified with haemorrhage"
    },
    {
      "name": "K255",
      "description": "Gastric ulcer Chronic or unspecified with perforation"
    },
    {
      "name": "K256",
      "description": "Gastric ulcer Chronic or unspecified with both  haemorrhage and perforation"
    },
    {
      "name": "K257",
      "description": "Gastric ulcer Chronic without haemorrhage or perforation"
    },
    {
      "name": "K259",
      "description": "Gastric ulcer Unspecified as acute or chronic, without haemorrhage or perforation"
    },
    {
      "name": "K260",
      "description": "Duodenal ulcer Acute with haemorrhage"
    },
    {
      "name": "K261",
      "description": "Duodenal ulcer Acute with perforation"
    },
    {
      "name": "K262",
      "description": "Duodenal ulcer Acute with both haemorrhage and perforation"
    },
    {
      "name": "K263",
      "description": "Duodenal ulcer Acute without  haemorrhage or perforation"
    },
    {
      "name": "K264",
      "description": "Duodenal ulcer Chronic or unspecified with haemorrhage"
    },
    {
      "name": "K265",
      "description": "Duodenal ulcer Chornic unspecified with perforation"
    },
    {
      "name": "K266",
      "description": "Duodenal ulcer Chronic or unspecified with both haemorrhage and perforation"
    },
    {
      "name": "K267",
      "description": "Duodenal ulcer Chronic without  haemorrhage or perforation"
    },
    {
      "name": "K269",
      "description": "Duodenal ulcer Unspecified as acute or chronic, without haemorrhage or perforation"
    },
    {
      "name": "K270",
      "description": "Peptic ulcer, site unspecified Acute with haemorrhage"
    },
    {
      "name": "K271",
      "description": "Peptic ulcer, site unspecified Acute with perforation"
    },
    {
      "name": "K272",
      "description": "Peptic ulcer, site unspecified Acute with both haemorrhage and perforation"
    },
    {
      "name": "K273",
      "description": "Peptic ulcer, site unspecified Acute without haemorrhage or perforation"
    },
    {
      "name": "K274",
      "description": "Peptic ulcer, site unspecified Chronic or unspecified with haemorrhage"
    },
    {
      "name": "K275",
      "description": "Peptic ulcer, site unspecified Chronic or unspecified with perforation"
    },
    {
      "name": "K276",
      "description": "Peptic ulcer, site unspecified Chronic or unspecified with both haemorrhage and perforation"
    },
    {
      "name": "K277",
      "description": "Peptic ulcer, site unspecified Chronic without haemorrhage or perforation"
    },
    {
      "name": "K279",
      "description": "Peptic ulcer, site unspecified Unspecified as acute or chronic, without haemorrhage or perforation"
    },
    {
      "name": "K280",
      "description": "Gastrojejunal ulcer Acute with haemorrhage"
    },
    {
      "name": "K281",
      "description": "Gastrojejunal ulcer Acute with perforation"
    },
    {
      "name": "K282",
      "description": "Gastrojejunal ulcer Acute with both haemorrhage and perforation"
    },
    {
      "name": "K283",
      "description": "Gastrojejunal ulcer Acute without haemorrhage or perforation"
    },
    {
      "name": "K284",
      "description": "Gastrojejunal ulcer Chronic or unspecified with haemorrhage"
    },
    {
      "name": "K285",
      "description": "Gastrojejunal ulcer Chronic or unspecified with perforation"
    },
    {
      "name": "K286",
      "description": "Gastrojejunal ulcer Chronic or unspecified with both haemorrhage and perforation"
    },
    {
      "name": "K287",
      "description": "Gastrojejunal ulcer Chronic without haemorrhage or perforation"
    },
    {
      "name": "K289",
      "description": "Gastrojejunal ulcer Unspecified as acute or chronic, without haemorrhage or perforation"
    },
    {
      "name": "K290",
      "description": "Acute hemorrhagic gastritis"
    },
    {
      "name": "K291",
      "description": "Other acute gastritis"
    },
    {
      "name": "K292",
      "description": "Alcoholic gastritis"
    },
    {
      "name": "K293",
      "description": "Chronic superficial gastritis"
    },
    {
      "name": "K294",
      "description": "Chronic atrophic gastritis"
    },
    {
      "name": "K295",
      "description": "Chronic gastritis, unspecified"
    },
    {
      "name": "K296",
      "description": "Other gastritis"
    },
    {
      "name": "K297",
      "description": "Gastritis, unspecified"
    },
    {
      "name": "K298",
      "description": "Duodenitis"
    },
    {
      "name": "K299",
      "description": "Gastroduodenitis unspecified"
    },
    {
      "name": "K30",
      "description": "Functional dyspepsia"
    },
    {
      "name": "K310",
      "description": "Acute dilatation of stomach"
    },
    {
      "name": "K311",
      "description": "Adult hypertrophic pyloric stenosis"
    },
    {
      "name": "K312",
      "description": "Hourglass stricture and stenosis of stomach"
    },
    {
      "name": "K313",
      "description": "Pylorospasm,not elsewhere classified"
    },
    {
      "name": "K314",
      "description": "Gastric diverticulum"
    },
    {
      "name": "K315",
      "description": "Obstruction of duodenum"
    },
    {
      "name": "K316",
      "description": "Fistula of stomach and duodenum"
    },
    {
      "name": "K317",
      "description": "Polyp of stomach and duoenum"
    },
    {
      "name": "K318",
      "description": "Other specified diseases of stomach and duodenum"
    },
    {
      "name": "K319",
      "description": "Disease of stomach and duodenum,unspecified"
    },
    {
      "name": "K352",
      "description": "Acute appendicitis with generalized peritonitis"
    },
    {
      "name": "K353",
      "description": "Acute appendicitis with localized peritonitis"
    },
    {
      "name": "K358",
      "description": "Acute appendicitis, other and unspecified"
    },
    {
      "name": "K36",
      "description": "Other appendicitis"
    },
    {
      "name": "K37",
      "description": "Unspecified appendicitis"
    },
    {
      "name": "K380",
      "description": "Hyperplasia of appendix"
    },
    {
      "name": "K381",
      "description": "Appendicular concretions"
    },
    {
      "name": "K382",
      "description": "Diverticulum of appendix"
    },
    {
      "name": "K383",
      "description": "Fistula of appendix"
    },
    {
      "name": "K388",
      "description": "Other specified diseases of appendix"
    },
    {
      "name": "K389",
      "description": "Disease of appendix, unspecified"
    },
    {
      "name": "K400",
      "description": "Bilateral inguinal hernia, with obstruction, without gangrene"
    },
    {
      "name": "K401",
      "description": "Bilateral inguinal hernia, with gangrene"
    },
    {
      "name": "K402",
      "description": "Bilateral inguinal hernia, without obstruction or gangrene"
    },
    {
      "name": "K403",
      "description": "Unilateral or unspecified inguinal hernia, with obstruction without gangrene"
    },
    {
      "name": "K404",
      "description": "Unilateral or unspecified inguinal hernia, with gangrene"
    },
    {
      "name": "K409",
      "description": "Unilateral or unspecified inguinal hernia, without obstruction or  gangrene"
    },
    {
      "name": "K410",
      "description": "Bilateral femoral hernia, with obstruction, without gangrene"
    },
    {
      "name": "K411",
      "description": "Bilateral femoral hernia, with gangrene"
    },
    {
      "name": "K412",
      "description": "Bilateral femoral hernia, without obstruction or gangrene"
    },
    {
      "name": "K413",
      "description": "Unilateral or unspecified femoral hernia, with obstruction, without gangrene"
    },
    {
      "name": "K414",
      "description": "Unilateral or unspecified femoral hernia, with gangrene"
    },
    {
      "name": "K419",
      "description": "Unilateral or unspecified femoral hernia, without obstruction or gangrene"
    },
    {
      "name": "K420",
      "description": "Umbilical hernia with obstruction, without gangrene"
    },
    {
      "name": "K421",
      "description": "Umbilical hernia with gangrene"
    },
    {
      "name": "K429",
      "description": "Umbilical hernia without obstruction or gangrene"
    },
    {
      "name": "K430",
      "description": "Incisional hernia, with obstruction, without gangrene"
    },
    {
      "name": "K431",
      "description": "Incisional hernia with gangrene"
    },
    {
      "name": "K432",
      "description": "Incisional hernia without obstruction or gangrene"
    },
    {
      "name": "K433",
      "description": "Parastomal hernia with obstruction without gangrene"
    },
    {
      "name": "K434",
      "description": "Parastomal hernia with gangrene"
    },
    {
      "name": "K435",
      "description": "Parastomal hernia without obstruction or gangrene"
    },
    {
      "name": "K436",
      "description": "Other and unspecified ventral hernia with obstruction without gangrene"
    },
    {
      "name": "K437",
      "description": "Other and unspecified ventral hernia with gangrene"
    },
    {
      "name": "K439",
      "description": "Other and unspecified ventral hernia without obstruction or gangrene"
    },
    {
      "name": "K440",
      "description": "Diaphragmatic hernia with obstruction, without gangrene"
    },
    {
      "name": "K441",
      "description": "Diaphragmatic hernia with gangrene"
    },
    {
      "name": "K449",
      "description": "Diaphragmatic hernia without obstruction or gangrene"
    },
    {
      "name": "K450",
      "description": "Other specified abdominal hernia with obstruction, without gangrene"
    },
    {
      "name": "K451",
      "description": "Other specified abdominal hernia with gangrene"
    },
    {
      "name": "K458",
      "description": "Other specified abdominal hernia without obstruction or gangrene"
    },
    {
      "name": "K460",
      "description": "Unspecified abdominal hernia with obstruction, without gangrene"
    },
    {
      "name": "K461",
      "description": "Unspecified abdominal hernia with gangrene"
    },
    {
      "name": "K469",
      "description": "Unspecified abdominal hernia without obstruction or gangrene"
    },
    {
      "name": "K500",
      "description": "Crohn's disease of small intestine"
    },
    {
      "name": "K501",
      "description": "Crohn's disease of large intestine"
    },
    {
      "name": "K508",
      "description": "Other Crohn's of disease"
    },
    {
      "name": "K509",
      "description": "Crohn's disease, unspecified"
    },
    {
      "name": "K510",
      "description": "Ulcerative (chronic) pancolitis "
    },
    {
      "name": "K512",
      "description": "Ulcerative (chronic) proctitis"
    },
    {
      "name": "K513",
      "description": "Ulcerative (chronic) rectosigmoiditis"
    },
    {
      "name": "K514",
      "description": "Inflammatory polyps"
    },
    {
      "name": "K515",
      "description": "Left side colitis "
    },
    {
      "name": "K518",
      "description": "Other ulcerative colitis"
    },
    {
      "name": "K519",
      "description": "Ulcerative colitis, unspecified"
    },
    {
      "name": "K520",
      "description": "Gastroenteritis and colitis due to radiation"
    },
    {
      "name": "K521",
      "description": "Toxic gastroenteritis and colitis"
    },
    {
      "name": "K5220",
      "description": "Allergic and dietetic gastroenteritis and colitis due to cow’s milk protein"
    },
    {
      "name": "K5228",
      "description": "Other allergic and dietetic gastroenteritis and colitis"
    },
    {
      "name": "K5229",
      "description": "Allergic and dietetic gastroenteritis and colitis, unspecified"
    },
    {
      "name": "K523",
      "description": "Indeterminate colitis "
    },
    {
      "name": "K528",
      "description": "Other specified noninfective gastroenteritis and colitis"
    },
    {
      "name": "K529",
      "description": "Noninfective gastroenteritis and colitis, unspecified"
    },
    {
      "name": "K550",
      "description": "Acute vascular disorders of intestine"
    },
    {
      "name": "K551",
      "description": "Chronic vascular disorders of intestine"
    },
    {
      "name": "K552",
      "description": "Angiodysplasia of colon"
    },
    {
      "name": "K558",
      "description": "Other vascular disorders of intestine"
    },
    {
      "name": "K559",
      "description": "Vascular disorder of intestine, unspecified"
    },
    {
      "name": "K560",
      "description": "Paralytic ileus"
    },
    {
      "name": "K561",
      "description": "Intussusception"
    },
    {
      "name": "K562",
      "description": "Volvulus"
    },
    {
      "name": "K563",
      "description": "Gallstone ileus"
    },
    {
      "name": "K564",
      "description": "Other impaction of intestine"
    },
    {
      "name": "K565",
      "description": "Intestinal adhesions [bands] with obstruction"
    },
    {
      "name": "K566",
      "description": "Other and unspecified intestinal obstruction"
    },
    {
      "name": "K567",
      "description": "lleus, unspecified"
    },
    {
      "name": "K570",
      "description": "Diverticular disease of small intestine with perforation and abscess"
    },
    {
      "name": "K571",
      "description": "Diverticular disease of small intestine without perforation and abscess"
    },
    {
      "name": "K572",
      "description": "Diverticular disease of large intestine with perforation and abscess"
    },
    {
      "name": "K573",
      "description": "Diverticular disease of large intestine without perforation or abscess"
    },
    {
      "name": "K574",
      "description": "Diverticular disease of both small and large intestine with perforation and abscess"
    },
    {
      "name": "K575",
      "description": "Diverticular disease of both small and large intestine without perforation and abscess"
    },
    {
      "name": "K578",
      "description": "Diverticular disease of intestine, part unspecified, with perforation and abscess"
    },
    {
      "name": "K579",
      "description": "Diverticular disease of intestine,part unspecified,without perforation or abscess"
    },
    {
      "name": "K580",
      "description": "Irritable bowel syndrome with diarrhoea"
    },
    {
      "name": "K589",
      "description": "Irritable bowel syndrome without diarrhoea"
    },
    {
      "name": "K590",
      "description": "Constipation"
    },
    {
      "name": "K591",
      "description": "Functional diarrhoea"
    },
    {
      "name": "K592",
      "description": "Neurogenic bowel, not elsewhere classified"
    },
    {
      "name": "K593",
      "description": "Megacolon, not elsewhere classified"
    },
    {
      "name": "K594",
      "description": "Anal spasm"
    },
    {
      "name": "K595",
      "description": "Anal sphincter relaxation"
    },
    {
      "name": "K598",
      "description": "Other specified functional intestinal disorders"
    },
    {
      "name": "K599",
      "description": "Functional intestinal disorder, unspecified"
    },
    {
      "name": "K600",
      "description": "Acute anal fissure"
    },
    {
      "name": "K601",
      "description": "Chronic anal fissure"
    },
    {
      "name": "K602",
      "description": "Anal fissure, unspecified"
    },
    {
      "name": "K603",
      "description": "Anal fistula"
    },
    {
      "name": "K604",
      "description": "Rectal fistula"
    },
    {
      "name": "K605",
      "description": "Anorectal fistula"
    },
    {
      "name": "K610",
      "description": "Anal abscess"
    },
    {
      "name": "K611",
      "description": "Rectal abscess"
    },
    {
      "name": "K612",
      "description": "Anorectal abscess"
    },
    {
      "name": "K613",
      "description": "Ischiorectal abscess"
    },
    {
      "name": "K614",
      "description": "Intrasphincteric abscess"
    },
    {
      "name": "K620",
      "description": "Anal polyp"
    },
    {
      "name": "K621",
      "description": "Rectal polyp"
    },
    {
      "name": "K622",
      "description": "Anal prolapse"
    },
    {
      "name": "K623",
      "description": "Rectal prolapse"
    },
    {
      "name": "K624",
      "description": "Stenosis of anus and rectum"
    },
    {
      "name": "K625",
      "description": "Haemorrhage of anus and rectum"
    },
    {
      "name": "K626",
      "description": "Ulcer of anus and rectum"
    },
    {
      "name": "K627",
      "description": "Radiation proctitis"
    },
    {
      "name": "K628",
      "description": "Other specified diseases of anus and rectum"
    },
    {
      "name": "K629",
      "description": "Disease of anus and rectum, unspecified"
    },
    {
      "name": "K630",
      "description": "Abscess of intestine"
    },
    {
      "name": "K631",
      "description": "Perforation of intestine(nontraumatic)"
    },
    {
      "name": "K632",
      "description": "Fistula of intestine"
    },
    {
      "name": "K633",
      "description": "Ulcer of intestine"
    },
    {
      "name": "K634",
      "description": "Enteroptosis"
    },
    {
      "name": "K635",
      "description": "Polyp of colon"
    },
    {
      "name": "K638",
      "description": "Other specified diseases of intestine"
    },
    {
      "name": "K639",
      "description": "Disease of intestine, unspecified"
    },
    {
      "name": "K640",
      "description": "First degree haemorrhoids"
    },
    {
      "name": "K641",
      "description": "Second degree haemorrhoids"
    },
    {
      "name": "K642",
      "description": "Third degree haemorrhoids"
    },
    {
      "name": "K643",
      "description": "Fourth degree haemorrhoids"
    },
    {
      "name": "K644",
      "description": "Residual haemorrhoidal skin tags"
    },
    {
      "name": "K645",
      "description": "Perianal venous thrombosis"
    },
    {
      "name": "K648",
      "description": "Other specified haemorrhoids"
    },
    {
      "name": "K649",
      "description": "Haemorrhoids, unspecified"
    },
    {
      "name": "K6500",
      "description": "Primary peritonitis"
    },
    {
      "name": "K6501",
      "description": "Secondary bacterial peritonitis"
    },
    {
      "name": "K658",
      "description": "Other peritonitis"
    },
    {
      "name": "K659",
      "description": "Peritonitis, unspecified  "
    },
    {
      "name": "K660",
      "description": "Peritoneal adhesions"
    },
    {
      "name": "K661",
      "description": "Haemoperitoneum"
    },
    {
      "name": "K668",
      "description": "Other specified disorders of peritoneum"
    },
    {
      "name": "K669",
      "description": "Disorder of peritoneum, unspecified"
    },
    {
      "name": "K670",
      "description": "Chlamydial peritonitis (A74.8)"
    },
    {
      "name": "K671",
      "description": "Gonococcal peritonitis (A54.8)"
    },
    {
      "name": "K672",
      "description": "Syphilitic peritonitis (A52.7)"
    },
    {
      "name": "K673",
      "description": "Tuberculous peritonitis (A18.3)"
    },
    {
      "name": "K678",
      "description": "Other disorders of peritoneum in infectious diseases classified elsewhere"
    },
    {
      "name": "K700",
      "description": "Alcoholic fatty liver"
    },
    {
      "name": "K701",
      "description": "Alcoholic hepatitis"
    },
    {
      "name": "K702",
      "description": "Alcoholic fibrosis and sclerosis of liver"
    },
    {
      "name": "K703",
      "description": "Alcoholic cirrhosis of liver"
    },
    {
      "name": "K704",
      "description": "Alcoholic hepatic failure"
    },
    {
      "name": "K709",
      "description": "Alcoholic liver disease, unspecified"
    },
    {
      "name": "K710",
      "description": "Toxic liver disease with cholestasis"
    },
    {
      "name": "K711",
      "description": "Toxic liver disease with hepatic necrosis"
    },
    {
      "name": "K712",
      "description": "Toxic liver disease with acute hepatitis"
    },
    {
      "name": "K713",
      "description": "Toxic liver disease with chronic persistent hepatitis"
    },
    {
      "name": "K714",
      "description": "Toxic liver disease with chronic lobular hepatitis"
    },
    {
      "name": "K715",
      "description": "Toxic liver disease with chronic active hepatitis"
    },
    {
      "name": "K716",
      "description": "Toxic liver disease with hepatitis, not elsewhere classified"
    },
    {
      "name": "K717",
      "description": "Toxic liver disease with fibrosis and cirrhosis of liver"
    },
    {
      "name": "K718",
      "description": "Toxic liver disease with other disorders "
    },
    {
      "name": "K719",
      "description": "Toxic liver disease, unspecified"
    },
    {
      "name": "K720",
      "description": "Acute and subacute hepatic failure"
    },
    {
      "name": "K721",
      "description": "Chronic hepatic failure"
    },
    {
      "name": "K729",
      "description": "Hepatic failure, unspecified"
    },
    {
      "name": "K730",
      "description": "Chronic persistent hepatitis, not elsewhere classified"
    },
    {
      "name": "K731",
      "description": "Chronic lobular hepatitis, not elsewhere classified"
    },
    {
      "name": "K732",
      "description": "Chronic active hepatitis, not elsewhere classified"
    },
    {
      "name": "K738",
      "description": "Other chronic hepatitis, not elsewhere classified"
    },
    {
      "name": "K739",
      "description": "Chronic hepatitis, unspecified"
    },
    {
      "name": "K740",
      "description": "Hepatic fibrosis"
    },
    {
      "name": "K741",
      "description": "Hepatic sclerosis"
    },
    {
      "name": "K742",
      "description": "Hepatic fibrosis with hepatic sclerosis"
    },
    {
      "name": "K743",
      "description": "Primary biliary cirrhosis"
    },
    {
      "name": "K744",
      "description": "Secondary biliary cirrhosis"
    },
    {
      "name": "K745",
      "description": "Biliary cirrhosis, unspecified"
    },
    {
      "name": "K746",
      "description": "Other and unspecified cirrhosis of liver"
    },
    {
      "name": "K750",
      "description": "Abscess of liver"
    },
    {
      "name": "K751",
      "description": "Phlebitis of portal vein"
    },
    {
      "name": "K752",
      "description": "Nonspecific reactive hepatitis"
    },
    {
      "name": "K753",
      "description": "Granulomatous hepatitis, not elsewhere classified"
    },
    {
      "name": "K754",
      "description": "Autoimmune hepatitis"
    },
    {
      "name": "K758",
      "description": "Other specified inflammatory liver diseases"
    },
    {
      "name": "K759",
      "description": "Inflammatory liver disease, unspecified"
    },
    {
      "name": "K760",
      "description": "Fatty (change of ) liver, not elsewhere classified"
    },
    {
      "name": "K761",
      "description": "Chronic passive congestion of liver"
    },
    {
      "name": "K762",
      "description": "Central haemorrhagic necrosis of liver"
    },
    {
      "name": "K763",
      "description": "Infarction of liver"
    },
    {
      "name": "K764",
      "description": "Peliosis hepatis"
    },
    {
      "name": "K765",
      "description": "Hepatic veno-occlusive disease"
    },
    {
      "name": "K766",
      "description": "Portal hypertension"
    },
    {
      "name": "K767",
      "description": "Hepatorenal syndrome"
    },
    {
      "name": "K768",
      "description": "Other specified diseases of liver"
    },
    {
      "name": "K769",
      "description": "Liver disease, unspecified"
    },
    {
      "name": "K770",
      "description": "Liver disorders in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "K778",
      "description": "Liver disorders in other diseases classified elsewhere"
    },
    {
      "name": "K800",
      "description": "Calculus of gallbladder with acute cholecystitis"
    },
    {
      "name": "K801",
      "description": "Calculus of gallbladder with other cholecystitis"
    },
    {
      "name": "K802",
      "description": "Calculus of gallbladder without cholecystitis"
    },
    {
      "name": "K803",
      "description": "Calculus of bile duct with cholangitis"
    },
    {
      "name": "K804",
      "description": "Calculus of bile duct with cholecystitis"
    },
    {
      "name": "K805",
      "description": "Calculus of bile duct without cholangitis or cholecystitis"
    },
    {
      "name": "K808",
      "description": "Other cholelithiasis"
    },
    {
      "name": "K810",
      "description": "Acute cholecystitis"
    },
    {
      "name": "K811",
      "description": "Chronic cholecystitis"
    },
    {
      "name": "K818",
      "description": "Other  cholecystitis"
    },
    {
      "name": "K819",
      "description": "Cholecystitis, unspecified"
    },
    {
      "name": "K820",
      "description": "Obstruction of gallbladder"
    },
    {
      "name": "K821",
      "description": "Hydrops of gallbladder"
    },
    {
      "name": "K822",
      "description": "Perforation of gallbladder"
    },
    {
      "name": "K823",
      "description": "Fistula of gallbladder"
    },
    {
      "name": "K824",
      "description": "Cholesterolosis of gallbladder"
    },
    {
      "name": "K828",
      "description": "Other specified diseases of gallbladder"
    },
    {
      "name": "K829",
      "description": "Disease of gallbladder, unspecified"
    },
    {
      "name": "K830",
      "description": "Cholangitis"
    },
    {
      "name": "K831",
      "description": "Obstruction of bile duct"
    },
    {
      "name": "K832",
      "description": "Perforation of bile duct"
    },
    {
      "name": "K833",
      "description": "Fistula of bile duct"
    },
    {
      "name": "K834",
      "description": "Spasm of sphincter of Oddi"
    },
    {
      "name": "K835",
      "description": "Biliary cyst"
    },
    {
      "name": "K836",
      "description": "Stenosis of sphincter of Oddi"
    },
    {
      "name": "K838",
      "description": "Other specified diseases of biliary tract"
    },
    {
      "name": "K839",
      "description": "Disease of biliary tract, unspecified"
    },
    {
      "name": "K850",
      "description": "Idiopathic acute pancreatitis"
    },
    {
      "name": "K851",
      "description": "Biliary acute pancreatitis"
    },
    {
      "name": "K852",
      "description": "Alcohol-induced acute pancreatitis"
    },
    {
      "name": "K853",
      "description": "Drug-induced acute pancreatitis"
    },
    {
      "name": "K858",
      "description": "Other acute pancreatitis"
    },
    {
      "name": "K859",
      "description": "Acute pancreatitis, unspecified"
    },
    {
      "name": "K860",
      "description": "Alcohol-induced chronic pancreatitis"
    },
    {
      "name": "K861",
      "description": "Other chronic pancreatitis"
    },
    {
      "name": "K862",
      "description": "Cyst of pancreas"
    },
    {
      "name": "K863",
      "description": "Pseudocyst of pancreas"
    },
    {
      "name": "K868",
      "description": "Other specified diseases of pancreas"
    },
    {
      "name": "K869",
      "description": "Disease of pancreas, unspecified"
    },
    {
      "name": "K870",
      "description": "Disorders of gallbladder and biliary tract in diseases classified elsewhere"
    },
    {
      "name": "K871",
      "description": "Disorders of pancreas in diseases classified elsewhere"
    },
    {
      "name": "K900",
      "description": "Coeliac disease"
    },
    {
      "name": "K901",
      "description": "Tropical spure"
    },
    {
      "name": "K902",
      "description": "Blind loop syndrome, not elsewhere classified"
    },
    {
      "name": "K903",
      "description": "Pancreatic steatorrhoea"
    },
    {
      "name": "K904",
      "description": "Malabsorption due to intolerance, not elsewhere classified"
    },
    {
      "name": "K908",
      "description": "Other intestinal malabsorption"
    },
    {
      "name": "K909",
      "description": "Intestinal malabsorption, unspecified"
    },
    {
      "name": "K910",
      "description": "Vomiting following gastrointestinal surgery"
    },
    {
      "name": "K911",
      "description": "Postgastric surgery syndromes"
    },
    {
      "name": "K9120",
      "description": "Post massive bowel resection"
    },
    {
      "name": "K9128",
      "description": "Other postsurgical malabsorption"
    },
    {
      "name": "K9129",
      "description": "Postsurgical malabsorption,  unspecified"
    },
    {
      "name": "K913",
      "description": "Postoperative intestinal obstruction"
    },
    {
      "name": "K914",
      "description": "Colostomy and enterostomy malfunction"
    },
    {
      "name": "K915",
      "description": "Postcholecystectomy syndrome"
    },
    {
      "name": "K918",
      "description": "Other postprocedural disorders of digestive system, not elsewhere classified"
    },
    {
      "name": "K919",
      "description": "Postprocedural disorder of digestive system, unspecified"
    },
    {
      "name": "K920",
      "description": "Haematemesis"
    },
    {
      "name": "K921",
      "description": "Melaena"
    },
    {
      "name": "K922",
      "description": "Gastrointestinal haemorrhage, unspecified"
    },
    {
      "name": "K923",
      "description": "Lower gastrointestinal haemorrhage, unspecified"
    },
    {
      "name": "K928",
      "description": "Other specified diseases of digestive system"
    },
    {
      "name": "K929",
      "description": "Disease of digestive system, unspecified"
    },
    {
      "name": "K930",
      "description": "Tuberculous disorders of intestines, peritoneum and mesenteric glands (A18.3)"
    },
    {
      "name": "K931",
      "description": "Megacolon in Chagas' disease(B57.3)"
    },
    {
      "name": "K938",
      "description": "Disorders of other specified digestive organs in diseases classified elsewhere"
    },
    {
      "name": "L00",
      "description": "Staphylococcal scalded skin syndrome"
    },
    {
      "name": "L010",
      "description": "Impetigo [any organism] [any site]"
    },
    {
      "name": "L011",
      "description": "Impetiginization of other dermatoses"
    },
    {
      "name": "L020",
      "description": "Cutaneous abscess, furuncle and carbuncle of face"
    },
    {
      "name": "L021",
      "description": "Cutaneous abscess, furuncle and carbuncle of neck"
    },
    {
      "name": "L022",
      "description": "Cutaneous abscess, furuncle and carbuncle of trunk"
    },
    {
      "name": "L023",
      "description": "Cutaneous abscess, furuncle and carbuncle of buttock"
    },
    {
      "name": "L024",
      "description": "Cutaneous abscess, furuncle and carbuncle of limb"
    },
    {
      "name": "L028",
      "description": "Cutaneous abscess, furuncle and carbuncle of other sites"
    },
    {
      "name": "L029",
      "description": "Cutaneous abscess, furuncle and carbuncle, unspecified"
    },
    {
      "name": "L030",
      "description": "Cellulitis of finger and toe"
    },
    {
      "name": "L031",
      "description": "Cellulitis of other parts of limb"
    },
    {
      "name": "L032",
      "description": "Cellulitis of face"
    },
    {
      "name": "L033",
      "description": "Cellulitis of trunk"
    },
    {
      "name": "L038",
      "description": "Cellulitis of other sites"
    },
    {
      "name": "L039",
      "description": "Cellulitis, unspecified"
    },
    {
      "name": "L040",
      "description": "Acute lymphadenitis of face, head and neck"
    },
    {
      "name": "L041",
      "description": "Acute lymphadenitis of trunk"
    },
    {
      "name": "L042",
      "description": "Acute lymphadenitis of upper limb"
    },
    {
      "name": "L043",
      "description": "Acute lymphadenitis of lower limb"
    },
    {
      "name": "L048",
      "description": "Acute lymphadenitis of other sites"
    },
    {
      "name": "L049",
      "description": "Acute lymphadenitis, unspecified"
    },
    {
      "name": "L050",
      "description": "Pilonidal cyst with abscess"
    },
    {
      "name": "L059",
      "description": "Pilonidal cyst without abscess"
    },
    {
      "name": "L080",
      "description": "Pyoderma"
    },
    {
      "name": "L081",
      "description": "Erythrasma"
    },
    {
      "name": "L088",
      "description": "Other specified local infections of skin and subcutaneous tissue"
    },
    {
      "name": "L089",
      "description": "Local infection of skin and subcutaneous tissue, unspecified"
    },
    {
      "name": "L100",
      "description": "Pemphigus vulgaris"
    },
    {
      "name": "L101",
      "description": "Pemphigus vegetans"
    },
    {
      "name": "L102",
      "description": "Pemphigus foliaceus"
    },
    {
      "name": "L103",
      "description": "Brazilian pemphigus [fogo selvagem]"
    },
    {
      "name": "L104",
      "description": "Pemphingus erythematosus"
    },
    {
      "name": "L105",
      "description": "Drug-induced pemphigus"
    },
    {
      "name": "L106",
      "description": "Paraneoplastic pemphigus"
    },
    {
      "name": "L108",
      "description": "Other pemphigus"
    },
    {
      "name": "L109",
      "description": "Pemphigus, unspecified"
    },
    {
      "name": "L110",
      "description": "Acquired keratosis follicularis"
    },
    {
      "name": "L111",
      "description": "Transient acantholytic dermatosis [Grover]"
    },
    {
      "name": "L118",
      "description": "Other specified acantholytic disorders"
    },
    {
      "name": "L119",
      "description": "Acantholytic disorder, unspecified"
    },
    {
      "name": "L120",
      "description": "Bullous pemphigoid"
    },
    {
      "name": "L121",
      "description": "Cicatricial pemphigoid"
    },
    {
      "name": "L122",
      "description": "Chronic bullous disease of childhood"
    },
    {
      "name": "L123",
      "description": "Acquired epidermolysis bullosa"
    },
    {
      "name": "L128",
      "description": "Other pemphigoid"
    },
    {
      "name": "L129",
      "description": "Pemphigoid, unspecified"
    },
    {
      "name": "L130",
      "description": "Dermatitis herpetiformis"
    },
    {
      "name": "L131",
      "description": "Subcorneal pustular dermatitis"
    },
    {
      "name": "L138",
      "description": "Other specified bullous disorders"
    },
    {
      "name": "L139",
      "description": "Bullous disorder, unspecified"
    },
    {
      "name": "L14",
      "description": "Bullous disorders in diseases classified elsewhere"
    },
    {
      "name": "L200",
      "description": "Besnier's prurigo"
    },
    {
      "name": "L208",
      "description": "Other atopic dermatitis"
    },
    {
      "name": "L209",
      "description": "Atopic dermatitis, unspecified"
    },
    {
      "name": "L210",
      "description": "Seborrhoea capitis"
    },
    {
      "name": "L211",
      "description": "Seborrhoeic infantile dermatitis"
    },
    {
      "name": "L218",
      "description": "Other seborrhoeic dermatitis"
    },
    {
      "name": "L219",
      "description": "Seborrhoeic dermatitis, unspecified"
    },
    {
      "name": "L22",
      "description": "Diaper [napkin] dermatitis"
    },
    {
      "name": "L230",
      "description": "Allergic contact dermatitis due to metals"
    },
    {
      "name": "L231",
      "description": "Allergic contact dermatitis due to adhesives"
    },
    {
      "name": "L232",
      "description": "Allergic contact dermatitis due to cosmetics"
    },
    {
      "name": "L233",
      "description": "Allergic contact dermatitis due to drugs in contact with skin"
    },
    {
      "name": "L234",
      "description": "Allergic contact dermatitis due to dyes"
    },
    {
      "name": "L235",
      "description": "Allergic contact dermatitis due to other chemical products"
    },
    {
      "name": "L236",
      "description": "Allergic contact dermatitis due to food in contact with skin"
    },
    {
      "name": "L237",
      "description": "Allergic contact dermatitis due to plants, except food"
    },
    {
      "name": "L238",
      "description": "Allergic contact dermatitis due to other agents"
    },
    {
      "name": "L239",
      "description": "Allergic contact dermatitis, unspecified cause"
    },
    {
      "name": "L240",
      "description": "Irritant contact dermatitis due to detergents"
    },
    {
      "name": "L241",
      "description": "Irritant contact dermatitis due to oils and greases"
    },
    {
      "name": "L242",
      "description": "Irritant contact dermatitis due to solvents"
    },
    {
      "name": "L243",
      "description": "Irritant contact dermatitis due to cosmetics"
    },
    {
      "name": "L244",
      "description": "Irritant contact dermatitis due to drugs in contact with skin"
    },
    {
      "name": "L245",
      "description": "Irritant contact dermatitis due to other chemical products"
    },
    {
      "name": "L246",
      "description": "Irritant contact dermatitis due to food in contact with skin"
    },
    {
      "name": "L247",
      "description": "Irritant contact dermatitis due to plants, except food"
    },
    {
      "name": "L2480",
      "description": "Irritant contact dermatitis due to dyes"
    },
    {
      "name": "L2481",
      "description": "Paederus dermatitis"
    },
    {
      "name": "L2488",
      "description": "Irritant contact dermatitis due to other agents"
    },
    {
      "name": "L249",
      "description": "Irritant contact dermatitis, unspecified cause"
    },
    {
      "name": "L250",
      "description": "Unspecified contact dermatitis due to cosmetics"
    },
    {
      "name": "L251",
      "description": "Unspecified contact dermatitis due to drugs in contact with skin"
    },
    {
      "name": "L252",
      "description": "Unspecified contact dermatitis due to dyes"
    },
    {
      "name": "L253",
      "description": "Unspecified contact dermatitis due to other chemical products"
    },
    {
      "name": "L254",
      "description": "Unspecified contact dermatitis due to food in contact with skin"
    },
    {
      "name": "L255",
      "description": "Unspecified contact dermatitis due to plants, except food"
    },
    {
      "name": "L258",
      "description": "Unspecified contact dermatitis dee to other agents"
    },
    {
      "name": "L259",
      "description": "Unspecified contact dermatitis, unspecified cause"
    },
    {
      "name": "L26",
      "description": "Exfoliative dermatitis"
    },
    {
      "name": "L270",
      "description": "Generalized skin eruption due to drugs and medicaments"
    },
    {
      "name": "L2710",
      "description": "Fixed drug eruption"
    },
    {
      "name": "L2718",
      "description": "Other specified localized skin eruption due to drugs and medicaments"
    },
    {
      "name": "L2719",
      "description": "Localized skin eruption due to drugs and medicaments, unspecified"
    },
    {
      "name": "L272",
      "description": "Dermatitis due to ingested food"
    },
    {
      "name": "L278",
      "description": "Dermatitis due to other substances taken internally"
    },
    {
      "name": "L279",
      "description": "Dermatitis due to unspecified substance taken internally"
    },
    {
      "name": "L280",
      "description": "Lichen simplex chronicus"
    },
    {
      "name": "L281",
      "description": "Prurigo nodularis"
    },
    {
      "name": "L282",
      "description": "Other prurigo"
    },
    {
      "name": "L290",
      "description": "Pruritus ani"
    },
    {
      "name": "L291",
      "description": "Pruritus scroti"
    },
    {
      "name": "L292",
      "description": "Pruritus vulvae"
    },
    {
      "name": "L293",
      "description": "Anogenital pruritus, unspecified"
    },
    {
      "name": "L298",
      "description": "Other pruritus"
    },
    {
      "name": "L299",
      "description": "Pruritus, unspecified"
    },
    {
      "name": "L300",
      "description": "Nummular dermatitis"
    },
    {
      "name": "L301",
      "description": "Dyshidrosis [pompholyx]"
    },
    {
      "name": "L302",
      "description": "Cutaneous autosensitization"
    },
    {
      "name": "L303",
      "description": "Infective dermatitis"
    },
    {
      "name": "L304",
      "description": "Erythema intertrigo"
    },
    {
      "name": "L305",
      "description": "Pityriasis alba"
    },
    {
      "name": "L308",
      "description": "Other specified dermatitis"
    },
    {
      "name": "L309",
      "description": "Dermatitis, unspecified"
    },
    {
      "name": "L400",
      "description": "Psoriasis vulgaris"
    },
    {
      "name": "L401",
      "description": "Generalized pustular psoriasis"
    },
    {
      "name": "L402",
      "description": "Acrodermatitis continua"
    },
    {
      "name": "L403",
      "description": "Pustulosis palmaris et plantaris"
    },
    {
      "name": "L404",
      "description": "Guttate psoriasis"
    },
    {
      "name": "L405",
      "description": "Arthropathic psoriasis (M07.0-M07.3*, M09.0*)"
    },
    {
      "name": "L408",
      "description": "Other psoriasis"
    },
    {
      "name": "L409",
      "description": "Psoriasis, unspecified"
    },
    {
      "name": "L410",
      "description": "Pityriasis lichenoides et verioliformis acuta"
    },
    {
      "name": "L411",
      "description": "Pityriasis lichenoides chronica"
    },
    {
      "name": "L413",
      "description": "Small plaque parapsoriasis"
    },
    {
      "name": "L414",
      "description": "Large plaque parapsoriasis"
    },
    {
      "name": "L415",
      "description": "Retiform parapsoriasis"
    },
    {
      "name": "L418",
      "description": "Other parapsoriasis"
    },
    {
      "name": "L419",
      "description": "Parapsoriasis, unspecified"
    },
    {
      "name": "L42",
      "description": "Pityriasis rosea"
    },
    {
      "name": "L430",
      "description": "Hypertrophic lichen planus"
    },
    {
      "name": "L431",
      "description": "Bullous lichen planus"
    },
    {
      "name": "L432",
      "description": "Lichenoid drug reaction"
    },
    {
      "name": "L433",
      "description": "Subacute (active) lichen planus"
    },
    {
      "name": "L438",
      "description": "Other lichen planus"
    },
    {
      "name": "L439",
      "description": "Lichen planus, unspecified"
    },
    {
      "name": "L440",
      "description": "Pityriasis rubra pilaris"
    },
    {
      "name": "L441",
      "description": "Lichen nitidus"
    },
    {
      "name": "L442",
      "description": "Lichen striatus"
    },
    {
      "name": "L443",
      "description": "Lichen ruber moniliformis"
    },
    {
      "name": "L444",
      "description": "Infantile papular acrodermatitis [Giannotti-Crosti]"
    },
    {
      "name": "L448",
      "description": "Other specified papulosquamous disorders"
    },
    {
      "name": "L449",
      "description": "Papulosquamous disorders, unspecified"
    },
    {
      "name": "L45",
      "description": "Papulosquamous disorders in diseases classified elsewhere"
    },
    {
      "name": "L500",
      "description": "Allergic urticaria"
    },
    {
      "name": "L501",
      "description": "Idiopathic uticaria"
    },
    {
      "name": "L502",
      "description": "Urticaria due to cold and heat"
    },
    {
      "name": "L503",
      "description": "Dermatographic urticaria"
    },
    {
      "name": "L504",
      "description": "Vibratory urticaria"
    },
    {
      "name": "L505",
      "description": "Cholinergic urticaria"
    },
    {
      "name": "L506",
      "description": "Contact urticaria"
    },
    {
      "name": "L508",
      "description": "Other urticaria"
    },
    {
      "name": "L509",
      "description": "Urticaria, unspecified"
    },
    {
      "name": "L510",
      "description": "Nonbullous erythema multiforme"
    },
    {
      "name": "L511",
      "description": "Bullous erythema multiforme"
    },
    {
      "name": "L512",
      "description": "Toxic epidermal necrolysis [Lyell]"
    },
    {
      "name": "L518",
      "description": "Other erythema multiforme"
    },
    {
      "name": "L519",
      "description": "Erythema multiforme, unspecified"
    },
    {
      "name": "L52",
      "description": "Erythema nodosum"
    },
    {
      "name": "L530",
      "description": "Toxic erythema"
    },
    {
      "name": "L531",
      "description": "Erythema annulare centrifugum"
    },
    {
      "name": "L532",
      "description": "Erythema marginatum"
    },
    {
      "name": "L533",
      "description": "Other chronic figurate erythema"
    },
    {
      "name": "L538",
      "description": "Other specified erythematous conditions"
    },
    {
      "name": "L539",
      "description": "Erythematous condition, unspecified"
    },
    {
      "name": "L540",
      "description": "Erythema marginatum in acute rheumatic fever (I00*]"
    },
    {
      "name": "L548",
      "description": "Erythema in other diseases classified elsewhere"
    },
    {
      "name": "L550",
      "description": "Sunburn of first degree"
    },
    {
      "name": "L551",
      "description": "Sunburn of second degree"
    },
    {
      "name": "L552",
      "description": "Sunburn of third degree"
    },
    {
      "name": "L558",
      "description": "Other sunburn"
    },
    {
      "name": "L559",
      "description": "Sunburn, unspecified"
    },
    {
      "name": "L560",
      "description": "Drug phototoxic response"
    },
    {
      "name": "L561",
      "description": "Drug photoallergic response"
    },
    {
      "name": "L562",
      "description": "Photocontact dermatitis [berloque dermatitis]"
    },
    {
      "name": "L563",
      "description": "Solar urticaria"
    },
    {
      "name": "L564",
      "description": "Polymorphous light eruption"
    },
    {
      "name": "L568",
      "description": "Other specified acute skin changes due to ultraviolet radiation"
    },
    {
      "name": "L569",
      "description": "Acute skin change due to ultraviolet radiation, unspecified"
    },
    {
      "name": "L570",
      "description": "Actinic keratosis"
    },
    {
      "name": "L571",
      "description": "Actinic reticuloid"
    },
    {
      "name": "L572",
      "description": "Cutis rhomboidalis nuchae"
    },
    {
      "name": "L573",
      "description": "Poikiloderma of Civatte"
    },
    {
      "name": "L574",
      "description": "Cutis laxa senilis"
    },
    {
      "name": "L575",
      "description": "Actinic granuloma"
    },
    {
      "name": "L576",
      "description": "Chronic actinic dermatitis"
    },
    {
      "name": "L578",
      "description": "Other skin changes due to chronic exposure to nonionizing radiation"
    },
    {
      "name": "L579",
      "description": "Skin changes due to chronic exposure to nonionizing radiation, unspecified"
    },
    {
      "name": "L580",
      "description": "Acute radiodermatitis"
    },
    {
      "name": "L581",
      "description": "Chronic radiodermatitis"
    },
    {
      "name": "L589",
      "description": "Radiodermatitis, unspecified"
    },
    {
      "name": "L590",
      "description": "Erythema ab igne [dermatitis ab igne]"
    },
    {
      "name": "L598",
      "description": "Other specified disorders of skin and subcutaneous tissue related to radiation"
    },
    {
      "name": "L599",
      "description": "Disorder fo skin and subcutaneous tissue related to radiation, unspecified"
    },
    {
      "name": "L600",
      "description": "Ingrowing nail"
    },
    {
      "name": "L601",
      "description": "Onycholysis"
    },
    {
      "name": "L602",
      "description": "Onychogryphosis"
    },
    {
      "name": "L603",
      "description": "Nail dystrophy"
    },
    {
      "name": "L604",
      "description": "Beau's lines"
    },
    {
      "name": "L605",
      "description": "Yellow nail syndrome"
    },
    {
      "name": "L608",
      "description": "Other nail disorders"
    },
    {
      "name": "L609",
      "description": "Nail disorder, unspecified"
    },
    {
      "name": "L620",
      "description": "Clubbed nail pachydermoperiostosis (M89.4*]"
    },
    {
      "name": "L628",
      "description": "Nail disorders in other diseases classified elsewhere"
    },
    {
      "name": "L630",
      "description": "Alopecia (capitis) totalis"
    },
    {
      "name": "L631",
      "description": "Alopecia universalis"
    },
    {
      "name": "L632",
      "description": "Ophiasis"
    },
    {
      "name": "L638",
      "description": "Other alopecia areata"
    },
    {
      "name": "L639",
      "description": "Alopecia areata, unspciefied"
    },
    {
      "name": "L640",
      "description": "Drug-induced androgenic alopecia"
    },
    {
      "name": "L648",
      "description": "Other androgenic alopecia"
    },
    {
      "name": "L649",
      "description": "Androgenic alopecia, unspecified"
    },
    {
      "name": "L650",
      "description": "Telogen effluvium"
    },
    {
      "name": "L651",
      "description": "Anagen effluvium"
    },
    {
      "name": "L652",
      "description": "Alopecia mucinosa"
    },
    {
      "name": "L658",
      "description": "Other specified nonscarring hair loss"
    },
    {
      "name": "L659",
      "description": "Nonscarring hair loss, unspecified"
    },
    {
      "name": "L660",
      "description": "Pseudopelade"
    },
    {
      "name": "L661",
      "description": "Lichen planopilaris"
    },
    {
      "name": "L662",
      "description": "Folliculitis decalvans"
    },
    {
      "name": "L663",
      "description": "Perifolliculitis capitis abscedens"
    },
    {
      "name": "L664",
      "description": "Folliculitis ulerythematosa reticulata"
    },
    {
      "name": "L668",
      "description": "Other cicatrical alopecia"
    },
    {
      "name": "L669",
      "description": "Cicatricial alopecia, unspecified"
    },
    {
      "name": "L670",
      "description": "Trichorrhexis nodosa"
    },
    {
      "name": "L671",
      "description": "Variations in hair colour"
    },
    {
      "name": "L678",
      "description": "Other hair color and hair shaft abnormalities"
    },
    {
      "name": "L679",
      "description": "Hair colour and hair shaft abnormality, unspecified"
    },
    {
      "name": "L680",
      "description": "Hirsutism"
    },
    {
      "name": "L681",
      "description": "Acquired hypertrichosis lanuginosa"
    },
    {
      "name": "L682",
      "description": "Localized hypertrichosis"
    },
    {
      "name": "L683",
      "description": "Polytrichia"
    },
    {
      "name": "L688",
      "description": "Other hypertrichosis"
    },
    {
      "name": "L689",
      "description": "Hypertrichosis, unspecified"
    },
    {
      "name": "L700",
      "description": "Acne vulgaris"
    },
    {
      "name": "L701",
      "description": "Acne conglobata"
    },
    {
      "name": "L702",
      "description": "Acne varioliformis"
    },
    {
      "name": "L703",
      "description": "Acne tropica"
    },
    {
      "name": "L704",
      "description": "Infantile acne"
    },
    {
      "name": "L705",
      "description": "Acné excoriée"
    },
    {
      "name": "L7080",
      "description": "Acne cosmetica"
    },
    {
      "name": "L7081",
      "description": "Acne scar"
    },
    {
      "name": "L7082",
      "description": "Steroid acne"
    },
    {
      "name": "L7083",
      "description": "Acne fulminans"
    },
    {
      "name": "L7088",
      "description": "Other acne"
    },
    {
      "name": "L709",
      "description": "Acne, unspecified"
    },
    {
      "name": "L710",
      "description": "Perioral dermatitis"
    },
    {
      "name": "L711",
      "description": "Rhinophyma"
    },
    {
      "name": "L718",
      "description": "Other rosacea"
    },
    {
      "name": "L719",
      "description": "Rosacea, unspecified"
    },
    {
      "name": "L720",
      "description": "Epidermal cyst"
    },
    {
      "name": "L721",
      "description": "Trichilemmal cyst"
    },
    {
      "name": "L722",
      "description": "Steatocystoma multiplex"
    },
    {
      "name": "L728",
      "description": "Other follicular cysts of skin and subcutaneous tissue"
    },
    {
      "name": "L729",
      "description": "Follicular cyst of skin and subcutaneous tissue, unspecified"
    },
    {
      "name": "L730",
      "description": "Acne keloid"
    },
    {
      "name": "L731",
      "description": "Pseudofolliculitis barbae"
    },
    {
      "name": "L732",
      "description": "Hidradenitis suppurativa"
    },
    {
      "name": "L738",
      "description": "Other specified follicular disorders"
    },
    {
      "name": "L739",
      "description": "Follicular disorder, unspecified"
    },
    {
      "name": "L740",
      "description": "Miliaria rubra"
    },
    {
      "name": "L741",
      "description": "Miliaria crystallina"
    },
    {
      "name": "L742",
      "description": "Miliaria profunda"
    },
    {
      "name": "L743",
      "description": "Miliaria, unspecified"
    },
    {
      "name": "L744",
      "description": "Anhidrosis"
    },
    {
      "name": "L748",
      "description": "Other eccrine sweat disorders"
    },
    {
      "name": "L749",
      "description": "Eccrine sweat disorder, unspecified"
    },
    {
      "name": "L750",
      "description": "Bromhidrosis"
    },
    {
      "name": "L751",
      "description": "Chromhidrosis"
    },
    {
      "name": "L752",
      "description": "Apocrine miliaria"
    },
    {
      "name": "L758",
      "description": "Other apocrine sweat disorders"
    },
    {
      "name": "L759",
      "description": "Apocrine sweat disorder, unspecified"
    },
    {
      "name": "L80",
      "description": "Vitiligo"
    },
    {
      "name": "L810",
      "description": "Postinflammatory hyperpigmentation"
    },
    {
      "name": "L811",
      "description": "Chloasma"
    },
    {
      "name": "L812",
      "description": "Freckles"
    },
    {
      "name": "L813",
      "description": "Cafe au lait spots"
    },
    {
      "name": "L814",
      "description": "Other melanin hyperpigmentation"
    },
    {
      "name": "L815",
      "description": "Leukoderma, not elsewhere classified"
    },
    {
      "name": "L816",
      "description": "Other disorders of diminished melanin formation"
    },
    {
      "name": "L817",
      "description": "Pigmented purpuric dermatosis"
    },
    {
      "name": "L8180",
      "description": "Nevus fuscocaeruleus (Nevus of  Hori)"
    },
    {
      "name": "L8181",
      "description": "Ochronosis, pigmented colloid milium"
    },
    {
      "name": "L8182",
      "description": "Cosmetic tattoo"
    },
    {
      "name": "L8183",
      "description": "Traumatic tattoo"
    },
    {
      "name": "L8188",
      "description": "Other specified disorders of pigmentation"
    },
    {
      "name": "L819",
      "description": "Disorder of pigmentation, unspecified"
    },
    {
      "name": "L82",
      "description": "Seborrhoeic keratosis"
    },
    {
      "name": "L83",
      "description": "Acanthosis nigricans"
    },
    {
      "name": "L84",
      "description": "Corns and callosities"
    },
    {
      "name": "L850",
      "description": "Acquired ichthyosis"
    },
    {
      "name": "L851",
      "description": "Acquired keratosis [keratoderma] palmaris et plantaris"
    },
    {
      "name": "L852",
      "description": "Keratosis punctata (palmaris et plantaris )"
    },
    {
      "name": "L853",
      "description": "Xerosis cutis"
    },
    {
      "name": "L858",
      "description": "Other specified epidermal thickening"
    },
    {
      "name": "L859",
      "description": "Epidermal thickening, unspecified"
    },
    {
      "name": "L86",
      "description": "Keratoderma in diseases classified elsewhere"
    },
    {
      "name": "L870",
      "description": "Keratosis follicularis et parafollicularis in cutem penetrans [Kyrle]"
    },
    {
      "name": "L871",
      "description": "Reactive perforating collagenosis"
    },
    {
      "name": "L872",
      "description": "Elastosis perforans serpiginosa"
    },
    {
      "name": "L873",
      "description": "Acquired perforans disorders"
    },
    {
      "name": "L878",
      "description": "Other transepidermal elimination disorders"
    },
    {
      "name": "L879",
      "description": "Transepidermal elimination disorder, unspecified"
    },
    {
      "name": "L88",
      "description": "Pyoderma gangrenosum"
    },
    {
      "name": "L890",
      "description": "Stage I decubitus ulcer and pressure area"
    },
    {
      "name": "L891",
      "description": "Stage II decubitus ulcer"
    },
    {
      "name": "L892",
      "description": "Stage III decubitus ulcer"
    },
    {
      "name": "L893",
      "description": "Stage IV decubitus ulcer"
    },
    {
      "name": "L899",
      "description": "Decubitus ulcer and pressure area, unspecified"
    },
    {
      "name": "L900",
      "description": "Lichen sclerosus et atrophicus"
    },
    {
      "name": "L901",
      "description": "Anetoderma of Schweninger-Buzzi"
    },
    {
      "name": "L902",
      "description": "Anetoderma of Jadassohn-Pellizzari"
    },
    {
      "name": "L903",
      "description": "Atrophoderma of Pasini and Pierini"
    },
    {
      "name": "L904",
      "description": "Acrodermatitis chronica atrophicans"
    },
    {
      "name": "L905",
      "description": "Scar conditions and fibrosis of skin"
    },
    {
      "name": "L906",
      "description": "Striae atrophicae"
    },
    {
      "name": "L908",
      "description": "Other atrophic disorders of skin"
    },
    {
      "name": "L909",
      "description": "Atrophic disorder of skin, unspecified"
    },
    {
      "name": "L910",
      "description": "Keloid scar"
    },
    {
      "name": "L918",
      "description": "Other hypertrophic disorders of skin"
    },
    {
      "name": "L919",
      "description": "Hypertrophic disorder of skin, unspecified"
    },
    {
      "name": "L920",
      "description": "Granuloma annulare"
    },
    {
      "name": "L921",
      "description": "Necrobiosis lipoidica, not elsewhere classified"
    },
    {
      "name": "L922",
      "description": "Granuloma faciale [eosinophilic granuloma of skin]"
    },
    {
      "name": "L923",
      "description": "Foreign body granuloma of skin and subcutaneous tissue"
    },
    {
      "name": "L928",
      "description": "Other granulomatous disorders of skin and subcutaneous tissue"
    },
    {
      "name": "L929",
      "description": "Granulomatous disorder of skin and subcutaneous tissue, unspecified"
    },
    {
      "name": "L930",
      "description": "Discoid lupus erythematosus"
    },
    {
      "name": "L931",
      "description": "Subacute cutaneous lupus erythematosus"
    },
    {
      "name": "L932",
      "description": "Lupus erythematosus profundus"
    },
    {
      "name": "L933",
      "description": "Acute cutaneous lupus erythematosus"
    },
    {
      "name": "L934",
      "description": "Bullous lupus erythematosus"
    },
    {
      "name": "L938",
      "description": "Other localized cutaneous lupus erythematosus"
    },
    {
      "name": "L939",
      "description": "Localized cutaneous lupus erythematosus, unspecified"
    },
    {
      "name": "L940",
      "description": "Localized scleroderma [morphea]"
    },
    {
      "name": "L941",
      "description": "Linear scleroderma"
    },
    {
      "name": "L942",
      "description": "Calcinosis cutis"
    },
    {
      "name": "L943",
      "description": "Sclerodactyly"
    },
    {
      "name": "L944",
      "description": "Gottron's papules"
    },
    {
      "name": "L945",
      "description": "Poikiloderma vasculare atrophicans"
    },
    {
      "name": "L946",
      "description": "Ainhum"
    },
    {
      "name": "L948",
      "description": "Other specified localized connective tissue disorders"
    },
    {
      "name": "L949",
      "description": "Localized connective tissue disorder, unspecified"
    },
    {
      "name": "L950",
      "description": "Livedoid vasculitis"
    },
    {
      "name": "L951",
      "description": "Erythema elevatum diutinum"
    },
    {
      "name": "L958",
      "description": "Other vasculitis limited to skin"
    },
    {
      "name": "L959",
      "description": "Vasculitis limited to skin, unspecified"
    },
    {
      "name": "L97",
      "description": "Ulcer of lower limb, not elsewhere classified"
    },
    {
      "name": "L980",
      "description": "Pyogenic granuloma"
    },
    {
      "name": "L981",
      "description": "Factitial dermatitis"
    },
    {
      "name": "L982",
      "description": "Ferbrile neutrophilic dermatosis [Sweet]"
    },
    {
      "name": "L983",
      "description": "Eosinophilic cellulitis [Wells]"
    },
    {
      "name": "L984",
      "description": "Chronic ulcer of skin, not elsewhere classified"
    },
    {
      "name": "L985",
      "description": "Mucinosis of skin"
    },
    {
      "name": "L986",
      "description": "Other infiltrative disorders of skin and subcutaneous tissue"
    },
    {
      "name": "L987",
      "description": "Excessive and redundant skin and subcutaneous tissue"
    },
    {
      "name": "L988",
      "description": "Other specified disorders of skin and subcutaneous tissue"
    },
    {
      "name": "L989",
      "description": "Disorder of skin and subcutaneous tissue, unspecified"
    },
    {
      "name": "L990",
      "description": "Amyloidosis of skin (E85.-*)"
    },
    {
      "name": "L998",
      "description": "Other specified disorders of skin and subcutaneous tissue in diseases classified elsewhere"
    },
    {
      "name": "M000",
      "description": "Staphylococcal arthritis and polyarthritis"
    },
    {
      "name": "M0000",
      "description": "Staphylococcal arthritis and polyarthritis Multiple sites"
    },
    {
      "name": "M0001",
      "description": "Staphylococcal arthritis and polyarthritis Shoulder region"
    },
    {
      "name": "M0002",
      "description": "Staphylococcal arthritis and polyarthritis Upper arm"
    },
    {
      "name": "M0003",
      "description": "Staphylococcal arthritis and polyarthritis Forearm"
    },
    {
      "name": "M0004",
      "description": "Staphylococcal arthritis and polyarthritis Hand"
    },
    {
      "name": "M0005",
      "description": "Staphylococcal arthritis and polyarthritis Pelvic region and thigh"
    },
    {
      "name": "M0006",
      "description": "Staphylococcal arthritis and polyarthritis Lower leg"
    },
    {
      "name": "M0007",
      "description": "Staphylococcal arthritis and polyarthritis Ankle and foot"
    },
    {
      "name": "M0008",
      "description": "Staphylococcal arthritis and polyarthritis Other "
    },
    {
      "name": "M0009",
      "description": "Staphylococcal arthritis and polyarthritis Site unspecified"
    },
    {
      "name": "M001",
      "description": "Pneumococcal arthritis and polyarthritis"
    },
    {
      "name": "M0010",
      "description": "Pneumococcal arthritis and polyarthritisMultiple sites"
    },
    {
      "name": "M0011",
      "description": "Pneumococcal arthritis and polyarthritis Shoulder region"
    },
    {
      "name": "M0012",
      "description": "Pneumococcal arthritis and polyarthritis Upper arm"
    },
    {
      "name": "M0013",
      "description": "Pneumococcal arthritis and polyarthritis Forearm"
    },
    {
      "name": "M0014",
      "description": "Pneumococcal arthritis and polyarthritis Hand"
    },
    {
      "name": "M0015",
      "description": "Pneumococcal arthritis and polyarthritis Pelvic region and thigh"
    },
    {
      "name": "M0016",
      "description": "Pneumococcal arthritis and polyarthritis Lower leg"
    },
    {
      "name": "M0017",
      "description": "Pneumococcal arthritis and polyarthritis Ankle and foot"
    },
    {
      "name": "M0018",
      "description": "Pneumococcal arthritis and polyarthritis Other"
    },
    {
      "name": "M0019",
      "description": "Pneumococcal arthritis and polyarthritis Site unspecified"
    },
    {
      "name": "M002",
      "description": "Other Streptococcal arthritis and polyarthritis"
    },
    {
      "name": "M0020",
      "description": "Other Streptococcal arthritis and polyarthritis Multiple sites"
    },
    {
      "name": "M0021",
      "description": "Other Streptococcal arthritis and polyarthritis Shoulder region"
    },
    {
      "name": "M0022",
      "description": "Other Streptococcal arthritis and polyarthritis upper arm"
    },
    {
      "name": "M0023",
      "description": "Other Streptococcal arthritis and polyarthritis Forearm"
    },
    {
      "name": "M0024",
      "description": "Other Streptococcal arthritis and polyarthritis Hand"
    },
    {
      "name": "M0025",
      "description": "Other Streptococcal arthritis and polyarthritis Pelvic region and thigh"
    },
    {
      "name": "M0026",
      "description": "Other Streptococcal arthritis and polyarthritis Lower leg"
    },
    {
      "name": "M0027",
      "description": "Other Streptococcal arthritis and polyarthritis Ankle and foot"
    },
    {
      "name": "M0028",
      "description": "Other Streptococcal arthritis and polyarthritis Other"
    },
    {
      "name": "M0029",
      "description": "Other Streptococcal arthritis and polyarthritis Site unspecified"
    },
    {
      "name": "M008",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents"
    },
    {
      "name": "M0080",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Multiple sites"
    },
    {
      "name": "M0081",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Shoulder region"
    },
    {
      "name": "M0082",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Upper arm"
    },
    {
      "name": "M0083",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Fore arm"
    },
    {
      "name": "M0084",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Hand"
    },
    {
      "name": "M0085",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Pelvic region and thigh"
    },
    {
      "name": "M0086",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Lower leg"
    },
    {
      "name": "M0087",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Ankle and foot"
    },
    {
      "name": "M0088",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Other"
    },
    {
      "name": "M0089",
      "description": "Arthritis and polyarthritis due to other specified bacterial agents Site unspecified"
    },
    {
      "name": "M009",
      "description": "Pyogenic arthritis, unspecified"
    },
    {
      "name": "M0090",
      "description": "Pyogenic arthritis, unspecified Multiple sites"
    },
    {
      "name": "M0091",
      "description": "Pyogenic arthritis, unspecified Shoulder region"
    },
    {
      "name": "M0092",
      "description": "Pyogenic arthritis, unspecified Upper arm"
    },
    {
      "name": "M0093",
      "description": "Pyogenic arthritis, unspecified Forearm"
    },
    {
      "name": "M0094",
      "description": "Pyogenic arthritis, unspecified Hand"
    },
    {
      "name": "M0095",
      "description": "Pyogenic arthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M0096",
      "description": "Pyogenic arthritis, unspecified Lower leg"
    },
    {
      "name": "M0097",
      "description": "Pyogenic arthritis, unspecified Ankle and foot"
    },
    {
      "name": "M0098",
      "description": "Pyogenic arthritis, unspecified Other"
    },
    {
      "name": "M0099",
      "description": "Pyogenic arthritis, unspecified Site unspecified"
    },
    {
      "name": "M010",
      "description": "Meningococcal arthritis (A39.8*)"
    },
    {
      "name": "M0100",
      "description": "Meningococcal arthritis ( A39.8*) Multiple sites"
    },
    {
      "name": "M0101",
      "description": "Meningococcal arthritis ( A39.8*) Shoulder region"
    },
    {
      "name": "M0102",
      "description": "Meningococcal arthritis ( A39.8*) Upper arm"
    },
    {
      "name": "M0103",
      "description": "Meningococcal arthritis ( A39.8*) Forearm"
    },
    {
      "name": "M0104",
      "description": "Meningococcal arthritis ( A39.8*) Hand"
    },
    {
      "name": "M0105",
      "description": "Meningococcal arthritis ( A39.8*) Pelvic region and thigh"
    },
    {
      "name": "M0106",
      "description": "Meningococcal arthritis ( A39.8*) Lower leg"
    },
    {
      "name": "M0107",
      "description": "Meningococcal arthritis ( A39.8*) Ankle and foot"
    },
    {
      "name": "M0108",
      "description": "Meningococcal arthritis ( A39.8*) Other"
    },
    {
      "name": "M0109",
      "description": "Meningococcal arthritis ( A39.8*) Site unspecified"
    },
    {
      "name": "M011",
      "description": "Tuberculous arthritis ( A18.0*)"
    },
    {
      "name": "M0110",
      "description": "Tuberculous arthritis ( A18.0*) Multiple sites"
    },
    {
      "name": "M0111",
      "description": "Tuberculous arthritis ( A18.0*) Shoulder region"
    },
    {
      "name": "M0112",
      "description": "Tuberculous arthritis ( A18.0*) Upper arm"
    },
    {
      "name": "M0113",
      "description": "Tuberculous arthritis ( A18.0*) Forearm"
    },
    {
      "name": "M0114",
      "description": "Tuberculous arthritis ( A18.0*) Hand"
    },
    {
      "name": "M0115",
      "description": "Tuberculous arthritis ( A18.0*) Pelvic region and thigh"
    },
    {
      "name": "M0116",
      "description": "Tuberculous arthritis ( A18.0*) Lower leg"
    },
    {
      "name": "M0117",
      "description": "Tuberculous arthritis ( A18.0*) Ankle and foot"
    },
    {
      "name": "M0118",
      "description": "Tuberculous arthritis ( A18.0*) Other"
    },
    {
      "name": "M0119",
      "description": "Tuberculous arthritis ( A18.0*) Site unspecified"
    },
    {
      "name": "M012",
      "description": "Arthritis in Lyme disease ( A69.2*)"
    },
    {
      "name": "M0120",
      "description": "Arthritis in Lyme disease ( A69.2*) Multiple sites"
    },
    {
      "name": "M0121",
      "description": "Arthritis in Lyme disease ( A69.2*) Shoulder region"
    },
    {
      "name": "M0122",
      "description": "Arthritis in Lyme disease ( A69.2*) Upper arm"
    },
    {
      "name": "M0123",
      "description": "Arthritis in Lyme disease ( A69.2*) Forearm"
    },
    {
      "name": "M0124",
      "description": "Arthritis in Lyme disease ( A69.2*) Hand"
    },
    {
      "name": "M0125",
      "description": "Arthritis in Lyme disease ( A69.2*) Pelvic region and thigh"
    },
    {
      "name": "M0126",
      "description": "Arthritis in Lyme disease ( A69.2*) Lower leg"
    },
    {
      "name": "M0127",
      "description": "Arthritis in Lyme disease ( A69.2*) Ankle and foot"
    },
    {
      "name": "M0128",
      "description": "Arthritis in Lyme disease ( A69.2*) Other"
    },
    {
      "name": "M0129",
      "description": "Arthritis in Lyme disease ( A69.2*) Site unspecified"
    },
    {
      "name": "M013",
      "description": "Arthritis in other bacterial diseases classified elsewhere"
    },
    {
      "name": "M0130",
      "description": "Arthritis in other bacterial diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0131",
      "description": "Arthritis in other bacterial diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0132",
      "description": "Arthritis in other bacterial diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0133",
      "description": "Arthritis in other bacterial diseases classified elsewhere Forearm"
    },
    {
      "name": "M0134",
      "description": "Arthritis in other bacterial diseases classified elsewhere Hand"
    },
    {
      "name": "M0135",
      "description": "Arthritis in other bacterial diseases classified elsewhere Pelvic region ant thigh"
    },
    {
      "name": "M0136",
      "description": "Arthritis in other bacterial diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0137",
      "description": "Arthritis in other bacterial diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0138",
      "description": "Arthritis in other bacterial diseases classified elsewhere Other"
    },
    {
      "name": "M0139",
      "description": "Arthritis in other bacterial diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M014",
      "description": "Rubella arthritis ( B06.8*)"
    },
    {
      "name": "M0140",
      "description": "Rubella arthritis ( B06.8*) Multiple sites"
    },
    {
      "name": "M0141",
      "description": "Rubella arthritis ( B06.8*) Shoulder region"
    },
    {
      "name": "M0142",
      "description": "Rubella arthritis ( B06.8*) Upper arm"
    },
    {
      "name": "M0143",
      "description": "Rubella arthritis ( B06.8*) Forearm"
    },
    {
      "name": "M0144",
      "description": "Rubella arthritis ( B06.8*) Hand"
    },
    {
      "name": "M0145",
      "description": "Rubella arthritis ( B06.8*) Pelvic region and thigh"
    },
    {
      "name": "M0146",
      "description": "Rubella arthritis ( B06.8*) Lower leg"
    },
    {
      "name": "M0147",
      "description": "Rubella arthritis ( B06.8*) Ankle and foot"
    },
    {
      "name": "M0148",
      "description": "Rubella arthritis ( B06.8*) Other"
    },
    {
      "name": "M0149",
      "description": "Rubella arthritis ( B06.8*) Site unspecified"
    },
    {
      "name": "M015",
      "description": "Arthritis in other viral diseases classified elsewhere"
    },
    {
      "name": "M0150",
      "description": "Arthritis in other viral diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0151",
      "description": "Arthritis in other viral diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0152",
      "description": "Arthritis in other viral diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0153",
      "description": "Arthritis in other viral diseases classified elsewhere Forearm"
    },
    {
      "name": "M0154",
      "description": "Arthritis in other viral diseases classified elsewhere Hand"
    },
    {
      "name": "M0155",
      "description": "Arthritis in other viral diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M0156",
      "description": "Arthritis in other viral diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0157",
      "description": "Arthritis in other viral diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0158",
      "description": "Arthritis in other viral diseases classified elsewhere Other"
    },
    {
      "name": "M0159",
      "description": "Arthritis in other viral diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M016",
      "description": "Arthritis in mycoses ( B35-B49*)"
    },
    {
      "name": "M0160",
      "description": "Arthritis in mycoses ( B35-B49*) Multiple sites"
    },
    {
      "name": "M0161",
      "description": "Arthritis in mycoses ( B35-B49*) Shoulder region"
    },
    {
      "name": "M0162",
      "description": "Arthritis in mycoses ( B35-B49*) Upper arm"
    },
    {
      "name": "M0163",
      "description": "Arthritis in mycoses ( B35-B49*) Forearm"
    },
    {
      "name": "M0164",
      "description": "Arthritis in mycoses ( B35-B49*) Hand"
    },
    {
      "name": "M0165",
      "description": "Arthritis in mycoses ( B35-B49*) Pelvic region and thigh"
    },
    {
      "name": "M0166",
      "description": "Arthritis in mycoses ( B35-B49*) Lower leg"
    },
    {
      "name": "M0167",
      "description": "Arthritis in mycoses ( B35-B49*) Ankle and foot"
    },
    {
      "name": "M0168",
      "description": "Arthritis in mycoses ( B35-B49*) Other"
    },
    {
      "name": "M0169",
      "description": "Arthritis in mycoses ( B35-B49*) Site unspecified"
    },
    {
      "name": "M018",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "M0180",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0181",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0182",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0183",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Fore arm"
    },
    {
      "name": "M0184",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Hand"
    },
    {
      "name": "M0185",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Pelvic region"
    },
    {
      "name": "M0186",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0187",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0188",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Other "
    },
    {
      "name": "M0189",
      "description": "Arthritis in other infectious and parasitic diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M020",
      "description": "Arthopathy following intestinal bypass"
    },
    {
      "name": "M0200",
      "description": "Arthopathy following intestinal bypass Multiple sites"
    },
    {
      "name": "M0201",
      "description": "Arthopathy following intestinal bypass Shoulder region "
    },
    {
      "name": "M0202",
      "description": "Arthopathy following intestinal bypass Upper arm"
    },
    {
      "name": "M0203",
      "description": "Arthopathy following intestinal bypass Forearm"
    },
    {
      "name": "M0204",
      "description": "Arthopathy following intestinal bypass Hand"
    },
    {
      "name": "M0205",
      "description": "Arthopathy following intestinal bypass Pelvic region"
    },
    {
      "name": "M0206",
      "description": "Arthopathy following intestinal bypass Lower leg"
    },
    {
      "name": "M0207",
      "description": "Arthopathy following intestinal bypass Ankle and foot"
    },
    {
      "name": "M0208",
      "description": "Arthopathy following intestinal bypass Other"
    },
    {
      "name": "M0209",
      "description": "Arthopathy following intestinal bypass Site unspecified"
    },
    {
      "name": "M021",
      "description": "Postdysenteric arthropathy"
    },
    {
      "name": "M0210",
      "description": "Postdysenteric arthropathy Multiple sites"
    },
    {
      "name": "M0211",
      "description": "Postdysenteric arthropathy Shoulder region"
    },
    {
      "name": "M0212",
      "description": "Postdysenteric arthropathy Upper arm"
    },
    {
      "name": "M0213",
      "description": "Postdysenteric arthropathy Forearm"
    },
    {
      "name": "M0214",
      "description": "Postdysenteric arthropathy Hand"
    },
    {
      "name": "M0215",
      "description": "Postdysenteric arthropathy Pelvic region and thigh"
    },
    {
      "name": "M0216",
      "description": "Postdysenteric arthropathy Lower leg"
    },
    {
      "name": "M0217",
      "description": "Postdysenteric arthropathy Ankel and foot"
    },
    {
      "name": "M0218",
      "description": "Postdysenteric arthropathy Other "
    },
    {
      "name": "M0219",
      "description": "Postdysenteric arthropathy Site unspecified"
    },
    {
      "name": "M022",
      "description": "Postimmunization arthropathy"
    },
    {
      "name": "M0220",
      "description": "Postimmunization arthropathy Multiple sites"
    },
    {
      "name": "M0221",
      "description": "Postimmunization arthropathy Shoulder region"
    },
    {
      "name": "M0222",
      "description": "Postimmunization arthropathy Upper arm"
    },
    {
      "name": "M0223",
      "description": "Postimmunization arthropathy Forearm "
    },
    {
      "name": "M0224",
      "description": "Postimmunization arthropathy Hand "
    },
    {
      "name": "M0225",
      "description": "Postimmunization arthropathy Pelvic region and thigh"
    },
    {
      "name": "M0226",
      "description": "Postimmunization arthropathy Lower leg"
    },
    {
      "name": "M0227",
      "description": "Postimmunization arthropathy Ankle and foot"
    },
    {
      "name": "M0228",
      "description": "Postimmunization arthropathy Other "
    },
    {
      "name": "M0229",
      "description": "Postimmunization arthropathy Site unspecified"
    },
    {
      "name": "M023",
      "description": "Reiter's disease"
    },
    {
      "name": "M0230",
      "description": "Reiter's disease Mutiple sites"
    },
    {
      "name": "M0231",
      "description": "Reiter's disease Shoulder region"
    },
    {
      "name": "M0232",
      "description": "Reiter's disease Upper arm"
    },
    {
      "name": "M0233",
      "description": "Reiter's disease Forearm"
    },
    {
      "name": "M0234",
      "description": "Reiter's disease Hand"
    },
    {
      "name": "M0235",
      "description": "Reiter's disease Pelvic region and thigh"
    },
    {
      "name": "M0236",
      "description": "Reiter's disease Lower leg"
    },
    {
      "name": "M0237",
      "description": "Reiter's disease Ankle and foot"
    },
    {
      "name": "M0238",
      "description": "Reiter's disease Other "
    },
    {
      "name": "M0239",
      "description": "Reiter's disease Site unspecified"
    },
    {
      "name": "M028",
      "description": "Other reactive arthopathies"
    },
    {
      "name": "M0280",
      "description": "Other reactive arthopathies Multiple sites"
    },
    {
      "name": "M0281",
      "description": "Other reactive arthopathies Shoulder region"
    },
    {
      "name": "M0282",
      "description": "Other reactive arthopathies Upper arm"
    },
    {
      "name": "M0283",
      "description": "Other reactive arthopathies Forearm"
    },
    {
      "name": "M0284",
      "description": "Other reactive arthopathies Hand"
    },
    {
      "name": "M0285",
      "description": "Other reactive arthopathies Pelvic region and thigh"
    },
    {
      "name": "M0286",
      "description": "Other reactive arthopathies Lower leg"
    },
    {
      "name": "M0287",
      "description": "Other reactive arthopathies Ankle and foot"
    },
    {
      "name": "M0288",
      "description": "Other reactive arthopathies Other "
    },
    {
      "name": "M0289",
      "description": "Other reactive arthopathies Site unspecified"
    },
    {
      "name": "M029",
      "description": "Reactive arthopathy, unspecified"
    },
    {
      "name": "M0290",
      "description": "Reactive arthopathy, unspecified Multiple sites"
    },
    {
      "name": "M0291",
      "description": "Reactive arthopathy, unspecified Shoulder region"
    },
    {
      "name": "M0292",
      "description": "Reactive arthopathy, unspecified Upper arm"
    },
    {
      "name": "M0293",
      "description": "Reactive arthopathy, unspecified Forearm"
    },
    {
      "name": "M0294",
      "description": "Reactive arthopathy, unspecified Hand"
    },
    {
      "name": "M0295",
      "description": "Reactive arthopathy, unspecified Pelvic region and thigh"
    },
    {
      "name": "M0296",
      "description": "Reactive arthopathy, unspecified Lower leg"
    },
    {
      "name": "M0297",
      "description": "Reactive arthopathy, unspecified Ankle and foot"
    },
    {
      "name": "M0298",
      "description": "Reactive arthopathy, unspecified Other "
    },
    {
      "name": "M0299",
      "description": "Reactive arthopathy, unspecified Site unspecified"
    },
    {
      "name": "M030",
      "description": "Postmeningococcal arthritis ( A39.8 )"
    },
    {
      "name": "M0300",
      "description": "Postmeningococcal arthritis ( A39.8 ) Multiple sites"
    },
    {
      "name": "M0301",
      "description": "Postmeningococcal arthritis ( A39.8 ) Shoulder region"
    },
    {
      "name": "M0302",
      "description": "Postmeningococcal arthritis ( A39.8 ) Upper arm"
    },
    {
      "name": "M0303",
      "description": "Postmeningococcal arthritis ( A39.8 ) Forearm"
    },
    {
      "name": "M0304",
      "description": "Postmeningococcal arthritis ( A39.8 ) Hand"
    },
    {
      "name": "M0305",
      "description": "Postmeningococcal arthritis ( A39.8 ) Pelvic region and thigh"
    },
    {
      "name": "M0306",
      "description": "Postmeningococcal arthritis ( A39.8 ) Lower leg"
    },
    {
      "name": "M0307",
      "description": "Postmeningococcal arthritis ( A39.8 ) Ankle and foot"
    },
    {
      "name": "M0308",
      "description": "Postmeningococcal arthritis ( A39.8 ) Other"
    },
    {
      "name": "M0309",
      "description": "Postmeningococcal arthritis ( A39.8 ) Site unspecified"
    },
    {
      "name": "M031",
      "description": "Postinfective arthopathy in syphilis"
    },
    {
      "name": "M0310",
      "description": "Postinfective arthopathy in syphilis Multiple sites"
    },
    {
      "name": "M0311",
      "description": "Postinfective arthopathy in syphilis Shoulder region"
    },
    {
      "name": "M0312",
      "description": "Postinfective arthopathy in syphilis Upper arm"
    },
    {
      "name": "M0313",
      "description": "Postinfective arthopathy in syphilis Forearm"
    },
    {
      "name": "M0314",
      "description": "Postinfective arthopathy in syphilis Hand"
    },
    {
      "name": "M0315",
      "description": "Postinfective arthopathy in syphilis Pelvic region and thigh"
    },
    {
      "name": "M0316",
      "description": "Postinfective arthopathy in syphilis Lower leg"
    },
    {
      "name": "M0317",
      "description": "Postinfective arthopathy in syphilis Ankle and foot"
    },
    {
      "name": "M0318",
      "description": "Postinfective arthopathy in syphilis Other "
    },
    {
      "name": "M0319",
      "description": "Postinfective arthopathy in syphilis Site unspecified"
    },
    {
      "name": "M032",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere"
    },
    {
      "name": "M0320",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0321",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0322",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0323",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Forearm"
    },
    {
      "name": "M0324",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Hand"
    },
    {
      "name": "M0325",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M0326",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0327",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0328",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Other "
    },
    {
      "name": "M0329",
      "description": "Other postinfectious arthopathies in diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M036",
      "description": "Reactive arthopathy in other diseases classified elsewhere"
    },
    {
      "name": "M0360",
      "description": "Reactive arthopathy in other diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0361",
      "description": "Reactive arthopathy in other diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0362",
      "description": "Reactive arthopathy in other diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0363",
      "description": "Reactive arthopathy in other diseases classified elsewhere Forearm"
    },
    {
      "name": "M0364",
      "description": "Reactive arthopathy in other diseases classified elsewhere Hand"
    },
    {
      "name": "M0365",
      "description": "Reactive arthopathy in other diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M0366",
      "description": "Reactive arthopathy in other diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0367",
      "description": "Reactive arthopathy in other diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0368",
      "description": "Reactive arthopathy in other diseases classified elsewhere Other "
    },
    {
      "name": "M0369",
      "description": "Reactive arthopathy in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M050",
      "description": "Felty's syndrome"
    },
    {
      "name": "M0500",
      "description": "Felty's syndrome Multiple sites"
    },
    {
      "name": "M0501",
      "description": "Felty's syndrome Shoulder region "
    },
    {
      "name": "M0502",
      "description": "Felty's syndrome Upper arm"
    },
    {
      "name": "M0503",
      "description": "Felty's syndrome Forearm"
    },
    {
      "name": "M0504",
      "description": "Felty's syndrome Hand "
    },
    {
      "name": "M0505",
      "description": "Felty's syndrome Pelvic region and thigh"
    },
    {
      "name": "M0506",
      "description": "Felty's syndrome Lower leg"
    },
    {
      "name": "M0507",
      "description": "Felty's syndrome Ankle and foot"
    },
    {
      "name": "M0508",
      "description": "Felty's syndrome Other "
    },
    {
      "name": "M0509",
      "description": "Felty's syndrome Site unspecified"
    },
    {
      "name": "M051",
      "description": "Rheumatoid lung disease ( J99.0 )"
    },
    {
      "name": "M0510",
      "description": "Rheumatoid lung disease ( J99.0 ) Multiple sites"
    },
    {
      "name": "M0511",
      "description": "Rheumatoid lung disease ( J99.0 ) Shoulder region"
    },
    {
      "name": "M0512",
      "description": "Rheumatoid lung disease ( J99.0 ) Upper arm"
    },
    {
      "name": "M0513",
      "description": "Rheumatoid lung disease ( J99.0 ) Forearm"
    },
    {
      "name": "M0514",
      "description": "Rheumatoid lung disease ( J99.0 ) Hand"
    },
    {
      "name": "M0515",
      "description": "Rheumatoid lung disease ( J99.0 ) Pelvic region and thigh"
    },
    {
      "name": "M0516",
      "description": "Rheumatoid lung disease ( J99.0 ) Lower leg"
    },
    {
      "name": "M0517",
      "description": "Rheumatoid lung disease ( J99.0 ) Ankle and foot"
    },
    {
      "name": "M0518",
      "description": "Rheumatoid lung disease ( J99.0 ) Other"
    },
    {
      "name": "M0519",
      "description": "Rheumatoid lung disease ( J99.0 ) Site unspecified"
    },
    {
      "name": "M052",
      "description": "Rheumatoid vasculitis"
    },
    {
      "name": "M0520",
      "description": "Rheumatoid vasculitis Multiple sites"
    },
    {
      "name": "M0521",
      "description": "Rheumatoid vasculitis Shoulder region"
    },
    {
      "name": "M0522",
      "description": "Rheumatoid vasculitis Upper arm"
    },
    {
      "name": "M0523",
      "description": "Rheumatoid vasculitis Forearm"
    },
    {
      "name": "M0524",
      "description": "Rheumatoid vasculitis Hand"
    },
    {
      "name": "M0525",
      "description": "Rheumatoid vasculitis Pelvic region and thigh"
    },
    {
      "name": "M0526",
      "description": "Rheumatoid vasculitis Lower leg"
    },
    {
      "name": "M0527",
      "description": "Rheumatoid vasculitis Ankle and foot"
    },
    {
      "name": "M0528",
      "description": "Rheumatoid vasculitis Other "
    },
    {
      "name": "M0529",
      "description": "Rheumatoid vasculitis Site unspecified"
    },
    {
      "name": "M053",
      "description": "Rheumatoid arthritis with involvement of other organs and system"
    },
    {
      "name": "M0530",
      "description": "Rheumatoid arthritis with involvement of other organs and system Multiple sites"
    },
    {
      "name": "M0531",
      "description": "Rheumatoid arthritis with involvement of other organs and system Shoulder region"
    },
    {
      "name": "M0532",
      "description": "Rheumatoid arthritis with involvement of other organs and system Upper arm"
    },
    {
      "name": "M0533",
      "description": "Rheumatoid arthritis with involvement of other organs and system Forearm"
    },
    {
      "name": "M0534",
      "description": "Rheumatoid arthritis with involvement of other organs and system Hand"
    },
    {
      "name": "M0535",
      "description": "Rheumatoid arthritis with involvement of other organs and system Pelvic region and thigh"
    },
    {
      "name": "M0536",
      "description": "Rheumatoid arthritis with involvement of other organs and system Lower leg"
    },
    {
      "name": "M0537",
      "description": "Rheumatoid arthritis with involvement of other organs and system Ankle and foot"
    },
    {
      "name": "M0538",
      "description": "Rheumatoid arthritis with involvement of other organs and system Other "
    },
    {
      "name": "M0539",
      "description": "Rheumatoid arthritis with involvement of other organs and system Site unspecified"
    },
    {
      "name": "M058",
      "description": "Other seropositive Rheumatoid arthritis"
    },
    {
      "name": "M0580",
      "description": "Other seropositive Rheumatoid arthritis Multiple sites"
    },
    {
      "name": "M0581",
      "description": "Other seropositive Rheumatoid arthritis Shoulder region"
    },
    {
      "name": "M0582",
      "description": "Other seropositive Rheumatoid arthritis Upper arm"
    },
    {
      "name": "M0583",
      "description": "Other seropositive Rheumatoid arthritis Forearm"
    },
    {
      "name": "M0584",
      "description": "Other seropositive Rheumatoid arthritis Hand"
    },
    {
      "name": "M0585",
      "description": "Other seropositive Rheumatoid arthritis Pelvic region and thigh"
    },
    {
      "name": "M0586",
      "description": "Other seropositive Rheumatoid arthritis Lower leg"
    },
    {
      "name": "M0587",
      "description": "Other seropositive Rheumatoid arthritis Ankle and foot"
    },
    {
      "name": "M0588",
      "description": "Other seropositive Rheumatoid arthritis Other "
    },
    {
      "name": "M0589",
      "description": "Other seropositive Rheumatoid arthritis Site unspecified"
    },
    {
      "name": "M059",
      "description": "Seropositive Rheumatoid arthritis, unspecified"
    },
    {
      "name": "M0590",
      "description": "Seropositive Rheumatoid arthritis, unspecified Multiple sites"
    },
    {
      "name": "M0591",
      "description": "Seropositive Rheumatoid arthritis, unspecified Shoulder region"
    },
    {
      "name": "M0592",
      "description": "Seropositive Rheumatoid arthritis, unspecified Upper arm"
    },
    {
      "name": "M0593",
      "description": "Seropositive Rheumatoid arthritis, unspecified Forearm"
    },
    {
      "name": "M0594",
      "description": "Seropositive Rheumatoid arthritis, unspecified Hand"
    },
    {
      "name": "M0595",
      "description": "Seropositive Rheumatoid arthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M0596",
      "description": "Seropositive Rheumatoid arthritis, unspecified Lower leg"
    },
    {
      "name": "M0597",
      "description": "Seropositive Rheumatoid arthritis, unspecified Ankle and foot"
    },
    {
      "name": "M0598",
      "description": "Seropositive Rheumatoid arthritis, unspecified Other "
    },
    {
      "name": "M0599",
      "description": "Seropositive Rheumatoid arthritis, unspecified Site unspecified"
    },
    {
      "name": "M060",
      "description": "Seronegative Rheumatoid arthritis"
    },
    {
      "name": "M0600",
      "description": "Seronegative Rheumatoid arthritis Multiple sites"
    },
    {
      "name": "M0601",
      "description": "Seronegative Rheumatoid arthritis Shoulder region"
    },
    {
      "name": "M0602",
      "description": "Seronegative Rheumatoid arthritis Upper arm"
    },
    {
      "name": "M0603",
      "description": "Seronegative Rheumatoid arthritis Forearm"
    },
    {
      "name": "M0604",
      "description": "Seronegative Rheumatoid arthritis Hand"
    },
    {
      "name": "M0605",
      "description": "Seronegative Rheumatoid arthritis Pelvic region and thigh"
    },
    {
      "name": "M0606",
      "description": "Seronegative Rheumatoid arthritis Lower leg"
    },
    {
      "name": "M0607",
      "description": "Seronegative Rheumatoid arthritis Ankle and foot"
    },
    {
      "name": "M0608",
      "description": "Seronegative Rheumatoid arthritis Other "
    },
    {
      "name": "M0609",
      "description": "Seronegative Rheumatoid arthritis Site unspecified"
    },
    {
      "name": "M061",
      "description": "Adult-onset Still's disease"
    },
    {
      "name": "M0610",
      "description": "Adult-onset Still's disease Multiple sites"
    },
    {
      "name": "M0611",
      "description": "Adult-onset Still's disease Shoulder region "
    },
    {
      "name": "M0612",
      "description": "Adult-onset Still's disease Upper arm"
    },
    {
      "name": "M0613",
      "description": "Adult-onset Still's disease Forearm"
    },
    {
      "name": "M0614",
      "description": "Adult-onset Still's disease Hand"
    },
    {
      "name": "M0615",
      "description": "Adult-onset Still's disease Pelvic region and thigh"
    },
    {
      "name": "M0616",
      "description": "Adult-onset Still's disease Lower leg"
    },
    {
      "name": "M0617",
      "description": "Adult-onset Still's disease Ankle and foot"
    },
    {
      "name": "M0618",
      "description": "Adult-onset Still's disease Other "
    },
    {
      "name": "M0619",
      "description": "Adult-onset Still's disease Site unspecified"
    },
    {
      "name": "M062",
      "description": "Rheumatoid bursitis"
    },
    {
      "name": "M0620",
      "description": "Rheumatoid bursitis Multiple sites"
    },
    {
      "name": "M0621",
      "description": "Rheumatoid bursitis Shoulder region"
    },
    {
      "name": "M0622",
      "description": "Rheumatoid bursitis Upper arm"
    },
    {
      "name": "M0623",
      "description": "Rheumatoid bursitis Forearm"
    },
    {
      "name": "M0624",
      "description": "Rheumatoid bursitis Hand"
    },
    {
      "name": "M0625",
      "description": "Rheumatoid bursitis Pelvic region and thigh"
    },
    {
      "name": "M0626",
      "description": "Rheumatoid bursitis Lower leg"
    },
    {
      "name": "M0627",
      "description": "Rheumatoid bursitis Ankle and foot"
    },
    {
      "name": "M0628",
      "description": "Rheumatoid bursitis Other "
    },
    {
      "name": "M0629",
      "description": "Rheumatoid bursitis Site unspecified"
    },
    {
      "name": "M063",
      "description": "Rheumatoid nodule"
    },
    {
      "name": "M0630",
      "description": "Rheumatoid nodule Multiple sites"
    },
    {
      "name": "M0631",
      "description": "Rheumatoid nodule Shoulder region "
    },
    {
      "name": "M0632",
      "description": "Rheumatoid nodule Upper arm"
    },
    {
      "name": "M0633",
      "description": "Rheumatoid nodule Forearm"
    },
    {
      "name": "M0634",
      "description": "Rheumatoid nodule Hand"
    },
    {
      "name": "M0635",
      "description": "Rheumatoid nodule Pelvic region and thigh"
    },
    {
      "name": "M0636",
      "description": "Rheumatoid nodule Lower leg"
    },
    {
      "name": "M0637",
      "description": "Rheumatoid nodule Ankle and foot"
    },
    {
      "name": "M0638",
      "description": "Rheumatoid nodule Other "
    },
    {
      "name": "M0639",
      "description": "Rheumatoid nodule Site unspecified"
    },
    {
      "name": "M064",
      "description": "Inflammatory polyarthropathy"
    },
    {
      "name": "M0640",
      "description": "Inflammatory polyarthropathy Multiple sites"
    },
    {
      "name": "M0641",
      "description": "Inflammatory polyarthropathy Shoulder region "
    },
    {
      "name": "M0642",
      "description": "Inflammatory polyarthropathy Upper arm"
    },
    {
      "name": "M0643",
      "description": "Inflammatory polyarthropathy Forearm"
    },
    {
      "name": "M0644",
      "description": "Inflammatory polyarthropathy Hand"
    },
    {
      "name": "M0645",
      "description": "Inflammatory polyarthropathy Pelvic rgion and thigh"
    },
    {
      "name": "M0646",
      "description": "Inflammatory polyarthropathy Lower leg"
    },
    {
      "name": "M0647",
      "description": "Inflammatory polyarthropathy Ankle and foot"
    },
    {
      "name": "M0648",
      "description": "Inflammatory polyarthropathy Other "
    },
    {
      "name": "M0649",
      "description": "Inflammatory polyarthropathy Site unspecified"
    },
    {
      "name": "M068",
      "description": "Other specified Rheumatoid arthritis"
    },
    {
      "name": "M0680",
      "description": "Other specified Rheumatoid arthritis Multiple sites"
    },
    {
      "name": "M0681",
      "description": "Other specified Rheumatoid arthritis Shoulder region"
    },
    {
      "name": "M0682",
      "description": "Other specified Rheumatoid arthritis Upper arm"
    },
    {
      "name": "M0683",
      "description": "Other specified Rheumatoid arthritis Forearm"
    },
    {
      "name": "M0684",
      "description": "Other specified Rheumatoid arthritis Hand"
    },
    {
      "name": "M0685",
      "description": "Other specified Rheumatoid arthritis Pelvic region and thigh"
    },
    {
      "name": "M0686",
      "description": "Other specified Rheumatoid arthritis Lower leg"
    },
    {
      "name": "M0687",
      "description": "Other specified Rheumatoid arthritis Ankle and foot"
    },
    {
      "name": "M0688",
      "description": "Other specified Rheumatoid arthritis Other "
    },
    {
      "name": "M0689",
      "description": "Other specified Rheumatoid arthritis Site unspecified"
    },
    {
      "name": "M069",
      "description": "Rheumatoid arthritis, unspecified"
    },
    {
      "name": "M0690",
      "description": "Rheumatoid arthritis, unspecified Multiple sites"
    },
    {
      "name": "M0691",
      "description": "Rheumatoid arthritis, unspecified Shoulder region "
    },
    {
      "name": "M0692",
      "description": "Rheumatoid arthritis, unspecified Upper arm"
    },
    {
      "name": "M0693",
      "description": "Rheumatoid arthritis, unspecified Forearm"
    },
    {
      "name": "M0694",
      "description": "Rheumatoid arthritis, unspecified Hand"
    },
    {
      "name": "M0695",
      "description": "Rheumatoid arthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M0696",
      "description": "Rheumatoid arthritis, unspecified Lower leg"
    },
    {
      "name": "M0697",
      "description": "Rheumatoid arthritis, unspecified Ankle and foot"
    },
    {
      "name": "M0698",
      "description": "Rheumatoid arthritis, unspecified Other "
    },
    {
      "name": "M0699",
      "description": "Rheumatoid arthritis, unspecified Site unspecified"
    },
    {
      "name": "M070",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 )"
    },
    {
      "name": "M0700",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Multiple sites"
    },
    {
      "name": "M0701",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Shoulder region"
    },
    {
      "name": "M0702",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Upper arm"
    },
    {
      "name": "M0703",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Forearm"
    },
    {
      "name": "M0704",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Hand"
    },
    {
      "name": "M0705",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Pelvic region and thigh"
    },
    {
      "name": "M0706",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Lower leg"
    },
    {
      "name": "M0707",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Ankle and foot"
    },
    {
      "name": "M0708",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Other"
    },
    {
      "name": "M0709",
      "description": "Distal Interphalangal psoriatic arthropathy ( L40.5 ) Site unspecified"
    },
    {
      "name": "M071",
      "description": "Arthritis mutilans ( L40.5 )"
    },
    {
      "name": "M0710",
      "description": "Arthritis mutilans ( L40.5 ) Multiple sites"
    },
    {
      "name": "M0711",
      "description": "Arthritis mutilans ( L40.5 ) Shoulder region"
    },
    {
      "name": "M0712",
      "description": "Arthritis mutilans ( L40.5 ) Upper arm"
    },
    {
      "name": "M0713",
      "description": "Arthritis mutilans ( L40.5 ) Forearm"
    },
    {
      "name": "M0714",
      "description": "Arthritis mutilans ( L40.5 ) Hand"
    },
    {
      "name": "M0715",
      "description": "Arthritis mutilans ( L40.5 ) Pelvic region and thigh"
    },
    {
      "name": "M0716",
      "description": "Arthritis mutilans ( L40.5 ) Lower leg"
    },
    {
      "name": "M0717",
      "description": "Arthritis mutilans ( L40.5 ) Ankle and foot"
    },
    {
      "name": "M0718",
      "description": "Arthritis mutilans ( L40.5 ) Other"
    },
    {
      "name": "M0719",
      "description": "Arthritis mutilans ( L40.5 ) Site unspecified"
    },
    {
      "name": "M072",
      "description": "Psoriatic spondylitis ( L40.5 )"
    },
    {
      "name": "M0720",
      "description": "Psoriatic spondylitis ( L40.5 ) Multiple sites"
    },
    {
      "name": "M0721",
      "description": "Psoriatic spondylitis ( L40.5 ) Shoulder region"
    },
    {
      "name": "M0722",
      "description": "Psoriatic spondylitis ( L40.5 ) Upper arm"
    },
    {
      "name": "M0723",
      "description": "Psoriatic spondylitis ( L40.5 ) Forearm"
    },
    {
      "name": "M0724",
      "description": "Psoriatic spondylitis ( L40.5 ) Hand"
    },
    {
      "name": "M0725",
      "description": "Psoriatic spondylitis ( L40.5 ) Pelvic and region"
    },
    {
      "name": "M0726",
      "description": "Psoriatic spondylitis ( L40.5 ) Lower leg"
    },
    {
      "name": "M0727",
      "description": "Psoriatic spondylitis ( L40.5 ) Ankle and foot"
    },
    {
      "name": "M0728",
      "description": "Psoriatic spondylitis ( L40.5 ) Other"
    },
    {
      "name": "M0729",
      "description": "Psoriatic spondylitis ( L40.5 ) Site unspecified"
    },
    {
      "name": "M073",
      "description": "Other psoriatic  arthropathies ( L40.5 )"
    },
    {
      "name": "M0730",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Multiple sites"
    },
    {
      "name": "M0731",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Shoulder region"
    },
    {
      "name": "M0732",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Upper arm"
    },
    {
      "name": "M0733",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Forearm"
    },
    {
      "name": "M0734",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Hand"
    },
    {
      "name": "M0735",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Pelvic region and thigh"
    },
    {
      "name": "M0736",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Lower leg"
    },
    {
      "name": "M0737",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Ankle and foot"
    },
    {
      "name": "M0738",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Other"
    },
    {
      "name": "M0739",
      "description": "Other psoriatic  arthropathies ( L40.5 ) Site unspecified"
    },
    {
      "name": "M074",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- )"
    },
    {
      "name": "M0740",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Multiple sites"
    },
    {
      "name": "M0741",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Shoulder region"
    },
    {
      "name": "M0742",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Upper arm"
    },
    {
      "name": "M0743",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Forearm"
    },
    {
      "name": "M0744",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Hand"
    },
    {
      "name": "M0745",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Pelvic region and thigh"
    },
    {
      "name": "M0746",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Lower leg"
    },
    {
      "name": "M0747",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Ankle and foot"
    },
    {
      "name": "M0748",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Other"
    },
    {
      "name": "M0749",
      "description": "Arthropathy in Crohn's disease [ regional enteritis ]( K50.- ) Site unspecified"
    },
    {
      "name": "M075",
      "description": "Arthropathy in ulcerative colitis ( K51.- )"
    },
    {
      "name": "M0750",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Multiple sites"
    },
    {
      "name": "M0751",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Shoulder region"
    },
    {
      "name": "M0752",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Upper arm"
    },
    {
      "name": "M0753",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Forearm"
    },
    {
      "name": "M0754",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Hand"
    },
    {
      "name": "M0755",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Pelvic region and thigh"
    },
    {
      "name": "M0756",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Lower leg"
    },
    {
      "name": "M0757",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Ankle and foot"
    },
    {
      "name": "M0758",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Other"
    },
    {
      "name": "M0759",
      "description": "Arthropathy in ulcerative colitis ( K51.- ) Site unspecified"
    },
    {
      "name": "M076",
      "description": "Other enteropathic arthropathies"
    },
    {
      "name": "M0760",
      "description": "Other enteropathic arthropathies Multiple sites"
    },
    {
      "name": "M0761",
      "description": "Other enteropathic arthropathies Shoulder region"
    },
    {
      "name": "M0762",
      "description": "Other enteropathic arthropathies Upper arm"
    },
    {
      "name": "M0763",
      "description": "Other enteropathic arthropathies Forearm"
    },
    {
      "name": "M0764",
      "description": "Other enteropathic arthropathies Hand"
    },
    {
      "name": "M0765",
      "description": "Other enteropathic arthropathies Pelvic region and thigh"
    },
    {
      "name": "M0766",
      "description": "Other enteropathic arthropathies Lower leg"
    },
    {
      "name": "M0767",
      "description": "Other enteropathic arthropathies Ankle and foot"
    },
    {
      "name": "M0768",
      "description": "Other enteropathic arthropathies Other"
    },
    {
      "name": "M0769",
      "description": "Other enteropathic arthropathies Site unspecified"
    },
    {
      "name": "M080",
      "description": "Juvenile rheumatoid arthritis"
    },
    {
      "name": "M0800",
      "description": "Juvenile rheumatoid arthritis Multiple sites"
    },
    {
      "name": "M0801",
      "description": "Juvenile rheumatoid arthritis Shoulder region"
    },
    {
      "name": "M0802",
      "description": "Juvenile rheumatoid arthritis Upper arm"
    },
    {
      "name": "M0803",
      "description": "Juvenile rheumatoid arthritis Forearm"
    },
    {
      "name": "M0804",
      "description": "Juvenile rheumatoid arthritis Hand"
    },
    {
      "name": "M0805",
      "description": "Juvenile rheumatoid arthritis Pelvic region and thigh"
    },
    {
      "name": "M0806",
      "description": "Juvenile rheumatoid arthritis Lower leg"
    },
    {
      "name": "M0807",
      "description": "Juvenile rheumatoid arthritis Ankle and foot"
    },
    {
      "name": "M0808",
      "description": "Juvenile rheumatoid arthritis Other"
    },
    {
      "name": "M0809",
      "description": "Juvenile rheumatoid arthritis Site unspecified"
    },
    {
      "name": "M081",
      "description": "Juvenile ankylosing spondylitis"
    },
    {
      "name": "M0810",
      "description": "Juvenile ankylosing spondylitis Multiple sites"
    },
    {
      "name": "M0811",
      "description": "Juvenile ankylosing spondylitis Shoulder region"
    },
    {
      "name": "M0812",
      "description": "Juvenile ankylosing spondylitis Upper arm"
    },
    {
      "name": "M0813",
      "description": "Juvenile ankylosing spondylitis Forearm"
    },
    {
      "name": "M0814",
      "description": "Juvenile ankylosing spondylitis Hand"
    },
    {
      "name": "M0815",
      "description": "Juvenile ankylosing spondylitis Pelvic region and thigh"
    },
    {
      "name": "M0816",
      "description": "Juvenile ankylosing spondylitis Lower leg"
    },
    {
      "name": "M0817",
      "description": "Juvenile ankylosing spondylitis Ankle and foot"
    },
    {
      "name": "M0818",
      "description": "Juvenile ankylosing spondylitis Other"
    },
    {
      "name": "M0819",
      "description": "Juvenile ankylosing spondylitis Site unspecified"
    },
    {
      "name": "M082",
      "description": "Juvenile arthritis with systemic onset"
    },
    {
      "name": "M0820",
      "description": "Juvenile arthritis with systemic onset Multiple sites"
    },
    {
      "name": "M0821",
      "description": "Juvenile arthritis with systemic onset Shoulder region"
    },
    {
      "name": "M0822",
      "description": "Juvenile arthritis with systemic onset Upper arm"
    },
    {
      "name": "M0823",
      "description": "Juvenile arthritis with systemic onset Forearm"
    },
    {
      "name": "M0824",
      "description": "Juvenile arthritis with systemic onset Hand"
    },
    {
      "name": "M0825",
      "description": "Juvenile arthritis with systemic onset Pelvic region and thigh"
    },
    {
      "name": "M0826",
      "description": "Juvenile arthritis with systemic onset Lower leg"
    },
    {
      "name": "M0827",
      "description": "Juvenile arthritis with systemic onset Ankle and foot"
    },
    {
      "name": "M0828",
      "description": "Juvenile arthritis with systemic onset Other"
    },
    {
      "name": "M0829",
      "description": "Juvenile arthritis with systemic onset Site unspecified"
    },
    {
      "name": "M083",
      "description": "Juvenile polyarthritis (seronegative)"
    },
    {
      "name": "M0830",
      "description": "Juvenile polyarthritis ( seronegative ) Multiple sites"
    },
    {
      "name": "M0831",
      "description": "Juvenile polyarthritis ( seronegative ) Shoulder region"
    },
    {
      "name": "M0832",
      "description": "Juvenile polyarthritis ( seronegative ) Upper arm"
    },
    {
      "name": "M0833",
      "description": "Juvenile polyarthritis ( seronegative ) Forearm"
    },
    {
      "name": "M0834",
      "description": "Juvenile polyarthritis ( seronegative ) Hand"
    },
    {
      "name": "M0835",
      "description": "Juvenile polyarthritis ( seronegative ) Pelvic region and thigh"
    },
    {
      "name": "M0836",
      "description": "Juvenile polyarthritis ( seronegative ) Lower leg"
    },
    {
      "name": "M0837",
      "description": "Juvenile polyarthritis ( seronegative ) Ankle and foot"
    },
    {
      "name": "M0838",
      "description": "Juvenile polyarthritis ( seronegative ) Other"
    },
    {
      "name": "M0839",
      "description": "Juvenile polyarthritis ( seronegative ) Site unspecified"
    },
    {
      "name": "M084",
      "description": "Pauciarticular juvenile arthritis"
    },
    {
      "name": "M0840",
      "description": "Pauciarticular juvenile arthritis Multiple sites"
    },
    {
      "name": "M0841",
      "description": "Pauciarticular juvenile arthritis Shoulder region"
    },
    {
      "name": "M0842",
      "description": "Pauciarticular juvenile arthritis Upper arm"
    },
    {
      "name": "M0843",
      "description": "Pauciarticular juvenile arthritis Forearm"
    },
    {
      "name": "M0844",
      "description": "Pauciarticular juvenile arthritis Hand"
    },
    {
      "name": "M0845",
      "description": "Pauciarticular juvenile arthritis Pelvic region and thigh"
    },
    {
      "name": "M0846",
      "description": "Pauciarticular juvenile arthritis Lower leg"
    },
    {
      "name": "M0847",
      "description": "Pauciarticular juvenile arthritis Ankle and foot"
    },
    {
      "name": "M0848",
      "description": "Pauciarticular juvenile arthritis Other"
    },
    {
      "name": "M0849",
      "description": "Pauciarticular juvenile arthritis Site unspecified"
    },
    {
      "name": "M088",
      "description": "Other juvenile arthritis"
    },
    {
      "name": "M0880",
      "description": "Other juvenile arthritis Multiple sites"
    },
    {
      "name": "M0881",
      "description": "Other juvenile arthritis Shoulder region"
    },
    {
      "name": "M0882",
      "description": "Other juvenile arthritis Upper arm"
    },
    {
      "name": "M0883",
      "description": "Other juvenile arthritis Forearm"
    },
    {
      "name": "M0884",
      "description": "Other juvenile arthritis Hand"
    },
    {
      "name": "M0885",
      "description": "Other juvenile arthritis Pelvic region and thigh"
    },
    {
      "name": "M0886",
      "description": "Other juvenile arthritis Lower leg"
    },
    {
      "name": "M0887",
      "description": "Other juvenile arthritis Ankle and foot"
    },
    {
      "name": "M0888",
      "description": "Other juvenile arthritis Other"
    },
    {
      "name": "M0889",
      "description": "Other juvenile arthritis Site unspecified"
    },
    {
      "name": "M089",
      "description": "Juvenile arthritis, unspecified"
    },
    {
      "name": "M0890",
      "description": "Juvenile arthritis, unspecified Multiple sites"
    },
    {
      "name": "M0891",
      "description": "Juvenile arthritis, unspecified Shoulder region"
    },
    {
      "name": "M0892",
      "description": "Juvenile arthritis, unspecified Upper arm"
    },
    {
      "name": "M0893",
      "description": "Juvenile arthritis, unspecified Forearm"
    },
    {
      "name": "M0894",
      "description": "Juvenile arthritis, unspecified Hand"
    },
    {
      "name": "M0895",
      "description": "Juvenile arthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M0896",
      "description": "Juvenile arthritis, unspecified Lower leg"
    },
    {
      "name": "M0897",
      "description": "Juvenile arthritis, unspecified Ankle and foot"
    },
    {
      "name": "M0898",
      "description": "Juvenile arthritis, unspecified Other"
    },
    {
      "name": "M0899",
      "description": "Juvenile arthritis, unspecified Site unspecified"
    },
    {
      "name": "M090",
      "description": "Juvenile arthritis in psoriasis ( L40.5 )"
    },
    {
      "name": "M0900",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Multiple sites"
    },
    {
      "name": "M0901",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Shoulder region"
    },
    {
      "name": "M0902",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Upper arm"
    },
    {
      "name": "M0903",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Forearm"
    },
    {
      "name": "M0904",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Hand"
    },
    {
      "name": "M0905",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Pelvic region and thigh"
    },
    {
      "name": "M0906",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Lower leg"
    },
    {
      "name": "M0907",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Ankle and foot"
    },
    {
      "name": "M0908",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Other"
    },
    {
      "name": "M0909",
      "description": "Juvenile arthritis in psoriasis ( L40.5 ) Site unspecified"
    },
    {
      "name": "M091",
      "description": "Juvenile arthritis in Crohn’s disease [regional enteritis]( K50.- )"
    },
    {
      "name": "M0910",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Multiple sites"
    },
    {
      "name": "M0911",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Shoulder region"
    },
    {
      "name": "M0912",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Upper arm"
    },
    {
      "name": "M0913",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Forearm"
    },
    {
      "name": "M0914",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Hand"
    },
    {
      "name": "M0915",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Pelvic region and thigh"
    },
    {
      "name": "M0916",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Lower leg"
    },
    {
      "name": "M0917",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Ankle and foot"
    },
    {
      "name": "M0918",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Other"
    },
    {
      "name": "M0919",
      "description": "Juvenile arthritis in Crohn's disease [ regional enteritis ]( K50.- ) Site unspecified"
    },
    {
      "name": "M092",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- )"
    },
    {
      "name": "M0920",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Multiple sites"
    },
    {
      "name": "M0921",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Shoulder region"
    },
    {
      "name": "M0922",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Upper arm"
    },
    {
      "name": "M0923",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Forearm"
    },
    {
      "name": "M0924",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Hand"
    },
    {
      "name": "M0925",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Pelvic region and thigh"
    },
    {
      "name": "M0926",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Lower leg"
    },
    {
      "name": "M0927",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Ankle and foot"
    },
    {
      "name": "M0928",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Other"
    },
    {
      "name": "M0929",
      "description": "Juvenile arthritis in ulcerative colitis ( K51.- ) Site unspecified"
    },
    {
      "name": "M098",
      "description": "Juvenile arthritis in other diseases classified elsewhere"
    },
    {
      "name": "M0980",
      "description": "Juvenile arthritis in other diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M0981",
      "description": "Juvenile arthritis in other diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M0982",
      "description": "Juvenile arthritis in other diseases classified elsewhere Upper arm"
    },
    {
      "name": "M0983",
      "description": "Juvenile arthritis in other diseases classified elsewhere Forearm"
    },
    {
      "name": "M0984",
      "description": "Juvenile arthritis in other diseases classified elsewhere Hand"
    },
    {
      "name": "M0985",
      "description": "Juvenile arthritis in other diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M0986",
      "description": "Juvenile arthritis in other diseases classified elsewhere Lower leg"
    },
    {
      "name": "M0987",
      "description": "Juvenile arthritis in other diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M0988",
      "description": "Juvenile arthritis in other diseases classified elsewhere Other"
    },
    {
      "name": "M0989",
      "description": "Juvenile arthritis in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M100",
      "description": "Idiopathic gout"
    },
    {
      "name": "M1000",
      "description": "Idiopathic gout Multiple sites"
    },
    {
      "name": "M1001",
      "description": "Idiopathic gout Shoulder region"
    },
    {
      "name": "M1002",
      "description": "Idiopathic gout Upper arm"
    },
    {
      "name": "M1003",
      "description": "Idiopathic gout Forearm"
    },
    {
      "name": "M1004",
      "description": "Idiopathic gout Hand"
    },
    {
      "name": "M1005",
      "description": "Idiopathic gout Pelvic region and thigh"
    },
    {
      "name": "M1006",
      "description": "Idiopathic gout Lower leg"
    },
    {
      "name": "M1007",
      "description": "Idiopathic gout Ankle and foot"
    },
    {
      "name": "M1008",
      "description": "Idiopathic gout Other"
    },
    {
      "name": "M1009",
      "description": "Idiopathic gout Site unspecified"
    },
    {
      "name": "M101",
      "description": "Lead-induced gout"
    },
    {
      "name": "M1010",
      "description": "Lead-induced gout Multiple sites"
    },
    {
      "name": "M1011",
      "description": "Lead-induced gout Shoulder region"
    },
    {
      "name": "M1012",
      "description": "Lead-induced gout Upper arm"
    },
    {
      "name": "M1013",
      "description": "Lead-induced gout Forearm"
    },
    {
      "name": "M1014",
      "description": "Lead-induced gout Hand"
    },
    {
      "name": "M1015",
      "description": "Lead-induced gout Pelvic region and thigh"
    },
    {
      "name": "M1016",
      "description": "Lead-induced gout Lower leg"
    },
    {
      "name": "M1017",
      "description": "Lead-induced gout Ankle and foot"
    },
    {
      "name": "M1018",
      "description": "Lead-induced gout Other"
    },
    {
      "name": "M1019",
      "description": "Lead-induced gout Site unspecified"
    },
    {
      "name": "M102",
      "description": "Drug-induced gout"
    },
    {
      "name": "M1020",
      "description": "Drug-induced gout Multiple sites"
    },
    {
      "name": "M1021",
      "description": "Drug-induced gout Shoulder region"
    },
    {
      "name": "M1022",
      "description": "Drug-induced gout Upper arm"
    },
    {
      "name": "M1023",
      "description": "Drug-induced gout Forearm"
    },
    {
      "name": "M1024",
      "description": "Drug-induced gout Hand"
    },
    {
      "name": "M1025",
      "description": "Drug-induced gout Pelvic region and thigh"
    },
    {
      "name": "M1026",
      "description": "Drug-induced gout Lower leg"
    },
    {
      "name": "M1027",
      "description": "Drug-induced gout Ankle and foot"
    },
    {
      "name": "M1028",
      "description": "Drug-induced gout Other"
    },
    {
      "name": "M1029",
      "description": "Drug-induced gout Site unspecified"
    },
    {
      "name": "M103",
      "description": "Gout due to impairment of renal function"
    },
    {
      "name": "M1030",
      "description": "Gout due to impairment of renal function Multiple sites"
    },
    {
      "name": "M1031",
      "description": "Gout due to impairment of renal function Shoulder region"
    },
    {
      "name": "M1032",
      "description": "Gout due to impairment of renal function Upper arm"
    },
    {
      "name": "M1033",
      "description": "Gout due to impairment of renal function Forearm"
    },
    {
      "name": "M1034",
      "description": "Gout due to impairment of renal function Hand"
    },
    {
      "name": "M1035",
      "description": "Gout due to impairment of renal function Pelvic region and thigh"
    },
    {
      "name": "M1036",
      "description": "Gout due to impairment of renal function Lower leg"
    },
    {
      "name": "M1037",
      "description": "Gout due to impairment of renal function Ankle and foot"
    },
    {
      "name": "M1038",
      "description": "Gout due to impairment of renal function Other"
    },
    {
      "name": "M1039",
      "description": "Gout due to impairment of renal function Site unspecified"
    },
    {
      "name": "M104",
      "description": "Other secondary gout"
    },
    {
      "name": "M1040",
      "description": "Other secondary gout Multiple sites"
    },
    {
      "name": "M1041",
      "description": "Other secondary gout Shoulder region"
    },
    {
      "name": "M1042",
      "description": "Other secondary gout Upper arm"
    },
    {
      "name": "M1043",
      "description": "Other secondary gout Forearm"
    },
    {
      "name": "M1044",
      "description": "Other secondary gout Hand"
    },
    {
      "name": "M1045",
      "description": "Other secondary gout Pelvic region and thigh"
    },
    {
      "name": "M1046",
      "description": "Other secondary gout Lower leg"
    },
    {
      "name": "M1047",
      "description": "Other secondary gout Ankle and foot"
    },
    {
      "name": "M1048",
      "description": "Other secondary gout Other"
    },
    {
      "name": "M1049",
      "description": "Other secondary gout Site unspecified"
    },
    {
      "name": "M109",
      "description": "Gout, unspecified"
    },
    {
      "name": "M1090",
      "description": "Gout, unspecified Multiple sites"
    },
    {
      "name": "M1091",
      "description": "Gout, unspecified Shoulder region"
    },
    {
      "name": "M1092",
      "description": "Gout, unspecified Upper arm"
    },
    {
      "name": "M1093",
      "description": "Gout, unspecified Forearm"
    },
    {
      "name": "M1094",
      "description": "Gout, unspecified Hand"
    },
    {
      "name": "M1095",
      "description": "Gout, unspecified Pelvic region and thigh"
    },
    {
      "name": "M1096",
      "description": "Gout, unspecified Lower leg"
    },
    {
      "name": "M1097",
      "description": "Gout, unspecified Ankle and foot"
    },
    {
      "name": "M1098",
      "description": "Gout, unspecified Other"
    },
    {
      "name": "M1099",
      "description": "Gout, unspecified Site unspecified"
    },
    {
      "name": "M110",
      "description": "Hydroxyapatite deposition disease"
    },
    {
      "name": "M1100",
      "description": "Hydroxyapatite deposition disease Multiple sites"
    },
    {
      "name": "M1101",
      "description": "Hydroxyapatite deposition disease Shoulder region"
    },
    {
      "name": "M1102",
      "description": "Hydroxyapatite deposition disease Upper arm"
    },
    {
      "name": "M1103",
      "description": "Hydroxyapatite deposition disease Forearm"
    },
    {
      "name": "M1104",
      "description": "Hydroxyapatite deposition disease Hand"
    },
    {
      "name": "M1105",
      "description": "Hydroxyapatite deposition disease Pelvic region and thigh"
    },
    {
      "name": "M1106",
      "description": "Hydroxyapatite deposition disease Lower leg"
    },
    {
      "name": "M1107",
      "description": "Hydroxyapatite deposition disease Ankle and foot"
    },
    {
      "name": "M1108",
      "description": "Hydroxyapatite deposition disease Other"
    },
    {
      "name": "M1109",
      "description": "Hydroxyapatite deposition disease Site unspecified"
    },
    {
      "name": "M111",
      "description": "Familial chondrocalcinosis"
    },
    {
      "name": "M1110",
      "description": "Familial chondrocalcinosis Multiple sites"
    },
    {
      "name": "M1111",
      "description": "Familial chondrocalcinosis Shoulder region"
    },
    {
      "name": "M1112",
      "description": "Familial chondrocalcinosis Upper arm"
    },
    {
      "name": "M1113",
      "description": "Familial chondrocalcinosis Forearm"
    },
    {
      "name": "M1114",
      "description": "Familial chondrocalcinosis Hand"
    },
    {
      "name": "M1115",
      "description": "Familial chondrocalcinosis Pelvic region and thigh"
    },
    {
      "name": "M1116",
      "description": "Familial chondrocalcinosis Lower leg"
    },
    {
      "name": "M1117",
      "description": "Familial chondrocalcinosis Ankle and foot"
    },
    {
      "name": "M1118",
      "description": "Familial chondrocalcinosis Other"
    },
    {
      "name": "M1119",
      "description": "Familial chondrocalcinosis Site unspecified"
    },
    {
      "name": "M112",
      "description": "Other chondrocalcinosis"
    },
    {
      "name": "M1120",
      "description": "Other chondrocalcinosis Multiple sites"
    },
    {
      "name": "M1121",
      "description": "Other chondrocalcinosis Shoulder region"
    },
    {
      "name": "M1122",
      "description": "Other chondrocalcinosis Upper arm"
    },
    {
      "name": "M1123",
      "description": "Other chondrocalcinosis Forearm"
    },
    {
      "name": "M1124",
      "description": "Other chondrocalcinosis Hand"
    },
    {
      "name": "M1125",
      "description": "Other chondrocalcinosis Pelvic region and thigh"
    },
    {
      "name": "M1126",
      "description": "Other chondrocalcinosis Lower leg"
    },
    {
      "name": "M1127",
      "description": "Other chondrocalcinosis Ankle and foot"
    },
    {
      "name": "M1128",
      "description": "Other chondrocalcinosis Other"
    },
    {
      "name": "M1129",
      "description": "Other chondrocalcinosis Site unspecified"
    },
    {
      "name": "M118",
      "description": "Other specified crystal arthropathies"
    },
    {
      "name": "M1180",
      "description": "Other specified crystal arthropathies Multiple sites"
    },
    {
      "name": "M1181",
      "description": "Other specified crystal arthropathies Shoulder region"
    },
    {
      "name": "M1182",
      "description": "Other specified crystal arthropathies Upper arm"
    },
    {
      "name": "M1183",
      "description": "Other specified crystal arthropathies Forearm"
    },
    {
      "name": "M1184",
      "description": "Other specified crystal arthropathies Hand"
    },
    {
      "name": "M1185",
      "description": "Other specified crystal arthropathies Pelvic region and thigh"
    },
    {
      "name": "M1186",
      "description": "Other specified crystal arthropathies Lower leg"
    },
    {
      "name": "M1187",
      "description": "Other specified crystal arthropathies Ankle and foot"
    },
    {
      "name": "M1188",
      "description": "Other specified crystal arthropathies Other"
    },
    {
      "name": "M1189",
      "description": "Other specified crystal arthropathies Site unspecified"
    },
    {
      "name": "M119",
      "description": "Crystal arthropathy, unspecified"
    },
    {
      "name": "M1190",
      "description": "Crystal arthropathy, unspecified Multiple sites"
    },
    {
      "name": "M1191",
      "description": "Crystal arthropathy, unspecified Shoulder region"
    },
    {
      "name": "M1192",
      "description": "Crystal arthropathy, unspecified Upper arm"
    },
    {
      "name": "M1193",
      "description": "Crystal arthropathy, unspecified Forearm"
    },
    {
      "name": "M1194",
      "description": "Crystal arthropathy, unspecified Hand"
    },
    {
      "name": "M1195",
      "description": "Crystal arthropathy, unspecified Pelvic region and thigh"
    },
    {
      "name": "M1196",
      "description": "Crystal arthropathy, unspecified Lower leg"
    },
    {
      "name": "M1197",
      "description": "Crystal arthropathy, unspecified Ankle and foot"
    },
    {
      "name": "M1198",
      "description": "Crystal arthropathy, unspecified Other"
    },
    {
      "name": "M1199",
      "description": "Crystal arthropathy, unspecified Site unspecified"
    },
    {
      "name": "M120",
      "description": "Chronic postrheumatic arthropathy [Jaccoud]"
    },
    {
      "name": "M1200",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Multiple sites"
    },
    {
      "name": "M1201",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Shoulder region"
    },
    {
      "name": "M1202",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Upper arm"
    },
    {
      "name": "M1203",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Forearm"
    },
    {
      "name": "M1204",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Hand"
    },
    {
      "name": "M1205",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Pelvic region and thigh"
    },
    {
      "name": "M1206",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Lower leg"
    },
    {
      "name": "M1207",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Ankle and foot"
    },
    {
      "name": "M1208",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Other"
    },
    {
      "name": "M1209",
      "description": "Chronic postrheumatic arthropathy [ Jaccoud ] Site unspecified"
    },
    {
      "name": "M121",
      "description": "Kaschin-Beck disease"
    },
    {
      "name": "M1210",
      "description": "Kaschin-Beck disease Multiple sites"
    },
    {
      "name": "M1211",
      "description": "Kaschin-Beck disease Shoulder region"
    },
    {
      "name": "M1212",
      "description": "Kaschin-Beck disease Upper arm"
    },
    {
      "name": "M1213",
      "description": "Kaschin-Beck disease Forearm"
    },
    {
      "name": "M1214",
      "description": "Kaschin-Beck disease Hand"
    },
    {
      "name": "M1215",
      "description": "Kaschin-Beck disease Pelvic region and thigh"
    },
    {
      "name": "M1216",
      "description": "Kaschin-Beck disease Lower leg"
    },
    {
      "name": "M1217",
      "description": "Kaschin-Beck disease Ankle and foot"
    },
    {
      "name": "M1218",
      "description": "Kaschin-Beck disease Other"
    },
    {
      "name": "M1219",
      "description": "Kaschin-Beck disease Site unspecified"
    },
    {
      "name": "M122",
      "description": "Villonodular synovitis (pigmented)"
    },
    {
      "name": "M1220",
      "description": "Villonodular synovitis ( pigmented ) Multiple sites"
    },
    {
      "name": "M1221",
      "description": "Villonodular synovitis ( pigmented ) Shoulder region"
    },
    {
      "name": "M1222",
      "description": "Villonodular synovitis ( pigmented ) Upper arm"
    },
    {
      "name": "M1223",
      "description": "Villonodular synovitis ( pigmented ) Forearm"
    },
    {
      "name": "M1224",
      "description": "Villonodular synovitis ( pigmented ) Hand"
    },
    {
      "name": "M1225",
      "description": "Villonodular synovitis ( pigmented ) Pelvic region and thigh"
    },
    {
      "name": "M1226",
      "description": "Villonodular synovitis ( pigmented ) Lower leg"
    },
    {
      "name": "M1227",
      "description": "Villonodular synovitis ( pigmented ) Ankle and foot"
    },
    {
      "name": "M1228",
      "description": "Villonodular synovitis ( pigmented ) Other"
    },
    {
      "name": "M1229",
      "description": "Villonodular synovitis ( pigmented ) Site unspecified"
    },
    {
      "name": "M123",
      "description": "Palindromic rheumatism"
    },
    {
      "name": "M1230",
      "description": "Palindromic rheumatism Multiple sites"
    },
    {
      "name": "M1231",
      "description": "Palindromic rheumatism Shoulder region"
    },
    {
      "name": "M1232",
      "description": "Palindromic rheumatism Upper arm"
    },
    {
      "name": "M1233",
      "description": "Palindromic rheumatism Forearm"
    },
    {
      "name": "M1234",
      "description": "Palindromic rheumatism Hand"
    },
    {
      "name": "M1235",
      "description": "Palindromic rheumatism Pelvic region and thigh"
    },
    {
      "name": "M1236",
      "description": "Palindromic rheumatism Lower leg"
    },
    {
      "name": "M1237",
      "description": "Palindromic rheumatism Ankle and foot"
    },
    {
      "name": "M1238",
      "description": "Palindromic rheumatism Other"
    },
    {
      "name": "M1239",
      "description": "Palindromic rheumatism Site unspecified"
    },
    {
      "name": "M124",
      "description": "Intermittent hydrarthrosis"
    },
    {
      "name": "M1240",
      "description": "Intermittent hydrarthrosis Multiple sites"
    },
    {
      "name": "M1241",
      "description": "Intermittent hydrarthrosis Shoulder region"
    },
    {
      "name": "M1242",
      "description": "Intermittent hydrarthrosis Upper arm"
    },
    {
      "name": "M1243",
      "description": "Intermittent hydrarthrosis Forearm"
    },
    {
      "name": "M1244",
      "description": "Intermittent hydrarthrosis Hand"
    },
    {
      "name": "M1245",
      "description": "Intermittent hydrarthrosis Pelvic region and thigh"
    },
    {
      "name": "M1246",
      "description": "Intermittent hydrarthrosis Lower leg"
    },
    {
      "name": "M1247",
      "description": "Intermittent hydrarthrosis Ankle and foot"
    },
    {
      "name": "M1248",
      "description": "Intermittent hydrarthrosis Other"
    },
    {
      "name": "M1249",
      "description": "Intermittent hydrarthrosis Site unspecified"
    },
    {
      "name": "M125",
      "description": "Traumatic arthropathy"
    },
    {
      "name": "M1250",
      "description": "Traumatic arthropathy Multiple sites"
    },
    {
      "name": "M1251",
      "description": "Traumatic arthropathy Shoulder region"
    },
    {
      "name": "M1252",
      "description": "Traumatic arthropathy Upper arm"
    },
    {
      "name": "M1253",
      "description": "Traumatic arthropathy Forearm"
    },
    {
      "name": "M1254",
      "description": "Traumatic arthropathy Hand"
    },
    {
      "name": "M1255",
      "description": "Traumatic arthropathy Pelvic region and thigh"
    },
    {
      "name": "M1256",
      "description": "Traumatic arthropathy Lower leg"
    },
    {
      "name": "M1257",
      "description": "Traumatic arthropathy Ankle and foot"
    },
    {
      "name": "M1258",
      "description": "Traumatic arthropathy Other"
    },
    {
      "name": "M1259",
      "description": "Traumatic arthropathy Site unspecified"
    },
    {
      "name": "M128",
      "description": "Other specific arthropathies, not elsewhere classified"
    },
    {
      "name": "M1280",
      "description": "Other specific arthropathies, not elsewhere classified Multiple sites"
    },
    {
      "name": "M1281",
      "description": "Other specific arthropathies, not elsewhere classified Shoulder region"
    },
    {
      "name": "M1282",
      "description": "Other specific arthropathies, not elsewhere classified Upper arm"
    },
    {
      "name": "M1283",
      "description": "Other specific arthropathies, not elsewhere classified Forearm"
    },
    {
      "name": "M1284",
      "description": "Other specific arthropathies, not elsewhere classified Hand"
    },
    {
      "name": "M1285",
      "description": "Other specific arthropathies, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M1286",
      "description": "Other specific arthropathies, not elsewhere classified Lower leg"
    },
    {
      "name": "M1287",
      "description": "Other specific arthropathies, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M1288",
      "description": "Other specific arthropathies, not elsewhere classified Other"
    },
    {
      "name": "M1289",
      "description": "Other specific arthropathies, not elsewhere classified Site unspecified"
    },
    {
      "name": "M130",
      "description": "Polyarthritis, unspecified"
    },
    {
      "name": "M1300",
      "description": "Polyarthritis, unspecified Multiple sites"
    },
    {
      "name": "M1301",
      "description": "Polyarthritis, unspecified Shoulder region"
    },
    {
      "name": "M1302",
      "description": "Polyarthritis, unspecified Upper arm"
    },
    {
      "name": "M1303",
      "description": "Polyarthritis, unspecified Forearm"
    },
    {
      "name": "M1304",
      "description": "Polyarthritis, unspecified Hand"
    },
    {
      "name": "M1305",
      "description": "Polyarthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M1306",
      "description": "Polyarthritis, unspecified Lower leg"
    },
    {
      "name": "M1307",
      "description": "Polyarthritis, unspecified Ankle and foot"
    },
    {
      "name": "M1308",
      "description": "Polyarthritis, unspecified Other"
    },
    {
      "name": "M1309",
      "description": "Polyarthritis, unspecified Site unspecified"
    },
    {
      "name": "M131",
      "description": "Monoarthritis, not elsewhere classified"
    },
    {
      "name": "M1310",
      "description": "Monoarthritis, not elsewhere classified Multiple sites"
    },
    {
      "name": "M1311",
      "description": "Monoarthritis, not elsewhere classified Shoulder region"
    },
    {
      "name": "M1312",
      "description": "Monoarthritis, not elsewhere classified Upper arm"
    },
    {
      "name": "M1313",
      "description": "Monoarthritis, not elsewhere classified Forearm"
    },
    {
      "name": "M1314",
      "description": "Monoarthritis, not elsewhere classified Hand"
    },
    {
      "name": "M1315",
      "description": "Monoarthritis, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M1316",
      "description": "Monoarthritis, not elsewhere classified Lower leg"
    },
    {
      "name": "M1317",
      "description": "Monoarthritis, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M1318",
      "description": "Monoarthritis, not elsewhere classified Other"
    },
    {
      "name": "M1319",
      "description": "Monoarthritis, not elsewhere classified Site unspecified"
    },
    {
      "name": "M138",
      "description": "Other specified arthritis"
    },
    {
      "name": "M1380",
      "description": "Other specified arthritis Multiple sites"
    },
    {
      "name": "M1381",
      "description": "Other specified arthritis Shoulder region"
    },
    {
      "name": "M1382",
      "description": "Other specified arthritis Upper arm"
    },
    {
      "name": "M1383",
      "description": "Other specified arthritis Forearm"
    },
    {
      "name": "M1384",
      "description": "Other specified arthritis Hand"
    },
    {
      "name": "M1385",
      "description": "Other specified arthritis Pelvic region and thigh"
    },
    {
      "name": "M1386",
      "description": "Other specified arthritis Lower leg"
    },
    {
      "name": "M1387",
      "description": "Other specified arthritis Ankle and foot"
    },
    {
      "name": "M1388",
      "description": "Other specified arthritis Other"
    },
    {
      "name": "M1389",
      "description": "Other specified arthritis Site unspecified"
    },
    {
      "name": "M139",
      "description": "Arthritis, unspecified"
    },
    {
      "name": "M1390",
      "description": "Arthritis, unspecified Multiple sites"
    },
    {
      "name": "M1391",
      "description": "Arthritis, unspecified Shoulder region"
    },
    {
      "name": "M1392",
      "description": "Arthritis, unspecified Upper arm"
    },
    {
      "name": "M1393",
      "description": "Arthritis, unspecified Forearm"
    },
    {
      "name": "M1394",
      "description": "Arthritis, unspecified Hand"
    },
    {
      "name": "M1395",
      "description": "Arthritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M1396",
      "description": "Arthritis, unspecified Lower leg"
    },
    {
      "name": "M1397",
      "description": "Arthritis, unspecified Ankle and foot"
    },
    {
      "name": "M1398",
      "description": "Arthritis, unspecified Other"
    },
    {
      "name": "M1399",
      "description": "Arthritis, unspecified Site unspecified"
    },
    {
      "name": "M140",
      "description": "Gouty arthropathy due to enzyme defects and other inherited disorders"
    },
    {
      "name": "M141",
      "description": "Crystal arthropathy in other metabolic disorders"
    },
    {
      "name": "M142",
      "description": "Diabetic arthropathy"
    },
    {
      "name": "M143",
      "description": "Lipoid dermatoarthritis"
    },
    {
      "name": "M144",
      "description": "Arthropathy in amyloidosis"
    },
    {
      "name": "M145",
      "description": "Arthropathies in other endocrine, nutritional and metabolic disorders"
    },
    {
      "name": "M146",
      "description": "Neuropathic arthropathy"
    },
    {
      "name": "M148",
      "description": "Arthropathies in other specified diseases classified elsewhere"
    },
    {
      "name": "M150",
      "description": "Primary generalized (osteo)arthrosis"
    },
    {
      "name": "M151",
      "description": "Heberden’s nodes (with arthropathy)"
    },
    {
      "name": "M152",
      "description": "Bouchard’s nodes (with arthropathy)"
    },
    {
      "name": "M153",
      "description": "Secondary multiple arthrosis"
    },
    {
      "name": "M154",
      "description": "Erosive (osteo)arthrosis"
    },
    {
      "name": "M158",
      "description": "Other polyarthrosis"
    },
    {
      "name": "M159",
      "description": "Polyarthrosis, unspecified"
    },
    {
      "name": "M160",
      "description": "Primary coxarthrosis, bilateral"
    },
    {
      "name": "M161",
      "description": "Other primary coxarthrosis"
    },
    {
      "name": "M162",
      "description": "Coxarthrosis resulting from dysplasia, bilateral"
    },
    {
      "name": "M163",
      "description": "Other dysplastic coxarthrosis"
    },
    {
      "name": "M164",
      "description": "Post-traumatic coxarthrosis, bilateral"
    },
    {
      "name": "M165",
      "description": "Other post-traumatic coxarthrosis"
    },
    {
      "name": "M166",
      "description": "Other secondary coxarthrosis, bilateral"
    },
    {
      "name": "M167",
      "description": "Other secondary coxarthrosis"
    },
    {
      "name": "M169",
      "description": "Coxarthrosis, unspecified"
    },
    {
      "name": "M170",
      "description": "Primary gonarthrosis, bilateral"
    },
    {
      "name": "M171",
      "description": "Other primary gonarthrosis"
    },
    {
      "name": "M172",
      "description": "Post-traumatic gonarthrosis, bilateral"
    },
    {
      "name": "M173",
      "description": "Other post-traumatic gonarthrosis"
    },
    {
      "name": "M174",
      "description": "Other secondary gonarthrosis, bilateral"
    },
    {
      "name": "M175",
      "description": "Other secondary gonarthrosis"
    },
    {
      "name": "M179",
      "description": "Gonarthrosis, unspecified"
    },
    {
      "name": "M180",
      "description": "Pimary arthrosis of first carpometacarpal joints, bilateral"
    },
    {
      "name": "M181",
      "description": "Other pimary arthrosis of first carpometacarpal joint"
    },
    {
      "name": "M182",
      "description": "Post-traumatic arthrosis of first carpometacarpal joints, bilateral"
    },
    {
      "name": "M183",
      "description": "Other post-traumatic arthrosis of first carpo- metacarpal joint"
    },
    {
      "name": "M184",
      "description": "Other secondary arthrosis of first carpometacarpal joints, bilateral"
    },
    {
      "name": "M185",
      "description": "Other secondary arthrosis of first carpometacarpal joint"
    },
    {
      "name": "M189",
      "description": "Arthrosis of first carpometacarpal joint, unspecified"
    },
    {
      "name": "M190",
      "description": "Primary arthrosis of other joints"
    },
    {
      "name": "M1900",
      "description": "Primary arthorosis of other joints Multiple sites"
    },
    {
      "name": "M1901",
      "description": "Primary arthorosis of other joints Shoulder region"
    },
    {
      "name": "M1902",
      "description": "Primary arthorosis of other joints Upper arm"
    },
    {
      "name": "M1903",
      "description": "Primary arthorosis of other joints Forearm"
    },
    {
      "name": "M1904",
      "description": "Primary arthorosis of other joints Hand"
    },
    {
      "name": "M1905",
      "description": "Primary arthorosis of other joints Pelvic region and thigh"
    },
    {
      "name": "M1906",
      "description": "Primary arthorosis of other joints Lower leg"
    },
    {
      "name": "M1907",
      "description": "Primary arthorosis of other joints Ankle and foot"
    },
    {
      "name": "M1908",
      "description": "Primary arthorosis of other joints Other"
    },
    {
      "name": "M1909",
      "description": "Primary arthorosis of other joints Site unspecified"
    },
    {
      "name": "M191",
      "description": "Post-traumatic arthrosis of other joints"
    },
    {
      "name": "M1910",
      "description": "Post-traumatic arthrosis of other joints Multiple sites"
    },
    {
      "name": "M1911",
      "description": "Post-traumatic arthrosis of other joints Shoulder region"
    },
    {
      "name": "M1912",
      "description": "Post-traumatic arthrosis of other joints Upper arm"
    },
    {
      "name": "M1913",
      "description": "Post-traumatic arthrosis of other joints Forearm"
    },
    {
      "name": "M1914",
      "description": "Post-traumatic arthrosis of other joints Hand"
    },
    {
      "name": "M1915",
      "description": "Post-traumatic arthrosis of other joints Pelvic region and thigh"
    },
    {
      "name": "M1916",
      "description": "Post-traumatic arthrosis of other joints Lower leg"
    },
    {
      "name": "M1917",
      "description": "Post-traumatic arthrosis of other joints Ankle and foot"
    },
    {
      "name": "M1918",
      "description": "Post-traumatic arthrosis of other joints Other"
    },
    {
      "name": "M1919",
      "description": "Post-traumatic arthrosis of other joints Site unspecified"
    },
    {
      "name": "M192",
      "description": "Other secondary arthrosis"
    },
    {
      "name": "M1920",
      "description": "Other secondary arthorosis Multiple sites"
    },
    {
      "name": "M1921",
      "description": "Other secondary arthorosis Shoulder region"
    },
    {
      "name": "M1922",
      "description": "Other secondary arthorosis Upper arm"
    },
    {
      "name": "M1923",
      "description": "Other secondary arthorosis Forearm"
    },
    {
      "name": "M1924",
      "description": "Other secondary arthorosis Hand"
    },
    {
      "name": "M1925",
      "description": "Other secondary arthorosis Pelvic region and thigh"
    },
    {
      "name": "M1926",
      "description": "Other secondary arthorosis Lower leg"
    },
    {
      "name": "M1927",
      "description": "Other secondary arthorosis Ankle and foot"
    },
    {
      "name": "M1928",
      "description": "Other secondary arthorosis Other"
    },
    {
      "name": "M1929",
      "description": "Other secondary arthorosis Site unspecified"
    },
    {
      "name": "M198",
      "description": "Other specified arthrosis"
    },
    {
      "name": "M1980",
      "description": "Other specified arthrosis Multiple sites"
    },
    {
      "name": "M1981",
      "description": "Other specified arthrosis Shoulder region"
    },
    {
      "name": "M1982",
      "description": "Other specified arthrosis Upper arm"
    },
    {
      "name": "M1983",
      "description": "Other specified arthrosis Forearm"
    },
    {
      "name": "M1984",
      "description": "Other specified arthrosis Hand"
    },
    {
      "name": "M1985",
      "description": "Other specified arthrosis Pelvic region and thigh"
    },
    {
      "name": "M1986",
      "description": "Other specified arthrosis Lower leg"
    },
    {
      "name": "M1987",
      "description": "Other specified arthrosis Ankle and foot"
    },
    {
      "name": "M1988",
      "description": "Other specified arthrosis Other"
    },
    {
      "name": "M1989",
      "description": "Other specified arthrosis Site unspecified"
    },
    {
      "name": "M199",
      "description": "Arthrosis, unspecified"
    },
    {
      "name": "M1990",
      "description": "Arthrosis, unspecified Multiple sites"
    },
    {
      "name": "M1991",
      "description": "Arthrosis, unspecified Shoulder region"
    },
    {
      "name": "M1992",
      "description": "Arthrosis, unspecified Upper arm"
    },
    {
      "name": "M1993",
      "description": "Arthrosis, unspecified Forearm"
    },
    {
      "name": "M1994",
      "description": "Arthrosis, unspecified Hand"
    },
    {
      "name": "M1995",
      "description": "Arthrosis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M1996",
      "description": "Arthrosis, unspecified Lower leg"
    },
    {
      "name": "M1997",
      "description": "Arthrosis, unspecified Ankle and foot"
    },
    {
      "name": "M1998",
      "description": "Arthrosis, unspecified Other"
    },
    {
      "name": "M1999",
      "description": "Arthrosis, unspecified Site unspecified"
    },
    {
      "name": "M200",
      "description": "Deformity of finger(s)"
    },
    {
      "name": "M201",
      "description": "Hallux valgus (acquired)"
    },
    {
      "name": "M202",
      "description": "Hallux rigidus"
    },
    {
      "name": "M203",
      "description": "Other deformity of hallux (acquired)"
    },
    {
      "name": "M204",
      "description": "Other hammer toe(s) (acquired)"
    },
    {
      "name": "M205",
      "description": "Other deformities of toe(s) (acquired)"
    },
    {
      "name": "M206",
      "description": "Acquired deformity of toe(s), unspecified"
    },
    {
      "name": "M210",
      "description": "Valgus deformity, not elsewhere classified"
    },
    {
      "name": "M2100",
      "description": "Valgus deformity, not elsewhere classified Multiple sites"
    },
    {
      "name": "M2101",
      "description": "Valgus deformity, not elsewhere classified Shoulder region"
    },
    {
      "name": "M2102",
      "description": "Valgus deformity, not elsewhere classified Upper arm"
    },
    {
      "name": "M2103",
      "description": "Valgus deformity, not elsewhere classified Forearm"
    },
    {
      "name": "M2104",
      "description": "Valgus deformity, not elsewhere classified Hand"
    },
    {
      "name": "M2105",
      "description": "Valgus deformity, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M2106",
      "description": "Valgus deformity, not elsewhere classified Lower leg"
    },
    {
      "name": "M2107",
      "description": "Valgus deformity, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M2108",
      "description": "Valgus deformity, not elsewhere classified Other"
    },
    {
      "name": "M2109",
      "description": "Valgus deformity, not elsewhere classified Site unspecified"
    },
    {
      "name": "M211",
      "description": "Varus deformity, not elsewhere classified"
    },
    {
      "name": "M2110",
      "description": "Varus deformity, not elsewhere classified Multiple sites"
    },
    {
      "name": "M2111",
      "description": "Varus deformity, not elsewhere classified Shoulder region"
    },
    {
      "name": "M2112",
      "description": "Varus deformity, not elsewhere classified Upper arm"
    },
    {
      "name": "M2113",
      "description": "Varus deformity, not elsewhere classified Forearm"
    },
    {
      "name": "M2114",
      "description": "Varus deformity, not elsewhere classified Hand"
    },
    {
      "name": "M2115",
      "description": "Varus deformity, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M2116",
      "description": "Varus deformity, not elsewhere classified Lower leg"
    },
    {
      "name": "M2117",
      "description": "Varus deformity, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M2118",
      "description": "Varus deformity, not elsewhere classified Other"
    },
    {
      "name": "M2119",
      "description": "Varus deformity, not elsewhere classified Site unspecified"
    },
    {
      "name": "M212",
      "description": "Flexion deformity"
    },
    {
      "name": "M2120",
      "description": "Flexion deformity Multiple sites"
    },
    {
      "name": "M2121",
      "description": "Flexion deformity Shoulder region"
    },
    {
      "name": "M2122",
      "description": "Flexion deformity Upper arm"
    },
    {
      "name": "M2123",
      "description": "Flexion deformity Forearm"
    },
    {
      "name": "M2124",
      "description": "Flexion deformity Hand"
    },
    {
      "name": "M2125",
      "description": "Flexion deformity Pelvic region and thigh"
    },
    {
      "name": "M2126",
      "description": "Flexion deformity Lower leg"
    },
    {
      "name": "M2127",
      "description": "Flexion deformity Ankle and foot"
    },
    {
      "name": "M2128",
      "description": "Flexion deformity Other"
    },
    {
      "name": "M2129",
      "description": "Flexion deformity Site unspecified"
    },
    {
      "name": "M213",
      "description": "Wrist or foot drop (acquired)"
    },
    {
      "name": "M2130",
      "description": "Wrist or foot drop ( acquired ) Multiple sites"
    },
    {
      "name": "M2133",
      "description": "Wrist or foot drop ( acquired ) Forearm"
    },
    {
      "name": "M2134",
      "description": "Wrist or foot drop ( acquired ) Hand"
    },
    {
      "name": "M2137",
      "description": "Wrist or foot drop ( acquired ) Ankle and foot"
    },
    {
      "name": "M2138",
      "description": "Wrist or foot drop ( acquired ) Other"
    },
    {
      "name": "M2139",
      "description": "Wrist or foot drop ( acquired ) Site unspecified"
    },
    {
      "name": "M214",
      "description": "Flat foot [pes planus] (acquired)"
    },
    {
      "name": "M2140",
      "description": "Flat foot [ pes planus ]( acquired ) Multiple sites"
    },
    {
      "name": "M2147",
      "description": "Flat foot [ pes planus ]( acquired ) Ankle and foot"
    },
    {
      "name": "M2149",
      "description": "Flat foot [ pes planus ]( acquired ) Site unspecified"
    },
    {
      "name": "M215",
      "description": "Acquired clawhand, clubhand, clawfoot and clubfoot"
    },
    {
      "name": "M2150",
      "description": "Acquired clawhand, clubhand, clawfoot and clubfoot Multiple sites"
    },
    {
      "name": "M2154",
      "description": "Acquired clawhand, clubhand, clawfoot and clubfoot Hand"
    },
    {
      "name": "M2157",
      "description": "Acquired clawhand, clubhand, clawfoot and clubfoot Ankle and foot"
    },
    {
      "name": "M2159",
      "description": "Acquired clawhand, clubhand, clawfoot and clubfoot Site unspecified"
    },
    {
      "name": "M216",
      "description": "Other acquired deformities of ankle and foot"
    },
    {
      "name": "M2160",
      "description": "Other acquired deformities of ankle and foot Multiple sites"
    },
    {
      "name": "M2167",
      "description": "Other acquired deformities of ankle and foot Ankle and foot"
    },
    {
      "name": "M2169",
      "description": "Other acquired deformities of ankle and foot Site unspecified"
    },
    {
      "name": "M217",
      "description": "Unequal limb length (acquired)"
    },
    {
      "name": "M2170",
      "description": "Unequal limb length ( acquired ) Multiple sites"
    },
    {
      "name": "M2171",
      "description": "Unequal limb length ( acquired ) Shoulder region"
    },
    {
      "name": "M2172",
      "description": "Unequal limb length ( acquired ) Upper arm"
    },
    {
      "name": "M2173",
      "description": "Unequal limb length ( acquired ) Forearm"
    },
    {
      "name": "M2174",
      "description": "Unequal limb length ( acquired ) Hand"
    },
    {
      "name": "M2175",
      "description": "Unequal limb length ( acquired ) Pelvic region and thigh"
    },
    {
      "name": "M2176",
      "description": "Unequal limb length ( acquired ) Lower leg"
    },
    {
      "name": "M2177",
      "description": "Unequal limb length ( acquired ) Ankle and foot"
    },
    {
      "name": "M2178",
      "description": "Unequal limb length ( acquired ) Other"
    },
    {
      "name": "M2179",
      "description": "Unequal limb length ( acquired ) Site unspecified"
    },
    {
      "name": "M218",
      "description": "Other specified acquired deformities of limbs"
    },
    {
      "name": "M2180",
      "description": "Other specified acquired deformities of limbs Multiple sites"
    },
    {
      "name": "M2181",
      "description": "Other specified acquired deformities of limbs Shoulder region"
    },
    {
      "name": "M2182",
      "description": "Other specified acquired deformities of limbs Upper arm"
    },
    {
      "name": "M2183",
      "description": "Other specified acquired deformities of limbs Forearm"
    },
    {
      "name": "M2184",
      "description": "Other specified acquired deformities of limbs Hand"
    },
    {
      "name": "M2185",
      "description": "Other specified acquired deformities of limbs Pelvic region and thigh"
    },
    {
      "name": "M2186",
      "description": "Other specified acquired deformities of limbs Lower leg"
    },
    {
      "name": "M2187",
      "description": "Other specified acquired deformities of limbs Ankle and foot"
    },
    {
      "name": "M2188",
      "description": "Other specified acquired deformities of limbs Other"
    },
    {
      "name": "M2189",
      "description": "Other specified acquired deformities of limbs Site unspecified"
    },
    {
      "name": "M219",
      "description": "Acquired deformity of limb, unspecified"
    },
    {
      "name": "M2190",
      "description": "Acquired deformity of limb, unspecified Multiple sites"
    },
    {
      "name": "M2191",
      "description": "Acquired deformity of limb, unspecified Shoulder region"
    },
    {
      "name": "M2192",
      "description": "Acquired deformity of limb, unspecified Upper arm"
    },
    {
      "name": "M2193",
      "description": "Acquired deformity of limb, unspecified Forearm"
    },
    {
      "name": "M2194",
      "description": "Acquired deformity of limb, unspecified Hand"
    },
    {
      "name": "M2195",
      "description": "Acquired deformity of limb, unspecified Pelvic region and thigh"
    },
    {
      "name": "M2196",
      "description": "Acquired deformity of limb, unspecified Lower leg"
    },
    {
      "name": "M2197",
      "description": "Acquired deformity of limb, unspecified Ankle and foot"
    },
    {
      "name": "M2198",
      "description": "Acquired deformity of limb, unspecified Other"
    },
    {
      "name": "M2199",
      "description": "Acquired deformity of limb, unspecified Site unspecified"
    },
    {
      "name": "M220",
      "description": "Recurrent dislocation of patella"
    },
    {
      "name": "M221",
      "description": "Recurrent subluxation of patella"
    },
    {
      "name": "M222",
      "description": "Patellofemoral disorders"
    },
    {
      "name": "M223",
      "description": "Other derangements of patella"
    },
    {
      "name": "M224",
      "description": "Chondromalacia patellae"
    },
    {
      "name": "M228",
      "description": "Other disorders of patella"
    },
    {
      "name": "M229",
      "description": "Disorder of patella, unspecified"
    },
    {
      "name": "M230",
      "description": "Cystic meniscus"
    },
    {
      "name": "M2300",
      "description": "Cystic meniscus Multiple sites"
    },
    {
      "name": "M2301",
      "description": "Cystic meniscus Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2302",
      "description": "Cystic meniscus Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2303",
      "description": "Cystic meniscus Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2304",
      "description": "Cystic meniscus Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2305",
      "description": "Cystic meniscus Posterior horn of lateral meniscus"
    },
    {
      "name": "M2306",
      "description": "Cystic meniscus Other and unspecified lateral meniscus"
    },
    {
      "name": "M2307",
      "description": "Cystic meniscus Capsular ligament"
    },
    {
      "name": "M2309",
      "description": "Cystic meniscus Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M231",
      "description": "Discoid meniscus (congenital)"
    },
    {
      "name": "M2310",
      "description": "Discoid meniscus ( congenital ) Multiple sites"
    },
    {
      "name": "M2311",
      "description": "Discoid meniscus ( congenital ) Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2312",
      "description": "Discoid meniscus ( congenital ) Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2313",
      "description": "Discoid meniscus ( congenital ) Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2314",
      "description": "Discoid meniscus ( congenital ) Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2315",
      "description": "Discoid meniscus ( congenital ) Posterior horn of lateral meniscus"
    },
    {
      "name": "M2316",
      "description": "Discoid meniscus ( congenital ) Other and unspecified lateral meniscus"
    },
    {
      "name": "M2317",
      "description": "Discoid meniscus ( congenital ) Capsular ligament"
    },
    {
      "name": "M2319",
      "description": "Discoid meniscus ( congenital ) Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M232",
      "description": "Derangement of meniscus due to old tear or injury"
    },
    {
      "name": "M2320",
      "description": "Derangement of meniscus due to old tear or injury Multiple sites"
    },
    {
      "name": "M2321",
      "description": "Derangement of meniscus due to old tear or injury Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2322",
      "description": "Derangement of meniscus due to old tear or injury Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2323",
      "description": "Derangement of meniscus due to old tear or injury Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2324",
      "description": "Derangement of meniscus due to old tear or injury Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2325",
      "description": "Derangement of meniscus due to old tear or injury Posterior horn of lateral meniscus"
    },
    {
      "name": "M2326",
      "description": "Derangement of meniscus due to old tear or injury Other and unspecified lateral meniscus"
    },
    {
      "name": "M2327",
      "description": "Derangement of meniscus due to old tear or injury Capsular ligament"
    },
    {
      "name": "M2329",
      "description": "Derangement of meniscus due to old tear or injury Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M233",
      "description": "Other meniscus derangements"
    },
    {
      "name": "M2330",
      "description": "Other meniscus derangements Multiple sites"
    },
    {
      "name": "M2331",
      "description": "Other meniscus derangements Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2332",
      "description": "Other meniscus derangements Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2333",
      "description": "Other meniscus derangements Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2334",
      "description": "Other meniscus derangements Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2335",
      "description": "Other meniscus derangements Posterior horn of lateral meniscus"
    },
    {
      "name": "M2336",
      "description": "Other meniscus derangements Other and unspecified lateral meniscus"
    },
    {
      "name": "M2337",
      "description": "Other meniscus derangements Capsular ligament"
    },
    {
      "name": "M2339",
      "description": "Other meniscus derangements Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M234",
      "description": "Loose body in knee"
    },
    {
      "name": "M2340",
      "description": "Loose body in knee Multiple sites"
    },
    {
      "name": "M2341",
      "description": "Loose body in knee Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2342",
      "description": "Loose body in knee Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2343",
      "description": "Loose body in knee Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2344",
      "description": "Loose body in knee Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2345",
      "description": "Loose body in knee Posterior horn of lateral meniscus"
    },
    {
      "name": "M2346",
      "description": "Loose body in knee Other and unspecified lateral meniscus"
    },
    {
      "name": "M2347",
      "description": "Loose body in knee Capsular ligament"
    },
    {
      "name": "M2349",
      "description": "Loose body in knee Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M235",
      "description": "Chronic instability of knee"
    },
    {
      "name": "M2350",
      "description": "Chronic instability of knee Multiple sites"
    },
    {
      "name": "M2351",
      "description": "Chronic instability of knee Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2352",
      "description": "Chronic instability of knee Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2353",
      "description": "Chronic instability of knee Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2354",
      "description": "Chronic instability of knee Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2355",
      "description": "Chronic instability of knee Posterior horn of lateral meniscus"
    },
    {
      "name": "M2356",
      "description": "Chronic instability of knee Other and unspecified lateral meniscus"
    },
    {
      "name": "M2357",
      "description": "Chronic instability of knee Capsular ligament"
    },
    {
      "name": "M2359",
      "description": "Chronic instability of knee Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M236",
      "description": "Other spontaneous disruption of ligament(s) of knee"
    },
    {
      "name": "M2360",
      "description": "Other spontaneous disruption of ligament(s) of knee Multiple sites"
    },
    {
      "name": "M2361",
      "description": "Other spontaneous disruption of ligament(s) of knee Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2362",
      "description": "Other spontaneous disruption of ligament(s) of knee Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2363",
      "description": "Other spontaneous disruption of ligament(s) of knee Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2364",
      "description": "Other spontaneous disruption of ligament(s) of knee Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2365",
      "description": "Other spontaneous disruption of ligament(s) of knee Posterior horn of lateral meniscus"
    },
    {
      "name": "M2366",
      "description": "Other spontaneous disruption of ligament(s) of knee Other and unspecified lateral meniscus"
    },
    {
      "name": "M2367",
      "description": "Other spontaneous disruption of ligament(s) of knee Capsular ligament"
    },
    {
      "name": "M2369",
      "description": "Other spontaneous disruption of ligament(s) of knee Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M238",
      "description": "Other internal derangements of knee"
    },
    {
      "name": "M2380",
      "description": "Other internal derangements of knee Multiple sites"
    },
    {
      "name": "M2381",
      "description": "Other internal derangements of knee Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2382",
      "description": "Other internal derangements of knee Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2383",
      "description": "Other internal derangements of knee Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2384",
      "description": "Other internal derangements of knee Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2385",
      "description": "Other internal derangements of knee Posterior horn of lateral meniscus"
    },
    {
      "name": "M2386",
      "description": "Other internal derangements of knee Other and unspecified lateral meniscus"
    },
    {
      "name": "M2387",
      "description": "Other internal derangements of knee Capsular ligament"
    },
    {
      "name": "M2389",
      "description": "Other internal derangements of knee Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M239",
      "description": "Internal derangement of knee, unspecified"
    },
    {
      "name": "M2390",
      "description": "Internal derangement of knee, unspecified Multiple sites"
    },
    {
      "name": "M2391",
      "description": "Internal derangement of knee, unspecified Anterior cruciate ligament or Anterior horn of medial meniscus"
    },
    {
      "name": "M2392",
      "description": "Internal derangement of knee, unspecified Posterior cruciate ligament or Posterior horn of medial meniscus"
    },
    {
      "name": "M2393",
      "description": "Internal derangement of knee, unspecified Medial collateral ligament or Other and unspecified medial meniscus"
    },
    {
      "name": "M2394",
      "description": "Internal derangement of knee, unspecified Lateral collateral ligament or Anterior horn of lateral meniscus"
    },
    {
      "name": "M2395",
      "description": "Internal derangement of knee, unspecified Posterior horn of lateral meniscus"
    },
    {
      "name": "M2396",
      "description": "Internal derangement of knee, unspecified Other and unspecified lateral meniscus"
    },
    {
      "name": "M2397",
      "description": "Internal derangement of knee, unspecified Capsular ligament"
    },
    {
      "name": "M2399",
      "description": "Internal derangement of knee, unspecified Unspecified ligament or Unspecified meniscus"
    },
    {
      "name": "M240",
      "description": "Loose body of joint"
    },
    {
      "name": "M2400",
      "description": "Loose body in joint Multiple sites"
    },
    {
      "name": "M2401",
      "description": "Loose body in joint Shoulder region"
    },
    {
      "name": "M2402",
      "description": "Loose body in joint Upper arm"
    },
    {
      "name": "M2403",
      "description": "Loose body in joint Forearm"
    },
    {
      "name": "M2404",
      "description": "Loose body in joint Hand"
    },
    {
      "name": "M2405",
      "description": "Loose body in joint Pelvic region and thigh"
    },
    {
      "name": "M2406",
      "description": "Loose body in joint Lower leg"
    },
    {
      "name": "M2407",
      "description": "Loose body in joint Ankle and foot"
    },
    {
      "name": "M2408",
      "description": "Loose body in joint Other"
    },
    {
      "name": "M2409",
      "description": "Loose body in joint Site unspecified"
    },
    {
      "name": "M241",
      "description": "Other articular cartilage disorders"
    },
    {
      "name": "M2410",
      "description": "Other auticular cartilage disorders Multiple sites"
    },
    {
      "name": "M2411",
      "description": "Other auticular cartilage disorders Shoulder region"
    },
    {
      "name": "M2412",
      "description": "Other auticular cartilage disorders Upper arm"
    },
    {
      "name": "M2413",
      "description": "Other auticular cartilage disorders Forearm"
    },
    {
      "name": "M2414",
      "description": "Other auticular cartilage disorders Hand"
    },
    {
      "name": "M2415",
      "description": "Other auticular cartilage disorders Pelvic region and thigh"
    },
    {
      "name": "M2416",
      "description": "Other auticular cartilage disorders Lower leg"
    },
    {
      "name": "M2417",
      "description": "Other auticular cartilage disorders Ankle and foot"
    },
    {
      "name": "M2418",
      "description": "Other auticular cartilage disorders Other"
    },
    {
      "name": "M2419",
      "description": "Other auticular cartilage disorders Site unspecified"
    },
    {
      "name": "M242",
      "description": "Disorder of ligament"
    },
    {
      "name": "M2420",
      "description": "Disorder of ligament Multiple sites"
    },
    {
      "name": "M2421",
      "description": "Disorder of ligament Shoulder region"
    },
    {
      "name": "M2422",
      "description": "Disorder of ligament Upper arm"
    },
    {
      "name": "M2423",
      "description": "Disorder of ligament Forearm"
    },
    {
      "name": "M2424",
      "description": "Disorder of ligament Hand"
    },
    {
      "name": "M2425",
      "description": "Disorder of ligament Pelvic region and thigh"
    },
    {
      "name": "M2426",
      "description": "Disorder of ligament Lower leg"
    },
    {
      "name": "M2427",
      "description": "Disorder of ligament Ankle and foot"
    },
    {
      "name": "M2428",
      "description": "Disorder of ligament Other"
    },
    {
      "name": "M2429",
      "description": "Disorder of ligament Site unspecified"
    },
    {
      "name": "M243",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified"
    },
    {
      "name": "M2430",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Multiple sites"
    },
    {
      "name": "M2431",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Shoulder region"
    },
    {
      "name": "M2432",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Upper arm"
    },
    {
      "name": "M2433",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Forearm"
    },
    {
      "name": "M2434",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Hand"
    },
    {
      "name": "M2435",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M2436",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Lower leg"
    },
    {
      "name": "M2437",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M2438",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Other"
    },
    {
      "name": "M2439",
      "description": "Pathological dislocation and subluxation of joint, not elsewhere classified Site unspecified"
    },
    {
      "name": "M244",
      "description": "Recurrent dislocation and subluxation of joint"
    },
    {
      "name": "M2440",
      "description": "Recurrent dislocation and subluxation of joint Multiple sites"
    },
    {
      "name": "M2441",
      "description": "Recurrent dislocation and subluxation of joint Shoulder region"
    },
    {
      "name": "M2442",
      "description": "Recurrent dislocation and subluxation of joint Upper arm"
    },
    {
      "name": "M2443",
      "description": "Recurrent dislocation and subluxation of joint Forearm"
    },
    {
      "name": "M2444",
      "description": "Recurrent dislocation and subluxation of joint Hand"
    },
    {
      "name": "M2445",
      "description": "Recurrent dislocation and subluxation of joint Pelvic region and thigh"
    },
    {
      "name": "M2446",
      "description": "Recurrent dislocation and subluxation of joint Lower leg"
    },
    {
      "name": "M2447",
      "description": "Recurrent dislocation and subluxation of joint Ankle and foot"
    },
    {
      "name": "M2448",
      "description": "Recurrent dislocation and subluxation of joint Other"
    },
    {
      "name": "M2449",
      "description": "Recurrent dislocation and subluxation of joint Site unspecified"
    },
    {
      "name": "M245",
      "description": "Contracture of joint"
    },
    {
      "name": "M2450",
      "description": "Contracture of joint Multiple sites"
    },
    {
      "name": "M2451",
      "description": "Contracture of joint Shoulder region"
    },
    {
      "name": "M2452",
      "description": "Contracture of joint Upper arm"
    },
    {
      "name": "M2453",
      "description": "Contracture of joint Forearm"
    },
    {
      "name": "M2454",
      "description": "Contracture of joint Hand"
    },
    {
      "name": "M2455",
      "description": "Contracture of joint Pelvic region and thigh"
    },
    {
      "name": "M2456",
      "description": "Contracture of joint Lower leg"
    },
    {
      "name": "M2457",
      "description": "Contracture of joint Ankle and foot"
    },
    {
      "name": "M2458",
      "description": "Contracture of joint Other"
    },
    {
      "name": "M2459",
      "description": "Contracture of joint Site unspecified"
    },
    {
      "name": "M246",
      "description": "Ankylosis of joint"
    },
    {
      "name": "M2460",
      "description": "Ankylosis of joint Multiple sites"
    },
    {
      "name": "M2461",
      "description": "Ankylosis of joint Shoulder region"
    },
    {
      "name": "M2462",
      "description": "Ankylosis of joint Upper arm"
    },
    {
      "name": "M2463",
      "description": "Ankylosis of joint Forearm"
    },
    {
      "name": "M2464",
      "description": "Ankylosis of joint Hand"
    },
    {
      "name": "M2465",
      "description": "Ankylosis of joint Pelvic region and thigh"
    },
    {
      "name": "M2466",
      "description": "Ankylosis of joint Lower leg"
    },
    {
      "name": "M2467",
      "description": "Ankylosis of joint Ankle and foot"
    },
    {
      "name": "M2468",
      "description": "Ankylosis of joint Other"
    },
    {
      "name": "M2469",
      "description": "Ankylosis of joint Site unspecified"
    },
    {
      "name": "M247",
      "description": "Protrusio acetabuli"
    },
    {
      "name": "M2470",
      "description": "Protrusio acetabuli Multiple sites"
    },
    {
      "name": "M2471",
      "description": "Protrusio acetabuli Shoulder region"
    },
    {
      "name": "M2472",
      "description": "Protrusio acetabuli Upper arm"
    },
    {
      "name": "M2473",
      "description": "Protrusio acetabuli Forearm"
    },
    {
      "name": "M2474",
      "description": "Protrusio acetabuli Hand"
    },
    {
      "name": "M2475",
      "description": "Protrusio acetabuli Pelvic region and thigh"
    },
    {
      "name": "M2476",
      "description": "Protrusio acetabuli Lower leg"
    },
    {
      "name": "M2477",
      "description": "Protrusio acetabuli Ankle and foot"
    },
    {
      "name": "M2478",
      "description": "Protrusio acetabuli Other"
    },
    {
      "name": "M2479",
      "description": "Protrusio acetabuli Site unspecified"
    },
    {
      "name": "M248",
      "description": "Other specific joint derangements, not elsewhere classified"
    },
    {
      "name": "M2480",
      "description": "Other specific joint derangements, not elsewhere classified Multiple sites"
    },
    {
      "name": "M2481",
      "description": "Other specific joint derangements, not elsewhere classified Shoulder region"
    },
    {
      "name": "M2482",
      "description": "Other specific joint derangements, not elsewhere classified Upper arm"
    },
    {
      "name": "M2483",
      "description": "Other specific joint derangements, not elsewhere classified Forearm"
    },
    {
      "name": "M2484",
      "description": "Other specific joint derangements, not elsewhere classified Hand"
    },
    {
      "name": "M2485",
      "description": "Other specific joint derangements, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M2486",
      "description": "Other specific joint derangements, not elsewhere classified Lower leg"
    },
    {
      "name": "M2487",
      "description": "Other specific joint derangements, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M2488",
      "description": "Other specific joint derangements, not elsewhere classified Other"
    },
    {
      "name": "M2489",
      "description": "Other specific joint derangements, not elsewhere classified Site unspecified"
    },
    {
      "name": "M249",
      "description": "Joint derangement, unspecified"
    },
    {
      "name": "M2490",
      "description": "Joint derangement, unspecified Multiple sites"
    },
    {
      "name": "M2491",
      "description": "Joint derangement, unspecified Shoulder region"
    },
    {
      "name": "M2492",
      "description": "Joint derangement, unspecified Upper arm"
    },
    {
      "name": "M2493",
      "description": "Joint derangement, unspecified Forearm"
    },
    {
      "name": "M2494",
      "description": "Joint derangement, unspecified Hand"
    },
    {
      "name": "M2495",
      "description": "Joint derangement, unspecified Pelvic region and thigh"
    },
    {
      "name": "M2496",
      "description": "Joint derangement, unspecified Lower leg"
    },
    {
      "name": "M2497",
      "description": "Joint derangement, unspecified Ankle and foot"
    },
    {
      "name": "M2498",
      "description": "Joint derangement, unspecified Other"
    },
    {
      "name": "M2499",
      "description": "Joint derangement, unspecified Site unspecified"
    },
    {
      "name": "M250",
      "description": "Haemarthrosis"
    },
    {
      "name": "M2500",
      "description": "Haemarthrosis Multiple sites"
    },
    {
      "name": "M2501",
      "description": "Haemarthrosis Shoulder region"
    },
    {
      "name": "M2502",
      "description": "Haemarthrosis Upper arm"
    },
    {
      "name": "M2503",
      "description": "Haemarthrosis Forearm"
    },
    {
      "name": "M2504",
      "description": "Haemarthrosis Hand"
    },
    {
      "name": "M2505",
      "description": "Haemarthrosis Pelvic region and thigh"
    },
    {
      "name": "M2506",
      "description": "Haemarthrosis Lower leg"
    },
    {
      "name": "M2507",
      "description": "Haemarthrosis Ankle and foot"
    },
    {
      "name": "M2508",
      "description": "Haemarthrosis Other"
    },
    {
      "name": "M2509",
      "description": "Haemarthrosis Site unspecified"
    },
    {
      "name": "M251",
      "description": "Fistula of joint"
    },
    {
      "name": "M2510",
      "description": "Fistula of joint Multiple sites"
    },
    {
      "name": "M2511",
      "description": "Fistula of joint Shoulder region"
    },
    {
      "name": "M2512",
      "description": "Fistula of joint Upper arm"
    },
    {
      "name": "M2513",
      "description": "Fistula of joint Forearm"
    },
    {
      "name": "M2514",
      "description": "Fistula of joint Hand"
    },
    {
      "name": "M2515",
      "description": "Fistula of joint Pelvic region and thigh"
    },
    {
      "name": "M2516",
      "description": "Fistula of joint Lower leg"
    },
    {
      "name": "M2517",
      "description": "Fistula of joint Ankle and foot"
    },
    {
      "name": "M2518",
      "description": "Fistula of joint Other"
    },
    {
      "name": "M2519",
      "description": "Fistula of joint Site unspecified"
    },
    {
      "name": "M252",
      "description": "Flail joint"
    },
    {
      "name": "M2520",
      "description": "Flail joint Multiple sites"
    },
    {
      "name": "M2521",
      "description": "Flail joint Shoulder region"
    },
    {
      "name": "M2522",
      "description": "Flail joint Upper arm"
    },
    {
      "name": "M2523",
      "description": "Flail joint Forearm"
    },
    {
      "name": "M2524",
      "description": "Flail joint Hand"
    },
    {
      "name": "M2525",
      "description": "Flail joint Pelvic region and thigh"
    },
    {
      "name": "M2526",
      "description": "Flail joint Lower leg"
    },
    {
      "name": "M2527",
      "description": "Flail joint Ankle and foot"
    },
    {
      "name": "M2528",
      "description": "Flail joint Other"
    },
    {
      "name": "M2529",
      "description": "Flail joint Site unspecified"
    },
    {
      "name": "M253",
      "description": "Other instability of joint"
    },
    {
      "name": "M2530",
      "description": "Other instability of joint Multiple sites"
    },
    {
      "name": "M2531",
      "description": "Other instability of joint Shoulder region"
    },
    {
      "name": "M2532",
      "description": "Other instability of joint Upper arm"
    },
    {
      "name": "M2533",
      "description": "Other instability of joint Forearm"
    },
    {
      "name": "M2534",
      "description": "Other instability of joint Hand"
    },
    {
      "name": "M2535",
      "description": "Other instability of joint Pelvic region and thigh"
    },
    {
      "name": "M2536",
      "description": "Other instability of joint Lower leg"
    },
    {
      "name": "M2537",
      "description": "Other instability of joint Ankle and foot"
    },
    {
      "name": "M2538",
      "description": "Other instability of joint Other"
    },
    {
      "name": "M2539",
      "description": "Other instability of joint Site unspecified"
    },
    {
      "name": "M254",
      "description": "Effusion of joint"
    },
    {
      "name": "M2540",
      "description": "Effusion of joint Multiple site"
    },
    {
      "name": "M2541",
      "description": "Effusion of joint Shoulder region"
    },
    {
      "name": "M2542",
      "description": "Effusion of joint Upper arm"
    },
    {
      "name": "M2543",
      "description": "Effusion of joint Forearm"
    },
    {
      "name": "M2544",
      "description": "Effusion of joint Hand"
    },
    {
      "name": "M2545",
      "description": "Effusion of joint Pelvic region and thigh"
    },
    {
      "name": "M2546",
      "description": "Effusion of joint Lower leg"
    },
    {
      "name": "M2547",
      "description": "Effusion of joint Ankle and foot"
    },
    {
      "name": "M2548",
      "description": "Effusion of joint Other"
    },
    {
      "name": "M2549",
      "description": "Effusion of joint Site unspecified"
    },
    {
      "name": "M255",
      "description": "Pain in joint"
    },
    {
      "name": "M2550",
      "description": "Pain in joint Multiple sites"
    },
    {
      "name": "M2551",
      "description": "Pain in joint Shoulder region"
    },
    {
      "name": "M2552",
      "description": "Pain in joint Upper arm"
    },
    {
      "name": "M2553",
      "description": "Pain in joint Forearm"
    },
    {
      "name": "M2554",
      "description": "Pain in joint Hand"
    },
    {
      "name": "M2555",
      "description": "Pain in joint Pelvic region and thigh"
    },
    {
      "name": "M2556",
      "description": "Pain in joint Lower leg"
    },
    {
      "name": "M2557",
      "description": "Pain in joint Ankle and foot"
    },
    {
      "name": "M2558",
      "description": "Pain in joint Other"
    },
    {
      "name": "M2559",
      "description": "Pain in joint Site unspecified"
    },
    {
      "name": "M256",
      "description": "Stiffness of joint, not elsewhere classified"
    },
    {
      "name": "M2560",
      "description": "Stiffness of joint, not elswhere classified Multiple sites"
    },
    {
      "name": "M2561",
      "description": "Stiffness of joint, not elswhere classified Shoulder region"
    },
    {
      "name": "M2562",
      "description": "Stiffness of joint, not elswhere classified Upper arm"
    },
    {
      "name": "M2563",
      "description": "Stiffness of joint, not elswhere classified Forearm"
    },
    {
      "name": "M2564",
      "description": "Stiffness of joint, not elswhere classified Hand"
    },
    {
      "name": "M2565",
      "description": "Stiffness of joint, not elswhere classified Pelvic region and thigh"
    },
    {
      "name": "M2566",
      "description": "Stiffness of joint, not elswhere classified Lower leg"
    },
    {
      "name": "M2567",
      "description": "Stiffness of joint, not elswhere classified Ankle and foot"
    },
    {
      "name": "M2568",
      "description": "Stiffness of joint, not elswhere classified Other"
    },
    {
      "name": "M2569",
      "description": "Stiffness of joint, not elswhere classified Site unspecified"
    },
    {
      "name": "M257",
      "description": "Osteophyte"
    },
    {
      "name": "M2570",
      "description": "Osteophyte Multiple sites"
    },
    {
      "name": "M2571",
      "description": "Osteophyte Shoulder region"
    },
    {
      "name": "M2572",
      "description": "Osteophyte Upper arm"
    },
    {
      "name": "M2573",
      "description": "Osteophyte Forearm"
    },
    {
      "name": "M2574",
      "description": "Osteophyte Hand"
    },
    {
      "name": "M2575",
      "description": "Osteophyte Pelvic region and thigh"
    },
    {
      "name": "M2576",
      "description": "Osteophyte Lower leg"
    },
    {
      "name": "M2577",
      "description": "Osteophyte Ankel and foot"
    },
    {
      "name": "M2578",
      "description": "Osteophyte Other"
    },
    {
      "name": "M2579",
      "description": "Osteophyte Site unspecified"
    },
    {
      "name": "M258",
      "description": "Other specified joint disorders"
    },
    {
      "name": "M2580",
      "description": "Other specified joint disorders Multiple sites"
    },
    {
      "name": "M2581",
      "description": "Other specified joint disorders Shoulder region"
    },
    {
      "name": "M2582",
      "description": "Other specified joint disorders Upper arm"
    },
    {
      "name": "M2583",
      "description": "Other specified joint disorders Forearm"
    },
    {
      "name": "M2584",
      "description": "Other specified joint disorders Hand"
    },
    {
      "name": "M2585",
      "description": "Other specified joint disorders Pelvic region and thigh"
    },
    {
      "name": "M2586",
      "description": "Other specified joint disorders Lower leg"
    },
    {
      "name": "M2587",
      "description": "Other specified joint disorders Ankle and foot"
    },
    {
      "name": "M2588",
      "description": "Other specified joint disorders Other"
    },
    {
      "name": "M2589",
      "description": "Other specified joint disorders Site unspecified"
    },
    {
      "name": "M259",
      "description": "Joint disorder, unspecified"
    },
    {
      "name": "M2590",
      "description": "Joint disorder, unspecified Multiple sites"
    },
    {
      "name": "M2591",
      "description": "Joint disorder, unspecified Shoulder region"
    },
    {
      "name": "M2592",
      "description": "Joint disorder, unspecified Upper arm"
    },
    {
      "name": "M2593",
      "description": "Joint disorder, unspecified Forearm"
    },
    {
      "name": "M2594",
      "description": "Joint disorder, unspecified Hand"
    },
    {
      "name": "M2595",
      "description": "Joint disorder, unspecified Pelvic region and thigh"
    },
    {
      "name": "M2596",
      "description": "Joint disorder, unspecified Lower leg"
    },
    {
      "name": "M2597",
      "description": "Joint disorder, unspecified Ankle and foot"
    },
    {
      "name": "M2598",
      "description": "Joint disorder, unspecified Other"
    },
    {
      "name": "M2599",
      "description": "Joint disorder, unspecified Site unspecified"
    },
    {
      "name": "M300",
      "description": "Polyarteritis nodosa"
    },
    {
      "name": "M301",
      "description": "Polyarteritis with lung involvement [Churg-Strauss]"
    },
    {
      "name": "M302",
      "description": "Juvenile polyarteritis"
    },
    {
      "name": "M303",
      "description": "Mucocutaneous lymph node syndrome [Kawasaki]"
    },
    {
      "name": "M308",
      "description": "Other conditions related to polyarteritis nodosa"
    },
    {
      "name": "M310",
      "description": "Hypersensitivity angiitis"
    },
    {
      "name": "M311",
      "description": "Thrombotic microangiopathy"
    },
    {
      "name": "M312",
      "description": "Lethal midline granuloma"
    },
    {
      "name": "M313",
      "description": "Wegener’s granulomatosis"
    },
    {
      "name": "M314",
      "description": "Aortic arch syndrome [Takayasu]"
    },
    {
      "name": "M315",
      "description": "Giant cell arteritis with polymyalgia rheumatica"
    },
    {
      "name": "M316",
      "description": "Other giant cell arteritis"
    },
    {
      "name": "M317",
      "description": "Microscopic polyangiitis"
    },
    {
      "name": "M318",
      "description": "Other specified necrotizing vasculopathies"
    },
    {
      "name": "M319",
      "description": "Necrotizing vasculopathy, unspecified"
    },
    {
      "name": "M320",
      "description": "Drug-induced systemic lupus erythematosus"
    },
    {
      "name": "M321",
      "description": "Systemic lupus erythematosus with organ or system involvement"
    },
    {
      "name": "M328",
      "description": "Other forms of systemic lupus erythematosus"
    },
    {
      "name": "M329",
      "description": "Systemic lupus erythematosus, unspecified"
    },
    {
      "name": "M330",
      "description": "Juvenile dermatomyositis"
    },
    {
      "name": "M331",
      "description": "Other dermatomyositis"
    },
    {
      "name": "M332",
      "description": "Polymyositis"
    },
    {
      "name": "M339",
      "description": "Dermatopolymyositis, unspecified"
    },
    {
      "name": "M340",
      "description": "Progressive systemic sclerosis"
    },
    {
      "name": "M341",
      "description": "CR(E)ST syndrome"
    },
    {
      "name": "M342",
      "description": "Systemic sclerosis induced by drugs and chemicals"
    },
    {
      "name": "M348",
      "description": "Other forms of systemic sclerosis"
    },
    {
      "name": "M349",
      "description": "Systemic sclerosis, unspecified"
    },
    {
      "name": "M350",
      "description": "Sicca syndrome [Sjögren]"
    },
    {
      "name": "M351",
      "description": "Other overlap syndromes"
    },
    {
      "name": "M352",
      "description": "Behçet’s disease"
    },
    {
      "name": "M353",
      "description": "Polymyalgia rheumatica"
    },
    {
      "name": "M354",
      "description": "Diffuse (eosinophilic) fasciitis"
    },
    {
      "name": "M355",
      "description": "Multifocal fibrosclerosis"
    },
    {
      "name": "M356",
      "description": "Relapsing panniculitis [Weber-Christian]"
    },
    {
      "name": "M357",
      "description": "Hypermobility syndrome"
    },
    {
      "name": "M358",
      "description": "Other specified systemic involvement of connective tissue"
    },
    {
      "name": "M359",
      "description": "Systemic involvement of connective tissue, unspecified"
    },
    {
      "name": "M360",
      "description": "Dermato(poly)myositis in neoplastic disease (C00-D48)"
    },
    {
      "name": "M361",
      "description": "Arthropathy in neoplastic disease (C00-D48)"
    },
    {
      "name": "M362",
      "description": "Haemophilic arthropathy (D66-D68)"
    },
    {
      "name": "M363",
      "description": "Arthropathy in other blood disorders (D50-D76)"
    },
    {
      "name": "M364",
      "description": "Arthropathy in hypersensitivity reactions classified elsewhere"
    },
    {
      "name": "M368",
      "description": "Systemic disorders of connective tissue in other diseases classified elsewhere"
    },
    {
      "name": "M400",
      "description": "Postural kyphosis"
    },
    {
      "name": "M4000",
      "description": "Postural kyphosis Multiple sites in spine"
    },
    {
      "name": "M4001",
      "description": "Postural kyphosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4002",
      "description": "Postural kyphosis Cervical region"
    },
    {
      "name": "M4003",
      "description": "Postural kyphosis Cervicothoracic region"
    },
    {
      "name": "M4004",
      "description": "Postural kyphosis Thoracic region"
    },
    {
      "name": "M4005",
      "description": "Postural kyphosis Thoracolumbar region"
    },
    {
      "name": "M4006",
      "description": "Postural kyphosis Lumbar region"
    },
    {
      "name": "M4007",
      "description": "Postural kyphosis Lumbosacral region"
    },
    {
      "name": "M4008",
      "description": "Postural kyphosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4009",
      "description": "Postural kyphosis Site unspecified"
    },
    {
      "name": "M401",
      "description": "Other secondary kyphosis"
    },
    {
      "name": "M4010",
      "description": "Other secondary kyphosis Multiple sites in spine"
    },
    {
      "name": "M4011",
      "description": "Other secondary kyphosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4012",
      "description": "Other secondary kyphosis Cervical region"
    },
    {
      "name": "M4013",
      "description": "Other secondary kyphosis Cervicothoracic region"
    },
    {
      "name": "M4014",
      "description": "Other secondary kyphosis Thoracic region"
    },
    {
      "name": "M4015",
      "description": "Other secondary kyphosis Thoracolumbar region"
    },
    {
      "name": "M4016",
      "description": "Other secondary kyphosis Lumbar region"
    },
    {
      "name": "M4017",
      "description": "Other secondary kyphosis Lumbosacral region"
    },
    {
      "name": "M4018",
      "description": "Other secondary kyphosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4019",
      "description": "Other secondary kyphosis Site unspecified"
    },
    {
      "name": "M402",
      "description": "Other and unspecified kyphosis"
    },
    {
      "name": "M4020",
      "description": "Other and unspecified kyphosis Multiple sites in spine"
    },
    {
      "name": "M4021",
      "description": "Other and unspecified kyphosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4022",
      "description": "Other and unspecified kyphosis Cervical region"
    },
    {
      "name": "M4023",
      "description": "Other and unspecified kyphosis Cervicothoracic region"
    },
    {
      "name": "M4024",
      "description": "Other and unspecified kyphosis Thoracic region"
    },
    {
      "name": "M4025",
      "description": "Other and unspecified kyphosis Thoracolumbar region"
    },
    {
      "name": "M4026",
      "description": "Other and unspecified kyphosis Lumbar region"
    },
    {
      "name": "M4027",
      "description": "Other and unspecified kyphosis Lumbosacral region"
    },
    {
      "name": "M4028",
      "description": "Other and unspecified kyphosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4029",
      "description": "Other and unspecified kyphosis Site unspecified"
    },
    {
      "name": "M403",
      "description": "Flatback syndrome"
    },
    {
      "name": "M4030",
      "description": "Flatback syndrome Multiple sites in spine"
    },
    {
      "name": "M4031",
      "description": "Flatback syndrome Occipito-atlanto-axial region"
    },
    {
      "name": "M4032",
      "description": "Flatback syndrome Cervical region"
    },
    {
      "name": "M4033",
      "description": "Flatback syndrome Cervicothoracic region"
    },
    {
      "name": "M4034",
      "description": "Flatback syndrome Thoracic region"
    },
    {
      "name": "M4035",
      "description": "Flatback syndrome Thoracolumbar region"
    },
    {
      "name": "M4036",
      "description": "Flatback syndrome Lumbar region"
    },
    {
      "name": "M4037",
      "description": "Flatback syndrome Lumbosacral region"
    },
    {
      "name": "M4038",
      "description": "Flatback syndrome Sacral and sacrococcygeal region"
    },
    {
      "name": "M4039",
      "description": "Flatback syndrome Site unspecified"
    },
    {
      "name": "M404",
      "description": "Other lordosis"
    },
    {
      "name": "M4040",
      "description": "Other lordosis Multiple sites in spine"
    },
    {
      "name": "M4041",
      "description": "Other lordosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4042",
      "description": "Other lordosis Cervical region"
    },
    {
      "name": "M4043",
      "description": "Other lordosis Cervicothoracic region"
    },
    {
      "name": "M4044",
      "description": "Other lordosis Thoracic region"
    },
    {
      "name": "M4045",
      "description": "Other lordosis Thoracolumbar region"
    },
    {
      "name": "M4046",
      "description": "Other lordosis Lumbar region"
    },
    {
      "name": "M4047",
      "description": "Other lordosis Lumbosacral region"
    },
    {
      "name": "M4048",
      "description": "Other lordosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4049",
      "description": "Other lordosis Site unspecified"
    },
    {
      "name": "M405",
      "description": "Lordosis, unspecified"
    },
    {
      "name": "M4050",
      "description": "Lordosis, unspecified Multiple sites in spine"
    },
    {
      "name": "M4051",
      "description": "Lordosis, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4052",
      "description": "Lordosis, unspecified Cervical region"
    },
    {
      "name": "M4053",
      "description": "Lordosis, unspecified Cervicothoracic region"
    },
    {
      "name": "M4054",
      "description": "Lordosis, unspecified Thoracic region"
    },
    {
      "name": "M4055",
      "description": "Lordosis, unspecified Thoracolumbar region"
    },
    {
      "name": "M4056",
      "description": "Lordosis, unspecified Lumbar region"
    },
    {
      "name": "M4057",
      "description": "Lordosis, unspecified Lumbosacral region"
    },
    {
      "name": "M4058",
      "description": "Lordosis, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4059",
      "description": "Lordosis, unspecified Site unspecified"
    },
    {
      "name": "M410",
      "description": "Infantile idiopathic scoliosis"
    },
    {
      "name": "M4100",
      "description": "Infantile idiopathic scoliosis Multiple sites in spine"
    },
    {
      "name": "M4101",
      "description": "Infantile idiopathic scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4102",
      "description": "Infantile idiopathic scoliosis Cervical region"
    },
    {
      "name": "M4103",
      "description": "Infantile idiopathic scoliosis Cervicothoracic region"
    },
    {
      "name": "M4104",
      "description": "Infantile idiopathic scoliosis Thoracic region"
    },
    {
      "name": "M4105",
      "description": "Infantile idiopathic scoliosis Thoracolumbar region"
    },
    {
      "name": "M4106",
      "description": "Infantile idiopathic scoliosis Lumbar region"
    },
    {
      "name": "M4107",
      "description": "Infantile idiopathic scoliosis Lumbosacral region"
    },
    {
      "name": "M4108",
      "description": "Infantile idiopathic scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4109",
      "description": "Infantile idiopathic scoliosis Site unspecified"
    },
    {
      "name": "M411",
      "description": "Juvenile idiopathic scoliosis"
    },
    {
      "name": "M4110",
      "description": "Juvenile idiopathic scoliosis Multiple sites in spine"
    },
    {
      "name": "M4111",
      "description": "Juvenile idiopathic scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4112",
      "description": "Juvenile idiopathic scoliosis Cervical region"
    },
    {
      "name": "M4113",
      "description": "Juvenile idiopathic scoliosis Cervicothoracic region"
    },
    {
      "name": "M4114",
      "description": "Juvenile idiopathic scoliosis Thoracic region"
    },
    {
      "name": "M4115",
      "description": "Juvenile idiopathic scoliosis Thoracolumbar region"
    },
    {
      "name": "M4116",
      "description": "Juvenile idiopathic scoliosis Lumbar region"
    },
    {
      "name": "M4117",
      "description": "Juvenile idiopathic scoliosis Lumbosacral region"
    },
    {
      "name": "M4118",
      "description": "Juvenile idiopathic scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4119",
      "description": "Juvenile idiopathic scoliosis Site unspecified"
    },
    {
      "name": "M412",
      "description": "Other idiopathic scoliosis"
    },
    {
      "name": "M4120",
      "description": "Other idiopathic scoliosis Multiple sites in spine"
    },
    {
      "name": "M4121",
      "description": "Other idiopathic scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4122",
      "description": "Other idiopathic scoliosis Cervical region"
    },
    {
      "name": "M4123",
      "description": "Other idiopathic scoliosis Cervicothoracic region"
    },
    {
      "name": "M4124",
      "description": "Other idiopathic scoliosis Thoracic region"
    },
    {
      "name": "M4125",
      "description": "Other idiopathic scoliosis Thoracolumbar region"
    },
    {
      "name": "M4126",
      "description": "Other idiopathic scoliosis Lumbar region"
    },
    {
      "name": "M4127",
      "description": "Other idiopathic scoliosis Lumbosacral region"
    },
    {
      "name": "M4128",
      "description": "Other idiopathic scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4129",
      "description": "Other idiopathic scoliosis Site unspecified"
    },
    {
      "name": "M413",
      "description": "Thoracogenic scoliosis"
    },
    {
      "name": "M4130",
      "description": "Thoracogenic scoliosis Multiple sites in spine"
    },
    {
      "name": "M4131",
      "description": "Thoracogenic scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4132",
      "description": "Thoracogenic scoliosis Cervical region"
    },
    {
      "name": "M4133",
      "description": "Thoracogenic scoliosis Cervicothoracic region"
    },
    {
      "name": "M4134",
      "description": "Thoracogenic scoliosis Thoracic region"
    },
    {
      "name": "M4135",
      "description": "Thoracogenic scoliosis Thoracolumbar region"
    },
    {
      "name": "M4136",
      "description": "Thoracogenic scoliosis Lumbar region"
    },
    {
      "name": "M4137",
      "description": "Thoracogenic scoliosis Lumbosacral region"
    },
    {
      "name": "M4138",
      "description": "Thoracogenic scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4139",
      "description": "Thoracogenic scoliosis Site unspecified"
    },
    {
      "name": "M414",
      "description": "Neuromuscular scoliosis"
    },
    {
      "name": "M4140",
      "description": "Neuromuscular scoliosis Multiple sites in spine"
    },
    {
      "name": "M4141",
      "description": "Neuromuscular scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4142",
      "description": "Neuromuscular scoliosis Cervical region"
    },
    {
      "name": "M4143",
      "description": "Neuromuscular scoliosis Cervicothoracic region"
    },
    {
      "name": "M4144",
      "description": "Neuromuscular scoliosis Thoracic region"
    },
    {
      "name": "M4145",
      "description": "Neuromuscular scoliosis Thoracolumbar region"
    },
    {
      "name": "M4146",
      "description": "Neuromuscular scoliosis Lumbar region"
    },
    {
      "name": "M4147",
      "description": "Neuromuscular scoliosis Lumbosacral region"
    },
    {
      "name": "M4148",
      "description": "Neuromuscular scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4149",
      "description": "Neuromuscular scoliosis Site unspecified"
    },
    {
      "name": "M415",
      "description": "Other secondary scoliosis"
    },
    {
      "name": "M4150",
      "description": "Other secondary scoliosis Multiple sites in spine"
    },
    {
      "name": "M4151",
      "description": "Other secondary scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4152",
      "description": "Other secondary scoliosis Cervical region"
    },
    {
      "name": "M4153",
      "description": "Other secondary scoliosis Cervicothoracic region"
    },
    {
      "name": "M4154",
      "description": "Other secondary scoliosis Thoracic region"
    },
    {
      "name": "M4155",
      "description": "Other secondary scoliosis Thoracolumbar region"
    },
    {
      "name": "M4156",
      "description": "Other secondary scoliosis Lumbar region"
    },
    {
      "name": "M4157",
      "description": "Other secondary scoliosis Lumbosacral region"
    },
    {
      "name": "M4158",
      "description": "Other secondary scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4159",
      "description": "Other secondary scoliosis Site unspecified"
    },
    {
      "name": "M418",
      "description": "Other forms of scoliosis"
    },
    {
      "name": "M4180",
      "description": "Other forms of scoliosis Multiple sites in spine"
    },
    {
      "name": "M4181",
      "description": "Other forms of scoliosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4182",
      "description": "Other forms of scoliosis Cervical region"
    },
    {
      "name": "M4183",
      "description": "Other forms of scoliosis Cervicothoracic region"
    },
    {
      "name": "M4184",
      "description": "Other forms of scoliosis Thoracic region"
    },
    {
      "name": "M4185",
      "description": "Other forms of scoliosis Thoracolumbar region"
    },
    {
      "name": "M4186",
      "description": "Other forms of scoliosis Lumbar region"
    },
    {
      "name": "M4187",
      "description": "Other forms of scoliosis Lumbosacral region"
    },
    {
      "name": "M4188",
      "description": "Other forms of scoliosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4189",
      "description": "Other forms of scoliosis Site unspecified"
    },
    {
      "name": "M419",
      "description": "Scoliosis, unspecified"
    },
    {
      "name": "M4190",
      "description": "Scoliosis, unspecified Multiple sites in spine"
    },
    {
      "name": "M4191",
      "description": "Scoliosis, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4192",
      "description": "Scoliosis, unspecified Cervical region"
    },
    {
      "name": "M4193",
      "description": "Scoliosis, unspecified Cervicothoracic region"
    },
    {
      "name": "M4194",
      "description": "Scoliosis, unspecified Thoracic region"
    },
    {
      "name": "M4195",
      "description": "Scoliosis, unspecified Thoracolumbar region"
    },
    {
      "name": "M4196",
      "description": "Scoliosis, unspecified Lumbar region"
    },
    {
      "name": "M4197",
      "description": "Scoliosis, unspecified Lumbosacral region"
    },
    {
      "name": "M4198",
      "description": "Scoliosis, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4199",
      "description": "Scoliosis, unspecified Site unspecified"
    },
    {
      "name": "M420",
      "description": "Juvenile osteochondrosis of spine"
    },
    {
      "name": "M4200",
      "description": "Juvenile osteochondrosis of spine Multiple sites in spine"
    },
    {
      "name": "M4201",
      "description": "Juvenile osteochondrosis of spine Occipito-atlanto-axial region"
    },
    {
      "name": "M4202",
      "description": "Juvenile osteochondrosis of spine Cervical region"
    },
    {
      "name": "M4203",
      "description": "Juvenile osteochondrosis of spine Cervicothoracic region"
    },
    {
      "name": "M4204",
      "description": "Juvenile osteochondrosis of spine Thoracic region"
    },
    {
      "name": "M4205",
      "description": "Juvenile osteochondrosis of spine Thoracolumbar region"
    },
    {
      "name": "M4206",
      "description": "Juvenile osteochondrosis of spine Lumbar region"
    },
    {
      "name": "M4207",
      "description": "Juvenile osteochondrosis of spine Lumbosacral region"
    },
    {
      "name": "M4208",
      "description": "Juvenile osteochondrosis of spine Sacral and sacrococcygeal region"
    },
    {
      "name": "M4209",
      "description": "Juvenile osteochondrosis of spine Site unspecified"
    },
    {
      "name": "M421",
      "description": "Adult osteochondrosis of spine"
    },
    {
      "name": "M4210",
      "description": "Adult osteochondrosis of spine Multiple sites in spine"
    },
    {
      "name": "M4211",
      "description": "Adult osteochondrosis of spine Occipito-atlanto-axial region"
    },
    {
      "name": "M4212",
      "description": "Adult osteochondrosis of spine Cervical region"
    },
    {
      "name": "M4213",
      "description": "Adult osteochondrosis of spine Cervicothoracic region"
    },
    {
      "name": "M4214",
      "description": "Adult osteochondrosis of spine Thoracic region"
    },
    {
      "name": "M4215",
      "description": "Adult osteochondrosis of spine Thoracolumbar region"
    },
    {
      "name": "M4216",
      "description": "Adult osteochondrosis of spine Lumbar region"
    },
    {
      "name": "M4217",
      "description": "Adult osteochondrosis of spine Lumbosacral region"
    },
    {
      "name": "M4218",
      "description": "Adult osteochondrosis of spine Sacral and sacrococcygeal region"
    },
    {
      "name": "M4219",
      "description": "Adult osteochondrosis of spine Site unspecified"
    },
    {
      "name": "M429",
      "description": "Spinal osteochondrosis, unspecified"
    },
    {
      "name": "M4290",
      "description": "Spinal osteochondrosis, unspecified Multiple sites in spine"
    },
    {
      "name": "M4291",
      "description": "Spinal osteochondrosis, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4292",
      "description": "Spinal osteochondrosis, unspecified Cervical region"
    },
    {
      "name": "M4293",
      "description": "Spinal osteochondrosis, unspecified Cervicothoracic region"
    },
    {
      "name": "M4294",
      "description": "Spinal osteochondrosis, unspecified Thoracic region"
    },
    {
      "name": "M4295",
      "description": "Spinal osteochondrosis, unspecified Thoracolumbar region"
    },
    {
      "name": "M4296",
      "description": "Spinal osteochondrosis, unspecified Lumbar region"
    },
    {
      "name": "M4297",
      "description": "Spinal osteochondrosis, unspecified Lumbosacral region"
    },
    {
      "name": "M4298",
      "description": "Spinal osteochondrosis, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4299",
      "description": "Spinal osteochondrosis, unspecified Site unspecified"
    },
    {
      "name": "M430",
      "description": "Spondylolysis"
    },
    {
      "name": "M4300",
      "description": "Spondylolysis Multiple sites in spine"
    },
    {
      "name": "M4301",
      "description": "Spondylolysis Occipito-atlanto-axial region"
    },
    {
      "name": "M4302",
      "description": "Spondylolysis Cervical region"
    },
    {
      "name": "M4303",
      "description": "Spondylolysis Cervicothoracic region"
    },
    {
      "name": "M4304",
      "description": "Spondylolysis Thoracic region"
    },
    {
      "name": "M4305",
      "description": "Spondylolysis Thoracolumbar region"
    },
    {
      "name": "M4306",
      "description": "Spondylolysis Lumbar region"
    },
    {
      "name": "M4307",
      "description": "Spondylolysis Lumbosacral region"
    },
    {
      "name": "M4308",
      "description": "Spondylolysis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4309",
      "description": "Spondylolysis Site unspecified"
    },
    {
      "name": "M431",
      "description": "Spondylolisthesis"
    },
    {
      "name": "M4310",
      "description": "Spondylolisthesis Multiple sites in spine"
    },
    {
      "name": "M4311",
      "description": "Spondylolisthesis Occipito-atlanto-axial region"
    },
    {
      "name": "M4312",
      "description": "Spondylolisthesis Cervical region"
    },
    {
      "name": "M4313",
      "description": "Spondylolisthesis Cervicothoracic region"
    },
    {
      "name": "M4314",
      "description": "Spondylolisthesis Thoracic region"
    },
    {
      "name": "M4315",
      "description": "Spondylolisthesis Thoracolumbar region"
    },
    {
      "name": "M4316",
      "description": "Spondylolisthesis Lumbar region"
    },
    {
      "name": "M4317",
      "description": "Spondylolisthesis Lumbosacral region"
    },
    {
      "name": "M4318",
      "description": "Spondylolisthesis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4319",
      "description": "Spondylolisthesis Site unspecified"
    },
    {
      "name": "M432",
      "description": "Other fusion of spine"
    },
    {
      "name": "M4320",
      "description": "Other fusion of spine Multiple sites in spine"
    },
    {
      "name": "M4321",
      "description": "Other fusion of spine Occipito-atlanto-axial region"
    },
    {
      "name": "M4322",
      "description": "Other fusion of spine Cervical region"
    },
    {
      "name": "M4323",
      "description": "Other fusion of spine Cervicothoracic region"
    },
    {
      "name": "M4324",
      "description": "Other fusion of spine Thoracic region"
    },
    {
      "name": "M4325",
      "description": "Other fusion of spine Thoracolumbar region"
    },
    {
      "name": "M4326",
      "description": "Other fusion of spine Lumbar region"
    },
    {
      "name": "M4327",
      "description": "Other fusion of spine Lumbosacral region"
    },
    {
      "name": "M4328",
      "description": "Other fusion of spine Sacral and sacrococcygeal region"
    },
    {
      "name": "M4329",
      "description": "Other fusion of spine Site unspecified"
    },
    {
      "name": "M433",
      "description": "Recurrent atlantoaxial subluxation with myelopathy"
    },
    {
      "name": "M4330",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Multiple sites in spine"
    },
    {
      "name": "M4331",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4332",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Cervical region"
    },
    {
      "name": "M4333",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Cervicothoracic region"
    },
    {
      "name": "M4334",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Thoracic region"
    },
    {
      "name": "M4335",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Thoracolumbar region"
    },
    {
      "name": "M4336",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Lumbar region"
    },
    {
      "name": "M4337",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Lumbosacral region"
    },
    {
      "name": "M4338",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4339",
      "description": "Recurrent atlantoaxial subluxation with myelopathy Site unspecified"
    },
    {
      "name": "M434",
      "description": "Other recurrent atlantoaxial subluxation"
    },
    {
      "name": "M4340",
      "description": "Other recurrent atlantoaxial subluxation Multiple sites in spine"
    },
    {
      "name": "M4341",
      "description": "Other recurrent atlantoaxial subluxation Occipito-atlanto-axial region"
    },
    {
      "name": "M4342",
      "description": "Other recurrent atlantoaxial subluxation Cervical region"
    },
    {
      "name": "M4343",
      "description": "Other recurrent atlantoaxial subluxation Cervicothoracic region"
    },
    {
      "name": "M4344",
      "description": "Other recurrent atlantoaxial subluxation Thoracic region"
    },
    {
      "name": "M4345",
      "description": "Other recurrent atlantoaxial subluxation Thoracolumbar region"
    },
    {
      "name": "M4346",
      "description": "Other recurrent atlantoaxial subluxation Lumbar region"
    },
    {
      "name": "M4347",
      "description": "Other recurrent atlantoaxial subluxation Lumbosacral region"
    },
    {
      "name": "M4348",
      "description": "Other recurrent atlantoaxial subluxation Sacral and sacrococcygeal region"
    },
    {
      "name": "M4349",
      "description": "Other recurrent atlantoaxial subluxation Site unspecified"
    },
    {
      "name": "M435",
      "description": "Other recurrent vertebral subluxation"
    },
    {
      "name": "M4350",
      "description": "Other recurrent vertebral subluxation Multiple sites in spine"
    },
    {
      "name": "M4351",
      "description": "Other recurrent vertebral subluxation Occipito-atlanto-axial region"
    },
    {
      "name": "M4352",
      "description": "Other recurrent vertebral subluxation Cervical region"
    },
    {
      "name": "M4353",
      "description": "Other recurrent vertebral subluxation Cervicothoracic region"
    },
    {
      "name": "M4354",
      "description": "Other recurrent vertebral subluxation Thoracic region"
    },
    {
      "name": "M4355",
      "description": "Other recurrent vertebral subluxation Thoracolumbar region"
    },
    {
      "name": "M4356",
      "description": "Other recurrent vertebral subluxation Lumbar region"
    },
    {
      "name": "M4357",
      "description": "Other recurrent vertebral subluxation Lumbosacral region"
    },
    {
      "name": "M4358",
      "description": "Other recurrent vertebral subluxation Sacral and sacrococcygeal region"
    },
    {
      "name": "M4359",
      "description": "Other recurrent vertebral subluxation Site unspecified"
    },
    {
      "name": "M436",
      "description": "Torticollis"
    },
    {
      "name": "M4360",
      "description": "Torticollis Multiple sites in spine"
    },
    {
      "name": "M4361",
      "description": "Torticollis Occipito-atlanto-axial region"
    },
    {
      "name": "M4362",
      "description": "Torticollis Cervical region"
    },
    {
      "name": "M4363",
      "description": "Torticollis Cervicothoracic region"
    },
    {
      "name": "M4364",
      "description": "Torticollis Thoracic region"
    },
    {
      "name": "M4365",
      "description": "Torticollis Thoracolumbar region"
    },
    {
      "name": "M4366",
      "description": "Torticollis Lumbar region"
    },
    {
      "name": "M4367",
      "description": "Torticollis Lumbosacral region"
    },
    {
      "name": "M4368",
      "description": "Torticollis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4369",
      "description": "Torticollis Site unspecified"
    },
    {
      "name": "M438",
      "description": "Other specified deforming dorsopathies"
    },
    {
      "name": "M4380",
      "description": "Other specified deforming dorsopathies Multiple sites in spine"
    },
    {
      "name": "M4381",
      "description": "Other specified deforming dorsopathies Occipito-atlanto-axial region"
    },
    {
      "name": "M4382",
      "description": "Other specified deforming dorsopathies Cervical region"
    },
    {
      "name": "M4383",
      "description": "Other specified deforming dorsopathies Cervicothoracic region"
    },
    {
      "name": "M4384",
      "description": "Other specified deforming dorsopathies Thoracic region"
    },
    {
      "name": "M4385",
      "description": "Other specified deforming dorsopathies Thoracolumbar region"
    },
    {
      "name": "M4386",
      "description": "Other specified deforming dorsopathies Lumbar region"
    },
    {
      "name": "M4387",
      "description": "Other specified deforming dorsopathies Lumbosacral region"
    },
    {
      "name": "M4388",
      "description": "Other specified deforming dorsopathies Sacral and sacrococcygeal region"
    },
    {
      "name": "M4389",
      "description": "Other specified deforming dorsopathies Site unspecified"
    },
    {
      "name": "M439",
      "description": "Deforming dorsopathy, unspecified"
    },
    {
      "name": "M4390",
      "description": "Deforming dorsopathy, unspecified Multiple sites in spine"
    },
    {
      "name": "M4391",
      "description": "Deforming dorsopathy, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4392",
      "description": "Deforming dorsopathy, unspecified Cervical region"
    },
    {
      "name": "M4393",
      "description": "Deforming dorsopathy, unspecified Cervicothoracic region"
    },
    {
      "name": "M4394",
      "description": "Deforming dorsopathy, unspecified Thoracic region"
    },
    {
      "name": "M4395",
      "description": "Deforming dorsopathy, unspecified Thoracolumbar region"
    },
    {
      "name": "M4396",
      "description": "Deforming dorsopathy, unspecified Lumbar region"
    },
    {
      "name": "M4397",
      "description": "Deforming dorsopathy, unspecified Lumbosacral region"
    },
    {
      "name": "M4398",
      "description": "Deforming dorsopathy, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4399",
      "description": "Deforming dorsopathy, unspecified Site unspecified"
    },
    {
      "name": "M45",
      "description": "Ankylosing spondylitis"
    },
    {
      "name": "M450",
      "description": "Ankylosing spondylitis Multiple sites in spine"
    },
    {
      "name": "M451",
      "description": "Ankylosing spondylitis Occipito-atlanto-axial region"
    },
    {
      "name": "M452",
      "description": "Ankylosing spondylitis Cervical region"
    },
    {
      "name": "M453",
      "description": "Ankylosing spondylitis Cervicothoracic region"
    },
    {
      "name": "M454",
      "description": "Ankylosing spondylitis Thoracic region"
    },
    {
      "name": "M455",
      "description": "Ankylosing spondylitis Thoracolumbar region"
    },
    {
      "name": "M456",
      "description": "Ankylosing spondylitis Lumbar region"
    },
    {
      "name": "M457",
      "description": "Ankylosing spondylitis Lumbosacral region"
    },
    {
      "name": "M458",
      "description": "Ankylosing spondylitis Sacral and sacrococcygeal region"
    },
    {
      "name": "M459",
      "description": "Ankylosing spondylitis Site unspecified"
    },
    {
      "name": "M460",
      "description": "Spinal enthesopathy"
    },
    {
      "name": "M4600",
      "description": "Spinal enthesopathy Multiple sites in spine"
    },
    {
      "name": "M4601",
      "description": "Spinal enthesopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4602",
      "description": "Spinal enthesopathy Cervical region"
    },
    {
      "name": "M4603",
      "description": "Spinal enthesopathy Cervicothoracic region"
    },
    {
      "name": "M4604",
      "description": "Spinal enthesopathy Thoracic region"
    },
    {
      "name": "M4605",
      "description": "Spinal enthesopathy Thoracolumbar region"
    },
    {
      "name": "M4606",
      "description": "Spinal enthesopathy Lumbar region"
    },
    {
      "name": "M4607",
      "description": "Spinal enthesopathy Lumbosacral region"
    },
    {
      "name": "M4608",
      "description": "Spinal enthesopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4609",
      "description": "Spinal enthesopathy Site unspecified"
    },
    {
      "name": "M461",
      "description": "Sacroiliitis, not elsewhere classified"
    },
    {
      "name": "M4610",
      "description": "Sacroiliitis, not elsewhere classified Multiple sites in spine"
    },
    {
      "name": "M4611",
      "description": "Sacroiliitis, not elsewhere classified Occipito-atlanto-axial region"
    },
    {
      "name": "M4612",
      "description": "Sacroiliitis, not elsewhere classified Cervical region"
    },
    {
      "name": "M4613",
      "description": "Sacroiliitis, not elsewhere classified Cervicothoracic region"
    },
    {
      "name": "M4614",
      "description": "Sacroiliitis, not elsewhere classified Thoracic region"
    },
    {
      "name": "M4615",
      "description": "Sacroiliitis, not elsewhere classified Thoracolumbar region"
    },
    {
      "name": "M4616",
      "description": "Sacroiliitis, not elsewhere classified Lumbar region"
    },
    {
      "name": "M4617",
      "description": "Sacroiliitis, not elsewhere classified Lumbosacral region"
    },
    {
      "name": "M4618",
      "description": "Sacroiliitis, not elsewhere classified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4619",
      "description": "Sacroiliitis, not elsewhere classified Site unspecified"
    },
    {
      "name": "M462",
      "description": "Osteomyelitis of vertebra"
    },
    {
      "name": "M4620",
      "description": "Osteomyelitis of vertebra Multiple sites in spine"
    },
    {
      "name": "M4621",
      "description": "Osteomyelitis of vertebra Occipito-atlanto-axial region"
    },
    {
      "name": "M4622",
      "description": "Osteomyelitis of vertebra Cervical region"
    },
    {
      "name": "M4623",
      "description": "Osteomyelitis of vertebra Cervicothoracic region"
    },
    {
      "name": "M4624",
      "description": "Osteomyelitis of vertebra Thoracic region"
    },
    {
      "name": "M4625",
      "description": "Osteomyelitis of vertebra Thoracolumbar region"
    },
    {
      "name": "M4626",
      "description": "Osteomyelitis of vertebra Lumbar region"
    },
    {
      "name": "M4627",
      "description": "Osteomyelitis of vertebra Lumbosacral region"
    },
    {
      "name": "M4628",
      "description": "Osteomyelitis of vertebra Sacral and sacrococcygeal region"
    },
    {
      "name": "M4629",
      "description": "Osteomyelitis of vertebra Site unspecified"
    },
    {
      "name": "M463",
      "description": "Infection of intervertebral disc (pyogenic)"
    },
    {
      "name": "M4630",
      "description": "Infection of intervertebral disc ( pyogenic ) Multiple sites in spine"
    },
    {
      "name": "M4631",
      "description": "Infection of intervertebral disc ( pyogenic ) Occipito-atlanto-axial region"
    },
    {
      "name": "M4632",
      "description": "Infection of intervertebral disc ( pyogenic ) Cervical region"
    },
    {
      "name": "M4633",
      "description": "Infection of intervertebral disc ( pyogenic ) Cervicothoracic region"
    },
    {
      "name": "M4634",
      "description": "Infection of intervertebral disc ( pyogenic ) Thoracic region"
    },
    {
      "name": "M4635",
      "description": "Infection of intervertebral disc ( pyogenic ) Thoracolumbar region"
    },
    {
      "name": "M4636",
      "description": "Infection of intervertebral disc ( pyogenic ) Lumbar region"
    },
    {
      "name": "M4637",
      "description": "Infection of intervertebral disc ( pyogenic ) Lumbosacral region"
    },
    {
      "name": "M4638",
      "description": "Infection of intervertebral disc ( pyogenic ) Sacral and sacrococcygeal region"
    },
    {
      "name": "M4639",
      "description": "Infection of intervertebral disc ( pyogenic ) Site unspecified"
    },
    {
      "name": "M464",
      "description": "Discitis, unspecified"
    },
    {
      "name": "M4640",
      "description": "Discitis, unspecified Multiple sites in spine"
    },
    {
      "name": "M4641",
      "description": "Discitis, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4642",
      "description": "Discitis, unspecified Cervical region"
    },
    {
      "name": "M4643",
      "description": "Discitis, unspecified Cervicothoracic region"
    },
    {
      "name": "M4644",
      "description": "Discitis, unspecified Thoracic region"
    },
    {
      "name": "M4645",
      "description": "Discitis, unspecified Thoracolumbar region"
    },
    {
      "name": "M4646",
      "description": "Discitis, unspecified Lumbar region"
    },
    {
      "name": "M4647",
      "description": "Discitis, unspecified Lumbosacral region"
    },
    {
      "name": "M4648",
      "description": "Discitis, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4649",
      "description": "Discitis, unspecified Site unspecified"
    },
    {
      "name": "M465",
      "description": "Other infective spondylopathies"
    },
    {
      "name": "M4650",
      "description": "Other infective spondylopathies Multiple sites in spine"
    },
    {
      "name": "M4651",
      "description": "Other infective spondylopathies Occipito-atlanto-axial region"
    },
    {
      "name": "M4652",
      "description": "Other infective spondylopathies Cervical region"
    },
    {
      "name": "M4653",
      "description": "Other infective spondylopathies Cervicothoracic region"
    },
    {
      "name": "M4654",
      "description": "Other infective spondylopathies Thoracic region"
    },
    {
      "name": "M4655",
      "description": "Other infective spondylopathies Thoracolumbar region"
    },
    {
      "name": "M4656",
      "description": "Other infective spondylopathies Lumbar region"
    },
    {
      "name": "M4657",
      "description": "Other infective spondylopathies Lumbosacral region"
    },
    {
      "name": "M4658",
      "description": "Other infective spondylopathies Sacral and sacrococcygeal region"
    },
    {
      "name": "M4659",
      "description": "Other infective spondylopathies Site unspecified"
    },
    {
      "name": "M468",
      "description": "Other specified inflammatory spondylopathies"
    },
    {
      "name": "M4680",
      "description": "Other specified inflammatory spondylopathies Multiple sites in spine"
    },
    {
      "name": "M4681",
      "description": "Other specified inflammatory spondylopathies Occipito-atlanto-axial region"
    },
    {
      "name": "M4682",
      "description": "Other specified inflammatory spondylopathies Cervical region"
    },
    {
      "name": "M4683",
      "description": "Other specified inflammatory spondylopathies Cervicothoracic region"
    },
    {
      "name": "M4684",
      "description": "Other specified inflammatory spondylopathies Thoracic region"
    },
    {
      "name": "M4685",
      "description": "Other specified inflammatory spondylopathies Thoracolumbar region"
    },
    {
      "name": "M4686",
      "description": "Other specified inflammatory spondylopathies Lumbar region"
    },
    {
      "name": "M4687",
      "description": "Other specified inflammatory spondylopathies Lumbosacral region"
    },
    {
      "name": "M4688",
      "description": "Other specified inflammatory spondylopathies Sacral and sacrococcygeal region"
    },
    {
      "name": "M4689",
      "description": "Other specified inflammatory spondylopathies Site unspecified"
    },
    {
      "name": "M469",
      "description": "nflammatory spondylopathy, unspecified"
    },
    {
      "name": "M4690",
      "description": "Inflammatory spondylopathy, unspecified Multiple sites in spine"
    },
    {
      "name": "M4691",
      "description": "Inflammatory spondylopathy, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4692",
      "description": "Inflammatory spondylopathy, unspecified Cervical region"
    },
    {
      "name": "M4693",
      "description": "Inflammatory spondylopathy, unspecified Cervicothoracic region"
    },
    {
      "name": "M4694",
      "description": "Inflammatory spondylopathy, unspecified Thoracic region"
    },
    {
      "name": "M4695",
      "description": "Inflammatory spondylopathy, unspecified Thoracolumbar region"
    },
    {
      "name": "M4696",
      "description": "Inflammatory spondylopathy, unspecified Lumbar region"
    },
    {
      "name": "M4697",
      "description": "Inflammatory spondylopathy, unspecified Lumbosacral region"
    },
    {
      "name": "M4698",
      "description": "Inflammatory spondylopathy, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4699",
      "description": "Inflammatory spondylopathy, unspecified Site unspecified"
    },
    {
      "name": "M470",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 )"
    },
    {
      "name": "M4700",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Multiple sites in spine"
    },
    {
      "name": "M4701",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Occipito-atlanto-axial region"
    },
    {
      "name": "M4702",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Cervical region"
    },
    {
      "name": "M4703",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Cervicothoracic region"
    },
    {
      "name": "M4704",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Thoracic region"
    },
    {
      "name": "M4705",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Thoracolumbar region"
    },
    {
      "name": "M4706",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Lumbar region"
    },
    {
      "name": "M4707",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Lumbosacral region"
    },
    {
      "name": "M4708",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Sacral and sacrococcygeal region"
    },
    {
      "name": "M4709",
      "description": "Anterior spinal and vertebral artery compression syndromes ( G99.2 ) Site unspecified"
    },
    {
      "name": "M471",
      "description": "Other spondylosis with myelopathy"
    },
    {
      "name": "M4710",
      "description": "Other spondylosis with myelopathy Multiple sites in spine"
    },
    {
      "name": "M4711",
      "description": "Other spondylosis with myelopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4712",
      "description": "Other spondylosis with myelopathy Cervical region"
    },
    {
      "name": "M4713",
      "description": "Other spondylosis with myelopathy Cervicothoracic region"
    },
    {
      "name": "M4714",
      "description": "Other spondylosis with myelopathy Thoracic region"
    },
    {
      "name": "M4715",
      "description": "Other spondylosis with myelopathy Thoracolumbar region"
    },
    {
      "name": "M4716",
      "description": "Other spondylosis with myelopathy Lumbar region"
    },
    {
      "name": "M4717",
      "description": "Other spondylosis with myelopathy Lumbosacral region"
    },
    {
      "name": "M4718",
      "description": "Other spondylosis with myelopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4719",
      "description": "Other spondylosis with myelopathy Site unspecified"
    },
    {
      "name": "M472",
      "description": "Other spondylosis with radiculopathy"
    },
    {
      "name": "M4720",
      "description": "Other spondylosis with radiculopathy Multiple sites in spine"
    },
    {
      "name": "M4721",
      "description": "Other spondylosis with radiculopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4722",
      "description": "Other spondylosis with radiculopathy Cervical region"
    },
    {
      "name": "M4723",
      "description": "Other spondylosis with radiculopathy Cervicothoracic region"
    },
    {
      "name": "M4724",
      "description": "Other spondylosis with radiculopathy Thoracic region"
    },
    {
      "name": "M4725",
      "description": "Other spondylosis with radiculopathy Thoracolumbar region"
    },
    {
      "name": "M4726",
      "description": "Other spondylosis with radiculopathy Lumbar region"
    },
    {
      "name": "M4727",
      "description": "Other spondylosis with radiculopathy Lumbosacral region"
    },
    {
      "name": "M4728",
      "description": "Other spondylosis with radiculopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4729",
      "description": "Other spondylosis with radiculopathy Site unspecified"
    },
    {
      "name": "M478",
      "description": "Other spondylosis"
    },
    {
      "name": "M4780",
      "description": "Other spondylosis Multiple sites in spine"
    },
    {
      "name": "M4781",
      "description": "Other spondylosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4782",
      "description": "Other spondylosis Cervical region"
    },
    {
      "name": "M4783",
      "description": "Other spondylosis Cervicothoracic region"
    },
    {
      "name": "M4784",
      "description": "Other spondylosis Thoracic region"
    },
    {
      "name": "M4785",
      "description": "Other spondylosis Thoracolumbar region"
    },
    {
      "name": "M4786",
      "description": "Other spondylosis Lumbar region"
    },
    {
      "name": "M4787",
      "description": "Other spondylosis Lumbosacral region"
    },
    {
      "name": "M4788",
      "description": "Other spondylosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4789",
      "description": "Other spondylosis Site unspecified"
    },
    {
      "name": "M479",
      "description": "Spondylosis, unspecified"
    },
    {
      "name": "M4790",
      "description": "Spondylosis unspecified Multiple sites in spine"
    },
    {
      "name": "M4791",
      "description": "Spondylosis unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4792",
      "description": "Spondylosis unspecified Cervical region"
    },
    {
      "name": "M4793",
      "description": "Spondylosis unspecified Cervicothoracic region"
    },
    {
      "name": "M4794",
      "description": "Spondylosis unspecified Thoracic region"
    },
    {
      "name": "M4795",
      "description": "Spondylosis unspecified Thoracolumbar region"
    },
    {
      "name": "M4796",
      "description": "Spondylosis unspecified Lumbar region"
    },
    {
      "name": "M4797",
      "description": "Spondylosis unspecified Lumbosacral region"
    },
    {
      "name": "M4798",
      "description": "Spondylosis unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4799",
      "description": "Spondylosis unspecified Site unspecified"
    },
    {
      "name": "M480",
      "description": "Spinal stenosis"
    },
    {
      "name": "M4800",
      "description": "Spinal stenosis Multiple sites in spine"
    },
    {
      "name": "M4801",
      "description": "Spinal stenosis Occipito-atlanto-axial region"
    },
    {
      "name": "M4802",
      "description": "Spinal stenosis Cervical region"
    },
    {
      "name": "M4803",
      "description": "Spinal stenosis Cervicothoracic region"
    },
    {
      "name": "M4804",
      "description": "Spinal stenosis Thoracic region"
    },
    {
      "name": "M4805",
      "description": "Spinal stenosis Thoracolumbar region"
    },
    {
      "name": "M4806",
      "description": "Spinal stenosis Lumbar region"
    },
    {
      "name": "M4807",
      "description": "Spinal stenosis Lumbosacral region"
    },
    {
      "name": "M4808",
      "description": "Spinal stenosis Sacral and sacrococcygeal region"
    },
    {
      "name": "M4809",
      "description": "Spinal stenosis Site unspecified"
    },
    {
      "name": "M481",
      "description": "Ankylosing hyperostosis [Forestier]"
    },
    {
      "name": "M4810",
      "description": "Ankylosing hyperostosis [ Forestier ] Multiple sites in spine"
    },
    {
      "name": "M4811",
      "description": "Ankylosing hyperostosis [ Forestier ] Occipito-atlanto-axial region"
    },
    {
      "name": "M4812",
      "description": "Ankylosing hyperostosis [ Forestier ] Cervical region"
    },
    {
      "name": "M4813",
      "description": "Ankylosing hyperostosis [ Forestier ] Cervicothoracic region"
    },
    {
      "name": "M4814",
      "description": "Ankylosing hyperostosis [ Forestier ] Thoracic region"
    },
    {
      "name": "M4815",
      "description": "Ankylosing hyperostosis [ Forestier ] Thoracolumbar region"
    },
    {
      "name": "M4816",
      "description": "Ankylosing hyperostosis [ Forestier ] Lumbar region"
    },
    {
      "name": "M4817",
      "description": "Ankylosing hyperostosis [ Forestier ] Lumbosacral region"
    },
    {
      "name": "M4818",
      "description": "Ankylosing hyperostosis [ Forestier ] Sacral and sacrococcygeal region"
    },
    {
      "name": "M4819",
      "description": "Ankylosing hyperostosis [ Forestier ] Site unspecified"
    },
    {
      "name": "M482",
      "description": "Kissing spine"
    },
    {
      "name": "M4820",
      "description": "Kissing spine Multiple sites in spine"
    },
    {
      "name": "M4821",
      "description": "Kissing spine Occipito-atlanto-axial region"
    },
    {
      "name": "M4822",
      "description": "Kissing spine Cervical region"
    },
    {
      "name": "M4823",
      "description": "Kissing spine Cervicothoracic region"
    },
    {
      "name": "M4824",
      "description": "Kissing spine Thoracic region"
    },
    {
      "name": "M4825",
      "description": "Kissing spine Thoracolumbar region"
    },
    {
      "name": "M4826",
      "description": "Kissing spine Lumbar region"
    },
    {
      "name": "M4827",
      "description": "Kissing spine Lumbosacral region"
    },
    {
      "name": "M4828",
      "description": "Kissing spine Sacral and sacrococcygeal region"
    },
    {
      "name": "M4829",
      "description": "Kissing spine Site unspecified"
    },
    {
      "name": "M483",
      "description": "Traumatic spondylopathy"
    },
    {
      "name": "M4830",
      "description": "Traumatic spondylopathy Multiple sites in spine"
    },
    {
      "name": "M4831",
      "description": "Traumatic spondylopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4832",
      "description": "Traumatic spondylopathy Cervical region"
    },
    {
      "name": "M4833",
      "description": "Traumatic spondylopathy Cervicothoracic region"
    },
    {
      "name": "M4834",
      "description": "Traumatic spondylopathy Thoracic region"
    },
    {
      "name": "M4835",
      "description": "Traumatic spondylopathy Thoracolumbar region"
    },
    {
      "name": "M4836",
      "description": "Traumatic spondylopathy Lumbar region"
    },
    {
      "name": "M4837",
      "description": "Traumatic spondylopathy Lumbosacral region"
    },
    {
      "name": "M4838",
      "description": "Traumatic spondylopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4839",
      "description": "Traumatic spondylopathy Site unspecified"
    },
    {
      "name": "M484",
      "description": "Fatigue fracture of vertebra"
    },
    {
      "name": "M4840",
      "description": "Fatigue fracture of vertebra Multiple sites in spine"
    },
    {
      "name": "M4841",
      "description": "Fatigue fracture of vertebra Occipito-atlanto-axial region"
    },
    {
      "name": "M4842",
      "description": "Fatigue fracture of vertebra Cervical region"
    },
    {
      "name": "M4843",
      "description": "Fatigue fracture of vertebra Cervicothoracic region"
    },
    {
      "name": "M4844",
      "description": "Fatigue fracture of vertebra Thoracic region"
    },
    {
      "name": "M4845",
      "description": "Fatigue fracture of vertebra Thoracolumbar region"
    },
    {
      "name": "M4846",
      "description": "Fatigue fracture of vertebra Lumbar region"
    },
    {
      "name": "M4847",
      "description": "Fatigue fracture of vertebra Lumbosacral region"
    },
    {
      "name": "M4848",
      "description": "Fatigue fracture of vertebra Sacral and sacrococcygeal region"
    },
    {
      "name": "M4849",
      "description": "Fatigue fracture of vertebra Site unspecified"
    },
    {
      "name": "M485",
      "description": "Collapsed vertebra, not elsewhere classified"
    },
    {
      "name": "M4850",
      "description": "Collapsed vertebra, not elsewhere classified Multiple sites in spine"
    },
    {
      "name": "M4851",
      "description": "Collapsed vertebra, not elsewhere classified Occipito-atlanto-axial region"
    },
    {
      "name": "M4852",
      "description": "Collapsed vertebra, not elsewhere classified Cervical region"
    },
    {
      "name": "M4853",
      "description": "Collapsed vertebra, not elsewhere classified Cervicothoracic region"
    },
    {
      "name": "M4854",
      "description": "Collapsed vertebra, not elsewhere classified Thoracic region"
    },
    {
      "name": "M4855",
      "description": "Collapsed vertebra, not elsewhere classified Thoracolumbar region"
    },
    {
      "name": "M4856",
      "description": "Collapsed vertebra, not elsewhere classified Lumbar region"
    },
    {
      "name": "M4857",
      "description": "Collapsed vertebra, not elsewhere classified Lumbosacral region"
    },
    {
      "name": "M4858",
      "description": "Collapsed vertebra, not elsewhere classified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4859",
      "description": "Collapsed vertebra, not elsewhere classified Site unspecified"
    },
    {
      "name": "M488",
      "description": "Other specified spondylopathies"
    },
    {
      "name": "M4880",
      "description": "Other specified spondylopathies Multiple sites in spine"
    },
    {
      "name": "M4881",
      "description": "Other specified spondylopathies Occipito-atlanto-axial region"
    },
    {
      "name": "M4882",
      "description": "Other specified spondylopathies Cervical region"
    },
    {
      "name": "M4883",
      "description": "Other specified spondylopathies Cervicothoracic region"
    },
    {
      "name": "M4884",
      "description": "Other specified spondylopathies Thoracic region"
    },
    {
      "name": "M4885",
      "description": "Other specified spondylopathies Thoracolumbar region"
    },
    {
      "name": "M4886",
      "description": "Other specified spondylopathies Lumbar region"
    },
    {
      "name": "M4887",
      "description": "Other specified spondylopathies Lumbosacral region"
    },
    {
      "name": "M4888",
      "description": "Other specified spondylopathies Sacral and sacrococcygeal region"
    },
    {
      "name": "M4889",
      "description": "Other specified spondylopathies Site unspecified"
    },
    {
      "name": "M489",
      "description": "Spondylopathy, unspecified"
    },
    {
      "name": "M4890",
      "description": "Spondylopathy, unspecified Multiple sites in spine"
    },
    {
      "name": "M4891",
      "description": "Spondylopathy, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M4892",
      "description": "Spondylopathy, unspecified Cervical region"
    },
    {
      "name": "M4893",
      "description": "Spondylopathy, unspecified Cervicothoracic region"
    },
    {
      "name": "M4894",
      "description": "Spondylopathy, unspecified Thoracic region"
    },
    {
      "name": "M4895",
      "description": "Spondylopathy, unspecified Thoracolumbar region"
    },
    {
      "name": "M4896",
      "description": "Spondylopathy, unspecified Lumbar region"
    },
    {
      "name": "M4897",
      "description": "Spondylopathy, unspecified Lumbosacral region"
    },
    {
      "name": "M4898",
      "description": "Spondylopathy, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M4899",
      "description": "Spondylopathy, unspecified Site unspecified"
    },
    {
      "name": "M490",
      "description": "Tuberculosis of spine  ( A18.0 )"
    },
    {
      "name": "M4900",
      "description": "Tuberculosis of spine ( A18.0 ) Multiple sites in spine"
    },
    {
      "name": "M4901",
      "description": "Tuberculosis of spine ( A18.0 ) Occipito-atlanto-axial region"
    },
    {
      "name": "M4902",
      "description": "Tuberculosis of spine ( A18.0 ) Cervical region"
    },
    {
      "name": "M4903",
      "description": "Tuberculosis of spine ( A18.0 ) Cervicothoracic region"
    },
    {
      "name": "M4904",
      "description": "Tuberculosis of spine ( A18.0 ) Thoracic region"
    },
    {
      "name": "M4905",
      "description": "Tuberculosis of spine ( A18.0 ) Thoracolumbar region"
    },
    {
      "name": "M4906",
      "description": "Tuberculosis of spine ( A18.0 ) Lumbar region"
    },
    {
      "name": "M4907",
      "description": "Tuberculosis of spine ( A18.0 ) Lumbosacral region"
    },
    {
      "name": "M4908",
      "description": "Tuberculosis of spine ( A18.0 ) Sacral and sacrococcygeal region"
    },
    {
      "name": "M4909",
      "description": "Tuberculosis of spine ( A18.0 ) Site unspecified"
    },
    {
      "name": "M491",
      "description": "Brucella spondylitis ( A23.- )"
    },
    {
      "name": "M4910",
      "description": "Brucella spondylitis ( A23.- ) Multiple sites in spine"
    },
    {
      "name": "M4911",
      "description": "Brucella spondylitis ( A23.- ) Occipito-atlanto-axial region"
    },
    {
      "name": "M4912",
      "description": "Brucella spondylitis ( A23.- ) Cervical region"
    },
    {
      "name": "M4913",
      "description": "Brucella spondylitis ( A23.- ) Cervicothoracic region"
    },
    {
      "name": "M4914",
      "description": "Brucella spondylitis ( A23.- ) Thoracic region"
    },
    {
      "name": "M4915",
      "description": "Brucella spondylitis ( A23.- ) Thoracolumbar region"
    },
    {
      "name": "M4916",
      "description": "Brucella spondylitis ( A23.- ) Lumbar region"
    },
    {
      "name": "M4917",
      "description": "Brucella spondylitis ( A23.- ) Lumbosacral region"
    },
    {
      "name": "M4918",
      "description": "Brucella spondylitis ( A23.- ) Sacral and sacrococcygeal region"
    },
    {
      "name": "M4919",
      "description": "Brucella spondylitis ( A23.- ) Site unspecified"
    },
    {
      "name": "M492",
      "description": "Enterobacterial spondylitis ( A01-A04 )"
    },
    {
      "name": "M4920",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Multiple sites in spine"
    },
    {
      "name": "M4921",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Occipito-atlanto-axial region"
    },
    {
      "name": "M4922",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Cervical region"
    },
    {
      "name": "M4923",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Cervicothoracic region"
    },
    {
      "name": "M4924",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Thoracic region"
    },
    {
      "name": "M4925",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Thoracolumbar region"
    },
    {
      "name": "M4926",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Lumbar region"
    },
    {
      "name": "M4927",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Lumbosacral region"
    },
    {
      "name": "M4928",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Sacral and sacrococcygeal region"
    },
    {
      "name": "M4929",
      "description": "Enterobacterial spondylitis ( A01-A04 ) Site unspecified"
    },
    {
      "name": "M493",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "M4930",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Multiple sites in spine"
    },
    {
      "name": "M4931",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Occipito-atlanto-axial region"
    },
    {
      "name": "M4932",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Cervical region"
    },
    {
      "name": "M4933",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Cervicothoracic region"
    },
    {
      "name": "M4934",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Thoracic region"
    },
    {
      "name": "M4935",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Thoracolumbar region"
    },
    {
      "name": "M4936",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Lumbar region"
    },
    {
      "name": "M4937",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Lumbosacral region"
    },
    {
      "name": "M4938",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Sacral and sacrococcygeal region"
    },
    {
      "name": "M4939",
      "description": "Spondylopathy in other infectious and parasitic diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M494",
      "description": "Neuropathic spondylopathy"
    },
    {
      "name": "M4940",
      "description": "Neuropathic spondylopathy Multiple sites in spine"
    },
    {
      "name": "M4941",
      "description": "Neuropathic spondylopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M4942",
      "description": "Neuropathic spondylopathy Cervical region"
    },
    {
      "name": "M4943",
      "description": "Neuropathic spondylopathy Cervicothoracic region"
    },
    {
      "name": "M4944",
      "description": "Neuropathic spondylopathy Thoracic region"
    },
    {
      "name": "M4945",
      "description": "Neuropathic spondylopathy Thoracolumbar region"
    },
    {
      "name": "M4946",
      "description": "Neuropathic spondylopathy Lumbar region"
    },
    {
      "name": "M4947",
      "description": "Neuropathic spondylopathy Lumbosacral region"
    },
    {
      "name": "M4948",
      "description": "Neuropathic spondylopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M4949",
      "description": "Neuropathic spondylopathy Site unspecified"
    },
    {
      "name": "M495",
      "description": "Collapsed vertebra in diseases classified elsewhere"
    },
    {
      "name": "M4950",
      "description": "Collapsed vertebra in diseases classified elsewhere Multiple sites in spine"
    },
    {
      "name": "M4951",
      "description": "Collapsed vertebra in diseases classified elsewhere Occipito-atlanto-axial region"
    },
    {
      "name": "M4952",
      "description": "Collapsed vertebra in diseases classified elsewhere Cervical region"
    },
    {
      "name": "M4953",
      "description": "Collapsed vertebra in diseases classified elsewhere Cervicothoracic region"
    },
    {
      "name": "M4954",
      "description": "Collapsed vertebra in diseases classified elsewhere Thoracic region"
    },
    {
      "name": "M4955",
      "description": "Collapsed vertebra in diseases classified elsewhere Thoracolumbar region"
    },
    {
      "name": "M4956",
      "description": "Collapsed vertebra in diseases classified elsewhere Lumbar region"
    },
    {
      "name": "M4957",
      "description": "Collapsed vertebra in diseases classified elsewhere Lumbosacral region"
    },
    {
      "name": "M4958",
      "description": "Collapsed vertebra in diseases classified elsewhere Sacral and sacrococcygeal region"
    },
    {
      "name": "M4959",
      "description": "Collapsed vertebra in diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M498",
      "description": "Spondylopathy in other diseases classified elsewhere"
    },
    {
      "name": "M4980",
      "description": "Spondylopathy in other diseases classified elsewhere Multiple sites in spine"
    },
    {
      "name": "M4981",
      "description": "Spondylopathy in other diseases classified elsewhere Occipito-atlanto-axial region"
    },
    {
      "name": "M4982",
      "description": "Spondylopathy in other diseases classified elsewhere Cervical region"
    },
    {
      "name": "M4983",
      "description": "Spondylopathy in other diseases classified elsewhere Cervicothoracic region"
    },
    {
      "name": "M4984",
      "description": "Spondylopathy in other diseases classified elsewhere Thoracic region"
    },
    {
      "name": "M4985",
      "description": "Spondylopathy in other diseases classified elsewhere Thoracolumbar region"
    },
    {
      "name": "M4986",
      "description": "Spondylopathy in other diseases classified elsewhere Lumbar region"
    },
    {
      "name": "M4987",
      "description": "Spondylopathy in other diseases classified elsewhere Lumbosacral region"
    },
    {
      "name": "M4988",
      "description": "Spondylopathy in other diseases classified elsewhere Sacral and sacrococcygeal region"
    },
    {
      "name": "M4989",
      "description": "Spondylopathy in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M500",
      "description": "Cervical disc disorder with myelopathy (G99.2)"
    },
    {
      "name": "M501",
      "description": "Cervical disc disorder with radiculopathy"
    },
    {
      "name": "M502",
      "description": "Other cervical disc displacement"
    },
    {
      "name": "M503",
      "description": "Other cervical disc degeneration"
    },
    {
      "name": "M508",
      "description": "Other cervical disc disorders"
    },
    {
      "name": "M509",
      "description": "Cervical disc disorder, unspecified"
    },
    {
      "name": "M510",
      "description": "Lumbar and other intervertebral disc disorders with myelopathy (G99.2)"
    },
    {
      "name": "M511",
      "description": "Lumbar and other intervertebral disc disorders with radiculopathy (G55.1)"
    },
    {
      "name": "M512",
      "description": "Other specified intervertebral disc displacement"
    },
    {
      "name": "M513",
      "description": "Other specified intervertebral disc degeneration"
    },
    {
      "name": "M514",
      "description": "Schmorl’s nodes"
    },
    {
      "name": "M518",
      "description": "Other specified intervertebral disc disorders"
    },
    {
      "name": "M519",
      "description": "Intervertebral disc disorder, unspecified"
    },
    {
      "name": "M530",
      "description": "Cervicocranial syndrome"
    },
    {
      "name": "M5300",
      "description": "Cervicocranial syndrome Multiple sites in spine"
    },
    {
      "name": "M5301",
      "description": "Cervicocranial syndrome Occipito-atlanto-axial region"
    },
    {
      "name": "M5302",
      "description": "Cervicocranial syndrome Cervical region"
    },
    {
      "name": "M5303",
      "description": "Cervicocranial syndrome Cervicothoracic region"
    },
    {
      "name": "M5304",
      "description": "Cervicocranial syndrome Thoracic region"
    },
    {
      "name": "M5305",
      "description": "Cervicocranial syndrome Thoracolumbar region"
    },
    {
      "name": "M5306",
      "description": "Cervicocranial syndrome Lumbar region"
    },
    {
      "name": "M5307",
      "description": "Cervicocranial syndrome Lumbosacral region"
    },
    {
      "name": "M5308",
      "description": "Cervicocranial syndrome Sacral and sacrococcygeal region"
    },
    {
      "name": "M5309",
      "description": "Cervicocranial syndrome Site unspecified"
    },
    {
      "name": "M531",
      "description": "Cervicobrachial syndrome"
    },
    {
      "name": "M5310",
      "description": "Cervicobrachial syndrome Multiple sites in spine"
    },
    {
      "name": "M5311",
      "description": "Cervicobrachial syndrome Occipito-atlanto-axial region"
    },
    {
      "name": "M5312",
      "description": "Cervicobrachial syndrome Cervical region"
    },
    {
      "name": "M5313",
      "description": "Cervicobrachial syndrome Cervicothoracic region"
    },
    {
      "name": "M5314",
      "description": "Cervicobrachial syndrome Thoracic region"
    },
    {
      "name": "M5315",
      "description": "Cervicobrachial syndrome Thoracolumbar region"
    },
    {
      "name": "M5316",
      "description": "Cervicobrachial syndrome Lumbar region"
    },
    {
      "name": "M5317",
      "description": "Cervicobrachial syndrome Lumbosacral region"
    },
    {
      "name": "M5318",
      "description": "Cervicobrachial syndrome Sacral and sacrococcygeal region"
    },
    {
      "name": "M5319",
      "description": "Cervicobrachial syndrome Site unspecified"
    },
    {
      "name": "M532",
      "description": "Spinal instabilities"
    },
    {
      "name": "M5320",
      "description": "Spinal instabilities Multiple sites in spine"
    },
    {
      "name": "M5321",
      "description": "Spinal instabilities Occipito-atlanto-axial region"
    },
    {
      "name": "M5322",
      "description": "Spinal instabilities Cervical region"
    },
    {
      "name": "M5323",
      "description": "Spinal instabilities Cervicothoracic region"
    },
    {
      "name": "M5324",
      "description": "Spinal instabilities Thoracic region"
    },
    {
      "name": "M5325",
      "description": "Spinal instabilities Thoracolumbar region"
    },
    {
      "name": "M5326",
      "description": "Spinal instabilities Lumbar region"
    },
    {
      "name": "M5327",
      "description": "Spinal instabilities Lumbosacral region"
    },
    {
      "name": "M5328",
      "description": "Spinal instabilities Sacral and sacrococcygeal region"
    },
    {
      "name": "M5329",
      "description": "Spinal instabilities Site unspecified"
    },
    {
      "name": "M533",
      "description": "Sacrococcygeal disorders, not elsewhere classified"
    },
    {
      "name": "M5330",
      "description": "Sacrococcygeal disorders, not elsewhere classified Multiple sites in spine"
    },
    {
      "name": "M5337",
      "description": "Sacrococcygeal disorders, not elsewhere classified Lumbosacral region"
    },
    {
      "name": "M5338",
      "description": "Sacrococcygeal disorders, not elsewhere classified Sacral and sacrococcygeal region"
    },
    {
      "name": "M5339",
      "description": "Sacrococcygeal disorders, not elsewhere classified Site unspecified"
    },
    {
      "name": "M538",
      "description": "Other specified dorsopathies"
    },
    {
      "name": "M5380",
      "description": "Other specified dorsopathies Multiple sites in spine"
    },
    {
      "name": "M5381",
      "description": "Other specified dorsopathies Occipito-atlanto-axial region"
    },
    {
      "name": "M5382",
      "description": "Other specified dorsopathies Cervical region"
    },
    {
      "name": "M5383",
      "description": "Other specified dorsopathies Cervicothoracic region"
    },
    {
      "name": "M5384",
      "description": "Other specified dorsopathies Thoracic region"
    },
    {
      "name": "M5385",
      "description": "Other specified dorsopathies Thoracolumbar region"
    },
    {
      "name": "M5386",
      "description": "Other specified dorsopathies Lumbar region"
    },
    {
      "name": "M5387",
      "description": "Other specified dorsopathies Lumbosacral region"
    },
    {
      "name": "M5388",
      "description": "Other specified dorsopathies Sacral and sacrococcygeal region"
    },
    {
      "name": "M5389",
      "description": "Other specified dorsopathies Site unspecified"
    },
    {
      "name": "M539",
      "description": "Dorsopathy, unspecified"
    },
    {
      "name": "M5390",
      "description": "Dorsopathy, unspecified Multiple sites in spine"
    },
    {
      "name": "M5391",
      "description": "Dorsopathy, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M5392",
      "description": "Dorsopathy, unspecified Cervical region"
    },
    {
      "name": "M5393",
      "description": "Dorsopathy, unspecified Cervicothoracic region"
    },
    {
      "name": "M5394",
      "description": "Dorsopathy, unspecified Thoracic region"
    },
    {
      "name": "M5395",
      "description": "Dorsopathy, unspecified Thoracolumbar region"
    },
    {
      "name": "M5396",
      "description": "Dorsopathy, unspecified Lumbar region"
    },
    {
      "name": "M5397",
      "description": "Dorsopathy, unspecified Lumbosacral region"
    },
    {
      "name": "M5398",
      "description": "Dorsopathy, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M5399",
      "description": "Dorsopathy, unspecified Site unspecified"
    },
    {
      "name": "M540",
      "description": "Panniculitis affecting regions of neck and back"
    },
    {
      "name": "M5400",
      "description": "Panniculitis affecting regions of neck and back Multiple sites in spine"
    },
    {
      "name": "M5401",
      "description": "Panniculitis affecting regions of neck and back Occipito-atlanto-axial region"
    },
    {
      "name": "M5402",
      "description": "Panniculitis affecting regions of neck and back Cervical region"
    },
    {
      "name": "M5403",
      "description": "Panniculitis affecting regions of neck and back Cervicothoracic region"
    },
    {
      "name": "M5404",
      "description": "Panniculitis affecting regions of neck and back Thoracic region"
    },
    {
      "name": "M5405",
      "description": "Panniculitis affecting regions of neck and back Thoracolumbar region"
    },
    {
      "name": "M5406",
      "description": "Panniculitis affecting regions of neck and back Lumbar region"
    },
    {
      "name": "M5407",
      "description": "Panniculitis affecting regions of neck and back Lumbosacral region"
    },
    {
      "name": "M5408",
      "description": "Panniculitis affecting regions of neck and back Sacral and sacrococcygeal region"
    },
    {
      "name": "M5409",
      "description": "Panniculitis affecting regions of neck and back Site unspecified"
    },
    {
      "name": "M541",
      "description": "Radiculopathy"
    },
    {
      "name": "M5410",
      "description": "Radiculopathy Multiple sites in spine"
    },
    {
      "name": "M5411",
      "description": "Radiculopathy Occipito-atlanto-axial region"
    },
    {
      "name": "M5412",
      "description": "Radiculopathy Cervical region"
    },
    {
      "name": "M5413",
      "description": "Radiculopathy Cervicothoracic region"
    },
    {
      "name": "M5414",
      "description": "Radiculopathy Thoracic region"
    },
    {
      "name": "M5415",
      "description": "Radiculopathy Thoracolumbar region"
    },
    {
      "name": "M5416",
      "description": "Radiculopathy Lumbar region"
    },
    {
      "name": "M5417",
      "description": "Radiculopathy Lumbosacral region"
    },
    {
      "name": "M5418",
      "description": "Radiculopathy Sacral and sacrococcygeal region"
    },
    {
      "name": "M5419",
      "description": "Radiculopathy Site unspecified"
    },
    {
      "name": "M542",
      "description": "Cervicalgia"
    },
    {
      "name": "M5420",
      "description": "Cervicalgia Multiple sites in spine"
    },
    {
      "name": "M5421",
      "description": "Cervicalgia Occipito-atlanto-axial region"
    },
    {
      "name": "M5422",
      "description": "Cervicalgia Cervical region"
    },
    {
      "name": "M5423",
      "description": "Cervicalgia Cervicothoracic region"
    },
    {
      "name": "M5429",
      "description": "Cervicalgia Site unspecified"
    },
    {
      "name": "M543",
      "description": "Sciatica"
    },
    {
      "name": "M5430",
      "description": "Sciatica Multiple sites in spine"
    },
    {
      "name": "M5435",
      "description": "Sciatica Thoracolumbar region"
    },
    {
      "name": "M5436",
      "description": "Sciatica Lumbar region"
    },
    {
      "name": "M5437",
      "description": "Sciatica Lumbosacral region"
    },
    {
      "name": "M5438",
      "description": "Sciatica Sacral and sacrococcygeal region"
    },
    {
      "name": "M5439",
      "description": "Sciatica Site unspecified"
    },
    {
      "name": "M544",
      "description": "Lumbago with sciatica"
    },
    {
      "name": "M5440",
      "description": "Lumbago with sciatica Multiple sites in spine"
    },
    {
      "name": "M5445",
      "description": "Lumbago with sciatica Thoracolumbar region"
    },
    {
      "name": "M5446",
      "description": "Lumbago with sciatica Lumbar region"
    },
    {
      "name": "M5447",
      "description": "Lumbago with sciatica Lumbosacral region"
    },
    {
      "name": "M5448",
      "description": "Lumbago with sciatica Sacral and sacrococcygeal region"
    },
    {
      "name": "M5449",
      "description": "Lumbago with sciatica Site unspecified"
    },
    {
      "name": "M545",
      "description": "Low back pain"
    },
    {
      "name": "M5450",
      "description": "Low back pain Multiple sites in spine"
    },
    {
      "name": "M5455",
      "description": "Low back pain Thoracolumbar region"
    },
    {
      "name": "M5456",
      "description": "Low back pain Lumbar region"
    },
    {
      "name": "M5457",
      "description": "Low back pain Lumbosacral region"
    },
    {
      "name": "M5458",
      "description": "Low back pain Sacral and sacrococcygeal region"
    },
    {
      "name": "M5459",
      "description": "Low back pain Site unspecified"
    },
    {
      "name": "M546",
      "description": "Pain in thoracic spine"
    },
    {
      "name": "M5460",
      "description": "Pain in thoracic spine Multiple sites in spine"
    },
    {
      "name": "M5461",
      "description": "Pain in thoracic spine Occipito-atlanto-axial region"
    },
    {
      "name": "M5462",
      "description": "Pain in thoracic spine Cervical region"
    },
    {
      "name": "M5463",
      "description": "Pain in thoracic spine Cervicothoracic region"
    },
    {
      "name": "M5464",
      "description": "Pain in thoracic spine Thoracic region"
    },
    {
      "name": "M5465",
      "description": "Pain in thoracic spine Thoracolumbar region"
    },
    {
      "name": "M5466",
      "description": "Pain in thoracic spine Lumbar region"
    },
    {
      "name": "M5467",
      "description": "Pain in thoracic spine Lumbosacral region"
    },
    {
      "name": "M5468",
      "description": "Pain in thoracic spine Sacral and sacrococcygeal region"
    },
    {
      "name": "M5469",
      "description": "Pain in thoracic spine Site unspecified"
    },
    {
      "name": "M548",
      "description": "Other dorsalgia"
    },
    {
      "name": "M5480",
      "description": "Other dorsalgia Multiple sites in spine"
    },
    {
      "name": "M5481",
      "description": "Other dorsalgia Occipito-atlanto-axial region"
    },
    {
      "name": "M5482",
      "description": "Other dorsalgia Cervical region"
    },
    {
      "name": "M5483",
      "description": "Other dorsalgia Cervicothoracic region"
    },
    {
      "name": "M5484",
      "description": "Other dorsalgia Thoracic region"
    },
    {
      "name": "M5485",
      "description": "Other dorsalgia Thoracolumbar region"
    },
    {
      "name": "M5486",
      "description": "Other dorsalgia Lumbar region"
    },
    {
      "name": "M5487",
      "description": "Other dorsalgia Lumbosacral region"
    },
    {
      "name": "M5488",
      "description": "Other dorsalgia Sacral and sacrococcygeal region"
    },
    {
      "name": "M5489",
      "description": "Other dorsalgia Site unspecified"
    },
    {
      "name": "M549",
      "description": "Dorsalgia, unspecified"
    },
    {
      "name": "M5490",
      "description": "Dorsalgia, unspecified Multiple sites in spine"
    },
    {
      "name": "M5491",
      "description": "Dorsalgia, unspecified Occipito-atlanto-axial region"
    },
    {
      "name": "M5492",
      "description": "Dorsalgia, unspecified Cervical region"
    },
    {
      "name": "M5493",
      "description": "Dorsalgia, unspecified Cervicothoracic region"
    },
    {
      "name": "M5494",
      "description": "Dorsalgia, unspecified Thoracic region"
    },
    {
      "name": "M5495",
      "description": "Dorsalgia, unspecified Thoracolumbar region"
    },
    {
      "name": "M5496",
      "description": "Dorsalgia, unspecified Lumbar region"
    },
    {
      "name": "M5497",
      "description": "Dorsalgia, unspecified Lumbosacral region"
    },
    {
      "name": "M5498",
      "description": "Dorsalgia, unspecified Sacral and sacrococcygeal region"
    },
    {
      "name": "M5499",
      "description": "Dorsalgia, unspecified Site unspecified"
    },
    {
      "name": "M600",
      "description": "Infective myositis"
    },
    {
      "name": "M6000",
      "description": "Infective myositis Multiple sites"
    },
    {
      "name": "M6001",
      "description": "Infective myositis Shoulder region"
    },
    {
      "name": "M6002",
      "description": "Infective myositis Upper arm"
    },
    {
      "name": "M6003",
      "description": "Infective myositis Forearm"
    },
    {
      "name": "M6004",
      "description": "Infective myositis Hand"
    },
    {
      "name": "M6005",
      "description": "Infective myositis Pelvic region and thigh"
    },
    {
      "name": "M6006",
      "description": "Infective myositis Lower leg"
    },
    {
      "name": "M6007",
      "description": "Infective myositis Ankle and foot"
    },
    {
      "name": "M6008",
      "description": "Infective myositis Other"
    },
    {
      "name": "M6009",
      "description": "Infective myositis Site unspecified"
    },
    {
      "name": "M601",
      "description": "Interstitial myositis"
    },
    {
      "name": "M6010",
      "description": "Interstitial myositis Multiple sites"
    },
    {
      "name": "M6011",
      "description": "Interstitial myositis Shoulder region"
    },
    {
      "name": "M6012",
      "description": "Interstitial myositis Upper arm"
    },
    {
      "name": "M6013",
      "description": "Interstitial myositis Forearm"
    },
    {
      "name": "M6014",
      "description": "Interstitial myositis Hand"
    },
    {
      "name": "M6015",
      "description": "Interstitial myositis Pelvic region and thigh"
    },
    {
      "name": "M6016",
      "description": "Interstitial myositis Lower leg"
    },
    {
      "name": "M6017",
      "description": "Interstitial myositis Ankle and foot"
    },
    {
      "name": "M6018",
      "description": "Interstitial myositis Other"
    },
    {
      "name": "M6019",
      "description": "Interstitial myositis Site unspecified"
    },
    {
      "name": "M602",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified"
    },
    {
      "name": "M6020",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Multiple sites"
    },
    {
      "name": "M6021",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Shoulder region"
    },
    {
      "name": "M6022",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Upper arm"
    },
    {
      "name": "M6023",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Forearm"
    },
    {
      "name": "M6024",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Hand"
    },
    {
      "name": "M6025",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M6026",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Lower leg"
    },
    {
      "name": "M6027",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M6028",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Other"
    },
    {
      "name": "M6029",
      "description": "Foreign body granuloma of soft tissue, not elsewhere classified Site unspecified"
    },
    {
      "name": "M608",
      "description": "Other myositis"
    },
    {
      "name": "M6080",
      "description": "Other myositis Multiple sites"
    },
    {
      "name": "M6081",
      "description": "Other myositis Shoulder region"
    },
    {
      "name": "M6082",
      "description": "Other myositis Upper arm"
    },
    {
      "name": "M6083",
      "description": "Other myositis Forearm"
    },
    {
      "name": "M6084",
      "description": "Other myositis Hand"
    },
    {
      "name": "M6085",
      "description": "Other myositis Pelvic region and thigh"
    },
    {
      "name": "M6086",
      "description": "Other myositis Lower leg"
    },
    {
      "name": "M6087",
      "description": "Other myositis Ankle and foot"
    },
    {
      "name": "M6088",
      "description": "Other myositis Other"
    },
    {
      "name": "M6089",
      "description": "Other myositis Site unspecified"
    },
    {
      "name": "M609",
      "description": "Myositis, unspecified"
    },
    {
      "name": "M6090",
      "description": "Myositis, unspecified Multiple sites"
    },
    {
      "name": "M6091",
      "description": "Myositis, unspecified Shoulder region"
    },
    {
      "name": "M6092",
      "description": "Myositis, unspecified Upper arm"
    },
    {
      "name": "M6093",
      "description": "Myositis, unspecified Forearm"
    },
    {
      "name": "M6094",
      "description": "Myositis, unspecified Hand"
    },
    {
      "name": "M6095",
      "description": "Myositis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M6096",
      "description": "Myositis, unspecified Lower leg"
    },
    {
      "name": "M6097",
      "description": "Myositis, unspecified Ankle and foot"
    },
    {
      "name": "M6098",
      "description": "Myositis, unspecified Other"
    },
    {
      "name": "M6099",
      "description": "Myositis, unspecified Site unspecified"
    },
    {
      "name": "M610",
      "description": "Myositis ossificans traumatica"
    },
    {
      "name": "M6100",
      "description": "Myositis ossificans traumatica Multiple sites"
    },
    {
      "name": "M6101",
      "description": "Myositis ossificans traumatica Shoulder region"
    },
    {
      "name": "M6102",
      "description": "Myositis ossificans traumatica Upper arm"
    },
    {
      "name": "M6103",
      "description": "Myositis ossificans traumatica Forearm"
    },
    {
      "name": "M6104",
      "description": "Myositis ossificans traumatica Hand"
    },
    {
      "name": "M6105",
      "description": "Myositis ossificans traumatica Pelvic region and thigh"
    },
    {
      "name": "M6106",
      "description": "Myositis ossificans traumatica Lower leg"
    },
    {
      "name": "M6107",
      "description": "Myositis ossificans traumatica Ankle and foot"
    },
    {
      "name": "M6108",
      "description": "Myositis ossificans traumatica Other"
    },
    {
      "name": "M6109",
      "description": "Myositis ossificans traumatica Site unspecified"
    },
    {
      "name": "M611",
      "description": "Myositis ossificans progressiva"
    },
    {
      "name": "M6110",
      "description": "Myositis ossificans progressiva Multiple sites"
    },
    {
      "name": "M6111",
      "description": "Myositis ossificans progressiva Shoulder region"
    },
    {
      "name": "M6112",
      "description": "Myositis ossificans progressiva Upper arm"
    },
    {
      "name": "M6113",
      "description": "Myositis ossificans progressiva Forearm"
    },
    {
      "name": "M6114",
      "description": "Myositis ossificans progressiva Hand"
    },
    {
      "name": "M6115",
      "description": "Myositis ossificans progressiva Pelvic region and thigh"
    },
    {
      "name": "M6116",
      "description": "Myositis ossificans progressiva Lower leg"
    },
    {
      "name": "M6117",
      "description": "Myositis ossificans progressiva Ankle and thigh"
    },
    {
      "name": "M6118",
      "description": "Myositis ossificans progressiva Other"
    },
    {
      "name": "M6119",
      "description": "Myositis ossificans progressiva Site unspecified"
    },
    {
      "name": "M612",
      "description": "Paralytic calcification and ossification of muscle"
    },
    {
      "name": "M6120",
      "description": "Paralytic calcification and ossification of muscle Multiple sites"
    },
    {
      "name": "M6121",
      "description": "Paralytic calcification and ossification of muscle Shoulder region"
    },
    {
      "name": "M6122",
      "description": "Paralytic calcification and ossification of muscle Upper arm"
    },
    {
      "name": "M6123",
      "description": "Paralytic calcification and ossification of muscle Forearm"
    },
    {
      "name": "M6124",
      "description": "Paralytic calcification and ossification of muscle Hand"
    },
    {
      "name": "M6125",
      "description": "Paralytic calcification and ossification of muscle Pelvic region and thigh"
    },
    {
      "name": "M6126",
      "description": "Paralytic calcification and ossification of muscle Lower leg"
    },
    {
      "name": "M6127",
      "description": "Paralytic calcification and ossification of muscle Ankle and foot"
    },
    {
      "name": "M6128",
      "description": "Paralytic calcification and ossification of muscle Other"
    },
    {
      "name": "M6129",
      "description": "Paralytic calcification and ossification of muscle Site unspecified"
    },
    {
      "name": "M613",
      "description": "Calcification and ossification of muscles associated with burns"
    },
    {
      "name": "M6130",
      "description": "Calcification and ossification of muscles associated with burns Multiple sites"
    },
    {
      "name": "M6131",
      "description": "Calcification and ossification of muscles associated with burns Shoulder region"
    },
    {
      "name": "M6132",
      "description": "Calcification and ossification of muscles associated with burns Upper arm"
    },
    {
      "name": "M6133",
      "description": "Calcification and ossification of muscles associated with burns Forearm"
    },
    {
      "name": "M6134",
      "description": "Calcification and ossification of muscles associated with burns Hand"
    },
    {
      "name": "M6135",
      "description": "Calcification and ossification of muscles associated with burns Pelvic region and thigh"
    },
    {
      "name": "M6136",
      "description": "Calcification and ossification of muscles associated with burns Lower leg"
    },
    {
      "name": "M6137",
      "description": "Calcification and ossification of muscles associated with burns Ankle and foot"
    },
    {
      "name": "M6138",
      "description": "Calcification and ossification of muscles associated with burns Other"
    },
    {
      "name": "M6139",
      "description": "Calcification and ossification of muscles associated with burns Site unspecified"
    },
    {
      "name": "M614",
      "description": "Other calcification of muscle"
    },
    {
      "name": "M6140",
      "description": "Other calcification of muscle Multiple sites"
    },
    {
      "name": "M6141",
      "description": "Other calcification of muscle Shoulder region"
    },
    {
      "name": "M6142",
      "description": "Other calcification of muscle Upper arm"
    },
    {
      "name": "M6143",
      "description": "Other calcification of muscle Forearm"
    },
    {
      "name": "M6144",
      "description": "Other calcification of muscle Hand"
    },
    {
      "name": "M6145",
      "description": "Other calcification of muscle Pelvic region and thigh"
    },
    {
      "name": "M6146",
      "description": "Other calcification of muscle Lower leg"
    },
    {
      "name": "M6147",
      "description": "Other calcification of muscle Ankle and foot"
    },
    {
      "name": "M6148",
      "description": "Other calcification of muscle Other"
    },
    {
      "name": "M6149",
      "description": "Other calcification of muscle Site unspecified"
    },
    {
      "name": "M615",
      "description": "Other ossification of muscle"
    },
    {
      "name": "M6150",
      "description": "Other ossification of muscle Multiple sites"
    },
    {
      "name": "M6151",
      "description": "Other ossification of muscle Shoulder region"
    },
    {
      "name": "M6152",
      "description": "Other ossification of muscle Upper arm"
    },
    {
      "name": "M6153",
      "description": "Other ossification of muscle Forearm"
    },
    {
      "name": "M6154",
      "description": "Other ossification of muscle Hand"
    },
    {
      "name": "M6155",
      "description": "Other ossification of muscle Pelvic region and thigh"
    },
    {
      "name": "M6156",
      "description": "Other ossification of muscle Lower leg"
    },
    {
      "name": "M6157",
      "description": "Other ossification of muscle Ankle and foot"
    },
    {
      "name": "M6158",
      "description": "Other ossification of muscle Other"
    },
    {
      "name": "M6159",
      "description": "Other ossification of muscle Site unspecified"
    },
    {
      "name": "M619",
      "description": "Calcification and ossification of muscle, unspecified"
    },
    {
      "name": "M6190",
      "description": "Calcification and ossification of muscle, unspecified Multiple sites"
    },
    {
      "name": "M6191",
      "description": "Calcification and ossification of muscle, unspecified Shoulder region"
    },
    {
      "name": "M6192",
      "description": "Calcification and ossification of muscle, unspecified Upper arm"
    },
    {
      "name": "M6193",
      "description": "Calcification and ossification of muscle, unspecified Forearm"
    },
    {
      "name": "M6194",
      "description": "Calcification and ossification of muscle, unspecified Hand"
    },
    {
      "name": "M6195",
      "description": "Calcification and ossification of muscle, unspecified Pelvic region and thigh"
    },
    {
      "name": "M6196",
      "description": "Calcification and ossification of muscle, unspecified Lower leg"
    },
    {
      "name": "M6197",
      "description": "Calcification and ossification of muscle, unspecified Ankle and foot"
    },
    {
      "name": "M6198",
      "description": "Calcification and ossification of muscle, unspecified Other"
    },
    {
      "name": "M6199",
      "description": "Calcification and ossification of muscle, unspecified Site unspecified"
    },
    {
      "name": "M620",
      "description": "Diastasis of muscle"
    },
    {
      "name": "M6200",
      "description": "Diastasis of muscle Multiple sites"
    },
    {
      "name": "M6201",
      "description": "Diastasis of muscle Shoulder region"
    },
    {
      "name": "M6202",
      "description": "Diastasis of muscle Upper arm"
    },
    {
      "name": "M6203",
      "description": "Diastasis of muscle Forearm"
    },
    {
      "name": "M6204",
      "description": "Diastasis of muscle Hand"
    },
    {
      "name": "M6205",
      "description": "Diastasis of muscle Pelvic region and thigh"
    },
    {
      "name": "M6206",
      "description": "Diastasis of muscle Lower leg"
    },
    {
      "name": "M6207",
      "description": "Diastasis of muscle Ankle and foot"
    },
    {
      "name": "M6208",
      "description": "Diastasis of muscle Other"
    },
    {
      "name": "M6209",
      "description": "Diastasis of muscle Site unspecified"
    },
    {
      "name": "M621",
      "description": "Other rupture of muscle (nontraumatic)"
    },
    {
      "name": "M6210",
      "description": "Other rupture of muscle ( nontraumatic ) Multiple sites"
    },
    {
      "name": "M6211",
      "description": "Other rupture of muscle ( nontraumatic ) Shoulder region"
    },
    {
      "name": "M6212",
      "description": "Other rupture of muscle ( nontraumatic ) Upper arm"
    },
    {
      "name": "M6213",
      "description": "Other rupture of muscle ( nontraumatic ) Forearm"
    },
    {
      "name": "M6214",
      "description": "Other rupture of muscle ( nontraumatic ) Hand"
    },
    {
      "name": "M6215",
      "description": "Other rupture of muscle ( nontraumatic ) Pelvic region and thigh"
    },
    {
      "name": "M6216",
      "description": "Other rupture of muscle ( nontraumatic ) Lower leg"
    },
    {
      "name": "M6217",
      "description": "Other rupture of muscle ( nontraumatic ) Ankle and foot"
    },
    {
      "name": "M6218",
      "description": "Other rupture of muscle ( nontraumatic ) Other"
    },
    {
      "name": "M6219",
      "description": "Other rupture of muscle ( nontraumatic ) Site unspecified"
    },
    {
      "name": "M622",
      "description": "Ischaemic infarction of muscle"
    },
    {
      "name": "M6220",
      "description": "Ischaemic infarction of muscle Multiple sites"
    },
    {
      "name": "M6221",
      "description": "Ischaemic infarction of muscle Shoulder region"
    },
    {
      "name": "M6222",
      "description": "Ischaemic infarction of muscle Upper arm"
    },
    {
      "name": "M6223",
      "description": "Ischaemic infarction of muscle Forearm"
    },
    {
      "name": "M6224",
      "description": "Ischaemic infarction of muscle Hand"
    },
    {
      "name": "M6225",
      "description": "Ischaemic infarction of muscle Pelvic region and thigh"
    },
    {
      "name": "M6226",
      "description": "Ischaemic infarction of muscle Lower leg"
    },
    {
      "name": "M6227",
      "description": "Ischaemic infarction of muscle Ankle and foot"
    },
    {
      "name": "M6228",
      "description": "Ischaemic infarction of muscle Other"
    },
    {
      "name": "M6229",
      "description": "Ischaemic infarction of muscle Site unspecified"
    },
    {
      "name": "M623",
      "description": "Immobility syndrome (paraplegic)"
    },
    {
      "name": "M6230",
      "description": "Immobility syndrome ( paraplegic ) Multiple sites"
    },
    {
      "name": "M6231",
      "description": "Immobility syndrome ( paraplegic ) Shoulder region"
    },
    {
      "name": "M6232",
      "description": "Immobility syndrome ( paraplegic ) Upper arm"
    },
    {
      "name": "M6233",
      "description": "Immobility syndrome ( paraplegic ) Forearm"
    },
    {
      "name": "M6234",
      "description": "Immobility syndrome ( paraplegic ) Hand"
    },
    {
      "name": "M6235",
      "description": "Immobility syndrome ( paraplegic ) Pelvic region and thigh"
    },
    {
      "name": "M6236",
      "description": "Immobility syndrome ( paraplegic ) Lower leg"
    },
    {
      "name": "M6237",
      "description": "Immobility syndrome ( paraplegic ) Ankle and foot"
    },
    {
      "name": "M6238",
      "description": "Immobility syndrome ( paraplegic ) Other"
    },
    {
      "name": "M6239",
      "description": "Immobility syndrome ( paraplegic ) Site unspecified"
    },
    {
      "name": "M624",
      "description": "Contracture of muscle"
    },
    {
      "name": "M6240",
      "description": "Contracture of muscle Multiple sites"
    },
    {
      "name": "M6241",
      "description": "Contracture of muscle Shoulder region"
    },
    {
      "name": "M6242",
      "description": "Contracture of muscle Upper arm"
    },
    {
      "name": "M6243",
      "description": "Contracture of muscle Forearm"
    },
    {
      "name": "M6244",
      "description": "Contracture of muscle Hand"
    },
    {
      "name": "M6245",
      "description": "Contracture of muscle Pelvic region and thigh"
    },
    {
      "name": "M6246",
      "description": "Contracture of muscle Lower leg"
    },
    {
      "name": "M6247",
      "description": "Contracture of muscle Ankle and foot"
    },
    {
      "name": "M6248",
      "description": "Contracture of muscle Other"
    },
    {
      "name": "M6249",
      "description": "Contracture of muscle Site unspecified"
    },
    {
      "name": "M625",
      "description": "Muscle wasting and atrophy, not elsewhere classified"
    },
    {
      "name": "M6250",
      "description": "Muscle wasting and atrophy, not elsewhere classified Multiple sites"
    },
    {
      "name": "M6251",
      "description": "Muscle wasting and atrophy, not elsewhere classified Shoulder region"
    },
    {
      "name": "M6252",
      "description": "Muscle wasting and atrophy, not elsewhere classified Upper arm"
    },
    {
      "name": "M6253",
      "description": "Muscle wasting and atrophy, not elsewhere classified Forearm"
    },
    {
      "name": "M6254",
      "description": "Muscle wasting and atrophy, not elsewhere classified Hand"
    },
    {
      "name": "M6255",
      "description": "Muscle wasting and atrophy, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M6256",
      "description": "Muscle wasting and atrophy, not elsewhere classified Lower leg"
    },
    {
      "name": "M6257",
      "description": "Muscle wasting and atrophy, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M6258",
      "description": "Muscle wasting and atrophy, not elsewhere classified Other"
    },
    {
      "name": "M6259",
      "description": "Muscle wasting and atrophy, not elsewhere classified Site unspecified"
    },
    {
      "name": "M626",
      "description": "Muscle strain"
    },
    {
      "name": "M6260",
      "description": "Muscle strain Multiple sites"
    },
    {
      "name": "M6261",
      "description": "Muscle strain Shoulder region"
    },
    {
      "name": "M6262",
      "description": "Muscle strain Upper arm"
    },
    {
      "name": "M6263",
      "description": "Muscle strain Forearm"
    },
    {
      "name": "M6264",
      "description": "Muscle strain Hand"
    },
    {
      "name": "M6265",
      "description": "Muscle strain Pelvic region and thigh"
    },
    {
      "name": "M6266",
      "description": "Muscle strain Lower leg"
    },
    {
      "name": "M6267",
      "description": "Muscle strain Ankle and foot"
    },
    {
      "name": "M6268",
      "description": "Muscle strain Other"
    },
    {
      "name": "M6269",
      "description": "Muscle strain Site unspecified"
    },
    {
      "name": "M627",
      "description": "Rhabdomyolysis "
    },
    {
      "name": "M6270",
      "description": "Rhabdomyolysis Multiple sites"
    },
    {
      "name": "M6271",
      "description": "Rhabdomyolysis Shoulder region"
    },
    {
      "name": "M6272",
      "description": "Rhabdomyolysis Upper arm"
    },
    {
      "name": "M6273",
      "description": "Rhabdomyolysis Forearm"
    },
    {
      "name": "M6274",
      "description": "Rhabdomyolysis Hand"
    },
    {
      "name": "M6275",
      "description": "Rhabdomyolysis Pelvic region and thigh"
    },
    {
      "name": "M6276",
      "description": "Rhabdomyolysis Lower leg"
    },
    {
      "name": "M6277",
      "description": "Rhabdomyolysis Ankle and foot"
    },
    {
      "name": "M6278",
      "description": "Rhabdomyolysis Other"
    },
    {
      "name": "M6279",
      "description": "Rhabdomyolysis Site unspecified"
    },
    {
      "name": "M628",
      "description": "Other specified disorders of muscle"
    },
    {
      "name": "M6280",
      "description": "Other specified disorders of muscle Multiple sites"
    },
    {
      "name": "M6281",
      "description": "Other specified disorders of muscle Shoulder region"
    },
    {
      "name": "M6282",
      "description": "Other specified disorders of muscle Upper arm"
    },
    {
      "name": "M6283",
      "description": "Other specified disorders of muscle Forearm"
    },
    {
      "name": "M6284",
      "description": "Other specified disorders of muscle Hand"
    },
    {
      "name": "M6285",
      "description": "Other specified disorders of muscle Pelvic region and thigh"
    },
    {
      "name": "M6286",
      "description": "Other specified disorders of muscle Lower leg"
    },
    {
      "name": "M6287",
      "description": "Other specified disorders of muscle Ankle and foot"
    },
    {
      "name": "M6288",
      "description": "Other specified disorders of muscle Other"
    },
    {
      "name": "M6289",
      "description": "Other specified disorders of muscle Site unspecified"
    },
    {
      "name": "M629",
      "description": "Disorder of muscle, unspecified"
    },
    {
      "name": "M6290",
      "description": "Disorder of muscle, unspecified Multiple sites"
    },
    {
      "name": "M6291",
      "description": "Disorder of muscle, unspecified Shoulder region"
    },
    {
      "name": "M6292",
      "description": "Disorder of muscle, unspecified Upper arm"
    },
    {
      "name": "M6293",
      "description": "Disorder of muscle, unspecified Forearm"
    },
    {
      "name": "M6294",
      "description": "Disorder of muscle, unspecified Hand"
    },
    {
      "name": "M6295",
      "description": "Disorder of muscle, unspecified Pelvic region and thigh"
    },
    {
      "name": "M6296",
      "description": "Disorder of muscle, unspecified Lower leg"
    },
    {
      "name": "M6297",
      "description": "Disorder of muscle, unspecified Ankle and foot"
    },
    {
      "name": "M6298",
      "description": "Disorder of muscle, unspecified Other"
    },
    {
      "name": "M6299",
      "description": "Disorder of muscle, unspecified Site unspecified"
    },
    {
      "name": "M630",
      "description": "Myositis in basterial diseases classified elsewhere"
    },
    {
      "name": "M631",
      "description": "Myositis in protozoal and parasitic infections classified elsewhere"
    },
    {
      "name": "M632",
      "description": "Myositis in other infectious diseases classified elsewhere"
    },
    {
      "name": "M633",
      "description": "Myositis in sarcoidosis ( D86.8 )"
    },
    {
      "name": "M638",
      "description": "Other disorders of muscle in diseases classified elsewhere"
    },
    {
      "name": "M650",
      "description": "Abscess of tendon sheath"
    },
    {
      "name": "M6500",
      "description": "Abscess of tendon sheath Multiple sites"
    },
    {
      "name": "M6501",
      "description": "Abscess of tendon sheath Shoulder region"
    },
    {
      "name": "M6502",
      "description": "Abscess of tendon sheath Upper arm"
    },
    {
      "name": "M6503",
      "description": "Abscess of tendon sheath Forearm"
    },
    {
      "name": "M6504",
      "description": "Abscess of tendon sheath Hand"
    },
    {
      "name": "M6505",
      "description": "Abscess of tendon sheath Pelvic region and thigh"
    },
    {
      "name": "M6506",
      "description": "Abscess of tendon sheath Lower leg"
    },
    {
      "name": "M6507",
      "description": "Abscess of tendon sheath Ankle and foot"
    },
    {
      "name": "M6508",
      "description": "Abscess of tendon sheath Other"
    },
    {
      "name": "M6509",
      "description": "Abscess of tendon sheath Site unspecified"
    },
    {
      "name": "M651",
      "description": "Other infective (teno)synovitis"
    },
    {
      "name": "M6510",
      "description": "Other infective (teno)synovitis Multiple sites"
    },
    {
      "name": "M6511",
      "description": "Other infective (teno)synovitis Shoulder region"
    },
    {
      "name": "M6512",
      "description": "Other infective (teno)synovitis Upper arm"
    },
    {
      "name": "M6513",
      "description": "Other infective (teno)synovitis Forearm"
    },
    {
      "name": "M6514",
      "description": "Other infective (teno)synovitis Hand"
    },
    {
      "name": "M6515",
      "description": "Other infective (teno)synovitis Pelvic region and thigh"
    },
    {
      "name": "M6516",
      "description": "Other infective (teno)synovitis Lower leg"
    },
    {
      "name": "M6517",
      "description": "Other infective (teno)synovitis Ankle and foot"
    },
    {
      "name": "M6518",
      "description": "Other infective (teno)synovitis Other"
    },
    {
      "name": "M6519",
      "description": "Other infective (teno)synovitis Site unspecified"
    },
    {
      "name": "M652",
      "description": "Calcific tendinitis"
    },
    {
      "name": "M6520",
      "description": "Calcific tendinitis Multiple sites"
    },
    {
      "name": "M6521",
      "description": "Calcific tendinitis Shoulder region"
    },
    {
      "name": "M6522",
      "description": "Calcific tendinitis Upper arm"
    },
    {
      "name": "M6523",
      "description": "Calcific tendinitis Forearm"
    },
    {
      "name": "M6524",
      "description": "Calcific tendinitis Hand"
    },
    {
      "name": "M6525",
      "description": "Calcific tendinitis  Pelvic region and thigh"
    },
    {
      "name": "M6526",
      "description": "Calcific tendinitis Lower leg"
    },
    {
      "name": "M6527",
      "description": "Calcific tendinitis Ankle and foot"
    },
    {
      "name": "M6528",
      "description": "Calcific tendinitis Other"
    },
    {
      "name": "M6529",
      "description": "Calcific tendinitis Site unspecified"
    },
    {
      "name": "M653",
      "description": "Trigger finger"
    },
    {
      "name": "M6530",
      "description": "Trigger finger Multiple sites"
    },
    {
      "name": "M6534",
      "description": "Trigger finger Hand"
    },
    {
      "name": "M6539",
      "description": "Trigger finger Site unspecified"
    },
    {
      "name": "M654",
      "description": "Radial styloid tenosynovitis [de Quervain]"
    },
    {
      "name": "M6540",
      "description": "Radial styloid tenosynovitis [ de Quervain ] Multiple sites"
    },
    {
      "name": "M6543",
      "description": "Radial styloid tenosynovitis [ de Quervain ] Forearm"
    },
    {
      "name": "M6544",
      "description": "Radial styloid tenosynovitis [ de Quervain ] Other"
    },
    {
      "name": "M6549",
      "description": "Radial styloid tenosynovitis [ de Quervain ] Site unspecified"
    },
    {
      "name": "M658",
      "description": "Other synovitis and tenosynovitis"
    },
    {
      "name": "M6580",
      "description": "Other synovitis and tenosynovitis Multiple sites"
    },
    {
      "name": "M6581",
      "description": "Other synovitis and tenosynovitis Shoulder region"
    },
    {
      "name": "M6582",
      "description": "Other synovitis and tenosynovitis Upper arm"
    },
    {
      "name": "M6583",
      "description": "Other synovitis and tenosynovitis Forearm"
    },
    {
      "name": "M6584",
      "description": "Other synovitis and tenosynovitis Hand"
    },
    {
      "name": "M6585",
      "description": "Other synovitis and tenosynovitis Pelvic region and thig"
    },
    {
      "name": "M6586",
      "description": "Other synovitis and tenosynovitis Lower leg"
    },
    {
      "name": "M6587",
      "description": "Other synovitis and tenosynovitis Ankle and foot"
    },
    {
      "name": "M6588",
      "description": "Other synovitis and tenosynovitis Other"
    },
    {
      "name": "M6589",
      "description": "Other synovitis and tenosynovitis Site unspecified"
    },
    {
      "name": "M659",
      "description": "Synovitis and tenosynovitis, unspecified"
    },
    {
      "name": "M6590",
      "description": "Synovitis and tenosynovitis, unspecified Multiple sites"
    },
    {
      "name": "M6591",
      "description": "Synovitis and tenosynovitis, unspecified Shoulder region"
    },
    {
      "name": "M6592",
      "description": "Synovitis and tenosynovitis, unspecified Upper arm"
    },
    {
      "name": "M6593",
      "description": "Synovitis and tenosynovitis, unspecified Forearm"
    },
    {
      "name": "M6594",
      "description": "Synovitis and tenosynovitis, unspecified Hand"
    },
    {
      "name": "M6595",
      "description": "Synovitis and tenosynovitis, unspecified Pelvic region and thig"
    },
    {
      "name": "M6596",
      "description": "Synovitis and tenosynovitis, unspecified Lower leg"
    },
    {
      "name": "M6597",
      "description": "Synovitis and tenosynovitis, unspecified Ankle and foot"
    },
    {
      "name": "M6598",
      "description": "Synovitis and tenosynovitis, unspecified Other"
    },
    {
      "name": "M6599",
      "description": "Synovitis and tenosynovitis, unspecified Site unspecified"
    },
    {
      "name": "M660",
      "description": "Rupture of popliteal cyst"
    },
    {
      "name": "M6600",
      "description": "Rupture of popliteal cyst Multiple sites"
    },
    {
      "name": "M6606",
      "description": "Rupture of popliteal cyst Lower leg"
    },
    {
      "name": "M6609",
      "description": "Rupture of popliteal cyst Site unspecified"
    },
    {
      "name": "M661",
      "description": "Rupture of synovium"
    },
    {
      "name": "M6610",
      "description": "Rupture of synovium Multiple sites"
    },
    {
      "name": "M6611",
      "description": "Rupture of synovium Shoulder region"
    },
    {
      "name": "M6612",
      "description": "Rupture of synovium Upper arm"
    },
    {
      "name": "M6613",
      "description": "Rupture of synovium Forearm"
    },
    {
      "name": "M6614",
      "description": "Rupture of synovium Hand"
    },
    {
      "name": "M6615",
      "description": "Rupture of synovium Pelvic region and thigh"
    },
    {
      "name": "M6616",
      "description": "Rupture of synovium Lower leg"
    },
    {
      "name": "M6617",
      "description": "Rupture of synovium Ankle and foot"
    },
    {
      "name": "M6618",
      "description": "Rupture of synovium Other"
    },
    {
      "name": "M6619",
      "description": "Rupture of synovium Site unspecified"
    },
    {
      "name": "M662",
      "description": "Spontaneous rupture of extensor tendons"
    },
    {
      "name": "M6620",
      "description": "Spontaneous rupture of extensor tendons Multiple sites"
    },
    {
      "name": "M6621",
      "description": "Spontaneous rupture of extensor tendons Shoulder region"
    },
    {
      "name": "M6622",
      "description": "Spontaneous rupture of extensor tendons Upper arm"
    },
    {
      "name": "M6623",
      "description": "Spontaneous rupture of extensor tendons Forearm"
    },
    {
      "name": "M6624",
      "description": "Spontaneous rupture of extensor tendons Hand"
    },
    {
      "name": "M6625",
      "description": "Spontaneous rupture of extensor tendons Pelvic region and thigh"
    },
    {
      "name": "M6626",
      "description": "Spontaneous rupture of extensor tendons Lower leg"
    },
    {
      "name": "M6627",
      "description": "Spontaneous rupture of extensor tendons Ankle and foot"
    },
    {
      "name": "M6628",
      "description": "Spontaneous rupture of extensor tendons Other"
    },
    {
      "name": "M6629",
      "description": "Spontaneous rupture of extensor tendons Site unspecified"
    },
    {
      "name": "M663",
      "description": "Spontaneous rupture of flexor tendons"
    },
    {
      "name": "M6630",
      "description": "Spontaneous rupture of flexor tendons Multiple sites"
    },
    {
      "name": "M6631",
      "description": "Spontaneous rupture of flexor tendons Shoulder region"
    },
    {
      "name": "M6632",
      "description": "Spontaneous rupture of flexor tendons Upper arm"
    },
    {
      "name": "M6633",
      "description": "Spontaneous rupture of flexor tendons Forearm"
    },
    {
      "name": "M6634",
      "description": "Spontaneous rupture of flexor tendons Hand"
    },
    {
      "name": "M6635",
      "description": "Spontaneous rupture of flexor tendons Pevic region and thigh"
    },
    {
      "name": "M6636",
      "description": "Spontaneous rupture of flexor tendons Lower leg"
    },
    {
      "name": "M6637",
      "description": "Spontaneous rupture of flexor tendons Ankle and foot"
    },
    {
      "name": "M6638",
      "description": "Spontaneous rupture of flexor tendons Other"
    },
    {
      "name": "M6639",
      "description": "Spontaneous rupture of flexor tendons Site unspecified"
    },
    {
      "name": "M664",
      "description": "Spontaneous rupture of other tendons"
    },
    {
      "name": "M6640",
      "description": "Spontaneous rupture of other tendons Multiple sites"
    },
    {
      "name": "M6641",
      "description": "Spontaneous rupture of other tendons Shoulder region"
    },
    {
      "name": "M6642",
      "description": "Spontaneous rupture of other tendons Upper arm"
    },
    {
      "name": "M6643",
      "description": "Spontaneous rupture of other tendons Forearm"
    },
    {
      "name": "M6644",
      "description": "Spontaneous rupture of other tendons Hand"
    },
    {
      "name": "M6645",
      "description": "Spontaneous rupture of other tendons Pelvic region and thigh"
    },
    {
      "name": "M6646",
      "description": "Spontaneous rupture of other tendons Lower leg"
    },
    {
      "name": "M6647",
      "description": "Spontaneous rupture of other tendons Ankle and foot"
    },
    {
      "name": "M6648",
      "description": "Spontaneous rupture of other tendons Other"
    },
    {
      "name": "M6649",
      "description": "Spontaneous rupture of other tendons Site unspecified"
    },
    {
      "name": "M665",
      "description": "Spontaneous rupture of unspecified tendon"
    },
    {
      "name": "M6650",
      "description": "Spontaneous rupture of unspecified tendon Multiple sites"
    },
    {
      "name": "M6651",
      "description": "Spontaneous rupture of unspecified tendon Shoulder region"
    },
    {
      "name": "M6652",
      "description": "Spontaneous rupture of unspecified tendon Upper arm"
    },
    {
      "name": "M6653",
      "description": "Spontaneous rupture of unspecified tendon Forearm"
    },
    {
      "name": "M6654",
      "description": "Spontaneous rupture of unspecified tendon Hand"
    },
    {
      "name": "M6655",
      "description": "Spontaneous rupture of unspecified tendon Pelvic region and thigh"
    },
    {
      "name": "M6656",
      "description": "Spontaneous rupture of unspecified tendon Lower leg"
    },
    {
      "name": "M6657",
      "description": "Spontaneous rupture of unspecified tendon Ankle and foot"
    },
    {
      "name": "M6658",
      "description": "Spontaneous rupture of unspecified tendon Other"
    },
    {
      "name": "M6659",
      "description": "Spontaneous rupture of unspecified tendon Site unspecified"
    },
    {
      "name": "M670",
      "description": "Short Achilles tendon (acquired)"
    },
    {
      "name": "M671",
      "description": "Other contracture of tendon (sheath)"
    },
    {
      "name": "M672",
      "description": "Synovial hypertrophy, not elsewhere classified"
    },
    {
      "name": "M673",
      "description": "Transient synovitis"
    },
    {
      "name": "M674",
      "description": "Ganglion"
    },
    {
      "name": "M678",
      "description": "Other specified disorders of synovium and tendon"
    },
    {
      "name": "M679",
      "description": "Disorder of synovium and tendon, unspecified"
    },
    {
      "name": "M680",
      "description": "Synovitis and tenosynovitis in bacterial diseases classified elsewhere"
    },
    {
      "name": "M688",
      "description": "Other disorders of synovium and tendon in diseases classified elsewhere"
    },
    {
      "name": "M700",
      "description": "Chronic crepitant synovitis of hand and wrist"
    },
    {
      "name": "M7000",
      "description": "Chronic crepitant synovitis of hand and wrist Multiple sites"
    },
    {
      "name": "M7004",
      "description": "Chronic crepitant synovitis of hand and wrist Hand"
    },
    {
      "name": "M7009",
      "description": "Chronic crepitant synovitis of hand and wrist Site unspecified"
    },
    {
      "name": "M701",
      "description": "Bursitis of hand"
    },
    {
      "name": "M7010",
      "description": "Bursitis of hand Multiple sites"
    },
    {
      "name": "M7014",
      "description": "Bursitis of hand Hand"
    },
    {
      "name": "M7019",
      "description": "Bursitis of hand Site unspecified"
    },
    {
      "name": "M702",
      "description": "Olecranon bursitis"
    },
    {
      "name": "M7020",
      "description": "Olecranon bursitis Multiple sites"
    },
    {
      "name": "M7022",
      "description": "Olecranon bursitis Upper arm"
    },
    {
      "name": "M7029",
      "description": "Olecranon bursitis Site unspecified"
    },
    {
      "name": "M703",
      "description": "Other bursitis of elbow"
    },
    {
      "name": "M7030",
      "description": "Other bursitis of elbow Multiple sites"
    },
    {
      "name": "M7032",
      "description": "Other bursitis of elbow Upper arm"
    },
    {
      "name": "M7039",
      "description": "Other bursitis of elbow Site unspecified"
    },
    {
      "name": "M704",
      "description": "Prepatellar bursitis"
    },
    {
      "name": "M7040",
      "description": "Prepatellar bursitis Multiple sites"
    },
    {
      "name": "M7046",
      "description": "Prepatellar busitis Lower leg"
    },
    {
      "name": "M7049",
      "description": "Prepatellar busitis Site unspecified"
    },
    {
      "name": "M705",
      "description": "Other bursitis of knee"
    },
    {
      "name": "M7050",
      "description": "Other bursitis of knee Multiple sites"
    },
    {
      "name": "M7056",
      "description": "Other bursitis of knee Lower leg"
    },
    {
      "name": "M7059",
      "description": "Other bursitis of knee Site unspecified"
    },
    {
      "name": "M706",
      "description": "Trochanteric bursitis"
    },
    {
      "name": "M7060",
      "description": "Trochanteric bursitis Multiple sites"
    },
    {
      "name": "M7065",
      "description": "Trochanteric bursitis Pelvic region and thigh"
    },
    {
      "name": "M7069",
      "description": "Trochanteric bursitis Site unspecified"
    },
    {
      "name": "M707",
      "description": "Other bursitis of hip"
    },
    {
      "name": "M7070",
      "description": "Other bursitis of hip Multiple sites"
    },
    {
      "name": "M7075",
      "description": "Other bursitis of hip Pelvic region and thigh"
    },
    {
      "name": "M7079",
      "description": "Other bursitis of hip Site unspecified"
    },
    {
      "name": "M708",
      "description": "Other soft tissue disorders related to use, overuse and pressure"
    },
    {
      "name": "M7080",
      "description": "Other soft tissue disorders related to use, overuse and pressure Multiple sites"
    },
    {
      "name": "M7081",
      "description": "Other soft tissue disorders related to use, overuse and pressure Shoulder region"
    },
    {
      "name": "M7082",
      "description": "Other soft tissue disorders related to use, overuse and pressure Upper arm"
    },
    {
      "name": "M7083",
      "description": "Other soft tissue disorders related to use, overuse and pressure Forearm"
    },
    {
      "name": "M7084",
      "description": "Other soft tissue disorders related to use, overuse and pressure Hand"
    },
    {
      "name": "M7085",
      "description": "Other soft tissue disorders related to use, overuse and pressure Pelvic region and thigh"
    },
    {
      "name": "M7086",
      "description": "Other soft tissue disorders related to use, overuse and pressure Lower leg"
    },
    {
      "name": "M7087",
      "description": "Other soft tissue disorders related to use, overuse and pressure Ankle and foot"
    },
    {
      "name": "M7088",
      "description": "Other soft tissue disorders related to use, overuse and pressure Other"
    },
    {
      "name": "M7089",
      "description": "Other soft tissue disorders related to use, overuse and pressure Site unspecified"
    },
    {
      "name": "M709",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure"
    },
    {
      "name": "M7090",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Multiple sites"
    },
    {
      "name": "M7091",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Shoulder region"
    },
    {
      "name": "M7092",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Upper arm"
    },
    {
      "name": "M7093",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Forearm"
    },
    {
      "name": "M7094",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Hand"
    },
    {
      "name": "M7095",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Pelvic region and thigh"
    },
    {
      "name": "M7096",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Lower leg"
    },
    {
      "name": "M7097",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Ankle and foot"
    },
    {
      "name": "M7098",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Other"
    },
    {
      "name": "M7099",
      "description": "Unspecified soft tissue disorder related to use, overuse and pressure Site unspecified"
    },
    {
      "name": "M710",
      "description": "Abscess of bursa"
    },
    {
      "name": "M7100",
      "description": "Abscess of bursa Multiple sites"
    },
    {
      "name": "M7101",
      "description": "Abscess of bursa Shoulder region"
    },
    {
      "name": "M7102",
      "description": "Abscess of bursa Upper arm"
    },
    {
      "name": "M7103",
      "description": "Abscess of bursa Forearm"
    },
    {
      "name": "M7104",
      "description": "Abscess of bursa Hand"
    },
    {
      "name": "M7105",
      "description": "Abscess of bursa Pelvic region and thigh"
    },
    {
      "name": "M7106",
      "description": "Abscess of bursa Lower leg"
    },
    {
      "name": "M7107",
      "description": "Abscess of bursa Ankle and foot"
    },
    {
      "name": "M7108",
      "description": "Abscess of bursa Other"
    },
    {
      "name": "M7109",
      "description": "Abscess of bursa Site unspecified"
    },
    {
      "name": "M711",
      "description": "Other infective bursitis"
    },
    {
      "name": "M7110",
      "description": "Other infective bursitis Multiple sites"
    },
    {
      "name": "M7111",
      "description": "Other infective bursitis Shoulder region"
    },
    {
      "name": "M7112",
      "description": "Other infective bursitis Upper arm"
    },
    {
      "name": "M7113",
      "description": "Other infective bursitis Forearm"
    },
    {
      "name": "M7114",
      "description": "Other infective bursitis Hand"
    },
    {
      "name": "M7115",
      "description": "Other infective bursitis Pelvic region and thigh"
    },
    {
      "name": "M7116",
      "description": "Other infective bursitis Lower leg"
    },
    {
      "name": "M7117",
      "description": "Other infective bursitis Ankle and foot"
    },
    {
      "name": "M7118",
      "description": "Other infective bursitis Other"
    },
    {
      "name": "M7119",
      "description": "Other infective bursitis Site unspecified"
    },
    {
      "name": "M712",
      "description": "Synovial cyst of popliteal space [Baker]"
    },
    {
      "name": "M7120",
      "description": "Synovial cyst of popliteal space [ Baker ] Multiple sites"
    },
    {
      "name": "M7126",
      "description": "Synovial cyst of popliteal space [ Baker ] Lower leg"
    },
    {
      "name": "M7129",
      "description": "Synovial cyst of popliteal space [ Baker ] Site unspecified"
    },
    {
      "name": "M713",
      "description": "Other bursal cyst"
    },
    {
      "name": "M7130",
      "description": "Other bursal cyst Multiple sites"
    },
    {
      "name": "M7131",
      "description": "Other bursal cyst Shoulder region"
    },
    {
      "name": "M7132",
      "description": "Other bursal cyst Upper arm"
    },
    {
      "name": "M7133",
      "description": "Other bursal cyst Forearm"
    },
    {
      "name": "M7134",
      "description": "Other bursal cyst Hand"
    },
    {
      "name": "M7135",
      "description": "Other bursal cyst Pelvic region and thigh"
    },
    {
      "name": "M7136",
      "description": "Other bursal cyst Lower leg"
    },
    {
      "name": "M7137",
      "description": "Other bursal cyst Ankle and foot"
    },
    {
      "name": "M7138",
      "description": "Other bursal cyst Other"
    },
    {
      "name": "M7139",
      "description": "Other bursal cyst Site unspecified"
    },
    {
      "name": "M714",
      "description": "Calcium deposit in bursa"
    },
    {
      "name": "M7140",
      "description": "Calcium deposit in bursa Multiple sites"
    },
    {
      "name": "M7141",
      "description": "Calcium deposit in bursa Shoulder region"
    },
    {
      "name": "M7142",
      "description": "Calcium deposit in bursa Upper arm"
    },
    {
      "name": "M7143",
      "description": "Calcium deposit in bursa Forearm"
    },
    {
      "name": "M7144",
      "description": "Calcium deposit in bursa Hand"
    },
    {
      "name": "M7145",
      "description": "Calcium deposit in bursa Pelvic region and thigh"
    },
    {
      "name": "M7146",
      "description": "Calcium deposit in bursa Lower leg"
    },
    {
      "name": "M7147",
      "description": "Calcium deposit in bursa Ankle and foot"
    },
    {
      "name": "M7148",
      "description": "Calcium deposit in bursa Other"
    },
    {
      "name": "M7149",
      "description": "Calcium deposit in bursa Site unspecified"
    },
    {
      "name": "M715",
      "description": "Other bursitis, not elsewhere classified"
    },
    {
      "name": "M7150",
      "description": "Other bursitis, not elsewhere classified Multiple sites"
    },
    {
      "name": "M7151",
      "description": "Other bursitis, not elsewhere classified Shoulder region"
    },
    {
      "name": "M7152",
      "description": "Other bursitis, not elsewhere classified Upper arm"
    },
    {
      "name": "M7153",
      "description": "Other bursitis, not elsewhere classified Forearm"
    },
    {
      "name": "M7154",
      "description": "Other bursitis, not elsewhere classified Hand"
    },
    {
      "name": "M7155",
      "description": "Other bursitis, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M7156",
      "description": "Other bursitis, not elsewhere classified Lower leg"
    },
    {
      "name": "M7157",
      "description": "Other bursitis, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M7158",
      "description": "Other bursitis, not elsewhere classified Other"
    },
    {
      "name": "M7159",
      "description": "Other bursitis, not elsewhere classified Site unspecified"
    },
    {
      "name": "M718",
      "description": "Other specified bursopathies"
    },
    {
      "name": "M7180",
      "description": "Other specified bursopathies Multiple sites"
    },
    {
      "name": "M7181",
      "description": "Other specified bursopathies Shoulder region"
    },
    {
      "name": "M7182",
      "description": "Other specified bursopathies Upper arm"
    },
    {
      "name": "M7183",
      "description": "Other specified bursopathies Forearm"
    },
    {
      "name": "M7184",
      "description": "Other specified bursopathies Hand"
    },
    {
      "name": "M7185",
      "description": "Other specified bursopathies Pelvic region and thigh"
    },
    {
      "name": "M7186",
      "description": "Other specified bursopathies Lower leg"
    },
    {
      "name": "M7187",
      "description": "Other specified bursopathies Ankle and foot"
    },
    {
      "name": "M7188",
      "description": "Other specified bursopathies Other"
    },
    {
      "name": "M7189",
      "description": "Other specified bursopathies Site unspecified"
    },
    {
      "name": "M719",
      "description": "Bursopathy, unspecified"
    },
    {
      "name": "M7190",
      "description": "Bursopathy, unspecified Multiple sites"
    },
    {
      "name": "M7191",
      "description": "Bursopathy, unspecified Shoulder region"
    },
    {
      "name": "M7192",
      "description": "Bursopathy, unspecified Upper arm"
    },
    {
      "name": "M7193",
      "description": "Bursopathy, unspecified Forearm"
    },
    {
      "name": "M7194",
      "description": "Bursopathy, unspecified Hand"
    },
    {
      "name": "M7195",
      "description": "Bursopathy, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7196",
      "description": "Bursopathy, unspecified Lower leg"
    },
    {
      "name": "M7197",
      "description": "Bursopathy, unspecified Ankle and foot"
    },
    {
      "name": "M7198",
      "description": "Bursopathy, unspecified Other"
    },
    {
      "name": "M7199",
      "description": "Bursopathy, unspecified Site unspecified"
    },
    {
      "name": "M720",
      "description": "Palmar fascial fibromatosis [Dupuytren]"
    },
    {
      "name": "M7200",
      "description": "Palmar fascial fibromatosis [ Dupuytren ] Multiple sites"
    },
    {
      "name": "M7204",
      "description": "Palmar fascial fibromatosis [ Dupuytren ] Hand"
    },
    {
      "name": "M7209",
      "description": "Palmar fascial fibromatosis [ Dupuytren ] Site unspecified"
    },
    {
      "name": "M721",
      "description": "Knuckle pads"
    },
    {
      "name": "M7210",
      "description": "Knuckle pads Multiple sites"
    },
    {
      "name": "M7214",
      "description": "Knuckle pads Hand"
    },
    {
      "name": "M7219",
      "description": "Knuckle pads Site unspecified"
    },
    {
      "name": "M722",
      "description": "Plantar fascial fibromatosis"
    },
    {
      "name": "M7220",
      "description": "Plantar fascial fibromatosis Multiple sites"
    },
    {
      "name": "M7227",
      "description": "Plantar fascial fibromatosis Ankle and foot"
    },
    {
      "name": "M7229",
      "description": "Plantar fascial fibromatosis Site unspecified"
    },
    {
      "name": "M724",
      "description": "Pseudosarcomatous fibromatosis"
    },
    {
      "name": "M7240",
      "description": "Pseudosarcomatous fibromatosis Multiple sites"
    },
    {
      "name": "M7241",
      "description": "Pseudosarcomatous fibromatosis Shoulder region"
    },
    {
      "name": "M7242",
      "description": "Pseudosarcomatous fibromatosis Upper arm"
    },
    {
      "name": "M7243",
      "description": "Pseudosarcomatous fibromatosis Forearm"
    },
    {
      "name": "M7244",
      "description": "Pseudosarcomatous fibromatosis Hand"
    },
    {
      "name": "M7245",
      "description": "Pseudosarcomatous fibromatosis Pelvic region and thigh"
    },
    {
      "name": "M7246",
      "description": "Pseudosarcomatous fibromatosis Lower leg"
    },
    {
      "name": "M7247",
      "description": "Pseudosarcomatous fibromatosis Ankle and foot"
    },
    {
      "name": "M7248",
      "description": "Pseudosarcomatous fibromatosis Other"
    },
    {
      "name": "M7249",
      "description": "Pseudosarcomatous fibromatosis Site unspecified"
    },
    {
      "name": "M726",
      "description": "Necrotizing fasciitis"
    },
    {
      "name": "M7260",
      "description": "Necrotizing fasciitis Multiple sites"
    },
    {
      "name": "M7261",
      "description": "Necrotizing fasciitis Shoulder region"
    },
    {
      "name": "M7262",
      "description": "Necrotizing fasciitis Upper arm"
    },
    {
      "name": "M7263",
      "description": "Necrotizing fasciitis Forearm"
    },
    {
      "name": "M7264",
      "description": "Necrotizing fasciitis Hand"
    },
    {
      "name": "M7265",
      "description": "Necrotizing fasciitis Pelvic region and thigh"
    },
    {
      "name": "M7266",
      "description": "Necrotizing fasciitis Lower leg"
    },
    {
      "name": "M7267",
      "description": "Necrotizing fasciitis Ankle and foot"
    },
    {
      "name": "M7268",
      "description": "Necrotizing fasciitis Other"
    },
    {
      "name": "M7269",
      "description": "Necrotizing fasciitis Site unspecified"
    },
    {
      "name": "M728",
      "description": "Other fibroblastic disorders"
    },
    {
      "name": "M7280",
      "description": "Other fibroblastic disorders Multiple sites"
    },
    {
      "name": "M7281",
      "description": "Other fibroblastic disorders Shoulder region"
    },
    {
      "name": "M7282",
      "description": "Other fibroblastic disorders Upper arm"
    },
    {
      "name": "M7283",
      "description": "Other fibroblastic disorders Forearm"
    },
    {
      "name": "M7284",
      "description": "Other fibroblastic disorders Hand"
    },
    {
      "name": "M7285",
      "description": "Other fibroblastic disorders Pelvic region and thigh"
    },
    {
      "name": "M7286",
      "description": "Other fibroblastic disorders Lower leg"
    },
    {
      "name": "M7287",
      "description": "Other fibroblastic disorders Ankle and foot"
    },
    {
      "name": "M7288",
      "description": "Other fibroblastic disorders Other"
    },
    {
      "name": "M7289",
      "description": "Other fibroblastic disorders Site unspecified"
    },
    {
      "name": "M729",
      "description": "Fibroblastic disorder, unspecified"
    },
    {
      "name": "M7290",
      "description": "Fibroblastic disorder, unspecified Multiple sites"
    },
    {
      "name": "M7291",
      "description": "Fibroblastic disorder, unspecified Shoulder region"
    },
    {
      "name": "M7292",
      "description": "Fibroblastic disorder, unspecified Upper arm"
    },
    {
      "name": "M7293",
      "description": "Fibroblastic disorder, unspecified Forearm"
    },
    {
      "name": "M7294",
      "description": "Fibroblastic disorder, unspecified Hand"
    },
    {
      "name": "M7295",
      "description": "Fibroblastic disorder, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7296",
      "description": "Fibroblastic disorder, unspecified Lower leg"
    },
    {
      "name": "M7297",
      "description": "Fibroblastic disorder, unspecified Ankle and foot"
    },
    {
      "name": "M7298",
      "description": "Fibroblastic disorder, unspecified Other"
    },
    {
      "name": "M7299",
      "description": "Fibroblastic disorder, unspecified Site unspecified"
    },
    {
      "name": "M730",
      "description": "Gonococcal bursitis ( A54.4 )"
    },
    {
      "name": "M7300",
      "description": "Gonococcal bursitis ( A54.4 ) Multiple sites"
    },
    {
      "name": "M7301",
      "description": "Gonococcal bursitis ( A54.4 ) Shoulder region"
    },
    {
      "name": "M7302",
      "description": "Gonococcal bursitis ( A54.4 ) Upper arm"
    },
    {
      "name": "M7303",
      "description": "Gonococcal bursitis ( A54.4 ) Forearm"
    },
    {
      "name": "M7304",
      "description": "Gonococcal bursitis ( A54.4 ) Hand"
    },
    {
      "name": "M7305",
      "description": "Gonococcal bursitis ( A54.4 ) Pelvic region and thigh"
    },
    {
      "name": "M7306",
      "description": "Gonococcal bursitis ( A54.4 ) Lower leg"
    },
    {
      "name": "M7307",
      "description": "Gonococcal bursitis ( A54.4 ) Ankle and foot"
    },
    {
      "name": "M7308",
      "description": "Gonococcal bursitis ( A54.4 ) Other"
    },
    {
      "name": "M7309",
      "description": "Gonococcal bursitis ( A54.4 ) Site unspecified"
    },
    {
      "name": "M731",
      "description": "Syphilitic bursitis ( A52.7 )"
    },
    {
      "name": "M7310",
      "description": "Syphilitic bursitis ( A52.7 ) Multiple sites"
    },
    {
      "name": "M7311",
      "description": "Syphilitic bursitis ( A52.7 ) Shoulder region"
    },
    {
      "name": "M7312",
      "description": "Syphilitic bursitis ( A52.7 ) Upper arm"
    },
    {
      "name": "M7313",
      "description": "Syphilitic bursitis ( A52.7 ) Forearm"
    },
    {
      "name": "M7314",
      "description": "Syphilitic bursitis ( A52.7 ) Hand"
    },
    {
      "name": "M7315",
      "description": "Syphilitic bursitis ( A52.7 ) Pelvic region and thigh"
    },
    {
      "name": "M7316",
      "description": "Syphilitic bursitis ( A52.7 ) Lower leg"
    },
    {
      "name": "M7317",
      "description": "Syphilitic bursitis ( A52.7 ) Ankle and foot"
    },
    {
      "name": "M7318",
      "description": "Syphilitic bursitis ( A52.7 ) Other"
    },
    {
      "name": "M7319",
      "description": "Syphilitic bursitis ( A52.7 ) Site unspecified"
    },
    {
      "name": "M738",
      "description": "Other soft tissue disorders in diseases classified elsewhere"
    },
    {
      "name": "M7380",
      "description": "Other soft tissue disorders in diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M7381",
      "description": "Other soft tissue disorders in diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M7382",
      "description": "Other soft tissue disorders in diseases classified elsewhere Upper arm"
    },
    {
      "name": "M7383",
      "description": "Other soft tissue disorders in diseases classified elsewhere Forearm"
    },
    {
      "name": "M7384",
      "description": "Other soft tissue disorders in diseases classified elsewhere Hand"
    },
    {
      "name": "M7385",
      "description": "Other soft tissue disorders in diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M7386",
      "description": "Other soft tissue disorders in diseases classified elsewhere Lower leg"
    },
    {
      "name": "M7387",
      "description": "Other soft tissue disorders in diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M7388",
      "description": "Other soft tissue disorders in diseases classified elsewhere Other"
    },
    {
      "name": "M7389",
      "description": "Other soft tissue disorders in diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M750",
      "description": "Adhesive capsulitis of shoulder"
    },
    {
      "name": "M751",
      "description": "Rotator cuff syndrome"
    },
    {
      "name": "M752",
      "description": "Bicipital tendinitis"
    },
    {
      "name": "M753",
      "description": "Calcific tendinitis of shoulder"
    },
    {
      "name": "M754",
      "description": "Impingement syndrome of shoulder"
    },
    {
      "name": "M755",
      "description": "Bursitis of shoulder"
    },
    {
      "name": "M758",
      "description": "Other shoulder lesions"
    },
    {
      "name": "M759",
      "description": "Shoulder lesion, unspecified"
    },
    {
      "name": "M760",
      "description": "Gluteal tendinitis"
    },
    {
      "name": "M7600",
      "description": "Gluteal tendinitis Multiple sites"
    },
    {
      "name": "M7605",
      "description": "Gluteal tendinitis Pelvic region and thigh"
    },
    {
      "name": "M7608",
      "description": "Gluteal tendinitis Other"
    },
    {
      "name": "M7609",
      "description": "Gluteal tendinitis Site unspecified"
    },
    {
      "name": "M761",
      "description": "Psoas tendinitis"
    },
    {
      "name": "M7610",
      "description": "Psoas tendinitis Multiple sites"
    },
    {
      "name": "M7618",
      "description": "Psoas tendinitis Other"
    },
    {
      "name": "M7619",
      "description": "Psoas tendinitis Site unspecified"
    },
    {
      "name": "M762",
      "description": "Iliac crest spur"
    },
    {
      "name": "M7620",
      "description": "Iliac crest spur Multiple sites"
    },
    {
      "name": "M7625",
      "description": "Iliac crest spur Pelvic region and thigh"
    },
    {
      "name": "M7629",
      "description": "Iliac crest spur Site unspecified"
    },
    {
      "name": "M763",
      "description": "Iliotibial band syndrome"
    },
    {
      "name": "M7630",
      "description": "Iliotibial band syndrome Multiple sites"
    },
    {
      "name": "M7635",
      "description": "Iliotibial band syndrome Pelvic region and thigh"
    },
    {
      "name": "M7636",
      "description": "Iliotibial band syndrome Lower leg"
    },
    {
      "name": "M7639",
      "description": "Iliotibial band syndrome Site unspecified"
    },
    {
      "name": "M764",
      "description": "Tibial collateral bursitis [Pellegrini-Stieda]"
    },
    {
      "name": "M7640",
      "description": "Tibial collateral bursitis [ Pellegrini-Stieda ] Multiple sites"
    },
    {
      "name": "M7646",
      "description": "Tibial collateral bursitis [ Pellegrini-Stieda ] Lower leg"
    },
    {
      "name": "M7649",
      "description": "Tibial collateral bursitis [ Pellegrini-Stieda ] Site unspecified"
    },
    {
      "name": "M765",
      "description": "Patellar tendinitis"
    },
    {
      "name": "M7650",
      "description": "Patellar tendinitis Multiple sites"
    },
    {
      "name": "M7656",
      "description": "Patellar tendinitis Lower leg"
    },
    {
      "name": "M7659",
      "description": "Patellar tendinitis Site unspecified"
    },
    {
      "name": "M766",
      "description": "Achilles tendinitis"
    },
    {
      "name": "M7660",
      "description": "Achilles tendinitis Multiple sites"
    },
    {
      "name": "M7666",
      "description": "Achilles tendinitis Lower leg"
    },
    {
      "name": "M7667",
      "description": "Achilles tendinitis Ankle and foot"
    },
    {
      "name": "M7669",
      "description": "Achilles tendinitis Site unspecified"
    },
    {
      "name": "M767",
      "description": "Peroneal tendinitis"
    },
    {
      "name": "M7670",
      "description": "Peroneal tendinitis Multiple sites"
    },
    {
      "name": "M7676",
      "description": "Peroneal tendinitis Lower leg"
    },
    {
      "name": "M7677",
      "description": "Peroneal tendinitis Ankle and foot"
    },
    {
      "name": "M7679",
      "description": "Peroneal tendinitis Site unspecified"
    },
    {
      "name": "M768",
      "description": "Other enthesopathies of lower limb, excluding foot"
    },
    {
      "name": "M7680",
      "description": "Other enthesopathies of lower limb, excluding foot Multiple sites"
    },
    {
      "name": "M7685",
      "description": "Other enthesopathies of lower limb, excluding foot Pelvic region and thigh"
    },
    {
      "name": "M7686",
      "description": "Other enthesopathies of lower limb, excluding foot Lower leg"
    },
    {
      "name": "M7689",
      "description": "Other enthesopathies of lower limb, excluding foot Site unspecified"
    },
    {
      "name": "M769",
      "description": "Enthesopathy of lower limb, unspecified"
    },
    {
      "name": "M7690",
      "description": "Enthesopathy of lower limb, unspecified Multiple sites"
    },
    {
      "name": "M7695",
      "description": "Enthesopathy of lower limb, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7696",
      "description": "Enthesopathy of lower limb, unspecified Lower leg"
    },
    {
      "name": "M7697",
      "description": "Enthesopathy of lower limb, unspecified Ankle and foot"
    },
    {
      "name": "M7699",
      "description": "Enthesopathy of lower limb, unspecified Site unspecified"
    },
    {
      "name": "M770",
      "description": "Medial epicondylitis"
    },
    {
      "name": "M7700",
      "description": "Medial epicondylitis Multiple sites"
    },
    {
      "name": "M7702",
      "description": "Medial epicondylitis Upper arm"
    },
    {
      "name": "M7709",
      "description": "Medial epicondylitis Site unspecified"
    },
    {
      "name": "M771",
      "description": "Lateral epicondylitis"
    },
    {
      "name": "M7710",
      "description": "Lateral epicondylitis Multiple sites"
    },
    {
      "name": "M7712",
      "description": "Lateral epicondylitis Upper arm"
    },
    {
      "name": "M7719",
      "description": "Lateral epicondylitis Site unspecified"
    },
    {
      "name": "M772",
      "description": "Periarthritis of wrist"
    },
    {
      "name": "M7720",
      "description": "Periarthritis of wrist Multiple sites"
    },
    {
      "name": "M7723",
      "description": "Periarthritis of wrist Forearm"
    },
    {
      "name": "M7729",
      "description": "Periarthritis of wrist Site unspecified"
    },
    {
      "name": "M773",
      "description": "Calcaneal spur"
    },
    {
      "name": "M7730",
      "description": "Calcaneal spur Multiple sites"
    },
    {
      "name": "M7737",
      "description": "Calcaneal spur Ankle and foot"
    },
    {
      "name": "M7739",
      "description": "Calcaneal spur Site unspecified"
    },
    {
      "name": "M774",
      "description": "Metatarsalgia"
    },
    {
      "name": "M7740",
      "description": "Metatasalgia Multiple sites"
    },
    {
      "name": "M7741",
      "description": "Metatasalgia Shoulder region"
    },
    {
      "name": "M7747",
      "description": "Metatasalgia Ankle and foot"
    },
    {
      "name": "M7749",
      "description": "Metatasalgia Site unspecified"
    },
    {
      "name": "M775",
      "description": "Other enthesopathy of foot"
    },
    {
      "name": "M7750",
      "description": "Other enthesopathy of foot Multiple sites"
    },
    {
      "name": "M7757",
      "description": "Other enthesopathy of foot Ankle and foot"
    },
    {
      "name": "M7759",
      "description": "Other enthesopathy of foot Site unspecified"
    },
    {
      "name": "M778",
      "description": "Other enthesopathies, not elsewhere classified"
    },
    {
      "name": "M7780",
      "description": "Other enthesopathies, not elsewhere classified Multiple sites"
    },
    {
      "name": "M7781",
      "description": "Other enthesopathies, not elsewhere classified Shoulder region"
    },
    {
      "name": "M7782",
      "description": "Other enthesopathies, not elsewhere classified Upper arm"
    },
    {
      "name": "M7783",
      "description": "Other enthesopathies, not elsewhere classified Forearm"
    },
    {
      "name": "M7784",
      "description": "Other enthesopathies, not elsewhere classified Hand"
    },
    {
      "name": "M7785",
      "description": "Other enthesopathies, not elsewhere classified Pelvic region and foot"
    },
    {
      "name": "M7786",
      "description": "Other enthesopathies, not elsewhere classified Lower leg"
    },
    {
      "name": "M7787",
      "description": "Other enthesopathies, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M7788",
      "description": "Other enthesopathies, not elsewhere classified Other"
    },
    {
      "name": "M7789",
      "description": "Other enthesopathies, not elsewhere classified Site unspecified"
    },
    {
      "name": "M779",
      "description": "Enthesopathy, unspecified"
    },
    {
      "name": "M7790",
      "description": "Enthesopathy, unspecified Multiple sites"
    },
    {
      "name": "M7791",
      "description": "Enthesopathy, unspecified Shoulder region"
    },
    {
      "name": "M7792",
      "description": "Enthesopathy, unspecified Upper arm"
    },
    {
      "name": "M7793",
      "description": "Enthesopathy, unspecified Forearm"
    },
    {
      "name": "M7794",
      "description": "Enthesopathy, unspecified Hand"
    },
    {
      "name": "M7795",
      "description": "Enthesopathy, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7796",
      "description": "Enthesopathy, unspecified Lower leg"
    },
    {
      "name": "M7797",
      "description": "Enthesopathy, unspecified Ankle and foot"
    },
    {
      "name": "M7798",
      "description": "Enthesopathy, unspecified Other"
    },
    {
      "name": "M7799",
      "description": "Enthesopathy, unspecified Site unspecified"
    },
    {
      "name": "M790",
      "description": "Rheumatism, unspecified"
    },
    {
      "name": "M7900",
      "description": "Rheumatism, unspecified Multiple sites"
    },
    {
      "name": "M7901",
      "description": "Rheumatism, unspecified Shoulder region"
    },
    {
      "name": "M7902",
      "description": "Rheumatism, unspecified Upper arm"
    },
    {
      "name": "M7903",
      "description": "Rheumatism, unspecified Forearm"
    },
    {
      "name": "M7904",
      "description": "Rheumatism, unspecified Hand"
    },
    {
      "name": "M7905",
      "description": "Rheumatism, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7906",
      "description": "Rheumatism, unspecified Lower leg"
    },
    {
      "name": "M7907",
      "description": "Rheumatism, unspecified Ankle and foot"
    },
    {
      "name": "M7908",
      "description": "Rheumatism, unspecified Other"
    },
    {
      "name": "M7909",
      "description": "Rheumatism, unspecified Site unspecified"
    },
    {
      "name": "M791",
      "description": "Myalgia"
    },
    {
      "name": "M7910",
      "description": "Myalgia Multiple sites"
    },
    {
      "name": "M7911",
      "description": "Myalgia Shoulder region"
    },
    {
      "name": "M7912",
      "description": "Myalgia Upper arm"
    },
    {
      "name": "M7913",
      "description": "Myalgia Forearm"
    },
    {
      "name": "M7914",
      "description": "Myalgia Hand"
    },
    {
      "name": "M7915",
      "description": "Myalgia Pelvic region and thigh"
    },
    {
      "name": "M7916",
      "description": "Myalgia Lower leg"
    },
    {
      "name": "M7917",
      "description": "Myalgia Ankle and foot"
    },
    {
      "name": "M7918",
      "description": "Myalgia Other"
    },
    {
      "name": "M7919",
      "description": "Myalgia Site unspecified"
    },
    {
      "name": "M792",
      "description": "Neuralgia and neuritis, unspecified"
    },
    {
      "name": "M7920",
      "description": "Neuralgia and neuritis, unspecified Multiple sites"
    },
    {
      "name": "M7921",
      "description": "Neuralgia and neuritis, unspecified Shoulder region"
    },
    {
      "name": "M7922",
      "description": "Neuralgia and neuritis, unspecified Upper arm"
    },
    {
      "name": "M7923",
      "description": "Neuralgia and neuritis, unspecified Forearm"
    },
    {
      "name": "M7924",
      "description": "Neuralgia and neuritis, unspecified Hand"
    },
    {
      "name": "M7925",
      "description": "Neuralgia and neuritis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7926",
      "description": "Neuralgia and neuritis, unspecified Lower leg"
    },
    {
      "name": "M7927",
      "description": "Neuralgia and neuritis, unspecified Ankle and foot"
    },
    {
      "name": "M7928",
      "description": "Neuralgia and neuritis, unspecified Other"
    },
    {
      "name": "M7929",
      "description": "Neuralgia and neuritis, unspecified Site unspecified"
    },
    {
      "name": "M793",
      "description": "Panniculitis, unspecified"
    },
    {
      "name": "M7930",
      "description": "Panniculitis, unspecified Multiple sites"
    },
    {
      "name": "M7931",
      "description": "Panniculitis, unspecified Shoulder region"
    },
    {
      "name": "M7932",
      "description": "Panniculitis, unspecified Upper arm"
    },
    {
      "name": "M7933",
      "description": "Panniculitis, unspecified Forearm"
    },
    {
      "name": "M7934",
      "description": "Panniculitis, unspecified Hand"
    },
    {
      "name": "M7935",
      "description": "Panniculitis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7936",
      "description": "Panniculitis, unspecified Lower leg"
    },
    {
      "name": "M7937",
      "description": "Panniculitis, unspecified Ankle and foot"
    },
    {
      "name": "M7938",
      "description": "Panniculitis, unspecified Other"
    },
    {
      "name": "M7939",
      "description": "Panniculitis, unspecified Site unspecified"
    },
    {
      "name": "M794",
      "description": "Hypertrophy of (infrapatellar) fat pad"
    },
    {
      "name": "M7940",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Multiple sites"
    },
    {
      "name": "M7941",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Shoulder region"
    },
    {
      "name": "M7942",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Upper arm"
    },
    {
      "name": "M7943",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Forearm"
    },
    {
      "name": "M7944",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Hand"
    },
    {
      "name": "M7945",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Pelvic region and thigh"
    },
    {
      "name": "M7946",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Lower leg"
    },
    {
      "name": "M7947",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Ankle and foot"
    },
    {
      "name": "M7948",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Other"
    },
    {
      "name": "M7949",
      "description": "Hypertrophy of ( infrapatellar ) fat pad Site unspecified"
    },
    {
      "name": "M795",
      "description": "Residual foreign body in soft tissue"
    },
    {
      "name": "M7950",
      "description": "Residual foreign body in soft tissue Multiple sites"
    },
    {
      "name": "M7951",
      "description": "Residual foreign body in soft tissue Shoulder region"
    },
    {
      "name": "M7952",
      "description": "Residual foreign body in soft tissue Upper arm"
    },
    {
      "name": "M7953",
      "description": "Residual foreign body in soft tissue Forearm"
    },
    {
      "name": "M7954",
      "description": "Residual foreign body in soft tissue Hand"
    },
    {
      "name": "M7955",
      "description": "Residual foreign body in soft tissue Pelvic region and thigh"
    },
    {
      "name": "M7956",
      "description": "Residual foreign body in soft tissue Lower leg"
    },
    {
      "name": "M7957",
      "description": "Residual foreign body in soft tissue Ankle and foot"
    },
    {
      "name": "M7958",
      "description": "Residual foreign body in soft tissue Other"
    },
    {
      "name": "M7959",
      "description": "Residual foreign body in soft tissue Site unspecified"
    },
    {
      "name": "M796",
      "description": "Pain in limb"
    },
    {
      "name": "M7960",
      "description": "Pain in limb Multiple sites"
    },
    {
      "name": "M7961",
      "description": "Pain in limb Shoulder region"
    },
    {
      "name": "M7962",
      "description": "Pain in limb Upper arm"
    },
    {
      "name": "M7963",
      "description": "Pain in limb Forearm"
    },
    {
      "name": "M7964",
      "description": "Pain in limb Hand"
    },
    {
      "name": "M7965",
      "description": "Pain in limb Pelvic region and thigh"
    },
    {
      "name": "M7966",
      "description": "Pain in limb Lower leg"
    },
    {
      "name": "M7967",
      "description": "Pain in limb Ankle and foot"
    },
    {
      "name": "M7968",
      "description": "Pain in limb Other"
    },
    {
      "name": "M7969",
      "description": "Pain in limb Site unspecified"
    },
    {
      "name": "M797",
      "description": "Fibromyalgia"
    },
    {
      "name": "M7970",
      "description": "Fibromyalgia Multiple sites"
    },
    {
      "name": "M7971",
      "description": "Fibromyalgia Shoulder region"
    },
    {
      "name": "M7972",
      "description": "Fibromyalgia Upper arm"
    },
    {
      "name": "M7973",
      "description": "Fibromyalgia Forearm"
    },
    {
      "name": "M7974",
      "description": "Fibromyalgia Hand"
    },
    {
      "name": "M7975",
      "description": "Fibromyalgia Pelvic region and thigh"
    },
    {
      "name": "M7976",
      "description": "Fibromyalgia Lower leg"
    },
    {
      "name": "M7977",
      "description": "Fibromyalgia Ankle and foot"
    },
    {
      "name": "M7978",
      "description": "Fibromyalgia Other"
    },
    {
      "name": "M7979",
      "description": "Fibromyalgia Site unspecified"
    },
    {
      "name": "M798",
      "description": "Other specified soft tissue disorders"
    },
    {
      "name": "M7980",
      "description": "Other specified soft tissue disorders Multiple sites"
    },
    {
      "name": "M7981",
      "description": "Other specified soft tissue disorders Shoulder region"
    },
    {
      "name": "M7982",
      "description": "Other specified soft tissue disorders Upper arm"
    },
    {
      "name": "M7983",
      "description": "Other specified soft tissue disorders Forearm"
    },
    {
      "name": "M7984",
      "description": "Other specified soft tissue disorders Hand"
    },
    {
      "name": "M7985",
      "description": "Other specified soft tissue disorders Pelvic region and thigh"
    },
    {
      "name": "M7986",
      "description": "Other specified soft tissue disorders Lower leg"
    },
    {
      "name": "M7987",
      "description": "Other specified soft tissue disorders Ankle and foot"
    },
    {
      "name": "M7988",
      "description": "Other specified soft tissue disorders Other"
    },
    {
      "name": "M7989",
      "description": "Other specified soft tissue disorders Site unspecified"
    },
    {
      "name": "M799",
      "description": "Soft tissue disorder, unspecified"
    },
    {
      "name": "M7990",
      "description": "Soft tissue disorders, unspecified Multiple sites"
    },
    {
      "name": "M7991",
      "description": "Soft tissue disorders, unspecified Shoulder region"
    },
    {
      "name": "M7992",
      "description": "Soft tissue disorders, unspecified Upper arm"
    },
    {
      "name": "M7993",
      "description": "Soft tissue disorders, unspecified Forearm"
    },
    {
      "name": "M7994",
      "description": "Soft tissue disorders, unspecified Hand"
    },
    {
      "name": "M7995",
      "description": "Soft tissue disorders, unspecified Pelvic region and thigh"
    },
    {
      "name": "M7996",
      "description": "Soft tissue disorders, unspecified Lower leg"
    },
    {
      "name": "M7997",
      "description": "Soft tissue disorders, unspecified Ankle and foot"
    },
    {
      "name": "M7998",
      "description": "Soft tissue disorders, unspecified Other"
    },
    {
      "name": "M7999",
      "description": "Soft tissue disorders, unspecified Site unspecified"
    },
    {
      "name": "M800",
      "description": "Postmenopausal osteoporosis with pathological fracture"
    },
    {
      "name": "M8000",
      "description": "Postmenopausal osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8001",
      "description": "Postmenopausal osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8002",
      "description": "Postmenopausal osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8003",
      "description": "Postmenopausal osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8004",
      "description": "Postmenopausal osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8005",
      "description": "Postmenopausal osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8006",
      "description": "Postmenopausal osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8007",
      "description": "Postmenopausal osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8008",
      "description": "Postmenopausal osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8009",
      "description": "Postmenopausal osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M801",
      "description": "Postoophorectomy osteoporosis with pathological fracture"
    },
    {
      "name": "M8010",
      "description": "Postoophorectomy osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8011",
      "description": "Postoophorectomy osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8012",
      "description": "Postoophorectomy osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8013",
      "description": "Postoophorectomy osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8014",
      "description": "Postoophorectomy osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8015",
      "description": "Postoophorectomy osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8016",
      "description": "Postoophorectomy osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8017",
      "description": "Postoophorectomy osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8018",
      "description": "Postoophorectomy osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8019",
      "description": "Postoophorectomy osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M802",
      "description": "Osteoporosis of disuse with pathological fracture"
    },
    {
      "name": "M8020",
      "description": "Osteoporosis of disuse with pathological fracture Multiple sites"
    },
    {
      "name": "M8021",
      "description": "Osteoporosis of disuse with pathological fracture Shoulder region"
    },
    {
      "name": "M8022",
      "description": "Osteoporosis of disuse with pathological fracture Upper arm"
    },
    {
      "name": "M8023",
      "description": "Osteoporosis of disuse with pathological fracture Forearm"
    },
    {
      "name": "M8024",
      "description": "Osteoporosis of disuse with pathological fracture Hand"
    },
    {
      "name": "M8025",
      "description": "Osteoporosis of disuse with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8026",
      "description": "Osteoporosis of disuse with pathological fracture Lower leg"
    },
    {
      "name": "M8027",
      "description": "Osteoporosis of disuse with pathological fracture Ankle and foot"
    },
    {
      "name": "M8028",
      "description": "Osteoporosis of disuse with pathological fracture Other"
    },
    {
      "name": "M8029",
      "description": "Osteoporosis of disuse with pathological fracture Site unspecified"
    },
    {
      "name": "M803",
      "description": "Postsurgical malabsorption osteoporosis with patholgical fracture"
    },
    {
      "name": "M8030",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8031",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8032",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8033",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8034",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8035",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8036",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8037",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8038",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8039",
      "description": "Postsurgical malabsorption osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M804",
      "description": "Drug-induced osteoporosis with pathological fracture"
    },
    {
      "name": "M8040",
      "description": "Drug-induced osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8041",
      "description": "Drug-induced osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8042",
      "description": "Drug-induced osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8043",
      "description": "Drug-induced osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8044",
      "description": "Drug-induced osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8045",
      "description": "Drug-induced osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8046",
      "description": "Drug-induced osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8047",
      "description": "Drug-induced osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8048",
      "description": "Drug-induced osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8049",
      "description": "Drug-induced osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M805",
      "description": "Idiopathic osteoporosis with pathological fracture"
    },
    {
      "name": "M8050",
      "description": "Idiopathic osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8051",
      "description": "Idiopathic osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8052",
      "description": "Idiopathic osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8053",
      "description": "Idiopathic osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8054",
      "description": "Idiopathic osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8055",
      "description": "Idiopathic osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8056",
      "description": "Idiopathic osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8057",
      "description": "Idiopathic osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8058",
      "description": "Idiopathic osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8059",
      "description": "Idiopathic osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M808",
      "description": "Other osteoporosis with pathological fracture"
    },
    {
      "name": "M8080",
      "description": "Other osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8081",
      "description": "Other osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8082",
      "description": "Other osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8083",
      "description": "Other osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8084",
      "description": "Other osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8085",
      "description": "Other osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8086",
      "description": "Other osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8087",
      "description": "Other osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8088",
      "description": "Other osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8089",
      "description": "Other osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M809",
      "description": "Unspecified osteoporosis with pathological fracture"
    },
    {
      "name": "M8090",
      "description": "Unspecified osteoporosis with pathological fracture Multiple sites"
    },
    {
      "name": "M8091",
      "description": "Unspecified osteoporosis with pathological fracture Shoulder region"
    },
    {
      "name": "M8092",
      "description": "Unspecified osteoporosis with pathological fracture Upper arm"
    },
    {
      "name": "M8093",
      "description": "Unspecified osteoporosis with pathological fracture Forearm"
    },
    {
      "name": "M8094",
      "description": "Unspecified osteoporosis with pathological fracture Hand"
    },
    {
      "name": "M8095",
      "description": "Unspecified osteoporosis with pathological fracture Pelvic region and thigh"
    },
    {
      "name": "M8096",
      "description": "Unspecified osteoporosis with pathological fracture Lower leg"
    },
    {
      "name": "M8097",
      "description": "Unspecified osteoporosis with pathological fracture Ankle and foot"
    },
    {
      "name": "M8098",
      "description": "Unspecified osteoporosis with pathological fracture Other"
    },
    {
      "name": "M8099",
      "description": "Unspecified osteoporosis with pathological fracture Site unspecified"
    },
    {
      "name": "M810",
      "description": "Postmenpausal osteoporosis"
    },
    {
      "name": "M8100",
      "description": "Postmenopausal osteoporosis Multiple sites"
    },
    {
      "name": "M8101",
      "description": "Postmenopausal osteoporosis Shoulder region"
    },
    {
      "name": "M8102",
      "description": "Postmenopausal osteoporosis Upper arm"
    },
    {
      "name": "M8103",
      "description": "Postmenopausal osteoporosis Forearm"
    },
    {
      "name": "M8104",
      "description": "Postmenopausal osteoporosis Hand"
    },
    {
      "name": "M8105",
      "description": "Postmenopausal osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8106",
      "description": "Postmenopausal osteoporosis Lower leg"
    },
    {
      "name": "M8107",
      "description": "Postmenopausal osteoporosis Ankle and foot"
    },
    {
      "name": "M8108",
      "description": "Postmenopausal osteoporosis Other"
    },
    {
      "name": "M8109",
      "description": "Postmenopausal osteoporosis Site unspecified"
    },
    {
      "name": "M811",
      "description": "Postoophorectomy osteoporosis"
    },
    {
      "name": "M8110",
      "description": "Postoophorectomy osteoporosis Multiple sites"
    },
    {
      "name": "M8111",
      "description": "Postoophorectomy osteoporosis Shoulder region"
    },
    {
      "name": "M8112",
      "description": "Postoophorectomy osteoporosis Upper arm"
    },
    {
      "name": "M8113",
      "description": "Postoophorectomy osteoporosis Forearm"
    },
    {
      "name": "M8114",
      "description": "Postoophorectomy osteoporosis Hand"
    },
    {
      "name": "M8115",
      "description": "Postoophorectomy osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8116",
      "description": "Postoophorectomy osteoporosis Lower leg"
    },
    {
      "name": "M8117",
      "description": "Postoophorectomy osteoporosis Ankle and foot"
    },
    {
      "name": "M8118",
      "description": "Postoophorectomy osteoporosis Other"
    },
    {
      "name": "M8119",
      "description": "Postoophorectomy osteoporosis Site unspecified"
    },
    {
      "name": "M812",
      "description": "Osteoporosis of disuse"
    },
    {
      "name": "M8120",
      "description": "Osteoporosis of disuse Multiple sites"
    },
    {
      "name": "M8121",
      "description": "Osteoporosis of disuse Shoulder region"
    },
    {
      "name": "M8122",
      "description": "Osteoporosis of disuse Upper arm"
    },
    {
      "name": "M8123",
      "description": "Osteoporosis of disuse Forearm"
    },
    {
      "name": "M8124",
      "description": "Osteoporosis of disuse Hand"
    },
    {
      "name": "M8125",
      "description": "Osteoporosis of disuse Pelvic region and thigh"
    },
    {
      "name": "M8126",
      "description": "Osteoporosis of disuse Lower leg"
    },
    {
      "name": "M8127",
      "description": "Osteoporosis of disuse Ankle and foot"
    },
    {
      "name": "M8128",
      "description": "Osteoporosis of disuse Other"
    },
    {
      "name": "M8129",
      "description": "Osteoporosis of disuse Site unspecified"
    },
    {
      "name": "M813",
      "description": "Postsurgical malabsorption osteoporosis"
    },
    {
      "name": "M8130",
      "description": "Postsurgical malabsorption osteoporosis Multiple sites"
    },
    {
      "name": "M8131",
      "description": "Postsurgical malabsorption osteoporosis Shoulder region"
    },
    {
      "name": "M8132",
      "description": "Postsurgical malabsorption osteoporosis Upper arm"
    },
    {
      "name": "M8133",
      "description": "Postsurgical malabsorption osteoporosis Forearm"
    },
    {
      "name": "M8134",
      "description": "Postsurgical malabsorption osteoporosis Hand"
    },
    {
      "name": "M8135",
      "description": "Postsurgical malabsorption osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8136",
      "description": "Postsurgical malabsorption osteoporosis Lower leg"
    },
    {
      "name": "M8137",
      "description": "Postsurgical malabsorption osteoporosis Ankle and foot"
    },
    {
      "name": "M8138",
      "description": "Postsurgical malabsorption osteoporosis Other"
    },
    {
      "name": "M8139",
      "description": "Postsurgical malabsorption osteoporosis Site unspecified"
    },
    {
      "name": "M814",
      "description": "Drug-induced osteoporosis"
    },
    {
      "name": "M8140",
      "description": "Drug-induced osteoporosis Multiple sites"
    },
    {
      "name": "M8141",
      "description": "Drug-induced osteoporosis Shoulder region"
    },
    {
      "name": "M8142",
      "description": "Drug-induced osteoporosis Upper arm"
    },
    {
      "name": "M8143",
      "description": "Drug-induced osteoporosis Forearm"
    },
    {
      "name": "M8144",
      "description": "Drug-induced osteoporosis Hand"
    },
    {
      "name": "M8145",
      "description": "Drug-induced osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8146",
      "description": "Drug-induced osteoporosis Lower leg"
    },
    {
      "name": "M8147",
      "description": "Drug-induced osteoporosis Ankle and foot"
    },
    {
      "name": "M8148",
      "description": "Drug-induced osteoporosis Other"
    },
    {
      "name": "M8149",
      "description": "Drug-induced osteoporosis Site unspecified"
    },
    {
      "name": "M815",
      "description": "Idiopathic osteoporosis"
    },
    {
      "name": "M8150",
      "description": "Idiopathic osteoporosis Multiple sites"
    },
    {
      "name": "M8151",
      "description": "Idiopathic osteoporosis Shoulder region"
    },
    {
      "name": "M8152",
      "description": "Idiopathic osteoporosis Upper arm"
    },
    {
      "name": "M8153",
      "description": "Idiopathic osteoporosis Forearm"
    },
    {
      "name": "M8154",
      "description": "Idiopathic osteoporosis Hand"
    },
    {
      "name": "M8155",
      "description": "Idiopathic osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8156",
      "description": "Idiopathic osteoporosis Lower leg"
    },
    {
      "name": "M8157",
      "description": "Idiopathic osteoporosis Ankle and foot"
    },
    {
      "name": "M8158",
      "description": "Idiopathic osteoporosis Other"
    },
    {
      "name": "M8159",
      "description": "Idiopathic osteoporosis Site unspecified"
    },
    {
      "name": "M816",
      "description": "Localized osteoporosis [Lequesne]"
    },
    {
      "name": "M8160",
      "description": "Localized osteoporosis [ Lequesne ] Multiple sites"
    },
    {
      "name": "M8161",
      "description": "Localized osteoporosis [ Lequesne ] Shoulder region"
    },
    {
      "name": "M8162",
      "description": "Localized osteoporosis [ Lequesne ] Upper arm"
    },
    {
      "name": "M8163",
      "description": "Localized osteoporosis [ Lequesne ] Forearm"
    },
    {
      "name": "M8164",
      "description": "Localized osteoporosis [ Lequesne ] Hand"
    },
    {
      "name": "M8165",
      "description": "Localized osteoporosis [ Lequesne ] Pelvic region and thigh"
    },
    {
      "name": "M8166",
      "description": "Localized osteoporosis [ Lequesne ] Lower leg"
    },
    {
      "name": "M8167",
      "description": "Localized osteoporosis [ Lequesne ] Ankle and foot"
    },
    {
      "name": "M8168",
      "description": "Localized osteoporosis [ Lequesne ] Other"
    },
    {
      "name": "M8169",
      "description": "Localized osteoporosis [ Lequesne ] Site unspecified"
    },
    {
      "name": "M818",
      "description": "Other osteoporosis"
    },
    {
      "name": "M8180",
      "description": "Other osteoporosis Multiple sites"
    },
    {
      "name": "M8181",
      "description": "Other osteoporosis Shoulder region"
    },
    {
      "name": "M8182",
      "description": "Other osteoporosis Upper arm"
    },
    {
      "name": "M8183",
      "description": "Other osteoporosis Forearm"
    },
    {
      "name": "M8184",
      "description": "Other osteoporosis Hand"
    },
    {
      "name": "M8185",
      "description": "Other osteoporosis Pelvic region and thigh"
    },
    {
      "name": "M8186",
      "description": "Other osteoporosis Lower leg"
    },
    {
      "name": "M8187",
      "description": "Other osteoporosis Ankle and foot"
    },
    {
      "name": "M8188",
      "description": "Other osteoporosis Other"
    },
    {
      "name": "M8189",
      "description": "Other osteoporosis Site unspecified"
    },
    {
      "name": "M819",
      "description": "Osteoporosis, unspecified"
    },
    {
      "name": "M8190",
      "description": "Osteoporosis, unspecified Multiple sites"
    },
    {
      "name": "M8191",
      "description": "Osteoporosis, unspecified Shoulder region"
    },
    {
      "name": "M8192",
      "description": "Osteoporosis, unspecified Upper arm"
    },
    {
      "name": "M8193",
      "description": "Osteoporosis, unspecified Forearm"
    },
    {
      "name": "M8194",
      "description": "Osteoporosis, unspecified Hand"
    },
    {
      "name": "M8195",
      "description": "Osteoporosis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8196",
      "description": "Osteoporosis, unspecified Lower leg"
    },
    {
      "name": "M8197",
      "description": "Osteoporosis, unspecified Ankle and foot"
    },
    {
      "name": "M8198",
      "description": "Osteoporosis, unspecified Other"
    },
    {
      "name": "M8199",
      "description": "Osteoporosis, unspecified Site unspecified"
    },
    {
      "name": "M820",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 )"
    },
    {
      "name": "M8200",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Multiple sites"
    },
    {
      "name": "M8201",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Shoulder region"
    },
    {
      "name": "M8202",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Upper arm"
    },
    {
      "name": "M8203",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Forearm"
    },
    {
      "name": "M8204",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Hand"
    },
    {
      "name": "M8205",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Pelvic region and thigh"
    },
    {
      "name": "M8206",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Lower leg"
    },
    {
      "name": "M8207",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Ankle and foot"
    },
    {
      "name": "M8208",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Other"
    },
    {
      "name": "M8209",
      "description": "Osteoporosis in multiple myelomatosis ( C90.0 ) Site unspecified"
    },
    {
      "name": "M821",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 )"
    },
    {
      "name": "M8210",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Multiple sites"
    },
    {
      "name": "M8211",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Shoulder region"
    },
    {
      "name": "M8212",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Upper arm"
    },
    {
      "name": "M8213",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Forearm"
    },
    {
      "name": "M8214",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Hand"
    },
    {
      "name": "M8215",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Pelvic region and thigh"
    },
    {
      "name": "M8216",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Lower leg"
    },
    {
      "name": "M8217",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Ankle and foot"
    },
    {
      "name": "M8218",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Other"
    },
    {
      "name": "M8219",
      "description": "Osteoporosis in endocrine disorders ( E00-E34 ) Site unspecified"
    },
    {
      "name": "M828",
      "description": "Osteoporosis in other diseases classified elsewhere"
    },
    {
      "name": "M8280",
      "description": "Osteoporosis in other diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M8281",
      "description": "Osteoporosis in other diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M8282",
      "description": "Osteoporosis in other diseases classified elsewhere Upper arm"
    },
    {
      "name": "M8283",
      "description": "Osteoporosis in other diseases classified elsewhere Forearm"
    },
    {
      "name": "M8284",
      "description": "Osteoporosis in other diseases classified elsewhere Hand"
    },
    {
      "name": "M8285",
      "description": "Osteoporosis in other diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M8286",
      "description": "Osteoporosis in other diseases classified elsewhere Lower leg"
    },
    {
      "name": "M8287",
      "description": "Osteoporosis in other diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M8288",
      "description": "Osteoporosis in other diseases classified elsewhere Other"
    },
    {
      "name": "M8289",
      "description": "Osteoporosis in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M830",
      "description": "Puerperal osteomalacia"
    },
    {
      "name": "M8300",
      "description": "Puerperal osteomalacia Multiple sites"
    },
    {
      "name": "M8301",
      "description": "Puerperal osteomalacia Shoulder region"
    },
    {
      "name": "M8302",
      "description": "Puerperal osteomalacia Upper arm"
    },
    {
      "name": "M8303",
      "description": "Puerperal osteomalacia Forearm"
    },
    {
      "name": "M8304",
      "description": "Puerperal osteomalacia Hand"
    },
    {
      "name": "M8305",
      "description": "Puerperal osteomalacia Pelvic region and thigh"
    },
    {
      "name": "M8306",
      "description": "Puerperal osteomalacia Lower leg"
    },
    {
      "name": "M8307",
      "description": "Puerperal osteomalacia Ankle and foot"
    },
    {
      "name": "M8308",
      "description": "Puerperal osteomalacia Other"
    },
    {
      "name": "M8309",
      "description": "Puerperal osteomalacia Site unspecified"
    },
    {
      "name": "M831",
      "description": "Senile osteomalacia"
    },
    {
      "name": "M8310",
      "description": "Senile osteomalacia Multiple sites"
    },
    {
      "name": "M8311",
      "description": "Senile osteomalacia Shoulder region"
    },
    {
      "name": "M8312",
      "description": "Senile osteomalacia Upper arm"
    },
    {
      "name": "M8313",
      "description": "Senile osteomalacia Forearm"
    },
    {
      "name": "M8314",
      "description": "Senile osteomalacia Hand"
    },
    {
      "name": "M8315",
      "description": "Senile osteomalacia Pelvic region and thigh"
    },
    {
      "name": "M8316",
      "description": "Senile osteomalacia Lower leg"
    },
    {
      "name": "M8317",
      "description": "Senile osteomalacia Ankle and foot"
    },
    {
      "name": "M8318",
      "description": "Senile osteomalacia Other"
    },
    {
      "name": "M8319",
      "description": "Senile osteomalacia Site unspecified"
    },
    {
      "name": "M832",
      "description": "Adult osteomalacia due to malabsorption"
    },
    {
      "name": "M8320",
      "description": "Adult osteomalacia due to malabsorption Multiple sites"
    },
    {
      "name": "M8321",
      "description": "Adult osteomalacia due to malabsorption Shoulder region"
    },
    {
      "name": "M8322",
      "description": "Adult osteomalacia due to malabsorption Upper arm"
    },
    {
      "name": "M8323",
      "description": "Adult osteomalacia due to malabsorption Forearm"
    },
    {
      "name": "M8324",
      "description": "Adult osteomalacia due to malabsorption Hand"
    },
    {
      "name": "M8325",
      "description": "Adult osteomalacia due to malabsorption Pelvic region and thigh"
    },
    {
      "name": "M8326",
      "description": "Adult osteomalacia due to malabsorption Lower leg"
    },
    {
      "name": "M8327",
      "description": "Adult osteomalacia due to malabsorption Ankle and foot"
    },
    {
      "name": "M8328",
      "description": "Adult osteomalacia due to malabsorption Other"
    },
    {
      "name": "M8329",
      "description": "Adult osteomalacia due to malabsorption Site unspecified"
    },
    {
      "name": "M833",
      "description": "Adult osteomalacia due to malnutrition"
    },
    {
      "name": "M8330",
      "description": "Adult osteomalacia due to malnutrition Multiple sites"
    },
    {
      "name": "M8331",
      "description": "Adult osteomalacia due to malnutrition Shoulder region"
    },
    {
      "name": "M8332",
      "description": "Adult osteomalacia due to malnutrition Upper arm"
    },
    {
      "name": "M8333",
      "description": "Adult osteomalacia due to malnutrition Forearm"
    },
    {
      "name": "M8334",
      "description": "Adult osteomalacia due to malnutrition Hand"
    },
    {
      "name": "M8335",
      "description": "Adult osteomalacia due to malnutrition Pelvic region and thigh"
    },
    {
      "name": "M8336",
      "description": "Adult osteomalacia due to malnutrition Lower leg"
    },
    {
      "name": "M8337",
      "description": "Adult osteomalacia due to malnutrition Ankle and foot"
    },
    {
      "name": "M8338",
      "description": "Adult osteomalacia due to malnutrition Other"
    },
    {
      "name": "M8339",
      "description": "Adult osteomalacia due to malnutrition Site unspecified"
    },
    {
      "name": "M834",
      "description": "Aluminium bone disease"
    },
    {
      "name": "M8340",
      "description": "Aluminium bone disease Multiple sites"
    },
    {
      "name": "M8341",
      "description": "Aluminium bone disease Shoulder region"
    },
    {
      "name": "M8342",
      "description": "Aluminium bone disease Upper arm"
    },
    {
      "name": "M8343",
      "description": "Aluminium bone disease Forearm"
    },
    {
      "name": "M8344",
      "description": "Aluminium bone disease Hand"
    },
    {
      "name": "M8345",
      "description": "Aluminium bone disease Pelvic region and thigh"
    },
    {
      "name": "M8346",
      "description": "Aluminium bone disease Lower leg"
    },
    {
      "name": "M8347",
      "description": "Aluminium bone disease Ankle and foot"
    },
    {
      "name": "M8348",
      "description": "Aluminium bone disease Other"
    },
    {
      "name": "M8349",
      "description": "Aluminium bone disease Site unspecified"
    },
    {
      "name": "M835",
      "description": "Other drug-induced osteomalacia in adults"
    },
    {
      "name": "M8350",
      "description": "Other drug-induced osteomalacia in adults Multiple sites"
    },
    {
      "name": "M8351",
      "description": "Other drug-induced osteomalacia in adults Shoulder region"
    },
    {
      "name": "M8352",
      "description": "Other drug-induced osteomalacia in adults Upper arm"
    },
    {
      "name": "M8353",
      "description": "Other drug-induced osteomalacia in adults Forearm"
    },
    {
      "name": "M8354",
      "description": "Other drug-induced osteomalacia in adults Hand"
    },
    {
      "name": "M8355",
      "description": "Other drug-induced osteomalacia in adults Pelvic region and thigh"
    },
    {
      "name": "M8356",
      "description": "Other drug-induced osteomalacia in adults Lower leg"
    },
    {
      "name": "M8357",
      "description": "Other drug-induced osteomalacia in adults Ankle and foot"
    },
    {
      "name": "M8358",
      "description": "Other drug-induced osteomalacia in adults Other"
    },
    {
      "name": "M8359",
      "description": "Other drug-induced osteomalacia in adults Site unspecified"
    },
    {
      "name": "M838",
      "description": "Other adult osteomalacia"
    },
    {
      "name": "M8380",
      "description": "Other adult osteomalacia Multiple sites"
    },
    {
      "name": "M8381",
      "description": "Other adult osteomalacia Shoulder region"
    },
    {
      "name": "M8382",
      "description": "Other adult osteomalacia Upper arm"
    },
    {
      "name": "M8383",
      "description": "Other adult osteomalacia Forearm"
    },
    {
      "name": "M8384",
      "description": "Other adult osteomalacia Hand"
    },
    {
      "name": "M8385",
      "description": "Other adult osteomalacia Pelvic region and thigh"
    },
    {
      "name": "M8386",
      "description": "Other adult osteomalacia Lower leg"
    },
    {
      "name": "M8387",
      "description": "Other adult osteomalacia Ankle and foot"
    },
    {
      "name": "M8388",
      "description": "Other adult osteomalacia Other"
    },
    {
      "name": "M8389",
      "description": "Other adult osteomalacia Site unspecified"
    },
    {
      "name": "M839",
      "description": "Adult osteomalacia, unspecified"
    },
    {
      "name": "M8390",
      "description": "Adult osteomalacia, unspecified Multiple sites"
    },
    {
      "name": "M8391",
      "description": "Adult osteomalacia, unspecified Shoulder region"
    },
    {
      "name": "M8392",
      "description": "Adult osteomalacia, unspecified Upper arm"
    },
    {
      "name": "M8393",
      "description": "Adult osteomalacia, unspecified Forearm"
    },
    {
      "name": "M8394",
      "description": "Adult osteomalacia, unspecified Hand"
    },
    {
      "name": "M8395",
      "description": "Adult osteomalacia, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8396",
      "description": "Adult osteomalacia, unspecified Lower leg"
    },
    {
      "name": "M8397",
      "description": "Adult osteomalacia, unspecified Ankle and foot"
    },
    {
      "name": "M8398",
      "description": "Adult osteomalacia, unspecified Other"
    },
    {
      "name": "M8399",
      "description": "Adult osteomalacia, unspecified Site unspecified"
    },
    {
      "name": "M840",
      "description": "Malunion of fracture"
    },
    {
      "name": "M8400",
      "description": "Malunion of fracture Multiple sites"
    },
    {
      "name": "M8401",
      "description": "Malunion of fracture Shoulder region"
    },
    {
      "name": "M8402",
      "description": "Malunion of fracture Upper arm"
    },
    {
      "name": "M8403",
      "description": "Malunion of fracture Forearm"
    },
    {
      "name": "M8404",
      "description": "Malunion of fracture Hand"
    },
    {
      "name": "M8405",
      "description": "Malunion of fracture Pelvic region and thigh"
    },
    {
      "name": "M8406",
      "description": "Malunion of fracture Lower leg"
    },
    {
      "name": "M8407",
      "description": "Malunion of fracture Ankle and foot"
    },
    {
      "name": "M8408",
      "description": "Malunion of fracture Other"
    },
    {
      "name": "M8409",
      "description": "Malunion of fracture Site unspecified"
    },
    {
      "name": "M841",
      "description": "Nonunion of fracture [pseudarthrosis]"
    },
    {
      "name": "M8410",
      "description": "Nonunion of fracture [ pseudarthrosis ] Multiple sites"
    },
    {
      "name": "M8411",
      "description": "Nonunion of fracture [ pseudarthrosis ] Shoulder region"
    },
    {
      "name": "M8412",
      "description": "Nonunion of fracture [ pseudarthrosis ] Upper arm"
    },
    {
      "name": "M8413",
      "description": "Nonunion of fracture [ pseudarthrosis ] Forearm"
    },
    {
      "name": "M8414",
      "description": "Nonunion of fracture [ pseudarthrosis ] Hand"
    },
    {
      "name": "M8415",
      "description": "Nonunion of fracture [ pseudarthrosis ] Pelvic region and thigh"
    },
    {
      "name": "M8416",
      "description": "Nonunion of fracture [ pseudarthrosis ] Lower leg"
    },
    {
      "name": "M8417",
      "description": "Nonunion of fracture [ pseudarthrosis ] Ankle and foot"
    },
    {
      "name": "M8418",
      "description": "Nonunion of fracture [ pseudarthrosis ] Other"
    },
    {
      "name": "M8419",
      "description": "Nonunion of fracture [ pseudarthrosis ] Site unspecified"
    },
    {
      "name": "M842",
      "description": "Delayed union of fracture"
    },
    {
      "name": "M8420",
      "description": "Delayed union of fracture Multiple sites"
    },
    {
      "name": "M8421",
      "description": "Delayed union of fracture Shoulder region"
    },
    {
      "name": "M8422",
      "description": "Delayed union of fracture Upper arm"
    },
    {
      "name": "M8423",
      "description": "Delayed union of fracture Forearm"
    },
    {
      "name": "M8424",
      "description": "Delayed union of fracture Hand"
    },
    {
      "name": "M8425",
      "description": "Delayed union of fracture Pelvic region and thigh"
    },
    {
      "name": "M8426",
      "description": "Delayed union of fracture Lower leg"
    },
    {
      "name": "M8427",
      "description": "Delayed union of fracture Ankle and foot"
    },
    {
      "name": "M8428",
      "description": "Delayed union of fracture Other"
    },
    {
      "name": "M8429",
      "description": "Delayed union of fracture Site unspecified"
    },
    {
      "name": "M843",
      "description": "Stress fracture, not elsewhere classified"
    },
    {
      "name": "M8430",
      "description": "Stress fracture, not elsewhere classified Multiple sites"
    },
    {
      "name": "M8431",
      "description": "Stress fracture, not elsewhere classified Shoulder region"
    },
    {
      "name": "M8432",
      "description": "Stress fracture, not elsewhere classified Upper arm"
    },
    {
      "name": "M8433",
      "description": "Stress fracture, not elsewhere classified Forearm"
    },
    {
      "name": "M8434",
      "description": "Stress fracture, not elsewhere classified Hand"
    },
    {
      "name": "M8435",
      "description": "Stress fracture, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M8436",
      "description": "Stress fracture, not elsewhere classified Lower leg"
    },
    {
      "name": "M8437",
      "description": "Stress fracture, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M8438",
      "description": "Stress fracture, not elsewhere classified Other"
    },
    {
      "name": "M8439",
      "description": "Stress fracture, not elsewhere classified Site unspecified"
    },
    {
      "name": "M844",
      "description": "Pathological fracture, not elsewhere classified"
    },
    {
      "name": "M8440",
      "description": "Pathological fracture, not elsewhere classified Multiple sites"
    },
    {
      "name": "M8441",
      "description": "Pathological fracture, not elsewhere classified Shoulder region"
    },
    {
      "name": "M8442",
      "description": "Pathological fracture, not elsewhere classified Upper arm"
    },
    {
      "name": "M8443",
      "description": "Pathological fracture, not elsewhere classified Forearm"
    },
    {
      "name": "M8444",
      "description": "Pathological fracture, not elsewhere classified Hand"
    },
    {
      "name": "M8445",
      "description": "Pathological fracture, not elsewhere classified Pelvic region and thigh"
    },
    {
      "name": "M8446",
      "description": "Pathological fracture, not elsewhere classified Lower leg"
    },
    {
      "name": "M8447",
      "description": "Pathological fracture, not elsewhere classified Ankle and foot"
    },
    {
      "name": "M8448",
      "description": "Pathological fracture, not elsewhere classified Other"
    },
    {
      "name": "M8449",
      "description": "Pathological fracture, not elsewhere classified Site unspecified"
    },
    {
      "name": "M848",
      "description": "Other disorders of continuity of bone"
    },
    {
      "name": "M8480",
      "description": "Other disorders of continuity of bone Multiple sites"
    },
    {
      "name": "M8481",
      "description": "Other disorders of continuity of bone Shoulder region"
    },
    {
      "name": "M8482",
      "description": "Other disorders of continuity of bone Upper arm"
    },
    {
      "name": "M8483",
      "description": "Other disorders of continuity of bone Forearm"
    },
    {
      "name": "M8484",
      "description": "Other disorders of continuity of bone Hand"
    },
    {
      "name": "M8485",
      "description": "Other disorders of continuity of bone Pelvic region and thigh"
    },
    {
      "name": "M8486",
      "description": "Other disorders of continuity of bone Lower leg"
    },
    {
      "name": "M8487",
      "description": "Other disorders of continuity of bone Ankle and foot"
    },
    {
      "name": "M8488",
      "description": "Other disorders of continuity of bone Other"
    },
    {
      "name": "M8489",
      "description": "Other disorders of continuity of bone Site unspecified"
    },
    {
      "name": "M849",
      "description": "Disorder of continuity of bone, unspecified"
    },
    {
      "name": "M8490",
      "description": "Disorder of continuity of bone, unspecified Multiple sites"
    },
    {
      "name": "M8491",
      "description": "Disorder of continuity of bone, unspecified Shoulder region"
    },
    {
      "name": "M8492",
      "description": "Disorder of continuity of bone, unspecified Upper arm"
    },
    {
      "name": "M8493",
      "description": "Disorder of continuity of bone, unspecified Forearm"
    },
    {
      "name": "M8494",
      "description": "Disorder of continuity of bone, unspecified Hand"
    },
    {
      "name": "M8495",
      "description": "Disorder of continuity of bone, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8496",
      "description": "Disorder of continuity of bone, unspecified Lower leg"
    },
    {
      "name": "M8497",
      "description": "Disorder of continuity of bone, unspecified Ankle and foot"
    },
    {
      "name": "M8498",
      "description": "Disorder of continuity of bone, unspecified Other"
    },
    {
      "name": "M8499",
      "description": "Disorder of continuity of bone, unspecified Site unspecified"
    },
    {
      "name": "M850",
      "description": "Fibrous dysplasia (monostotic)"
    },
    {
      "name": "M8500",
      "description": "Fibrous dysplasia ( monostotic ) Multiple sites"
    },
    {
      "name": "M8501",
      "description": "Fibrous dysplasia ( monostotic ) Shoulder region"
    },
    {
      "name": "M8502",
      "description": "Fibrous dysplasia ( monostotic ) Upper arm"
    },
    {
      "name": "M8503",
      "description": "Fibrous dysplasia ( monostotic ) Forearm"
    },
    {
      "name": "M8504",
      "description": "Fibrous dysplasia ( monostotic ) Hand"
    },
    {
      "name": "M8505",
      "description": "Fibrous dysplasia ( monostotic ) Pelvic region and thigh"
    },
    {
      "name": "M8506",
      "description": "Fibrous dysplasia ( monostotic ) Lower leg"
    },
    {
      "name": "M8507",
      "description": "Fibrous dysplasia ( monostotic ) Ankle and foot"
    },
    {
      "name": "M8508",
      "description": "Fibrous dysplasia ( monostotic ) Other"
    },
    {
      "name": "M8509",
      "description": "Fibrous dysplasia ( monostotic ) Site unspecified"
    },
    {
      "name": "M851",
      "description": "Skeletal fluorosis"
    },
    {
      "name": "M8510",
      "description": "Skeletal fluorosis Multiple sites"
    },
    {
      "name": "M8511",
      "description": "Skeletal fluorosis Shoulder region"
    },
    {
      "name": "M8512",
      "description": "Skeletal fluorosis Upper arm"
    },
    {
      "name": "M8513",
      "description": "Skeletal fluorosis Forearm"
    },
    {
      "name": "M8514",
      "description": "Skeletal fluorosis Hand"
    },
    {
      "name": "M8515",
      "description": "Skeletal fluorosis Pelvic region and thigh"
    },
    {
      "name": "M8516",
      "description": "Skeletal fluorosis Lower leg"
    },
    {
      "name": "M8517",
      "description": "Skeletal fluorosis Ankle and foot"
    },
    {
      "name": "M8518",
      "description": "Skeletal fluorosis Other"
    },
    {
      "name": "M8519",
      "description": "Skeletal fluorosis Site unspecified"
    },
    {
      "name": "M852",
      "description": "Hyperostosis of skull"
    },
    {
      "name": "M8520",
      "description": "Hyperostosis of skull Multiple sites"
    },
    {
      "name": "M8521",
      "description": "Hyperostosis of skull Shoulder region"
    },
    {
      "name": "M8522",
      "description": "Hyperostosis of skull Upper arm"
    },
    {
      "name": "M8523",
      "description": "Hyperostosis of skull Forearm"
    },
    {
      "name": "M8524",
      "description": "Hyperostosis of skull Hand"
    },
    {
      "name": "M8525",
      "description": "Hyperostosis of skull Pelvic region and thigh"
    },
    {
      "name": "M8526",
      "description": "Hyperostosis of skull Lower leg"
    },
    {
      "name": "M8527",
      "description": "Hyperostosis of skull Ankle and foot"
    },
    {
      "name": "M8528",
      "description": "Hyperostosis of skull Other"
    },
    {
      "name": "M8529",
      "description": "Hyperostosis of skull Site unspecified"
    },
    {
      "name": "M853",
      "description": "Osteitis condensans"
    },
    {
      "name": "M8530",
      "description": "Osteitis condensans Multiple sites"
    },
    {
      "name": "M8531",
      "description": "Osteitis condensans Shoulder region"
    },
    {
      "name": "M8532",
      "description": "Osteitis condensans Upper arm"
    },
    {
      "name": "M8533",
      "description": "Osteitis condensans Forearm"
    },
    {
      "name": "M8534",
      "description": "Osteitis condensans Hand"
    },
    {
      "name": "M8535",
      "description": "Osteitis condensans Pelvic region and thigh"
    },
    {
      "name": "M8536",
      "description": "Osteitis condensans Lower leg"
    },
    {
      "name": "M8537",
      "description": "Osteitis condensans Ankle and foot"
    },
    {
      "name": "M8538",
      "description": "Osteitis condensans Other"
    },
    {
      "name": "M8539",
      "description": "Osteitis condensans Site unspecified"
    },
    {
      "name": "M854",
      "description": "Solitary bone cyst"
    },
    {
      "name": "M8540",
      "description": "Solitary bone cyst Multiple sites"
    },
    {
      "name": "M8541",
      "description": "Solitary bone cyst Shoulder region"
    },
    {
      "name": "M8542",
      "description": "Solitary bone cyst Upper arm"
    },
    {
      "name": "M8543",
      "description": "Solitary bone cyst Forearm"
    },
    {
      "name": "M8544",
      "description": "Solitary bone cyst Hand"
    },
    {
      "name": "M8545",
      "description": "Solitary bone cyst Pelvic region and thigh"
    },
    {
      "name": "M8546",
      "description": "Solitary bone cyst Lower leg"
    },
    {
      "name": "M8547",
      "description": "Solitary bone cyst Ankle and foot"
    },
    {
      "name": "M8548",
      "description": "Solitary bone cyst Other"
    },
    {
      "name": "M8549",
      "description": "Solitary bone cyst Site unspecified"
    },
    {
      "name": "M855",
      "description": "Aneurysmal bone cyst"
    },
    {
      "name": "M8550",
      "description": "Aneurysmal bone cyst Multiple sites"
    },
    {
      "name": "M8551",
      "description": "Aneurysmal bone cyst Shoulder region"
    },
    {
      "name": "M8552",
      "description": "Aneurysmal bone cyst Upper arm"
    },
    {
      "name": "M8553",
      "description": "Aneurysmal bone cyst Forearm"
    },
    {
      "name": "M8554",
      "description": "Aneurysmal bone cyst Hand"
    },
    {
      "name": "M8555",
      "description": "Aneurysmal bone cyst Pelvic region and thigh"
    },
    {
      "name": "M8556",
      "description": "Aneurysmal bone cyst Lower leg"
    },
    {
      "name": "M8557",
      "description": "Aneurysmal bone cyst Ankle and foot"
    },
    {
      "name": "M8558",
      "description": "Aneurysmal bone cyst Other"
    },
    {
      "name": "M8559",
      "description": "Aneurysmal bone cyst Site unspecified"
    },
    {
      "name": "M856",
      "description": "Other cyst of bone"
    },
    {
      "name": "M8560",
      "description": "Other cyst of bone Multiple sites"
    },
    {
      "name": "M8561",
      "description": "Other cyst of bone Shoulder region"
    },
    {
      "name": "M8562",
      "description": "Other cyst of bone Upper arm"
    },
    {
      "name": "M8563",
      "description": "Other cyst of bone Forearm"
    },
    {
      "name": "M8564",
      "description": "Other cyst of bone Hand"
    },
    {
      "name": "M8565",
      "description": "Other cyst of bone Pelvic region and thigh"
    },
    {
      "name": "M8566",
      "description": "Other cyst of bone Lower leg"
    },
    {
      "name": "M8567",
      "description": "Other cyst of bone Ankle and foot"
    },
    {
      "name": "M8568",
      "description": "Other cyst of bone Other"
    },
    {
      "name": "M8569",
      "description": "Other cyst of bone Site unspecified"
    },
    {
      "name": "M858",
      "description": "Other specified disorders of bone density and structure"
    },
    {
      "name": "M8580",
      "description": "Other specified disorders of bone density and structure Multiple sites"
    },
    {
      "name": "M8581",
      "description": "Other specified disorders of bone density and structure Shoulder region"
    },
    {
      "name": "M8582",
      "description": "Other specified disorders of bone density and structure Upper arm"
    },
    {
      "name": "M8583",
      "description": "Other specified disorders of bone density and structure Forearm"
    },
    {
      "name": "M8584",
      "description": "Other specified disorders of bone density and structure Hand"
    },
    {
      "name": "M8585",
      "description": "Other specified disorders of bone density and structure Pelvic region and thigh"
    },
    {
      "name": "M8586",
      "description": "Other specified disorders of bone density and structure Lower leg"
    },
    {
      "name": "M8587",
      "description": "Other specified disorders of bone density and structure Ankle and foot"
    },
    {
      "name": "M8588",
      "description": "Other specified disorders of bone density and structure Other"
    },
    {
      "name": "M8589",
      "description": "Other specified disorders of bone density and structure Site unspecified"
    },
    {
      "name": "M859",
      "description": "Disorder of bone density and structure, unspecified"
    },
    {
      "name": "M8590",
      "description": "Disorder of bone density and structure, unspecified Multiple sites"
    },
    {
      "name": "M8591",
      "description": "Disorder of bone density and structure, unspecified Shoulder region"
    },
    {
      "name": "M8592",
      "description": "Disorder of bone density and structure, unspecified Upper arm"
    },
    {
      "name": "M8593",
      "description": "Disorder of bone density and structure, unspecified Forearm"
    },
    {
      "name": "M8594",
      "description": "Disorder of bone density and structure, unspecified Hand"
    },
    {
      "name": "M8595",
      "description": "Disorder of bone density and structure, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8596",
      "description": "Disorder of bone density and structure, unspecified Lower leg"
    },
    {
      "name": "M8597",
      "description": "Disorder of bone density and structure, unspecified Ankle and foot"
    },
    {
      "name": "M8598",
      "description": "Disorder of bone density and structure, unspecified Other"
    },
    {
      "name": "M8599",
      "description": "Disorder of bone density and structure, unspecified Site unsepcified"
    },
    {
      "name": "M860",
      "description": "Acute haematogenous osteomyelitis"
    },
    {
      "name": "M8600",
      "description": "Acute haematogenous osteomyelitis Multiple sites"
    },
    {
      "name": "M8601",
      "description": "Acute haematogenous osteomyelitis Shoulder region"
    },
    {
      "name": "M8602",
      "description": "Acute haematogenous osteomyelitis Upper arm"
    },
    {
      "name": "M8603",
      "description": "Acute haematogenous osteomyelitis Forearm"
    },
    {
      "name": "M8604",
      "description": "Acute haematogenous osteomyelitis Hand"
    },
    {
      "name": "M8605",
      "description": "Acute haematogenous osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8606",
      "description": "Acute haematogenous osteomyelitis Lower leg"
    },
    {
      "name": "M8607",
      "description": "Acute haematogenous osteomyelitis Ankle and foot"
    },
    {
      "name": "M8608",
      "description": "Acute haematogenous osteomyelitis Other"
    },
    {
      "name": "M8609",
      "description": "Acute haematogenous osteomyelitis Site unspecified"
    },
    {
      "name": "M861",
      "description": "Other acute osteomyelitis"
    },
    {
      "name": "M8610",
      "description": "Other acute osteomyelitis Multiple sites"
    },
    {
      "name": "M8611",
      "description": "Other acute osteomyelitis Shoulder region"
    },
    {
      "name": "M8612",
      "description": "Other acute osteomyelitis Upper arm"
    },
    {
      "name": "M8613",
      "description": "Other acute osteomyelitis Forearm"
    },
    {
      "name": "M8614",
      "description": "Other acute osteomyelitis Hand"
    },
    {
      "name": "M8615",
      "description": "Other acute osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8616",
      "description": "Other acute osteomyelitis Lower leg"
    },
    {
      "name": "M8617",
      "description": "Other acute osteomyelitis Ankle and foot"
    },
    {
      "name": "M8618",
      "description": "Other acute osteomyelitis Other"
    },
    {
      "name": "M8619",
      "description": "Other acute osteomyelitis Site unspecified"
    },
    {
      "name": "M862",
      "description": "Subacute osteomyelitis"
    },
    {
      "name": "M8620",
      "description": "Subacute osteomyelitis Multiple sites"
    },
    {
      "name": "M8621",
      "description": "Subacute osteomyelitis Shoulder region"
    },
    {
      "name": "M8622",
      "description": "Subacute osteomyelitis Upper arm"
    },
    {
      "name": "M8623",
      "description": "Subacute osteomyelitis Forearm"
    },
    {
      "name": "M8624",
      "description": "Subacute osteomyelitis Hand"
    },
    {
      "name": "M8625",
      "description": "Subacute osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8626",
      "description": "Subacute osteomyelitis Lower leg"
    },
    {
      "name": "M8627",
      "description": "Subacute osteomyelitis Ankle and foot"
    },
    {
      "name": "M8628",
      "description": "Subacute osteomyelitis Other"
    },
    {
      "name": "M8629",
      "description": "Subacute osteomyelitis Site unspecified"
    },
    {
      "name": "M863",
      "description": "Chronic multifocal osteomyelitis"
    },
    {
      "name": "M8630",
      "description": "Chronic multifocal osteomyelitis Multiple sites"
    },
    {
      "name": "M8631",
      "description": "Chronic multifocal osteomyelitis Shoulder region"
    },
    {
      "name": "M8632",
      "description": "Chronic multifocal osteomyelitis Upper arm"
    },
    {
      "name": "M8633",
      "description": "Chronic multifocal osteomyelitis Forearm"
    },
    {
      "name": "M8634",
      "description": "Chronic multifocal osteomyelitis Hand"
    },
    {
      "name": "M8635",
      "description": "Chronic multifocal osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8636",
      "description": "Chronic multifocal osteomyelitis Lower leg"
    },
    {
      "name": "M8637",
      "description": "Chronic multifocal osteomyelitis Ankle and foot"
    },
    {
      "name": "M8638",
      "description": "Chronic multifocal osteomyelitis Other"
    },
    {
      "name": "M8639",
      "description": "Chronic multifocal osteomyelitis Site unspecified"
    },
    {
      "name": "M864",
      "description": "Chronic osteomyelitis with draining sinus"
    },
    {
      "name": "M8640",
      "description": "Chronic osteomyelitis with draining sinus Multiple sites"
    },
    {
      "name": "M8641",
      "description": "Chronic osteomyelitis with draining sinus Shoulder region"
    },
    {
      "name": "M8642",
      "description": "Chronic osteomyelitis with draining sinus Upper arm"
    },
    {
      "name": "M8643",
      "description": "Chronic osteomyelitis with draining sinus Forearm"
    },
    {
      "name": "M8644",
      "description": "Chronic osteomyelitis with draining sinus Hand"
    },
    {
      "name": "M8645",
      "description": "Chronic osteomyelitis with draining sinus Pelvic region and thigh"
    },
    {
      "name": "M8646",
      "description": "Chronic osteomyelitis with draining sinus Lower leg"
    },
    {
      "name": "M8647",
      "description": "Chronic osteomyelitis with draining sinus Ankle and foot"
    },
    {
      "name": "M8648",
      "description": "Chronic osteomyelitis with draining sinus Other"
    },
    {
      "name": "M8649",
      "description": "Chronic osteomyelitis with draining sinus Site unspecified"
    },
    {
      "name": "M865",
      "description": "Other chronic haematogenous osteomyelitis"
    },
    {
      "name": "M8650",
      "description": "Other chronic haematogenous osteomyelitis Multiple sites"
    },
    {
      "name": "M8651",
      "description": "Other chronic haematogenous osteomyelitis Shoulder region"
    },
    {
      "name": "M8652",
      "description": "Other chronic haematogenous osteomyelitis Upper arm"
    },
    {
      "name": "M8653",
      "description": "Other chronic haematogenous osteomyelitis Forearm"
    },
    {
      "name": "M8654",
      "description": "Other chronic haematogenous osteomyelitis Hand"
    },
    {
      "name": "M8655",
      "description": "Other chronic haematogenous osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8656",
      "description": "Other chronic haematogenous osteomyelitis Lower leg"
    },
    {
      "name": "M8657",
      "description": "Other chronic haematogenous osteomyelitis Ankle and foot"
    },
    {
      "name": "M8658",
      "description": "Other chronic haematogenous osteomyelitis Other"
    },
    {
      "name": "M8659",
      "description": "Other chronic haematogenous osteomyelitis Site unspecified"
    },
    {
      "name": "M866",
      "description": "Other chronic osteomyelitis"
    },
    {
      "name": "M8660",
      "description": "Other chronic osteomyelitis Multiple sites"
    },
    {
      "name": "M8661",
      "description": "Other chronic osteomyelitis Shoulder region"
    },
    {
      "name": "M8662",
      "description": "Other chronic osteomyelitis Upper arm"
    },
    {
      "name": "M8663",
      "description": "Other chronic osteomyelitis Forearm"
    },
    {
      "name": "M8664",
      "description": "Other chronic osteomyelitis Hand"
    },
    {
      "name": "M8665",
      "description": "Other chronic osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8666",
      "description": "Other chronic osteomyelitis Lower leg"
    },
    {
      "name": "M8667",
      "description": "Other chronic osteomyelitis Ankle and foot"
    },
    {
      "name": "M8668",
      "description": "Other chronic osteomyelitis Other"
    },
    {
      "name": "M8669",
      "description": "Other chronic osteomyelitis Site unspecified"
    },
    {
      "name": "M868",
      "description": "Other osteomyelitis"
    },
    {
      "name": "M8680",
      "description": "Other osteomyelitis Multiple sites"
    },
    {
      "name": "M8681",
      "description": "Other osteomyelitis Shoulder region"
    },
    {
      "name": "M8682",
      "description": "Other osteomyelitis Upper arm"
    },
    {
      "name": "M8683",
      "description": "Other osteomyelitis Forearm"
    },
    {
      "name": "M8684",
      "description": "Other osteomyelitis Hand"
    },
    {
      "name": "M8685",
      "description": "Other osteomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8686",
      "description": "Other osteomyelitis Lower leg"
    },
    {
      "name": "M8687",
      "description": "Other osteomyelitis Ankle and foot"
    },
    {
      "name": "M8688",
      "description": "Other osteomyelitis Other"
    },
    {
      "name": "M8689",
      "description": "Other osteomyelitis Site unspecified"
    },
    {
      "name": "M869",
      "description": "Osteomyelitis, unspecified"
    },
    {
      "name": "M8690",
      "description": "Osteomyelitis, unspecified Multiple sites"
    },
    {
      "name": "M8691",
      "description": "Osteomyelitis, unspecified Shoulder region"
    },
    {
      "name": "M8692",
      "description": "Osteomyelitis, unspecified Upper arm"
    },
    {
      "name": "M8693",
      "description": "Osteomyelitis, unspecified Forearm"
    },
    {
      "name": "M8694",
      "description": "Osteomyelitis, unspecified Hand"
    },
    {
      "name": "M8695",
      "description": "Osteomyelitis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8696",
      "description": "Osteomyelitis, unspecified Lower leg"
    },
    {
      "name": "M8697",
      "description": "Osteomyelitis, unspecified Ankle and foot"
    },
    {
      "name": "M8698",
      "description": "Osteomyelitis, unspecified Other"
    },
    {
      "name": "M8699",
      "description": "Osteomyelitis, unspecified Site unspecified"
    },
    {
      "name": "M870",
      "description": "Idiopathic aseptic necrosis of bone"
    },
    {
      "name": "M8700",
      "description": "Idiopathic aseptic necrosis of bone Multiple sites"
    },
    {
      "name": "M8701",
      "description": "Idiopathic aseptic necrosis of bone Shoulder region"
    },
    {
      "name": "M8702",
      "description": "Idiopathic aseptic necrosis of bone Upper arm"
    },
    {
      "name": "M8703",
      "description": "Idiopathic aseptic necrosis of bone Forearm"
    },
    {
      "name": "M8704",
      "description": "Idiopathic aseptic necrosis of bone Hand"
    },
    {
      "name": "M8705",
      "description": "Idiopathic aseptic necrosis of bone Pelvic region and thigh"
    },
    {
      "name": "M8706",
      "description": "Idiopathic aseptic necrosis of bone Lower leg"
    },
    {
      "name": "M8707",
      "description": "Idiopathic aseptic necrosis of bone Ankle and foot"
    },
    {
      "name": "M8708",
      "description": "Idiopathic aseptic necrosis of bone Other"
    },
    {
      "name": "M8709",
      "description": "Idiopathic aseptic necrosis of bone Site unspecified"
    },
    {
      "name": "M871",
      "description": "Osteonecrosis due to drugs"
    },
    {
      "name": "M8710",
      "description": "Osteonecrosis due to drugs Multiple sites"
    },
    {
      "name": "M8711",
      "description": "Osteonecrosis due to drugs Shoulder region"
    },
    {
      "name": "M8712",
      "description": "Osteonecrosis due to drugs Upper arm"
    },
    {
      "name": "M8713",
      "description": "Osteonecrosis due to drugs Forearm"
    },
    {
      "name": "M8714",
      "description": "Osteonecrosis due to drugs Hand"
    },
    {
      "name": "M8715",
      "description": "Osteonecrosis due to drugs Pelvic region and thigh"
    },
    {
      "name": "M8716",
      "description": "Osteonecrosis due to drugs Lower leg"
    },
    {
      "name": "M8717",
      "description": "Osteonecrosis due to drugs Ankle and foot"
    },
    {
      "name": "M8718",
      "description": "Osteonecrosis due to drugs Other"
    },
    {
      "name": "M8719",
      "description": "Osteonecrosis due to drugs Site unspecified"
    },
    {
      "name": "M872",
      "description": "Osteonecrosis due to previous trauma"
    },
    {
      "name": "M8720",
      "description": "Osteonecrosis due to previous trauma Multiple sites"
    },
    {
      "name": "M8721",
      "description": "Osteonecrosis due to previous trauma Shoulder region"
    },
    {
      "name": "M8722",
      "description": "Osteonecrosis due to previous trauma Upper arm"
    },
    {
      "name": "M8723",
      "description": "Osteonecrosis due to previous trauma Forearm"
    },
    {
      "name": "M8724",
      "description": "Osteonecrosis due to previous trauma Hand"
    },
    {
      "name": "M8725",
      "description": "Osteonecrosis due to previous trauma Pelvic region and thigh"
    },
    {
      "name": "M8726",
      "description": "Osteonecrosis due to previous trauma Lower leg"
    },
    {
      "name": "M8727",
      "description": "Osteonecrosis due to previous trauma Ankle and foot"
    },
    {
      "name": "M8728",
      "description": "Osteonecrosis due to previous trauma Other"
    },
    {
      "name": "M8729",
      "description": "Osteonecrosis due to previous trauma Site unspecified"
    },
    {
      "name": "M873",
      "description": "Other secondary osteonecrosis"
    },
    {
      "name": "M8730",
      "description": "Other secondary osteonecrosis Multiple sites"
    },
    {
      "name": "M8731",
      "description": "Other secondary osteonecrosis Shoulder region"
    },
    {
      "name": "M8732",
      "description": "Other secondary osteonecrosis Upper arm"
    },
    {
      "name": "M8733",
      "description": "Other secondary osteonecrosis Forearm"
    },
    {
      "name": "M8734",
      "description": "Other secondary osteonecrosis Hand"
    },
    {
      "name": "M8735",
      "description": "Other secondary osteonecrosis Pelvic region and thigh"
    },
    {
      "name": "M8736",
      "description": "Other secondary osteonecrosis Lower leg"
    },
    {
      "name": "M8737",
      "description": "Other secondary osteonecrosis Ankle and foot"
    },
    {
      "name": "M8738",
      "description": "Other secondary osteonecrosis Other"
    },
    {
      "name": "M8739",
      "description": "Other secondary osteonecrosis Site unspecified"
    },
    {
      "name": "M878",
      "description": "Other osteonecrosis"
    },
    {
      "name": "M8780",
      "description": "Other osteonecrosis Multiple sites"
    },
    {
      "name": "M8781",
      "description": "Other osteonecrosis Shoulder region"
    },
    {
      "name": "M8782",
      "description": "Other osteonecrosis Upper arm"
    },
    {
      "name": "M8783",
      "description": "Other osteonecrosis Forearm"
    },
    {
      "name": "M8784",
      "description": "Other osteonecrosis Hand"
    },
    {
      "name": "M8785",
      "description": "Other osteonecrosis Pelvic region and thigh"
    },
    {
      "name": "M8786",
      "description": "Other osteonecrosis Lower leg"
    },
    {
      "name": "M8787",
      "description": "Other osteonecrosis Ankle and foot"
    },
    {
      "name": "M8788",
      "description": "Other osteonecrosis Other"
    },
    {
      "name": "M8789",
      "description": "Other osteonecrosis Site unspecified"
    },
    {
      "name": "M879",
      "description": "Osteonecrosis, unspecified"
    },
    {
      "name": "M8790",
      "description": "Osteonecrosis, unspecified Multiple sites"
    },
    {
      "name": "M8791",
      "description": "Osteonecrosis, unspecified Shoulder region"
    },
    {
      "name": "M8792",
      "description": "Osteonecrosis, unspecified Upper arm"
    },
    {
      "name": "M8793",
      "description": "Osteonecrosis, unspecified Forearm"
    },
    {
      "name": "M8794",
      "description": "Osteonecrosis, unspecified Hand"
    },
    {
      "name": "M8795",
      "description": "Osteonecrosis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8796",
      "description": "Osteonecrosis, unspecified Lower leg"
    },
    {
      "name": "M8797",
      "description": "Osteonecrosis, unspecified Ankle and foot"
    },
    {
      "name": "M8798",
      "description": "Osteonecrosis, unspecified Other"
    },
    {
      "name": "M8799",
      "description": "Osteonecrosis, unspecified Site unspecified"
    },
    {
      "name": "M880",
      "description": "Paget’s disease of skull"
    },
    {
      "name": "M8800",
      "description": "Paget's disease of skull Multiple sites"
    },
    {
      "name": "M8809",
      "description": "Paget's disease of skull Site unspecified"
    },
    {
      "name": "M888",
      "description": "Paget's disease of other bones"
    },
    {
      "name": "M8880",
      "description": "Paget's disease of other bones Multiple sites"
    },
    {
      "name": "M8881",
      "description": "Paget's disease of other bones Shoulder region"
    },
    {
      "name": "M8882",
      "description": "Paget's disease of other bones Upper arm"
    },
    {
      "name": "M8883",
      "description": "Paget's disease of other bones Forearm"
    },
    {
      "name": "M8884",
      "description": "Paget's disease of other bones Hand"
    },
    {
      "name": "M8885",
      "description": "Paget's disease of other bones Pelvic region and thigh"
    },
    {
      "name": "M8886",
      "description": "Paget's disease of other bones Lower leg"
    },
    {
      "name": "M8887",
      "description": "Paget's disease of other bones Ankle and foot"
    },
    {
      "name": "M8888",
      "description": "Paget's disease of other bones Other"
    },
    {
      "name": "M8889",
      "description": "Paget's disease of other bones Site unspecified"
    },
    {
      "name": "M889",
      "description": "Paget’s disease of bone, unspecified"
    },
    {
      "name": "M8890",
      "description": "Paget's disease of bone, unspecified Multiple sites"
    },
    {
      "name": "M8891",
      "description": "Paget's disease of bone, unspecified Shoulder region"
    },
    {
      "name": "M8892",
      "description": "Paget's disease of bone, unspecified Upper arm"
    },
    {
      "name": "M8893",
      "description": "Paget's disease of bone, unspecified Forearm"
    },
    {
      "name": "M8894",
      "description": "Paget's disease of bone, unspecified Hand"
    },
    {
      "name": "M8895",
      "description": "Paget's disease of bone, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8896",
      "description": "Paget's disease of bone, unspecified Lower leg"
    },
    {
      "name": "M8897",
      "description": "Paget's disease of bone, unspecified Ankle and foot"
    },
    {
      "name": "M8898",
      "description": "Paget's disease of bone, unspecified Other"
    },
    {
      "name": "M8899",
      "description": "Paget's disease of bone, unspecified Site unspecified"
    },
    {
      "name": "M890",
      "description": "Algoneurodystrophy"
    },
    {
      "name": "M8900",
      "description": "Algoneurodystrophy Multiple sites"
    },
    {
      "name": "M8901",
      "description": "Algoneurodystrophy Shoulder region"
    },
    {
      "name": "M8902",
      "description": "Algoneurodystrophy Upper arm"
    },
    {
      "name": "M8903",
      "description": "Algoneurodystrophy Forearm"
    },
    {
      "name": "M8904",
      "description": "Algoneurodystrophy Hand"
    },
    {
      "name": "M8905",
      "description": "Algoneurodystrophy Pelvic region and thigh"
    },
    {
      "name": "M8906",
      "description": "Algoneurodystrophy Lower leg"
    },
    {
      "name": "M8907",
      "description": "Algoneurodystrophy Ankle and foot"
    },
    {
      "name": "M8908",
      "description": "Algoneurodystrophy Other"
    },
    {
      "name": "M8909",
      "description": "Algoneurodystrophy Site unspecified"
    },
    {
      "name": "M891",
      "description": "Epiphyseal arrest"
    },
    {
      "name": "M8910",
      "description": "Epiphyseal arrest Multiple sites"
    },
    {
      "name": "M8911",
      "description": "Epiphyseal arrest Shoulder region"
    },
    {
      "name": "M8912",
      "description": "Epiphyseal arrest Upper arm"
    },
    {
      "name": "M8913",
      "description": "Epiphyseal arrest Forearm"
    },
    {
      "name": "M8914",
      "description": "Epiphyseal arrest Hand"
    },
    {
      "name": "M8915",
      "description": "Epiphyseal arrest Pelvic region and thigh"
    },
    {
      "name": "M8916",
      "description": "Epiphyseal arrest Lower leg"
    },
    {
      "name": "M8917",
      "description": "Epiphyseal arrest Ankle and foot"
    },
    {
      "name": "M8918",
      "description": "Epiphyseal arrest Other"
    },
    {
      "name": "M8919",
      "description": "Epiphyseal arrest Site unspecified"
    },
    {
      "name": "M892",
      "description": "Other disorders of bone development and growth"
    },
    {
      "name": "M8920",
      "description": "Other disorders of bone development and growth Multiple sites"
    },
    {
      "name": "M8921",
      "description": "Other disorders of bone development and growth Shoulder region"
    },
    {
      "name": "M8922",
      "description": "Other disorders of bone development and growth Upper arm"
    },
    {
      "name": "M8923",
      "description": "Other disorders of bone development and growth Forearm"
    },
    {
      "name": "M8924",
      "description": "Other disorders of bone development and growth Hand"
    },
    {
      "name": "M8925",
      "description": "Other disorders of bone development and growth Pelvic region and thigh"
    },
    {
      "name": "M8926",
      "description": "Other disorders of bone development and growth Lower leg"
    },
    {
      "name": "M8927",
      "description": "Other disorders of bone development and growth Ankle and foot"
    },
    {
      "name": "M8928",
      "description": "Other disorders of bone development and growth Other"
    },
    {
      "name": "M8929",
      "description": "Other disorders of bone development and growth Site unspecified"
    },
    {
      "name": "M893",
      "description": "Hypertrophy of bone"
    },
    {
      "name": "M8930",
      "description": "Hypertrophy of bone Multiple sites"
    },
    {
      "name": "M8931",
      "description": "Hypertrophy of bone Shoulder region"
    },
    {
      "name": "M8932",
      "description": "Hypertrophy of bone Upper arm"
    },
    {
      "name": "M8933",
      "description": "Hypertrophy of bone Forearm"
    },
    {
      "name": "M8934",
      "description": "Hypertrophy of bone Hand"
    },
    {
      "name": "M8935",
      "description": "Hypertrophy of bone Pelvic region and thigh"
    },
    {
      "name": "M8936",
      "description": "Hypertrophy of bone Lower leg"
    },
    {
      "name": "M8937",
      "description": "Hypertrophy of bone Ankle and foot"
    },
    {
      "name": "M8938",
      "description": "Hypertrophy of bone Other"
    },
    {
      "name": "M8939",
      "description": "Hypertrophy of bone Site unspecified"
    },
    {
      "name": "M894",
      "description": "Other hypertrophic osteoarthropathy"
    },
    {
      "name": "M8940",
      "description": "Other hypertrophic osteoarthropathy Multiple sites"
    },
    {
      "name": "M8941",
      "description": "Other hypertrophic osteoarthropathy Shoulder region"
    },
    {
      "name": "M8942",
      "description": "Other hypertrophic osteoarthropathy Upper arm"
    },
    {
      "name": "M8943",
      "description": "Other hypertrophic osteoarthropathy Forearm"
    },
    {
      "name": "M8944",
      "description": "Other hypertrophic osteoarthropathy Hand"
    },
    {
      "name": "M8945",
      "description": "Other hypertrophic osteoarthropathy Pelvic region and thigh"
    },
    {
      "name": "M8946",
      "description": "Other hypertrophic osteoarthropathy Lower leg"
    },
    {
      "name": "M8947",
      "description": "Other hypertrophic osteoarthropathy Ankle and foot"
    },
    {
      "name": "M8948",
      "description": "Other hypertrophic osteoarthropathy Other"
    },
    {
      "name": "M8949",
      "description": "Other hypertrophic osteoarthropathy Site unspecified"
    },
    {
      "name": "M895",
      "description": "Osteolysis"
    },
    {
      "name": "M8950",
      "description": "Osteolysis Multiple sites"
    },
    {
      "name": "M8951",
      "description": "Osteolysis Shoulder region"
    },
    {
      "name": "M8952",
      "description": "Osteolysis Upper arm"
    },
    {
      "name": "M8953",
      "description": "Osteolysis Forearm"
    },
    {
      "name": "M8954",
      "description": "Osteolysis Hand"
    },
    {
      "name": "M8955",
      "description": "Osteolysis Pelvic region and thigh"
    },
    {
      "name": "M8956",
      "description": "Osteolysis Lower leg"
    },
    {
      "name": "M8957",
      "description": "Osteolysis Ankle and foot"
    },
    {
      "name": "M8958",
      "description": "Osteolysis Other"
    },
    {
      "name": "M8959",
      "description": "Osteolysis Site unspecified"
    },
    {
      "name": "M896",
      "description": "Osteopathy after poliomyelitis"
    },
    {
      "name": "M8960",
      "description": "Osteopathy after poliomyelitis Multiple sites"
    },
    {
      "name": "M8961",
      "description": "Osteopathy after poliomyelitis Shoulder region"
    },
    {
      "name": "M8962",
      "description": "Osteopathy after poliomyelitis Upper arm"
    },
    {
      "name": "M8963",
      "description": "Osteopathy after poliomyelitis Forearm"
    },
    {
      "name": "M8964",
      "description": "Osteopathy after poliomyelitis Hand"
    },
    {
      "name": "M8965",
      "description": "Osteopathy after poliomyelitis Pelvic region and thigh"
    },
    {
      "name": "M8966",
      "description": "Osteopathy after poliomyelitis Lower leg"
    },
    {
      "name": "M8967",
      "description": "Osteopathy after poliomyelitis Ankle and foot"
    },
    {
      "name": "M8968",
      "description": "Osteopathy after poliomyelitis Other"
    },
    {
      "name": "M8969",
      "description": "Osteopathy after poliomyelitis Site unspecified"
    },
    {
      "name": "M8980",
      "description": "Infantile cortical hyperostoses"
    },
    {
      "name": "M8988",
      "description": "Other specified disorders of bone"
    },
    {
      "name": "M899",
      "description": "Disorder of bone, unspecified"
    },
    {
      "name": "M8990",
      "description": "Disorder of bone, unspecified Multiple sites"
    },
    {
      "name": "M8991",
      "description": "Disorder of bone, unspecified Shoulder region"
    },
    {
      "name": "M8992",
      "description": "Disorder of bone, unspecified Upper arm"
    },
    {
      "name": "M8993",
      "description": "Disorder of bone, unspecified Forearm"
    },
    {
      "name": "M8994",
      "description": "Disorder of bone, unspecified Hand"
    },
    {
      "name": "M8995",
      "description": "Disorder of bone, unspecified Pelvic region and thigh"
    },
    {
      "name": "M8996",
      "description": "Disorder of bone, unspecified Lower leg"
    },
    {
      "name": "M8997",
      "description": "Disorder of bone, unspecified Ankle and foot"
    },
    {
      "name": "M8998",
      "description": "Disorder of bone, unspecified Other"
    },
    {
      "name": "M8999",
      "description": "Disorder of bone, unspecified Site unspecified"
    },
    {
      "name": "M900",
      "description": "Tuberculosis of bone ( A18.0 )"
    },
    {
      "name": "M9000",
      "description": "Tuberculosis of bone ( A18.0 ) Multiple sites"
    },
    {
      "name": "M9001",
      "description": "Tuberculosis of bone ( A18.0 ) Shoulder region"
    },
    {
      "name": "M9002",
      "description": "Tuberculosis of bone ( A18.0 ) Upper arm"
    },
    {
      "name": "M9003",
      "description": "Tuberculosis of bone ( A18.0 ) Forearm"
    },
    {
      "name": "M9004",
      "description": "Tuberculosis of bone ( A18.0 ) Hand"
    },
    {
      "name": "M9005",
      "description": "Tuberculosis of bone ( A18.0 ) Pelvic region and thigh"
    },
    {
      "name": "M9006",
      "description": "Tuberculosis of bone ( A18.0 ) Lower leg"
    },
    {
      "name": "M9007",
      "description": "Tuberculosis of bone ( A18.0 ) Ankle and foot"
    },
    {
      "name": "M9008",
      "description": "Tuberculosis of bone ( A18.0 ) Other"
    },
    {
      "name": "M9009",
      "description": "Tuberculosis of bone ( A18.0 ) Site unspecified"
    },
    {
      "name": "M901",
      "description": "Periostitis in other infectious diseases classified elsewhere"
    },
    {
      "name": "M9010",
      "description": "Periostitis in other infectious diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M9011",
      "description": "Periostitis in other infectious diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M9012",
      "description": "Periostitis in other infectious diseases classified elsewhere Upper arm"
    },
    {
      "name": "M9013",
      "description": "Periostitis in other infectious diseases classified elsewhere Forearm"
    },
    {
      "name": "M9014",
      "description": "Periostitis in other infectious diseases classified elsewhere Hand"
    },
    {
      "name": "M9015",
      "description": "Periostitis in other infectious diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M9016",
      "description": "Periostitis in other infectious diseases classified elsewhere Lower leg"
    },
    {
      "name": "M9017",
      "description": "Periostitis in other infectious diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M9018",
      "description": "Periostitis in other infectious diseases classified elsewhere Other"
    },
    {
      "name": "M9019",
      "description": "Periostitis in other infectious diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M902",
      "description": "Osteopathy in other infectious diseases classified elsewhere"
    },
    {
      "name": "M9020",
      "description": "Osteopathy in other infectious diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M9021",
      "description": "Osteopathy in other infectious diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M9022",
      "description": "Osteopathy in other infectious diseases classified elsewhere Upper arm"
    },
    {
      "name": "M9023",
      "description": "Osteopathy in other infectious diseases classified elsewhere Forearm"
    },
    {
      "name": "M9024",
      "description": "Osteopathy in other infectious diseases classified elsewhere Hand"
    },
    {
      "name": "M9025",
      "description": "Osteopathy in other infectious diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M9026",
      "description": "Osteopathy in other infectious diseases classified elsewhere Lower leg"
    },
    {
      "name": "M9027",
      "description": "Osteopathy in other infectious diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M9028",
      "description": "Osteopathy in other infectious diseases classified elsewhere Other"
    },
    {
      "name": "M9029",
      "description": "Osteopathy in other infectious diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M903",
      "description": "Osteonecrosis in Caisson disease ( T70.3 )"
    },
    {
      "name": "M9030",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Multiple sites"
    },
    {
      "name": "M9031",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Shoulder region"
    },
    {
      "name": "M9032",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Upper arm"
    },
    {
      "name": "M9033",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Forearm"
    },
    {
      "name": "M9034",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Hand"
    },
    {
      "name": "M9035",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Pelvic region and thigh"
    },
    {
      "name": "M9036",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Lower leg"
    },
    {
      "name": "M9037",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Ankle and foot"
    },
    {
      "name": "M9038",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Other"
    },
    {
      "name": "M9039",
      "description": "Osteonecrosis in caisson disease ( T70.3 ) Site unspecified"
    },
    {
      "name": "M904",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 )"
    },
    {
      "name": "M9040",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Multiple sites"
    },
    {
      "name": "M9041",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Shoulder region"
    },
    {
      "name": "M9042",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Upper arm"
    },
    {
      "name": "M9043",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Forearm"
    },
    {
      "name": "M9044",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 Hand"
    },
    {
      "name": "M9045",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Pelvic region and thigh"
    },
    {
      "name": "M9046",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Lower leg"
    },
    {
      "name": "M9047",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Ankle and foot"
    },
    {
      "name": "M9048",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Other"
    },
    {
      "name": "M9049",
      "description": "Osteonecrosis due to haemoglobinopathy ( D50-D64 ) Site unspecified"
    },
    {
      "name": "M905",
      "description": "Osteonecrosis in other diseases classified elsewhere"
    },
    {
      "name": "M9050",
      "description": "Osteonecrosis in other diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M9051",
      "description": "Osteonecrosis in other diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M9052",
      "description": "Osteonecrosis in other diseases classified elsewhere Upper arm"
    },
    {
      "name": "M9053",
      "description": "Osteonecrosis in other diseases classified elsewhere Forearm"
    },
    {
      "name": "M9054",
      "description": "Osteonecrosis in other diseases classified elsewhere Hand"
    },
    {
      "name": "M9055",
      "description": "Osteonecrosis in other diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M9056",
      "description": "Osteonecrosis in other diseases classified elsewhere Lower leg"
    },
    {
      "name": "M9057",
      "description": "Osteonecrosis in other diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M9058",
      "description": "Osteonecrosis in other diseases classified elsewhere Other"
    },
    {
      "name": "M9059",
      "description": "Osteonecrosis in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M906",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 )"
    },
    {
      "name": "M9060",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Multiple sites"
    },
    {
      "name": "M9061",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Shoulder region"
    },
    {
      "name": "M9062",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Upper arm"
    },
    {
      "name": "M9063",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Forearm"
    },
    {
      "name": "M9064",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Hand"
    },
    {
      "name": "M9065",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Pelvic region and thigh"
    },
    {
      "name": "M9066",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Lower leg"
    },
    {
      "name": "M9067",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Ankle and foot"
    },
    {
      "name": "M9068",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Other"
    },
    {
      "name": "M9069",
      "description": "Osteitis deformans in neoplastic disease ( C00-D48 ) Site unspecified"
    },
    {
      "name": "M907",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 )"
    },
    {
      "name": "M9070",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Multiple sites"
    },
    {
      "name": "M9071",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Shoulder region"
    },
    {
      "name": "M9072",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Upper arm"
    },
    {
      "name": "M9073",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Forearm"
    },
    {
      "name": "M9074",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Hand"
    },
    {
      "name": "M9075",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Pelvic region and thigh"
    },
    {
      "name": "M9076",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Lower leg"
    },
    {
      "name": "M9077",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Ankle and foot"
    },
    {
      "name": "M9078",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Other"
    },
    {
      "name": "M9079",
      "description": "Fracture of bone in neoplastic disease ( C00-D48 ) Site unspecified"
    },
    {
      "name": "M908",
      "description": "Osteopathy in other diseases classified elsewhere"
    },
    {
      "name": "M9080",
      "description": "Osteopathy in other diseases classified elsewhere Multiple sites"
    },
    {
      "name": "M9081",
      "description": "Osteopathy in other diseases classified elsewhere Shoulder region"
    },
    {
      "name": "M9082",
      "description": "Osteopathy in other diseases classified elsewhere Upper arm"
    },
    {
      "name": "M9083",
      "description": "Osteopathy in other diseases classified elsewhere Forearm"
    },
    {
      "name": "M9084",
      "description": "Osteopathy in other diseases classified elsewhere Hand"
    },
    {
      "name": "M9085",
      "description": "Osteopathy in other diseases classified elsewhere Pelvic region and thigh"
    },
    {
      "name": "M9086",
      "description": "Osteopathy in other diseases classified elsewhere Lower leg"
    },
    {
      "name": "M9087",
      "description": "Osteopathy in other diseases classified elsewhere Ankle and foot"
    },
    {
      "name": "M9088",
      "description": "Osteopathy in other diseases classified elsewhere Other"
    },
    {
      "name": "M9089",
      "description": "Osteopathy in other diseases classified elsewhere Site unspecified"
    },
    {
      "name": "M910",
      "description": "Juvenile osteochondrosis of pelvis"
    },
    {
      "name": "M9100",
      "description": "Juvenile osteochondrosis of pelvis Multiple sites"
    },
    {
      "name": "M9105",
      "description": "Juvenile osteochondrosis of pelvis Pelvic region and thigh"
    },
    {
      "name": "M9109",
      "description": "Juvenile osteochondrosis of pelvis Site unspecified"
    },
    {
      "name": "M911",
      "description": "Juvenile osteochondrosis of head of femur [Legg-Calvé-Perthes]"
    },
    {
      "name": "M9110",
      "description": "Juvenile osteochondrosis of head of femur [ Legg-Calve-Perthes ] Multiple sites"
    },
    {
      "name": "M9115",
      "description": "Juvenile osteochondrosis of head of femur [ Legg-Calve-Perthes ] Pelvic region and thigh"
    },
    {
      "name": "M9119",
      "description": "Juvenile osteochondrosis of head of femur [ Legg-Calve-Perthes ] Site unspecified"
    },
    {
      "name": "M912",
      "description": "Coxa plana"
    },
    {
      "name": "M9120",
      "description": "Coxa plana Multiple sites"
    },
    {
      "name": "M9125",
      "description": "Coxa plana Pelvic region"
    },
    {
      "name": "M9129",
      "description": "Coxa plana Site unspecified"
    },
    {
      "name": "M913",
      "description": "Pseudocoxalgia"
    },
    {
      "name": "M9130",
      "description": "Pseudocoxalgia Multiple sites"
    },
    {
      "name": "M9135",
      "description": "Pseudocoxalgia Pelvic region and thigh"
    },
    {
      "name": "M9139",
      "description": "Pseudocoxalgia Site unspecified"
    },
    {
      "name": "M918",
      "description": "Other juvenile osteochondrosis of hip and pelvis"
    },
    {
      "name": "M9180",
      "description": "Other juvenile osteochondrosis of hip and pelvis Multiple sites"
    },
    {
      "name": "M9185",
      "description": "Other juvenile osteochondrosis of hip and pelvis Pelvic region and thigh"
    },
    {
      "name": "M9189",
      "description": "Other juvenile osteochondrosis of hip and pelvis Site unspecified"
    },
    {
      "name": "M919",
      "description": "Juvenile osteochondrosis of hip and pelvis, unspecified"
    },
    {
      "name": "M9190",
      "description": "Juvenile osteochondrosis of hip and pelvis, unspecified Multiple sites"
    },
    {
      "name": "M9195",
      "description": "Juvenile osteochondrosis of hip and pelvis, unspecified Pelvic region and thigh"
    },
    {
      "name": "M9199",
      "description": "Juvenile osteochondrosis of hip and pelvis, unspecified Site unspecified"
    },
    {
      "name": "M920",
      "description": "Juvenile osteochondrosis of humerus"
    },
    {
      "name": "M921",
      "description": "Juvenile osteochondrosis of radius and ulna"
    },
    {
      "name": "M922",
      "description": "Juvenile osteochondrosis of hand"
    },
    {
      "name": "M923",
      "description": "Other juvenile osteochondrosis of upper limb"
    },
    {
      "name": "M924",
      "description": "Juvenile osteochondrosis of patella"
    },
    {
      "name": "M925",
      "description": "Juvenile osteochondrosis of tibia and fibula"
    },
    {
      "name": "M926",
      "description": "Juvenile osteochondrosis of tarsus"
    },
    {
      "name": "M927",
      "description": "Juvenile osteochondrosis of metatarsus"
    },
    {
      "name": "M928",
      "description": "Other specified juvenile osteochondrosis"
    },
    {
      "name": "M929",
      "description": "Juvenile osteochondrosis, unspecified"
    },
    {
      "name": "M930",
      "description": "Slipped upper femoral epiphysis (nontraumatic)"
    },
    {
      "name": "M931",
      "description": "Kienböck’s disease of adults"
    },
    {
      "name": "M932",
      "description": "Osteochondritis dissecans"
    },
    {
      "name": "M938",
      "description": "Other specified osteochondropathies"
    },
    {
      "name": "M939",
      "description": "Osteochondropathy, unspecified"
    },
    {
      "name": "M940",
      "description": "Chondrocostal junction syndrome [Tietze]"
    },
    {
      "name": "M9408",
      "description": "Chondrocostal junction syndrome [ Tietze ] Other"
    },
    {
      "name": "M9409",
      "description": "Chondrocostal junction syndrome [ Tietze ] Site unspecified"
    },
    {
      "name": "M941",
      "description": "Relapsing polychondritis"
    },
    {
      "name": "M9410",
      "description": "Relapsing polychondritis Multiple sites"
    },
    {
      "name": "M9411",
      "description": "Relapsing polychondritis Shoulder region"
    },
    {
      "name": "M9412",
      "description": "Relapsing polychondritis Upper arm"
    },
    {
      "name": "M9413",
      "description": "Relapsing polychondritis Forearm"
    },
    {
      "name": "M9414",
      "description": "Relapsing polychondritis Hand"
    },
    {
      "name": "M9415",
      "description": "Relapsing polychondritis Pelvic region and thigh"
    },
    {
      "name": "M9416",
      "description": "Relapsing polychondritis Lower leg"
    },
    {
      "name": "M9417",
      "description": "Relapsing polychondritis Ankle and foot"
    },
    {
      "name": "M9418",
      "description": "Relapsing polychondritis Other"
    },
    {
      "name": "M9419",
      "description": "Relapsing polychondritis Site unspecified"
    },
    {
      "name": "M942",
      "description": "Chondromalacia"
    },
    {
      "name": "M9420",
      "description": "Chondromalacia Multiple sites"
    },
    {
      "name": "M9421",
      "description": "Chondromalacia Shoulder region"
    },
    {
      "name": "M9422",
      "description": "Chondromalacia Upper arm"
    },
    {
      "name": "M9423",
      "description": "Chondromalacia Forearm"
    },
    {
      "name": "M9424",
      "description": "Chondromalacia Hand"
    },
    {
      "name": "M9425",
      "description": "Chondromalacia Pelvic region and thigh"
    },
    {
      "name": "M9426",
      "description": "Chondromalacia Lower leg"
    },
    {
      "name": "M9427",
      "description": "Chondromalacia Ankle and foot"
    },
    {
      "name": "M9428",
      "description": "Chondromalacia Other"
    },
    {
      "name": "M9429",
      "description": "Chondromalacia Site unspecified"
    },
    {
      "name": "M943",
      "description": "Chondrolysis"
    },
    {
      "name": "M9430",
      "description": "Chondrolysis Multiple sites"
    },
    {
      "name": "M9431",
      "description": "Chondrolysis Shoulder region"
    },
    {
      "name": "M9432",
      "description": "Chondrolysis Upper arm"
    },
    {
      "name": "M9433",
      "description": "Chondrolysis Forearm"
    },
    {
      "name": "M9434",
      "description": "Chondrolysis Hand"
    },
    {
      "name": "M9435",
      "description": "Chondrolysis Pelvic region and thigh"
    },
    {
      "name": "M9436",
      "description": "Chondrolysis Lower leg"
    },
    {
      "name": "M9437",
      "description": "Chondrolysis Ankle and foot"
    },
    {
      "name": "M9438",
      "description": "Chondrolysis Other"
    },
    {
      "name": "M9439",
      "description": "Chondrolysis Site unspecified"
    },
    {
      "name": "M948",
      "description": "Other specified disorders of cartilage"
    },
    {
      "name": "M9480",
      "description": "Other specified disorders of cartilage Multiple sites"
    },
    {
      "name": "M9481",
      "description": "Other specified disorders of cartilage Shoulder region"
    },
    {
      "name": "M9482",
      "description": "Other specified disorders of cartilage Upper arm"
    },
    {
      "name": "M9483",
      "description": "Other specified disorders of cartilage Forearm"
    },
    {
      "name": "M9484",
      "description": "Other specified disorders of cartilage Hand"
    },
    {
      "name": "M9485",
      "description": "Other specified disorders of cartilage Pelvic region and thigh"
    },
    {
      "name": "M9486",
      "description": "Other specified disorders of cartilage Lower leg"
    },
    {
      "name": "M9487",
      "description": "Other specified disorders of cartilage Ankle and foot"
    },
    {
      "name": "M9488",
      "description": "Other specified disorders of cartilage Other"
    },
    {
      "name": "M9489",
      "description": "Other specified disorders of cartilage Site unspecified"
    },
    {
      "name": "M949",
      "description": "Disorder of cartilage, unspecified"
    },
    {
      "name": "M9490",
      "description": "Disorder of cartilage, unspecified Multiple sites"
    },
    {
      "name": "M9491",
      "description": "Disorder of cartilage, unspecified Shoulder region"
    },
    {
      "name": "M9492",
      "description": "Disorder of cartilage, unspecified Upper arm"
    },
    {
      "name": "M9493",
      "description": "Disorder of cartilage, unspecified Forearm"
    },
    {
      "name": "M9494",
      "description": "Disorder of cartilage, unspecified Hand"
    },
    {
      "name": "M9495",
      "description": "Disorder of cartilage, unspecified Pelvic region and thigh"
    },
    {
      "name": "M9496",
      "description": "Disorder of cartilage, unspecified Lower leg"
    },
    {
      "name": "M9497",
      "description": "Disorder of cartilage, unspecified Ankle and foot"
    },
    {
      "name": "M9498",
      "description": "Disorder of cartilage, unspecified Other"
    },
    {
      "name": "M9499",
      "description": "Disorder of cartilage, unspecified Site unspecified"
    },
    {
      "name": "M950",
      "description": "Acquired deformity of nose"
    },
    {
      "name": "M951",
      "description": "Cauliflower ear"
    },
    {
      "name": "M952",
      "description": "Other acquired deformity of head"
    },
    {
      "name": "M953",
      "description": "Acquired deformity of neck"
    },
    {
      "name": "M954",
      "description": "Acquired deformity of chest and rib"
    },
    {
      "name": "M955",
      "description": "Acquired deformity of pelvis"
    },
    {
      "name": "M958",
      "description": "Other specified acquired deformities of musculoskeletal system"
    },
    {
      "name": "M959",
      "description": "Acquired deformity of musculoskeletal system, unspecified"
    },
    {
      "name": "M960",
      "description": "Pseudarthrosis after fusion or arthrodesis"
    },
    {
      "name": "M961",
      "description": "Postlaminectomy syndrome, not elsewhere classified"
    },
    {
      "name": "M962",
      "description": "Postradiation kyphosis"
    },
    {
      "name": "M963",
      "description": "Postlaminectomy kyphosis"
    },
    {
      "name": "M964",
      "description": "Postsurgical lordosis"
    },
    {
      "name": "M965",
      "description": "Postradiation scoliosis"
    },
    {
      "name": "M966",
      "description": "Fracture of bone following insertion of orthopaedic implant, joint prosthesis, or bone plate"
    },
    {
      "name": "M968",
      "description": "Other postprocedural musculoskeletal disorders"
    },
    {
      "name": "M969",
      "description": "Postprocedural musculoskeletal disorder, unspecified"
    },
    {
      "name": "M990",
      "description": "Segmental and somatic dysfunction"
    },
    {
      "name": "M9900",
      "description": "Segmental and somatic dysfunction Head region"
    },
    {
      "name": "M9901",
      "description": "Segmental and somatic dysfunction Cervical region"
    },
    {
      "name": "M9902",
      "description": "Segmental and somatic dysfunction Thoracic region"
    },
    {
      "name": "M9903",
      "description": "Segmental and somatic dysfunction Lumbar region"
    },
    {
      "name": "M9904",
      "description": "Segmental and somatic dysfunction Sacral region"
    },
    {
      "name": "M9905",
      "description": "Segmental and somatic dysfunction Pelvic region"
    },
    {
      "name": "M9906",
      "description": "Segmental and somatic dysfunction Lower extremity"
    },
    {
      "name": "M9907",
      "description": "Segmental and somatic dysfunction Upper extremity"
    },
    {
      "name": "M9908",
      "description": "Segmental and somatic dysfunction Rib cage"
    },
    {
      "name": "M9909",
      "description": "Segmental and somatic dysfunction Abdomen and other"
    },
    {
      "name": "M991",
      "description": "Subluxation complex (vertebral)"
    },
    {
      "name": "M9910",
      "description": "Subluxation complex ( vertebral ) Head region"
    },
    {
      "name": "M9911",
      "description": "Subluxation complex ( vertebral ) Cervical region"
    },
    {
      "name": "M9912",
      "description": "Subluxation complex ( vertebral ) Thoracic region"
    },
    {
      "name": "M9913",
      "description": "Subluxation complex ( vertebral ) Lumbar region"
    },
    {
      "name": "M9914",
      "description": "Subluxation complex ( vertebral ) Sacral region"
    },
    {
      "name": "M9915",
      "description": "Subluxation complex ( vertebral ) Pelvic region"
    },
    {
      "name": "M9916",
      "description": "Subluxation complex ( vertebral ) Lower extremity"
    },
    {
      "name": "M9917",
      "description": "Subluxation complex ( vertebral ) Upper extremiry"
    },
    {
      "name": "M9918",
      "description": "Subluxation complex ( vertebral ) Rib cage"
    },
    {
      "name": "M9919",
      "description": "Subluxation complex ( vertebral ) Abdomen and other"
    },
    {
      "name": "M992",
      "description": "Subluxation stenosis of neural canal"
    },
    {
      "name": "M9920",
      "description": "Subluxation stenosis of neural canal Head region"
    },
    {
      "name": "M9921",
      "description": "Subluxation stenosis of neural canal Cervical region"
    },
    {
      "name": "M9922",
      "description": "Subluxation stenosis of neural canal Thoracic region"
    },
    {
      "name": "M9923",
      "description": "Subluxation stenosis of neural canal Lumbar region"
    },
    {
      "name": "M9924",
      "description": "Subluxation stenosis of neural canal Sacral region"
    },
    {
      "name": "M9925",
      "description": "Subluxation stenosis of neural canal Pelvic region"
    },
    {
      "name": "M9926",
      "description": "Subluxation stenosis of neural canal Lower extremity"
    },
    {
      "name": "M9927",
      "description": "Subluxation stenosis of neural canal Upper extremity"
    },
    {
      "name": "M9928",
      "description": "Subluxation stenosis of neural canal Rib cage"
    },
    {
      "name": "M9929",
      "description": "Subluxation stenosis of neural canal Abdomen and other"
    },
    {
      "name": "M993",
      "description": "Osseous stenosis of neural canal"
    },
    {
      "name": "M9930",
      "description": "Osseous stenosis of neural canal Head region"
    },
    {
      "name": "M9931",
      "description": "Osseous stenosis of neural canal Cervical region"
    },
    {
      "name": "M9932",
      "description": "Osseous stenosis of neural canal Thoracic region"
    },
    {
      "name": "M9933",
      "description": "Osseous stenosis of neural canal Lumbar region"
    },
    {
      "name": "M9934",
      "description": "Osseous stenosis of neural canal Sacral region"
    },
    {
      "name": "M9935",
      "description": "Osseous stenosis of neural canal Pelvic region"
    },
    {
      "name": "M9936",
      "description": "Osseous stenosis of neural canal Lower extremity"
    },
    {
      "name": "M9937",
      "description": "Osseous stenosis of neural canal Upper extremity"
    },
    {
      "name": "M9938",
      "description": "Osseous stenosis of neural canal Rib cage"
    },
    {
      "name": "M9939",
      "description": "Osseous stenosis of neural canal Abdomen and other"
    },
    {
      "name": "M994",
      "description": "Connective tissue stenosis of neural canal"
    },
    {
      "name": "M9940",
      "description": "Connective tissue stenosis of neural canal Head region"
    },
    {
      "name": "M9941",
      "description": "Connective tissue stenosis of neural canal Cervical region"
    },
    {
      "name": "M9942",
      "description": "Connective tissue stenosis of neural canal Thoracic region"
    },
    {
      "name": "M9943",
      "description": "Connective tissue stenosis of neural canal Lumbar region"
    },
    {
      "name": "M9944",
      "description": "Connective tissue stenosis of neural canal Sacral region"
    },
    {
      "name": "M9945",
      "description": "Connective tissue stenosis of neural canal Pelvic region"
    },
    {
      "name": "M9946",
      "description": "Connective tissue stenosis of neural canal Lower extremity"
    },
    {
      "name": "M9947",
      "description": "Connective tissue stenosis of neural canal Upper extremity"
    },
    {
      "name": "M9948",
      "description": "Connective tissue stenosis of neural canal Rib cage"
    },
    {
      "name": "M9949",
      "description": "Connective tissue stenosis of neural canal Abdomen and other"
    },
    {
      "name": "M995",
      "description": "Intervertebral disc stenosis of neural canal"
    },
    {
      "name": "M9950",
      "description": "Intervertebral disc stenosis of neural canal Head region"
    },
    {
      "name": "M9951",
      "description": "Intervertebral disc stenosis of neural canal Cervical region"
    },
    {
      "name": "M9952",
      "description": "Intervertebral disc stenosis of neural canal Thoracic region"
    },
    {
      "name": "M9953",
      "description": "Intervertebral disc stenosis of neural canal Lumbar region"
    },
    {
      "name": "M9954",
      "description": "Intervertebral disc stenosis of neural canal Sacral region"
    },
    {
      "name": "M9955",
      "description": "Intervertebral disc stenosis of neural canal Pelvic region"
    },
    {
      "name": "M9956",
      "description": "Intervertebral disc stenosis of neural canal Lower extremity"
    },
    {
      "name": "M9957",
      "description": "Intervertebral disc stenosis of neural canal Upper extremity"
    },
    {
      "name": "M9958",
      "description": "Intervertebral disc stenosis of neural canal Rib cage"
    },
    {
      "name": "M9959",
      "description": "Intervertebral disc stenosis of neural canal Abdomen and other"
    },
    {
      "name": "M996",
      "description": "Osseous and subluxation stenosis of intervertebral foramina"
    },
    {
      "name": "M9960",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Head region"
    },
    {
      "name": "M9961",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Cervical region"
    },
    {
      "name": "M9962",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Thoracic region"
    },
    {
      "name": "M9963",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Lumbar region"
    },
    {
      "name": "M9964",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Sacral region"
    },
    {
      "name": "M9965",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Pelvic region"
    },
    {
      "name": "M9966",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Lower extremity"
    },
    {
      "name": "M9967",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Upper extremity"
    },
    {
      "name": "M9968",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Rib cage"
    },
    {
      "name": "M9969",
      "description": "Osseous and subluxaton stenosis of intervertebral foramina Abdomen and other"
    },
    {
      "name": "M997",
      "description": "Connective tissue and disc stenosis of intervertebral foramina"
    },
    {
      "name": "M9970",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Head region"
    },
    {
      "name": "M9971",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Cervical region"
    },
    {
      "name": "M9972",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Thoracic region"
    },
    {
      "name": "M9973",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Lumbar region"
    },
    {
      "name": "M9974",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Sacral region"
    },
    {
      "name": "M9975",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Pelvic region"
    },
    {
      "name": "M9976",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Lower extremity"
    },
    {
      "name": "M9977",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Upper extremity"
    },
    {
      "name": "M9978",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Rib cage"
    },
    {
      "name": "M9979",
      "description": "Connective tissue and disc stenosis of intervertebral foramina Abdomen and other"
    },
    {
      "name": "M998",
      "description": "Other biomechanical lesions"
    },
    {
      "name": "M9980",
      "description": "Other biomechanical lesions Head region"
    },
    {
      "name": "M9981",
      "description": "Other biomechanical lesions Cervical region"
    },
    {
      "name": "M9982",
      "description": "Other biomechanical lesions Thoracic region"
    },
    {
      "name": "M9983",
      "description": "Other biomechanical lesions Lumbar region"
    },
    {
      "name": "M9984",
      "description": "Other biomechanical lesions Sacral region"
    },
    {
      "name": "M9985",
      "description": "Other biomechanical lesions Pelvic region"
    },
    {
      "name": "M9986",
      "description": "Other biomechanical lesions Lower extremity"
    },
    {
      "name": "M9987",
      "description": "Other biomechanical lesions Upper extremity"
    },
    {
      "name": "M9988",
      "description": "Other biomechanical lesions Rib cage"
    },
    {
      "name": "M9989",
      "description": "Other biomechanical lesions Abdomen and other"
    },
    {
      "name": "M999",
      "description": "Biomechanical lesion, unspecified"
    },
    {
      "name": "M9990",
      "description": "Biomechanical lesion, unspecified Head region"
    },
    {
      "name": "M9991",
      "description": "Biomechanical lesion, unspecified Cervical region"
    },
    {
      "name": "M9992",
      "description": "Biomechanical lesion, unspecified Thoracic region"
    },
    {
      "name": "M9993",
      "description": "Biomechanical lesion, unspecified Lumbar region"
    },
    {
      "name": "M9994",
      "description": "Biomechanical lesion, unspecified Sacral region"
    },
    {
      "name": "M9995",
      "description": "Biomechanical lesion, unspecified Pelvic region"
    },
    {
      "name": "M9996",
      "description": "Biomechanical lesion, unspecified Lower extremity"
    },
    {
      "name": "M9997",
      "description": "Biomechanical lesion, unspecified Upper extremity"
    },
    {
      "name": "M9998",
      "description": "Biomechanical lesion, unspecified Rib cage"
    },
    {
      "name": "M9999",
      "description": "Biomechanical lesion, unspecified Abdomen and other"
    },
    {
      "name": "N000",
      "description": "Acute nephritic syndrome at minor glomerular abnormality"
    },
    {
      "name": "N0000",
      "description": "Acute nephritic syndrome at minor glomerular abnormality IgM staining"
    },
    {
      "name": "N0001",
      "description": "Acute nephritic syndrome at minor glomerular abnormality IgA staining"
    },
    {
      "name": "N0002",
      "description": "Acute nephritic syndrome at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0008",
      "description": "Acute nephritic syndrome at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0009",
      "description": "Acute nephritic syndrome at minor glomerular abnormality unspecified"
    },
    {
      "name": "N001",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0010",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0011",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0012",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0018",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0019",
      "description": "Acute nephritic syndrome at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N002",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0020",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0021",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0022",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis  IgG staining"
    },
    {
      "name": "N0028",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0029",
      "description": "Acute nephritic syndrome at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N003",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0030",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0031",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis  IgA staining"
    },
    {
      "name": "N0032",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0038",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0039",
      "description": "Acute nephritic syndrome at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N004",
      "description": "Acute nephritic syndrome at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0040",
      "description": "Acute nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0041",
      "description": "Acute nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0042",
      "description": "Acute nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0048",
      "description": "Acute nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0049",
      "description": "Acute nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N005",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0050",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis  IgM staining"
    },
    {
      "name": "N0051",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0052",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0058",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0059",
      "description": "Acute nephritic syndrome at diffuse mesangiocapillary glomerulonephritis unspecified"
    },
    {
      "name": "N006",
      "description": "Acute nephritic syndrome at dense deposit disease"
    },
    {
      "name": "N0060",
      "description": "Acute nephritic syndrome at dense deposit disease IgM staining"
    },
    {
      "name": "N0061",
      "description": "Acute nephritic syndrome at dense deposit disease  IgA staining"
    },
    {
      "name": "N0062",
      "description": "Acute nephritic syndrome at dense deposit disease  IgG staining"
    },
    {
      "name": "N0068",
      "description": "Acute nephritic syndrome at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0069",
      "description": "Acute nephritic syndrome at dense deposit disease  unspecified"
    },
    {
      "name": "N007",
      "description": "Acute nephritic syndrome at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0070",
      "description": "Acute nephritic syndrome at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0071",
      "description": "Acute nephritic syndrome at diffuse crescentic glomerulonephritis  IgA staining"
    },
    {
      "name": "N0072",
      "description": "Acute nephritic syndrome at diffuse crescentic glomerulonephritis  IgG staining"
    },
    {
      "name": "N0078",
      "description": "Acute nephritic syndrome at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0079",
      "description": "Acute nephritic syndrome at diffuse crescentic glomerulonephritis  unspecified"
    },
    {
      "name": "N008",
      "description": "Acute nephritic syndrome at other"
    },
    {
      "name": "N0080",
      "description": "Acute nephritic syndrome at other IgM staining"
    },
    {
      "name": "N0081",
      "description": "Acute nephritic syndrome at other  IgA staining"
    },
    {
      "name": "N0082",
      "description": "Acute nephritic syndrome at other IgG staining"
    },
    {
      "name": "N0088",
      "description": "Acute nephritic syndrome at other multiple immunoglobulin staining"
    },
    {
      "name": "N0089",
      "description": "Acute nephritic syndrome at other unspecified"
    },
    {
      "name": "N009",
      "description": "Acute nephritic syndrome at unspecified"
    },
    {
      "name": "N0090",
      "description": "Acute nephritic syndrome at unspecified IgM staining"
    },
    {
      "name": "N0091",
      "description": "Acute nephritic syndrome at unspecified  IgA staining"
    },
    {
      "name": "N0092",
      "description": "Acute nephritic syndrome at unspecified  IgG staining"
    },
    {
      "name": "N0098",
      "description": "Acute nephritic syndrome at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0099",
      "description": "Acute nephritic syndrome at unspecified unspecified"
    },
    {
      "name": "N010",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality"
    },
    {
      "name": "N0100",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality  IgM staining"
    },
    {
      "name": "N0101",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality  IgA staining"
    },
    {
      "name": "N0102",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0108",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0109",
      "description": "Rapidly progressive nephritic syndrome at minor glomerular abnormality unspecified"
    },
    {
      "name": "N011",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0110",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0111",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0112",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0118",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0119",
      "description": "Rapidly progressive nephritic syndrome at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N012",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0120",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0121",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis  IgA staining"
    },
    {
      "name": "N0122",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0128",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0129",
      "description": "Rapidly progressive nephritic syndrome at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N013",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0130",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0131",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis  IgA staining"
    },
    {
      "name": "N0132",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0138",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0139",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N014",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0140",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0141",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis  IgA staining"
    },
    {
      "name": "N0142",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0148",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0149",
      "description": "Rapidly progressive nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N015",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0150",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgM staining"
    },
    {
      "name": "N0151",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis  IgA staining"
    },
    {
      "name": "N0152",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0158",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0159",
      "description": "Rapidly progressive nephritic syndrome at diffuse mesangiocapillary glomerulonephritis  unspecified"
    },
    {
      "name": "N016",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease"
    },
    {
      "name": "N0160",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease IgM staining"
    },
    {
      "name": "N0161",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease IgA staining"
    },
    {
      "name": "N0162",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease  IgG staining"
    },
    {
      "name": "N0168",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0169",
      "description": "Rapidly progressive nephritic syndrome at dense deposit disease unspecified"
    },
    {
      "name": "N017",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0170",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic glomerulonephritis  IgM staining"
    },
    {
      "name": "N0171",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0172",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0178",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0179",
      "description": "Rapidly progressive nephritic syndrome at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N018",
      "description": "Rapidly progressive nephritic syndrome at other"
    },
    {
      "name": "N0180",
      "description": "Rapidly progressive nephritic syndrome at other  IgM staining"
    },
    {
      "name": "N0181",
      "description": "Rapidly progressive nephritic syndrome at other  IgA staining"
    },
    {
      "name": "N0182",
      "description": "Rapidly progressive nephritic syndrome at other IgG staining"
    },
    {
      "name": "N0188",
      "description": "Rapidly progressive nephritic syndrome at other multiple immunoglobulin staining"
    },
    {
      "name": "N0189",
      "description": "Rapidly progressive nephritic syndrome at other unspecified"
    },
    {
      "name": "N019",
      "description": "Rapidly progressive nephritic syndrome at unspecified"
    },
    {
      "name": "N0190",
      "description": "Rapidly progressive nephritic syndrome at unspecified IgM staining"
    },
    {
      "name": "N0191",
      "description": "Rapidly progressive nephritic syndrome at unspecified IgA staining"
    },
    {
      "name": "N0192",
      "description": "Rapidly progressive nephritic syndrome at unspecified  IgG staining"
    },
    {
      "name": "N0198",
      "description": "Rapidly progressive nephritic syndrome at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0199",
      "description": "Rapidly progressive nephritic syndrome at unspecified unspecified"
    },
    {
      "name": "N020",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality"
    },
    {
      "name": "N0200",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality  IgM staining"
    },
    {
      "name": "N0201",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality  IgA staining"
    },
    {
      "name": "N0202",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0208",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0209",
      "description": "Recurrent and persistent haematuria at minor glomerular abnormality unspecified"
    },
    {
      "name": "N021",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0210",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions  IgM staining"
    },
    {
      "name": "N0211",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0212",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0218",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0219",
      "description": "Recurrent and persistent haematuria at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N022",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0220",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0221",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0222",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0228",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0229",
      "description": "Recurrent and persistent haematuria at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N023",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0230",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0231",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0232",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0238",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0239",
      "description": "Recurrent and persistent haematuria at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N024",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0240",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0241",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0242",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary proliferative glomerulonephritis  IgG staining"
    },
    {
      "name": "N0248",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0249",
      "description": "Recurrent and persistent haematuria at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N025",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0250",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis IgM staining"
    },
    {
      "name": "N0251",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0252",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0258",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0259",
      "description": "Recurrent and persistent haematuria at diffuse mesangiocapillary glomerulonephritis unspecified"
    },
    {
      "name": "N026",
      "description": "Recurrent and persistent haematuria at dense deposit disease"
    },
    {
      "name": "N0260",
      "description": "Recurrent and persistent haematuria at dense deposit disease  IgM staining"
    },
    {
      "name": "N0261",
      "description": "Recurrent and persistent haematuria at dense deposit disease IgA staining"
    },
    {
      "name": "N0262",
      "description": "Recurrent and persistent haematuria at dense deposit disease IgG staining"
    },
    {
      "name": "N0268",
      "description": "Recurrent and persistent haematuria at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0269",
      "description": "Recurrent and persistent haematuria at dense deposit disease unspecified"
    },
    {
      "name": "N027",
      "description": "Recurrent and persistent haematuria at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0270",
      "description": "Recurrent and persistent haematuria at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0271",
      "description": "Recurrent and persistent haematuria at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0272",
      "description": "Recurrent and persistent haematuria at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0278",
      "description": "Recurrent and persistent haematuria at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0279",
      "description": "Recurrent and persistent haematuria at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N028",
      "description": "Recurrent and persistent haematuria at other"
    },
    {
      "name": "N0280",
      "description": "Recurrent and persistent haematuria at other IgM staining"
    },
    {
      "name": "N0281",
      "description": "Recurrent and persistent haematuria at other IgA staining"
    },
    {
      "name": "N0282",
      "description": "Recurrent and persistent haematuria at other IgG staining"
    },
    {
      "name": "N0288",
      "description": "Recurrent and persistent haematuria at other multiple immunoglobulin staining"
    },
    {
      "name": "N0289",
      "description": "Recurrent and persistent haematuria at other unspecified"
    },
    {
      "name": "N029",
      "description": "Recurrent and persistent haematuria at unspecified"
    },
    {
      "name": "N0290",
      "description": "Recurrent and persistent haematuria at unspecified IgM staining"
    },
    {
      "name": "N0291",
      "description": "Recurrent and persistent haematuria at unspecified IgA staining"
    },
    {
      "name": "N0292",
      "description": "Recurrent and persistent haematuria at unspecified IgG staining"
    },
    {
      "name": "N0298",
      "description": "Recurrent and persistent haematuria at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0299",
      "description": "Recurrent and persistent haematuria at unspecified unspecified"
    },
    {
      "name": "N030",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality"
    },
    {
      "name": "N0300",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality IgM staining"
    },
    {
      "name": "N0301",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality  IgA staining"
    },
    {
      "name": "N0302",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0308",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0309",
      "description": "Chronic nephritic syndrome at minor glomerular abnormality unspecified"
    },
    {
      "name": "N031",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0310",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0311",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions  IgA staining"
    },
    {
      "name": "N0312",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0318",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0319",
      "description": "Chronic nephritic syndrome at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N032",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0320",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0321",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis  IgA staining"
    },
    {
      "name": "N0322",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0328",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0329",
      "description": "Chronic nephritic syndrome at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N033",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0330",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0331",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis  IgA staining"
    },
    {
      "name": "N0332",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis  IgG staining"
    },
    {
      "name": "N0338",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0339",
      "description": "Chronic nephritic syndrome at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N034",
      "description": "Chronic nephritic syndrome at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0340",
      "description": "Chronic nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0341",
      "description": "Chronic nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis  IgA staining"
    },
    {
      "name": "N0342",
      "description": "Chronic nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis  IgG staining"
    },
    {
      "name": "N0348",
      "description": "Chronic nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0349",
      "description": "Chronic nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N035",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0350",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgM staining"
    },
    {
      "name": "N0351",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0352",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0358",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0359",
      "description": "Chronic nephritic syndrome at diffuse mesangiocapillary glomerulonephritis unspecified"
    },
    {
      "name": "N036",
      "description": "Chronic nephritic syndrome at dense deposit disease"
    },
    {
      "name": "N0360",
      "description": "Chronic nephritic syndrome at dense deposit disease  IgM staining"
    },
    {
      "name": "N0361",
      "description": "Chronic nephritic syndrome at dense deposit disease IgA staining"
    },
    {
      "name": "N0362",
      "description": "Chronic nephritic syndrome at dense deposit disease IgG staining"
    },
    {
      "name": "N0368",
      "description": "Chronic nephritic syndrome at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0369",
      "description": "Chronic nephritic syndrome at dense deposit disease unspecified"
    },
    {
      "name": "N037",
      "description": "Chronic nephritic syndrome at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0370",
      "description": "Chronic nephritic syndrome at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0371",
      "description": "Chronic nephritic syndrome at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0372",
      "description": "Chronic nephritic syndrome at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0378",
      "description": "Chronic nephritic syndrome at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0379",
      "description": "Chronic nephritic syndrome at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N038",
      "description": "Chronic nephritic syndrome at other"
    },
    {
      "name": "N0380",
      "description": "Chronic nephritic syndrome at other IgM staining"
    },
    {
      "name": "N0381",
      "description": "Chronic nephritic syndrome at other IgA staining"
    },
    {
      "name": "N0382",
      "description": "Chronic nephritic syndrome at other IgG staining"
    },
    {
      "name": "N0388",
      "description": "Chronic nephritic syndrome at other multiple immunoglobulin staining"
    },
    {
      "name": "N0389",
      "description": "Chronic nephritic syndrome at other unspecified"
    },
    {
      "name": "N039",
      "description": "Chronic nephritic syndrome at unspecified"
    },
    {
      "name": "N0390",
      "description": "Chronic nephritic syndrome at unspecified IgM staining"
    },
    {
      "name": "N0391",
      "description": "Chronic nephritic syndrome at unspecified  IgA staining"
    },
    {
      "name": "N0392",
      "description": "Chronic nephritic syndrome at unspecified IgG staining"
    },
    {
      "name": "N0398",
      "description": "Chronic nephritic syndrome at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0399",
      "description": "Chronic nephritic syndrome at unspecified unspecified"
    },
    {
      "name": "N040",
      "description": "Nephrotic syndrome at minor glomerular abnormality"
    },
    {
      "name": "N0400",
      "description": "Nephrotic syndrome at minor glomerular abnormality IgM staining"
    },
    {
      "name": "N0401",
      "description": "Nephrotic syndrome at minor glomerular abnormality IgA staining"
    },
    {
      "name": "N0402",
      "description": "Nephrotic syndrome at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0408",
      "description": "Nephrotic syndrome at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0409",
      "description": "Nephrotic syndrome at minor glomerular abnormality unspecified"
    },
    {
      "name": "N041",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0410",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0411",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0412",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0418",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0419",
      "description": "Nephrotic syndrome at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N042",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0420",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0421",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0422",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0428",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0429",
      "description": "Nephrotic syndrome at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N043",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0430",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis  IgM staining"
    },
    {
      "name": "N0431",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0432",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis  IgG staining"
    },
    {
      "name": "N0438",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0439",
      "description": "Nephrotic syndrome at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N044",
      "description": "Nephrotic syndrome at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0440",
      "description": "Nephrotic syndrome at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0441",
      "description": "Nephrotic syndrome at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0442",
      "description": "Nephrotic syndrome at diffuse endocapillary proliferative glomerulonephritis  IgG staining"
    },
    {
      "name": "N0448",
      "description": "Nephrotic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0449",
      "description": "Nephrotic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N045",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0450",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis  IgM staining"
    },
    {
      "name": "N0451",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0452",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis  IgG staining"
    },
    {
      "name": "N0458",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0459",
      "description": "Nephrotic syndrome at diffuse mesangiocapillary glomerulonephritis  unspecified"
    },
    {
      "name": "N046",
      "description": "Nephrotic syndrome at dense deposit disease"
    },
    {
      "name": "N0460",
      "description": "Nephrotic syndrome at dense deposit disease  IgM staining"
    },
    {
      "name": "N0461",
      "description": "Nephrotic syndrome at dense deposit disease  IgA staining"
    },
    {
      "name": "N0462",
      "description": "Nephrotic syndrome at dense deposit disease  IgG staining"
    },
    {
      "name": "N0468",
      "description": "Nephrotic syndrome at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0469",
      "description": "Nephrotic syndrome at dense deposit disease unspecified"
    },
    {
      "name": "N047",
      "description": "Nephrotic syndrome at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0470",
      "description": "Nephrotic syndrome at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0471",
      "description": "Nephrotic syndrome at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0472",
      "description": "Nephrotic syndrome at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0478",
      "description": "Nephrotic syndrome at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0479",
      "description": "Nephrotic syndrome at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N048",
      "description": "Nephrotic syndrome at other"
    },
    {
      "name": "N0480",
      "description": "Nephrotic syndrome at other IgM staining"
    },
    {
      "name": "N0481",
      "description": "Nephrotic syndrome at other IgA staining"
    },
    {
      "name": "N0482",
      "description": "Nephrotic syndrome at other IgG staining"
    },
    {
      "name": "N0488",
      "description": "Nephrotic syndrome at other multiple immunoglobulin staining"
    },
    {
      "name": "N0489",
      "description": "Nephrotic syndrome at other unspecified"
    },
    {
      "name": "N049",
      "description": "Nephrotic syndrome at unspecified"
    },
    {
      "name": "N0490",
      "description": "Nephrotic syndrome at unspecified IgM staining"
    },
    {
      "name": "N0491",
      "description": "Nephrotic syndrome at unspecified IgA staining"
    },
    {
      "name": "N0492",
      "description": "Nephrotic syndrome at unspecified  IgG staining"
    },
    {
      "name": "N0498",
      "description": "Nephrotic syndrome at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0499",
      "description": "Nephrotic syndrome at unspecified unspecified"
    },
    {
      "name": "N050",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality"
    },
    {
      "name": "N0500",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality  IgM staining"
    },
    {
      "name": "N0501",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality IgA staining"
    },
    {
      "name": "N0502",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0508",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0509",
      "description": "Unspecified nephritic syndrome at minor glomerular abnormality unspecified"
    },
    {
      "name": "N051",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0510",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions  IgM staining"
    },
    {
      "name": "N0511",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0512",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0518",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0519",
      "description": "Unspecified nephritic syndrome at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N052",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0520",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0521",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0522",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0528",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0529",
      "description": "Unspecified nephritic syndrome at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N053",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0530",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0531",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0532",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0538",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0539",
      "description": "Unspecified nephritic syndrome at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N054",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0540",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0541",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0542",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0548",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0549",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N055",
      "description": "Unspecified nephritic syndrome at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0550",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis  IgM staining"
    },
    {
      "name": "N0551",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0552",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0558",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0559",
      "description": "Unspecified nephritic syndrome at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N056",
      "description": "Unspecified nephritic syndrome at dense deposit disease"
    },
    {
      "name": "N0560",
      "description": "Unspecified nephritic syndrome at dense deposit disease IgM staining"
    },
    {
      "name": "N0561",
      "description": "Unspecified nephritic syndrome at dense deposit disease IgA staining"
    },
    {
      "name": "N0562",
      "description": "Unspecified nephritic syndrome at dense deposit disease IgG staining"
    },
    {
      "name": "N0568",
      "description": "Unspecified nephritic syndrome at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0569",
      "description": "Unspecified nephritic syndrome at dense deposit disease unspecified"
    },
    {
      "name": "N057",
      "description": "Unspecified nephritic syndrome at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0570",
      "description": "Unspecified nephritic syndrome at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0571",
      "description": "Unspecified nephritic syndrome at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0572",
      "description": "Unspecified nephritic syndrome at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0578",
      "description": "Unspecified nephritic syndrome at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0579",
      "description": "Unspecified nephritic syndrome at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N058",
      "description": "Unspecified nephritic syndrome at other"
    },
    {
      "name": "N0580",
      "description": "Unspecified nephritic syndrome at other IgM staining"
    },
    {
      "name": "N0581",
      "description": "Unspecified nephritic syndrome at other IgA staining"
    },
    {
      "name": "N0582",
      "description": "Unspecified nephritic syndrome at other IgG staining"
    },
    {
      "name": "N0588",
      "description": "Unspecified nephritic syndrome at other multiple immunoglobulin staining"
    },
    {
      "name": "N0589",
      "description": "Unspecified nephritic syndrome at other unspecified"
    },
    {
      "name": "N059",
      "description": "Unspecified nephritic syndrome at unspecified"
    },
    {
      "name": "N0590",
      "description": "Unspecified nephritic syndrome at unspecified IgM staining"
    },
    {
      "name": "N0591",
      "description": "Unspecified nephritic syndrome at unspecified IgA staining"
    },
    {
      "name": "N0592",
      "description": "Unspecified nephritic syndrome at unspecified IgG staining"
    },
    {
      "name": "N0598",
      "description": "Unspecified nephritic syndrome at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0599",
      "description": "Unspecified nephritic syndrome at unspecified unspecified"
    },
    {
      "name": "N060",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality"
    },
    {
      "name": "N0600",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality IgM staining"
    },
    {
      "name": "N0601",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality IgA staining"
    },
    {
      "name": "N0602",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0608",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0609",
      "description": "Isolate proteinuria with specified morphological lesion at minor glomerular abnormality  unspecified"
    },
    {
      "name": "N061",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0610",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0611",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0612",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0618",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0619",
      "description": "Isolate proteinuria with specified morphological lesion at focal andf segmental glomerular lesions  unspecified"
    },
    {
      "name": "N062",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0620",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis  IgM staining"
    },
    {
      "name": "N0621",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0622",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0628",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0629",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N063",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0630",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis  IgM staining"
    },
    {
      "name": "N0631",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0632",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0638",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0639",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N064",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0640",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0641",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0642",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0648",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0649",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N065",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0650",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis IgM staining"
    },
    {
      "name": "N0651",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0652",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0658",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0659",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse mesangiocapillary glomerulonephritis unspecified"
    },
    {
      "name": "N066",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease"
    },
    {
      "name": "N0660",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease  IgM staining"
    },
    {
      "name": "N0661",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease IgA staining"
    },
    {
      "name": "N0662",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease IgG staining"
    },
    {
      "name": "N0668",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0669",
      "description": "Isolate proteinuria with specified morphological lesion at dense deposit disease unspecified"
    },
    {
      "name": "N067",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0670",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic glomerulonephritis  IgM staining"
    },
    {
      "name": "N0671",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0672",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0678",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0679",
      "description": "Isolate proteinuria with specified morphological lesion at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N068",
      "description": "Isolate proteinuria with specified morphological lesion at other"
    },
    {
      "name": "N0680",
      "description": "Isolate proteinuria with specified morphological lesion at other  IgM staining"
    },
    {
      "name": "N0681",
      "description": "Isolate proteinuria with specified morphological lesion at other IgA staining"
    },
    {
      "name": "N0682",
      "description": "Isolate proteinuria with specified morphological lesion at other IgG staining"
    },
    {
      "name": "N0688",
      "description": "Isolate proteinuria with specified morphological lesion at other multiple immunoglobulin staining"
    },
    {
      "name": "N0689",
      "description": "Isolate proteinuria with specified morphological lesion at other unspecified"
    },
    {
      "name": "N069",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified"
    },
    {
      "name": "N0690",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified IgM staining"
    },
    {
      "name": "N0691",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified IgA staining"
    },
    {
      "name": "N0692",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified IgG staining"
    },
    {
      "name": "N0698",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0699",
      "description": "Isolate proteinuria with specified morphological lesion at unspecified unspecified"
    },
    {
      "name": "N070",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality"
    },
    {
      "name": "N0700",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality IgM staining"
    },
    {
      "name": "N0701",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality IgA staining"
    },
    {
      "name": "N0702",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality IgG staining"
    },
    {
      "name": "N0708",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality multiple immunoglobulin staining"
    },
    {
      "name": "N0709",
      "description": "Hereditary nephropathy, not elsewhere classified at minor glomerular abnormality unspecified"
    },
    {
      "name": "N071",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions"
    },
    {
      "name": "N0710",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions IgM staining"
    },
    {
      "name": "N0711",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions IgA staining"
    },
    {
      "name": "N0712",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions IgG staining"
    },
    {
      "name": "N0718",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions multiple immunoglobulin staining"
    },
    {
      "name": "N0719",
      "description": "Hereditary nephropathy, not elsewhere classified at focal andf segmental glomerular lesions unspecified"
    },
    {
      "name": "N072",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis"
    },
    {
      "name": "N0720",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis IgM staining"
    },
    {
      "name": "N0721",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis IgA staining"
    },
    {
      "name": "N0722",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis IgG staining"
    },
    {
      "name": "N0728",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0729",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse membranous glomerulonephritis unspecified"
    },
    {
      "name": "N073",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis"
    },
    {
      "name": "N0730",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0731",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0732",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0738",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0739",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangial proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N074",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary  proliferative glomerulonephritis"
    },
    {
      "name": "N0740",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary proliferative glomerulonephritis IgM staining"
    },
    {
      "name": "N0741",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary proliferative glomerulonephritis IgA staining"
    },
    {
      "name": "N0742",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary proliferative glomerulonephritis IgG staining"
    },
    {
      "name": "N0748",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary proliferative glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0749",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse endocapillary proliferative glomerulonephritis unspecified"
    },
    {
      "name": "N075",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis"
    },
    {
      "name": "N0750",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis IgM staining"
    },
    {
      "name": "N0751",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis IgA staining"
    },
    {
      "name": "N0752",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis IgG staining"
    },
    {
      "name": "N0758",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0759",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse mesangiocapillary glomerulonephritis unspecified"
    },
    {
      "name": "N076",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease"
    },
    {
      "name": "N0760",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease IgM staining"
    },
    {
      "name": "N0761",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease IgA staining"
    },
    {
      "name": "N0762",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease IgG staining"
    },
    {
      "name": "N0768",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease multiple immunoglobulin staining"
    },
    {
      "name": "N0769",
      "description": "Hereditary nephropathy, not elsewhere classified at dense deposit disease unspecified"
    },
    {
      "name": "N077",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic  glomerulonephritis"
    },
    {
      "name": "N0770",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic glomerulonephritis IgM staining"
    },
    {
      "name": "N0771",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic glomerulonephritis IgA staining"
    },
    {
      "name": "N0772",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic glomerulonephritis IgG staining"
    },
    {
      "name": "N0778",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic glomerulonephritis multiple immunoglobulin staining"
    },
    {
      "name": "N0779",
      "description": "Hereditary nephropathy, not elsewhere classified at diffuse crescentic glomerulonephritis unspecified"
    },
    {
      "name": "N078",
      "description": "Hereditary nephropathy, not elsewhere classified at other"
    },
    {
      "name": "N0780",
      "description": "Hereditary nephropathy, not elsewhere classified at other IgM staining"
    },
    {
      "name": "N0781",
      "description": "Hereditary nephropathy, not elsewhere classified at other IgA staining"
    },
    {
      "name": "N0782",
      "description": "Hereditary nephropathy, not elsewhere classified at other IgG staining"
    },
    {
      "name": "N0788",
      "description": "Hereditary nephropathy, not elsewhere classified at other multiple immunoglobulin staining"
    },
    {
      "name": "N0789",
      "description": "Hereditary nephropathy, not elsewhere classified at other unspecified"
    },
    {
      "name": "N079",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified"
    },
    {
      "name": "N0790",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified IgM staining"
    },
    {
      "name": "N0791",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified IgA staining"
    },
    {
      "name": "N0792",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified IgG staining"
    },
    {
      "name": "N0798",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified multiple immunoglobulin staining"
    },
    {
      "name": "N0799",
      "description": "Hereditary nephropathy, not elsewhere classified at unspecified unspecified"
    },
    {
      "name": "N080",
      "description": "Glomerular disorders in infectious and parastic diseases classified elsewhere"
    },
    {
      "name": "N081",
      "description": "Glomerular disorders in neoplastic diseases"
    },
    {
      "name": "N082",
      "description": "Glomerular disorders in blood diseases and disorders involving the immune mechanism"
    },
    {
      "name": "N083",
      "description": "Glomerular disorders in diabetes mellitus ( E10-E14 with common fourth character .2 )"
    },
    {
      "name": "N084",
      "description": "Glomerular disorders in other endocrine, nutritional and metabolic diseases"
    },
    {
      "name": "N085",
      "description": "Glomerular disorders in systemic connective tissue disorders"
    },
    {
      "name": "N088",
      "description": "Glomerular disorders in other diseases classified elsewhere"
    },
    {
      "name": "N10",
      "description": "Acute tubulo-interstitial nephritis"
    },
    {
      "name": "N110",
      "description": "Nonobstructive reflux-associated chronic pyelonephritis"
    },
    {
      "name": "N111",
      "description": "Chronic obstructive pyelonephritis"
    },
    {
      "name": "N118",
      "description": "Other chronic tubulo-interstitial nephritis"
    },
    {
      "name": "N119",
      "description": "Chronic tubulo-interstitial nephritis, unspecified"
    },
    {
      "name": "N12",
      "description": "Tubulo-interstitial nephritis, not specified as acute or chronic"
    },
    {
      "name": "N130",
      "description": "Hydronephrosis with ureteropelvic junction obstruction"
    },
    {
      "name": "N131",
      "description": "Hydronephrosis with ureteral stricture, not elsewhere classified"
    },
    {
      "name": "N132",
      "description": "Hydronephrosis with renal and ureteral calculous obstruction"
    },
    {
      "name": "N133",
      "description": "Other and unspecified hyfronephrosis"
    },
    {
      "name": "N134",
      "description": "Hydroureter"
    },
    {
      "name": "N135",
      "description": "Kinking and stricture of ureter without hydronephrosis"
    },
    {
      "name": "N136",
      "description": "Pyonephrosis"
    },
    {
      "name": "N137",
      "description": "Vesicoureteral-reflux-associated urepathy"
    },
    {
      "name": "N138",
      "description": "Other obstructive and reflux urepathy"
    },
    {
      "name": "N139",
      "description": "Obstructive and reflux urepathy, unspecified"
    },
    {
      "name": "N140",
      "description": "Analgesic nephropathy"
    },
    {
      "name": "N141",
      "description": "Nephropathy induced by other drugs, medicaments and biological substances"
    },
    {
      "name": "N142",
      "description": "Nephropathy induced by unspecified drug, medicaments or biological substance"
    },
    {
      "name": "N143",
      "description": "Nephropathy induced by heavy metals"
    },
    {
      "name": "N144",
      "description": "Toxic nephropathy, not elsewhere classified"
    },
    {
      "name": "N150",
      "description": "Balkan nephropathy"
    },
    {
      "name": "N151",
      "description": "Renal and perinephric abscess"
    },
    {
      "name": "N158",
      "description": "Other specified renal tubulo-interstitial diseases"
    },
    {
      "name": "N159",
      "description": "Renal tubulo-interstitial diseases, unspecified"
    },
    {
      "name": "N160",
      "description": "Renal tubulo-interstitial disorders in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "N161",
      "description": "Renal tubulo-interstitial disorders in neoplastic diseases"
    },
    {
      "name": "N162",
      "description": "Renal tubulo-interstitial disorders in blood diseases and disorders involving the immune mechanism"
    },
    {
      "name": "N163",
      "description": "Renal tubulo-interstitial disorders in metabolic diseases"
    },
    {
      "name": "N164",
      "description": "Renal tubulo-interstitial disorders in systemic connective tissue disorders"
    },
    {
      "name": "N165",
      "description": "Renal tubulo-interstitial disorders in transplant rejection ( T86 )"
    },
    {
      "name": "N168",
      "description": "Renal tubulo-interstitial disorders in other diseases classified elsewhere"
    },
    {
      "name": "N170",
      "description": "Acute renal failure with tubular necrosis"
    },
    {
      "name": "N171",
      "description": "Acute renal failure with acute cortical necrosis"
    },
    {
      "name": "N172",
      "description": "Acute renal failure with medullary necrosis"
    },
    {
      "name": "N178",
      "description": "Other acute renal failure"
    },
    {
      "name": "N179",
      "description": "Acute renal failure, unspecified"
    },
    {
      "name": "N181",
      "description": "Chronic kidney disease, stage 1"
    },
    {
      "name": "N182",
      "description": "Chronic kidney disease, stage 2"
    },
    {
      "name": "N183",
      "description": "Chronic kidney disease, stage 3"
    },
    {
      "name": "N184",
      "description": "Chronic kidney disease, stage 4"
    },
    {
      "name": "N185",
      "description": "Chronic kidney disease, stage 5"
    },
    {
      "name": "N189",
      "description": "Chronic kidney disease, unspecified"
    },
    {
      "name": "N19",
      "description": "Unspecified renal failure"
    },
    {
      "name": "N200",
      "description": "Calculus of kidney"
    },
    {
      "name": "N201",
      "description": "Calculus of ureter"
    },
    {
      "name": "N202",
      "description": "Calculus of kidney with calculus of ureter"
    },
    {
      "name": "N209",
      "description": "Urinary calculus, unspecified"
    },
    {
      "name": "N210",
      "description": "Calculus in bladder"
    },
    {
      "name": "N211",
      "description": "Calculus in urethra"
    },
    {
      "name": "N218",
      "description": "Other lower urinary tract calculus"
    },
    {
      "name": "N219",
      "description": "Calculus of lower urinary tract, unspecified"
    },
    {
      "name": "N220",
      "description": "Urinary calculus in schistosomiasis [ bilharziasis ]( B65.- )"
    },
    {
      "name": "N228",
      "description": "Calculus of urinary tract in other diseases classified elsewhere"
    },
    {
      "name": "N23",
      "description": "Unspecified renal colic"
    },
    {
      "name": "N250",
      "description": "Renal osteodystrophy"
    },
    {
      "name": "N251",
      "description": "Nephrogenic diabetes insipidus"
    },
    {
      "name": "N258",
      "description": "Other disorders resulting from impaired renal tubular function"
    },
    {
      "name": "N259",
      "description": "Disorder resulting from impaired renal tubular function, unspecified"
    },
    {
      "name": "N26",
      "description": "Unspecified contracted kidney"
    },
    {
      "name": "N270",
      "description": "Small kidney, unilateral"
    },
    {
      "name": "N271",
      "description": "Small kidney, bilateral"
    },
    {
      "name": "N279",
      "description": "Small kidney, unspecified"
    },
    {
      "name": "N280",
      "description": "Ischaemia and infarction of kidney"
    },
    {
      "name": "N281",
      "description": "Cyst of kidney"
    },
    {
      "name": "N288",
      "description": "Other specified disorders of kidney and ureter"
    },
    {
      "name": "N289",
      "description": "Disorder of kidney and ureter, unspecified"
    },
    {
      "name": "N290",
      "description": "Late syphilis of kidney ( A52.7 )"
    },
    {
      "name": "N291",
      "description": "Other disorders of kidney and ureter in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "N298",
      "description": "Other disorders of kidney and ureter in other diseases classified elsewhere"
    },
    {
      "name": "N300",
      "description": "Acute cystitis"
    },
    {
      "name": "N301",
      "description": "Interstitial cystitis ( chronic )"
    },
    {
      "name": "N302",
      "description": "Other chronic cystitis"
    },
    {
      "name": "N303",
      "description": "Trigonitis"
    },
    {
      "name": "N304",
      "description": "Irradiation cystitis"
    },
    {
      "name": "N308",
      "description": "Other cystitis"
    },
    {
      "name": "N309",
      "description": "Cystitis, unspecified"
    },
    {
      "name": "N310",
      "description": "Uninhibited neuropathic bladder, not elsewhere classified"
    },
    {
      "name": "N311",
      "description": "Reflex neuropathic bladder, not elsewhere classified"
    },
    {
      "name": "N312",
      "description": "Flaccid neuropathic bladder, not elsewhere classified"
    },
    {
      "name": "N318",
      "description": "Other neuromuscular dysfunction of bladder"
    },
    {
      "name": "N319",
      "description": "Neuromuscular dysfunction of bladder, unspecified"
    },
    {
      "name": "N320",
      "description": "Bladder-neck obstruction"
    },
    {
      "name": "N321",
      "description": "Vesicointestinal fistula"
    },
    {
      "name": "N322",
      "description": "Vesical fistula, not elsewhere classified"
    },
    {
      "name": "N323",
      "description": "Diverticulum of bladder"
    },
    {
      "name": "N324",
      "description": "Rupture of bladder, nontraumatic"
    },
    {
      "name": "N328",
      "description": "Other specified disorders of bladder"
    },
    {
      "name": "N329",
      "description": "Bladder disorder, unspecified"
    },
    {
      "name": "N330",
      "description": "Tuberculous cystitis ( A18.1 )"
    },
    {
      "name": "N338",
      "description": "Bladder disorders in other diseases classified elsewhere"
    },
    {
      "name": "N340",
      "description": "Urethral abscess"
    },
    {
      "name": "N341",
      "description": "Nonspecific urethritis"
    },
    {
      "name": "N342",
      "description": "Other urethritis"
    },
    {
      "name": "N343",
      "description": "Urethral syndrome, unspecified"
    },
    {
      "name": "N350",
      "description": "Post-traumatic urethral stricture"
    },
    {
      "name": "N351",
      "description": "Postinfictive urethral stricture, not elsewhere classified"
    },
    {
      "name": "N358",
      "description": "Other urethral stricture"
    },
    {
      "name": "N359",
      "description": "Urethral stricture, unspecified"
    },
    {
      "name": "N360",
      "description": "Urethral fistula"
    },
    {
      "name": "N361",
      "description": "Urethral diverticulum"
    },
    {
      "name": "N362",
      "description": "Urethral caruncle"
    },
    {
      "name": "N363",
      "description": "Prolapsed urethral mucosa"
    },
    {
      "name": "N368",
      "description": "Other specified disorders of urethra"
    },
    {
      "name": "N369",
      "description": "Urethral disorder, unspecified"
    },
    {
      "name": "N370",
      "description": "Urethral in diseases classified elsewhere"
    },
    {
      "name": "N378",
      "description": "Other urethral disorders in diseases classified elsewhere"
    },
    {
      "name": "N390",
      "description": "Urinary tract infection, site not specified"
    },
    {
      "name": "N391",
      "description": "Persistent proteinuria, unspecified"
    },
    {
      "name": "N392",
      "description": "Orthostatic proteinuria, unspecified"
    },
    {
      "name": "N393",
      "description": "Stress incontinence"
    },
    {
      "name": "N394",
      "description": "Other specified urinary incontinence"
    },
    {
      "name": "N398",
      "description": "Other specified disorders of urinary system"
    },
    {
      "name": "N399",
      "description": "Disorder of urinary system, unspecified"
    },
    {
      "name": "N40",
      "description": "Hyperplasia of prostate"
    },
    {
      "name": "N410",
      "description": "Acute prostatitis"
    },
    {
      "name": "N411",
      "description": "Chronic prostatitis"
    },
    {
      "name": "N412",
      "description": "Abscess of prostate"
    },
    {
      "name": "N413",
      "description": "Prostatocystitis"
    },
    {
      "name": "N418",
      "description": "Other inflammatory diseases of prostate"
    },
    {
      "name": "N419",
      "description": "Inflammatory disease of prostate, unspecified"
    },
    {
      "name": "N420",
      "description": "Calculus of prostate"
    },
    {
      "name": "N421",
      "description": "Congestion and haemorrhage of prostate"
    },
    {
      "name": "N422",
      "description": "Atrophy of prostate"
    },
    {
      "name": "N423",
      "description": "Dysplasia of prostate"
    },
    {
      "name": "N428",
      "description": "Other specified disorders of prostate"
    },
    {
      "name": "N429",
      "description": "Disorder of prostate,unspecified"
    },
    {
      "name": "N430",
      "description": "Encysted hydrocele"
    },
    {
      "name": "N431",
      "description": "Infected hydrocele"
    },
    {
      "name": "N432",
      "description": "Other hydrocele"
    },
    {
      "name": "N433",
      "description": "Hydrocele, unspecified"
    },
    {
      "name": "N434",
      "description": "Spermatocele"
    },
    {
      "name": "N44",
      "description": "Torsion of testis"
    },
    {
      "name": "N450",
      "description": "Orchitis, epididymitis and epididymo-orchitis with abscess"
    },
    {
      "name": "N459",
      "description": "Orchitis, epididymitis and epididymo-orchitis without  abscess"
    },
    {
      "name": "N46",
      "description": "Male infertility"
    },
    {
      "name": "N47",
      "description": "Redundant prepuce, phimosis and paraphimosis"
    },
    {
      "name": "N480",
      "description": "Leukoplakia of penis"
    },
    {
      "name": "N481",
      "description": "Balanoposthitis"
    },
    {
      "name": "N482",
      "description": "Other inflammatory disorders of penis"
    },
    {
      "name": "N483",
      "description": "Priapism"
    },
    {
      "name": "N484",
      "description": "Impotence of organic origin"
    },
    {
      "name": "N485",
      "description": "Ulcer of penis"
    },
    {
      "name": "N486",
      "description": "Balanitis xerotica obliterans"
    },
    {
      "name": "N488",
      "description": "Other specified disorders of penis"
    },
    {
      "name": "N489",
      "description": "Disorders of penis, unspecified"
    },
    {
      "name": "N490",
      "description": "Inflammatory disorders of seminal vesicle"
    },
    {
      "name": "N491",
      "description": "Inflammatory disorders of spermatic cord, tunica vaginalis and vas deferens"
    },
    {
      "name": "N492",
      "description": "Inflammatory disorders of scrotum"
    },
    {
      "name": "N498",
      "description": "Inflammatory disorders of other specified male genital organs"
    },
    {
      "name": "N499",
      "description": "Inflammatory disorders of unspecified male genital organ"
    },
    {
      "name": "N500",
      "description": "Atrophy of testis"
    },
    {
      "name": "N501",
      "description": "Vascular disorders of male genital organs"
    },
    {
      "name": "N508",
      "description": "Other specified disorders of male genital organs"
    },
    {
      "name": "N509",
      "description": "Disorder of male genital organs, unspecified"
    },
    {
      "name": "N510",
      "description": "Disorders of prostate in diseases classified elsewhere"
    },
    {
      "name": "N511",
      "description": "Disorders of testis and epididymis in diseases classified elsewhere"
    },
    {
      "name": "N512",
      "description": "Balanitis in diseases classified elsewhere"
    },
    {
      "name": "N518",
      "description": "Other disorders of male genital organs in diseases classified elsewhere"
    },
    {
      "name": "N600",
      "description": "Solitary cyst of breast"
    },
    {
      "name": "N601",
      "description": "Diffuse cystic mastopathy"
    },
    {
      "name": "N602",
      "description": "Fibroadenosis of breast"
    },
    {
      "name": "N603",
      "description": "Fibrosclerosis of breast"
    },
    {
      "name": "N604",
      "description": "Mammary duct ectasia"
    },
    {
      "name": "N608",
      "description": "Other benign mammary dysplasias"
    },
    {
      "name": "N609",
      "description": "Benign mammary dysplasia, unspecified"
    },
    {
      "name": "N61",
      "description": "Inflammatory disorders of breast"
    },
    {
      "name": "N62",
      "description": "Hypertrophy of breast"
    },
    {
      "name": "N63",
      "description": "Unspecified lump in breast"
    },
    {
      "name": "N640",
      "description": "Fissure and fistula of nipple"
    },
    {
      "name": "N641",
      "description": "Fat necrosis of breast"
    },
    {
      "name": "N642",
      "description": "Atrophy of breast"
    },
    {
      "name": "N643",
      "description": "Galactorrhoea not associated with childbirth"
    },
    {
      "name": "N644",
      "description": "Mastodynia"
    },
    {
      "name": "N645",
      "description": "Other signs and symptoms in breast"
    },
    {
      "name": "N648",
      "description": "Other specified disorders of breast"
    },
    {
      "name": "N649",
      "description": "Disorders of breast, unspecified"
    },
    {
      "name": "N700",
      "description": "Acute salpingitis and oophoritis"
    },
    {
      "name": "N701",
      "description": "Chronic salpingitis ans oophoritis"
    },
    {
      "name": "N702",
      "description": "Tubo-ovarian abscess"
    },
    {
      "name": "N709",
      "description": "Salpingitis and oophoritis, unspecified"
    },
    {
      "name": "N710",
      "description": "Acute inflammatory disease of uterus"
    },
    {
      "name": "N711",
      "description": "Chronic inflammatory disease of uterus"
    },
    {
      "name": "N719",
      "description": "Inflammatory disease of uterus, unspecified"
    },
    {
      "name": "N72",
      "description": "Inflammatory disease of cervix uteri"
    },
    {
      "name": "N730",
      "description": "Acute parametritis and pelvic cellulitis"
    },
    {
      "name": "N731",
      "description": "Chronic parametritis and pelvic cellulitis"
    },
    {
      "name": "N732",
      "description": "Unspecified parametritis and pelvic cellulitis"
    },
    {
      "name": "N733",
      "description": "Female acute pelvic peritonitis"
    },
    {
      "name": "N734",
      "description": "Female chronic pelvic peritonitis"
    },
    {
      "name": "N735",
      "description": "Female pelvic peritonitis, unspecified"
    },
    {
      "name": "N736",
      "description": "Female pelvic peritoneal adhesions"
    },
    {
      "name": "N738",
      "description": "Other specified female pelvic inflammatory diseases"
    },
    {
      "name": "N739",
      "description": "Female pelvic inflammatory disease, unspecified"
    },
    {
      "name": "N740",
      "description": "Tuberculous infection of cervix uteri ( A18.1 )"
    },
    {
      "name": "N741",
      "description": "Female tuberculous pelvic inflammatory disease ( A18.1 )"
    },
    {
      "name": "N742",
      "description": "Female syphilitic pelvic inflammatory disease ( A51.4, A52.7 )"
    },
    {
      "name": "N743",
      "description": "Female gonococcal pelvic inflammatory disease ( A54.2 )"
    },
    {
      "name": "N744",
      "description": "Female chlamydial pelvic inflammatory disease ( A56.1 )"
    },
    {
      "name": "N748",
      "description": "Female pelvic inflammatory disorders in other diseases classified elsewhere"
    },
    {
      "name": "N750",
      "description": "Cyst of Bartholin's gland"
    },
    {
      "name": "N751",
      "description": "Abscess of Bartholin's gland"
    },
    {
      "name": "N758",
      "description": "Other diseases of Bartholin's gland"
    },
    {
      "name": "N759",
      "description": "Disease of Bartholin's gland, unspecified"
    },
    {
      "name": "N760",
      "description": "Acute vaginitis"
    },
    {
      "name": "N761",
      "description": "Subacute and chronic vaginitis"
    },
    {
      "name": "N762",
      "description": "Acute vulvitis"
    },
    {
      "name": "N763",
      "description": "Subacute and chronic vulvitis"
    },
    {
      "name": "N764",
      "description": "Abscess of vulva"
    },
    {
      "name": "N765",
      "description": "Ulceration of vagina"
    },
    {
      "name": "N766",
      "description": "Ulceration of vulva"
    },
    {
      "name": "N768",
      "description": "Other specified inflammation of vagina and vulva"
    },
    {
      "name": "N770",
      "description": "Ulceration of vulva in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "N771",
      "description": "Vaginitis, vulvitis and vulvovaginitis in infectious and parasitic diseases classified elsewhere"
    },
    {
      "name": "N778",
      "description": "Vulvovaginal ulceration and inflammation in other diseases classified elsewhere"
    },
    {
      "name": "N800",
      "description": "Endometriosis of uterus"
    },
    {
      "name": "N801",
      "description": "Endometriosis of ovary"
    },
    {
      "name": "N802",
      "description": "Endometriosis of fallopian tube"
    },
    {
      "name": "N803",
      "description": "Endometriosis of pelvic peritoneum"
    },
    {
      "name": "N804",
      "description": "Endometriosis of rectovaginal septum and vagina"
    },
    {
      "name": "N805",
      "description": "Endometriosis of intestine"
    },
    {
      "name": "N806",
      "description": "Endometriosis in cutaneous scar"
    },
    {
      "name": "N807",
      "description": "Pelvic endometriosis"
    },
    {
      "name": "N808",
      "description": "Other endometriosis"
    },
    {
      "name": "N809",
      "description": "Endometriosis, unspecified"
    },
    {
      "name": "N810",
      "description": "Female urrethrocele"
    },
    {
      "name": "N811",
      "description": "Cystocele"
    },
    {
      "name": "N812",
      "description": "Incomplete uterovaginal prolapse"
    },
    {
      "name": "N813",
      "description": "Complete uterovaginal prolapse"
    },
    {
      "name": "N814",
      "description": "Uterovaginal prolapse, unspecified"
    },
    {
      "name": "N815",
      "description": "Vaginal enterocele"
    },
    {
      "name": "N816",
      "description": "Rectocele"
    },
    {
      "name": "N817",
      "description": "Cystorectocele"
    },
    {
      "name": "N818",
      "description": "Other female genital prolapse"
    },
    {
      "name": "N819",
      "description": "Female genital prolapse, unspecified"
    },
    {
      "name": "N820",
      "description": "Vesicovaginal fistula"
    },
    {
      "name": "N821",
      "description": "Other female urinary-genital tract fistulae"
    },
    {
      "name": "N822",
      "description": "Fistula of vagina to small intestine"
    },
    {
      "name": "N823",
      "description": "Fistula of vagina to large intestine"
    },
    {
      "name": "N824",
      "description": "Other female intestinal-genital tract fistulae"
    },
    {
      "name": "N825",
      "description": "Female genital tract-skin fistulae"
    },
    {
      "name": "N828",
      "description": "Other female genital tract fistulae"
    },
    {
      "name": "N829",
      "description": "Female genital tract fistulae, unspecified"
    },
    {
      "name": "N830",
      "description": "Follicular cyst of ovary"
    },
    {
      "name": "N831",
      "description": "Corpus luteum cyst"
    },
    {
      "name": "N832",
      "description": "Other and unspecified ovarian cysts"
    },
    {
      "name": "N833",
      "description": "Acquired atrophy of ovary and fallopian tube"
    },
    {
      "name": "N834",
      "description": "Prolapse and hernia of ovary and fallopian tube"
    },
    {
      "name": "N835",
      "description": "Torsion of ovary, ovarian pedicle and fallopian tube"
    },
    {
      "name": "N836",
      "description": "Haematosalpinx"
    },
    {
      "name": "N837",
      "description": "Haematoma of broad ligament"
    },
    {
      "name": "N838",
      "description": "Other noninflammatory disorders of ovary, fallopian tube and broad ligament"
    },
    {
      "name": "N839",
      "description": "Noninflammatory disorders of ovary, fallopian tube and broad ligament, unspecified"
    },
    {
      "name": "N840",
      "description": "Polyp of corpus uteri"
    },
    {
      "name": "N841",
      "description": "Polyp of cervix uteri"
    },
    {
      "name": "N842",
      "description": "Polyp of vagina"
    },
    {
      "name": "N843",
      "description": "Polyp of vulva"
    },
    {
      "name": "N844",
      "description": "Polyp of other parts of female genital tract"
    },
    {
      "name": "N849",
      "description": "Polyp of female genital tract, unspecified"
    },
    {
      "name": "N850",
      "description": "Endometrial glandular hyperplasia"
    },
    {
      "name": "N851",
      "description": "Endometrial adenomatous hyperplasia"
    },
    {
      "name": "N852",
      "description": "Hypertrophy of uterus"
    },
    {
      "name": "N853",
      "description": "Subinvolution of uterus"
    },
    {
      "name": "N854",
      "description": "Malposition of uterus"
    },
    {
      "name": "N855",
      "description": "Inversion of uterus"
    },
    {
      "name": "N856",
      "description": "Intrauterine synechiae"
    },
    {
      "name": "N857",
      "description": "Haematometra"
    },
    {
      "name": "N858",
      "description": "Other specified noninflammatory disorders of uterus"
    },
    {
      "name": "N859",
      "description": "Noninflammatory disorders of uterus, unspecified"
    },
    {
      "name": "N86",
      "description": "Erosion and ectropion of cervix uteri"
    },
    {
      "name": "N870",
      "description": "Mild cervical dysplasia"
    },
    {
      "name": "N871",
      "description": "Moderate cervical dysplasia"
    },
    {
      "name": "N872",
      "description": "Severe cervical dysplasia, not elsewhere classified"
    },
    {
      "name": "N873",
      "description": "High-grade squamous intraepithelial lesion [HSIL], so stated"
    },
    {
      "name": "N879",
      "description": "Dysplasia of cervix uteri, unspecified"
    },
    {
      "name": "N880",
      "description": "Leukoplakia of cervix uteri"
    },
    {
      "name": "N881",
      "description": "Old laceration of cervix uteri"
    },
    {
      "name": "N882",
      "description": "Stricture and stenosis of cervix uteri"
    },
    {
      "name": "N883",
      "description": "Incompetence of cervix uteri"
    },
    {
      "name": "N884",
      "description": "Hypertrophic elongation of cervix uteri"
    },
    {
      "name": "N888",
      "description": "Other specified noninflammatory disorders of cervix uteri"
    },
    {
      "name": "N889",
      "description": "Noninflammatory disorders of cervix uteri, unspecified"
    },
    {
      "name": "N890",
      "description": "Mild vaginal dysplasia"
    },
    {
      "name": "N891",
      "description": "Moderate vaginal dysplasia"
    },
    {
      "name": "N892",
      "description": "Severe vaginal dysplasia, not elsewhere classified"
    },
    {
      "name": "N893",
      "description": "Dysplasia of vagina, unspecified"
    },
    {
      "name": "N894",
      "description": "Leukoplakia of vagina"
    },
    {
      "name": "N895",
      "description": "Stricture and atresia of vagina"
    },
    {
      "name": "N896",
      "description": "Tight hymenal ring"
    },
    {
      "name": "N897",
      "description": "Haematocolpos"
    },
    {
      "name": "N898",
      "description": "Other specified noninflammatory disorders of vagina"
    },
    {
      "name": "N899",
      "description": "Noninflammatory disorders of vagina, unspecified"
    },
    {
      "name": "N900",
      "description": "Mild vulva dysplasia"
    },
    {
      "name": "N901",
      "description": "Moderate vulva dysplasia"
    },
    {
      "name": "N902",
      "description": "Severe vulva dysplasia, not elsewhere classified"
    },
    {
      "name": "N903",
      "description": "Dysplasia of vulva, unspecified"
    },
    {
      "name": "N904",
      "description": "Leukoplakia of vulva"
    },
    {
      "name": "N905",
      "description": "Atrophy of vulva"
    },
    {
      "name": "N906",
      "description": "Hypertrophy of vulva"
    },
    {
      "name": "N907",
      "description": "Vulva cyst"
    },
    {
      "name": "N908",
      "description": "Other specified noninflammatory disorders of vulva and perineum"
    },
    {
      "name": "N909",
      "description": "Noninflammatory disorder of vulva and perineum, unspecified"
    },
    {
      "name": "N910",
      "description": "Primary amenorrhoea"
    },
    {
      "name": "N911",
      "description": "Secondary amenorrhoea"
    },
    {
      "name": "N912",
      "description": "Amenorrhoea, unspecified"
    },
    {
      "name": "N913",
      "description": "Primary oligomenorrhoea"
    },
    {
      "name": "N914",
      "description": "Secondary oligomenorrhoea"
    },
    {
      "name": "N915",
      "description": "Oligomenorrhoea, unspecified"
    },
    {
      "name": "N920",
      "description": "Excessive and frequent menstruation with regular cycle"
    },
    {
      "name": "N921",
      "description": "Excessive and frequent menstruation with irregular cycle"
    },
    {
      "name": "N922",
      "description": "Excessive menstruation at puberty"
    },
    {
      "name": "N923",
      "description": "Ovulation bleeding"
    },
    {
      "name": "N924",
      "description": "Excessive bleeding in the premenopausal period"
    },
    {
      "name": "N925",
      "description": "Other specified irregular menstruation"
    },
    {
      "name": "N926",
      "description": "Irregular menstruation, unspecified"
    },
    {
      "name": "N9300",
      "description": "Contact bleeding"
    },
    {
      "name": "N9301",
      "description": "Postcoital bleeding"
    },
    {
      "name": "N938",
      "description": "Other specified abnormal uterine and vaginal bleeding"
    },
    {
      "name": "N939",
      "description": "Abnormal uterine and vaginal bleeding, unspecified"
    },
    {
      "name": "N940",
      "description": "Mittelschmerz"
    },
    {
      "name": "N941",
      "description": "Dyspareunia"
    },
    {
      "name": "N942",
      "description": "Vaginismus"
    },
    {
      "name": "N943",
      "description": "Premenstrual tension syndrome"
    },
    {
      "name": "N944",
      "description": "Primary dysmenorrhoea"
    },
    {
      "name": "N945",
      "description": "Secondary dysmenorrhoea"
    },
    {
      "name": "N946",
      "description": "Dysmenorrhoea, unspecified"
    },
    {
      "name": "N948",
      "description": "Other specified conditions associated with female genital organs and menstrual cycle"
    },
    {
      "name": "N949",
      "description": "Unspecified condition associated with female genital organs and menstrual cycle"
    },
    {
      "name": "N950",
      "description": "Postmenopausal bleeding"
    },
    {
      "name": "N951",
      "description": "Menopausal and female climacteric states"
    },
    {
      "name": "N952",
      "description": "Postmenopausal atrophic vaginitis"
    },
    {
      "name": "N953",
      "description": "States associated with artificial menopause"
    },
    {
      "name": "N958",
      "description": "Other specified menopausal and perimenopausal disorders"
    },
    {
      "name": "N959",
      "description": "Menopausal and perimenopausal disorders, unspecified"
    },
    {
      "name": "N96",
      "description": "Habitual aborter"
    },
    {
      "name": "N970",
      "description": "Female infertility associated with anovulation"
    },
    {
      "name": "N971",
      "description": "Female infertility of tubal origin"
    },
    {
      "name": "N972",
      "description": "Female infertility of uterine origin"
    },
    {
      "name": "N973",
      "description": "Female infertility of cervical origin"
    },
    {
      "name": "N974",
      "description": "Female infertility associated with male factors"
    },
    {
      "name": "N975",
      "description": "Female infertility associated with peritoneal factors"
    },
    {
      "name": "N978",
      "description": "Female infertility of other origin"
    },
    {
      "name": "N979",
      "description": "Female infertility, unspecified"
    },
    {
      "name": "N980",
      "description": "Infection associated with artificial insemination"
    },
    {
      "name": "N981",
      "description": "Hyperstimulation of ovaries"
    },
    {
      "name": "N982",
      "description": "Complications of attempted introduction of fertilized ovum following in vitro fertilization"
    },
    {
      "name": "N983",
      "description": "Complications of attempted introduction of embryo in embryo transfer"
    },
    {
      "name": "N988",
      "description": "Other complications associated with artificial fertilization"
    },
    {
      "name": "N989",
      "description": "Complications associated with artificial fertilization, unspecified"
    },
    {
      "name": "N990",
      "description": "Postprocedural renal failure"
    },
    {
      "name": "N991",
      "description": "Postprocedural urethal stricture"
    },
    {
      "name": "N992",
      "description": "Postoperative adhesions of vagina"
    },
    {
      "name": "N993",
      "description": "Prolapse of vaginal vault after hysterectomy"
    },
    {
      "name": "N994",
      "description": "Postprocedural pelvic peritoneal adhesions"
    },
    {
      "name": "N995",
      "description": "Malfunction of external stoma of urinary tract"
    },
    {
      "name": "N998",
      "description": "Other postprocedural disorders of genitourinary system"
    },
    {
      "name": "N999",
      "description": "Postprocedural disorder of genitourinary system, unspecified"
    },
    {
      "name": "O000",
      "description": "Abdominal pregnancy"
    },
    {
      "name": "O001",
      "description": "Tubal pregnancy"
    },
    {
      "name": "O002",
      "description": "Ovarian pregnancy"
    },
    {
      "name": "O008",
      "description": "Other ectopic pregnancy"
    },
    {
      "name": "O009",
      "description": "Ectopic pregnancy, unspecified"
    },
    {
      "name": "O010",
      "description": "Classical hydatidiform mole"
    },
    {
      "name": "O011",
      "description": "Incomplete and partial hydatidiform mole"
    },
    {
      "name": "O019",
      "description": "Hydatidiform mole, unspecified"
    },
    {
      "name": "O020",
      "description": "Blighted ovum and nonhydatidiform mole"
    },
    {
      "name": "O021",
      "description": "Missed abortion"
    },
    {
      "name": "O028",
      "description": "Other specified abnormal products of conception"
    },
    {
      "name": "O029",
      "description": "Abnormal products of conception, unspecified"
    },
    {
      "name": "O030",
      "description": "Spontaneous abortion Incomplete, complicated by genital tract and pelvic infection With conditions in O08.0"
    },
    {
      "name": "O031",
      "description": "Spontaneous abortion Incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O032",
      "description": "Spontaneous abortion Incomplete, complicated by embolism"
    },
    {
      "name": "O033",
      "description": "Spontaneous abortion Incomplete, with other and unspecified complications"
    },
    {
      "name": "O034",
      "description": "Spontaneous abortion Incomplete, without complication"
    },
    {
      "name": "O035",
      "description": "Spontaneous abortion Complete or unspecified, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O036",
      "description": "Spontaneous abortion Complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O037",
      "description": "Spontaneous abortion Complete or unspecified, complicated by embolism"
    },
    {
      "name": "O038",
      "description": "Spontaneous abortion Complete or unspecified, with other and unspecified complications"
    },
    {
      "name": "O039",
      "description": "Spontaneous abortion Complete or unspecified, without complication"
    },
    {
      "name": "O040",
      "description": "Medical abortion Incomplete, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O041",
      "description": "Medical abortion Incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O042",
      "description": "Medical abortion Incomplete, complicated by embolism"
    },
    {
      "name": "O043",
      "description": "Medical abortion Incomplete, with other and unspecified complications"
    },
    {
      "name": "O044",
      "description": "Medical abortion Incomplete, without complication"
    },
    {
      "name": "O045",
      "description": "Medical abortion Complete or unspecified, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O046",
      "description": "Medical abortion Complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O047",
      "description": "Medical abortion Complete or unspecified, complicated by embolism"
    },
    {
      "name": "O048",
      "description": "Medical abortion Complete or unspecified, with other and unspecified complications"
    },
    {
      "name": "O049",
      "description": "Medical abortion Complete or unspecified, without complication"
    },
    {
      "name": "O050",
      "description": "Other abortion Incomplete, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O051",
      "description": "Other abortion Incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O052",
      "description": "Other abortion Incomplete, complicated by embolism"
    },
    {
      "name": "O053",
      "description": "Other abortion Incomplete, with other and unspecified complications"
    },
    {
      "name": "O054",
      "description": "Other abortion Incomplete, without complication"
    },
    {
      "name": "O055",
      "description": "Other abortion Complete or unspecified, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O056",
      "description": "Other abortion Complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O057",
      "description": "Other abortion Complete or unspecified, complicated by embolism"
    },
    {
      "name": "O058",
      "description": "Other abortion Complete or unspecified, with other and unspecified complications"
    },
    {
      "name": "O059",
      "description": "Other abortion Complete or unspecified, without complication"
    },
    {
      "name": "O060",
      "description": "Unspecified abortion Incomplete, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O061",
      "description": "Unspecified abortion Incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O062",
      "description": "Unspecified abortion Incomplete, complicated by embolism"
    },
    {
      "name": "O063",
      "description": "Unspecified abortion Incomplete, with other and unspecified complications"
    },
    {
      "name": "O064",
      "description": "Unspecified abortion Incomplete, without complication"
    },
    {
      "name": "O065",
      "description": "Unspecified abortion Complete or unspecified, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O066",
      "description": "Unspecified abortion Complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O067",
      "description": "Unspecified abortion Complete or unspecified, complicated by embolism"
    },
    {
      "name": "O068",
      "description": "Unspecified abortion Complete or unspecified, with other and unspecified complications"
    },
    {
      "name": "O069",
      "description": "Unspecified abortion Complete or unspecified, without complication"
    },
    {
      "name": "O070",
      "description": "Failed medical abortion, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O071",
      "description": "Failed medical abortion, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O072",
      "description": "Failed medical abortion, complicated by embolism"
    },
    {
      "name": "O073",
      "description": "Failed medical abortion, with other and unspecified complications"
    },
    {
      "name": "O074",
      "description": "Failed medical abortion, without complication"
    },
    {
      "name": "O075",
      "description": "Other and unspecified failed attempted abortion, complicated by genital tract and pelvic infection"
    },
    {
      "name": "O076",
      "description": "Other and unspecified failed attempted abortion, complicated by delayed or excessive haemorrhage"
    },
    {
      "name": "O077",
      "description": "Other and unspecified failed attempted abortion, complicated by embolism"
    },
    {
      "name": "O078",
      "description": "Other and unspecified failed attempted abortion, with other and unspecified complications"
    },
    {
      "name": "O079",
      "description": "Other and unspecified failed attempted abortion, without complication"
    },
    {
      "name": "O080",
      "description": "Genital tract and pelvic infection following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O081",
      "description": "Delayed or excessive haemorrhage following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O082",
      "description": "Embolism following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O083",
      "description": "Shock following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O084",
      "description": "Renal failure following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O085",
      "description": "Metabolic disorders following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O086",
      "description": "Damage to pelvic organs and tissues following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O087",
      "description": "Other venous complications following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O088",
      "description": "Other complications following abortion and ectopic and molar pregnancy"
    },
    {
      "name": "O089",
      "description": "Complication following abortion and ectopic and molar pregnancy, unspecified"
    },
    {
      "name": "O100",
      "description": "Pre-existing essential hypertension complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O101",
      "description": "Pre-existing hypertensive heart disease complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O102",
      "description": "Pre-existing hypertensive renal disease complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O103",
      "description": "Pre-existing hypertensive heart and renal disease complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O104",
      "description": "Pre-existing secondary hypertension complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O109",
      "description": "Unspecified pre-existing hypertension complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O11",
      "description": "Pre-eclampsia superimposed on chronic hypertension"
    },
    {
      "name": "O120",
      "description": "Gestational oedema"
    },
    {
      "name": "O121",
      "description": "Gestational proteinuria"
    },
    {
      "name": "O122",
      "description": "Gestational oedema  with proteinuria"
    },
    {
      "name": "O13",
      "description": "Gestational [pregnancy-induced] hypertension"
    },
    {
      "name": "O140",
      "description": "Mild to moderate pre-eclampsia"
    },
    {
      "name": "O141",
      "description": "Severe pre-eclampsia"
    },
    {
      "name": "O142",
      "description": "HELLP syndrome"
    },
    {
      "name": "O149",
      "description": "Pre-eclampsia, unspecified"
    },
    {
      "name": "O150",
      "description": "Eclampsia in pregnancy"
    },
    {
      "name": "O151",
      "description": "Eclampsia in labour"
    },
    {
      "name": "O152",
      "description": "Eclampsia in the puerperium"
    },
    {
      "name": "O159",
      "description": "Eclampsia, unspecified as to time period"
    },
    {
      "name": "O16",
      "description": "Unspecified maternal hypertension"
    },
    {
      "name": "O200",
      "description": "Threatened abortion"
    },
    {
      "name": "O208",
      "description": "Other haemorrhage in early pregnancy"
    },
    {
      "name": "O209",
      "description": "Haemorrhage in early pregnancy, unspecified"
    },
    {
      "name": "O210",
      "description": "Mild hyperemesis gravidarum"
    },
    {
      "name": "O211",
      "description": "Hyperemesis gravidarum with metabolic disturbance"
    },
    {
      "name": "O212",
      "description": "Late vomiting of pregnancy"
    },
    {
      "name": "O218",
      "description": "Other vomiting complicating pregnancy"
    },
    {
      "name": "O219",
      "description": "Vomiting of pregnancy, unspecified"
    },
    {
      "name": "O220",
      "description": "Varicose veins of lower extremity in pregnancy"
    },
    {
      "name": "O221",
      "description": "Genital varices in pregnancy"
    },
    {
      "name": "O222",
      "description": "Superficial thrombophlebitis in pregnancy"
    },
    {
      "name": "O223",
      "description": "Deep phlebothrombosis in pregnancy"
    },
    {
      "name": "O224",
      "description": "Haemorrhoids in pregnancy"
    },
    {
      "name": "O225",
      "description": "Cerebral venous thrombosis in pregnancy"
    },
    {
      "name": "O228",
      "description": "Other venous complications in pregnancy"
    },
    {
      "name": "O229",
      "description": "Venous complication in pregnancy, unspecified"
    },
    {
      "name": "O230",
      "description": "Infections of kidney in pregnancy"
    },
    {
      "name": "O231",
      "description": "Infections of bladder in pregnancy"
    },
    {
      "name": "O232",
      "description": "Infections of urethra in pregnancy"
    },
    {
      "name": "O233",
      "description": "Infections of other parts of urinary tract in pregnancy"
    },
    {
      "name": "O234",
      "description": "Unspecified infections of urinary tract in pregnancy"
    },
    {
      "name": "O235",
      "description": "Infections of the genital tract in pregnancy"
    },
    {
      "name": "O239",
      "description": "Other and unspecified genitourinary tract infection in pregnancy"
    },
    {
      "name": "O240",
      "description": "Pre-existing type 1 diabetes mellitus"
    },
    {
      "name": "O241",
      "description": "Pre-existing type 2 diabetes mellitus"
    },
    {
      "name": "O242",
      "description": "Pre-existing malnutrition-related diabetes mellitus"
    },
    {
      "name": "O243",
      "description": "Pre-existing diabetes mellitus, unspecified"
    },
    {
      "name": "O244",
      "description": "Diabetes mellitus arising in pregnancy"
    },
    {
      "name": "O249",
      "description": "Diabetes mellitus in pregnancy, unspecified"
    },
    {
      "name": "O25",
      "description": "Malnutrition in pregnancy"
    },
    {
      "name": "O260",
      "description": "Excessive weight gain in pregnancy"
    },
    {
      "name": "O261",
      "description": "Low weight gain in pregnancy"
    },
    {
      "name": "O262",
      "description": "Pregnancy care of habitual aborter"
    },
    {
      "name": "O263",
      "description": "Retained intrauterine contraceptive device in pregnancy"
    },
    {
      "name": "O264",
      "description": "Herpes gestationis"
    },
    {
      "name": "O265",
      "description": "Maternal hypotension syndrome"
    },
    {
      "name": "O266",
      "description": "Liver disorders in pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O267",
      "description": "Subluxation of symphysis ( pubis ) in pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O268",
      "description": "Other specified pregnancy-related conditions"
    },
    {
      "name": "O269",
      "description": "Pregnancy-related condition, unspecified"
    },
    {
      "name": "O280",
      "description": "Abnormal haematological finding on antenatal screening of mother"
    },
    {
      "name": "O281",
      "description": "Abnormal biochemical finding on antenatal screening of mother"
    },
    {
      "name": "O282",
      "description": "Abnormal cytological finding on antenatal screening of mother"
    },
    {
      "name": "O283",
      "description": "Abnormal ultrasonic finding on antenatal screening of mother"
    },
    {
      "name": "O284",
      "description": "Abnormal radiological finding on antenatal screening of mother"
    },
    {
      "name": "O285",
      "description": "Abnormal chromosomal and genetic finding on antenatal screening of mother"
    },
    {
      "name": "O288",
      "description": "Other abnormal findings on antenatal screening of mother"
    },
    {
      "name": "O289",
      "description": "Abnormal finding on antenatal screening of mother,unspecified"
    },
    {
      "name": "O290",
      "description": "Pulmonary complications of anaesthesia during pregnancy"
    },
    {
      "name": "O291",
      "description": "Cardiac complications of anaesthesia during pregnancy"
    },
    {
      "name": "O292",
      "description": "Central nervous system complications of anaesthesia during pregnancy"
    },
    {
      "name": "O293",
      "description": "Toxic reaction to local anaesthesia during pregnancy"
    },
    {
      "name": "O294",
      "description": "Spinal and epidural anaesthesia-induced headache during pregnancy"
    },
    {
      "name": "O295",
      "description": "Other complications of spinal and epidural anaesthesia during pregnancy"
    },
    {
      "name": "O296",
      "description": "Failed or difficult intubation during pregnancy"
    },
    {
      "name": "O298",
      "description": "Other complications of anaesthesia during pregnancy"
    },
    {
      "name": "O299",
      "description": "Complication of anaesthesia during pregnancy, unspecified"
    },
    {
      "name": "O300",
      "description": "Twin pregnancy"
    },
    {
      "name": "O301",
      "description": "Triplet pregnancy"
    },
    {
      "name": "O302",
      "description": "Quadruplet pregnancy"
    },
    {
      "name": "O308",
      "description": "Other multiple gestation"
    },
    {
      "name": "O309",
      "description": "Multiple gestation, unspecified"
    },
    {
      "name": "O310",
      "description": "Papyraceous fetus"
    },
    {
      "name": "O311",
      "description": "Continuing pregnancy after abortion of one fetus or more"
    },
    {
      "name": "O312",
      "description": "Continuing pregnancy after intrauterine death of one fetus or more"
    },
    {
      "name": "O318",
      "description": "Other complications specific to multiple gestation"
    },
    {
      "name": "O320",
      "description": "Maternal care for unstable lie"
    },
    {
      "name": "O321",
      "description": "Maternal care for breech presentation"
    },
    {
      "name": "O322",
      "description": "Maternal care for transverse and oblique lie"
    },
    {
      "name": "O323",
      "description": "Maternal care for face, brow and chin presentation"
    },
    {
      "name": "O324",
      "description": "Maternal care for high head at term"
    },
    {
      "name": "O325",
      "description": "Maternal care for multiple gestation with malpresentation of one fetus or more"
    },
    {
      "name": "O326",
      "description": "Maternal care for compound presentation"
    },
    {
      "name": "O328",
      "description": "Maternal care for other malpresentation of fetus"
    },
    {
      "name": "O329",
      "description": "Maternal care for malpresentation of fetus, unspecified"
    },
    {
      "name": "O330",
      "description": "Maternal care for disproportion due to deformity of maternal pelvic bones"
    },
    {
      "name": "O331",
      "description": "Maternal care for disproportion due to generally contracted pelvis"
    },
    {
      "name": "O332",
      "description": "Maternal care for disproportion due to inlet contraction of pelvis"
    },
    {
      "name": "O333",
      "description": "Maternal care for disproportion due to outlet contraction of pelvis"
    },
    {
      "name": "O334",
      "description": "Maternal care for disproportion of mixed maternal and fetal origin"
    },
    {
      "name": "O335",
      "description": "Maternal care for disproportion due to unusually large fetus"
    },
    {
      "name": "O336",
      "description": "Maternal care for disproportion due to hydrocephalic fetus"
    },
    {
      "name": "O337",
      "description": "Maternal care for disproportion due to other fetal deformities"
    },
    {
      "name": "O338",
      "description": "Maternal care for disproportion of other origin"
    },
    {
      "name": "O339",
      "description": "Maternal care for disproportion, unspecified"
    },
    {
      "name": "O340",
      "description": "Maternal care for congenital malformation of uterus"
    },
    {
      "name": "O341",
      "description": "Maternal care for tumour of corpus uteri"
    },
    {
      "name": "O342",
      "description": "Maternal care due to uterine scar from previous surgery"
    },
    {
      "name": "O343",
      "description": "Maternal care for cervical incompetence"
    },
    {
      "name": "O344",
      "description": "Maternal care for other abnormalities of cervix"
    },
    {
      "name": "O345",
      "description": "Maternal care for other abnormalities of gravid uterus"
    },
    {
      "name": "O346",
      "description": "Maternal care for abnormality of vagina"
    },
    {
      "name": "O347",
      "description": "Maternal care for abnormality of vulva and perineum"
    },
    {
      "name": "O348",
      "description": "Maternal care for other abnormalities of pelvic organs"
    },
    {
      "name": "O349",
      "description": "Maternal care for abnormality of pelvic organ, unspecified"
    },
    {
      "name": "O350",
      "description": "Maternal care for ( suspected ) central nervous system malformation in fetus"
    },
    {
      "name": "O351",
      "description": "Maternal care for ( suspected ) chromosomal abnormality in fetus"
    },
    {
      "name": "O352",
      "description": "Maternal care for ( suspected ) hereditary disease in fetus"
    },
    {
      "name": "O353",
      "description": "Maternal care for ( suspected ) damage to fetus from viral disease in mother"
    },
    {
      "name": "O354",
      "description": "Maternal care for ( suspected ) damage to fetus from alcohol"
    },
    {
      "name": "O355",
      "description": "Maternal care for ( suspected ) damage to fetus by drugs"
    },
    {
      "name": "O356",
      "description": "Maternal care for ( suspected ) damage to fetus by radiation"
    },
    {
      "name": "O357",
      "description": "Maternal care for ( suspected ) damage to fetus by other medical procedures"
    },
    {
      "name": "O358",
      "description": "Maternal care for other ( suspected ) fetal abnormality and damage"
    },
    {
      "name": "O359",
      "description": "Maternal care for ( suspected ) fetal abnormality and damage, unspecified"
    },
    {
      "name": "O360",
      "description": "Maternal care for rhesus isoimmunization"
    },
    {
      "name": "O361",
      "description": "Maternal care for other isoimmunization"
    },
    {
      "name": "O362",
      "description": "Maternal care for hydrops fetalis"
    },
    {
      "name": "O363",
      "description": "Maternal care for signs of fetal hypoxia"
    },
    {
      "name": "O364",
      "description": "Maternal care for intrauterine death"
    },
    {
      "name": "O365",
      "description": "Maternal care for poor fetal growth"
    },
    {
      "name": "O366",
      "description": "Maternal care for excessive fetal growth"
    },
    {
      "name": "O367",
      "description": "Maternal care for viable fetus in abdominal pregnancy"
    },
    {
      "name": "O368",
      "description": "Maternal care for other specified fetal problems"
    },
    {
      "name": "O369",
      "description": "Maternal care for fetal problem, unspecified"
    },
    {
      "name": "O40",
      "description": "Polyhydramnios"
    },
    {
      "name": "O410",
      "description": "Oligohydramnios"
    },
    {
      "name": "O411",
      "description": "Infection of amniotic sac and membranes"
    },
    {
      "name": "O418",
      "description": "Other specified disorders of amniotic fluid and membranes"
    },
    {
      "name": "O419",
      "description": "Disorder of amniotic fluid and membranes,unspecified"
    },
    {
      "name": "O420",
      "description": "Premature rupture of membranes, onset of labour within 24 hours"
    },
    {
      "name": "O421",
      "description": "Premature rupture of membranes, onset of labour after 24 hours"
    },
    {
      "name": "O422",
      "description": "Premature rupture of membranes, labour delayed by therapy"
    },
    {
      "name": "O429",
      "description": "Premature rupture of membranes, unspecified"
    },
    {
      "name": "O430",
      "description": "Placental transfusion syndromes"
    },
    {
      "name": "O431",
      "description": "Malformation of placenta"
    },
    {
      "name": "O438",
      "description": "Other placental disorders"
    },
    {
      "name": "O439",
      "description": "Placental disorder, unspecified"
    },
    {
      "name": "O440",
      "description": "Placenta praevia specified as without haemorrhage"
    },
    {
      "name": "O441",
      "description": "Placenta praevia with haemorrhage"
    },
    {
      "name": "O450",
      "description": "Premature separation of placenta with coagulation defect"
    },
    {
      "name": "O458",
      "description": "Other premature separation of placenta"
    },
    {
      "name": "O459",
      "description": "Premature separation of placenta, unspecified"
    },
    {
      "name": "O460",
      "description": "Antepartum haemorrhage with coagulation defect"
    },
    {
      "name": "O468",
      "description": "Other antepartum haemorrhage"
    },
    {
      "name": "O469",
      "description": "Antepartum haemorrhage, unspecified"
    },
    {
      "name": "O470",
      "description": "False labour before 37 completed weeks of gestation"
    },
    {
      "name": "O471",
      "description": "False labour at or after 37 completed weeks of gestation"
    },
    {
      "name": "O479",
      "description": "False labour, unspecified"
    },
    {
      "name": "O48",
      "description": "Prolonged pregnancy"
    },
    {
      "name": "O600",
      "description": "Preterm labour without delivery"
    },
    {
      "name": "O601",
      "description": "Preterm spontaneous labour with preterm delivery"
    },
    {
      "name": "O602",
      "description": "Preterm spontaneous labour with term delivery"
    },
    {
      "name": "O603",
      "description": "Preterm delivery without spontaneous labour"
    },
    {
      "name": "O610",
      "description": "Failed medical induction of labour"
    },
    {
      "name": "O611",
      "description": "Failed instrumental induction of labour"
    },
    {
      "name": "O618",
      "description": "Other failed induction of labour"
    },
    {
      "name": "O619",
      "description": "Failed induction of labour, unspecified"
    },
    {
      "name": "O620",
      "description": "Primary inadequate contractions"
    },
    {
      "name": "O621",
      "description": "Secondary uterine inertia"
    },
    {
      "name": "O622",
      "description": "Other uterine inertia"
    },
    {
      "name": "O623",
      "description": "Precipitate labour"
    },
    {
      "name": "O624",
      "description": "Hypertonic, incoordinate, and prolonged uterine contractions"
    },
    {
      "name": "O628",
      "description": "Other abnormalities of forces of labour"
    },
    {
      "name": "O629",
      "description": "Abnormality of forces of labour, unspecified"
    },
    {
      "name": "O630",
      "description": "Prolonged first stage ( of labour )"
    },
    {
      "name": "O631",
      "description": "Prolonged second stage ( of labour )"
    },
    {
      "name": "O632",
      "description": "Delayed delivery of second twin, triplet, etc."
    },
    {
      "name": "O639",
      "description": "Long labour, unspecified"
    },
    {
      "name": "O640",
      "description": "Obstructed labour due to incomplete rotation of fetal head"
    },
    {
      "name": "O641",
      "description": "Obstructed labour due to breech presentation"
    },
    {
      "name": "O642",
      "description": "Obstructed labour due to face presentation"
    },
    {
      "name": "O643",
      "description": "Obstructed labour due to brow presentation"
    },
    {
      "name": "O644",
      "description": "Obstructed labour due to shoulder presentation"
    },
    {
      "name": "O645",
      "description": "Obstructed labour due to compound presentation"
    },
    {
      "name": "O648",
      "description": "Obstructed labour due to other malposition and malpresentation"
    },
    {
      "name": "O649",
      "description": "Obstructed labour due to malposition and malpresentation, unspecified"
    },
    {
      "name": "O650",
      "description": "Obstructed labour due to deformed pelvis"
    },
    {
      "name": "O651",
      "description": "Obstructed labour due to generally contracted pelvis"
    },
    {
      "name": "O652",
      "description": "Obstructed labour due to pelvic inlet contraction"
    },
    {
      "name": "O653",
      "description": "Obstructed labour due to pelvic outlet and mid-cavity contraction"
    },
    {
      "name": "O654",
      "description": "Obstructed labour due to fetopelvic disproportion, unspecified"
    },
    {
      "name": "O655",
      "description": "Obstructed labour due to abnormality of maternal pelvic organs"
    },
    {
      "name": "O658",
      "description": "Obstructed labour due to other maternal pelvic abnormalities"
    },
    {
      "name": "O659",
      "description": "Obstructed labour due to maternal pelvic abnormality, unspecified"
    },
    {
      "name": "O660",
      "description": "Obstructed labour due to shoulder dystocia"
    },
    {
      "name": "O661",
      "description": "Obstructed labour due to locked twins"
    },
    {
      "name": "O662",
      "description": "Obstructed labour due to unusually large fetus"
    },
    {
      "name": "O663",
      "description": "Obstructed labour due to other abnormalities of fetus"
    },
    {
      "name": "O664",
      "description": "Failed trial of labour, unspecified"
    },
    {
      "name": "O665",
      "description": "Failed application of vacuum extractor and forceps, unspecified"
    },
    {
      "name": "O668",
      "description": "Other specified obstructed labour"
    },
    {
      "name": "O669",
      "description": "Obstructed labour, unspecified"
    },
    {
      "name": "O670",
      "description": "Intrapartum haemorrhage with coagulation defect"
    },
    {
      "name": "O678",
      "description": "Other intrapartum haemorrhage"
    },
    {
      "name": "O679",
      "description": "Intrapartum haemorrhage, unspecified"
    },
    {
      "name": "O680",
      "description": "Labour and delivery complicated by fetal heart rate anomaly"
    },
    {
      "name": "O681",
      "description": "Labour and delivery complicated by meconium in amniotic fluid"
    },
    {
      "name": "O682",
      "description": "Labour and delivery complicated by fetal heart rate anomaly with meconium in amniotic fluid"
    },
    {
      "name": "O683",
      "description": "Labour and delivery complicated by biochemical evidence of fetal stress"
    },
    {
      "name": "O688",
      "description": "Labour and delivery complicated by other evidence of fetal stress"
    },
    {
      "name": "O689",
      "description": "Labour and delivery complicated by fetal stress, unspecified"
    },
    {
      "name": "O690",
      "description": "Labour and delivery complicated by prolapse of cord"
    },
    {
      "name": "O691",
      "description": "Labour and delivery complicated by cord around neck, with compression"
    },
    {
      "name": "O692",
      "description": "Labour and delivery complicated by other cord entanglement"
    },
    {
      "name": "O693",
      "description": "Labour and delivery complicated by short cord"
    },
    {
      "name": "O694",
      "description": "Labour and delivery complicated by vasa praevia"
    },
    {
      "name": "O695",
      "description": "Labour and delivery complicated by vascular lesion of cord"
    },
    {
      "name": "O698",
      "description": "Labour and delivery complicated by other cord complications"
    },
    {
      "name": "O699",
      "description": "Labour and delivery complicated by cord complication, unspecified"
    },
    {
      "name": "O700",
      "description": "First degree perineal laceration during delivery"
    },
    {
      "name": "O701",
      "description": "Second degree perineal laceration during delivery"
    },
    {
      "name": "O702",
      "description": "Third degree perineal laceration during delivery"
    },
    {
      "name": "O703",
      "description": "Fourth degree perineal laceration during delivery"
    },
    {
      "name": "O709",
      "description": "Perineal laceration during delivery, unspecified"
    },
    {
      "name": "O710",
      "description": "Rupture of uterus before onset of labour"
    },
    {
      "name": "O711",
      "description": "Rupture of uterus during labour"
    },
    {
      "name": "O712",
      "description": "Postpartum inversion of uterus"
    },
    {
      "name": "O713",
      "description": "Obstetric laceration of cervix"
    },
    {
      "name": "O714",
      "description": "Obstetric high vaginal laceration"
    },
    {
      "name": "O715",
      "description": "Other obstetric injury to pelvic organs"
    },
    {
      "name": "O716",
      "description": "Obstetric damage to pelvic joints and ligaments"
    },
    {
      "name": "O717",
      "description": "Obstetric haematoma of pelvis"
    },
    {
      "name": "O718",
      "description": "Other specified obstetric trauma"
    },
    {
      "name": "O719",
      "description": "Obstetric trauma, unspecified"
    },
    {
      "name": "O720",
      "description": "Third-stage haemorrhage"
    },
    {
      "name": "O721",
      "description": "Other immediate postpartum haemorrhage"
    },
    {
      "name": "O722",
      "description": "Delayed and secondary postpartum haemorrhage"
    },
    {
      "name": "O723",
      "description": "Postpartum coagulation defects"
    },
    {
      "name": "O730",
      "description": "Retained placenta without haemorrhage"
    },
    {
      "name": "O731",
      "description": "Retained portions of placenta and membranes, without haemorrhage"
    },
    {
      "name": "O740",
      "description": "Aspiration pneumonitis due to anaesthesia during labour and delivery"
    },
    {
      "name": "O741",
      "description": "Other pulmonary complications of anaesthesia during labour and delivery"
    },
    {
      "name": "O742",
      "description": "Cardiac complications of anaesthesia during labour and delivery"
    },
    {
      "name": "O743",
      "description": "Central nervous system complications of anaesthesia during labour and delivery"
    },
    {
      "name": "O744",
      "description": "Toxic reaction to local anaesthesia during labour and delivery"
    },
    {
      "name": "O745",
      "description": "Spinal and epidural anaesthesia-induced headache during labour and delivery"
    },
    {
      "name": "O746",
      "description": "Other complications of spinal and epidural anaesthesia during labour and delivery"
    },
    {
      "name": "O747",
      "description": "Failed or difficult intubation during labour and delivery"
    },
    {
      "name": "O748",
      "description": "Other complications of anaesthesia during labour and delivery"
    },
    {
      "name": "O749",
      "description": "Complication of anaesthesia during labour and delivery, unspecified"
    },
    {
      "name": "O750",
      "description": "Maternal distress during labour and delivery"
    },
    {
      "name": "O751",
      "description": "Shock during or following labour and delivery"
    },
    {
      "name": "O752",
      "description": "Pyrexia during labour, not elsewhere classified"
    },
    {
      "name": "O753",
      "description": "Other infection during labour"
    },
    {
      "name": "O754",
      "description": "Other complications of obstetric surgery and procedures"
    },
    {
      "name": "O755",
      "description": "Delayed delivery after artificial rupture of membranes"
    },
    {
      "name": "O756",
      "description": "Delayed delivery after spontaneous or unspecified rupture of membranes"
    },
    {
      "name": "O757",
      "description": "Vaginal delivery following previous caesarean section"
    },
    {
      "name": "O758",
      "description": "Other specified complications of labour and delivery"
    },
    {
      "name": "O759",
      "description": "Complication of labour and delivery, unspecified"
    },
    {
      "name": "O800",
      "description": "Spontaneous vertex delivery"
    },
    {
      "name": "O801",
      "description": "Spontaneous breech delivery"
    },
    {
      "name": "O802",
      "description": "Other single spontaneous delivery"
    },
    {
      "name": "O809",
      "description": "Single spontaneous delivery, unspecified"
    },
    {
      "name": "O810",
      "description": "Low forceps delivery"
    },
    {
      "name": "O811",
      "description": "Mid-cavity forceps delivery"
    },
    {
      "name": "O812",
      "description": "Mid-cavity forceps with rotation"
    },
    {
      "name": "O813",
      "description": "Other and unspecified forceps delivery"
    },
    {
      "name": "O814",
      "description": "Vacuum extractor delivery"
    },
    {
      "name": "O815",
      "description": "Delivery by combination of forceps and vacuum extractor"
    },
    {
      "name": "O820",
      "description": "Delivery by elective caesarean section"
    },
    {
      "name": "O821",
      "description": "Delivery by emergency caesarean section"
    },
    {
      "name": "O822",
      "description": "Delivery by caesarean hysterectomy"
    },
    {
      "name": "O828",
      "description": "Other single delivery by caesarean section"
    },
    {
      "name": "O829",
      "description": "Delivery by caesarean section, unspecified"
    },
    {
      "name": "O830",
      "description": "Breech extraction"
    },
    {
      "name": "O831",
      "description": "Other assisted breech delivery"
    },
    {
      "name": "O832",
      "description": "Other manipulation-assisted delivery"
    },
    {
      "name": "O833",
      "description": "Delivery of viable fetus in abdominal pregnancy"
    },
    {
      "name": "O834",
      "description": "Destructive operation for delivery"
    },
    {
      "name": "O838",
      "description": "Other specified assisted single delivery"
    },
    {
      "name": "O839",
      "description": "Assisted single delivery, unspecified"
    },
    {
      "name": "O840",
      "description": "Multiple delivery, all spontaneous"
    },
    {
      "name": "O841",
      "description": "Multiple delivery, all by forceps and vacuum extractor"
    },
    {
      "name": "O842",
      "description": "Multiple delivery, all by caesarean section"
    },
    {
      "name": "O848",
      "description": "Other multiple delivery"
    },
    {
      "name": "O849",
      "description": "Multiple delivery, unspecified"
    },
    {
      "name": "O85",
      "description": "Puerperal sepsis"
    },
    {
      "name": "O860",
      "description": "Infection of obstetric surgical wound"
    },
    {
      "name": "O861",
      "description": "Other infection of genital tract following delivery"
    },
    {
      "name": "O862",
      "description": "Urinary tract infection following delivery"
    },
    {
      "name": "O863",
      "description": "Other genitourinary tract infections following delivery"
    },
    {
      "name": "O864",
      "description": "Pyrexia of unknown origin following delivery"
    },
    {
      "name": "O868",
      "description": "Other specified puerperal infections"
    },
    {
      "name": "O870",
      "description": "Superficial thrombophlebitis in the puerperium"
    },
    {
      "name": "O871",
      "description": "Deep phlebothrombosis in the puerperium"
    },
    {
      "name": "O872",
      "description": "Haemorrhoids in the puerperium"
    },
    {
      "name": "O873",
      "description": "Cerebral venous thrombosis in the puerperium"
    },
    {
      "name": "O878",
      "description": "Other venous complications in the puerperium"
    },
    {
      "name": "O879",
      "description": "Venous complication in the puerperium, unspecified"
    },
    {
      "name": "O880",
      "description": "Obstetric air embolism"
    },
    {
      "name": "O881",
      "description": "Amniotic fluid embolism"
    },
    {
      "name": "O882",
      "description": "Obstetric blood-clot embolism"
    },
    {
      "name": "O883",
      "description": "Obstetric pyaemic and septic embolism"
    },
    {
      "name": "O888",
      "description": "Other obstetric embolism"
    },
    {
      "name": "O890",
      "description": "Pulmonary complications of anaesthesia during the puerperium"
    },
    {
      "name": "O891",
      "description": "Cardiac complications of anaesthesia during the puerperium"
    },
    {
      "name": "O892",
      "description": "Central nervous system complications of anaesthesia during the puerperium"
    },
    {
      "name": "O893",
      "description": "Toxic reaction to local anaesthesia during the puerperium"
    },
    {
      "name": "O894",
      "description": "Spinal and epidural anaesthesia-induced headache during the puerperium"
    },
    {
      "name": "O895",
      "description": "Other complications of spinal and epidural anaesthesia during the puerperium"
    },
    {
      "name": "O896",
      "description": "Failed or difficult intubation during the puerperium"
    },
    {
      "name": "O898",
      "description": "Other complications of anaesthesia during the puerperium"
    },
    {
      "name": "O899",
      "description": "Complication of anaesthesia during the puerperium, unspecified"
    },
    {
      "name": "O900",
      "description": "Disruption of caesarean section wound"
    },
    {
      "name": "O901",
      "description": "Disruption of perineal obstetric wound"
    },
    {
      "name": "O902",
      "description": "Haematoma of obstetric wound"
    },
    {
      "name": "O903",
      "description": "Cardiomyopathy in the puerperium"
    },
    {
      "name": "O904",
      "description": "Postpartum acute renal failure"
    },
    {
      "name": "O905",
      "description": "Postpartum thyroiditis"
    },
    {
      "name": "O908",
      "description": "Other complications of the puerperium, not elsewhere classified"
    },
    {
      "name": "O909",
      "description": "Complication of the puerperium, unspecified"
    },
    {
      "name": "O910",
      "description": "Infection of nipple associated with childbirth"
    },
    {
      "name": "O911",
      "description": "Abscess of breast associated with childbirth"
    },
    {
      "name": "O912",
      "description": "Nonpurulent mastitis associated with childbirth"
    },
    {
      "name": "O920",
      "description": "Retracted nipple associated with childbirth"
    },
    {
      "name": "O921",
      "description": "Cracked nipple associated with childbirth"
    },
    {
      "name": "O922",
      "description": "Other and unspecified disorders of breast associated with childbirth"
    },
    {
      "name": "O923",
      "description": "Agalactia"
    },
    {
      "name": "O924",
      "description": "Hypogalactia"
    },
    {
      "name": "O925",
      "description": "Suppressed lactation"
    },
    {
      "name": "O926",
      "description": "Galactorrhoea"
    },
    {
      "name": "O927",
      "description": "Other and unspecified disorders of lactation"
    },
    {
      "name": "O94",
      "description": "Sequelae of cpmplication of pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O95",
      "description": "Obstetric death of unspecified cause"
    },
    {
      "name": "O960",
      "description": "Death from direct obstetric cause occurring more than 42 days but less than one year after delivery"
    },
    {
      "name": "O961",
      "description": "Death from indirect obstetric cause occurring more than 42 days but less than one year after delivery"
    },
    {
      "name": "O969",
      "description": "Death from unspecified obstetric cause occurring more than 42 days but less than one year after delivery"
    },
    {
      "name": "O97",
      "description": "Death from sequelae of obstetric causes"
    },
    {
      "name": "O970",
      "description": "Death from sequelae of direct obstetric cause"
    },
    {
      "name": "O971",
      "description": "Death from sequelae of indirect obstetric cause"
    },
    {
      "name": "O979",
      "description": "Death from sequelae of obstetric cause, unspecified"
    },
    {
      "name": "O980",
      "description": "Tuberculosis complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O981",
      "description": "Syphilis complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O982",
      "description": "Gonorrhoea complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O983",
      "description": "Other infections with a predominantly sexual mode of transmission complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O984",
      "description": "Viral hepatitis complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O985",
      "description": "Other viral diseases complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O986",
      "description": "Protozoal diseases complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O987",
      "description": "Human immunodeficiency virus [HIV] diseases complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O988",
      "description": "Other maternal infectious and parasitic diseases complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O989",
      "description": "Unspecified maternal infectious and parasitic disease complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O990",
      "description": "Anaemia complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O991",
      "description": "Other diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O992",
      "description": "Endocrine, nutritional and metabolic diseases complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O993",
      "description": "Mental disorders and diseases of the nervous system complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O994",
      "description": "Diseases of the circulatory system complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O995",
      "description": "Diseases of the respiratory system complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O996",
      "description": "Diseases of the digestive system complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O997",
      "description": "Diseases of the skin and subcutaneous tissue complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "O998",
      "description": "Other specified diseases and conditions complicating pregnancy, childbirth and the puerperium"
    },
    {
      "name": "P000",
      "description": "Fetus and newborn affected by maternal hypertensive disorders"
    },
    {
      "name": "P001",
      "description": "Fetus and newborn affected by maternal renal and urinary tract diseases"
    },
    {
      "name": "P002",
      "description": "Fetus and newborn affected by maternal infectious and parasitic diseases"
    },
    {
      "name": "P003",
      "description": "Fetus and newborn affected by other maternal circulatory and respiartory diseases"
    },
    {
      "name": "P004",
      "description": "Fetus and newborn affected by maternal nutritional disorders"
    },
    {
      "name": "P005",
      "description": "Fetus and newborn affected by maternal injury"
    },
    {
      "name": "P006",
      "description": "Fetus and newborn affected by surgical procedure on mother"
    },
    {
      "name": "P007",
      "description": "Fetus and newborn affected by other medical procedures on mother, not elsewhere classified"
    },
    {
      "name": "P008",
      "description": "Fetus and newborn affected by other maternal conditions"
    },
    {
      "name": "P009",
      "description": "Fetus and newborn affected by unspecified maternal condition"
    },
    {
      "name": "P010",
      "description": "Fetus and newborn affected by incompetent cervix"
    },
    {
      "name": "P011",
      "description": "Fetus and newborn affected by premature rupture of membranes"
    },
    {
      "name": "P012",
      "description": "Fetus and newborn affected by oligohydramnios"
    },
    {
      "name": "P013",
      "description": "Fetus and newborn affected by polyhydramnios"
    },
    {
      "name": "P014",
      "description": "Fetus and newborn affected by ectopic pregnancy"
    },
    {
      "name": "P015",
      "description": "Fetus and newborn affected by multiple pregnancy"
    },
    {
      "name": "P016",
      "description": "Fetus and newborn affected by maternal death"
    },
    {
      "name": "P017",
      "description": "Fetus and newborn affected by malpresentation before labour"
    },
    {
      "name": "P018",
      "description": "Fetus and newborn affected by other maternal complications of pregnancy"
    },
    {
      "name": "P019",
      "description": "Fetus and newborn affected by maternal complication of pregnancy, unspecified"
    },
    {
      "name": "P020",
      "description": "Fetus and newborn affected by placenta praevia"
    },
    {
      "name": "P021",
      "description": "Fetus and newborn affected by other forms of placental separation and haemorrhage"
    },
    {
      "name": "P022",
      "description": "Fetus and newborn affected by other and unspecified morphological and functional abnormalities of placenta"
    },
    {
      "name": "P023",
      "description": "Fetus and newborn affected by placental transfusion syndromes"
    },
    {
      "name": "P024",
      "description": "Fetus and newborn affected by prolapsed cord"
    },
    {
      "name": "P025",
      "description": "Fetus and newborn affected by other compression of umbilical cord"
    },
    {
      "name": "P026",
      "description": "Fetus and newborn affected by other and unspecified conditions of umbilical cord"
    },
    {
      "name": "P027",
      "description": "Fetus and newborn affected by chorioamnionitis"
    },
    {
      "name": "P028",
      "description": "Fetus and newborn affected by other abnormalities of membranes"
    },
    {
      "name": "P029",
      "description": "Fetus and newborn affected by abnormality of membranes, unspecified"
    },
    {
      "name": "P030",
      "description": "Fetus and newborn affected by breech delivery and extraction"
    },
    {
      "name": "P031",
      "description": "Fetus and newborn affected by other malpresentation, malposition and disproportion during labour and delivery"
    },
    {
      "name": "P032",
      "description": "Fetus and newborn affected by forceps delivery"
    },
    {
      "name": "P033",
      "description": "Fetus and newborn affected by delivery by vacuum extractor [ventouse]"
    },
    {
      "name": "P034",
      "description": "Fetus and newborn affected by caesarean delivery"
    },
    {
      "name": "P035",
      "description": "Fetus and newborn affected by precipitate delivery"
    },
    {
      "name": "P036",
      "description": "Fetus and newborn affected by abnormal uterine contractions"
    },
    {
      "name": "P038",
      "description": "Fetus and newborn affected by other specified complications of labour and delivery"
    },
    {
      "name": "P039",
      "description": "Fetus and newborn affected by complication of labour and delivery, unspecified"
    },
    {
      "name": "P040",
      "description": "Fetus and newborn affected by maternal anaesthesia and analgesia in pregnancy, labour and delivery"
    },
    {
      "name": "P041",
      "description": "Fetus and newborn affected by other maternal medication"
    },
    {
      "name": "P042",
      "description": "Fetus and newborn affected by maternal use of tobacco"
    },
    {
      "name": "P043",
      "description": "Fetus and newborn affected by maternal use of alcohol"
    },
    {
      "name": "P044",
      "description": "Fetus and newborn affected by maternal use of drugs of addiction"
    },
    {
      "name": "P045",
      "description": "Fetus and newborn affected by maternal use of nutritional chemical substances"
    },
    {
      "name": "P046",
      "description": "Fetus and newborn affected by maternal exposure to environmental chemical substances"
    },
    {
      "name": "P048",
      "description": "Fetus and newborn affected by other maternal noxious influences"
    },
    {
      "name": "P049",
      "description": "Fetus and newborn affected by maternal noxious influences, unspecified"
    },
    {
      "name": "P050",
      "description": "Light for gestational age"
    },
    {
      "name": "P051",
      "description": "Small for gestational age"
    },
    {
      "name": "P052",
      "description": "Fetal malnutrition without mention of light or small for gestational age"
    },
    {
      "name": "P059",
      "description": "Slow fetal growth, unspecified"
    },
    {
      "name": "P070",
      "description": "Extremely low birth weight"
    },
    {
      "name": "P071",
      "description": "Other low birth weight"
    },
    {
      "name": "P072",
      "description": "Extreme immaturity"
    },
    {
      "name": "P073",
      "description": "Other preterm infants"
    },
    {
      "name": "P080",
      "description": "Exceptionally large baby"
    },
    {
      "name": "P081",
      "description": "Other heavy for gestational age infants"
    },
    {
      "name": "P082",
      "description": "Post-term infant, not heavy for gestational age"
    },
    {
      "name": "P100",
      "description": "Subdural haemorrhage due to birth injury"
    },
    {
      "name": "P101",
      "description": "Cerebral haemorrhage due to birth injury"
    },
    {
      "name": "P102",
      "description": "Intraventricular haemorrhage due to birth injury"
    },
    {
      "name": "P103",
      "description": "Subarachnoid haemorrhage due to birth injury"
    },
    {
      "name": "P104",
      "description": "Tentorial tear due to birth injury"
    },
    {
      "name": "P108",
      "description": "Other intracranial lacerations and haemorrhage due to birth injury"
    },
    {
      "name": "P109",
      "description": "Unspecified intracranial laceration and haemorrhage due to birth injury"
    },
    {
      "name": "P110",
      "description": "Cerebral oedema due to birth injury"
    },
    {
      "name": "P111",
      "description": "Other specified brain damage due to birth injury"
    },
    {
      "name": "P112",
      "description": "Unspecified brain damage due to birth injury"
    },
    {
      "name": "P113",
      "description": "Birth injury to facial nerve"
    },
    {
      "name": "P114",
      "description": "Birth injury to other cranial nerves"
    },
    {
      "name": "P115",
      "description": "Birth injury to spine and spinal cord"
    },
    {
      "name": "P119",
      "description": "Birth injury to central nervous system, unspecified"
    },
    {
      "name": "P120",
      "description": "Cephalhaematoma due to birth injury"
    },
    {
      "name": "P121",
      "description": "Chignon due to birth injury"
    },
    {
      "name": "P122",
      "description": "Epicranial subaponeurotic haemorrhgae due to birth injury"
    },
    {
      "name": "P123",
      "description": "Bruising of scalp due to birth injury"
    },
    {
      "name": "P124",
      "description": "Monitoring injury of scalp of newborn"
    },
    {
      "name": "P128",
      "description": "Other birth injuries to scalp"
    },
    {
      "name": "P129",
      "description": "Birth injury to scalp, unspecified"
    },
    {
      "name": "P130",
      "description": "Fracture of skull due to birth injury"
    },
    {
      "name": "P131",
      "description": "Other birth injuries to skull"
    },
    {
      "name": "P132",
      "description": "Birth injury to femur"
    },
    {
      "name": "P133",
      "description": "Birth injury to other long bones"
    },
    {
      "name": "P134",
      "description": "Fracture of clavicle due to birth injury"
    },
    {
      "name": "P138",
      "description": "Birth injuries to other parts of skeleton"
    },
    {
      "name": "P139",
      "description": "Birth injury to skeleton, unspecified"
    },
    {
      "name": "P140",
      "description": "Erb’s paralysis due to birth injury"
    },
    {
      "name": "P141",
      "description": "Klumpke’s paralysis due to birth injury"
    },
    {
      "name": "P142",
      "description": "Phrenic nerve paralysis due to birth injury"
    },
    {
      "name": "P143",
      "description": "Other brachial plexus birth injuries"
    },
    {
      "name": "P148",
      "description": "Birth injuries to other parts of peripheral nervous system"
    },
    {
      "name": "P149",
      "description": "Birth injury to peripheral nervous system, unspecified"
    },
    {
      "name": "P150",
      "description": "Birth injury to liver"
    },
    {
      "name": "P151",
      "description": "Birth injury to spleen"
    },
    {
      "name": "P152",
      "description": "Sternomastoid injury due to birth injury"
    },
    {
      "name": "P153",
      "description": "Birth injury to eye"
    },
    {
      "name": "P154",
      "description": "Birth injury to face"
    },
    {
      "name": "P155",
      "description": "Birth injury to external genitalia"
    },
    {
      "name": "P156",
      "description": "Subcutaneous fat necrosis due to birth injury"
    },
    {
      "name": "P158",
      "description": "Other specified birth injuries"
    },
    {
      "name": "P159",
      "description": "Birth injury, unspecified"
    },
    {
      "name": "P200",
      "description": "Intrauterine hypoxia first noted before onset of labour"
    },
    {
      "name": "P201",
      "description": "Intrauterine hypoxia first noted during labour and delivery"
    },
    {
      "name": "P209",
      "description": "Intrauterine hypoxia, unspecified"
    },
    {
      "name": "P210",
      "description": "Severe birth asphyxia"
    },
    {
      "name": "P211",
      "description": "Mild and moderate birth asphyxia"
    },
    {
      "name": "P219",
      "description": "Birth asphyxia, unspecified"
    },
    {
      "name": "P220",
      "description": "Respiratory distress syndrome of newborn"
    },
    {
      "name": "P221",
      "description": "Transient tachypnoea of newborn"
    },
    {
      "name": "P228",
      "description": "Other respiratory distress of newborn"
    },
    {
      "name": "P229",
      "description": "Respiratory distress of newborn, unspecified"
    },
    {
      "name": "P230",
      "description": "Congenital pneumonia due to viral agent"
    },
    {
      "name": "P231",
      "description": "Congenital pneumonia due to Chlamydia"
    },
    {
      "name": "P232",
      "description": "Congenital pneumonia due to staphylococcus"
    },
    {
      "name": "P233",
      "description": "Congenital pneumonia due to streptococcus,group B"
    },
    {
      "name": "P234",
      "description": "Congenital pneumonia due to Escherichia coli"
    },
    {
      "name": "P235",
      "description": "Congenital pneumonia due to Pseudomonas"
    },
    {
      "name": "P236",
      "description": "Congenital pneumonia due to other bacterial agents"
    },
    {
      "name": "P238",
      "description": "Congenital pneumonia due to other organisms"
    },
    {
      "name": "P239",
      "description": "Congenital pneumonia, unspecified"
    },
    {
      "name": "P240",
      "description": "Neonatal aspiration of meconium"
    },
    {
      "name": "P241",
      "description": "Neonatal aspiration of amniotic fluid and mucus"
    },
    {
      "name": "P242",
      "description": "Neonatal aspiration of blood"
    },
    {
      "name": "P243",
      "description": "Neonatal aspiration of milk and regurgitated food"
    },
    {
      "name": "P248",
      "description": "Other neonatal aspiration syndromes"
    },
    {
      "name": "P249",
      "description": "Neonatal aspiration syndrome, unspecified"
    },
    {
      "name": "P250",
      "description": "Interstitial emphysema originating in the perinatal period"
    },
    {
      "name": "P251",
      "description": "Pneumothorax originating in the perinatal period"
    },
    {
      "name": "P252",
      "description": "Pneumomediastinum originating in the perinatal period"
    },
    {
      "name": "P253",
      "description": "Pneumopericardium originating in the perinatal period"
    },
    {
      "name": "P258",
      "description": "Other conditions related to interstitial emphysema originating in the perinatal period"
    },
    {
      "name": "P260",
      "description": "Tracheobronchial haemorrhage originating in the perinatal period"
    },
    {
      "name": "P261",
      "description": "Massive pulmonary haemorrhage originating in the perinatal period"
    },
    {
      "name": "P268",
      "description": "Other pulmonary haemorrhages originating in the perinatal period"
    },
    {
      "name": "P269",
      "description": "Unspecified pulmonary haemorrhage originating in the perinatal period"
    },
    {
      "name": "P270",
      "description": "Wilson-Mikity syndrome"
    },
    {
      "name": "P271",
      "description": "Bronchopulmonary dysplasia originating in the perinatal period"
    },
    {
      "name": "P278",
      "description": "Other chronic respiratory diseases originating in the perinatal period"
    },
    {
      "name": "P279",
      "description": "Unsepcified chronic respiratory disease originating in the perinatal period"
    },
    {
      "name": "P280",
      "description": "Primary atelectasis of newborn"
    },
    {
      "name": "P281",
      "description": "Other and unspecified atelectasis of newborn"
    },
    {
      "name": "P282",
      "description": "Cyanotic attacks of newborn"
    },
    {
      "name": "P283",
      "description": "Primary sleep apnoea of newborn"
    },
    {
      "name": "P284",
      "description": "Other apnoea of newborn"
    },
    {
      "name": "P285",
      "description": "Respiratory failure of newborn"
    },
    {
      "name": "P288",
      "description": "Other specified respiratory conditions of newborn"
    },
    {
      "name": "P289",
      "description": "Respiratory condition of newborn, unspecified"
    },
    {
      "name": "P290",
      "description": "Neonatal cardiac failure"
    },
    {
      "name": "P291",
      "description": "Neonatal cardiac dysrhythmia"
    },
    {
      "name": "P292",
      "description": "Neonatal hypertension"
    },
    {
      "name": "P293",
      "description": "Persistent fetal circulation"
    },
    {
      "name": "P294",
      "description": "Transient myocardial ischaemia of newborn"
    },
    {
      "name": "P298",
      "description": "Other cardiovascular disorders originating in the perinatal period"
    },
    {
      "name": "P299",
      "description": "Cardiovascular disorders originating in the perinatal period, unspecified"
    },
    {
      "name": "P350",
      "description": "Congenital rubella syndrome"
    },
    {
      "name": "P351",
      "description": "Congenital cytomegalovirus infection"
    },
    {
      "name": "P352",
      "description": "Congenital herpesviral [herpes simplex] infection"
    },
    {
      "name": "P353",
      "description": "Congenital viral hepatitis"
    },
    {
      "name": "P358",
      "description": "Other congenital viral diseases"
    },
    {
      "name": "P359",
      "description": "Congenital viral disease, unspecified"
    },
    {
      "name": "P360",
      "description": "Sepsis of newborn due to streptococcus, group B"
    },
    {
      "name": "P361",
      "description": "Sepsis of newborn due to other and unspecified streptococci"
    },
    {
      "name": "P362",
      "description": "Sepsis of newborn due to Staphylococcus aureus"
    },
    {
      "name": "P363",
      "description": "Sepsis of newborn due to other and unspecified streptococci"
    },
    {
      "name": "P364",
      "description": "Sepsis of newborn due to Escherichia coli"
    },
    {
      "name": "P365",
      "description": "Sepsis of newborn due to anaerobes"
    },
    {
      "name": "P368",
      "description": "Other bacterial sepsis of newborn"
    },
    {
      "name": "P369",
      "description": "Bacterial sepsis of newborn, unspecified"
    },
    {
      "name": "P370",
      "description": "Congenital tuberculosis"
    },
    {
      "name": "P371",
      "description": "Congenital toxoplasmosis"
    },
    {
      "name": "P372",
      "description": "Neonatal (disseminated) listeriosis"
    },
    {
      "name": "P373",
      "description": "Congenital falciparum malaria"
    },
    {
      "name": "P374",
      "description": "Other congenital malaria"
    },
    {
      "name": "P375",
      "description": "Neonatal candidiasis"
    },
    {
      "name": "P378",
      "description": "Other specified congenital infectious and parasitic diseases"
    },
    {
      "name": "P379",
      "description": "Congenital infectious and parasitic diseases,unspecified"
    },
    {
      "name": "P38",
      "description": "Omphalitis of newborn with or without mild haemorrhage"
    },
    {
      "name": "P390",
      "description": "Neonatal infective mastitis"
    },
    {
      "name": "P391",
      "description": "Neonatal conjunctivitis and dacryocystitis"
    },
    {
      "name": "P392",
      "description": "Intra-amniotic infection of fetus, not elsewhere classified"
    },
    {
      "name": "P393",
      "description": "Neonatal urinary tract infection"
    },
    {
      "name": "P394",
      "description": "Neonatal skin infection"
    },
    {
      "name": "P398",
      "description": "Other specified infections specific to the perinatal period"
    },
    {
      "name": "P399",
      "description": "Infection specific to the perinatal period,unspecified"
    },
    {
      "name": "P500",
      "description": "Fetal blood loss from vasa praevia"
    },
    {
      "name": "P501",
      "description": "Fetal blood loss from ruptured cord"
    },
    {
      "name": "P502",
      "description": "Fetal blood loss from placenta"
    },
    {
      "name": "P503",
      "description": "Haemorrhage into co-twin"
    },
    {
      "name": "P504",
      "description": "Haemorrhage into maternal circulation"
    },
    {
      "name": "P505",
      "description": "Fetal blood loss from cut end of co-twin’s cord"
    },
    {
      "name": "P508",
      "description": "Other fetal blood loss"
    },
    {
      "name": "P509",
      "description": "Fetal blood loss, unspecified"
    },
    {
      "name": "P510",
      "description": "Massive umbilical haemorrhage of newborn"
    },
    {
      "name": "P518",
      "description": "Other umbilical haemorrhage of newborn"
    },
    {
      "name": "P519",
      "description": "Umbilical haemorrhage of newborn, unspecified"
    },
    {
      "name": "P520",
      "description": "Intraventricular (nontraumatic) haemorrhage,grade 1, of fetus and newborn"
    },
    {
      "name": "P521",
      "description": "Intraventricular (nontraumatic) haemorrhage,grade 2, of fetus and newborn"
    },
    {
      "name": "P522",
      "description": "Intraventricular (nontraumatic) haemorrhage,grade 3 and grade 4, of fetus and newborn"
    },
    {
      "name": "P523",
      "description": "Unspecified intraventricular (nontraumatic) haemorrhage of fetus and newborn"
    },
    {
      "name": "P524",
      "description": "Intracerebral (nontraumatic) haemorrhage of fetus and newborn"
    },
    {
      "name": "P525",
      "description": "Subarachnoid (nontraumatic) haemorrhage of fetus and newborn"
    },
    {
      "name": "P526",
      "description": "Cerebellar (nontraumatic) and posterior fossa haemorrhage of fetus and newborn"
    },
    {
      "name": "P528",
      "description": "Other intracranial (nontraumatic) haemorrhage of fetus and newborn"
    },
    {
      "name": "P529",
      "description": "Intracranial (nontraumatic) haemorrhage of fetus and newborn, unspecified"
    },
    {
      "name": "P53",
      "description": "Haemorrhagic disease of fetus and newborn"
    },
    {
      "name": "P540",
      "description": "Neonatal haematemesis"
    },
    {
      "name": "P541",
      "description": "Neonatal melaena"
    },
    {
      "name": "P542",
      "description": "Neonatal rectal haemorrhage"
    },
    {
      "name": "P543",
      "description": "Other neonatal gastrointestinal haemorrghage"
    },
    {
      "name": "P544",
      "description": "Neonatal adrenal haemorrhage"
    },
    {
      "name": "P545",
      "description": "Neonatal cutaneous haemorrhage"
    },
    {
      "name": "P546",
      "description": "Neonatal vaginal haemorrhage"
    },
    {
      "name": "P548",
      "description": "Other specified neonatal haemorrhages"
    },
    {
      "name": "P549",
      "description": "Neonatal haemorrhage, unspecified"
    },
    {
      "name": "P550",
      "description": "Rh isoimmunization of fetus and newborn"
    },
    {
      "name": "P551",
      "description": "ABO isoimmunization of fetus and newborn"
    },
    {
      "name": "P558",
      "description": "Other haemolytic diseases of fetus and newborn"
    },
    {
      "name": "P559",
      "description": "Haemolytic diseases of fetus and newborn,unspecified"
    },
    {
      "name": "P560",
      "description": "Hydrops fetalis due to isoimmunization"
    },
    {
      "name": "P569",
      "description": "Hydrops fetalis due to other and unspecified haemolytic disease"
    },
    {
      "name": "P570",
      "description": "Kernicterus due to isoimmunization"
    },
    {
      "name": "P578",
      "description": "Other specified kernicterus"
    },
    {
      "name": "P579",
      "description": "Kernicterus, unspecified"
    },
    {
      "name": "P580",
      "description": "Neonatal jaundice due to bruising"
    },
    {
      "name": "P581",
      "description": "Neonatal jaundice due to bleeding"
    },
    {
      "name": "P582",
      "description": "Neonatal jaundice due to infection"
    },
    {
      "name": "P583",
      "description": "Neonatal jaundice due to polycythaemia"
    },
    {
      "name": "P584",
      "description": "Neonatal jaundice due to drugs or toxins transmitted from mother or given to newborn"
    },
    {
      "name": "P585",
      "description": "Neonatal jaundice due to swallowed maternal blood"
    },
    {
      "name": "P588",
      "description": "Neonatal jaundice due to other specified excessive haemolysis"
    },
    {
      "name": "P589",
      "description": "Neonatal jaundice due to excessive haemolysis,unspecified"
    },
    {
      "name": "P590",
      "description": "Neonatal jaundice associated with preterm delivery"
    },
    {
      "name": "P591",
      "description": "Inspissated bile syndrome"
    },
    {
      "name": "P592",
      "description": "Neonatal jaundice from other and unspecified hepatocellular damage"
    },
    {
      "name": "P593",
      "description": "Neonatal jaundice from breast milk inhibitor"
    },
    {
      "name": "P598",
      "description": "Neonatal jaundice from other specified causes"
    },
    {
      "name": "P599",
      "description": "Neonatal jaundice, unspecified"
    },
    {
      "name": "P60",
      "description": "Disseminated intravascular coagulation of fetus and newborn"
    },
    {
      "name": "P610",
      "description": "Transient neonatal thrombocytopenia"
    },
    {
      "name": "P611",
      "description": "Polycythaemia neonatorum"
    },
    {
      "name": "P612",
      "description": "Anaemia of prematurity"
    },
    {
      "name": "P613",
      "description": "Congenital anaemia from fetal blood loss"
    },
    {
      "name": "P614",
      "description": "Other congenital anaemias, not elsewhere classified"
    },
    {
      "name": "P615",
      "description": "Transient neonatal neutropenia"
    },
    {
      "name": "P616",
      "description": "Other transient neonatal disorders of coagulation"
    },
    {
      "name": "P618",
      "description": "Other specified perinatal haematological disorders"
    },
    {
      "name": "P619",
      "description": "Perinatal haematological disorders, unspecified"
    },
    {
      "name": "P700",
      "description": "Syndrome of infant of mother with gestational diabetes"
    },
    {
      "name": "P701",
      "description": "Syndrome of infant of a diabetic mother"
    },
    {
      "name": "P702",
      "description": "Neonatal diabetes mellitus"
    },
    {
      "name": "P703",
      "description": "Iatrogenic neonatal hypoglycaemia"
    },
    {
      "name": "P704",
      "description": "Other neonatal hypoglycaemia"
    },
    {
      "name": "P708",
      "description": "Other transitory disorders of carbohydrate metabolism of fetus and newborn"
    },
    {
      "name": "P709",
      "description": "Transitory disorder of carbohydrate metabolism of fetus and newborn, unspecified"
    },
    {
      "name": "P710",
      "description": "Cow’s milk hypocalcaemia in newborn"
    },
    {
      "name": "P711",
      "description": "Other neonatal hypocalcaemia"
    },
    {
      "name": "P712",
      "description": "Neonatal hypomagnesaemia"
    },
    {
      "name": "P713",
      "description": "Neonatal tetany without calcium or magnesium deficiency"
    },
    {
      "name": "P714",
      "description": "Transitory neonatal hypoparathyroidism"
    },
    {
      "name": "P718",
      "description": "Other transitory neonatal disorders of calcium and magnesium metabolism"
    },
    {
      "name": "P719",
      "description": "Transitory neonatal disorder of calcium and magnesium metabolism, unspecified"
    },
    {
      "name": "P720",
      "description": "Neonatal goitre, not elsewhere classified"
    },
    {
      "name": "P721",
      "description": "Transitory neonatal hyperthyroidism"
    },
    {
      "name": "P722",
      "description": "Other transitory neonatal disorders of thyroid function, not elsewhere classified"
    },
    {
      "name": "P728",
      "description": "Other specified transitory neonatal endocrine disorders"
    },
    {
      "name": "P729",
      "description": "Transitory neonatal andocrine disorder,unspecified"
    },
    {
      "name": "P740",
      "description": "Late metabolic acidosis of newborn"
    },
    {
      "name": "P741",
      "description": "Dehydration of newborn"
    },
    {
      "name": "P742",
      "description": "Disturbances of sodium balance of newborn"
    },
    {
      "name": "P743",
      "description": "Disturbances of potassium balance of newborn"
    },
    {
      "name": "P744",
      "description": "Other transitory electrolyte disturbances of newborn"
    },
    {
      "name": "P745",
      "description": "Transitory tyrosinaemia of newborn"
    },
    {
      "name": "P748",
      "description": "Other transitory metabolic disturbances of newborn"
    },
    {
      "name": "P749",
      "description": "Transitory metabolic disturbance of newborn,unspecified"
    },
    {
      "name": "P75",
      "description": "Meconium ileus in cystic fibrosis (E84.1)"
    },
    {
      "name": "P760",
      "description": "Meconium plug syndrome"
    },
    {
      "name": "P761",
      "description": "Transitory ileus of newborn"
    },
    {
      "name": "P762",
      "description": "Intestinal obstruction due to inspissated milk"
    },
    {
      "name": "P768",
      "description": "Other specified intestinal obstruction of newborn"
    },
    {
      "name": "P769",
      "description": "Intestinal obstruction of newborn, unspecified"
    },
    {
      "name": "P77",
      "description": "Necrotizing enterocolitis of fetus and newborn"
    },
    {
      "name": "P780",
      "description": "Perinatal intestinal perforation"
    },
    {
      "name": "P781",
      "description": "Other neonatal peritonitis"
    },
    {
      "name": "P782",
      "description": "Neonatal haematemesis and melaena due to swallowed maternal blood"
    },
    {
      "name": "P783",
      "description": "Noninfective neonatal diarrhoea"
    },
    {
      "name": "P788",
      "description": "Other specified perinatal digestive system disorders"
    },
    {
      "name": "P789",
      "description": "Perinatal digestive system disorders, unspecified"
    },
    {
      "name": "P800",
      "description": "Cold injury syndrome"
    },
    {
      "name": "P808",
      "description": "Other hypothermia of newborn"
    },
    {
      "name": "P809",
      "description": "Hypothermia of newborn, unspecified"
    },
    {
      "name": "P810",
      "description": "Environmental hyperthermia of newborn"
    },
    {
      "name": "P818",
      "description": "Other specified disturbances of temperature regulation of newborn"
    },
    {
      "name": "P819",
      "description": "Disturbance of temperature regulation of newborn, unspecified"
    },
    {
      "name": "P830",
      "description": "Sclerema neonatorum"
    },
    {
      "name": "P831",
      "description": "Neonatal erythema toxicum"
    },
    {
      "name": "P832",
      "description": "Hydrops fetalis not due to haemolytic disease"
    },
    {
      "name": "P833",
      "description": "Other and unspecified oedema specific to fetus and newborn"
    },
    {
      "name": "P834",
      "description": "Breast engorgement of newborn"
    },
    {
      "name": "P835",
      "description": "Congenital hydrocele"
    },
    {
      "name": "P836",
      "description": "Umbilical polyp of newborn"
    },
    {
      "name": "P838",
      "description": "Other specified conditions of integument specific to fetus and newborn"
    },
    {
      "name": "P839",
      "description": "Condition of integument specific to fetus and newborn, unspecified"
    },
    {
      "name": "P90",
      "description": "Convulsions of newborn"
    },
    {
      "name": "P910",
      "description": "Neonatal cerebral ischaemia"
    },
    {
      "name": "P911",
      "description": "Acquired periventricular cysts of newborn"
    },
    {
      "name": "P912",
      "description": "Neonatal cerebral leukomalacia"
    },
    {
      "name": "P913",
      "description": "Neonatal cerebral irritability"
    },
    {
      "name": "P914",
      "description": "Neonatal cerebral depression"
    },
    {
      "name": "P915",
      "description": "Neonatal coma"
    },
    {
      "name": "P916",
      "description": "Hypoxic ischaemic encephalopathy of newborn"
    },
    {
      "name": "P917",
      "description": "Acquired hydrocephalus of newborn"
    },
    {
      "name": "P918",
      "description": "Other specified disturbances of cerebral status of newborn"
    },
    {
      "name": "P919",
      "description": "Disturbance of cerebral status of newborn,unspecified"
    },
    {
      "name": "P920",
      "description": "Vomiting in newborn"
    },
    {
      "name": "P921",
      "description": "Regurgitation and rumination in newborn"
    },
    {
      "name": "P922",
      "description": "Slow feeding of newborn"
    },
    {
      "name": "P923",
      "description": "Underfeeding of newborn"
    },
    {
      "name": "P924",
      "description": "Overfeeding of newborn"
    },
    {
      "name": "P925",
      "description": "Neonatal difficulty in feeding at breast"
    },
    {
      "name": "P928",
      "description": "Other feeding problems of newborn"
    },
    {
      "name": "P929",
      "description": "Feeding problem of newborn, unspecified"
    },
    {
      "name": "P93",
      "description": "Reactions and intoxications due to drugs administered to fetus and newborn"
    },
    {
      "name": "P940",
      "description": "Transient neonatal myasthenia gravis"
    },
    {
      "name": "P941",
      "description": "Congenital hypertonia"
    },
    {
      "name": "P942",
      "description": "Congenital hypotonia"
    },
    {
      "name": "P948",
      "description": "Other disorders of muscle tone of newborn"
    },
    {
      "name": "P949",
      "description": "Disorder of muscle tone of newborn, unspecified"
    },
    {
      "name": "P95",
      "description": "Fetal death of unspecified cause"
    },
    {
      "name": "P960",
      "description": "Congenital renal failure"
    },
    {
      "name": "P961",
      "description": "Neonatal withdrawal syndrome from maternal use of drugs of addiction"
    },
    {
      "name": "P962",
      "description": "Withdrawal symptoms from therapeutic use of drugs in newborn"
    },
    {
      "name": "P963",
      "description": "Wide cranial sutures of newborn"
    },
    {
      "name": "P964",
      "description": "Termination of pregnancy, affecting fetus and newborn"
    },
    {
      "name": "P965",
      "description": "Complications of intrauterine procedures,not elsewhere classified"
    },
    {
      "name": "P968",
      "description": "Other specified conditions originating in the perinatal period"
    },
    {
      "name": "P969",
      "description": "Condition originating in the perinatal period,unspecified"
    },
    {
      "name": "Q000",
      "description": "Anencephaly"
    },
    {
      "name": "Q001",
      "description": "Craniorachischisis"
    },
    {
      "name": "Q002",
      "description": "Iniencephaly"
    },
    {
      "name": "Q010",
      "description": "Frontal encephalocele"
    },
    {
      "name": "Q011",
      "description": "Nasofrontal encephalocele"
    },
    {
      "name": "Q012",
      "description": "Occipital encephalocele"
    },
    {
      "name": "Q018",
      "description": "Encephalocele of other sites"
    },
    {
      "name": "Q019",
      "description": "Encephalocele, unspecified"
    },
    {
      "name": "Q02",
      "description": "Microcephaly"
    },
    {
      "name": "Q030",
      "description": "Malformations of aqueduct of Sylvius"
    },
    {
      "name": "Q031",
      "description": "Atresia of foramina of Magendie and Luschka"
    },
    {
      "name": "Q038",
      "description": "Other congenital hydrocephalus"
    },
    {
      "name": "Q039",
      "description": "Congenital hydrocephalus, unspecified"
    },
    {
      "name": "Q040",
      "description": "Congenital malformations of corpus callosum"
    },
    {
      "name": "Q041",
      "description": "Arhinencephaly"
    },
    {
      "name": "Q042",
      "description": "Holoprosencephaly"
    },
    {
      "name": "Q043",
      "description": "Other reduction deformities of brain"
    },
    {
      "name": "Q044",
      "description": "Septo-optic dysplasia"
    },
    {
      "name": "Q045",
      "description": "Megalencephaly"
    },
    {
      "name": "Q046",
      "description": "Congenital cerebral cysts"
    },
    {
      "name": "Q048",
      "description": "Other specified congenital malformations of brain"
    },
    {
      "name": "Q049",
      "description": "Congenital malformation of brain, unspecified"
    },
    {
      "name": "Q050",
      "description": "Cervical spina bifida with hydrocephalus"
    },
    {
      "name": "Q051",
      "description": "Thoracic spina bifida with hydrocephalus"
    },
    {
      "name": "Q052",
      "description": "Lumbar spina bifida with hydrocephalus"
    },
    {
      "name": "Q053",
      "description": "Sacral spina bifida with hydrocephalus"
    },
    {
      "name": "Q054",
      "description": "Unspecified spina bifida with hydrocephalus"
    },
    {
      "name": "Q055",
      "description": "Cervical spina bifida without hydrocephalus"
    },
    {
      "name": "Q056",
      "description": "Thoracic spina bifida without hydrocephalus"
    },
    {
      "name": "Q057",
      "description": "Lumbar spina bifida without hydrocephalus"
    },
    {
      "name": "Q058",
      "description": "Sacral spina bifida without hydrocephalus"
    },
    {
      "name": "Q059",
      "description": "Spina bifida, unspecified"
    },
    {
      "name": "Q060",
      "description": "Amyelia"
    },
    {
      "name": "Q061",
      "description": "Hypoplasia and dysplasia of spinal cord"
    },
    {
      "name": "Q062",
      "description": "Diastematomyelia"
    },
    {
      "name": "Q063",
      "description": "Other congenital cauda equina malformations"
    },
    {
      "name": "Q064",
      "description": "Hydromyelia"
    },
    {
      "name": "Q068",
      "description": "Other specified congenital malformations of spinal cord"
    },
    {
      "name": "Q069",
      "description": "Congenital malformation of spinal cord,unspecified"
    },
    {
      "name": "Q070",
      "description": "Arnold-Chiari syndrome"
    },
    {
      "name": "Q078",
      "description": "Other specified congenital malformations of nervous system"
    },
    {
      "name": "Q079",
      "description": "Congenital malformation of nervous system,unspecified"
    },
    {
      "name": "Q100",
      "description": "Congenital ptosis"
    },
    {
      "name": "Q101",
      "description": "Congenital ectropion"
    },
    {
      "name": "Q102",
      "description": "Congenital entropion"
    },
    {
      "name": "Q103",
      "description": "Other congenital malformations of eyelid"
    },
    {
      "name": "Q104",
      "description": "Absence and agenesis of lacrimal apparatus"
    },
    {
      "name": "Q105",
      "description": "Congenital stenosis and stricture of lacrimal duct"
    },
    {
      "name": "Q106",
      "description": "Other congenital malformations of lacrimal apparatus"
    },
    {
      "name": "Q107",
      "description": "Congenital malformation of orbit"
    },
    {
      "name": "Q110",
      "description": "Cystic eyeball"
    },
    {
      "name": "Q111",
      "description": "Other anophthalmos"
    },
    {
      "name": "Q112",
      "description": "Microphthalmos"
    },
    {
      "name": "Q113",
      "description": "Macrophthalmos"
    },
    {
      "name": "Q120",
      "description": "Congenital cataract"
    },
    {
      "name": "Q121",
      "description": "Congenital displaced lens"
    },
    {
      "name": "Q122",
      "description": "Coloboma of lens"
    },
    {
      "name": "Q123",
      "description": "Congenital aphakia"
    },
    {
      "name": "Q124",
      "description": "Spherophakia"
    },
    {
      "name": "Q128",
      "description": "Other congenital lens malformations"
    },
    {
      "name": "Q129",
      "description": "Congenital lens malformation, unspecified"
    },
    {
      "name": "Q130",
      "description": "Coloboma of iris"
    },
    {
      "name": "Q131",
      "description": "Absence of iris"
    },
    {
      "name": "Q132",
      "description": "Other congenital malformations of iris"
    },
    {
      "name": "Q133",
      "description": "Congenital corneal opacity"
    },
    {
      "name": "Q134",
      "description": "Other congenital corneal malformations"
    },
    {
      "name": "Q135",
      "description": "Blue sclera"
    },
    {
      "name": "Q138",
      "description": "Other congenital malformations of anterio segment of eye"
    },
    {
      "name": "Q139",
      "description": "Congenital malformation of anterior segment of eye, unspecified"
    },
    {
      "name": "Q140",
      "description": "Congenital malformation of vitreous humour"
    },
    {
      "name": "Q141",
      "description": "Congenital malformation of retina"
    },
    {
      "name": "Q142",
      "description": "Congenital malformation of optic disc"
    },
    {
      "name": "Q143",
      "description": "Congenital malformation of choroid"
    },
    {
      "name": "Q148",
      "description": "Other congenital malformations of posterior segment ot eye"
    },
    {
      "name": "Q149",
      "description": "Congenital malformation of posterior segment of eye, unspecified"
    },
    {
      "name": "Q150",
      "description": "Congenital glaucoma"
    },
    {
      "name": "Q158",
      "description": "Other specified congenital malformations of eye"
    },
    {
      "name": "Q159",
      "description": "Congenital malformation of eye, unspecified"
    },
    {
      "name": "Q160",
      "description": "Congenital absence of (ear) auricle"
    },
    {
      "name": "Q161",
      "description": "Congenital absence, atresia and stricture of auditory canal (external)"
    },
    {
      "name": "Q162",
      "description": "Absence of eustachian tube"
    },
    {
      "name": "Q163",
      "description": "Congenital malformation of ear ossicles"
    },
    {
      "name": "Q164",
      "description": "Other congenital malformations of middle ear"
    },
    {
      "name": "Q165",
      "description": "Congenital malformation of inner ear"
    },
    {
      "name": "Q169",
      "description": "Congenital malformation of ear causing impairment of hearing, unspecified"
    },
    {
      "name": "Q170",
      "description": "Accessory auricle"
    },
    {
      "name": "Q171",
      "description": "Macrotia"
    },
    {
      "name": "Q172",
      "description": "Microtia"
    },
    {
      "name": "Q173",
      "description": "Other misshapen ear"
    },
    {
      "name": "Q174",
      "description": "Misplaced ear"
    },
    {
      "name": "Q175",
      "description": "Prominent ear"
    },
    {
      "name": "Q178",
      "description": "Other specified congenital malformations of ear"
    },
    {
      "name": "Q179",
      "description": "Congenital malformation of ear, unspecified"
    },
    {
      "name": "Q180",
      "description": "Sinus, fistula and cyst of branchial cleft"
    },
    {
      "name": "Q181",
      "description": "Preauricular sinus and cyst"
    },
    {
      "name": "Q182",
      "description": "Other branchial cleft malformations"
    },
    {
      "name": "Q183",
      "description": "Webbing of neck"
    },
    {
      "name": "Q184",
      "description": "Macrostomia"
    },
    {
      "name": "Q185",
      "description": "Microstomia"
    },
    {
      "name": "Q186",
      "description": "Macrocheilia"
    },
    {
      "name": "Q187",
      "description": "Microcheilia"
    },
    {
      "name": "Q188",
      "description": "Other specified congenital malformations of face and neck"
    },
    {
      "name": "Q189",
      "description": "Congenital malformation of face and neck,unspecified"
    },
    {
      "name": "Q200",
      "description": "Common arterial trunk"
    },
    {
      "name": "Q201",
      "description": "Double outlet right ventricle"
    },
    {
      "name": "Q202",
      "description": "Double outlet left ventricle"
    },
    {
      "name": "Q203",
      "description": "Discordant ventriculoarterial connection"
    },
    {
      "name": "Q204",
      "description": "Double inlet ventricle"
    },
    {
      "name": "Q205",
      "description": "Discordant atrioventricular connection"
    },
    {
      "name": "Q206",
      "description": "Isomerism of atrial appendages"
    },
    {
      "name": "Q208",
      "description": "Other congenital malformations of cardiac chambers and connections"
    },
    {
      "name": "Q209",
      "description": "Congenital malformation of cardiac chambers and connections, unspecified"
    },
    {
      "name": "Q210",
      "description": "Ventricular septal defect"
    },
    {
      "name": "Q211",
      "description": "Atrial septal defect"
    },
    {
      "name": "Q212",
      "description": "Atrioventricular septal defect"
    },
    {
      "name": "Q213",
      "description": "Tetralogy of Fallot"
    },
    {
      "name": "Q214",
      "description": "Aortopulmonary septal defect"
    },
    {
      "name": "Q218",
      "description": "Other congenital malformations of cardiac septa"
    },
    {
      "name": "Q219",
      "description": "Congenital malformation of cardiac septum,unspecified"
    },
    {
      "name": "Q220",
      "description": "Pulmonary valve atresia"
    },
    {
      "name": "Q221",
      "description": "Congenital pulmonary valve stenosis"
    },
    {
      "name": "Q222",
      "description": "Congenital pulmonary valve insufficiency"
    },
    {
      "name": "Q223",
      "description": "Other congenital malformations of pulmonary valve"
    },
    {
      "name": "Q224",
      "description": "Congenital tricuspid stenosis"
    },
    {
      "name": "Q225",
      "description": "Ebstein’s anomaly"
    },
    {
      "name": "Q226",
      "description": "Hypoplastic right heart syndrome"
    },
    {
      "name": "Q228",
      "description": "Other congenital malformations of tricuspid valve"
    },
    {
      "name": "Q229",
      "description": "Congenital malformation of tricuspid valve,unspecified"
    },
    {
      "name": "Q230",
      "description": "Congenital stenosis of aortic valve"
    },
    {
      "name": "Q231",
      "description": "Congenital insufficiency of aortic valve"
    },
    {
      "name": "Q232",
      "description": "Congenital mitral stenosis"
    },
    {
      "name": "Q233",
      "description": "Congenital mitral insufficiency"
    },
    {
      "name": "Q234",
      "description": "Hypoplastic left heart syndrome"
    },
    {
      "name": "Q238",
      "description": "Other congenital malformations of aortic and mitral valves"
    },
    {
      "name": "Q239",
      "description": "Congenital malformation of aortic and mitral valves, unspecified"
    },
    {
      "name": "Q240",
      "description": "Dextrocardia"
    },
    {
      "name": "Q241",
      "description": "Laevocardia"
    },
    {
      "name": "Q242",
      "description": "Cor triatriatum"
    },
    {
      "name": "Q243",
      "description": "Pulmonary infundibular stenosis"
    },
    {
      "name": "Q244",
      "description": "Congenital subaortic stenosis"
    },
    {
      "name": "Q245",
      "description": "Malformation of coronary vessels"
    },
    {
      "name": "Q246",
      "description": "Congenital heart block"
    },
    {
      "name": "Q248",
      "description": "Other specified congenital malformations of heart"
    },
    {
      "name": "Q249",
      "description": "Congenital malformation of heart, unspecified"
    },
    {
      "name": "Q250",
      "description": "Patent ductus arteriosus"
    },
    {
      "name": "Q251",
      "description": "Coarctation of aorta"
    },
    {
      "name": "Q252",
      "description": "Atresia of aorta"
    },
    {
      "name": "Q253",
      "description": "Stenosis of aorta"
    },
    {
      "name": "Q254",
      "description": "Other congenital malformations of aorta"
    },
    {
      "name": "Q255",
      "description": "Atresia of pulmonary artery"
    },
    {
      "name": "Q256",
      "description": "Stenosis of pulmonary artery"
    },
    {
      "name": "Q257",
      "description": "Other congenital malformations of pulmonary artery"
    },
    {
      "name": "Q258",
      "description": "Other congenital malformations of great arteries"
    },
    {
      "name": "Q259",
      "description": "Congenital malformation of great arteries,unspecified"
    },
    {
      "name": "Q260",
      "description": "Congenital stenosis of vena cava"
    },
    {
      "name": "Q261",
      "description": "Persistent left superior vena cava"
    },
    {
      "name": "Q262",
      "description": "Total anomalous pulmonary venous connection"
    },
    {
      "name": "Q263",
      "description": "Partial anomalous pulmonary venous connection"
    },
    {
      "name": "Q264",
      "description": "Anomalous pulmonary venous connection,unspecified"
    },
    {
      "name": "Q265",
      "description": "Anomalous portal venous connection"
    },
    {
      "name": "Q266",
      "description": "Portal vein-hepatic artery fistula"
    },
    {
      "name": "Q268",
      "description": "Other congenital malformations of great veins"
    },
    {
      "name": "Q269",
      "description": "Congenital malformation of great vein,unspecified"
    },
    {
      "name": "Q270",
      "description": "Congenital absence and hypoplasia of umbilical artery"
    },
    {
      "name": "Q271",
      "description": "Congenital renal artery stenosis"
    },
    {
      "name": "Q272",
      "description": "Other congenital malformations of renal artery"
    },
    {
      "name": "Q273",
      "description": "Peripheral arteriovenous malformation"
    },
    {
      "name": "Q274",
      "description": "Congenital phlebectasia"
    },
    {
      "name": "Q278",
      "description": "Other specified congenital malformations of peripheral vascular system"
    },
    {
      "name": "Q279",
      "description": "Congenital malformation of peripheral vascular system, unspecified"
    },
    {
      "name": "Q280",
      "description": "Arteriovenous malformation of precerebral vessels"
    },
    {
      "name": "Q281",
      "description": "Other malformations of precerebral vessels"
    },
    {
      "name": "Q282",
      "description": "Arteriovenous malformation of cerebral vessels"
    },
    {
      "name": "Q283",
      "description": "Other malformations of cerebral vessels"
    },
    {
      "name": "Q288",
      "description": "Other specified congenital malformations of circulatory system"
    },
    {
      "name": "Q289",
      "description": "Congenital malformation of circulatory system,unspecified"
    },
    {
      "name": "Q300",
      "description": "Choanal atresia"
    },
    {
      "name": "Q301",
      "description": "Agenesis and underdevelopment of nose"
    },
    {
      "name": "Q302",
      "description": "Fissured, notched and cleft nose"
    },
    {
      "name": "Q303",
      "description": "Congenital perforated nasal septum"
    },
    {
      "name": "Q308",
      "description": "Other congenital malformations of nose"
    },
    {
      "name": "Q309",
      "description": "Congenital malformation of nose, unspecified"
    },
    {
      "name": "Q310",
      "description": "Web of larynx"
    },
    {
      "name": "Q311",
      "description": "Congenital subglottic stenosis"
    },
    {
      "name": "Q312",
      "description": "Laryngeal hypoplasia"
    },
    {
      "name": "Q313",
      "description": "Laryngocele"
    },
    {
      "name": "Q315",
      "description": "Congenital laryngomalacia"
    },
    {
      "name": "Q318",
      "description": "Other congenital malformations of larynx"
    },
    {
      "name": "Q319",
      "description": "Congenital malformation of larynx, unspecified"
    },
    {
      "name": "Q320",
      "description": "Congenital tracheomalacia"
    },
    {
      "name": "Q321",
      "description": "Other congenital malformations of trachea"
    },
    {
      "name": "Q322",
      "description": "Congenital bronchomalacia"
    },
    {
      "name": "Q323",
      "description": "Congenital stenosis of bronchus"
    },
    {
      "name": "Q324",
      "description": "Other congenital malformations of bronchus"
    },
    {
      "name": "Q330",
      "description": "Congenital cystic lung"
    },
    {
      "name": "Q331",
      "description": "Accessory lobe of lung"
    },
    {
      "name": "Q332",
      "description": "Sequestration of lung"
    },
    {
      "name": "Q333",
      "description": "Agenesis of lung"
    },
    {
      "name": "Q334",
      "description": "Congenital bronchiectasis"
    },
    {
      "name": "Q335",
      "description": "Ectopic tissue in lung"
    },
    {
      "name": "Q336",
      "description": "Hypoplasia and dysplasia of lung"
    },
    {
      "name": "Q338",
      "description": "Other congenital malformations of lung"
    },
    {
      "name": "Q339",
      "description": "Congenital malformation of lung, unspecified"
    },
    {
      "name": "Q340",
      "description": "Anomaly of pleura"
    },
    {
      "name": "Q341",
      "description": "Congenital cyst of mediastinum"
    },
    {
      "name": "Q348",
      "description": "Other specified congenital malformations of respiratory system"
    },
    {
      "name": "Q349",
      "description": "Congenital malformation of respiratory system,unspecified"
    },
    {
      "name": "Q351",
      "description": "Cleft hard palate"
    },
    {
      "name": "Q353",
      "description": "Cleft soft palate"
    },
    {
      "name": "Q355",
      "description": "Cleft hard palate with cleft soft palate"
    },
    {
      "name": "Q357",
      "description": "Cleft uvula"
    },
    {
      "name": "Q359",
      "description": "Cleft palate, unspecified"
    },
    {
      "name": "Q360",
      "description": "Cleft lip, bilateral"
    },
    {
      "name": "Q361",
      "description": "Cleft lip, median"
    },
    {
      "name": "Q369",
      "description": "Cleft lip, unilateral"
    },
    {
      "name": "Q370",
      "description": "Cleft hard palate with bilateral cleft lip"
    },
    {
      "name": "Q371",
      "description": "Cleft hard palate with unilateral cleft lip"
    },
    {
      "name": "Q372",
      "description": "Cleft soft palate with bilateral cleft lip"
    },
    {
      "name": "Q373",
      "description": "Cleft soft palate with unilateral cleft lip"
    },
    {
      "name": "Q374",
      "description": "Cleft hard and soft palate with bilateral cleft lip"
    },
    {
      "name": "Q375",
      "description": "Cleft hard and soft palate with unilateral cleft lip"
    },
    {
      "name": "Q378",
      "description": "Unspecified cleft palate with bilateral cleft lip"
    },
    {
      "name": "Q379",
      "description": "Unspecified cleft palate with unilateral cleft lip"
    },
    {
      "name": "Q380",
      "description": "Congenital malformations of lip, not elsewhere classified"
    },
    {
      "name": "Q381",
      "description": "Ankyloglossia"
    },
    {
      "name": "Q382",
      "description": "Macroglossia"
    },
    {
      "name": "Q3830",
      "description": "Aglossia"
    },
    {
      "name": "Q3831",
      "description": "Bifid tongue"
    },
    {
      "name": "Q3832",
      "description": "Adhesion of tongue, congenital"
    },
    {
      "name": "Q3833",
      "description": "Fissure of tongue, congenital"
    },
    {
      "name": "Q3834",
      "description": "Microglossia"
    },
    {
      "name": "Q3838",
      "description": "Other specified congenital malformations of tongue"
    },
    {
      "name": "Q3839",
      "description": "Congenital malformation of tongue, unspecified"
    },
    {
      "name": "Q3840",
      "description": "Absence of salivary gland or duct"
    },
    {
      "name": "Q3841",
      "description": "Accessory salivary gland or duct"
    },
    {
      "name": "Q3842",
      "description": "Atresia of salivary gland or duct"
    },
    {
      "name": "Q3843",
      "description": "Congenital fistula of salivary gland"
    },
    {
      "name": "Q3848",
      "description": "Other specified congenital malformations of salivary glands and ducts"
    },
    {
      "name": "Q3849",
      "description": "Congenital malformation of salivary glands and ducts, unspecified"
    },
    {
      "name": "Q3850",
      "description": "Absence of uvula"
    },
    {
      "name": "Q3851",
      "description": "High arched palate"
    },
    {
      "name": "Q3858",
      "description": "Other specified congenital malformations of palate"
    },
    {
      "name": "Q3859",
      "description": "Congenital malformation of palate, unspecified"
    },
    {
      "name": "Q3860",
      "description": "Fordyce condition [Fordyce disease]"
    },
    {
      "name": "Q3861",
      "description": "White sponge naevus [leukokeratosis oris]"
    },
    {
      "name": "Q3868",
      "description": "Other specified congenital malformations of mouth"
    },
    {
      "name": "Q3869",
      "description": "Congenital malformation of mouth, unspecified"
    },
    {
      "name": "Q387",
      "description": "Pharyngeal pouch"
    },
    {
      "name": "Q388",
      "description": "Other congenital malformation of pharynx"
    },
    {
      "name": "Q390",
      "description": "Atresia of oesophagus without fistula"
    },
    {
      "name": "Q391",
      "description": "Atresia of oesophagus with tracheo-oesophageal fistula"
    },
    {
      "name": "Q392",
      "description": "Congenital tracheo-oesophageal fistula without atresia"
    },
    {
      "name": "Q393",
      "description": "Congenital stenosis and stricture of oesophagus"
    },
    {
      "name": "Q394",
      "description": "Oesophageal web"
    },
    {
      "name": "Q395",
      "description": "Congenital dilatation of oesophagus"
    },
    {
      "name": "Q396",
      "description": "Diverticulum of oesophagus"
    },
    {
      "name": "Q398",
      "description": "Other congenital malformations of oesophagus"
    },
    {
      "name": "Q399",
      "description": "Congenital malformation of oesophagus,unspecified"
    },
    {
      "name": "Q400",
      "description": "Congenital hypertrophic pyloric stenosis"
    },
    {
      "name": "Q401",
      "description": "Congenital hiatus hernia"
    },
    {
      "name": "Q402",
      "description": "Other specified congenital malformations of stomach"
    },
    {
      "name": "Q403",
      "description": "Congenital malformation of stomach, unspecified"
    },
    {
      "name": "Q404",
      "description": "Congenital microvillous atrophy"
    },
    {
      "name": "Q408",
      "description": "Other specified congenital malformations of upper alimentary tract"
    },
    {
      "name": "Q409",
      "description": "Congenital malformation of upper alimentary tract, unspecified"
    },
    {
      "name": "Q4100",
      "description": "Duodenal web"
    },
    {
      "name": "Q4108",
      "description": "Other congenital absence, atresia and stenosis of duodenum"
    },
    {
      "name": "Q4109",
      "description": "Congenital absence, atresia and stenosis of duodenum, unspecified"
    },
    {
      "name": "Q411",
      "description": "Congenital absence, atresia and stenosis of jejunum"
    },
    {
      "name": "Q412",
      "description": "Congenital absence, atresia and stenosis of ileum"
    },
    {
      "name": "Q418",
      "description": "Congenital absence, atresia and stenosis of other specified part of small intestine"
    },
    {
      "name": "Q419",
      "description": "Congenital absence, atresia and stenosis of small intestine, part unspecified"
    },
    {
      "name": "Q420",
      "description": "Congenital absence, atresia and stenosis of rectum with fistula"
    },
    {
      "name": "Q421",
      "description": "Congenital absence, atresia and stenosis of rectum without fistula"
    },
    {
      "name": "Q422",
      "description": "Congenital absence, atresia and stenosis of anus with fistula"
    },
    {
      "name": "Q423",
      "description": "Congenital absence, atresia and stenosis of anus without fistula"
    },
    {
      "name": "Q428",
      "description": "Congenital absence, atresia and stenosis of other parts of large intestine"
    },
    {
      "name": "Q429",
      "description": "Congenital absence, atresia and stenosis of large intestine, part unspecified"
    },
    {
      "name": "Q430",
      "description": "Meckel’s diverticulum"
    },
    {
      "name": "Q431",
      "description": "Hirschsprung’s disease"
    },
    {
      "name": "Q432",
      "description": "Other congenital functional disorders of colon"
    },
    {
      "name": "Q433",
      "description": "Congenital malformations of intestinal fixation"
    },
    {
      "name": "Q434",
      "description": "Duplication of intestine"
    },
    {
      "name": "Q435",
      "description": "Ectopic anus"
    },
    {
      "name": "Q436",
      "description": "Congenital fistula of rectum and anus"
    },
    {
      "name": "Q437",
      "description": "Persistent cloaca"
    },
    {
      "name": "Q438",
      "description": "Other specified congenital malformations of intestine"
    },
    {
      "name": "Q439",
      "description": "Congenital malformation of intestine, unspecified"
    },
    {
      "name": "Q440",
      "description": "Agenesis, aplasia and hypoplasia of gallbladder"
    },
    {
      "name": "Q441",
      "description": "Other congenital malformations of gallbladder"
    },
    {
      "name": "Q442",
      "description": "Atresia of bile ducts"
    },
    {
      "name": "Q443",
      "description": "Congenital stenosis and stricture of bile ducts"
    },
    {
      "name": "Q444",
      "description": "Choledochal cyst"
    },
    {
      "name": "Q445",
      "description": "Other congenital malformations of bile ducts"
    },
    {
      "name": "Q446",
      "description": "Cystic disease of liver"
    },
    {
      "name": "Q4470",
      "description": "Alagille’s syndrome"
    },
    {
      "name": "Q4471",
      "description": "Progressive familial intrahepatic cholestasis"
    },
    {
      "name": "Q4478",
      "description": "Other congenital malformations of liver"
    },
    {
      "name": "Q4479",
      "description": "Congenital malformation of liver, unspecified"
    },
    {
      "name": "Q450",
      "description": "Agenesis, aplasia and hypoplasia of pancreas"
    },
    {
      "name": "Q451",
      "description": "Annular pancreas"
    },
    {
      "name": "Q452",
      "description": "Congenital pancreatic cyst"
    },
    {
      "name": "Q453",
      "description": "Other congenital malformations of pancreas and pancreatic duct"
    },
    {
      "name": "Q458",
      "description": "Other specified congenital malformations of digestive system"
    },
    {
      "name": "Q459",
      "description": "Congenital malformation of digestive system,unspecified"
    },
    {
      "name": "Q500",
      "description": "Congenital absence of ovary"
    },
    {
      "name": "Q501",
      "description": "Developmental ovarian cyst"
    },
    {
      "name": "Q502",
      "description": "Congenital torsion of ovary"
    },
    {
      "name": "Q503",
      "description": "Other congenital malformations of ovary"
    },
    {
      "name": "Q504",
      "description": "Embryonic cyst of fallopian tube"
    },
    {
      "name": "Q505",
      "description": "Embryonic cyst of broad ligament"
    },
    {
      "name": "Q506",
      "description": "Other congenital malformations of fallopian tube and broad ligament"
    },
    {
      "name": "Q510",
      "description": "Agenesis and aplasia of uterus"
    },
    {
      "name": "Q511",
      "description": "Doubling of uterus with doubling of cervix and vagina"
    },
    {
      "name": "Q512",
      "description": "Other doubling of uterus"
    },
    {
      "name": "Q513",
      "description": "Bicornate uterus"
    },
    {
      "name": "Q514",
      "description": "Unicornate uterus"
    },
    {
      "name": "Q515",
      "description": "Agenesis and aplasia of cervix"
    },
    {
      "name": "Q516",
      "description": "Embryonic cyst of cervix"
    },
    {
      "name": "Q517",
      "description": "Congenital fistulae between uterus and digestive and urinary tracts"
    },
    {
      "name": "Q518",
      "description": "Other congenital malformations of uterus and cervix"
    },
    {
      "name": "Q519",
      "description": "Congenital malformation of uterus and cervix,unspecified"
    },
    {
      "name": "Q520",
      "description": "Congenital absence of vagina"
    },
    {
      "name": "Q521",
      "description": "Doubling of vagina"
    },
    {
      "name": "Q522",
      "description": "Congenital rectovaginal fistula"
    },
    {
      "name": "Q523",
      "description": "Imperforate hymen"
    },
    {
      "name": "Q524",
      "description": "Other congenital malformations of vagina"
    },
    {
      "name": "Q525",
      "description": "Fusion of labia"
    },
    {
      "name": "Q526",
      "description": "Congenital malformation of clitoris"
    },
    {
      "name": "Q527",
      "description": "Other congenital malformations of vulva"
    },
    {
      "name": "Q528",
      "description": "Other specified congenital malformations of female genitalia"
    },
    {
      "name": "Q529",
      "description": "Congenital malformation of female genitalia,unspecified"
    },
    {
      "name": "Q530",
      "description": "Ectopic testis"
    },
    {
      "name": "Q531",
      "description": "Undescended testicle, unilateral"
    },
    {
      "name": "Q532",
      "description": "Undescended testicle, bilateral"
    },
    {
      "name": "Q539",
      "description": "Undescended testicle, unspecified"
    },
    {
      "name": "Q540",
      "description": "Hypospadias, balanic"
    },
    {
      "name": "Q541",
      "description": "Hypospadias, penile"
    },
    {
      "name": "Q542",
      "description": "Hypospadias, penoscrotal"
    },
    {
      "name": "Q543",
      "description": "Hypospadias, perineal"
    },
    {
      "name": "Q544",
      "description": "Congenital chordee"
    },
    {
      "name": "Q548",
      "description": "Other hypospadias"
    },
    {
      "name": "Q549",
      "description": "Hypospadias, unspecified"
    },
    {
      "name": "Q550",
      "description": "Absence and aplasia of testis"
    },
    {
      "name": "Q551",
      "description": "Hypoplasia of testis and scrotum"
    },
    {
      "name": "Q552",
      "description": "Other congenital malformations of testis and scrotum"
    },
    {
      "name": "Q553",
      "description": "Atresia of vas deferens"
    },
    {
      "name": "Q554",
      "description": "Other congenital malformations of vas deferens,epididymis, seminal vesicles and prostate"
    },
    {
      "name": "Q555",
      "description": "Congenital absence and aplasia of penis"
    },
    {
      "name": "Q556",
      "description": "Other congenital malformations of penis"
    },
    {
      "name": "Q558",
      "description": "Other specified congenital malformations of male genital organs"
    },
    {
      "name": "Q559",
      "description": "Congenital malformation of male genital organ,unspecified"
    },
    {
      "name": "Q560",
      "description": "Hermaphroditism, not elsewhere classified"
    },
    {
      "name": "Q561",
      "description": "Male pseudohermaphroditism, not elsewhere classified"
    },
    {
      "name": "Q562",
      "description": "Female pseudohermaphroditism, not elsewhere classified"
    },
    {
      "name": "Q563",
      "description": "Pseudohermaphroditism, unspecified"
    },
    {
      "name": "Q564",
      "description": "Indeterminate sex, unspecified"
    },
    {
      "name": "Q600",
      "description": "Renal agenesis, unilateral"
    },
    {
      "name": "Q601",
      "description": "Renal agenesis, bilateral"
    },
    {
      "name": "Q602",
      "description": "Renal agenesis, unspecified"
    },
    {
      "name": "Q603",
      "description": "Renal hypoplasia, unilateral"
    },
    {
      "name": "Q604",
      "description": "Renal hypoplasia, bilateral"
    },
    {
      "name": "Q605",
      "description": "Renal hypoplasia, unspecified"
    },
    {
      "name": "Q606",
      "description": "Potter’s syndrome"
    },
    {
      "name": "Q610",
      "description": "Congenital single renal cyst"
    },
    {
      "name": "Q611",
      "description": "Polycystic kidney, autosomal recessive"
    },
    {
      "name": "Q612",
      "description": "Polycystic kidney, autosomal dominant"
    },
    {
      "name": "Q613",
      "description": "Polycystic kidney, unspecified"
    },
    {
      "name": "Q614",
      "description": "Renal dysplasia"
    },
    {
      "name": "Q615",
      "description": "Medullary cystic kidney"
    },
    {
      "name": "Q618",
      "description": "Other cystic kidney diseases"
    },
    {
      "name": "Q619",
      "description": "Cystic kidney disease, unspecified"
    },
    {
      "name": "Q620",
      "description": "Congenital hydronephrosis"
    },
    {
      "name": "Q621",
      "description": "Atresia and stenosis of ureter"
    },
    {
      "name": "Q622",
      "description": "Congenital megaloureter"
    },
    {
      "name": "Q623",
      "description": "Other obstructive defects of renal pelvis and ureter"
    },
    {
      "name": "Q624",
      "description": "Agenesis of ureter"
    },
    {
      "name": "Q625",
      "description": "Duplication of ureter"
    },
    {
      "name": "Q626",
      "description": "Malposition of ureter"
    },
    {
      "name": "Q627",
      "description": "Congenital vesico-uretero-renal reflux"
    },
    {
      "name": "Q628",
      "description": "Other congenital malformations of ureter"
    },
    {
      "name": "Q630",
      "description": "Accessory kidney"
    },
    {
      "name": "Q631",
      "description": "Lobulated, fused and horseshoe kidney"
    },
    {
      "name": "Q632",
      "description": "Ectopic kidney"
    },
    {
      "name": "Q633",
      "description": "Hyperplastic and giant kidney"
    },
    {
      "name": "Q638",
      "description": "Other specified congenital malformations of kidney"
    },
    {
      "name": "Q639",
      "description": "Congenital malformation of kidney, unspecified"
    },
    {
      "name": "Q640",
      "description": "Epispadias"
    },
    {
      "name": "Q641",
      "description": "Exstrophy of urinary bladder"
    },
    {
      "name": "Q642",
      "description": "Congenital posterior urethral valves"
    },
    {
      "name": "Q643",
      "description": "Other atresia and stenosis of urethra and bladder neck"
    },
    {
      "name": "Q644",
      "description": "Malformation of urachus"
    },
    {
      "name": "Q645",
      "description": "Congenital absence of bladder and urethra"
    },
    {
      "name": "Q646",
      "description": "Congenital diverticulum of bladder"
    },
    {
      "name": "Q647",
      "description": "Other congenital malformations of bladder and urethra"
    },
    {
      "name": "Q648",
      "description": "Other specified congenital malformations of urinary system"
    },
    {
      "name": "Q649",
      "description": "Congenital malformation of urinary system,unspecified"
    },
    {
      "name": "Q650",
      "description": "Congenital dislocation of hip, unilateral"
    },
    {
      "name": "Q651",
      "description": "Congenital dislocation of hip, bilateral"
    },
    {
      "name": "Q652",
      "description": "Congenital dislocation of hip, unspecified"
    },
    {
      "name": "Q653",
      "description": "Congenital subluxation of hip, unilateral"
    },
    {
      "name": "Q654",
      "description": "Congenital subluxation of hip, bilateral"
    },
    {
      "name": "Q655",
      "description": "Congenital subluxation of hip, unspecified"
    },
    {
      "name": "Q656",
      "description": "Unstable hip"
    },
    {
      "name": "Q658",
      "description": "Other congenital deformities of hip"
    },
    {
      "name": "Q659",
      "description": "Congenital deformity of hip, unspecified"
    },
    {
      "name": "Q660",
      "description": "Talipes equinovarus"
    },
    {
      "name": "Q661",
      "description": "Talipes calcaneovarus"
    },
    {
      "name": "Q662",
      "description": "Metatarsus varus"
    },
    {
      "name": "Q663",
      "description": "Other congenital varus deformities of feet"
    },
    {
      "name": "Q664",
      "description": "Talipes calcaneovalgus"
    },
    {
      "name": "Q665",
      "description": "Congenital pes planus"
    },
    {
      "name": "Q666",
      "description": "Other congenital valgus deformities of feet"
    },
    {
      "name": "Q667",
      "description": "Pes cavus"
    },
    {
      "name": "Q668",
      "description": "Other congenital deformities of feet"
    },
    {
      "name": "Q669",
      "description": "Congenital deformity of feet, unspecified"
    },
    {
      "name": "Q670",
      "description": "Facial asymmetry"
    },
    {
      "name": "Q671",
      "description": "Compression facies"
    },
    {
      "name": "Q672",
      "description": "Dolichocephaly"
    },
    {
      "name": "Q673",
      "description": "Plagiocephaly"
    },
    {
      "name": "Q6740",
      "description": "Hemifacial atrophy"
    },
    {
      "name": "Q6741",
      "description": "Hemifacial hypertrophy"
    },
    {
      "name": "Q6748",
      "description": "Other specified congenital deformities of skull, face and jaw"
    },
    {
      "name": "Q6749",
      "description": "Congenital deformity of skull, face and jaw,unspecified"
    },
    {
      "name": "Q675",
      "description": "Congenital deformity of spine"
    },
    {
      "name": "Q676",
      "description": "Pectus excavatum"
    },
    {
      "name": "Q677",
      "description": "Pectus carinatum"
    },
    {
      "name": "Q678",
      "description": "Other congenital deformities of chest"
    },
    {
      "name": "Q680",
      "description": "Congenital deformity of sternocleidomastoid muscle"
    },
    {
      "name": "Q681",
      "description": "Congenital deformity of hand"
    },
    {
      "name": "Q682",
      "description": "Congenital deformity of knee"
    },
    {
      "name": "Q683",
      "description": "Congenital bowing of femur"
    },
    {
      "name": "Q684",
      "description": "Congenital bowing of tibia and fibula"
    },
    {
      "name": "Q685",
      "description": "Congenital bowing of long bones of leg,unspecified"
    },
    {
      "name": "Q6880",
      "description": "Congenital deformity of clavicle"
    },
    {
      "name": "Q6881",
      "description": "Congenital deformity of elbow"
    },
    {
      "name": "Q6882",
      "description": "Congenital deformity of forearm"
    },
    {
      "name": "Q6883",
      "description": "Congenital deformity of scapula"
    },
    {
      "name": "Q6884",
      "description": "Congenital dislocation of elbow"
    },
    {
      "name": "Q6885",
      "description": "Congenital dislocation of shoulder"
    },
    {
      "name": "Q6888",
      "description": "Other specified congenital musculoskeletal deformities"
    },
    {
      "name": "Q690",
      "description": "Accessory finger(s)"
    },
    {
      "name": "Q691",
      "description": "Accessory thumb(s)"
    },
    {
      "name": "Q692",
      "description": "Accessory toe(s)"
    },
    {
      "name": "Q699",
      "description": "Polydactyly, unspecified"
    },
    {
      "name": "Q700",
      "description": "Fused fingers"
    },
    {
      "name": "Q701",
      "description": "Webbed fingers"
    },
    {
      "name": "Q702",
      "description": "Fused toes"
    },
    {
      "name": "Q703",
      "description": "Webbed toes"
    },
    {
      "name": "Q704",
      "description": "Polysyndactyly"
    },
    {
      "name": "Q709",
      "description": "Syndactyly, unspecified"
    },
    {
      "name": "Q710",
      "description": "Congenital complete absence of upper limb(s)"
    },
    {
      "name": "Q711",
      "description": "Congenital absence of upper arm and forearm with hand present"
    },
    {
      "name": "Q712",
      "description": "Congenital absence of both forearm and hand"
    },
    {
      "name": "Q7130",
      "description": "Congenital absence of hand"
    },
    {
      "name": "Q7131",
      "description": "Congenital absence of finger(s)"
    },
    {
      "name": "Q7132",
      "description": "Congenital absence of thumb"
    },
    {
      "name": "Q7133",
      "description": "Congenital hypoplastic thumb"
    },
    {
      "name": "Q7138",
      "description": "Other congenital absence of hand and finger(s)"
    },
    {
      "name": "Q714",
      "description": "Longitudinal reduction defect of radius"
    },
    {
      "name": "Q715",
      "description": "Longitudinal reduction defect of ulna"
    },
    {
      "name": "Q716",
      "description": "Lobster-claw hand"
    },
    {
      "name": "Q718",
      "description": "Other reduction defects of upper limb(s)"
    },
    {
      "name": "Q719",
      "description": "Reduction defect of upper limb, unspecified"
    },
    {
      "name": "Q720",
      "description": "Congenital complete absence of lower limb(s)"
    },
    {
      "name": "Q721",
      "description": "Congenital absence of thigh and lower leg with foot present"
    },
    {
      "name": "Q722",
      "description": "Congenital absence of both lower leg and foot"
    },
    {
      "name": "Q7230",
      "description": "Congenital absence of foot"
    },
    {
      "name": "Q7231",
      "description": "Congenital absence of toe(s)"
    },
    {
      "name": "Q724",
      "description": "Longitudinal reduction defect of femur"
    },
    {
      "name": "Q725",
      "description": "Longitudinal reduction defect of tibia"
    },
    {
      "name": "Q726",
      "description": "Longitudinal reduction defect of fibula"
    },
    {
      "name": "Q727",
      "description": "Split foot"
    },
    {
      "name": "Q728",
      "description": "Other reduction defects of lower limb(s)"
    },
    {
      "name": "Q729",
      "description": "Reduction defect of lower limb, unspecified"
    },
    {
      "name": "Q730",
      "description": "Congenital absence of unspecified limb(s)"
    },
    {
      "name": "Q731",
      "description": "Phocomelia, unspecified limb(s)"
    },
    {
      "name": "Q738",
      "description": "Other reduction defects of unspecified limb(s)"
    },
    {
      "name": "Q740",
      "description": "Other congenital malformations of upper limb,including shoulder girdle"
    },
    {
      "name": "Q741",
      "description": "Congenital malformation of knee"
    },
    {
      "name": "Q742",
      "description": "Other congenital malformations of lower limb,including pelvic girdle"
    },
    {
      "name": "Q743",
      "description": "Arthrogryposis multiplex congenita"
    },
    {
      "name": "Q748",
      "description": "Other specified congenital malformations of limb(s)"
    },
    {
      "name": "Q749",
      "description": "Unspecified congenital malformation of limb(s)"
    },
    {
      "name": "Q7500",
      "description": "Acrocephaly"
    },
    {
      "name": "Q7501",
      "description": "Imperfect fusion of skull"
    },
    {
      "name": "Q7503",
      "description": "Trigonocephaly"
    },
    {
      "name": "Q7509",
      "description": "Craniosynostosis, unspecified"
    },
    {
      "name": "Q751",
      "description": "Craniofacial dysostosis"
    },
    {
      "name": "Q752",
      "description": "Hypertelorism"
    },
    {
      "name": "Q753",
      "description": "Macrocephaly"
    },
    {
      "name": "Q754",
      "description": "Mandibulofacial dysostosis"
    },
    {
      "name": "Q755",
      "description": "Oculomandibular dysostosis"
    },
    {
      "name": "Q758",
      "description": "Other specified congenital malformations of skull and face bones"
    },
    {
      "name": "Q759",
      "description": "Congenital malformation of skull and face bones,unspecified"
    },
    {
      "name": "Q760",
      "description": "Spina bifida occulta"
    },
    {
      "name": "Q761",
      "description": "Klippel-Feil syndrome"
    },
    {
      "name": "Q7620",
      "description": "Congenital spondylolisthesis"
    },
    {
      "name": "Q7621",
      "description": "Congenital spondylolysis"
    },
    {
      "name": "Q763",
      "description": "Congenital scoliosis due to congenital bony malformation"
    },
    {
      "name": "Q764",
      "description": "Other congenital malformations of spine, not associated with scoliosis"
    },
    {
      "name": "Q765",
      "description": "Cervical rib"
    },
    {
      "name": "Q766",
      "description": "Other congenital malformations of ribs"
    },
    {
      "name": "Q767",
      "description": "Congenital malformation of sternum"
    },
    {
      "name": "Q768",
      "description": "Other congenital malformations of bony thorax"
    },
    {
      "name": "Q769",
      "description": "Congenital malformation of bony thorax,unspecified"
    },
    {
      "name": "Q770",
      "description": "Achondrogenesis"
    },
    {
      "name": "Q771",
      "description": "Thanatophoric short stature"
    },
    {
      "name": "Q772",
      "description": "Short rib syndrome"
    },
    {
      "name": "Q773",
      "description": "Chondrodysplasia punctata"
    },
    {
      "name": "Q774",
      "description": "Achondroplasia"
    },
    {
      "name": "Q775",
      "description": "Diastrophic dysplasia"
    },
    {
      "name": "Q776",
      "description": "Chondroectodermal dysplasia"
    },
    {
      "name": "Q777",
      "description": "Spondyloepiphyseal dysplasia"
    },
    {
      "name": "Q778",
      "description": "Other osteochondrodysplasia with defects of growth of tubular bones and spine"
    },
    {
      "name": "Q779",
      "description": "Osteochondrodysplasia with defects of growth of tubular bones and spine, unspecified"
    },
    {
      "name": "Q780",
      "description": "Osteogenesis imperfecta"
    },
    {
      "name": "Q781",
      "description": "Polyostotic fibrous dysplasia"
    },
    {
      "name": "Q782",
      "description": "Osteopetrosis"
    },
    {
      "name": "Q783",
      "description": "Progressive diaphyseal dysplasia"
    },
    {
      "name": "Q784",
      "description": "Enchondromatosis"
    },
    {
      "name": "Q785",
      "description": "Metaphyseal dysplasia"
    },
    {
      "name": "Q786",
      "description": "Multiple congenital exostoses"
    },
    {
      "name": "Q788",
      "description": "Other specified osteochondrodysplasias"
    },
    {
      "name": "Q789",
      "description": "Osteochondrodysplasia, unspecified"
    },
    {
      "name": "Q790",
      "description": "Congenital diaphragmatic hernia"
    },
    {
      "name": "Q791",
      "description": "Other congenital malformations of diaphragm"
    },
    {
      "name": "Q792",
      "description": "Exomphalos"
    },
    {
      "name": "Q793",
      "description": "Gastroschisis"
    },
    {
      "name": "Q794",
      "description": "Prune belly syndrome"
    },
    {
      "name": "Q795",
      "description": "Other congenital malformations of abdominal wall"
    },
    {
      "name": "Q796",
      "description": "Ehlers-Danlos syndrome"
    },
    {
      "name": "Q798",
      "description": "Other congenital malformations of musculoskeletal system"
    },
    {
      "name": "Q799",
      "description": "Congenital malformation of musculoskeletal system, unspecified"
    },
    {
      "name": "Q800",
      "description": "Ichthyosis vulgaris"
    },
    {
      "name": "Q801",
      "description": "X-linked ichthyosis"
    },
    {
      "name": "Q802",
      "description": "Lamellar ichthyosis"
    },
    {
      "name": "Q803",
      "description": "Congenital bullous ichthyosiform erythroderma"
    },
    {
      "name": "Q804",
      "description": "Harlequin fetus"
    },
    {
      "name": "Q808",
      "description": "Other congenital ichthyosis"
    },
    {
      "name": "Q809",
      "description": "Congenital ichthyosis, unspecified"
    },
    {
      "name": "Q810",
      "description": "Epidermolysis bullosa simplex"
    },
    {
      "name": "Q811",
      "description": "Epidermolysis bullosa letallis"
    },
    {
      "name": "Q812",
      "description": "Epidermolysis bullosa dystrophica"
    },
    {
      "name": "Q818",
      "description": "Other epidermolysis bullosa"
    },
    {
      "name": "Q819",
      "description": "Epidermolysis bullosa, unspecified"
    },
    {
      "name": "Q820",
      "description": "Hereditary lymphoedema"
    },
    {
      "name": "Q821",
      "description": "Xeroderma pigmentosum"
    },
    {
      "name": "Q822",
      "description": "Mastocytosis"
    },
    {
      "name": "Q823",
      "description": "Incontinentia pigmenti"
    },
    {
      "name": "Q824",
      "description": "Ectodermal dysplasia (anhidrotic)"
    },
    {
      "name": "Q825",
      "description": "Congenital non-neoplastic naevus"
    },
    {
      "name": "Q8280",
      "description": "Benign familial pemphigus [Hailey-Hailey]"
    },
    {
      "name": "Q8281",
      "description": "Keratosis follicularis [Darier-White]"
    },
    {
      "name": "Q8282",
      "description": "Inherited keratosis palmaris et plantaris"
    },
    {
      "name": "Q8283",
      "description": "Dyskeratosis congenita"
    },
    {
      "name": "Q8284",
      "description": "Pseudoxanthoma elasticum"
    },
    {
      "name": "Q8285",
      "description": "Acrokeratosis verruciformis"
    },
    {
      "name": "Q8288",
      "description": "Other specified congenital malformations of skin"
    },
    {
      "name": "Q829",
      "description": "Congenital malformation of skin, unspecified"
    },
    {
      "name": "Q830",
      "description": "Congenital absence of breast with absent nipple"
    },
    {
      "name": "Q831",
      "description": "Accessory breast"
    },
    {
      "name": "Q832",
      "description": "Absent nipple"
    },
    {
      "name": "Q833",
      "description": "Accessory nipple"
    },
    {
      "name": "Q838",
      "description": "Other congenital malformations of breast"
    },
    {
      "name": "Q839",
      "description": "Congenital malformation of breast, unspecified"
    },
    {
      "name": "Q840",
      "description": "Congenital alopecia"
    },
    {
      "name": "Q841",
      "description": "Congenital morphological disturbances of hair,not elsewhere classified"
    },
    {
      "name": "Q842",
      "description": "Other congenital malformations of hair"
    },
    {
      "name": "Q843",
      "description": "Anonychia"
    },
    {
      "name": "Q844",
      "description": "Congenital leukonychia"
    },
    {
      "name": "Q845",
      "description": "Enlarged and hypertrophic nails"
    },
    {
      "name": "Q846",
      "description": "Other congenital malformations of nails"
    },
    {
      "name": "Q848",
      "description": "Other specified congenital malformations of integument"
    },
    {
      "name": "Q849",
      "description": "Congenital malformation of integument unspecified"
    },
    {
      "name": "Q850",
      "description": "Neurofibromatosis( nonmalignant)"
    },
    {
      "name": "Q851",
      "description": "Tuberous sclerosis"
    },
    {
      "name": "Q8580",
      "description": "Peutz-Jeghers syndrome"
    },
    {
      "name": "Q8581",
      "description": "Struge-Weber-Dimitri syndrome"
    },
    {
      "name": "Q8588",
      "description": "Other phakomatoses, not elsewhere classified"
    },
    {
      "name": "Q859",
      "description": "Phakomatosis, unspecified"
    },
    {
      "name": "Q860",
      "description": "Fetal alcohol syndrome (dysmorphic)"
    },
    {
      "name": "Q861",
      "description": "Fetal hydantoin syndrome"
    },
    {
      "name": "Q862",
      "description": "Dysmorphism due to warfarin"
    },
    {
      "name": "Q868",
      "description": "Other congenital malformation syndromes due to known exogenous causes"
    },
    {
      "name": "Q8700",
      "description": "Acrocephalopolysyndactyly [Apert]"
    },
    {
      "name": "Q8701",
      "description": "Goldenhar’s syndrome"
    },
    {
      "name": "Q8702",
      "description": "Moebius’ syndrome"
    },
    {
      "name": "Q8703",
      "description": "Oro-facial-digital syndrome"
    },
    {
      "name": "Q8705",
      "description": "Robin’s syndrome"
    },
    {
      "name": "Q8708",
      "description": "Other congenital malformation syndromes predominantly affecting facial appearance"
    },
    {
      "name": "Q871",
      "description": "Congenital malformation syndromes predominantly associated with short stature"
    },
    {
      "name": "Q872",
      "description": "Congenital malformation syndromes predominantly involving limbs"
    },
    {
      "name": "Q873",
      "description": "Congenital malformation syndromes involving early overgrowth"
    },
    {
      "name": "Q874",
      "description": "Marfan’s syndrome"
    },
    {
      "name": "Q875",
      "description": "Other congenital malformation syndromes with other skeletal changes"
    },
    {
      "name": "Q878",
      "description": "Other specified congenital malformation syndromes, not elsewhere classified"
    },
    {
      "name": "Q890",
      "description": "Congenital malformations of spleen"
    },
    {
      "name": "Q891",
      "description": "Congenital malformations of adrenal gland"
    },
    {
      "name": "Q8920",
      "description": "Persistent thyroglossal or thyrolingual duct"
    },
    {
      "name": "Q8921",
      "description": "Thyroglossal cyst"
    },
    {
      "name": "Q8922",
      "description": "Thyroglossal fistula"
    },
    {
      "name": "Q8923",
      "description": "Lingual thyroid"
    },
    {
      "name": "Q8928",
      "description": "Congenital malformations of other endocrine glands"
    },
    {
      "name": "Q893",
      "description": "Situs inversus"
    },
    {
      "name": "Q894",
      "description": "Conjoined twins"
    },
    {
      "name": "Q897",
      "description": "Multiple congenital malformations, not elsewhere classified"
    },
    {
      "name": "Q898",
      "description": "Other specified congenital malformations"
    },
    {
      "name": "Q899",
      "description": "Congenital malformation, unspecified"
    },
    {
      "name": "Q900",
      "description": "Trisomy 21, meiotic nondisjunction"
    },
    {
      "name": "Q901",
      "description": "Trisomy 21, mosaicism (mitotic nondisjunction)"
    },
    {
      "name": "Q902",
      "description": "Trisomy 21, translocation"
    },
    {
      "name": "Q909",
      "description": "Down’s syndrome, unspecified"
    },
    {
      "name": "Q910",
      "description": "Trisomy 18, meiotic nondisjunction"
    },
    {
      "name": "Q911",
      "description": "Trisomy 18, mosaicism (mitotic nondisjunction)"
    },
    {
      "name": "Q912",
      "description": "Trisomy 18, translocation"
    },
    {
      "name": "Q913",
      "description": "Edward’s syndrome, unspecified"
    },
    {
      "name": "Q914",
      "description": "Trisomy 13, meiotic nondisjunction"
    },
    {
      "name": "Q915",
      "description": "Trisomy 13, mosaicism (mitotic nondisjunction)"
    },
    {
      "name": "Q916",
      "description": "Trisomy 13, translocation"
    },
    {
      "name": "Q917",
      "description": "Patau’s syndrome, unspecified"
    },
    {
      "name": "Q920",
      "description": "Whole chromosome trisomy, meiotic nondisjunction"
    },
    {
      "name": "Q921",
      "description": "Whole chromosome trisomy, mosaicism (mitotic nondisjunction)"
    },
    {
      "name": "Q922",
      "description": "Major partial trisomy"
    },
    {
      "name": "Q923",
      "description": "Minor partial trisomy"
    },
    {
      "name": "Q924",
      "description": "Duplications seen only at prometaphase"
    },
    {
      "name": "Q925",
      "description": "Duplications with other complex rearrangements"
    },
    {
      "name": "Q926",
      "description": "Extra marker chromosomes"
    },
    {
      "name": "Q927",
      "description": "Triploidy and polyploidy"
    },
    {
      "name": "Q928",
      "description": "Other specified trisomies and partial trisomies of autosomes"
    },
    {
      "name": "Q929",
      "description": "Trisomy and partial trisomy of autosomes, unspecified"
    },
    {
      "name": "Q930",
      "description": "Whole chromosome monosomy, meiotic nondisjunction"
    },
    {
      "name": "Q931",
      "description": "Whole chromosome monosomy, mosaicism (mitotic nondisjunction)"
    },
    {
      "name": "Q932",
      "description": "Chromosome replaced with ring or dicentric"
    },
    {
      "name": "Q933",
      "description": "Deletion of short arm of chromosome 4"
    },
    {
      "name": "Q934",
      "description": "Deletion of short arm of chromosome 5"
    },
    {
      "name": "Q935",
      "description": "Other deletions of part of a chromosome"
    },
    {
      "name": "Q936",
      "description": "Deletions seen only at prometaphase"
    },
    {
      "name": "Q937",
      "description": "Deletions with other complex rearrangements"
    },
    {
      "name": "Q938",
      "description": "Other deletions from the autosomes"
    },
    {
      "name": "Q939",
      "description": "Deletion from autosomes, unspecified"
    },
    {
      "name": "Q950",
      "description": "Balanced translocation and insertion in normal individual"
    },
    {
      "name": "Q951",
      "description": "Chromosome inversion in normal individual"
    },
    {
      "name": "Q952",
      "description": "Balanced autosomal rearrangement in abnormal individual"
    },
    {
      "name": "Q953",
      "description": "Balanced sex/autosomal rearrangement in abnormal individual"
    },
    {
      "name": "Q954",
      "description": "Individuals with marker heterochromatin"
    },
    {
      "name": "Q955",
      "description": "Individuals with autosomal fragile site"
    },
    {
      "name": "Q958",
      "description": "Other balanced rearrangements and structural markers"
    },
    {
      "name": "Q959",
      "description": "Balanced rearrangement and structural marker,unspecified"
    },
    {
      "name": "Q960",
      "description": "Karyotype 45,X"
    },
    {
      "name": "Q961",
      "description": "Karyotype 46,X iso (Xq)"
    },
    {
      "name": "Q962",
      "description": "Karyotype 46,X with abnormal sex chromosome,except iso (Xq)"
    },
    {
      "name": "Q963",
      "description": "Mosaicism, 45,X/46,XX or XY"
    },
    {
      "name": "Q964",
      "description": "Mosaicism, 45,X/other cell line(s) with abnormal sex chromosome"
    },
    {
      "name": "Q968",
      "description": "Other variants of Turner’s syndrome"
    },
    {
      "name": "Q969",
      "description": "Turner’s syndrome, unspecified"
    },
    {
      "name": "Q970",
      "description": "Karyotype 47,XXX"
    },
    {
      "name": "Q971",
      "description": "Female with more than three X chromosomes"
    },
    {
      "name": "Q972",
      "description": "Mosaicism, lines with various numbers of X chromosomes"
    },
    {
      "name": "Q973",
      "description": "Female with 46,XY karyotype"
    },
    {
      "name": "Q978",
      "description": "Other specified sex chromosome abnormalities, female phenotype"
    },
    {
      "name": "Q979",
      "description": "Sex chromosome abnormality, female phenotype,unspecified"
    },
    {
      "name": "Q980",
      "description": "Klinefelter’s syndrome karyotype 47,XXY"
    },
    {
      "name": "Q981",
      "description": "Klinefelter’s syndrome, male with more than two X chromosomes"
    },
    {
      "name": "Q982",
      "description": "Klinefelter’s syndrome, male with 46,XX karyotype"
    },
    {
      "name": "Q983",
      "description": "Other male with 46,XX karyotype"
    },
    {
      "name": "Q984",
      "description": "Klinefelter’s syndrome, unspecified"
    },
    {
      "name": "Q985",
      "description": "Karyotype 47,XYY"
    },
    {
      "name": "Q986",
      "description": "Male with structurally abnormal sex chromosome"
    },
    {
      "name": "Q987",
      "description": "Male with sex chromosome mosaicism"
    },
    {
      "name": "Q988",
      "description": "Other specified sex chromosome abnormalities,male phenotype"
    },
    {
      "name": "Q989",
      "description": "Sex chromosome abnormality, male phenotype,unspecified"
    },
    {
      "name": "Q990",
      "description": "Chimera 46,XX/46,XY"
    },
    {
      "name": "Q991",
      "description": "46,XX true hermaphrodite"
    },
    {
      "name": "Q992",
      "description": "Fragile X chromosome"
    },
    {
      "name": "Q998",
      "description": "Other specified chromosome abnormalities"
    },
    {
      "name": "Q999",
      "description": "Chromosomal abnormality, unspecified"
    },
    {
      "name": "R000",
      "description": "Tachycardia, unspecified"
    },
    {
      "name": "R001",
      "description": "Bradycardia, unspecified"
    },
    {
      "name": "R002",
      "description": "Palpitations"
    },
    {
      "name": "R008",
      "description": "Other and unspecified abnormalities of heart beat"
    },
    {
      "name": "R010",
      "description": "Benign and innocent cardiac murmurs"
    },
    {
      "name": "R011",
      "description": "Cardiac murmur, unspecified"
    },
    {
      "name": "R012",
      "description": "Other cardiac sounds"
    },
    {
      "name": "R02",
      "description": "Gangrene, not elsewhere classified"
    },
    {
      "name": "R030",
      "description": "Elevated blood-pressure reading, without diagnosis of hypertension"
    },
    {
      "name": "R031",
      "description": "Nonspecific low blood-pressure reading"
    },
    {
      "name": "R040",
      "description": "Epistaxis"
    },
    {
      "name": "R041",
      "description": "Haemorrhage from throat"
    },
    {
      "name": "R042",
      "description": "Haemoptysis"
    },
    {
      "name": "R043",
      "description": "Haemorrhage from other sites in respiratory passages"
    },
    {
      "name": "R049",
      "description": "Haemorrhage from respiratory passages,unspecified"
    },
    {
      "name": "R05",
      "description": "Cough"
    },
    {
      "name": "R060",
      "description": "Dyspnoea"
    },
    {
      "name": "R061",
      "description": "Stridor"
    },
    {
      "name": "R062",
      "description": "Wheezing"
    },
    {
      "name": "R063",
      "description": "Periodic breathing"
    },
    {
      "name": "R064",
      "description": "Hyperventilation"
    },
    {
      "name": "R065",
      "description": "Mouth breathing"
    },
    {
      "name": "R066",
      "description": "Hiccough"
    },
    {
      "name": "R067",
      "description": "Sneezing"
    },
    {
      "name": "R068",
      "description": "Other and unspecified abnormalities of breathing"
    },
    {
      "name": "R070",
      "description": "Pain in throat"
    },
    {
      "name": "R071",
      "description": "Chest pain on breathing"
    },
    {
      "name": "R072",
      "description": "Precordial pain"
    },
    {
      "name": "R073",
      "description": "Other chest pain"
    },
    {
      "name": "R074",
      "description": "Chest pain, unspecified"
    },
    {
      "name": "R090",
      "description": "Asphyxia"
    },
    {
      "name": "R091",
      "description": "Pleurisy"
    },
    {
      "name": "R092",
      "description": "Respiratory arrest"
    },
    {
      "name": "R093",
      "description": "Abnormal sputum"
    },
    {
      "name": "R098",
      "description": "Other specified symptoms and signs involving the circulatory and respiratory systems"
    },
    {
      "name": "R100",
      "description": "Acute abdomen"
    },
    {
      "name": "R101",
      "description": "Pain localized to upper abdomen"
    },
    {
      "name": "R102",
      "description": "Pelvic and perineal pain"
    },
    {
      "name": "R103",
      "description": "Pain localized to other parts of lower abdomen"
    },
    {
      "name": "R1040",
      "description": "Idiopathic recurrent abdominal pain of childhood"
    },
    {
      "name": "R1048",
      "description": "Other abdominal pain"
    },
    {
      "name": "R1049",
      "description": "Unspecified abdominal pain"
    },
    {
      "name": "R110",
      "description": "Cyclic vomiting"
    },
    {
      "name": "R119",
      "description": "Nausea and vomiting, unspecified"
    },
    {
      "name": "R12",
      "description": "Heartburn"
    },
    {
      "name": "R13",
      "description": "Dysphagia"
    },
    {
      "name": "R14",
      "description": "Flatulence and related conditions"
    },
    {
      "name": "R15",
      "description": "Faecal incontinence"
    },
    {
      "name": "R160",
      "description": "Hepatomegaly, not elsewhere classified"
    },
    {
      "name": "R161",
      "description": "Splenomegaly, not elsewhere classified"
    },
    {
      "name": "R162",
      "description": "Hepatomegaly with splenomegaly, not elsewhere classified"
    },
    {
      "name": "R170",
      "description": "Cholestatic jaundice"
    },
    {
      "name": "R171",
      "description": "Obstructive jaundice"
    },
    {
      "name": "R178",
      "description": "Other jaundice"
    },
    {
      "name": "R179",
      "description": "Jaundice, unspecified"
    },
    {
      "name": "R18",
      "description": "Ascites"
    },
    {
      "name": "R190",
      "description": "Intra-abdominal and pelvic swelling, mass and lump"
    },
    {
      "name": "R191",
      "description": "Abnormal bowel sounds"
    },
    {
      "name": "R192",
      "description": "Visible peristalsis"
    },
    {
      "name": "R193",
      "description": "Abdominal rigidity"
    },
    {
      "name": "R194",
      "description": "Change in bowel habit"
    },
    {
      "name": "R195",
      "description": "Other faecal abnormalities"
    },
    {
      "name": "R196",
      "description": "Halitosis"
    },
    {
      "name": "R198",
      "description": "Other specified symptoms and signs involving the digestive system and abdomen"
    },
    {
      "name": "R200",
      "description": "Anaesthesia of skin"
    },
    {
      "name": "R201",
      "description": "Hypoaesthesia of skin"
    },
    {
      "name": "R202",
      "description": "Paraesthesia of skin"
    },
    {
      "name": "R203",
      "description": "Hyperaesthesia"
    },
    {
      "name": "R208",
      "description": "Other and unspecified disturbances of skin sensation"
    },
    {
      "name": "R21",
      "description": "Rash and other nonspecific skin eruption"
    },
    {
      "name": "R220",
      "description": "Localized swelling, mass and lump, head"
    },
    {
      "name": "R221",
      "description": "Localized swelling, mass and lump, neck"
    },
    {
      "name": "R222",
      "description": "Localized swelling, mass and lump, trunk"
    },
    {
      "name": "R223",
      "description": "Localized swelling, mass and lump, upper limb"
    },
    {
      "name": "R224",
      "description": "Localized swelling, mass and lump, lower limb"
    },
    {
      "name": "R227",
      "description": "Localized swelling, mass and lump, multiple sites"
    },
    {
      "name": "R229",
      "description": "Localized swelling, mass and lump, unspecified"
    },
    {
      "name": "R230",
      "description": "Cyanosis"
    },
    {
      "name": "R231",
      "description": "Pallor"
    },
    {
      "name": "R232",
      "description": "Flushing"
    },
    {
      "name": "R233",
      "description": "Spontaneous ecchymoses"
    },
    {
      "name": "R234",
      "description": "Changes in skin texture"
    },
    {
      "name": "R238",
      "description": "Other and unspecified skin changes"
    },
    {
      "name": "R250",
      "description": "Abnormal head movements"
    },
    {
      "name": "R251",
      "description": "Tremor"
    },
    {
      "name": "R252",
      "description": "Cramp and spasm"
    },
    {
      "name": "R253",
      "description": "Fasciculation"
    },
    {
      "name": "R258",
      "description": "Other and unspecified abnormal involuntary movements"
    },
    {
      "name": "R260",
      "description": "Ataxic gait"
    },
    {
      "name": "R261",
      "description": "Paralytic gait"
    },
    {
      "name": "R262",
      "description": "Difficulty in walking, not elsewhere classified"
    },
    {
      "name": "R263",
      "description": "Immobility"
    },
    {
      "name": "R268",
      "description": "Other and unspecified abnormalities of gait and mobility"
    },
    {
      "name": "R270",
      "description": "Ataxia, unspecified"
    },
    {
      "name": "R278",
      "description": "Other and unspecified lack of coordination"
    },
    {
      "name": "R290",
      "description": "Tetany"
    },
    {
      "name": "R291",
      "description": "Meningismus"
    },
    {
      "name": "R292",
      "description": "Abnormal reflex"
    },
    {
      "name": "R293",
      "description": "Abnormal posture"
    },
    {
      "name": "R294",
      "description": "Clicking hip"
    },
    {
      "name": "R296",
      "description": "Tendency to fall, not elsewhere classified"
    },
    {
      "name": "R298",
      "description": "Other and unspecified symptoms and signs involving the nervous and musculoskeletal systems"
    },
    {
      "name": "R300",
      "description": "Dysuria"
    },
    {
      "name": "R301",
      "description": "Vesical tenesmus"
    },
    {
      "name": "R309",
      "description": "Painful micturition, unspecified"
    },
    {
      "name": "R31",
      "description": "Unspecified haematuria"
    },
    {
      "name": "R32",
      "description": "Unspecified urinary incontinence"
    },
    {
      "name": "R33",
      "description": "Retention of urine"
    },
    {
      "name": "R34",
      "description": "Anuria and oliguria"
    },
    {
      "name": "R35",
      "description": "Polyuria"
    },
    {
      "name": "R36",
      "description": "Urethral discharge"
    },
    {
      "name": "R390",
      "description": "Extravasation of urine"
    },
    {
      "name": "R391",
      "description": "Other difficulties with micturition"
    },
    {
      "name": "R392",
      "description": "Extrarenal uraemia"
    },
    {
      "name": "R398",
      "description": "Other and unspecified symptoms and signs involving the urinary system"
    },
    {
      "name": "R400",
      "description": "Somnolence"
    },
    {
      "name": "R401",
      "description": "Stupor"
    },
    {
      "name": "R402",
      "description": "Coma, unspecified"
    },
    {
      "name": "R409",
      "description": "Alteration of consciousness, unspecified"
    },
    {
      "name": "R410",
      "description": "Disorientation, unspecified"
    },
    {
      "name": "R411",
      "description": "Anterograde amnesia"
    },
    {
      "name": "R412",
      "description": "Retrograde amnesia"
    },
    {
      "name": "R413",
      "description": "Other amnesia"
    },
    {
      "name": "R418",
      "description": "Other and unspecified symptoms and signs involving cognitive functions and awareness"
    },
    {
      "name": "R42",
      "description": "Dizziness and giddiness"
    },
    {
      "name": "R430",
      "description": "Anosmia"
    },
    {
      "name": "R431",
      "description": "Parosmia"
    },
    {
      "name": "R432",
      "description": "Parageusia"
    },
    {
      "name": "R438",
      "description": "Other and unspecified disturbances of smell and taste"
    },
    {
      "name": "R440",
      "description": "Auditory hallucinations"
    },
    {
      "name": "R441",
      "description": "Visual hallucinations"
    },
    {
      "name": "R442",
      "description": "Other hallucinations"
    },
    {
      "name": "R443",
      "description": "Hallucinations, unspecified"
    },
    {
      "name": "R448",
      "description": "Other and unspecified symptoms and signs involving general sensations and perceptions"
    },
    {
      "name": "R450",
      "description": "Nervousness"
    },
    {
      "name": "R451",
      "description": "Restlessness and agitation"
    },
    {
      "name": "R452",
      "description": "Unhappiness"
    },
    {
      "name": "R453",
      "description": "Demoralization and apathy"
    },
    {
      "name": "R454",
      "description": "Irritability and anger"
    },
    {
      "name": "R455",
      "description": "Hostility"
    },
    {
      "name": "R456",
      "description": "Physical violence"
    },
    {
      "name": "R457",
      "description": "State of emotional shock and stress, unspecified"
    },
    {
      "name": "R458",
      "description": "Other symptoms and signs involving emotional state"
    },
    {
      "name": "R460",
      "description": "Very low level of personal hygiene"
    },
    {
      "name": "R461",
      "description": "Bizarre personal appearance"
    },
    {
      "name": "R462",
      "description": "Strange and inexplicable behaviour"
    },
    {
      "name": "R463",
      "description": "Overactivity"
    },
    {
      "name": "R464",
      "description": "Slowness and poor responsiveness"
    },
    {
      "name": "R465",
      "description": "Suspiciousness and marked evasiveness"
    },
    {
      "name": "R466",
      "description": "Undue concern and preoccupation with stressful events"
    },
    {
      "name": "R467",
      "description": "Verbosity and circumstantial detail obscuring reason for contact"
    },
    {
      "name": "R468",
      "description": "Other symptoms and signs involving appearance and behaviour"
    },
    {
      "name": "R470",
      "description": "Dysphasia and aphasia"
    },
    {
      "name": "R471",
      "description": "Dysarthria and anarthria"
    },
    {
      "name": "R478",
      "description": "Other and unspecified speech disturbances"
    },
    {
      "name": "R480",
      "description": "Dyslexia and alexia"
    },
    {
      "name": "R481",
      "description": "Agnosia"
    },
    {
      "name": "R482",
      "description": "Apraxia"
    },
    {
      "name": "R488",
      "description": "Other and unspecified symbolic dysfunctions"
    },
    {
      "name": "R490",
      "description": "Dysphonia"
    },
    {
      "name": "R491",
      "description": "Aphonia"
    },
    {
      "name": "R492",
      "description": "Hypernasality and hyponasality"
    },
    {
      "name": "R498",
      "description": "Other and unspecified voice disturbances"
    },
    {
      "name": "R502",
      "description": "Drug-induced fever"
    },
    {
      "name": "R508",
      "description": "Other specified fever"
    },
    {
      "name": "R509",
      "description": "Fever, unspecified"
    },
    {
      "name": "R510",
      "description": "Headache, unspecified"
    },
    {
      "name": "R511",
      "description": "Facial pain, unspecified "
    },
    {
      "name": "R520",
      "description": "Acute pain"
    },
    {
      "name": "R521",
      "description": "Chronic intractable pain"
    },
    {
      "name": "R522",
      "description": "Other chronic pain"
    },
    {
      "name": "R529",
      "description": "Pain, unspecified"
    },
    {
      "name": "R53",
      "description": "Malaise and fatigue"
    },
    {
      "name": "R54",
      "description": "Senility"
    },
    {
      "name": "R55",
      "description": "Syncope and collapse"
    },
    {
      "name": "R560",
      "description": "Febrile convulsions"
    },
    {
      "name": "R568",
      "description": "Other and unspecified convulsions"
    },
    {
      "name": "R570",
      "description": "Cardiogenic shock"
    },
    {
      "name": "R571",
      "description": "Hypovolaemic shock"
    },
    {
      "name": "R572",
      "description": "Septic shock"
    },
    {
      "name": "R578",
      "description": "Other shock"
    },
    {
      "name": "R579",
      "description": "Shock, unspecifie"
    },
    {
      "name": "R58",
      "description": "Haemorrhage, not elsewhere classified"
    },
    {
      "name": "R590",
      "description": "Localized enlarged lymph nodes"
    },
    {
      "name": "R591",
      "description": "Generalized enlarged lymph nodes"
    },
    {
      "name": "R599",
      "description": "Enlarged lymph nodes, unspecified"
    },
    {
      "name": "R600",
      "description": "Localized oedema"
    },
    {
      "name": "R601",
      "description": "Generalized oedema"
    },
    {
      "name": "R609",
      "description": "Oedema, unspecified"
    },
    {
      "name": "R610",
      "description": "Localized hyperhidrosis"
    },
    {
      "name": "R611",
      "description": "Generalized hyperhidrosis"
    },
    {
      "name": "R619",
      "description": "Hyperhidrosis, unspecified"
    },
    {
      "name": "R620",
      "description": "Delayed milestone"
    },
    {
      "name": "R628",
      "description": "Other lack of expected normal physiological development"
    },
    {
      "name": "R629",
      "description": "Lack of expected normal physiological development, unspecified"
    },
    {
      "name": "R630",
      "description": "Anorexia"
    },
    {
      "name": "R631",
      "description": "Polydipsia"
    },
    {
      "name": "R632",
      "description": "Polyphagia"
    },
    {
      "name": "R633",
      "description": "Feeding difficulties and mismanagement"
    },
    {
      "name": "R634",
      "description": "Abnormal weight loss"
    },
    {
      "name": "R635",
      "description": "Abnormal weight gain"
    },
    {
      "name": "R636",
      "description": "Insufficient intake of food and water due to self neglect"
    },
    {
      "name": "R638",
      "description": "Other symptoms and signs concerning food and fluid intake"
    },
    {
      "name": "R64",
      "description": "Cachexia"
    },
    {
      "name": "R650",
      "description": "Systemic inflammatory response syndrome of infectious origin without organ failure"
    },
    {
      "name": "R651",
      "description": "Systemic inflammatory response syndrome of infectious origin with organ failure"
    },
    {
      "name": "R652",
      "description": "Systemic inflammatory response syndrome of non-infectious origin without organ failure"
    },
    {
      "name": "R653",
      "description": "Systemic inflammatory response syndrome of non-infectious origin with organ failure"
    },
    {
      "name": "R659",
      "description": "Systemic inflammatory response syndrome,unspecified"
    },
    {
      "name": "R680",
      "description": "Hypothermia, not associated with low environmental temperature"
    },
    {
      "name": "R681",
      "description": "Nonspecific symptoms peculiar to infancy"
    },
    {
      "name": "R682",
      "description": "Dry mouth, unspecified"
    },
    {
      "name": "R683",
      "description": "Clubbing of fingers"
    },
    {
      "name": "R688",
      "description": "Other specified general symptoms and signs"
    },
    {
      "name": "R69",
      "description": "Unknown and unspecified causes of morbidity"
    },
    {
      "name": "R700",
      "description": "Elevated erythrocyte sedimentation rate"
    },
    {
      "name": "R701",
      "description": "Abnormal plasma viscosity"
    },
    {
      "name": "R71",
      "description": "Abnormality of red blood cells"
    },
    {
      "name": "R72",
      "description": "Abnormality of white blood cells, not elsewhere classified"
    },
    {
      "name": "R730",
      "description": "Abnormal glucose tolerance test"
    },
    {
      "name": "R731",
      "description": "Impaired fasting glucose"
    },
    {
      "name": "R739",
      "description": "Hyperglycaemia, unspecified"
    },
    {
      "name": "R740",
      "description": "Elevation of levels of transaminase and lactic acid dehydrogenase [LDH]"
    },
    {
      "name": "R748",
      "description": "Abnormal levels of other serum enzymes"
    },
    {
      "name": "R749",
      "description": "Abnormal level of unspecified serum enzyme"
    },
    {
      "name": "R75",
      "description": "Laboratory evidence of human immunodeficiency virus [HIV]"
    },
    {
      "name": "R760",
      "description": "Raised antibody titre"
    },
    {
      "name": "R761",
      "description": "Abnormal reaction to tuberculin test"
    },
    {
      "name": "R762",
      "description": "False-positive serological test for syphilis"
    },
    {
      "name": "R768",
      "description": "Other specified abnormal immunological findings in serum"
    },
    {
      "name": "R769",
      "description": "Abnormal immunological finding in serum,unspecified"
    },
    {
      "name": "R770",
      "description": "Abnormality of albumin"
    },
    {
      "name": "R771",
      "description": "Abnormality of globulin"
    },
    {
      "name": "R772",
      "description": "Abnormality of alphafetoprotein"
    },
    {
      "name": "R778",
      "description": "Other specified abnormalities of plasma proteins"
    },
    {
      "name": "R779",
      "description": "Abnormality of plasma protein, unspecified"
    },
    {
      "name": "R780",
      "description": "Finding of alcohol in blood"
    },
    {
      "name": "R781",
      "description": "Finding of opiate drug in blood"
    },
    {
      "name": "R782",
      "description": "Finding of cocaine in blood"
    },
    {
      "name": "R783",
      "description": "Finding of hallucinogen in blood"
    },
    {
      "name": "R784",
      "description": "Finding of other drugs of addictive potential in blood"
    },
    {
      "name": "R785",
      "description": "Finding of psychotropic drug in blood"
    },
    {
      "name": "R786",
      "description": "Finding of steroid agent in blood"
    },
    {
      "name": "R787",
      "description": "Finding of abnormal level of heavy metals in blood"
    },
    {
      "name": "R788",
      "description": "Finding of other specified substances, not normally found in blood"
    },
    {
      "name": "R789",
      "description": "Finding of unspecified substance, not normally found in blood"
    },
    {
      "name": "R790",
      "description": "Abnormal level of blood mineral"
    },
    {
      "name": "R798",
      "description": "Other specified abnormal findings of blood chemistry"
    },
    {
      "name": "R799",
      "description": "Abnormal finding of blood chemistry, unspecified"
    },
    {
      "name": "R80",
      "description": "Isolated proteinuria"
    },
    {
      "name": "R81",
      "description": "Glycosuria"
    },
    {
      "name": "R820",
      "description": "Chyluria"
    },
    {
      "name": "R821",
      "description": "Myoglobinuria"
    },
    {
      "name": "R822",
      "description": "Biliuria"
    },
    {
      "name": "R823",
      "description": "Haemoglobinuria"
    },
    {
      "name": "R824",
      "description": "Acetonuria"
    },
    {
      "name": "R825",
      "description": "Elevated urine levels of drugs, medicaments and biological substances"
    },
    {
      "name": "R826",
      "description": "Abnormal urine levels of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R827",
      "description": "Abnormal findings on microbiological examination of urine"
    },
    {
      "name": "R828",
      "description": "Abnormal findings on cytological and histological examination of urine"
    },
    {
      "name": "R829",
      "description": "Other and unspecified abnormal findings in urine"
    },
    {
      "name": "R830",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal level of enzymes"
    },
    {
      "name": "R831",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal level of hormones"
    },
    {
      "name": "R832",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal level of other drugs,medicaments and biological substances"
    },
    {
      "name": "R833",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R834",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal immunological findings"
    },
    {
      "name": "R835",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal microbiological findings "
    },
    {
      "name": "R836",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal cytological findings "
    },
    {
      "name": "R837",
      "description": "Abnormal findings in cerebrospinal fluid at abnormal histological findings"
    },
    {
      "name": "R838",
      "description": "Abnormal findings in cerebrospinal fluid at other abnormal findings "
    },
    {
      "name": "R839",
      "description": "Abnormal findings in cerebrospinal fluid at unspecified abnormal finding"
    },
    {
      "name": "R840",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal level of enzymes"
    },
    {
      "name": "R841",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal level of hormones"
    },
    {
      "name": "R842",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal level of other drugs,medicaments and biological substances"
    },
    {
      "name": "R843",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R844",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal immunological findings"
    },
    {
      "name": "R845",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal microbiological findings "
    },
    {
      "name": "R846",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal cytological findings "
    },
    {
      "name": "R847",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at abnormal histological findings"
    },
    {
      "name": "R848",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at other abnormal findings "
    },
    {
      "name": "R849",
      "description": "Abnormal findings in specimens from respiratory organs and thorax at unspecified abnormal finding"
    },
    {
      "name": "R850",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal level of enzymes"
    },
    {
      "name": "R851",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal level of hormones"
    },
    {
      "name": "R852",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal level of other drugs,medicaments and biological substances"
    },
    {
      "name": "R853",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R854",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal immunological findings"
    },
    {
      "name": "R855",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal microbiological findings "
    },
    {
      "name": "R856",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal cytological findings "
    },
    {
      "name": "R857",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at abnormal histological findings"
    },
    {
      "name": "R858",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at other abnormal findings "
    },
    {
      "name": "R859",
      "description": "Abnormal findings in specimens from digestive organs and abdominal cavity at unspecified abnormal finding"
    },
    {
      "name": "R860",
      "description": "Abnormal findings in specimens from male genital organs at abnormal level of enzymes"
    },
    {
      "name": "R861",
      "description": "Abnormal findings in specimens from male genital organs at abnormal level of hormones"
    },
    {
      "name": "R862",
      "description": "Abnormal findings in specimens from male genital organs at abnormal level of other drugs,medicaments and biological substances"
    },
    {
      "name": "R863",
      "description": "Abnormal findings in specimens from male genital organs at abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R864",
      "description": "Abnormal findings in specimens from male genital organs at abnormal immunological findings"
    },
    {
      "name": "R865",
      "description": "Abnormal findings in specimens from male genital organs at abnormal microbiological findings"
    },
    {
      "name": "R866",
      "description": "Abnormal findings in specimens from male genital organs at abnormal cytological findings "
    },
    {
      "name": "R867",
      "description": "Abnormal findings in specimens from male genital organs at abnormal histological findings"
    },
    {
      "name": "R868",
      "description": "Abnormal findings in specimens from male genital organs at other abnormal findings "
    },
    {
      "name": "R869",
      "description": "Abnormal findings in specimens from male genital organs at unspecified abnormal finding"
    },
    {
      "name": "R870",
      "description": "Abnormal findings in specimens from female genital organs  at abnormal level of enzymes"
    },
    {
      "name": "R871",
      "description": "Abnormal findings in specimens from female genital organs at abnormal level of hormones"
    },
    {
      "name": "R872",
      "description": "Abnormal findings in specimens from female genital organs at abnormal level of other drugs,medicaments and biological substances"
    },
    {
      "name": "R873",
      "description": "Abnormal findings in specimens from female genital organs at abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R874",
      "description": "Abnormal findings in specimens from female genital organs at abnormal immunological findings"
    },
    {
      "name": "R875",
      "description": "Abnormal findings in specimens from female genital organs at abnormal microbiological findings "
    },
    {
      "name": "R876",
      "description": "Abnormal findings in specimens from female genital organs at abnormal cytological findings "
    },
    {
      "name": "R8760",
      "description": "Atypical squamous cells of undetermined significance [ASCUS]"
    },
    {
      "name": "R8763",
      "description": "Atypical glandular cells of undetermined significance [AGUS]"
    },
    {
      "name": "R8768",
      "description": "Other abnormal cytological findings in specimens from female genital organs"
    },
    {
      "name": "R8769",
      "description": "Abnormal cytologic finding in specimens from female genital organs, unspecified"
    },
    {
      "name": "R877",
      "description": "Abnormal findings in specimens from female genital organs at abnormal histological findings"
    },
    {
      "name": "R878",
      "description": "Abnormal findings in specimens from female genital organs at other abnormal findings "
    },
    {
      "name": "R879",
      "description": "Abnormal findings in specimens from female genital organs at unspecified abnormal finding"
    },
    {
      "name": "R890",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal level of enzymes"
    },
    {
      "name": "R891",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal level of hormones"
    },
    {
      "name": "R892",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal level of other drugs, medicaments and biological substances"
    },
    {
      "name": "R893",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal level of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "R894",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal immunological findings"
    },
    {
      "name": "R895",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal microbiological findings"
    },
    {
      "name": "R896",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal cytological findings"
    },
    {
      "name": "R897",
      "description": "Abnormal findings in specimens from other organs, systems and tissues abnormal histological findings"
    },
    {
      "name": "R898",
      "description": "Abnormal findings in specimens from other organs, systems and tissues other abnormal findings"
    },
    {
      "name": "R899",
      "description": "Abnormal findings in specimens from other organs, systems and tissues unspecified abnormal finding"
    },
    {
      "name": "R900",
      "description": "Intracranial space-occupying lesion"
    },
    {
      "name": "R908",
      "description": "Other abnormal findings on diagnostic imaging of central nervous system"
    },
    {
      "name": "R91",
      "description": "Abnormal findings on diagnostic imaging of lung"
    },
    {
      "name": "R92",
      "description": "Abnormal findings on diagnostic imaging of breast"
    },
    {
      "name": "R930",
      "description": "Abnormal findings on diagnostic imaging of skull and head, not elsewhere classified"
    },
    {
      "name": "R931",
      "description": "Abnormal findings on diagnostic imaging of heart and coronary circulation"
    },
    {
      "name": "R932",
      "description": "Abnormal findings on diagnostic imaging of liver and biliary tract"
    },
    {
      "name": "R933",
      "description": "Abnormal findings on diagnostic imaging of othe parts of digestive tract"
    },
    {
      "name": "R934",
      "description": "Abnormal findings on diagnostic imaging of urinary organs"
    },
    {
      "name": "R935",
      "description": "Abnormal findings on diagnostic imaging of other abdominal regions, including retroperitoneum"
    },
    {
      "name": "R936",
      "description": "Abnormal findings on diagnostic imaging of limbs"
    },
    {
      "name": "R937",
      "description": "Abnormal findings on diagnostic imaging of other parts of musculoskeletal system"
    },
    {
      "name": "R938",
      "description": "Abnormal findings on diagnostic imaging of other specified body structures"
    },
    {
      "name": "R940",
      "description": "Abnormal results of function studies of central nervous system"
    },
    {
      "name": "R941",
      "description": "Abnormal results of function studies of peripheral nervous system and special senses"
    },
    {
      "name": "R942",
      "description": "Abnormal results of pulmonary function studies"
    },
    {
      "name": "R943",
      "description": "Abnormal results of cardiovascular function studies"
    },
    {
      "name": "R944",
      "description": "Abnormal results of kidney function studies"
    },
    {
      "name": "R945",
      "description": "Abnormal results of liver function studies"
    },
    {
      "name": "R946",
      "description": "Abnormal results of thyroid function studies"
    },
    {
      "name": "R947",
      "description": "Abnormal results of other endocrine function studies"
    },
    {
      "name": "R948",
      "description": "Abnormal results of function studies of other organs and systems"
    },
    {
      "name": "R950",
      "description": "Sudden infant death syndrome with mention of autopsy"
    },
    {
      "name": "R959",
      "description": "Sudden infant death syndrome without mention of autopsy"
    },
    {
      "name": "R960",
      "description": "Instantaneous death"
    },
    {
      "name": "R961",
      "description": "Death occurring less than 24 hours from onset of symptoms, not otherwise explained"
    },
    {
      "name": "R962",
      "description": "Sudden unexpected death syndrome"
    },
    {
      "name": "R98",
      "description": "Unattended death"
    },
    {
      "name": "R99",
      "description": "Other ill-defined and unspecified causes of mortality"
    },
    {
      "name": "S000",
      "description": "Superficial injury of scalp"
    },
    {
      "name": "S001",
      "description": "Contusion of eyelid and periocular area"
    },
    {
      "name": "S002",
      "description": "Other superficial injuries of eyelid and periocular area"
    },
    {
      "name": "S003",
      "description": "Superficial injury of nose"
    },
    {
      "name": "S004",
      "description": "Superficial injury of ear"
    },
    {
      "name": "S0050",
      "description": "Superficial injury of internal cheek"
    },
    {
      "name": "S0051",
      "description": "Superficial injury of other parts of mouth, including tongue"
    },
    {
      "name": "S0052",
      "description": "Superficial injury of lip"
    },
    {
      "name": "S007",
      "description": "Multiple superficial injuries of head"
    },
    {
      "name": "S008",
      "description": "Superficial injury of other parts of head"
    },
    {
      "name": "S009",
      "description": "Superficial injury of head, part unspecified"
    },
    {
      "name": "S010",
      "description": "Open wound of scalp"
    },
    {
      "name": "S011",
      "description": "Open wound of eyelid and periocular area"
    },
    {
      "name": "S012",
      "description": "Open wound of nose"
    },
    {
      "name": "S013",
      "description": "Open wound of ear"
    },
    {
      "name": "S0140",
      "description": "Open wound of cheek"
    },
    {
      "name": "S0141",
      "description": "Open wound of temporomandibular area"
    },
    {
      "name": "S0147",
      "description": "Open wound of cheek with open wound of temporomandibular area"
    },
    {
      "name": "S0150",
      "description": "Open wound of oral cavity"
    },
    {
      "name": "S0151",
      "description": "Open wound of lip"
    },
    {
      "name": "S0157",
      "description": "Open wound of oral cavity with open wound of lip"
    },
    {
      "name": "S017",
      "description": "Multiple open wounds of head"
    },
    {
      "name": "S018",
      "description": "Open wound of other parts of head"
    },
    {
      "name": "S019",
      "description": "Open wound of head, part unspecified"
    },
    {
      "name": "S0200",
      "description": "Closed fracture of vault of skull"
    },
    {
      "name": "S0201",
      "description": "Open fracture of vault of skull"
    },
    {
      "name": "S0210",
      "description": "Closed fracture of base of skull"
    },
    {
      "name": "S0211",
      "description": "Open fracture of base of skull"
    },
    {
      "name": "S0220",
      "description": "Closed fracture of nasal bones"
    },
    {
      "name": "S0221",
      "description": "Open fracture of nasal bones"
    },
    {
      "name": "S0230",
      "description": "Closed fracture of orbital floor"
    },
    {
      "name": "S0231",
      "description": "Open fracture of orbital floor"
    },
    {
      "name": "S02400",
      "description": "Closed fracture of maxillary alveolar process"
    },
    {
      "name": "S02401",
      "description": "Closed fracture of malar bone [zygoma]"
    },
    {
      "name": "S02402",
      "description": "Closed fracture of maxillary bones"
    },
    {
      "name": "S02407",
      "description": "Multiple closed fractures of malar and maxillary bones"
    },
    {
      "name": "S02410",
      "description": "Open fracture of maxillary alveolar process"
    },
    {
      "name": "S02411",
      "description": "Open fracture of malar bone [zygoma]"
    },
    {
      "name": "S02412",
      "description": "Open fracture of maxillary bones"
    },
    {
      "name": "S02417",
      "description": "Multiple open fractures of malar and maxillary bones"
    },
    {
      "name": "S02500",
      "description": "Closed fracture of enamel of tooth only"
    },
    {
      "name": "S02501",
      "description": "Closed fracture of crown of tooth without pulpal involvement"
    },
    {
      "name": "S02502",
      "description": "Closed fracture of crown of tooth with pulpal involvement"
    },
    {
      "name": "S02503",
      "description": "Closed fracture of root of tooth"
    },
    {
      "name": "S02504",
      "description": "Closed fracture of crown with fracture of root of tooth"
    },
    {
      "name": "S02507",
      "description": "Mutiple closed fractures of teeth"
    },
    {
      "name": "S02509",
      "description": "Closed fracture of tooth, unspecified"
    },
    {
      "name": "S02510",
      "description": "Open fracture of enamel of tooth only"
    },
    {
      "name": "S02511",
      "description": "Open fracture of crown of tooth without pulpal involvement"
    },
    {
      "name": "S02512",
      "description": "Open fracture of crown of tooth with pulpal involvement"
    },
    {
      "name": "S02513",
      "description": "Open fracture of root of tooth"
    },
    {
      "name": "S02514",
      "description": "Open fracture of crown with fracture of root of tooth"
    },
    {
      "name": "S02517",
      "description": "Multiple open fractures of tooth"
    },
    {
      "name": "S02519",
      "description": "Open fracture of tooth, unspecified"
    },
    {
      "name": "S02600",
      "description": "Closed fracture of alveolar process"
    },
    {
      "name": "S02601",
      "description": "Closed fracture of body of mandible"
    },
    {
      "name": "S02602",
      "description": "Closed fracture of condyle"
    },
    {
      "name": "S02603",
      "description": "Closed fracture of coronoid process"
    },
    {
      "name": "S02604",
      "description": "Closed fracture of ramus"
    },
    {
      "name": "S02605",
      "description": "Closed fracture of symphysis"
    },
    {
      "name": "S02606",
      "description": "Closed fracture of angle"
    },
    {
      "name": "S02607",
      "description": "Multiple closed fractures of mandible"
    },
    {
      "name": "S02609",
      "description": "Closed fracture of mandible, part unspecified"
    },
    {
      "name": "S02610",
      "description": "Open fracture of alveolar process"
    },
    {
      "name": "S02611",
      "description": "Open fracture of body of mandible"
    },
    {
      "name": "S02612",
      "description": "Open fracture of condyle"
    },
    {
      "name": "S02613",
      "description": "Open fracture of coronoid process"
    },
    {
      "name": "S02614",
      "description": "Open fracture of ramus"
    },
    {
      "name": "S02615",
      "description": "Open fracture of symphysis"
    },
    {
      "name": "S02616",
      "description": "Open fracture of angle"
    },
    {
      "name": "S02617",
      "description": "Multiple open fractures of mandible"
    },
    {
      "name": "S02619",
      "description": "Open fracture of mandible, part unspecified"
    },
    {
      "name": "S0270",
      "description": "Multiple closed fractures of skull and facial bones"
    },
    {
      "name": "S0271",
      "description": "Multiple open fractures of skull and facial bones"
    },
    {
      "name": "S0280",
      "description": "Closed fracture of other skull and facial bones"
    },
    {
      "name": "S0281",
      "description": "Open fracture of other skull and facial bones"
    },
    {
      "name": "S0290",
      "description": "Closed fracture of skull and facial bones, part unspecified"
    },
    {
      "name": "S0291",
      "description": "Open fracture of skull and facial bones, part unspecified"
    },
    {
      "name": "S030",
      "description": "Dislocation of jaw"
    },
    {
      "name": "S031",
      "description": "Dislocation of septal cartilage of nose"
    },
    {
      "name": "S0320",
      "description": "Luxation of tooth"
    },
    {
      "name": "S0321",
      "description": "Intrusion or extrusion of tooth"
    },
    {
      "name": "S0322",
      "description": "Avulsion of tooth"
    },
    {
      "name": "S0329",
      "description": "Dislocation of tooth, unspecified"
    },
    {
      "name": "S033",
      "description": "Dislocation of other and unspecified parts of head"
    },
    {
      "name": "S034",
      "description": "Sprain and strain of jaw"
    },
    {
      "name": "S035",
      "description": "Sprain and strain of joints and ligaments of other and unspecified parts of head"
    },
    {
      "name": "S040",
      "description": "Injury of optic nerve and pathways"
    },
    {
      "name": "S041",
      "description": "Injury of oculomotor nerve"
    },
    {
      "name": "S042",
      "description": "Injury of trochlear nerve"
    },
    {
      "name": "S043",
      "description": "Injury of trigeminal nerve"
    },
    {
      "name": "S044",
      "description": "Injury of abducent nerve"
    },
    {
      "name": "S045",
      "description": "Injury of facial nerve"
    },
    {
      "name": "S046",
      "description": "Injury of acoustic nerve"
    },
    {
      "name": "S047",
      "description": "Injury of accessory nerve"
    },
    {
      "name": "S0480",
      "description": "Injury of olfactory nerve"
    },
    {
      "name": "S0481",
      "description": "Injury of glossopharyngeal nerve"
    },
    {
      "name": "S0482",
      "description": "Injury of vagus nerve"
    },
    {
      "name": "S0483",
      "description": "Injury of hypoglossal nerve"
    },
    {
      "name": "S049",
      "description": "Injury of unspecified cranial nerve"
    },
    {
      "name": "S050",
      "description": "Injury of conjunctiva and corneal abrasion without mention of foreign body"
    },
    {
      "name": "S051",
      "description": "Contusion of eyeball and orbital tissues"
    },
    {
      "name": "S052",
      "description": "Ocular laceration and rupture with prolapse or loss of intraocular tissue"
    },
    {
      "name": "S053",
      "description": "Ocular laceration without prolapse or loss of intraocular tissue"
    },
    {
      "name": "S054",
      "description": "Penetrating wound of orbit with or without foreign body"
    },
    {
      "name": "S055",
      "description": "Penetrating wound of eyeball with foreign body"
    },
    {
      "name": "S056",
      "description": "Penetrating wound of eyeball without foreign body"
    },
    {
      "name": "S057",
      "description": "Avulsion of eye"
    },
    {
      "name": "S058",
      "description": "Other injuries of eye and orbit"
    },
    {
      "name": "S059",
      "description": "Injury of eye and orbit, unspecified"
    },
    {
      "name": "S0600",
      "description": "Concussion without open intracranial wound"
    },
    {
      "name": "S0601",
      "description": "Concussion with open intracranial wound"
    },
    {
      "name": "S0610",
      "description": "Traumatic cerebral oedema without open intracranial wound"
    },
    {
      "name": "S0611",
      "description": "Traumatic cerebral oedema with open intracranial wound"
    },
    {
      "name": "S0620",
      "description": "Diffuse brain injury without open intracranial wound"
    },
    {
      "name": "S0621",
      "description": "Diffuse brain injury with open intracranial wound"
    },
    {
      "name": "S0630",
      "description": "Focal brain injury without open intracranial wound"
    },
    {
      "name": "S0631",
      "description": "Focal brain injury with open intracranial wound"
    },
    {
      "name": "S0640",
      "description": "Epidural haemorrhage without open intracranial wound"
    },
    {
      "name": "S0641",
      "description": "Epidural haemorrhage with open intracranial wound"
    },
    {
      "name": "S0650",
      "description": "Traumatic subdural haemorrhage without open intracranial wound"
    },
    {
      "name": "S0651",
      "description": "Traumatic subdural haemorrhage with open intracranial wound"
    },
    {
      "name": "S0660",
      "description": "Traumatic subarachnoid haemorrhage without open intracranial wound"
    },
    {
      "name": "S0661",
      "description": "Traumatic subarachnoid haemorrhage with open intracranial wound"
    },
    {
      "name": "S0670",
      "description": "Intracranial injury with prolonged coma without open intracranial wound"
    },
    {
      "name": "S0671",
      "description": "Intracranial injury with prolonged coma with open intracranial wound"
    },
    {
      "name": "S0680",
      "description": "Other intracranial injuries without open intracranial wound"
    },
    {
      "name": "S0681",
      "description": "Other intracranial injuries with open intracranial wound"
    },
    {
      "name": "S0690",
      "description": "Unspecified intracranial injury without open intracranial wound"
    },
    {
      "name": "S0691",
      "description": "Unspecified intracranial injury with open intracranial wound"
    },
    {
      "name": "S070",
      "description": "Crushing injury of face"
    },
    {
      "name": "S071",
      "description": "Crushing injury of skull"
    },
    {
      "name": "S078",
      "description": "Crushing injury of other parts of head"
    },
    {
      "name": "S079",
      "description": "Crushing injury of head, part unspecified"
    },
    {
      "name": "S080",
      "description": "Avulsion of scalp"
    },
    {
      "name": "S081",
      "description": "Traumatic amputation of ear"
    },
    {
      "name": "S088",
      "description": "Traumatic amputation of other parts of head"
    },
    {
      "name": "S089",
      "description": "Traumatic amputation of unspecified part of head"
    },
    {
      "name": "S090",
      "description": "Injury of blood vessels of head, not elsewhere classified"
    },
    {
      "name": "S091",
      "description": "Injury of muscle and tendon of head"
    },
    {
      "name": "S092",
      "description": "Traumatic rupture of ear drum"
    },
    {
      "name": "S097",
      "description": "Multiple injuries of head"
    },
    {
      "name": "S098",
      "description": "Other specified injuries of head"
    },
    {
      "name": "S099",
      "description": "Unspecified injury of head"
    },
    {
      "name": "S100",
      "description": "Contusion of throat"
    },
    {
      "name": "S101",
      "description": "Other and unspecified superficial injuries of throat"
    },
    {
      "name": "S107",
      "description": "Multiple superficial injuries of neck"
    },
    {
      "name": "S108",
      "description": "Superficial injury of other parts of neck"
    },
    {
      "name": "S109",
      "description": "Superficial injury of neck, part unspecified"
    },
    {
      "name": "S110",
      "description": "Open wound involving larynx and trachea"
    },
    {
      "name": "S111",
      "description": "Open wound involving thyroid gland"
    },
    {
      "name": "S112",
      "description": "Open wound involving pharynx and cervical oesophagus"
    },
    {
      "name": "S117",
      "description": "Multiple open wounds of neck"
    },
    {
      "name": "S118",
      "description": "Open wound of other parts of neck"
    },
    {
      "name": "S119",
      "description": "Open wound of neck, part unspecified"
    },
    {
      "name": "S1200",
      "description": "Closed fracture of first cervical vertebra"
    },
    {
      "name": "S1201",
      "description": "Open fracture of first cervical vertebra"
    },
    {
      "name": "S1210",
      "description": "Closed fracture of second cervical vertebra"
    },
    {
      "name": "S1211",
      "description": "Open fracture of second cervical vertebra"
    },
    {
      "name": "S12200",
      "description": "Closed fracture of 3rd cervical vertebra"
    },
    {
      "name": "S12201",
      "description": "Closed fracture of 4th cervical vertebra"
    },
    {
      "name": "S12202",
      "description": "Closed fracture of 5th cervical vertebra"
    },
    {
      "name": "S12203",
      "description": "Closed fracture of 6th cervical vertebra"
    },
    {
      "name": "S12204",
      "description": "Closed fracture of 7th cervical vertebra"
    },
    {
      "name": "S12210",
      "description": "Open fracture of 3rd cervical vertebra"
    },
    {
      "name": "S12211",
      "description": "Open fracture of 4th cervical vertebra"
    },
    {
      "name": "S12212",
      "description": "Open fracture of 5th cervical vertebra"
    },
    {
      "name": "S12213",
      "description": "Open fracture of 6th cervical vertebra"
    },
    {
      "name": "S12214",
      "description": "Open fracture of 7th cervical vertebra"
    },
    {
      "name": "S1270",
      "description": "Multiple closed fractures of cervical spine"
    },
    {
      "name": "S1271",
      "description": "Multiple open fractures of cervical spine"
    },
    {
      "name": "S1280",
      "description": "Closed fracture of other parts of neck"
    },
    {
      "name": "S1281",
      "description": "Open fracture of other parts of neck"
    },
    {
      "name": "S1290",
      "description": "Closed fracture of neck, part unspecified"
    },
    {
      "name": "S1291",
      "description": "Open fracture of neck, part unspecified"
    },
    {
      "name": "S130",
      "description": "Traumatic rupture of cervical intervertebral disc"
    },
    {
      "name": "S1310",
      "description": "Dislocation of C1/C2 vertebra"
    },
    {
      "name": "S1311",
      "description": "Dislocation of C2/C3 vertebra"
    },
    {
      "name": "S1312",
      "description": "Dislocation of C3/C4 vertebra"
    },
    {
      "name": "S1313",
      "description": "Dislocation of C4/C5 vertebra"
    },
    {
      "name": "S1314",
      "description": "Dislocation of C5/C6 vertebra"
    },
    {
      "name": "S1315",
      "description": "Dislocation of C6/C7 vertebra"
    },
    {
      "name": "S1316",
      "description": "Dislocation of C7/T1 vertebra"
    },
    {
      "name": "S1319",
      "description": "Dislocation of cervical vertebra, part unspecified"
    },
    {
      "name": "S132",
      "description": "Dislocation of other and unspecified parts of neck"
    },
    {
      "name": "S133",
      "description": "Multiple dislocations of neck"
    },
    {
      "name": "S134",
      "description": "Sprain and strain of cervical spine"
    },
    {
      "name": "S135",
      "description": "Sprain and strain of thyroid region"
    },
    {
      "name": "S136",
      "description": "Sprain and strain of joints and ligaments of other and unspecified parts of neck"
    },
    {
      "name": "S140",
      "description": "Concussion and oedema of cervical spinal cord"
    },
    {
      "name": "S141",
      "description": "Other and unspecified injuries of cervical spinal cord"
    },
    {
      "name": "S142",
      "description": "Injury of nerve root of cervical spine"
    },
    {
      "name": "S143",
      "description": "Injury of brachial plexus"
    },
    {
      "name": "S144",
      "description": "Injury of peripheral nerves of neck"
    },
    {
      "name": "S145",
      "description": "Injury of cervical sympathetic nerves"
    },
    {
      "name": "S146",
      "description": "Injury of other and unspecified nerves of neck"
    },
    {
      "name": "S150",
      "description": "Injury of carotid artery"
    },
    {
      "name": "S151",
      "description": "Injury of vertebral artery"
    },
    {
      "name": "S152",
      "description": "Injury of external jugular vein"
    },
    {
      "name": "S153",
      "description": "Injury of internal jugular vein"
    },
    {
      "name": "S157",
      "description": "Injury of multiple blood vessels at neck level"
    },
    {
      "name": "S158",
      "description": "Injury of other blood vessels at neck level"
    },
    {
      "name": "S159",
      "description": "Injury of unspecified blood vessels at neck level"
    },
    {
      "name": "S16",
      "description": "Injury of muscle and tendon at neck level"
    },
    {
      "name": "S170",
      "description": "Crushing injury of larynx and trachea"
    },
    {
      "name": "S178",
      "description": "Crushing injury of other parts of neck"
    },
    {
      "name": "S179",
      "description": "Crushing injury of neck, part unspecified"
    },
    {
      "name": "S18",
      "description": "Traumatic amputation at neck level"
    },
    {
      "name": "S190",
      "description": "Multiple injuries of neck"
    },
    {
      "name": "S198",
      "description": "Other specified injuries of neck"
    },
    {
      "name": "S199",
      "description": "Unspecified injury of neck"
    },
    {
      "name": "S200",
      "description": "Contusion of breast"
    },
    {
      "name": "S201",
      "description": "Other and unspecified superficial injuries of breast"
    },
    {
      "name": "S202",
      "description": "Contusion of thorax"
    },
    {
      "name": "S203",
      "description": "Other superficial injuries of front wall of thorax"
    },
    {
      "name": "S204",
      "description": "Other superficial injuries of back wall of thorax"
    },
    {
      "name": "S207",
      "description": "Multiple superficial injuries of thorax"
    },
    {
      "name": "S208",
      "description": "Superficial injury of other and unspecified parts of thorax"
    },
    {
      "name": "S210",
      "description": "Open wound of breast"
    },
    {
      "name": "S211",
      "description": "Open wound of front wall of thorax"
    },
    {
      "name": "S212",
      "description": "Open wound of back wall of thorax"
    },
    {
      "name": "S217",
      "description": "Multiple open wounds of thoracic wall"
    },
    {
      "name": "S218",
      "description": "Open wound of other parts of thorax"
    },
    {
      "name": "S219",
      "description": "Open wound of thorax, part unspecified"
    },
    {
      "name": "S22000",
      "description": "Closed fracture of thoracic vertebra, compression type"
    },
    {
      "name": "S22001",
      "description": "Closed fracture of thoracic vertebra, burst type"
    },
    {
      "name": "S22002",
      "description": "Closed fracture of thoracic vertebra, seat-belt type"
    },
    {
      "name": "S22003",
      "description": "Closed fracture of thoracic vertebra, flexion-rotation type"
    },
    {
      "name": "S22004",
      "description": "Closed fracture of thoracic vertebra, shear type"
    },
    {
      "name": "S22005",
      "description": "Closed fracture of thoracic vertebra, flexion-distraction type"
    },
    {
      "name": "S22009",
      "description": "Closed fracture of thoracic vertebra, mechanism unspecified"
    },
    {
      "name": "S22010",
      "description": "Open fracture of thoracic vertebra, compression type"
    },
    {
      "name": "S22011",
      "description": "Open fracture of thoracic vertebra, burst type"
    },
    {
      "name": "S22012",
      "description": "Open fracture of thoracic vertebra, seat-belt type"
    },
    {
      "name": "S22013",
      "description": "Open fracture of thoracic vertebra, flexion-rotation type"
    },
    {
      "name": "S22014",
      "description": "Open fracture of thoracic vertebra, shear type"
    },
    {
      "name": "S22015",
      "description": "Open fracture of thoracic vertebra, flexion-distraction type"
    },
    {
      "name": "S22019",
      "description": "Open fracture of thoracic vertebra, mechanism unspecified"
    },
    {
      "name": "S22100",
      "description": "Multiple closed fractures of thoracic spine, T1-T6 level"
    },
    {
      "name": "S22101",
      "description": "Multiple closed fractures of thoracic spine, T7-T12 level"
    },
    {
      "name": "S22102",
      "description": "Multiple closed fractures of thoracic and lumbar spine T and L spine"
    },
    {
      "name": "S22109",
      "description": "Multiple closed fractures of thoracic spine, unspecified"
    },
    {
      "name": "S22110",
      "description": "Multiple open fractures of thoracic spine, T1-T6 level"
    },
    {
      "name": "S22111",
      "description": "Multiple open fractures of thoracic spine, T7-T12 level"
    },
    {
      "name": "S22112",
      "description": "Multiple open fractures of thoracic and lumbar spine (T and L spine)"
    },
    {
      "name": "S22119",
      "description": "Multiple open fractures of thoracic spine, unspecified"
    },
    {
      "name": "S2220",
      "description": "Closed fracture of sternum"
    },
    {
      "name": "S2221",
      "description": "Open fracture of sternum"
    },
    {
      "name": "S2230",
      "description": "Closed fracture of rib"
    },
    {
      "name": "S2231",
      "description": "Open fracture of rib"
    },
    {
      "name": "S2240",
      "description": "Multiple closed fractures of ribs"
    },
    {
      "name": "S2241",
      "description": "Multiple open fractures of ribs"
    },
    {
      "name": "S2250",
      "description": "Closed flail chest"
    },
    {
      "name": "S2251",
      "description": "Open flail chest"
    },
    {
      "name": "S2280",
      "description": "Closed fracture of other parts of bony thorax"
    },
    {
      "name": "S2281",
      "description": "Open fracture of other parts of bony thorax"
    },
    {
      "name": "S2290",
      "description": "Closed fracture of bony thorax, part unspecified"
    },
    {
      "name": "S2291",
      "description": "Open fracture of bony thorax, part unspecified"
    },
    {
      "name": "S230",
      "description": "Traumatic rupture of thoracic intervertebral disc"
    },
    {
      "name": "S231",
      "description": "Dislocation of thoracic vertebra"
    },
    {
      "name": "S232",
      "description": "Dislocation of other and unspecified parts of thorax"
    },
    {
      "name": "S233",
      "description": "Sprain and strain of thoracic spine"
    },
    {
      "name": "S234",
      "description": "Sprain and strain of ribs and sternum"
    },
    {
      "name": "S235",
      "description": "Sprain and strain of other and unspecified parts of thorax"
    },
    {
      "name": "S240",
      "description": "Concussion and oedema of thoracic spinal cord"
    },
    {
      "name": "S241",
      "description": "Other and unspecified injuries of thoracic spinal cord"
    },
    {
      "name": "S242",
      "description": "Injury of nerve root of thoracic spine"
    },
    {
      "name": "S243",
      "description": "Injury of peripheral nerves of thorax"
    },
    {
      "name": "S244",
      "description": "Injury of thoracic sympathetic nerves"
    },
    {
      "name": "S245",
      "description": "Injury of other nerves of thorax"
    },
    {
      "name": "S246",
      "description": "Injury of unspecified nerve of thorax"
    },
    {
      "name": "S250",
      "description": "Injury of thoracic aorta"
    },
    {
      "name": "S251",
      "description": "Injury of innominate or subclavian artery"
    },
    {
      "name": "S252",
      "description": "Injury of superior vena cava"
    },
    {
      "name": "S253",
      "description": "Injury of innominate or subclavian vein"
    },
    {
      "name": "S254",
      "description": "Injury of pulmonary blood vessels"
    },
    {
      "name": "S255",
      "description": "Injury of intercostal blood vessels"
    },
    {
      "name": "S257",
      "description": "Injury of multiple blood vessels of thorax"
    },
    {
      "name": "S258",
      "description": "Injury of other blood vessels of thorax"
    },
    {
      "name": "S259",
      "description": "Injury of unspecified blood vessel of thorax"
    },
    {
      "name": "S2600",
      "description": "Injury of heart with haemopericardium without open wound into thoracic cavity"
    },
    {
      "name": "S2601",
      "description": "Injury of heart with haemopericardium with open wound into thoracic cavity"
    },
    {
      "name": "S2680",
      "description": "Other injuries of heart without open wound into thoracic cavity"
    },
    {
      "name": "S2681",
      "description": "Other injuries of heart with open wound into thoracic cavity"
    },
    {
      "name": "S2690",
      "description": "Injury of heart, unspecified, without open wound into thoracic cavity"
    },
    {
      "name": "S2691",
      "description": "Injury of heart, unspecified, with open wound into thoracic cavity"
    },
    {
      "name": "S2700",
      "description": "Traumatic pneumothorax without open wound into thoracic cavity"
    },
    {
      "name": "S2701",
      "description": "Traumatic pneumothorax with open wound into thoracic cavity"
    },
    {
      "name": "S2710",
      "description": "Traumatic haemothorax without open wound into thoracic cavity"
    },
    {
      "name": "S2711",
      "description": "Traumatic haemothorax with open wound into thoracic cavity"
    },
    {
      "name": "S2720",
      "description": "Traumatic haemopneumothorax without open wound into thoracic cavity"
    },
    {
      "name": "S2721",
      "description": "Traumatic haemopneumothorax with open wound into thoracic cavity"
    },
    {
      "name": "S2730",
      "description": "Other injuries of lung without open wound into thoracic cavity"
    },
    {
      "name": "S2731",
      "description": "Other injuries of lung with open wound into thoracic cavity"
    },
    {
      "name": "S2740",
      "description": "Injury of bronchus without open wound into thoracic cavity"
    },
    {
      "name": "S2741",
      "description": "Injury of bronchus with open wound into thoracic cavity"
    },
    {
      "name": "S2750",
      "description": "Injury of thoracic trachea without open wound into thoracic cavity"
    },
    {
      "name": "S2751",
      "description": "Injury of thoracic trachea with open wound into thoracic cavity"
    },
    {
      "name": "S2760",
      "description": "Injury of pleura without open wound into thoracic cavity"
    },
    {
      "name": "S2761",
      "description": "Injury of pleura with open wound into thoracic cavity"
    },
    {
      "name": "S2770",
      "description": "Multiple injuries of intrathoracic organs without open wound into thoracic cavity"
    },
    {
      "name": "S2771",
      "description": "Multiple injuries of intrathoracic organs with open wound into thoracic cavity"
    },
    {
      "name": "S2780",
      "description": "Injury of other specified intrathoracic organs without open wound into thoracic cavity"
    },
    {
      "name": "S2781",
      "description": "Injury of other specified intrathoracic organs with open wound into thoracic cavity"
    },
    {
      "name": "S2790",
      "description": "Injury of unspecified intrathoracic organ without open wound into thoracic cavity"
    },
    {
      "name": "S2791",
      "description": "Injury of unspecified intrathoracic organ with open wound into thoracic cavity"
    },
    {
      "name": "S280",
      "description": "Crushed chest"
    },
    {
      "name": "S281",
      "description": "Traumatic amputation of part of thorax"
    },
    {
      "name": "S290",
      "description": "Injury of muscle and tendon at thorax level"
    },
    {
      "name": "S297",
      "description": "Multiple injuries of thorax"
    },
    {
      "name": "S298",
      "description": "Other specified injuries of thorax"
    },
    {
      "name": "S299",
      "description": "Unspecified injury of thorax"
    },
    {
      "name": "S300",
      "description": "Contusion of lower back and pelvis"
    },
    {
      "name": "S301",
      "description": "Contusion of abdominal wall"
    },
    {
      "name": "S302",
      "description": "Contusion of external genital organs"
    },
    {
      "name": "S307",
      "description": "Multiple superficial injuries of abdomen, lower back and pelvis"
    },
    {
      "name": "S308",
      "description": "Other superficial injuries of abdomen, lower back and pelvis"
    },
    {
      "name": "S309",
      "description": "Superficial injury of abdomen, lower back and pelvis, part unspecified"
    },
    {
      "name": "S310",
      "description": "Open wound of lower back and pelvis"
    },
    {
      "name": "S311",
      "description": "Open wound of abdominal wall"
    },
    {
      "name": "S312",
      "description": "Open wound of penis"
    },
    {
      "name": "S313",
      "description": "Open wound of scrotum and testes"
    },
    {
      "name": "S314",
      "description": "Open wound of vagina and vulva"
    },
    {
      "name": "S315",
      "description": "Open wound of other and unspecified external genital organs"
    },
    {
      "name": "S317",
      "description": "Multiple open wounds of abdomen, lower back and pelvis"
    },
    {
      "name": "S318",
      "description": "Open wound of other and unspecified parts of abdomen"
    },
    {
      "name": "S32000",
      "description": "Closed fracture of lumbar vertebra, compression type"
    },
    {
      "name": "S32001",
      "description": "Closed fracture of lumbar vertebra, burst type"
    },
    {
      "name": "S32002",
      "description": "Closed fracture of lumbar vertebra, seat-belt type"
    },
    {
      "name": "S32003",
      "description": "Closed fracture of lumbar vertebra, flexion-rotation type"
    },
    {
      "name": "S32004",
      "description": "Closed fracture of lumbar vertebra, shear type"
    },
    {
      "name": "S32005",
      "description": "Closed fracture of lumbar vertebra, flexion-distraction type"
    },
    {
      "name": "S32009",
      "description": "Closed fracture of lumbar vertebra, mechanism unspecified"
    },
    {
      "name": "S32010",
      "description": "Open fracture of lumbar vertebra, compression type"
    },
    {
      "name": "S32011",
      "description": "Open fracture of lumbar vertebra, burst type"
    },
    {
      "name": "S32012",
      "description": "Open fracture of lumbar vertebra, seat-belt type"
    },
    {
      "name": "S32013",
      "description": "Open fracture of lumbar vertebra, flexion-rotation type"
    },
    {
      "name": "S32014",
      "description": "Open fracture of lumbar vertebra, shear type"
    },
    {
      "name": "S32015",
      "description": "Open fracture of lumbar vertebra, flexion-distraction type"
    },
    {
      "name": "S32019",
      "description": "Open fracture of lumbar vertebra, mechanism unspecified"
    },
    {
      "name": "S3210",
      "description": "Closed fracture of sacrum"
    },
    {
      "name": "S3211",
      "description": "Open fracture of sacrum"
    },
    {
      "name": "S3220",
      "description": "Closed fracture of coccyx"
    },
    {
      "name": "S3221",
      "description": "Open fracture of coccyx"
    },
    {
      "name": "S3230",
      "description": "Closed fracture of ilium"
    },
    {
      "name": "S3231",
      "description": "Open fracture of ilium"
    },
    {
      "name": "S3240",
      "description": "Closed fracture of acetabulum"
    },
    {
      "name": "S3241",
      "description": "Open fracture of acetabulum"
    },
    {
      "name": "S3250",
      "description": "Closed fracture of pubis"
    },
    {
      "name": "S3251",
      "description": "Open fracture of pubis"
    },
    {
      "name": "S3270",
      "description": "Multiple closed fractures of lumbar spine and pelvis"
    },
    {
      "name": "S3271",
      "description": "Multiple open fractures of lumbar spine and pelvis"
    },
    {
      "name": "S3280",
      "description": "Closed fracture of other and unspecified parts of lumbar spine and pelvis"
    },
    {
      "name": "S3281",
      "description": "Open fracture of other and unspecified parts of lumbar spine and pelvis"
    },
    {
      "name": "S330",
      "description": "Traumatic rupture of lumbar intervertebral disc"
    },
    {
      "name": "S331",
      "description": "Dislocation of lumbar vertebra"
    },
    {
      "name": "S332",
      "description": "Dislocation of sacroiliac and sacrococcygeal joint"
    },
    {
      "name": "S333",
      "description": "Dislocation of other and unspecified parts of lumbar spine and pelvis"
    },
    {
      "name": "S334",
      "description": "Traumatic rupture of symphysis pubis"
    },
    {
      "name": "S335",
      "description": "Sprain and strain of lumbar spine"
    },
    {
      "name": "S336",
      "description": "Sprain and strain of sacroiliac joint"
    },
    {
      "name": "S337",
      "description": "Sprain and strain of other and unspecified parts of lumbar spine and pelvis"
    },
    {
      "name": "S340",
      "description": "Concussion and oedema of lumbar spinal cord"
    },
    {
      "name": "S341",
      "description": "Other injury of lumbar spinal cord"
    },
    {
      "name": "S342",
      "description": "Injury of nerve root of lumbar and sacral spine"
    },
    {
      "name": "S343",
      "description": "Injury of cauda equina"
    },
    {
      "name": "S344",
      "description": "Injury of lumbosacral plexus"
    },
    {
      "name": "S345",
      "description": "Injury of lumbar, sacral and pelvic sympathetic nerves"
    },
    {
      "name": "S346",
      "description": "Injury of peripheral nerve(s) of abdomen, lower back and pelvis"
    },
    {
      "name": "S348",
      "description": "Injury of other and unspecified nerves at abdomen, lower back and pelvis level"
    },
    {
      "name": "S350",
      "description": "Injury of abdominal aorta"
    },
    {
      "name": "S351",
      "description": "Injury of inferior vena cava"
    },
    {
      "name": "S352",
      "description": "Injury of coeliac or mesenteric artery"
    },
    {
      "name": "S353",
      "description": "Injury of portal or splenic vein"
    },
    {
      "name": "S354",
      "description": "Injury of renal blood vessels"
    },
    {
      "name": "S355",
      "description": "Injury of iliac blood vessels"
    },
    {
      "name": "S357",
      "description": "Injury of multiple blood vessels at abdomen,lower back and pelvis level"
    },
    {
      "name": "S358",
      "description": "Injury of other blood vessels at abdomen,lower back and pelvis level"
    },
    {
      "name": "S359",
      "description": "Injury of unspecified blood vessel at abdomen,lower back and pelvis level"
    },
    {
      "name": "S3600",
      "description": "Injury of spleen without open wound into cavity"
    },
    {
      "name": "S3601",
      "description": "Injury of spleen with open wound into cavity"
    },
    {
      "name": "S3610",
      "description": "Injury of liver or gallbladder without open wound into cavity"
    },
    {
      "name": "S3611",
      "description": "Injury of liver or gallbladder with open wound into cavity"
    },
    {
      "name": "S3620",
      "description": "Injury of pancreas without open wound into cavity"
    },
    {
      "name": "S3621",
      "description": "Injury of pancreas with open wound into cavity"
    },
    {
      "name": "S3630",
      "description": "Injury of stomach without open wound into cavity"
    },
    {
      "name": "S3631",
      "description": "Injury of stomach with open wound into cavity"
    },
    {
      "name": "S3640",
      "description": "Injury of small intestine without open wound into cavity"
    },
    {
      "name": "S3641",
      "description": "Injury of small intestine with open wound into cavity"
    },
    {
      "name": "S3650",
      "description": "Injury of colon without open wound into cavity"
    },
    {
      "name": "S3651",
      "description": "Injury of colon with open wound into cavity"
    },
    {
      "name": "S3660",
      "description": "Injury of rectum without open wound into cavity"
    },
    {
      "name": "S3661",
      "description": "Injury of rectum with open wound into cavity"
    },
    {
      "name": "S3670",
      "description": "Injury of multiple intra-abdominal organs without open wound into cavity"
    },
    {
      "name": "S3671",
      "description": "Injury of multiple intra-abdominal organs with open wound into cavity"
    },
    {
      "name": "S3680",
      "description": "Injury of other intra-abdominal organs without open wound into cavity"
    },
    {
      "name": "S3681",
      "description": "Injury of other intra-abdominal organs with open wound into cavity"
    },
    {
      "name": "S3690",
      "description": "Injury of unspecified intra-abdominal organ without open wound into cavity"
    },
    {
      "name": "S3691",
      "description": "Injury of multiple intra-abdominal organs with open wound into cavity"
    },
    {
      "name": "S3700",
      "description": "Injury of kidney without open wound into cavity"
    },
    {
      "name": "S3701",
      "description": "Injury of kidney with open wound into cavity"
    },
    {
      "name": "S3710",
      "description": "Injury of ureter without open wound into cavity"
    },
    {
      "name": "S3711",
      "description": "Injury of ureter with open wound into cavity"
    },
    {
      "name": "S3720",
      "description": "Injury of bladder without open wound into cavity"
    },
    {
      "name": "S3721",
      "description": "Injury of bladder with open wound into cavity"
    },
    {
      "name": "S3730",
      "description": "Injury of urethra without open wound into cavity"
    },
    {
      "name": "S3731",
      "description": "Injury of urethra with open wound into cavity"
    },
    {
      "name": "S3740",
      "description": "Injury of ovary without open wound into cavity"
    },
    {
      "name": "S3741",
      "description": "Injury of ovary with open wound into cavity"
    },
    {
      "name": "S3750",
      "description": "Injury of fallopian tube without open wound into cavity"
    },
    {
      "name": "S3751",
      "description": "Injury of fallopian tube with open wound into cavity"
    },
    {
      "name": "S3760",
      "description": "Injury of uterus without open wound into cavity"
    },
    {
      "name": "S3761",
      "description": "Injury of uterus with open wound into cavity"
    },
    {
      "name": "S3770",
      "description": "Injury of multiple pelvic organs without open wound into cavity"
    },
    {
      "name": "S3771",
      "description": "Injury of multiple pelvic organs with open wound into cavity"
    },
    {
      "name": "S3780",
      "description": "Injury of other pelvic organs without open wound into cavity"
    },
    {
      "name": "S3781",
      "description": "Injury of other pelvic organs with open wound into cavity"
    },
    {
      "name": "S3790",
      "description": "Injury of unspecified pelvic organ without open wound into cavity"
    },
    {
      "name": "S3791",
      "description": "Injury of unspecified pelvic organ with open wound into cavity"
    },
    {
      "name": "S380",
      "description": "Crushing injury of external genital organs"
    },
    {
      "name": "S381",
      "description": "Crushing injury of other and unspecified parts of abdomen, lower back and pelvis"
    },
    {
      "name": "S382",
      "description": "Traumatic amputation of external genital organs"
    },
    {
      "name": "S383",
      "description": "Traumatic amputation of other and unspecified parts of abdomen, lower back and pelvis"
    },
    {
      "name": "S390",
      "description": "Injury of muscle and tendon of abdomen, lower back and pelvis"
    },
    {
      "name": "S396",
      "description": "Injury of intra-abdominal organ(s) with pelvic organ(s)"
    },
    {
      "name": "S397",
      "description": "Other multiple injuries of abdomen, lower back and pelvis"
    },
    {
      "name": "S398",
      "description": "Other specified injuries of abdomen, lower back and pelvis"
    },
    {
      "name": "S399",
      "description": "Unspecified injury of abdomen, lower back and pelvis"
    },
    {
      "name": "S400",
      "description": "Contusion of shoulder and upper arm"
    },
    {
      "name": "S401",
      "description": "Contusion of upper arm"
    },
    {
      "name": "S407",
      "description": "Multiple superficial injuries of shoulder and upper arm"
    },
    {
      "name": "S4080",
      "description": "Abrasion of shoulder and upper arm"
    },
    {
      "name": "S4081",
      "description": "Blister of shoulder and upper arm"
    },
    {
      "name": "S4082",
      "description": "Insect bite of shoulder and upper arm"
    },
    {
      "name": "S4083",
      "description": "Superficial foreign body of shoulder and upper arm"
    },
    {
      "name": "S4088",
      "description": "Other superficial injuries of shoulder and upper arm"
    },
    {
      "name": "S409",
      "description": "Superficial injury of shoulder and upper arm, unspecified"
    },
    {
      "name": "S410",
      "description": "Open wound of shoulder"
    },
    {
      "name": "S411",
      "description": "Open wound of upper arm"
    },
    {
      "name": "S417",
      "description": "Multiple open wounds of shoulder and upper arm"
    },
    {
      "name": "S418",
      "description": "Open wound of other and unspecified parts of shoulder girdle"
    },
    {
      "name": "S42000",
      "description": "Closed fracture of clavicle, sternal end"
    },
    {
      "name": "S42001",
      "description": "Closed fracture of clavicle, shaft"
    },
    {
      "name": "S42002",
      "description": "Closed fracture of clavicle, acromial end"
    },
    {
      "name": "S42007",
      "description": "Closed fracture of clavicle, multiple fractures"
    },
    {
      "name": "S42009",
      "description": "Closed fracture of clavicle, unspecified part"
    },
    {
      "name": "S42010",
      "description": "Open fracture of clavicle, sternal end"
    },
    {
      "name": "S42011",
      "description": "Open fracture of clavicle, shaft"
    },
    {
      "name": "S42012",
      "description": "Open fracture of clavicle, acromial end"
    },
    {
      "name": "S42017",
      "description": "Open fracture of clavicle, multiple fractures"
    },
    {
      "name": "S42019",
      "description": "Open fracture of clavicle, unspecified part"
    },
    {
      "name": "S42100",
      "description": "Closed fracture of scapula, body"
    },
    {
      "name": "S42101",
      "description": "Closed fracture of scapula, acromion process"
    },
    {
      "name": "S42102",
      "description": "Closed fracture of scapula, coracoid"
    },
    {
      "name": "S42103",
      "description": "Closed fracture of scapula, glenoid and neck of scapula"
    },
    {
      "name": "S42107",
      "description": "Closed fracture of scapula, multiple fractures"
    },
    {
      "name": "S42109",
      "description": "Closed fracture of scapula, unspecified part"
    },
    {
      "name": "S42110",
      "description": "Open fracture of scapula, body"
    },
    {
      "name": "S42111",
      "description": "Open fracture of scapula, acromion process"
    },
    {
      "name": "S42112",
      "description": "Open fracture of scapula, coracoid"
    },
    {
      "name": "S42113",
      "description": "Open fracture of scapula, glenoid and neck of scapula"
    },
    {
      "name": "S42117",
      "description": "Open fracture of scapula, multiple fractures"
    },
    {
      "name": "S42119",
      "description": "Open fracture of scapula, unspecified part"
    },
    {
      "name": "S42200",
      "description": "Closed fracture of upper end of humerus, non-displaced"
    },
    {
      "name": "S42201",
      "description": "Closed fracture of upper end of humerus, 2 parts"
    },
    {
      "name": "S42202",
      "description": "Closed fracture of upper end of humerus, 3 parts"
    },
    {
      "name": "S42203",
      "description": "Closed fracture of upper end of humerus, 4 parts"
    },
    {
      "name": "S42204",
      "description": "Closed fracture of upper end of humerus, with articular involvement"
    },
    {
      "name": "S42205",
      "description": "Closed fracture of epiphysis of upper end of humerus"
    },
    {
      "name": "S42208",
      "description": "Closed fracture of upper end of humerus, others"
    },
    {
      "name": "S42209",
      "description": "Closed fracture of upper end of humerus, unspecified"
    },
    {
      "name": "S42210",
      "description": "Open fracture of upper end of humerus, non-displaced"
    },
    {
      "name": "S42211",
      "description": "Open fracture of upper end of humerus, 2 parts"
    },
    {
      "name": "S42212",
      "description": "Open fracture of upper end of humerus, 3 parts"
    },
    {
      "name": "S42213",
      "description": "Open fracture of upper end of humerus, 4 parts"
    },
    {
      "name": "S42214",
      "description": "Open fracture of upper end of humerus, with articular involvement"
    },
    {
      "name": "S42215",
      "description": "Open fracture of epiphysis of upper end of humerus"
    },
    {
      "name": "S42218",
      "description": "Open fracture of upper end of humerus, others"
    },
    {
      "name": "S42219",
      "description": "Open fracture of upper end of humerus, unspecified"
    },
    {
      "name": "S42300",
      "description": "Closed fracture of shaft of humerus, simple fracture"
    },
    {
      "name": "S42301",
      "description": "Closed fracture of shaft of humerus, butterfly fracture"
    },
    {
      "name": "S42302",
      "description": "Closed fracture of shaft of humerus, comminuted fracture"
    },
    {
      "name": "S42307",
      "description": "Multiple closed fractures of shaft of humerus"
    },
    {
      "name": "S42309",
      "description": "Closed fracture of shaft of humerus, unspecified"
    },
    {
      "name": "S42310",
      "description": "Open fracture of shaft of humerus, simple fracture"
    },
    {
      "name": "S42311",
      "description": "Open fracture of shaft of humerus, butterfly fracture"
    },
    {
      "name": "S42312",
      "description": "Open fracture of shaft of humerus, comminuted fracture"
    },
    {
      "name": "S42317",
      "description": "Multiple open fractures of shaft of humerus"
    },
    {
      "name": "S42319",
      "description": "Open fracture of shaft of humerus, unspecified"
    },
    {
      "name": "S42400",
      "description": "Closed fracture of lower end of humerus, supracondylar"
    },
    {
      "name": "S42401",
      "description": "Closed fracture of lower end of humerus, lateral condyle"
    },
    {
      "name": "S42402",
      "description": "Closed fracture of lower end of humerus, medial condyle"
    },
    {
      "name": "S42403",
      "description": "Closed fracture of lower end of humerus, other parts of condyle and epicondyle"
    },
    {
      "name": "S42404",
      "description": "Closed fracture of lower end of humerus, T-shape intraarticular fracture"
    },
    {
      "name": "S42405",
      "description": "Closed fracture of lower end of humerus, multifragment intraarticular fracture"
    },
    {
      "name": "S42406",
      "description": "Closed fracture of physis and apophysis of lower end of humerus"
    },
    {
      "name": "S42407",
      "description": "Multiple closed fractures of lower end of humerus"
    },
    {
      "name": "S42408",
      "description": "Closed fracture of lower end of humerus, others"
    },
    {
      "name": "S42409",
      "description": "Closed fracture of lower end of humerus, unspecified"
    },
    {
      "name": "S42410",
      "description": "Open fracture of lower end of humerus, supracondylar"
    },
    {
      "name": "S42411",
      "description": "Open fracture of lower end of humerus, lateral condyle"
    },
    {
      "name": "S42412",
      "description": "Open fracture of lower end of humerus, medial condyle"
    },
    {
      "name": "S42413",
      "description": "Open fracture of lower end of humerus, other parts of condyle and epicondyle"
    },
    {
      "name": "S42414",
      "description": "Open fracture of lower end of humerus, T-shape intraarticular fracture"
    },
    {
      "name": "S42415",
      "description": "Open fracture of lower end of humerus, multifragment intraarticular fracture"
    },
    {
      "name": "S42416",
      "description": "Open fracture of physis and apophysis of lower end of humerus"
    },
    {
      "name": "S42417",
      "description": "Multiple open fractures of lower end of humerus"
    },
    {
      "name": "S42418",
      "description": "Open fracture of lower end of humerus, others"
    },
    {
      "name": "S42419",
      "description": "Open fracture of lower end of humerus, unspecified"
    },
    {
      "name": "S4270",
      "description": "Multiple closed fractures of clavicle, scapula and humerus"
    },
    {
      "name": "S4271",
      "description": "Multiple open fractures of clavicle, scapula and humerus"
    },
    {
      "name": "S4280",
      "description": "Closed fracture of other parts of shoulder and arm"
    },
    {
      "name": "S4281",
      "description": "Open fracture of other parts of shoulder and arm"
    },
    {
      "name": "S4290",
      "description": "Closed fracture of shoulder girdle, part unspecified"
    },
    {
      "name": "S4291",
      "description": "Open fracture of shoulder girdle, part unspecified"
    },
    {
      "name": "S4300",
      "description": "Dislocation of shoulder joint, anterior"
    },
    {
      "name": "S4301",
      "description": "Dislocation of shoulder joint, posterior"
    },
    {
      "name": "S4302",
      "description": "Dislocation of shoulder joint, inferior"
    },
    {
      "name": "S4308",
      "description": "Dislocation of shoulder joint, others"
    },
    {
      "name": "S4309",
      "description": "Dislocation of shoulder joint, unspecified"
    },
    {
      "name": "S431",
      "description": "Dislocation of acromioclavicular joint"
    },
    {
      "name": "S432",
      "description": "Dislocation of sternoclavicular joint"
    },
    {
      "name": "S433",
      "description": "Dislocation of other and unspecified parts of shoulder girdle"
    },
    {
      "name": "S434",
      "description": "Sprain and strain of shoulder joint"
    },
    {
      "name": "S435",
      "description": "Sprain and strain of acromioclavicular joint"
    },
    {
      "name": "S436",
      "description": "Sprain and strain of sternoclavicular joint"
    },
    {
      "name": "S437",
      "description": "Sprain and strain of other and unspecified parts of shoulder girdle"
    },
    {
      "name": "S440",
      "description": "Injury of ulnar nerve at upper arm level"
    },
    {
      "name": "S441",
      "description": "Injury of median nerve at upper arm level"
    },
    {
      "name": "S442",
      "description": "Injury of radial nerve at upper arm level"
    },
    {
      "name": "S443",
      "description": "Injury of axillary nerve"
    },
    {
      "name": "S444",
      "description": "Injury of musculocutaneous nerve"
    },
    {
      "name": "S445",
      "description": "Injury of cutaneous sensory nerve at shoulder and upper arm level"
    },
    {
      "name": "S447",
      "description": "Injury of multiple nerves at shoulder and upper arm level"
    },
    {
      "name": "S448",
      "description": "Injury of other nerves at shoulder and upper arm level"
    },
    {
      "name": "S449",
      "description": "Injury of unspecified nerve at shoulder and upper arm level"
    },
    {
      "name": "S450",
      "description": "Injury of axillary artery"
    },
    {
      "name": "S451",
      "description": "Injury of brachial artery"
    },
    {
      "name": "S452",
      "description": "Injury of axillary or brachial vein"
    },
    {
      "name": "S453",
      "description": "Injury of superficial vein at shoulder and upper arm level"
    },
    {
      "name": "S457",
      "description": "Injury of multiple blood vessels at shoulder and upper arm level"
    },
    {
      "name": "S458",
      "description": "Injury of other blood vessels at shoulder and upper arm level"
    },
    {
      "name": "S459",
      "description": "Injury of unspecified blood vessel at shoulder and upper arm level"
    },
    {
      "name": "S460",
      "description": "Injury of muscle(s) and tendon(s) of the rotator cuff of shoulder"
    },
    {
      "name": "S461",
      "description": "Injury of muscle and tendon of long head of biceps"
    },
    {
      "name": "S462",
      "description": "Injury of muscle and tendon of other parts of biceps"
    },
    {
      "name": "S463",
      "description": "Injury of muscle and tendon of triceps"
    },
    {
      "name": "S467",
      "description": "Injury of multiple muscles and tendons at shoulder and upper arm level"
    },
    {
      "name": "S468",
      "description": "Injury of other muscles and tendons at shoulder and upper arm level"
    },
    {
      "name": "S469",
      "description": "Injury of unspecified muscle and tendon at shoulder and upper arm level"
    },
    {
      "name": "S47",
      "description": "Crushing injury of shoulder and upper arm"
    },
    {
      "name": "S480",
      "description": "Traumatic amputation at shoulder joint"
    },
    {
      "name": "S481",
      "description": "Traumatic amputation at level between shoulder and elbow"
    },
    {
      "name": "S489",
      "description": "Traumatic amputation of shoulder and upper arm, level unspecified"
    },
    {
      "name": "S497",
      "description": "Multiple injuries of shoulder and upper arm"
    },
    {
      "name": "S498",
      "description": "Other specified injuries of shoulder and upper arm"
    },
    {
      "name": "S499",
      "description": "Unspecified injury of shoulder and upper arm"
    },
    {
      "name": "S500",
      "description": "Contusion of elbow"
    },
    {
      "name": "S501",
      "description": "Contusion of other and unspecified parts of forearm"
    },
    {
      "name": "S507",
      "description": "Multiple superficial injuries of forearm"
    },
    {
      "name": "S5080",
      "description": "Abrasion of forearm"
    },
    {
      "name": "S5081",
      "description": "Blister of forearm"
    },
    {
      "name": "S5082",
      "description": "Insect bite of forearm"
    },
    {
      "name": "S5083",
      "description": "Superficial foreign body of forearm"
    },
    {
      "name": "S5088",
      "description": "Other superficial injuries of forearm"
    },
    {
      "name": "S509",
      "description": "Superficial injury of forearm, unspecified"
    },
    {
      "name": "S510",
      "description": "Open wound of elbow"
    },
    {
      "name": "S517",
      "description": "Multiple open wounds of forearm"
    },
    {
      "name": "S518",
      "description": "Open wound of other parts of forearm"
    },
    {
      "name": "S519",
      "description": "Open wound of forearm, part unspecified"
    },
    {
      "name": "S52000",
      "description": "Closed fracture of upper end of ulna, olecranon"
    },
    {
      "name": "S52001",
      "description": "Closed fracture of upper end of ulna, coronoid process"
    },
    {
      "name": "S52002",
      "description": "Closed fracture of upper end of ulna, with dislocation of radial head"
    },
    {
      "name": "S52003",
      "description": "Closed fracture of epiphysis of upper end of ulna"
    },
    {
      "name": "S52007",
      "description": "Closed fracture of upper end of ulna, multiple fracture"
    },
    {
      "name": "S52008",
      "description": "Closed fracture of upper end of ulna, others"
    },
    {
      "name": "S52009",
      "description": "Closed fracture of upper end of ulna, unspecified"
    },
    {
      "name": "S52010",
      "description": "Open fracture of upper end of ulna, olecranon"
    },
    {
      "name": "S52011",
      "description": "Open fracture of upper end of ulna, coronoid process"
    },
    {
      "name": "S52012",
      "description": "Open fracture of proximal ulna, with dislocation of radial head"
    },
    {
      "name": "S52013",
      "description": "Open fracture of epiphysis of proximal ulna"
    },
    {
      "name": "S52017",
      "description": "Open fracture of proximal ulna, multiple fracture"
    },
    {
      "name": "S52018",
      "description": "Open fracture of proximal ulna, others"
    },
    {
      "name": "S52019",
      "description": "Open fracture of proximal ulna, unspecified"
    },
    {
      "name": "S52100",
      "description": "Closed fracture of head of radius"
    },
    {
      "name": "S52101",
      "description": "Closed fracture of neck of radius"
    },
    {
      "name": "S52102",
      "description": "Closed fracture of epiphysis of upper end of radius"
    },
    {
      "name": "S52107",
      "description": "Closed fracture of upper end of radius, multiple fractures"
    },
    {
      "name": "S52108",
      "description": "Closed fracture of upper end of radius, others"
    },
    {
      "name": "S52109",
      "description": "Closed fracture of upper end of radius, unspecified"
    },
    {
      "name": "S52110",
      "description": "Open fracture of head of radius"
    },
    {
      "name": "S52111",
      "description": "Open fracture of neck of radius"
    },
    {
      "name": "S52112",
      "description": "Open fracture of epiphysis of upper end of radius"
    },
    {
      "name": "S52117",
      "description": "Open fracture of upper end of radius, multiple fractures"
    },
    {
      "name": "S52118",
      "description": "Open fracture of upper end of radius, others"
    },
    {
      "name": "S52119",
      "description": "Open fracture of upper end of radius, unspecified"
    },
    {
      "name": "S52200",
      "description": "Closed fracture of shaft of ulna, simple fracture"
    },
    {
      "name": "S52201",
      "description": "Closed fracture of shaft of ulna, butterfly fracture"
    },
    {
      "name": "S52202",
      "description": "Closed fracture of shaft of ulna, comminuted fracture"
    },
    {
      "name": "S52203",
      "description": "Closed fracture of shaft of ulna, with dislocation of radial head"
    },
    {
      "name": "S52207",
      "description": "Closed fracture of shaft of ulna, bifocal and segmental and multiple fractures"
    },
    {
      "name": "S52209",
      "description": "Closed fracture of shaft of ulna, unspecified"
    },
    {
      "name": "S52210",
      "description": "Open fracture of shaft of ulna, simple fracture"
    },
    {
      "name": "S52211",
      "description": "Open fracture of shaft of ulna, butterfly fracture"
    },
    {
      "name": "S52212",
      "description": "Open fracture of shaft of ulna, comminuted fracture"
    },
    {
      "name": "S52213",
      "description": "Open fracture of shaft of ulna, with dislocation of radial head"
    },
    {
      "name": "S52217",
      "description": "Open fracture of shaft of ulna, bifocal and segmental and multiple fractures"
    },
    {
      "name": "S52219",
      "description": "Open fracture of shaft of ulna, unspecified"
    },
    {
      "name": "S52300",
      "description": "Closed fracture of shaft of radius, simple fracture"
    },
    {
      "name": "S52301",
      "description": "Closed fracture of shaft of radius, butterfly fracture"
    },
    {
      "name": "S52302",
      "description": "Closed fracture of shaft of radius, comminuted fracture"
    },
    {
      "name": "S52303",
      "description": "Closed fracture of shaft of radius, with dislocation of head of ulna"
    },
    {
      "name": "S52307",
      "description": "Closed fracture of shaft of radius, bifocal and segmental and multiple fractures"
    },
    {
      "name": "S52309",
      "description": "Closed fracture of shaft of radius, unspecified"
    },
    {
      "name": "S52310",
      "description": "Open fracture of shaft of radius, simple fracture"
    },
    {
      "name": "S52311",
      "description": "Open fracture of shaft of radius, butterfly fracture"
    },
    {
      "name": "S52312",
      "description": "Open fracture of shaft of radius, comminuted fracture"
    },
    {
      "name": "S52313",
      "description": "Open fracture of shaft of radius, with dislocation of head of ulna"
    },
    {
      "name": "S52317",
      "description": "Open fracture of shaft of radius, bifocal and segmental and multiple fractures"
    },
    {
      "name": "S52319",
      "description": "Open fracture of shaft of radius, unspecified"
    },
    {
      "name": "S5240",
      "description": "Closed fracture of shaft of both ulna and radius"
    },
    {
      "name": "S5241",
      "description": "Open fracture of shaft of both ulna and radius"
    },
    {
      "name": "S52500",
      "description": "Closed fracture of lower end of radius, extraarticular with dorsal angulation"
    },
    {
      "name": "S52501",
      "description": "Closed fracture of lower end of radius, extraarticular with volar angulation"
    },
    {
      "name": "S52502",
      "description": "Closed fracture of lower end of radius, partial intraarticular"
    },
    {
      "name": "S52503",
      "description": "Closed fracture of lower end of radius, complete intraarticular"
    },
    {
      "name": "S52504",
      "description": "Closed fracture of epiphysis of lower end of radius"
    },
    {
      "name": "S52507",
      "description": "Closed fracture of lower end of radius, multiple fractures"
    },
    {
      "name": "S52508",
      "description": "Closed fracture of lower end of radius, others"
    },
    {
      "name": "S52509",
      "description": "Closed fracture of lower end of radius, unspecified"
    },
    {
      "name": "S52510",
      "description": "Open fracture of lower end of radius, extraarticular with dorsal angulation"
    },
    {
      "name": "S52511",
      "description": "Open fracture of lower end of radius, extraarticular with volar angulation"
    },
    {
      "name": "S52512",
      "description": "Open fracture of lower end of radius, partial intraarticular"
    },
    {
      "name": "S52513",
      "description": "Open fracture of lower end of radius, complete intraarticular"
    },
    {
      "name": "S52514",
      "description": "Open fracture of epiphysis of lower end of radius"
    },
    {
      "name": "S52517",
      "description": "Open fracture of lower end of radius, multiple fractures"
    },
    {
      "name": "S52518",
      "description": "Open fracture of lower end of radius, others"
    },
    {
      "name": "S52519",
      "description": "Open fracture of lower end of radius, unspecified"
    },
    {
      "name": "S5260",
      "description": "Closed fracture of lower end of both ulna and radius"
    },
    {
      "name": "S5261",
      "description": "Open fracture of lower end of both ulna and radius"
    },
    {
      "name": "S52700",
      "description": "Closed fracture of proximal part of both ulna and radius"
    },
    {
      "name": "S52708",
      "description": "Multiple closed fractures of forearm, others"
    },
    {
      "name": "S52709",
      "description": "Multiple closed fractures of forearm, unspecified"
    },
    {
      "name": "S52710",
      "description": "Open fracture of proximal part of both ulna and radius"
    },
    {
      "name": "S52718",
      "description": "Multiple open fractures of forearm, others"
    },
    {
      "name": "S52719",
      "description": "Multiple open fractures of forearm, unspecified"
    },
    {
      "name": "S5280",
      "description": "Closed fracture of other parts of forearm"
    },
    {
      "name": "S5281",
      "description": "Open fracture of other parts of forearm"
    },
    {
      "name": "S5290",
      "description": "Closed fracture of forearm, part unspecified"
    },
    {
      "name": "S5291",
      "description": "Open fracture of forearm, part unspecified"
    },
    {
      "name": "S530",
      "description": "Dislocation of radial head"
    },
    {
      "name": "S5310",
      "description": "Dislocation of elbow, anterior"
    },
    {
      "name": "S5311",
      "description": "Dislocation of elbow, posterior"
    },
    {
      "name": "S5312",
      "description": "Dislocation of elbow, medial"
    },
    {
      "name": "S5313",
      "description": "Dislocation of elbow, lateral"
    },
    {
      "name": "S5314",
      "description": "Dislocation of elbow, divergent"
    },
    {
      "name": "S5315",
      "description": "Dislocation of elbow, with associated fracture"
    },
    {
      "name": "S5318",
      "description": "Dislocation of elbow, others"
    },
    {
      "name": "S5319",
      "description": "Dislocation of elbow, unspecified"
    },
    {
      "name": "S532",
      "description": "Traumatic rupture of radial collateral ligament"
    },
    {
      "name": "S533",
      "description": "Traumatic rupture of ulnar collateral ligament"
    },
    {
      "name": "S534",
      "description": "Sprain and strain of elbow"
    },
    {
      "name": "S540",
      "description": "Injury of ulnar nerve at forearm level"
    },
    {
      "name": "S541",
      "description": "Injury of median nerve at forearm level"
    },
    {
      "name": "S542",
      "description": "Injury of radial nerve at forearm level"
    },
    {
      "name": "S543",
      "description": "Injury of cutaneous sensory nerves at forearm level"
    },
    {
      "name": "S547",
      "description": "Injury of multiple nerves at forearm level"
    },
    {
      "name": "S548",
      "description": "Injury of other nerves at forearm level"
    },
    {
      "name": "S549",
      "description": "Injury of unspecified nerve at forearm level"
    },
    {
      "name": "S550",
      "description": "Injury of ulnar artery at forearm level"
    },
    {
      "name": "S551",
      "description": "Injury of radial artery at forearm level"
    },
    {
      "name": "S552",
      "description": "Injury of vein at forearm level"
    },
    {
      "name": "S557",
      "description": "Injury of multiple blood vessels at forearm level"
    },
    {
      "name": "S558",
      "description": "Injury of other blood vessels at forearm level"
    },
    {
      "name": "S559",
      "description": "Injury of unspecified blood vessel at forearm level"
    },
    {
      "name": "S560",
      "description": "Injury of flexor muscle and tendon of thumb at forearm level"
    },
    {
      "name": "S561",
      "description": "Injury of long flexor muscle and tendon of other finger(s) at forearm level"
    },
    {
      "name": "S562",
      "description": "Injury of other flexor muscle and tendon at forearm level"
    },
    {
      "name": "S563",
      "description": "Injury of extensor or abductor muscles and tendons of thumb at forearm level"
    },
    {
      "name": "S564",
      "description": "Injury of extensor muscle and tendon of other finger(s) at forearm level"
    },
    {
      "name": "S565",
      "description": "Injury of other extensor muscle and tendon at forearm level"
    },
    {
      "name": "S567",
      "description": "Injury of multiple muscles and tendons at forearm level"
    },
    {
      "name": "S568",
      "description": "Injury of other and unspecified muscles and tendons at forearm level"
    },
    {
      "name": "S570",
      "description": "Crushing injury of elbow"
    },
    {
      "name": "S578",
      "description": "Crushing injury of other parts of forearm"
    },
    {
      "name": "S579",
      "description": "Crushing injury of forearm, part unspecified"
    },
    {
      "name": "S580",
      "description": "Traumatic amputation at elbow level"
    },
    {
      "name": "S581",
      "description": "Traumatic amputation at level between elbow and wrist"
    },
    {
      "name": "S589",
      "description": "Traumatic amputation of forearm, level unspecified"
    },
    {
      "name": "S597",
      "description": "Multiple injuries of forearm"
    },
    {
      "name": "S598",
      "description": "Other specified injuries of forearm"
    },
    {
      "name": "S599",
      "description": "Unspecified injury of forearm"
    },
    {
      "name": "S600",
      "description": "Contusion of finger(s) without damage to nail"
    },
    {
      "name": "S601",
      "description": "Contusion of finger(s) with damage to nail"
    },
    {
      "name": "S602",
      "description": "Contusion of other parts of wrist and hand"
    },
    {
      "name": "S607",
      "description": "Multiple superficial injuries of wrist and hand"
    },
    {
      "name": "S6080",
      "description": "Abrasion of wrist and hand"
    },
    {
      "name": "S6081",
      "description": "Blister of wrist and hand"
    },
    {
      "name": "S6082",
      "description": "Insect bite of wrist and hand"
    },
    {
      "name": "S6083",
      "description": "Superficial foreign body of wrist and hand"
    },
    {
      "name": "S6088",
      "description": "Other superficial injuries af wrist and hand"
    },
    {
      "name": "S609",
      "description": "Superficial injury of wrist and hand, unspecified"
    },
    {
      "name": "S610",
      "description": "Open wound of finger(s) without damage to nail"
    },
    {
      "name": "S611",
      "description": "Open wound of finger(s) with damage to nail"
    },
    {
      "name": "S617",
      "description": "Multiple open wounds of wrist and hand"
    },
    {
      "name": "S618",
      "description": "Open wound of other parts of wrist and hand"
    },
    {
      "name": "S619",
      "description": "Open wound of wrist and hand, part unspecified"
    },
    {
      "name": "S6200",
      "description": "Closed fracture of navicular [scaphoid] bone of hand"
    },
    {
      "name": "S6201",
      "description": "Open fracture of navicular [scaphoid] bone of hand"
    },
    {
      "name": "S62100",
      "description": "Closed fracture of lunate [semilunar]"
    },
    {
      "name": "S62101",
      "description": "Closed fracture of triquetrum [cuneiform]"
    },
    {
      "name": "S62102",
      "description": "Closed fracture of pisiform"
    },
    {
      "name": "S62103",
      "description": "Closed fracture of trapezium [greater multangular]"
    },
    {
      "name": "S62104",
      "description": "Closed fracture of trapezoid [lesser multangular]"
    },
    {
      "name": "S62105",
      "description": "Closed fracture of capitate [os magnum]"
    },
    {
      "name": "S62106",
      "description": "Closed fracture of hamate [unciform]"
    },
    {
      "name": "S62107",
      "description": "Closed fracture of other carpal bone(s), multiple fractures"
    },
    {
      "name": "S62109",
      "description": "Closed fracture of other carpal bone(s), unspecified"
    },
    {
      "name": "S62110",
      "description": "Open fracture of lunate [semilunar]"
    },
    {
      "name": "S62111",
      "description": "Open fracture of triquetrum [cuneiform]"
    },
    {
      "name": "S62112",
      "description": "Open fracture of pisiform"
    },
    {
      "name": "S62113",
      "description": "Open fracture of trapezium [greater multangular]"
    },
    {
      "name": "S62114",
      "description": "Open fracture of trapezoid [lesser multangular]"
    },
    {
      "name": "S62115",
      "description": "Open fracture of capitate [os magnum]"
    },
    {
      "name": "S62116",
      "description": "Open fracture of hamate [unciform]"
    },
    {
      "name": "S62117",
      "description": "Open fracture of other carpal bone(s), multiple fractures"
    },
    {
      "name": "S62119",
      "description": "Open fracture of other carpal bone(s), unspecified"
    },
    {
      "name": "S6220",
      "description": "Closed fracture of first metacarpal bone"
    },
    {
      "name": "S6221",
      "description": "Open fracture of first metacarpal bone"
    },
    {
      "name": "S6230",
      "description": "Closed fracture of other metacarpal bone"
    },
    {
      "name": "S6231",
      "description": "Open fracture of other metacarpal bone"
    },
    {
      "name": "S6240",
      "description": "Multiple closed fractures of metacarpal bones"
    },
    {
      "name": "S6241",
      "description": "Multiple open fractures of metacarpal bones"
    },
    {
      "name": "S6250",
      "description": "Closed fracture of thumb"
    },
    {
      "name": "S6251",
      "description": "Open fracture of thumb"
    },
    {
      "name": "S6260",
      "description": "Closed fracture of other fingers"
    },
    {
      "name": "S6261",
      "description": "Open fracture of other fingers"
    },
    {
      "name": "S6270",
      "description": "Multiple closed fractures of fingers"
    },
    {
      "name": "S6271",
      "description": "Multiple open fractures of fingers"
    },
    {
      "name": "S6280",
      "description": "Closed fracture of other and unspecified parts of wrist and hand"
    },
    {
      "name": "S6281",
      "description": "Open fracture of other and unspecified parts of wrist and hand"
    },
    {
      "name": "S6300",
      "description": "Dislocation of wrist, distal radioulnar joint"
    },
    {
      "name": "S6301",
      "description": "Dislocation of wrist, distal radiocarpal joint"
    },
    {
      "name": "S6302",
      "description": "Dislocation of wrist, midcarpal joint"
    },
    {
      "name": "S6303",
      "description": "Dislocation of wrist, carpometacarpal joint"
    },
    {
      "name": "S6308",
      "description": "Dislocation of wrist, others"
    },
    {
      "name": "S6309",
      "description": "Dislocation of wrist, unspecified joint"
    },
    {
      "name": "S6310",
      "description": "Dislocation of metacarpophalangeal joint MCP of finger"
    },
    {
      "name": "S6311",
      "description": "Dislocation of interphalangeal joint of finger"
    },
    {
      "name": "S6319",
      "description": "Dislocation of finger, unspecified joint"
    },
    {
      "name": "S632",
      "description": "Multiple dislocations of fingers"
    },
    {
      "name": "S633",
      "description": "Traumatic rupture of ligament of wrist and carpus"
    },
    {
      "name": "S634",
      "description": "Traumatic rupture of ligament of finger at metacarpophalangeal and interphalangeal joint(s)"
    },
    {
      "name": "S635",
      "description": "Sprain and strain of wrist"
    },
    {
      "name": "S636",
      "description": "Sprain and strain of joint of thumb and finger(s)"
    },
    {
      "name": "S637",
      "description": "Sprain and strain of other and unspecified parts of hand"
    },
    {
      "name": "S640",
      "description": "Injury of ulnar nerve at wrist and hand level"
    },
    {
      "name": "S641",
      "description": "Injury of median nerve at wrist and hand level"
    },
    {
      "name": "S642",
      "description": "Injury of radial nerve at wrist and hand level"
    },
    {
      "name": "S643",
      "description": "Injury of digital nerve of thumb"
    },
    {
      "name": "S644",
      "description": "Injury of digital nerve of other fingers"
    },
    {
      "name": "S647",
      "description": "Injury of multiple nerves at wrist and hand level"
    },
    {
      "name": "S648",
      "description": "Injury of other nerves at wrist and hand level"
    },
    {
      "name": "S649",
      "description": "Injury of unspecified nerve at wrist and hand level"
    },
    {
      "name": "S650",
      "description": "Injury of ulnar artery at wrist and hand level"
    },
    {
      "name": "S651",
      "description": "Injury of radial artery at wrist and hand level"
    },
    {
      "name": "S652",
      "description": "Injury of superficial palmar arch"
    },
    {
      "name": "S653",
      "description": "Injury of deep palmar arch"
    },
    {
      "name": "S654",
      "description": "Injury of blood vessel(s) of thumb"
    },
    {
      "name": "S655",
      "description": "Injury of blood vessel(s) of other fingers"
    },
    {
      "name": "S657",
      "description": "Injury of multiple blood vessels at wrist and hand level"
    },
    {
      "name": "S658",
      "description": "Injury of other blood vessels at wrist and hand level"
    },
    {
      "name": "S659",
      "description": "Injury of unspecified blood vessel at wrist and hand level"
    },
    {
      "name": "S660",
      "description": "Injury of long flexor muscle and tendon of thumb at wrist and hand level"
    },
    {
      "name": "S661",
      "description": "Injury of flexor muscle and tendon of other fingers at wrist and hand level"
    },
    {
      "name": "S662",
      "description": "Injury of extensor muscle and tendon of thumb at wrist and hand level"
    },
    {
      "name": "S663",
      "description": "Injury of extensor muscle and tendon of other fingers at wrist and hand level"
    },
    {
      "name": "S664",
      "description": "Injury of intrinsic muscle and tendon of thumb at wrist and hand level"
    },
    {
      "name": "S665",
      "description": "Injury of intrinsic muscle and tendon of other fingers at wrist and hand level"
    },
    {
      "name": "S666",
      "description": "Injury of multiple flexor muscles and tendons at wrist and hand level"
    },
    {
      "name": "S667",
      "description": "Injury of multiple extensor muscles and tendons at wrist and hand level"
    },
    {
      "name": "S668",
      "description": "Injury of other muscles and tendons at wrist and hand level"
    },
    {
      "name": "S669",
      "description": "Injury of unspecified muscle and tendon at wrist and hand level"
    },
    {
      "name": "S670",
      "description": "Crushing injury of thumb and other finger(s)"
    },
    {
      "name": "S678",
      "description": "Crushing injury of other and unspecified parts of wrist and hand"
    },
    {
      "name": "S680",
      "description": "Traumatic amputation of thumb (complete) (partial)"
    },
    {
      "name": "S681",
      "description": "Traumatic amputation of other single finger (complete) (partial)"
    },
    {
      "name": "S682",
      "description": "Traumatic amputation of two or more fingers alone (complete) (partial)"
    },
    {
      "name": "S683",
      "description": "Combined traumatic amputation of part of finger(s) with other parts of wrist and hand"
    },
    {
      "name": "S684",
      "description": "Traumatic amputation of hand at wrist level"
    },
    {
      "name": "S688",
      "description": "Traumatic amputation of other parts of wrist and hand"
    },
    {
      "name": "S689",
      "description": "Traumatic amputation of wrist and hand, level unspecified"
    },
    {
      "name": "S697",
      "description": "Multiple injuries of wrist and hand"
    },
    {
      "name": "S698",
      "description": "Other specified injuries of wrist and hand"
    },
    {
      "name": "S699",
      "description": "Unspecified injury of wrist and hand"
    },
    {
      "name": "S700",
      "description": "Contusion of hip"
    },
    {
      "name": "S701",
      "description": "Contusion of thigh"
    },
    {
      "name": "S707",
      "description": "Multiple superficial injuries of hip and thigh"
    },
    {
      "name": "S7080",
      "description": "Abrasion of hip and thigh"
    },
    {
      "name": "S7081",
      "description": "Blister of hip and thigh"
    },
    {
      "name": "S7082",
      "description": "Insect bite of hip and thigh"
    },
    {
      "name": "S7083",
      "description": "Superficial foreign body of hip and thigh"
    },
    {
      "name": "S7088",
      "description": "Other superficial injuries of hip and thigh"
    },
    {
      "name": "S709",
      "description": "Superficial injury of hip and thigh, unspecified"
    },
    {
      "name": "S710",
      "description": "Open wound of hip"
    },
    {
      "name": "S711",
      "description": "Open wound of thigh"
    },
    {
      "name": "S717",
      "description": "Multiple open wounds of hip and thigh"
    },
    {
      "name": "S718",
      "description": "Open wound of other and unspecified parts of pelvic girdle"
    },
    {
      "name": "S72000",
      "description": "Closed fracture of neck of femur, undisplaced intracapsular fracture"
    },
    {
      "name": "S72001",
      "description": "Closed fracture of neck of femur, displaced intracapsular fracture"
    },
    {
      "name": "S72002",
      "description": "Closed fracture of neck of femur, impacted intracapsular fracture"
    },
    {
      "name": "S72003",
      "description": "Closed fracture of neck of femur, midcervical shear fracture"
    },
    {
      "name": "S72004",
      "description": "Closed fracture of neck of femur, base of neck (cervicotrochanteric)"
    },
    {
      "name": "S72005",
      "description": "Closed fracture of head of femur"
    },
    {
      "name": "S72006",
      "description": "Closed fracture of epiphysis of upper femur"
    },
    {
      "name": "S72007",
      "description": "Closed fracture of neck of femur, multiple"
    },
    {
      "name": "S72008",
      "description": "Closed fracture of neck of femur, others"
    },
    {
      "name": "S72009",
      "description": "Closed fracture of neck of femur, unspecified"
    },
    {
      "name": "S72010",
      "description": "Open fracture of neck of femur, undisplaced intracapsular fracture"
    },
    {
      "name": "S72011",
      "description": "Open fracture of neck of femur, displaced intracapsular fracture"
    },
    {
      "name": "S72012",
      "description": "Open fracture of neck of femur, impacted intracapsular fracture"
    },
    {
      "name": "S72013",
      "description": "Open fracture of neck of femur, midcervical shear fracture"
    },
    {
      "name": "S72014",
      "description": "Open fracture of neck of femur, base of neck cervicotrochanteric"
    },
    {
      "name": "S72015",
      "description": "Open fracture of head of femur"
    },
    {
      "name": "S72016",
      "description": "Open fracture of epiphysis of upper femur"
    },
    {
      "name": "S72017",
      "description": "Open fracture of neck of femur, multiple"
    },
    {
      "name": "S72018",
      "description": "Open fracture of neck of femur, others"
    },
    {
      "name": "S72019",
      "description": "Open fracture of neck of femur, unspecified"
    },
    {
      "name": "S72100",
      "description": "Closed pertrochanteric fracture, stable type"
    },
    {
      "name": "S72101",
      "description": "Closed pertrochanteric fracture, unstable type"
    },
    {
      "name": "S72108",
      "description": "Closed fracture of trochanteric region, others"
    },
    {
      "name": "S72109",
      "description": "Closed pertrochanteric fracture, unspecified"
    },
    {
      "name": "S72110",
      "description": "Open pertrochanteric fracture, stable type"
    },
    {
      "name": "S72111",
      "description": "Open pertrochanteric fracture, unstable type"
    },
    {
      "name": "S72118",
      "description": "Open fracture of trochanteric region, others"
    },
    {
      "name": "S72119",
      "description": "Open pertrochanteric fracture, unspecified"
    },
    {
      "name": "S7220",
      "description": "Closed subtrochanteric fracture"
    },
    {
      "name": "S7221",
      "description": "Open subtrochanteric fracture"
    },
    {
      "name": "S72300",
      "description": "Closed fracture of shaft of femur, simple fracture"
    },
    {
      "name": "S72301",
      "description": "Closed fracture of shaft of femur, butterfly fracture"
    },
    {
      "name": "S72302",
      "description": "Closed fracture of shaft of femur, comminuted fracture"
    },
    {
      "name": "S72307",
      "description": "Closed fracture of shaft of femur, bifocal and segmental and multiple"
    },
    {
      "name": "S72309",
      "description": "Closed fracture of shaft of femur, unspecified"
    },
    {
      "name": "S72310",
      "description": "Open fracture of shaft of femur, simple fracture"
    },
    {
      "name": "S72311",
      "description": "Open fracture of shaft of femur, butterfly fracture"
    },
    {
      "name": "S72312",
      "description": "Open fracture of shaft of femur, comminuted fracture"
    },
    {
      "name": "S72317",
      "description": "Open fracture of shaft of femur, bifocal and segmental and multiple"
    },
    {
      "name": "S72319",
      "description": "Open fracture of shaft of femur, unspecified"
    },
    {
      "name": "S72400",
      "description": "Closed fracture of lower end of femur, supracondylar"
    },
    {
      "name": "S72401",
      "description": "Closed fracture of lower end of femur, lateral condyle"
    },
    {
      "name": "S72402",
      "description": "Closed fracture of lower end of femur, medial condyle"
    },
    {
      "name": "S72403",
      "description": "Closed fracture of lower end of femur, other parts of condyle and epicondyle"
    },
    {
      "name": "S72404",
      "description": "Closed fracture of lower end of femur, T-shape intraarticular fracture"
    },
    {
      "name": "S72405",
      "description": "Closed fracture of lower end of femur, multifragment intraarticular fracture"
    },
    {
      "name": "S72406",
      "description": "Closed fracture of lower end of femur, multiple fractures"
    },
    {
      "name": "S72407",
      "description": "Closed fracture of lower end of femur, epiphysis"
    },
    {
      "name": "S72408",
      "description": "Closed fracture of lower end of femur, others"
    },
    {
      "name": "S72409",
      "description": "Closed fracture of lower end of femur, unspecified"
    },
    {
      "name": "S72410",
      "description": "Open fracture of lower end of femur, supracondylar"
    },
    {
      "name": "S72411",
      "description": "Open fracture of lower end of femur, lateral condyle"
    },
    {
      "name": "S72412",
      "description": "Open fracture of lower end of femur, medial condyle"
    },
    {
      "name": "S72413",
      "description": "Open fracture of lower end of femur, other parts of condyle and epicondyle"
    },
    {
      "name": "S72414",
      "description": "Open fracture of lower end of femur, T-shape intraarticular fracture"
    },
    {
      "name": "S72415",
      "description": "Open fracture of lower end of femur, multifragment intraarticular fracture"
    },
    {
      "name": "S72416",
      "description": "Open fracture of lower end of femur, multiple fractures"
    },
    {
      "name": "S72417",
      "description": "Open fracture of lower end of femur, epiphysis"
    },
    {
      "name": "S72418",
      "description": "Open fracture of lower end of femur, others"
    },
    {
      "name": "S72419",
      "description": "Open fracture of lower end of femur, unspecified"
    },
    {
      "name": "S7270",
      "description": "Multiple closed fractures of femur"
    },
    {
      "name": "S7271",
      "description": "Multiple open fractures of femur"
    },
    {
      "name": "S7280",
      "description": "Closed fracture of other parts of femur"
    },
    {
      "name": "S7281",
      "description": "Open fracture of other parts of femur"
    },
    {
      "name": "S7290",
      "description": "Closed fracture of femur, part unspecified"
    },
    {
      "name": "S7291",
      "description": "Open fracture of femur, part unspecified"
    },
    {
      "name": "S7300",
      "description": "Dislocation of hip, anterior"
    },
    {
      "name": "S7301",
      "description": "Dislocation of hip, posterior type with or without minor fracture"
    },
    {
      "name": "S7302",
      "description": "Dislocation of hip, posterior type with large single fracture"
    },
    {
      "name": "S7303",
      "description": "Dislocation of hip, posterior type with comminution of rim"
    },
    {
      "name": "S7304",
      "description": "Dislocation of hip, posterior type with fracture of the acetabular"
    },
    {
      "name": "S7305",
      "description": "Dislocation of hip, posterior type with fracture of head of femur"
    },
    {
      "name": "S7308",
      "description": "Dislocation of hip, others"
    },
    {
      "name": "S7309",
      "description": "Dislocation of hip, unspecified"
    },
    {
      "name": "S731",
      "description": "Sprain and strain of hip"
    },
    {
      "name": "S740",
      "description": "Injury of sciatic nerve at hip and thigh level"
    },
    {
      "name": "S741",
      "description": "Injury of femoral nerve at hip and thigh level"
    },
    {
      "name": "S742",
      "description": "Injury of cutaneous sensory nerve at hip and thigh level"
    },
    {
      "name": "S747",
      "description": "Injury of multiple nerves at hip and thigh level"
    },
    {
      "name": "S748",
      "description": "Injury of other nerves at hip and thigh level"
    },
    {
      "name": "S749",
      "description": "Injury of unspecified nerve at hip and thigh level"
    },
    {
      "name": "S750",
      "description": "Injury of femoral artery"
    },
    {
      "name": "S751",
      "description": "Injury of femoral vein at hip and thigh level"
    },
    {
      "name": "S752",
      "description": "Injury of greater saphenous vein at hip and thigh level"
    },
    {
      "name": "S757",
      "description": "Injury of multiple blood vessels at hip and thigh level"
    },
    {
      "name": "S758",
      "description": "Injury of other blood vessels at hip and thigh level"
    },
    {
      "name": "S759",
      "description": "Injury of unspecified blood vessel at hip and thigh level"
    },
    {
      "name": "S760",
      "description": "Injury of muscle and tendon of hip"
    },
    {
      "name": "S761",
      "description": "Injury of quadriceps muscle and tendon"
    },
    {
      "name": "S762",
      "description": "Injury of adductor muscle and tendon of thigh"
    },
    {
      "name": "S763",
      "description": "Injury of muscle and tendon of the posterior muscle group at thigh level"
    },
    {
      "name": "S764",
      "description": "Injury of other and unspecified muscles and tendons at hip and thigh level"
    },
    {
      "name": "S767",
      "description": "Injury of multiple muscles and tendons at hip and thigh level"
    },
    {
      "name": "S770",
      "description": "Crushing injury of hip"
    },
    {
      "name": "S771",
      "description": "Crushing injury of thigh"
    },
    {
      "name": "S772",
      "description": "Crushing injury of hip with thigh"
    },
    {
      "name": "S780",
      "description": "Traumatic amputation at hip joint"
    },
    {
      "name": "S781",
      "description": "Traumatic amputation at level between hip and knee"
    },
    {
      "name": "S789",
      "description": "Traumatic amputation of hip and thigh, level unspecified"
    },
    {
      "name": "S797",
      "description": "Multiple injuries of hip and thigh"
    },
    {
      "name": "S798",
      "description": "Other specified injuries of hip and thigh"
    },
    {
      "name": "S799",
      "description": "Unspecified injury of hip and thigh"
    },
    {
      "name": "S800",
      "description": "Contusion of knee"
    },
    {
      "name": "S801",
      "description": "Contusion of other and unspecified parts of lower leg"
    },
    {
      "name": "S807",
      "description": "Multiple superficial injuries of lower leg"
    },
    {
      "name": "S8080",
      "description": "Abrasion of lower leg"
    },
    {
      "name": "S8081",
      "description": "Blister of lower leg"
    },
    {
      "name": "S8082",
      "description": "Insect bite of lower leg"
    },
    {
      "name": "S8083",
      "description": "Superficial foreign body of lower leg"
    },
    {
      "name": "S8088",
      "description": "Other superficial injuries of lower leg"
    },
    {
      "name": "S809",
      "description": "Superficial injury of lower leg, unspecified"
    },
    {
      "name": "S810",
      "description": "Open wound of knee"
    },
    {
      "name": "S817",
      "description": "Multiple open wounds of lower leg"
    },
    {
      "name": "S818",
      "description": "Open wound of other parts of lower leg"
    },
    {
      "name": "S819",
      "description": "Open wound of lower leg, part unspecified"
    },
    {
      "name": "S8200",
      "description": "Closed fracture of patella"
    },
    {
      "name": "S8201",
      "description": "Open fracture of patella"
    },
    {
      "name": "S82100",
      "description": "Closed fracture of upper end of tibia, extraarticular"
    },
    {
      "name": "S82101",
      "description": "Closed fracture of upper end of tibia, medial condyle"
    },
    {
      "name": "S82102",
      "description": "Closed fracture of upper end of tibia, lateral condyle (split)"
    },
    {
      "name": "S82103",
      "description": "Closed fracture of upper end of tibia, bicondyle"
    },
    {
      "name": "S82104",
      "description": "Closed fracture of upper end of tibia, with ligamentous injury"
    },
    {
      "name": "S82105",
      "description": "Closed isolated fracture of tibial spine and intercondylar eminence"
    },
    {
      "name": "S82106",
      "description": "Closed fracture of epiphysis of upper end of tibia"
    },
    {
      "name": "S82107",
      "description": "Closed fracture of upper end of tibia, multiple fractures"
    },
    {
      "name": "S82108",
      "description": "Closed fracture of upper end of tibia, others"
    },
    {
      "name": "S82109",
      "description": "Closed fracture of upper end of tibia, unspecified"
    },
    {
      "name": "S82110",
      "description": "Open fracture of upper end of tibia, extraarticular"
    },
    {
      "name": "S82111",
      "description": "Open fracture of upper end of tibia, medial condyle"
    },
    {
      "name": "S82112",
      "description": "Open fracture of upper end of tibia, lateral condyle (split)"
    },
    {
      "name": "S82113",
      "description": "Open fracture of upper end of tibia, bicondyle"
    },
    {
      "name": "S82114",
      "description": "Open fracture of upper end of tibia, with ligamentous injury"
    },
    {
      "name": "S82115",
      "description": "Open isolated fracture of tibial spine and intercondylar eminence"
    },
    {
      "name": "S82116",
      "description": "Open fracture of epiphysis of upper end of tibia"
    },
    {
      "name": "S82117",
      "description": "Open fracture of upper end of tibia, multiple fractures"
    },
    {
      "name": "S82118",
      "description": "Open fracture of upper end of tibia, others"
    },
    {
      "name": "S82119",
      "description": "Open fracture of upper end of tibia, unspecified"
    },
    {
      "name": "S82200",
      "description": "Closed fracture of shaft of tibia with intact fibula"
    },
    {
      "name": "S82201",
      "description": "Closed fracture of shaft of tibia with fracture of fibula at same level"
    },
    {
      "name": "S82202",
      "description": "Closed fracture of shaft of tibia with fracture of fibula at different level"
    },
    {
      "name": "S82207",
      "description": "Closed fracture of shaft of tibia, bifocal and segmental and multiple"
    },
    {
      "name": "S82208",
      "description": "Closed fracture of shaft of tibia, others"
    },
    {
      "name": "S82209",
      "description": "Closed fracture of shaft of tibia, unspecified"
    },
    {
      "name": "S82210",
      "description": "Open fracture of shaft of tibia with intact fibula"
    },
    {
      "name": "S82211",
      "description": "Open fracture of shaft of tibia with fracture of fibula at same level"
    },
    {
      "name": "S82212",
      "description": "Open fracture of shaft of tibia with fracture of fibula at different level"
    },
    {
      "name": "S82217",
      "description": "Open fracture of shaft of tibia, bifocal and segmental and multiple"
    },
    {
      "name": "S82218",
      "description": "Open fracture of shaft of tibia, others"
    },
    {
      "name": "S82219",
      "description": "Open fracture of shaft of tibia, unspecified"
    },
    {
      "name": "S82300",
      "description": "Closed fracture of lower end of tibia, extraarticular"
    },
    {
      "name": "S82301",
      "description": "Closed fracture of lower end of tibia, nondisplaced intraarticular"
    },
    {
      "name": "S82302",
      "description": "Closed fracture of lower end of tibia, displaced intraarticular"
    },
    {
      "name": "S82303",
      "description": "Closed fracture of epiphysis of lower end of tibia"
    },
    {
      "name": "S82307",
      "description": "Closed fracture of lower end of tibia, multiple fractures"
    },
    {
      "name": "S82308",
      "description": "Closed fracture of lower end of tibia, others"
    },
    {
      "name": "S82309",
      "description": "Closed fracture of lower end of tibia, unspecified"
    },
    {
      "name": "S82310",
      "description": "Open fracture of lower end of tibia, extraarticular"
    },
    {
      "name": "S82311",
      "description": "Open fracture of lower end of tibia, nondisplaced intraarticular"
    },
    {
      "name": "S82312",
      "description": "Open fracture of lower end of tibia, displaced intraarticular"
    },
    {
      "name": "S82313",
      "description": "Open fracture of epiphysis of lower end of tibia"
    },
    {
      "name": "S82317",
      "description": "Open fracture of lower end of tibia, multiple fractures"
    },
    {
      "name": "S82318",
      "description": "Open fracture of lower end of tibia, others"
    },
    {
      "name": "S82319",
      "description": "Open fracture of lower end of tibia, unspecified"
    },
    {
      "name": "S8240",
      "description": "Closed fracture of fibula alone"
    },
    {
      "name": "S8241",
      "description": "Open fracture of fibula alone"
    },
    {
      "name": "S8250",
      "description": "Closed fracture of medial malleolus"
    },
    {
      "name": "S8251",
      "description": "Open fracture of medial malleolus"
    },
    {
      "name": "S8260",
      "description": "Closed fracture of lateral malleolus"
    },
    {
      "name": "S8261",
      "description": "Open fracture of lateral malleolus"
    },
    {
      "name": "S8270",
      "description": "Multiple closed fractures of leg"
    },
    {
      "name": "S8271",
      "description": "Multiple open fractures of leg"
    },
    {
      "name": "S8280",
      "description": "Closed fractures of other parts of leg"
    },
    {
      "name": "S8281",
      "description": "Open fractures of other parts of leg"
    },
    {
      "name": "S8290",
      "description": "Closed fracture of leg, part unspecified"
    },
    {
      "name": "S8291",
      "description": "Open fracture of leg, part unspecified"
    },
    {
      "name": "S830",
      "description": "Dislocation of patella"
    },
    {
      "name": "S831",
      "description": "Dislocation of knee"
    },
    {
      "name": "S832",
      "description": "Tear of meniscus, current"
    },
    {
      "name": "S833",
      "description": "Tear of articular cartilage of knee, current"
    },
    {
      "name": "S834",
      "description": "Sprain and strain involving (fibular) (tibial )collateral ligament of knee"
    },
    {
      "name": "S8350",
      "description": "Sprain ans strain involving anterior cruciate ligament ACL"
    },
    {
      "name": "S8351",
      "description": "Sprain ans strain involving posterior cruciate ligament PCL"
    },
    {
      "name": "S8359",
      "description": "Sprain and strain involving cruciate ligament of knee, unspecified"
    },
    {
      "name": "S836",
      "description": "Sprain and strain of ligament of other and unspecified parts of knee"
    },
    {
      "name": "S837",
      "description": "Injury to multiple structures of knee"
    },
    {
      "name": "S840",
      "description": "Injury of tibial nerve at lower leg level"
    },
    {
      "name": "S841",
      "description": "Injury of peroneal nerve at lower leg level"
    },
    {
      "name": "S842",
      "description": "Injury of cutaneous sensory nerve at lower leg level"
    },
    {
      "name": "S847",
      "description": "Injury of multiple nerves at lower leg level"
    },
    {
      "name": "S848",
      "description": "Injury of other nerves at lower leg level"
    },
    {
      "name": "S849",
      "description": "Injury of unspecified nerve at lower leg level"
    },
    {
      "name": "S850",
      "description": "Injury of popliteal artery"
    },
    {
      "name": "S851",
      "description": "Injury of anterior posterior tibial artery"
    },
    {
      "name": "S852",
      "description": "Injury of peroneal artery"
    },
    {
      "name": "S853",
      "description": "Injury of greater saphenous vein at lower leg level"
    },
    {
      "name": "S854",
      "description": "Injury of lesser saphenous vein at lower leg level"
    },
    {
      "name": "S855",
      "description": "Injury of popliteal vein"
    },
    {
      "name": "S857",
      "description": "Injury of multiple blood vessels at lower leg level"
    },
    {
      "name": "S858",
      "description": "Injury of other blood vessels at lower leg level"
    },
    {
      "name": "S859",
      "description": "Injury of unspecified blood vessel at lower leg level"
    },
    {
      "name": "S860",
      "description": "Injury of Achilles tendon"
    },
    {
      "name": "S861",
      "description": "Injury of other muscle and tendon(s) of posterior muscle group at lower leg level"
    },
    {
      "name": "S862",
      "description": "Injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level"
    },
    {
      "name": "S863",
      "description": "Injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level"
    },
    {
      "name": "S867",
      "description": "Injury of multiple muscles and tendon at lower leg level"
    },
    {
      "name": "S868",
      "description": "Injury of other muscles and tendons at lower leg level"
    },
    {
      "name": "S869",
      "description": "Injury of unspecified muscle and tendon at lower leg level"
    },
    {
      "name": "S870",
      "description": "Crushing injury of knee"
    },
    {
      "name": "S878",
      "description": "Crushing injury of other and unspecified parts of lower leg"
    },
    {
      "name": "S880",
      "description": "Traumatic amputation at knee level"
    },
    {
      "name": "S881",
      "description": "Traumatic amputation at level between knee and ankle"
    },
    {
      "name": "S889",
      "description": "Traumatic amputation of lower leg, level unspecified"
    },
    {
      "name": "S897",
      "description": "Multiple injuries of lower leg"
    },
    {
      "name": "S898",
      "description": "Other specified injuries of lower leg"
    },
    {
      "name": "S899",
      "description": "Unspecified injury of lower leg"
    },
    {
      "name": "S900",
      "description": "Contusion of ankle"
    },
    {
      "name": "S901",
      "description": "Contusion of toe(s) without damage to nail"
    },
    {
      "name": "S902",
      "description": "Contusion of toe(s) with damage to nail"
    },
    {
      "name": "S903",
      "description": "Contusion of other and unspecified parts of foot"
    },
    {
      "name": "S907",
      "description": "Multiple superficial injuries of ankle and foot"
    },
    {
      "name": "S9080",
      "description": "Abrasion of ankle and foot"
    },
    {
      "name": "S9081",
      "description": "Bister of ankle and foot"
    },
    {
      "name": "S9082",
      "description": "Insect bite of ankle and foot"
    },
    {
      "name": "S9083",
      "description": "Superficial foreign body of ankle and foot"
    },
    {
      "name": "S9088",
      "description": "Other superficial injuries of ankle and foot"
    },
    {
      "name": "S909",
      "description": "Superficial injury of ankle and foot, unspecified"
    },
    {
      "name": "S910",
      "description": "Open wound of ankle"
    },
    {
      "name": "S911",
      "description": "Open wound of toe(s) without damage to nail"
    },
    {
      "name": "S912",
      "description": "Open wound of toe(s) with damage to nail"
    },
    {
      "name": "S913",
      "description": "Open wound of other parts of foot"
    },
    {
      "name": "S917",
      "description": "Multiple open wounds of ankle and foot"
    },
    {
      "name": "S9200",
      "description": "Closed fracture of calcaneous"
    },
    {
      "name": "S9201",
      "description": "Open fracture of calcaneous"
    },
    {
      "name": "S9210",
      "description": "Closed fracture of talus"
    },
    {
      "name": "S9211",
      "description": "Open fracture of talus"
    },
    {
      "name": "S9220",
      "description": "Closed fracture of other tarsal bone(s)"
    },
    {
      "name": "S9221",
      "description": "Open fracture of other tarsal bone(s)"
    },
    {
      "name": "S92300",
      "description": "Closed fracture of one metatarsal bone"
    },
    {
      "name": "S92301",
      "description": "Closed fracture of two metatarsal bones"
    },
    {
      "name": "S92302",
      "description": "Closed fracture of three or more metatarsal bones"
    },
    {
      "name": "S92309",
      "description": "Closed fracture of unspecified number of metatarsal bone"
    },
    {
      "name": "S92310",
      "description": "Open fracture of one metatarsal bone"
    },
    {
      "name": "S92311",
      "description": "Open fracture of two metatarsal bones"
    },
    {
      "name": "S92312",
      "description": "Open fracture of three or more metatarsal bones"
    },
    {
      "name": "S92319",
      "description": "Open fracture of unspecified number of metatarsal bone"
    },
    {
      "name": "S9240",
      "description": "Closed fracture of great toe"
    },
    {
      "name": "S9241",
      "description": "Open fracture of great toe"
    },
    {
      "name": "S9250",
      "description": "Closed fracture of other toe"
    },
    {
      "name": "S9251",
      "description": "Open fracture of other toe"
    },
    {
      "name": "S9270",
      "description": "Multiple closed fracture of foot"
    },
    {
      "name": "S9271",
      "description": "Multiple open fracture of foot"
    },
    {
      "name": "S9290",
      "description": "Closed fracture of foot, unspecified"
    },
    {
      "name": "S9291",
      "description": "Open fracture of foot, unspecified"
    },
    {
      "name": "S930",
      "description": "Dislocation of ankle joint"
    },
    {
      "name": "S931",
      "description": "Dislocation of toe(s)"
    },
    {
      "name": "S932",
      "description": "Rupture of ligaments at ankle and foot level"
    },
    {
      "name": "S933",
      "description": "Dislocation of other and unspecified parts of foot"
    },
    {
      "name": "S934",
      "description": "Sprain and strain of ankle"
    },
    {
      "name": "S935",
      "description": "Sprain and strain of toe(s)"
    },
    {
      "name": "S936",
      "description": "Sprain and strain of other and unspecified parts of foot"
    },
    {
      "name": "S940",
      "description": "Injury of lateral plantar nerve"
    },
    {
      "name": "S941",
      "description": "Injury of medial plantar nerve"
    },
    {
      "name": "S942",
      "description": "Injury of deep peroneal nerve at ankle and foot level"
    },
    {
      "name": "S943",
      "description": "Injury of cutaneous sensory nerve at ankle and foot level"
    },
    {
      "name": "S947",
      "description": "Injury of multiple nerves at ankle and foot level"
    },
    {
      "name": "S948",
      "description": "Injury of other nerves at ankle and foot level"
    },
    {
      "name": "S949",
      "description": "Injury of unspecified nerve at ankle and foot level"
    },
    {
      "name": "S950",
      "description": "Injury of dorsal artery of foot"
    },
    {
      "name": "S951",
      "description": "Injury of plantar artery of foot"
    },
    {
      "name": "S952",
      "description": "Injury of dorsal vein of foot"
    },
    {
      "name": "S957",
      "description": "Injury of multiple blood vessels at ankle and foot level"
    },
    {
      "name": "S958",
      "description": "Injury of other blood vessels at ankle and foot level"
    },
    {
      "name": "S959",
      "description": "Injury of unspecified blood vessel at ankle and foot level"
    },
    {
      "name": "S960",
      "description": "Injury of muscle and tendon of long flexor muscle of toe at ankle and foot level"
    },
    {
      "name": "S961",
      "description": "Injury of muscle and tendon of long extensor muscle of toe at ankle and foot level"
    },
    {
      "name": "S962",
      "description": "Injury of intrinsic muscle and tendon at ankle and foot level"
    },
    {
      "name": "S967",
      "description": "Injury of multiple muscles and tendons at ankle and foot level"
    },
    {
      "name": "S968",
      "description": "Injury of other muscles and tendons at ankle and foot level"
    },
    {
      "name": "S969",
      "description": "Injury of unspecified muscle and tendon at ankle and foot level"
    },
    {
      "name": "S970",
      "description": "Crushing injury of ankle"
    },
    {
      "name": "S971",
      "description": "Crushing injury of toe(s)"
    },
    {
      "name": "S978",
      "description": "Crushing injury of other parts of ankle and foot"
    },
    {
      "name": "S980",
      "description": "Traumatic amputation of foot at ankle level"
    },
    {
      "name": "S981",
      "description": "Traumatic amputation of one toe"
    },
    {
      "name": "S982",
      "description": "Traumatic amputation of two or more toes"
    },
    {
      "name": "S983",
      "description": "Traumatic amputation of other parts of foot"
    },
    {
      "name": "S984",
      "description": "Traumatic amputation of ankle and foot,unspecified"
    },
    {
      "name": "S997",
      "description": "Multiple injuries of ankle and foot"
    },
    {
      "name": "S998",
      "description": "Other specified injuries of ankle and foot"
    },
    {
      "name": "S999",
      "description": "Unspecified injury of ankle and foot"
    },
    {
      "name": "T000",
      "description": "Superficial injuries involving head with neck"
    },
    {
      "name": "T001",
      "description": "Superficial injuries involving thorax with abdomen, lower back and pelvis"
    },
    {
      "name": "T002",
      "description": "Superficial injuries involving multiple regions of upper limb(s)"
    },
    {
      "name": "T003",
      "description": "Superficial injuries involving multiple regions of lower limb(s)"
    },
    {
      "name": "T006",
      "description": "Superficial injuries involving multiple regions of upper limb(s) with lower limb(s)"
    },
    {
      "name": "T008",
      "description": "Superficial injuries involving other combinations of body regions"
    },
    {
      "name": "T009",
      "description": "Multiple superficial injuries, unspecified"
    },
    {
      "name": "T010",
      "description": "Open wounds involving head with neck"
    },
    {
      "name": "T011",
      "description": "Open wounds involving thorax with abdomen, lower back and pelvis"
    },
    {
      "name": "T012",
      "description": "Open wounds involving multiple regions of upper limb(s)"
    },
    {
      "name": "T013",
      "description": "Open wounds involving multiple regions of lower limb(s)"
    },
    {
      "name": "T016",
      "description": "Open wounds involving multiple regions of upper limb(s) with lower limb(s)"
    },
    {
      "name": "T018",
      "description": "Open wounds involving other combinations of body regions"
    },
    {
      "name": "T019",
      "description": "Multiple open wounds, unspecified"
    },
    {
      "name": "T0200",
      "description": "Closed fractures involving head with neck"
    },
    {
      "name": "T0201",
      "description": "Open fractures involving head with neck"
    },
    {
      "name": "T0210",
      "description": "Closed fractures involving thorax with lower back and pelvis"
    },
    {
      "name": "T0211",
      "description": "Open fractures involving thorax with lower back and pelvis"
    },
    {
      "name": "T0220",
      "description": "Closed fractures involving multiple regions of one upper limb"
    },
    {
      "name": "T0221",
      "description": "Open fractures involving multiple regions of one upper limb"
    },
    {
      "name": "T0230",
      "description": "Closed fractures involving multiple regions of one lower limb"
    },
    {
      "name": "T0231",
      "description": "Open fractures involving multiple regions of one lower limb"
    },
    {
      "name": "T0240",
      "description": "Closed fractures involving multiple regions of both upper limbs"
    },
    {
      "name": "T0241",
      "description": "Open fractures involving multiple regions of both upper limbs"
    },
    {
      "name": "T0250",
      "description": "Closed fractures involving multiple regions of both lower limbs"
    },
    {
      "name": "T0251",
      "description": "Open fractures involving multiple regions of both lower limbs"
    },
    {
      "name": "T0260",
      "description": "Closed fractures involving multiple regions of upper limb(s) and lower limb(s)"
    },
    {
      "name": "T0261",
      "description": "Open fractures involving multiple regions of upper limb(s) and lower limb(s)"
    },
    {
      "name": "T0270",
      "description": "Closed fractures involving thorax with lower back and pelvis and limb(s)"
    },
    {
      "name": "T0271",
      "description": "Open fractures involving thorax with lower back and pelvis and limb(s)"
    },
    {
      "name": "T0280",
      "description": "Closed fractures involving other combinations of body regions"
    },
    {
      "name": "T0281",
      "description": "Open fractures involving other combinations of body regions"
    },
    {
      "name": "T0290",
      "description": "Multiple closed fractures, unspecified"
    },
    {
      "name": "T0291",
      "description": "Multiple open fractures, unspecified"
    },
    {
      "name": "T030",
      "description": "Dislocations, sprains and strains involving head with neck"
    },
    {
      "name": "T031",
      "description": "Dislocations, sprains and strains involving thorax with lower back and pelvis"
    },
    {
      "name": "T032",
      "description": "Dislocations, sprains and strains involving multiple regions of upper limb(s)"
    },
    {
      "name": "T033",
      "description": "Dislocations, sprains and strains involving multiple regions of lower limb(s)"
    },
    {
      "name": "T034",
      "description": "Dislocations, sprains and strains involving multiple regions of upper limb(s) with lower limb(s)"
    },
    {
      "name": "T038",
      "description": "Dislocations, sprains and strains involving other combinations of body regions"
    },
    {
      "name": "T039",
      "description": "Multiple dislocations, sprains and strains,unspecified"
    },
    {
      "name": "T040",
      "description": "Crushing injuries involving head with neck"
    },
    {
      "name": "T041",
      "description": "Crushing injuries involving thorax with abdomen,lower back and pelvis"
    },
    {
      "name": "T042",
      "description": "Crushing injuries involving multiple regions of upper limb(s)"
    },
    {
      "name": "T043",
      "description": "Crushing injuries involving multiple regions of lower limb(s)"
    },
    {
      "name": "T044",
      "description": "Crushing injuries involving multiple regions of upper limb(s) with lower limb(s)"
    },
    {
      "name": "T047",
      "description": "Crushing injuries of thorax with abdomen, lower back and pelvis with limb(s)"
    },
    {
      "name": "T048",
      "description": "Crushing injuries involving other combinations of body regions"
    },
    {
      "name": "T049",
      "description": "Multiple crushing injuries, unspecified"
    },
    {
      "name": "T050",
      "description": "Traumatic amputation of both hands"
    },
    {
      "name": "T051",
      "description": "Traumatic amputation of one hand and other arm [any level, except hand]"
    },
    {
      "name": "T052",
      "description": "Traumatic amputation of both arms [any level]"
    },
    {
      "name": "T053",
      "description": "Traumatic amputation of both feet"
    },
    {
      "name": "T054",
      "description": "Traumatic amputation of one foot and other leg [any level, except foot]"
    },
    {
      "name": "T055",
      "description": "Traumatic amputation of both legs [any level]"
    },
    {
      "name": "T056",
      "description": "Traumatic amputation of upper and lower limbs,any combination [any level]"
    },
    {
      "name": "T058",
      "description": "Traumatic amputations involving other combinations of body regions"
    },
    {
      "name": "T059",
      "description": "Multiple traumatic amputations, unspecified"
    },
    {
      "name": "T060",
      "description": "Injuries of brain and cranial nerves with injuries of nerves and spinal cord at neck level"
    },
    {
      "name": "T061",
      "description": "Injuries of nerves and spinal cord involving other multiple body regions"
    },
    {
      "name": "T062",
      "description": "Injureis of nerves involving multiple body regions"
    },
    {
      "name": "T063",
      "description": "Injuries of blood vessels involving multiple body regions"
    },
    {
      "name": "T064",
      "description": "Injuries of muscles and tendons involving multiple body regions"
    },
    {
      "name": "T065",
      "description": "Injuries of intrathoracic organs with intra-abdominal and pelvic organs"
    },
    {
      "name": "T068",
      "description": "Other specified injuries involving multiple body regions"
    },
    {
      "name": "T07",
      "description": "Unspecified multiple injuries"
    },
    {
      "name": "T080",
      "description": "Closed fracture of spine, level unspecified"
    },
    {
      "name": "T081",
      "description": "Open fracture of spine, unspecified"
    },
    {
      "name": "T090",
      "description": "Superficial injury of trunk, level unspecified"
    },
    {
      "name": "T091",
      "description": "Open wound of trunk, level unspecified"
    },
    {
      "name": "T092",
      "description": "Dislocation, sprain and strain of unspecified joint and ligament of trunk"
    },
    {
      "name": "T093",
      "description": "Injury of spinal cord, level unspecified"
    },
    {
      "name": "T094",
      "description": "Injury of unspecified nerve, spinal nerve root and plexus of trunk"
    },
    {
      "name": "T095",
      "description": "Injury of unspecified muscle and tendon of trunk"
    },
    {
      "name": "T096",
      "description": "Traumatic amputation of trunk, level unspecified"
    },
    {
      "name": "T098",
      "description": "Other specified injuries of trunk, level unspecified"
    },
    {
      "name": "T099",
      "description": "Unspecified injury of trunk, level unspecified"
    },
    {
      "name": "T100",
      "description": "Closed fracture of upper limb, level unspecified"
    },
    {
      "name": "T101",
      "description": "Open fracture of upper limb, level unspecified"
    },
    {
      "name": "T110",
      "description": "Superficial injury of upper limb, level unspecified"
    },
    {
      "name": "T111",
      "description": "Open wound of upper limb, level unspecified"
    },
    {
      "name": "T112",
      "description": "Dislocation, sprain and strain of unspecified joint and ligament of upper limb, level unspecified"
    },
    {
      "name": "T113",
      "description": "Injury of unspecified nerve of upper limb, level unspecified"
    },
    {
      "name": "T114",
      "description": "Injury of unspecified blood vessel of upper limb,level unspecified"
    },
    {
      "name": "T115",
      "description": "Injury of unspecified muscle and tendon of upper limb, level unspecified"
    },
    {
      "name": "T116",
      "description": "Traumatic amputation of upper limb, level unspecified"
    },
    {
      "name": "T118",
      "description": "Other specified injuries of upper limb, level unspecified"
    },
    {
      "name": "T119",
      "description": "Unspecified injury of upper limb, level unspecified"
    },
    {
      "name": "T120",
      "description": "Closed fracture of lower limb, level unspecified"
    },
    {
      "name": "T121",
      "description": "Open fracture of lower limb, level unspecified"
    },
    {
      "name": "T130",
      "description": "Superficial injury of lower limb, level unspecified"
    },
    {
      "name": "T131",
      "description": "Open wound of lower limb, level unspecified"
    },
    {
      "name": "T132",
      "description": "Dislocation, sprain and strain of unspecified joint and ligament of lower limb, level unspecified"
    },
    {
      "name": "T133",
      "description": "Injury of unspecified nerve of lower limb, level unspecified"
    },
    {
      "name": "T134",
      "description": "Injury of unspecified blood vessel of lower limb,level unspecified"
    },
    {
      "name": "T135",
      "description": "Injury of unspecified muscle and tendon of lower limb, level unspecified"
    },
    {
      "name": "T136",
      "description": "Traumatic amputation of lower limb, level unspecified"
    },
    {
      "name": "T138",
      "description": "Other specified injuries of lower limb, level unspecified"
    },
    {
      "name": "T139",
      "description": "Unspecified injury of lower limb, level unspecified"
    },
    {
      "name": "T140",
      "description": "Superficial injury of unspecified body region"
    },
    {
      "name": "T141",
      "description": "Open wound of unspecified body region"
    },
    {
      "name": "T1420",
      "description": "Closed fracture of unspecified body region"
    },
    {
      "name": "T1421",
      "description": "Open fracture of unspecified body region"
    },
    {
      "name": "T143",
      "description": "Dislocation, sprain and strain of unspecified body region"
    },
    {
      "name": "T144",
      "description": "Injury of nerve(s) of unspecified body region"
    },
    {
      "name": "T145",
      "description": "Injury of blood vessel(s) of unspecified body region"
    },
    {
      "name": "T146",
      "description": "Injury of muscles and tendons of unspecified body region"
    },
    {
      "name": "T147",
      "description": "Crushing injury and traumatic amputation of unspecified body region"
    },
    {
      "name": "T148",
      "description": "Other injuries of unspecified body region"
    },
    {
      "name": "T149",
      "description": "Injury, unspecified"
    },
    {
      "name": "T150",
      "description": "Foreign body in cornea"
    },
    {
      "name": "T151",
      "description": "Foreign body in conjunctival sac"
    },
    {
      "name": "T158",
      "description": "Foreign body in other and multiple parts of external eye"
    },
    {
      "name": "T159",
      "description": "Foreign body on external eye, part unspecified"
    },
    {
      "name": "T16",
      "description": "Foreign body in ear"
    },
    {
      "name": "T170",
      "description": "Foreign body in nasal sinus"
    },
    {
      "name": "T171",
      "description": "Foreign body in nostril"
    },
    {
      "name": "T172",
      "description": "Foreign body in pharynx"
    },
    {
      "name": "T173",
      "description": "Foreign body in larynx"
    },
    {
      "name": "T174",
      "description": "Foreign body in trachea"
    },
    {
      "name": "T175",
      "description": "Foreign body in bronchus"
    },
    {
      "name": "T178",
      "description": "Foreign body in other and multiple parts of respiratory tract"
    },
    {
      "name": "T179",
      "description": "Foreign body in respiratory tract, part unspecified"
    },
    {
      "name": "T180",
      "description": "Foreign body in mouth"
    },
    {
      "name": "T181",
      "description": "Foreign body in oesophagus"
    },
    {
      "name": "T182",
      "description": "Foreign body in stomach"
    },
    {
      "name": "T183",
      "description": "Foreign body in small intestine"
    },
    {
      "name": "T184",
      "description": "Foreign body in colon"
    },
    {
      "name": "T185",
      "description": "Foreign body in anus and rectum"
    },
    {
      "name": "T188",
      "description": "Foreign body in other and multiple parts of alimentary tract"
    },
    {
      "name": "T189",
      "description": "Foreign body in alimentary tract, part unspecified"
    },
    {
      "name": "T190",
      "description": "Foreign body in urethra"
    },
    {
      "name": "T191",
      "description": "Foreign body in bladder"
    },
    {
      "name": "T192",
      "description": "Foreign body in vulva and vagina"
    },
    {
      "name": "T193",
      "description": "Foreign body in uterus any part"
    },
    {
      "name": "T198",
      "description": "Foreign body in other and multiple parts of genitourinary tract"
    },
    {
      "name": "T199",
      "description": "Foreign body in genitourinary tract, part unspecified"
    },
    {
      "name": "T200",
      "description": "Burn of unspecified degree of head and neck"
    },
    {
      "name": "T201",
      "description": "Burn of first degree of head and neck"
    },
    {
      "name": "T202",
      "description": "Burn of second degree of head and neck"
    },
    {
      "name": "T203",
      "description": "Burn of third degree of head and neck"
    },
    {
      "name": "T204",
      "description": "Corrosion of unspecified degree of head and neck"
    },
    {
      "name": "T205",
      "description": "Corrosion of first degree of head and neck"
    },
    {
      "name": "T206",
      "description": "Corrosion of second degree of head and neck"
    },
    {
      "name": "T207",
      "description": "Corrosion of third degree of head and neck"
    },
    {
      "name": "T210",
      "description": "Burn of unspecified degree of trunk"
    },
    {
      "name": "T211",
      "description": "Burn of first degree of trunk"
    },
    {
      "name": "T212",
      "description": "Burn of second degree of trunk"
    },
    {
      "name": "T213",
      "description": "Burn of third degree of trunk"
    },
    {
      "name": "T214",
      "description": "Corrosion of unspecified degree of trunk"
    },
    {
      "name": "T215",
      "description": "Corrosion of first degree of trunk"
    },
    {
      "name": "T216",
      "description": "Corrosion of second degree of trunk"
    },
    {
      "name": "T217",
      "description": "Corrosion of third degree of trunk"
    },
    {
      "name": "T220",
      "description": "Burn of unspecified degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T221",
      "description": "Burn of first degree of shoulder and upper limb,except wrist and hand"
    },
    {
      "name": "T222",
      "description": "Burn of second degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T223",
      "description": "Burn of third degree of shoulder and upper limb,except wrist and hand"
    },
    {
      "name": "T224",
      "description": "Corrosion of unspecified degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T225",
      "description": "Corrosion of first degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T226",
      "description": "Corrosion of second degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T227",
      "description": "Corrosion of third degree of shoulder and upper limb, except wrist and hand"
    },
    {
      "name": "T230",
      "description": "Burn of unspecified degree of wrist and hand"
    },
    {
      "name": "T231",
      "description": "Burn of first degree of wrist and hand"
    },
    {
      "name": "T232",
      "description": "Burn of second degree of wrist and hand"
    },
    {
      "name": "T233",
      "description": "Burn of third degree of wrist and hand"
    },
    {
      "name": "T234",
      "description": "Corrosion of unspecified degree of wrist and hand"
    },
    {
      "name": "T235",
      "description": "Corrosion of first degree of wrist and hand"
    },
    {
      "name": "T236",
      "description": "Corrosion of second degree of wrist and hand"
    },
    {
      "name": "T237",
      "description": "Corrosion of third degree of wrist and hand"
    },
    {
      "name": "T240",
      "description": "Burn of unspecified degree of hip and lower limb, except ankle and foot"
    },
    {
      "name": "T241",
      "description": "Burn of first degree of hip and lower limb, except ankle and foot"
    },
    {
      "name": "T242",
      "description": "Burn of second degree of hip and lower limb,except ankle and foot"
    },
    {
      "name": "T243",
      "description": "Burn of third degree of hip and lower limb, except ankle and foot"
    },
    {
      "name": "T244",
      "description": "Corrosion of unspecified degree of hip and lower limb, except ankle and foot"
    },
    {
      "name": "T245",
      "description": "Corrosion of first degree of hip and lower limb,except ankle and foot"
    },
    {
      "name": "T246",
      "description": "Corrosion of second degree of hip and lower limb, except ankle and foot"
    },
    {
      "name": "T247",
      "description": "Corrosion of third degree of hip and lower limb,except ankle and foot"
    },
    {
      "name": "T250",
      "description": "Burn of unspecified degree of ankle and foot"
    },
    {
      "name": "T251",
      "description": "Burn of first degree of ankle and foot"
    },
    {
      "name": "T252",
      "description": "Burn of second degree of ankle and foot"
    },
    {
      "name": "T253",
      "description": "Burn of third degree of ankle and foot"
    },
    {
      "name": "T254",
      "description": "Corrosion of unspecified degree of ankle and foot"
    },
    {
      "name": "T255",
      "description": "Corrosion of first degree of ankle and foot"
    },
    {
      "name": "T256",
      "description": "Corrosion of second degree of ankle and foot"
    },
    {
      "name": "T257",
      "description": "Corrosion of third degree of ankle and foot"
    },
    {
      "name": "T260",
      "description": "Burn of eyelid and periocular area"
    },
    {
      "name": "T261",
      "description": "Burn of cornea and conjunctival sac"
    },
    {
      "name": "T262",
      "description": "Burn with resulting rupture and destruction of eyeball"
    },
    {
      "name": "T263",
      "description": "Burn of other parts of eye and adnexa"
    },
    {
      "name": "T264",
      "description": "Burn of eye and adnexa, part unspecified"
    },
    {
      "name": "T265",
      "description": "Corrosion of eyelid and periocular area"
    },
    {
      "name": "T266",
      "description": "Corrosion of cornea and conjunctival sac"
    },
    {
      "name": "T267",
      "description": "Corrosion with resulting rupture and destruction of eyeball"
    },
    {
      "name": "T268",
      "description": "Corrosion of other parts of eye and adnexa"
    },
    {
      "name": "T269",
      "description": "Corrosion of eye and adnexa, part unspecified"
    },
    {
      "name": "T270",
      "description": "Burn of larynx and trachea"
    },
    {
      "name": "T271",
      "description": "Burn involving larynx and trachea with lung"
    },
    {
      "name": "T272",
      "description": "Burn of other parts of respiratory tract"
    },
    {
      "name": "T273",
      "description": "Burn of respiratory tract, part unspecified"
    },
    {
      "name": "T274",
      "description": "Corrosion of larynx and trachea"
    },
    {
      "name": "T275",
      "description": "Corrosion involving larynx and trachea with lung"
    },
    {
      "name": "T276",
      "description": "Corrosion of other parts of respiratory tract"
    },
    {
      "name": "T277",
      "description": "Corrosion of respiratory tract, part unspecified"
    },
    {
      "name": "T280",
      "description": "Burn of mouth and pharynx"
    },
    {
      "name": "T281",
      "description": "Burn of oesophagus"
    },
    {
      "name": "T282",
      "description": "Burn of other parts of alimentary tract"
    },
    {
      "name": "T283",
      "description": "Burn of internal genitourinary organs"
    },
    {
      "name": "T284",
      "description": "Burn of other and unspecified internal organs"
    },
    {
      "name": "T285",
      "description": "Corrosion of mouth and pharynx"
    },
    {
      "name": "T286",
      "description": "Corrosion of oesophagus"
    },
    {
      "name": "T287",
      "description": "Corrosion of other parts of alimentary tract"
    },
    {
      "name": "T288",
      "description": "Corrosion of internal genitourinary organs"
    },
    {
      "name": "T289",
      "description": "Corrosion of other and unspecified internal organs"
    },
    {
      "name": "T290",
      "description": "Burns of multiple regions, unspecified degree"
    },
    {
      "name": "T291",
      "description": "Burns of multiple regions, no more than first-degree burns mentioned"
    },
    {
      "name": "T292",
      "description": "Burns of multiple regions, no more than second-degree burns mentioned"
    },
    {
      "name": "T293",
      "description": "Burns of multiple regions, at least one burn of third degree mentioned"
    },
    {
      "name": "T294",
      "description": "Corrosions of multiple regions, unspecified degree"
    },
    {
      "name": "T295",
      "description": "Corrosions of multiple regions, no more than first-degree corrosions mentioned"
    },
    {
      "name": "T296",
      "description": "Corrosions of multiple regions, no more than second-degree corrosions mentioned"
    },
    {
      "name": "T297",
      "description": "Corrosions of multiple regions, at least one corrosion of third degree mentioned"
    },
    {
      "name": "T300",
      "description": "Burn of unspecified body region, unspecified degree"
    },
    {
      "name": "T301",
      "description": "Burn of first degree, body region unspecified"
    },
    {
      "name": "T302",
      "description": "Burn of second degree, body region unspecified"
    },
    {
      "name": "T303",
      "description": "Burn of third degree, body region unspecified"
    },
    {
      "name": "T304",
      "description": "Corrosion of unspecified body region,unspecified degree"
    },
    {
      "name": "T305",
      "description": "Corrosion of first degree, body region unspecified"
    },
    {
      "name": "T306",
      "description": "Corrosion of second degree, body region unspecified"
    },
    {
      "name": "T307",
      "description": "Corrosion of third degree, body region unspecified"
    },
    {
      "name": "T310",
      "description": "Burns involving less than 10% of body surface"
    },
    {
      "name": "T311",
      "description": "Burns involving 10-19% of body surface"
    },
    {
      "name": "T312",
      "description": "Burns involving 20-29% of body surface"
    },
    {
      "name": "T313",
      "description": "Burns involving 30-39% of body surface"
    },
    {
      "name": "T314",
      "description": "Burns involving 40-49% of body surface"
    },
    {
      "name": "T315",
      "description": "Burns involving 50-59% of body surface"
    },
    {
      "name": "T316",
      "description": "Burns involving 60-69% of body surface"
    },
    {
      "name": "T317",
      "description": "Burns involving 70-79% of body surface"
    },
    {
      "name": "T318",
      "description": "Burns involving 80-89% of body surface"
    },
    {
      "name": "T319",
      "description": "Burns involving 90% or more of body surface"
    },
    {
      "name": "T320",
      "description": "Corrosions involving less than 10% of body surface"
    },
    {
      "name": "T321",
      "description": "Corrosions involving 10-19% of body surface"
    },
    {
      "name": "T322",
      "description": "Corrosions involving 20-29% of body surface"
    },
    {
      "name": "T323",
      "description": "Corrosions involving 30-39% of body surface"
    },
    {
      "name": "T324",
      "description": "Corrosions involving 40-49% of body surface"
    },
    {
      "name": "T325",
      "description": "Corrosions involving 50-59% of body surface"
    },
    {
      "name": "T326",
      "description": "Corrosions involving 60-69% of body surface"
    },
    {
      "name": "T327",
      "description": "Corrosions involving 70-79% of body surface"
    },
    {
      "name": "T328",
      "description": "Corrosions involving 80-89% of body surface"
    },
    {
      "name": "T329",
      "description": "Corrosions involving 90% or more of body surface"
    },
    {
      "name": "T330",
      "description": "Superficial frostbite of head"
    },
    {
      "name": "T331",
      "description": "Superficial frostbite of neck"
    },
    {
      "name": "T332",
      "description": "Superficial frostbite of thorax"
    },
    {
      "name": "T333",
      "description": "Superficial frostbite of abdominal wall, lower back and pelvis"
    },
    {
      "name": "T334",
      "description": "Superficial frostbite of arm"
    },
    {
      "name": "T335",
      "description": "Superficial frostbite of wrist and hand"
    },
    {
      "name": "T336",
      "description": "Superficial frostbite of hip and thigh"
    },
    {
      "name": "T337",
      "description": "Superficial frostbite of knee and lower leg"
    },
    {
      "name": "T338",
      "description": "Superficial frostbite of ankle and foot"
    },
    {
      "name": "T339",
      "description": "Superficial frostbite of other and unspecified sites"
    },
    {
      "name": "T340",
      "description": "Frostbite with tissue necrosis of head"
    },
    {
      "name": "T341",
      "description": "Frostbite with tissue necrosis of neck"
    },
    {
      "name": "T342",
      "description": "Frostbite with tissue necrosis of thorax"
    },
    {
      "name": "T343",
      "description": "Frostbite with tissue necrosis of abdominal wall,lower back and pelvis"
    },
    {
      "name": "T344",
      "description": "Frostbite with tissue necrosis of arm"
    },
    {
      "name": "T345",
      "description": "Frostbite with tissue necrosis of wrist and hand"
    },
    {
      "name": "T346",
      "description": "Frostbite with tissue necrosis of hip and thigh"
    },
    {
      "name": "T347",
      "description": "Frostbite with tissue necrosis of knee and lower leg"
    },
    {
      "name": "T348",
      "description": "Frostbite with tissue necrosis of ankle and foot"
    },
    {
      "name": "T349",
      "description": "Frostbite with tissue necrosis of other and unspecified sites"
    },
    {
      "name": "T350",
      "description": "Superficial frostbite involving multiple body regions"
    },
    {
      "name": "T351",
      "description": "Frostbite with tissue necrosis involving multiple body regions"
    },
    {
      "name": "T352",
      "description": "Unspecified frostbite of head and neck"
    },
    {
      "name": "T353",
      "description": "Unspecified frostbite of thorax, abdomen,lower back and pelvis"
    },
    {
      "name": "T354",
      "description": "Unspecified frostbite of upper limb"
    },
    {
      "name": "T355",
      "description": "Unspecified frostbite of lower limb"
    },
    {
      "name": "T356",
      "description": "Unspecified frostbite involving multiple body regions"
    },
    {
      "name": "T357",
      "description": "Unspecified frostbite of unspecified site"
    },
    {
      "name": "T360",
      "description": "Penicillins"
    },
    {
      "name": "T361",
      "description": "Cefalosporins and other β–lactam antibiotics"
    },
    {
      "name": "T362",
      "description": "Chloramphenicol group"
    },
    {
      "name": "T363",
      "description": "Macrolides"
    },
    {
      "name": "T364",
      "description": "Tetracyclines"
    },
    {
      "name": "T365",
      "description": "Aminoglycosides"
    },
    {
      "name": "T366",
      "description": "Rifamycins"
    },
    {
      "name": "T367",
      "description": "Antifungal antibiotics, systemically used"
    },
    {
      "name": "T368",
      "description": "Other systemic antibiotics"
    },
    {
      "name": "T369",
      "description": "Systemic antibiotic, unspecified"
    },
    {
      "name": "T370",
      "description": "Sulfonamides"
    },
    {
      "name": "T371",
      "description": "Antimycobacterial drugs"
    },
    {
      "name": "T372",
      "description": "Antimalarial and drugs acting on other blood protozoa"
    },
    {
      "name": "T373",
      "description": "Other antiprotozoal drugs"
    },
    {
      "name": "T374",
      "description": "Anthelminthics"
    },
    {
      "name": "T375",
      "description": "Antiviral drugs"
    },
    {
      "name": "T378",
      "description": "Other specified systemic anti-infectives and antiparasitics"
    },
    {
      "name": "T379",
      "description": "Systemic anti-infective and antiparasitic,unspecified"
    },
    {
      "name": "T380",
      "description": "Glucocorticoids and synthetic analogues"
    },
    {
      "name": "T381",
      "description": "Thyroid hormones and substitutes"
    },
    {
      "name": "T382",
      "description": "Antithyroid drugs"
    },
    {
      "name": "T383",
      "description": "Insulin and oral hypoglycaemic antidiabetic drugs"
    },
    {
      "name": "T384",
      "description": "Oral contraceptives"
    },
    {
      "name": "T385",
      "description": "Other estrogens and progestogens"
    },
    {
      "name": "T386",
      "description": "Antigonadotrophins, antiestrogens,antiandrogens, not elsewhere classified"
    },
    {
      "name": "T387",
      "description": "Androgens and anabolic congeners"
    },
    {
      "name": "T388",
      "description": "Other and unspecified hormones and their synthetic substitutes"
    },
    {
      "name": "T389",
      "description": "Other and unspecified hormone antagonists"
    },
    {
      "name": "T390",
      "description": "Salicylates"
    },
    {
      "name": "T391",
      "description": "4-Aminophenol derivatives"
    },
    {
      "name": "T392",
      "description": "Pyrazolone derivatives"
    },
    {
      "name": "T393",
      "description": "Other nonsteroidal anti-inflammatory drugs [NSAID]"
    },
    {
      "name": "T394",
      "description": "Antirheumatics, not elsewhere classified"
    },
    {
      "name": "T398",
      "description": "Other nonopioid analgesics and antipyretics,not elsewhere classified"
    },
    {
      "name": "T399",
      "description": "Nonopioid analgesic, antipyretic and antirheumatic, unspecified"
    },
    {
      "name": "T400",
      "description": "Opium"
    },
    {
      "name": "T401",
      "description": "Heroin"
    },
    {
      "name": "T402",
      "description": "Other opioids"
    },
    {
      "name": "T403",
      "description": "Methadone"
    },
    {
      "name": "T404",
      "description": "Other synthetic narcotics"
    },
    {
      "name": "T405",
      "description": "Cocaine"
    },
    {
      "name": "T406",
      "description": "Other and unspecified narcotics"
    },
    {
      "name": "T407",
      "description": "Cannabis (derivatives)"
    },
    {
      "name": "T408",
      "description": "Lysergide [LSD]"
    },
    {
      "name": "T409",
      "description": "Other and unspecified psychodysleptics [hallucinogens]"
    },
    {
      "name": "T410",
      "description": "Inhaled anaesthetics"
    },
    {
      "name": "T411",
      "description": "Intravenous anaesthetics"
    },
    {
      "name": "T412",
      "description": "Other and unspecified general anaesthetics"
    },
    {
      "name": "T413",
      "description": "Local anaesthetics"
    },
    {
      "name": "T414",
      "description": "Anaesthetic, unspecified"
    },
    {
      "name": "T415",
      "description": "Therapeutic gases"
    },
    {
      "name": "T420",
      "description": "Hydantoin derivatives"
    },
    {
      "name": "T421",
      "description": "Iminostilbenes"
    },
    {
      "name": "T422",
      "description": "Succinimides and oxazolidinediones"
    },
    {
      "name": "T423",
      "description": "Barbiturates"
    },
    {
      "name": "T424",
      "description": "Benzodiazepines"
    },
    {
      "name": "T425",
      "description": "Mixed antiepileptics, not elsewhere classified"
    },
    {
      "name": "T426",
      "description": "Other antiepileptic and sedative-hypnotic drugs"
    },
    {
      "name": "T427",
      "description": "Antiepileptic and sedative-hypnotic drugs,unspecified"
    },
    {
      "name": "T428",
      "description": "Antiparkinsonism drugs and other central muscle-tone depressants"
    },
    {
      "name": "T430",
      "description": "Tricyclic and tetracyclic antidepressants"
    },
    {
      "name": "T431",
      "description": "Monoamine-oxidase-inhibitor antidepressants"
    },
    {
      "name": "T432",
      "description": "Other and unspecified antidepressants"
    },
    {
      "name": "T433",
      "description": "Phenothiazine antipsychotics and neuroleptics"
    },
    {
      "name": "T434",
      "description": "Butyrophenone and thioxanthene neuroleptics"
    },
    {
      "name": "T435",
      "description": "Other and unspecified antipsychotics and neuroleptics"
    },
    {
      "name": "T436",
      "description": "Psychostimulants with abuse potential"
    },
    {
      "name": "T438",
      "description": "Other psychotropic drugs, not elsewhere classified"
    },
    {
      "name": "T439",
      "description": "Psychotropic drug, unspecified"
    },
    {
      "name": "T440",
      "description": "Anticholinesterase agents"
    },
    {
      "name": "T441",
      "description": "Other parasympathomimetics [cholinergics]"
    },
    {
      "name": "T442",
      "description": "Ganglionic blocking drugs, not elsewhere classified"
    },
    {
      "name": "T443",
      "description": "Other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics, not elsewhere classified"
    },
    {
      "name": "T444",
      "description": "Predominantly α–adrenoreceptor agonists,not elsewhere classified"
    },
    {
      "name": "T445",
      "description": "Predominantly β–adrenoreceptor agonists,not elsewhere classified"
    },
    {
      "name": "T446",
      "description": "α–Adrenoreceptor antagonists, not elsewhere classified"
    },
    {
      "name": "T447",
      "description": "β–Adrenoreceptor antagonists, not elsewhere classified"
    },
    {
      "name": "T448",
      "description": "Centrally acting and adrenergic-neuron-blocking agents, not elsewhere classified"
    },
    {
      "name": "T449",
      "description": "Other and unspecified drugs primarily affecting the autonomic nervous system"
    },
    {
      "name": "T450",
      "description": "Antiallergic and antiemetic drugs"
    },
    {
      "name": "T451",
      "description": "Antineoplastic and immunosuppressive drugs"
    },
    {
      "name": "T452",
      "description": "Vitamins, not elsewhere classified"
    },
    {
      "name": "T453",
      "description": "Enzymes, not elsewhere classified"
    },
    {
      "name": "T454",
      "description": "Iron and its compounds"
    },
    {
      "name": "T455",
      "description": "Anticoagulants"
    },
    {
      "name": "T456",
      "description": "Fibrinolysis-affecting drugs"
    },
    {
      "name": "T457",
      "description": "Anticoagulant antagonists, vitamin K and other coagulants"
    },
    {
      "name": "T458",
      "description": "Other primarily systemic and haematological agents"
    },
    {
      "name": "T459",
      "description": "Primarily systemic and haematological agent,unspecified"
    },
    {
      "name": "T460",
      "description": "Cardiac-stimulant glycosides and drugs of similar action"
    },
    {
      "name": "T461",
      "description": "Calcium-channel blockers"
    },
    {
      "name": "T462",
      "description": "Other antidysrhythmic drugs, not elsewhere classified"
    },
    {
      "name": "T463",
      "description": "Coronary vasodilators, not elsewhere classified"
    },
    {
      "name": "T464",
      "description": "Angiotensin-converting-enzyme inhibitors"
    },
    {
      "name": "T465",
      "description": "Other antihypertensive drugs, not elsewhere classified"
    },
    {
      "name": "T466",
      "description": "Antihyperlipidaemic and antiarteriosclerotic drugs"
    },
    {
      "name": "T467",
      "description": "Peripheral vasodilators"
    },
    {
      "name": "T468",
      "description": "Antivaricose drugs, including sclerosing agents"
    },
    {
      "name": "T469",
      "description": "Other and unspecified agents primarily affecting the cardiovascular system"
    },
    {
      "name": "T470",
      "description": "Histamine H2-receptor antagonists"
    },
    {
      "name": "T471",
      "description": "Other antacids and anti-gastric-secretion drugs"
    },
    {
      "name": "T472",
      "description": "Stimulant laxatives"
    },
    {
      "name": "T473",
      "description": "Saline and osmotic laxatives"
    },
    {
      "name": "T474",
      "description": "Other laxatives"
    },
    {
      "name": "T475",
      "description": "Digestants"
    },
    {
      "name": "T476",
      "description": "Antidiarrhoeal drugs"
    },
    {
      "name": "T477",
      "description": "Emetics"
    },
    {
      "name": "T478",
      "description": "Other agents primarily affecting the gastrointestinal system"
    },
    {
      "name": "T479",
      "description": "Agent primarily affecting the gastrointestinal system, unspecified"
    },
    {
      "name": "T480",
      "description": "Oxytocic drugs"
    },
    {
      "name": "T481",
      "description": "Skeletal muscle relaxants [neuromuscular blocking agents]"
    },
    {
      "name": "T482",
      "description": "Other and unspecified agents primarily acting on muscles"
    },
    {
      "name": "T483",
      "description": "Antitussives"
    },
    {
      "name": "T484",
      "description": "Expectorants"
    },
    {
      "name": "T485",
      "description": "Anti-common-cold drugs"
    },
    {
      "name": "T486",
      "description": "Antiasthmatics, not elsewhere classified"
    },
    {
      "name": "T487",
      "description": "Other and unspecified agents primarily acting on the respiratory system"
    },
    {
      "name": "T490",
      "description": "Local antifungal, anti-infective and anti-inflammatory drugs, not elsewhere classified"
    },
    {
      "name": "T491",
      "description": "Antipruritics"
    },
    {
      "name": "T492",
      "description": "Local astringents and local detergents"
    },
    {
      "name": "T493",
      "description": "Emollients, demulcents and protectants"
    },
    {
      "name": "T494",
      "description": "Keratolytics, keratoplastics and other hair treatment drugs and preparations"
    },
    {
      "name": "T495",
      "description": "Ophthalmological drugs and preparations "
    },
    {
      "name": "T496",
      "description": "Otorhinolaryngological drugs and preparations"
    },
    {
      "name": "T497",
      "description": "Dental drugs, topically applied"
    },
    {
      "name": "T498",
      "description": "Other topical agents"
    },
    {
      "name": "T499",
      "description": "Topical agent, unspecified"
    },
    {
      "name": "T500",
      "description": "Mineralocorticoids and their antagonists"
    },
    {
      "name": "T501",
      "description": "Loop [high-ceiling] diuretics"
    },
    {
      "name": "T502",
      "description": "Carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics"
    },
    {
      "name": "T503",
      "description": "Electrolytic, caloric and water-balance agents"
    },
    {
      "name": "T504",
      "description": "Drugs affecting uric acid metabolism"
    },
    {
      "name": "T505",
      "description": "Appetite depressants"
    },
    {
      "name": "T506",
      "description": "Antidotes and chelating agents, not elsewhere classified"
    },
    {
      "name": "T507",
      "description": "Analeptics and opioid receptor antagonists"
    },
    {
      "name": "T508",
      "description": "Diagnostic agents"
    },
    {
      "name": "T509",
      "description": "Other and unspecified drugs, medicaments and biological substances"
    },
    {
      "name": "T510",
      "description": "Ethanol"
    },
    {
      "name": "T511",
      "description": "Methanol"
    },
    {
      "name": "T512",
      "description": "2-Propanol"
    },
    {
      "name": "T513",
      "description": "Fusel oil"
    },
    {
      "name": "T518",
      "description": "Other alcohols"
    },
    {
      "name": "T519",
      "description": "Alcohol, unspecifie"
    },
    {
      "name": "T520",
      "description": "Petroleum products"
    },
    {
      "name": "T521",
      "description": "Benzene"
    },
    {
      "name": "T522",
      "description": "Homologues of benzene"
    },
    {
      "name": "T523",
      "description": "Glycols"
    },
    {
      "name": "T524",
      "description": "Ketones"
    },
    {
      "name": "T528",
      "description": "Other organic solvents"
    },
    {
      "name": "T529",
      "description": "Organic solvent, unspecified"
    },
    {
      "name": "T530",
      "description": "Carbon tetrachloride"
    },
    {
      "name": "T531",
      "description": "Chloroform"
    },
    {
      "name": "T532",
      "description": "Trichloroethylene"
    },
    {
      "name": "T533",
      "description": "Tetrachloroethylene"
    },
    {
      "name": "T534",
      "description": "Dichloromethane"
    },
    {
      "name": "T535",
      "description": "Chlorofluorocarbons"
    },
    {
      "name": "T536",
      "description": "Other halogen derivatives of aliphatic hydrocarbons"
    },
    {
      "name": "T537",
      "description": "Other halogen derivatives of aromatic hydrocarbons"
    },
    {
      "name": "T538",
      "description": "Halogen derivative of aliphatic and aromatic hydrocarbons, unspecified"
    },
    {
      "name": "T540",
      "description": "Phenol and phenol homologues"
    },
    {
      "name": "T541",
      "description": "Other corrosive organic compounds"
    },
    {
      "name": "T542",
      "description": "Corrosive acids and acid-like substances"
    },
    {
      "name": "T543",
      "description": "Corrosive alkalis and alkali-like substances"
    },
    {
      "name": "T549",
      "description": "Corrosive substance, unspecified"
    },
    {
      "name": "T55",
      "description": "Toxic effect of soaps and detergents"
    },
    {
      "name": "T560",
      "description": "Lead and its compounds"
    },
    {
      "name": "T561",
      "description": "Mercury and its compounds"
    },
    {
      "name": "T562",
      "description": "Chromium and its compounds"
    },
    {
      "name": "T563",
      "description": "Cadmium and its compounds"
    },
    {
      "name": "T564",
      "description": "Copper and its compounds"
    },
    {
      "name": "T565",
      "description": "Zinc and its compounds"
    },
    {
      "name": "T566",
      "description": "Tin and its compounds"
    },
    {
      "name": "T567",
      "description": "Beryllium and its compounds"
    },
    {
      "name": "T568",
      "description": "Other metals"
    },
    {
      "name": "T569",
      "description": "Metal, unspecified"
    },
    {
      "name": "T570",
      "description": "Arsenic and its compounds"
    },
    {
      "name": "T571",
      "description": "Phosphorus and its compounds"
    },
    {
      "name": "T572",
      "description": "Manganese and its compounds"
    },
    {
      "name": "T573",
      "description": "Hydrogen cyanide"
    },
    {
      "name": "T578",
      "description": "Other specified inorganic substances"
    },
    {
      "name": "T579",
      "description": "Inorganic substance, unspecified"
    },
    {
      "name": "T58",
      "description": "Toxic effect of carbon monoxide"
    },
    {
      "name": "T590",
      "description": "Nitrogen oxides"
    },
    {
      "name": "T591",
      "description": "Sulfur dioxide"
    },
    {
      "name": "T592",
      "description": "Formaldehyde"
    },
    {
      "name": "T593",
      "description": "Lacrimogenic gas"
    },
    {
      "name": "T594",
      "description": "Chlorine gas"
    },
    {
      "name": "T595",
      "description": "Fluorine gas and hydrogen fluoride"
    },
    {
      "name": "T596",
      "description": "Hydrogen sulfide"
    },
    {
      "name": "T597",
      "description": "Carbon dioxide"
    },
    {
      "name": "T598",
      "description": "Other specified gases, fumes and vapours"
    },
    {
      "name": "T599",
      "description": "Gases, fumes and vapours, unspecified"
    },
    {
      "name": "T600",
      "description": "Organophosphate and carbamate insecticides"
    },
    {
      "name": "T601",
      "description": "Halogenated insecticides"
    },
    {
      "name": "T602",
      "description": "Other insecticides"
    },
    {
      "name": "T603",
      "description": "Herbicides and fungicides"
    },
    {
      "name": "T604",
      "description": "Rodenticides"
    },
    {
      "name": "T608",
      "description": "Other pesticides"
    },
    {
      "name": "T609",
      "description": "Pesticide, unspecified"
    },
    {
      "name": "T610",
      "description": "Ciguatera fish poisoning"
    },
    {
      "name": "T611",
      "description": "Scombroid fish poisoning"
    },
    {
      "name": "T612",
      "description": "Other fish and shellfish poisoning"
    },
    {
      "name": "T618",
      "description": "Toxic effect of other seafoods"
    },
    {
      "name": "T619",
      "description": "Toxic effect of unspecified seafood"
    },
    {
      "name": "T620",
      "description": "Ingested mushrooms"
    },
    {
      "name": "T621",
      "description": "Ingested berries"
    },
    {
      "name": "T622",
      "description": "Other ingested (parts of) plant(s)"
    },
    {
      "name": "T628",
      "description": "Other specified noxious substances eaten as food"
    },
    {
      "name": "T629",
      "description": "Noxious substance eaten as food, unspecified"
    },
    {
      "name": "T6300",
      "description": "Haematotoxin"
    },
    {
      "name": "T6301",
      "description": "Neurotoxin"
    },
    {
      "name": "T6303",
      "description": "Mixed muscular and neurotoxin"
    },
    {
      "name": "T6308",
      "description": "Other specified snake venom"
    },
    {
      "name": "T6309",
      "description": "Snake venom, unspecified"
    },
    {
      "name": "T631",
      "description": "Venom of other reptiles"
    },
    {
      "name": "T632",
      "description": "Venom of scorpion"
    },
    {
      "name": "T633",
      "description": "Venom of spider"
    },
    {
      "name": "T634",
      "description": "Venom of other arthropods"
    },
    {
      "name": "T635",
      "description": "Toxic effect of contact with fish"
    },
    {
      "name": "T636",
      "description": "Toxic effect of contact with other marine animals"
    },
    {
      "name": "T638",
      "description": "Toxic effect of contact with other venomous animals"
    },
    {
      "name": "T639",
      "description": "Toxic effect of contact with unspecified venomous animal"
    },
    {
      "name": "T64",
      "description": "Toxic effect of aflatoxin and other mycotoxin food contaminants"
    },
    {
      "name": "T650",
      "description": "Cyanides"
    },
    {
      "name": "T651",
      "description": "Strychnine and its salts"
    },
    {
      "name": "T652",
      "description": "Tobacco and nicotine"
    },
    {
      "name": "T653",
      "description": "Nitroderivatives and aminoderivatives of benzene and its homologues"
    },
    {
      "name": "T654",
      "description": "Carbon disulfide"
    },
    {
      "name": "T655",
      "description": "Nitroglycerin and other nitric acids and esters"
    },
    {
      "name": "T656",
      "description": "Paints and dyes, not elsewhere classified"
    },
    {
      "name": "T658",
      "description": "Toxic effect of other specified substances"
    },
    {
      "name": "T659",
      "description": "Toxic effect of unspecified substances"
    },
    {
      "name": "T66",
      "description": "Unspecified effects of radiation"
    },
    {
      "name": "T670",
      "description": "Heatstroke and sunstroke"
    },
    {
      "name": "T671",
      "description": "Heat syncope"
    },
    {
      "name": "T672",
      "description": "Heat cramp"
    },
    {
      "name": "T673",
      "description": "Heat exhaustion, anhydrotic"
    },
    {
      "name": "T674",
      "description": "Heat exhaustion due to salt depletion"
    },
    {
      "name": "T675",
      "description": "Heat exhaustion, unspecified"
    },
    {
      "name": "T676",
      "description": "Heat fatigue, transient"
    },
    {
      "name": "T677",
      "description": "Heat oedema"
    },
    {
      "name": "T678",
      "description": "Other effects of heat and light"
    },
    {
      "name": "T679",
      "description": "Effect of heat and light, unspecified"
    },
    {
      "name": "T68",
      "description": "Hypothermia"
    },
    {
      "name": "T690",
      "description": "Immersion hand and foot"
    },
    {
      "name": "T691",
      "description": "Chilblains"
    },
    {
      "name": "T698",
      "description": "Other specified effects of reduced temperature"
    },
    {
      "name": "T699",
      "description": "Effect of reduced temperature, unspecified"
    },
    {
      "name": "T700",
      "description": "Otitic barotrauma"
    },
    {
      "name": "T701",
      "description": "Sinus barotrauma"
    },
    {
      "name": "T702",
      "description": "Other and unspecified effects of high altitude"
    },
    {
      "name": "T703",
      "description": "Caisson disease [decompression sickness]"
    },
    {
      "name": "T704",
      "description": "Effects of high-pressure fluids"
    },
    {
      "name": "T708",
      "description": "Other effects of air pressure and water pressure"
    },
    {
      "name": "T709",
      "description": "Effect of air pressure and water pressure,unspecified"
    },
    {
      "name": "T71",
      "description": "Asphyxiation"
    },
    {
      "name": "T730",
      "description": "Effects of hunger"
    },
    {
      "name": "T731",
      "description": "Effects of thirst"
    },
    {
      "name": "T732",
      "description": "Exhaustion due to exposure"
    },
    {
      "name": "T733",
      "description": "Exhaustion due to excessive exertion"
    },
    {
      "name": "T738",
      "description": "Other effects of deprivation"
    },
    {
      "name": "T739",
      "description": "Effect of deprivation, unspecified"
    },
    {
      "name": "T740",
      "description": "Neglect or abandonment"
    },
    {
      "name": "T741",
      "description": "Physical abuse"
    },
    {
      "name": "T742",
      "description": "Sexual abuse"
    },
    {
      "name": "T743",
      "description": "Psychological abuse"
    },
    {
      "name": "T748",
      "description": "Other maltreatnent syndromes"
    },
    {
      "name": "T749",
      "description": "Maltreatment syndrome, unspecified"
    },
    {
      "name": "T750",
      "description": "Effects of lightning"
    },
    {
      "name": "T751",
      "description": "Drowning and nonfatal submersion"
    },
    {
      "name": "T752",
      "description": "Effects of vibration"
    },
    {
      "name": "T753",
      "description": "Motion sickness"
    },
    {
      "name": "T754",
      "description": "Effects of electric current"
    },
    {
      "name": "T758",
      "description": "Other specified effects of external causes"
    },
    {
      "name": "T780",
      "description": "Anaphylactic shock due to adverse food reaction"
    },
    {
      "name": "T781",
      "description": "Other adverse food reactions, not elsewhere classified"
    },
    {
      "name": "T782",
      "description": "Anaphylactic shock, unspecified"
    },
    {
      "name": "T783",
      "description": "Angioneurotic oedema"
    },
    {
      "name": "T784",
      "description": "Allergy, unspecified"
    },
    {
      "name": "T788",
      "description": "Other adverse effects, not elsewhere classified"
    },
    {
      "name": "T789",
      "description": "Adverse effect, unspecified"
    },
    {
      "name": "T790",
      "description": "Air embolism (traumatic)"
    },
    {
      "name": "T791",
      "description": "Fat embolism (traumatic)"
    },
    {
      "name": "T792",
      "description": "Traumatic secondary and recurrent haemorrhage"
    },
    {
      "name": "T793",
      "description": "Post-traumatic wound infection, not elsewhere classified"
    },
    {
      "name": "T794",
      "description": "Traumatic shock"
    },
    {
      "name": "T795",
      "description": "Traumatic anuria"
    },
    {
      "name": "T796",
      "description": "Traumatic ischaemia of muscle"
    },
    {
      "name": "T797",
      "description": "Traumatic subcutaneous emphysema"
    },
    {
      "name": "T798",
      "description": "Other early complications of trauma"
    },
    {
      "name": "T799",
      "description": "Unspecified early complication of trauma"
    },
    {
      "name": "T800",
      "description": "Air embolism following infusion, transfusion and therapeutic injection"
    },
    {
      "name": "T801",
      "description": "Vascular complications following infusion,transfusion and therapeutic injection"
    },
    {
      "name": "T802",
      "description": "Infections following infusion, transfusion and therapeutic injection"
    },
    {
      "name": "T803",
      "description": "ABO incompatibility reaction"
    },
    {
      "name": "T804",
      "description": "Rh incompatibility reaction"
    },
    {
      "name": "T805",
      "description": "Anaphylactic shock due to serum"
    },
    {
      "name": "T806",
      "description": "Other serum reactions"
    },
    {
      "name": "T808",
      "description": "Other complications following infusion,transfusion and therapeutic injection"
    },
    {
      "name": "T809",
      "description": "Unspecified complication following infusion,transfusion and therapeutic injection"
    },
    {
      "name": "T810",
      "description": "Haemorrhage and haematoma complicating a procedure, not elsewhere classified"
    },
    {
      "name": "T811",
      "description": "Shock during or resulting from a procedure,not elsewhere classified"
    },
    {
      "name": "T812",
      "description": "Accidental puncture and laceration during a procedure, not elsewhere classified"
    },
    {
      "name": "T813",
      "description": "Disruption of operation wound, not elsewhere classified"
    },
    {
      "name": "T814",
      "description": "Infection following a procedure, not elsewhere classified"
    },
    {
      "name": "T815",
      "description": "Foreign body accidentally left in body cavity or operation wound following a procedure"
    },
    {
      "name": "T816",
      "description": "Acute reaction to foreign substance accidentally left during a procedure"
    },
    {
      "name": "T817",
      "description": "Vascular complications following a procedure,not elsewhere classified"
    },
    {
      "name": "T818",
      "description": "Other complications of procedures, not elsewhere classified"
    },
    {
      "name": "T819",
      "description": "Unspecified complication of procedure"
    },
    {
      "name": "T820",
      "description": "Mechanical complication of heart valve prosthesis"
    },
    {
      "name": "T821",
      "description": "Mechanical complication of cardiac electronic device"
    },
    {
      "name": "T822",
      "description": "Mechanical complication of coronary artery bypass and valve grafts"
    },
    {
      "name": "T823",
      "description": "Mechanical complication of other vascular grafts"
    },
    {
      "name": "T824",
      "description": "Mechanical complication of vascular dialysis catheter"
    },
    {
      "name": "T825",
      "description": "Mechanical complication of other cardiac and vascular devices and implants"
    },
    {
      "name": "T826",
      "description": "Infection and inflammatory reaction due to cardiac valve prosthesis"
    },
    {
      "name": "T827",
      "description": "Infection and inflammatory reaction due to other cardiac and vascular devices, implants and grafts"
    },
    {
      "name": "T828",
      "description": "Other specified complications of cardiac and vascular prosthetic devices, implants and grafts"
    },
    {
      "name": "T829",
      "description": "Unspecified complication of cardiac and vascular prosthetic device, implant and graft"
    },
    {
      "name": "T830",
      "description": "Mechanical complication of urinary indwelling catheter"
    },
    {
      "name": "T831",
      "description": "Mechanical complication of other urinary devices and implants"
    },
    {
      "name": "T832",
      "description": "Mechanical complication of graft of urinary organ"
    },
    {
      "name": "T833",
      "description": "Mechanical complication of intrauterine contraceptive device"
    },
    {
      "name": "T834",
      "description": "Mechanical complication of other prosthetic devices, implants and grafts in genital tract"
    },
    {
      "name": "T835",
      "description": "Infection and inflammatory reaction due to prosthetic device, implant and graft in urinary system"
    },
    {
      "name": "T836",
      "description": "Infection and inflammatory reaction due to prosthetic device, implant and graft in genital tract"
    },
    {
      "name": "T838",
      "description": "Other complications of genitourinary prosthetic devices, implants and grafts"
    },
    {
      "name": "T839",
      "description": "Unspecified complication of genitourinary prosthetic device, implant and graft"
    },
    {
      "name": "T840",
      "description": "Mechanical complication of internal joint prosthesis"
    },
    {
      "name": "T841",
      "description": "Mechanical complication of internal fixation device of bones of limb"
    },
    {
      "name": "T842",
      "description": "Mechanical complication of internal fixation device of other bones"
    },
    {
      "name": "T843",
      "description": "Mechanical complication of other bone devices,implants and grafts"
    },
    {
      "name": "T844",
      "description": "Mechanical complication of other internal orthopaedic devices, implants and grafts"
    },
    {
      "name": "T845",
      "description": "Infection and inflammatory reaction due to internal joint prosthesis"
    },
    {
      "name": "T846",
      "description": "Infection and inflammatory reaction due to internal fixation device [any site]"
    },
    {
      "name": "T847",
      "description": "Infection and inflammatory reaction due to other internal orthopaedic prosthetic devices, implants and grafts"
    },
    {
      "name": "T848",
      "description": "Other complications of internal orthopaedic prosthetic devices, implants and grafts"
    },
    {
      "name": "T849",
      "description": "Unspecified complication of internal orthopaedic prosthetic device, implant and graft"
    },
    {
      "name": "T850",
      "description": "Mechanical complication of ventricular intracranial (communicating) shunt"
    },
    {
      "name": "T851",
      "description": "Mechanical complication of implanted electronic stimulator of nervous system"
    },
    {
      "name": "T852",
      "description": "Mechanical complication of intraocular lens"
    },
    {
      "name": "T853",
      "description": "Mechanical complication of other ocular prosthetic devices, implants and grafts"
    },
    {
      "name": "T854",
      "description": "Mechanical complication of breast prosthesis and implant"
    },
    {
      "name": "T855",
      "description": "Mechanical complication of gastrointestinal prosthetic devices, implants and grafts"
    },
    {
      "name": "T856",
      "description": "Mechanical complication of other specified internal prosthetic devices, implants and grafts"
    },
    {
      "name": "T857",
      "description": "Infection and inflammatory reaction due to other internal prosthetic devices, implants and grafts"
    },
    {
      "name": "T858",
      "description": "Other complications of internal prosthetic devices, implants and grafts, not elsewhere classified"
    },
    {
      "name": "T859",
      "description": "Unspecified complication of internal prosthetic device, implant and graft"
    },
    {
      "name": "T860",
      "description": "Bone-marrow transplant rejection"
    },
    {
      "name": "T861",
      "description": "Kidney transplant failure and rejection"
    },
    {
      "name": "T862",
      "description": "Heart transplant failure and rejection"
    },
    {
      "name": "T863",
      "description": "Heart-lung transplant failure and rejection"
    },
    {
      "name": "T864",
      "description": "Liver transplant failure and rejection"
    },
    {
      "name": "T868",
      "description": "Failure and rejection of other transplanted organs and tissues"
    },
    {
      "name": "T869",
      "description": "Failure and rejection of unspecified transplanted organ and tissue"
    },
    {
      "name": "T870",
      "description": "Complications of reattached (part of) upper extremity"
    },
    {
      "name": "T871",
      "description": "Complications of reattached (part of) lower extremity"
    },
    {
      "name": "T872",
      "description": "Complications of other reattached body part"
    },
    {
      "name": "T873",
      "description": "Neuroma of amputation stump"
    },
    {
      "name": "T874",
      "description": "Infection of amputation stump"
    },
    {
      "name": "T875",
      "description": "Necrosis of amputation stump"
    },
    {
      "name": "T876",
      "description": "Other and unspecified complications of amputation stump"
    },
    {
      "name": "T880",
      "description": "Infection following immunization"
    },
    {
      "name": "T881",
      "description": "Other complications following immunization,not elsewhere classified"
    },
    {
      "name": "T882",
      "description": "Shock due to anaesthesia"
    },
    {
      "name": "T883",
      "description": "Malignant hyperpyrexia due to anaesthesia"
    },
    {
      "name": "T884",
      "description": "Failed or difficult intubation"
    },
    {
      "name": "T885",
      "description": "Other complications of anaesthesia"
    },
    {
      "name": "T886",
      "description": "Anaphylactic shock due to adverse effect of correct drug or medicament properly administered"
    },
    {
      "name": "T887",
      "description": "Unspecified adverse effect of drug or medicament"
    },
    {
      "name": "T888",
      "description": "Other specified complications of surgical and medical care, not elsewhere classified"
    },
    {
      "name": "T889",
      "description": "Complication of surgical and medical care,unspecified"
    },
    {
      "name": "T900",
      "description": "Sequelae of superficial injury of head"
    },
    {
      "name": "T901",
      "description": "Sequelae of open wound of head"
    },
    {
      "name": "T902",
      "description": "Sequelae of fracture of skull and facial bones"
    },
    {
      "name": "T903",
      "description": "Sequelae of injury of cranial nerves"
    },
    {
      "name": "T904",
      "description": "Sequelae of injury of eye and orbit"
    },
    {
      "name": "T905",
      "description": "Sequelae of intracranial injury"
    },
    {
      "name": "T908",
      "description": "Sequelae of other specified injuries of head"
    },
    {
      "name": "T909",
      "description": "Sequelae of unspecified injury of head"
    },
    {
      "name": "T910",
      "description": "Sequelae of superficial injury and open wound of neck and trunk"
    },
    {
      "name": "T911",
      "description": "Sequelae of fracture of spine"
    },
    {
      "name": "T912",
      "description": "Sequelae of other fracture of thorax and pelvis"
    },
    {
      "name": "T913",
      "description": "Sequelae of injury of spinal cord"
    },
    {
      "name": "T914",
      "description": "Sequelae of injury of intrathoracic organs"
    },
    {
      "name": "T915",
      "description": "Sequelae of injury of intra-abdominal and pelvic organs"
    },
    {
      "name": "T918",
      "description": "Sequelae of other specified injuries of neck and trunk"
    },
    {
      "name": "T919",
      "description": "Sequelae of unspecified injury of neck and trunk"
    },
    {
      "name": "T920",
      "description": "Sequelae of open wound of upper limb"
    },
    {
      "name": "T921",
      "description": "Sequelae of fracture of arm"
    },
    {
      "name": "T922",
      "description": "Sequelae of fracture at wrist and hand level"
    },
    {
      "name": "T923",
      "description": "Sequelae of dislocation, sprain and strain of upper limb"
    },
    {
      "name": "T924",
      "description": "Sequelae of injury of nerve of upper limb"
    },
    {
      "name": "T925",
      "description": "Sequelae of injury of muscle and tendon of upper limb"
    },
    {
      "name": "T926",
      "description": "Sequelae of crushing injury and traumatic amputation of upper limb"
    },
    {
      "name": "T928",
      "description": "Sequelae of other specified injuries of upper limb"
    },
    {
      "name": "T929",
      "description": "Sequelae of unspecified injury of upper limb"
    },
    {
      "name": "T930",
      "description": "Sequelae of open wound of lower limb"
    },
    {
      "name": "T931",
      "description": "Sequelae of fracture of femur"
    },
    {
      "name": "T932",
      "description": "Sequelae of other fractures of lower limb"
    },
    {
      "name": "T933",
      "description": "Sequelae of dislocation, sprain and strain of lower limb"
    },
    {
      "name": "T934",
      "description": "Sequelae of injury of nerve of lower limb"
    },
    {
      "name": "T935",
      "description": "Sequelae of injury of muscle and tendon of lower limb"
    },
    {
      "name": "T936",
      "description": "Sequelae of crushing injury and traumatic amputation of lower limb"
    },
    {
      "name": "T938",
      "description": "Sequelae of other specified injuries of lower limb"
    },
    {
      "name": "T939",
      "description": "Sequelae of unspecified injury of lower limb"
    },
    {
      "name": "T940",
      "description": "Sequelae of injuries involving multiple body regions"
    },
    {
      "name": "T941",
      "description": "Sequelae of injuries, not specified by body region"
    },
    {
      "name": "T950",
      "description": "Sequelae of burn, corrosion and frostbite of head and neck"
    },
    {
      "name": "T951",
      "description": "Sequelae of burn, corrosion and frostbite of trunk"
    },
    {
      "name": "T952",
      "description": "Sequelae of burn, corrosion and frostbite of upper limb"
    },
    {
      "name": "T953",
      "description": "Sequelae of burn, corrosion and frostbite of lower limb"
    },
    {
      "name": "T954",
      "description": "Sequelae of burn and corrosion classifiable only according to extent of body surface involved"
    },
    {
      "name": "T958",
      "description": "Sequelae of other specified burn, corrosion and frostbite"
    },
    {
      "name": "T959",
      "description": "Sequelae of unspecified burn, corrosion and frostbite"
    },
    {
      "name": "T96",
      "description": "Sequelae of poisoning by drugs, medicaments and biological substances"
    },
    {
      "name": "T97",
      "description": "Sequelae of toxic effects of substances chiefly nonmedicinal as to source"
    },
    {
      "name": "T980",
      "description": "Sequelae of effects of foreign body entering through natural orifice"
    },
    {
      "name": "T981",
      "description": "Sequelae of other and unspecified effects of external causes"
    },
    {
      "name": "T982",
      "description": "Sequelae of certain early complications of trauma"
    },
    {
      "name": "T983",
      "description": "Sequelae of complications of surgical and medical care, not elsewhere classified"
    },
    {
      "name": "U049",
      "description": "Severe acute respiratory syndrome [SARS], unspecified"
    },
    {
      "name": "U060",
      "description": "Emergency use of U06.0"
    },
    {
      "name": "U061",
      "description": "Emergency use of U06.1"
    },
    {
      "name": "U062",
      "description": "Emergency use of U06.2"
    },
    {
      "name": "U063",
      "description": "Emergency use of U06.3"
    },
    {
      "name": "U064",
      "description": "Emergency use of U06.4"
    },
    {
      "name": "U065",
      "description": "Emergency use of U06.5"
    },
    {
      "name": "U066",
      "description": "Emergency use of U06.6"
    },
    {
      "name": "U067",
      "description": "Emergency use of U06.7"
    },
    {
      "name": "U068",
      "description": "Emergency use of U06.8"
    },
    {
      "name": "U069",
      "description": "Zika virus disease, unspecified "
    },
    {
      "name": "U070",
      "description": "Emergency use of U07.0"
    },
    {
      "name": "U071",
      "description": "Emergency use of U07.1"
    },
    {
      "name": "U072",
      "description": "Emergency use of U07.2"
    },
    {
      "name": "U073",
      "description": "Emergency use of U07.3"
    },
    {
      "name": "U074",
      "description": "Emergency use of U07.4"
    },
    {
      "name": "U075",
      "description": "Emergency use of U07.5"
    },
    {
      "name": "U076",
      "description": "Emergency use of U07.6"
    },
    {
      "name": "U077",
      "description": "Emergency use of U07.7"
    },
    {
      "name": "U078",
      "description": "Emergency use of U07.8"
    },
    {
      "name": "U079",
      "description": "Emergency use of U07.9"
    },
    {
      "name": "U820",
      "description": "Resistance to penicillin"
    },
    {
      "name": "U821",
      "description": "Resistance to methicillin"
    },
    {
      "name": "U822",
      "description": "Extended spectrum betalactamase (ESBL)  resistance "
    },
    {
      "name": "U828",
      "description": "Resistance to other betalactam antibiotics"
    },
    {
      "name": "U829",
      "description": "Resistance to betalactam antibiotics, unspecified"
    },
    {
      "name": "U830",
      "description": "Resistance to vancomycin "
    },
    {
      "name": "U831",
      "description": "Resistance to other vancomycin related antibiotics"
    },
    {
      "name": "U832",
      "description": "Resistance to quinolones"
    },
    {
      "name": "U837",
      "description": "Resistance to multiple antibiotics "
    },
    {
      "name": "U838",
      "description": "Resistance to other single specified antibiotic"
    },
    {
      "name": "U839",
      "description": "Resistance to unspecified antibiotic "
    },
    {
      "name": "U840",
      "description": "Resistance to antiparasitic drug(s)"
    },
    {
      "name": "U841",
      "description": "Resistance to antifungal drug(s) "
    },
    {
      "name": "U842",
      "description": "Resistance to antiviral drug(s)"
    },
    {
      "name": "U843",
      "description": "Resistance to tuberculostatic drug(s) "
    },
    {
      "name": "U847",
      "description": "Resistance to multiple antimicrobial drugs"
    },
    {
      "name": "U848",
      "description": "Resistance to other specified antimicrobial drug   "
    },
    {
      "name": "U849",
      "description": "Resistance to unspecified antimicrobial drug"
    },
    {
      "name": "U85",
      "description": "Resistance to antineoplastic drugs"
    },
    {
      "name": "V010",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident"
    },
    {
      "name": "V0100",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0101",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0102",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While working for income"
    },
    {
      "name": "V0103",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0104",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0108",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0109",
      "description": "Pedestrian injured in collision with pedal cycle at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V011",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident"
    },
    {
      "name": "V0110",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0111",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0112",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While working for income"
    },
    {
      "name": "V0113",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0114",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0118",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0119",
      "description": "Pedestrian injured in collision with pedal cycle at Traffic accident During unspecified activity"
    },
    {
      "name": "V019",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0190",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0191",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0192",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While working for income"
    },
    {
      "name": "V0193",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0194",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0198",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0199",
      "description": "Pedestrian injured in collision with pedal cycle at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V020",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident"
    },
    {
      "name": "V0200",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While engaged in sports activity "
    },
    {
      "name": "V0201",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0202",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While working for income"
    },
    {
      "name": "V0203",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0204",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0208",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0209",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V021",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident"
    },
    {
      "name": "V0210",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0211",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0212",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While working for income"
    },
    {
      "name": "V0213",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0214",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0218",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0219",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Traffic accident During unspecified activity"
    },
    {
      "name": "V029",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0290",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0291",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0292",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While working for income"
    },
    {
      "name": "V0293",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0294",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0298",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0299",
      "description": "Pedestrian injured in collision with two-or three-wheeled motor vehicle at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V030",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident"
    },
    {
      "name": "V0300",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0301",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0302",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While working for income"
    },
    {
      "name": "V0303",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0304",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0308",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0309",
      "description": "Pedestrian injured in collision with car, pick-up trunk or van at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V031",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident"
    },
    {
      "name": "V0310",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0311",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0312",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While working for income"
    },
    {
      "name": "V0313",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0314",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0318",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0319",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Traffic accident During unspecified activity"
    },
    {
      "name": "V039",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0390",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0391",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0392",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While working for income"
    },
    {
      "name": "V0393",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0394",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0398",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0399",
      "description": "Pedestrian injured in collision with car, pick-up truck or van at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V040",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident"
    },
    {
      "name": "V0400",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0401",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0402",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While working for income"
    },
    {
      "name": "V0403",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0404",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0408",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0409",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V041",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident"
    },
    {
      "name": "V0410",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0411",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0412",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While working for income"
    },
    {
      "name": "V0413",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0414",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0418",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0419",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Traffic accident During unspecified activity"
    },
    {
      "name": "V049",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0490",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0491",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0492",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While working for income"
    },
    {
      "name": "V0493",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0494",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0498",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0499",
      "description": "Pedestrian injured in collision with heavy transport vehicle or bus at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V050",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident"
    },
    {
      "name": "V0500",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0501",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0502",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While working for income"
    },
    {
      "name": "V0503",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0504",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0508",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0509",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V051",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident"
    },
    {
      "name": "V0510",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0511",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0512",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While working for income"
    },
    {
      "name": "V0513",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0514",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0518",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0519",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Traffic accident During unspecified activity"
    },
    {
      "name": "V059",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0590",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0591",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0592",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While working for income While working for income"
    },
    {
      "name": "V0593",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0594",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other viral activities"
    },
    {
      "name": "V0598",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0599",
      "description": "Pedestrian injured in collision with railway train or railway vehicle at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V060",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident"
    },
    {
      "name": "V0600",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0601",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0602",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While working for income"
    },
    {
      "name": "V0603",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0604",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0608",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0609",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Nontraffic accident During unspecified activity"
    },
    {
      "name": "V061",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident"
    },
    {
      "name": "V0610",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While engaged in sports activity"
    },
    {
      "name": "V0611",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0612",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While working for income"
    },
    {
      "name": "V0613",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While engaged in other types of work"
    },
    {
      "name": "V0614",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0618",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0619",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Traffic accident During unspecified activity"
    },
    {
      "name": "V069",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident"
    },
    {
      "name": "V0690",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0691",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0692",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While working for income"
    },
    {
      "name": "V0693",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0694",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0698",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0699",
      "description": "Pedestrian injured in collision with other nonmotor vehicle at Unspecified whether traffic or nontraffic accident During unspecified activity"
    },
    {
      "name": "V090",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles"
    },
    {
      "name": "V0900",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While engaged in sports activity"
    },
    {
      "name": "V0901",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While engaged in leisure activity"
    },
    {
      "name": "V0902",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While working for income"
    },
    {
      "name": "V0903",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While engaged in other types of work"
    },
    {
      "name": "V0904",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0908",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles While engaged in other specified activities"
    },
    {
      "name": "V0909",
      "description": "Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles During unspecified activity"
    },
    {
      "name": "V091",
      "description": "Pedestrian injured in unspecified nontraffic accident"
    },
    {
      "name": "V0910",
      "description": "Pedestrian injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V0911",
      "description": "Pedestrian injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V0912",
      "description": "Pedestrian injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V0913",
      "description": "Pedestrian injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V0914",
      "description": "Pedestrian injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0918",
      "description": "Pedestrian injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V0919",
      "description": "Pedestrian injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V092",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles"
    },
    {
      "name": "V0920",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While engaged in sports activity"
    },
    {
      "name": "V0921",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While engaged in leisure activity"
    },
    {
      "name": "V0922",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While working for income"
    },
    {
      "name": "V0923",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While engaged in other types of work"
    },
    {
      "name": "V0924",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0928",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles While engaged in other specified activities"
    },
    {
      "name": "V0929",
      "description": "Pedestrian injured in traffic accident involving other and unspecified motor vehicles During unspecified activity"
    },
    {
      "name": "V093",
      "description": "Pedestrian injured in unspecified traffic accident"
    },
    {
      "name": "V0930",
      "description": "Pedestrian injured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V0931",
      "description": "Pedestrian injured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V0932",
      "description": "Pedestrian injured in unspecified traffic accident While working for income"
    },
    {
      "name": "V0933",
      "description": "Pedestrian injured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V0934",
      "description": "Pedestrian injured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0938",
      "description": "Pedestrian injured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V0939",
      "description": "Pedestrian injured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V099",
      "description": "Pedestrian injured in unspecified transport accident"
    },
    {
      "name": "V0990",
      "description": "Pedestrian injured in unspecified transport accident While engaged in sports activity"
    },
    {
      "name": "V0991",
      "description": "Pedestrian injured in unspecified transport accident While engaged in leisure activity"
    },
    {
      "name": "V0992",
      "description": "Pedestrian injured in unspecified transport accident While working for income"
    },
    {
      "name": "V0993",
      "description": "Pedestrian injured in unspecified transport accident While engaged in other types of work"
    },
    {
      "name": "V0994",
      "description": "Pedestrian injured in unspecified transport accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V0998",
      "description": "Pedestrian injured in unspecified transport accident While engaged in other specified activities"
    },
    {
      "name": "V0999",
      "description": "Pedestrian injured in unspecified transport accident During unspecified activity"
    },
    {
      "name": "V100",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V1000",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1001",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1002",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1003",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1004",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1008",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1009",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V101",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1010",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1011",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1012",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1013",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1014",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1018",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1019",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity During unspecified activity"
    },
    {
      "name": "V102",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1020",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1021",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1022",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1023",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1024",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1028",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1029",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V103",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting"
    },
    {
      "name": "V1030",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1031",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1032",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1033",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1034",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1038",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1039",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V104",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V1040",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1041",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1042",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1043",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1044",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1048",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1049",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V105",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident"
    },
    {
      "name": "V1050",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1051",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1052",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1053",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1054",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1058",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1059",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V109",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1090",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1091",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1092",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1093",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1094",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1098",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1099",
      "description": "Pedal cyclist injured in collision with pedestrian or animal at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V110",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V1100",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1101",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1102",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1103",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1104",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1108",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1109",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V111",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1110",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1111",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1112",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1113",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1114",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1118",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1119",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V112",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1120",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1121",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1122",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1123",
      "description": "Pedal cyclist injured in collision with other pedalcycle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1124",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1128",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1129",
      "description": "Pedal cyclist injured in collision with other pedal cycle at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V113",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting"
    },
    {
      "name": "V1130",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1131",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1132",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1133",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1134",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1138",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1139",
      "description": "Pedal cyclist injured in collision with other pedal cycle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V114",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident"
    },
    {
      "name": "V1140",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1141",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1142",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1143",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1144",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1148",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1149",
      "description": "Pedal cyclist injured in collision with other pedal cycle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V115",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident"
    },
    {
      "name": "V1150",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1151",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1152",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1153",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1154",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1158",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1159",
      "description": "Pedal cyclist injured in collision with other pedal cycle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V119",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1190",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1191",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1192",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1193",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1194",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1198",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1199",
      "description": "Pedal cyclist injured in collision with other pedal cycle at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V120",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident "
    },
    {
      "name": "V1200",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1201",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1202",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1203",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1204",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1208",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1209",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V121",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1210",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1211",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1212",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1213",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1214",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1218",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1219",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V122",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1220",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1221",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1222",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1223",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1224",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1228",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V1229",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at Unspecified pedal cyclist injured in traffic accident During unspecified activity "
    },
    {
      "name": "V123",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V1230",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1231",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1232",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1233",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1234",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1238",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1239",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V124",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V1240",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1241",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1242",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1243",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1244",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1248",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1249",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V125",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V1250",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1251",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1252",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1253",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1254",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1258",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1259",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V129",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident "
    },
    {
      "name": "V1290",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1291",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1292",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1293",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1294",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1298",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1299",
      "description": "Pedal cyclist injured in collision with two-or-three-wheeled motor vehicle at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V130",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V1300",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1301",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1302",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1303",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1304",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1308",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1309",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V131",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1310",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1311",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1312",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1313",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1314",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1318",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V1319",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V132",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1320",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1321",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1322",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1323",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1324",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1328",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1329",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V133",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting"
    },
    {
      "name": "V1330",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1331",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1332",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1333",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1334",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1338",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1339",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V134",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident  "
    },
    {
      "name": "V1340",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1341",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident  While engaged in leisure activity"
    },
    {
      "name": "V1342",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident  While working for income"
    },
    {
      "name": "V1343",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1344",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1348",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident  While engaged in other specified activities"
    },
    {
      "name": "V1349",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V135",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident"
    },
    {
      "name": "V1350",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1351",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1352",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1353",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1354",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1358",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1359",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V139",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1390",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While engaged in sports activity "
    },
    {
      "name": "V1391",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1392",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1393",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1394",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1398",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1399",
      "description": "Pedal cyclist injured in collision with car, pick-up trunk or van at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V140",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident "
    },
    {
      "name": "V1400",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1401",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1402",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1403",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1404",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1408",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1409",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V141",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1410",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1411",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1412",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1413",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1414",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1418",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1419",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V142",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1420",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1421",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1422",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1423",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1424",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1428",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1429",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V143",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting"
    },
    {
      "name": "V1430",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1431",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1432",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1433",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1434",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1438",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1439",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V144",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident"
    },
    {
      "name": "V1440",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1441",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1442",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1443",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1444",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1448",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1449",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V145",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident"
    },
    {
      "name": "V1450",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1451",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1452",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1453",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1454",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1458",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1459",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V149",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1490",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1491",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1492",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1493",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1494",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1498",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1499",
      "description": "Pedal cyclist injured in collision with heavy transport vehicle or bus at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V150",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V1500",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1501",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1502",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1503",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1504",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1508",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1509",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V151",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1510",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1511",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1512",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1513",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1514",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1518",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1519",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V152",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1520",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1521",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1522",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1523",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1524",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1528",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1529",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V153",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V1530",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in sports activity "
    },
    {
      "name": "V1531",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1532",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1533",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1534",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1538",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1539",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V154",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident"
    },
    {
      "name": "V1540",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1541",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1542",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1543",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1544",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1548",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1549",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V155",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V1550",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1551",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1552",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1553",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1554",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1558",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1559",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V159",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1590",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1591",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1592",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1593",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1594",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1598",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1599",
      "description": "Pedal cyclist injured in collision with railway train or railway vehicle at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V160",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident "
    },
    {
      "name": "V1600",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1601",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1602",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1603",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1604",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1608",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1609",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V161",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1610",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1611",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1612",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1613",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1614",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1618",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1619",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V162",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1620",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1621",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1622",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1623",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1624",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1628",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1629",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V163",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V1630",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1631",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1632",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1633",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1634",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1638",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1639",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V164",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V1640",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1641",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1642",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1643",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1644",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1648",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1649",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V165",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V1650",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1651",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1652",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1653",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1654",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1658",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1659",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V169",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1690",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1691",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1692",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1693",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1694",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1698",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1699",
      "description": "Pedal cyclist injured in collision with other nonmotor vehicle at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V170",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident"
    },
    {
      "name": "V1700",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1701",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1702",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1703",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1704",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1708",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1709",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V171",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1710",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1711",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1712",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1713",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1714",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1718",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1719",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V172",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1720",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1721",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1722",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1723",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1724",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1728",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1729",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V173",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting"
    },
    {
      "name": "V1730",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1731",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1732",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1733",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1734",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1738",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1739",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V174",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident"
    },
    {
      "name": "V1740",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1741",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1742",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1743",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1744",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1748",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1749",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V175",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident"
    },
    {
      "name": "V1750",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1751",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1752",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1753",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1754",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1758",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1759",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V179",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1790",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1791",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1792",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1793",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1794",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1798",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1799",
      "description": "Pedal cyclist injured in collision with fixed or stationary object at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V180",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V1800",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1801",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1802",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V1803",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1804",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1808",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1809",
      "description": "Pedal cyclist injured in noncollision transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V181",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V1810",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1811",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1812",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V1813",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1814",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1818",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1819",
      "description": "Pedal cyclist injured in noncollision transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V182",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident"
    },
    {
      "name": "V1820",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1821",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1822",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While working for income"
    },
    {
      "name": "V1823",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1824",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1828",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1829",
      "description": "Pedal cyclist injured in noncollision transport accident at Unspecified pedal cyclist injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V183",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting"
    },
    {
      "name": "V1830",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V1831",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V1832",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V1833",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V1834",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1838",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V1839",
      "description": "Pedal cyclist injured in noncollision transport accident at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V184",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident"
    },
    {
      "name": "V1840",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1841",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1842",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While working for income"
    },
    {
      "name": "V1843",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1844",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1848",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1849",
      "description": "Pedal cyclist injured in noncollision transport accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V185",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident"
    },
    {
      "name": "V1850",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1851",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1852",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V1853",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1854",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1858",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1859",
      "description": "Pedal cyclist injured in noncollision transport accident at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V189",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident"
    },
    {
      "name": "V1890",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1891",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1892",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While working for income"
    },
    {
      "name": "V1893",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1894",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1898",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1899",
      "description": "Pedal cyclist injured in noncollision transport accident at unspecified pedal cyclist injured in traffic accident During unspecified activity"
    },
    {
      "name": "V190",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V1900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V1903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V191",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V1910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V1913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V192",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V1920",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1921",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1922",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V1923",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1924",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1928",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1929",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V193",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident"
    },
    {
      "name": "V1930",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V1931",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V1932",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V1933",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V1934",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1938",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V1939",
      "description": "Pedal cyclist [any] injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V194",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V1940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V1943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V195",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V1950",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V1951",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1952",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V1953",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1954",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1958",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1959",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V196",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V1960",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in sports activity"
    },
    {
      "name": "V1961",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1962",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V1963",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V1964",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1968",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1969",
      "description": "Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V198",
      "description": "Pedal cyclist [any] injured in other specified transport accidents"
    },
    {
      "name": "V1980",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V1981",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V1982",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While working for income"
    },
    {
      "name": "V1983",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V1984",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1988",
      "description": "Pedal cyclist [any] injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V1989",
      "description": "Pedal cyclist [any] injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V199",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident"
    },
    {
      "name": "V1990",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V1991",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V1992",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While working for income"
    },
    {
      "name": "V1993",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V1994",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V1998",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V1999",
      "description": "Pedal cyclist [any] injured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V200",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident "
    },
    {
      "name": "V2000",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2001",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2002",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2003",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2004",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2008",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2009",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V201",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident "
    },
    {
      "name": "V2010",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2011",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2012",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2013",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2014",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2018",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2019",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V202",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2020",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2021",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2022",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident While working for income"
    },
    {
      "name": "V2023",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V2024",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2028",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2029",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at Unspecified motorcycle rider injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V203",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting"
    },
    {
      "name": "V2030",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2031",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2032",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2033",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2034",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2038",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2039",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at person injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V204",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V2040",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2041",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2042",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V2043",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2044",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2048",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2049",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V205",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic accident"
    },
    {
      "name": "V2050",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2051",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2052",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V2053",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2054",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2058",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2059",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V209",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2090",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2091",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2092",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2093",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2094",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2098",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2099",
      "description": "Motorcycle rider injured in collision with pedestrian or animal at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V210",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V2100",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2101",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2102",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2103",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2104",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2108",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2109",
      "description": "Motorcycle rider injured in collision with pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V211",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2110",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2111",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2112",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2113",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2114",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2118",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2119",
      "description": "Motorcycle rider injured in collision with pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V212",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2120",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2121",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2122",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While working for income"
    },
    {
      "name": "V2123",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2124",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2128",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2129",
      "description": "Motorcycle rider injured in collision with pedal cycle at Unspecified motorcycle rider injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V213",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting"
    },
    {
      "name": "V2130",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2131",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2132",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2133",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2134",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2138",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2139",
      "description": "Motorcycle rider injured in collision with pedal cycle at person injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V214",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic accident"
    },
    {
      "name": "V2140",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2141",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2142",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V2143",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2144",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2148",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2149",
      "description": "Motorcycle rider injured in collision with pedal cycle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V215",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic accident"
    },
    {
      "name": "V2150",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2151",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2152",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V2153",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2154",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2158",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2159",
      "description": "Motorcycle rider injured in collision with pedal cycle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V219",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2190",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2191",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2192",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2193",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2194",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2198",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2199",
      "description": "Motorcycle rider injured in collision with pedal cycle at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V220",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident "
    },
    {
      "name": "V2200",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2201",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2202",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2203",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2204",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2208",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2209",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V221",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2210",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2211",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2212",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2213",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2214",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2218",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2219",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V222",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid"
    },
    {
      "name": "V2220",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While engaged in sports activity"
    },
    {
      "name": "V2221",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While engaged in leisure activity"
    },
    {
      "name": "V2222",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While working for income"
    },
    {
      "name": "V2223",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While engaged in other types of work"
    },
    {
      "name": "V2224",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2228",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid While engaged in other specified activities"
    },
    {
      "name": "V2229",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at Unspecified motorcycle rider injured in nontraffic accid During unspecified activity"
    },
    {
      "name": "V223",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting"
    },
    {
      "name": "V2230",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2231",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2232",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While working for income"
    },
    {
      "name": "V2233",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2234",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2238",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2239",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at persom injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V224",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident"
    },
    {
      "name": "V2240",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2241",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2242",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V2243",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2244",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2248",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2249",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V225",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic  accident"
    },
    {
      "name": "V2250",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2251",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2252",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V2253",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2254",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2258",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2259",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V229",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2290",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2291",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2292",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2293",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2294",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2298",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2299",
      "description": "Motorcycle rider injured in collision with two-or three-wheeled motor vehicle at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V230",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V2300",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2301",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2302",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2303",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2304",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2308",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2309",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V231",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2310",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2311",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2312",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2313",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2314",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2318",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other specified activities "
    },
    {
      "name": "V2319",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V232",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2320",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V2321",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2322",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  While working for income"
    },
    {
      "name": "V2323",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V2324",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2328",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V2329",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at Unspecified motorcycle rider injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V233",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting"
    },
    {
      "name": "V2330",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2331",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2332",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2333",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2334",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2338",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2339",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at person injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V234",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident"
    },
    {
      "name": "V2340",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2341",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2342",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V2343",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2344",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2348",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2349",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V235",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic accident"
    },
    {
      "name": "V2350",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2351",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2352",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V2353",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2354",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2358",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2359",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V239",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2390",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2391",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2392",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2393",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2394",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2398",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2399",
      "description": "Motorcycle rider injured in collision with car, pick-up truck or van at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V240",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident"
    },
    {
      "name": "V2400",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2401",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2402",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2403",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2404",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2408",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2409",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V241",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident "
    },
    {
      "name": "V2410",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2411",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2412",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2413",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2414",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2418",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2419",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V242",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident "
    },
    {
      "name": "V2420",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V2421",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V2422",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident While working for income"
    },
    {
      "name": "V2423",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V2424",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2428",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2429",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at Unspecified motorcycle rider injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V243",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting"
    },
    {
      "name": "V2430",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2431",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2432",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2433",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2434",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2438",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2439",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at person injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V244",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic accident"
    },
    {
      "name": "V2440",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2441",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2442",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V2443",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2444",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2448",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2449",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V245",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident"
    },
    {
      "name": "V2450",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2451",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2452",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V2453",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2454",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2458",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2459",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V249",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2490",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2491",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2492",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2493",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2494",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2498",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2499",
      "description": "Motorcycle rider injured in collision with heavy transport vehicle or bus at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V250",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V2500",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2501",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2502",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2503",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2504",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2508",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2509",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V251",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident "
    },
    {
      "name": "V2510",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2511",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2512",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2513",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2514",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2518",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2519",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V252",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2520",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V2521",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V2522",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While working for income"
    },
    {
      "name": "V2523",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V2524",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2528",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V2529",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at Unspecified motorcycle rider injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V253",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting"
    },
    {
      "name": "V2530",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2531",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2532",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2533",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2534",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2538",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2539",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at person  injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V254",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic  accident"
    },
    {
      "name": "V2540",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2541",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2542",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic accident While working for income "
    },
    {
      "name": "V2543",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2544",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2548",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2549",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V255",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V2550",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2551",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2552",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V2553",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2554",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2558",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2559",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V259",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2590",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2591",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2592",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2593",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2594",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2598",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2599",
      "description": "Motorcycle rider injured in collision with railway train or railway vehicle at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V260",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V2600",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2601",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2602",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2603",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2604",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2608",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2609",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V261",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2610",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2611",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2612",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2613",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2614",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2618",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2619",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V262",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2620",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2621",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2622",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While working for income"
    },
    {
      "name": "V2623",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2624",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2628",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2629",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at Unspecified motorcycle rider injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V263",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person injured while broading or alighting"
    },
    {
      "name": "V2630",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2631",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person  injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2632",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2633",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2634",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person  injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2638",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2639",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at person  injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V264",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident"
    },
    {
      "name": "V2640",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2641",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2642",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V2643",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2644",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2648",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2649",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V265",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V2650",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V2651",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V2652",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V2653",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V2654",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2658",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2659",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V269",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2690",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2691",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2692",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2693",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2694",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2698",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2699",
      "description": "Motorcycle rider injured in collision with other nonmotor vehicle at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V270",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident"
    },
    {
      "name": "V2700",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2701",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2702",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2703",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2704",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2708",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2709",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V271",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2710",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2711",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2712",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2713",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2714",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2718",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2719",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V272",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident "
    },
    {
      "name": "V2720",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V2721",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V2722",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  While working for income"
    },
    {
      "name": "V2723",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V2724",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2728",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V2729",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at Unspecified motorcycle rider injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V273",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting"
    },
    {
      "name": "V2730",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While engaged in sports activity"
    },
    {
      "name": "V2731",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While engaged in leisure activity"
    },
    {
      "name": "V2732",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While working for income"
    },
    {
      "name": "V2733",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While engaged in other types of work"
    },
    {
      "name": "V2734",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2738",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person injured while broading or alighting While engaged in other specified activities"
    },
    {
      "name": "V2739",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at person  injured while broading or alighting During unspecified activity"
    },
    {
      "name": "V274",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident"
    },
    {
      "name": "V2740",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2741",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2742",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While working for income"
    },
    {
      "name": "V2743",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2744",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2748",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2749",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V275",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic  accident"
    },
    {
      "name": "V2750",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2751",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2752",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V2753",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2754",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2758",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V2759",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V279",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2790",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2791",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2792",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2793",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2794",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2798",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2799",
      "description": "Motorcycle rider injured in collision with fixed or stationary object at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V280",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V2800",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2801",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2802",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V2803",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2804",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2808",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2809",
      "description": "Motorcycle rider injured in noncollision transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V281",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V2810",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2811",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2812",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V2813",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2814",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2818",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2819",
      "description": "Motorcycle rider injured in noncollision transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V282",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident"
    },
    {
      "name": "V2820",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2821",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2822",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While working for income"
    },
    {
      "name": "V2823",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2824",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2828",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2829",
      "description": "Motorcycle rider injured in noncollision transport accident at Unspecified motorcycle rider injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V283",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting"
    },
    {
      "name": "V2830",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V2831",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V2832",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V2833",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V2834",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2838",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V2839",
      "description": "Motorcycle rider injured in noncollision transport accident at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V284",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident"
    },
    {
      "name": "V2840",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2841",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2842",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While working for income"
    },
    {
      "name": "V2843",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2844",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2848",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2849",
      "description": "Motorcycle rider injured in noncollision transport accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V285",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident"
    },
    {
      "name": "V2850",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2851",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2852",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V2853",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2854",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2858",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2859",
      "description": "Motorcycle rider injured in noncollision transport accident at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V289",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident"
    },
    {
      "name": "V2890",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2891",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2892",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While working for income"
    },
    {
      "name": "V2893",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2894",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2898",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2899",
      "description": "Motorcycle rider injured in noncollision transport accident at unspecified motorcycle rider injured in traffic accident During unspecified activity"
    },
    {
      "name": "V290",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident "
    },
    {
      "name": "V2900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V2903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V291",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V2910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V2913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V292",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V2920",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2921",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2922",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V2923",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2924",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2928",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2929",
      "description": "Unsepcified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V293",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident"
    },
    {
      "name": "V2930",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V2931",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V2932",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V2933",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V2934",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2938",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V2939",
      "description": "Motorcycle rider [any] injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V294",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V2940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V2943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V295",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V2950",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V2951",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2952",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V2953",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2954",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2958",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2959",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V296",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V2960",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accidentt While engaged in sports activity"
    },
    {
      "name": "V2961",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2962",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V2963",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V2964",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2968",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2969",
      "description": "Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V298",
      "description": "Motorcycle rider [any] injured in other specified transport accidents"
    },
    {
      "name": "V2980",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V2981",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V2982",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While working for income"
    },
    {
      "name": "V2983",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V2984",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2988",
      "description": "Motorcycle rider [any] injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V2989",
      "description": "Motorcycle rider [any] injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V299",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident"
    },
    {
      "name": "V2990",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V2991",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V2992",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While working for income"
    },
    {
      "name": "V2993",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V2994",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V2998",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V2999",
      "description": "Motorcycle rider [any] injured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V300",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V3000",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3001",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3002",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3003",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3004",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3008",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3009",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V301",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident\n"
    },
    {
      "name": "V3010",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3011",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3012",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3013",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3014",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3018",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3019",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Passenger  During unspecified activityinjured in nontraffic accident  "
    },
    {
      "name": "V302",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3020",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3021",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3022",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3023",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3024",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3028",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3029",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V303",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident"
    },
    {
      "name": "V3030",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While engaged in sports activity"
    },
    {
      "name": "V3031",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While engaged in leisure activity"
    },
    {
      "name": "V3032",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While working for income"
    },
    {
      "name": "V3033",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While engaged in other types of work"
    },
    {
      "name": "V3034",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3038",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident While engaged in other specified activities"
    },
    {
      "name": "V3039",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three-wheeled motor vihicle injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V304",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting"
    },
    {
      "name": "V3040",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3041",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3042",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V3043",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3044",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3048",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3049",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V305",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V3050",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3051",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3052",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3053",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3054",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3058",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3059",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V306",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident"
    },
    {
      "name": "V3060",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3061",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3062",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3063",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3064",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3068",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3069",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V307",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3070",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3071",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3072",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3073",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3074",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3078",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3079",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V309",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident"
    },
    {
      "name": "V3090",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3091",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3092",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While working for income"
    },
    {
      "name": "V3093",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3094",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3098",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3099",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal at unspecified occupant of three wheeled motor vihicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V310",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V3100",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3101",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3102",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3103",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3104",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3108",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3109",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V311",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3110",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3111",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3112",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3113",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3114",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3118",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3119",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V312",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3120",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3121",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3122",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3123",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3124",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3128",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3129",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V313",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident"
    },
    {
      "name": "V3130",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3131",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3132",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3133",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3134",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3138",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3139",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three-wheeled motor vihicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V314",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting"
    },
    {
      "name": "V3140",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3141",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3142",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V3143",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3144",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3148",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3149",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V315",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident"
    },
    {
      "name": "V3150",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3151",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3152",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3153",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3154",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3158",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3159",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V316",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident"
    },
    {
      "name": "V3160",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3161",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3162",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3163",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3164",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3168",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3169",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V317",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3170",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3171",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3172",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3173",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3174",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3178",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3179",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V319",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident"
    },
    {
      "name": "V3190",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3191",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3192",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While working for income"
    },
    {
      "name": "V3193",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3194",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3198",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3199",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with pedal cycle at unspecified occupant of three wheeled motor vihicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V320",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V3200",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3201",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3202",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3203",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3204",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3208",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3209",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V321",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3210",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3211",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3212",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3213",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3214",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3218",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3219",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V322",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3220",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3221",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3222",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3223",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3224",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3228",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3229",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V323",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident"
    },
    {
      "name": "V3230",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3231",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3232",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3233",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3234",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3238",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3239",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V324",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V3240",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3241",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3242",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V3243",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3244",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3248",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3249",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V325",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V3250",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3251",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3252",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3253",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3254",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3258",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3259",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V326",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V3260",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3261",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3262",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3263",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3264",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3268",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3269",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V327",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3270",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3271",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3272",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3273",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3274",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3278",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3279",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V329",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident"
    },
    {
      "name": "V3290",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3291",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3292",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While working for income"
    },
    {
      "name": "V3293",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3294",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3298",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3299",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with two-or three-wheeld motor vehicle at unspecified occupant of three-wheeled motor vihicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V330",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V3300",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3301",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3302",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3303",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3304",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3308",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3309",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V331",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident "
    },
    {
      "name": "V3310",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3311",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3312",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3313",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3314",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3318",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3319",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V332",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3320",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3321",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3322",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3323",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V3324",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3328",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3329",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V333",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident"
    },
    {
      "name": "V3330",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3331",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3332",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3333",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3334",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3338",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3339",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V334",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting"
    },
    {
      "name": "V3340",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3341",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3342",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V3343",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3344",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3348",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3349",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V335",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident"
    },
    {
      "name": "V3350",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3351",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3352",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3353",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3354",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3358",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3359",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V336",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident"
    },
    {
      "name": "V3360",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3361",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3362",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3363",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3364",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3368",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3369",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V337",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3370",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3371",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3372",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3373",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3374",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3378",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3379",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V339",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident"
    },
    {
      "name": "V3390",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3391",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3392",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While working for income"
    },
    {
      "name": "V3393",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3394",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3398",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3399",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van at unspecified occupant of three wheeled motor vihicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V340",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident"
    },
    {
      "name": "V3400",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3401",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3402",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3403",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3404",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3408",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3409",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V341",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3410",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3411",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3412",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3413",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3414",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3418",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3419",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V342",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3420",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3421",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3422",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3423",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3424",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3428",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3429",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V343",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident"
    },
    {
      "name": "V3430",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3431",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3432",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3433",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3434",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3438",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3439",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V344",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting"
    },
    {
      "name": "V3440",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3441",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3442",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V3443",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3444",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3448",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3449",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V345",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident"
    },
    {
      "name": "V3450",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3451",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3452",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3453",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3454",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3458",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3459",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V346",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident"
    },
    {
      "name": "V3460",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3461",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3462",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3463",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3464",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3468",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3469",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V347",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3470",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3471",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3472",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3473",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3474",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3478",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3479",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V349",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident"
    },
    {
      "name": "V3490",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V3491",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While engaged in leisure activity"
    },
    {
      "name": "V3492",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While working for income"
    },
    {
      "name": "V3493",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While engaged in other types of work"
    },
    {
      "name": "V3494",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3498",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident While engaged in other specified activities"
    },
    {
      "name": "V3499",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus at unspecified occupant of three wheeled motor vihicle injured in traffice accident During unspecified activity"
    },
    {
      "name": "V350",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V3500",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3501",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3502",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3503",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3504",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3508",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3509",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V351",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3510",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3511",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3512",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3513",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3514",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3518",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3519",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V352",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3520",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3521",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3522",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3523",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3524",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3528",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3529",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V353",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident"
    },
    {
      "name": "V3530",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3531",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3532",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3533",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3534",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3538",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3539",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three wheeled motor vihicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V354",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V3540",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3541",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3542",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V3543",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3544",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3548",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3549",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V355",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident"
    },
    {
      "name": "V3550",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3551",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3552",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3553",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3554",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3558",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3559",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V356",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V3560",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3561",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3562",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3563",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3564",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3568",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3569",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V357",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3570",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3571",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3572",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3573",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3574",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3578",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3579",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V359",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident"
    },
    {
      "name": "V3590",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3591",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in trafficaccident While engaged in leisure activity"
    },
    {
      "name": "V3592",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3593",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in trafficeaccident While engaged in other types of work"
    },
    {
      "name": "V3594",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in trafficeaccident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3598",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3599",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V360",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V3600",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3601",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3602",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3603",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3604",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3608",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3609",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V361",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3610",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3611",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3612",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3613",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3614",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3618",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3619",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V362",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3620",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3621",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3622",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3623",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3624",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3628",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3629",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V363",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident"
    },
    {
      "name": "V3630",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three -wheeled motor vehicle injured in nontraffice accident While engaged in sports activity"
    },
    {
      "name": "V3631",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident While engaged in leisure activity"
    },
    {
      "name": "V3632",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three -wheeled motor vehicle injured in nontraffice accident While working for income"
    },
    {
      "name": "V3633",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident While engaged in other types of work"
    },
    {
      "name": "V3634",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3638",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident While engaged in other specified activities"
    },
    {
      "name": "V3639",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V364",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V3640",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3641",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3642",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V3643",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3644",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3648",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3649",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V365",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V3650",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in sports activity "
    },
    {
      "name": "V3651",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3652",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3653",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3654",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3658",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3659",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V366",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V3660",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in sports activity "
    },
    {
      "name": "V3661",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3662",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3663",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3664",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3668",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3669",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident During unspecified activity "
    },
    {
      "name": "V367",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3670",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3671",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3672",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3673",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3674",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3678",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3679",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V369",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident"
    },
    {
      "name": "V3690",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V3691",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3692",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3693",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three - wheeled motor vehicle injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V3694",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3698",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3699",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle at unspecified occupant of three- wheeled motor vehicle injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V370",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident "
    },
    {
      "name": "V3700",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3701",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3702",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3703",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3704",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3708",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3709",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V371",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V3710",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3711",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3712",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3713",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3714",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3718",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3719",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V372",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3720",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3721",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3722",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3723",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3724",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3728",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3729",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V373",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at unspecified occupant of three-wheeled motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V3730",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three- wheeled motor vehicle injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V3731",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at unspecified occupant of three -wheeled motor vehicle injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3732",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in nontraffic  accident While working for income"
    },
    {
      "name": "V3733",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at unspecified occupant of three- wheeled motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3734",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three- wheeled motor vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3738",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3739",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three- wheeled motor vehicle injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V374",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting"
    },
    {
      "name": "V3740",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3741",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3742",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V3743",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3744",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3748",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3749",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V375",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at driver injured in traffic accident"
    },
    {
      "name": "V3750",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or  stationary object at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V3751",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3752",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at driver injured in traffic accident While working for income"
    },
    {
      "name": "V3753",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V3754",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3758",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3759",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V376",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at passenger injured in traffic accident"
    },
    {
      "name": "V3760",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3761",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or  stationary object at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3762",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V3763",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3764",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3768",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or  stationary object at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3769",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V377",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3770",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3771",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3772",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3773",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3774",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3778",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3779",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V379",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic  accident"
    },
    {
      "name": "V3790",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V3791",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3792",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3793",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3794",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3798",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed or  stationary object at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3799",
      "description": "Occupant of three-wheeled motor vehicle injured in collision with fixed  or stationary object at unspecified occupant of three-wheeled motor vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V380",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V3800",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3801",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3802",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V3803",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3804",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3808",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3809",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V381",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident "
    },
    {
      "name": "V3810",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3811",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3812",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V3813",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3814",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3818",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3819",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V382",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V3820",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3821",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3822",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V3823",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3824",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3828",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3829",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V383",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three- wheeled motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V3830",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V3831",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three- wheeled motor vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3832",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three-wheeled motor vehicle injured in nontraffic  accident While working for income"
    },
    {
      "name": "V3833",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3834",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3838",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V3839",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V384",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting"
    },
    {
      "name": "V3840",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V3841",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V3842",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V3843",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V3844",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3848",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V3849",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V385",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic accident"
    },
    {
      "name": "V3850",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3851",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3852",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V3853",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V3854",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3858",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3859",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V386",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic  accident"
    },
    {
      "name": "V3860",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V3861",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3862",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V3863",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3864",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3868",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3869",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V387",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V3870",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3871",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3872",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3873",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3874",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3878",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3879",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V389",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in traffic accident"
    },
    {
      "name": "V3890",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V3891",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V3892",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V3893",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three- wheeled motor vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3894",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3898",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three -wheeled motor vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3899",
      "description": "Occupant of three-wheeled motor vehicle injured in noncollision transport accident at unspecified occupant of three- wheeled motor vehicle injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V390",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V3900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V3903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V391",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V3910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V3913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V392",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V3920",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3921",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3922",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V3923",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3924",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3928",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3929",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V393",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident"
    },
    {
      "name": "V3930",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V3931",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V3932",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V3933",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V3934",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3938",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V3939",
      "description": "Occupant [any] of three-weeled motor vehicle injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V394",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V3940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V3943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work "
    },
    {
      "name": "V3944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V395",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident"
    },
    {
      "name": "V3950",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3951",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3952",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V3953",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3954",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3958",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3959",
      "description": "Passenger injured in collision with other and unspcified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V396",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V3960",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V3961",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3962",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V3963",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V3964",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3968",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3969",
      "description": "Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V398",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents"
    },
    {
      "name": "V3980",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V3981",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V3982",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While working for income"
    },
    {
      "name": "V3983",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V3984",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3988",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V3989",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V399",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident"
    },
    {
      "name": "V3990",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V3991",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V3992",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While working for income"
    },
    {
      "name": "V3993",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V3994",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V3998",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V3999",
      "description": "Occupant [any] of three-wheeled motor vehicle  injured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V400",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V4000",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4001",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4002",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4003",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4004",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4008",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4009",
      "description": "Car occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V401",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4010",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4011",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4012",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4013",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4014",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4018",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4019",
      "description": "Car occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V402",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4020",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4021",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4022",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4023",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4024",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4028",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4029",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V403",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4030",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4031",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4032",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4033",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4034",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4038",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4039",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V404",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting"
    },
    {
      "name": "V4040",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4041",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4042",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V4043",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4044",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4048",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4049",
      "description": "Car occupant injured in collision with pedestrian or animal at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V405",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V4050",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4051",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4052",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4053",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4054",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4058",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V4059",
      "description": "Car occupant injured in collision with pedestrian or animal at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V406",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident"
    },
    {
      "name": "V4060",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4061",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V4062",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V4063",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4064",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4068",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V4069",
      "description": "Car occupant injured in collision with pedestrian or animal at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V407",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4070",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4071",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4072",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4073",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4074",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4078",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4079",
      "description": "Car occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V409",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic  accident"
    },
    {
      "name": "V4090",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4091",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V4092",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic  accident While working for income"
    },
    {
      "name": "V4093",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4094",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4098",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4099",
      "description": "Car occupant injured in collision with pedestrian or animal at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V410",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V4100",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4101",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4102",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4103",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4104",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4108",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4109",
      "description": "Car occupant injured in collision with pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V411",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4110",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4111",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4112",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4113",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4114",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4118",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4119",
      "description": "Car occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V412",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4120",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4121",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4122",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4123",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4124",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4128",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4129",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V413",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic  accident"
    },
    {
      "name": "V4130",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V4131",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4132",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4133",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V4134",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4138",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4139",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V414",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting"
    },
    {
      "name": "V4140",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4141",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4142",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V4143",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4144",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4148",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4149",
      "description": "Car occupant injured in collision with pedal cycle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V415",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic   accident"
    },
    {
      "name": "V4150",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V4151",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V4152",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V4153",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4154",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4158",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V4159",
      "description": "Car occupant injured in collision with pedal cycle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V416",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident"
    },
    {
      "name": "V4160",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V4161",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V4162",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V4163",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4164",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4168",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V4169",
      "description": "Car occupant injured in collision with pedal cycle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V417",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4170",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4171",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4172",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4173",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4174",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4178",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4179",
      "description": "Car occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V419",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident"
    },
    {
      "name": "V4190",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V4191",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V4192",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While working for income"
    },
    {
      "name": "V4193",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V4194",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4198",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V4199",
      "description": "Car occupant injured in collision with pedal cycle at unspecified car occupant  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V420",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V4200",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4201",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4202",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4203",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4204",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4208",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4209",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V421",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4210",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4211",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4212",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4213",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4214",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4218",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4219",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V422",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4220",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4221",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4222",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4223",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4224",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4228",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4229",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V423",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4230",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4231",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4232",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4233",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4234",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4238",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4239",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V424",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V4240",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4241",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4242",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4243",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4244",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4248",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4249",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V425",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V4250",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4251",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4252",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4253",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4254",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4258",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4259",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V426",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V4260",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4261",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4262",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4263",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4264",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4268",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4269",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V427",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4270",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4271",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4272",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4273",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4274",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4278",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4279",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V429",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4290",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4291",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4292",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4293",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4294",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4298",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4299",
      "description": "Car occupant injured in collision with two-or three-wheeled motor vehicle at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V430",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V4300",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4301",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4302",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4303",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4304",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4308",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4309",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V431",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4310",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4311",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4312",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4313",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4314",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4318",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4319",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V432",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4320",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4321",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4322",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4323",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4324",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4328",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4329",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V433",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4330",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4331",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4332",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4333",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4334",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4338",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4339",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V434",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting"
    },
    {
      "name": "V4340",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4341",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4342",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4343",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4344",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4348",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4349",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V435",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident"
    },
    {
      "name": "V4350",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4351",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4352",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4353",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4354",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4358",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4359",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V436",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident"
    },
    {
      "name": "V4360",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4361",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4362",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4363",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4364",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4368",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4369",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V437",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4370",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4371",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4372",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4373",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4374",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4378",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4379",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V439",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4390",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4391",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4392",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4393",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4394",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4398",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4399",
      "description": "Car occupant injured in collision with car, pick-up trunk or van at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V440",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident"
    },
    {
      "name": "V4400",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4401",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4402",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4403",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4404",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4408",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4409",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V441",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4410",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4411",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4412",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4413",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4414",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4418",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4419",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V442",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4420",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4421",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4422",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4423",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4424",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4428",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4429",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V443",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4430",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4431",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4432",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4433",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4434",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4438",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4439",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V444",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting"
    },
    {
      "name": "V4440",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4441",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4442",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4443",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4444",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4448",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4449",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V445",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident"
    },
    {
      "name": "V4450",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4451",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4452",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4453",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4454",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4458",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4459",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V446",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident"
    },
    {
      "name": "V4460",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4461",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4462",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4463",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4464",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4468",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4469",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V447",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4470",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4471",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4472",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4473",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4474",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4478",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4479",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V449",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4490",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4491",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4492",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4493",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4494",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4498",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4499",
      "description": "Car occupant injured in collision with heavy transport vehicle or bus at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V450",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V4500",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4501",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4502",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4503",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4504",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4508",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4509",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V451",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4510",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4511",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4512",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4513",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4514",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4518",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4519",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V452",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4520",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4521",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4522",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4523",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4524",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4528",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4529",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V453",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4530",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4531",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4532",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4533",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4534",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4538",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4539",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V454",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V4540",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4541",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4542",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4543",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4544",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4548",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4549",
      "description": "Car occupant injured in collision with railway train or railway vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V455",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident"
    },
    {
      "name": "V4550",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4551",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4552",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4553",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4554",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4558",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4559",
      "description": "Car occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V456",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V4560",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4561",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4562",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4563",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4564",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4568",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4569",
      "description": "Car occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V457",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4570",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4571",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4572",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4573",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4574",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4578",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4579",
      "description": "Car occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V459",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4590",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4591",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4592",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4593",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4594",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4598",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4599",
      "description": "Car occupant injured in collision with railway train or railway vehicle at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V460",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V4600",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4601",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4602",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4603",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4604",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4608",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4609",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V461",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4610",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4611",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4612",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4613",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4614",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4618",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4619",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V462",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4620",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4621",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4622",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4623",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4624",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4628",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4629",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V463",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4630",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4631",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4632",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4633",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4634",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4638",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4639",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V464",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V4640",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4641",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4642",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4643",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4644",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4648",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4649",
      "description": "Car occupant injured in collision with other nonmotor vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V465",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V4650",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4651",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4652",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4653",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4654",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4658",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4659",
      "description": "Car occupant injured in collision with other nonmotor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V466",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V4660",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4661",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4662",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4663",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4664",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4668",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4669",
      "description": "Car occupant injured in collision with other nonmotor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V467",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4670",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4671",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4672",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4673",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4674",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4678",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4679",
      "description": "Car occupant injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V469",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4690",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4691",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4692",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4693",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4694",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4698",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4699",
      "description": "Car occupant injured in collision with other nonmotor vehicle at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V470",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident"
    },
    {
      "name": "V4700",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4701",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4702",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4703",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4704",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4708",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4709",
      "description": "Car occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V471",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4710",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4711",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4712",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4713",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4714",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4718",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4719",
      "description": "Car occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V472",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4720",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4721",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4722",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4723",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4724",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4728",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4729",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V473",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4730",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4731",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4732",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4733",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4734",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4738",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4739",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V474",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting"
    },
    {
      "name": "V4740",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4741",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4742",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4743",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4744",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4748",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4749",
      "description": "Car occupant injured in collision with fixed or stationary object at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V475",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident"
    },
    {
      "name": "V4750",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4751",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4752",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4753",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4754",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4758",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4759",
      "description": "Car occupant injured in collision with fixed or stationary object at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V476",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident"
    },
    {
      "name": "V4760",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4761",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4762",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4763",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4764",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4768",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4769",
      "description": "Car occupant injured in collision with fixed or stationary object at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V477",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4770",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4771",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4772",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4773",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4774",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4778",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4779",
      "description": "Car occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V479",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4790",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4791",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4792",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4793",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4794",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4798",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4799",
      "description": "Car occupant injured in collision with fixed or stationary object at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V480",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V4800",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4801",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4802",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V4803",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4804",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4808",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4809",
      "description": "Car occupant injured in noncollision transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V481",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V4810",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4811",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4812",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V4813",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4814",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4818",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4819",
      "description": "Car occupant injured in noncollision transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V482",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V4820",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4821",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4822",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V4823",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4824",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4828",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4829",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V483",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident"
    },
    {
      "name": "V4830",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4831",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4832",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While working for income"
    },
    {
      "name": "V4833",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4834",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4838",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4839",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V484",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting"
    },
    {
      "name": "V4840",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V4841",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V4842",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V4843",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V4844",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4848",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V4849",
      "description": "Car occupant injured in noncollision transport accident at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V485",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident"
    },
    {
      "name": "V4850",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4851",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4852",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While working for income"
    },
    {
      "name": "V4853",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4854",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4858",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4859",
      "description": "Car occupant injured in noncollision transport accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V486",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident"
    },
    {
      "name": "V4860",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4861",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4862",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V4863",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4864",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4868",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4869",
      "description": "Car occupant injured in noncollision transport accident at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V487",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V4870",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4871",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4872",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V4873",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4874",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4878",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4879",
      "description": "Car occupant injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V489",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident"
    },
    {
      "name": "V4890",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4891",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4892",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While working for income"
    },
    {
      "name": "V4893",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4894",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4898",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4899",
      "description": "Car occupant injured in noncollision transport accident at unspecified car occupant  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V490",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V4900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V4903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V491",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V4910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V4913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V492",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V4920",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4921",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4922",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V4923",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4924",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4928",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4929",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V493",
      "description": "Car occupant [any] injured in unspecified nontraffic accident"
    },
    {
      "name": "V4930",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V4931",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V4932",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V4933",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V4934",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4938",
      "description": "Car occupant [any] injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V4939",
      "description": "Car occupant [any] injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V494",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V4940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V4943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V495",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V4950",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4951",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4952",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V4953",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4954",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4958",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4959",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V496",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V4960",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V4961",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V4962",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V4963",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V4964",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4968",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V4969",
      "description": "Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V498",
      "description": "Car occupant [any] injured in other specified transport accidents"
    },
    {
      "name": "V4980",
      "description": "Car occupant [any] injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V4981",
      "description": "Car occupant [any] injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V4982",
      "description": "Car occupant [any] injured in other specified transport accidents While working for income"
    },
    {
      "name": "V4983",
      "description": "Car occupant [any] injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V4984",
      "description": "Car occupant [any] injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4988",
      "description": "Car occupant [any] injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V4989",
      "description": "Car occupant [any] injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V499",
      "description": "Car occupant [any] injured in unspecified traffic accidents"
    },
    {
      "name": "V4990",
      "description": "Car occupant [any] injured in unspecified traffic accidents While engaged in sports activity"
    },
    {
      "name": "V4991",
      "description": "Car occupant [any] injured in unspecified traffic accidents While engaged in leisure activity"
    },
    {
      "name": "V4992",
      "description": "Car occupant [any] injured in unspecified traffic accidents While working for income"
    },
    {
      "name": "V4993",
      "description": "Car occupant [any] injured in unspecified traffic accidents While engaged in other types of work"
    },
    {
      "name": "V4994",
      "description": "Car occupant [any] injured in unspecified traffic accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V4998",
      "description": "Car occupant [any] injured in unspecified traffic accidents While engaged in other specified activities"
    },
    {
      "name": "V4999",
      "description": "Car occupant [any] injured in unspecified traffic accidents During unspecified activity"
    },
    {
      "name": "V500",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V5000",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5001",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5002",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5003",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5004",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5008",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5009",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V501",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5010",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5011",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5012",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5013",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5014",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5018",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5019",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V502",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5020",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5021",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5022",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5023",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5024",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5028",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5029",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V503",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5030",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5031",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5032",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5033",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5034",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5038",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5039",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V504",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting"
    },
    {
      "name": "V5040",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5041",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5042",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5043",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5044",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5048",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5049",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V505",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V5050",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5051",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5052",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5053",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5054",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5058",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5059",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V506",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident"
    },
    {
      "name": "V5060",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5061",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5062",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5063",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5064",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5068",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5069",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V507",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5070",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5071",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5072",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5073",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5074",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5078",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5079",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V509",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident"
    },
    {
      "name": "V5090",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V5091",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While engaged in leisure activity"
    },
    {
      "name": "V5092",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While working for income"
    },
    {
      "name": "V5093",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While engaged in other types of work"
    },
    {
      "name": "V5094",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5098",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident While engaged in other specified activities"
    },
    {
      "name": "V5099",
      "description": "Occupant of pick-up truck or van injured in collision with pedestrian or animal at unspecified   occupant  of pick-up truck or van  injured in traffice accident During unspecified activity"
    },
    {
      "name": "V510",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V5100",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5101",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5102",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5103",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5104",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5108",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5109",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V511",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5110",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5111",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5112",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5113",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5114",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5118",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5119",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V512",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5120",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5121",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5122",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5123",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5124",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5128",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5129",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V513",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5130",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5131",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5132",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5133",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5134",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5138",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5139",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V514",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting"
    },
    {
      "name": "V5140",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5141",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5142",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5143",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5144",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5148",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5149",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V515",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident"
    },
    {
      "name": "V5150",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5151",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5152",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5153",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5154",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5158",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5159",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V516",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident"
    },
    {
      "name": "V5160",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5161",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5162",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5163",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5164",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5168",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5169",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V517",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5170",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5171",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5172",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5173",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5174",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5178",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5179",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V519",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5190",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5191",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5192",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5193",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5194",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5198",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5199",
      "description": "Occupant of pick-up truck or van injured in collision with pendal cycle at unspecified   occupant  of pick-up truck or van  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V520",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V5200",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5201",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5202",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5203",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5204",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5208",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5209",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V521",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5210",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic While engaged in sports activity  "
    },
    {
      "name": "V5211",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5212",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5213",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5214",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5218",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5219",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V522",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5220",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5221",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5222",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5223",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5224",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5228",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5229",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V523",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5230",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5231",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5232",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5233",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5234",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5238",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5239",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V524",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V5240",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5241",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5242",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5243",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5244",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5248",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5249",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V525",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V5250",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5251",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5252",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5253",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5254",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5258",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5259",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V526",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V5260",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5261",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5262",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5263",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5264",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5268",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5269",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V527",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5270",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5271",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5272",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5273",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5274",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5278",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5279",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V529",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5290",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5291",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5292",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5293",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5294",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5298",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5299",
      "description": "Occupant of pick-up truck or van injured in collision with two- or three-wheeld motor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V530",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V5300",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5301",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5302",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5303",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5304",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5308",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5309",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V531",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5310",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5311",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5312",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5313",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5314",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5318",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5319",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V532",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5320",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5321",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5322",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5323",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5324",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5328",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5329",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V533",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5330",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5331",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5332",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5333",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5334",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5338",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5339",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V534",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting"
    },
    {
      "name": "V5340",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5341",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5342",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5343",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5344",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5348",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5349",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V535",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident"
    },
    {
      "name": "V5350",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5351",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5352",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5353",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5354",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5358",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5359",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V536",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident"
    },
    {
      "name": "V5360",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5361",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5362",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5363",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5364",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5368",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5369",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V537",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5370",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5371",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5372",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5373",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5374",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5378",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5379",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V539",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5390",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5391",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5392",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5393",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5394",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5398",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5399",
      "description": "Occupant of pick-up truck or van injured in collision with car, pick-up truck or van at unspecified   occupant  of pick-up truck or van  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V540",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident"
    },
    {
      "name": "V5400",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5401",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5402",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5403",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5404",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5408",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5409",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V541",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5410",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5411",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5412",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5413",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5414",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5418",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5419",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V542",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5420",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5421",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5422",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5423",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5424",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5428",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5429",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V543",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5430",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5431",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5432",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5433",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5434",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5438",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5439",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V544",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting"
    },
    {
      "name": "V5440",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5441",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5442",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5443",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5444",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5448",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5449",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V545",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident"
    },
    {
      "name": "V5450",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5451",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5452",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5453",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5454",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5458",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5459",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V546",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident"
    },
    {
      "name": "V5460",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5461",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5462",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5463",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5464",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5468",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5469",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V547",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5470",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5471",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5472",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5473",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5474",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5478",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident  While engaged in other specified activities"
    },
    {
      "name": "V5479",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V549",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5490",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5491",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5492",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5493",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5494",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5498",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5499",
      "description": "Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus at unspecified   occupant  of pick-up truck or van  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V550",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V5500",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5501",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5502",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5503",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5504",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5508",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5509",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V551",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5510",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5511",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5512",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5513",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5514",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5518",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5519",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V552",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5520",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5521",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5522",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5523",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5524",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5528",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5529",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V553",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5530",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5531",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5532",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While working for income"
    },
    {
      "name": "V5533",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5534",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5538",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5539",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V554",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V5540",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5541",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5542",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V5543",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5544",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5548",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5549",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V555",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident"
    },
    {
      "name": "V5550",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5551",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5552",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V5553",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5554",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5558",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5559",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V556",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V5560",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in sports activity  "
    },
    {
      "name": "V5561",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5562",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V5563",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5564",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5568",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5569",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V557",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5570",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5571",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5572",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5573",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5574",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5578",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5579",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V559",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5590",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5591",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5592",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5593",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5594",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5598",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5599",
      "description": "Occupant of pick-up truck or van injured in collision with railway train or railway vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V560",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V5600",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5601",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5602",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5603",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5604",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5608",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5609",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V561",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5610",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5611",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5612",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5613",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5614",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5618",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5619",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V562",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5620",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5621",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5622",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5623",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5624",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5628",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5629",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V563",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident"
    },
    {
      "name": "V5630",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic   accident While engaged in sports activity"
    },
    {
      "name": "V5631",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5632",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While working for income"
    },
    {
      "name": "V5633",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V5634",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5638",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5639",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified  occupant  of pick-up truck or van injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V564",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V5640",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5641",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5642",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V5643",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5644",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5648",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5649",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V565",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident"
    },
    {
      "name": "V5650",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5651",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5652",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V5653",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5654",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5658",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5659",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V566",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident"
    },
    {
      "name": "V5660",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5661",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5662",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V5663",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident  While engaged in other types of work"
    },
    {
      "name": "V5664",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities "
    },
    {
      "name": "V5668",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5669",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at passenger injured in traffic  accident During unspecified activity "
    },
    {
      "name": "V567",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5670",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5671",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5672",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5673",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5674",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5678",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5679",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V569",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident"
    },
    {
      "name": "V5690",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5691",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5692",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While working for income"
    },
    {
      "name": "V5693",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5694",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5698",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5699",
      "description": "Occupant of pick-up truck or van injured in collision with other nonmotor vehicle at unspecified   occupant  of pick-up truck or van  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V570",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident"
    },
    {
      "name": "V5700",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5701",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5702",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5703",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5704",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5708",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other specified activities "
    },
    {
      "name": "V5709",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V571",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5710",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5711",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5712",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5713",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5714",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5718",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5719",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V572",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5720",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5721",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5722",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5723",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5724",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5728",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5729",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V573",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic accident"
    },
    {
      "name": "V5730",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V5731",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5732",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While working for income"
    },
    {
      "name": "V5733",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V5734",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5738",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5739",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V574",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting"
    },
    {
      "name": "V5740",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5741",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5742",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V5743",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5744",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5748",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5749",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V575",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident"
    },
    {
      "name": "V5750",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5751",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5752",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V5753",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5754",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5758",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5759",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V576",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident"
    },
    {
      "name": "V5760",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5761",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5762",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V5763",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5764",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5768",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5769",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V577",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5770",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5771",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5772",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5773",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5774",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5778",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5779",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V579",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident"
    },
    {
      "name": "V5790",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5791",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5792",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic accident While working for income"
    },
    {
      "name": "V5793",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5794",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5798",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5799",
      "description": "Occupant of pick-up truck or van injured in collision with fixed or stationary object at unspecified   occupant  of pick-up truck or van  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V580",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V5800",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5801",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5802",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V5803",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5804",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5808",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5809",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V581",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V5810",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5811",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5812",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V5813",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5814",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5818",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5819",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V582",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V5820",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5821",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5822",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V5823",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5824",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5828",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5829",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V583",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic   accident"
    },
    {
      "name": "V5830",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5831",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5832",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While working for income"
    },
    {
      "name": "V5833",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V5834",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5838",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5839",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified  occupant  of pick-up truck or van injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V584",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting"
    },
    {
      "name": "V5840",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V5841",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V5842",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V5843",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V5844",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5848",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V5849",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V585",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic accident"
    },
    {
      "name": "V5850",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5851",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5852",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V5853",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5854",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5858",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5859",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V586",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident"
    },
    {
      "name": "V5860",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5861",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5862",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V5863",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5864",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5868",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5869",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V587",
      "description": "Occupant of pick-up trunk or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V5870",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5871",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5872",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V5873",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5874",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5878",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5879",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V589",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic accident"
    },
    {
      "name": "V5890",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V5891",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V5892",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While working for income"
    },
    {
      "name": "V5893",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V5894",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5898",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V5899",
      "description": "Occupant of pick-up truck or van injured in noncollision transport accident at unspecified   occupant  of pick-up truck or van  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V590",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V5900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V5903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V591",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V5910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V5913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V592",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident"
    },
    {
      "name": "V5920",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5921",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5922",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While working for income"
    },
    {
      "name": "V5923",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5924",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While resting, sleeping, eating or engaging in other vital activities "
    },
    {
      "name": "V5928",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5929",
      "description": "Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles int nontraffic accident During unspecified activity"
    },
    {
      "name": "V593",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident"
    },
    {
      "name": "V5930",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V5931",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V5932",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V5933",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V5934",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5938",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V5939",
      "description": "Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V594",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V5940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V5943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V595",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V5950",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5951",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5952",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V5953",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5954",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5958",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5959",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V596",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V5960",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V5961",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5962",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V5963",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V5964",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5968",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5969",
      "description": "Unspecified occepant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V598",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents"
    },
    {
      "name": "V5980",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V5981",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V5982",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While working for income"
    },
    {
      "name": "V5983",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V5984",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5988",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V5989",
      "description": "Occupant [any] of pick-up truck or van injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V599",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident"
    },
    {
      "name": "V5990",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V5991",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V5992",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While working for income"
    },
    {
      "name": "V5993",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V5994",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V5998",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V5999",
      "description": "Occupant [any] of pick-up truck or van injured in  unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V600",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V6000",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6001",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6002",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6003",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6004",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6008",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6009",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V601",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6010",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6011",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6012",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6013",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6014",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6018",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6019",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V602",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6020",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6021",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6022",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6023",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6024",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6028",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident "
    },
    {
      "name": "V6029",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V603",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident"
    },
    {
      "name": "V6030",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V6031",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6032",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While working for income"
    },
    {
      "name": "V6033",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V6034",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6038",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6039",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V604",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting"
    },
    {
      "name": "V6040",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6041",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6042",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V6043",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6044",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6048",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6049",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V605",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident"
    },
    {
      "name": "V6050",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6051",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6052",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V6053",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6054",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6058",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6059",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V606",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident"
    },
    {
      "name": "V6060",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6061",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6062",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6063",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6064",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6068",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6069",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V607",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6070",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6071",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6072",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6073",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6074",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6078",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6079",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V609",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident"
    },
    {
      "name": "V6090",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6091",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6092",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While working for income"
    },
    {
      "name": "V6093",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6094",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6098",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6099",
      "description": "Occupant of heavy transport vehicle injured in collision with pedestrian or animal at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V610",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V6100",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6101",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6102",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6103",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6104",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6108",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6109",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V611",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6110",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6111",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6112",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6113",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6114",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6118",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6119",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V612",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6120",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6121",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6122",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6123",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6124",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6128",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6129",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V613",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident"
    },
    {
      "name": "V6130",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V6131",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6132",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While working for income"
    },
    {
      "name": "V6133",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V6134",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6138",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6139",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V614",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting"
    },
    {
      "name": "V6140",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6141",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6142",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V6143",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6144",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6148",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6149",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V615",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident"
    },
    {
      "name": "V6150",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6151",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6152",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V6153",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6154",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6158",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6159",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V616",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident"
    },
    {
      "name": "V6160",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6161",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6162",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6163",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6164",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6168",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6169",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V617",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6170",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6171",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6172",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6173",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6174",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6178",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6179",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V619",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident"
    },
    {
      "name": "V6190",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6191",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6192",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While working for income"
    },
    {
      "name": "V6193",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6194",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6198",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6199",
      "description": "Occupant of heavy transport vehicle injured in collision with pedal cycle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V620",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V6200",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6201",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6202",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6203",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6204",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6208",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6209",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V621",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6210",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6211",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6212",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6213",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6214",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6218",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6219",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V622",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6220",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6221",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6222",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6223",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6224",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6228",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6229",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V623",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontrafficr  accident"
    },
    {
      "name": "V6230",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V6231",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6232",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or  three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While working for income"
    },
    {
      "name": "V6233",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V6234",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6238",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6239",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V624",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting"
    },
    {
      "name": "V6240",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6241",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6242",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While working for income"
    },
    {
      "name": "V6243",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6244",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While resting, sleeping, eating or engaging in other vital activities While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6248",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6249",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at person injured while boarding or alighting During unspecified activity"
    },
    {
      "name": "V625",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident"
    },
    {
      "name": "V6250",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or  three-wheeled motor vehicle at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6251",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6252",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V6253",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6254",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6258",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6259",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V626",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident"
    },
    {
      "name": "V6260",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6261",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6262",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6263",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6264",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6268",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6269",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V627",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6270",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6271",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6272",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6273",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6274",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6278",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6279",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V629",
      "description": "Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident"
    },
    {
      "name": "V6290",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V6291",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in leisure activity"
    },
    {
      "name": "V6292",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While working for income"
    },
    {
      "name": "V6293",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in other types of work"
    },
    {
      "name": "V6294",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6298",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in other specified activities"
    },
    {
      "name": "V6299",
      "description": "Occupant of heavy transport vehicle injured in collision with two- ot three-wheeled motor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident During unspecified activity"
    },
    {
      "name": "V630",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V6300",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6301",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in leisure activity "
    },
    {
      "name": "V6302",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6303",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6304",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6308",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6309",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V631",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6310",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6311",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6312",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6313",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6314",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6318",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6319",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V632",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6320",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6321",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6322",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6323",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6324",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6328",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6329",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V633",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident"
    },
    {
      "name": "V6330",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in sports activity"
    },
    {
      "name": "V6331",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in leisure activity"
    },
    {
      "name": "V6332",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While working for income"
    },
    {
      "name": "V6333",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other types of work"
    },
    {
      "name": "V6334",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6338",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other specified activities"
    },
    {
      "name": "V6339",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V634",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting"
    },
    {
      "name": "V6340",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6341",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6342",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6343",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6344",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6348",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6349",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V635",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic accident"
    },
    {
      "name": "V6350",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6351",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6352",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V6353",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6354",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6358",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6359",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V636",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic  accident"
    },
    {
      "name": "V6360",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6361",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in leisure activity "
    },
    {
      "name": "V6362",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6363",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6364",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic  accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6368",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6369",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V637",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6370",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6371",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6372",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6373",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6374",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6378",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6379",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V639",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V6390",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6391",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6392",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While working for income"
    },
    {
      "name": "V6393",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6394",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6398",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6399",
      "description": "Occupant of heavy transport vehicle injured in collision with car, pick-up trunk or van at unspecified   occupant  of heavy transport vehicle  injured in traffice accident During unspecified activity"
    },
    {
      "name": "V640",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident"
    },
    {
      "name": "V6400",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6401",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6402",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6403",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6404",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6408",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6409",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V641",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6410",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6411",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6412",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6413",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6414",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6418",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6419",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V642",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6420",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6421",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6422",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6423",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6424",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6428",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6429",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V643",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident"
    },
    {
      "name": "V6430",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in sports activity"
    },
    {
      "name": "V6431",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in leisure activity"
    },
    {
      "name": "V6432",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While working for income"
    },
    {
      "name": "V6433",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other types of work"
    },
    {
      "name": "V6434",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6438",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other specified activities"
    },
    {
      "name": "V6439",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V644",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting"
    },
    {
      "name": "V6440",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6441",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6442",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6443",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6444",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6448",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6449",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V645",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic  accident "
    },
    {
      "name": "V6450",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6451",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6452",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V6453",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6454",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6458",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6459",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V646",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident"
    },
    {
      "name": "V6460",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6461",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6462",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6463",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6464",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6468",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6469",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V647",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6470",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6471",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6472",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6473",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6474",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6478",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6479",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V649",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V6490",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6491",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6492",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V6493",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6494",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6498",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6499",
      "description": "Occupant of heavy transport vehicle injured in collison with heavy transport vehicle or bus at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V650",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V6500",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6501",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6502",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6503",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6504",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6508",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6509",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V651",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6510",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6511",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6512",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6513",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6514",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6518",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6519",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V652",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6520",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6521",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6522",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6523",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6524",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6528",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6529",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V653",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident"
    },
    {
      "name": "V6530",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in sports activity"
    },
    {
      "name": "V6531",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in leisure activity"
    },
    {
      "name": "V6532",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While working for income"
    },
    {
      "name": "V6533",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other types of work"
    },
    {
      "name": "V6534",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6538",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident While engaged in other specified activities"
    },
    {
      "name": "V6539",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffice accident During unspecified activity"
    },
    {
      "name": "V654",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V6540",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6541",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6542",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6543",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6544",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6548",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6549",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V655",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident"
    },
    {
      "name": "V6550",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V6551",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While engaged in leisure activity"
    },
    {
      "name": "V6552",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While working for income"
    },
    {
      "name": "V6553",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While engaged in other types of work"
    },
    {
      "name": "V6554",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6558",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident While engaged in other specified activities"
    },
    {
      "name": "V6559",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at driver injured in traffice accident During unspecified activity"
    },
    {
      "name": "V656",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V6560",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6561",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6562",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V6563",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6564",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6568",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6569",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V657",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6570",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6571",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6572",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6573",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6574",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6578",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6579",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V659",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident"
    },
    {
      "name": "V6590",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in sports activity"
    },
    {
      "name": "V6591",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in leisure activity"
    },
    {
      "name": "V6592",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While working for income"
    },
    {
      "name": "V6593",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in other types of work"
    },
    {
      "name": "V6594",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6598",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident While engaged in other specified activities"
    },
    {
      "name": "V6599",
      "description": "Occupant of heavy transport vehicle injured in collison with railway vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffice accident During unspecified activity"
    },
    {
      "name": "V660",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V6600",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6601",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6602",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6603",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6604",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6608",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6609",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V661",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6610",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6611",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6612",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6613",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6614",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6618",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6619",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V662",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6620",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6621",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6622",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6623",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6624",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6628",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6629",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V663",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident"
    },
    {
      "name": "V6630",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6631",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6632",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While working for income"
    },
    {
      "name": "V6633",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V6634",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6638",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6639",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V664",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting"
    },
    {
      "name": "V6640",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6641",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6642",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6643",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6644",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6648",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting  While engaged in other specified activities"
    },
    {
      "name": "V6649",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V665",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V6650",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6651",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6652",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V6653",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6654",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6658",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6659",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V666",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V6660",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6661",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6662",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V6663",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6664",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6668",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6669",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V667",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6670",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6671",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6672",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6673",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6674",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6678",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6679",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V669",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V6690",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6691",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6692",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V6693",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6694",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6698",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6699",
      "description": "Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle at unspecified   occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V670",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident"
    },
    {
      "name": "V6700",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6701",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6702",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6703",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6704",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6708",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6709",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V671",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6710",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6711",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6712",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6713",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6714",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6718",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6719",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V672",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6720",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6721",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6722",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6723",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6724",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6728",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6729",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V673",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident"
    },
    {
      "name": "V6730",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6731",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6732",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While working for income"
    },
    {
      "name": "V6733",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6734",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6738",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6739",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V674",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting"
    },
    {
      "name": "V6740",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6741",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6742",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6743",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting  While engaged in other types of work"
    },
    {
      "name": "V6744",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6748",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6749",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V675",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic accident"
    },
    {
      "name": "V6750",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V6751",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6752",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V6753",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6754",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6758",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6759",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V676",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident"
    },
    {
      "name": "V6760",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6761",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6762",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V6763",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6764",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6768",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic  accident  While engaged in other specified activities"
    },
    {
      "name": "V6769",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V677",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6770",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6771",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6772",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6773",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6774",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6778",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6779",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V679",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V6790",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6791",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6792",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While working for income"
    },
    {
      "name": "V6793",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6794",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6798",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6799",
      "description": "Occupant of heavy transport vehicle injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V680",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V6800",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6801",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6802",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V6803",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6804",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6808",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6809",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V681",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V6810",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6811",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6812",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V6813",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6814",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6818",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6819",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V682",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V6820",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6821",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6822",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V6823",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6824",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6828",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6829",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V683",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic accident"
    },
    {
      "name": "V6830",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in sports activity"
    },
    {
      "name": "V6831",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in leisure activity"
    },
    {
      "name": "V6832",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While working for income"
    },
    {
      "name": "V6833",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other types of work"
    },
    {
      "name": "V6834",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6838",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6839",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified  occupant  of heavy transport vehicle  injured in nontraffic  accident During unspecified activity"
    },
    {
      "name": "V684",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting"
    },
    {
      "name": "V6840",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While engaged in sports activity"
    },
    {
      "name": "V6841",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While engaged in leisure activity"
    },
    {
      "name": "V6842",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While working for income"
    },
    {
      "name": "V6843",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While engaged in other types of work"
    },
    {
      "name": "V6844",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6848",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting While engaged in other specified activities"
    },
    {
      "name": "V6849",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at person injued while boarding or alighting During unspecified activity"
    },
    {
      "name": "V685",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic  accident"
    },
    {
      "name": "V6850",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6851",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6852",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While working for income"
    },
    {
      "name": "V6853",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6854",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6858",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6859",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V686",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic accident"
    },
    {
      "name": "V6860",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6861",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6862",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V6863",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6864",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities "
    },
    {
      "name": "V6868",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6869",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V687",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V6870",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6871",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6872",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V6873",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6874",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6878",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6879",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V689",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V6890",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6891",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6892",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V6893",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V6894",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6898",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V6899",
      "description": "Occupant of heavy transport vehicle injured in noncollison transport accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V690",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident"
    },
    {
      "name": "V6900",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6901",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6902",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V6903",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6904",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6908",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6909",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V691",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident"
    },
    {
      "name": "V6910",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6911",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6912",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V6913",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6914",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6918",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6919",
      "description": "Passenger ingured in collision with other and unsepcified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V692",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident"
    },
    {
      "name": "V6920",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6921",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6922",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V6923",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6924",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6928",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6929",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V693",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident"
    },
    {
      "name": "V6930",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V6931",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V6932",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V6933",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V6934",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6938",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V6939",
      "description": "Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V694",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident"
    },
    {
      "name": "V6940",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6941",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6942",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V6943",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6944",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities "
    },
    {
      "name": "V6948",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6949",
      "description": "Driver injured in collision with other and unsepcified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V695",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V6950",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V6951",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6952",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V6953",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V6954",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6958",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6959",
      "description": "Passenger  injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V696",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident"
    },
    {
      "name": "V6960",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in sports activity"
    },
    {
      "name": "V6961",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in leisure activity"
    },
    {
      "name": "V6962",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While working for income"
    },
    {
      "name": "V6963",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in other types of work"
    },
    {
      "name": "V6964",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6968",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in other specified activities"
    },
    {
      "name": "V6969",
      "description": "Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident  During unspecified activity"
    },
    {
      "name": "V698",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents"
    },
    {
      "name": "V6980",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V6981",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents  While engaged in leisure activity"
    },
    {
      "name": "V6982",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents While working for income"
    },
    {
      "name": "V6983",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents  While engaged in other types of work"
    },
    {
      "name": "V6984",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6988",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents  While engaged in other specified activities"
    },
    {
      "name": "V6989",
      "description": "Occupant [any] of heavy transport vehicle injured in other specified transport accidents  During unspecified activity"
    },
    {
      "name": "V699",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident"
    },
    {
      "name": "V6990",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V6991",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V6992",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While working for income"
    },
    {
      "name": "V6993",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V6994",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V6998",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V6999",
      "description": "Occupant [any] of heavy transport vehicle ingured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V700",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident"
    },
    {
      "name": "V7000",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7001",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7002",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V7003",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7004",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7008",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7009",
      "description": "Bus occupant injured in collision with pedestrian or animal at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V701",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7010",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7011",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7012",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7013",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7014",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7018",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7019",
      "description": "Bus occupant injured in collision with pedestrian or animal at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V702",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7020",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7021",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7022",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7023",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7024",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7028",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7029",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V703",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7030",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7031",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7032",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7033",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7034",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7038",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7039",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V704",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident"
    },
    {
      "name": "V7040",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7041",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7042",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While working for income"
    },
    {
      "name": "V7043",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7044",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7048",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7049",
      "description": "Bus occupant injured in collision with pedestrian or animal at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V705",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident"
    },
    {
      "name": "V7050",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7051",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7052",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7053",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7054",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7058",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7059",
      "description": "Bus occupant injured in collision with pedestrian or animal at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V706",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident"
    },
    {
      "name": "V7060",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7061",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7062",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7063",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7064",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7068",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7069",
      "description": "Bus occupant injured in collision with pedestrian or animal at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V707",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7070",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7071",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7072",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7073",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7074",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7078",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7079",
      "description": "Bus occupant injured in collision with pedestrian or animal at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V709",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7090",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7091",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7092",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7093",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7094",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7098",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7099",
      "description": "Bus occupant injured in collision with pedestrian or animal at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V710",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident"
    },
    {
      "name": "V7100",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7101",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7102",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V7103",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7104",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7108",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7109",
      "description": "Bus occupant injured in collision with pedal cycle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V711",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7110",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7111",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7112",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7113",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7114",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7118",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7119",
      "description": "Bus occupant injured in collision with pedal cycle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V712",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7120",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7121",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7122",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7123",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7124",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7128",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7129",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V713",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7130",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7131",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7132",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7133",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7134",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7138",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7139",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V714",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident"
    },
    {
      "name": "V7140",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7141",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7142",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While working for income"
    },
    {
      "name": "V7143",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7144",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7148",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7149",
      "description": "Bus occupant injured in collision with pedal cycle at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V715",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident"
    },
    {
      "name": "V7150",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7151",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7152",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7153",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7154",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7158",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7159",
      "description": "Bus occupant injured in collision with pedal cycle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V716",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident"
    },
    {
      "name": "V7160",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7161",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7162",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7163",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7164",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7168",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7169",
      "description": "Bus occupant injured in collision with pedal cycle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V717",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7170",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7171",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7172",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7173",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7174",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7178",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7179",
      "description": "Bus occupant injured in collision with pedal cycle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V719",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7190",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7191",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7192",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7193",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7194",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7198",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7199",
      "description": "Bus occupant injured in collision with pedal cycle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V720",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident"
    },
    {
      "name": "V7200",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7201",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7202",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V7203",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7204",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7208",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7209",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V721",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7210",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7211",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7212",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7213",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7214",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7218",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7219",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V722",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7220",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7221",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7222",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7223",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7224",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7228",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7229",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V723",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7230",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7231",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7232",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7233",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7234",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7238",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7239",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V724",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident"
    },
    {
      "name": "V7240",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7241",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7242",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While working for income"
    },
    {
      "name": "V7243",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7244",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7248",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7249",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V725",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident"
    },
    {
      "name": "V7250",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7251",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7252",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7253",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7254",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7258",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7259",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V726",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V7260",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7261",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7262",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7263",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7264",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7268",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7269",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V727",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7270",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7271",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7272",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7273",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7274",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7278",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7279",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V729",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7290",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7291",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7292",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7293",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7294",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7298",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7299",
      "description": "Bus occupant injured in collision with two- or three motor vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V730",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident"
    },
    {
      "name": "V7300",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7301",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7302",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V7303",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7304",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7308",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7309",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V731",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7310",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7311",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7312",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7313",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7314",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7318",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7319",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V732",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7320",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7321",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7322",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7323",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7324",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7328",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7329",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V733",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7330",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7331",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7332",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7333",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7334",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7338",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7339",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V734",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident"
    },
    {
      "name": "V7340",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7341",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7342",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While working for income"
    },
    {
      "name": "V7343",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7344",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7348",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7349",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V735",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident"
    },
    {
      "name": "V7350",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7351",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7352",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7353",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7354",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7358",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7359",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V736",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident"
    },
    {
      "name": "V7360",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7361",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7362",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7363",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7364",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7368",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7369",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V737",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7370",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7371",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7372",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7373",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7374",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7378",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7379",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V739",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7390",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7391",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7392",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7393",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7394",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7398",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7399",
      "description": "Bus occupant injured in collision with car, pick-up truck or van at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V740",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  "
    },
    {
      "name": "V7400",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V7401",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V7402",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While working for income"
    },
    {
      "name": "V7403",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V7404",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7408",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V7409",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Driver injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V741",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7410",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7411",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7412",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7413",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7414",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7418",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7419",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V742",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7420",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7421",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7422",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7423",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7424",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7428",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7429",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V743",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7430",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7431",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7432",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7433",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7434",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7438",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7439",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V744",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident"
    },
    {
      "name": "V7440",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7441",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7442",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While working for income"
    },
    {
      "name": "V7443",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7444",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7448",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7449",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V745",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident"
    },
    {
      "name": "V7450",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7451",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7452",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7453",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7454",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7458",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7459",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V746",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident"
    },
    {
      "name": "V7460",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7461",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7462",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7463",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7464",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7468",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7469",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V747",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7470",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7471",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7472",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7473",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7474",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7478",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7479",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V749",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7490",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7491",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7492",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7493",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7494",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7498",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7499",
      "description": "Bus occupant injured in collision with heavy transport vehical or bus at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V750",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident  "
    },
    {
      "name": "V7500",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7501",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7502",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7503",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7504",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7508",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7509",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Driver injured in nontraffic accident   During unspecified activity"
    },
    {
      "name": "V751",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7510",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7511",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7512",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7513",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7514",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7518",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7519",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Passenger injured in nontraffic accident   During unspecified activity"
    },
    {
      "name": "V752",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7520",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7521",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7522",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While working for income"
    },
    {
      "name": "V7523",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7524",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7528",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7529",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V753",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7530",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7531",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7532",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7533",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7534",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7538",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7539",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified  bus  occupant   injured in nontraffic accident   During unspecified activity"
    },
    {
      "name": "V754",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident"
    },
    {
      "name": "V7540",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7541",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7542",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While working for income"
    },
    {
      "name": "V7543",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7544",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7548",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7549",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at person injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V755",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident"
    },
    {
      "name": "V7550",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7551",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7552",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While working for income"
    },
    {
      "name": "V7553",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7554",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7558",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7559",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at driver injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V756",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident"
    },
    {
      "name": "V7560",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7561",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7562",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While working for income"
    },
    {
      "name": "V7563",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7564",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7568",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7569",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at passenger injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V757",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7570",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7571",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7572",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While working for income"
    },
    {
      "name": "V7573",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7574",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7578",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7579",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at Person on outside of vehicle injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V759",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7590",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7591",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7592",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While working for income"
    },
    {
      "name": "V7593",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7594",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7598",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7599",
      "description": "Bus occupant injured in collision with railway train or railway vehicle at unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   During unspecified activity"
    },
    {
      "name": "V760",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident  "
    },
    {
      "name": "V7600",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7601",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7602",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7603",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7604",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7608",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7609",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Driver injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V761",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7610",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7611",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V7612",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7613",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7614",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7618",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7619",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Passenger injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V762",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7620",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7621",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7622",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While working for income"
    },
    {
      "name": "V7623",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7624",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7628",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7629",
      "description": "Bus occupant injured in collision other nonmotor vehicle at Person on outside of vehicle injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V763",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7630",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in sports activity"
    },
    {
      "name": "V7631",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7632",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While working for income"
    },
    {
      "name": "V7633",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in other types of work"
    },
    {
      "name": "V7634",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7638",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7639",
      "description": "Bus occupant injured in collision other nonmotor vehicle at unspecified  bus  occupant   injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V764",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident"
    },
    {
      "name": "V7640",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7641",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7642",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While working for income"
    },
    {
      "name": "V7643",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7644",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7648",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7649",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  person injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V765",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident"
    },
    {
      "name": "V7650",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7651",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7652",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While working for income"
    },
    {
      "name": "V7653",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7654",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7658",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7659",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  driver injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V766",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident"
    },
    {
      "name": "V7660",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7661",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7662",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While working for income"
    },
    {
      "name": "V7663",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7664",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7668",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7669",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  passenger injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V767",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7670",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7671",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7672",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While working for income"
    },
    {
      "name": "V7673",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7674",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7678",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7679",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  Person on outside of vehicle injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V769",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7690",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in sports activity"
    },
    {
      "name": "V7691",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in leisure activity"
    },
    {
      "name": "V7692",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While working for income"
    },
    {
      "name": "V7693",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in other types of work"
    },
    {
      "name": "V7694",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7698",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident   While engaged in other specified activities"
    },
    {
      "name": "V7699",
      "description": "Bus occupant injured in collision other nonmotor vehicle at  unspecified bus occupant  of heavy transport vehicle  injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V770",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident "
    },
    {
      "name": "V7700",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While engaged in sports activity"
    },
    {
      "name": "V7701",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While engaged in leisure activity"
    },
    {
      "name": "V7702",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While working for income"
    },
    {
      "name": "V7703",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While engaged in other types of work"
    },
    {
      "name": "V7704",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7708",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident    While engaged in other specified activities"
    },
    {
      "name": "V7709",
      "description": "Bus occupant injured in collision with fixed or stationary object at Driver injured in nontraffic accident   During unspecified activity"
    },
    {
      "name": "V771",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7710",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7711",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7712",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident  While working for income"
    },
    {
      "name": "V7713",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident  While engaged in other types of work"
    },
    {
      "name": "V7714",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7718",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7719",
      "description": "Bus occupant injured in collision with fixed or stationary object at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V772",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7720",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7721",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7722",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7723",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7724",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7728",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7729",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V773",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7730",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7731",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7732",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7733",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7734",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7738",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7739",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V774",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident"
    },
    {
      "name": "V7740",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7741",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7742",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While working for income"
    },
    {
      "name": "V7743",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7744",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7748",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7749",
      "description": "Bus occupant injured in collision with fixed or stationary object at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V775",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident"
    },
    {
      "name": "V7750",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V7751",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V7752",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While working for income"
    },
    {
      "name": "V7753",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V7754",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7758",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V7759",
      "description": "Bus occupant injured in collision with fixed or stationary object at driver injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V776",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident"
    },
    {
      "name": "V7760",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V7761",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V7762",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While working for income"
    },
    {
      "name": "V7763",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V7764",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7768",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V7769",
      "description": "Bus occupant injured in collision with fixed or stationary object at passenger injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V777",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7770",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7771",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7772",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7773",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7774",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7778",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7779",
      "description": "Bus occupant injured in collision with fixed or stationary object at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V779",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident"
    },
    {
      "name": "V7790",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in sports activity"
    },
    {
      "name": "V7791",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in leisure activity"
    },
    {
      "name": "V7792",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While working for income"
    },
    {
      "name": "V7793",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other types of work"
    },
    {
      "name": "V7794",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7798",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident While engaged in other specified activities"
    },
    {
      "name": "V7799",
      "description": "Bus occupant injured in collision with fixed or stationary object at unspecified   occupant  of heavy transport vehicle  injured in traffic  accident During unspecified activity"
    },
    {
      "name": "V780",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident"
    },
    {
      "name": "V7800",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7801",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7802",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While working for income"
    },
    {
      "name": "V7803",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7804",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7808",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7809",
      "description": "Bus occupant injured in noncollision transport  accident at Driver injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V781",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident"
    },
    {
      "name": "V7810",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7811",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7812",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While working for income"
    },
    {
      "name": "V7813",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7814",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7818",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7819",
      "description": "Bus occupant injured in noncollision transport  accident at Passenger injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V782",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident"
    },
    {
      "name": "V7820",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7821",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7822",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V7823",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7824",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7828",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7829",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V783",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident"
    },
    {
      "name": "V7830",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7831",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7832",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While working for income"
    },
    {
      "name": "V7833",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7834",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7838",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7839",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified  bus  occupant   injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V784",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident"
    },
    {
      "name": "V7840",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7841",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7842",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While working for income"
    },
    {
      "name": "V7843",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7844",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7848",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7849",
      "description": "Bus occupant injured in noncollision transport  accident at person injured in traffic accident During unspecified activity"
    },
    {
      "name": "V785",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident"
    },
    {
      "name": "V7850",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7851",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7852",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While working for income"
    },
    {
      "name": "V7853",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7854",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7858",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7859",
      "description": "Bus occupant injured in noncollision transport  accident at driver injured in traffic accident During unspecified activity"
    },
    {
      "name": "V786",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident"
    },
    {
      "name": "V7860",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7861",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7862",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While working for income"
    },
    {
      "name": "V7863",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7864",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7868",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7869",
      "description": "Bus occupant injured in noncollision transport  accident at passenger injured in traffic accident During unspecified activity"
    },
    {
      "name": "V787",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident"
    },
    {
      "name": "V7870",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7871",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7872",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V7873",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7874",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7878",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7879",
      "description": "Bus occupant injured in noncollision transport  accident at Person on outside of vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V789",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident"
    },
    {
      "name": "V7890",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7891",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7892",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While working for income"
    },
    {
      "name": "V7893",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7894",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7898",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7899",
      "description": "Bus occupant injured in noncollision transport  accident at unspecified   occupant  of heavy transport vehicle  injured in traffic accident During unspecified activity"
    },
    {
      "name": "V790",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident "
    },
    {
      "name": "V7900",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7901",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7902",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V7903",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7904",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7908",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7909",
      "description": "Driver injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V791",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident "
    },
    {
      "name": "V7910",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7911",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident  While engaged in leisure activity"
    },
    {
      "name": "V7912",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V7913",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7914",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7918",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7919",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V792",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident "
    },
    {
      "name": "V7920",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7921",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7922",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While working for income"
    },
    {
      "name": "V7923",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7924",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7928",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7929",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident During unspecified activity"
    },
    {
      "name": "V793",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident "
    },
    {
      "name": "V7930",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V7931",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V7932",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident While working for income"
    },
    {
      "name": "V7933",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V7934",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7938",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V7939",
      "description": "Bus occupant [any]  injured in unspecified nontraffic accident During unspecified activity"
    },
    {
      "name": "V794",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident "
    },
    {
      "name": "V7940",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7941",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7942",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V7943",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7944",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7948",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7949",
      "description": "Driver injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V795",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident "
    },
    {
      "name": "V7950",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7951",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident  While engaged in leisure activity"
    },
    {
      "name": "V7952",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While working for income"
    },
    {
      "name": "V7953",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7954",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7958",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7959",
      "description": "Passenger injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V796",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident "
    },
    {
      "name": "V7960",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in sports activity"
    },
    {
      "name": "V7961",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V7962",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident  While working for income"
    },
    {
      "name": "V7963",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other types of work"
    },
    {
      "name": "V7964",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7968",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V7969",
      "description": "Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident During unspecified activity"
    },
    {
      "name": "V798",
      "description": "Bus occupant [any] injured in other specified transport accidents"
    },
    {
      "name": "V7980",
      "description": "Bus occupant [any] injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V7981",
      "description": "Bus occupant [any] injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V7982",
      "description": "Bus occupant [any] injured in other specified transport accidentsWhile working for income"
    },
    {
      "name": "V7983",
      "description": "Bus occupant [any] injured in other specified transport accidentsWhile engaged in other types of work"
    },
    {
      "name": "V7984",
      "description": "Bus occupant [any] injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7988",
      "description": "Bus occupant [any] injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V7989",
      "description": "Bus occupant [any] injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V799",
      "description": "Bus occupant [any] injured in unspecified traffic accidents"
    },
    {
      "name": "V7990",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While engaged in sports activity"
    },
    {
      "name": "V7991",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While engaged in leisure activity"
    },
    {
      "name": "V7992",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While working for income"
    },
    {
      "name": "V7993",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While engaged in other types of work"
    },
    {
      "name": "V7994",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V7998",
      "description": "Bus occupant [any] injured in unspecified traffic accidents While engaged in other specified activities"
    },
    {
      "name": "V7999",
      "description": "Bus occupant [any] injured in unspecified traffic accidents During unspecified activity"
    },
    {
      "name": "V800",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident"
    },
    {
      "name": "V8000",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident While engaged in sports activity"
    },
    {
      "name": "V8001",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident While engaged in leisure activity"
    },
    {
      "name": "V8002",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident  While working for income"
    },
    {
      "name": "V8003",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident While engaged in other types of work"
    },
    {
      "name": "V8004",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8008",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident While engaged in other specified activities"
    },
    {
      "name": "V8009",
      "description": "Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident During unspecified activity"
    },
    {
      "name": "V801",
      "description": "Rider or occupant injured in collision with pedestrian or animal"
    },
    {
      "name": "V8010",
      "description": "Rider or occupant injured in collision with pedestrian or animal While engaged in sports activity"
    },
    {
      "name": "V8011",
      "description": "Rider or occupant injured in collision with pedestrian or animal While engaged in leisure activity"
    },
    {
      "name": "V8012",
      "description": "Rider or occupant injured in collision with pedestrian or animal  While working for income"
    },
    {
      "name": "V8013",
      "description": "Rider or occupant injured in collision with pedestrian or animal While engaged in other types of work"
    },
    {
      "name": "V8014",
      "description": "Rider or occupant injured in collision with pedestrian or animal While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8018",
      "description": "Rider or occupant injured in collision with pedestrian or animal While engaged in other specified activities"
    },
    {
      "name": "V8019",
      "description": "Rider or occupant injured in collision with pedestrian or animal  During unspecified activity"
    },
    {
      "name": "V802",
      "description": "Rider or occupant injured in collision with pedal cycle"
    },
    {
      "name": "V8020",
      "description": "Rider or occupant injured in collision with pedal cycle While engaged in sports activity"
    },
    {
      "name": "V8021",
      "description": "Rider or occupant injured in collision with pedal cycle While engaged in leisure activity"
    },
    {
      "name": "V8022",
      "description": "Rider or occupant injured in collision with pedal cycle While working for income"
    },
    {
      "name": "V8023",
      "description": "Rider or occupant injured in collision with pedal cycle  While engaged in other types of work"
    },
    {
      "name": "V8024",
      "description": "Rider or occupant injured in collision with pedal cycle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8028",
      "description": "Rider or occupant injured in collision with pedal cycle While engaged in other specified activities"
    },
    {
      "name": "V8029",
      "description": "Rider or occupant injured in collision with pedal cycle  During unspecified activity"
    },
    {
      "name": "V803",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle"
    },
    {
      "name": "V8030",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While engaged in sports activity"
    },
    {
      "name": "V8031",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While engaged in leisure activity"
    },
    {
      "name": "V8032",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While working for income"
    },
    {
      "name": "V8033",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While engaged in other types of work"
    },
    {
      "name": "V8034",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8038",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle While engaged in other specified activities"
    },
    {
      "name": "V8039",
      "description": "Rider or occupant injured in collision with two- ot three wheeled motor vehicle During unspecified activity"
    },
    {
      "name": "V804",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus"
    },
    {
      "name": "V8040",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus While engaged in sports activity"
    },
    {
      "name": "V8041",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus While engaged in leisure activity"
    },
    {
      "name": "V8042",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or busWhile working for income"
    },
    {
      "name": "V8043",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or busWhile engaged in other types of work"
    },
    {
      "name": "V8044",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8048",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus While engaged in other specified activities"
    },
    {
      "name": "V8049",
      "description": "Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus During unspecified activity"
    },
    {
      "name": "V805",
      "description": "Rider or occupant injured in collision with other specified motor vehicle"
    },
    {
      "name": "V8050",
      "description": "Rider or occupant injured in collision with other specified motor vehicleWhile engaged in sports activity"
    },
    {
      "name": "V8051",
      "description": "Rider or occupant injured in collision with other specified motor vehicleWhile engaged in leisure activity"
    },
    {
      "name": "V8052",
      "description": "Rider or occupant injured in collision with other specified motor vehicleWhile working for income"
    },
    {
      "name": "V8053",
      "description": "Rider or occupant injured in collision with other specified motor vehicleWhile engaged in other types of work"
    },
    {
      "name": "V8054",
      "description": "Rider or occupant injured in collision with other specified motor vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8058",
      "description": "Rider or occupant injured in collision with other specified motor vehicle While engaged in other specified activities"
    },
    {
      "name": "V8059",
      "description": "Rider or occupant injured in collision with other specified motor vehicle During unspecified activity"
    },
    {
      "name": "V806",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle"
    },
    {
      "name": "V8060",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle While engaged in sports activity"
    },
    {
      "name": "V8061",
      "description": "Rider or occupant injured in collision with railway train or railway vehicleWhile engaged in leisure activity"
    },
    {
      "name": "V8062",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle While working for income"
    },
    {
      "name": "V8063",
      "description": "Rider or occupant injured in collision with railway train or railway vehicleWhile engaged in other types of work"
    },
    {
      "name": "V8064",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8068",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle While engaged in other specified activities"
    },
    {
      "name": "V8069",
      "description": "Rider or occupant injured in collision with railway train or railway vehicle During unspecified activity"
    },
    {
      "name": "V807",
      "description": "Rider or occupant injured in collision with other nonmotor vehicle"
    },
    {
      "name": "V8070",
      "description": "Rider or occupant injured in collision with other nonmotor vehicleWhile engaged in sports activity"
    },
    {
      "name": "V8071",
      "description": "Rider or occupant injured in collision with other nonmotor vehicleWhile engaged in leisure activity"
    },
    {
      "name": "V8072",
      "description": "Rider or occupant injured in collision with other nonmotor vehicleWhile working for income"
    },
    {
      "name": "V8073",
      "description": "Rider or occupant injured in collision with other nonmotor vehicle While engaged in other types of work"
    },
    {
      "name": "V8074",
      "description": "Rider or occupant injured in collision with other nonmotor vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8078",
      "description": "Rider or occupant injured in collision with other nonmotor vehicle While engaged in other specified activities"
    },
    {
      "name": "V8079",
      "description": "Rider or occupant injured in collision with other nonmotor vehicle During unspecified activity"
    },
    {
      "name": "V808",
      "description": "Rider or occupant injured in collision with fixed or stationary object"
    },
    {
      "name": "V8080",
      "description": "Rider or occupant injured in collision with fixed or stationary objectWhile engaged in sports activity"
    },
    {
      "name": "V8081",
      "description": "Rider or occupant injured in collision with fixed or stationary objectWhile engaged in leisure activity"
    },
    {
      "name": "V8082",
      "description": "Rider or occupant injured in collision with fixed or stationary object While working for income"
    },
    {
      "name": "V8083",
      "description": "Rider or occupant injured in collision with fixed or stationary objectWhile engaged in other types of work"
    },
    {
      "name": "V8084",
      "description": "Rider or occupant injured in collision with fixed or stationary object While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8088",
      "description": "Rider or occupant injured in collision with fixed or stationary objectWhile engaged in other specified activities"
    },
    {
      "name": "V8089",
      "description": "Rider or occupant injured in collision with fixed or stationary object During unspecified activity"
    },
    {
      "name": "V809",
      "description": "Rider or occupant injured in other and unspecified transport accidents"
    },
    {
      "name": "V8090",
      "description": "Rider or occupant injured in other and unspecified transport accidentsWhile engaged in sports activity"
    },
    {
      "name": "V8091",
      "description": "Rider or occupant injured in other and unspecified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V8092",
      "description": "Rider or occupant injured in other and unspecified transport accidentsWhile working for income"
    },
    {
      "name": "V8093",
      "description": "Rider or occupant injured in other and unspecified transport accidentsWhile engaged in other types of work"
    },
    {
      "name": "V8094",
      "description": "Rider or occupant injured in other and unspecified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8098",
      "description": "Rider or occupant injured in other and unspecified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V8099",
      "description": "Rider or occupant injured in other and unspecified transport accidents During unspecified activity"
    },
    {
      "name": "V810",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident"
    },
    {
      "name": "V8100",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8101",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8102",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident While working for income"
    },
    {
      "name": "V8103",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8104",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8108",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident  While engaged in other specified activities"
    },
    {
      "name": "V8109",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident During unspecified activity"
    },
    {
      "name": "V811",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident"
    },
    {
      "name": "V8110",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident  While engaged in sports activity"
    },
    {
      "name": "V8111",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8112",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident While working for income"
    },
    {
      "name": "V8113",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8114",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8118",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8119",
      "description": "Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident  During unspecified activity"
    },
    {
      "name": "V812",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock"
    },
    {
      "name": "V8120",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While engaged in sports activity"
    },
    {
      "name": "V8121",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While engaged in leisure activity"
    },
    {
      "name": "V8122",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While working for income"
    },
    {
      "name": "V8123",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While engaged in other types of work"
    },
    {
      "name": "V8124",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8128",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock While engaged in other specified activities"
    },
    {
      "name": "V8129",
      "description": "Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock During unspecified activity"
    },
    {
      "name": "V813",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object"
    },
    {
      "name": "V8130",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While engaged in sports activity"
    },
    {
      "name": "V8131",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While engaged in leisure activity"
    },
    {
      "name": "V8132",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While working for income"
    },
    {
      "name": "V8133",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While engaged in other types of work"
    },
    {
      "name": "V8134",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8138",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object While engaged in other specified activities"
    },
    {
      "name": "V8139",
      "description": "Occupant of railway train or railway vehicle injured in collision with other object During unspecified activity"
    },
    {
      "name": "V814",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle"
    },
    {
      "name": "V8140",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicleWhile engaged in sports activity"
    },
    {
      "name": "V8141",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle While engaged in leisure activity"
    },
    {
      "name": "V8142",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle While working for income"
    },
    {
      "name": "V8143",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle While engaged in other types of work"
    },
    {
      "name": "V8144",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8148",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle While engaged in other specified activities"
    },
    {
      "name": "V8149",
      "description": "Person injured while boarding or  alighting from railway train or railway vehicle During unspecified activity"
    },
    {
      "name": "V815",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle"
    },
    {
      "name": "V8150",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle While engaged in sports activity"
    },
    {
      "name": "V8151",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle  While engaged in leisure activity"
    },
    {
      "name": "V8152",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle While working for income"
    },
    {
      "name": "V8153",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle While engaged in other types of work"
    },
    {
      "name": "V8154",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8158",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle While engaged in other specified activities"
    },
    {
      "name": "V8159",
      "description": "Occupant of railway train or railway vehicle injureb by full in railway train or raiway vehicle  During unspecified activity"
    },
    {
      "name": "V816",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle"
    },
    {
      "name": "V8160",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While engaged in sports activity"
    },
    {
      "name": "V8161",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While engaged in leisure activity"
    },
    {
      "name": "V8162",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While working for income"
    },
    {
      "name": "V8163",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While engaged in other types of work"
    },
    {
      "name": "V8164",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8168",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle While engaged in other specified activities"
    },
    {
      "name": "V8169",
      "description": "Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle During unspecified activity"
    },
    {
      "name": "V817",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision"
    },
    {
      "name": "V8170",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While engaged in sports activity"
    },
    {
      "name": "V8171",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While engaged in leisure activity"
    },
    {
      "name": "V8172",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While working for income"
    },
    {
      "name": "V8173",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While engaged in other types of work"
    },
    {
      "name": "V8174",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8178",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision While engaged in other specified activities"
    },
    {
      "name": "V8179",
      "description": "Occupant of railway train or railway vehicle injured in derailment without antecedent collision During unspecified activity"
    },
    {
      "name": "V818",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents"
    },
    {
      "name": "V8180",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While engaged in sports activity"
    },
    {
      "name": "V8181",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While engaged in leisure activity"
    },
    {
      "name": "V8182",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While working for income"
    },
    {
      "name": "V8183",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While engaged in other types of work"
    },
    {
      "name": "V8184",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8188",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents While engaged in other specified activities"
    },
    {
      "name": "V8189",
      "description": "Occupant of railway train or railway vehicle injured in other specified railway accidents During unspecified activity"
    },
    {
      "name": "V819",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident"
    },
    {
      "name": "V8190",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While engaged in sports activity"
    },
    {
      "name": "V8191",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While engaged in leisure activity"
    },
    {
      "name": "V8192",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While working for income"
    },
    {
      "name": "V8193",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While engaged in other types of work"
    },
    {
      "name": "V8194",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8198",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident While engaged in other specified activities"
    },
    {
      "name": "V8199",
      "description": "Occupant of railway train or railway vehicle injured in unspecified railway accident During unspecified activity"
    },
    {
      "name": "V820",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident"
    },
    {
      "name": "V8200",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8201",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8202",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident  While working for income"
    },
    {
      "name": "V8203",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8204",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8208",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8209",
      "description": "Occupant of streetcar injured in collision with motor vehicle in nontraffic accident During unspecified activity"
    },
    {
      "name": "V821",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident"
    },
    {
      "name": "V8210",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8211",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8212",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While working for income"
    },
    {
      "name": "V8213",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8214",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8218",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8219",
      "description": "Occupant of streetcar injured in collision with motor vehicle in traffic accident During unspecified activity"
    },
    {
      "name": "V822",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock"
    },
    {
      "name": "V8220",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock While engaged in sports activity"
    },
    {
      "name": "V8221",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock While engaged in leisure activity"
    },
    {
      "name": "V8222",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock  While working for income"
    },
    {
      "name": "V8223",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock While engaged in other types of work"
    },
    {
      "name": "V8224",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8228",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock While engaged in other specified activities"
    },
    {
      "name": "V8229",
      "description": "Occupant of streetcar injured in collision with or hit by rolling stock During unspecified activity"
    },
    {
      "name": "V823",
      "description": "Occupant of streetcar injured in collision with other object"
    },
    {
      "name": "V8230",
      "description": "Occupant of streetcar injured in collision with other object While engaged in sports activity"
    },
    {
      "name": "V8231",
      "description": "Occupant of streetcar injured in collision with other object While engaged in leisure activity"
    },
    {
      "name": "V8232",
      "description": "Occupant of streetcar injured in collision with other object While working for income"
    },
    {
      "name": "V8233",
      "description": "Occupant of streetcar injured in collision with other object While engaged in other types of work"
    },
    {
      "name": "V8234",
      "description": "Occupant of streetcar injured in collision with other object  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8238",
      "description": "Occupant of streetcar injured in collision with other object While engaged in other specified activities"
    },
    {
      "name": "V8239",
      "description": "Occupant of streetcar injured in collision with other object  During unspecified activity"
    },
    {
      "name": "V824",
      "description": "Person injured while boarding or alighting form streetcar"
    },
    {
      "name": "V8240",
      "description": "Person injured while boarding or alighting form streetcar While engaged in sports activity"
    },
    {
      "name": "V8241",
      "description": "Person injured while boarding or alighting form streetcar While engaged in leisure activity"
    },
    {
      "name": "V8242",
      "description": "Person injured while boarding or alighting form streetcar While working for income"
    },
    {
      "name": "V8243",
      "description": "Person injured while boarding or alighting form streetcar While engaged in other types of work"
    },
    {
      "name": "V8244",
      "description": "Person injured while boarding or alighting form streetcar While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8248",
      "description": "Person injured while boarding or alighting form streetcar While engaged in other specified activities"
    },
    {
      "name": "V8249",
      "description": "Person injured while boarding or alighting form streetcar During unspecified activity"
    },
    {
      "name": "V825",
      "description": "Occupant of streetcar injured by fall in streetcar"
    },
    {
      "name": "V8250",
      "description": "Occupant of streetcar injured by fall in streetcar While engaged in sports activity"
    },
    {
      "name": "V8251",
      "description": "Occupant of streetcar injured by fall in streetcar While engaged in leisure activity"
    },
    {
      "name": "V8252",
      "description": "Occupant of streetcar injured by fall in streetcar While working for income"
    },
    {
      "name": "V8253",
      "description": "Occupant of streetcar injured by fall in streetcar While engaged in other types of work"
    },
    {
      "name": "V8254",
      "description": "Occupant of streetcar injured by fall in streetcar While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8258",
      "description": "Occupant of streetcar injured by fall in streetcar  While engaged in other specified activities"
    },
    {
      "name": "V8259",
      "description": "Occupant of streetcar injured by fall in streetcar During unspecified activity"
    },
    {
      "name": "V826",
      "description": "Occupant of streetcar injured by fall from streetcar"
    },
    {
      "name": "V8260",
      "description": "Occupant of streetcar injured by fall from streetcar While engaged in sports activity"
    },
    {
      "name": "V8261",
      "description": "Occupant of streetcar injured by fall from streetcar While engaged in leisure activity"
    },
    {
      "name": "V8262",
      "description": "Occupant of streetcar injured by fall from streetcar While working for income"
    },
    {
      "name": "V8263",
      "description": "Occupant of streetcar injured by fall from streetcar While engaged in other types of work"
    },
    {
      "name": "V8264",
      "description": "Occupant of streetcar injured by fall from streetcar While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8268",
      "description": "Occupant of streetcar injured by fall from streetcar While engaged in other specified activities"
    },
    {
      "name": "V8269",
      "description": "Occupant of streetcar injured by fall from streetcar During unspecified activity"
    },
    {
      "name": "V827",
      "description": "Occupant of streetcar injured in derailment without antecedent collision"
    },
    {
      "name": "V8270",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While engaged in sports activity"
    },
    {
      "name": "V8271",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While engaged in leisure activity"
    },
    {
      "name": "V8272",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While working for income"
    },
    {
      "name": "V8273",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While engaged in other types of work"
    },
    {
      "name": "V8274",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8278",
      "description": "Occupant of streetcar injured in derailment without antecedent collision While engaged in other specified activities"
    },
    {
      "name": "V8279",
      "description": "Occupant of streetcar injured in derailment without antecedent collision During unspecified activity"
    },
    {
      "name": "V828",
      "description": "Occupant of streetcar injured in other specified transport accidents"
    },
    {
      "name": "V8280",
      "description": "Occupant of streetcar injured in other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V8281",
      "description": "Occupant of streetcar injured in other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V8282",
      "description": "Occupant of streetcar injured in other specified transport accidents While working for income"
    },
    {
      "name": "V8283",
      "description": "Occupant of streetcar injured in other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V8284",
      "description": "Occupant of streetcar injured in other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8288",
      "description": "Occupant of streetcar injured in other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V8289",
      "description": "Occupant of streetcar injured in other specified transport accidents During unspecified activity"
    },
    {
      "name": "V829",
      "description": "Occupant of streetcar injured in unspecified traffic accident"
    },
    {
      "name": "V8290",
      "description": "Occupant of streetcar injured in unspecified traffic accident While engaged in sports activity"
    },
    {
      "name": "V8291",
      "description": "Occupant of streetcar injured in unspecified traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8292",
      "description": "Occupant of streetcar injured in unspecified traffic accident While working for income"
    },
    {
      "name": "V8293",
      "description": "Occupant of streetcar injured in unspecified traffic accident While engaged in other types of work"
    },
    {
      "name": "V8294",
      "description": "Occupant of streetcar injured in unspecified traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8298",
      "description": "Occupant of streetcar injured in unspecified traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8299",
      "description": "Occupant of streetcar injured in unspecified traffic accident During unspecified activity"
    },
    {
      "name": "V830",
      "description": "Driver of special industrial vehicle injured in traffic accident"
    },
    {
      "name": "V8300",
      "description": "Driver of special industrial vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8301",
      "description": "Driver of special industrial vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8302",
      "description": "Driver of special industrial vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8303",
      "description": "Driver of special industrial vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8304",
      "description": "Driver of special industrial vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8308",
      "description": "Driver of special industrial vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8309",
      "description": "Driver of special industrial vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V831",
      "description": "Passenger of  special industrial vehicle injured in traffic accident"
    },
    {
      "name": "V8310",
      "description": "Passenger of  special industrial vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8311",
      "description": "Passenger of  special industrial vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8312",
      "description": "Passenger of  special industrial vehicle injured in traffic accident  While working for income"
    },
    {
      "name": "V8313",
      "description": "Passenger of  special industrial vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8314",
      "description": "Passenger of  special industrial vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8318",
      "description": "Passenger of  special industrial vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8319",
      "description": "Passenger of  special industrial vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V832",
      "description": "Person on outside of special industrial vehicle injured in traffic accident"
    },
    {
      "name": "V8320",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8321",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8322",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8323",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8324",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8328",
      "description": "Person on outside of special industrial vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8329",
      "description": "Person on outside of special industrial vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V833",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident"
    },
    {
      "name": "V8330",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident  While engaged in sports activity"
    },
    {
      "name": "V8331",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident While engaged in leisure activity"
    },
    {
      "name": "V8332",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident While working for income"
    },
    {
      "name": "V8333",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident While engaged in other types of work"
    },
    {
      "name": "V8334",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8338",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident While engaged in other specified activities"
    },
    {
      "name": "V8339",
      "description": "Unspecified occupant of special industrial vehicle injured in transport accident During unspecified activity"
    },
    {
      "name": "V834",
      "description": "Person injured while boarding or alighting from specical industrial vehicle"
    },
    {
      "name": "V8340",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While engaged in sports activity"
    },
    {
      "name": "V8341",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While engaged in leisure activity"
    },
    {
      "name": "V8342",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While working for income"
    },
    {
      "name": "V8343",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While engaged in other types of work"
    },
    {
      "name": "V8344",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8348",
      "description": "Person injured while boarding or alighting from specical industrial vehicle While engaged in other specified activities"
    },
    {
      "name": "V8349",
      "description": "Person injured while boarding or alighting from specical industrial vehicle During unspecified activity"
    },
    {
      "name": "V835",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident"
    },
    {
      "name": "V8350",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8351",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8352",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8353",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8354",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8358",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8359",
      "description": "Driver of specical industrial vehicle injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V836",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident"
    },
    {
      "name": "V8360",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8361",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8362",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8363",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8364",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8368",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8369",
      "description": "Passenger of  special industrial vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V837",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident"
    },
    {
      "name": "V8370",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8371",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8372",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8373",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8374",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8378",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8379",
      "description": "Person on outside of special industrial vehicle injured in nontraffic accident  During unspecified activity"
    },
    {
      "name": "V839",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident"
    },
    {
      "name": "V8390",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8391",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8392",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8393",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8394",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8398",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8399",
      "description": "Unspecified occupant of special industrial vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V840",
      "description": "Driver of  special agricultural vehicle injured in traffic accident"
    },
    {
      "name": "V8400",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8401",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8402",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8403",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8404",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8408",
      "description": "Driver of  special agricultural vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8409",
      "description": "Driver of  special agricultural vehicle injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V841",
      "description": "Passenger of special africultural vehicle injured in traffic accident"
    },
    {
      "name": "V8410",
      "description": "Passenger of special africultural vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8411",
      "description": "Passenger of special africultural vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8412",
      "description": "Passenger of special africultural vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8413",
      "description": "Passenger of special africultural vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8414",
      "description": "Passenger of special africultural vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8418",
      "description": "Passenger of special africultural vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8419",
      "description": "Passenger of special africultural vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V842",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident"
    },
    {
      "name": "V8420",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8421",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8422",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8423",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8424",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8428",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8429",
      "description": "Person on outside of specia agricultural vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V843",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident"
    },
    {
      "name": "V8430",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8431",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident  While engaged in leisure activity"
    },
    {
      "name": "V8432",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8433",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8434",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8438",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8439",
      "description": "Unspecified occupant of special agricultural vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V844",
      "description": "Person injured while boarding or alighting from special agricultural vehicle"
    },
    {
      "name": "V8440",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While engaged in sports activity"
    },
    {
      "name": "V8441",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While engaged in leisure activity"
    },
    {
      "name": "V8442",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While working for income"
    },
    {
      "name": "V8443",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While engaged in other types of work"
    },
    {
      "name": "V8444",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8448",
      "description": "Person injured while boarding or alighting from special agricultural vehicle While engaged in other specified activities"
    },
    {
      "name": "V8449",
      "description": "Person injured while boarding or alighting from special agricultural vehicle During unspecified activity"
    },
    {
      "name": "V845",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident"
    },
    {
      "name": "V8450",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8451",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8452",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8453",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8454",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8458",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8459",
      "description": "Driver of special agricultural vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V846",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident"
    },
    {
      "name": "V8460",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8461",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8462",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8463",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8464",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8468",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8469",
      "description": "Passenger of special agricultural vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V847",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident"
    },
    {
      "name": "V8470",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident  While engaged in sports activity"
    },
    {
      "name": "V8471",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8472",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8473",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8474",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8478",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8479",
      "description": "Person on outside of special agricultural vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V849",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident"
    },
    {
      "name": "V8490",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8491",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8492",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8493",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8494",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8498",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8499",
      "description": "Unspecified occupant of special agricultulral vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V850",
      "description": "Driver of special construction vehicle injured in traffic accident"
    },
    {
      "name": "V8500",
      "description": "Driver of special construction vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8501",
      "description": "Driver of special construction vehicle injured in traffic accident  While engaged in leisure activity"
    },
    {
      "name": "V8502",
      "description": "Driver of special construction vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8503",
      "description": "Driver of special construction vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8504",
      "description": "Driver of special construction vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8508",
      "description": "Driver of special construction vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8509",
      "description": "Driver of special construction vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V851",
      "description": "Passenger of special construction vehicle injured in traffic accident"
    },
    {
      "name": "V8510",
      "description": "Passenger of special construction vehicle injured in traffic accident  While engaged in sports activity"
    },
    {
      "name": "V8511",
      "description": "Passenger of special construction vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8512",
      "description": "Passenger of special construction vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8513",
      "description": "Passenger of special construction vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8514",
      "description": "Passenger of special construction vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8518",
      "description": "Passenger of special construction vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8519",
      "description": "Passenger of special construction vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V852",
      "description": "Person on outside of special construction vehicle injured in traffic accident"
    },
    {
      "name": "V8520",
      "description": "Person on outside of special construction vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8521",
      "description": "Person on outside of special construction vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8522",
      "description": "Person on outside of special construction vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8523",
      "description": "Person on outside of special construction vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8524",
      "description": "Person on outside of special construction vehicle injured in traffic accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8528",
      "description": "Person on outside of special construction vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8529",
      "description": "Person on outside of special construction vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V853",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident"
    },
    {
      "name": "V8530",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8531",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8532",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8533",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8534",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8538",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8539",
      "description": "Unspecified occupant of special construction vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V854",
      "description": "Peron injured while boarding or alighting from special construction vehicle"
    },
    {
      "name": "V8540",
      "description": "Peron injured while boarding or alighting from special construction vehicle While engaged in sports activity"
    },
    {
      "name": "V8541",
      "description": "Peron injured while boarding or alighting from special construction vehicle While engaged in leisure activity"
    },
    {
      "name": "V8542",
      "description": "Peron injured while boarding or alighting from special construction vehicle While working for income"
    },
    {
      "name": "V8543",
      "description": "Peron injured while boarding or alighting from special construction vehicle While engaged in other types of work"
    },
    {
      "name": "V8544",
      "description": "Peron injured while boarding or alighting from special construction vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8548",
      "description": "Peron injured while boarding or alighting from special construction vehicle While engaged in other specified activities"
    },
    {
      "name": "V8549",
      "description": "Peron injured while boarding or alighting from special construction vehicle During unspecified activity"
    },
    {
      "name": "V855",
      "description": "Driver of special construction vehicle injured in nontraffic accident"
    },
    {
      "name": "V8550",
      "description": "Driver of special construction vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8551",
      "description": "Driver of special construction vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8552",
      "description": "Driver of special construction vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8553",
      "description": "Driver of special construction vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8554",
      "description": "Driver of special construction vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8558",
      "description": "Driver of special construction vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8559",
      "description": "Driver of special construction vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V856",
      "description": "Passenger of special construction vehicle injured in nontraffic accident"
    },
    {
      "name": "V8560",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8561",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8562",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8563",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8564",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8568",
      "description": "Passenger of special construction vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8569",
      "description": "Passenger of special construction vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V857",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident"
    },
    {
      "name": "V8570",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8571",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8572",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8573",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8574",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8578",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8579",
      "description": "Person on outside of special construction vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V859",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident"
    },
    {
      "name": "V8590",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8591",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8592",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8593",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8594",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8598",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8599",
      "description": "Unspecified occupant of speical construction vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V860",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident"
    },
    {
      "name": "V8600",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8601",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8602",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8603",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8604",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8608",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8609",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in traffic accident  During unspecified activity"
    },
    {
      "name": "V861",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident"
    },
    {
      "name": "V8610",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8611",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8612",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8613",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8614",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8618",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8619",
      "description": "Passenger of all-terrain or other off-road moter vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V862",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident"
    },
    {
      "name": "V8620",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8621",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8622",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8623",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8624",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8628",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8629",
      "description": "Person on outside of all-terrain or other off-road moter vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V863",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident"
    },
    {
      "name": "V8630",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in sports activity"
    },
    {
      "name": "V8631",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in leisure activity"
    },
    {
      "name": "V8632",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While working for income"
    },
    {
      "name": "V8633",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other types of work"
    },
    {
      "name": "V8634",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8638",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident While engaged in other specified activities"
    },
    {
      "name": "V8639",
      "description": "Unspecified occupant of all-terrain or other off-road moter vehicle injured in traffic accident During unspecified activity"
    },
    {
      "name": "V864",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle"
    },
    {
      "name": "V8640",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While engaged in sports activity"
    },
    {
      "name": "V8641",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While engaged in leisure activity"
    },
    {
      "name": "V8642",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While working for income"
    },
    {
      "name": "V8643",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While engaged in other types of work"
    },
    {
      "name": "V8644",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8648",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle While engaged in other specified activities"
    },
    {
      "name": "V8649",
      "description": "Person injured while boarding or alighting from all-terrain or other off-road motor vehicle During unspecified activity"
    },
    {
      "name": "V865",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V8650",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8651",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8652",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8653",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8654",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8658",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8659",
      "description": "Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V866",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V8660",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8661",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8662",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident  While working for income"
    },
    {
      "name": "V8663",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8664",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8668",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8669",
      "description": "Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V867",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V8670",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8671",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8672",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8673",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8674",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8678",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8679",
      "description": "Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V869",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident"
    },
    {
      "name": "V8690",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in sports activity"
    },
    {
      "name": "V8691",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in leisure activity"
    },
    {
      "name": "V8692",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While working for income"
    },
    {
      "name": "V8693",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other types of work"
    },
    {
      "name": "V8694",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8698",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident While engaged in other specified activities"
    },
    {
      "name": "V8699",
      "description": "Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident During unspecified activity"
    },
    {
      "name": "V870",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic)"
    },
    {
      "name": "V8700",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8701",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8702",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While working for income"
    },
    {
      "name": "V8703",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8704",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8708",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8709",
      "description": "Person injured in collision between car and two- or three- wheeled motor vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V871",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic)"
    },
    {
      "name": "V8710",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8711",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8712",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While working for income"
    },
    {
      "name": "V8713",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8714",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8718",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8719",
      "description": "Person injured in collision between other motor vehicle and two-or three-wheeled motor vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V872",
      "description": "Person injured in collision between car and pick-up truck or van (traffic)"
    },
    {
      "name": "V8720",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While engaged in sports activity"
    },
    {
      "name": "V8721",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8722",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While working for income"
    },
    {
      "name": "V8723",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While engaged in other types of work"
    },
    {
      "name": "V8724",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8728",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8729",
      "description": "Person injured in collision between car and pick-up truck or van (traffic) During unspecified activity"
    },
    {
      "name": "V873",
      "description": "Person injured in collision between car and bus (traffic)"
    },
    {
      "name": "V8730",
      "description": "Person injured in collision between car and bus (traffic) While engaged in sports activity"
    },
    {
      "name": "V8731",
      "description": "Person injured in collision between car and bus (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8732",
      "description": "Person injured in collision between car and bus (traffic) While working for income"
    },
    {
      "name": "V8733",
      "description": "Person injured in collision between car and bus (traffic) While engaged in other types of work"
    },
    {
      "name": "V8734",
      "description": "Person injured in collision between car and bus (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8738",
      "description": "Person injured in collision between car and bus (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8739",
      "description": "Person injured in collision between car and bus (traffic) During unspecified activity"
    },
    {
      "name": "V874",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic)"
    },
    {
      "name": "V8740",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8741",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8742",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While working for income"
    },
    {
      "name": "V8743",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8744",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8748",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8749",
      "description": "Person injured in collision between car and heavy transport vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V875",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic)"
    },
    {
      "name": "V8750",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) While engaged in sports activity"
    },
    {
      "name": "V8751",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8752",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic)  While working for income"
    },
    {
      "name": "V8753",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) While engaged in other types of work"
    },
    {
      "name": "V8754",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8758",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8759",
      "description": "Person injured in collision between car and heavy transport vehicle and bus (traffic) During unspecified activity"
    },
    {
      "name": "V876",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic)"
    },
    {
      "name": "V8760",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) While engaged in sports activity"
    },
    {
      "name": "V8761",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8762",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic)  While working for income"
    },
    {
      "name": "V8763",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) While engaged in other types of work"
    },
    {
      "name": "V8764",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8768",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8769",
      "description": "Person injured in collision between railway train or railway vehicle and car (traffic) During unspecified activity"
    },
    {
      "name": "V877",
      "description": "Person injured in collision between other  specified motor vehicle (traffic)"
    },
    {
      "name": "V8770",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8771",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8772",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While working for income"
    },
    {
      "name": "V8773",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8774",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8778",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8779",
      "description": "Person injured in collision between other  specified motor vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V878",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic)"
    },
    {
      "name": "V8780",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8781",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8782",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While working for income"
    },
    {
      "name": "V8783",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8784",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8788",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8789",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V879",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic)"
    },
    {
      "name": "V8790",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While engaged in sports activity"
    },
    {
      "name": "V8791",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While engaged in leisure activity"
    },
    {
      "name": "V8792",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While working for income"
    },
    {
      "name": "V8793",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While engaged in other types of work"
    },
    {
      "name": "V8794",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8798",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) While engaged in other specified activities"
    },
    {
      "name": "V8799",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic) During unspecified activity"
    },
    {
      "name": "V880",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic"
    },
    {
      "name": "V8800",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8801",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8802",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While working for income"
    },
    {
      "name": "V8803",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8804",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8808",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8809",
      "description": "Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic During unspecified activity"
    },
    {
      "name": "V881",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic"
    },
    {
      "name": "V8810",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8811",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8812",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While working for income"
    },
    {
      "name": "V8813",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8814",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8818",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8819",
      "description": "Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic During unspecified activity"
    },
    {
      "name": "V882",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic"
    },
    {
      "name": "V8820",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic While engaged in sports activity"
    },
    {
      "name": "V8821",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic  While engaged in leisure activity"
    },
    {
      "name": "V8822",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic While working for income"
    },
    {
      "name": "V8823",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic While engaged in other types of work"
    },
    {
      "name": "V8824",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8828",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8829",
      "description": "Person injured in collision between car and pick-up trunk or van,  nontraffic During unspecified activity"
    },
    {
      "name": "V883",
      "description": "Person injured in collision between car and bus, nontraffic"
    },
    {
      "name": "V8830",
      "description": "Person injured in collision between car and bus, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8831",
      "description": "Person injured in collision between car and bus, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8832",
      "description": "Person injured in collision between car and bus, nontraffic While working for income"
    },
    {
      "name": "V8833",
      "description": "Person injured in collision between car and bus, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8834",
      "description": "Person injured in collision between car and bus, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8838",
      "description": "Person injured in collision between car and bus, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8839",
      "description": "Person injured in collision between car and bus, nontraffic During unspecified activity"
    },
    {
      "name": "V884",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic"
    },
    {
      "name": "V8840",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8841",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8842",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While working for income"
    },
    {
      "name": "V8843",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8844",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8848",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8849",
      "description": "Person injured in collision between car and heavy transport vehicle, nontraffic During unspecified activity"
    },
    {
      "name": "V885",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic"
    },
    {
      "name": "V8850",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8851",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8852",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While working for income"
    },
    {
      "name": "V8853",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8854",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8858",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8859",
      "description": "Person injured in collision between heavy transport vehicle and bus, nontraffic During unspecified activity"
    },
    {
      "name": "V886",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic"
    },
    {
      "name": "V8860",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8861",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8862",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic  While working for income"
    },
    {
      "name": "V8863",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8864",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8868",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8869",
      "description": "Person injured in collision between railway train or railway vehicle and car, nontraffic During unspecified activity"
    },
    {
      "name": "V887",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic"
    },
    {
      "name": "V8870",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8871",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8872",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While working for income"
    },
    {
      "name": "V8873",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8874",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8878",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8879",
      "description": "Person injured in collision between other specified motor vehicles, nontraffic During unspecified activity"
    },
    {
      "name": "V888",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic"
    },
    {
      "name": "V8880",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8881",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8882",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While working for income"
    },
    {
      "name": "V8883",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8884",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8888",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8889",
      "description": "Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic During unspecified activity"
    },
    {
      "name": "V889",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic"
    },
    {
      "name": "V8890",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8891",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8892",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While working for income"
    },
    {
      "name": "V8893",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8894",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8898",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8899",
      "description": "Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic During unspecified activity"
    },
    {
      "name": "V890",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic"
    },
    {
      "name": "V8900",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8901",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8902",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic  While working for income"
    },
    {
      "name": "V8903",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8904",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8908",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8909",
      "description": "Person injured in unspecified motor-vehicle accident, nontraffic During unspecified activity"
    },
    {
      "name": "V891",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic"
    },
    {
      "name": "V8910",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While engaged in sports activity"
    },
    {
      "name": "V8911",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While engaged in leisure activity"
    },
    {
      "name": "V8912",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While working for income"
    },
    {
      "name": "V8913",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While engaged in other types of work"
    },
    {
      "name": "V8914",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8918",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic While engaged in other specified activities"
    },
    {
      "name": "V8919",
      "description": "Person injured in unspecified nonmotor-vehicle accident, nontraffic During unspecified activity"
    },
    {
      "name": "V892",
      "description": "Person injured in unspecified motor-vehicle accident, traffic"
    },
    {
      "name": "V8920",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While engaged in sports activity"
    },
    {
      "name": "V8921",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While engaged in leisure activity"
    },
    {
      "name": "V8922",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While working for income"
    },
    {
      "name": "V8923",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While engaged in other types of work"
    },
    {
      "name": "V8924",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8928",
      "description": "Person injured in unspecified motor-vehicle accident, traffic While engaged in other specified activities"
    },
    {
      "name": "V8929",
      "description": "Person injured in unspecified motor-vehicle accident, traffic During unspecified activity"
    },
    {
      "name": "V893",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic"
    },
    {
      "name": "V8930",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While engaged in sports activity"
    },
    {
      "name": "V8931",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While engaged in leisure activity"
    },
    {
      "name": "V8932",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While working for income"
    },
    {
      "name": "V8933",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While engaged in other types of work"
    },
    {
      "name": "V8934",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8938",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic While engaged in other specified activities"
    },
    {
      "name": "V8939",
      "description": "Person injured in unspecified nonmotor-vehicle accident, traffic During unspecified activity"
    },
    {
      "name": "V899",
      "description": "Person injured in unspecified motor-vehicle accident"
    },
    {
      "name": "V8990",
      "description": "Person injured in unspecified motor-vehicle accident While engaged in sports activity"
    },
    {
      "name": "V8991",
      "description": "Person injured in unspecified motor-vehicle accident While engaged in leisure activity"
    },
    {
      "name": "V8992",
      "description": "Person injured in unspecified motor-vehicle accident While working for income"
    },
    {
      "name": "V8993",
      "description": "Person injured in unspecified motor-vehicle accident  While engaged in other types of work"
    },
    {
      "name": "V8994",
      "description": "Person injured in unspecified motor-vehicle accident  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V8998",
      "description": "Person injured in unspecified motor-vehicle accident While engaged in other specified activities"
    },
    {
      "name": "V8999",
      "description": "Person injured in unspecified motor-vehicle accident During unspecified activity"
    },
    {
      "name": "V900",
      "description": "Accident to watercraft causing drowning and submersion"
    },
    {
      "name": "V9000",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While engaged in sports activity"
    },
    {
      "name": "V9001",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While engaged in leisure activity"
    },
    {
      "name": "V9002",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While working for income"
    },
    {
      "name": "V9003",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While engaged in other types of work"
    },
    {
      "name": "V9004",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9008",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship While engaged in other specified activities"
    },
    {
      "name": "V9009",
      "description": "Accident to watercraft causing drowning and submersion at merchant  ship During unspecified activity"
    },
    {
      "name": "V901",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner)"
    },
    {
      "name": "V9010",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While engaged in sports activity"
    },
    {
      "name": "V9011",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While engaged in leisure activity"
    },
    {
      "name": "V9012",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While working for income"
    },
    {
      "name": "V9013",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While engaged in other types of work"
    },
    {
      "name": "V9014",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9018",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) While engaged in other specified activities"
    },
    {
      "name": "V9019",
      "description": "Accident to watercraft causing drowning and submersion at passenger ship(ferry boat,liner) During unspecified activity"
    },
    {
      "name": "V902",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat"
    },
    {
      "name": "V9020",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While engaged in sports activity"
    },
    {
      "name": "V9021",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While engaged in leisure activity"
    },
    {
      "name": "V9022",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While working for income"
    },
    {
      "name": "V9023",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While engaged in other types of work"
    },
    {
      "name": "V9024",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9028",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat While engaged in other specified activities"
    },
    {
      "name": "V9029",
      "description": "Accident to watercraft causing drowning and submersion at fishing boat During unspecified activity"
    },
    {
      "name": "V903",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis"
    },
    {
      "name": "V9030",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in sports activity"
    },
    {
      "name": "V9031",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in leisure activity"
    },
    {
      "name": "V9032",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While working for income"
    },
    {
      "name": "V9033",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other types of work"
    },
    {
      "name": "V9034",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9038",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other specified activities"
    },
    {
      "name": "V9039",
      "description": "Accident to watercraft causing drowning and submersion at other powered watercraft such as hovercraft(on open water),jetskis During unspecified activity"
    },
    {
      "name": "V904",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht"
    },
    {
      "name": "V9040",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While engaged in sports activity"
    },
    {
      "name": "V9041",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While engaged in leisure activity"
    },
    {
      "name": "V9042",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While working for income"
    },
    {
      "name": "V9043",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While engaged in other types of work"
    },
    {
      "name": "V9044",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9048",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht While engaged in other specified activities"
    },
    {
      "name": "V9049",
      "description": "Accident to watercraft causing drowning and submersion at sailboat  such as yacht During unspecified activity"
    },
    {
      "name": "V905",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak"
    },
    {
      "name": "V9050",
      "description": "Accident to watercraft causng drowning and submersion at canoe or kayak While engaged in sports activity"
    },
    {
      "name": "V9051",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak While engaged in leisure activity"
    },
    {
      "name": "V9052",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak While working for income"
    },
    {
      "name": "V9053",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak While engaged in other types of work"
    },
    {
      "name": "V9054",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9058",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak While engaged in other specified activities"
    },
    {
      "name": "V9059",
      "description": "Accident to watercraft causing drowning and submersion at canoe or kayak During unspecified activity"
    },
    {
      "name": "V906",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered)"
    },
    {
      "name": "V9060",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While engaged in sports activity"
    },
    {
      "name": "V9061",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While engaged in leisure activity"
    },
    {
      "name": "V9062",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While working for income"
    },
    {
      "name": "V9063",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While engaged in other types of work"
    },
    {
      "name": "V9064",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9068",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) While engaged in other specified activities"
    },
    {
      "name": "V9069",
      "description": "Accident to watercraft causing drowning and submersion at infratable craft(nonpowered) During unspecified activity"
    },
    {
      "name": "V907",
      "description": "Accident to watercraft causing drowning and submersion at water-ski"
    },
    {
      "name": "V9070",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While engaged in sports activity"
    },
    {
      "name": "V9071",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While engaged in leisure activity"
    },
    {
      "name": "V9072",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While working for income"
    },
    {
      "name": "V9073",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While engaged in other types of work"
    },
    {
      "name": "V9074",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9078",
      "description": "Accident to watercraft causing drowning and submersion at water-ski While engaged in other specified activities"
    },
    {
      "name": "V9079",
      "description": "Accident to watercraft causing drowning and submersion at water-ski During unspecified activity"
    },
    {
      "name": "V908",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS"
    },
    {
      "name": "V9080",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in sports activity"
    },
    {
      "name": "V9081",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in leisure activity"
    },
    {
      "name": "V9082",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While working for income"
    },
    {
      "name": "V9083",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in other types of work"
    },
    {
      "name": "V9084",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9088",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in other specified activities"
    },
    {
      "name": "V9089",
      "description": "Accident to watercraft causing drowning and submersion at other unpower watercraft , boat NOS,ship NOS During unspecified activity"
    },
    {
      "name": "V909",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS"
    },
    {
      "name": "V9090",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in sports activity"
    },
    {
      "name": "V9091",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in leisure activity"
    },
    {
      "name": "V9092",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While working for income"
    },
    {
      "name": "V9093",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other types of work"
    },
    {
      "name": "V9094",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9098",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other specified activities"
    },
    {
      "name": "V9099",
      "description": "Accident to watercraft causing drowning and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS During unspecified activity"
    },
    {
      "name": "V910",
      "description": "Accident to watercraft causing other injury at merchant  ship"
    },
    {
      "name": "V9100",
      "description": "Accident to watercraft causing other injury at merchant  ship While engaged in sports activity"
    },
    {
      "name": "V9101",
      "description": "Accident to watercraft causing other injury at merchant  ship While engaged in leisure activity"
    },
    {
      "name": "V9102",
      "description": "Accident to watercraft causing other injury at merchant  ship While working for income"
    },
    {
      "name": "V9103",
      "description": "Accident to watercraft causing other injury at merchant  ship While engaged in other types of work"
    },
    {
      "name": "V9104",
      "description": "Accident to watercraft causing other injury at merchant  ship While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9108",
      "description": "Accident to watercraft causing other injury at merchant  ship While engaged in other specified activities"
    },
    {
      "name": "V9109",
      "description": "Accident to watercraft causing other injury at merchant  ship During unspecified activity"
    },
    {
      "name": "V911",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner)"
    },
    {
      "name": "V9110",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While engaged in sports activity"
    },
    {
      "name": "V9111",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While engaged in leisure activity"
    },
    {
      "name": "V9112",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While working for income"
    },
    {
      "name": "V9113",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While engaged in other types of work"
    },
    {
      "name": "V9114",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9118",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) While engaged in other specified activities"
    },
    {
      "name": "V9119",
      "description": "Accident to watercraft causing other injury at passenger ship(ferry boat,liner) During unspecified activity"
    },
    {
      "name": "V912",
      "description": "Accident to watercraft causing other injury at fishing boat"
    },
    {
      "name": "V9120",
      "description": "Accident to watercraft causing other injury at fishing boat While engaged in sports activity"
    },
    {
      "name": "V9121",
      "description": "Accident to watercraft causing other injury at fishing boat While engaged in leisure activity"
    },
    {
      "name": "V9122",
      "description": "Accident to watercraft causing other injury at fishing boat While working for income"
    },
    {
      "name": "V9123",
      "description": "Accident to watercraft causing other injury at fishing boat While engaged in other types of work"
    },
    {
      "name": "V9124",
      "description": "Accident to watercraft causing other injury at fishing boat While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9128",
      "description": "Accident to watercraft causing other injury at fishing boat While engaged in other specified activities"
    },
    {
      "name": "V9129",
      "description": "Accident to watercraft causing other injury at fishing boat During unspecified activity"
    },
    {
      "name": "V913",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis"
    },
    {
      "name": "V9130",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While engaged in sports activity"
    },
    {
      "name": "V9131",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While engaged in leisure activity"
    },
    {
      "name": "V9132",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While working for income"
    },
    {
      "name": "V9133",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other types of work"
    },
    {
      "name": "V9134",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9138",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other specified activities"
    },
    {
      "name": "V9139",
      "description": "Accident to watercraft causing other injury at other powered watercraft such as hovercraft(on open water),jetskis During unspecified activity"
    },
    {
      "name": "V914",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht"
    },
    {
      "name": "V9140",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While engaged in sports activity"
    },
    {
      "name": "V9141",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While engaged in leisure activity"
    },
    {
      "name": "V9142",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While working for income"
    },
    {
      "name": "V9143",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While engaged in other types of work"
    },
    {
      "name": "V9144",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9148",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht While engaged in other specified activities"
    },
    {
      "name": "V9149",
      "description": "Accident to watercraft causing other injury at sailboat  such as yacht During unspecified activity"
    },
    {
      "name": "V915",
      "description": "Accident to watercraft causing other injury at canoe or kayak"
    },
    {
      "name": "V9150",
      "description": "Accident to watercraft causing other injury at canoe or kayak While engaged in sports activity"
    },
    {
      "name": "V9151",
      "description": "Accident to watercraft causing other injury at canoe or kayak While engaged in leisure activity"
    },
    {
      "name": "V9152",
      "description": "Accident to watercraft causing other injury at canoe or kayak While working for income"
    },
    {
      "name": "V9153",
      "description": "Accident to watercraft causing other injury at canoe or kayak While engaged in other types of work"
    },
    {
      "name": "V9154",
      "description": "Accident to watercraft causing other injury at canoe or kayak While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9158",
      "description": "Accident to watercraft causing other injury at canoe or kayak While engaged in other specified activities"
    },
    {
      "name": "V9159",
      "description": "Accident to watercraft causing other injury at canoe or kayak During unspecified activity"
    },
    {
      "name": "V916",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered)"
    },
    {
      "name": "V9160",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While engaged in sports activity"
    },
    {
      "name": "V9161",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While engaged in leisure activity"
    },
    {
      "name": "V9162",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While working for income"
    },
    {
      "name": "V9163",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While engaged in other types of work"
    },
    {
      "name": "V9164",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9168",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) While engaged in other specified activities"
    },
    {
      "name": "V9169",
      "description": "Accident to watercraft causing other injury at infratable craft(nonpowered) During unspecified activity"
    },
    {
      "name": "V917",
      "description": "Accident to watercraft causing other injury at water-ski"
    },
    {
      "name": "V9170",
      "description": "Accident to watercraft causing other injury at water-ski While engaged in sports activity"
    },
    {
      "name": "V9171",
      "description": "Accident to watercraft causing other injury at water-ski While engaged in leisure activity"
    },
    {
      "name": "V9172",
      "description": "Accident to watercraft causing other injury at water-ski While working for income"
    },
    {
      "name": "V9173",
      "description": "Accident to watercraft causing other injury at water-ski While engaged in other types of work"
    },
    {
      "name": "V9174",
      "description": "Accident to watercraft causing other injury at water-ski While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9178",
      "description": "Accident to watercraft causing other injury at water-ski While engaged in other specified activities"
    },
    {
      "name": "V9179",
      "description": "Accident to watercraft causing other injury at water-ski During unspecified activity"
    },
    {
      "name": "V918",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS"
    },
    {
      "name": "V9180",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While engaged in sports activity"
    },
    {
      "name": "V9181",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While engaged in leisure activity"
    },
    {
      "name": "V9182",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While working for income"
    },
    {
      "name": "V9183",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While engaged in other types of work"
    },
    {
      "name": "V9184",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9188",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS While engaged in other specified activities"
    },
    {
      "name": "V9189",
      "description": "Accident to watercraft causing other injury at other unpower watercraft , boat NOS,ship NOS During unspecified activity"
    },
    {
      "name": "V919",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS"
    },
    {
      "name": "V9190",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in sports activity"
    },
    {
      "name": "V9191",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in leisure activity"
    },
    {
      "name": "V9192",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While working for income"
    },
    {
      "name": "V9193",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other types of work"
    },
    {
      "name": "V9194",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9198",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other specified activities"
    },
    {
      "name": "V9199",
      "description": "Accident to watercraft causing other injury at unspecified watercraft,boat NOS,ship NOS,watercraft NOS During unspecified activity"
    },
    {
      "name": "V920",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship"
    },
    {
      "name": "V9200",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While engaged in sports activity"
    },
    {
      "name": "V9201",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While engaged in leisure activity"
    },
    {
      "name": "V9202",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While working for income"
    },
    {
      "name": "V9203",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While engaged in other types of work"
    },
    {
      "name": "V9204",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9208",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship While engaged in other specified activities"
    },
    {
      "name": "V9209",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at merchant  ship During unspecified activity"
    },
    {
      "name": "V921",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner)"
    },
    {
      "name": "V9210",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While engaged in sports activity"
    },
    {
      "name": "V9211",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While engaged in leisure activity"
    },
    {
      "name": "V9212",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While working for income"
    },
    {
      "name": "V9213",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While engaged in other types of work"
    },
    {
      "name": "V9214",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9218",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) While engaged in other specified activities"
    },
    {
      "name": "V9219",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at passenger ship(ferry boat,liner) During unspecified activity"
    },
    {
      "name": "V922",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat"
    },
    {
      "name": "V9220",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While engaged in sports activity"
    },
    {
      "name": "V9221",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While engaged in leisure activity"
    },
    {
      "name": "V9222",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While working for income"
    },
    {
      "name": "V9223",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While engaged in other types of work"
    },
    {
      "name": "V9224",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9228",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat While engaged in other specified activities"
    },
    {
      "name": "V9229",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at fishing boat During unspecified activity"
    },
    {
      "name": "V923",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis"
    },
    {
      "name": "V9230",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While engaged in sports activity"
    },
    {
      "name": "V9231",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While engaged in leisure activity"
    },
    {
      "name": "V9232",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While working for income"
    },
    {
      "name": "V9233",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other types of work"
    },
    {
      "name": "V9234",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9238",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other specified activities"
    },
    {
      "name": "V9239",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other powered watercraft such as hovercraft(on open water),jetskis During unspecified activity"
    },
    {
      "name": "V924",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht"
    },
    {
      "name": "V9240",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While engaged in sports activity"
    },
    {
      "name": "V9241",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While engaged in leisure activity"
    },
    {
      "name": "V9242",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While working for income"
    },
    {
      "name": "V9243",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While engaged in other types of work"
    },
    {
      "name": "V9244",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9248",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht While engaged in other specified activities"
    },
    {
      "name": "V9249",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at sailboat  such as yacht During unspecified activity"
    },
    {
      "name": "V925",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak"
    },
    {
      "name": "V9250",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While engaged in sports activity"
    },
    {
      "name": "V9251",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While engaged in leisure activity"
    },
    {
      "name": "V9252",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While working for income"
    },
    {
      "name": "V9253",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While engaged in other types of work"
    },
    {
      "name": "V9254",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9258",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak While engaged in other specified activities"
    },
    {
      "name": "V9259",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at canoe or kayak During unspecified activity"
    },
    {
      "name": "V926",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered)"
    },
    {
      "name": "V9260",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While engaged in sports activity"
    },
    {
      "name": "V9261",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While engaged in leisure activity"
    },
    {
      "name": "V9262",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While working for income"
    },
    {
      "name": "V9263",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While engaged in other types of work"
    },
    {
      "name": "V9264",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9268",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) While engaged in other specified activities"
    },
    {
      "name": "V9269",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at infratable craft(nonpowered) During unspecified activity"
    },
    {
      "name": "V927",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski"
    },
    {
      "name": "V9270",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While engaged in sports activity"
    },
    {
      "name": "V9271",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While engaged in leisure activity"
    },
    {
      "name": "V9272",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While working for income"
    },
    {
      "name": "V9273",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While engaged in other types of work"
    },
    {
      "name": "V9274",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9278",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski While engaged in other specified activities"
    },
    {
      "name": "V9279",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at water-ski During unspecified activity"
    },
    {
      "name": "V928",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS"
    },
    {
      "name": "V9280",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While engaged in sports activity"
    },
    {
      "name": "V9281",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While engaged in leisure activity"
    },
    {
      "name": "V9282",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While working for income"
    },
    {
      "name": "V9283",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While engaged in other types of work"
    },
    {
      "name": "V9284",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9288",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS While engaged in other specified activities"
    },
    {
      "name": "V9289",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at other unpower watercraft , boat NOS,ship NOS During unspecified activity"
    },
    {
      "name": "V929",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS"
    },
    {
      "name": "V9290",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in sports activity"
    },
    {
      "name": "V9291",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in leisure activity"
    },
    {
      "name": "V9292",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While working for income"
    },
    {
      "name": "V9293",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other types of work"
    },
    {
      "name": "V9294",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9298",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other specified activities"
    },
    {
      "name": "V9299",
      "description": "Water-transport-related drownig and submersion without accident to watercraft at unspecified watercraft,boat NOS,ship NOS,watercraft NOS During unspecified activity"
    },
    {
      "name": "V930",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship"
    },
    {
      "name": "V9300",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While engaged in sports activity"
    },
    {
      "name": "V9301",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While engaged in leisure activity"
    },
    {
      "name": "V9302",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While working for income"
    },
    {
      "name": "V9303",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While engaged in other types of work"
    },
    {
      "name": "V9304",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9308",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship While engaged in other specified activities"
    },
    {
      "name": "V9309",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at merchant  ship During unspecified activity"
    },
    {
      "name": "V931",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner)"
    },
    {
      "name": "V9310",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While engaged in sports activity"
    },
    {
      "name": "V9311",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While engaged in leisure activity"
    },
    {
      "name": "V9312",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While working for income"
    },
    {
      "name": "V9313",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While engaged in other types of work"
    },
    {
      "name": "V9314",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9318",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) While engaged in other specified activities"
    },
    {
      "name": "V9319",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at passenger ship(ferry boat,liner) During unspecified activity"
    },
    {
      "name": "V932",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat"
    },
    {
      "name": "V9320",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While engaged in sports activity"
    },
    {
      "name": "V9321",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While engaged in leisure activity"
    },
    {
      "name": "V9322",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While working for income"
    },
    {
      "name": "V9323",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While engaged in other types of work"
    },
    {
      "name": "V9324",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9328",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat While engaged in other specified activities"
    },
    {
      "name": "V9329",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at fishing boat During unspecified activity"
    },
    {
      "name": "V933",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis"
    },
    {
      "name": "V9330",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in sports activity"
    },
    {
      "name": "V9331",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in leisure activity"
    },
    {
      "name": "V9332",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While working for income"
    },
    {
      "name": "V9333",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other types of work"
    },
    {
      "name": "V9334",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9338",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other specified activities"
    },
    {
      "name": "V9339",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other powered watercraft such as hovercraft(on open water),jetskis During unspecified activity"
    },
    {
      "name": "V934",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht"
    },
    {
      "name": "V9340",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While engaged in sports activity"
    },
    {
      "name": "V9341",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While engaged in leisure activity"
    },
    {
      "name": "V9342",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While working for income"
    },
    {
      "name": "V9343",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While engaged in other types of work"
    },
    {
      "name": "V9344",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9348",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht While engaged in other specified activities"
    },
    {
      "name": "V9349",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at sailboat  such as yacht During unspecified activity"
    },
    {
      "name": "V935",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak"
    },
    {
      "name": "V9350",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While engaged in sports activity"
    },
    {
      "name": "V9351",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While engaged in leisure activity"
    },
    {
      "name": "V9352",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While working for income"
    },
    {
      "name": "V9353",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While engaged in other types of work"
    },
    {
      "name": "V9354",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9358",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak While engaged in other specified activities"
    },
    {
      "name": "V9359",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at canoe or kayak During unspecified activity"
    },
    {
      "name": "V936",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered)"
    },
    {
      "name": "V9360",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While engaged in sports activity"
    },
    {
      "name": "V9361",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While engaged in leisure activity"
    },
    {
      "name": "V9362",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While working for income"
    },
    {
      "name": "V9363",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While engaged in other types of work"
    },
    {
      "name": "V9364",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9368",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) While engaged in other specified activities"
    },
    {
      "name": "V9369",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at infratable craft(nonpowered) During unspecified activity"
    },
    {
      "name": "V937",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski"
    },
    {
      "name": "V9370",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While engaged in sports activity"
    },
    {
      "name": "V9371",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While engaged in leisure activity"
    },
    {
      "name": "V9372",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While working for income"
    },
    {
      "name": "V9373",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While engaged in other types of work"
    },
    {
      "name": "V9374",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9378",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski While engaged in other specified activities"
    },
    {
      "name": "V9379",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at water-ski During unspecified activity"
    },
    {
      "name": "V938",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS"
    },
    {
      "name": "V9380",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in sports activity"
    },
    {
      "name": "V9381",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in leisure activity"
    },
    {
      "name": "V9382",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While working for income"
    },
    {
      "name": "V9383",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in other types of work"
    },
    {
      "name": "V9384",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9388",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS While engaged in other specified activities"
    },
    {
      "name": "V9389",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at other unpower watercraft , boat NOS,ship NOS During unspecified activity"
    },
    {
      "name": "V939",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS"
    },
    {
      "name": "V9390",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in sports activity"
    },
    {
      "name": "V9391",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in leisure activity"
    },
    {
      "name": "V9392",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While working for income"
    },
    {
      "name": "V9393",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other types of work"
    },
    {
      "name": "V9394",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9398",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other specified activities"
    },
    {
      "name": "V9399",
      "description": "Accident on board watercraft without accident to watercraft, not causing drowing and submersion at unspecified watercraft,boat NOS,ship NOS,watercraft NOS During unspecified activity"
    },
    {
      "name": "V940",
      "description": "Other and unspecified water transport accidents at merchant  ship"
    },
    {
      "name": "V9400",
      "description": "Other and unspecified water transport accidents at merchant  ship While engaged in sports activity"
    },
    {
      "name": "V9401",
      "description": "Other and unspecified water transport accidents at merchant  ship While engaged in leisure activity"
    },
    {
      "name": "V9402",
      "description": "Other and unspecified water transport accidents at merchant  ship While working for income"
    },
    {
      "name": "V9403",
      "description": "Other and unspecified water transport accidents at merchant  ship While engaged in other types of work"
    },
    {
      "name": "V9404",
      "description": "Other and unspecified water transport accidents at merchant  ship While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9408",
      "description": "Other and unspecified water transport accidents at merchant  ship While engaged in other specified activities"
    },
    {
      "name": "V9409",
      "description": "Other and unspecified water transport accidents at merchant  ship During unspecified activity"
    },
    {
      "name": "V941",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner)"
    },
    {
      "name": "V9410",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While engaged in sports activity"
    },
    {
      "name": "V9411",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While engaged in leisure activity"
    },
    {
      "name": "V9412",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While working for income"
    },
    {
      "name": "V9413",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While engaged in other types of work"
    },
    {
      "name": "V9414",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9418",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) While engaged in other specified activities"
    },
    {
      "name": "V9419",
      "description": "Other and unspecified water transport accidents at passenger ship(ferry boat,liner) During unspecified activity"
    },
    {
      "name": "V942",
      "description": "Other and unspecified water transport accidents at fishing boat"
    },
    {
      "name": "V9420",
      "description": "Other and unspecified water transport accidents at fishing boat While engaged in sports activity"
    },
    {
      "name": "V9421",
      "description": "Other and unspecified water transport accidents at fishing boat While engaged in leisure activity"
    },
    {
      "name": "V9422",
      "description": "Other and unspecified water transport accidents at fishing boat While working for income"
    },
    {
      "name": "V9423",
      "description": "Other and unspecified water transport accidents at fishing boat While engaged in other types of work"
    },
    {
      "name": "V9424",
      "description": "Other and unspecified water transport accidents at fishing boat While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9428",
      "description": "Other and unspecified water transport accidents at fishing boat While engaged in other specified activities"
    },
    {
      "name": "V9429",
      "description": "Other and unspecified water transport accidents at fishing boat During unspecified activity"
    },
    {
      "name": "V943",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis"
    },
    {
      "name": "V9430",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While engaged in sports activity"
    },
    {
      "name": "V9431",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While engaged in leisure activity"
    },
    {
      "name": "V9432",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While working for income"
    },
    {
      "name": "V9433",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other types of work"
    },
    {
      "name": "V9434",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9438",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis While engaged in other specified activities"
    },
    {
      "name": "V9439",
      "description": "Other and unspecified water transport accidents at other powered watercraft such as hovercraft(on open water),jetskis During unspecified activity"
    },
    {
      "name": "V944",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht"
    },
    {
      "name": "V9440",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht While engaged in sports activity"
    },
    {
      "name": "V9441",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht  While engaged in leisure activity"
    },
    {
      "name": "V9442",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht While working for income"
    },
    {
      "name": "V9443",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht While engaged in other types of work"
    },
    {
      "name": "V9444",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9448",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht While engaged in other specified activities"
    },
    {
      "name": "V9449",
      "description": "Other and unspecified water transport accidents at sailboat  such as yacht During unspecified activity"
    },
    {
      "name": "V945",
      "description": "Other and unspecified water transport accidents at canoe or kayak"
    },
    {
      "name": "V9450",
      "description": "Other and unspecified water transport accidents at canoe or kayak While engaged in sports activity"
    },
    {
      "name": "V9451",
      "description": "Other and unspecified water transport accidents at canoe or kayak While engaged in leisure activity"
    },
    {
      "name": "V9452",
      "description": "Other and unspecified water transport accidents at canoe or kayak While working for income"
    },
    {
      "name": "V9453",
      "description": "Other and unspecified water transport accidents at canoe or kayak While engaged in other types of work"
    },
    {
      "name": "V9454",
      "description": "Other and unspecified water transport accidents at canoe or kayak While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9458",
      "description": "Other and unspecified water transport accidents at canoe or kayak While engaged in other specified activities"
    },
    {
      "name": "V9459",
      "description": "Other and unspecified water transport accidents at canoe or kayak During unspecified activity"
    },
    {
      "name": "V946",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered)"
    },
    {
      "name": "V9460",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While engaged in sports activity"
    },
    {
      "name": "V9461",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While engaged in leisure activity"
    },
    {
      "name": "V9462",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While working for income"
    },
    {
      "name": "V9463",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While engaged in other types of work"
    },
    {
      "name": "V9464",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9468",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) While engaged in other specified activities"
    },
    {
      "name": "V9469",
      "description": "Other and unspecified water transport accidents at infratable craft(nonpowered) During unspecified activity"
    },
    {
      "name": "V947",
      "description": "Other and unspecified water transport accidents at water-ski"
    },
    {
      "name": "V9470",
      "description": "Other and unspecified water transport accidents at water-ski While engaged in sports activity"
    },
    {
      "name": "V9471",
      "description": "Other and unspecified water transport accidents at water-ski While engaged in leisure activity"
    },
    {
      "name": "V9472",
      "description": "Other and unspecified water transport accidents at water-ski While working for income"
    },
    {
      "name": "V9473",
      "description": "Other and unspecified water transport accidents at water-ski While engaged in other types of work"
    },
    {
      "name": "V9474",
      "description": "Other and unspecified water transport accidents at water-ski While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9478",
      "description": "Other and unspecified water transport accidents at water-ski While engaged in other specified activities"
    },
    {
      "name": "V9479",
      "description": "Other and unspecified water transport accidents at water-ski During unspecified activity"
    },
    {
      "name": "V948",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS"
    },
    {
      "name": "V9480",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While engaged in sports activity"
    },
    {
      "name": "V9481",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While engaged in leisure activity"
    },
    {
      "name": "V9482",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While working for income"
    },
    {
      "name": "V9483",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While engaged in other types of work"
    },
    {
      "name": "V9484",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9488",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS While engaged in other specified activities"
    },
    {
      "name": "V9489",
      "description": "Other and unspecified water transport accidents at other unpower watercraft , boat NOS,ship NOS During unspecified activity"
    },
    {
      "name": "V949",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS"
    },
    {
      "name": "V9490",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in sports activity"
    },
    {
      "name": "V9491",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in leisure activity"
    },
    {
      "name": "V9492",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While working for income"
    },
    {
      "name": "V9493",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other types of work"
    },
    {
      "name": "V9494",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9498",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS While engaged in other specified activities"
    },
    {
      "name": "V9499",
      "description": "Other and unspecified water transport accidents at unspecified watercraft,boat NOS,ship NOS,watercraft NOS During unspecified activity"
    },
    {
      "name": "V950",
      "description": "Helicopter accident injuring occupant"
    },
    {
      "name": "V9500",
      "description": "Helicopter accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9501",
      "description": "Helicopter accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9502",
      "description": "Helicopter accident injuring occupant While working for income"
    },
    {
      "name": "V9503",
      "description": "Helicopter accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9504",
      "description": "Helicopter accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9508",
      "description": "Helicopter accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9509",
      "description": "Helicopter accident injuring occupant During unspecified activity"
    },
    {
      "name": "V951",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant"
    },
    {
      "name": "V9510",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9511",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9512",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While working for income"
    },
    {
      "name": "V9513",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9514",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9518",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9519",
      "description": "Ultralight, microlight or powered-glider accident injuring occupant During unspecified activity"
    },
    {
      "name": "V952",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant"
    },
    {
      "name": "V9520",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9521",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9522",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While working for income"
    },
    {
      "name": "V9523",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9524",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9528",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9529",
      "description": "Accident to other private fixed-wing aircraft, injuring occupant During unspecified activity"
    },
    {
      "name": "V953",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant"
    },
    {
      "name": "V9530",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9531",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9532",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While working for income"
    },
    {
      "name": "V9533",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9534",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9538",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9539",
      "description": "Accident to commercial fixed-wing aircraft, injuring occupant During unspecified activity"
    },
    {
      "name": "V954",
      "description": "Spacecraft accident injuring occupant"
    },
    {
      "name": "V9540",
      "description": "Spacecraft accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9541",
      "description": "Spacecraft accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9542",
      "description": "Spacecraft accident injuring occupant While working for income"
    },
    {
      "name": "V9543",
      "description": "Spacecraft accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9544",
      "description": "Spacecraft accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9548",
      "description": "Spacecraft accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9549",
      "description": "Spacecraft accident injuring occupant During unspecified activity"
    },
    {
      "name": "V958",
      "description": "Other aircraft accidents injuring occupant"
    },
    {
      "name": "V9580",
      "description": "Other aircraft accidents injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9581",
      "description": "Other aircraft accidents injuring occupant  While engaged in leisure activity"
    },
    {
      "name": "V9582",
      "description": "Other aircraft accidents injuring occupant While working for income"
    },
    {
      "name": "V9583",
      "description": "Other aircraft accidents injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9584",
      "description": "Other aircraft accidents injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9588",
      "description": "Other aircraft accidents injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9589",
      "description": "Other aircraft accidents injuring occupant During unspecified activity"
    },
    {
      "name": "V959",
      "description": "Unspecified aircraft accident injuring occupant"
    },
    {
      "name": "V9590",
      "description": "Unspecified aircraft accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9591",
      "description": "Unspecified aircraft accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9592",
      "description": "Unspecified aircraft accident injuring occupant  While working for income"
    },
    {
      "name": "V9593",
      "description": "Unspecified aircraft accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9594",
      "description": "Unspecified aircraft accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9598",
      "description": "Unspecified aircraft accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9599",
      "description": "Unspecified aircraft accident injuring occupant During unspecified activity"
    },
    {
      "name": "V960",
      "description": "Balloon accident injuring occupant"
    },
    {
      "name": "V9600",
      "description": "Balloon accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9601",
      "description": "Balloon accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9602",
      "description": "Balloon accident injuring occupant  While working for income"
    },
    {
      "name": "V9603",
      "description": "Balloon accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9604",
      "description": "Balloon accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9608",
      "description": "Balloon accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9609",
      "description": "Balloon accident injuring occupant During unspecified activity"
    },
    {
      "name": "V961",
      "description": "Hang-gilder accident injuring occupant"
    },
    {
      "name": "V9610",
      "description": "Hang-gilder accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9611",
      "description": "Hang-gilder accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9612",
      "description": "Hang-gilder accident injuring occupant While working for income"
    },
    {
      "name": "V9613",
      "description": "Hang-gilder accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9614",
      "description": "Hang-gilder accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9618",
      "description": "Hang-gilder accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9619",
      "description": "Hang-gilder accident injuring occupant During unspecified activity"
    },
    {
      "name": "V962",
      "description": "Glider (nonpowered) accident injuring occupant"
    },
    {
      "name": "V9620",
      "description": "Glider (nonpowered) accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9621",
      "description": "Glider (nonpowered) accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9622",
      "description": "Glider (nonpowered) accident injuring occupant While working for income"
    },
    {
      "name": "V9623",
      "description": "Glider (nonpowered) accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9624",
      "description": "Glider (nonpowered) accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9628",
      "description": "Glider (nonpowered) accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9629",
      "description": "Glider (nonpowered) accident injuring occupant During unspecified activity"
    },
    {
      "name": "V968",
      "description": "Other nonpowered-aircraft accidents injuring occupant"
    },
    {
      "name": "V9680",
      "description": "Other nonpowered-aircraft accidents injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9681",
      "description": "Other nonpowered-aircraft accidents injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9682",
      "description": "Other nonpowered-aircraft accidents injuring occupant While working for income"
    },
    {
      "name": "V9683",
      "description": "Other nonpowered-aircraft accidents injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9684",
      "description": "Other nonpowered-aircraft accidents injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9688",
      "description": "Other nonpowered-aircraft accidents injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9689",
      "description": "Other nonpowered-aircraft accidents injuring occupant During unspecified activity"
    },
    {
      "name": "V969",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant"
    },
    {
      "name": "V9690",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While engaged in sports activity"
    },
    {
      "name": "V9691",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While engaged in leisure activity"
    },
    {
      "name": "V9692",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While working for income"
    },
    {
      "name": "V9693",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While engaged in other types of work"
    },
    {
      "name": "V9694",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9698",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant While engaged in other specified activities"
    },
    {
      "name": "V9699",
      "description": "Unspecified nonpowered-aircraft accident injuring occupant During unspecified activity"
    },
    {
      "name": "V970",
      "description": "Occupant of  aircraft injured in other specified air transport accidents"
    },
    {
      "name": "V9700",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While engaged in sports activity"
    },
    {
      "name": "V9701",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While engaged in leisure activity"
    },
    {
      "name": "V9702",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While working for income"
    },
    {
      "name": "V9703",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While engaged in other types of work"
    },
    {
      "name": "V9704",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9708",
      "description": "Occupant of  aircraft injured in other specified air transport accidents While engaged in other specified activities"
    },
    {
      "name": "V9709",
      "description": "Occupant of  aircraft injured in other specified air transport accidents  During unspecified activity"
    },
    {
      "name": "V971",
      "description": "Person injured while boarding or alighting from aircraft"
    },
    {
      "name": "V972",
      "description": "Parachutist injured in air transport accident"
    },
    {
      "name": "V9720",
      "description": "Parachutist injured in air transport accident While engaged in sports activity"
    },
    {
      "name": "V9721",
      "description": "Parachutist injured in air transport accident While engaged in leisure activity"
    },
    {
      "name": "V9722",
      "description": "Parachutist injured in air transport accident While working for income"
    },
    {
      "name": "V9723",
      "description": "Parachutist injured in air transport accident While engaged in other types of work"
    },
    {
      "name": "V9724",
      "description": "Parachutist injured in air transport accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9728",
      "description": "Parachutist injured in air transport accident While engaged in other specified activities"
    },
    {
      "name": "V9729",
      "description": "Parachutist injured in air transport accident During unspecified activity"
    },
    {
      "name": "V973",
      "description": "Person on ground injured in air transport accident"
    },
    {
      "name": "V9730",
      "description": "Person on ground injured in air transport accident While engaged in sports activity"
    },
    {
      "name": "V9731",
      "description": "Person on ground injured in air transport accident While engaged in leisure activity"
    },
    {
      "name": "V9732",
      "description": "Person on ground injured in air transport accident While working for income"
    },
    {
      "name": "V9733",
      "description": "Person on ground injured in air transport accident While engaged in other types of work"
    },
    {
      "name": "V9734",
      "description": "Person on ground injured in air transport accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9738",
      "description": "Person on ground injured in air transport accident While engaged in other specified activities"
    },
    {
      "name": "V9739",
      "description": "Person on ground injured in air transport accident During unspecified activity"
    },
    {
      "name": "V978",
      "description": "Other air transport accidents, not elsewhere classified"
    },
    {
      "name": "V9780",
      "description": "Other air transport accidents, not elsewhere classified While engaged in sports activity"
    },
    {
      "name": "V9781",
      "description": "Other air transport accidents, not elsewhere classified While engaged in leisure activity"
    },
    {
      "name": "V9782",
      "description": "Other air transport accidents, not elsewhere classified While working for income"
    },
    {
      "name": "V9783",
      "description": "Other air transport accidents, not elsewhere classified While engaged in other types of work"
    },
    {
      "name": "V9784",
      "description": "Other air transport accidents, not elsewhere classified While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V9788",
      "description": "Other air transport accidents, not elsewhere classified While engaged in other specified activities"
    },
    {
      "name": "V9789",
      "description": "Other air transport accidents, not elsewhere classified During unspecified activity"
    },
    {
      "name": "V98",
      "description": "Other specified transport accidents"
    },
    {
      "name": "V980",
      "description": "Other specified transport accidents While engaged in sports activity"
    },
    {
      "name": "V981",
      "description": "Other specified transport accidents While engaged in leisure activity"
    },
    {
      "name": "V982",
      "description": "Other specified transport accidents While working for income"
    },
    {
      "name": "V983",
      "description": "Other specified transport accidents While engaged in other types of work"
    },
    {
      "name": "V984",
      "description": "Other specified transport accidents While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V988",
      "description": "Other specified transport accidents While engaged in other specified activities"
    },
    {
      "name": "V989",
      "description": "Other specified transport accidents During unspecified activity"
    },
    {
      "name": "V99",
      "description": "Unspecified transport accident"
    },
    {
      "name": "V990",
      "description": "Unspecified transport accident While engaged in sports activity"
    },
    {
      "name": "V991",
      "description": "Unspecified transport accident While engaged in leisure activity"
    },
    {
      "name": "V992",
      "description": "Unspecified transport accident While working for income"
    },
    {
      "name": "V993",
      "description": "Unspecified transport accident While engaged in other types of work"
    },
    {
      "name": "V994",
      "description": "Unspecified transport accident While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "V998",
      "description": "Unspecified transport accident While engaged in other specified activities"
    },
    {
      "name": "V999",
      "description": "Unspecified transport accident During unspecified activity"
    },
    {
      "name": "W000",
      "description": "Fall on same level involving ice and snow at home"
    },
    {
      "name": "W0000",
      "description": "Fall on same level involving ice and snow at home While engaged in sports activity"
    },
    {
      "name": "W0001",
      "description": "Fall on same level involving ice and snow at home While engaged in leisure activity"
    },
    {
      "name": "W0002",
      "description": "Fall on same level involving ice and snow at home While working for income"
    },
    {
      "name": "W0003",
      "description": "Fall on same level involving ice and snow at home While engaged in other types of work"
    },
    {
      "name": "W0004",
      "description": "Fall on same level involving ice and snow at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0008",
      "description": "Fall on same level involving ice and snow at home While engaged in other specified activities"
    },
    {
      "name": "W0009",
      "description": "Fall on same level involving ice and snow at home During unspecified activity"
    },
    {
      "name": "W001",
      "description": "Fall on same level involving ice and snow at residential institution"
    },
    {
      "name": "W0010",
      "description": "Fall on same level involving ice and snow at residential institution While engaged in sports activity"
    },
    {
      "name": "W0011",
      "description": "Fall on same level involving ice and snow at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0012",
      "description": "Fall on same level involving ice and snow at residential institution While working for income"
    },
    {
      "name": "W0013",
      "description": "Fall on same level involving ice and snow at residential institution While engaged in other types of work"
    },
    {
      "name": "W0014",
      "description": "Fall on same level involving ice and snow at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0018",
      "description": "Fall on same level involving ice and snow at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0019",
      "description": "Fall on same level involving ice and snow at residential institution During unspecified activity"
    },
    {
      "name": "W002",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area"
    },
    {
      "name": "W0020",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0021",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0022",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0023",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0024",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0028",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0029",
      "description": "Fall on same level involving ice and snow at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W003",
      "description": "Fall on same level involving ice and snow at sports and athletics area"
    },
    {
      "name": "W0030",
      "description": "Fall on same level involving ice and snow at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0031",
      "description": "Fall on same level involving ice and snow at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0032",
      "description": "Fall on same level involving ice and snow at sports and athletics area While working for income"
    },
    {
      "name": "W0033",
      "description": "Fall on same level involving ice and snow at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0034",
      "description": "Fall on same level involving ice and snow at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0038",
      "description": "Fall on same level involving ice and snow at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0039",
      "description": "Fall on same level involving ice and snow at sports and athletics area During unspecified activity"
    },
    {
      "name": "W004",
      "description": "Fall on same level involving ice and snow at street and highway"
    },
    {
      "name": "W0040",
      "description": "Fall on same level involving ice and snow at street and highway While engaged in sports activity"
    },
    {
      "name": "W0041",
      "description": "Fall on same level involving ice and snow at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0042",
      "description": "Fall on same level involving ice and snow at street and highway While working for income"
    },
    {
      "name": "W0043",
      "description": "Fall on same level involving ice and snow at street and highway While engaged in other types of work"
    },
    {
      "name": "W0044",
      "description": "Fall on same level involving ice and snow at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0048",
      "description": "Fall on same level involving ice and snow at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0049",
      "description": "Fall on same level involving ice and snow at street and highway During unspecified activity"
    },
    {
      "name": "W005",
      "description": "Fall on same level involving ice and snow at trade and service area"
    },
    {
      "name": "W0050",
      "description": "Fall on same level involving ice and snow at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0051",
      "description": "Fall on same level involving ice and snow at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0052",
      "description": "Fall on same level involving ice and snow at trade and service area While working for income"
    },
    {
      "name": "W0053",
      "description": "Fall on same level involving ice and snow at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0054",
      "description": "Fall on same level involving ice and snow at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0058",
      "description": "Fall on same level involving ice and snow at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0059",
      "description": "Fall on same level involving ice and snow at trade and service area During unspecified activity"
    },
    {
      "name": "W006",
      "description": "Fall on same level involving ice and snow at industrial and construction area"
    },
    {
      "name": "W0060",
      "description": "Fall on same level involving ice and snow at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0061",
      "description": "Fall on same level involving ice and snow at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0062",
      "description": "Fall on same level involving ice and snow at industrial and construction area While working for income"
    },
    {
      "name": "W0063",
      "description": "Fall on same level involving ice and snow at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0064",
      "description": "Fall on same level involving ice and snow at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0068",
      "description": "Fall on same level involving ice and snow at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0069",
      "description": "Fall on same level involving ice and snow at industrial and construction area During unspecified activity"
    },
    {
      "name": "W007",
      "description": "Fall on same level involving ice and snow at farm"
    },
    {
      "name": "W0070",
      "description": "Fall on same level involving ice and snow at farm While engaged in sports activity"
    },
    {
      "name": "W0071",
      "description": "Fall on same level involving ice and snow at farm While engaged in leisure activity"
    },
    {
      "name": "W0072",
      "description": "Fall on same level involving ice and snow at farm While working for income"
    },
    {
      "name": "W0073",
      "description": "Fall on same level involving ice and snow at farm While engaged in other types of work"
    },
    {
      "name": "W0074",
      "description": "Fall on same level involving ice and snow at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0078",
      "description": "Fall on same level involving ice and snow at farm While engaged in other specified activities"
    },
    {
      "name": "W0079",
      "description": "Fall on same level involving ice and snow at farm During unspecified activity"
    },
    {
      "name": "W008",
      "description": "Fall on same level involving ice and snow at other specified places"
    },
    {
      "name": "W0080",
      "description": "Fall on same level involving ice and snow at other specified places While engaged in sports activity"
    },
    {
      "name": "W0081",
      "description": "Fall on same level involving ice and snow at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0082",
      "description": "Fall on same level involving ice and snow at other specified places While working for income"
    },
    {
      "name": "W0083",
      "description": "Fall on same level involving ice and snow at other specified places While engaged in other types of work"
    },
    {
      "name": "W0084",
      "description": "Fall on same level involving ice and snow at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0088",
      "description": "Fall on same level involving ice and snow at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0089",
      "description": "Fall on same level involving ice and snow at other specified places During unspecified activity"
    },
    {
      "name": "W009",
      "description": "Fall on same level involving ice and snow at unspecified place"
    },
    {
      "name": "W0090",
      "description": "Fall on same level involving ice and snow at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0091",
      "description": "Fall on same level involving ice and snow at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0092",
      "description": "Fall on same level involving ice and snow at unspecified place While working for income"
    },
    {
      "name": "W0093",
      "description": "Fall on same level involving ice and snow at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0094",
      "description": "Fall on same level involving ice and snow at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0098",
      "description": "Fall on same level involving ice and snow at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0099",
      "description": "Fall on same level involving ice and snow at unspecified place During unspecified activity"
    },
    {
      "name": "W010",
      "description": "Fall on same level from slipping, tripping and stumbling at home"
    },
    {
      "name": "W0100",
      "description": "Fall on same level from slipping, tripping and stumbling at home While engaged in sports activity"
    },
    {
      "name": "W0101",
      "description": "Fall on same level from slipping, tripping and stumbling at home While engaged in leisure activity"
    },
    {
      "name": "W0102",
      "description": "Fall on same level from slipping, tripping and stumbling at home While working for income"
    },
    {
      "name": "W0103",
      "description": "Fall on same level from slipping, tripping and stumbling at home While engaged in other types of work"
    },
    {
      "name": "W0104",
      "description": "Fall on same level from slipping, tripping and stumbling at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0108",
      "description": "Fall on same level from slipping, tripping and stumbling at home While engaged in other specified activities"
    },
    {
      "name": "W0109",
      "description": "Fall on same level from slipping, tripping and stumbling at home During unspecified activity"
    },
    {
      "name": "W011",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution"
    },
    {
      "name": "W0110",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While engaged in sports activity"
    },
    {
      "name": "W0111",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0112",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While working for income"
    },
    {
      "name": "W0113",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While engaged in other types of work"
    },
    {
      "name": "W0114",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0118",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0119",
      "description": "Fall on same level from slipping, tripping and stumbling at residential institution During unspecified activity"
    },
    {
      "name": "W012",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area"
    },
    {
      "name": "W0120",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0121",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0122",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0123",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0124",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0128",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0129",
      "description": "Fall on same level from slipping, tripping and stumbling at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W013",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area"
    },
    {
      "name": "W0130",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0131",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0132",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While working for income"
    },
    {
      "name": "W0133",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0134",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0138",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0139",
      "description": "Fall on same level from slipping, tripping and stumbling at sports and athletics area During unspecified activity"
    },
    {
      "name": "W014",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway"
    },
    {
      "name": "W0140",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While engaged in sports activity"
    },
    {
      "name": "W0141",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0142",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While working for income"
    },
    {
      "name": "W0143",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While engaged in other types of work"
    },
    {
      "name": "W0144",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0148",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0149",
      "description": "Fall on same level from slipping, tripping and stumbling at street and highway During unspecified activity"
    },
    {
      "name": "W015",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area"
    },
    {
      "name": "W0150",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0151",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0152",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While working for income"
    },
    {
      "name": "W0153",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0154",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0158",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0159",
      "description": "Fall on same level from slipping, tripping and stumbling at trade and service area During unspecified activity"
    },
    {
      "name": "W016",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area"
    },
    {
      "name": "W0160",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0161",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0162",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While working for income"
    },
    {
      "name": "W0163",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0164",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0168",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0169",
      "description": "Fall on same level from slipping, tripping and stumbling at industrial and construction area During unspecified activity"
    },
    {
      "name": "W017",
      "description": "Fall on same level from slipping, tripping and stumbling at farm"
    },
    {
      "name": "W0170",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While engaged in sports activity"
    },
    {
      "name": "W0171",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While engaged in leisure activity"
    },
    {
      "name": "W0172",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While working for income"
    },
    {
      "name": "W0173",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While engaged in other types of work"
    },
    {
      "name": "W0174",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0178",
      "description": "Fall on same level from slipping, tripping and stumbling at farm While engaged in other specified activities"
    },
    {
      "name": "W0179",
      "description": "Fall on same level from slipping, tripping and stumbling at farm During unspecified activity"
    },
    {
      "name": "W018",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places"
    },
    {
      "name": "W0180",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While engaged in sports activity"
    },
    {
      "name": "W0181",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0182",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While working for income"
    },
    {
      "name": "W0183",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While engaged in other types of work"
    },
    {
      "name": "W0184",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0188",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0189",
      "description": "Fall on same level from slipping, tripping and stumbling at other specified places During unspecified activity"
    },
    {
      "name": "W019",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place"
    },
    {
      "name": "W0190",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0191",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0192",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While working for income"
    },
    {
      "name": "W0193",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0194",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0198",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0199",
      "description": "Fall on same level from slipping, tripping and stumbling at unspecified place During unspecified activity"
    },
    {
      "name": "W020",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home"
    },
    {
      "name": "W0200",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home While engaged in sports activity"
    },
    {
      "name": "W0201",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home While engaged in leisure activity"
    },
    {
      "name": "W0202",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home While working for income"
    },
    {
      "name": "W0203",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home While engaged in other types of work"
    },
    {
      "name": "W0204",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0208",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home  While engaged in other specified activities"
    },
    {
      "name": "W0209",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at home During unspecified activity"
    },
    {
      "name": "W021",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution"
    },
    {
      "name": "W0210",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While engaged in sports activity"
    },
    {
      "name": "W0211",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0212",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While working for income"
    },
    {
      "name": "W0213",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While engaged in other types of work"
    },
    {
      "name": "W0214",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0218",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution While engaged in other specified activities "
    },
    {
      "name": "W0219",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at residential institution During unspecified activity"
    },
    {
      "name": "W022",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area"
    },
    {
      "name": "W0220",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area  While engaged in sports activity"
    },
    {
      "name": "W0221",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0222",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0223",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0224",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0228",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0229",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W023",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area"
    },
    {
      "name": "W0230",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0231",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0232",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While working for income"
    },
    {
      "name": "W0233",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0234",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0238",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0239",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at sports and athletics area During unspecified activity"
    },
    {
      "name": "W024",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway"
    },
    {
      "name": "W0240",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While engaged in sports activity"
    },
    {
      "name": "W0241",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0242",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While working for income"
    },
    {
      "name": "W0243",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While engaged in other types of work"
    },
    {
      "name": "W0244",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0248",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0249",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at street and highway During unspecified activity"
    },
    {
      "name": "W025",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area"
    },
    {
      "name": "W0250",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0251",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0252",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While working for income"
    },
    {
      "name": "W0253",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0254",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0258",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0259",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at trade and service area During unspecified activity"
    },
    {
      "name": "W026",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area"
    },
    {
      "name": "W0260",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0261",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0262",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While working for income"
    },
    {
      "name": "W0263",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0264",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0268",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0269",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at industrial and construction area During unspecified activity"
    },
    {
      "name": "W027",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm"
    },
    {
      "name": "W0270",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While engaged in sports activity"
    },
    {
      "name": "W0271",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While engaged in leisure activity"
    },
    {
      "name": "W0272",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While working for income"
    },
    {
      "name": "W0273",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While engaged in other types of work"
    },
    {
      "name": "W0274",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0278",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm While engaged in other specified activities"
    },
    {
      "name": "W0279",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at farm During unspecified activity"
    },
    {
      "name": "W028",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places"
    },
    {
      "name": "W0280",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While engaged in sports activity"
    },
    {
      "name": "W0281",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0282",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While working for income"
    },
    {
      "name": "W0283",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While engaged in other types of work"
    },
    {
      "name": "W0284",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0288",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0289",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at other specified places During unspecified activity"
    },
    {
      "name": "W029",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place"
    },
    {
      "name": "W0290",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0291",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0292",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While working for income"
    },
    {
      "name": "W0293",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0294",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0298",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0299",
      "description": "Fall involving ice-skates, skis, roller-skates or skateboards at unspecified place During unspecified activity"
    },
    {
      "name": "W030",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home"
    },
    {
      "name": "W0300",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While engaged in sports activity"
    },
    {
      "name": "W0301",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While engaged in leisure activity"
    },
    {
      "name": "W0302",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While working for income"
    },
    {
      "name": "W0303",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While engaged in other types of work"
    },
    {
      "name": "W0304",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0308",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home While engaged in other specified activities"
    },
    {
      "name": "W0309",
      "description": "Other fall on same level due to collision with, or pushing by, another person at home During unspecified activity"
    },
    {
      "name": "W031",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution"
    },
    {
      "name": "W0310",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While engaged in sports activity"
    },
    {
      "name": "W0311",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0312",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While working for income"
    },
    {
      "name": "W0313",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While engaged in other types of work"
    },
    {
      "name": "W0314",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0318",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0319",
      "description": "Other fall on same level due to collision with, or pushing by, another person at residential institution During unspecified activity"
    },
    {
      "name": "W032",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area"
    },
    {
      "name": "W0320",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0321",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0322",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0323",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0324",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0328",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0329",
      "description": "Other fall on same level due to collision with, or pushing by, another person at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W033",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area"
    },
    {
      "name": "W0330",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0331",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0332",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While working for income"
    },
    {
      "name": "W0333",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0334",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0338",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0339",
      "description": "Other fall on same level due to collision with, or pushing by, another person at sports and athletics area During unspecified activity"
    },
    {
      "name": "W034",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway"
    },
    {
      "name": "W0340",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While engaged in sports activity"
    },
    {
      "name": "W0341",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0342",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While working for income"
    },
    {
      "name": "W0343",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While engaged in other types of work"
    },
    {
      "name": "W0344",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0348",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0349",
      "description": "Other fall on same level due to collision with, or pushing by, another person at street and highway During unspecified activity"
    },
    {
      "name": "W035",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area"
    },
    {
      "name": "W0350",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0351",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0352",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While working for income"
    },
    {
      "name": "W0353",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0354",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0358",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0359",
      "description": "Other fall on same level due to collision with, or pushing by, another person at trade and service area During unspecified activity"
    },
    {
      "name": "W036",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area"
    },
    {
      "name": "W0360",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0361",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0362",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While working for income"
    },
    {
      "name": "W0363",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0364",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0368",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0369",
      "description": "Other fall on same level due to collision with, or pushing by, another person at industrial and construction area During unspecified activity"
    },
    {
      "name": "W037",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm"
    },
    {
      "name": "W0370",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While engaged in sports activity"
    },
    {
      "name": "W0371",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While engaged in leisure activity"
    },
    {
      "name": "W0372",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While working for income"
    },
    {
      "name": "W0373",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While engaged in other types of work"
    },
    {
      "name": "W0374",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0378",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm While engaged in other specified activities"
    },
    {
      "name": "W0379",
      "description": "Other fall on same level due to collision with, or pushing by, another person at farm During unspecified activity"
    },
    {
      "name": "W038",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places"
    },
    {
      "name": "W0380",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While engaged in sports activity"
    },
    {
      "name": "W0381",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0382",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While working for income"
    },
    {
      "name": "W0383",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While engaged in other types of work"
    },
    {
      "name": "W0384",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0388",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0389",
      "description": "Other fall on same level due to collision with, or pushing by, another person at other specified places During unspecified activity"
    },
    {
      "name": "W039",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place"
    },
    {
      "name": "W0390",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0391",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0392",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While working for income"
    },
    {
      "name": "W0393",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0394",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0398",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0399",
      "description": "Other fall on same level due to collision with, or pushing by, another person at unspecified place During unspecified activity"
    },
    {
      "name": "W040",
      "description": "Fall while being carried or supported by other persons at home"
    },
    {
      "name": "W0400",
      "description": "Fall while being carried or supported by other persons at home While engaged in sports activity"
    },
    {
      "name": "W0401",
      "description": "Fall while being carried or supported by other persons at home While engaged in leisure activity"
    },
    {
      "name": "W0402",
      "description": "Fall while being carried or supported by other persons at home While working for income"
    },
    {
      "name": "W0403",
      "description": "Fall while being carried or supported by other persons at home While engaged in other types of work"
    },
    {
      "name": "W0404",
      "description": "Fall while being carried or supported by other persons at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0408",
      "description": "Fall while being carried or supported by other persons at home While engaged in other specified activities"
    },
    {
      "name": "W0409",
      "description": "Fall while being carried or supported by other persons at home During unspecified activity"
    },
    {
      "name": "W041",
      "description": "Fall while being carried or supported by other persons at residential institution"
    },
    {
      "name": "W0410",
      "description": "Fall while being carried or supported by other persons at residential institution While engaged in sports activity"
    },
    {
      "name": "W0411",
      "description": "Fall while being carried or supported by other persons at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0412",
      "description": "Fall while being carried or supported by other persons at residential institution While working for income"
    },
    {
      "name": "W0413",
      "description": "Fall while being carried or supported by other persons at residential institution While engaged in other types of work"
    },
    {
      "name": "W0414",
      "description": "Fall while being carried or supported by other persons at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0418",
      "description": "Fall while being carried or supported by other persons at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0419",
      "description": "Fall while being carried or supported by other persons at residential institution During unspecified activity"
    },
    {
      "name": "W042",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area"
    },
    {
      "name": "W0420",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0421",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0422",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0423",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While engaged in other types of work "
    },
    {
      "name": "W0424",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0428",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0429",
      "description": "Fall while being carried or supported by other persons at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W043",
      "description": "Fall while being carried or supported by other persons at sports and athletics area"
    },
    {
      "name": "W0430",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0431",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0432",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While working for income"
    },
    {
      "name": "W0433",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0434",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0438",
      "description": "Fall while being carried or supported by other persons at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0439",
      "description": "Fall while being carried or supported by other persons at sports and athletics area During unspecified activity"
    },
    {
      "name": "W044",
      "description": "Fall while being carried or supported by other persons at street and highway"
    },
    {
      "name": "W0440",
      "description": "Fall while being carried or supported by other persons at street and highway While engaged in sports activity"
    },
    {
      "name": "W0441",
      "description": "Fall while being carried or supported by other persons at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0442",
      "description": "Fall while being carried or supported by other persons at street and highway While working for income"
    },
    {
      "name": "W0443",
      "description": "Fall while being carried or supported by other persons at street and highway While engaged in other types of work"
    },
    {
      "name": "W0444",
      "description": "Fall while being carried or supported by other persons at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0448",
      "description": "Fall while being carried or supported by other persons at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0449",
      "description": "Fall while being carried or supported by other persons at street and highway During unspecified activity"
    },
    {
      "name": "W045",
      "description": "Fall while being carried or supported by other persons at trade and service area"
    },
    {
      "name": "W0450",
      "description": "Fall while being carried or supported by other persons at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0451",
      "description": "Fall while being carried or supported by other persons at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0452",
      "description": "Fall while being carried or supported by other persons at trade and service area While working for income"
    },
    {
      "name": "W0453",
      "description": "Fall while being carried or supported by other persons at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0454",
      "description": "Fall while being carried or supported by other persons at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0458",
      "description": "Fall while being carried or supported by other persons at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0459",
      "description": "Fall while being carried or supported by other persons at trade and service area During unspecified activity"
    },
    {
      "name": "W046",
      "description": "Fall while being carried or supported by other persons at industrial and construction area"
    },
    {
      "name": "W0460",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0461",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0462",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While working for income"
    },
    {
      "name": "W0463",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0464",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0468",
      "description": "Fall while being carried or supported by other persons at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0469",
      "description": "Fall while being carried or supported by other persons at industrial and construction area During unspecified activity"
    },
    {
      "name": "W047",
      "description": "Fall while being carried or supported by other persons at farm"
    },
    {
      "name": "W0470",
      "description": "Fall while being carried or supported by other persons at farm While engaged in sports activity"
    },
    {
      "name": "W0471",
      "description": "Fall while being carried or supported by other persons at farm While engaged in leisure activity"
    },
    {
      "name": "W0472",
      "description": "Fall while being carried or supported by other persons at farm While working for income"
    },
    {
      "name": "W0473",
      "description": "Fall while being carried or supported by other persons at farm While engaged in other types of work"
    },
    {
      "name": "W0474",
      "description": "Fall while being carried or supported by other persons at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0478",
      "description": "Fall while being carried or supported by other persons at farm While engaged in other specified activities"
    },
    {
      "name": "W0479",
      "description": "Fall while being carried or supported by other persons at farm During unspecified activity"
    },
    {
      "name": "W048",
      "description": "Fall while being carried or supported by other persons at other specified places"
    },
    {
      "name": "W0480",
      "description": "Fall while being carried or supported by other persons at other specified places While engaged in sports activity"
    },
    {
      "name": "W0481",
      "description": "Fall while being carried or supported by other persons at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0482",
      "description": "Fall while being carried or supported by other persons at other specified places While working for income"
    },
    {
      "name": "W0483",
      "description": "Fall while being carried or supported by other persons at other specified places While engaged in other types of work"
    },
    {
      "name": "W0484",
      "description": "Fall while being carried or supported by other persons at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0488",
      "description": "Fall while being carried or supported by other persons at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0489",
      "description": "Fall while being carried or supported by other persons at other specified places During unspecified activity"
    },
    {
      "name": "W049",
      "description": "Fall while being carried or supported by other persons at unspecified place"
    },
    {
      "name": "W0490",
      "description": "Fall while being carried or supported by other persons at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0491",
      "description": "Fall while being carried or supported by other persons at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0492",
      "description": "Fall while being carried or supported by other persons at unspecified place While working for income"
    },
    {
      "name": "W0493",
      "description": "Fall while being carried or supported by other persons at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0494",
      "description": "Fall while being carried or supported by other persons at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0498",
      "description": "Fall while being carried or supported by other persons at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0499",
      "description": "Fall while being carried or supported by other persons at unspecified place During unspecified activity"
    },
    {
      "name": "W050",
      "description": "Fall involving wheelchair at home"
    },
    {
      "name": "W0500",
      "description": "Fall involving wheelchair at home While engaged in sports activity"
    },
    {
      "name": "W0501",
      "description": "Fall involving wheelchair at home While engaged in leisure activity"
    },
    {
      "name": "W0502",
      "description": "Fall involving wheelchair at home While working for income"
    },
    {
      "name": "W0503",
      "description": "Fall involving wheelchair at home While engaged in other types of work"
    },
    {
      "name": "W0504",
      "description": "Fall involving wheelchair at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0508",
      "description": "Fall involving wheelchair at home While engaged in other specified activities"
    },
    {
      "name": "W0509",
      "description": "Fall involving wheelchair at home During unspecified activity"
    },
    {
      "name": "W051",
      "description": "Fall involving wheelchair at residential institution"
    },
    {
      "name": "W0510",
      "description": "Fall involving wheelchair at residential institution While engaged in sports activity"
    },
    {
      "name": "W0511",
      "description": "Fall involving wheelchair at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0512",
      "description": "Fall involving wheelchair at residential institution While working for income"
    },
    {
      "name": "W0513",
      "description": "Fall involving wheelchair at residential institution While engaged in other types of work"
    },
    {
      "name": "W0514",
      "description": "Fall involving wheelchair at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0518",
      "description": "Fall involving wheelchair at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0519",
      "description": "Fall involving wheelchair at residential institution During unspecified activity"
    },
    {
      "name": "W052",
      "description": "Fall involving wheelchair at school,other institution and public administrative area"
    },
    {
      "name": "W0520",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0521",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0522",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0523",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0524",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0528",
      "description": "Fall involving wheelchair at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0529",
      "description": "Fall involving wheelchair at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W053",
      "description": "Fall involving wheelchair at sports and athletics area"
    },
    {
      "name": "W0530",
      "description": "Fall involving wheelchair at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0531",
      "description": "Fall involving wheelchair at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0532",
      "description": "Fall involving wheelchair at sports and athletics area While working for income"
    },
    {
      "name": "W0533",
      "description": "Fall involving wheelchair at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0534",
      "description": "Fall involving wheelchair at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0538",
      "description": "Fall involving wheelchair at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0539",
      "description": "Fall involving wheelchair at sports and athletics area During unspecified activity"
    },
    {
      "name": "W054",
      "description": "Fall involving wheelchair at street and highway"
    },
    {
      "name": "W0540",
      "description": "Fall involving wheelchair at street and highway While engaged in sports activity"
    },
    {
      "name": "W0541",
      "description": "Fall involving wheelchair at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0542",
      "description": "Fall involving wheelchair at street and highway While working for income"
    },
    {
      "name": "W0543",
      "description": "Fall involving wheelchair at street and highway While engaged in other types of work"
    },
    {
      "name": "W0544",
      "description": "Fall involving wheelchair at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0548",
      "description": "Fall involving wheelchair at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0549",
      "description": "Fall involving wheelchair at street and highway During unspecified activity"
    },
    {
      "name": "W055",
      "description": "Fall involving wheelchair at trade and service area"
    },
    {
      "name": "W0550",
      "description": "Fall involving wheelchair at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0551",
      "description": "Fall involving wheelchair at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0552",
      "description": "Fall involving wheelchair at trade and service area While working for income"
    },
    {
      "name": "W0553",
      "description": "Fall involving wheelchair at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0554",
      "description": "Fall involving wheelchair at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0558",
      "description": "Fall involving wheelchair at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0559",
      "description": "Fall involving wheelchair at trade and service area During unspecified activity"
    },
    {
      "name": "W056",
      "description": "Fall involving wheelchair at industrial and construction area"
    },
    {
      "name": "W0560",
      "description": "Fall involving wheelchair at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0561",
      "description": "Fall involving wheelchair at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0562",
      "description": "Fall involving wheelchair at industrial and construction area While working for income"
    },
    {
      "name": "W0563",
      "description": "Fall involving wheelchair at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0564",
      "description": "Fall involving wheelchair at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0568",
      "description": "Fall involving wheelchair at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0569",
      "description": "Fall involving wheelchair at industrial and construction area During unspecified activity"
    },
    {
      "name": "W057",
      "description": "Fall involving wheelchair at farm"
    },
    {
      "name": "W0570",
      "description": "Fall involving wheelchair at farm While engaged in sports activity"
    },
    {
      "name": "W0571",
      "description": "Fall involving wheelchair at farm While engaged in leisure activity"
    },
    {
      "name": "W0572",
      "description": "Fall involving wheelchair at farm While working for income"
    },
    {
      "name": "W0573",
      "description": "Fall involving wheelchair at farm While engaged in other types of work"
    },
    {
      "name": "W0574",
      "description": "Fall involving wheelchair at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0578",
      "description": "Fall involving wheelchair at farm While engaged in other specified activities"
    },
    {
      "name": "W0579",
      "description": "Fall involving wheelchair at farm During unspecified activity"
    },
    {
      "name": "W058",
      "description": "Fall involving wheelchair at other specified places"
    },
    {
      "name": "W0580",
      "description": "Fall involving wheelchair at other specified places While engaged in sports activity"
    },
    {
      "name": "W0581",
      "description": "Fall involving wheelchair at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0582",
      "description": "Fall involving wheelchair at other specified places While working for income"
    },
    {
      "name": "W0583",
      "description": "Fall involving wheelchair at other specified places While engaged in other types of work"
    },
    {
      "name": "W0584",
      "description": "Fall involving wheelchair at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0588",
      "description": "Fall involving wheelchair at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0589",
      "description": "Fall involving wheelchair at other specified places During unspecified activity"
    },
    {
      "name": "W059",
      "description": "Fall involving wheelchair at unspecified place"
    },
    {
      "name": "W0590",
      "description": "Fall involving wheelchair at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0591",
      "description": "Fall involving wheelchair at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0592",
      "description": "Fall involving wheelchair at unspecified place While working for income"
    },
    {
      "name": "W0593",
      "description": "Fall involving wheelchair at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0594",
      "description": "Fall involving wheelchair at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0598",
      "description": "Fall involving wheelchair at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0599",
      "description": "Fall involving wheelchair at unspecified place During unspecified activity"
    },
    {
      "name": "W060",
      "description": "Fall involing bed at home"
    },
    {
      "name": "W0600",
      "description": "Fall involing bed at home While engaged in sports activity"
    },
    {
      "name": "W0601",
      "description": "Fall involing bed at home While engaged in leisure activity"
    },
    {
      "name": "W0602",
      "description": "Fall involing bed at home While working for income"
    },
    {
      "name": "W0603",
      "description": "Fall involing bed at home While engaged in other types of work"
    },
    {
      "name": "W0604",
      "description": "Fall involing bed at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0608",
      "description": "Fall involing bed at home While engaged in other specified activities"
    },
    {
      "name": "W0609",
      "description": "Fall involing bed at home During unspecified activity"
    },
    {
      "name": "W061",
      "description": "Fall involing bed at residential institution"
    },
    {
      "name": "W0610",
      "description": "Fall involing bed at residential institution While engaged in sports activity"
    },
    {
      "name": "W0611",
      "description": "Fall involing bed at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0612",
      "description": "Fall involing bed at residential institution While working for income"
    },
    {
      "name": "W0613",
      "description": "Fall involing bed at residential institution While engaged in other types of work"
    },
    {
      "name": "W0614",
      "description": "Fall involing bed at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0618",
      "description": "Fall involing bed at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0619",
      "description": "Fall involing bed at residential institution During unspecified activity"
    },
    {
      "name": "W062",
      "description": "Fall involing bed at school,other institution and public administrative area"
    },
    {
      "name": "W0620",
      "description": "Fall involing bed at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0621",
      "description": "Fall involing bed at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0622",
      "description": "Fall involing bed at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0623",
      "description": "Fall involing bed at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0624",
      "description": "Fall involing bed at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0628",
      "description": "Fall involing bed at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0629",
      "description": "Fall involing bed at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W063",
      "description": "Fall involing bed at sports and athletics area"
    },
    {
      "name": "W0630",
      "description": "Fall involing bed at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0631",
      "description": "Fall involing bed at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0632",
      "description": "Fall involing bed at sports and athletics area While working for income"
    },
    {
      "name": "W0633",
      "description": "Fall involing bed at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0634",
      "description": "Fall involing bed at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0638",
      "description": "Fall involing bed at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0639",
      "description": "Fall involing bed at sports and athletics area During unspecified activity"
    },
    {
      "name": "W064",
      "description": "Fall involing bed at street and highway"
    },
    {
      "name": "W0640",
      "description": "Fall involing bed at street and highway While engaged in sports activity"
    },
    {
      "name": "W0641",
      "description": "Fall involing bed at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0642",
      "description": "Fall involing bed at street and highway While working for income"
    },
    {
      "name": "W0643",
      "description": "Fall involing bed at street and highway While engaged in other types of work"
    },
    {
      "name": "W0644",
      "description": "Fall involing bed at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0648",
      "description": "Fall involing bed at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0649",
      "description": "Fall involing bed at street and highway During unspecified activity"
    },
    {
      "name": "W065",
      "description": "Fall involing bed at trade and service area"
    },
    {
      "name": "W0650",
      "description": "Fall involing bed at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0651",
      "description": "Fall involing bed at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0652",
      "description": "Fall involing bed at trade and service area While working for income"
    },
    {
      "name": "W0653",
      "description": "Fall involing bed at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0654",
      "description": "Fall involing bed at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0658",
      "description": "Fall involing bed at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0659",
      "description": "Fall involing bed at trade and service area During unspecified activity"
    },
    {
      "name": "W066",
      "description": "Fall involing bed at industrial and construction area"
    },
    {
      "name": "W0660",
      "description": "Fall involing bed at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0661",
      "description": "Fall involing bed at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0662",
      "description": "Fall involing bed at industrial and construction area While working for income"
    },
    {
      "name": "W0663",
      "description": "Fall involing bed at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0664",
      "description": "Fall involing bed at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0668",
      "description": "Fall involing bed at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0669",
      "description": "Fall involing bed at industrial and construction area During unspecified activity"
    },
    {
      "name": "W067",
      "description": "Fall involing bed at farm"
    },
    {
      "name": "W0670",
      "description": "Fall involing bed at farm While engaged in sports activity"
    },
    {
      "name": "W0671",
      "description": "Fall involing bed at farm While engaged in leisure activity"
    },
    {
      "name": "W0672",
      "description": "Fall involing bed at farm While working for income"
    },
    {
      "name": "W0673",
      "description": "Fall involing bed at farm While engaged in other types of work"
    },
    {
      "name": "W0674",
      "description": "Fall involing bed at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0678",
      "description": "Fall involing bed at farm While engaged in other specified activities"
    },
    {
      "name": "W0679",
      "description": "Fall involing bed at farm  During unspecified activity"
    },
    {
      "name": "W068",
      "description": "Fall involing bed at other specified places"
    },
    {
      "name": "W0680",
      "description": "Fall involing bed at other specified places While engaged in sports activity"
    },
    {
      "name": "W0681",
      "description": "Fall involing bed at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0682",
      "description": "Fall involing bed at other specified places While working for income"
    },
    {
      "name": "W0683",
      "description": "Fall involing bed at other specified places While engaged in other types of work"
    },
    {
      "name": "W0684",
      "description": "Fall involing bed at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0688",
      "description": "Fall involing bed at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0689",
      "description": "Fall involing bed at other specified places During unspecified activity"
    },
    {
      "name": "W069",
      "description": "Fall involing bed at unspecified place"
    },
    {
      "name": "W0690",
      "description": "Fall involing bed at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0691",
      "description": "Fall involing bed at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0692",
      "description": "Fall involing bed at unspecified place While working for income"
    },
    {
      "name": "W0693",
      "description": "Fall involing bed at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0694",
      "description": "Fall involing bed at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0698",
      "description": "Fall involing bed at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0699",
      "description": "Fall involing bed at unspecified place During unspecified activity"
    },
    {
      "name": "W070",
      "description": "Fall involving chair at home"
    },
    {
      "name": "W0700",
      "description": "Fall involving chair at home While engaged in sports activity"
    },
    {
      "name": "W0701",
      "description": "Fall involving chair at home While engaged in leisure activity"
    },
    {
      "name": "W0702",
      "description": "Fall involving chair at home While working for income"
    },
    {
      "name": "W0703",
      "description": "Fall involving chair at home While engaged in other types of work"
    },
    {
      "name": "W0704",
      "description": "Fall involving chair at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0708",
      "description": "Fall involving chair at home While engaged in other specified activities"
    },
    {
      "name": "W0709",
      "description": "Fall involving chair at home During unspecified activity"
    },
    {
      "name": "W071",
      "description": "Fall involving chair at residential institution"
    },
    {
      "name": "W0710",
      "description": "Fall involving chair at residential institution While engaged in sports activity"
    },
    {
      "name": "W0711",
      "description": "Fall involving chair at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0712",
      "description": "Fall involving chair at residential institution While working for income"
    },
    {
      "name": "W0713",
      "description": "Fall involving chair at residential institution While engaged in other types of work"
    },
    {
      "name": "W0714",
      "description": "Fall involving chair at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0718",
      "description": "Fall involving chair at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0719",
      "description": "Fall involving chair at residential institution During unspecified activity"
    },
    {
      "name": "W072",
      "description": "Fall involving chair at school,other institution and public administrative area"
    },
    {
      "name": "W0720",
      "description": "Fall involving chair at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0721",
      "description": "Fall involving chair at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0722",
      "description": "Fall involving chair at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0723",
      "description": "Fall involving chair at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0724",
      "description": "Fall involving chair at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0728",
      "description": "Fall involving chair at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0729",
      "description": "Fall involving chair at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W073",
      "description": "Fall involving chair at sports and athletics area"
    },
    {
      "name": "W0730",
      "description": "Fall involving chair at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0731",
      "description": "Fall involving chair at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0732",
      "description": "Fall involving chair at sports and athletics area While working for income"
    },
    {
      "name": "W0733",
      "description": "Fall involving chair at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0734",
      "description": "Fall involving chair at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0738",
      "description": "Fall involving chair at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0739",
      "description": "Fall involving chair at sports and athletics area During unspecified activity"
    },
    {
      "name": "W074",
      "description": "Fall involving chair at street and highway"
    },
    {
      "name": "W0740",
      "description": "Fall involving chair at street and highway While engaged in sports activity"
    },
    {
      "name": "W0741",
      "description": "Fall involving chair at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0742",
      "description": "Fall involving chair at street and highway While working for income"
    },
    {
      "name": "W0743",
      "description": "Fall involving chair at street and highway While engaged in other types of work"
    },
    {
      "name": "W0744",
      "description": "Fall involving chair at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0748",
      "description": "Fall involving chair at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0749",
      "description": "Fall involving chair at street and highway During unspecified activity"
    },
    {
      "name": "W075",
      "description": "Fall involving chair at trade and service area"
    },
    {
      "name": "W0750",
      "description": "Fall involving chair at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0751",
      "description": "Fall involving chair at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0752",
      "description": "Fall involving chair at trade and service area While working for income"
    },
    {
      "name": "W0753",
      "description": "Fall involving chair at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0754",
      "description": "Fall involving chair at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0758",
      "description": "Fall involving chair at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0759",
      "description": "Fall involving chair at trade and service area During unspecified activity"
    },
    {
      "name": "W076",
      "description": "Fall involving chair at industrial and construction area"
    },
    {
      "name": "W0760",
      "description": "Fall involving chair at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0761",
      "description": "Fall involving chair at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0762",
      "description": "Fall involving chair at industrial and construction area While working for income"
    },
    {
      "name": "W0763",
      "description": "Fall involving chair at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0764",
      "description": "Fall involving chair at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0768",
      "description": "Fall involving chair at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0769",
      "description": "Fall involving chair at industrial and construction area During unspecified activity"
    },
    {
      "name": "W077",
      "description": "Fall involving chair at farm"
    },
    {
      "name": "W0770",
      "description": "Fall involving chair at farm While engaged in sports activity"
    },
    {
      "name": "W0771",
      "description": "Fall involving chair at farm While engaged in leisure activity"
    },
    {
      "name": "W0772",
      "description": "Fall involving chair at farm While working for income"
    },
    {
      "name": "W0773",
      "description": "Fall involving chair at farm While engaged in other types of work"
    },
    {
      "name": "W0774",
      "description": "Fall involving chair at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0778",
      "description": "Fall involving chair at farm While engaged in other specified activities"
    },
    {
      "name": "W0779",
      "description": "Fall involving chair at farm During unspecified activity"
    },
    {
      "name": "W078",
      "description": "Fall involving chair at other specified places"
    },
    {
      "name": "W0780",
      "description": "Fall involving chair at other specified places While engaged in sports activity"
    },
    {
      "name": "W0781",
      "description": "Fall involving chair at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0782",
      "description": "Fall involving chair at other specified places While working for income"
    },
    {
      "name": "W0783",
      "description": "Fall involving chair at other specified places While engaged in other types of work"
    },
    {
      "name": "W0784",
      "description": "Fall involving chair at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0788",
      "description": "Fall involving chair at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0789",
      "description": "Fall involving chair at other specified places During unspecified activity"
    },
    {
      "name": "W079",
      "description": "Fall involving chair at unspecified place"
    },
    {
      "name": "W0790",
      "description": "Fall involving chair at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0791",
      "description": "Fall involving chair at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0792",
      "description": "Fall involving chair at unspecified place While working for income"
    },
    {
      "name": "W0793",
      "description": "Fall involving chair at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0794",
      "description": "Fall involving chair at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0798",
      "description": "Fall involving chair at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0799",
      "description": "Fall involving chair at unspecified place During unspecified activity"
    },
    {
      "name": "W080",
      "description": "Fall involving other furniture at home "
    },
    {
      "name": "W0800",
      "description": "Fall involving other furniture at home  While engaged in sports activity"
    },
    {
      "name": "W0801",
      "description": "Fall involving other furniture at home  While engaged in leisure activity"
    },
    {
      "name": "W0802",
      "description": "Fall involving other furniture at home While working for income"
    },
    {
      "name": "W0803",
      "description": "Fall involving other furniture at home  While engaged in other types of work"
    },
    {
      "name": "W0804",
      "description": "Fall involving other furniture at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0808",
      "description": "Fall involving other furniture at home While engaged in other specified activities"
    },
    {
      "name": "W0809",
      "description": "Fall involving other furniture at home During unspecified activity"
    },
    {
      "name": "W081",
      "description": "Fall involving other furniture at residential institution"
    },
    {
      "name": "W0810",
      "description": "Fall involving other furniture at residential institution While engaged in sports activity"
    },
    {
      "name": "W0811",
      "description": "Fall involving other furniture at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0812",
      "description": "Fall involving other furniture at residential institution While working for income"
    },
    {
      "name": "W0813",
      "description": "Fall involving other furniture at residential institution While engaged in other types of work"
    },
    {
      "name": "W0814",
      "description": "Fall involving other furniture at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0818",
      "description": "Fall involving other furniture at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0819",
      "description": "Fall involving other furniture at residential institution During unspecified activity"
    },
    {
      "name": "W082",
      "description": "Fall involving other furniture at school,other institution and public administrative area"
    },
    {
      "name": "W0820",
      "description": "Fall involving other furniture at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0821",
      "description": "Fall involving other furniture at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0822",
      "description": "Fall involving other furniture at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0823",
      "description": "Fall involving other furniture at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0824",
      "description": "Fall involving other furniture at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0828",
      "description": "Fall involving other furniture at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0829",
      "description": "Fall involving other furniture at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W083",
      "description": "Fall involving other furniture at sports and athletics area"
    },
    {
      "name": "W0830",
      "description": "Fall involving other furniture at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0831",
      "description": "Fall involving other furniture at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0832",
      "description": "Fall involving other furniture at sports and athletics area While working for income"
    },
    {
      "name": "W0833",
      "description": "Fall involving other furniture at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0834",
      "description": "Fall involving other furniture at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0838",
      "description": "Fall involving other furniture at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0839",
      "description": "Fall involving other furniture at sports and athletics area During unspecified activity"
    },
    {
      "name": "W084",
      "description": "Fall involving other furniture at street and highway"
    },
    {
      "name": "W0840",
      "description": "Fall involving other furniture at street and highway While engaged in sports activity"
    },
    {
      "name": "W0841",
      "description": "Fall involving other furniture at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0842",
      "description": "Fall involving other furniture at street and highway While working for income"
    },
    {
      "name": "W0843",
      "description": "Fall involving other furniture at street and highway While engaged in other types of work"
    },
    {
      "name": "W0844",
      "description": "Fall involving other furniture at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0848",
      "description": "Fall involving other furniture at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0849",
      "description": "Fall involving other furniture at street and highway During unspecified activity"
    },
    {
      "name": "W085",
      "description": "Fall involving other furniture at trade and service area"
    },
    {
      "name": "W0850",
      "description": "Fall involving other furniture at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0851",
      "description": "Fall involving other furniture at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0852",
      "description": "Fall involving other furniture at trade and service area While working for income"
    },
    {
      "name": "W0853",
      "description": "Fall involving other furniture at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0854",
      "description": "Fall involving other furniture at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0858",
      "description": "Fall involving other furniture at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0859",
      "description": "Fall involving other furniture at trade and service area During unspecified activity"
    },
    {
      "name": "W086",
      "description": "Fall involving other furniture at industrial and construction area"
    },
    {
      "name": "W0860",
      "description": "Fall involving other furniture at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0861",
      "description": "Fall involving other furniture at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0862",
      "description": "Fall involving other furniture at industrial and construction area While working for income"
    },
    {
      "name": "W0863",
      "description": "Fall involving other furniture at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0864",
      "description": "Fall involving other furniture at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0868",
      "description": "Fall involving other furniture at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0869",
      "description": "Fall involving other furniture at industrial and construction area During unspecified activity"
    },
    {
      "name": "W087",
      "description": "Fall involving other furniture at farm"
    },
    {
      "name": "W0870",
      "description": "Fall involving other furniture at farm While engaged in sports activity"
    },
    {
      "name": "W0871",
      "description": "Fall involving other furniture at farm While engaged in leisure activity"
    },
    {
      "name": "W0872",
      "description": "Fall involving other furniture at farm While working for income"
    },
    {
      "name": "W0873",
      "description": "Fall involving other furniture at farm While engaged in other types of work"
    },
    {
      "name": "W0874",
      "description": "Fall involving other furniture at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0878",
      "description": "Fall involving other furniture at farm While engaged in other specified activities"
    },
    {
      "name": "W0879",
      "description": "Fall involving other furniture at farm During unspecified activity"
    },
    {
      "name": "W088",
      "description": "Fall involving other furniture at other specified places"
    },
    {
      "name": "W0880",
      "description": "Fall involving other furniture at other specified places While engaged in sports activity"
    },
    {
      "name": "W0881",
      "description": "Fall involving other furniture at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0882",
      "description": "Fall involving other furniture at other specified places While working for income"
    },
    {
      "name": "W0883",
      "description": "Fall involving other furniture at other specified places While engaged in other types of work"
    },
    {
      "name": "W0884",
      "description": "Fall involving other furniture at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0888",
      "description": "Fall involving other furniture at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0889",
      "description": "Fall involving other furniture at other specified places During unspecified activity"
    },
    {
      "name": "W089",
      "description": "Fall involving other furniture at unspecified place"
    },
    {
      "name": "W0890",
      "description": "Fall involving other furniture at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0891",
      "description": "Fall involving other furniture at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0892",
      "description": "Fall involving other furniture at unspecified place While working for income"
    },
    {
      "name": "W0893",
      "description": "Fall involving other furniture at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0894",
      "description": "Fall involving other furniture at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0898",
      "description": "Fall involving other furniture at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0899",
      "description": "Fall involving other furniture at unspecified place During unspecified activity"
    },
    {
      "name": "W090",
      "description": "Fall involving playground equipment at home"
    },
    {
      "name": "W0900",
      "description": "Fall involving playground equipment at home While engaged in sports activity"
    },
    {
      "name": "W0901",
      "description": "Fall involving playground equipment at home While engaged in leisure activity"
    },
    {
      "name": "W0902",
      "description": "Fall involving playground equipment at home While working for income"
    },
    {
      "name": "W0903",
      "description": "Fall involving playground equipment at home While engaged in other types of work"
    },
    {
      "name": "W0904",
      "description": "Fall involving playground equipment at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0908",
      "description": "Fall involving playground equipment at home While engaged in other specified activities"
    },
    {
      "name": "W0909",
      "description": "Fall involving playground equipment at home During unspecified activity"
    },
    {
      "name": "W091",
      "description": "Fall involving playground equipment at residential institution"
    },
    {
      "name": "W0910",
      "description": "Fall involving playground equipment at residential institution While engaged in sports activity"
    },
    {
      "name": "W0911",
      "description": "Fall involving playground equipment at residential institution While engaged in leisure activity"
    },
    {
      "name": "W0912",
      "description": "Fall involving playground equipment at residential institution While working for income"
    },
    {
      "name": "W0913",
      "description": "Fall involving playground equipment at residential institution While engaged in other types of work"
    },
    {
      "name": "W0914",
      "description": "Fall involving playground equipment at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0918",
      "description": "Fall involving playground equipment at residential institution While engaged in other specified activities"
    },
    {
      "name": "W0919",
      "description": "Fall involving playground equipment at residential institution During unspecified activity"
    },
    {
      "name": "W092",
      "description": "Fall involving playground equipment at school,other institution and public administrative area"
    },
    {
      "name": "W0920",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W0921",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W0922",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W0923",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W0924",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0928",
      "description": "Fall involving playground equipment at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W0929",
      "description": "Fall involving playground equipment at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W093",
      "description": "Fall involving playground equipment at sports and athletics area"
    },
    {
      "name": "W0930",
      "description": "Fall involving playground equipment at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W0931",
      "description": "Fall involving playground equipment at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W0932",
      "description": "Fall involving playground equipment at sports and athletics area While working for income"
    },
    {
      "name": "W0933",
      "description": "Fall involving playground equipment at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W0934",
      "description": "Fall involving playground equipment at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0938",
      "description": "Fall involving playground equipment at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W0939",
      "description": "Fall involving playground equipment at sports and athletics area During unspecified activity"
    },
    {
      "name": "W094",
      "description": "Fall involving playground equipment at street and highway"
    },
    {
      "name": "W0940",
      "description": "Fall involving playground equipment at street and highway While engaged in sports activity"
    },
    {
      "name": "W0941",
      "description": "Fall involving playground equipment at street and highway While engaged in leisure activity"
    },
    {
      "name": "W0942",
      "description": "Fall involving playground equipment at street and highway While working for income"
    },
    {
      "name": "W0943",
      "description": "Fall involving playground equipment at street and highway While engaged in other types of work"
    },
    {
      "name": "W0944",
      "description": "Fall involving playground equipment at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0948",
      "description": "Fall involving playground equipment at street and highway While engaged in other specified activities"
    },
    {
      "name": "W0949",
      "description": "Fall involving playground equipment at street and highway During unspecified activity"
    },
    {
      "name": "W095",
      "description": "Fall involving playground equipment at trade and service area"
    },
    {
      "name": "W0950",
      "description": "Fall involving playground equipment at trade and service area While engaged in sports activity"
    },
    {
      "name": "W0951",
      "description": "Fall involving playground equipment at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W0952",
      "description": "Fall involving playground equipment at trade and service area While working for income"
    },
    {
      "name": "W0953",
      "description": "Fall involving playground equipment at trade and service area While engaged in other types of work"
    },
    {
      "name": "W0954",
      "description": "Fall involving playground equipment at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0958",
      "description": "Fall involving playground equipment at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W0959",
      "description": "Fall involving playground equipment at trade and service area During unspecified activity"
    },
    {
      "name": "W096",
      "description": "Fall involving playground equipment at industrial and construction area"
    },
    {
      "name": "W0960",
      "description": "Fall involving playground equipment at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W0961",
      "description": "Fall involving playground equipment at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W0962",
      "description": "Fall involving playground equipment at industrial and construction area While working for income"
    },
    {
      "name": "W0963",
      "description": "Fall involving playground equipment at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W0964",
      "description": "Fall involving playground equipment at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0968",
      "description": "Fall involving playground equipment at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W0969",
      "description": "Fall involving playground equipment at industrial and construction area During unspecified activity"
    },
    {
      "name": "W097",
      "description": "Fall involving playground equipment at farm"
    },
    {
      "name": "W0970",
      "description": "Fall involving playground equipment at farm While engaged in sports activity"
    },
    {
      "name": "W0971",
      "description": "Fall involving playground equipment at farm While engaged in leisure activity"
    },
    {
      "name": "W0972",
      "description": "Fall involving playground equipment at farm While working for income"
    },
    {
      "name": "W0973",
      "description": "Fall involving playground equipment at farm While engaged in other types of work"
    },
    {
      "name": "W0974",
      "description": "Fall involving playground equipment at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0978",
      "description": "Fall involving playground equipment at farm While engaged in other specified activities"
    },
    {
      "name": "W0979",
      "description": "Fall involving playground equipment at farm During unspecified activity"
    },
    {
      "name": "W098",
      "description": "Fall involving playground equipment at other specified places"
    },
    {
      "name": "W0980",
      "description": "Fall involving playground equipment at other specified places While engaged in sports activity"
    },
    {
      "name": "W0981",
      "description": "Fall involving playground equipment at other specified places While engaged in leisure activity"
    },
    {
      "name": "W0982",
      "description": "Fall involving playground equipment at other specified places While working for income"
    },
    {
      "name": "W0983",
      "description": "Fall involving playground equipment at other specified places While engaged in other types of work"
    },
    {
      "name": "W0984",
      "description": "Fall involving playground equipment at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0988",
      "description": "Fall involving playground equipment at other specified places While engaged in other specified activities"
    },
    {
      "name": "W0989",
      "description": "Fall involving playground equipment at other specified places During unspecified activity"
    },
    {
      "name": "W099",
      "description": "Fall involving playground equipment at unspecified place"
    },
    {
      "name": "W0990",
      "description": "Fall involving playground equipment at unspecified place While engaged in sports activity"
    },
    {
      "name": "W0991",
      "description": "Fall involving playground equipment at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W0992",
      "description": "Fall involving playground equipment at unspecified place While working for income"
    },
    {
      "name": "W0993",
      "description": "Fall involving playground equipment at unspecified place While engaged in other types of work"
    },
    {
      "name": "W0994",
      "description": "Fall involving playground equipment at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W0998",
      "description": "Fall involving playground equipment at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W0999",
      "description": "Fall involving playground equipment at unspecified place During unspecified activity"
    },
    {
      "name": "W100",
      "description": "Fall on and from stairs and steps at home"
    },
    {
      "name": "W1000",
      "description": "Fall on and from stairs and steps at home While engaged in sports activity"
    },
    {
      "name": "W1001",
      "description": "Fall on and from stairs and steps at home While engaged in leisure activity"
    },
    {
      "name": "W1002",
      "description": "Fall on and from stairs and steps at home While working for income"
    },
    {
      "name": "W1003",
      "description": "Fall on and from stairs and steps at home While engaged in other types of work"
    },
    {
      "name": "W1004",
      "description": "Fall on and from stairs and steps at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1008",
      "description": "Fall on and from stairs and steps at home While engaged in other specified activities"
    },
    {
      "name": "W1009",
      "description": "Fall on and from stairs and steps at home During unspecified activity"
    },
    {
      "name": "W101",
      "description": "Fall on and from stairs and steps at residential institution"
    },
    {
      "name": "W1010",
      "description": "Fall on and from stairs and steps at residential institution While engaged in sports activity"
    },
    {
      "name": "W1011",
      "description": "Fall on and from stairs and steps at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1012",
      "description": "Fall on and from stairs and steps at residential institution While working for income"
    },
    {
      "name": "W1013",
      "description": "Fall on and from stairs and steps at residential institution While engaged in other types of work"
    },
    {
      "name": "W1014",
      "description": "Fall on and from stairs and steps at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1018",
      "description": "Fall on and from stairs and steps at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1019",
      "description": "Fall on and from stairs and steps at residential institution During unspecified activity"
    },
    {
      "name": "W102",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area"
    },
    {
      "name": "W1020",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1021",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1022",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1023",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1024",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1028",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1029",
      "description": "Fall on and from stairs and steps at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W103",
      "description": "Fall on and from stairs and steps at sports and athletics area"
    },
    {
      "name": "W1030",
      "description": "Fall on and from stairs and steps at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1031",
      "description": "Fall on and from stairs and steps at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1032",
      "description": "Fall on and from stairs and steps at sports and athletics area While working for income"
    },
    {
      "name": "W1033",
      "description": "Fall on and from stairs and steps at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1034",
      "description": "Fall on and from stairs and steps at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1038",
      "description": "Fall on and from stairs and steps at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1039",
      "description": "Fall on and from stairs and steps at sports and athletics area During unspecified activity"
    },
    {
      "name": "W104",
      "description": "Fall on and from stairs and steps at street and highway"
    },
    {
      "name": "W1040",
      "description": "Fall on and from stairs and steps at street and highway While engaged in sports activity"
    },
    {
      "name": "W1041",
      "description": "Fall on and from stairs and steps at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1042",
      "description": "Fall on and from stairs and steps at street and highway While working for income"
    },
    {
      "name": "W1043",
      "description": "Fall on and from stairs and steps at street and highway While engaged in other types of work"
    },
    {
      "name": "W1044",
      "description": "Fall on and from stairs and steps at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1048",
      "description": "Fall on and from stairs and steps at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1049",
      "description": "Fall on and from stairs and steps at street and highway During unspecified activity"
    },
    {
      "name": "W105",
      "description": "Fall on and from stairs and steps at trade and service area"
    },
    {
      "name": "W1050",
      "description": "Fall on and from stairs and steps at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1051",
      "description": "Fall on and from stairs and steps at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1052",
      "description": "Fall on and from stairs and steps at trade and service area While working for income"
    },
    {
      "name": "W1053",
      "description": "Fall on and from stairs and steps at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1054",
      "description": "Fall on and from stairs and steps at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1058",
      "description": "Fall on and from stairs and steps at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1059",
      "description": "Fall on and from stairs and steps at trade and service area During unspecified activity"
    },
    {
      "name": "W106",
      "description": "Fall on and from stairs and steps at industrial and construction area"
    },
    {
      "name": "W1060",
      "description": "Fall on and from stairs and steps at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1061",
      "description": "Fall on and from stairs and steps at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1062",
      "description": "Fall on and from stairs and steps at industrial and construction area While working for income"
    },
    {
      "name": "W1063",
      "description": "Fall on and from stairs and steps at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1064",
      "description": "Fall on and from stairs and steps at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1068",
      "description": "Fall on and from stairs and steps at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1069",
      "description": "Fall on and from stairs and steps at industrial and construction area During unspecified activity"
    },
    {
      "name": "W107",
      "description": "Fall on and from stairs and steps at farm"
    },
    {
      "name": "W1070",
      "description": "Fall on and from stairs and steps at farm While engaged in sports activity"
    },
    {
      "name": "W1071",
      "description": "Fall on and from stairs and steps at farm While engaged in leisure activity"
    },
    {
      "name": "W1072",
      "description": "Fall on and from stairs and steps at farm While working for income"
    },
    {
      "name": "W1073",
      "description": "Fall on and from stairs and steps at farm While engaged in other types of work"
    },
    {
      "name": "W1074",
      "description": "Fall on and from stairs and steps at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1078",
      "description": "Fall on and from stairs and steps at farm While engaged in other specified activities"
    },
    {
      "name": "W1079",
      "description": "Fall on and from stairs and steps at farm During unspecified activity"
    },
    {
      "name": "W108",
      "description": "Fall on and from stairs and steps at other specified places"
    },
    {
      "name": "W1080",
      "description": "Fall on and from stairs and steps at other specified places While engaged in sports activity"
    },
    {
      "name": "W1081",
      "description": "Fall on and from stairs and steps at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1082",
      "description": "Fall on and from stairs and steps at other specified places While working for income"
    },
    {
      "name": "W1083",
      "description": "Fall on and from stairs and steps at other specified places While engaged in other types of work"
    },
    {
      "name": "W1084",
      "description": "Fall on and from stairs and steps at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1088",
      "description": "Fall on and from stairs and steps at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1089",
      "description": "Fall on and from stairs and steps at other specified places During unspecified activity"
    },
    {
      "name": "W109",
      "description": "Fall on and from stairs and steps at unspecified place"
    },
    {
      "name": "W1090",
      "description": "Fall on and from stairs and steps at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1091",
      "description": "Fall on and from stairs and steps at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1092",
      "description": "Fall on and from stairs and steps at unspecified place While working for income"
    },
    {
      "name": "W1093",
      "description": "Fall on and from stairs and steps at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1094",
      "description": "Fall on and from stairs and steps at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1098",
      "description": "Fall on and from stairs and steps at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1099",
      "description": "Fall on and from stairs and steps at unspecified place During unspecified activity"
    },
    {
      "name": "W110",
      "description": "Fall on and from ladder at home"
    },
    {
      "name": "W1100",
      "description": "Fall on and from ladder at home While engaged in sports activity"
    },
    {
      "name": "W1101",
      "description": "Fall on and from ladder at home While engaged in leisure activity"
    },
    {
      "name": "W1102",
      "description": "Fall on and from ladder at home While working for income"
    },
    {
      "name": "W1103",
      "description": "Fall on and from ladder at home While engaged in other types of work"
    },
    {
      "name": "W1104",
      "description": "Fall on and from ladder at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1108",
      "description": "Fall on and from ladder at home While engaged in other specified activities"
    },
    {
      "name": "W1109",
      "description": "Fall on and from ladder at home During unspecified activity"
    },
    {
      "name": "W111",
      "description": "Fall on and from ladder at residential institution"
    },
    {
      "name": "W1110",
      "description": "Fall on and from ladder at residential institution While engaged in sports activity"
    },
    {
      "name": "W1111",
      "description": "Fall on and from ladder at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1112",
      "description": "Fall on and from ladder at residential institution While working for income"
    },
    {
      "name": "W1113",
      "description": "Fall on and from ladder at residential institution While engaged in other types of work"
    },
    {
      "name": "W1114",
      "description": "Fall on and from ladder at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1118",
      "description": "Fall on and from ladder at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1119",
      "description": "Fall on and from ladder at residential institution During unspecified activity"
    },
    {
      "name": "W112",
      "description": "Fall on and from ladder at school,other institution and public administrative area"
    },
    {
      "name": "W1120",
      "description": "Fall on and from ladder at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1121",
      "description": "Fall on and from ladder at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1122",
      "description": "Fall on and from ladder at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1123",
      "description": "Fall on and from ladder at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1124",
      "description": "Fall on and from ladder at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1128",
      "description": "Fall on and from ladder at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1129",
      "description": "Fall on and from ladder at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W113",
      "description": "Fall on and from ladder at sports and athletics area"
    },
    {
      "name": "W1130",
      "description": "Fall on and from ladder at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1131",
      "description": "Fall on and from ladder at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1132",
      "description": "Fall on and from ladder at sports and athletics area While working for income"
    },
    {
      "name": "W1133",
      "description": "Fall on and from ladder at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1134",
      "description": "Fall on and from ladder at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1138",
      "description": "Fall on and from ladder at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1139",
      "description": "Fall on and from ladder at sports and athletics area During unspecified activity"
    },
    {
      "name": "W114",
      "description": "Fall on and from ladder at street and highway"
    },
    {
      "name": "W1140",
      "description": "Fall on and from ladder at street and highway While engaged in sports activity"
    },
    {
      "name": "W1141",
      "description": "Fall on and from ladder at street and highway While engaged in leisure activity "
    },
    {
      "name": "W1142",
      "description": "Fall on and from ladder at street and highway While working for income"
    },
    {
      "name": "W1143",
      "description": "Fall on and from ladder at street and highway While engaged in other types of work"
    },
    {
      "name": "W1144",
      "description": "Fall on and from ladder at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1148",
      "description": "Fall on and from ladder at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1149",
      "description": "Fall on and from ladder at street and highway During unspecified activity"
    },
    {
      "name": "W115",
      "description": "Fall on and from ladder at trade and service area"
    },
    {
      "name": "W1150",
      "description": "Fall on and from ladder at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1151",
      "description": "Fall on and from ladder at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1152",
      "description": "Fall on and from ladder at trade and service area While working for income"
    },
    {
      "name": "W1153",
      "description": "Fall on and from ladder at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1154",
      "description": "Fall on and from ladder at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1158",
      "description": "Fall on and from ladder at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1159",
      "description": "Fall on and from ladder at trade and service area During unspecified activity"
    },
    {
      "name": "W116",
      "description": "Fall on and from ladder at industrial and construction area"
    },
    {
      "name": "W1160",
      "description": "Fall on and from ladder at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1161",
      "description": "Fall on and from ladder at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1162",
      "description": "Fall on and from ladder at industrial and construction area While working for income"
    },
    {
      "name": "W1163",
      "description": "Fall on and from ladder at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1164",
      "description": "Fall on and from ladder at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1168",
      "description": "Fall on and from ladder at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1169",
      "description": "Fall on and from ladder at industrial and construction area During unspecified activity"
    },
    {
      "name": "W117",
      "description": "Fall on and from ladder at farm"
    },
    {
      "name": "W1170",
      "description": "Fall on and from ladder at farm While engaged in sports activity"
    },
    {
      "name": "W1171",
      "description": "Fall on and from ladder at farm While engaged in leisure activity"
    },
    {
      "name": "W1172",
      "description": "Fall on and from ladder at farm While working for income"
    },
    {
      "name": "W1173",
      "description": "Fall on and from ladder at farm While engaged in other types of work"
    },
    {
      "name": "W1174",
      "description": "Fall on and from ladder at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1178",
      "description": "Fall on and from ladder at farm While engaged in other specified activities"
    },
    {
      "name": "W1179",
      "description": "Fall on and from ladder at farm During unspecified activity"
    },
    {
      "name": "W118",
      "description": "Fall on and from ladder at other specified places"
    },
    {
      "name": "W1180",
      "description": "Fall on and from ladder at other specified places While engaged in sports activity"
    },
    {
      "name": "W1181",
      "description": "Fall on and from ladder at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1182",
      "description": "Fall on and from ladder at other specified places While working for income"
    },
    {
      "name": "W1183",
      "description": "Fall on and from ladder at other specified places While engaged in other types of work"
    },
    {
      "name": "W1184",
      "description": "Fall on and from ladder at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1188",
      "description": "Fall on and from ladder at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1189",
      "description": "Fall on and from ladder at other specified places During unspecified activity"
    },
    {
      "name": "W119",
      "description": "Fall on and from ladder at unspecified place"
    },
    {
      "name": "W1190",
      "description": "Fall on and from ladder at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1191",
      "description": "Fall on and from ladder at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1192",
      "description": "Fall on and from ladder at unspecified place While working for income"
    },
    {
      "name": "W1193",
      "description": "Fall on and from ladder at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1194",
      "description": "Fall on and from ladder at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1198",
      "description": "Fall on and from ladder at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1199",
      "description": "Fall on and from ladder at unspecified place During unspecified activity"
    },
    {
      "name": "W120",
      "description": "Fall on and from scaffolding at home"
    },
    {
      "name": "W1200",
      "description": "Fall on and from scaffolding at home While engaged in sports activity"
    },
    {
      "name": "W1201",
      "description": "Fall on and from scaffolding at home While engaged in leisure activity"
    },
    {
      "name": "W1202",
      "description": "Fall on and from scaffolding at home While working for income"
    },
    {
      "name": "W1203",
      "description": "Fall on and from scaffolding at home While engaged in other types of work"
    },
    {
      "name": "W1204",
      "description": "Fall on and from scaffolding at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1208",
      "description": "Fall on and from scaffolding at home While engaged in other specified activities"
    },
    {
      "name": "W1209",
      "description": "Fall on and from scaffolding at home During unspecified activity"
    },
    {
      "name": "W121",
      "description": "Fall on and from scaffolding at residential institution"
    },
    {
      "name": "W1210",
      "description": "Fall on and from scaffolding at residential institution While engaged in sports activity"
    },
    {
      "name": "W1211",
      "description": "Fall on and from scaffolding at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1212",
      "description": "Fall on and from scaffolding at residential institution While working for income"
    },
    {
      "name": "W1213",
      "description": "Fall on and from scaffolding at residential institution While engaged in other types of work"
    },
    {
      "name": "W1214",
      "description": "Fall on and from scaffolding at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1218",
      "description": "Fall on and from scaffolding at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1219",
      "description": "Fall on and from scaffolding at residential institution During unspecified activity"
    },
    {
      "name": "W122",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area"
    },
    {
      "name": "W1220",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1221",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1222",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1223",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1224",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1228",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1229",
      "description": "Fall on and from scaffolding at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W123",
      "description": "Fall on and from scaffolding at sports and athletics area"
    },
    {
      "name": "W1230",
      "description": "Fall on and from scaffolding at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1231",
      "description": "Fall on and from scaffolding at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1232",
      "description": "Fall on and from scaffolding at sports and athletics area While working for income"
    },
    {
      "name": "W1233",
      "description": "Fall on and from scaffolding at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1234",
      "description": "Fall on and from scaffolding at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1238",
      "description": "Fall on and from scaffolding at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1239",
      "description": "Fall on and from scaffolding at sports and athletics area During unspecified activity"
    },
    {
      "name": "W124",
      "description": "Fall on and from scaffolding at street and highway"
    },
    {
      "name": "W1240",
      "description": "Fall on and from scaffolding at street and highway While engaged in sports activity"
    },
    {
      "name": "W1241",
      "description": "Fall on and from scaffolding at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1242",
      "description": "Fall on and from scaffolding at street and highway While working for income"
    },
    {
      "name": "W1243",
      "description": "Fall on and from scaffolding at street and highway While engaged in other types of work"
    },
    {
      "name": "W1244",
      "description": "Fall on and from scaffolding at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1248",
      "description": "Fall on and from scaffolding at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1249",
      "description": "Fall on and from scaffolding at street and highway During unspecified activity"
    },
    {
      "name": "W125",
      "description": "Fall on and from scaffolding at trade and service area"
    },
    {
      "name": "W1250",
      "description": "Fall on and from scaffolding at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1251",
      "description": "Fall on and from scaffolding at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1252",
      "description": "Fall on and from scaffolding at trade and service area While working for income"
    },
    {
      "name": "W1253",
      "description": "Fall on and from scaffolding at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1254",
      "description": "Fall on and from scaffolding at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1258",
      "description": "Fall on and from scaffolding at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1259",
      "description": "Fall on and from scaffolding at trade and service area  During unspecified activity"
    },
    {
      "name": "W126",
      "description": "Fall on and from scaffolding at industrial and construction area"
    },
    {
      "name": "W1260",
      "description": "Fall on and from scaffolding at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1261",
      "description": "Fall on and from scaffolding at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1262",
      "description": "Fall on and from scaffolding at industrial and construction area While working for income"
    },
    {
      "name": "W1263",
      "description": "Fall on and from scaffolding at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1264",
      "description": "Fall on and from scaffolding at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1268",
      "description": "Fall on and from scaffolding at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1269",
      "description": "Fall on and from scaffolding at industrial and construction area During unspecified activity"
    },
    {
      "name": "W127",
      "description": "Fall on and from scaffolding at farm"
    },
    {
      "name": "W1270",
      "description": "Fall on and from scaffolding at farm While engaged in sports activity"
    },
    {
      "name": "W1271",
      "description": "Fall on and from scaffolding at farm While engaged in leisure activity"
    },
    {
      "name": "W1272",
      "description": "Fall on and from scaffolding at farm While working for income"
    },
    {
      "name": "W1273",
      "description": "Fall on and from scaffolding at farm While engaged in other types of work"
    },
    {
      "name": "W1274",
      "description": "Fall on and from scaffolding at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1278",
      "description": "Fall on and from scaffolding at farm While engaged in other specified activities"
    },
    {
      "name": "W1279",
      "description": "Fall on and from scaffolding at farm During unspecified activity"
    },
    {
      "name": "W128",
      "description": "Fall on and from scaffolding at other specified places"
    },
    {
      "name": "W1280",
      "description": "Fall on and from scaffolding at other specified places While engaged in sports activity"
    },
    {
      "name": "W1281",
      "description": "Fall on and from scaffolding at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1282",
      "description": "Fall on and from scaffolding at other specified places While working for income"
    },
    {
      "name": "W1283",
      "description": "Fall on and from scaffolding at other specified places While engaged in other types of work"
    },
    {
      "name": "W1284",
      "description": "Fall on and from scaffolding at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1288",
      "description": "Fall on and from scaffolding at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1289",
      "description": "Fall on and from scaffolding at other specified places During unspecified activity"
    },
    {
      "name": "W129",
      "description": "Fall on and from scaffolding at unspecified place"
    },
    {
      "name": "W1290",
      "description": "Fall on and from scaffolding at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1291",
      "description": "Fall on and from scaffolding at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1292",
      "description": "Fall on and from scaffolding at unspecified place While working for income"
    },
    {
      "name": "W1293",
      "description": "Fall on and from scaffolding at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1294",
      "description": "Fall on and from scaffolding at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1298",
      "description": "Fall on and from scaffolding at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1299",
      "description": "Fall on and from scaffolding at unspecified place During unspecified activity"
    },
    {
      "name": "W130",
      "description": "Fall from, out of or through building or structure at home"
    },
    {
      "name": "W1300",
      "description": "Fall from, out of or through building or structure at home While engaged in sports activity"
    },
    {
      "name": "W1301",
      "description": "Fall from, out of or through building or structure at home While engaged in leisure activity"
    },
    {
      "name": "W1302",
      "description": "Fall from, out of or through building or structure at home While working for income"
    },
    {
      "name": "W1303",
      "description": "Fall from, out of or through building or structure at home While engaged in other types of work"
    },
    {
      "name": "W1304",
      "description": "Fall from, out of or through building or structure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1308",
      "description": "Fall from, out of or through building or structure at home While engaged in other specified activities"
    },
    {
      "name": "W1309",
      "description": "Fall from, out of or through building or structure at home During unspecified activity"
    },
    {
      "name": "W131",
      "description": "Fall from, out of or through building or structure at residential institution"
    },
    {
      "name": "W1310",
      "description": "Fall from, out of or through building or structure at residential institution While engaged in sports activity"
    },
    {
      "name": "W1311",
      "description": "Fall from, out of or through building or structure at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1312",
      "description": "Fall from, out of or through building or structure at residential institution While working for income"
    },
    {
      "name": "W1313",
      "description": "Fall from, out of or through building or structure at residential institution While engaged in other types of work"
    },
    {
      "name": "W1314",
      "description": "Fall from, out of or through building or structure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1318",
      "description": "Fall from, out of or through building or structure at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1319",
      "description": "Fall from, out of or through building or structure at residential institution During unspecified activity"
    },
    {
      "name": "W132",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area"
    },
    {
      "name": "W1320",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1321",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1322",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1323",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1324",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1328",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1329",
      "description": "Fall from, out of or through building or structure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W133",
      "description": "Fall from, out of or through building or structure at sports and athletics area"
    },
    {
      "name": "W1330",
      "description": "Fall from, out of or through building or structure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1331",
      "description": "Fall from, out of or through building or structure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1332",
      "description": "Fall from, out of or through building or structure at sports and athletics area While working for income"
    },
    {
      "name": "W1333",
      "description": "Fall from, out of or through building or structure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1334",
      "description": "Fall from, out of or through building or structure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1338",
      "description": "Fall from, out of or through building or structure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1339",
      "description": "Fall from, out of or through building or structure at sports and athletics area During unspecified activity"
    },
    {
      "name": "W134",
      "description": "Fall from, out of or through building or structure at street and highway"
    },
    {
      "name": "W1340",
      "description": "Fall from, out of or through building or structure at street and highway While engaged in sports activity"
    },
    {
      "name": "W1341",
      "description": "Fall from, out of or through building or structure at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1342",
      "description": "Fall from, out of or through building or structure at street and highway While working for income"
    },
    {
      "name": "W1343",
      "description": "Fall from, out of or through building or structure at street and highway While engaged in other types of work"
    },
    {
      "name": "W1344",
      "description": "Fall from, out of or through building or structure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1348",
      "description": "Fall from, out of or through building or structure at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1349",
      "description": "Fall from, out of or through building or structure at street and highway During unspecified activity"
    },
    {
      "name": "W135",
      "description": "Fall from, out of or through building or structure at trade and service area"
    },
    {
      "name": "W1350",
      "description": "Fall from, out of or through building or structure at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1351",
      "description": "Fall from, out of or through building or structure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1352",
      "description": "Fall from, out of or through building or structure at trade and service area While working for income"
    },
    {
      "name": "W1353",
      "description": "Fall from, out of or through building or structure at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1354",
      "description": "Fall from, out of or through building or structure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1358",
      "description": "Fall from, out of or through building or structure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1359",
      "description": "Fall from, out of or through building or structure at trade and service area During unspecified activity"
    },
    {
      "name": "W136",
      "description": "Fall from, out of or through building or structure at industrial and construction area"
    },
    {
      "name": "W1360",
      "description": "Fall from, out of or through building or structure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1361",
      "description": "Fall from, out of or through building or structure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1362",
      "description": "Fall from, out of or through building or structure at industrial and construction area While working for income"
    },
    {
      "name": "W1363",
      "description": "Fall from, out of or through building or structure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1364",
      "description": "Fall from, out of or through building or structure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1368",
      "description": "Fall from, out of or through building or structure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1369",
      "description": "Fall from, out of or through building or structure at industrial and construction area During unspecified activity"
    },
    {
      "name": "W137",
      "description": "Fall from, out of or through building or structure at farm"
    },
    {
      "name": "W1370",
      "description": "Fall from, out of or through building or structure at farm While engaged in sports activity"
    },
    {
      "name": "W1371",
      "description": "Fall from, out of or through building or structure at farm While engaged in leisure activity"
    },
    {
      "name": "W1372",
      "description": "Fall from, out of or through building or structure at farm While working for income"
    },
    {
      "name": "W1373",
      "description": "Fall from, out of or through building or structure at farm  While engaged in other types of work"
    },
    {
      "name": "W1374",
      "description": "Fall from, out of or through building or structure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1378",
      "description": "Fall from, out of or through building or structure at farm While engaged in other specified activities"
    },
    {
      "name": "W1379",
      "description": "Fall from, out of or through building or structure at farm During unspecified activity"
    },
    {
      "name": "W138",
      "description": "Fall from, out of or through building or structure at other specified places"
    },
    {
      "name": "W1380",
      "description": "Fall from, out of or through building or structure at other specified places While engaged in sports activity"
    },
    {
      "name": "W1381",
      "description": "Fall from, out of or through building or structure at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1382",
      "description": "Fall from, out of or through building or structure at other specified places While working for income"
    },
    {
      "name": "W1383",
      "description": "Fall from, out of or through building or structure at other specified places While engaged in other types of work"
    },
    {
      "name": "W1384",
      "description": "Fall from, out of or through building or structure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1388",
      "description": "Fall from, out of or through building or structure at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1389",
      "description": "Fall from, out of or through building or structure at other specified places During unspecified activity"
    },
    {
      "name": "W139",
      "description": "Fall from, out of or through building or structure at unspecified place"
    },
    {
      "name": "W1390",
      "description": "Fall from, out of or through building or structure at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1391",
      "description": "Fall from, out of or through building or structure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1392",
      "description": "Fall from, out of or through building or structure at unspecified place While working for income"
    },
    {
      "name": "W1393",
      "description": "Fall from, out of or through building or structure at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1394",
      "description": "Fall from, out of or through building or structure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1398",
      "description": "Fall from, out of or through building or structure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1399",
      "description": "Fall from, out of or through building or structure at unspecified place During unspecified activity"
    },
    {
      "name": "W140",
      "description": "Fall from tree at home"
    },
    {
      "name": "W1400",
      "description": "Fall from tree at home While engaged in sports activity"
    },
    {
      "name": "W1401",
      "description": "Fall from tree at home While engaged in leisure activity"
    },
    {
      "name": "W1402",
      "description": "Fall from tree at home While working for income"
    },
    {
      "name": "W1403",
      "description": "Fall from tree at home While engaged in other types of work"
    },
    {
      "name": "W1404",
      "description": "Fall from tree at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1408",
      "description": "Fall from tree at home While engaged in other specified activities"
    },
    {
      "name": "W1409",
      "description": "Fall from tree at home During unspecified activity"
    },
    {
      "name": "W141",
      "description": "Fall from tree at residential institution"
    },
    {
      "name": "W1410",
      "description": "Fall from tree at residential institution While engaged in sports activity"
    },
    {
      "name": "W1411",
      "description": "Fall from tree at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1412",
      "description": "Fall from tree at residential institution While working for income"
    },
    {
      "name": "W1413",
      "description": "Fall from tree at residential institution While engaged in other types of work"
    },
    {
      "name": "W1414",
      "description": "Fall from tree at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1418",
      "description": "Fall from tree at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1419",
      "description": "Fall from tree at residential institution During unspecified activity"
    },
    {
      "name": "W142",
      "description": "Fall from tree at school,other institution and public administrative area"
    },
    {
      "name": "W1420",
      "description": "Fall from tree at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1421",
      "description": "Fall from tree at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1422",
      "description": "Fall from tree at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1423",
      "description": "Fall from tree at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1424",
      "description": "Fall from tree at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1428",
      "description": "Fall from tree at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1429",
      "description": "Fall from tree at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W143",
      "description": "Fall from tree at sports and athletics area"
    },
    {
      "name": "W1430",
      "description": "Fall from tree at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1431",
      "description": "Fall from tree at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1432",
      "description": "Fall from tree at sports and athletics area While working for income"
    },
    {
      "name": "W1433",
      "description": "Fall from tree at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1434",
      "description": "Fall from tree at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1438",
      "description": "Fall from tree at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1439",
      "description": "Fall from tree at sports and athletics area During unspecified activity"
    },
    {
      "name": "W144",
      "description": "Fall from tree at street and highway"
    },
    {
      "name": "W1440",
      "description": "Fall from tree at street and highway While engaged in sports activity"
    },
    {
      "name": "W1441",
      "description": "Fall from tree at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1442",
      "description": "Fall from tree at street and highway While working for income"
    },
    {
      "name": "W1443",
      "description": "Fall from tree at street and highway While engaged in other types of work"
    },
    {
      "name": "W1444",
      "description": "Fall from tree at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1448",
      "description": "Fall from tree at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1449",
      "description": "Fall from tree at street and highway During unspecified activity"
    },
    {
      "name": "W145",
      "description": "Fall from tree at trade and service area"
    },
    {
      "name": "W1450",
      "description": "Fall from tree at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1451",
      "description": "Fall from tree at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1452",
      "description": "Fall from tree at trade and service area While working for income"
    },
    {
      "name": "W1453",
      "description": "Fall from tree at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1454",
      "description": "Fall from tree at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1458",
      "description": "Fall from tree at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1459",
      "description": "Fall from tree at trade and service area During unspecified activity"
    },
    {
      "name": "W146",
      "description": "Fall from tree at industrial and construction area"
    },
    {
      "name": "W1460",
      "description": "Fall from tree at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1461",
      "description": "Fall from tree at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1462",
      "description": "Fall from tree at industrial and construction area While working for income"
    },
    {
      "name": "W1463",
      "description": "Fall from tree at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1464",
      "description": "Fall from tree at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1468",
      "description": "Fall from tree at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1469",
      "description": "Fall from tree at industrial and construction area During unspecified activity"
    },
    {
      "name": "W147",
      "description": "Fall from tree at farm"
    },
    {
      "name": "W1470",
      "description": "Fall from tree at farm While engaged in sports activity"
    },
    {
      "name": "W1471",
      "description": "Fall from tree at farm While engaged in leisure activity"
    },
    {
      "name": "W1472",
      "description": "Fall from tree at farm While working for income"
    },
    {
      "name": "W1473",
      "description": "Fall from tree at farm While engaged in other types of work"
    },
    {
      "name": "W1474",
      "description": "Fall from tree at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1478",
      "description": "Fall from tree at farm While engaged in other specified activities"
    },
    {
      "name": "W1479",
      "description": "Fall from tree at farm During unspecified activity"
    },
    {
      "name": "W148",
      "description": "Fall from tree at other specified places"
    },
    {
      "name": "W1480",
      "description": "Fall from tree at other specified places While engaged in sports activity"
    },
    {
      "name": "W1481",
      "description": "Fall from tree at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1482",
      "description": "Fall from tree at other specified places While working for income"
    },
    {
      "name": "W1483",
      "description": "Fall from tree at other specified places While engaged in other types of work"
    },
    {
      "name": "W1484",
      "description": "Fall from tree at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1488",
      "description": "Fall from tree at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1489",
      "description": "Fall from tree at other specified places During unspecified activity"
    },
    {
      "name": "W149",
      "description": "Fall from tree at unspecified place"
    },
    {
      "name": "W1490",
      "description": "Fall from tree at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1491",
      "description": "Fall from tree at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1492",
      "description": "Fall from tree at unspecified place While working for income"
    },
    {
      "name": "W1493",
      "description": "Fall from tree at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1494",
      "description": "Fall from tree at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1498",
      "description": "Fall from tree at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1499",
      "description": "Fall from tree at unspecified place During unspecified activity"
    },
    {
      "name": "W150",
      "description": "Fall from cliff at home"
    },
    {
      "name": "W1500",
      "description": "Fall from cliff at home While engaged in sports activity"
    },
    {
      "name": "W1501",
      "description": "Fall from cliff at home While engaged in leisure activity"
    },
    {
      "name": "W1502",
      "description": "Fall from cliff at home While working for income"
    },
    {
      "name": "W1503",
      "description": "Fall from cliff at home While engaged in other types of work"
    },
    {
      "name": "W1504",
      "description": "Fall from cliff at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1508",
      "description": "Fall from cliff at home While engaged in other specified activities"
    },
    {
      "name": "W1509",
      "description": "Fall from cliff at home During unspecified activity"
    },
    {
      "name": "W151",
      "description": "Fall from cliff at residential institution"
    },
    {
      "name": "W1510",
      "description": "Fall from cliff at residential institution While engaged in sports activity"
    },
    {
      "name": "W1511",
      "description": "Fall from cliff at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1512",
      "description": "Fall from cliff at residential institution While working for income"
    },
    {
      "name": "W1513",
      "description": "Fall from cliff at residential institution While engaged in other types of work"
    },
    {
      "name": "W1514",
      "description": "Fall from cliff at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1518",
      "description": "Fall from cliff at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1519",
      "description": "Fall from cliff at residential institution During unspecified activity"
    },
    {
      "name": "W152",
      "description": "Fall from cliff at school,other institution and public administrative area"
    },
    {
      "name": "W1520",
      "description": "Fall from cliff at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1521",
      "description": "Fall from cliff at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1522",
      "description": "Fall from cliff at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1523",
      "description": "Fall from cliff at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1524",
      "description": "Fall from cliff at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1528",
      "description": "Fall from cliff at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1529",
      "description": "Fall from cliff at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W153",
      "description": "Fall from cliff at sports and athletics area"
    },
    {
      "name": "W1530",
      "description": "Fall from cliff at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1531",
      "description": "Fall from cliff at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1532",
      "description": "Fall from cliff at sports and athletics area While working for income"
    },
    {
      "name": "W1533",
      "description": "Fall from cliff at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1534",
      "description": "Fall from cliff at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1538",
      "description": "Fall from cliff at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1539",
      "description": "Fall from cliff at sports and athletics area During unspecified activity"
    },
    {
      "name": "W154",
      "description": "Fall from cliff at street and highway"
    },
    {
      "name": "W1540",
      "description": "Fall from cliff at street and highway While engaged in sports activity"
    },
    {
      "name": "W1541",
      "description": "Fall from cliff at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1542",
      "description": "Fall from cliff at street and highway While working for income"
    },
    {
      "name": "W1543",
      "description": "Fall from cliff at street and highway While engaged in other types of work"
    },
    {
      "name": "W1544",
      "description": "Fall from cliff at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1548",
      "description": "Fall from cliff at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1549",
      "description": "Fall from cliff at street and highway During unspecified activity"
    },
    {
      "name": "W155",
      "description": "Fall from cliff at trade and service area"
    },
    {
      "name": "W1550",
      "description": "Fall from cliff at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1551",
      "description": "Fall from cliff at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1552",
      "description": "Fall from cliff at trade and service area While working for income"
    },
    {
      "name": "W1553",
      "description": "Fall from cliff at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1554",
      "description": "Fall from cliff at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1558",
      "description": "Fall from cliff at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1559",
      "description": "Fall from cliff at trade and service area During unspecified activity"
    },
    {
      "name": "W156",
      "description": "Fall from cliff at industrial and construction area"
    },
    {
      "name": "W1560",
      "description": "Fall from cliff at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1561",
      "description": "Fall from cliff at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1562",
      "description": "Fall from cliff at industrial and construction area While working for income"
    },
    {
      "name": "W1563",
      "description": "Fall from cliff at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1564",
      "description": "Fall from cliff at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1568",
      "description": "Fall from cliff at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1569",
      "description": "Fall from cliff at industrial and construction area During unspecified activity"
    },
    {
      "name": "W157",
      "description": "Fall from cliff at farm"
    },
    {
      "name": "W1570",
      "description": "Fall from cliff at farm While engaged in sports activity"
    },
    {
      "name": "W1571",
      "description": "Fall from cliff at farm While engaged in leisure activity"
    },
    {
      "name": "W1572",
      "description": "Fall from cliff at farm While working for income"
    },
    {
      "name": "W1573",
      "description": "Fall from cliff at farm While engaged in other types of work"
    },
    {
      "name": "W1574",
      "description": "Fall from cliff at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1578",
      "description": "Fall from cliff at farm While engaged in other specified activities"
    },
    {
      "name": "W1579",
      "description": "Fall from cliff at farm During unspecified activity"
    },
    {
      "name": "W158",
      "description": "Fall from cliff at other specified places"
    },
    {
      "name": "W1580",
      "description": "Fall from cliff at other specified places While engaged in sports activity"
    },
    {
      "name": "W1581",
      "description": "Fall from cliff at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1582",
      "description": "Fall from cliff at other specified places While working for income"
    },
    {
      "name": "W1583",
      "description": "Fall from cliff at other specified places While engaged in other types of work"
    },
    {
      "name": "W1584",
      "description": "Fall from cliff at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1588",
      "description": "Fall from cliff at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1589",
      "description": "Fall from cliff at other specified places During unspecified activity"
    },
    {
      "name": "W159",
      "description": "Fall from cliff at unspecified place"
    },
    {
      "name": "W1590",
      "description": "Fall from cliff at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1591",
      "description": "Fall from cliff at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1592",
      "description": "Fall from cliff at unspecified place While working for income"
    },
    {
      "name": "W1593",
      "description": "Fall from cliff at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1594",
      "description": "Fall from cliff at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1598",
      "description": "Fall from cliff at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1599",
      "description": "Fall from cliff at unspecified place During unspecified activity"
    },
    {
      "name": "W160",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home"
    },
    {
      "name": "W1600",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While engaged in sports activity"
    },
    {
      "name": "W1601",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While engaged in leisure activity"
    },
    {
      "name": "W1602",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While working for income"
    },
    {
      "name": "W1603",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While engaged in other types of work"
    },
    {
      "name": "W1604",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1608",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home While engaged in other specified activities"
    },
    {
      "name": "W1609",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at home During unspecified activity"
    },
    {
      "name": "W161",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution"
    },
    {
      "name": "W1610",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While engaged in sports activity"
    },
    {
      "name": "W1611",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1612",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While working for income"
    },
    {
      "name": "W1613",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While engaged in other types of work"
    },
    {
      "name": "W1614",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1618",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1619",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at residential institution During unspecified activity"
    },
    {
      "name": "W162",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area"
    },
    {
      "name": "W1620",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1621",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1622",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1623",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1624",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1628",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1629",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W163",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area"
    },
    {
      "name": "W1630",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1631",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1632",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While working for income"
    },
    {
      "name": "W1633",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1634",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1638",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1639",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at sports and athletics area During unspecified activity"
    },
    {
      "name": "W164",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway"
    },
    {
      "name": "W1640",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While engaged in sports activity"
    },
    {
      "name": "W1641",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1642",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While working for income"
    },
    {
      "name": "W1643",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While engaged in other types of work"
    },
    {
      "name": "W1644",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1648",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1649",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at street and highway During unspecified activity"
    },
    {
      "name": "W165",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area"
    },
    {
      "name": "W1650",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1651",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1652",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While working for income"
    },
    {
      "name": "W1653",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1654",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1658",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1659",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at trade and service area During unspecified activity"
    },
    {
      "name": "W166",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area"
    },
    {
      "name": "W1660",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1661",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1662",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While working for income"
    },
    {
      "name": "W1663",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1664",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1668",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1669",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at industrial and construction area During unspecified activity"
    },
    {
      "name": "W167",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm"
    },
    {
      "name": "W1670",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While engaged in sports activity"
    },
    {
      "name": "W1671",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While engaged in leisure activity"
    },
    {
      "name": "W1672",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While working for income"
    },
    {
      "name": "W1673",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While engaged in other types of work"
    },
    {
      "name": "W1674",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1678",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm While engaged in other specified activities"
    },
    {
      "name": "W1679",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at farm During unspecified activity"
    },
    {
      "name": "W168",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places"
    },
    {
      "name": "W1680",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While engaged in sports activity"
    },
    {
      "name": "W1681",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1682",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While working for income"
    },
    {
      "name": "W1683",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While engaged in other types of work"
    },
    {
      "name": "W1684",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1688",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1689",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at other specified places During unspecified activity"
    },
    {
      "name": "W169",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place"
    },
    {
      "name": "W1690",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1691",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1692",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While working for income"
    },
    {
      "name": "W1693",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1694",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1698",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1699",
      "description": "Diving or jumping into water causing injury other than drowning or submersion at unspecified place During unspecified activity"
    },
    {
      "name": "W170",
      "description": "Other fall from one level to another at home"
    },
    {
      "name": "W1700",
      "description": "Other fall from one level to another at home While engaged in sports activity"
    },
    {
      "name": "W1701",
      "description": "Other fall from one level to another at home While engaged in leisure activity"
    },
    {
      "name": "W1702",
      "description": "Other fall from one level to another at home While working for income"
    },
    {
      "name": "W1703",
      "description": "Other fall from one level to another at home While engaged in other types of work"
    },
    {
      "name": "W1704",
      "description": "Other fall from one level to another at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1708",
      "description": "Other fall from one level to another at home While engaged in other specified activities"
    },
    {
      "name": "W1709",
      "description": "Other fall from one level to another at home During unspecified activity"
    },
    {
      "name": "W171",
      "description": "Other fall from one level to another at residential institution"
    },
    {
      "name": "W1710",
      "description": "Other fall from one level to another at residential institution While engaged in sports activity"
    },
    {
      "name": "W1711",
      "description": "Other fall from one level to another at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1712",
      "description": "Other fall from one level to another at residential institution While working for income"
    },
    {
      "name": "W1713",
      "description": "Other fall from one level to another at residential institution While engaged in other types of work"
    },
    {
      "name": "W1714",
      "description": "Other fall from one level to another at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1718",
      "description": "Other fall from one level to another at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1719",
      "description": "Other fall from one level to another at residential institution During unspecified activity"
    },
    {
      "name": "W172",
      "description": "Other fall from one level to another at school,other institution and public administrative area"
    },
    {
      "name": "W1720",
      "description": "Other fall from one level to another at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1721",
      "description": "Other fall from one level to another at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1722",
      "description": "Other fall from one level to another at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1723",
      "description": "Other fall from one level to another at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1724",
      "description": "Other fall from one level to another at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1728",
      "description": "Other fall from one level to another at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1729",
      "description": "Other fall from one level to another at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W173",
      "description": "Other fall from one level to another at sports and athletics area"
    },
    {
      "name": "W1730",
      "description": "Other fall from one level to another at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1731",
      "description": "Other fall from one level to another at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1732",
      "description": "Other fall from one level to another at sports and athletics area While working for income"
    },
    {
      "name": "W1733",
      "description": "Other fall from one level to another at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1734",
      "description": "Other fall from one level to another at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1738",
      "description": "Other fall from one level to another at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1739",
      "description": "Other fall from one level to another at sports and athletics area During unspecified activity"
    },
    {
      "name": "W174",
      "description": "Other fall from one level to another at street and highway"
    },
    {
      "name": "W1740",
      "description": "Other fall from one level to another at street and highway While engaged in sports activity"
    },
    {
      "name": "W1741",
      "description": "Other fall from one level to another at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1742",
      "description": "Other fall from one level to another at street and highway While working for income"
    },
    {
      "name": "W1743",
      "description": "Other fall from one level to another at street and highway While engaged in other types of work"
    },
    {
      "name": "W1744",
      "description": "Other fall from one level to another at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1748",
      "description": "Other fall from one level to another at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1749",
      "description": "Other fall from one level to another at street and highway During unspecified activity"
    },
    {
      "name": "W175",
      "description": "Other fall from one level to another at trade and service area"
    },
    {
      "name": "W1750",
      "description": "Other fall from one level to another at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1751",
      "description": "Other fall from one level to another at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1752",
      "description": "Other fall from one level to another at trade and service area While working for income"
    },
    {
      "name": "W1753",
      "description": "Other fall from one level to another at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1754",
      "description": "Other fall from one level to another at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1758",
      "description": "Other fall from one level to another at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1759",
      "description": "Other fall from one level to another at trade and service area During unspecified activity"
    },
    {
      "name": "W176",
      "description": "Other fall from one level to another at industrial and construction area"
    },
    {
      "name": "W1760",
      "description": "Other fall from one level to another at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1761",
      "description": "Other fall from one level to another at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1762",
      "description": "Other fall from one level to another at industrial and construction area While working for income"
    },
    {
      "name": "W1763",
      "description": "Other fall from one level to another at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1764",
      "description": "Other fall from one level to another at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1768",
      "description": "Other fall from one level to another at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1769",
      "description": "Other fall from one level to another at industrial and construction area During unspecified activity"
    },
    {
      "name": "W177",
      "description": "Other fall from one level to another at farm"
    },
    {
      "name": "W1770",
      "description": "Other fall from one level to another at farm While engaged in sports activity"
    },
    {
      "name": "W1771",
      "description": "Other fall from one level to another at farm While engaged in leisure activity"
    },
    {
      "name": "W1772",
      "description": "Other fall from one level to another at farm While working for income"
    },
    {
      "name": "W1773",
      "description": "Other fall from one level to another at farm While engaged in other types of work"
    },
    {
      "name": "W1774",
      "description": "Other fall from one level to another at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1778",
      "description": "Other fall from one level to another at farm While engaged in other specified activities"
    },
    {
      "name": "W1779",
      "description": "Other fall from one level to another at farm During unspecified activity"
    },
    {
      "name": "W178",
      "description": "Other fall from one level to another at other specified places"
    },
    {
      "name": "W1780",
      "description": "Other fall from one level to another at other specified places While engaged in sports activity"
    },
    {
      "name": "W1781",
      "description": "Other fall from one level to another at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1782",
      "description": "Other fall from one level to another at other specified places While working for income"
    },
    {
      "name": "W1783",
      "description": "Other fall from one level to another at other specified places While engaged in other types of work"
    },
    {
      "name": "W1784",
      "description": "Other fall from one level to another at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1788",
      "description": "Other fall from one level to another at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1789",
      "description": "Other fall from one level to another at other specified places During unspecified activity"
    },
    {
      "name": "W179",
      "description": "Other fall from one level to another at unspecified place"
    },
    {
      "name": "W1790",
      "description": "Other fall from one level to another at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1791",
      "description": "Other fall from one level to another at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1792",
      "description": "Other fall from one level to another at unspecified place While working for income"
    },
    {
      "name": "W1793",
      "description": "Other fall from one level to another at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1794",
      "description": "Other fall from one level to another at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1798",
      "description": "Other fall from one level to another at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1799",
      "description": "Other fall from one level to another at unspecified place During unspecified activity"
    },
    {
      "name": "W180",
      "description": "Other fall on same level at home"
    },
    {
      "name": "W1800",
      "description": "Other fall on same level at home While engaged in sports activity"
    },
    {
      "name": "W1801",
      "description": "Other fall on same level at home While engaged in leisure activity"
    },
    {
      "name": "W1802",
      "description": "Other fall on same level at home While working for income"
    },
    {
      "name": "W1803",
      "description": "Other fall on same level at home While engaged in other types of work"
    },
    {
      "name": "W1804",
      "description": "Other fall on same level at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1808",
      "description": "Other fall on same level at home While engaged in other specified activities"
    },
    {
      "name": "W1809",
      "description": "Other fall on same level at home During unspecified activity"
    },
    {
      "name": "W181",
      "description": "Other fall on same level at residential institution"
    },
    {
      "name": "W1810",
      "description": "Other fall on same level at residential institution While engaged in sports activity"
    },
    {
      "name": "W1811",
      "description": "Other fall on same level at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1812",
      "description": "Other fall on same level at residential institution While working for income"
    },
    {
      "name": "W1813",
      "description": "Other fall on same level at residential institution While engaged in other types of work"
    },
    {
      "name": "W1814",
      "description": "Other fall on same level at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1818",
      "description": "Other fall on same level at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1819",
      "description": "Other fall on same level at residential institution During unspecified activity"
    },
    {
      "name": "W182",
      "description": "Other fall on same level at school,other institution and public administrative area"
    },
    {
      "name": "W1820",
      "description": "Other fall on same level at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1821",
      "description": "Other fall on same level at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1822",
      "description": "Other fall on same level at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1823",
      "description": "Other fall on same level at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1824",
      "description": "Other fall on same level at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1828",
      "description": "Other fall on same level at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1829",
      "description": "Other fall on same level at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W183",
      "description": "Other fall on same level at sports and athletics area"
    },
    {
      "name": "W1830",
      "description": "Other fall on same level at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1831",
      "description": "Other fall on same level at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1832",
      "description": "Other fall on same level at sports and athletics area While working for income"
    },
    {
      "name": "W1833",
      "description": "Other fall on same level at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1834",
      "description": "Other fall on same level at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1838",
      "description": "Other fall on same level at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1839",
      "description": "Other fall on same level at sports and athletics area During unspecified activity"
    },
    {
      "name": "W184",
      "description": "Other fall on same level at street and highway"
    },
    {
      "name": "W1840",
      "description": "Other fall on same level at street and highway While engaged in sports activity"
    },
    {
      "name": "W1841",
      "description": "Other fall on same level at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1842",
      "description": "Other fall on same level at street and highway While working for income"
    },
    {
      "name": "W1843",
      "description": "Other fall on same level at street and highway While engaged in other types of work"
    },
    {
      "name": "W1844",
      "description": "Other fall on same level at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1848",
      "description": "Other fall on same level at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1849",
      "description": "Other fall on same level at street and highway During unspecified activity"
    },
    {
      "name": "W185",
      "description": "Other fall on same level at trade and service area"
    },
    {
      "name": "W1850",
      "description": "Other fall on same level at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1851",
      "description": "Other fall on same level at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1852",
      "description": "Other fall on same level at trade and service area While working for income"
    },
    {
      "name": "W1853",
      "description": "Other fall on same level at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1854",
      "description": "Other fall on same level at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1858",
      "description": "Other fall on same level at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1859",
      "description": "Other fall on same level at trade and service area During unspecified activity"
    },
    {
      "name": "W186",
      "description": "Other fall on same level at industrial and construction area"
    },
    {
      "name": "W1860",
      "description": "Other fall on same level at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1861",
      "description": "Other fall on same level at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1862",
      "description": "Other fall on same level at industrial and construction area While working for income"
    },
    {
      "name": "W1863",
      "description": "Other fall on same level at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1864",
      "description": "Other fall on same level at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1868",
      "description": "Other fall on same level at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1869",
      "description": "Other fall on same level at industrial and construction area During unspecified activity"
    },
    {
      "name": "W187",
      "description": "Other fall on same level at farm"
    },
    {
      "name": "W1870",
      "description": "Other fall on same level at farm While engaged in sports activity"
    },
    {
      "name": "W1871",
      "description": "Other fall on same level at farm While engaged in leisure activity"
    },
    {
      "name": "W1872",
      "description": "Other fall on same level at farm While working for income"
    },
    {
      "name": "W1873",
      "description": "Other fall on same level at farm While engaged in other types of work"
    },
    {
      "name": "W1874",
      "description": "Other fall on same level at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1878",
      "description": "Other fall on same level at farm While engaged in other specified activities"
    },
    {
      "name": "W1879",
      "description": "Other fall on same level at farm During unspecified activity"
    },
    {
      "name": "W188",
      "description": "Other fall on same level at other specified places"
    },
    {
      "name": "W1880",
      "description": "Other fall on same level at other specified places While engaged in sports activity"
    },
    {
      "name": "W1881",
      "description": "Other fall on same level at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1882",
      "description": "Other fall on same level at other specified places While working for income"
    },
    {
      "name": "W1883",
      "description": "Other fall on same level at other specified places While engaged in other types of work"
    },
    {
      "name": "W1884",
      "description": "Other fall on same level at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1888",
      "description": "Other fall on same level at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1889",
      "description": "Other fall on same level at other specified places During unspecified activity"
    },
    {
      "name": "W189",
      "description": "Other fall on same level at unspecified place"
    },
    {
      "name": "W1890",
      "description": "Other fall on same level at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1891",
      "description": "Other fall on same level at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1892",
      "description": "Other fall on same level at unspecified place While working for income"
    },
    {
      "name": "W1893",
      "description": "Other fall on same level at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1894",
      "description": "Other fall on same level at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1898",
      "description": "Other fall on same level at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1899",
      "description": "Other fall on same level at unspecified place During unspecified activity"
    },
    {
      "name": "W190",
      "description": "Unspecified fall at home"
    },
    {
      "name": "W1900",
      "description": "Unspecified fall at home While engaged in sports activity"
    },
    {
      "name": "W1901",
      "description": "Unspecified fall at home While engaged in leisure activity"
    },
    {
      "name": "W1902",
      "description": "Unspecified fall at home While working for income"
    },
    {
      "name": "W1903",
      "description": "Unspecified fall at home While engaged in other types of work"
    },
    {
      "name": "W1904",
      "description": "Unspecified fall at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1908",
      "description": "Unspecified fall at home While engaged in other specified activities"
    },
    {
      "name": "W1909",
      "description": "Unspecified fall at home During unspecified activity"
    },
    {
      "name": "W191",
      "description": "Unspecified fall at residential institution"
    },
    {
      "name": "W1910",
      "description": "Unspecified fall at residential institution While engaged in sports activity"
    },
    {
      "name": "W1911",
      "description": "Unspecified fall at residential institution While engaged in leisure activity"
    },
    {
      "name": "W1912",
      "description": "Unspecified fall at residential institution While working for income"
    },
    {
      "name": "W1913",
      "description": "Unspecified fall at residential institution While engaged in other types of work"
    },
    {
      "name": "W1914",
      "description": "Unspecified fall at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1918",
      "description": "Unspecified fall at residential institution While engaged in other specified activities"
    },
    {
      "name": "W1919",
      "description": "Unspecified fall at residential institution During unspecified activity"
    },
    {
      "name": "W192",
      "description": "Unspecified fall at school,other institution and public administrative area"
    },
    {
      "name": "W1920",
      "description": "Unspecified fall at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W1921",
      "description": "Unspecified fall at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W1922",
      "description": "Unspecified fall at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W1923",
      "description": "Unspecified fall at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W1924",
      "description": "Unspecified fall at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1928",
      "description": "Unspecified fall at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W1929",
      "description": "Unspecified fall at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W193",
      "description": "Unspecified fall at sports and athletics area"
    },
    {
      "name": "W1930",
      "description": "Unspecified fall at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W1931",
      "description": "Unspecified fall at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W1932",
      "description": "Unspecified fall at sports and athletics area While working for income"
    },
    {
      "name": "W1933",
      "description": "Unspecified fall at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W1934",
      "description": "Unspecified fall at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1938",
      "description": "Unspecified fall at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W1939",
      "description": "Unspecified fall at sports and athletics area During unspecified activity"
    },
    {
      "name": "W194",
      "description": "Unspecified fall at street and highway"
    },
    {
      "name": "W1940",
      "description": "Unspecified fall at street and highway While engaged in sports activity"
    },
    {
      "name": "W1941",
      "description": "Unspecified fall at street and highway While engaged in leisure activity"
    },
    {
      "name": "W1942",
      "description": "Unspecified fall at street and highway While working for income"
    },
    {
      "name": "W1943",
      "description": "Unspecified fall at street and highway While engaged in other types of work"
    },
    {
      "name": "W1944",
      "description": "Unspecified fall at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1948",
      "description": "Unspecified fall at street and highway While engaged in other specified activities"
    },
    {
      "name": "W1949",
      "description": "Unspecified fall at street and highway During unspecified activity"
    },
    {
      "name": "W195",
      "description": "Unspecified fall at trade and service area"
    },
    {
      "name": "W1950",
      "description": "Unspecified fall at trade and service area While engaged in sports activity"
    },
    {
      "name": "W1951",
      "description": "Unspecified fall at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W1952",
      "description": "Unspecified fall at trade and service area While working for income"
    },
    {
      "name": "W1953",
      "description": "Unspecified fall at trade and service area While engaged in other types of work"
    },
    {
      "name": "W1954",
      "description": "Unspecified fall at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1958",
      "description": "Unspecified fall at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W1959",
      "description": "Unspecified fall at trade and service area During unspecified activity"
    },
    {
      "name": "W196",
      "description": "Unspecified fall at industrial and construction area"
    },
    {
      "name": "W1960",
      "description": "Unspecified fall at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W1961",
      "description": "Unspecified fall at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W1962",
      "description": "Unspecified fall at industrial and construction area While working for income"
    },
    {
      "name": "W1963",
      "description": "Unspecified fall at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W1964",
      "description": "Unspecified fall at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1968",
      "description": "Unspecified fall at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W1969",
      "description": "Unspecified fall at industrial and construction area During unspecified activity"
    },
    {
      "name": "W197",
      "description": "Unspecified fall at farm"
    },
    {
      "name": "W1970",
      "description": "Unspecified fall at farm While engaged in sports activity"
    },
    {
      "name": "W1971",
      "description": "Unspecified fall at farm While engaged in leisure activity"
    },
    {
      "name": "W1972",
      "description": "Unspecified fall at farm While working for income"
    },
    {
      "name": "W1973",
      "description": "Unspecified fall at farm While engaged in other types of work"
    },
    {
      "name": "W1974",
      "description": "Unspecified fall at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1978",
      "description": "Unspecified fall at farm While engaged in other specified activities"
    },
    {
      "name": "W1979",
      "description": "Unspecified fall at farm During unspecified activity"
    },
    {
      "name": "W198",
      "description": "Unspecified fall at other specified places"
    },
    {
      "name": "W1980",
      "description": "Unspecified fall at other specified places While engaged in sports activity"
    },
    {
      "name": "W1981",
      "description": "Unspecified fall at other specified places While engaged in leisure activity"
    },
    {
      "name": "W1982",
      "description": "Unspecified fall at other specified places While working for income"
    },
    {
      "name": "W1983",
      "description": "Unspecified fall at other specified places While engaged in other types of work"
    },
    {
      "name": "W1984",
      "description": "Unspecified fall at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1988",
      "description": "Unspecified fall at other specified places While engaged in other specified activities"
    },
    {
      "name": "W1989",
      "description": "Unspecified fall at other specified places During unspecified activity"
    },
    {
      "name": "W199",
      "description": "Unspecified fall at unspecified place"
    },
    {
      "name": "W1990",
      "description": "Unspecified fall at unspecified place While engaged in sports activity"
    },
    {
      "name": "W1991",
      "description": "Unspecified fall at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W1992",
      "description": "Unspecified fall at unspecified place While working for income"
    },
    {
      "name": "W1993",
      "description": "Unspecified fall at unspecified place While engaged in other types of work"
    },
    {
      "name": "W1994",
      "description": "Unspecified fall at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W1998",
      "description": "Unspecified fall at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W1999",
      "description": "Unspecified fall at unspecified place During unspecified activity"
    },
    {
      "name": "W200",
      "description": "Struck by thrown, projected or falling object at home"
    },
    {
      "name": "W2000",
      "description": "Struck by thrown, projected or falling object at home While engaged in sports activity"
    },
    {
      "name": "W2001",
      "description": "Struck by thrown, projected or falling object at home While engaged in leisure activity"
    },
    {
      "name": "W2002",
      "description": "Struck by thrown, projected or falling object at home While working for income"
    },
    {
      "name": "W2003",
      "description": "Struck by thrown, projected or falling object at home While engaged in other types of work"
    },
    {
      "name": "W2004",
      "description": "Struck by thrown, projected or falling object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2008",
      "description": "Struck by thrown, projected or falling object at home While engaged in other specified activities"
    },
    {
      "name": "W2009",
      "description": "Struck by thrown, projected or falling object at home During unspecified activity"
    },
    {
      "name": "W201",
      "description": "Struck by thrown, projected or falling object at residential institution"
    },
    {
      "name": "W2010",
      "description": "Struck by thrown, projected or falling object at residential institution While engaged in sports activity"
    },
    {
      "name": "W2011",
      "description": "Struck by thrown, projected or falling object at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2012",
      "description": "Struck by thrown, projected or falling object at residential institution While working for income"
    },
    {
      "name": "W2013",
      "description": "Struck by thrown, projected or falling object at residential institution While engaged in other types of work"
    },
    {
      "name": "W2014",
      "description": "Struck by thrown, projected or falling object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2018",
      "description": "Struck by thrown, projected or falling object at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2019",
      "description": "Struck by thrown, projected or falling object at residential institution During unspecified activity"
    },
    {
      "name": "W202",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area"
    },
    {
      "name": "W2020",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2021",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2022",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2023",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2024",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2028",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2029",
      "description": "Struck by thrown, projected or falling object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W203",
      "description": "Struck by thrown, projected or falling object at sports and athletics area"
    },
    {
      "name": "W2030",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2031",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2032",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While working for income"
    },
    {
      "name": "W2033",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2034",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2038",
      "description": "Struck by thrown, projected or falling object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2039",
      "description": "Struck by thrown, projected or falling object at sports and athletics area During unspecified activity"
    },
    {
      "name": "W204",
      "description": "Struck by thrown, projected or falling object at street and highway"
    },
    {
      "name": "W2040",
      "description": "Struck by thrown, projected or falling object at street and highway While engaged in sports activity"
    },
    {
      "name": "W2041",
      "description": "Struck by thrown, projected or falling object at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2042",
      "description": "Struck by thrown, projected or falling object at street and highway While working for income"
    },
    {
      "name": "W2043",
      "description": "Struck by thrown, projected or falling object at street and highway While engaged in other types of work"
    },
    {
      "name": "W2044",
      "description": "Struck by thrown, projected or falling object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2048",
      "description": "Struck by thrown, projected or falling object at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2049",
      "description": "Struck by thrown, projected or falling object at street and highway During unspecified activity"
    },
    {
      "name": "W205",
      "description": "Struck by thrown, projected or falling object at trade and service area"
    },
    {
      "name": "W2050",
      "description": "Struck by thrown, projected or falling object at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2051",
      "description": "Struck by thrown, projected or falling object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2052",
      "description": "Struck by thrown, projected or falling object at trade and service area While working for income"
    },
    {
      "name": "W2053",
      "description": "Struck by thrown, projected or falling object at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2054",
      "description": "Struck by thrown, projected or falling object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2058",
      "description": "Struck by thrown, projected or falling object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2059",
      "description": "Struck by thrown, projected or falling object at trade and service area During unspecified activity"
    },
    {
      "name": "W206",
      "description": "Struck by thrown, projected or falling object at industrial and construction area"
    },
    {
      "name": "W2060",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2061",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2062",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While working for income"
    },
    {
      "name": "W2063",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2064",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2068",
      "description": "Struck by thrown, projected or falling object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2069",
      "description": "Struck by thrown, projected or falling object at industrial and construction area During unspecified activity"
    },
    {
      "name": "W207",
      "description": "Struck by thrown, projected or falling object at farm"
    },
    {
      "name": "W2070",
      "description": "Struck by thrown, projected or falling object at farm While engaged in sports activity"
    },
    {
      "name": "W2071",
      "description": "Struck by thrown, projected or falling object at farm While engaged in leisure activity"
    },
    {
      "name": "W2072",
      "description": "Struck by thrown, projected or falling object at farm While working for income"
    },
    {
      "name": "W2073",
      "description": "Struck by thrown, projected or falling object at farm While engaged in other types of work"
    },
    {
      "name": "W2074",
      "description": "Struck by thrown, projected or falling object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2078",
      "description": "Struck by thrown, projected or falling object at farm While engaged in other specified activities"
    },
    {
      "name": "W2079",
      "description": "Struck by thrown, projected or falling object at farm During unspecified activity"
    },
    {
      "name": "W208",
      "description": "Struck by thrown, projected or falling object at other specified places"
    },
    {
      "name": "W2080",
      "description": "Struck by thrown, projected or falling object at other specified places While engaged in sports activity"
    },
    {
      "name": "W2081",
      "description": "Struck by thrown, projected or falling object at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2082",
      "description": "Struck by thrown, projected or falling object at other specified places While working for income"
    },
    {
      "name": "W2083",
      "description": "Struck by thrown, projected or falling object at other specified places While engaged in other types of work"
    },
    {
      "name": "W2084",
      "description": "Struck by thrown, projected or falling object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2088",
      "description": "Struck by thrown, projected or falling object at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2089",
      "description": "Struck by thrown, projected or falling object at other specified places During unspecified activity"
    },
    {
      "name": "W209",
      "description": "Struck by thrown, projected or falling object at unspecified place"
    },
    {
      "name": "W2090",
      "description": "Struck by thrown, projected or falling object at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2091",
      "description": "Struck by thrown, projected or falling object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2092",
      "description": "Struck by thrown, projected or falling object at unspecified place While working for income"
    },
    {
      "name": "W2093",
      "description": "Struck by thrown, projected or falling object at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2094",
      "description": "Struck by thrown, projected or falling object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2098",
      "description": "Struck by thrown, projected or falling object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2099",
      "description": "Struck by thrown, projected or falling object at unspecified place During unspecified activity"
    },
    {
      "name": "W210",
      "description": "Striking against or struck by sports equipment at home"
    },
    {
      "name": "W2100",
      "description": "Striking against or struck by sports equipment at home While engaged in sports activity"
    },
    {
      "name": "W2101",
      "description": "Striking against or struck by sports equipment at home While engaged in leisure activity"
    },
    {
      "name": "W2102",
      "description": "Striking against or struck by sports equipment at home While working for income"
    },
    {
      "name": "W2103",
      "description": "Striking against or struck by sports equipment at home While engaged in other types of work"
    },
    {
      "name": "W2104",
      "description": "Striking against or struck by sports equipment at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2108",
      "description": "Striking against or struck by sports equipment at home While engaged in other specified activities"
    },
    {
      "name": "W2109",
      "description": "Striking against or struck by sports equipment at home During unspecified activity"
    },
    {
      "name": "W211",
      "description": "Striking against or struck by sports equipment at residential institution"
    },
    {
      "name": "W2110",
      "description": "Striking against or struck by sports equipment at residential institution While engaged in sports activity"
    },
    {
      "name": "W2111",
      "description": "Striking against or struck by sports equipment at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2112",
      "description": "Striking against or struck by sports equipment at residential institution While working for income"
    },
    {
      "name": "W2113",
      "description": "Striking against or struck by sports equipment at residential institution While engaged in other types of work"
    },
    {
      "name": "W2114",
      "description": "Striking against or struck by sports equipment at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2118",
      "description": "Striking against or struck by sports equipment at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2119",
      "description": "Striking against or struck by sports equipment at residential institution During unspecified activity"
    },
    {
      "name": "W212",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area"
    },
    {
      "name": "W2120",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2121",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2122",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2123",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2124",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2128",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2129",
      "description": "Striking against or struck by sports equipment at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W213",
      "description": "Striking against or struck by sports equipment at sports and athletics area"
    },
    {
      "name": "W2130",
      "description": "Striking against or struck by sports equipment at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2131",
      "description": "Striking against or struck by sports equipment at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2132",
      "description": "Striking against or struck by sports equipment at sports and athletics area While working for income"
    },
    {
      "name": "W2133",
      "description": "Striking against or struck by sports equipment at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2134",
      "description": "Striking against or struck by sports equipment at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2138",
      "description": "Striking against or struck by sports equipment at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2139",
      "description": "Striking against or struck by sports equipment at sports and athletics area During unspecified activity"
    },
    {
      "name": "W214",
      "description": "Striking against or struck by sports equipment at street and highway"
    },
    {
      "name": "W2140",
      "description": "Striking against or struck by sports equipment at street and highway While engaged in sports activity"
    },
    {
      "name": "W2141",
      "description": "Striking against or struck by sports equipment at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2142",
      "description": "Striking against or struck by sports equipment at street and highway While working for income"
    },
    {
      "name": "W2143",
      "description": "Striking against or struck by sports equipment at street and highway While engaged in other types of work"
    },
    {
      "name": "W2144",
      "description": "Striking against or struck by sports equipment at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2148",
      "description": "Striking against or struck by sports equipment at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2149",
      "description": "Striking against or struck by sports equipment at street and highway During unspecified activity"
    },
    {
      "name": "W215",
      "description": "Striking against or struck by sports equipment at trade and service area"
    },
    {
      "name": "W2150",
      "description": "Striking against or struck by sports equipment at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2151",
      "description": "Striking against or struck by sports equipment at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2152",
      "description": "Striking against or struck by sports equipment at trade and service area While working for income"
    },
    {
      "name": "W2153",
      "description": "Striking against or struck by sports equipment at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2154",
      "description": "Striking against or struck by sports equipment at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2158",
      "description": "Striking against or struck by sports equipment at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2159",
      "description": "Striking against or struck by sports equipment at trade and service area During unspecified activity"
    },
    {
      "name": "W216",
      "description": "Striking against or struck by sports equipment at industrial and construction area"
    },
    {
      "name": "W2160",
      "description": "Striking against or struck by sports equipment at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2161",
      "description": "Striking against or struck by sports equipment at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2162",
      "description": "Striking against or struck by sports equipment at industrial and construction area While working for income"
    },
    {
      "name": "W2163",
      "description": "Striking against or struck by sports equipment at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2164",
      "description": "Striking against or struck by sports equipment at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2168",
      "description": "Striking against or struck by sports equipment at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2169",
      "description": "Striking against or struck by sports equipment at industrial and construction area During unspecified activity"
    },
    {
      "name": "W217",
      "description": "Striking against or struck by sports equipment at farm"
    },
    {
      "name": "W2170",
      "description": "Striking against or struck by sports equipment at farm While engaged in sports activity"
    },
    {
      "name": "W2171",
      "description": "Striking against or struck by sports equipment at farm While engaged in leisure activity"
    },
    {
      "name": "W2172",
      "description": "Striking against or struck by sports equipment at farm While working for income"
    },
    {
      "name": "W2173",
      "description": "Striking against or struck by sports equipment at farm While engaged in other types of work"
    },
    {
      "name": "W2174",
      "description": "Striking against or struck by sports equipment at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2178",
      "description": "Striking against or struck by sports equipment at farm While engaged in other specified activities"
    },
    {
      "name": "W2179",
      "description": "Striking against or struck by sports equipment at farm During unspecified activity"
    },
    {
      "name": "W218",
      "description": "Striking against or struck by sports equipment at other specified places"
    },
    {
      "name": "W2180",
      "description": "Striking against or struck by sports equipment at other specified places While engaged in sports activity"
    },
    {
      "name": "W2181",
      "description": "Striking against or struck by sports equipment at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2182",
      "description": "Striking against or struck by sports equipment at other specified places While working for income"
    },
    {
      "name": "W2183",
      "description": "Striking against or struck by sports equipment at other specified places While engaged in other types of work"
    },
    {
      "name": "W2184",
      "description": "Striking against or struck by sports equipment at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2188",
      "description": "Striking against or struck by sports equipment at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2189",
      "description": "Striking against or struck by sports equipment at other specified places During unspecified activity"
    },
    {
      "name": "W219",
      "description": "Striking against or struck by sports equipment at unspecified place"
    },
    {
      "name": "W2190",
      "description": "Striking against or struck by sports equipment at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2191",
      "description": "Striking against or struck by sports equipment at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2192",
      "description": "Striking against or struck by sports equipment at unspecified place While working for income"
    },
    {
      "name": "W2193",
      "description": "Striking against or struck by sports equipment at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2194",
      "description": "Striking against or struck by sports equipment at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2198",
      "description": "Striking against or struck by sports equipment at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2199",
      "description": "Striking against or struck by sports equipment at unspecified place During unspecified activity"
    },
    {
      "name": "W220",
      "description": "Striking against or struck by other objects at home"
    },
    {
      "name": "W2200",
      "description": "Striking against or struck by other objects at home While engaged in sports activity"
    },
    {
      "name": "W2201",
      "description": "Striking against or struck by other objects at home While engaged in leisure activity"
    },
    {
      "name": "W2202",
      "description": "Striking against or struck by other objects at home While working for income"
    },
    {
      "name": "W2203",
      "description": "Striking against or struck by other objects at home While engaged in other types of work"
    },
    {
      "name": "W2204",
      "description": "Striking against or struck by other objects at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2208",
      "description": "Striking against or struck by other objects at home While engaged in other specified activities"
    },
    {
      "name": "W2209",
      "description": "Striking against or struck by other objects at home During unspecified activity"
    },
    {
      "name": "W221",
      "description": "Striking against or struck by other objects at residential institution"
    },
    {
      "name": "W2210",
      "description": "Striking against or struck by other objects at residential institution While engaged in sports activity"
    },
    {
      "name": "W2211",
      "description": "Striking against or struck by other objects at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2212",
      "description": "Striking against or struck by other objects at residential institution While working for income"
    },
    {
      "name": "W2213",
      "description": "Striking against or struck by other objects at residential institution While engaged in other types of work"
    },
    {
      "name": "W2214",
      "description": "Striking against or struck by other objects at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2218",
      "description": "Striking against or struck by other objects at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2219",
      "description": "Striking against or struck by other objects at residential institution During unspecified activity"
    },
    {
      "name": "W222",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area"
    },
    {
      "name": "W2220",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2221",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2222",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2223",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2224",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2228",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2229",
      "description": "Striking against or struck by other objects at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W223",
      "description": "Striking against or struck by other objects at sports and athletics area"
    },
    {
      "name": "W2230",
      "description": "Striking against or struck by other objects at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2231",
      "description": "Striking against or struck by other objects at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2232",
      "description": "Striking against or struck by other objects at sports and athletics area While working for income"
    },
    {
      "name": "W2233",
      "description": "Striking against or struck by other objects at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2234",
      "description": "Striking against or struck by other objects at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2238",
      "description": "Striking against or struck by other objects at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2239",
      "description": "Striking against or struck by other objects at sports and athletics area During unspecified activity"
    },
    {
      "name": "W224",
      "description": "Striking against or struck by other objects at street and highway"
    },
    {
      "name": "W2240",
      "description": "Striking against or struck by other objects at street and highway While engaged in sports activity"
    },
    {
      "name": "W2241",
      "description": "Striking against or struck by other objects at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2242",
      "description": "Striking against or struck by other objects at street and highway While working for income"
    },
    {
      "name": "W2243",
      "description": "Striking against or struck by other objects at street and highway While engaged in other types of work"
    },
    {
      "name": "W2244",
      "description": "Striking against or struck by other objects at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2248",
      "description": "Striking against or struck by other objects at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2249",
      "description": "Striking against or struck by other objects at street and highway During unspecified activity"
    },
    {
      "name": "W225",
      "description": "Striking against or struck by other objects at trade and service area"
    },
    {
      "name": "W2250",
      "description": "Striking against or struck by other objects at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2251",
      "description": "Striking against or struck by other objects at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2252",
      "description": "Striking against or struck by other objects at trade and service area While working for income"
    },
    {
      "name": "W2253",
      "description": "Striking against or struck by other objects at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2254",
      "description": "Striking against or struck by other objects at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2258",
      "description": "Striking against or struck by other objects at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2259",
      "description": "Striking against or struck by other objects at trade and service area During unspecified activity"
    },
    {
      "name": "W226",
      "description": "Striking against or struck by other objects at industrial and construction area"
    },
    {
      "name": "W2260",
      "description": "Striking against or struck by other objects at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2261",
      "description": "Striking against or struck by other objects at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2262",
      "description": "Striking against or struck by other objects at industrial and construction area While working for income"
    },
    {
      "name": "W2263",
      "description": "Striking against or struck by other objects at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2264",
      "description": "Striking against or struck by other objects at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2268",
      "description": "Striking against or struck by other objects at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2269",
      "description": "Striking against or struck by other objects at industrial and construction area During unspecified activity"
    },
    {
      "name": "W227",
      "description": "Striking against or struck by other objects at farm"
    },
    {
      "name": "W2270",
      "description": "Striking against or struck by other objects at farm While engaged in sports activity"
    },
    {
      "name": "W2271",
      "description": "Striking against or struck by other objects at farm While engaged in leisure activity"
    },
    {
      "name": "W2272",
      "description": "Striking against or struck by other objects at farm While working for income"
    },
    {
      "name": "W2273",
      "description": "Striking against or struck by other objects at farm While engaged in other types of work"
    },
    {
      "name": "W2274",
      "description": "Striking against or struck by other objects at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2278",
      "description": "Striking against or struck by other objects at farm While engaged in other specified activities"
    },
    {
      "name": "W2279",
      "description": "Striking against or struck by other objects at farm During unspecified activity"
    },
    {
      "name": "W228",
      "description": "Striking against or struck by other objects at other specified places"
    },
    {
      "name": "W2280",
      "description": "Striking against or struck by other objects at other specified places While engaged in sports activity"
    },
    {
      "name": "W2281",
      "description": "Striking against or struck by other objects at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2282",
      "description": "Striking against or struck by other objects at other specified places While working for income"
    },
    {
      "name": "W2283",
      "description": "Striking against or struck by other objects at other specified places While engaged in other types of work"
    },
    {
      "name": "W2284",
      "description": "Striking against or struck by other objects at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2288",
      "description": "Striking against or struck by other objects at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2289",
      "description": "Striking against or struck by other objects at other specified places During unspecified activity"
    },
    {
      "name": "W229",
      "description": "Striking against or struck by other objects at unspecified place"
    },
    {
      "name": "W2290",
      "description": "Striking against or struck by other objects at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2291",
      "description": "Striking against or struck by other objects at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2292",
      "description": "Striking against or struck by other objects at unspecified place While working for income"
    },
    {
      "name": "W2293",
      "description": "Striking against or struck by other objects at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2294",
      "description": "Striking against or struck by other objects at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2298",
      "description": "Striking against or struck by other objects at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2299",
      "description": "Striking against or struck by other objects at unspecified place During unspecified activity"
    },
    {
      "name": "W230",
      "description": "Caught, crushed, jammed or pinched in or between objects at home"
    },
    {
      "name": "W2300",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While engaged in sports activity"
    },
    {
      "name": "W2301",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While engaged in leisure activity"
    },
    {
      "name": "W2302",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While working for income"
    },
    {
      "name": "W2303",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While engaged in other types of work"
    },
    {
      "name": "W2304",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2308",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While engaged in other specified activities"
    },
    {
      "name": "W2309",
      "description": "Caught, crushed, jammed or pinched in or between objects at home During unspecified activity"
    },
    {
      "name": "W231",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution"
    },
    {
      "name": "W2310",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While engaged in sports activity"
    },
    {
      "name": "W2311",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2312",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While working for income"
    },
    {
      "name": "W2313",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While engaged in other types of work"
    },
    {
      "name": "W2314",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2318",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2319",
      "description": "Caught, crushed, jammed or pinched in or between objects at residential institution During unspecified activity"
    },
    {
      "name": "W232",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area"
    },
    {
      "name": "W2320",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2321",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2322",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2323",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2324",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2328",
      "description": "Caught, crushed, jammed or pinched in or between objects at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2329",
      "description": "Caught, crushed, jammed or pinched in or between objects at home While engaged in other specified activities During unspecified activity"
    },
    {
      "name": "W233",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area"
    },
    {
      "name": "W2330",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2331",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2332",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While working for income"
    },
    {
      "name": "W2333",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2334",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2338",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2339",
      "description": "Caught, crushed, jammed or pinched in or between objects at sports and athletics area During unspecified activity"
    },
    {
      "name": "W234",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway"
    },
    {
      "name": "W2340",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While engaged in sports activity"
    },
    {
      "name": "W2341",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2342",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While working for income"
    },
    {
      "name": "W2343",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While engaged in other types of work"
    },
    {
      "name": "W2344",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2348",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2349",
      "description": "Caught, crushed, jammed or pinched in or between objects at street and highway During unspecified activity"
    },
    {
      "name": "W235",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area"
    },
    {
      "name": "W2350",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2351",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2352",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While working for income"
    },
    {
      "name": "W2353",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2354",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2358",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2359",
      "description": "Caught, crushed, jammed or pinched in or between objects at trade and service area During unspecified activity"
    },
    {
      "name": "W236",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area"
    },
    {
      "name": "W2360",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2361",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2362",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While working for income"
    },
    {
      "name": "W2363",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2364",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2368",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2369",
      "description": "Caught, crushed, jammed or pinched in or between objects at industrial and construction area During unspecified activity"
    },
    {
      "name": "W237",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm"
    },
    {
      "name": "W2370",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While engaged in sports activity"
    },
    {
      "name": "W2371",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While engaged in leisure activity"
    },
    {
      "name": "W2372",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While working for income"
    },
    {
      "name": "W2373",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While engaged in other types of work"
    },
    {
      "name": "W2374",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2378",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm While engaged in other specified activities"
    },
    {
      "name": "W2379",
      "description": "Caught, crushed, jammed or pinched in or between objects at farm During unspecified activity"
    },
    {
      "name": "W238",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places"
    },
    {
      "name": "W2380",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While engaged in sports activity"
    },
    {
      "name": "W2381",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2382",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While working for income"
    },
    {
      "name": "W2383",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While engaged in other types of work"
    },
    {
      "name": "W2384",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2388",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2389",
      "description": "Caught, crushed, jammed or pinched in or between objects at other specified places During unspecified activity"
    },
    {
      "name": "W239",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place"
    },
    {
      "name": "W2390",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2391",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2392",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While working for income"
    },
    {
      "name": "W2393",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2394",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2398",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2399",
      "description": "Caught, crushed, jammed or pinched in or between objects at unspecified place During unspecified activity"
    },
    {
      "name": "W240",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home"
    },
    {
      "name": "W2400",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While engaged in sports activity"
    },
    {
      "name": "W2401",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While engaged in leisure activity"
    },
    {
      "name": "W2402",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While working for income"
    },
    {
      "name": "W2403",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While engaged in other types of work"
    },
    {
      "name": "W2404",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2408",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home While engaged in other specified activities"
    },
    {
      "name": "W2409",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at home During unspecified activity"
    },
    {
      "name": "W241",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution"
    },
    {
      "name": "W2410",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While engaged in sports activity"
    },
    {
      "name": "W2411",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2412",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While working for income"
    },
    {
      "name": "W2413",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While engaged in other types of work"
    },
    {
      "name": "W2414",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2418",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2419",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at residential institution During unspecified activity"
    },
    {
      "name": "W242",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area"
    },
    {
      "name": "W2420",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2421",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2422",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2423",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2424",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2428",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2429",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W243",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area"
    },
    {
      "name": "W2430",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2431",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2432",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While working for income"
    },
    {
      "name": "W2433",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2434",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2438",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2439",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at sports and athletics area During unspecified activity"
    },
    {
      "name": "W244",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway"
    },
    {
      "name": "W2440",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While engaged in sports activity"
    },
    {
      "name": "W2441",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2442",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While working for income"
    },
    {
      "name": "W2443",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While engaged in other types of work"
    },
    {
      "name": "W2444",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2448",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2449",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at street and highway During unspecified activity"
    },
    {
      "name": "W245",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area"
    },
    {
      "name": "W2450",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2451",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2452",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While working for income"
    },
    {
      "name": "W2453",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2454",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2458",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2459",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at trade and service area During unspecified activity"
    },
    {
      "name": "W246",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area"
    },
    {
      "name": "W2460",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2461",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2462",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While working for income"
    },
    {
      "name": "W2463",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2464",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2468",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2469",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at industrial and construction area During unspecified activity"
    },
    {
      "name": "W247",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm"
    },
    {
      "name": "W2470",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While engaged in sports activity"
    },
    {
      "name": "W2471",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While engaged in leisure activity"
    },
    {
      "name": "W2472",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While working for income"
    },
    {
      "name": "W2473",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While engaged in other types of work"
    },
    {
      "name": "W2474",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2478",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm While engaged in other specified activities"
    },
    {
      "name": "W2479",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at farm During unspecified activity"
    },
    {
      "name": "W248",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places"
    },
    {
      "name": "W2480",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While engaged in sports activity"
    },
    {
      "name": "W2481",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2482",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While working for income"
    },
    {
      "name": "W2483",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While engaged in other types of work"
    },
    {
      "name": "W2484",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2488",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2489",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at other specified places During unspecified activity"
    },
    {
      "name": "W249",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place"
    },
    {
      "name": "W2490",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2491",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2492",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While working for income"
    },
    {
      "name": "W2493",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2494",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2498",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2499",
      "description": "Contact with lifting and transmission devices, not elsewhere classified at unspecified place During unspecified activity"
    },
    {
      "name": "W250",
      "description": "Contact with sharp glass at home"
    },
    {
      "name": "W2500",
      "description": "Contact with sharp glass at home While engaged in sports activity"
    },
    {
      "name": "W2501",
      "description": "Contact with sharp glass at home While engaged in leisure activity"
    },
    {
      "name": "W2502",
      "description": "Contact with sharp glass at home While working for income"
    },
    {
      "name": "W2503",
      "description": "Contact with sharp glass at home While engaged in other types of work"
    },
    {
      "name": "W2504",
      "description": "Contact with sharp glass at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2508",
      "description": "Contact with sharp glass at home While engaged in other specified activities"
    },
    {
      "name": "W2509",
      "description": "Contact with sharp glass at home During unspecified activity"
    },
    {
      "name": "W251",
      "description": "Contact with sharp glass at residential institution"
    },
    {
      "name": "W2510",
      "description": "Contact with sharp glass at residential institution While engaged in sports activity"
    },
    {
      "name": "W2511",
      "description": "Contact with sharp glass at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2512",
      "description": "Contact with sharp glass at residential institution While working for income"
    },
    {
      "name": "W2513",
      "description": "Contact with sharp glass at residential institution While engaged in other types of work"
    },
    {
      "name": "W2514",
      "description": "Contact with sharp glass at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2518",
      "description": "Contact with sharp glass at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2519",
      "description": "Contact with sharp glass at residential institution During unspecified activity"
    },
    {
      "name": "W252",
      "description": "Contact with sharp glass at school,other institution and public administrative area"
    },
    {
      "name": "W2520",
      "description": "Contact with sharp glass at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2521",
      "description": "Contact with sharp glass at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2522",
      "description": "Contact with sharp glass at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2523",
      "description": "Contact with sharp glass at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2524",
      "description": "Contact with sharp glass at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2528",
      "description": "Contact with sharp glass at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2529",
      "description": "Contact with sharp glass at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W253",
      "description": "Contact with sharp glass at sports and athletics area"
    },
    {
      "name": "W2530",
      "description": "Contact with sharp glass at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2531",
      "description": "Contact with sharp glass at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2532",
      "description": "Contact with sharp glass at sports and athletics area While working for income"
    },
    {
      "name": "W2533",
      "description": "Contact with sharp glass at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2534",
      "description": "Contact with sharp glass at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2538",
      "description": "Contact with sharp glass at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2539",
      "description": "Contact with sharp glass at sports and athletics area During unspecified activity"
    },
    {
      "name": "W254",
      "description": "Contact with sharp glass at street and highway"
    },
    {
      "name": "W2540",
      "description": "Contact with sharp glass at street and highway While engaged in sports activity"
    },
    {
      "name": "W2541",
      "description": "Contact with sharp glass at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2542",
      "description": "Contact with sharp glass at street and highway While working for income"
    },
    {
      "name": "W2543",
      "description": "Contact with sharp glass at street and highway While engaged in other types of work"
    },
    {
      "name": "W2544",
      "description": "Contact with sharp glass at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2548",
      "description": "Contact with sharp glass at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2549",
      "description": "Contact with sharp glass at street and highway During unspecified activity"
    },
    {
      "name": "W255",
      "description": "Contact with sharp glass at trade and service area"
    },
    {
      "name": "W2550",
      "description": "Contact with sharp glass at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2551",
      "description": "Contact with sharp glass at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2552",
      "description": "Contact with sharp glass at trade and service area While working for income"
    },
    {
      "name": "W2553",
      "description": "Contact with sharp glass at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2554",
      "description": "Contact with sharp glass at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2558",
      "description": "Contact with sharp glass at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2559",
      "description": "Contact with sharp glass at trade and service area During unspecified activity"
    },
    {
      "name": "W256",
      "description": "Contact with sharp glass at industrial and construction area"
    },
    {
      "name": "W2560",
      "description": "Contact with sharp glass at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2561",
      "description": "Contact with sharp glass at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2562",
      "description": "Contact with sharp glass at industrial and construction area While working for income"
    },
    {
      "name": "W2563",
      "description": "Contact with sharp glass at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2564",
      "description": "Contact with sharp glass at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2568",
      "description": "Contact with sharp glass at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2569",
      "description": "Contact with sharp glass at industrial and construction area During unspecified activity"
    },
    {
      "name": "W257",
      "description": "Contact with sharp glass at farm"
    },
    {
      "name": "W2570",
      "description": "Contact with sharp glass at farm While engaged in sports activity"
    },
    {
      "name": "W2571",
      "description": "Contact with sharp glass at farm While engaged in leisure activity"
    },
    {
      "name": "W2572",
      "description": "Contact with sharp glass at farm While working for income"
    },
    {
      "name": "W2573",
      "description": "Contact with sharp glass at farm While engaged in other types of work"
    },
    {
      "name": "W2574",
      "description": "Contact with sharp glass at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2578",
      "description": "Contact with sharp glass at farm While engaged in other specified activities"
    },
    {
      "name": "W2579",
      "description": "Contact with sharp glass at farm During unspecified activity"
    },
    {
      "name": "W258",
      "description": "Contact with sharp glass at other specified places"
    },
    {
      "name": "W2580",
      "description": "Contact with sharp glass at other specified places While engaged in sports activity"
    },
    {
      "name": "W2581",
      "description": "Contact with sharp glass at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2582",
      "description": "Contact with sharp glass at other specified places While working for income"
    },
    {
      "name": "W2583",
      "description": "Contact with sharp glass at other specified places While engaged in other types of work"
    },
    {
      "name": "W2584",
      "description": "Contact with sharp glass at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2588",
      "description": "Contact with sharp glass at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2589",
      "description": "Contact with sharp glass at other specified places During unspecified activity"
    },
    {
      "name": "W259",
      "description": "Contact with sharp glass at unspecified place"
    },
    {
      "name": "W2590",
      "description": "Contact with sharp glass at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2591",
      "description": "Contact with sharp glass at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2592",
      "description": "Contact with sharp glass at unspecified place While working for income"
    },
    {
      "name": "W2593",
      "description": "Contact with sharp glass at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2594",
      "description": "Contact with sharp glass at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2598",
      "description": "Contact with sharp glass at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2599",
      "description": "Contact with sharp glass at unspecified place During unspecified activity"
    },
    {
      "name": "W260",
      "description": "Contact with knife, sword or dagger at home"
    },
    {
      "name": "W2600",
      "description": "Contact with knife, sword or dagger at home While engaged in sports activity"
    },
    {
      "name": "W2601",
      "description": "Contact with knife, sword or dagger at home While engaged in leisure activity"
    },
    {
      "name": "W2602",
      "description": "Contact with knife, sword or dagger at home While working for income"
    },
    {
      "name": "W2603",
      "description": "Contact with knife, sword or dagger at home While engaged in other types of work"
    },
    {
      "name": "W2604",
      "description": "Contact with knife, sword or dagger at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2608",
      "description": "Contact with knife, sword or dagger at home While engaged in other specified activities"
    },
    {
      "name": "W2609",
      "description": "Contact with knife, sword or dagger at home During unspecified activity"
    },
    {
      "name": "W261",
      "description": "Contact with knife, sword or dagger at residential institution"
    },
    {
      "name": "W2610",
      "description": "Contact with knife, sword or dagger at residential institution While engaged in sports activity"
    },
    {
      "name": "W2611",
      "description": "Contact with knife, sword or dagger at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2612",
      "description": "Contact with knife, sword or dagger at residential institution While working for income"
    },
    {
      "name": "W2613",
      "description": "Contact with knife, sword or dagger at residential institution While engaged in other types of work"
    },
    {
      "name": "W2614",
      "description": "Contact with knife, sword or dagger at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2618",
      "description": "Contact with knife, sword or dagger at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2619",
      "description": "Contact with knife, sword or dagger at residential institution During unspecified activity"
    },
    {
      "name": "W262",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area"
    },
    {
      "name": "W2620",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2621",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2622",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2623",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2624",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2628",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2629",
      "description": "Contact with knife, sword or dagger at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W263",
      "description": "Contact with knife, sword or dagger at sports and athletics area"
    },
    {
      "name": "W2630",
      "description": "Contact with knife, sword or dagger at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2631",
      "description": "Contact with knife, sword or dagger at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2632",
      "description": "Contact with knife, sword or dagger at sports and athletics area While working for income"
    },
    {
      "name": "W2633",
      "description": "Contact with knife, sword or dagger at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2634",
      "description": "Contact with knife, sword or dagger at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2638",
      "description": "Contact with knife, sword or dagger at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2639",
      "description": "Contact with knife, sword or dagger at sports and athletics area During unspecified activity"
    },
    {
      "name": "W264",
      "description": "Contact with knife, sword or dagger at street and highway"
    },
    {
      "name": "W2640",
      "description": "Contact with knife, sword or dagger at street and highway While engaged in sports activity"
    },
    {
      "name": "W2641",
      "description": "Contact with knife, sword or dagger at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2642",
      "description": "Contact with knife, sword or dagger at street and highway While working for income"
    },
    {
      "name": "W2643",
      "description": "Contact with knife, sword or dagger at street and highway While engaged in other types of work"
    },
    {
      "name": "W2644",
      "description": "Contact with knife, sword or dagger at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2648",
      "description": "Contact with knife, sword or dagger at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2649",
      "description": "Contact with knife, sword or dagger at street and highway During unspecified activity"
    },
    {
      "name": "W265",
      "description": "Contact with knife, sword or dagger at trade and service area"
    },
    {
      "name": "W2650",
      "description": "Contact with knife, sword or dagger at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2651",
      "description": "Contact with knife, sword or dagger at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2652",
      "description": "Contact with knife, sword or dagger at trade and service area While working for income"
    },
    {
      "name": "W2653",
      "description": "Contact with knife, sword or dagger at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2654",
      "description": "Contact with knife, sword or dagger at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2658",
      "description": "Contact with knife, sword or dagger at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2659",
      "description": "Contact with knife, sword or dagger at trade and service area During unspecified activity"
    },
    {
      "name": "W266",
      "description": "Contact with knife, sword or dagger at industrial and construction area"
    },
    {
      "name": "W2660",
      "description": "Contact with knife, sword or dagger at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2661",
      "description": "Contact with knife, sword or dagger at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2662",
      "description": "Contact with knife, sword or dagger at industrial and construction area While working for income"
    },
    {
      "name": "W2663",
      "description": "Contact with knife, sword or dagger at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2664",
      "description": "Contact with knife, sword or dagger at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2668",
      "description": "Contact with knife, sword or dagger at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2669",
      "description": "Contact with knife, sword or dagger at industrial and construction area During unspecified activity"
    },
    {
      "name": "W267",
      "description": "Contact with knife, sword or dagger at farm"
    },
    {
      "name": "W2670",
      "description": "Contact with knife, sword or dagger at farm While engaged in sports activity"
    },
    {
      "name": "W2671",
      "description": "Contact with knife, sword or dagger at farm While engaged in leisure activity"
    },
    {
      "name": "W2672",
      "description": "Contact with knife, sword or dagger at farm While working for income"
    },
    {
      "name": "W2673",
      "description": "Contact with knife, sword or dagger at farm While engaged in other types of work"
    },
    {
      "name": "W2674",
      "description": "Contact with knife, sword or dagger at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2678",
      "description": "Contact with knife, sword or dagger at farm While engaged in other specified activities"
    },
    {
      "name": "W2679",
      "description": "Contact with knife, sword or dagger at farm During unspecified activity"
    },
    {
      "name": "W268",
      "description": "Contact with knife, sword or dagger at other specified places"
    },
    {
      "name": "W2680",
      "description": "Contact with knife, sword or dagger at other specified places While engaged in sports activity"
    },
    {
      "name": "W2681",
      "description": "Contact with knife, sword or dagger at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2682",
      "description": "Contact with knife, sword or dagger at other specified places While working for income"
    },
    {
      "name": "W2683",
      "description": "Contact with knife, sword or dagger at other specified places While engaged in other types of work"
    },
    {
      "name": "W2684",
      "description": "Contact with knife, sword or dagger at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2688",
      "description": "Contact with knife, sword or dagger at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2689",
      "description": "Contact with knife, sword or dagger at other specified places During unspecified activity"
    },
    {
      "name": "W269",
      "description": "Contact with knife, sword or dagger at unspecified place"
    },
    {
      "name": "W2690",
      "description": "Contact with knife, sword or dagger at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2691",
      "description": "Contact with knife, sword or dagger at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2692",
      "description": "Contact with knife, sword or dagger at unspecified place While working for income"
    },
    {
      "name": "W2693",
      "description": "Contact with knife, sword or dagger at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2694",
      "description": "Contact with knife, sword or dagger at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2698",
      "description": "Contact with knife, sword or dagger at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2699",
      "description": "Contact with knife, sword or dagger at unspecified place During unspecified activity"
    },
    {
      "name": "W270",
      "description": "Contact with nonpowered hand tool at home"
    },
    {
      "name": "W2700",
      "description": "Contact with nonpowered hand tool at home While engaged in sports activity"
    },
    {
      "name": "W2701",
      "description": "Contact with nonpowered hand tool at home While engaged in leisure activity"
    },
    {
      "name": "W2702",
      "description": "Contact with nonpowered hand tool at home While working for income"
    },
    {
      "name": "W2703",
      "description": "Contact with nonpowered hand tool at home While engaged in other types of work"
    },
    {
      "name": "W2704",
      "description": "Contact with nonpowered hand tool at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2708",
      "description": "Contact with nonpowered hand tool at home While engaged in other specified activities"
    },
    {
      "name": "W2709",
      "description": "Contact with nonpowered hand tool at home During unspecified activity"
    },
    {
      "name": "W271",
      "description": "Contact with nonpowered hand tool at residential institution"
    },
    {
      "name": "W2710",
      "description": "Contact with nonpowered hand tool at residential institution While engaged in sports activity"
    },
    {
      "name": "W2711",
      "description": "Contact with nonpowered hand tool at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2712",
      "description": "Contact with nonpowered hand tool at residential institution While working for income"
    },
    {
      "name": "W2713",
      "description": "Contact with nonpowered hand tool at residential institution While engaged in other types of work"
    },
    {
      "name": "W2714",
      "description": "Contact with nonpowered hand tool at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2718",
      "description": "Contact with nonpowered hand tool at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2719",
      "description": "Contact with nonpowered hand tool at residential institution During unspecified activity"
    },
    {
      "name": "W272",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area"
    },
    {
      "name": "W2720",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2721",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2722",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2723",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2724",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2728",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2729",
      "description": "Contact with nonpowered hand tool at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W273",
      "description": "Contact with nonpowered hand tool at sports and athletics area"
    },
    {
      "name": "W2730",
      "description": "Contact with nonpowered hand tool at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2731",
      "description": "Contact with nonpowered hand tool at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2732",
      "description": "Contact with nonpowered hand tool at sports and athletics area While working for income"
    },
    {
      "name": "W2733",
      "description": "Contact with nonpowered hand tool at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2734",
      "description": "Contact with nonpowered hand tool at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2738",
      "description": "Contact with nonpowered hand tool at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2739",
      "description": "Contact with nonpowered hand tool at sports and athletics area During unspecified activity"
    },
    {
      "name": "W274",
      "description": "Contact with nonpowered hand tool at street and highway"
    },
    {
      "name": "W2740",
      "description": "Contact with nonpowered hand tool at street and highway While engaged in sports activity"
    },
    {
      "name": "W2741",
      "description": "Contact with nonpowered hand tool at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2742",
      "description": "Contact with nonpowered hand tool at street and highway While working for income"
    },
    {
      "name": "W2743",
      "description": "Contact with nonpowered hand tool at street and highway While engaged in other types of work"
    },
    {
      "name": "W2744",
      "description": "Contact with nonpowered hand tool at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2748",
      "description": "Contact with nonpowered hand tool at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2749",
      "description": "Contact with nonpowered hand tool at street and highway During unspecified activity"
    },
    {
      "name": "W275",
      "description": "Contact with nonpowered hand tool at trade and service area"
    },
    {
      "name": "W2750",
      "description": "Contact with nonpowered hand tool at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2751",
      "description": "Contact with nonpowered hand tool at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2752",
      "description": "Contact with nonpowered hand tool at trade and service area While working for income"
    },
    {
      "name": "W2753",
      "description": "Contact with nonpowered hand tool at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2754",
      "description": "Contact with nonpowered hand tool at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2758",
      "description": "Contact with nonpowered hand tool at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2759",
      "description": "Contact with nonpowered hand tool at trade and service area During unspecified activity"
    },
    {
      "name": "W276",
      "description": "Contact with nonpowered hand tool at industrial and construction area"
    },
    {
      "name": "W2760",
      "description": "Contact with nonpowered hand tool at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2761",
      "description": "Contact with nonpowered hand tool at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2762",
      "description": "Contact with nonpowered hand tool at industrial and construction area While working for income"
    },
    {
      "name": "W2763",
      "description": "Contact with nonpowered hand tool at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2764",
      "description": "Contact with nonpowered hand tool at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2768",
      "description": "Contact with nonpowered hand tool at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2769",
      "description": "Contact with nonpowered hand tool at industrial and construction area During unspecified activity"
    },
    {
      "name": "W277",
      "description": "Contact with nonpowered hand tool at farm"
    },
    {
      "name": "W2770",
      "description": "Contact with nonpowered hand tool at farm While engaged in sports activity"
    },
    {
      "name": "W2771",
      "description": "Contact with nonpowered hand tool at farm While engaged in leisure activity"
    },
    {
      "name": "W2772",
      "description": "Contact with nonpowered hand tool at farm While working for income"
    },
    {
      "name": "W2773",
      "description": "Contact with nonpowered hand tool at farm While engaged in other types of work"
    },
    {
      "name": "W2774",
      "description": "Contact with nonpowered hand tool at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2778",
      "description": "Contact with nonpowered hand tool at farm While engaged in other specified activities"
    },
    {
      "name": "W2779",
      "description": "Contact with nonpowered hand tool at farm During unspecified activity"
    },
    {
      "name": "W278",
      "description": "Contact with nonpowered hand tool at other specified places"
    },
    {
      "name": "W2780",
      "description": "Contact with nonpowered hand tool at other specified places While engaged in sports activity"
    },
    {
      "name": "W2781",
      "description": "Contact with nonpowered hand tool at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2782",
      "description": "Contact with nonpowered hand tool at other specified places While working for income"
    },
    {
      "name": "W2783",
      "description": "Contact with nonpowered hand tool at other specified places While engaged in other types of work"
    },
    {
      "name": "W2784",
      "description": "Contact with nonpowered hand tool at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2788",
      "description": "Contact with nonpowered hand tool at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2789",
      "description": "Contact with nonpowered hand tool at other specified places During unspecified activity"
    },
    {
      "name": "W279",
      "description": "Contact with nonpowered hand tool at unspecified place"
    },
    {
      "name": "W2790",
      "description": "Contact with nonpowered hand tool at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2791",
      "description": "Contact with nonpowered hand tool at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2792",
      "description": "Contact with nonpowered hand tool at unspecified place While working for income"
    },
    {
      "name": "W2793",
      "description": "Contact with nonpowered hand tool at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2794",
      "description": "Contact with nonpowered hand tool at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2798",
      "description": "Contact with nonpowered hand tool at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2799",
      "description": "Contact with nonpowered hand tool at unspecified place During unspecified activity"
    },
    {
      "name": "W280",
      "description": "Contact with powered lawnmower at home"
    },
    {
      "name": "W2800",
      "description": "Contact with powered lawnmower at home While engaged in sports activity"
    },
    {
      "name": "W2801",
      "description": "Contact with powered lawnmower at home While engaged in leisure activity"
    },
    {
      "name": "W2802",
      "description": "Contact with powered lawnmower at home While working for income"
    },
    {
      "name": "W2803",
      "description": "Contact with powered lawnmower at home While engaged in other types of work"
    },
    {
      "name": "W2804",
      "description": "Contact with powered lawnmower at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2808",
      "description": "Contact with powered lawnmower at home While engaged in other specified activities"
    },
    {
      "name": "W2809",
      "description": "Contact with powered lawnmower at home During unspecified activity"
    },
    {
      "name": "W281",
      "description": "Contact with powered lawnmower at residential institution"
    },
    {
      "name": "W2810",
      "description": "Contact with powered lawnmower at residential institution While engaged in sports activity"
    },
    {
      "name": "W2811",
      "description": "Contact with powered lawnmower at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2812",
      "description": "Contact with powered lawnmower at residential institution While working for income"
    },
    {
      "name": "W2813",
      "description": "Contact with powered lawnmower at residential institution While engaged in other types of work"
    },
    {
      "name": "W2814",
      "description": "Contact with powered lawnmower at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2818",
      "description": "Contact with powered lawnmower at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2819",
      "description": "Contact with powered lawnmower at residential institution During unspecified activity"
    },
    {
      "name": "W282",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area"
    },
    {
      "name": "W2820",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2821",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2822",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2823",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2824",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2828",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2829",
      "description": "Contact with powered lawnmower at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W283",
      "description": "Contact with powered lawnmower at sports and athletics area"
    },
    {
      "name": "W2830",
      "description": "Contact with powered lawnmower at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2831",
      "description": "Contact with powered lawnmower at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2832",
      "description": "Contact with powered lawnmower at sports and athletics area While working for income"
    },
    {
      "name": "W2833",
      "description": "Contact with powered lawnmower at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2834",
      "description": "Contact with powered lawnmower at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2838",
      "description": "Contact with powered lawnmower at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2839",
      "description": "Contact with powered lawnmower at sports and athletics area During unspecified activity"
    },
    {
      "name": "W284",
      "description": "Contact with powered lawnmower at street and highway"
    },
    {
      "name": "W2840",
      "description": "Contact with powered lawnmower at street and highway While engaged in sports activity"
    },
    {
      "name": "W2841",
      "description": "Contact with powered lawnmower at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2842",
      "description": "Contact with powered lawnmower at street and highway While working for income"
    },
    {
      "name": "W2843",
      "description": "Contact with powered lawnmower at street and highway While engaged in other types of work"
    },
    {
      "name": "W2844",
      "description": "Contact with powered lawnmower at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2848",
      "description": "Contact with powered lawnmower at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2849",
      "description": "Contact with powered lawnmower at street and highway During unspecified activity"
    },
    {
      "name": "W285",
      "description": "Contact with powered lawnmower at trade and service area"
    },
    {
      "name": "W2850",
      "description": "Contact with powered lawnmower at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2851",
      "description": "Contact with powered lawnmower at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2852",
      "description": "Contact with powered lawnmower at trade and service area While working for income"
    },
    {
      "name": "W2853",
      "description": "Contact with powered lawnmower at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2854",
      "description": "Contact with powered lawnmower at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2858",
      "description": "Contact with powered lawnmower at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2859",
      "description": "Contact with powered lawnmower at trade and service area During unspecified activity"
    },
    {
      "name": "W286",
      "description": "Contact with powered lawnmower at industrial and construction area"
    },
    {
      "name": "W2860",
      "description": "Contact with powered lawnmower at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2861",
      "description": "Contact with powered lawnmower at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2862",
      "description": "Contact with powered lawnmower at industrial and construction area While working for income"
    },
    {
      "name": "W2863",
      "description": "Contact with powered lawnmower at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2864",
      "description": "Contact with powered lawnmower at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2868",
      "description": "Contact with powered lawnmower at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2869",
      "description": "Contact with powered lawnmower at industrial and construction area During unspecified activity"
    },
    {
      "name": "W287",
      "description": "Contact with powered lawnmower at farm"
    },
    {
      "name": "W2870",
      "description": "Contact with powered lawnmower at farm While engaged in sports activity"
    },
    {
      "name": "W2871",
      "description": "Contact with powered lawnmower at farm While engaged in leisure activity"
    },
    {
      "name": "W2872",
      "description": "Contact with powered lawnmower at farm While working for income"
    },
    {
      "name": "W2873",
      "description": "Contact with powered lawnmower at farm While engaged in other types of work"
    },
    {
      "name": "W2874",
      "description": "Contact with powered lawnmower at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2878",
      "description": "Contact with powered lawnmower at farm While engaged in other specified activities"
    },
    {
      "name": "W2879",
      "description": "Contact with powered lawnmower at farm During unspecified activity"
    },
    {
      "name": "W288",
      "description": "Contact with powered lawnmower at other specified places"
    },
    {
      "name": "W2880",
      "description": "Contact with powered lawnmower at other specified places While engaged in sports activity"
    },
    {
      "name": "W2881",
      "description": "Contact with powered lawnmower at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2882",
      "description": "Contact with powered lawnmower at other specified places While working for income"
    },
    {
      "name": "W2883",
      "description": "Contact with powered lawnmower at other specified places While engaged in other types of work"
    },
    {
      "name": "W2884",
      "description": "Contact with powered lawnmower at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2888",
      "description": "Contact with powered lawnmower at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2889",
      "description": "Contact with powered lawnmower at other specified places During unspecified activity"
    },
    {
      "name": "W289",
      "description": "Contact with powered lawnmower at unspecified place"
    },
    {
      "name": "W2890",
      "description": "Contact with powered lawnmower at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2891",
      "description": "Contact with powered lawnmower at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2892",
      "description": "Contact with powered lawnmower at unspecified place While working for income"
    },
    {
      "name": "W2893",
      "description": "Contact with powered lawnmower at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2894",
      "description": "Contact with powered lawnmower at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2898",
      "description": "Contact with powered lawnmower at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2899",
      "description": "Contact with powered lawnmower at unspecified place During unspecified activity"
    },
    {
      "name": "W290",
      "description": "Contact with other powered hand tools and household machinery at home"
    },
    {
      "name": "W2900",
      "description": "Contact with other powered hand tools and household machinery at home While engaged in sports activity"
    },
    {
      "name": "W2901",
      "description": "Contact with other powered hand tools and household machinery at home While engaged in leisure activity"
    },
    {
      "name": "W2902",
      "description": "Contact with other powered hand tools and household machinery at home While working for income"
    },
    {
      "name": "W2903",
      "description": "Contact with other powered hand tools and household machinery at home While engaged in other types of work"
    },
    {
      "name": "W2904",
      "description": "Contact with other powered hand tools and household machinery at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2908",
      "description": "Contact with other powered hand tools and household machinery at home While engaged in other specified activities"
    },
    {
      "name": "W2909",
      "description": "Contact with other powered hand tools and household machinery at home During unspecified activity"
    },
    {
      "name": "W291",
      "description": "Contact with other powered hand tools and household machinery at residential institution"
    },
    {
      "name": "W2910",
      "description": "Contact with other powered hand tools and household machinery at residential institution While engaged in sports activity"
    },
    {
      "name": "W2911",
      "description": "Contact with other powered hand tools and household machinery at residential institution While engaged in leisure activity"
    },
    {
      "name": "W2912",
      "description": "Contact with other powered hand tools and household machinery at residential institution While working for income"
    },
    {
      "name": "W2913",
      "description": "Contact with other powered hand tools and household machinery at residential institution While engaged in other types of work"
    },
    {
      "name": "W2914",
      "description": "Contact with other powered hand tools and household machinery at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2918",
      "description": "Contact with other powered hand tools and household machinery at residential institution While engaged in other specified activities"
    },
    {
      "name": "W2919",
      "description": "Contact with other powered hand tools and household machinery at residential institution During unspecified activity"
    },
    {
      "name": "W292",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area"
    },
    {
      "name": "W2920",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W2921",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W2922",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W2923",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W2924",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2928",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W2929",
      "description": "Contact with other powered hand tools and household machinery at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W293",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area"
    },
    {
      "name": "W2930",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W2931",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W2932",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While working for income"
    },
    {
      "name": "W2933",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W2934",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2938",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W2939",
      "description": "Contact with other powered hand tools and household machinery at sports and athletics area During unspecified activity"
    },
    {
      "name": "W294",
      "description": "Contact with other powered hand tools and household machinery at street and highway"
    },
    {
      "name": "W2940",
      "description": "Contact with other powered hand tools and household machinery at street and highway While engaged in sports activity"
    },
    {
      "name": "W2941",
      "description": "Contact with other powered hand tools and household machinery at street and highway While engaged in leisure activity"
    },
    {
      "name": "W2942",
      "description": "Contact with other powered hand tools and household machinery at street and highway While working for income"
    },
    {
      "name": "W2943",
      "description": "Contact with other powered hand tools and household machinery at street and highway While engaged in other types of work"
    },
    {
      "name": "W2944",
      "description": "Contact with other powered hand tools and household machinery at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2948",
      "description": "Contact with other powered hand tools and household machinery at street and highway While engaged in other specified activities"
    },
    {
      "name": "W2949",
      "description": "Contact with other powered hand tools and household machinery at street and highway During unspecified activity"
    },
    {
      "name": "W295",
      "description": "Contact with other powered hand tools and household machinery at trade and service area"
    },
    {
      "name": "W2950",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While engaged in sports activity"
    },
    {
      "name": "W2951",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W2952",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While working for income"
    },
    {
      "name": "W2953",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While engaged in other types of work"
    },
    {
      "name": "W2954",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2958",
      "description": "Contact with other powered hand tools and household machinery at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W2959",
      "description": "Contact with other powered hand tools and household machinery at trade and service area During unspecified activity"
    },
    {
      "name": "W296",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area"
    },
    {
      "name": "W2960",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W2961",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W2962",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While working for income"
    },
    {
      "name": "W2963",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W2964",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2968",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W2969",
      "description": "Contact with other powered hand tools and household machinery at industrial and construction area During unspecified activity"
    },
    {
      "name": "W297",
      "description": "Contact with other powered hand tools and household machinery at farm"
    },
    {
      "name": "W2970",
      "description": "Contact with other powered hand tools and household machinery at farm While engaged in sports activity"
    },
    {
      "name": "W2971",
      "description": "Contact with other powered hand tools and household machinery at farm While engaged in leisure activity"
    },
    {
      "name": "W2972",
      "description": "Contact with other powered hand tools and household machinery at farm While working for income"
    },
    {
      "name": "W2973",
      "description": "Contact with other powered hand tools and household machinery at farm While engaged in other types of work"
    },
    {
      "name": "W2974",
      "description": "Contact with other powered hand tools and household machinery at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2978",
      "description": "Contact with other powered hand tools and household machinery at farm While engaged in other specified activities"
    },
    {
      "name": "W2979",
      "description": "Contact with other powered hand tools and household machinery at farm During unspecified activity"
    },
    {
      "name": "W298",
      "description": "Contact with other powered hand tools and household machinery at other specified places"
    },
    {
      "name": "W2980",
      "description": "Contact with other powered hand tools and household machinery at other specified places While engaged in sports activity"
    },
    {
      "name": "W2981",
      "description": "Contact with other powered hand tools and household machinery at other specified places While engaged in leisure activity"
    },
    {
      "name": "W2982",
      "description": "Contact with other powered hand tools and household machinery at other specified places While working for income"
    },
    {
      "name": "W2983",
      "description": "Contact with other powered hand tools and household machinery at other specified places While engaged in other types of work"
    },
    {
      "name": "W2984",
      "description": "Contact with other powered hand tools and household machinery at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2988",
      "description": "Contact with other powered hand tools and household machinery at other specified places While engaged in other specified activities"
    },
    {
      "name": "W2989",
      "description": "Contact with other powered hand tools and household machinery at other specified places During unspecified activity"
    },
    {
      "name": "W299",
      "description": "Contact with other powered hand tools and household machinery at unspecified place"
    },
    {
      "name": "W2990",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While engaged in sports activity"
    },
    {
      "name": "W2991",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W2992",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While working for income"
    },
    {
      "name": "W2993",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While engaged in other types of work"
    },
    {
      "name": "W2994",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W2998",
      "description": "Contact with other powered hand tools and household machinery at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W2999",
      "description": "Contact with other powered hand tools and household machinery at unspecified place During unspecified activity"
    },
    {
      "name": "W300",
      "description": "Contact with agricultural machinery at home"
    },
    {
      "name": "W3000",
      "description": "Contact with agricultural machinery at home While engaged in sports activity"
    },
    {
      "name": "W3001",
      "description": "Contact with agricultural machinery at home While engaged in leisure activity"
    },
    {
      "name": "W3002",
      "description": "Contact with agricultural machinery at home While working for income"
    },
    {
      "name": "W3003",
      "description": "Contact with agricultural machinery at home While engaged in other types of work"
    },
    {
      "name": "W3004",
      "description": "Contact with agricultural machinery at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3008",
      "description": "Contact with agricultural machinery at home While engaged in other specified activities"
    },
    {
      "name": "W3009",
      "description": "Contact with agricultural machinery at home During unspecified activity"
    },
    {
      "name": "W301",
      "description": "Contact with agricultural machinery at residential institution"
    },
    {
      "name": "W3010",
      "description": "Contact with agricultural machinery at residential institution While engaged in sports activity"
    },
    {
      "name": "W3011",
      "description": "Contact with agricultural machinery at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3012",
      "description": "Contact with agricultural machinery at residential institution While working for income"
    },
    {
      "name": "W3013",
      "description": "Contact with agricultural machinery at residential institution While engaged in other types of work"
    },
    {
      "name": "W3014",
      "description": "Contact with agricultural machinery at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3018",
      "description": "Contact with agricultural machinery at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3019",
      "description": "Contact with agricultural machinery at residential institution During unspecified activity"
    },
    {
      "name": "W302",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area"
    },
    {
      "name": "W3020",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3021",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3022",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3023",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3024",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3028",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3029",
      "description": "Contact with agricultural machinery at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W303",
      "description": "Contact with agricultural machinery at sports and athletics area"
    },
    {
      "name": "W3030",
      "description": "Contact with agricultural machinery at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3031",
      "description": "Contact with agricultural machinery at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3032",
      "description": "Contact with agricultural machinery at sports and athletics area While working for income"
    },
    {
      "name": "W3033",
      "description": "Contact with agricultural machinery at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3034",
      "description": "Contact with agricultural machinery at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3038",
      "description": "Contact with agricultural machinery at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3039",
      "description": "Contact with agricultural machinery at sports and athletics area During unspecified activity"
    },
    {
      "name": "W304",
      "description": "Contact with agricultural machinery at street and highway"
    },
    {
      "name": "W3040",
      "description": "Contact with agricultural machinery at street and highway While engaged in sports activity"
    },
    {
      "name": "W3041",
      "description": "Contact with agricultural machinery at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3042",
      "description": "Contact with agricultural machinery at street and highway While working for income"
    },
    {
      "name": "W3043",
      "description": "Contact with agricultural machinery at street and highway While engaged in other types of work"
    },
    {
      "name": "W3044",
      "description": "Contact with agricultural machinery at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3048",
      "description": "Contact with agricultural machinery at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3049",
      "description": "Contact with agricultural machinery at street and highway During unspecified activity"
    },
    {
      "name": "W305",
      "description": "Contact with agricultural machinery at trade and service area"
    },
    {
      "name": "W3050",
      "description": "Contact with agricultural machinery at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3051",
      "description": "Contact with agricultural machinery at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3052",
      "description": "Contact with agricultural machinery at trade and service area While working for income"
    },
    {
      "name": "W3053",
      "description": "Contact with agricultural machinery at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3054",
      "description": "Contact with agricultural machinery at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3058",
      "description": "Contact with agricultural machinery at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3059",
      "description": "Contact with agricultural machinery at trade and service area During unspecified activity"
    },
    {
      "name": "W306",
      "description": "Contact with agricultural machinery at industrial and construction area"
    },
    {
      "name": "W3060",
      "description": "Contact with agricultural machinery at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3061",
      "description": "Contact with agricultural machinery at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3062",
      "description": "Contact with agricultural machinery at industrial and construction area While working for income"
    },
    {
      "name": "W3063",
      "description": "Contact with agricultural machinery at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3064",
      "description": "Contact with agricultural machinery at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3068",
      "description": "Contact with agricultural machinery at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3069",
      "description": "Contact with agricultural machinery at industrial and construction area During unspecified activity"
    },
    {
      "name": "W307",
      "description": "Contact with agricultural machinery at farm"
    },
    {
      "name": "W3070",
      "description": "Contact with agricultural machinery at farm While engaged in sports activity"
    },
    {
      "name": "W3071",
      "description": "Contact with agricultural machinery at farm While engaged in leisure activity"
    },
    {
      "name": "W3072",
      "description": "Contact with agricultural machinery at farm While working for income"
    },
    {
      "name": "W3073",
      "description": "Contact with agricultural machinery at farm While engaged in other types of work"
    },
    {
      "name": "W3074",
      "description": "Contact with agricultural machinery at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3078",
      "description": "Contact with agricultural machinery at farm While engaged in other specified activities"
    },
    {
      "name": "W3079",
      "description": "Contact with agricultural machinery at farm During unspecified activity"
    },
    {
      "name": "W308",
      "description": "Contact with agricultural machinery at other specified places"
    },
    {
      "name": "W3080",
      "description": "Contact with agricultural machinery at other specified places While engaged in sports activity"
    },
    {
      "name": "W3081",
      "description": "Contact with agricultural machinery at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3082",
      "description": "Contact with agricultural machinery at other specified places While working for income"
    },
    {
      "name": "W3083",
      "description": "Contact with agricultural machinery at other specified places While engaged in other types of work"
    },
    {
      "name": "W3084",
      "description": "Contact with agricultural machinery at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3088",
      "description": "Contact with agricultural machinery at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3089",
      "description": "Contact with agricultural machinery at other specified places During unspecified activity"
    },
    {
      "name": "W309",
      "description": "Contact with agricultural machinery at unspecified place"
    },
    {
      "name": "W3090",
      "description": "Contact with agricultural machinery at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3091",
      "description": "Contact with agricultural machinery at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3092",
      "description": "Contact with agricultural machinery at unspecified place While working for income"
    },
    {
      "name": "W3093",
      "description": "Contact with agricultural machinery at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3094",
      "description": "Contact with agricultural machinery at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3098",
      "description": "Contact with agricultural machinery at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3099",
      "description": "Contact with agricultural machinery at unspecified place During unspecified activity"
    },
    {
      "name": "W310",
      "description": "Contact with other and unspecified machinery at home"
    },
    {
      "name": "W3100",
      "description": "Contact with other and unspecified machinery at home While engaged in sports activity"
    },
    {
      "name": "W3101",
      "description": "Contact with other and unspecified machinery at home While engaged in leisure activity"
    },
    {
      "name": "W3102",
      "description": "Contact with other and unspecified machinery at home While working for income"
    },
    {
      "name": "W3103",
      "description": "Contact with other and unspecified machinery at home While engaged in other types of work"
    },
    {
      "name": "W3104",
      "description": "Contact with other and unspecified machinery at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3108",
      "description": "Contact with other and unspecified machinery at home While engaged in other specified activities"
    },
    {
      "name": "W3109",
      "description": "Contact with other and unspecified machinery at home During unspecified activity"
    },
    {
      "name": "W311",
      "description": "Contact with other and unspecified machinery at residential institution"
    },
    {
      "name": "W3110",
      "description": "Contact with other and unspecified machinery at residential institution While engaged in sports activity"
    },
    {
      "name": "W3111",
      "description": "Contact with other and unspecified machinery at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3112",
      "description": "Contact with other and unspecified machinery at residential institution While working for income"
    },
    {
      "name": "W3113",
      "description": "Contact with other and unspecified machinery at residential institution While engaged in other types of work"
    },
    {
      "name": "W3114",
      "description": "Contact with other and unspecified machinery at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3118",
      "description": "Contact with other and unspecified machinery at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3119",
      "description": "Contact with other and unspecified machinery at residential institution During unspecified activity"
    },
    {
      "name": "W312",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area"
    },
    {
      "name": "W3120",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3121",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3122",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3123",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3124",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3128",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3129",
      "description": "Contact with other and unspecified machinery at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W313",
      "description": "Contact with other and unspecified machinery at sports and athletics area"
    },
    {
      "name": "W3130",
      "description": "Contact with other and unspecified machinery at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3131",
      "description": "Contact with other and unspecified machinery at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3132",
      "description": "Contact with other and unspecified machinery at sports and athletics area While working for income"
    },
    {
      "name": "W3133",
      "description": "Contact with other and unspecified machinery at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3134",
      "description": "Contact with other and unspecified machinery at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3138",
      "description": "Contact with other and unspecified machinery at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3139",
      "description": "Contact with other and unspecified machinery at sports and athletics area During unspecified activity"
    },
    {
      "name": "W314",
      "description": "Contact with other and unspecified machinery at street and highway"
    },
    {
      "name": "W3140",
      "description": "Contact with other and unspecified machinery at street and highway While engaged in sports activity"
    },
    {
      "name": "W3141",
      "description": "Contact with other and unspecified machinery at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3142",
      "description": "Contact with other and unspecified machinery at street and highway While working for income"
    },
    {
      "name": "W3143",
      "description": "Contact with other and unspecified machinery at street and highway While engaged in other types of work"
    },
    {
      "name": "W3144",
      "description": "Contact with other and unspecified machinery at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3148",
      "description": "Contact with other and unspecified machinery at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3149",
      "description": "Contact with other and unspecified machinery at street and highway During unspecified activity"
    },
    {
      "name": "W315",
      "description": "Contact with other and unspecified machinery at trade and service area"
    },
    {
      "name": "W3150",
      "description": "Contact with other and unspecified machinery at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3151",
      "description": "Contact with other and unspecified machinery at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3152",
      "description": "Contact with other and unspecified machinery at trade and service area While working for income"
    },
    {
      "name": "W3153",
      "description": "Contact with other and unspecified machinery at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3154",
      "description": "Contact with other and unspecified machinery at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3158",
      "description": "Contact with other and unspecified machinery at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3159",
      "description": "Contact with other and unspecified machinery at trade and service area During unspecified activity"
    },
    {
      "name": "W316",
      "description": "Contact with other and unspecified machinery at industrial and construction area"
    },
    {
      "name": "W3160",
      "description": "Contact with other and unspecified machinery at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3161",
      "description": "Contact with other and unspecified machinery at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3162",
      "description": "Contact with other and unspecified machinery at industrial and construction area While working for income"
    },
    {
      "name": "W3163",
      "description": "Contact with other and unspecified machinery at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3164",
      "description": "Contact with other and unspecified machinery at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3168",
      "description": "Contact with other and unspecified machinery at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3169",
      "description": "Contact with other and unspecified machinery at industrial and construction area During unspecified activity"
    },
    {
      "name": "W317",
      "description": "Contact with other and unspecified machinery at farm"
    },
    {
      "name": "W3170",
      "description": "Contact with other and unspecified machinery at farm While engaged in sports activity"
    },
    {
      "name": "W3171",
      "description": "Contact with other and unspecified machinery at farm While engaged in leisure activity"
    },
    {
      "name": "W3172",
      "description": "Contact with other and unspecified machinery at farm While working for income"
    },
    {
      "name": "W3173",
      "description": "Contact with other and unspecified machinery at farm While engaged in other types of work"
    },
    {
      "name": "W3174",
      "description": "Contact with other and unspecified machinery at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3178",
      "description": "Contact with other and unspecified machinery at farm While engaged in other specified activities"
    },
    {
      "name": "W3179",
      "description": "Contact with other and unspecified machinery at farm During unspecified activity"
    },
    {
      "name": "W318",
      "description": "Contact with other and unspecified machinery at other specified places"
    },
    {
      "name": "W3180",
      "description": "Contact with other and unspecified machinery at other specified places While engaged in sports activity"
    },
    {
      "name": "W3181",
      "description": "Contact with other and unspecified machinery at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3182",
      "description": "Contact with other and unspecified machinery at other specified places While working for income"
    },
    {
      "name": "W3183",
      "description": "Contact with other and unspecified machinery at other specified places While engaged in other types of work"
    },
    {
      "name": "W3184",
      "description": "Contact with other and unspecified machinery at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3188",
      "description": "Contact with other and unspecified machinery at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3189",
      "description": "Contact with other and unspecified machinery at other specified places During unspecified activity"
    },
    {
      "name": "W319",
      "description": "Contact with other and unspecified machinery at unspecified place"
    },
    {
      "name": "W3190",
      "description": "Contact with other and unspecified machinery at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3191",
      "description": "Contact with other and unspecified machinery at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3192",
      "description": "Contact with other and unspecified machinery at unspecified place While working for income"
    },
    {
      "name": "W3193",
      "description": "Contact with other and unspecified machinery at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3194",
      "description": "Contact with other and unspecified machinery at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3198",
      "description": "Contact with other and unspecified machinery at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3199",
      "description": "Contact with other and unspecified machinery at unspecified place During unspecified activity"
    },
    {
      "name": "W320",
      "description": "Handgus discharge at home"
    },
    {
      "name": "W3200",
      "description": "Handgus discharge at home While engaged in sports activity"
    },
    {
      "name": "W3201",
      "description": "Handgus discharge at home While engaged in leisure activity"
    },
    {
      "name": "W3202",
      "description": "Handgus discharge at home While working for income"
    },
    {
      "name": "W3203",
      "description": "Handgus discharge at home While engaged in other types of work"
    },
    {
      "name": "W3204",
      "description": "Handgus discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3208",
      "description": "Handgus discharge at home While engaged in other specified activities"
    },
    {
      "name": "W3209",
      "description": "Handgus discharge at home During unspecified activity"
    },
    {
      "name": "W321",
      "description": "Handgus discharge at residential institution"
    },
    {
      "name": "W3210",
      "description": "Handgus discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "W3211",
      "description": "Handgus discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3212",
      "description": "Handgus discharge at residential institution While working for income"
    },
    {
      "name": "W3213",
      "description": "Handgus discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "W3214",
      "description": "Handgus discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3218",
      "description": "Handgus discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3219",
      "description": "Handgus discharge at residential institution During unspecified activity"
    },
    {
      "name": "W322",
      "description": "Handgus discharge at school,other institution and public administrative area"
    },
    {
      "name": "W3220",
      "description": "Handgus discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3221",
      "description": "Handgus discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3222",
      "description": "Handgus discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3223",
      "description": "Handgus discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3224",
      "description": "Handgus discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3228",
      "description": "Handgus discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3229",
      "description": "Handgus discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W323",
      "description": "Handgus discharge at sports and athletics area"
    },
    {
      "name": "W3230",
      "description": "Handgus discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3231",
      "description": "Handgus discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3232",
      "description": "Handgus discharge at sports and athletics area While working for income"
    },
    {
      "name": "W3233",
      "description": "Handgus discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3234",
      "description": "Handgus discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3238",
      "description": "Handgus discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3239",
      "description": "Handgus discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "W324",
      "description": "Handgus discharge at street and highway"
    },
    {
      "name": "W3240",
      "description": "Handgus discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "W3241",
      "description": "Handgus discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3242",
      "description": "Handgus discharge at street and highway While working for income"
    },
    {
      "name": "W3243",
      "description": "Handgus discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "W3244",
      "description": "Handgus discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3248",
      "description": "Handgus discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3249",
      "description": "Handgus discharge at street and highway During unspecified activity"
    },
    {
      "name": "W325",
      "description": "Handgus discharge at trade and service area"
    },
    {
      "name": "W3250",
      "description": "Handgus discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3251",
      "description": "Handgus discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3252",
      "description": "Handgus discharge at trade and service area While working for income"
    },
    {
      "name": "W3253",
      "description": "Handgus discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3254",
      "description": "Handgus discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3258",
      "description": "Handgus discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3259",
      "description": "Handgus discharge at trade and service area During unspecified activity"
    },
    {
      "name": "W326",
      "description": "Handgus discharge at industrial and construction area"
    },
    {
      "name": "W3260",
      "description": "Handgus discharge at industrial and construction area  While engaged in sports activity"
    },
    {
      "name": "W3261",
      "description": "Handgus discharge at industrial and construction area  While engaged in leisure activity"
    },
    {
      "name": "W3262",
      "description": "Handgus discharge at industrial and construction area While working for income"
    },
    {
      "name": "W3263",
      "description": "Handgus discharge at industrial and construction area  While engaged in other types of work"
    },
    {
      "name": "W3264",
      "description": "Handgus discharge at industrial and construction area  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3268",
      "description": "Handgus discharge at industrial and construction area  While engaged in other specified activities"
    },
    {
      "name": "W3269",
      "description": "Handgus discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "W327",
      "description": "Handgus discharge at farm"
    },
    {
      "name": "W3270",
      "description": "Handgus discharge at farm While engaged in sports activity"
    },
    {
      "name": "W3271",
      "description": "Handgus discharge at farm While engaged in leisure activity"
    },
    {
      "name": "W3272",
      "description": "Handgus discharge at farm While working for income"
    },
    {
      "name": "W3273",
      "description": "Handgus discharge at farm While engaged in other types of work"
    },
    {
      "name": "W3274",
      "description": "Handgus discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3278",
      "description": "Handgus discharge at farm While engaged in other specified activities"
    },
    {
      "name": "W3279",
      "description": "Handgus discharge at farm During unspecified activity"
    },
    {
      "name": "W328",
      "description": "Handgus discharge at other specified places"
    },
    {
      "name": "W3280",
      "description": "Handgus discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "W3281",
      "description": "Handgus discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3282",
      "description": "Handgus discharge at other specified places While working for income"
    },
    {
      "name": "W3283",
      "description": "Handgus discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "W3284",
      "description": "Handgus discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3288",
      "description": "Handgus discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3289",
      "description": "Handgus discharge at other specified places During unspecified activity"
    },
    {
      "name": "W329",
      "description": "Handgus discharge at unspecified place"
    },
    {
      "name": "W3290",
      "description": "Handgus discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3291",
      "description": "Handgus discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3292",
      "description": "Handgus discharge at unspecified place While working for income"
    },
    {
      "name": "W3293",
      "description": "Handgus discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3294",
      "description": "Handgus discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3298",
      "description": "Handgus discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3299",
      "description": "Handgus discharge at unspecified place During unspecified activity"
    },
    {
      "name": "W330",
      "description": "Rifle, shotgun and larger firearm discharge at home"
    },
    {
      "name": "W3300",
      "description": "Rifle, shotgun and larger firearm discharge at home While engaged in sports activity"
    },
    {
      "name": "W3301",
      "description": "Rifle, shotgun and larger firearm discharge at home While engaged in leisure activity"
    },
    {
      "name": "W3302",
      "description": "Rifle, shotgun and larger firearm discharge at home While working for income"
    },
    {
      "name": "W3303",
      "description": "Rifle, shotgun and larger firearm discharge at home While engaged in other types of work"
    },
    {
      "name": "W3304",
      "description": "Rifle, shotgun and larger firearm discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3308",
      "description": "Rifle, shotgun and larger firearm discharge at home While engaged in other specified activities"
    },
    {
      "name": "W3309",
      "description": "Rifle, shotgun and larger firearm discharge at home During unspecified activity"
    },
    {
      "name": "W331",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution"
    },
    {
      "name": "W3310",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "W3311",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3312",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While working for income"
    },
    {
      "name": "W3313",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "W3314",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3318",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3319",
      "description": "Rifle, shotgun and larger firearm discharge at residential institution During unspecified activity"
    },
    {
      "name": "W332",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area"
    },
    {
      "name": "W3320",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3321",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3322",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3323",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3324",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3328",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3329",
      "description": "Rifle, shotgun and larger firearm discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W333",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area"
    },
    {
      "name": "W3330",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3331",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3332",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While working for income"
    },
    {
      "name": "W3333",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3334",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3338",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3339",
      "description": "Rifle, shotgun and larger firearm discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "W334",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway"
    },
    {
      "name": "W3340",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "W3341",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3342",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While working for income"
    },
    {
      "name": "W3343",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "W3344",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3348",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3349",
      "description": "Rifle, shotgun and larger firearm discharge at street and highway During unspecified activity"
    },
    {
      "name": "W335",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area"
    },
    {
      "name": "W3350",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3351",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3352",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While working for income"
    },
    {
      "name": "W3353",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3354",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3358",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3359",
      "description": "Rifle, shotgun and larger firearm discharge at trade and service area During unspecified activity"
    },
    {
      "name": "W336",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area"
    },
    {
      "name": "W3360",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3361",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3362",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While working for income"
    },
    {
      "name": "W3363",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3364",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3368",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3369",
      "description": "Rifle, shotgun and larger firearm discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "W337",
      "description": "Rifle, shotgun and larger firearm discharge at farm"
    },
    {
      "name": "W3370",
      "description": "Rifle, shotgun and larger firearm discharge at farm While engaged in sports activity"
    },
    {
      "name": "W3371",
      "description": "Rifle, shotgun and larger firearm discharge at farm While engaged in leisure activity"
    },
    {
      "name": "W3372",
      "description": "Rifle, shotgun and larger firearm discharge at farm While working for income"
    },
    {
      "name": "W3373",
      "description": "Rifle, shotgun and larger firearm discharge at farm While engaged in other types of work"
    },
    {
      "name": "W3374",
      "description": "Rifle, shotgun and larger firearm discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3378",
      "description": "Rifle, shotgun and larger firearm discharge at farm While engaged in other specified activities"
    },
    {
      "name": "W3379",
      "description": "Rifle, shotgun and larger firearm discharge at farm During unspecified activity"
    },
    {
      "name": "W338",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places"
    },
    {
      "name": "W3380",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "W3381",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3382",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While working for income"
    },
    {
      "name": "W3383",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "W3384",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3388",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3389",
      "description": "Rifle, shotgun and larger firearm discharge at other specified places During unspecified activity"
    },
    {
      "name": "W339",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place"
    },
    {
      "name": "W3390",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3391",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3392",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While working for income"
    },
    {
      "name": "W3393",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3394",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3398",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3399",
      "description": "Rifle, shotgun and larger firearm discharge at unspecified place During unspecified activity"
    },
    {
      "name": "W340",
      "description": "Discharge from other and unspecified firearms at home"
    },
    {
      "name": "W3400",
      "description": "Discharge from other and unspecified firearms at home While engaged in sports activity"
    },
    {
      "name": "W3401",
      "description": "Discharge from other and unspecified firearms at home While engaged in leisure activity"
    },
    {
      "name": "W3402",
      "description": "Discharge from other and unspecified firearms at home While working for income"
    },
    {
      "name": "W3403",
      "description": "Discharge from other and unspecified firearms at home While engaged in other types of work"
    },
    {
      "name": "W3404",
      "description": "Discharge from other and unspecified firearms at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3408",
      "description": "Discharge from other and unspecified firearms at home While engaged in other specified activities"
    },
    {
      "name": "W3409",
      "description": "Discharge from other and unspecified firearms at home During unspecified activity"
    },
    {
      "name": "W341",
      "description": "Discharge from other and unspecified firearms at residential institution"
    },
    {
      "name": "W3410",
      "description": "Discharge from other and unspecified firearms at residential institution While engaged in sports activity"
    },
    {
      "name": "W3411",
      "description": "Discharge from other and unspecified firearms at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3412",
      "description": "Discharge from other and unspecified firearms at residential institution While working for income"
    },
    {
      "name": "W3413",
      "description": "Discharge from other and unspecified firearms at residential institution While engaged in other types of work"
    },
    {
      "name": "W3414",
      "description": "Discharge from other and unspecified firearms at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3418",
      "description": "Discharge from other and unspecified firearms at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3419",
      "description": "Discharge from other and unspecified firearms at residential institution During unspecified activity"
    },
    {
      "name": "W342",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area"
    },
    {
      "name": "W3420",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3421",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3422",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3423",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3424",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3428",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3429",
      "description": "Discharge from other and unspecified firearms at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W343",
      "description": "Discharge from other and unspecified firearms at sports and athletics area"
    },
    {
      "name": "W3430",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3431",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3432",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While working for income"
    },
    {
      "name": "W3433",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3434",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3438",
      "description": "Discharge from other and unspecified firearms at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3439",
      "description": "Discharge from other and unspecified firearms at sports and athletics area During unspecified activity"
    },
    {
      "name": "W344",
      "description": "Discharge from other and unspecified firearms at street and highway"
    },
    {
      "name": "W3440",
      "description": "Discharge from other and unspecified firearms at street and highway While engaged in sports activity"
    },
    {
      "name": "W3441",
      "description": "Discharge from other and unspecified firearms at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3442",
      "description": "Discharge from other and unspecified firearms at street and highway While working for income"
    },
    {
      "name": "W3443",
      "description": "Discharge from other and unspecified firearms at street and highway While engaged in other types of work"
    },
    {
      "name": "W3444",
      "description": "Discharge from other and unspecified firearms at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3448",
      "description": "Discharge from other and unspecified firearms at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3449",
      "description": "Discharge from other and unspecified firearms at street and highway During unspecified activity"
    },
    {
      "name": "W345",
      "description": "Discharge from other and unspecified firearms at trade and service area"
    },
    {
      "name": "W3450",
      "description": "Discharge from other and unspecified firearms at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3451",
      "description": "Discharge from other and unspecified firearms at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3452",
      "description": "Discharge from other and unspecified firearms at trade and service area While working for income"
    },
    {
      "name": "W3453",
      "description": "Discharge from other and unspecified firearms at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3454",
      "description": "Discharge from other and unspecified firearms at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3458",
      "description": "Discharge from other and unspecified firearms at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3459",
      "description": "Discharge from other and unspecified firearms at trade and service area During unspecified activity"
    },
    {
      "name": "W346",
      "description": "Discharge from other and unspecified firearms at industrial and construction area"
    },
    {
      "name": "W3460",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3461",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3462",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While working for income"
    },
    {
      "name": "W3463",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3464",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3468",
      "description": "Discharge from other and unspecified firearms at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3469",
      "description": "Discharge from other and unspecified firearms at industrial and construction area During unspecified activity"
    },
    {
      "name": "W347",
      "description": "Discharge from other and unspecified firearms at farm"
    },
    {
      "name": "W3470",
      "description": "Discharge from other and unspecified firearms at farm While engaged in sports activity"
    },
    {
      "name": "W3471",
      "description": "Discharge from other and unspecified firearms at farm While engaged in leisure activity"
    },
    {
      "name": "W3472",
      "description": "Discharge from other and unspecified firearms at farm While working for income"
    },
    {
      "name": "W3473",
      "description": "Discharge from other and unspecified firearms at farm While engaged in other types of work"
    },
    {
      "name": "W3474",
      "description": "Discharge from other and unspecified firearms at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3478",
      "description": "Discharge from other and unspecified firearms at farm While engaged in other specified activities"
    },
    {
      "name": "W3479",
      "description": "Discharge from other and unspecified firearms at farm During unspecified activity"
    },
    {
      "name": "W348",
      "description": "Discharge from other and unspecified firearms at other specified places"
    },
    {
      "name": "W3480",
      "description": "Discharge from other and unspecified firearms at other specified places While engaged in sports activity"
    },
    {
      "name": "W3481",
      "description": "Discharge from other and unspecified firearms at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3482",
      "description": "Discharge from other and unspecified firearms at other specified places While working for income"
    },
    {
      "name": "W3483",
      "description": "Discharge from other and unspecified firearms at other specified places While engaged in other types of work"
    },
    {
      "name": "W3484",
      "description": "Discharge from other and unspecified firearms at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3488",
      "description": "Discharge from other and unspecified firearms at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3489",
      "description": "Discharge from other and unspecified firearms at other specified places During unspecified activity"
    },
    {
      "name": "W349",
      "description": "Discharge from other and unspecified firearms at unspecified place"
    },
    {
      "name": "W3490",
      "description": "Discharge from other and unspecified firearms at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3491",
      "description": "Discharge from other and unspecified firearms at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3492",
      "description": "Discharge from other and unspecified firearms at unspecified place While working for income"
    },
    {
      "name": "W3493",
      "description": "Discharge from other and unspecified firearms at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3494",
      "description": "Discharge from other and unspecified firearms at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3498",
      "description": "Discharge from other and unspecified firearms at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3499",
      "description": "Discharge from other and unspecified firearms at unspecified place During unspecified activity"
    },
    {
      "name": "W350",
      "description": "Explosion and rupture of boiler at home"
    },
    {
      "name": "W3500",
      "description": "Explosion and rupture of boiler at home While engaged in sports activity"
    },
    {
      "name": "W3501",
      "description": "Explosion and rupture of boiler at home While engaged in leisure activity"
    },
    {
      "name": "W3502",
      "description": "Explosion and rupture of boiler at home While working for income"
    },
    {
      "name": "W3503",
      "description": "Explosion and rupture of boiler at home While engaged in other types of work"
    },
    {
      "name": "W3504",
      "description": "Explosion and rupture of boiler at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3508",
      "description": "Explosion and rupture of boiler at home While engaged in other specified activities"
    },
    {
      "name": "W3509",
      "description": "Explosion and rupture of boiler at home During unspecified activity"
    },
    {
      "name": "W351",
      "description": "Explosion and rupture of boiler at residential institution"
    },
    {
      "name": "W3510",
      "description": "Explosion and rupture of boiler at residential institution While engaged in sports activity"
    },
    {
      "name": "W3511",
      "description": "Explosion and rupture of boiler at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3512",
      "description": "Explosion and rupture of boiler at residential institution While working for income"
    },
    {
      "name": "W3513",
      "description": "Explosion and rupture of boiler at residential institution While engaged in other types of work"
    },
    {
      "name": "W3514",
      "description": "Explosion and rupture of boiler at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3518",
      "description": "Explosion and rupture of boiler at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3519",
      "description": "Explosion and rupture of boiler at residential institution During unspecified activity"
    },
    {
      "name": "W352",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area"
    },
    {
      "name": "W3520",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3521",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3522",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3523",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3524",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3528",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3529",
      "description": "Explosion and rupture of boiler at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W353",
      "description": "Explosion and rupture of boiler at sports and athletics area"
    },
    {
      "name": "W3530",
      "description": "Explosion and rupture of boiler at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3531",
      "description": "Explosion and rupture of boiler at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3532",
      "description": "Explosion and rupture of boiler at sports and athletics area While working for income"
    },
    {
      "name": "W3533",
      "description": "Explosion and rupture of boiler at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3534",
      "description": "Explosion and rupture of boiler at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3538",
      "description": "Explosion and rupture of boiler at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3539",
      "description": "Explosion and rupture of boiler at sports and athletics area During unspecified activity"
    },
    {
      "name": "W354",
      "description": "Explosion and rupture of boiler at street and highway"
    },
    {
      "name": "W3540",
      "description": "Explosion and rupture of boiler at street and highway While engaged in sports activity"
    },
    {
      "name": "W3541",
      "description": "Explosion and rupture of boiler at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3542",
      "description": "Explosion and rupture of boiler at street and highway While working for income"
    },
    {
      "name": "W3543",
      "description": "Explosion and rupture of boiler at street and highway While engaged in other types of work"
    },
    {
      "name": "W3544",
      "description": "Explosion and rupture of boiler at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3548",
      "description": "Explosion and rupture of boiler at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3549",
      "description": "Explosion and rupture of boiler at street and highway During unspecified activity"
    },
    {
      "name": "W355",
      "description": "Explosion and rupture of boiler at trade and service area"
    },
    {
      "name": "W3550",
      "description": "Explosion and rupture of boiler at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3551",
      "description": "Explosion and rupture of boiler at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3552",
      "description": "Explosion and rupture of boiler at trade and service area While working for income"
    },
    {
      "name": "W3553",
      "description": "Explosion and rupture of boiler at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3554",
      "description": "Explosion and rupture of boiler at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3558",
      "description": "Explosion and rupture of boiler at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3559",
      "description": "Explosion and rupture of boiler at trade and service area During unspecified activity"
    },
    {
      "name": "W356",
      "description": "Explosion and rupture of boiler at industrial and construction area"
    },
    {
      "name": "W3560",
      "description": "Explosion and rupture of boiler at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3561",
      "description": "Explosion and rupture of boiler at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3562",
      "description": "Explosion and rupture of boiler at industrial and construction area While working for income"
    },
    {
      "name": "W3563",
      "description": "Explosion and rupture of boiler at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3564",
      "description": "Explosion and rupture of boiler at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3568",
      "description": "Explosion and rupture of boiler at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3569",
      "description": "Explosion and rupture of boiler at industrial and construction area During unspecified activity"
    },
    {
      "name": "W357",
      "description": "Explosion and rupture of boiler at farm"
    },
    {
      "name": "W3570",
      "description": "Explosion and rupture of boiler at farm While engaged in sports activity"
    },
    {
      "name": "W3571",
      "description": "Explosion and rupture of boiler at farm While engaged in leisure activity"
    },
    {
      "name": "W3572",
      "description": "Explosion and rupture of boiler at farm While working for income"
    },
    {
      "name": "W3573",
      "description": "Explosion and rupture of boiler at farm While engaged in other types of work"
    },
    {
      "name": "W3574",
      "description": "Explosion and rupture of boiler at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3578",
      "description": "Explosion and rupture of boiler at farm While engaged in other specified activities"
    },
    {
      "name": "W3579",
      "description": "Explosion and rupture of boiler at farm During unspecified activity"
    },
    {
      "name": "W358",
      "description": "Explosion and rupture of boiler at other specified places"
    },
    {
      "name": "W3580",
      "description": "Explosion and rupture of boiler at other specified places While engaged in sports activity"
    },
    {
      "name": "W3581",
      "description": "Explosion and rupture of boiler at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3582",
      "description": "Explosion and rupture of boiler at other specified places While working for income"
    },
    {
      "name": "W3583",
      "description": "Explosion and rupture of boiler at other specified places While engaged in other types of work"
    },
    {
      "name": "W3584",
      "description": "Explosion and rupture of boiler at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3588",
      "description": "Explosion and rupture of boiler at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3589",
      "description": "Explosion and rupture of boiler at other specified places During unspecified activity"
    },
    {
      "name": "W359",
      "description": "Explosion and rupture of boiler at unspecified place"
    },
    {
      "name": "W3590",
      "description": "Explosion and rupture of boiler at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3591",
      "description": "Explosion and rupture of boiler at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3592",
      "description": "Explosion and rupture of boiler at unspecified place While working for income"
    },
    {
      "name": "W3593",
      "description": "Explosion and rupture of boiler at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3594",
      "description": "Explosion and rupture of boiler at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3598",
      "description": "Explosion and rupture of boiler at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3599",
      "description": "Explosion and rupture of boiler at unspecified place During unspecified activity"
    },
    {
      "name": "W360",
      "description": "Explosion and rupture of gas cylinder at home"
    },
    {
      "name": "W3600",
      "description": "Explosion and rupture of gas cylinder at home While engaged in sports activity"
    },
    {
      "name": "W3601",
      "description": "Explosion and rupture of gas cylinder at home While engaged in leisure activity"
    },
    {
      "name": "W3602",
      "description": "Explosion and rupture of gas cylinder at home While working for income"
    },
    {
      "name": "W3603",
      "description": "Explosion and rupture of gas cylinder at home While engaged in other types of work"
    },
    {
      "name": "W3604",
      "description": "Explosion and rupture of gas cylinder at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3608",
      "description": "Explosion and rupture of gas cylinder at home While engaged in other specified activities"
    },
    {
      "name": "W3609",
      "description": "Explosion and rupture of gas cylinder at home During unspecified activity"
    },
    {
      "name": "W361",
      "description": "Explosion and rupture of gas cylinder at residential institution"
    },
    {
      "name": "W3610",
      "description": "Explosion and rupture of gas cylinder at residential institution While engaged in sports activity"
    },
    {
      "name": "W3611",
      "description": "Explosion and rupture of gas cylinder at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3612",
      "description": "Explosion and rupture of gas cylinder at residential institution While working for income"
    },
    {
      "name": "W3613",
      "description": "Explosion and rupture of gas cylinder at residential institution While engaged in other types of work"
    },
    {
      "name": "W3614",
      "description": "Explosion and rupture of gas cylinder at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3618",
      "description": "Explosion and rupture of gas cylinder at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3619",
      "description": "Explosion and rupture of gas cylinder at residential institution During unspecified activity"
    },
    {
      "name": "W362",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area"
    },
    {
      "name": "W3620",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3621",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3622",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3623",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While engaged in other types of work "
    },
    {
      "name": "W3624",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3628",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3629",
      "description": "Explosion and rupture of gas cylinder at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W363",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area"
    },
    {
      "name": "W3630",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3631",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3632",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While working for income"
    },
    {
      "name": "W3633",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3634",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3638",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3639",
      "description": "Explosion and rupture of gas cylinder at sports and athletics area During unspecified activity"
    },
    {
      "name": "W364",
      "description": "Explosion and rupture of gas cylinder at street and highway"
    },
    {
      "name": "W3640",
      "description": "Explosion and rupture of gas cylinder at street and highway While engaged in sports activity"
    },
    {
      "name": "W3641",
      "description": "Explosion and rupture of gas cylinder at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3642",
      "description": "Explosion and rupture of gas cylinder at street and highway While working for income"
    },
    {
      "name": "W3643",
      "description": "Explosion and rupture of gas cylinder at street and highway While engaged in other types of work"
    },
    {
      "name": "W3644",
      "description": "Explosion and rupture of gas cylinder at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3648",
      "description": "Explosion and rupture of gas cylinder at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3649",
      "description": "Explosion and rupture of gas cylinder at street and highway During unspecified activity"
    },
    {
      "name": "W365",
      "description": "Explosion and rupture of gas cylinder at trade and service area"
    },
    {
      "name": "W3650",
      "description": "Explosion and rupture of gas cylinder at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3651",
      "description": "Explosion and rupture of gas cylinder at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3652",
      "description": "Explosion and rupture of gas cylinder at trade and service area While working for income"
    },
    {
      "name": "W3653",
      "description": "Explosion and rupture of gas cylinder at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3654",
      "description": "Explosion and rupture of gas cylinder at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3658",
      "description": "Explosion and rupture of gas cylinder at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3659",
      "description": "Explosion and rupture of gas cylinder at trade and service area During unspecified activity"
    },
    {
      "name": "W366",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area"
    },
    {
      "name": "W3660",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3661",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3662",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While working for income"
    },
    {
      "name": "W3663",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3664",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3668",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3669",
      "description": "Explosion and rupture of gas cylinder at industrial and construction area During unspecified activity"
    },
    {
      "name": "W367",
      "description": "Explosion and rupture of gas cylinder at farm"
    },
    {
      "name": "W3670",
      "description": "Explosion and rupture of gas cylinder at farm While engaged in sports activity"
    },
    {
      "name": "W3671",
      "description": "Explosion and rupture of gas cylinder at farm While engaged in leisure activity"
    },
    {
      "name": "W3672",
      "description": "Explosion and rupture of gas cylinder at farm While working for income"
    },
    {
      "name": "W3673",
      "description": "Explosion and rupture of gas cylinder at farm While engaged in other types of work"
    },
    {
      "name": "W3674",
      "description": "Explosion and rupture of gas cylinder at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3678",
      "description": "Explosion and rupture of gas cylinder at farm While engaged in other specified activities"
    },
    {
      "name": "W3679",
      "description": "Explosion and rupture of gas cylinder at farm During unspecified activity"
    },
    {
      "name": "W368",
      "description": "Explosion and rupture of gas cylinder at other specified places"
    },
    {
      "name": "W3680",
      "description": "Explosion and rupture of gas cylinder at other specified places While engaged in sports activity"
    },
    {
      "name": "W3681",
      "description": "Explosion and rupture of gas cylinder at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3682",
      "description": "Explosion and rupture of gas cylinder at other specified places While working for income"
    },
    {
      "name": "W3683",
      "description": "Explosion and rupture of gas cylinder at other specified places While engaged in other types of work"
    },
    {
      "name": "W3684",
      "description": "Explosion and rupture of gas cylinder at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3688",
      "description": "Explosion and rupture of gas cylinder at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3689",
      "description": "Explosion and rupture of gas cylinder at other specified places During unspecified activity"
    },
    {
      "name": "W369",
      "description": "Explosion and rupture of gas cylinder at unspecified place"
    },
    {
      "name": "W3690",
      "description": "Explosion and rupture of gas cylinder at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3691",
      "description": "Explosion and rupture of gas cylinder at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3692",
      "description": "Explosion and rupture of gas cylinder at unspecified place While working for income"
    },
    {
      "name": "W3693",
      "description": "Explosion and rupture of gas cylinder at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3694",
      "description": "Explosion and rupture of gas cylinder at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3698",
      "description": "Explosion and rupture of gas cylinder at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3699",
      "description": "Explosion and rupture of gas cylinder at unspecified place During unspecified activity"
    },
    {
      "name": "W370",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home"
    },
    {
      "name": "W3700",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While engaged in sports activity"
    },
    {
      "name": "W3701",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While engaged in leisure activity"
    },
    {
      "name": "W3702",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While working for income"
    },
    {
      "name": "W3703",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While engaged in other types of work"
    },
    {
      "name": "W3704",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3708",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home While engaged in other specified activities"
    },
    {
      "name": "W3709",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at home During unspecified activity"
    },
    {
      "name": "W371",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution"
    },
    {
      "name": "W3710",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While engaged in sports activity"
    },
    {
      "name": "W3711",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3712",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While working for income"
    },
    {
      "name": "W3713",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While engaged in other types of work"
    },
    {
      "name": "W3714",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3718",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3719",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at residential institution During unspecified activity"
    },
    {
      "name": "W372",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area"
    },
    {
      "name": "W3720",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3721",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3722",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3723",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3724",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3728",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3729",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W373",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area"
    },
    {
      "name": "W3730",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3731",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3732",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While working for income"
    },
    {
      "name": "W3733",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3734",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3738",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3739",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at sports and athletics area During unspecified activity"
    },
    {
      "name": "W374",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway"
    },
    {
      "name": "W3740",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While engaged in sports activity"
    },
    {
      "name": "W3741",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3742",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While working for income"
    },
    {
      "name": "W3743",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While engaged in other types of work"
    },
    {
      "name": "W3744",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3748",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3749",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at street and highway During unspecified activity"
    },
    {
      "name": "W375",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area"
    },
    {
      "name": "W3750",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3751",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3752",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While working for income"
    },
    {
      "name": "W3753",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3754",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3758",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3759",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at trade and service area During unspecified activity"
    },
    {
      "name": "W376",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area"
    },
    {
      "name": "W3760",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3761",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3762",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While working for income"
    },
    {
      "name": "W3763",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3764",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3768",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3769",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at industrial and construction area During unspecified activity"
    },
    {
      "name": "W377",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm"
    },
    {
      "name": "W3770",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While engaged in sports activity"
    },
    {
      "name": "W3771",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While engaged in leisure activity"
    },
    {
      "name": "W3772",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While working for income"
    },
    {
      "name": "W3773",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While engaged in other types of work"
    },
    {
      "name": "W3774",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3778",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm While engaged in other specified activities"
    },
    {
      "name": "W3779",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at farm During unspecified activity"
    },
    {
      "name": "W378",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places"
    },
    {
      "name": "W3780",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While engaged in sports activity"
    },
    {
      "name": "W3781",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3782",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While working for income"
    },
    {
      "name": "W3783",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While engaged in other types of work"
    },
    {
      "name": "W3784",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3788",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3789",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at other specified places During unspecified activity"
    },
    {
      "name": "W379",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place"
    },
    {
      "name": "W3790",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3791",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3792",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While working for income"
    },
    {
      "name": "W3793",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3794",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3798",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3799",
      "description": "Explosion and rupture of pressurized tyre, pipe or hose at unspecified place During unspecified activity"
    },
    {
      "name": "W380",
      "description": "Explosion and rupture of other specified pressurized devices at home"
    },
    {
      "name": "W3800",
      "description": "Explosion and rupture of other specified pressurized devices at home While engaged in sports activity"
    },
    {
      "name": "W3801",
      "description": "Explosion and rupture of other specified pressurized devices at home While engaged in leisure activity"
    },
    {
      "name": "W3802",
      "description": "Explosion and rupture of other specified pressurized devices at home While working for income"
    },
    {
      "name": "W3803",
      "description": "Explosion and rupture of other specified pressurized devices at home While engaged in other types of work"
    },
    {
      "name": "W3804",
      "description": "Explosion and rupture of other specified pressurized devices at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3808",
      "description": "Explosion and rupture of other specified pressurized devices at home While engaged in other specified activities"
    },
    {
      "name": "W3809",
      "description": "Explosion and rupture of other specified pressurized devices at home During unspecified activity"
    },
    {
      "name": "W381",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution"
    },
    {
      "name": "W3810",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While engaged in sports activity"
    },
    {
      "name": "W3811",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3812",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While working for income"
    },
    {
      "name": "W3813",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While engaged in other types of work"
    },
    {
      "name": "W3814",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3818",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3819",
      "description": "Explosion and rupture of other specified pressurized devices at residential institution During unspecified activity"
    },
    {
      "name": "W382",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area"
    },
    {
      "name": "W3820",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3821",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3822",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3823",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3824",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3828",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3829",
      "description": "Explosion and rupture of other specified pressurized devices at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W383",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area"
    },
    {
      "name": "W3830",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3831",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3832",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While working for income"
    },
    {
      "name": "W3833",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3834",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3838",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3839",
      "description": "Explosion and rupture of other specified pressurized devices at sports and athletics area During unspecified activity"
    },
    {
      "name": "W384",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway"
    },
    {
      "name": "W3840",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While engaged in sports activity"
    },
    {
      "name": "W3841",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3842",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While working for income"
    },
    {
      "name": "W3843",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While engaged in other types of work"
    },
    {
      "name": "W3844",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3848",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3849",
      "description": "Explosion and rupture of other specified pressurized devices at street and highway During unspecified activity"
    },
    {
      "name": "W385",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area"
    },
    {
      "name": "W3850",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3851",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3852",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While working for income"
    },
    {
      "name": "W3853",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3854",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3858",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3859",
      "description": "Explosion and rupture of other specified pressurized devices at trade and service area During unspecified activity"
    },
    {
      "name": "W386",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area"
    },
    {
      "name": "W3860",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3861",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3862",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While working for income"
    },
    {
      "name": "W3863",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3864",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3868",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3869",
      "description": "Explosion and rupture of other specified pressurized devices at industrial and construction area During unspecified activity"
    },
    {
      "name": "W387",
      "description": "Explosion and rupture of other specified pressurized devices at farm"
    },
    {
      "name": "W3870",
      "description": "Explosion and rupture of other specified pressurized devices at farm While engaged in sports activity"
    },
    {
      "name": "W3871",
      "description": "Explosion and rupture of other specified pressurized devices at farm While engaged in leisure activity"
    },
    {
      "name": "W3872",
      "description": "Explosion and rupture of other specified pressurized devices at farm While working for income"
    },
    {
      "name": "W3873",
      "description": "Explosion and rupture of other specified pressurized devices at farm While engaged in other types of work"
    },
    {
      "name": "W3874",
      "description": "Explosion and rupture of other specified pressurized devices at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3878",
      "description": "Explosion and rupture of other specified pressurized devices at farm While engaged in other specified activities"
    },
    {
      "name": "W3879",
      "description": "Explosion and rupture of other specified pressurized devices at farm During unspecified activity"
    },
    {
      "name": "W388",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places"
    },
    {
      "name": "W3880",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While engaged in sports activity"
    },
    {
      "name": "W3881",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3882",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While working for income"
    },
    {
      "name": "W3883",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While engaged in other types of work"
    },
    {
      "name": "W3884",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3888",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3889",
      "description": "Explosion and rupture of other specified pressurized devices at other specified places During unspecified activity"
    },
    {
      "name": "W389",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place"
    },
    {
      "name": "W3890",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3891",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3892",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While working for income"
    },
    {
      "name": "W3893",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3894",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3898",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3899",
      "description": "Explosion and rupture of other specified pressurized devices at unspecified place During unspecified activity"
    },
    {
      "name": "W390",
      "description": "Discharge of  firework at home"
    },
    {
      "name": "W3900",
      "description": "Discharge of  firework at home While engaged in sports activity"
    },
    {
      "name": "W3901",
      "description": "Discharge of  firework at home While engaged in leisure activity"
    },
    {
      "name": "W3902",
      "description": "Discharge of  firework at home While working for income"
    },
    {
      "name": "W3903",
      "description": "Discharge of  firework at home While engaged in other types of work"
    },
    {
      "name": "W3904",
      "description": "Discharge of  firework at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3908",
      "description": "Discharge of  firework at home While engaged in other specified activities"
    },
    {
      "name": "W3909",
      "description": "Discharge of  firework at home During unspecified activity"
    },
    {
      "name": "W391",
      "description": "Discharge of  firework at residential institution"
    },
    {
      "name": "W3910",
      "description": "Discharge of  firework at residential institution While engaged in sports activity"
    },
    {
      "name": "W3911",
      "description": "Discharge of  firework at residential institution While engaged in leisure activity"
    },
    {
      "name": "W3912",
      "description": "Discharge of  firework at residential institution While working for income"
    },
    {
      "name": "W3913",
      "description": "Discharge of  firework at residential institution While engaged in other types of work"
    },
    {
      "name": "W3914",
      "description": "Discharge of  firework at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3918",
      "description": "Discharge of  firework at residential institution While engaged in other specified activities"
    },
    {
      "name": "W3919",
      "description": "Discharge of  firework at residential institution During unspecified activity"
    },
    {
      "name": "W392",
      "description": "Discharge of  firework at school,other institution and public administrative area"
    },
    {
      "name": "W3920",
      "description": "Discharge of  firework at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W3921",
      "description": "Discharge of  firework at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W3922",
      "description": "Discharge of  firework at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W3923",
      "description": "Discharge of  firework at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W3924",
      "description": "Discharge of  firework at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3928",
      "description": "Discharge of  firework at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W3929",
      "description": "Discharge of  firework at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W393",
      "description": "Discharge of  firework at sports and athletics area"
    },
    {
      "name": "W3930",
      "description": "Discharge of  firework at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W3931",
      "description": "Discharge of  firework at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W3932",
      "description": "Discharge of  firework at sports and athletics area While working for income"
    },
    {
      "name": "W3933",
      "description": "Discharge of  firework at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W3934",
      "description": "Discharge of  firework at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3938",
      "description": "Discharge of  firework at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W3939",
      "description": "Discharge of  firework at sports and athletics area During unspecified activity"
    },
    {
      "name": "W394",
      "description": "Discharge of  firework at street and highway"
    },
    {
      "name": "W3940",
      "description": "Discharge of  firework at street and highway While engaged in sports activity"
    },
    {
      "name": "W3941",
      "description": "Discharge of  firework at street and highway While engaged in leisure activity"
    },
    {
      "name": "W3942",
      "description": "Discharge of  firework at street and highway While working for income"
    },
    {
      "name": "W3943",
      "description": "Discharge of  firework at street and highway While engaged in other types of work"
    },
    {
      "name": "W3944",
      "description": "Discharge of  firework at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3948",
      "description": "Discharge of  firework at street and highway While engaged in other specified activities"
    },
    {
      "name": "W3949",
      "description": "Discharge of  firework at street and highway During unspecified activity"
    },
    {
      "name": "W395",
      "description": "Discharge of  firework at trade and service area"
    },
    {
      "name": "W3950",
      "description": "Discharge of  firework at trade and service area While engaged in sports activity"
    },
    {
      "name": "W3951",
      "description": "Discharge of  firework at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W3952",
      "description": "Discharge of  firework at trade and service area While working for income"
    },
    {
      "name": "W3953",
      "description": "Discharge of  firework at trade and service area While engaged in other types of work"
    },
    {
      "name": "W3954",
      "description": "Discharge of  firework at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3958",
      "description": "Discharge of  firework at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W3959",
      "description": "Discharge of  firework at trade and service area During unspecified activity"
    },
    {
      "name": "W396",
      "description": "Discharge of  firework at industrial and construction area"
    },
    {
      "name": "W3960",
      "description": "Discharge of  firework at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W3961",
      "description": "Discharge of  firework at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W3962",
      "description": "Discharge of  firework at industrial and construction area While working for income"
    },
    {
      "name": "W3963",
      "description": "Discharge of  firework at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W3964",
      "description": "Discharge of  firework at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3968",
      "description": "Discharge of  firework at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W3969",
      "description": "Discharge of  firework at industrial and construction area During unspecified activity"
    },
    {
      "name": "W397",
      "description": "Discharge of  firework at farm"
    },
    {
      "name": "W3970",
      "description": "Discharge of  firework at farm While engaged in sports activity"
    },
    {
      "name": "W3971",
      "description": "Discharge of  firework at farm While engaged in leisure activity"
    },
    {
      "name": "W3972",
      "description": "Discharge of  firework at farm While working for income"
    },
    {
      "name": "W3973",
      "description": "Discharge of  firework at farm While engaged in other types of work"
    },
    {
      "name": "W3974",
      "description": "Discharge of  firework at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3978",
      "description": "Discharge of  firework at farm While engaged in other specified activities"
    },
    {
      "name": "W3979",
      "description": "Discharge of  firework at farm During unspecified activity"
    },
    {
      "name": "W398",
      "description": "Discharge of  firework at other specified places"
    },
    {
      "name": "W3980",
      "description": "Discharge of  firework at other specified places While engaged in sports activity"
    },
    {
      "name": "W3981",
      "description": "Discharge of  firework at other specified places While engaged in leisure activity"
    },
    {
      "name": "W3982",
      "description": "Discharge of  firework at other specified places While working for income"
    },
    {
      "name": "W3983",
      "description": "Discharge of  firework at other specified places While engaged in other types of work"
    },
    {
      "name": "W3984",
      "description": "Discharge of  firework at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3988",
      "description": "Discharge of  firework at other specified places While engaged in other specified activities"
    },
    {
      "name": "W3989",
      "description": "Discharge of  firework at other specified places During unspecified activity"
    },
    {
      "name": "W399",
      "description": "Discharge of  firework at unspecified place"
    },
    {
      "name": "W3990",
      "description": "Discharge of  firework at unspecified place While engaged in sports activity"
    },
    {
      "name": "W3991",
      "description": "Discharge of  firework at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W3992",
      "description": "Discharge of  firework at unspecified place While working for income"
    },
    {
      "name": "W3993",
      "description": "Discharge of  firework at unspecified place While engaged in other types of work"
    },
    {
      "name": "W3994",
      "description": "Discharge of  firework at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W3998",
      "description": "Discharge of  firework at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W3999",
      "description": "Discharge of  firework at unspecified place During unspecified activity"
    },
    {
      "name": "W400",
      "description": "Explosion of other materials at home"
    },
    {
      "name": "W4000",
      "description": "Explosion of other materials at home While engaged in sports activity"
    },
    {
      "name": "W4001",
      "description": "Explosion of other materials at home While engaged in leisure activity"
    },
    {
      "name": "W4002",
      "description": "Explosion of other materials at home While working for income"
    },
    {
      "name": "W4003",
      "description": "Explosion of other materials at home While engaged in other types of work"
    },
    {
      "name": "W4004",
      "description": "Explosion of other materials at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4008",
      "description": "Explosion of other materials at home While engaged in other specified activities"
    },
    {
      "name": "W4009",
      "description": "Explosion of other materials at home During unspecified activity"
    },
    {
      "name": "W401",
      "description": "Explosion of other materials at residential institution"
    },
    {
      "name": "W4010",
      "description": "Explosion of other materials at residential institution While engaged in sports activity"
    },
    {
      "name": "W4011",
      "description": "Explosion of other materials at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4012",
      "description": "Explosion of other materials at residential institution While working for income"
    },
    {
      "name": "W4013",
      "description": "Explosion of other materials at residential institution While engaged in other types of work"
    },
    {
      "name": "W4014",
      "description": "Explosion of other materials at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4018",
      "description": "Explosion of other materials at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4019",
      "description": "Explosion of other materials at residential institution During unspecified activity"
    },
    {
      "name": "W402",
      "description": "Explosion of other materials at school,other institution and public administrative area"
    },
    {
      "name": "W4020",
      "description": "Explosion of other materials at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4021",
      "description": "Explosion of other materials at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4022",
      "description": "Explosion of other materials at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4023",
      "description": "Explosion of other materials at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4024",
      "description": "Explosion of other materials at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4028",
      "description": "Explosion of other materials at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4029",
      "description": "Explosion of other materials at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W403",
      "description": "Explosion of other materials at sports and athletics area"
    },
    {
      "name": "W4030",
      "description": "Explosion of other materials at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4031",
      "description": "Explosion of other materials at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4032",
      "description": "Explosion of other materials at sports and athletics area While working for income"
    },
    {
      "name": "W4033",
      "description": "Explosion of other materials at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4034",
      "description": "Explosion of other materials at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4038",
      "description": "Explosion of other materials at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4039",
      "description": "Explosion of other materials at sports and athletics area During unspecified activity"
    },
    {
      "name": "W404",
      "description": "Explosion of other materials at street and highway"
    },
    {
      "name": "W4040",
      "description": "Explosion of other materials at street and highway While engaged in sports activity"
    },
    {
      "name": "W4041",
      "description": "Explosion of other materials at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4042",
      "description": "Explosion of other materials at street and highway While working for income"
    },
    {
      "name": "W4043",
      "description": "Explosion of other materials at street and highway While engaged in other types of work"
    },
    {
      "name": "W4044",
      "description": "Explosion of other materials at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4048",
      "description": "Explosion of other materials at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4049",
      "description": "Explosion of other materials at street and highway During unspecified activity"
    },
    {
      "name": "W405",
      "description": "Explosion of other materials at trade and service area"
    },
    {
      "name": "W4050",
      "description": "Explosion of other materials at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4051",
      "description": "Explosion of other materials at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4052",
      "description": "Explosion of other materials at trade and service area While working for income"
    },
    {
      "name": "W4053",
      "description": "Explosion of other materials at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4054",
      "description": "Explosion of other materials at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4058",
      "description": "Explosion of other materials at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4059",
      "description": "Explosion of other materials at trade and service area During unspecified activity"
    },
    {
      "name": "W406",
      "description": "Explosion of other materials at industrial and construction area"
    },
    {
      "name": "W4060",
      "description": "Explosion of other materials at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4061",
      "description": "Explosion of other materials at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4062",
      "description": "Explosion of other materials at industrial and construction area While working for income"
    },
    {
      "name": "W4063",
      "description": "Explosion of other materials at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4064",
      "description": "Explosion of other materials at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4068",
      "description": "Explosion of other materials at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4069",
      "description": "Explosion of other materials at industrial and construction area During unspecified activity"
    },
    {
      "name": "W407",
      "description": "Explosion of other materials at farm"
    },
    {
      "name": "W4070",
      "description": "Explosion of other materials at farm While engaged in sports activity"
    },
    {
      "name": "W4071",
      "description": "Explosion of other materials at farm While engaged in leisure activity"
    },
    {
      "name": "W4072",
      "description": "Explosion of other materials at farm While working for income"
    },
    {
      "name": "W4073",
      "description": "Explosion of other materials at farm While engaged in other types of work"
    },
    {
      "name": "W4074",
      "description": "Explosion of other materials at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4078",
      "description": "Explosion of other materials at farm While engaged in other specified activities"
    },
    {
      "name": "W4079",
      "description": "Explosion of other materials at farm During unspecified activity"
    },
    {
      "name": "W408",
      "description": "Explosion of other materials at other specified places"
    },
    {
      "name": "W4080",
      "description": "Explosion of other materials at other specified places While engaged in sports activity"
    },
    {
      "name": "W4081",
      "description": "Explosion of other materials at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4082",
      "description": "Explosion of other materials at other specified places While working for income"
    },
    {
      "name": "W4083",
      "description": "Explosion of other materials at other specified places While engaged in other types of work"
    },
    {
      "name": "W4084",
      "description": "Explosion of other materials at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4088",
      "description": "Explosion of other materials at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4089",
      "description": "Explosion of other materials at other specified places During unspecified activity"
    },
    {
      "name": "W409",
      "description": "Explosion of other materials at unspecified place"
    },
    {
      "name": "W4090",
      "description": "Explosion of other materials at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4091",
      "description": "Explosion of other materials at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4092",
      "description": "Explosion of other materials at unspecified place While working for income"
    },
    {
      "name": "W4093",
      "description": "Explosion of other materials at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4094",
      "description": "Explosion of other materials at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4098",
      "description": "Explosion of other materials at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4099",
      "description": "Explosion of other materials at unspecified place During unspecified activity"
    },
    {
      "name": "W410",
      "description": "Exposure to high-pressure jet at home"
    },
    {
      "name": "W4100",
      "description": "Exposure to high-pressure jet at home While engaged in sports activity"
    },
    {
      "name": "W4101",
      "description": "Exposure to high-pressure jet at home While engaged in leisure activity"
    },
    {
      "name": "W4102",
      "description": "Exposure to high-pressure jet at home While working for income"
    },
    {
      "name": "W4103",
      "description": "Exposure to high-pressure jet at home While engaged in other types of work"
    },
    {
      "name": "W4104",
      "description": "Exposure to high-pressure jet at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4108",
      "description": "Exposure to high-pressure jet at home While engaged in other specified activities"
    },
    {
      "name": "W4109",
      "description": "Exposure to high-pressure jet at home During unspecified activity"
    },
    {
      "name": "W411",
      "description": "Exposure to high-pressure jet at residential institution"
    },
    {
      "name": "W4110",
      "description": "Exposure to high-pressure jet at residential institution While engaged in sports activity"
    },
    {
      "name": "W4111",
      "description": "Exposure to high-pressure jet at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4112",
      "description": "Exposure to high-pressure jet at residential institution While working for income"
    },
    {
      "name": "W4113",
      "description": "Exposure to high-pressure jet at residential institution While engaged in other types of work"
    },
    {
      "name": "W4114",
      "description": "Exposure to high-pressure jet at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4118",
      "description": "Exposure to high-pressure jet at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4119",
      "description": "Exposure to high-pressure jet at residential institution During unspecified activity"
    },
    {
      "name": "W412",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area"
    },
    {
      "name": "W4120",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4121",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4122",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4123",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4124",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4128",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4129",
      "description": "Exposure to high-pressure jet at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W413",
      "description": "Exposure to high-pressure jet at sports and athletics area"
    },
    {
      "name": "W4130",
      "description": "Exposure to high-pressure jet at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4131",
      "description": "Exposure to high-pressure jet at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4132",
      "description": "Exposure to high-pressure jet at sports and athletics area While working for income"
    },
    {
      "name": "W4133",
      "description": "Exposure to high-pressure jet at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4134",
      "description": "Exposure to high-pressure jet at sports and athletics area While resting, sleeping, eating or engaging in other vital activities "
    },
    {
      "name": "W4138",
      "description": "Exposure to high-pressure jet at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4139",
      "description": "Exposure to high-pressure jet at sports and athletics area During unspecified activity"
    },
    {
      "name": "W414",
      "description": "Exposure to high-pressure jet at street and highway"
    },
    {
      "name": "W4140",
      "description": "Exposure to high-pressure jet at street and highway While engaged in sports activity"
    },
    {
      "name": "W4141",
      "description": "Exposure to high-pressure jet at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4142",
      "description": "Exposure to high-pressure jet at street and highway While working for income"
    },
    {
      "name": "W4143",
      "description": "Exposure to high-pressure jet at street and highway While engaged in other types of work"
    },
    {
      "name": "W4144",
      "description": "Exposure to high-pressure jet at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4148",
      "description": "Exposure to high-pressure jet at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4149",
      "description": "Exposure to high-pressure jet at street and highway During unspecified activity"
    },
    {
      "name": "W415",
      "description": "Exposure to high-pressure jet at trade and service area"
    },
    {
      "name": "W4150",
      "description": "Exposure to high-pressure jet at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4151",
      "description": "Exposure to high-pressure jet at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4152",
      "description": "Exposure to high-pressure jet at trade and service area While working for income"
    },
    {
      "name": "W4153",
      "description": "Exposure to high-pressure jet at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4154",
      "description": "Exposure to high-pressure jet at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4158",
      "description": "Exposure to high-pressure jet at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4159",
      "description": "Exposure to high-pressure jet at trade and service area During unspecified activity"
    },
    {
      "name": "W416",
      "description": "Exposure to high-pressure jet at industrial and construction area"
    },
    {
      "name": "W4160",
      "description": "Exposure to high-pressure jet at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4161",
      "description": "Exposure to high-pressure jet at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4162",
      "description": "Exposure to high-pressure jet at industrial and construction area While working for income"
    },
    {
      "name": "W4163",
      "description": "Exposure to high-pressure jet at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4164",
      "description": "Exposure to high-pressure jet at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4168",
      "description": "Exposure to high-pressure jet at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4169",
      "description": "Exposure to high-pressure jet at industrial and construction area During unspecified activity"
    },
    {
      "name": "W417",
      "description": "Exposure to high-pressure jet at farm"
    },
    {
      "name": "W4170",
      "description": "Exposure to high-pressure jet at farm While engaged in sports activity"
    },
    {
      "name": "W4171",
      "description": "Exposure to high-pressure jet at farm While engaged in leisure activity"
    },
    {
      "name": "W4172",
      "description": "Exposure to high-pressure jet at farm While working for income"
    },
    {
      "name": "W4173",
      "description": "Exposure to high-pressure jet at farm While engaged in other types of work"
    },
    {
      "name": "W4174",
      "description": "Exposure to high-pressure jet at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4178",
      "description": "Exposure to high-pressure jet at farm While engaged in other specified activities"
    },
    {
      "name": "W4179",
      "description": "Exposure to high-pressure jet at farm During unspecified activity"
    },
    {
      "name": "W418",
      "description": "Exposure to high-pressure jet at other specified places"
    },
    {
      "name": "W4180",
      "description": "Exposure to high-pressure jet at other specified places While engaged in sports activity"
    },
    {
      "name": "W4181",
      "description": "Exposure to high-pressure jet at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4182",
      "description": "Exposure to high-pressure jet at other specified places While working for income"
    },
    {
      "name": "W4183",
      "description": "Exposure to high-pressure jet at other specified places While engaged in other types of work"
    },
    {
      "name": "W4184",
      "description": "Exposure to high-pressure jet at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4188",
      "description": "Exposure to high-pressure jet at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4189",
      "description": "Exposure to high-pressure jet at other specified places During unspecified activity"
    },
    {
      "name": "W419",
      "description": "Exposure to high-pressure jet at unspecified place"
    },
    {
      "name": "W4190",
      "description": "Exposure to high-pressure jet at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4191",
      "description": "Exposure to high-pressure jet at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4192",
      "description": "Exposure to high-pressure jet at unspecified place While working for income"
    },
    {
      "name": "W4193",
      "description": "Exposure to high-pressure jet at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4194",
      "description": "Exposure to high-pressure jet at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4198",
      "description": "Exposure to high-pressure jet at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4199",
      "description": "Exposure to high-pressure jet at unspecified place During unspecified activity"
    },
    {
      "name": "W420",
      "description": "Exposure to noise at home"
    },
    {
      "name": "W4200",
      "description": "Exposure to noise at home While engaged in sports activity"
    },
    {
      "name": "W4201",
      "description": "Exposure to noise at home While engaged in leisure activity"
    },
    {
      "name": "W4202",
      "description": "Exposure to noise at home While working for income"
    },
    {
      "name": "W4203",
      "description": "Exposure to noise at home While engaged in other types of work"
    },
    {
      "name": "W4204",
      "description": "Exposure to noise at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4208",
      "description": "Exposure to noise at home While engaged in other specified activities"
    },
    {
      "name": "W4209",
      "description": "Exposure to noise at home During unspecified activity"
    },
    {
      "name": "W421",
      "description": "Exposure to noise at residential institution"
    },
    {
      "name": "W4210",
      "description": "Exposure to noise at residential institution While engaged in sports activity"
    },
    {
      "name": "W4211",
      "description": "Exposure to noise at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4212",
      "description": "Exposure to noise at residential institution While working for income"
    },
    {
      "name": "W4213",
      "description": "Exposure to noise at residential institution While engaged in other types of work"
    },
    {
      "name": "W4214",
      "description": "Exposure to noise at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4218",
      "description": "Exposure to noise at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4219",
      "description": "Exposure to noise at residential institution During unspecified activity"
    },
    {
      "name": "W422",
      "description": "Exposure to noise at school,other institution and public administrative area"
    },
    {
      "name": "W4220",
      "description": "Exposure to noise at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4221",
      "description": "Exposure to noise at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4222",
      "description": "Exposure to noise at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4223",
      "description": "Exposure to noise at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4224",
      "description": "Exposure to noise at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4228",
      "description": "Exposure to noise at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4229",
      "description": "Exposure to noise at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W423",
      "description": "Exposure to noise at sports and athletics area"
    },
    {
      "name": "W4230",
      "description": "Exposure to noise at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4231",
      "description": "Exposure to noise at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4232",
      "description": "Exposure to noise at sports and athletics area While working for income"
    },
    {
      "name": "W4233",
      "description": "Exposure to noise at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4234",
      "description": "Exposure to noise at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4238",
      "description": "Exposure to noise at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4239",
      "description": "Exposure to noise at sports and athletics area During unspecified activity"
    },
    {
      "name": "W424",
      "description": "Exposure to noise at street and highway"
    },
    {
      "name": "W4240",
      "description": "Exposure to noise at street and highway While engaged in sports activity"
    },
    {
      "name": "W4241",
      "description": "Exposure to noise at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4242",
      "description": "Exposure to noise at street and highway While working for income"
    },
    {
      "name": "W4243",
      "description": "Exposure to noise at street and highway While engaged in other types of work"
    },
    {
      "name": "W4244",
      "description": "Exposure to noise at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4248",
      "description": "Exposure to noise at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4249",
      "description": "Exposure to noise at street and highway During unspecified activity"
    },
    {
      "name": "W425",
      "description": "Exposure to noise at trade and service area"
    },
    {
      "name": "W4250",
      "description": "Exposure to noise at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4251",
      "description": "Exposure to noise at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4252",
      "description": "Exposure to noise at trade and service area While working for income"
    },
    {
      "name": "W4253",
      "description": "Exposure to noise at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4254",
      "description": "Exposure to noise at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4258",
      "description": "Exposure to noise at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4259",
      "description": "Exposure to noise at trade and service area During unspecified activity"
    },
    {
      "name": "W426",
      "description": "Exposure to noise at industrial and construction area"
    },
    {
      "name": "W4260",
      "description": "Exposure to noise at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4261",
      "description": "Exposure to noise at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4262",
      "description": "Exposure to noise at industrial and construction area While working for income"
    },
    {
      "name": "W4263",
      "description": "Exposure to noise at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4264",
      "description": "Exposure to noise at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4268",
      "description": "Exposure to noise at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4269",
      "description": "Exposure to noise at industrial and construction area During unspecified activity"
    },
    {
      "name": "W427",
      "description": "Exposure to noise at farm"
    },
    {
      "name": "W4270",
      "description": "Exposure to noise at farm While engaged in sports activity"
    },
    {
      "name": "W4271",
      "description": "Exposure to noise at farm While engaged in leisure activity"
    },
    {
      "name": "W4272",
      "description": "Exposure to noise at farm While working for income"
    },
    {
      "name": "W4273",
      "description": "Exposure to noise at farm While engaged in other types of work"
    },
    {
      "name": "W4274",
      "description": "Exposure to noise at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4278",
      "description": "Exposure to noise at farm While engaged in other specified activities"
    },
    {
      "name": "W4279",
      "description": "Exposure to noise at farm During unspecified activity"
    },
    {
      "name": "W428",
      "description": "Exposure to noise at other specified places"
    },
    {
      "name": "W4280",
      "description": "Exposure to noise at other specified places While engaged in sports activity"
    },
    {
      "name": "W4281",
      "description": "Exposure to noise at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4282",
      "description": "Exposure to noise at other specified places While working for income"
    },
    {
      "name": "W4283",
      "description": "Exposure to noise at other specified places While engaged in other types of work"
    },
    {
      "name": "W4284",
      "description": "Exposure to noise at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4288",
      "description": "Exposure to noise at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4289",
      "description": "Exposure to noise at other specified places During unspecified activity"
    },
    {
      "name": "W429",
      "description": "Exposure to noise at unspecified place"
    },
    {
      "name": "W4290",
      "description": "Exposure to noise at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4291",
      "description": "Exposure to noise at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4292",
      "description": "Exposure to noise at unspecified place While working for income"
    },
    {
      "name": "W4293",
      "description": "Exposure to noise at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4294",
      "description": "Exposure to noise at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4298",
      "description": "Exposure to noise at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4299",
      "description": "Exposure to noise at unspecified place During unspecified activity"
    },
    {
      "name": "W430",
      "description": "Exposure to vibration at home"
    },
    {
      "name": "W4300",
      "description": "Exposure to vibration at home While engaged in sports activity"
    },
    {
      "name": "W4301",
      "description": "Exposure to vibration at home While engaged in leisure activity"
    },
    {
      "name": "W4302",
      "description": "Exposure to vibration at home While working for income"
    },
    {
      "name": "W4303",
      "description": "Exposure to vibration at home While engaged in other types of work"
    },
    {
      "name": "W4304",
      "description": "Exposure to vibration at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4308",
      "description": "Exposure to vibration at home While engaged in other specified activities"
    },
    {
      "name": "W4309",
      "description": "Exposure to vibration at home During unspecified activity"
    },
    {
      "name": "W431",
      "description": "Exposure to vibration at residential institution"
    },
    {
      "name": "W4310",
      "description": "Exposure to vibration at residential institution While engaged in sports activity"
    },
    {
      "name": "W4311",
      "description": "Exposure to vibration at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4312",
      "description": "Exposure to vibration at residential institution While working for income"
    },
    {
      "name": "W4313",
      "description": "Exposure to vibration at residential institution While engaged in other types of work"
    },
    {
      "name": "W4314",
      "description": "Exposure to vibration at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4318",
      "description": "Exposure to vibration at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4319",
      "description": "Exposure to vibration at residential institution During unspecified activity"
    },
    {
      "name": "W432",
      "description": "Exposure to vibration at school,other institution and public administrative area"
    },
    {
      "name": "W4320",
      "description": "Exposure to vibration at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4321",
      "description": "Exposure to vibration at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4322",
      "description": "Exposure to vibration at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4323",
      "description": "Exposure to vibration at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4324",
      "description": "Exposure to vibration at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4328",
      "description": "Exposure to vibration at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4329",
      "description": "Exposure to vibration at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W433",
      "description": "Exposure to vibration at sports and athletics area"
    },
    {
      "name": "W4330",
      "description": "Exposure to vibration at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4331",
      "description": "Exposure to vibration at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4332",
      "description": "Exposure to vibration at sports and athletics area While working for income"
    },
    {
      "name": "W4333",
      "description": "Exposure to vibration at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4334",
      "description": "Exposure to vibration at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4338",
      "description": "Exposure to vibration at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4339",
      "description": "Exposure to vibration at sports and athletics area During unspecified activity"
    },
    {
      "name": "W434",
      "description": "Exposure to vibration at street and highway"
    },
    {
      "name": "W4340",
      "description": "Exposure to vibration at street and highway While engaged in sports activity"
    },
    {
      "name": "W4341",
      "description": "Exposure to vibration at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4342",
      "description": "Exposure to vibration at street and highway While working for income"
    },
    {
      "name": "W4343",
      "description": "Exposure to vibration at street and highway While engaged in other types of work"
    },
    {
      "name": "W4344",
      "description": "Exposure to vibration at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4348",
      "description": "Exposure to vibration at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4349",
      "description": "Exposure to vibration at street and highway During unspecified activity"
    },
    {
      "name": "W435",
      "description": "Exposure to vibration at trade and service area"
    },
    {
      "name": "W4350",
      "description": "Exposure to vibration at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4351",
      "description": "Exposure to vibration at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4352",
      "description": "Exposure to vibration at trade and service area While working for income"
    },
    {
      "name": "W4353",
      "description": "Exposure to vibration at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4354",
      "description": "Exposure to vibration at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4358",
      "description": "Exposure to vibration at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4359",
      "description": "Exposure to vibration at trade and service area During unspecified activity"
    },
    {
      "name": "W436",
      "description": "Exposure to vibration at industrial and construction area"
    },
    {
      "name": "W4360",
      "description": "Exposure to vibration at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4361",
      "description": "Exposure to vibration at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4362",
      "description": "Exposure to vibration at industrial and construction area While working for income"
    },
    {
      "name": "W4363",
      "description": "Exposure to vibration at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4364",
      "description": "Exposure to vibration at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4368",
      "description": "Exposure to vibration at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4369",
      "description": "Exposure to vibration at industrial and construction area During unspecified activity"
    },
    {
      "name": "W437",
      "description": "Exposure to vibration at farm"
    },
    {
      "name": "W4370",
      "description": "Exposure to vibration at farm While engaged in sports activity"
    },
    {
      "name": "W4371",
      "description": "Exposure to vibration at farm While engaged in leisure activity"
    },
    {
      "name": "W4372",
      "description": "Exposure to vibration at farm While working for income"
    },
    {
      "name": "W4373",
      "description": "Exposure to vibration at farm While engaged in other types of work"
    },
    {
      "name": "W4374",
      "description": "Exposure to vibration at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4378",
      "description": "Exposure to vibration at farm While engaged in other specified activities"
    },
    {
      "name": "W4379",
      "description": "Exposure to vibration at farm During unspecified activity"
    },
    {
      "name": "W438",
      "description": "Exposure to vibration at other specified places"
    },
    {
      "name": "W4380",
      "description": "Exposure to vibration at other specified places While engaged in sports activity"
    },
    {
      "name": "W4381",
      "description": "Exposure to vibration at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4382",
      "description": "Exposure to vibration at other specified places While working for income"
    },
    {
      "name": "W4383",
      "description": "Exposure to vibration at other specified places While engaged in other types of work"
    },
    {
      "name": "W4384",
      "description": "Exposure to vibration at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4388",
      "description": "Exposure to vibration at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4389",
      "description": "Exposure to vibration at other specified places During unspecified activity"
    },
    {
      "name": "W439",
      "description": "Exposure to vibration at unspecified place"
    },
    {
      "name": "W4390",
      "description": "Exposure to vibration at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4391",
      "description": "Exposure to vibration at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4392",
      "description": "Exposure to vibration at unspecified place While working for income"
    },
    {
      "name": "W4393",
      "description": "Exposure to vibration at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4394",
      "description": "Exposure to vibration at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4398",
      "description": "Exposure to vibration at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4399",
      "description": "Exposure to vibration at unspecified place"
    },
    {
      "name": "W440",
      "description": "Foreign body entering into or through eye or natural orifice at home"
    },
    {
      "name": "W4400",
      "description": "Foreign body entering into or through eye or natural orifice at home While engaged in sports activity"
    },
    {
      "name": "W4401",
      "description": "Foreign body entering into or through eye or natural orifice at home While engaged in leisure activity"
    },
    {
      "name": "W4402",
      "description": "Foreign body entering into or through eye or natural orifice at home While working for income"
    },
    {
      "name": "W4403",
      "description": "Foreign body entering into or through eye or natural orifice at home While engaged in other types of work"
    },
    {
      "name": "W4404",
      "description": "Foreign body entering into or through eye or natural orifice at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4408",
      "description": "Foreign body entering into or through eye or natural orifice at home While engaged in other specified activities"
    },
    {
      "name": "W4409",
      "description": "Foreign body entering into or through eye or natural orifice at home During unspecified activity"
    },
    {
      "name": "W441",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution"
    },
    {
      "name": "W4410",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While engaged in sports activity"
    },
    {
      "name": "W4411",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4412",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While working for income"
    },
    {
      "name": "W4413",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While engaged in other types of work"
    },
    {
      "name": "W4414",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4418",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4419",
      "description": "Foreign body entering into or through eye or natural orifice at residential institution During unspecified activity"
    },
    {
      "name": "W442",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area"
    },
    {
      "name": "W4420",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4421",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4422",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4423",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4424",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4428",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4429",
      "description": "Foreign body entering into or through eye or natural orifice at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W443",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area"
    },
    {
      "name": "W4430",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4431",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4432",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While working for income"
    },
    {
      "name": "W4433",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4434",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4438",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4439",
      "description": "Foreign body entering into or through eye or natural orifice at sports and athletics area During unspecified activity"
    },
    {
      "name": "W444",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway"
    },
    {
      "name": "W4440",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While engaged in sports activity"
    },
    {
      "name": "W4441",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4442",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While working for income"
    },
    {
      "name": "W4443",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While engaged in other types of work"
    },
    {
      "name": "W4444",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4448",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4449",
      "description": "Foreign body entering into or through eye or natural orifice at street and highway During unspecified activity"
    },
    {
      "name": "W445",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area"
    },
    {
      "name": "W4450",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4451",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4452",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While working for income"
    },
    {
      "name": "W4453",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4454",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4458",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4459",
      "description": "Foreign body entering into or through eye or natural orifice at trade and service area During unspecified activity"
    },
    {
      "name": "W446",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area"
    },
    {
      "name": "W4460",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4461",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4462",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While working for income"
    },
    {
      "name": "W4463",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4464",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4468",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4469",
      "description": "Foreign body entering into or through eye or natural orifice at industrial and construction area During unspecified activity"
    },
    {
      "name": "W447",
      "description": "Foreign body entering into or through eye or natural orifice at farm"
    },
    {
      "name": "W4470",
      "description": "Foreign body entering into or through eye or natural orifice at farm While engaged in sports activity"
    },
    {
      "name": "W4471",
      "description": "Foreign body entering into or through eye or natural orifice at farm While engaged in leisure activity"
    },
    {
      "name": "W4472",
      "description": "Foreign body entering into or through eye or natural orifice at farm While working for income"
    },
    {
      "name": "W4473",
      "description": "Foreign body entering into or through eye or natural orifice at farm While engaged in other types of work"
    },
    {
      "name": "W4474",
      "description": "Foreign body entering into or through eye or natural orifice at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4478",
      "description": "Foreign body entering into or through eye or natural orifice at farm While engaged in other specified activities"
    },
    {
      "name": "W4479",
      "description": "Foreign body entering into or through eye or natural orifice at farm During unspecified activity"
    },
    {
      "name": "W448",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places"
    },
    {
      "name": "W4480",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While engaged in sports activity"
    },
    {
      "name": "W4481",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4482",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While working for income"
    },
    {
      "name": "W4483",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While engaged in other types of work"
    },
    {
      "name": "W4484",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4488",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4489",
      "description": "Foreign body entering into or through eye or natural orifice at other specified places During unspecified activity"
    },
    {
      "name": "W449",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place"
    },
    {
      "name": "W4490",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4491",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4492",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While working for income"
    },
    {
      "name": "W4493",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4494",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4498",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4499",
      "description": "Foreign body entering into or through eye or natural orifice at unspecified place During unspecified activity"
    },
    {
      "name": "W450",
      "description": "Foreign body or object entering through skin at home"
    },
    {
      "name": "W4500",
      "description": "Foreign body or object entering through skin at home While engaged in sports activity"
    },
    {
      "name": "W4501",
      "description": "Foreign body or object entering through skin at home While engaged in leisure activity"
    },
    {
      "name": "W4502",
      "description": "Foreign body or object entering through skin at home While working for income"
    },
    {
      "name": "W4503",
      "description": "Foreign body or object entering through skin at home While engaged in other types of work"
    },
    {
      "name": "W4504",
      "description": "Foreign body or object entering through skin at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4508",
      "description": "Foreign body or object entering through skin at home While engaged in other specified activities"
    },
    {
      "name": "W4509",
      "description": "Foreign body or object entering through skin at home During unspecified activity"
    },
    {
      "name": "W451",
      "description": "Foreign body or object entering through skin at residential institution"
    },
    {
      "name": "W4510",
      "description": "Foreign body or object entering through skin at residential institution While engaged in sports activity"
    },
    {
      "name": "W4511",
      "description": "Foreign body or object entering through skin at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4512",
      "description": "Foreign body or object entering through skin at residential institution While working for income"
    },
    {
      "name": "W4513",
      "description": "Foreign body or object entering through skin at residential institution While engaged in other types of work"
    },
    {
      "name": "W4514",
      "description": "Foreign body or object entering through skin at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4518",
      "description": "Foreign body or object entering through skin at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4519",
      "description": "Foreign body or object entering through skin at residential institution During unspecified activity"
    },
    {
      "name": "W452",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area"
    },
    {
      "name": "W4520",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4521",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4522",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4523",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4524",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4528",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4529",
      "description": "Foreign body or object entering through skin at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W453",
      "description": "Foreign body or object entering through skin at sports and athletics area"
    },
    {
      "name": "W4530",
      "description": "Foreign body or object entering through skin at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4531",
      "description": "Foreign body or object entering through skin at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4532",
      "description": "Foreign body or object entering through skin at sports and athletics area While working for income"
    },
    {
      "name": "W4533",
      "description": "Foreign body or object entering through skin at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4534",
      "description": "Foreign body or object entering through skin at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4538",
      "description": "Foreign body or object entering through skin at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4539",
      "description": "Foreign body or object entering through skin at sports and athletics area During unspecified activity"
    },
    {
      "name": "W454",
      "description": "Foreign body or object entering through skin at street and highway"
    },
    {
      "name": "W4540",
      "description": "Foreign body or object entering through skin at street and highway While engaged in sports activity"
    },
    {
      "name": "W4541",
      "description": "Foreign body or object entering through skin at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4542",
      "description": "Foreign body or object entering through skin at street and highway While working for income"
    },
    {
      "name": "W4543",
      "description": "Foreign body or object entering through skin at street and highway While engaged in other types of work"
    },
    {
      "name": "W4544",
      "description": "Foreign body or object entering through skin at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4548",
      "description": "Foreign body or object entering through skin at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4549",
      "description": "Foreign body or object entering through skin at street and highway During unspecified activity"
    },
    {
      "name": "W455",
      "description": "Foreign body or object entering through skin at trade and service area"
    },
    {
      "name": "W4550",
      "description": "Foreign body or object entering through skin at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4551",
      "description": "Foreign body or object entering through skin at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4552",
      "description": "Foreign body or object entering through skin at trade and service area While working for income"
    },
    {
      "name": "W4553",
      "description": "Foreign body or object entering through skin at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4554",
      "description": "Foreign body or object entering through skin at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4558",
      "description": "Foreign body or object entering through skin at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4559",
      "description": "Foreign body or object entering through skin at trade and service area During unspecified activity"
    },
    {
      "name": "W456",
      "description": "Foreign body or object entering through skin at industrial and construction area"
    },
    {
      "name": "W4560",
      "description": "Foreign body or object entering through skin at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4561",
      "description": "Foreign body or object entering through skin at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4562",
      "description": "Foreign body or object entering through skin at industrial and construction area While working for income"
    },
    {
      "name": "W4563",
      "description": "Foreign body or object entering through skin at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4564",
      "description": "Foreign body or object entering through skin at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4568",
      "description": "Foreign body or object entering through skin at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4569",
      "description": "Foreign body or object entering through skin at industrial and construction area During unspecified activity"
    },
    {
      "name": "W457",
      "description": "Foreign body or object entering through skin at farm"
    },
    {
      "name": "W4570",
      "description": "Foreign body or object entering through skin at farm While engaged in sports activity"
    },
    {
      "name": "W4571",
      "description": "Foreign body or object entering through skin at farm While engaged in leisure activity"
    },
    {
      "name": "W4572",
      "description": "Foreign body or object entering through skin at farm While working for income"
    },
    {
      "name": "W4573",
      "description": "Foreign body or object entering through skin at farm While engaged in other types of work"
    },
    {
      "name": "W4574",
      "description": "Foreign body or object entering through skin at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4578",
      "description": "Foreign body or object entering through skin at farm While engaged in other specified activities"
    },
    {
      "name": "W4579",
      "description": "Foreign body or object entering through skin at farm During unspecified activity"
    },
    {
      "name": "W458",
      "description": "Foreign body or object entering through skin at other specified places"
    },
    {
      "name": "W4580",
      "description": "Foreign body or object entering through skin at other specified places While engaged in sports activity"
    },
    {
      "name": "W4581",
      "description": "Foreign body or object entering through skin at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4582",
      "description": "Foreign body or object entering through skin at other specified places While working for income"
    },
    {
      "name": "W4583",
      "description": "Foreign body or object entering through skin at other specified places While engaged in other types of work"
    },
    {
      "name": "W4584",
      "description": "Foreign body or object entering through skin at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4588",
      "description": "Foreign body or object entering through skin at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4589",
      "description": "Foreign body or object entering through skin at other specified places During unspecified activity"
    },
    {
      "name": "W459",
      "description": "Foreign body or object entering through skin at unspecified place"
    },
    {
      "name": "W4590",
      "description": "Foreign body or object entering through skin at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4591",
      "description": "Foreign body or object entering through skin at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4592",
      "description": "Foreign body or object entering through skin at unspecified place While working for income"
    },
    {
      "name": "W4593",
      "description": "Foreign body or object entering through skin at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4594",
      "description": "Foreign body or object entering through skin at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4598",
      "description": "Foreign body or object entering through skin at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4599",
      "description": "Foreign body or object entering through skin at unspecified place During unspecified activity"
    },
    {
      "name": "W460",
      "description": "Contact with hypodermic needle at home"
    },
    {
      "name": "W4600",
      "description": "Contact with hypodermic needle at home While engaged in sports activity"
    },
    {
      "name": "W4601",
      "description": "Contact with hypodermic needle at home While engaged in leisure activity"
    },
    {
      "name": "W4602",
      "description": "Contact with hypodermic needle at home While working for income"
    },
    {
      "name": "W4603",
      "description": "Contact with hypodermic needle at home While engaged in other types of work"
    },
    {
      "name": "W4604",
      "description": "Contact with hypodermic needle at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4608",
      "description": "Contact with hypodermic needle at home While engaged in other specified activities"
    },
    {
      "name": "W4609",
      "description": "Contact with hypodermic needle at home During unspecified activity"
    },
    {
      "name": "W461",
      "description": "Contact with hypodermic needle at residential institution"
    },
    {
      "name": "W4610",
      "description": "Contact with hypodermic needle at residential institution While engaged in sports activity"
    },
    {
      "name": "W4611",
      "description": "Contact with hypodermic needle at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4612",
      "description": "Contact with hypodermic needle at residential institution While working for income"
    },
    {
      "name": "W4613",
      "description": "Contact with hypodermic needle at residential institution While engaged in other types of work"
    },
    {
      "name": "W4614",
      "description": "Contact with hypodermic needle at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4618",
      "description": "Contact with hypodermic needle at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4619",
      "description": "Contact with hypodermic needle at residential institution During unspecified activity"
    },
    {
      "name": "W462",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area"
    },
    {
      "name": "W4620",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4621",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4622",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4623",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4624",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4628",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4629",
      "description": "Contact with hypodermic needle at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W463",
      "description": "Contact with hypodermic needle at sports and athletics area"
    },
    {
      "name": "W4630",
      "description": "Contact with hypodermic needle at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4631",
      "description": "Contact with hypodermic needle at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4632",
      "description": "Contact with hypodermic needle at sports and athletics area While working for income"
    },
    {
      "name": "W4633",
      "description": "Contact with hypodermic needle at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4634",
      "description": "Contact with hypodermic needle at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4638",
      "description": "Contact with hypodermic needle at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4639",
      "description": "Contact with hypodermic needle at sports and athletics area During unspecified activity"
    },
    {
      "name": "W464",
      "description": "Contact with hypodermic needle at street and highway"
    },
    {
      "name": "W4640",
      "description": "Contact with hypodermic needle at street and highway While engaged in sports activity"
    },
    {
      "name": "W4641",
      "description": "Contact with hypodermic needle at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4642",
      "description": "Contact with hypodermic needle at street and highway While working for income"
    },
    {
      "name": "W4643",
      "description": "Contact with hypodermic needle at street and highway While engaged in other types of work"
    },
    {
      "name": "W4644",
      "description": "Contact with hypodermic needle at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4648",
      "description": "Contact with hypodermic needle at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4649",
      "description": "Contact with hypodermic needle at street and highway During unspecified activity"
    },
    {
      "name": "W465",
      "description": "Contact with hypodermic needle at trade and service area"
    },
    {
      "name": "W4650",
      "description": "Contact with hypodermic needle at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4651",
      "description": "Contact with hypodermic needle at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4652",
      "description": "Contact with hypodermic needle at trade and service area While working for income"
    },
    {
      "name": "W4653",
      "description": "Contact with hypodermic needle at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4654",
      "description": "Contact with hypodermic needle at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4658",
      "description": "Contact with hypodermic needle at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4659",
      "description": "Contact with hypodermic needle at trade and service area During unspecified activity"
    },
    {
      "name": "W466",
      "description": "Contact with hypodermic needle at industrial and construction area"
    },
    {
      "name": "W4660",
      "description": "Contact with hypodermic needle at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4661",
      "description": "Contact with hypodermic needle at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4662",
      "description": "Contact with hypodermic needle at industrial and construction area While working for income"
    },
    {
      "name": "W4663",
      "description": "Contact with hypodermic needle at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4664",
      "description": "Contact with hypodermic needle at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4668",
      "description": "Contact with hypodermic needle at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4669",
      "description": "Contact with hypodermic needle at industrial and construction area During unspecified activity"
    },
    {
      "name": "W467",
      "description": "Contact with hypodermic needle at farm"
    },
    {
      "name": "W4670",
      "description": "Contact with hypodermic needle at farm While engaged in sports activity"
    },
    {
      "name": "W4671",
      "description": "Contact with hypodermic needle at farm While engaged in leisure activity"
    },
    {
      "name": "W4672",
      "description": "Contact with hypodermic needle at farm While working for income"
    },
    {
      "name": "W4673",
      "description": "Contact with hypodermic needle at farm While engaged in other types of work"
    },
    {
      "name": "W4674",
      "description": "Contact with hypodermic needle at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4678",
      "description": "Contact with hypodermic needle at farm While engaged in other specified activities"
    },
    {
      "name": "W4679",
      "description": "Contact with hypodermic needle at farm During unspecified activity"
    },
    {
      "name": "W468",
      "description": "Contact with hypodermic needle at other specified places"
    },
    {
      "name": "W4680",
      "description": "Contact with hypodermic needle at other specified places While engaged in sports activity"
    },
    {
      "name": "W4681",
      "description": "Contact with hypodermic needle at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4682",
      "description": "Contact with hypodermic needle at other specified places While working for income"
    },
    {
      "name": "W4683",
      "description": "Contact with hypodermic needle at other specified places While engaged in other types of work"
    },
    {
      "name": "W4684",
      "description": "Contact with hypodermic needle at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4688",
      "description": "Contact with hypodermic needle at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4689",
      "description": "Contact with hypodermic needle at other specified places During unspecified activity"
    },
    {
      "name": "W469",
      "description": "Contact with hypodermic needle at unspecified place"
    },
    {
      "name": "W4690",
      "description": "Contact with hypodermic needle at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4691",
      "description": "Contact with hypodermic needle at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4692",
      "description": "Contact with hypodermic needle at unspecified place While working for income"
    },
    {
      "name": "W4693",
      "description": "Contact with hypodermic needle at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4694",
      "description": "Contact with hypodermic needle at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4698",
      "description": "Contact with hypodermic needle at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4699",
      "description": "Contact with hypodermic needle at unspecified place During unspecified activity"
    },
    {
      "name": "W490",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home"
    },
    {
      "name": "W4900",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While engaged in sports activity"
    },
    {
      "name": "W4901",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While engaged in leisure activity"
    },
    {
      "name": "W4902",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While working for income"
    },
    {
      "name": "W4903",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While engaged in other types of work"
    },
    {
      "name": "W4904",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4908",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home While engaged in other specified activities"
    },
    {
      "name": "W4909",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at home During unspecified activity"
    },
    {
      "name": "W491",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution"
    },
    {
      "name": "W4910",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While engaged in sports activity"
    },
    {
      "name": "W4911",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While engaged in leisure activity"
    },
    {
      "name": "W4912",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While working for income"
    },
    {
      "name": "W4913",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While engaged in other types of work"
    },
    {
      "name": "W4914",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4918",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution While engaged in other specified activities"
    },
    {
      "name": "W4919",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at residential institution During unspecified activity"
    },
    {
      "name": "W492",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area"
    },
    {
      "name": "W4920",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W4921",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W4922",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W4923",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W4924",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4928",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W4929",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W493",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area"
    },
    {
      "name": "W4930",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W4931",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W4932",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While working for income"
    },
    {
      "name": "W4933",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W4934",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4938",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W4939",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at sports and athletics area During unspecified activity"
    },
    {
      "name": "W494",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway"
    },
    {
      "name": "W4940",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While engaged in sports activity"
    },
    {
      "name": "W4941",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While engaged in leisure activity"
    },
    {
      "name": "W4942",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While working for income"
    },
    {
      "name": "W4943",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While engaged in other types of work"
    },
    {
      "name": "W4944",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4948",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway While engaged in other specified activities"
    },
    {
      "name": "W4949",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at street and highway During unspecified activity"
    },
    {
      "name": "W495",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area"
    },
    {
      "name": "W4950",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While engaged in sports activity"
    },
    {
      "name": "W4951",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W4952",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While working for income"
    },
    {
      "name": "W4953",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While engaged in other types of work"
    },
    {
      "name": "W4954",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4958",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W4959",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at trade and service area During unspecified activity"
    },
    {
      "name": "W496",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area"
    },
    {
      "name": "W4960",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W4961",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W4962",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While working for income"
    },
    {
      "name": "W4963",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W4964",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4968",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W4969",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at industrial and construction area During unspecified activity"
    },
    {
      "name": "W497",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm"
    },
    {
      "name": "W4970",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While engaged in sports activity"
    },
    {
      "name": "W4971",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While engaged in leisure activity"
    },
    {
      "name": "W4972",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While working for income"
    },
    {
      "name": "W4973",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While engaged in other types of work"
    },
    {
      "name": "W4974",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4978",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm While engaged in other specified activities"
    },
    {
      "name": "W4979",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at farm During unspecified activity"
    },
    {
      "name": "W498",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places"
    },
    {
      "name": "W4980",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While engaged in sports activity"
    },
    {
      "name": "W4981",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While engaged in leisure activity"
    },
    {
      "name": "W4982",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While working for income"
    },
    {
      "name": "W4983",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While engaged in other types of work"
    },
    {
      "name": "W4984",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4988",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places While engaged in other specified activities"
    },
    {
      "name": "W4989",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at other specified places During unspecified activity"
    },
    {
      "name": "W499",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place"
    },
    {
      "name": "W4990",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While engaged in sports activity"
    },
    {
      "name": "W4991",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W4992",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While working for income"
    },
    {
      "name": "W4993",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While engaged in other types of work"
    },
    {
      "name": "W4994",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W4998",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W4999",
      "description": "Exposure to other and unspecified inanimate mechanicla forces at unspecified place During unspecified activity"
    },
    {
      "name": "W500",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home"
    },
    {
      "name": "W5000",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While engaged in sports activity"
    },
    {
      "name": "W5001",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While engaged in leisure activity"
    },
    {
      "name": "W5002",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While working for income"
    },
    {
      "name": "W5003",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While engaged in other types of work"
    },
    {
      "name": "W5004",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5008",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home While engaged in other specified activities"
    },
    {
      "name": "W5009",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at home During unspecified activity"
    },
    {
      "name": "W501",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution"
    },
    {
      "name": "W5010",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While engaged in sports activity"
    },
    {
      "name": "W5011",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5012",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While working for income"
    },
    {
      "name": "W5013",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While engaged in other types of work"
    },
    {
      "name": "W5014",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5018",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5019",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at residential institution During unspecified activity"
    },
    {
      "name": "W502",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area"
    },
    {
      "name": "W5020",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5021",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5022",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5023",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5024",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5028",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5029",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W503",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area"
    },
    {
      "name": "W5030",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5031",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5032",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While working for income"
    },
    {
      "name": "W5033",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5034",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5038",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5039",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at sports and athletics area During unspecified activity"
    },
    {
      "name": "W504",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway"
    },
    {
      "name": "W5040",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While engaged in sports activity"
    },
    {
      "name": "W5041",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5042",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While working for income"
    },
    {
      "name": "W5043",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While engaged in other types of work"
    },
    {
      "name": "W5044",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5048",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5049",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at street and highway During unspecified activity"
    },
    {
      "name": "W505",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area"
    },
    {
      "name": "W5050",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5051",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5052",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While working for income"
    },
    {
      "name": "W5053",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5054",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5058",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5059",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at trade and service area During unspecified activity"
    },
    {
      "name": "W506",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area"
    },
    {
      "name": "W5060",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5061",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5062",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While working for income"
    },
    {
      "name": "W5063",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5064",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5068",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5069",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at industrial and construction area During unspecified activity"
    },
    {
      "name": "W507",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm"
    },
    {
      "name": "W5070",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While engaged in sports activity"
    },
    {
      "name": "W5071",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While engaged in leisure activity"
    },
    {
      "name": "W5072",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While working for income"
    },
    {
      "name": "W5073",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While engaged in other types of work"
    },
    {
      "name": "W5074",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5078",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm While engaged in other specified activities"
    },
    {
      "name": "W5079",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at farm During unspecified activity"
    },
    {
      "name": "W508",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places"
    },
    {
      "name": "W5080",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While engaged in sports activity"
    },
    {
      "name": "W5081",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5082",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While working for income"
    },
    {
      "name": "W5083",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While engaged in other types of work"
    },
    {
      "name": "W5084",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5088",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5089",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at other specified places During unspecified activity"
    },
    {
      "name": "W509",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place"
    },
    {
      "name": "W5090",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5091",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5092",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While working for income"
    },
    {
      "name": "W5093",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5094",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5098",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5099",
      "description": "Hit, struck, kicked, twisted, bitten or scratched by another person at unspecified place During unspecified activity"
    },
    {
      "name": "W510",
      "description": "Striking against or bumped into by another person at home"
    },
    {
      "name": "W5100",
      "description": "Striking against or bumped into by another person at home While engaged in sports activity"
    },
    {
      "name": "W5101",
      "description": "Striking against or bumped into by another person at home While engaged in leisure activity"
    },
    {
      "name": "W5102",
      "description": "Striking against or bumped into by another person at home While working for income"
    },
    {
      "name": "W5103",
      "description": "Striking against or bumped into by another person at home While engaged in other types of work"
    },
    {
      "name": "W5104",
      "description": "Striking against or bumped into by another person at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5108",
      "description": "Striking against or bumped into by another person at home While engaged in other specified activities"
    },
    {
      "name": "W5109",
      "description": "Striking against or bumped into by another person at home During unspecified activity"
    },
    {
      "name": "W511",
      "description": "Striking against or bumped into by another person at residential institution"
    },
    {
      "name": "W5110",
      "description": "Striking against or bumped into by another person at residential institution While engaged in sports activity"
    },
    {
      "name": "W5111",
      "description": "Striking against or bumped into by another person at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5112",
      "description": "Striking against or bumped into by another person at residential institution While working for income"
    },
    {
      "name": "W5113",
      "description": "Striking against or bumped into by another person at residential institution While engaged in other types of work"
    },
    {
      "name": "W5114",
      "description": "Striking against or bumped into by another person at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5118",
      "description": "Striking against or bumped into by another person at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5119",
      "description": "Striking against or bumped into by another person at residential institution During unspecified activity"
    },
    {
      "name": "W512",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area"
    },
    {
      "name": "W5120",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5121",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5122",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5123",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5124",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5128",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5129",
      "description": "Striking against or bumped into by another person at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W513",
      "description": "Striking against or bumped into by another person at sports and athletics area"
    },
    {
      "name": "W5130",
      "description": "Striking against or bumped into by another person at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5131",
      "description": "Striking against or bumped into by another person at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5132",
      "description": "Striking against or bumped into by another person at sports and athletics area While working for income"
    },
    {
      "name": "W5133",
      "description": "Striking against or bumped into by another person at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5134",
      "description": "Striking against or bumped into by another person at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5138",
      "description": "Striking against or bumped into by another person at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5139",
      "description": "Striking against or bumped into by another person at sports and athletics area During unspecified activity"
    },
    {
      "name": "W514",
      "description": "Striking against or bumped into by another person at street and highway"
    },
    {
      "name": "W5140",
      "description": "Striking against or bumped into by another person at street and highway While engaged in sports activity"
    },
    {
      "name": "W5141",
      "description": "Striking against or bumped into by another person at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5142",
      "description": "Striking against or bumped into by another person at street and highway While working for income"
    },
    {
      "name": "W5143",
      "description": "Striking against or bumped into by another person at street and highway While engaged in other types of work"
    },
    {
      "name": "W5144",
      "description": "Striking against or bumped into by another person at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5148",
      "description": "Striking against or bumped into by another person at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5149",
      "description": "Striking against or bumped into by another person at street and highway During unspecified activity"
    },
    {
      "name": "W515",
      "description": "Striking against or bumped into by another person at trade and service area"
    },
    {
      "name": "W5150",
      "description": "Striking against or bumped into by another person at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5151",
      "description": "Striking against or bumped into by another person at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5152",
      "description": "Striking against or bumped into by another person at trade and service area While working for income"
    },
    {
      "name": "W5153",
      "description": "Striking against or bumped into by another person at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5154",
      "description": "Striking against or bumped into by another person at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5158",
      "description": "Striking against or bumped into by another person at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5159",
      "description": "Striking against or bumped into by another person at trade and service area During unspecified activity"
    },
    {
      "name": "W516",
      "description": "Striking against or bumped into by another person at industrial and construction area"
    },
    {
      "name": "W5160",
      "description": "Striking against or bumped into by another person at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5161",
      "description": "Striking against or bumped into by another person at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5162",
      "description": "Striking against or bumped into by another person at industrial and construction area While working for income"
    },
    {
      "name": "W5163",
      "description": "Striking against or bumped into by another person at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5164",
      "description": "Striking against or bumped into by another person at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5168",
      "description": "Striking against or bumped into by another person at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5169",
      "description": "Striking against or bumped into by another person at industrial and construction area During unspecified activity"
    },
    {
      "name": "W517",
      "description": "Striking against or bumped into by another person at farm"
    },
    {
      "name": "W5170",
      "description": "Striking against or bumped into by another person at farm While engaged in sports activity"
    },
    {
      "name": "W5171",
      "description": "Striking against or bumped into by another person at farm While engaged in leisure activity"
    },
    {
      "name": "W5172",
      "description": "Striking against or bumped into by another person at farm While working for income"
    },
    {
      "name": "W5173",
      "description": "Striking against or bumped into by another person at farm While engaged in other types of work"
    },
    {
      "name": "W5174",
      "description": "Striking against or bumped into by another person at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5178",
      "description": "Striking against or bumped into by another person at farm While engaged in other specified activities"
    },
    {
      "name": "W5179",
      "description": "Striking against or bumped into by another person at farm During unspecified activity"
    },
    {
      "name": "W518",
      "description": "Striking against or bumped into by another person at other specified places"
    },
    {
      "name": "W5180",
      "description": "Striking against or bumped into by another person at other specified places While engaged in sports activity"
    },
    {
      "name": "W5181",
      "description": "Striking against or bumped into by another person at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5182",
      "description": "Striking against or bumped into by another person at other specified places While working for income"
    },
    {
      "name": "W5183",
      "description": "Striking against or bumped into by another person at other specified places While engaged in other types of work"
    },
    {
      "name": "W5184",
      "description": "Striking against or bumped into by another person at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5188",
      "description": "Striking against or bumped into by another person at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5189",
      "description": "Striking against or bumped into by another person at other specified places During unspecified activity"
    },
    {
      "name": "W519",
      "description": "Striking against or bumped into by another person at unspecified place"
    },
    {
      "name": "W5190",
      "description": "Striking against or bumped into by another person at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5191",
      "description": "Striking against or bumped into by another person at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5192",
      "description": "Striking against or bumped into by another person at unspecified place While working for income"
    },
    {
      "name": "W5193",
      "description": "Striking against or bumped into by another person at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5194",
      "description": "Striking against or bumped into by another person at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5198",
      "description": "Striking against or bumped into by another person at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5199",
      "description": "Striking against or bumped into by another person at unspecified place During unspecified activity"
    },
    {
      "name": "W520",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home"
    },
    {
      "name": "W5200",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While engaged in sports activity"
    },
    {
      "name": "W5201",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While engaged in leisure activity"
    },
    {
      "name": "W5202",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While working for income"
    },
    {
      "name": "W5203",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While engaged in other types of work"
    },
    {
      "name": "W5204",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5208",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home While engaged in other specified activities"
    },
    {
      "name": "W5209",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at home During unspecified activity"
    },
    {
      "name": "W521",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution"
    },
    {
      "name": "W5210",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While engaged in sports activity"
    },
    {
      "name": "W5211",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5212",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While working for income"
    },
    {
      "name": "W5213",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While engaged in other types of work"
    },
    {
      "name": "W5214",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5218",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5219",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at residential institution During unspecified activity"
    },
    {
      "name": "W522",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area"
    },
    {
      "name": "W5220",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5221",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5222",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5223",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5224",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5228",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5229",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W523",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area"
    },
    {
      "name": "W5230",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5231",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5232",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While working for income"
    },
    {
      "name": "W5233",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5234",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5238",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5239",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at sports and athletics area During unspecified activity"
    },
    {
      "name": "W524",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway"
    },
    {
      "name": "W5240",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While engaged in sports activity"
    },
    {
      "name": "W5241",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5242",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While working for income"
    },
    {
      "name": "W5243",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While engaged in other types of work"
    },
    {
      "name": "W5244",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5248",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5249",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at street and highway During unspecified activity"
    },
    {
      "name": "W525",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area"
    },
    {
      "name": "W5250",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5251",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5252",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While working for income"
    },
    {
      "name": "W5253",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5254",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5258",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5259",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at trade and service area During unspecified activity"
    },
    {
      "name": "W526",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area"
    },
    {
      "name": "W5260",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5261",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5262",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While working for income"
    },
    {
      "name": "W5263",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5264",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5268",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5269",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at industrial and construction area During unspecified activity"
    },
    {
      "name": "W527",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm"
    },
    {
      "name": "W5270",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While engaged in sports activity"
    },
    {
      "name": "W5271",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While engaged in leisure activity"
    },
    {
      "name": "W5272",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While working for income"
    },
    {
      "name": "W5273",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While engaged in other types of work"
    },
    {
      "name": "W5274",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5278",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm While engaged in other specified activities"
    },
    {
      "name": "W5279",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at farm During unspecified activity"
    },
    {
      "name": "W528",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places"
    },
    {
      "name": "W5280",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While engaged in sports activity"
    },
    {
      "name": "W5281",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5282",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While working for income"
    },
    {
      "name": "W5283",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While engaged in other types of work"
    },
    {
      "name": "W5284",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5288",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5289",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at other specified places During unspecified activity"
    },
    {
      "name": "W529",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place"
    },
    {
      "name": "W5290",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5291",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5292",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While working for income"
    },
    {
      "name": "W5293",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5294",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5298",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5299",
      "description": "Crushed, pushed or stepped on by crowd or human stampede at unspecified place During unspecified activity"
    },
    {
      "name": "W530",
      "description": "Bitten by rat at home"
    },
    {
      "name": "W5300",
      "description": "Bitten by rat at home While engaged in sports activity"
    },
    {
      "name": "W5301",
      "description": "Bitten by rat at home While engaged in leisure activity"
    },
    {
      "name": "W5302",
      "description": "Bitten by rat at home While working for income"
    },
    {
      "name": "W5303",
      "description": "Bitten by rat at home While engaged in other types of work"
    },
    {
      "name": "W5304",
      "description": "Bitten by rat at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5308",
      "description": "Bitten by rat at home While engaged in other specified activities"
    },
    {
      "name": "W5309",
      "description": "Bitten by rat at home During unspecified activity"
    },
    {
      "name": "W531",
      "description": "Bitten by rat at residential institution"
    },
    {
      "name": "W5310",
      "description": "Bitten by rat at residential institution While engaged in sports activity"
    },
    {
      "name": "W5311",
      "description": "Bitten by rat at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5312",
      "description": "Bitten by rat at residential institution While working for income"
    },
    {
      "name": "W5313",
      "description": "Bitten by rat at residential institution While engaged in other types of work"
    },
    {
      "name": "W5314",
      "description": "Bitten by rat at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5318",
      "description": "Bitten by rat at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5319",
      "description": "Bitten by rat at residential institution During unspecified activity"
    },
    {
      "name": "W532",
      "description": "Bitten by rat at school,other institution and public administrative area"
    },
    {
      "name": "W5320",
      "description": "Bitten by rat at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5321",
      "description": "Bitten by rat at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5322",
      "description": "Bitten by rat at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5323",
      "description": "Bitten by rat at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5324",
      "description": "Bitten by rat at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5328",
      "description": "Bitten by rat at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5329",
      "description": "Bitten by rat at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W533",
      "description": "Bitten by rat at sports and athletics area"
    },
    {
      "name": "W5330",
      "description": "Bitten by rat at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5331",
      "description": "Bitten by rat at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5332",
      "description": "Bitten by rat at sports and athletics area While working for income"
    },
    {
      "name": "W5333",
      "description": "Bitten by rat at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5334",
      "description": "Bitten by rat at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5338",
      "description": "Bitten by rat at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5339",
      "description": "Bitten by rat at sports and athletics area During unspecified activity"
    },
    {
      "name": "W534",
      "description": "Bitten by rat at street and highway"
    },
    {
      "name": "W5340",
      "description": "Bitten by rat at street and highway While engaged in sports activity"
    },
    {
      "name": "W5341",
      "description": "Bitten by rat at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5342",
      "description": "Bitten by rat at street and highway While working for income"
    },
    {
      "name": "W5343",
      "description": "Bitten by rat at street and highway While engaged in other types of work"
    },
    {
      "name": "W5344",
      "description": "Bitten by rat at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5348",
      "description": "Bitten by rat at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5349",
      "description": "Bitten by rat at street and highway During unspecified activity"
    },
    {
      "name": "W535",
      "description": "Bitten by rat at trade and service area"
    },
    {
      "name": "W5350",
      "description": "Bitten by rat at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5351",
      "description": "Bitten by rat at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5352",
      "description": "Bitten by rat at trade and service area While working for income"
    },
    {
      "name": "W5353",
      "description": "Bitten by rat at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5354",
      "description": "Bitten by rat at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5358",
      "description": "Bitten by rat at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5359",
      "description": "Bitten by rat at trade and service area During unspecified activity"
    },
    {
      "name": "W536",
      "description": "Bitten by rat at industrial and construction area"
    },
    {
      "name": "W5360",
      "description": "Bitten by rat at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5361",
      "description": "Bitten by rat at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5362",
      "description": "Bitten by rat at industrial and construction area While working for income"
    },
    {
      "name": "W5363",
      "description": "Bitten by rat at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5364",
      "description": "Bitten by rat at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5368",
      "description": "Bitten by rat at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5369",
      "description": "Bitten by rat at industrial and construction area During unspecified activity"
    },
    {
      "name": "W537",
      "description": "Bitten by rat at farm"
    },
    {
      "name": "W5370",
      "description": "Bitten by rat at farm While engaged in sports activity"
    },
    {
      "name": "W5371",
      "description": "Bitten by rat at farm While engaged in leisure activity"
    },
    {
      "name": "W5372",
      "description": "Bitten by rat at farm While working for income"
    },
    {
      "name": "W5373",
      "description": "Bitten by rat at farm While engaged in other types of work"
    },
    {
      "name": "W5374",
      "description": "Bitten by rat at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5378",
      "description": "Bitten by rat at farm While engaged in other specified activities"
    },
    {
      "name": "W5379",
      "description": "Bitten by rat at farm During unspecified activity"
    },
    {
      "name": "W538",
      "description": "Bitten by rat at other specified places"
    },
    {
      "name": "W5380",
      "description": "Bitten by rat at other specified places While engaged in sports activity"
    },
    {
      "name": "W5381",
      "description": "Bitten by rat at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5382",
      "description": "Bitten by rat at other specified places While working for income"
    },
    {
      "name": "W5383",
      "description": "Bitten by rat at other specified places While engaged in other types of work"
    },
    {
      "name": "W5384",
      "description": "Bitten by rat at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5388",
      "description": "Bitten by rat at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5389",
      "description": "Bitten by rat at other specified places During unspecified activity"
    },
    {
      "name": "W539",
      "description": "Bitten by rat at unspecified place"
    },
    {
      "name": "W5390",
      "description": "Bitten by rat at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5391",
      "description": "Bitten by rat at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5392",
      "description": "Bitten by rat at unspecified place While working for income"
    },
    {
      "name": "W5393",
      "description": "Bitten by rat at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5394",
      "description": "Bitten by rat at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5398",
      "description": "Bitten by rat at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5399",
      "description": "Bitten by rat at unspecified place During unspecified activity"
    },
    {
      "name": "W540",
      "description": "Bitten or struck by dog at home"
    },
    {
      "name": "W5400",
      "description": "Bitten or struck by dog at home While engaged in sports activity"
    },
    {
      "name": "W5401",
      "description": "Bitten or struck by dog at home While engaged in leisure activity"
    },
    {
      "name": "W5402",
      "description": "Bitten or struck by dog at home While working for income"
    },
    {
      "name": "W5403",
      "description": "Bitten or struck by dog at home While engaged in other types of work"
    },
    {
      "name": "W5404",
      "description": "Bitten or struck by dog at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5408",
      "description": "Bitten or struck by dog at home While engaged in other specified activities"
    },
    {
      "name": "W5409",
      "description": "Bitten or struck by dog at home During unspecified activity"
    },
    {
      "name": "W541",
      "description": "Bitten or struck by dog at residential institution"
    },
    {
      "name": "W5410",
      "description": "Bitten or struck by dog at residential institution While engaged in sports activity"
    },
    {
      "name": "W5411",
      "description": "Bitten or struck by dog at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5412",
      "description": "Bitten or struck by dog at residential institution While working for income"
    },
    {
      "name": "W5413",
      "description": "Bitten or struck by dog at residential institution While engaged in other types of work"
    },
    {
      "name": "W5414",
      "description": "Bitten or struck by dog at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5418",
      "description": "Bitten or struck by dog at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5419",
      "description": "Bitten or struck by dog at residential institution During unspecified activity"
    },
    {
      "name": "W542",
      "description": "Bitten or struck by dog at school,other institution and public administrative area"
    },
    {
      "name": "W5420",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5421",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5422",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5423",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5424",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5428",
      "description": "Bitten or struck by dog at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5429",
      "description": "Bitten or struck by dog at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W543",
      "description": "Bitten or struck by dog at sports and athletics area"
    },
    {
      "name": "W5430",
      "description": "Bitten or struck by dog at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5431",
      "description": "Bitten or struck by dog at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5432",
      "description": "Bitten or struck by dog at sports and athletics area While working for income"
    },
    {
      "name": "W5433",
      "description": "Bitten or struck by dog at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5434",
      "description": "Bitten or struck by dog at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5438",
      "description": "Bitten or struck by dog at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5439",
      "description": "Bitten or struck by dog at sports and athletics area During unspecified activity"
    },
    {
      "name": "W544",
      "description": "Bitten or struck by dog at street and highway"
    },
    {
      "name": "W5440",
      "description": "Bitten or struck by dog at street and highway While engaged in sports activity"
    },
    {
      "name": "W5441",
      "description": "Bitten or struck by dog at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5442",
      "description": "Bitten or struck by dog at street and highway While working for income"
    },
    {
      "name": "W5443",
      "description": "Bitten or struck by dog at street and highway While engaged in other types of work"
    },
    {
      "name": "W5444",
      "description": "Bitten or struck by dog at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5448",
      "description": "Bitten or struck by dog at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5449",
      "description": "Bitten or struck by dog at street and highway During unspecified activity"
    },
    {
      "name": "W545",
      "description": "Bitten or struck by dog at trade and service area"
    },
    {
      "name": "W5450",
      "description": "Bitten or struck by dog at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5451",
      "description": "Bitten or struck by dog at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5452",
      "description": "Bitten or struck by dog at trade and service area While working for income"
    },
    {
      "name": "W5453",
      "description": "Bitten or struck by dog at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5454",
      "description": "Bitten or struck by dog at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5458",
      "description": "Bitten or struck by dog at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5459",
      "description": "Bitten or struck by dog at trade and service area During unspecified activity"
    },
    {
      "name": "W546",
      "description": "Bitten or struck by dog at industrial and construction area"
    },
    {
      "name": "W5460",
      "description": "Bitten or struck by dog at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5461",
      "description": "Bitten or struck by dog at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5462",
      "description": "Bitten or struck by dog at industrial and construction area While working for income"
    },
    {
      "name": "W5463",
      "description": "Bitten or struck by dog at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5464",
      "description": "Bitten or struck by dog at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5468",
      "description": "Bitten or struck by dog at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5469",
      "description": "Bitten or struck by dog at industrial and construction area During unspecified activity"
    },
    {
      "name": "W547",
      "description": "Bitten or struck by dog at farm"
    },
    {
      "name": "W5470",
      "description": "Bitten or struck by dog at farm While engaged in sports activity"
    },
    {
      "name": "W5471",
      "description": "Bitten or struck by dog at farm While engaged in leisure activity"
    },
    {
      "name": "W5472",
      "description": "Bitten or struck by dog at farm While working for income"
    },
    {
      "name": "W5473",
      "description": "Bitten or struck by dog at farm While engaged in other types of work"
    },
    {
      "name": "W5474",
      "description": "Bitten or struck by dog at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5478",
      "description": "Bitten or struck by dog at farm While engaged in other specified activities"
    },
    {
      "name": "W5479",
      "description": "Bitten or struck by dog at farm During unspecified activity"
    },
    {
      "name": "W548",
      "description": "Bitten or struck by dog at other specified places"
    },
    {
      "name": "W5480",
      "description": "Bitten or struck by dog at other specified places While engaged in sports activity"
    },
    {
      "name": "W5481",
      "description": "Bitten or struck by dog at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5482",
      "description": "Bitten or struck by dog at other specified places While working for income"
    },
    {
      "name": "W5483",
      "description": "Bitten or struck by dog at other specified places While engaged in other types of work"
    },
    {
      "name": "W5484",
      "description": "Bitten or struck by dog at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5488",
      "description": "Bitten or struck by dog at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5489",
      "description": "Bitten or struck by dog at other specified places During unspecified activity"
    },
    {
      "name": "W549",
      "description": "Bitten or struck by dog at unspecified place"
    },
    {
      "name": "W5490",
      "description": "Bitten or struck by dog at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5491",
      "description": "Bitten or struck by dog at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5492",
      "description": "Bitten or struck by dog at unspecified place While working for income"
    },
    {
      "name": "W5493",
      "description": "Bitten or struck by dog at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5494",
      "description": "Bitten or struck by dog at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5498",
      "description": "Bitten or struck by dog at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5499",
      "description": "Bitten or struck by dog at unspecified place During unspecified activity"
    },
    {
      "name": "W550",
      "description": "Bitten or struck by other mammals at home"
    },
    {
      "name": "W5500",
      "description": "Bitten or struck by other mammals at home While engaged in sports activity"
    },
    {
      "name": "W5501",
      "description": "Bitten or struck by other mammals at home While engaged in leisure activity"
    },
    {
      "name": "W5502",
      "description": "Bitten or struck by other mammals at home While working for income"
    },
    {
      "name": "W5503",
      "description": "Bitten or struck by other mammals at home While engaged in other types of work"
    },
    {
      "name": "W5504",
      "description": "Bitten or struck by other mammals at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5508",
      "description": "Bitten or struck by other mammals at home While engaged in other specified activities"
    },
    {
      "name": "W5509",
      "description": "Bitten or struck by other mammals at home During unspecified activity"
    },
    {
      "name": "W551",
      "description": "Bitten or struck by other mammals at residential institution"
    },
    {
      "name": "W5510",
      "description": "Bitten or struck by other mammals at residential institution While engaged in sports activity"
    },
    {
      "name": "W5511",
      "description": "Bitten or struck by other mammals at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5512",
      "description": "Bitten or struck by other mammals at residential institution While working for income"
    },
    {
      "name": "W5513",
      "description": "Bitten or struck by other mammals at residential institution While engaged in other types of work"
    },
    {
      "name": "W5514",
      "description": "Bitten or struck by other mammals at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5518",
      "description": "Bitten or struck by other mammals at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5519",
      "description": "Bitten or struck by other mammals at residential institution During unspecified activity"
    },
    {
      "name": "W552",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area"
    },
    {
      "name": "W5520",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5521",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5522",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5523",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5524",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5528",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5529",
      "description": "Bitten or struck by other mammals at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W553",
      "description": "Bitten or struck by other mammals at sports and athletics area"
    },
    {
      "name": "W5530",
      "description": "Bitten or struck by other mammals at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5531",
      "description": "Bitten or struck by other mammals at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5532",
      "description": "Bitten or struck by other mammals at sports and athletics area While working for income"
    },
    {
      "name": "W5533",
      "description": "Bitten or struck by other mammals at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5534",
      "description": "Bitten or struck by other mammals at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5538",
      "description": "Bitten or struck by other mammals at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5539",
      "description": "Bitten or struck by other mammals at sports and athletics area During unspecified activity"
    },
    {
      "name": "W554",
      "description": "Bitten or struck by other mammals at street and highway"
    },
    {
      "name": "W5540",
      "description": "Bitten or struck by other mammals at street and highway While engaged in sports activity"
    },
    {
      "name": "W5541",
      "description": "Bitten or struck by other mammals at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5542",
      "description": "Bitten or struck by other mammals at street and highway While working for income"
    },
    {
      "name": "W5543",
      "description": "Bitten or struck by other mammals at street and highway While engaged in other types of work"
    },
    {
      "name": "W5544",
      "description": "Bitten or struck by other mammals at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5548",
      "description": "Bitten or struck by other mammals at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5549",
      "description": "Bitten or struck by other mammals at street and highway During unspecified activity"
    },
    {
      "name": "W555",
      "description": "Bitten or struck by other mammals at trade and service area"
    },
    {
      "name": "W5550",
      "description": "Bitten or struck by other mammals at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5551",
      "description": "Bitten or struck by other mammals at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5552",
      "description": "Bitten or struck by other mammals at trade and service area While working for income"
    },
    {
      "name": "W5553",
      "description": "Bitten or struck by other mammals at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5554",
      "description": "Bitten or struck by other mammals at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5558",
      "description": "Bitten or struck by other mammals at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5559",
      "description": "Bitten or struck by other mammals at trade and service area During unspecified activity"
    },
    {
      "name": "W556",
      "description": "Bitten or struck by other mammals at industrial and construction area"
    },
    {
      "name": "W5560",
      "description": "Bitten or struck by other mammals at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5561",
      "description": "Bitten or struck by other mammals at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5562",
      "description": "Bitten or struck by other mammals at industrial and construction area While working for income"
    },
    {
      "name": "W5563",
      "description": "Bitten or struck by other mammals at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5564",
      "description": "Bitten or struck by other mammals at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5568",
      "description": "Bitten or struck by other mammals at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5569",
      "description": "Bitten or struck by other mammals at industrial and construction area During unspecified activity"
    },
    {
      "name": "W557",
      "description": "Bitten or struck by other mammals at farm"
    },
    {
      "name": "W5570",
      "description": "Bitten or struck by other mammals at farm While engaged in sports activity"
    },
    {
      "name": "W5571",
      "description": "Bitten or struck by other mammals at farm While engaged in leisure activity"
    },
    {
      "name": "W5572",
      "description": "Bitten or struck by other mammals at farm While working for income"
    },
    {
      "name": "W5573",
      "description": "Bitten or struck by other mammals at farm While engaged in other types of work"
    },
    {
      "name": "W5574",
      "description": "Bitten or struck by other mammals at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5578",
      "description": "Bitten or struck by other mammals at farm While engaged in other specified activities"
    },
    {
      "name": "W5579",
      "description": "Bitten or struck by other mammals at farm During unspecified activity"
    },
    {
      "name": "W558",
      "description": "Bitten or struck by other mammals at other specified places"
    },
    {
      "name": "W5580",
      "description": "Bitten or struck by other mammals at other specified places While engaged in sports activity"
    },
    {
      "name": "W5581",
      "description": "Bitten or struck by other mammals at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5582",
      "description": "Bitten or struck by other mammals at other specified places While working for income"
    },
    {
      "name": "W5583",
      "description": "Bitten or struck by other mammals at other specified places While engaged in other types of work"
    },
    {
      "name": "W5584",
      "description": "Bitten or struck by other mammals at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5588",
      "description": "Bitten or struck by other mammals at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5589",
      "description": "Bitten or struck by other mammals at other specified places During unspecified activity"
    },
    {
      "name": "W559",
      "description": "Bitten or struck by other mammals at unspecified place"
    },
    {
      "name": "W5590",
      "description": "Bitten or struck by other mammals at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5591",
      "description": "Bitten or struck by other mammals at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5592",
      "description": "Bitten or struck by other mammals at unspecified place While working for income"
    },
    {
      "name": "W5593",
      "description": "Bitten or struck by other mammals at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5594",
      "description": "Bitten or struck by other mammals at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5598",
      "description": "Bitten or struck by other mammals at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5599",
      "description": "Bitten or struck by other mammals at unspecified place During unspecified activity"
    },
    {
      "name": "W560",
      "description": "Contact with marine animal at home"
    },
    {
      "name": "W5600",
      "description": "Contact with marine animal at home While engaged in sports activity"
    },
    {
      "name": "W5601",
      "description": "Contact with marine animal at home While engaged in leisure activity"
    },
    {
      "name": "W5602",
      "description": "Contact with marine animal at home While working for income"
    },
    {
      "name": "W5603",
      "description": "Contact with marine animal at home While engaged in other types of work"
    },
    {
      "name": "W5604",
      "description": "Contact with marine animal at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5608",
      "description": "Contact with marine animal at home While engaged in other specified activities"
    },
    {
      "name": "W5609",
      "description": "Contact with marine animal at home During unspecified activity"
    },
    {
      "name": "W561",
      "description": "Contact with marine animal at residential institution"
    },
    {
      "name": "W5610",
      "description": "Contact with marine animal at residential institution While engaged in sports activity"
    },
    {
      "name": "W5611",
      "description": "Contact with marine animal at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5612",
      "description": "Contact with marine animal at residential institution While working for income"
    },
    {
      "name": "W5613",
      "description": "Contact with marine animal at residential institution While engaged in other types of work"
    },
    {
      "name": "W5614",
      "description": "Contact with marine animal at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5618",
      "description": "Contact with marine animal at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5619",
      "description": "Contact with marine animal at residential institution During unspecified activity"
    },
    {
      "name": "W562",
      "description": "Contact with marine animal at school,other institution and public administrative area"
    },
    {
      "name": "W5620",
      "description": "Contact with marine animal at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5621",
      "description": "Contact with marine animal at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5622",
      "description": "Contact with marine animal at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5623",
      "description": "Contact with marine animal at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5624",
      "description": "Contact with marine animal at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5628",
      "description": "Contact with marine animal at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5629",
      "description": "Contact with marine animal at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W563",
      "description": "Contact with marine animal at sports and athletics area"
    },
    {
      "name": "W5630",
      "description": "Contact with marine animal at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5631",
      "description": "Contact with marine animal at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5632",
      "description": "Contact with marine animal at sports and athletics area While working for income"
    },
    {
      "name": "W5633",
      "description": "Contact with marine animal at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5634",
      "description": "Contact with marine animal at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5638",
      "description": "Contact with marine animal at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5639",
      "description": "Contact with marine animal at sports and athletics area During unspecified activity"
    },
    {
      "name": "W564",
      "description": "Contact with marine animal at street and highway"
    },
    {
      "name": "W5640",
      "description": "Contact with marine animal at street and highway While engaged in sports activity"
    },
    {
      "name": "W5641",
      "description": "Contact with marine animal at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5642",
      "description": "Contact with marine animal at street and highway While working for income"
    },
    {
      "name": "W5643",
      "description": "Contact with marine animal at street and highway While engaged in other types of work"
    },
    {
      "name": "W5644",
      "description": "Contact with marine animal at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5648",
      "description": "Contact with marine animal at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5649",
      "description": "Contact with marine animal at street and highway During unspecified activity"
    },
    {
      "name": "W565",
      "description": "Contact with marine animal at trade and service area"
    },
    {
      "name": "W5650",
      "description": "Contact with marine animal at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5651",
      "description": "Contact with marine animal at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5652",
      "description": "Contact with marine animal at trade and service area While working for income"
    },
    {
      "name": "W5653",
      "description": "Contact with marine animal at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5654",
      "description": "Contact with marine animal at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5658",
      "description": "Contact with marine animal at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5659",
      "description": "Contact with marine animal at trade and service area During unspecified activity"
    },
    {
      "name": "W566",
      "description": "Contact with marine animal at industrial and construction area"
    },
    {
      "name": "W5660",
      "description": "Contact with marine animal at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5661",
      "description": "Contact with marine animal at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5662",
      "description": "Contact with marine animal at industrial and construction area While working for income"
    },
    {
      "name": "W5663",
      "description": "Contact with marine animal at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5664",
      "description": "Contact with marine animal at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5668",
      "description": "Contact with marine animal at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5669",
      "description": "Contact with marine animal at industrial and construction area During unspecified activity"
    },
    {
      "name": "W567",
      "description": "Contact with marine animal at farm"
    },
    {
      "name": "W5670",
      "description": "Contact with marine animal at farm While engaged in sports activity"
    },
    {
      "name": "W5671",
      "description": "Contact with marine animal at farm While engaged in leisure activity"
    },
    {
      "name": "W5672",
      "description": "Contact with marine animal at farm While working for income"
    },
    {
      "name": "W5673",
      "description": "Contact with marine animal at farm While engaged in other types of work"
    },
    {
      "name": "W5674",
      "description": "Contact with marine animal at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5678",
      "description": "Contact with marine animal at farm While engaged in other specified activities"
    },
    {
      "name": "W5679",
      "description": "Contact with marine animal at farm During unspecified activity"
    },
    {
      "name": "W568",
      "description": "Contact with marine animal at other specified places"
    },
    {
      "name": "W5680",
      "description": "Contact with marine animal at other specified places While engaged in sports activity"
    },
    {
      "name": "W5681",
      "description": "Contact with marine animal at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5682",
      "description": "Contact with marine animal at other specified places While working for income"
    },
    {
      "name": "W5683",
      "description": "Contact with marine animal at other specified places While engaged in other types of work"
    },
    {
      "name": "W5684",
      "description": "Contact with marine animal at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5688",
      "description": "Contact with marine animal at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5689",
      "description": "Contact with marine animal at other specified places During unspecified activity"
    },
    {
      "name": "W569",
      "description": "Contact with marine animal at unspecified place"
    },
    {
      "name": "W5690",
      "description": "Contact with marine animal at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5691",
      "description": "Contact with marine animal at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5692",
      "description": "Contact with marine animal at unspecified place While working for income"
    },
    {
      "name": "W5693",
      "description": "Contact with marine animal at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5694",
      "description": "Contact with marine animal at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5698",
      "description": "Contact with marine animal at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5699",
      "description": "Contact with marine animal at unspecified place During unspecified activity"
    },
    {
      "name": "W570",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home"
    },
    {
      "name": "W5700",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While engaged in sports activity"
    },
    {
      "name": "W5701",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While engaged in leisure activity"
    },
    {
      "name": "W5702",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While working for income"
    },
    {
      "name": "W5703",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While engaged in other types of work"
    },
    {
      "name": "W5704",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5708",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home While engaged in other specified activities"
    },
    {
      "name": "W5709",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at home During unspecified activity"
    },
    {
      "name": "W571",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution"
    },
    {
      "name": "W5710",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While engaged in sports activity"
    },
    {
      "name": "W5711",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5712",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While working for income"
    },
    {
      "name": "W5713",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While engaged in other types of work"
    },
    {
      "name": "W5714",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5718",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5719",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at residential institution During unspecified activity"
    },
    {
      "name": "W572",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area"
    },
    {
      "name": "W5720",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5721",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5722",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5723",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5724",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5728",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5729",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W573",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area"
    },
    {
      "name": "W5730",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5731",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5732",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While working for income"
    },
    {
      "name": "W5733",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5734",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5738",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5739",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at sports and athletics area During unspecified activity"
    },
    {
      "name": "W574",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway"
    },
    {
      "name": "W5740",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While engaged in sports activity"
    },
    {
      "name": "W5741",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5742",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While working for income"
    },
    {
      "name": "W5743",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While engaged in other types of work"
    },
    {
      "name": "W5744",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5748",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5749",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at street and highway During unspecified activity"
    },
    {
      "name": "W575",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area"
    },
    {
      "name": "W5750",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5751",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5752",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While working for income"
    },
    {
      "name": "W5753",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5754",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5758",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5759",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at trade and service area During unspecified activity"
    },
    {
      "name": "W576",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area"
    },
    {
      "name": "W5760",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5761",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5762",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While working for income"
    },
    {
      "name": "W5763",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5764",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5768",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5769",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at industrial and construction area During unspecified activity"
    },
    {
      "name": "W577",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm"
    },
    {
      "name": "W5770",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While engaged in sports activity"
    },
    {
      "name": "W5771",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While engaged in leisure activity"
    },
    {
      "name": "W5772",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While working for income"
    },
    {
      "name": "W5773",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While engaged in other types of work"
    },
    {
      "name": "W5774",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5778",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm While engaged in other specified activities"
    },
    {
      "name": "W5779",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at farm During unspecified activity"
    },
    {
      "name": "W578",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places"
    },
    {
      "name": "W5780",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While engaged in sports activity"
    },
    {
      "name": "W5781",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5782",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While working for income"
    },
    {
      "name": "W5783",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While engaged in other types of work"
    },
    {
      "name": "W5784",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5788",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5789",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at other specified places During unspecified activity"
    },
    {
      "name": "W579",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place"
    },
    {
      "name": "W5790",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5791",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5792",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While working for income"
    },
    {
      "name": "W5793",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5794",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5798",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5799",
      "description": "Bitten or stung by nonvenomous insect and other nonvenomous arthropods at unspecified place During unspecified activity"
    },
    {
      "name": "W580",
      "description": "Bitten or struck by crocodile or alligator at home"
    },
    {
      "name": "W5800",
      "description": "Bitten or struck by crocodile or alligator at home While engaged in sports activity"
    },
    {
      "name": "W5801",
      "description": "Bitten or struck by crocodile or alligator at home While engaged in leisure activity"
    },
    {
      "name": "W5802",
      "description": "Bitten or struck by crocodile or alligator at home While working for income"
    },
    {
      "name": "W5803",
      "description": "Bitten or struck by crocodile or alligator at home While engaged in other types of work"
    },
    {
      "name": "W5804",
      "description": "Bitten or struck by crocodile or alligator at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5808",
      "description": "Bitten or struck by crocodile or alligator at home While engaged in other specified activities"
    },
    {
      "name": "W5809",
      "description": "Bitten or struck by crocodile or alligator at home During unspecified activity"
    },
    {
      "name": "W581",
      "description": "Bitten or struck by crocodile or alligator at residential institution"
    },
    {
      "name": "W5810",
      "description": "Bitten or struck by crocodile or alligator at residential institution While engaged in sports activity"
    },
    {
      "name": "W5811",
      "description": "Bitten or struck by crocodile or alligator at residential institution While engaged in leisure activity"
    },
    {
      "name": "W5812",
      "description": "Bitten or struck by crocodile or alligator at residential institution While working for income"
    },
    {
      "name": "W5813",
      "description": "Bitten or struck by crocodile or alligator at residential institution While engaged in other types of work"
    },
    {
      "name": "W5814",
      "description": "Bitten or struck by crocodile or alligator at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5818",
      "description": "Bitten or struck by crocodile or alligator at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5819",
      "description": "Bitten or struck by crocodile or alligator at residential institution During unspecified activity"
    },
    {
      "name": "W582",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area"
    },
    {
      "name": "W5820",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5821",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5822",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5823",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5824",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5828",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5829",
      "description": "Bitten or struck by crocodile or alligator at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W583",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area"
    },
    {
      "name": "W5830",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5831",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5832",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While working for income"
    },
    {
      "name": "W5833",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5834",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5838",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5839",
      "description": "Bitten or struck by crocodile or alligator at sports and athletics area During unspecified activity"
    },
    {
      "name": "W584",
      "description": "Bitten or struck by crocodile or alligator at street and highway"
    },
    {
      "name": "W5840",
      "description": "Bitten or struck by crocodile or alligator at street and highway While engaged in sports activity"
    },
    {
      "name": "W5841",
      "description": "Bitten or struck by crocodile or alligator at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5842",
      "description": "Bitten or struck by crocodile or alligator at street and highway While working for income"
    },
    {
      "name": "W5843",
      "description": "Bitten or struck by crocodile or alligator at street and highway While engaged in other types of work"
    },
    {
      "name": "W5844",
      "description": "Bitten or struck by crocodile or alligator at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5848",
      "description": "Bitten or struck by crocodile or alligator at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5849",
      "description": "Bitten or struck by crocodile or alligator at street and highway During unspecified activity"
    },
    {
      "name": "W585",
      "description": "Bitten or struck by crocodile or alligator at trade and service area"
    },
    {
      "name": "W5850",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5851",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5852",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While working for income"
    },
    {
      "name": "W5853",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5854",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5858",
      "description": "Bitten or struck by crocodile or alligator at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5859",
      "description": "Bitten or struck by crocodile or alligator at trade and service area During unspecified activity"
    },
    {
      "name": "W586",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area"
    },
    {
      "name": "W5860",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5861",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5862",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While working for income"
    },
    {
      "name": "W5863",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5864",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5868",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5869",
      "description": "Bitten or struck by crocodile or alligator at industrial and construction area During unspecified activity"
    },
    {
      "name": "W587",
      "description": "Bitten or struck by crocodile or alligator at farm"
    },
    {
      "name": "W5870",
      "description": "Bitten or struck by crocodile or alligator at farm While engaged in sports activity"
    },
    {
      "name": "W5871",
      "description": "Bitten or struck by crocodile or alligator at farm While engaged in leisure activity"
    },
    {
      "name": "W5872",
      "description": "Bitten or struck by crocodile or alligator at farm While working for income"
    },
    {
      "name": "W5873",
      "description": "Bitten or struck by crocodile or alligator at farm While engaged in other types of work"
    },
    {
      "name": "W5874",
      "description": "Bitten or struck by crocodile or alligator at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5878",
      "description": "Bitten or struck by crocodile or alligator at farm While engaged in other specified activities"
    },
    {
      "name": "W5879",
      "description": "Bitten or struck by crocodile or alligator at farm During unspecified activity"
    },
    {
      "name": "W588",
      "description": "Bitten or struck by crocodile or alligator at other specified places"
    },
    {
      "name": "W5880",
      "description": "Bitten or struck by crocodile or alligator at other specified places While engaged in sports activity"
    },
    {
      "name": "W5881",
      "description": "Bitten or struck by crocodile or alligator at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5882",
      "description": "Bitten or struck by crocodile or alligator at other specified places While working for income"
    },
    {
      "name": "W5883",
      "description": "Bitten or struck by crocodile or alligator at other specified places While engaged in other types of work"
    },
    {
      "name": "W5884",
      "description": "Bitten or struck by crocodile or alligator at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5888",
      "description": "Bitten or struck by crocodile or alligator at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5889",
      "description": "Bitten or struck by crocodile or alligator at other specified places During unspecified activity"
    },
    {
      "name": "W589",
      "description": "Bitten or struck by crocodile or alligator at unspecified place"
    },
    {
      "name": "W5890",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5891",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5892",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While working for income"
    },
    {
      "name": "W5893",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5894",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5898",
      "description": "Bitten or struck by crocodile or alligator at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5899",
      "description": "Bitten or struck by crocodile or alligator at unspecified place During unspecified activity"
    },
    {
      "name": "W590",
      "description": "Bitten or crushed by other reptiles at home"
    },
    {
      "name": "W5900",
      "description": "Bitten or crushed by other reptiles at home While engaged in sports activity"
    },
    {
      "name": "W5901",
      "description": "Bitten or crushed by other reptiles at home While engaged in leisure activity"
    },
    {
      "name": "W5902",
      "description": "Bitten or crushed by other reptiles at home While working for income"
    },
    {
      "name": "W5903",
      "description": "Bitten or crushed by other reptiles at home While engaged in other types of work"
    },
    {
      "name": "W5904",
      "description": "Bitten or crushed by other reptiles at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5908",
      "description": "Bitten or crushed by other reptiles at home While engaged in other specified activities"
    },
    {
      "name": "W5909",
      "description": "Bitten or crushed by other reptiles at home During unspecified activity"
    },
    {
      "name": "W591",
      "description": "Bitten or crushed by other reptiles at residential institution"
    },
    {
      "name": "W5910",
      "description": "Bitten or crushed by other reptiles at residential institution While engaged in sports activity"
    },
    {
      "name": "W5911",
      "description": "Bitten or crushed by other reptiles at residential institution  While engaged in leisure activity"
    },
    {
      "name": "W5912",
      "description": "Bitten or crushed by other reptiles at residential institution While working for income"
    },
    {
      "name": "W5913",
      "description": "Bitten or crushed by other reptiles at residential institution While engaged in other types of work"
    },
    {
      "name": "W5914",
      "description": "Bitten or crushed by other reptiles at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5918",
      "description": "Bitten or crushed by other reptiles at residential institution While engaged in other specified activities"
    },
    {
      "name": "W5919",
      "description": "Bitten or crushed by other reptiles at residential institution During unspecified activity"
    },
    {
      "name": "W592",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area"
    },
    {
      "name": "W5920",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W5921",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W5922",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W5923",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W5924",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5928",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W5929",
      "description": "Bitten or crushed by other reptiles at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W593",
      "description": "Bitten or crushed by other reptiles at sports and athletics area"
    },
    {
      "name": "W5930",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W5931",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W5932",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While working for income"
    },
    {
      "name": "W5933",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W5934",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5938",
      "description": "Bitten or crushed by other reptiles at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W5939",
      "description": "Bitten or crushed by other reptiles at sports and athletics area During unspecified activity"
    },
    {
      "name": "W594",
      "description": "Bitten or crushed by other reptiles at street and highway"
    },
    {
      "name": "W5940",
      "description": "Bitten or crushed by other reptiles at street and highway While engaged in sports activity"
    },
    {
      "name": "W5941",
      "description": "Bitten or crushed by other reptiles at street and highway While engaged in leisure activity"
    },
    {
      "name": "W5942",
      "description": "Bitten or crushed by other reptiles at street and highway While working for income"
    },
    {
      "name": "W5943",
      "description": "Bitten or crushed by other reptiles at street and highway While engaged in other types of work"
    },
    {
      "name": "W5944",
      "description": "Bitten or crushed by other reptiles at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5948",
      "description": "Bitten or crushed by other reptiles at street and highway While engaged in other specified activities"
    },
    {
      "name": "W5949",
      "description": "Bitten or crushed by other reptiles at street and highway During unspecified activity"
    },
    {
      "name": "W595",
      "description": "Bitten or crushed by other reptiles at trade and service area"
    },
    {
      "name": "W5950",
      "description": "Bitten or crushed by other reptiles at trade and service area While engaged in sports activity"
    },
    {
      "name": "W5951",
      "description": "Bitten or crushed by other reptiles at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W5952",
      "description": "Bitten or crushed by other reptiles at trade and service area While working for income"
    },
    {
      "name": "W5953",
      "description": "Bitten or crushed by other reptiles at trade and service area While engaged in other types of work"
    },
    {
      "name": "W5954",
      "description": "Bitten or crushed by other reptiles at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5958",
      "description": "Bitten or crushed by other reptiles at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W5959",
      "description": "Bitten or crushed by other reptiles at trade and service area During unspecified activity"
    },
    {
      "name": "W596",
      "description": "Bitten or crushed by other reptiles at industrial and construction area"
    },
    {
      "name": "W5960",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W5961",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W5962",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While working for income"
    },
    {
      "name": "W5963",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W5964",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5968",
      "description": "Bitten or crushed by other reptiles at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W5969",
      "description": "Bitten or crushed by other reptiles at industrial and construction area During unspecified activity"
    },
    {
      "name": "W597",
      "description": "Bitten or crushed by other reptiles at farm"
    },
    {
      "name": "W5970",
      "description": "Bitten or crushed by other reptiles at farm While engaged in sports activity"
    },
    {
      "name": "W5971",
      "description": "Bitten or crushed by other reptiles at farm While engaged in leisure activity"
    },
    {
      "name": "W5972",
      "description": "Bitten or crushed by other reptiles at farm While working for income"
    },
    {
      "name": "W5973",
      "description": "Bitten or crushed by other reptiles at farm While engaged in other types of work"
    },
    {
      "name": "W5974",
      "description": "Bitten or crushed by other reptiles at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5978",
      "description": "Bitten or crushed by other reptiles at farm While engaged in other specified activities"
    },
    {
      "name": "W5979",
      "description": "Bitten or crushed by other reptiles at farm During unspecified activity"
    },
    {
      "name": "W598",
      "description": "Bitten or crushed by other reptiles at other specified places"
    },
    {
      "name": "W5980",
      "description": "Bitten or crushed by other reptiles at other specified places While engaged in sports activity"
    },
    {
      "name": "W5981",
      "description": "Bitten or crushed by other reptiles at other specified places While engaged in leisure activity"
    },
    {
      "name": "W5982",
      "description": "Bitten or crushed by other reptiles at other specified places While working for income"
    },
    {
      "name": "W5983",
      "description": "Bitten or crushed by other reptiles at other specified places While engaged in other types of work"
    },
    {
      "name": "W5984",
      "description": "Bitten or crushed by other reptiles at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5988",
      "description": "Bitten or crushed by other reptiles at other specified places While engaged in other specified activities"
    },
    {
      "name": "W5989",
      "description": "Bitten or crushed by other reptiles at other specified places During unspecified activity"
    },
    {
      "name": "W599",
      "description": "Bitten or crushed by other reptiles at unspecified place"
    },
    {
      "name": "W5990",
      "description": "Bitten or crushed by other reptiles at unspecified place While engaged in sports activity"
    },
    {
      "name": "W5991",
      "description": "Bitten or crushed by other reptiles at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W5992",
      "description": "Bitten or crushed by other reptiles at unspecified place While working for income"
    },
    {
      "name": "W5993",
      "description": "Bitten or crushed by other reptiles at unspecified place While engaged in other types of work"
    },
    {
      "name": "W5994",
      "description": "Bitten or crushed by other reptiles at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W5998",
      "description": "Bitten or crushed by other reptiles at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W5999",
      "description": "Bitten or crushed by other reptiles at unspecified place During unspecified activity"
    },
    {
      "name": "W600",
      "description": "Contact with plant thorns and spines and sharp leaves at home"
    },
    {
      "name": "W6000",
      "description": "Contact with plant thorns and spines and sharp leaves at home While engaged in sports activity"
    },
    {
      "name": "W6001",
      "description": "Contact with plant thorns and spines and sharp leaves at home While engaged in leisure activity"
    },
    {
      "name": "W6002",
      "description": "Contact with plant thorns and spines and sharp leaves at home While working for income"
    },
    {
      "name": "W6003",
      "description": "Contact with plant thorns and spines and sharp leaves at home While engaged in other types of work"
    },
    {
      "name": "W6004",
      "description": "Contact with plant thorns and spines and sharp leaves at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6008",
      "description": "Contact with plant thorns and spines and sharp leaves at home While engaged in other specified activities"
    },
    {
      "name": "W6009",
      "description": "Contact with plant thorns and spines and sharp leaves at home During unspecified activity"
    },
    {
      "name": "W601",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution"
    },
    {
      "name": "W6010",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While engaged in sports activity"
    },
    {
      "name": "W6011",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6012",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While working for income"
    },
    {
      "name": "W6013",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While engaged in other types of work"
    },
    {
      "name": "W6014",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6018",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6019",
      "description": "Contact with plant thorns and spines and sharp leaves at residential institution During unspecified activity"
    },
    {
      "name": "W602",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area"
    },
    {
      "name": "W6020",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6021",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6022",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6023",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6024",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6028",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6029",
      "description": "Contact with plant thorns and spines and sharp leaves at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W603",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area"
    },
    {
      "name": "W6030",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6031",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6032",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While working for income"
    },
    {
      "name": "W6033",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6034",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6038",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6039",
      "description": "Contact with plant thorns and spines and sharp leaves at sports and athletics area During unspecified activity"
    },
    {
      "name": "W604",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway"
    },
    {
      "name": "W6040",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While engaged in sports activity"
    },
    {
      "name": "W6041",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6042",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While working for income"
    },
    {
      "name": "W6043",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While engaged in other types of work"
    },
    {
      "name": "W6044",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6048",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6049",
      "description": "Contact with plant thorns and spines and sharp leaves at street and highway During unspecified activity"
    },
    {
      "name": "W605",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area"
    },
    {
      "name": "W6050",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6051",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6052",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While working for income"
    },
    {
      "name": "W6053",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6054",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6058",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6059",
      "description": "Contact with plant thorns and spines and sharp leaves at trade and service area During unspecified activity"
    },
    {
      "name": "W606",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area"
    },
    {
      "name": "W6060",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6061",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6062",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While working for income"
    },
    {
      "name": "W6063",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6064",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6068",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6069",
      "description": "Contact with plant thorns and spines and sharp leaves at industrial and construction area During unspecified activity"
    },
    {
      "name": "W607",
      "description": "Contact with plant thorns and spines and sharp leaves at farm"
    },
    {
      "name": "W6070",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While engaged in sports activity"
    },
    {
      "name": "W6071",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While engaged in leisure activity"
    },
    {
      "name": "W6072",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While working for income"
    },
    {
      "name": "W6073",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While engaged in other types of work"
    },
    {
      "name": "W6074",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6078",
      "description": "Contact with plant thorns and spines and sharp leaves at farm While engaged in other specified activities"
    },
    {
      "name": "W6079",
      "description": "Contact with plant thorns and spines and sharp leaves at farm During unspecified activity"
    },
    {
      "name": "W608",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places"
    },
    {
      "name": "W6080",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While engaged in sports activity"
    },
    {
      "name": "W6081",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6082",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While working for income"
    },
    {
      "name": "W6083",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While engaged in other types of work"
    },
    {
      "name": "W6084",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6088",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6089",
      "description": "Contact with plant thorns and spines and sharp leaves at other specified places During unspecified activity"
    },
    {
      "name": "W609",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place"
    },
    {
      "name": "W6090",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6091",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6092",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While working for income"
    },
    {
      "name": "W6093",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6094",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6098",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6099",
      "description": "Contact with plant thorns and spines and sharp leaves at unspecified place During unspecified activity"
    },
    {
      "name": "W640",
      "description": "Exposure to other and unspecified animate mechanical forces at home"
    },
    {
      "name": "W6400",
      "description": "Exposure to other and unspecified animate mechanical forces at home While engaged in sports activity"
    },
    {
      "name": "W6401",
      "description": "Exposure to other and unspecified animate mechanical forces at home While engaged in leisure activity"
    },
    {
      "name": "W6402",
      "description": "Exposure to other and unspecified animate mechanical forces at home While working for income"
    },
    {
      "name": "W6403",
      "description": "Exposure to other and unspecified animate mechanical forces at home While engaged in other types of work"
    },
    {
      "name": "W6404",
      "description": "Exposure to other and unspecified animate mechanical forces at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6408",
      "description": "Exposure to other and unspecified animate mechanical forces at home While engaged in other specified activities"
    },
    {
      "name": "W6409",
      "description": "Exposure to other and unspecified animate mechanical forces at home During unspecified activity"
    },
    {
      "name": "W641",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution"
    },
    {
      "name": "W6410",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While engaged in sports activity"
    },
    {
      "name": "W6411",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6412",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While working for income"
    },
    {
      "name": "W6413",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While engaged in other types of work"
    },
    {
      "name": "W6414",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6418",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6419",
      "description": "Exposure to other and unspecified animate mechanical forces at residential institution During unspecified activity"
    },
    {
      "name": "W642",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area"
    },
    {
      "name": "W6420",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6421",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6422",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6423",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6424",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6428",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6429",
      "description": "Exposure to other and unspecified animate mechanical forces at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W643",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area"
    },
    {
      "name": "W6430",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6431",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6432",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While working for income"
    },
    {
      "name": "W6433",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6434",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6438",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6439",
      "description": "Exposure to other and unspecified animate mechanical forces at sports and athletics area During unspecified activity"
    },
    {
      "name": "W644",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway"
    },
    {
      "name": "W6440",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While engaged in sports activity"
    },
    {
      "name": "W6441",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6442",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While working for income"
    },
    {
      "name": "W6443",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While engaged in other types of work"
    },
    {
      "name": "W6444",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6448",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6449",
      "description": "Exposure to other and unspecified animate mechanical forces at street and highway During unspecified activity"
    },
    {
      "name": "W645",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area"
    },
    {
      "name": "W6450",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6451",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6452",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While working for income"
    },
    {
      "name": "W6453",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6454",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6458",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6459",
      "description": "Exposure to other and unspecified animate mechanical forces at trade and service area During unspecified activity"
    },
    {
      "name": "W646",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area"
    },
    {
      "name": "W6460",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6461",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6462",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While working for income"
    },
    {
      "name": "W6463",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6464",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6468",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6469",
      "description": "Exposure to other and unspecified animate mechanical forces at industrial and construction area During unspecified activity"
    },
    {
      "name": "W647",
      "description": "Exposure to other and unspecified animate mechanical forces at farm"
    },
    {
      "name": "W6470",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While engaged in sports activity"
    },
    {
      "name": "W6471",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While engaged in leisure activity"
    },
    {
      "name": "W6472",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While working for income"
    },
    {
      "name": "W6473",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While engaged in other types of work"
    },
    {
      "name": "W6474",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6478",
      "description": "Exposure to other and unspecified animate mechanical forces at farm While engaged in other specified activities"
    },
    {
      "name": "W6479",
      "description": "Exposure to other and unspecified animate mechanical forces at farm During unspecified activity"
    },
    {
      "name": "W648",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places"
    },
    {
      "name": "W6480",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While engaged in sports activity"
    },
    {
      "name": "W6481",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6482",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While working for income"
    },
    {
      "name": "W6483",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While engaged in other types of work"
    },
    {
      "name": "W6484",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6488",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6489",
      "description": "Exposure to other and unspecified animate mechanical forces at other specified places During unspecified activity"
    },
    {
      "name": "W649",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place"
    },
    {
      "name": "W6490",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6491",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6492",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While working for income"
    },
    {
      "name": "W6493",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6494",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6498",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6499",
      "description": "Exposure to other and unspecified animate mechanical forces at unspecified place During unspecified activity"
    },
    {
      "name": "W650",
      "description": "Drowning and submersion while in bath-tub at home"
    },
    {
      "name": "W6500",
      "description": "Drowning and submersion while in bath-tub at home While engaged in sports activity"
    },
    {
      "name": "W6501",
      "description": "Drowning and submersion while in bath-tub at home While engaged in leisure activity"
    },
    {
      "name": "W6502",
      "description": "Drowning and submersion while in bath-tub at home While working for income"
    },
    {
      "name": "W6503",
      "description": "Drowning and submersion while in bath-tub at home While engaged in other types of work"
    },
    {
      "name": "W6504",
      "description": "Drowning and submersion while in bath-tub at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6508",
      "description": "Drowning and submersion while in bath-tub at home While engaged in other specified activities"
    },
    {
      "name": "W6509",
      "description": "Drowning and submersion while in bath-tub at home During unspecified activity"
    },
    {
      "name": "W651",
      "description": "Drowning and submersion while in bath-tub at residential institution"
    },
    {
      "name": "W6510",
      "description": "Drowning and submersion while in bath-tub at residential institution While engaged in sports activity"
    },
    {
      "name": "W6511",
      "description": "Drowning and submersion while in bath-tub at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6512",
      "description": "Drowning and submersion while in bath-tub at residential institution While working for income"
    },
    {
      "name": "W6513",
      "description": "Drowning and submersion while in bath-tub at residential institution While engaged in other types of work"
    },
    {
      "name": "W6514",
      "description": "Drowning and submersion while in bath-tub at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6518",
      "description": "Drowning and submersion while in bath-tub at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6519",
      "description": "Drowning and submersion while in bath-tub at residential institution During unspecified activity"
    },
    {
      "name": "W652",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area"
    },
    {
      "name": "W6520",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6521",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6522",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6523",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6524",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6528",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6529",
      "description": "Drowning and submersion while in bath-tub at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W653",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area"
    },
    {
      "name": "W6530",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6531",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6532",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While working for income"
    },
    {
      "name": "W6533",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6534",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6538",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6539",
      "description": "Drowning and submersion while in bath-tub at sports and athletics area During unspecified activity"
    },
    {
      "name": "W654",
      "description": "Drowning and submersion while in bath-tub at street and highway"
    },
    {
      "name": "W6540",
      "description": "Drowning and submersion while in bath-tub at street and highway While engaged in sports activity"
    },
    {
      "name": "W6541",
      "description": "Drowning and submersion while in bath-tub at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6542",
      "description": "Drowning and submersion while in bath-tub at street and highway While working for income"
    },
    {
      "name": "W6543",
      "description": "Drowning and submersion while in bath-tub at street and highway While engaged in other types of work"
    },
    {
      "name": "W6544",
      "description": "Drowning and submersion while in bath-tub at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6548",
      "description": "Drowning and submersion while in bath-tub at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6549",
      "description": "Drowning and submersion while in bath-tub at street and highway During unspecified activity"
    },
    {
      "name": "W655",
      "description": "Drowning and submersion while in bath-tub at trade and service area"
    },
    {
      "name": "W6550",
      "description": "Drowning and submersion while in bath-tub at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6551",
      "description": "Drowning and submersion while in bath-tub at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6552",
      "description": "Drowning and submersion while in bath-tub at trade and service area While working for income"
    },
    {
      "name": "W6553",
      "description": "Drowning and submersion while in bath-tub at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6554",
      "description": "Drowning and submersion while in bath-tub at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6558",
      "description": "Drowning and submersion while in bath-tub at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6559",
      "description": "Drowning and submersion while in bath-tub at trade and service area During unspecified activity"
    },
    {
      "name": "W656",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area"
    },
    {
      "name": "W6560",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6561",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6562",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While working for income"
    },
    {
      "name": "W6563",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6564",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6568",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6569",
      "description": "Drowning and submersion while in bath-tub at industrial and construction area During unspecified activity"
    },
    {
      "name": "W657",
      "description": "Drowning and submersion while in bath-tub at farm"
    },
    {
      "name": "W6570",
      "description": "Drowning and submersion while in bath-tub at farm While engaged in sports activity"
    },
    {
      "name": "W6571",
      "description": "Drowning and submersion while in bath-tub at farm While engaged in leisure activity"
    },
    {
      "name": "W6572",
      "description": "Drowning and submersion while in bath-tub at farm While working for income"
    },
    {
      "name": "W6573",
      "description": "Drowning and submersion while in bath-tub at farm While engaged in other types of work"
    },
    {
      "name": "W6574",
      "description": "Drowning and submersion while in bath-tub at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6578",
      "description": "Drowning and submersion while in bath-tub at farm While engaged in other specified activities"
    },
    {
      "name": "W6579",
      "description": "Drowning and submersion while in bath-tub at farm During unspecified activity"
    },
    {
      "name": "W658",
      "description": "Drowning and submersion while in bath-tub at other specified places"
    },
    {
      "name": "W6580",
      "description": "Drowning and submersion while in bath-tub at other specified places While engaged in sports activity"
    },
    {
      "name": "W6581",
      "description": "Drowning and submersion while in bath-tub at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6582",
      "description": "Drowning and submersion while in bath-tub at other specified places While working for income"
    },
    {
      "name": "W6583",
      "description": "Drowning and submersion while in bath-tub at other specified places While engaged in other types of work"
    },
    {
      "name": "W6584",
      "description": "Drowning and submersion while in bath-tub at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6588",
      "description": "Drowning and submersion while in bath-tub at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6589",
      "description": "Drowning and submersion while in bath-tub at other specified places During unspecified activity"
    },
    {
      "name": "W659",
      "description": "Drowning and submersion while in bath-tub at unspecified place"
    },
    {
      "name": "W6590",
      "description": "Drowning and submersion while in bath-tub at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6591",
      "description": "Drowning and submersion while in bath-tub at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6592",
      "description": "Drowning and submersion while in bath-tub at unspecified place While working for income"
    },
    {
      "name": "W6593",
      "description": "Drowning and submersion while in bath-tub at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6594",
      "description": "Drowning and submersion while in bath-tub at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6598",
      "description": "Drowning and submersion while in bath-tub at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6599",
      "description": "Drowning and submersion while in bath-tub at unspecified place During unspecified activity"
    },
    {
      "name": "W660",
      "description": "Drowning and submersion following fall into bath-tub at home"
    },
    {
      "name": "W6600",
      "description": "Drowning and submersion following fall into bath-tub at home While engaged in sports activity"
    },
    {
      "name": "W6601",
      "description": "Drowning and submersion following fall into bath-tub at home While engaged in leisure activity"
    },
    {
      "name": "W6602",
      "description": "Drowning and submersion following fall into bath-tub at home While working for income"
    },
    {
      "name": "W6603",
      "description": "Drowning and submersion following fall into bath-tub at home While engaged in other types of work"
    },
    {
      "name": "W6604",
      "description": "Drowning and submersion following fall into bath-tub at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6608",
      "description": "Drowning and submersion following fall into bath-tub at home While engaged in other specified activities"
    },
    {
      "name": "W6609",
      "description": "Drowning and submersion following fall into bath-tub at home During unspecified activity"
    },
    {
      "name": "W661",
      "description": "Drowning and submersion following fall into bath-tub at residential institution"
    },
    {
      "name": "W6610",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While engaged in sports activity"
    },
    {
      "name": "W6611",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6612",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While working for income"
    },
    {
      "name": "W6613",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While engaged in other types of work"
    },
    {
      "name": "W6614",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6618",
      "description": "Drowning and submersion following fall into bath-tub at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6619",
      "description": "Drowning and submersion following fall into bath-tub at residential institution During unspecified activity"
    },
    {
      "name": "W662",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area"
    },
    {
      "name": "W6620",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6621",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6622",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6623",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6624",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6628",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6629",
      "description": "Drowning and submersion following fall into bath-tub at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W663",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area"
    },
    {
      "name": "W6630",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6631",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6632",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While working for income"
    },
    {
      "name": "W6633",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6634",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6638",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6639",
      "description": "Drowning and submersion following fall into bath-tub at sports and athletics area During unspecified activity"
    },
    {
      "name": "W664",
      "description": "Drowning and submersion following fall into bath-tub at street and highway"
    },
    {
      "name": "W6640",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While engaged in sports activity"
    },
    {
      "name": "W6641",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6642",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While working for income"
    },
    {
      "name": "W6643",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While engaged in other types of work"
    },
    {
      "name": "W6644",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6648",
      "description": "Drowning and submersion following fall into bath-tub at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6649",
      "description": "Drowning and submersion following fall into bath-tub at street and highway During unspecified activity"
    },
    {
      "name": "W665",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area"
    },
    {
      "name": "W6650",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6651",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6652",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While working for income"
    },
    {
      "name": "W6653",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6654",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6658",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6659",
      "description": "Drowning and submersion following fall into bath-tub at trade and service area During unspecified activity"
    },
    {
      "name": "W666",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area"
    },
    {
      "name": "W6660",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6661",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6662",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While working for income"
    },
    {
      "name": "W6663",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6664",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6668",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6669",
      "description": "Drowning and submersion following fall into bath-tub at industrial and construction area During unspecified activity"
    },
    {
      "name": "W667",
      "description": "Drowning and submersion following fall into bath-tub at farm"
    },
    {
      "name": "W6670",
      "description": "Drowning and submersion following fall into bath-tub at farm While engaged in sports activity"
    },
    {
      "name": "W6671",
      "description": "Drowning and submersion following fall into bath-tub at farm While engaged in leisure activity"
    },
    {
      "name": "W6672",
      "description": "Drowning and submersion following fall into bath-tub at farm While working for income"
    },
    {
      "name": "W6673",
      "description": "Drowning and submersion following fall into bath-tub at farm While engaged in other types of work"
    },
    {
      "name": "W6674",
      "description": "Drowning and submersion following fall into bath-tub at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6678",
      "description": "Drowning and submersion following fall into bath-tub at farm While engaged in other specified activities"
    },
    {
      "name": "W6679",
      "description": "Drowning and submersion following fall into bath-tub at farm During unspecified activity"
    },
    {
      "name": "W668",
      "description": "Drowning and submersion following fall into bath-tub at other specified places"
    },
    {
      "name": "W6680",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While engaged in sports activity"
    },
    {
      "name": "W6681",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6682",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While working for income"
    },
    {
      "name": "W6683",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While engaged in other types of work"
    },
    {
      "name": "W6684",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6688",
      "description": "Drowning and submersion following fall into bath-tub at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6689",
      "description": "Drowning and submersion following fall into bath-tub at other specified places During unspecified activity"
    },
    {
      "name": "W669",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place"
    },
    {
      "name": "W6690",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6691",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6692",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While working for income"
    },
    {
      "name": "W6693",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6694",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6698",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6699",
      "description": "Drowning and submersion following fall into bath-tub at unspecified place During unspecified activity"
    },
    {
      "name": "W670",
      "description": "Drowning and submersion while in swimming-pool at home"
    },
    {
      "name": "W6700",
      "description": "Drowning and submersion while in swimming-pool at home While engaged in sports activity"
    },
    {
      "name": "W6701",
      "description": "Drowning and submersion while in swimming-pool at home While engaged in leisure activity"
    },
    {
      "name": "W6702",
      "description": "Drowning and submersion while in swimming-pool at home While working for income"
    },
    {
      "name": "W6703",
      "description": "Drowning and submersion while in swimming-pool at home While engaged in other types of work"
    },
    {
      "name": "W6704",
      "description": "Drowning and submersion while in swimming-pool at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6708",
      "description": "Drowning and submersion while in swimming-pool at home While engaged in other specified activities"
    },
    {
      "name": "W6709",
      "description": "Drowning and submersion while in swimming-pool at home During unspecified activity"
    },
    {
      "name": "W671",
      "description": "Drowning and submersion while in swimming-pool at residential institution"
    },
    {
      "name": "W6710",
      "description": "Drowning and submersion while in swimming-pool at residential institution While engaged in sports activity"
    },
    {
      "name": "W6711",
      "description": "Drowning and submersion while in swimming-pool at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6712",
      "description": "Drowning and submersion while in swimming-pool at residential institution While working for income"
    },
    {
      "name": "W6713",
      "description": "Drowning and submersion while in swimming-pool at residential institution While engaged in other types of work"
    },
    {
      "name": "W6714",
      "description": "Drowning and submersion while in swimming-pool at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6718",
      "description": "Drowning and submersion while in swimming-pool at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6719",
      "description": "Drowning and submersion while in swimming-pool at residential institution During unspecified activity"
    },
    {
      "name": "W672",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area"
    },
    {
      "name": "W6720",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6721",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6722",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6723",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6724",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6728",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6729",
      "description": "Drowning and submersion while in swimming-pool at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W673",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area"
    },
    {
      "name": "W6730",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6731",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6732",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While working for income"
    },
    {
      "name": "W6733",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6734",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6738",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6739",
      "description": "Drowning and submersion while in swimming-pool at sports and athletics area During unspecified activity"
    },
    {
      "name": "W674",
      "description": "Drowning and submersion while in swimming-pool at street and highway"
    },
    {
      "name": "W6740",
      "description": "Drowning and submersion while in swimming-pool at street and highway While engaged in sports activity"
    },
    {
      "name": "W6741",
      "description": "Drowning and submersion while in swimming-pool at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6742",
      "description": "Drowning and submersion while in swimming-pool at street and highway While working for income"
    },
    {
      "name": "W6743",
      "description": "Drowning and submersion while in swimming-pool at street and highway While engaged in other types of work"
    },
    {
      "name": "W6744",
      "description": "Drowning and submersion while in swimming-pool at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6748",
      "description": "Drowning and submersion while in swimming-pool at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6749",
      "description": "Drowning and submersion while in swimming-pool at street and highway During unspecified activity"
    },
    {
      "name": "W675",
      "description": "Drowning and submersion while in swimming-pool at trade and service area"
    },
    {
      "name": "W6750",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6751",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6752",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While working for income"
    },
    {
      "name": "W6753",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6754",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6758",
      "description": "Drowning and submersion while in swimming-pool at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6759",
      "description": "Drowning and submersion while in swimming-pool at trade and service area During unspecified activity"
    },
    {
      "name": "W676",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area"
    },
    {
      "name": "W6760",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6761",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6762",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While working for income"
    },
    {
      "name": "W6763",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6764",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6768",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6769",
      "description": "Drowning and submersion while in swimming-pool at industrial and construction area During unspecified activity"
    },
    {
      "name": "W677",
      "description": "Drowning and submersion while in swimming-pool at farm"
    },
    {
      "name": "W6770",
      "description": "Drowning and submersion while in swimming-pool at farm While engaged in sports activity"
    },
    {
      "name": "W6771",
      "description": "Drowning and submersion while in swimming-pool at farm While engaged in leisure activity"
    },
    {
      "name": "W6772",
      "description": "Drowning and submersion while in swimming-pool at farm While working for income"
    },
    {
      "name": "W6773",
      "description": "Drowning and submersion while in swimming-pool at farm While engaged in other types of work"
    },
    {
      "name": "W6774",
      "description": "Drowning and submersion while in swimming-pool at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6778",
      "description": "Drowning and submersion while in swimming-pool at farm While engaged in other specified activities"
    },
    {
      "name": "W6779",
      "description": "Drowning and submersion while in swimming-pool at farm During unspecified activity"
    },
    {
      "name": "W678",
      "description": "Drowning and submersion while in swimming-pool at other specified places"
    },
    {
      "name": "W6780",
      "description": "Drowning and submersion while in swimming-pool at other specified places While engaged in sports activity"
    },
    {
      "name": "W6781",
      "description": "Drowning and submersion while in swimming-pool at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6782",
      "description": "Drowning and submersion while in swimming-pool at other specified places While working for income"
    },
    {
      "name": "W6783",
      "description": "Drowning and submersion while in swimming-pool at other specified places While engaged in other types of work"
    },
    {
      "name": "W6784",
      "description": "Drowning and submersion while in swimming-pool at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6788",
      "description": "Drowning and submersion while in swimming-pool at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6789",
      "description": "Drowning and submersion while in swimming-pool at other specified places During unspecified activity"
    },
    {
      "name": "W679",
      "description": "Drowning and submersion while in swimming-pool at unspecified place"
    },
    {
      "name": "W6790",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6791",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6792",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While working for income"
    },
    {
      "name": "W6793",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6794",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6798",
      "description": "Drowning and submersion while in swimming-pool at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6799",
      "description": "Drowning and submersion while in swimming-pool at unspecified place During unspecified activity"
    },
    {
      "name": "W680",
      "description": "Drowning and submersion following fall into swimming-pool at home"
    },
    {
      "name": "W6800",
      "description": "Drowning and submersion following fall into swimming-pool at home While engaged in sports activity"
    },
    {
      "name": "W6801",
      "description": "Drowning and submersion following fall into swimming-pool at home While engaged in leisure activity"
    },
    {
      "name": "W6802",
      "description": "Drowning and submersion following fall into swimming-pool at home While working for income"
    },
    {
      "name": "W6803",
      "description": "Drowning and submersion following fall into swimming-pool at home While engaged in other types of work"
    },
    {
      "name": "W6804",
      "description": "Drowning and submersion following fall into swimming-pool at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6808",
      "description": "Drowning and submersion following fall into swimming-pool at home While engaged in other specified activities"
    },
    {
      "name": "W6809",
      "description": "Drowning and submersion following fall into swimming-pool at home During unspecified activity"
    },
    {
      "name": "W681",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution"
    },
    {
      "name": "W6810",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While engaged in sports activity"
    },
    {
      "name": "W6811",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6812",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While working for income"
    },
    {
      "name": "W6813",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While engaged in other types of work"
    },
    {
      "name": "W6814",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6818",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6819",
      "description": "Drowning and submersion following fall into swimming-pool at residential institution During unspecified activity"
    },
    {
      "name": "W682",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area"
    },
    {
      "name": "W6820",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6821",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6822",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6823",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6824",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6828",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6829",
      "description": "Drowning and submersion following fall into swimming-pool at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W683",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area"
    },
    {
      "name": "W6830",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6831",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6832",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While working for income"
    },
    {
      "name": "W6833",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6834",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6838",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6839",
      "description": "Drowning and submersion following fall into swimming-pool at sports and athletics area During unspecified activity"
    },
    {
      "name": "W684",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway"
    },
    {
      "name": "W6840",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While engaged in sports activity"
    },
    {
      "name": "W6841",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6842",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While working for income"
    },
    {
      "name": "W6843",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While engaged in other types of work"
    },
    {
      "name": "W6844",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6848",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6849",
      "description": "Drowning and submersion following fall into swimming-pool at street and highway During unspecified activity"
    },
    {
      "name": "W685",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area"
    },
    {
      "name": "W6850",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6851",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6852",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While working for income"
    },
    {
      "name": "W6853",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6854",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6858",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6859",
      "description": "Drowning and submersion following fall into swimming-pool at trade and service area During unspecified activity"
    },
    {
      "name": "W686",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area"
    },
    {
      "name": "W6860",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6861",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6862",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While working for income"
    },
    {
      "name": "W6863",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6864",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6868",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6869",
      "description": "Drowning and submersion following fall into swimming-pool at industrial and construction area During unspecified activity"
    },
    {
      "name": "W687",
      "description": "Drowning and submersion following fall into swimming-pool at farm"
    },
    {
      "name": "W6870",
      "description": "Drowning and submersion following fall into swimming-pool at farm While engaged in sports activity"
    },
    {
      "name": "W6871",
      "description": "Drowning and submersion following fall into swimming-pool at farm While engaged in leisure activity"
    },
    {
      "name": "W6872",
      "description": "Drowning and submersion following fall into swimming-pool at farm While working for income"
    },
    {
      "name": "W6873",
      "description": "Drowning and submersion following fall into swimming-pool at farm While engaged in other types of work"
    },
    {
      "name": "W6874",
      "description": "Drowning and submersion following fall into swimming-pool at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6878",
      "description": "Drowning and submersion following fall into swimming-pool at farm While engaged in other specified activities"
    },
    {
      "name": "W6879",
      "description": "Drowning and submersion following fall into swimming-pool at farm During unspecified activity"
    },
    {
      "name": "W688",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places"
    },
    {
      "name": "W6880",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While engaged in sports activity"
    },
    {
      "name": "W6881",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6882",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While working for income"
    },
    {
      "name": "W6883",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While engaged in other types of work"
    },
    {
      "name": "W6884",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6888",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6889",
      "description": "Drowning and submersion following fall into swimming-pool at other specified places During unspecified activity"
    },
    {
      "name": "W689",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place"
    },
    {
      "name": "W6890",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6891",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6892",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While working for income"
    },
    {
      "name": "W6893",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6894",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6898",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6899",
      "description": "Drowning and submersion following fall into swimming-pool at unspecified place During unspecified activity"
    },
    {
      "name": "W690",
      "description": "Drowning and submersion while in natural water at home"
    },
    {
      "name": "W6900",
      "description": "Drowning and submersion while in natural water at home While engaged in sports activity"
    },
    {
      "name": "W6901",
      "description": "Drowning and submersion while in natural water at home While engaged in leisure activity"
    },
    {
      "name": "W6902",
      "description": "Drowning and submersion while in natural water at home While working for income"
    },
    {
      "name": "W6903",
      "description": "Drowning and submersion while in natural water at home While engaged in other types of work"
    },
    {
      "name": "W6904",
      "description": "Drowning and submersion while in natural water at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6908",
      "description": "Drowning and submersion while in natural water at home While engaged in other specified activities"
    },
    {
      "name": "W6909",
      "description": "Drowning and submersion while in natural water at home During unspecified activity"
    },
    {
      "name": "W691",
      "description": "Drowning and submersion while in natural water at residential institution"
    },
    {
      "name": "W6910",
      "description": "Drowning and submersion while in natural water at residential institution While engaged in sports activity"
    },
    {
      "name": "W6911",
      "description": "Drowning and submersion while in natural water at residential institution While engaged in leisure activity"
    },
    {
      "name": "W6912",
      "description": "Drowning and submersion while in natural water at residential institution While working for income"
    },
    {
      "name": "W6913",
      "description": "Drowning and submersion while in natural water at residential institution While engaged in other types of work"
    },
    {
      "name": "W6914",
      "description": "Drowning and submersion while in natural water at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6918",
      "description": "Drowning and submersion while in natural water at residential institution While engaged in other specified activities"
    },
    {
      "name": "W6919",
      "description": "Drowning and submersion while in natural water at residential institution During unspecified activity"
    },
    {
      "name": "W692",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area"
    },
    {
      "name": "W6920",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W6921",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W6922",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W6923",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W6924",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6928",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W6929",
      "description": "Drowning and submersion while in natural water at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W693",
      "description": "Drowning and submersion while in natural water at sports and athletics area"
    },
    {
      "name": "W6930",
      "description": "Drowning and submersion while in natural water at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W6931",
      "description": "Drowning and submersion while in natural water at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W6932",
      "description": "Drowning and submersion while in natural water at sports and athletics area While working for income"
    },
    {
      "name": "W6933",
      "description": "Drowning and submersion while in natural water at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W6934",
      "description": "Drowning and submersion while in natural water at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6938",
      "description": "Drowning and submersion while in natural water at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W6939",
      "description": "Drowning and submersion while in natural water at sports and athletics area During unspecified activity"
    },
    {
      "name": "W694",
      "description": "Drowning and submersion while in natural water at street and highway"
    },
    {
      "name": "W6940",
      "description": "Drowning and submersion while in natural water at street and highway While engaged in sports activity"
    },
    {
      "name": "W6941",
      "description": "Drowning and submersion while in natural water at street and highway While engaged in leisure activity"
    },
    {
      "name": "W6942",
      "description": "Drowning and submersion while in natural water at street and highway While working for income"
    },
    {
      "name": "W6943",
      "description": "Drowning and submersion while in natural water at street and highway While engaged in other types of work"
    },
    {
      "name": "W6944",
      "description": "Drowning and submersion while in natural water at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6948",
      "description": "Drowning and submersion while in natural water at street and highway While engaged in other specified activities"
    },
    {
      "name": "W6949",
      "description": "Drowning and submersion while in natural water at street and highway During unspecified activity"
    },
    {
      "name": "W695",
      "description": "Drowning and submersion while in natural water at trade and service area"
    },
    {
      "name": "W6950",
      "description": "Drowning and submersion while in natural water at trade and service area While engaged in sports activity"
    },
    {
      "name": "W6951",
      "description": "Drowning and submersion while in natural water at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W6952",
      "description": "Drowning and submersion while in natural water at trade and service area While working for income"
    },
    {
      "name": "W6953",
      "description": "Drowning and submersion while in natural water at trade and service area While engaged in other types of work"
    },
    {
      "name": "W6954",
      "description": "Drowning and submersion while in natural water at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6958",
      "description": "Drowning and submersion while in natural water at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W6959",
      "description": "Drowning and submersion while in natural water at trade and service area During unspecified activity"
    },
    {
      "name": "W696",
      "description": "Drowning and submersion while in natural water at industrial and construction area"
    },
    {
      "name": "W6960",
      "description": "Drowning and submersion while in natural water at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W6961",
      "description": "Drowning and submersion while in natural water at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W6962",
      "description": "Drowning and submersion while in natural water at industrial and construction area While working for income"
    },
    {
      "name": "W6963",
      "description": "Drowning and submersion while in natural water at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W6964",
      "description": "Drowning and submersion while in natural water at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6968",
      "description": "Drowning and submersion while in natural water at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W6969",
      "description": "Drowning and submersion while in natural water at industrial and construction area During unspecified activity"
    },
    {
      "name": "W697",
      "description": "Drowning and submersion while in natural water at farm"
    },
    {
      "name": "W6970",
      "description": "Drowning and submersion while in natural water at farm While engaged in sports activity"
    },
    {
      "name": "W6971",
      "description": "Drowning and submersion while in natural water at farm While engaged in leisure activity"
    },
    {
      "name": "W6972",
      "description": "Drowning and submersion while in natural water at farm While working for income"
    },
    {
      "name": "W6973",
      "description": "Drowning and submersion while in natural water at farm While engaged in other types of work"
    },
    {
      "name": "W6974",
      "description": "Drowning and submersion while in natural water at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6978",
      "description": "Drowning and submersion while in natural water at farm While engaged in other specified activities"
    },
    {
      "name": "W6979",
      "description": "Drowning and submersion while in natural water at farm During unspecified activity"
    },
    {
      "name": "W698",
      "description": "Drowning and submersion while in natural water at other specified places"
    },
    {
      "name": "W6980",
      "description": "Drowning and submersion while in natural water at other specified places While engaged in sports activity"
    },
    {
      "name": "W6981",
      "description": "Drowning and submersion while in natural water at other specified places While engaged in leisure activity"
    },
    {
      "name": "W6982",
      "description": "Drowning and submersion while in natural water at other specified places While working for income"
    },
    {
      "name": "W6983",
      "description": "Drowning and submersion while in natural water at other specified places While engaged in other types of work"
    },
    {
      "name": "W6984",
      "description": "Drowning and submersion while in natural water at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6988",
      "description": "Drowning and submersion while in natural water at other specified places While engaged in other specified activities"
    },
    {
      "name": "W6989",
      "description": "Drowning and submersion while in natural water at other specified places During unspecified activity"
    },
    {
      "name": "W699",
      "description": "Drowning and submersion while in natural water at unspecified place"
    },
    {
      "name": "W6990",
      "description": "Drowning and submersion while in natural water at unspecified place While engaged in sports activity"
    },
    {
      "name": "W6991",
      "description": "Drowning and submersion while in natural water at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W6992",
      "description": "Drowning and submersion while in natural water at unspecified place While working for income"
    },
    {
      "name": "W6993",
      "description": "Drowning and submersion while in natural water at unspecified place While engaged in other types of work"
    },
    {
      "name": "W6994",
      "description": "Drowning and submersion while in natural water at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W6998",
      "description": "Drowning and submersion while in natural water at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W6999",
      "description": "Drowning and submersion while in natural water at unspecified place During unspecified activity"
    },
    {
      "name": "W700",
      "description": "Drowning and submersion following fall into natural water at home"
    },
    {
      "name": "W7000",
      "description": "Drowning and submersion following fall into natural water at home While engaged in sports activity"
    },
    {
      "name": "W7001",
      "description": "Drowning and submersion following fall into natural water at home While engaged in leisure activity"
    },
    {
      "name": "W7002",
      "description": "Drowning and submersion following fall into natural water at home While working for income"
    },
    {
      "name": "W7003",
      "description": "Drowning and submersion following fall into natural water at home While engaged in other types of work"
    },
    {
      "name": "W7004",
      "description": "Drowning and submersion following fall into natural water at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7008",
      "description": "Drowning and submersion following fall into natural water at home While engaged in other specified activities"
    },
    {
      "name": "W7009",
      "description": "Drowning and submersion following fall into natural water at home During unspecified activity"
    },
    {
      "name": "W701",
      "description": "Drowning and submersion following fall into natural water at residential institution"
    },
    {
      "name": "W7010",
      "description": "Drowning and submersion following fall into natural water at residential institution While engaged in sports activity"
    },
    {
      "name": "W7011",
      "description": "Drowning and submersion following fall into natural water at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7012",
      "description": "Drowning and submersion following fall into natural water at residential institution While working for income"
    },
    {
      "name": "W7013",
      "description": "Drowning and submersion following fall into natural water at residential institution While engaged in other types of work"
    },
    {
      "name": "W7014",
      "description": "Drowning and submersion following fall into natural water at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7018",
      "description": "Drowning and submersion following fall into natural water at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7019",
      "description": "Drowning and submersion following fall into natural water at residential institution During unspecified activity"
    },
    {
      "name": "W702",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area"
    },
    {
      "name": "W7020",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7021",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7022",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7023",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7024",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7028",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7029",
      "description": "Drowning and submersion following fall into natural water at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W703",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area"
    },
    {
      "name": "W7030",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7031",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7032",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While working for income"
    },
    {
      "name": "W7033",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7034",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7038",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7039",
      "description": "Drowning and submersion following fall into natural water at sports and athletics area During unspecified activity"
    },
    {
      "name": "W704",
      "description": "Drowning and submersion following fall into natural water at street and highway"
    },
    {
      "name": "W7040",
      "description": "Drowning and submersion following fall into natural water at street and highway While engaged in sports activity"
    },
    {
      "name": "W7041",
      "description": "Drowning and submersion following fall into natural water at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7042",
      "description": "Drowning and submersion following fall into natural water at street and highway While working for income"
    },
    {
      "name": "W7043",
      "description": "Drowning and submersion following fall into natural water at street and highway While engaged in other types of work"
    },
    {
      "name": "W7044",
      "description": "Drowning and submersion following fall into natural water at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7048",
      "description": "Drowning and submersion following fall into natural water at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7049",
      "description": "Drowning and submersion following fall into natural water at street and highway During unspecified activity"
    },
    {
      "name": "W705",
      "description": "Drowning and submersion following fall into natural water at trade and service area"
    },
    {
      "name": "W7050",
      "description": "Drowning and submersion following fall into natural water at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7051",
      "description": "Drowning and submersion following fall into natural water at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7052",
      "description": "Drowning and submersion following fall into natural water at trade and service area While working for income"
    },
    {
      "name": "W7053",
      "description": "Drowning and submersion following fall into natural water at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7054",
      "description": "Drowning and submersion following fall into natural water at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7058",
      "description": "Drowning and submersion following fall into natural water at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7059",
      "description": "Drowning and submersion following fall into natural water at trade and service area During unspecified activity"
    },
    {
      "name": "W706",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area"
    },
    {
      "name": "W7060",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7061",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7062",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While working for income"
    },
    {
      "name": "W7063",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7064",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7068",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7069",
      "description": "Drowning and submersion following fall into natural water at industrial and construction area During unspecified activity"
    },
    {
      "name": "W707",
      "description": "Drowning and submersion following fall into natural water at farm"
    },
    {
      "name": "W7070",
      "description": "Drowning and submersion following fall into natural water at farm While engaged in sports activity"
    },
    {
      "name": "W7071",
      "description": "Drowning and submersion following fall into natural water at farm While engaged in leisure activity"
    },
    {
      "name": "W7072",
      "description": "Drowning and submersion following fall into natural water at farm While working for income"
    },
    {
      "name": "W7073",
      "description": "Drowning and submersion following fall into natural water at farm While engaged in other types of work"
    },
    {
      "name": "W7074",
      "description": "Drowning and submersion following fall into natural water at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7078",
      "description": "Drowning and submersion following fall into natural water at farm While engaged in other specified activities"
    },
    {
      "name": "W7079",
      "description": "Drowning and submersion following fall into natural water at farm During unspecified activity"
    },
    {
      "name": "W708",
      "description": "Drowning and submersion following fall into natural water at other specified places"
    },
    {
      "name": "W7080",
      "description": "Drowning and submersion following fall into natural water at other specified places While engaged in sports activity"
    },
    {
      "name": "W7081",
      "description": "Drowning and submersion following fall into natural water at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7082",
      "description": "Drowning and submersion following fall into natural water at other specified places While working for income"
    },
    {
      "name": "W7083",
      "description": "Drowning and submersion following fall into natural water at other specified places While engaged in other types of work"
    },
    {
      "name": "W7084",
      "description": "Drowning and submersion following fall into natural water at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7088",
      "description": "Drowning and submersion following fall into natural water at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7089",
      "description": "Drowning and submersion following fall into natural water at other specified places During unspecified activity"
    },
    {
      "name": "W709",
      "description": "Drowning and submersion following fall into natural water at unspecified place"
    },
    {
      "name": "W7090",
      "description": "Drowning and submersion following fall into natural water at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7091",
      "description": "Drowning and submersion following fall into natural water at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7092",
      "description": "Drowning and submersion following fall into natural water at unspecified place While working for income"
    },
    {
      "name": "W7093",
      "description": "Drowning and submersion following fall into natural water at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7094",
      "description": "Drowning and submersion following fall into natural water at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7098",
      "description": "Drowning and submersion following fall into natural water at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7099",
      "description": "Drowning and submersion following fall into natural water at unspecified place During unspecified activity"
    },
    {
      "name": "W730",
      "description": "Other  specified drowning and submersion at home"
    },
    {
      "name": "W7300",
      "description": "Other  specified drowning and submersion at home While engaged in sports activity"
    },
    {
      "name": "W7301",
      "description": "Other  specified drowning and submersion at home While engaged in leisure activity"
    },
    {
      "name": "W7302",
      "description": "Other  specified drowning and submersion at home While working for income"
    },
    {
      "name": "W7303",
      "description": "Other  specified drowning and submersion at home While engaged in other types of work"
    },
    {
      "name": "W7304",
      "description": "Other  specified drowning and submersion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7308",
      "description": "Other  specified drowning and submersion at home While engaged in other specified activities"
    },
    {
      "name": "W7309",
      "description": "Other  specified drowning and submersion at home During unspecified activity"
    },
    {
      "name": "W731",
      "description": "Other  specified drowning and submersion at residential institution"
    },
    {
      "name": "W7310",
      "description": "Other  specified drowning and submersion at residential institution While engaged in sports activity"
    },
    {
      "name": "W7311",
      "description": "Other  specified drowning and submersion at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7312",
      "description": "Other  specified drowning and submersion at residential institution While working for income"
    },
    {
      "name": "W7313",
      "description": "Other  specified drowning and submersion at residential institution While engaged in other types of work"
    },
    {
      "name": "W7314",
      "description": "Other  specified drowning and submersion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7318",
      "description": "Other  specified drowning and submersion at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7319",
      "description": "Other  specified drowning and submersion at residential institution During unspecified activity"
    },
    {
      "name": "W732",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area"
    },
    {
      "name": "W7320",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7321",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7322",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7323",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7324",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7328",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7329",
      "description": "Other  specified drowning and submersion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W733",
      "description": "Other  specified drowning and submersion at sports and athletics area"
    },
    {
      "name": "W7330",
      "description": "Other  specified drowning and submersion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7331",
      "description": "Other  specified drowning and submersion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7332",
      "description": "Other  specified drowning and submersion at sports and athletics area While working for income"
    },
    {
      "name": "W7333",
      "description": "Other  specified drowning and submersion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7334",
      "description": "Other  specified drowning and submersion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7338",
      "description": "Other  specified drowning and submersion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7339",
      "description": "Other  specified drowning and submersion at sports and athletics area During unspecified activity"
    },
    {
      "name": "W734",
      "description": "Other  specified drowning and submersion at street and highway"
    },
    {
      "name": "W7340",
      "description": "Other  specified drowning and submersion at street and highway While engaged in sports activity"
    },
    {
      "name": "W7341",
      "description": "Other  specified drowning and submersion at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7342",
      "description": "Other  specified drowning and submersion at street and highway While working for income"
    },
    {
      "name": "W7343",
      "description": "Other  specified drowning and submersion at street and highway While engaged in other types of work"
    },
    {
      "name": "W7344",
      "description": "Other  specified drowning and submersion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7348",
      "description": "Other  specified drowning and submersion at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7349",
      "description": "Other  specified drowning and submersion at street and highway During unspecified activity"
    },
    {
      "name": "W735",
      "description": "Other  specified drowning and submersion at trade and service area"
    },
    {
      "name": "W7350",
      "description": "Other  specified drowning and submersion at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7351",
      "description": "Other  specified drowning and submersion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7352",
      "description": "Other  specified drowning and submersion at trade and service area While working for income"
    },
    {
      "name": "W7353",
      "description": "Other  specified drowning and submersion at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7354",
      "description": "Other  specified drowning and submersion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7358",
      "description": "Other  specified drowning and submersion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7359",
      "description": "Other  specified drowning and submersion at trade and service area During unspecified activity"
    },
    {
      "name": "W736",
      "description": "Other  specified drowning and submersion at industrial and construction area"
    },
    {
      "name": "W7360",
      "description": "Other  specified drowning and submersion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7361",
      "description": "Other  specified drowning and submersion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7362",
      "description": "Other  specified drowning and submersion at industrial and construction area While working for income"
    },
    {
      "name": "W7363",
      "description": "Other  specified drowning and submersion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7364",
      "description": "Other  specified drowning and submersion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7368",
      "description": "Other  specified drowning and submersion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7369",
      "description": "Other  specified drowning and submersion at industrial and construction area During unspecified activity"
    },
    {
      "name": "W737",
      "description": "Other  specified drowning and submersion at farm"
    },
    {
      "name": "W7370",
      "description": "Other  specified drowning and submersion at farm While engaged in sports activity"
    },
    {
      "name": "W7371",
      "description": "Other  specified drowning and submersion at farm While engaged in leisure activity"
    },
    {
      "name": "W7372",
      "description": "Other  specified drowning and submersion at farm While working for income"
    },
    {
      "name": "W7373",
      "description": "Other  specified drowning and submersion at farm While engaged in other types of work"
    },
    {
      "name": "W7374",
      "description": "Other  specified drowning and submersion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7378",
      "description": "Other  specified drowning and submersion at farm While engaged in other specified activities"
    },
    {
      "name": "W7379",
      "description": "Other  specified drowning and submersion at farm During unspecified activity"
    },
    {
      "name": "W738",
      "description": "Other  specified drowning and submersion at other specified places"
    },
    {
      "name": "W7380",
      "description": "Other  specified drowning and submersion at other specified places While engaged in sports activity"
    },
    {
      "name": "W7381",
      "description": "Other  specified drowning and submersion at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7382",
      "description": "Other  specified drowning and submersion at other specified places While working for income"
    },
    {
      "name": "W7383",
      "description": "Other  specified drowning and submersion at other specified places While engaged in other types of work"
    },
    {
      "name": "W7384",
      "description": "Other  specified drowning and submersion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7388",
      "description": "Other  specified drowning and submersion at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7389",
      "description": "Other  specified drowning and submersion at other specified places During unspecified activity"
    },
    {
      "name": "W739",
      "description": "Other  specified drowning and submersion at unspecified place"
    },
    {
      "name": "W7390",
      "description": "Other  specified drowning and submersion at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7391",
      "description": "Other  specified drowning and submersion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7392",
      "description": "Other  specified drowning and submersion at unspecified place While working for income"
    },
    {
      "name": "W7393",
      "description": "Other  specified drowning and submersion at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7394",
      "description": "Other  specified drowning and submersion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7398",
      "description": "Other  specified drowning and submersion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7399",
      "description": "Other  specified drowning and submersion at unspecified place During unspecified activity"
    },
    {
      "name": "W740",
      "description": "Unspecified  drowning and submersion at home"
    },
    {
      "name": "W7400",
      "description": "Unspecified  drowning and submersion at home While engaged in sports activity"
    },
    {
      "name": "W7401",
      "description": "Unspecified  drowning and submersion at home While engaged in leisure activity"
    },
    {
      "name": "W7402",
      "description": "Unspecified  drowning and submersion at home While working for income"
    },
    {
      "name": "W7403",
      "description": "Unspecified  drowning and submersion at home While engaged in other types of work"
    },
    {
      "name": "W7404",
      "description": "Unspecified  drowning and submersion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7408",
      "description": "Unspecified  drowning and submersion at home While engaged in other specified activities"
    },
    {
      "name": "W7409",
      "description": "Unspecified  drowning and submersion at home During unspecified activity"
    },
    {
      "name": "W741",
      "description": "Unspecified  drowning and submersion at residential institution"
    },
    {
      "name": "W7410",
      "description": "Unspecified  drowning and submersion at residential institution While engaged in sports activity"
    },
    {
      "name": "W7411",
      "description": "Unspecified  drowning and submersion at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7412",
      "description": "Unspecified  drowning and submersion at residential institution While working for income"
    },
    {
      "name": "W7413",
      "description": "Unspecified  drowning and submersion at residential institution While engaged in other types of work"
    },
    {
      "name": "W7414",
      "description": "Unspecified  drowning and submersion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7418",
      "description": "Unspecified  drowning and submersion at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7419",
      "description": "Unspecified  drowning and submersion at residential institution During unspecified activity"
    },
    {
      "name": "W742",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area"
    },
    {
      "name": "W7420",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7421",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7422",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7423",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7424",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7428",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7429",
      "description": "Unspecified  drowning and submersion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W743",
      "description": "Unspecified  drowning and submersion at sports and athletics area"
    },
    {
      "name": "W7430",
      "description": "Unspecified  drowning and submersion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7431",
      "description": "Unspecified  drowning and submersion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7432",
      "description": "Unspecified  drowning and submersion at sports and athletics area While working for income"
    },
    {
      "name": "W7433",
      "description": "Unspecified  drowning and submersion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7434",
      "description": "Unspecified  drowning and submersion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7438",
      "description": "Unspecified  drowning and submersion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7439",
      "description": "Unspecified  drowning and submersion at sports and athletics area During unspecified activity"
    },
    {
      "name": "W744",
      "description": "Unspecified  drowning and submersion at street and highway"
    },
    {
      "name": "W7440",
      "description": "Unspecified  drowning and submersion at street and highway While engaged in sports activity"
    },
    {
      "name": "W7441",
      "description": "Unspecified  drowning and submersion at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7442",
      "description": "Unspecified  drowning and submersion at street and highway While working for income"
    },
    {
      "name": "W7443",
      "description": "Unspecified  drowning and submersion at street and highway While engaged in other types of work"
    },
    {
      "name": "W7444",
      "description": "Unspecified  drowning and submersion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7448",
      "description": "Unspecified  drowning and submersion at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7449",
      "description": "Unspecified  drowning and submersion at street and highway During unspecified activity"
    },
    {
      "name": "W745",
      "description": "Unspecified  drowning and submersion at trade and service area"
    },
    {
      "name": "W7450",
      "description": "Unspecified  drowning and submersion at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7451",
      "description": "Unspecified  drowning and submersion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7452",
      "description": "Unspecified  drowning and submersion at trade and service area While working for income"
    },
    {
      "name": "W7453",
      "description": "Unspecified  drowning and submersion at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7454",
      "description": "Unspecified  drowning and submersion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7458",
      "description": "Unspecified  drowning and submersion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7459",
      "description": "Unspecified  drowning and submersion at trade and service area During unspecified activity"
    },
    {
      "name": "W746",
      "description": "Unspecified  drowning and submersion at industrial and construction area"
    },
    {
      "name": "W7460",
      "description": "Unspecified  drowning and submersion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7461",
      "description": "Unspecified  drowning and submersion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7462",
      "description": "Unspecified  drowning and submersion at industrial and construction area While working for income"
    },
    {
      "name": "W7463",
      "description": "Unspecified  drowning and submersion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7464",
      "description": "Unspecified  drowning and submersion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7468",
      "description": "Unspecified  drowning and submersion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7469",
      "description": "Unspecified  drowning and submersion at industrial and construction area During unspecified activity"
    },
    {
      "name": "W747",
      "description": "Unspecified  drowning and submersion at farm"
    },
    {
      "name": "W7470",
      "description": "Unspecified  drowning and submersion at farm While engaged in sports activity"
    },
    {
      "name": "W7471",
      "description": "Unspecified  drowning and submersion at farm While engaged in leisure activity"
    },
    {
      "name": "W7472",
      "description": "Unspecified  drowning and submersion at farm While working for income"
    },
    {
      "name": "W7473",
      "description": "Unspecified  drowning and submersion at farm While engaged in other types of work"
    },
    {
      "name": "W7474",
      "description": "Unspecified  drowning and submersion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7478",
      "description": "Unspecified  drowning and submersion at farm While engaged in other specified activities"
    },
    {
      "name": "W7479",
      "description": "Unspecified  drowning and submersion at farm During unspecified activity"
    },
    {
      "name": "W748",
      "description": "Unspecified  drowning and submersion at other specified places"
    },
    {
      "name": "W7480",
      "description": "Unspecified  drowning and submersion at other specified places While engaged in sports activity"
    },
    {
      "name": "W7481",
      "description": "Unspecified  drowning and submersion at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7482",
      "description": "Unspecified  drowning and submersion at other specified places While working for income"
    },
    {
      "name": "W7483",
      "description": "Unspecified  drowning and submersion at other specified places While engaged in other types of work"
    },
    {
      "name": "W7484",
      "description": "Unspecified  drowning and submersion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7488",
      "description": "Unspecified  drowning and submersion at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7489",
      "description": "Unspecified  drowning and submersion at other specified places During unspecified activity"
    },
    {
      "name": "W749",
      "description": "Unspecified  drowning and submersion at unspecified place"
    },
    {
      "name": "W7490",
      "description": "Unspecified  drowning and submersion at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7491",
      "description": "Unspecified  drowning and submersion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7492",
      "description": "Unspecified  drowning and submersion at unspecified place While working for income"
    },
    {
      "name": "W7493",
      "description": "Unspecified  drowning and submersion at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7494",
      "description": "Unspecified  drowning and submersion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7498",
      "description": "Unspecified  drowning and submersion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7499",
      "description": "Unspecified  drowning and submersion at unspecified place During unspecified activity"
    },
    {
      "name": "W750",
      "description": "Accidental suffocation and strangulation in bed at home"
    },
    {
      "name": "W7500",
      "description": "Accidental suffocation and strangulation in bed at home While engaged in sports activity"
    },
    {
      "name": "W7501",
      "description": "Accidental suffocation and strangulation in bed at home While engaged in leisure activity"
    },
    {
      "name": "W7502",
      "description": "Accidental suffocation and strangulation in bed at home While working for income"
    },
    {
      "name": "W7503",
      "description": "Accidental suffocation and strangulation in bed at home While engaged in other types of work"
    },
    {
      "name": "W7504",
      "description": "Accidental suffocation and strangulation in bed at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7508",
      "description": "Accidental suffocation and strangulation in bed at home While engaged in other specified activities"
    },
    {
      "name": "W7509",
      "description": "Accidental suffocation and strangulation in bed at home During unspecified activity"
    },
    {
      "name": "W751",
      "description": "Accidental suffocation and strangulation in bed at residential institution"
    },
    {
      "name": "W7510",
      "description": "Accidental suffocation and strangulation in bed at residential institution While engaged in sports activity"
    },
    {
      "name": "W7511",
      "description": "Accidental suffocation and strangulation in bed at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7512",
      "description": "Accidental suffocation and strangulation in bed at residential institution While working for income"
    },
    {
      "name": "W7513",
      "description": "Accidental suffocation and strangulation in bed at residential institution While engaged in other types of work"
    },
    {
      "name": "W7514",
      "description": "Accidental suffocation and strangulation in bed at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7518",
      "description": "Accidental suffocation and strangulation in bed at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7519",
      "description": "Accidental suffocation and strangulation in bed at residential institution During unspecified activity"
    },
    {
      "name": "W752",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area"
    },
    {
      "name": "W7520",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7521",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7522",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7523",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7524",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7528",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7529",
      "description": "Accidental suffocation and strangulation in bed at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W753",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area"
    },
    {
      "name": "W7530",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7531",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7532",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While working for income"
    },
    {
      "name": "W7533",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7534",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7538",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7539",
      "description": "Accidental suffocation and strangulation in bed at sports and athletics area During unspecified activity"
    },
    {
      "name": "W754",
      "description": "Accidental suffocation and strangulation in bed at street and highway"
    },
    {
      "name": "W7540",
      "description": "Accidental suffocation and strangulation in bed at street and highway While engaged in sports activity"
    },
    {
      "name": "W7541",
      "description": "Accidental suffocation and strangulation in bed at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7542",
      "description": "Accidental suffocation and strangulation in bed at street and highway While working for income"
    },
    {
      "name": "W7543",
      "description": "Accidental suffocation and strangulation in bed at street and highway While engaged in other types of work"
    },
    {
      "name": "W7544",
      "description": "Accidental suffocation and strangulation in bed at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7548",
      "description": "Accidental suffocation and strangulation in bed at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7549",
      "description": "Accidental suffocation and strangulation in bed at street and highway During unspecified activity"
    },
    {
      "name": "W755",
      "description": "Accidental suffocation and strangulation in bed at trade and service area"
    },
    {
      "name": "W7550",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7551",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7552",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While working for income"
    },
    {
      "name": "W7553",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7554",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7558",
      "description": "Accidental suffocation and strangulation in bed at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7559",
      "description": "Accidental suffocation and strangulation in bed at trade and service area During unspecified activity"
    },
    {
      "name": "W756",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area"
    },
    {
      "name": "W7560",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7561",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7562",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While working for income"
    },
    {
      "name": "W7563",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7564",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7568",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7569",
      "description": "Accidental suffocation and strangulation in bed at industrial and construction area During unspecified activity"
    },
    {
      "name": "W757",
      "description": "Accidental suffocation and strangulation in bed at farm"
    },
    {
      "name": "W7570",
      "description": "Accidental suffocation and strangulation in bed at farm While engaged in sports activity"
    },
    {
      "name": "W7571",
      "description": "Accidental suffocation and strangulation in bed at farm While engaged in leisure activity"
    },
    {
      "name": "W7572",
      "description": "Accidental suffocation and strangulation in bed at farm While working for income"
    },
    {
      "name": "W7573",
      "description": "Accidental suffocation and strangulation in bed at farm While engaged in other types of work"
    },
    {
      "name": "W7574",
      "description": "Accidental suffocation and strangulation in bed at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7578",
      "description": "Accidental suffocation and strangulation in bed at farm While engaged in other specified activities"
    },
    {
      "name": "W7579",
      "description": "Accidental suffocation and strangulation in bed at farm During unspecified activity"
    },
    {
      "name": "W758",
      "description": "Accidental suffocation and strangulation in bed at other specified places"
    },
    {
      "name": "W7580",
      "description": "Accidental suffocation and strangulation in bed at other specified places While engaged in sports activity"
    },
    {
      "name": "W7581",
      "description": "Accidental suffocation and strangulation in bed at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7582",
      "description": "Accidental suffocation and strangulation in bed at other specified places While working for income"
    },
    {
      "name": "W7583",
      "description": "Accidental suffocation and strangulation in bed at other specified places While engaged in other types of work"
    },
    {
      "name": "W7584",
      "description": "Accidental suffocation and strangulation in bed at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7588",
      "description": "Accidental suffocation and strangulation in bed at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7589",
      "description": "Accidental suffocation and strangulation in bed at other specified places During unspecified activity"
    },
    {
      "name": "W759",
      "description": "Accidental suffocation and strangulation in bed at unspecified place"
    },
    {
      "name": "W7590",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7591",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7592",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While working for income"
    },
    {
      "name": "W7593",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7594",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7598",
      "description": "Accidental suffocation and strangulation in bed at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7599",
      "description": "Accidental suffocation and strangulation in bed at unspecified place During unspecified activity"
    },
    {
      "name": "W760",
      "description": "Other accidental hanging and strangulation at home"
    },
    {
      "name": "W7600",
      "description": "Other accidental hanging and strangulation at home While engaged in sports activity"
    },
    {
      "name": "W7601",
      "description": "Other accidental hanging and strangulation at home While engaged in leisure activity"
    },
    {
      "name": "W7602",
      "description": "Other accidental hanging and strangulation at home While working for income"
    },
    {
      "name": "W7603",
      "description": "Other accidental hanging and strangulation at home While engaged in other types of work"
    },
    {
      "name": "W7604",
      "description": "Other accidental hanging and strangulation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7608",
      "description": "Other accidental hanging and strangulation at home While engaged in other specified activities"
    },
    {
      "name": "W7609",
      "description": "Other accidental hanging and strangulation at home During unspecified activity"
    },
    {
      "name": "W761",
      "description": "Other accidental hanging and strangulation at residential institution"
    },
    {
      "name": "W7610",
      "description": "Other accidental hanging and strangulation at residential institution While engaged in sports activity"
    },
    {
      "name": "W7611",
      "description": "Other accidental hanging and strangulation at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7612",
      "description": "Other accidental hanging and strangulation at residential institution While working for income"
    },
    {
      "name": "W7613",
      "description": "Other accidental hanging and strangulation at residential institution While engaged in other types of work"
    },
    {
      "name": "W7614",
      "description": "Other accidental hanging and strangulation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7618",
      "description": "Other accidental hanging and strangulation at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7619",
      "description": "Other accidental hanging and strangulation at residential institution During unspecified activity"
    },
    {
      "name": "W762",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area"
    },
    {
      "name": "W7620",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7621",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7622",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7623",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7624",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7628",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7629",
      "description": "Other accidental hanging and strangulation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W763",
      "description": "Other accidental hanging and strangulation at sports and athletics area"
    },
    {
      "name": "W7630",
      "description": "Other accidental hanging and strangulation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7631",
      "description": "Other accidental hanging and strangulation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7632",
      "description": "Other accidental hanging and strangulation at sports and athletics area While working for income"
    },
    {
      "name": "W7633",
      "description": "Other accidental hanging and strangulation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7634",
      "description": "Other accidental hanging and strangulation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7638",
      "description": "Other accidental hanging and strangulation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7639",
      "description": "Other accidental hanging and strangulation at sports and athletics area During unspecified activity"
    },
    {
      "name": "W764",
      "description": "Other accidental hanging and strangulation at street and highway"
    },
    {
      "name": "W7640",
      "description": "Other accidental hanging and strangulation at street and highway While engaged in sports activity"
    },
    {
      "name": "W7641",
      "description": "Other accidental hanging and strangulation at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7642",
      "description": "Other accidental hanging and strangulation at street and highway While working for income"
    },
    {
      "name": "W7643",
      "description": "Other accidental hanging and strangulation at street and highway While engaged in other types of work"
    },
    {
      "name": "W7644",
      "description": "Other accidental hanging and strangulation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7648",
      "description": "Other accidental hanging and strangulation at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7649",
      "description": "Other accidental hanging and strangulation at street and highway During unspecified activity"
    },
    {
      "name": "W765",
      "description": "Other accidental hanging and strangulation at trade and service area"
    },
    {
      "name": "W7650",
      "description": "Other accidental hanging and strangulation at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7651",
      "description": "Other accidental hanging and strangulation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7652",
      "description": "Other accidental hanging and strangulation at trade and service area While working for income"
    },
    {
      "name": "W7653",
      "description": "Other accidental hanging and strangulation at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7654",
      "description": "Other accidental hanging and strangulation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7658",
      "description": "Other accidental hanging and strangulation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7659",
      "description": "Other accidental hanging and strangulation at trade and service area During unspecified activity"
    },
    {
      "name": "W766",
      "description": "Other accidental hanging and strangulation at industrial and construction area"
    },
    {
      "name": "W7660",
      "description": "Other accidental hanging and strangulation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7661",
      "description": "Other accidental hanging and strangulation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7662",
      "description": "Other accidental hanging and strangulation at industrial and construction area While working for income"
    },
    {
      "name": "W7663",
      "description": "Other accidental hanging and strangulation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7664",
      "description": "Other accidental hanging and strangulation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7668",
      "description": "Other accidental hanging and strangulation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7669",
      "description": "Other accidental hanging and strangulation at industrial and construction area During unspecified activity"
    },
    {
      "name": "W767",
      "description": "Other accidental hanging and strangulation at farm"
    },
    {
      "name": "W7670",
      "description": "Other accidental hanging and strangulation at farm While engaged in sports activity"
    },
    {
      "name": "W7671",
      "description": "Other accidental hanging and strangulation at farm While engaged in leisure activity"
    },
    {
      "name": "W7672",
      "description": "Other accidental hanging and strangulation at farm While working for income"
    },
    {
      "name": "W7673",
      "description": "Other accidental hanging and strangulation at farm While engaged in other types of work"
    },
    {
      "name": "W7674",
      "description": "Other accidental hanging and strangulation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7678",
      "description": "Other accidental hanging and strangulation at farm While engaged in other specified activities"
    },
    {
      "name": "W7679",
      "description": "Other accidental hanging and strangulation at farm During unspecified activity"
    },
    {
      "name": "W768",
      "description": "Other accidental hanging and strangulation at other specified places"
    },
    {
      "name": "W7680",
      "description": "Other accidental hanging and strangulation at other specified places While engaged in sports activity"
    },
    {
      "name": "W7681",
      "description": "Other accidental hanging and strangulation at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7682",
      "description": "Other accidental hanging and strangulation at other specified places While working for income"
    },
    {
      "name": "W7683",
      "description": "Other accidental hanging and strangulation at other specified places While engaged in other types of work"
    },
    {
      "name": "W7684",
      "description": "Other accidental hanging and strangulation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7688",
      "description": "Other accidental hanging and strangulation at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7689",
      "description": "Other accidental hanging and strangulation at other specified places During unspecified activity"
    },
    {
      "name": "W769",
      "description": "Other accidental hanging and strangulation at unspecified place"
    },
    {
      "name": "W7690",
      "description": "Other accidental hanging and strangulation at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7691",
      "description": "Other accidental hanging and strangulation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7692",
      "description": "Other accidental hanging and strangulation at unspecified place While working for income"
    },
    {
      "name": "W7693",
      "description": "Other accidental hanging and strangulation at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7694",
      "description": "Other accidental hanging and strangulation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7698",
      "description": "Other accidental hanging and strangulation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7699",
      "description": "Other accidental hanging and strangulation at unspecified place During unspecified activity"
    },
    {
      "name": "W770",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home"
    },
    {
      "name": "W7700",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While engaged in sports activity"
    },
    {
      "name": "W7701",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While engaged in leisure activity"
    },
    {
      "name": "W7702",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While working for income"
    },
    {
      "name": "W7703",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While engaged in other types of work"
    },
    {
      "name": "W7704",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7708",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home While engaged in other specified activities"
    },
    {
      "name": "W7709",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at home During unspecified activity"
    },
    {
      "name": "W771",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution"
    },
    {
      "name": "W7710",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While engaged in sports activity"
    },
    {
      "name": "W7711",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7712",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While working for income"
    },
    {
      "name": "W7713",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While engaged in other types of work"
    },
    {
      "name": "W7714",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7718",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7719",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at residential institution During unspecified activity"
    },
    {
      "name": "W772",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area"
    },
    {
      "name": "W7720",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7721",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7722",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7723",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7724",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7728",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7729",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W773",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area"
    },
    {
      "name": "W7730",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7731",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7732",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While working for income"
    },
    {
      "name": "W7733",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7734",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7738",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7739",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "W774",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway"
    },
    {
      "name": "W7740",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While engaged in sports activity"
    },
    {
      "name": "W7741",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7742",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While working for income"
    },
    {
      "name": "W7743",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While engaged in other types of work"
    },
    {
      "name": "W7744",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7748",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7749",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at street and highway During unspecified activity"
    },
    {
      "name": "W775",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area"
    },
    {
      "name": "W7750",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7751",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7752",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While working for income"
    },
    {
      "name": "W7753",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7754",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7758",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7759",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at trade and service area During unspecified activity"
    },
    {
      "name": "W776",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area"
    },
    {
      "name": "W7760",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7761",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7762",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While working for income"
    },
    {
      "name": "W7763",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7764",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7768",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7769",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "W777",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm"
    },
    {
      "name": "W7770",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While engaged in sports activity"
    },
    {
      "name": "W7771",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While engaged in leisure activity"
    },
    {
      "name": "W7772",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While working for income"
    },
    {
      "name": "W7773",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While engaged in other types of work"
    },
    {
      "name": "W7774",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7778",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm While engaged in other specified activities"
    },
    {
      "name": "W7779",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at farm During unspecified activity"
    },
    {
      "name": "W778",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places"
    },
    {
      "name": "W7780",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While engaged in sports activity"
    },
    {
      "name": "W7781",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7782",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While working for income"
    },
    {
      "name": "W7783",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While engaged in other types of work"
    },
    {
      "name": "W7784",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7788",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7789",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at other specified places During unspecified activity"
    },
    {
      "name": "W779",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place"
    },
    {
      "name": "W7790",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7791",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7792",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While working for income"
    },
    {
      "name": "W7793",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7794",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7798",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7799",
      "description": "Threat to breathing due to cave-in, falling earth and other substances at unspecified place During unspecified activity"
    },
    {
      "name": "W780",
      "description": "Inhalation of gastric contents at home"
    },
    {
      "name": "W7800",
      "description": "Inhalation of gastric contents at home While engaged in sports activity"
    },
    {
      "name": "W7801",
      "description": "Inhalation of gastric contents at home While engaged in leisure activity"
    },
    {
      "name": "W7802",
      "description": "Inhalation of gastric contents at home While working for income"
    },
    {
      "name": "W7803",
      "description": "Inhalation of gastric contents at home While engaged in other types of work"
    },
    {
      "name": "W7804",
      "description": "Inhalation of gastric contents at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7808",
      "description": "Inhalation of gastric contents at home While engaged in other specified activities"
    },
    {
      "name": "W7809",
      "description": "Inhalation of gastric contents at home During unspecified activity"
    },
    {
      "name": "W781",
      "description": "Inhalation of gastric contents at residential institution"
    },
    {
      "name": "W7810",
      "description": "Inhalation of gastric contents at residential institution While engaged in sports activity"
    },
    {
      "name": "W7811",
      "description": "Inhalation of gastric contents at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7812",
      "description": "Inhalation of gastric contents at residential institution While working for income"
    },
    {
      "name": "W7813",
      "description": "Inhalation of gastric contents at residential institution While engaged in other types of work"
    },
    {
      "name": "W7814",
      "description": "Inhalation of gastric contents at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7818",
      "description": "Inhalation of gastric contents at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7819",
      "description": "Inhalation of gastric contents at residential institution During unspecified activity"
    },
    {
      "name": "W782",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area"
    },
    {
      "name": "W7820",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7821",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7822",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W7823",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7824",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7828",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7829",
      "description": "Inhalation of gastric contents at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W783",
      "description": "Inhalation of gastric contents at sports and athletics area"
    },
    {
      "name": "W7830",
      "description": "Inhalation of gastric contents at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7831",
      "description": "Inhalation of gastric contents at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7832",
      "description": "Inhalation of gastric contents at sports and athletics area While working for income"
    },
    {
      "name": "W7833",
      "description": "Inhalation of gastric contents at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7834",
      "description": "Inhalation of gastric contents at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7838",
      "description": "Inhalation of gastric contents at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7839",
      "description": "Inhalation of gastric contents at sports and athletics area During unspecified activity"
    },
    {
      "name": "W784",
      "description": "Inhalation of gastric contents at street and highway"
    },
    {
      "name": "W7840",
      "description": "Inhalation of gastric contents at street and highway While engaged in sports activity"
    },
    {
      "name": "W7841",
      "description": "Inhalation of gastric contents at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7842",
      "description": "Inhalation of gastric contents at street and highway While working for income"
    },
    {
      "name": "W7843",
      "description": "Inhalation of gastric contents at street and highway While engaged in other types of work"
    },
    {
      "name": "W7844",
      "description": "Inhalation of gastric contents at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7848",
      "description": "Inhalation of gastric contents at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7849",
      "description": "Inhalation of gastric contents at street and highway During unspecified activity"
    },
    {
      "name": "W785",
      "description": "Inhalation of gastric contents at trade and service area"
    },
    {
      "name": "W7850",
      "description": "Inhalation of gastric contents at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7851",
      "description": "Inhalation of gastric contents at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7852",
      "description": "Inhalation of gastric contents at trade and service area While working for income"
    },
    {
      "name": "W7853",
      "description": "Inhalation of gastric contents at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7854",
      "description": "Inhalation of gastric contents at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7858",
      "description": "Inhalation of gastric contents at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7859",
      "description": "Inhalation of gastric contents at trade and service area During unspecified activity"
    },
    {
      "name": "W786",
      "description": "Inhalation of gastric contents at industrial and construction area"
    },
    {
      "name": "W7860",
      "description": "Inhalation of gastric contents at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7861",
      "description": "Inhalation of gastric contents at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7862",
      "description": "Inhalation of gastric contents at industrial and construction area While working for income"
    },
    {
      "name": "W7863",
      "description": "Inhalation of gastric contents at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7864",
      "description": "Inhalation of gastric contents at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7868",
      "description": "Inhalation of gastric contents at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7869",
      "description": "Inhalation of gastric contents at industrial and construction area During unspecified activity"
    },
    {
      "name": "W787",
      "description": "Inhalation of gastric contents at farm"
    },
    {
      "name": "W7870",
      "description": "Inhalation of gastric contents at farm While engaged in sports activity"
    },
    {
      "name": "W7871",
      "description": "Inhalation of gastric contents at farm While engaged in leisure activity"
    },
    {
      "name": "W7872",
      "description": "Inhalation of gastric contents at farm While working for income"
    },
    {
      "name": "W7873",
      "description": "Inhalation of gastric contents at farm While engaged in other types of work"
    },
    {
      "name": "W7874",
      "description": "Inhalation of gastric contents at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7878",
      "description": "Inhalation of gastric contents at farm While engaged in other specified activities"
    },
    {
      "name": "W7879",
      "description": "Inhalation of gastric contents at farm During unspecified activity"
    },
    {
      "name": "W788",
      "description": "Inhalation of gastric contents at other specified places"
    },
    {
      "name": "W7880",
      "description": "Inhalation of gastric contents at other specified places While engaged in sports activity"
    },
    {
      "name": "W7881",
      "description": "Inhalation of gastric contents at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7882",
      "description": "Inhalation of gastric contents at other specified places While working for income"
    },
    {
      "name": "W7883",
      "description": "Inhalation of gastric contents at other specified places While engaged in other types of work"
    },
    {
      "name": "W7884",
      "description": "Inhalation of gastric contents at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7888",
      "description": "Inhalation of gastric contents at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7889",
      "description": "Inhalation of gastric contents at other specified places During unspecified activity"
    },
    {
      "name": "W789",
      "description": "Inhalation of gastric contents at unspecified place"
    },
    {
      "name": "W7890",
      "description": "Inhalation of gastric contents at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7891",
      "description": "Inhalation of gastric contents at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7892",
      "description": "Inhalation of gastric contents at unspecified place While working for income"
    },
    {
      "name": "W7893",
      "description": "Inhalation of gastric contents at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7894",
      "description": "Inhalation of gastric contents at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7898",
      "description": "Inhalation of gastric contents at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7899",
      "description": "Inhalation of gastric contents at unspecified place During unspecified activity"
    },
    {
      "name": "W790",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home"
    },
    {
      "name": "W7900",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While engaged in sports activity"
    },
    {
      "name": "W7901",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While engaged in leisure activity"
    },
    {
      "name": "W7902",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While working for income"
    },
    {
      "name": "W7903",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While engaged in other types of work"
    },
    {
      "name": "W7904",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7908",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home While engaged in other specified activities"
    },
    {
      "name": "W7909",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at home During unspecified activity"
    },
    {
      "name": "W791",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution"
    },
    {
      "name": "W7910",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While engaged in sports activity"
    },
    {
      "name": "W7911",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While engaged in leisure activity"
    },
    {
      "name": "W7912",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While working for income"
    },
    {
      "name": "W7913",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While engaged in other types of work"
    },
    {
      "name": "W7914",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7918",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution While engaged in other specified activities"
    },
    {
      "name": "W7919",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at residential institution During unspecified activity"
    },
    {
      "name": "W792",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area"
    },
    {
      "name": "W7920",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W7921",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W7922",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While working for income "
    },
    {
      "name": "W7923",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W7924",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7928",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W7929",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W793",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area"
    },
    {
      "name": "W7930",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W7931",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W7932",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While working for income"
    },
    {
      "name": "W7933",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W7934",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7938",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W7939",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at sports and athletics area During unspecified activity"
    },
    {
      "name": "W794",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway"
    },
    {
      "name": "W7940",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While engaged in sports activity"
    },
    {
      "name": "W7941",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While engaged in leisure activity"
    },
    {
      "name": "W7942",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While working for income"
    },
    {
      "name": "W7943",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While engaged in other types of work"
    },
    {
      "name": "W7944",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7948",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway While engaged in other specified activities"
    },
    {
      "name": "W7949",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at street and highway During unspecified activity"
    },
    {
      "name": "W795",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area"
    },
    {
      "name": "W7950",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While engaged in sports activity"
    },
    {
      "name": "W7951",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W7952",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While working for income"
    },
    {
      "name": "W7953",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While engaged in other types of work"
    },
    {
      "name": "W7954",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7958",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W7959",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at trade and service area During unspecified activity"
    },
    {
      "name": "W796",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area"
    },
    {
      "name": "W7960",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W7961",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W7962",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While working for income"
    },
    {
      "name": "W7963",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W7964",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7968",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W7969",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at industrial and construction area During unspecified activity"
    },
    {
      "name": "W797",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm"
    },
    {
      "name": "W7970",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While engaged in sports activity"
    },
    {
      "name": "W7971",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While engaged in leisure activity"
    },
    {
      "name": "W7972",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While working for income"
    },
    {
      "name": "W7973",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While engaged in other types of work"
    },
    {
      "name": "W7974",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7978",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm While engaged in other specified activities"
    },
    {
      "name": "W7979",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at farm During unspecified activity"
    },
    {
      "name": "W798",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places"
    },
    {
      "name": "W7980",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While engaged in sports activity"
    },
    {
      "name": "W7981",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While engaged in leisure activity"
    },
    {
      "name": "W7982",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While working for income"
    },
    {
      "name": "W7983",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While engaged in other types of work"
    },
    {
      "name": "W7984",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7988",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places While engaged in other specified activities"
    },
    {
      "name": "W7989",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at other specified places During unspecified activity"
    },
    {
      "name": "W799",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place"
    },
    {
      "name": "W7990",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While engaged in sports activity"
    },
    {
      "name": "W7991",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W7992",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While working for income"
    },
    {
      "name": "W7993",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While engaged in other types of work"
    },
    {
      "name": "W7994",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W7998",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W7999",
      "description": "Inhalation and ingestion of food causing obstruction of respiratory tract at unspecified place During unspecified activity"
    },
    {
      "name": "W800",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home"
    },
    {
      "name": "W8000",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While engaged in sports activity"
    },
    {
      "name": "W8001",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While engaged in leisure activity"
    },
    {
      "name": "W8002",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While working for income"
    },
    {
      "name": "W8003",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While engaged in other types of work"
    },
    {
      "name": "W8004",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8008",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home While engaged in other specified activities"
    },
    {
      "name": "W8009",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at home During unspecified activity"
    },
    {
      "name": "W801",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution"
    },
    {
      "name": "W8010",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While engaged in sports activity"
    },
    {
      "name": "W8011",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8012",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While working for income"
    },
    {
      "name": "W8013",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While engaged in other types of work"
    },
    {
      "name": "W8014",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8018",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8019",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at residential institution During unspecified activity"
    },
    {
      "name": "W802",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area"
    },
    {
      "name": "W8020",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8021",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8022",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8023",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8024",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8028",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8029",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W803",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area"
    },
    {
      "name": "W8030",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8031",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8032",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While working for income"
    },
    {
      "name": "W8033",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8034",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8038",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8039",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at sports and athletics area During unspecified activity"
    },
    {
      "name": "W804",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway"
    },
    {
      "name": "W8040",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While engaged in sports activity"
    },
    {
      "name": "W8041",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8042",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While working for income"
    },
    {
      "name": "W8043",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While engaged in other types of work"
    },
    {
      "name": "W8044",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8048",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8049",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at street and highway During unspecified activity"
    },
    {
      "name": "W805",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area"
    },
    {
      "name": "W8050",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8051",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8052",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While working for income"
    },
    {
      "name": "W8053",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8054",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8058",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8059",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at trade and service area During unspecified activity"
    },
    {
      "name": "W806",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area"
    },
    {
      "name": "W8060",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8061",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8062",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While working for income"
    },
    {
      "name": "W8063",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8064",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8068",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8069",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at industrial and construction area During unspecified activity"
    },
    {
      "name": "W807",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm"
    },
    {
      "name": "W8070",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While engaged in sports activity"
    },
    {
      "name": "W8071",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While engaged in leisure activity"
    },
    {
      "name": "W8072",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While working for income"
    },
    {
      "name": "W8073",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While engaged in other types of work"
    },
    {
      "name": "W8074",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8078",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm While engaged in other specified activities"
    },
    {
      "name": "W8079",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at farm During unspecified activity"
    },
    {
      "name": "W808",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places"
    },
    {
      "name": "W8080",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While engaged in sports activity"
    },
    {
      "name": "W8081",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8082",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While working for income"
    },
    {
      "name": "W8083",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While engaged in other types of work"
    },
    {
      "name": "W8084",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8088",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8089",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at other specified places During unspecified activity"
    },
    {
      "name": "W809",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place"
    },
    {
      "name": "W8090",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8091",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8092",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While working for income"
    },
    {
      "name": "W8093",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8094",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8098",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8099",
      "description": "Inhalation and ingestion of other objects causing obstruction of respiratory tract at unspecified place During unspecified activity"
    },
    {
      "name": "W810",
      "description": "Confined to or trapped in a low-oxygen environment at home"
    },
    {
      "name": "W8100",
      "description": "Confined to or trapped in a low-oxygen environment at home While engaged in sports activity"
    },
    {
      "name": "W8101",
      "description": "Confined to or trapped in a low-oxygen environment at home While engaged in leisure activity"
    },
    {
      "name": "W8102",
      "description": "Confined to or trapped in a low-oxygen environment at home While working for income"
    },
    {
      "name": "W8103",
      "description": "Confined to or trapped in a low-oxygen environment at home While engaged in other types of work"
    },
    {
      "name": "W8104",
      "description": "Confined to or trapped in a low-oxygen environment at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8108",
      "description": "Confined to or trapped in a low-oxygen environment at home While engaged in other specified activities"
    },
    {
      "name": "W8109",
      "description": "Confined to or trapped in a low-oxygen environment at home During unspecified activity"
    },
    {
      "name": "W811",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution"
    },
    {
      "name": "W8110",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While engaged in sports activity"
    },
    {
      "name": "W8111",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8112",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While working for income"
    },
    {
      "name": "W8113",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While engaged in other types of work"
    },
    {
      "name": "W8114",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8118",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8119",
      "description": "Confined to or trapped in a low-oxygen environment at residential institution During unspecified activity"
    },
    {
      "name": "W812",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area"
    },
    {
      "name": "W8120",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8121",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8122",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8123",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8124",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8128",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8129",
      "description": "Confined to or trapped in a low-oxygen environment at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W813",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area"
    },
    {
      "name": "W8130",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8131",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8132",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While working for income"
    },
    {
      "name": "W8133",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8134",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8138",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8139",
      "description": "Confined to or trapped in a low-oxygen environment at sports and athletics area During unspecified activity"
    },
    {
      "name": "W814",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway"
    },
    {
      "name": "W8140",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While engaged in sports activity"
    },
    {
      "name": "W8141",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8142",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While working for income"
    },
    {
      "name": "W8143",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While engaged in other types of work"
    },
    {
      "name": "W8144",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8148",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8149",
      "description": "Confined to or trapped in a low-oxygen environment at street and highway During unspecified activity"
    },
    {
      "name": "W815",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area"
    },
    {
      "name": "W8150",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8151",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8152",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While working for income"
    },
    {
      "name": "W8153",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8154",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8158",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8159",
      "description": "Confined to or trapped in a low-oxygen environment at trade and service area During unspecified activity"
    },
    {
      "name": "W816",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area"
    },
    {
      "name": "W8160",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8161",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8162",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While working for income"
    },
    {
      "name": "W8163",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8164",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8168",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8169",
      "description": "Confined to or trapped in a low-oxygen environment at industrial and construction area During unspecified activity"
    },
    {
      "name": "W817",
      "description": "Confined to or trapped in a low-oxygen environment at farm"
    },
    {
      "name": "W8170",
      "description": "Confined to or trapped in a low-oxygen environment at farm While engaged in sports activity"
    },
    {
      "name": "W8171",
      "description": "Confined to or trapped in a low-oxygen environment at farm While engaged in leisure activity"
    },
    {
      "name": "W8172",
      "description": "Confined to or trapped in a low-oxygen environment at farm While working for income"
    },
    {
      "name": "W8173",
      "description": "Confined to or trapped in a low-oxygen environment at farm While engaged in other types of work"
    },
    {
      "name": "W8174",
      "description": "Confined to or trapped in a low-oxygen environment at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8178",
      "description": "Confined to or trapped in a low-oxygen environment at farm While engaged in other specified activities"
    },
    {
      "name": "W8179",
      "description": "Confined to or trapped in a low-oxygen environment at farm During unspecified activity"
    },
    {
      "name": "W818",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places"
    },
    {
      "name": "W8180",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While engaged in sports activity"
    },
    {
      "name": "W8181",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8182",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While working for income"
    },
    {
      "name": "W8183",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While engaged in other types of work"
    },
    {
      "name": "W8184",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8188",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8189",
      "description": "Confined to or trapped in a low-oxygen environment at other specified places During unspecified activity"
    },
    {
      "name": "W819",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place"
    },
    {
      "name": "W8190",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8191",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8192",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While working for income"
    },
    {
      "name": "W8193",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8194",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8198",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8199",
      "description": "Confined to or trapped in a low-oxygen environment at unspecified place During unspecified activity"
    },
    {
      "name": "W830",
      "description": "Other specified threats to breathing at home"
    },
    {
      "name": "W8300",
      "description": "Other specified threats to breathing at home While engaged in sports activity"
    },
    {
      "name": "W8301",
      "description": "Other specified threats to breathing at home While engaged in leisure activity"
    },
    {
      "name": "W8302",
      "description": "Other specified threats to breathing at home While working for income"
    },
    {
      "name": "W8303",
      "description": "Other specified threats to breathing at home While engaged in other types of work"
    },
    {
      "name": "W8304",
      "description": "Other specified threats to breathing at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8308",
      "description": "Other specified threats to breathing at home While engaged in other specified activities"
    },
    {
      "name": "W8309",
      "description": "Other specified threats to breathing at home During unspecified activity"
    },
    {
      "name": "W831",
      "description": "Other specified threats to breathing at residential institution"
    },
    {
      "name": "W8310",
      "description": "Other specified threats to breathing at residential institution While engaged in sports activity"
    },
    {
      "name": "W8311",
      "description": "Other specified threats to breathing at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8312",
      "description": "Other specified threats to breathing at residential institution While working for income"
    },
    {
      "name": "W8313",
      "description": "Other specified threats to breathing at residential institution While engaged in other types of work"
    },
    {
      "name": "W8314",
      "description": "Other specified threats to breathing at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8318",
      "description": "Other specified threats to breathing at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8319",
      "description": "Other specified threats to breathing at residential institution During unspecified activity"
    },
    {
      "name": "W832",
      "description": "Other specified threats to breathing at school,other institution and public administrative area"
    },
    {
      "name": "W8320",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8321",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8322",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8323",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8324",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8328",
      "description": "Other specified threats to breathing at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8329",
      "description": "Other specified threats to breathing at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W833",
      "description": "Other specified threats to breathing at sports and athletics area"
    },
    {
      "name": "W8330",
      "description": "Other specified threats to breathing at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8331",
      "description": "Other specified threats to breathing at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8332",
      "description": "Other specified threats to breathing at sports and athletics area While working for income"
    },
    {
      "name": "W8333",
      "description": "Other specified threats to breathing at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8334",
      "description": "Other specified threats to breathing at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8338",
      "description": "Other specified threats to breathing at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8339",
      "description": "Other specified threats to breathing at sports and athletics area During unspecified activity"
    },
    {
      "name": "W834",
      "description": "Other specified threats to breathing at street and highway"
    },
    {
      "name": "W8340",
      "description": "Other specified threats to breathing at street and highway While engaged in sports activity"
    },
    {
      "name": "W8341",
      "description": "Other specified threats to breathing at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8342",
      "description": "Other specified threats to breathing at street and highway While working for income"
    },
    {
      "name": "W8343",
      "description": "Other specified threats to breathing at street and highway While engaged in other types of work"
    },
    {
      "name": "W8344",
      "description": "Other specified threats to breathing at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8348",
      "description": "Other specified threats to breathing at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8349",
      "description": "Other specified threats to breathing at street and highway During unspecified activity"
    },
    {
      "name": "W835",
      "description": "Other specified threats to breathing at trade and service area"
    },
    {
      "name": "W8350",
      "description": "Other specified threats to breathing at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8351",
      "description": "Other specified threats to breathing at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8352",
      "description": "Other specified threats to breathing at trade and service area While working for income"
    },
    {
      "name": "W8353",
      "description": "Other specified threats to breathing at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8354",
      "description": "Other specified threats to breathing at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8358",
      "description": "Other specified threats to breathing at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8359",
      "description": "Other specified threats to breathing at trade and service area During unspecified activity"
    },
    {
      "name": "W836",
      "description": "Other specified threats to breathing at industrial and construction area"
    },
    {
      "name": "W8360",
      "description": "Other specified threats to breathing at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8361",
      "description": "Other specified threats to breathing at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8362",
      "description": "Other specified threats to breathing at industrial and construction area While working for income"
    },
    {
      "name": "W8363",
      "description": "Other specified threats to breathing at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8364",
      "description": "Other specified threats to breathing at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8368",
      "description": "Other specified threats to breathing at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8369",
      "description": "Other specified threats to breathing at industrial and construction area During unspecified activity"
    },
    {
      "name": "W837",
      "description": "Other specified threats to breathing at farm"
    },
    {
      "name": "W8370",
      "description": "Other specified threats to breathing at farm While engaged in sports activity"
    },
    {
      "name": "W8371",
      "description": "Other specified threats to breathing at farm While engaged in leisure activity"
    },
    {
      "name": "W8372",
      "description": "Other specified threats to breathing at farm While working for income"
    },
    {
      "name": "W8373",
      "description": "Other specified threats to breathing at farm While engaged in other types of work"
    },
    {
      "name": "W8374",
      "description": "Other specified threats to breathing at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8378",
      "description": "Other specified threats to breathing at farm While engaged in other specified activities"
    },
    {
      "name": "W8379",
      "description": "Other specified threats to breathing at farm During unspecified activity"
    },
    {
      "name": "W838",
      "description": "Other specified threats to breathing at other specified places"
    },
    {
      "name": "W8380",
      "description": "Other specified threats to breathing at other specified places While engaged in sports activity"
    },
    {
      "name": "W8381",
      "description": "Other specified threats to breathing at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8382",
      "description": "Other specified threats to breathing at other specified places While working for income"
    },
    {
      "name": "W8383",
      "description": "Other specified threats to breathing at other specified places While engaged in other types of work"
    },
    {
      "name": "W8384",
      "description": "Other specified threats to breathing at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8388",
      "description": "Other specified threats to breathing at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8389",
      "description": "Other specified threats to breathing at other specified places During unspecified activity"
    },
    {
      "name": "W839",
      "description": "Other specified threats to breathing at unspecified place"
    },
    {
      "name": "W8390",
      "description": "Other specified threats to breathing at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8391",
      "description": "Other specified threats to breathing at unspecified place  While engaged in leisure activity"
    },
    {
      "name": "W8392",
      "description": "Other specified threats to breathing at unspecified place While working for income"
    },
    {
      "name": "W8393",
      "description": "Other specified threats to breathing at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8394",
      "description": "Other specified threats to breathing at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8398",
      "description": "Other specified threats to breathing at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8399",
      "description": "Other specified threats to breathing at unspecified place During unspecified activity"
    },
    {
      "name": "W840",
      "description": "Unspecified threats to breathing at home"
    },
    {
      "name": "W8400",
      "description": "Unspecified threats to breathing at home While engaged in sports activity"
    },
    {
      "name": "W8401",
      "description": "Unspecified threats to breathing at home While engaged in leisure activity"
    },
    {
      "name": "W8402",
      "description": "Unspecified threats to breathing at home While working for income"
    },
    {
      "name": "W8403",
      "description": "Unspecified threats to breathing at home While engaged in other types of work"
    },
    {
      "name": "W8404",
      "description": "Unspecified threats to breathing at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8408",
      "description": "Unspecified threats to breathing at home While engaged in other specified activities"
    },
    {
      "name": "W8409",
      "description": "Unspecified threats to breathing at home During unspecified activity"
    },
    {
      "name": "W841",
      "description": "Unspecified threats to breathing at residential institution"
    },
    {
      "name": "W8410",
      "description": "Unspecified threats to breathing at residential institution While engaged in sports activity"
    },
    {
      "name": "W8411",
      "description": "Unspecified threats to breathing at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8412",
      "description": "Unspecified threats to breathing at residential institution While working for income"
    },
    {
      "name": "W8413",
      "description": "Unspecified threats to breathing at residential institution While engaged in other types of work"
    },
    {
      "name": "W8414",
      "description": "Unspecified threats to breathing at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8418",
      "description": "Unspecified threats to breathing at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8419",
      "description": "Unspecified threats to breathing at residential institution During unspecified activity"
    },
    {
      "name": "W842",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area"
    },
    {
      "name": "W8420",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8421",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8422",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8423",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8424",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8428",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8429",
      "description": "Unspecified threats to breathing at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W843",
      "description": "Unspecified threats to breathing at sports and athletics area"
    },
    {
      "name": "W8430",
      "description": "Unspecified threats to breathing at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8431",
      "description": "Unspecified threats to breathing at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8432",
      "description": "Unspecified threats to breathing at sports and athletics area While working for income"
    },
    {
      "name": "W8433",
      "description": "Unspecified threats to breathing at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8434",
      "description": "Unspecified threats to breathing at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8438",
      "description": "Unspecified threats to breathing at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8439",
      "description": "Unspecified threats to breathing at sports and athletics area During unspecified activity"
    },
    {
      "name": "W844",
      "description": "Unspecified threats to breathing at street and highway"
    },
    {
      "name": "W8440",
      "description": "Unspecified threats to breathing at street and highway While engaged in sports activity"
    },
    {
      "name": "W8441",
      "description": "Unspecified threats to breathing at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8442",
      "description": "Unspecified threats to breathing at street and highway While working for income"
    },
    {
      "name": "W8443",
      "description": "Unspecified threats to breathing at street and highway While engaged in other types of work"
    },
    {
      "name": "W8444",
      "description": "Unspecified threats to breathing at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8448",
      "description": "Unspecified threats to breathing at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8449",
      "description": "Unspecified threats to breathing at street and highway During unspecified activity"
    },
    {
      "name": "W845",
      "description": "Unspecified threats to breathing at trade and service area"
    },
    {
      "name": "W8450",
      "description": "Unspecified threats to breathing at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8451",
      "description": "Unspecified threats to breathing at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8452",
      "description": "Unspecified threats to breathing at trade and service area While working for income"
    },
    {
      "name": "W8453",
      "description": "Unspecified threats to breathing at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8454",
      "description": "Unspecified threats to breathing at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8458",
      "description": "Unspecified threats to breathing at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8459",
      "description": "Unspecified threats to breathing at trade and service area During unspecified activity"
    },
    {
      "name": "W846",
      "description": "Unspecified threats to breathing at industrial and construction area"
    },
    {
      "name": "W8460",
      "description": "Unspecified threats to breathing at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8461",
      "description": "Unspecified threats to breathing at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8462",
      "description": "Unspecified threats to breathing at industrial and construction area While working for income"
    },
    {
      "name": "W8463",
      "description": "Unspecified threats to breathing at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8464",
      "description": "Unspecified threats to breathing at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8468",
      "description": "Unspecified threats to breathing at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8469",
      "description": "Unspecified threats to breathing at industrial and construction area During unspecified activity"
    },
    {
      "name": "W847",
      "description": "Unspecified threats to breathing at farm"
    },
    {
      "name": "W8470",
      "description": "Unspecified threats to breathing at farm While engaged in sports activity"
    },
    {
      "name": "W8471",
      "description": "Unspecified threats to breathing at farm While engaged in leisure activity"
    },
    {
      "name": "W8472",
      "description": "Unspecified threats to breathing at farm While working for income"
    },
    {
      "name": "W8473",
      "description": "Unspecified threats to breathing at farm While engaged in other types of work"
    },
    {
      "name": "W8474",
      "description": "Unspecified threats to breathing at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8478",
      "description": "Unspecified threats to breathing at farm While engaged in other specified activities"
    },
    {
      "name": "W8479",
      "description": "Unspecified threats to breathing at farm During unspecified activity"
    },
    {
      "name": "W848",
      "description": "Unspecified threats to breathing at other specified places"
    },
    {
      "name": "W8480",
      "description": "Unspecified threats to breathing at other specified places While engaged in sports activity"
    },
    {
      "name": "W8481",
      "description": "Unspecified threats to breathing at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8482",
      "description": "Unspecified threats to breathing at other specified places While working for income"
    },
    {
      "name": "W8483",
      "description": "Unspecified threats to breathing at other specified places While engaged in other types of work"
    },
    {
      "name": "W8484",
      "description": "Unspecified threats to breathing at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8488",
      "description": "Unspecified threats to breathing at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8489",
      "description": "Unspecified threats to breathing at other specified places During unspecified activity"
    },
    {
      "name": "W849",
      "description": "Unspecified threats to breathing at unspecified place"
    },
    {
      "name": "W8490",
      "description": "Unspecified threats to breathing at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8491",
      "description": "Unspecified threats to breathing at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8492",
      "description": "Unspecified threats to breathing at unspecified place While working for income"
    },
    {
      "name": "W8493",
      "description": "Unspecified threats to breathing at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8494",
      "description": "Unspecified threats to breathing at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8498",
      "description": "Unspecified threats to breathing at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8499",
      "description": "Unspecified threats to breathing at unspecified place During unspecified activity"
    },
    {
      "name": "W850",
      "description": "Exposure to electric transmission lines at home"
    },
    {
      "name": "W8500",
      "description": "Exposure to electric transmission lines at home While engaged in sports activity"
    },
    {
      "name": "W8501",
      "description": "Exposure to electric transmission lines at home While engaged in leisure activity"
    },
    {
      "name": "W8502",
      "description": "Exposure to electric transmission lines at home While working for income"
    },
    {
      "name": "W8503",
      "description": "Exposure to electric transmission lines at home While engaged in other types of work"
    },
    {
      "name": "W8504",
      "description": "Exposure to electric transmission lines at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8508",
      "description": "Exposure to electric transmission lines at home While engaged in other specified activities"
    },
    {
      "name": "W8509",
      "description": "Exposure to electric transmission lines at home During unspecified activity"
    },
    {
      "name": "W851",
      "description": "Exposure to electric transmission lines at residential institution"
    },
    {
      "name": "W8510",
      "description": "Exposure to electric transmission lines at residential institution While engaged in sports activity"
    },
    {
      "name": "W8511",
      "description": "Exposure to electric transmission lines at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8512",
      "description": "Exposure to electric transmission lines at residential institution While working for income"
    },
    {
      "name": "W8513",
      "description": "Exposure to electric transmission lines at residential institution While engaged in other types of work"
    },
    {
      "name": "W8514",
      "description": "Exposure to electric transmission lines at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8518",
      "description": "Exposure to electric transmission lines at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8519",
      "description": "Exposure to electric transmission lines at residential institution During unspecified activity"
    },
    {
      "name": "W852",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area"
    },
    {
      "name": "W8520",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8521",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8522",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8523",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8524",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8528",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8529",
      "description": "Exposure to electric transmission lines at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W853",
      "description": "Exposure to electric transmission lines at sports and athletics area"
    },
    {
      "name": "W8530",
      "description": "Exposure to electric transmission lines at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8531",
      "description": "Exposure to electric transmission lines at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8532",
      "description": "Exposure to electric transmission lines at sports and athletics area While working for income"
    },
    {
      "name": "W8533",
      "description": "Exposure to electric transmission lines at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8534",
      "description": "Exposure to electric transmission lines at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8538",
      "description": "Exposure to electric transmission lines at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8539",
      "description": "Exposure to electric transmission lines at sports and athletics area  During unspecified activity"
    },
    {
      "name": "W854",
      "description": "Exposure to electric transmission lines at street and highway"
    },
    {
      "name": "W8540",
      "description": "Exposure to electric transmission lines at street and highway While engaged in sports activity"
    },
    {
      "name": "W8541",
      "description": "Exposure to electric transmission lines at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8542",
      "description": "Exposure to electric transmission lines at street and highway While working for income"
    },
    {
      "name": "W8543",
      "description": "Exposure to electric transmission lines at street and highway While engaged in other types of work"
    },
    {
      "name": "W8544",
      "description": "Exposure to electric transmission lines at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8548",
      "description": "Exposure to electric transmission lines at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8549",
      "description": "Exposure to electric transmission lines at street and highway During unspecified activity"
    },
    {
      "name": "W855",
      "description": "Exposure to electric transmission lines at trade and service area"
    },
    {
      "name": "W8550",
      "description": "Exposure to electric transmission lines at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8551",
      "description": "Exposure to electric transmission lines at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8552",
      "description": "Exposure to electric transmission lines at trade and service area While working for income"
    },
    {
      "name": "W8553",
      "description": "Exposure to electric transmission lines at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8554",
      "description": "Exposure to electric transmission lines at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8558",
      "description": "Exposure to electric transmission lines at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8559",
      "description": "Exposure to electric transmission lines at trade and service area During unspecified activity"
    },
    {
      "name": "W856",
      "description": "Exposure to electric transmission lines at industrial and construction area"
    },
    {
      "name": "W8560",
      "description": "Exposure to electric transmission lines at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8561",
      "description": "Exposure to electric transmission lines at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8562",
      "description": "Exposure to electric transmission lines at industrial and construction area While working for income"
    },
    {
      "name": "W8563",
      "description": "Exposure to electric transmission lines at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8564",
      "description": "Exposure to electric transmission lines at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8568",
      "description": "Exposure to electric transmission lines at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8569",
      "description": "Exposure to electric transmission lines at industrial and construction area During unspecified activity"
    },
    {
      "name": "W857",
      "description": "Exposure to electric transmission lines at farm"
    },
    {
      "name": "W8570",
      "description": "Exposure to electric transmission lines at farm While engaged in sports activity"
    },
    {
      "name": "W8571",
      "description": "Exposure to electric transmission lines at farm While engaged in leisure activity"
    },
    {
      "name": "W8572",
      "description": "Exposure to electric transmission lines at farm While working for income"
    },
    {
      "name": "W8573",
      "description": "Exposure to electric transmission lines at farm While engaged in other types of work"
    },
    {
      "name": "W8574",
      "description": "Exposure to electric transmission lines at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8578",
      "description": "Exposure to electric transmission lines at farm While engaged in other specified activities"
    },
    {
      "name": "W8579",
      "description": "Exposure to electric transmission lines at farm During unspecified activity"
    },
    {
      "name": "W858",
      "description": "Exposure to electric transmission lines at other specified places"
    },
    {
      "name": "W8580",
      "description": "Exposure to electric transmission lines at other specified places While engaged in sports activity"
    },
    {
      "name": "W8581",
      "description": "Exposure to electric transmission lines at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8582",
      "description": "Exposure to electric transmission lines at other specified places While working for income"
    },
    {
      "name": "W8583",
      "description": "Exposure to electric transmission lines at other specified places While engaged in other types of work"
    },
    {
      "name": "W8584",
      "description": "Exposure to electric transmission lines at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8588",
      "description": "Exposure to electric transmission lines at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8589",
      "description": "Exposure to electric transmission lines at other specified places During unspecified activity"
    },
    {
      "name": "W859",
      "description": "Exposure to electric transmission lines at unspecified place"
    },
    {
      "name": "W8590",
      "description": "Exposure to electric transmission lines at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8591",
      "description": "Exposure to electric transmission lines at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8592",
      "description": "Exposure to electric transmission lines at unspecified place While working for income"
    },
    {
      "name": "W8593",
      "description": "Exposure to electric transmission lines at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8594",
      "description": "Exposure to electric transmission lines at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8598",
      "description": "Exposure to electric transmission lines at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8599",
      "description": "Exposure to electric transmission lines at unspecified place During unspecified activity"
    },
    {
      "name": "W860",
      "description": "Exposure to other specified electric current at home"
    },
    {
      "name": "W8600",
      "description": "Exposure to other specified electric current at home While engaged in sports activity"
    },
    {
      "name": "W8601",
      "description": "Exposure to other specified electric current at home While engaged in leisure activity"
    },
    {
      "name": "W8602",
      "description": "Exposure to other specified electric current at home While working for income"
    },
    {
      "name": "W8603",
      "description": "Exposure to other specified electric current at home While engaged in other types of work"
    },
    {
      "name": "W8604",
      "description": "Exposure to other specified electric current at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8608",
      "description": "Exposure to other specified electric current at home While engaged in other specified activities"
    },
    {
      "name": "W8609",
      "description": "Exposure to other specified electric current at home During unspecified activity"
    },
    {
      "name": "W861",
      "description": "Exposure to other specified electric current at residential institution"
    },
    {
      "name": "W8610",
      "description": "Exposure to other specified electric current at residential institution While engaged in sports activity"
    },
    {
      "name": "W8611",
      "description": "Exposure to other specified electric current at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8612",
      "description": "Exposure to other specified electric current at residential institution While working for income"
    },
    {
      "name": "W8613",
      "description": "Exposure to other specified electric current at residential institution While engaged in other types of work"
    },
    {
      "name": "W8614",
      "description": "Exposure to other specified electric current at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8618",
      "description": "Exposure to other specified electric current at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8619",
      "description": "Exposure to other specified electric current at residential institution During unspecified activity"
    },
    {
      "name": "W862",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area"
    },
    {
      "name": "W8620",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8621",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8622",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8623",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8624",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8628",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8629",
      "description": "Exposure to other specified electric current at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W863",
      "description": "Exposure to other specified electric current at sports and athletics area"
    },
    {
      "name": "W8630",
      "description": "Exposure to other specified electric current at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8631",
      "description": "Exposure to other specified electric current at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8632",
      "description": "Exposure to other specified electric current at sports and athletics area While working for income"
    },
    {
      "name": "W8633",
      "description": "Exposure to other specified electric current at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8634",
      "description": "Exposure to other specified electric current at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8638",
      "description": "Exposure to other specified electric current at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8639",
      "description": "Exposure to other specified electric current at sports and athletics area During unspecified activity"
    },
    {
      "name": "W864",
      "description": "Exposure to other specified electric current at street and highway"
    },
    {
      "name": "W8640",
      "description": "Exposure to other specified electric current at street and highway While engaged in sports activity"
    },
    {
      "name": "W8641",
      "description": "Exposure to other specified electric current at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8642",
      "description": "Exposure to other specified electric current at street and highway While working for income"
    },
    {
      "name": "W8643",
      "description": "Exposure to other specified electric current at street and highway While engaged in other types of work"
    },
    {
      "name": "W8644",
      "description": "Exposure to other specified electric current at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8648",
      "description": "Exposure to other specified electric current at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8649",
      "description": "Exposure to other specified electric current at street and highway During unspecified activity"
    },
    {
      "name": "W865",
      "description": "Exposure to other specified electric current at trade and service area"
    },
    {
      "name": "W8650",
      "description": "Exposure to other specified electric current at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8651",
      "description": "Exposure to other specified electric current at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8652",
      "description": "Exposure to other specified electric current at trade and service area While working for income"
    },
    {
      "name": "W8653",
      "description": "Exposure to other specified electric current at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8654",
      "description": "Exposure to other specified electric current at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8658",
      "description": "Exposure to other specified electric current at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8659",
      "description": "Exposure to other specified electric current at trade and service area During unspecified activity"
    },
    {
      "name": "W866",
      "description": "Exposure to other specified electric current at industrial and construction area"
    },
    {
      "name": "W8660",
      "description": "Exposure to other specified electric current at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8661",
      "description": "Exposure to other specified electric current at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8662",
      "description": "Exposure to other specified electric current at industrial and construction area While working for income"
    },
    {
      "name": "W8663",
      "description": "Exposure to other specified electric current at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8664",
      "description": "Exposure to other specified electric current at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8668",
      "description": "Exposure to other specified electric current at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8669",
      "description": "Exposure to other specified electric current at industrial and construction area During unspecified activity"
    },
    {
      "name": "W867",
      "description": "Exposure to other specified electric current at farm"
    },
    {
      "name": "W8670",
      "description": "Exposure to other specified electric current at farm While engaged in sports activity"
    },
    {
      "name": "W8671",
      "description": "Exposure to other specified electric current at farm While engaged in leisure activity"
    },
    {
      "name": "W8672",
      "description": "Exposure to other specified electric current at farm While working for income"
    },
    {
      "name": "W8673",
      "description": "Exposure to other specified electric current at farm While engaged in other types of work"
    },
    {
      "name": "W8674",
      "description": "Exposure to other specified electric current at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8678",
      "description": "Exposure to other specified electric current at farm While engaged in other specified activities"
    },
    {
      "name": "W8679",
      "description": "Exposure to other specified electric current at farm During unspecified activity"
    },
    {
      "name": "W868",
      "description": "Exposure to other specified electric current at other specified places"
    },
    {
      "name": "W8680",
      "description": "Exposure to other specified electric current at other specified places While engaged in sports activity"
    },
    {
      "name": "W8681",
      "description": "Exposure to other specified electric current at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8682",
      "description": "Exposure to other specified electric current at other specified places While working for income"
    },
    {
      "name": "W8683",
      "description": "Exposure to other specified electric current at other specified places While engaged in other types of work"
    },
    {
      "name": "W8684",
      "description": "Exposure to other specified electric current at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8688",
      "description": "Exposure to other specified electric current at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8689",
      "description": "Exposure to other specified electric current at other specified places During unspecified activity"
    },
    {
      "name": "W869",
      "description": "Exposure to other specified electric current at unspecified place"
    },
    {
      "name": "W8690",
      "description": "Exposure to other specified electric current at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8691",
      "description": "Exposure to other specified electric current at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8692",
      "description": "Exposure to other specified electric current at unspecified place While working for income"
    },
    {
      "name": "W8693",
      "description": "Exposure to other specified electric current at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8694",
      "description": "Exposure to other specified electric current at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8698",
      "description": "Exposure to other specified electric current at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8699",
      "description": "Exposure to other specified electric current at unspecified place During unspecified activity"
    },
    {
      "name": "W870",
      "description": "Exposure to unspecified electric current at home"
    },
    {
      "name": "W8700",
      "description": "Exposure to unspecified electric current at home While engaged in sports activity"
    },
    {
      "name": "W8701",
      "description": "Exposure to unspecified electric current at home While engaged in leisure activity"
    },
    {
      "name": "W8702",
      "description": "Exposure to unspecified electric current at home While working for income"
    },
    {
      "name": "W8703",
      "description": "Exposure to unspecified electric current at home While engaged in other types of work"
    },
    {
      "name": "W8704",
      "description": "Exposure to unspecified electric current at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8708",
      "description": "Exposure to unspecified electric current at home While engaged in other specified activities"
    },
    {
      "name": "W8709",
      "description": "Exposure to unspecified electric current at home During unspecified activity"
    },
    {
      "name": "W871",
      "description": "Exposure to unspecified electric current at residential institution"
    },
    {
      "name": "W8710",
      "description": "Exposure to unspecified electric current at residential institution While engaged in sports activity"
    },
    {
      "name": "W8711",
      "description": "Exposure to unspecified electric current at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8712",
      "description": "Exposure to unspecified electric current at residential institution While working for income"
    },
    {
      "name": "W8713",
      "description": "Exposure to unspecified electric current at residential institution While engaged in other types of work"
    },
    {
      "name": "W8714",
      "description": "Exposure to unspecified electric current at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8718",
      "description": "Exposure to unspecified electric current at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8719",
      "description": "Exposure to unspecified electric current at residential institution During unspecified activity"
    },
    {
      "name": "W872",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area"
    },
    {
      "name": "W8720",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8721",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8722",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8723",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8724",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8728",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8729",
      "description": "Exposure to unspecified electric current at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W873",
      "description": "Exposure to unspecified electric current at sports and athletics area"
    },
    {
      "name": "W8730",
      "description": "Exposure to unspecified electric current at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8731",
      "description": "Exposure to unspecified electric current at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8732",
      "description": "Exposure to unspecified electric current at sports and athletics area While working for income"
    },
    {
      "name": "W8733",
      "description": "Exposure to unspecified electric current at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8734",
      "description": "Exposure to unspecified electric current at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8738",
      "description": "Exposure to unspecified electric current at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8739",
      "description": "Exposure to unspecified electric current at sports and athletics area During unspecified activity"
    },
    {
      "name": "W874",
      "description": "Exposure to unspecified electric current at street and highway"
    },
    {
      "name": "W8740",
      "description": "Exposure to unspecified electric current at street and highway While engaged in sports activity"
    },
    {
      "name": "W8741",
      "description": "Exposure to unspecified electric current at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8742",
      "description": "Exposure to unspecified electric current at street and highway While working for income"
    },
    {
      "name": "W8743",
      "description": "Exposure to unspecified electric current at street and highway While engaged in other types of work"
    },
    {
      "name": "W8744",
      "description": "Exposure to unspecified electric current at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8748",
      "description": "Exposure to unspecified electric current at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8749",
      "description": "Exposure to unspecified electric current at street and highway During unspecified activity"
    },
    {
      "name": "W875",
      "description": "Exposure to unspecified electric current at trade and service area"
    },
    {
      "name": "W8750",
      "description": "Exposure to unspecified electric current at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8751",
      "description": "Exposure to unspecified electric current at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8752",
      "description": "Exposure to unspecified electric current at trade and service area While working for income"
    },
    {
      "name": "W8753",
      "description": "Exposure to unspecified electric current at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8754",
      "description": "Exposure to unspecified electric current at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8758",
      "description": "Exposure to unspecified electric current at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8759",
      "description": "Exposure to unspecified electric current at trade and service area During unspecified activity"
    },
    {
      "name": "W876",
      "description": "Exposure to unspecified electric current at industrial and construction area"
    },
    {
      "name": "W8760",
      "description": "Exposure to unspecified electric current at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8761",
      "description": "Exposure to unspecified electric current at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8762",
      "description": "Exposure to unspecified electric current at industrial and construction area While working for income"
    },
    {
      "name": "W8763",
      "description": "Exposure to unspecified electric current at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8764",
      "description": "Exposure to unspecified electric current at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8768",
      "description": "Exposure to unspecified electric current at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8769",
      "description": "Exposure to unspecified electric current at industrial and construction area During unspecified activity"
    },
    {
      "name": "W877",
      "description": "Exposure to unspecified electric current at farm"
    },
    {
      "name": "W8770",
      "description": "Exposure to unspecified electric current at farm While engaged in sports activity"
    },
    {
      "name": "W8771",
      "description": "Exposure to unspecified electric current at farm While engaged in leisure activity"
    },
    {
      "name": "W8772",
      "description": "Exposure to unspecified electric current at farm While working for income"
    },
    {
      "name": "W8773",
      "description": "Exposure to unspecified electric current at farm While engaged in other types of work"
    },
    {
      "name": "W8774",
      "description": "Exposure to unspecified electric current at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8778",
      "description": "Exposure to unspecified electric current at farm While engaged in other specified activities"
    },
    {
      "name": "W8779",
      "description": "Exposure to unspecified electric current at farm During unspecified activity"
    },
    {
      "name": "W878",
      "description": "Exposure to unspecified electric current at other specified places"
    },
    {
      "name": "W8780",
      "description": "Exposure to unspecified electric current at other specified places While engaged in sports activity"
    },
    {
      "name": "W8781",
      "description": "Exposure to unspecified electric current at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8782",
      "description": "Exposure to unspecified electric current at other specified places While working for income"
    },
    {
      "name": "W8783",
      "description": "Exposure to unspecified electric current at other specified places While engaged in other types of work"
    },
    {
      "name": "W8784",
      "description": "Exposure to unspecified electric current at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8788",
      "description": "Exposure to unspecified electric current at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8789",
      "description": "Exposure to unspecified electric current at other specified places During unspecified activity"
    },
    {
      "name": "W879",
      "description": "Exposure to unspecified electric current at unspecified place"
    },
    {
      "name": "W8790",
      "description": "Exposure to unspecified electric current at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8791",
      "description": "Exposure to unspecified electric current at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8792",
      "description": "Exposure to unspecified electric current at unspecified place While working for income"
    },
    {
      "name": "W8793",
      "description": "Exposure to unspecified electric current at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8794",
      "description": "Exposure to unspecified electric current at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8798",
      "description": "Exposure to unspecified electric current at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8799",
      "description": "Exposure to unspecified electric current at unspecified place During unspecified activity"
    },
    {
      "name": "W880",
      "description": "Exposure to ionizing radiation at home "
    },
    {
      "name": "W8800",
      "description": "Exposure to ionizing radiation at home While engaged in sports activity"
    },
    {
      "name": "W8801",
      "description": "Exposure to ionizing radiation at home While engaged in leisure activity"
    },
    {
      "name": "W8802",
      "description": "Exposure to ionizing radiation at home While working for income"
    },
    {
      "name": "W8803",
      "description": "Exposure to ionizing radiation at home  While engaged in other types of work"
    },
    {
      "name": "W8804",
      "description": "Exposure to ionizing radiation at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8808",
      "description": "Exposure to  ionizing radiation at home While engaged in other specified activities"
    },
    {
      "name": "W8809",
      "description": "Exposure to  ionizing radiation at home During unspecified activity"
    },
    {
      "name": "W881",
      "description": "Exposure to  ionizing radiation at residential institution"
    },
    {
      "name": "W8810",
      "description": "Exposure to ionizing radiation at residential institution While engaged in sports activity"
    },
    {
      "name": "W8811",
      "description": "Exposure to ionizing radiation at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8812",
      "description": "Exposure to  ionizing radiation at residential institution While working for income"
    },
    {
      "name": "W8813",
      "description": "Exposure to  ionizing radiation at residential institution While engaged in other types of work"
    },
    {
      "name": "W8814",
      "description": "Exposure to  ionizing radiation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8818",
      "description": "Exposure to  ionizing radiation at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8819",
      "description": "Exposure to  ionizing radiation at residential institution During unspecified activity"
    },
    {
      "name": "W882",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area"
    },
    {
      "name": "W8820",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8821",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8822",
      "description": "Exposure to ionizing radiation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8823",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8824",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8828",
      "description": "Exposure to  ionizing radiation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8829",
      "description": "Exposure to ionizing radiation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W883",
      "description": "Exposure to ionizing radiation at sports and athletics area"
    },
    {
      "name": "W8830",
      "description": "Exposure to ionizing radiation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8831",
      "description": "Exposure to ionizing radiation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8832",
      "description": "Exposure to ionizing radiation at sports and athletics area While working for income"
    },
    {
      "name": "W8833",
      "description": "Exposure to ionizing radiation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8834",
      "description": "Exposure to ionizing radiation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8838",
      "description": "Exposure to ionizing radiation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8839",
      "description": "Exposure to ionizing radiation at sports and athletics area During unspecified activity"
    },
    {
      "name": "W884",
      "description": "Exposure to ionizing radiation at street and highway"
    },
    {
      "name": "W8840",
      "description": "Exposure to ionizing radiation at street and highway While engaged in sports activity"
    },
    {
      "name": "W8841",
      "description": "Exposure to ionizing radiation at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8842",
      "description": "Exposure to ionizing radiation at street and highway While working for income"
    },
    {
      "name": "W8843",
      "description": "Exposure to ionizing radiation at street and highway While engaged in other types of work"
    },
    {
      "name": "W8844",
      "description": "Exposure to ionizing radiation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8848",
      "description": "Exposure to ionizing radiation at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8849",
      "description": "Exposure to ionizing radiation at street and highway During unspecified activity"
    },
    {
      "name": "W885",
      "description": "Exposure to ionizing radiation at trade and service area"
    },
    {
      "name": "W8850",
      "description": "Exposure to ionizing radiation at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8851",
      "description": "Exposure to ionizing radiation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8852",
      "description": "Exposure to ionizing radiation at trade and service area While working for income"
    },
    {
      "name": "W8853",
      "description": "Exposure to ionizing radiation at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8854",
      "description": "Exposure to ionizing radiation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8858",
      "description": "Exposure to ionizing radiation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8859",
      "description": "Exposure to ionizing radiation at trade and service area During unspecified activity"
    },
    {
      "name": "W886",
      "description": "Exposure to ionizing radiation at industrial and construction area"
    },
    {
      "name": "W8860",
      "description": "Exposure to ionizing radiation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8861",
      "description": "Exposure to ionizing radiation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8862",
      "description": "Exposure to ionizing radiation at industrial and construction area While working for income"
    },
    {
      "name": "W8863",
      "description": "Exposure to ionizing radiation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8864",
      "description": "Exposure to ionizing radiation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8868",
      "description": "Exposure to ionizing radiation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8869",
      "description": "Exposure to ionizing radiation at industrial and construction area During unspecified activity"
    },
    {
      "name": "W887",
      "description": "Exposure to ionizing radiation at farm"
    },
    {
      "name": "W8870",
      "description": "Exposure to ionizing radiation at farm While engaged in sports activity"
    },
    {
      "name": "W8871",
      "description": "Exposure to ionizing radiation at farm While engaged in leisure activity"
    },
    {
      "name": "W8872",
      "description": "Exposure to ionizing radiation at farm While working for income"
    },
    {
      "name": "W8873",
      "description": "Exposure to ionizing radiation at farm While engaged in other types of work"
    },
    {
      "name": "W8874",
      "description": "Exposure to ionizing radiation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8878",
      "description": "Exposure to ionizing radiation at farm While engaged in other specified activities"
    },
    {
      "name": "W8879",
      "description": "Exposure to ionizing radiation at farm During unspecified activity"
    },
    {
      "name": "W888",
      "description": "Exposure to ionizing radiation at other specified places"
    },
    {
      "name": "W8880",
      "description": "Exposure to ionizing radiation at other specified places While engaged in sports activity"
    },
    {
      "name": "W8881",
      "description": "Exposure to ionizing radiation at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8882",
      "description": "Exposure to ionizing radiation at other specified places While working for income"
    },
    {
      "name": "W8883",
      "description": "Exposure to ionizing radiation at other specified places While engaged in other types of work"
    },
    {
      "name": "W8884",
      "description": "Exposure to ionizing radiation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8888",
      "description": "Exposure to ionizing radiation at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8889",
      "description": "Exposure to ionizing radiation at other specified places During unspecified activity"
    },
    {
      "name": "W889",
      "description": "Exposure to ionizing radiation at unspecified place"
    },
    {
      "name": "W8890",
      "description": "Exposure to ionizing radiation at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8891",
      "description": "Exposure to ionizing radiation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8892",
      "description": "Exposure to ionizing radiation at unspecified place While working for income"
    },
    {
      "name": "W8893",
      "description": "Exposure to ionizing radiation at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8894",
      "description": "Exposure to ionizing radiation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8898",
      "description": "Exposure to ionizing radiation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8899",
      "description": "Exposure to ionizing radiation at unspecified place During unspecified activity"
    },
    {
      "name": "W890",
      "description": "Exposure to man-made visible and ultraviolet light at home"
    },
    {
      "name": "W8900",
      "description": "Exposure to man-made visible and ultraviolet light at home While engaged in sports activity"
    },
    {
      "name": "W8901",
      "description": "Exposure to man-made visible and ultraviolet light at home While engaged in leisure activity"
    },
    {
      "name": "W8902",
      "description": "Exposure to man-made visible and ultraviolet light at home While working for income"
    },
    {
      "name": "W8903",
      "description": "Exposure to man-made visible and ultraviolet light at home While engaged in other types of work"
    },
    {
      "name": "W8904",
      "description": "Exposure to man-made visible and ultraviolet light at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8908",
      "description": "Exposure to man-made visible and ultraviolet light at home While engaged in other specified activities"
    },
    {
      "name": "W8909",
      "description": "Exposure to man-made visible and ultraviolet light at home During unspecified activity"
    },
    {
      "name": "W891",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution"
    },
    {
      "name": "W8910",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While engaged in sports activity"
    },
    {
      "name": "W8911",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While engaged in leisure activity"
    },
    {
      "name": "W8912",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While working for income"
    },
    {
      "name": "W8913",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While engaged in other types of work"
    },
    {
      "name": "W8914",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8918",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution While engaged in other specified activities"
    },
    {
      "name": "W8919",
      "description": "Exposure to man-made visible and ultraviolet light at residential institution During unspecified activity"
    },
    {
      "name": "W892",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area"
    },
    {
      "name": "W8920",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W8921",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W8922",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W8923",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W8924",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8928",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W8929",
      "description": "Exposure to man-made visible and ultraviolet light at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W893",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area"
    },
    {
      "name": "W8930",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W8931",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W8932",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While working for income"
    },
    {
      "name": "W8933",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W8934",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8938",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W8939",
      "description": "Exposure to man-made visible and ultraviolet light at sports and athletics area During unspecified activity"
    },
    {
      "name": "W894",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway"
    },
    {
      "name": "W8940",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While engaged in sports activity"
    },
    {
      "name": "W8941",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While engaged in leisure activity"
    },
    {
      "name": "W8942",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While working for income"
    },
    {
      "name": "W8943",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While engaged in other types of work"
    },
    {
      "name": "W8944",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8948",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway While engaged in other specified activities"
    },
    {
      "name": "W8949",
      "description": "Exposure to man-made visible and ultraviolet light at street and highway During unspecified activity"
    },
    {
      "name": "W895",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area"
    },
    {
      "name": "W8950",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While engaged in sports activity"
    },
    {
      "name": "W8951",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W8952",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While working for income"
    },
    {
      "name": "W8953",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While engaged in other types of work"
    },
    {
      "name": "W8954",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8958",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W8959",
      "description": "Exposure to man-made visible and ultraviolet light at trade and service area During unspecified activity"
    },
    {
      "name": "W896",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area"
    },
    {
      "name": "W8960",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W8961",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W8962",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While working for income"
    },
    {
      "name": "W8963",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W8964",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8968",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W8969",
      "description": "Exposure to man-made visible and ultraviolet light at industrial and construction area During unspecified activity"
    },
    {
      "name": "W897",
      "description": "Exposure to man-made visible and ultraviolet light at farm"
    },
    {
      "name": "W8970",
      "description": "Exposure to man-made visible and ultraviolet light at farm While engaged in sports activity"
    },
    {
      "name": "W8971",
      "description": "Exposure to man-made visible and ultraviolet light at farm While engaged in leisure activity"
    },
    {
      "name": "W8972",
      "description": "Exposure to man-made visible and ultraviolet light at farm While working for income"
    },
    {
      "name": "W8973",
      "description": "Exposure to man-made visible and ultraviolet light at farm While engaged in other types of work"
    },
    {
      "name": "W8974",
      "description": "Exposure to man-made visible and ultraviolet light at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8978",
      "description": "Exposure to man-made visible and ultraviolet light at farm While engaged in other specified activities"
    },
    {
      "name": "W8979",
      "description": "Exposure to man-made visible and ultraviolet light at farm During unspecified activity"
    },
    {
      "name": "W898",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places"
    },
    {
      "name": "W8980",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While engaged in sports activity"
    },
    {
      "name": "W8981",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While engaged in leisure activity"
    },
    {
      "name": "W8982",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While working for income"
    },
    {
      "name": "W8983",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While engaged in other types of work"
    },
    {
      "name": "W8984",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8988",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places While engaged in other specified activities"
    },
    {
      "name": "W8989",
      "description": "Exposure to man-made visible and ultraviolet light at other specified places During unspecified activity"
    },
    {
      "name": "W899",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place"
    },
    {
      "name": "W8990",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While engaged in sports activity"
    },
    {
      "name": "W8991",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W8992",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While working for income"
    },
    {
      "name": "W8993",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While engaged in other types of work"
    },
    {
      "name": "W8994",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W8998",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W8999",
      "description": "Exposure to man-made visible and ultraviolet light at unspecified place During unspecified activity"
    },
    {
      "name": "W900",
      "description": "Exposure to nonionizing radiation at home"
    },
    {
      "name": "W9000",
      "description": "Exposure to nonionizing radiation at home While engaged in sports activity"
    },
    {
      "name": "W9001",
      "description": "Exposure to nonionizing radiation at home While engaged in leisure activity"
    },
    {
      "name": "W9002",
      "description": "Exposure to nonionizing radiation at home While working for income"
    },
    {
      "name": "W9003",
      "description": "Exposure to nonionizing radiation at home While engaged in other types of work"
    },
    {
      "name": "W9004",
      "description": "Exposure to nonionizing radiation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9008",
      "description": "Exposure to nonionizing radiation at home While engaged in other specified activities"
    },
    {
      "name": "W9009",
      "description": "Exposure to nonionizing radiation at home During unspecified activity"
    },
    {
      "name": "W901",
      "description": "Exposure to nonionizing radiation at residential institution"
    },
    {
      "name": "W9010",
      "description": "Exposure to nonionizing radiation at residential institution While engaged in sports activity"
    },
    {
      "name": "W9011",
      "description": "Exposure to nonionizing radiation at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9012",
      "description": "Exposure to nonionizing radiation at residential institution While working for income"
    },
    {
      "name": "W9013",
      "description": "Exposure to nonionizing radiation at residential institution While engaged in other types of work"
    },
    {
      "name": "W9014",
      "description": "Exposure to nonionizing radiation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9018",
      "description": "Exposure to nonionizing radiation at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9019",
      "description": "Exposure to nonionizing radiation at residential institution During unspecified activity"
    },
    {
      "name": "W902",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area"
    },
    {
      "name": "W9020",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9021",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9022",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9023",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9024",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9028",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9029",
      "description": "Exposure to nonionizing radiation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W903",
      "description": "Exposure to nonionizing radiation at sports and athletics area"
    },
    {
      "name": "W9030",
      "description": "Exposure to nonionizing radiation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9031",
      "description": "Exposure to nonionizing radiation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9032",
      "description": "Exposure to nonionizing radiation at sports and athletics area While working for income"
    },
    {
      "name": "W9033",
      "description": "Exposure to nonionizing radiation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9034",
      "description": "Exposure to nonionizing radiation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9038",
      "description": "Exposure to nonionizing radiation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9039",
      "description": "Exposure to nonionizing radiation at sports and athletics area During unspecified activity"
    },
    {
      "name": "W904",
      "description": "Exposure to nonionizing radiation at street and highway"
    },
    {
      "name": "W9040",
      "description": "Exposure to nonionizing radiation at street and highway While engaged in sports activity"
    },
    {
      "name": "W9041",
      "description": "Exposure to nonionizing radiation at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9042",
      "description": "Exposure to nonionizing radiation at street and highway While working for income"
    },
    {
      "name": "W9043",
      "description": "Exposure to nonionizing radiation at street and highway While engaged in other types of work"
    },
    {
      "name": "W9044",
      "description": "Exposure to nonionizing radiation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9048",
      "description": "Exposure to nonionizing radiation at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9049",
      "description": "Exposure to nonionizing radiation at street and highway During unspecified activity"
    },
    {
      "name": "W905",
      "description": "Exposure to nonionizing radiation at trade and service area"
    },
    {
      "name": "W9050",
      "description": "Exposure to nonionizing radiation at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9051",
      "description": "Exposure to nonionizing radiation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9052",
      "description": "Exposure to nonionizing radiation at trade and service area While working for income"
    },
    {
      "name": "W9053",
      "description": "Exposure to nonionizing radiation at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9054",
      "description": "Exposure to nonionizing radiation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9058",
      "description": "Exposure to nonionizing radiation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9059",
      "description": "Exposure to nonionizing radiation at trade and service area During unspecified activity"
    },
    {
      "name": "W906",
      "description": "Exposure to nonionizing radiation at industrial and construction area"
    },
    {
      "name": "W9060",
      "description": "Exposure to nonionizing radiation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9061",
      "description": "Exposure to nonionizing radiation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9062",
      "description": "Exposure to nonionizing radiation at industrial and construction area While working for income"
    },
    {
      "name": "W9063",
      "description": "Exposure to nonionizing radiation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9064",
      "description": "Exposure to nonionizing radiation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9068",
      "description": "Exposure to nonionizing radiation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9069",
      "description": "Exposure to nonionizing radiation at industrial and construction area During unspecified activity"
    },
    {
      "name": "W907",
      "description": "Exposure to nonionizing radiation at farm"
    },
    {
      "name": "W9070",
      "description": "Exposure to nonionizing radiation at farm While engaged in sports activity"
    },
    {
      "name": "W9071",
      "description": "Exposure to nonionizing radiation at farm While engaged in leisure activity"
    },
    {
      "name": "W9072",
      "description": "Exposure to nonionizing radiation at farm While working for income"
    },
    {
      "name": "W9073",
      "description": "Exposure to nonionizing radiation at farm While engaged in other types of work"
    },
    {
      "name": "W9074",
      "description": "Exposure to nonionizing radiation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9078",
      "description": "Exposure to nonionizing radiation at farm While engaged in other specified activities"
    },
    {
      "name": "W9079",
      "description": "Exposure to nonionizing radiation at farm During unspecified activity"
    },
    {
      "name": "W908",
      "description": "Exposure to nonionizing radiation at other specified places"
    },
    {
      "name": "W9080",
      "description": "Exposure to nonionizing radiation at other specified places While engaged in sports activity"
    },
    {
      "name": "W9081",
      "description": "Exposure to nonionizing radiation at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9082",
      "description": "Exposure to nonionizing radiation at other specified places While working for income"
    },
    {
      "name": "W9083",
      "description": "Exposure to nonionizing radiation at other specified places While engaged in other types of work"
    },
    {
      "name": "W9084",
      "description": "Exposure to nonionizing radiation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9088",
      "description": "Exposure to nonionizing radiation at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9089",
      "description": "Exposure to nonionizing radiation at other specified places During unspecified activity"
    },
    {
      "name": "W909",
      "description": "Exposure to nonionizing radiation at unspecified place"
    },
    {
      "name": "W9090",
      "description": "Exposure to nonionizing radiation at unspecified place While engaged in sports activity"
    },
    {
      "name": "W9091",
      "description": "Exposure to nonionizing radiation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9092",
      "description": "Exposure to nonionizing radiation at unspecified place While working for income"
    },
    {
      "name": "W9093",
      "description": "Exposure to nonionizing radiation at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9094",
      "description": "Exposure to nonionizing radiation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9098",
      "description": "Exposure to nonionizing radiation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9099",
      "description": "Exposure to nonionizing radiation at unspecified place During unspecified activity"
    },
    {
      "name": "W910",
      "description": "Exposure to unspecified type of radiation at home"
    },
    {
      "name": "W9100",
      "description": "Exposure to unspecified type of radiation at home While engaged in sports activity"
    },
    {
      "name": "W9101",
      "description": "Exposure to unspecified type of radiation at home While engaged in leisure activity"
    },
    {
      "name": "W9102",
      "description": "Exposure to unspecified type of radiation at home While working for income"
    },
    {
      "name": "W9103",
      "description": "Exposure to unspecified type of radiation at home While engaged in other types of work"
    },
    {
      "name": "W9104",
      "description": "Exposure to unspecified type of radiation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9108",
      "description": "Exposure to unspecified type of radiation at home While engaged in other specified activities"
    },
    {
      "name": "W9109",
      "description": "Exposure to unspecified type of radiation at home During unspecified activity"
    },
    {
      "name": "W911",
      "description": "Exposure to unspecified type of radiation at residential institution"
    },
    {
      "name": "W9110",
      "description": "Exposure to unspecified type of radiation at residential institution While engaged in sports activity"
    },
    {
      "name": "W9111",
      "description": "Exposure to unspecified type of radiation at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9112",
      "description": "Exposure to unspecified type of radiation at residential institution While working for income"
    },
    {
      "name": "W9113",
      "description": "Exposure to unspecified type of radiation at residential institution While engaged in other types of work"
    },
    {
      "name": "W9114",
      "description": "Exposure to unspecified type of radiation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9118",
      "description": "Exposure to unspecified type of radiation at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9119",
      "description": "Exposure to unspecified type of radiation at residential institution During unspecified activity"
    },
    {
      "name": "W912",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area"
    },
    {
      "name": "W9120",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9121",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9122",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9123",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9124",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9128",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9129",
      "description": "Exposure to unspecified type of radiation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W913",
      "description": "Exposure to unspecified type of radiation at sports and athletics area"
    },
    {
      "name": "W9130",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9131",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9132",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While working for income"
    },
    {
      "name": "W9133",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9134",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9138",
      "description": "Exposure to unspecified type of radiation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9139",
      "description": "Exposure to unspecified type of radiation at sports and athletics area During unspecified activity"
    },
    {
      "name": "W914",
      "description": "Exposure to unspecified type of radiation at street and highway"
    },
    {
      "name": "W9140",
      "description": "Exposure to unspecified type of radiation at street and highway While engaged in sports activity"
    },
    {
      "name": "W9141",
      "description": "Exposure to unspecified type of radiation at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9142",
      "description": "Exposure to unspecified type of radiation at street and highway While working for income"
    },
    {
      "name": "W9143",
      "description": "Exposure to unspecified type of radiation at street and highway While engaged in other types of work"
    },
    {
      "name": "W9144",
      "description": "Exposure to unspecified type of radiation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9148",
      "description": "Exposure to unspecified type of radiation at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9149",
      "description": "Exposure to unspecified type of radiation at street and highway During unspecified activity"
    },
    {
      "name": "W915",
      "description": "Exposure to unspecified type of radiation at trade and service area"
    },
    {
      "name": "W9150",
      "description": "Exposure to unspecified type of radiation at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9151",
      "description": "Exposure to unspecified type of radiation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9152",
      "description": "Exposure to unspecified type of radiation at trade and service area While working for income"
    },
    {
      "name": "W9153",
      "description": "Exposure to unspecified type of radiation at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9154",
      "description": "Exposure to unspecified type of radiation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9158",
      "description": "Exposure to unspecified type of radiation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9159",
      "description": "Exposure to unspecified type of radiation at trade and service area During unspecified activity"
    },
    {
      "name": "W916",
      "description": "Exposure to unspecified type of radiation at industrial and construction area"
    },
    {
      "name": "W9160",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9161",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9162",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While working for income"
    },
    {
      "name": "W9163",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9164",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9168",
      "description": "Exposure to unspecified type of radiation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9169",
      "description": "Exposure to unspecified type of radiation at industrial and construction area During unspecified activity"
    },
    {
      "name": "W917",
      "description": "Exposure to unspecified type of radiation at farm"
    },
    {
      "name": "W9170",
      "description": "Exposure to unspecified type of radiation at farm While engaged in sports activity"
    },
    {
      "name": "W9171",
      "description": "Exposure to unspecified type of radiation at farm While engaged in leisure activity"
    },
    {
      "name": "W9172",
      "description": "Exposure to unspecified type of radiation at farm While working for income"
    },
    {
      "name": "W9173",
      "description": "Exposure to unspecified type of radiation at farm While engaged in other types of work"
    },
    {
      "name": "W9174",
      "description": "Exposure to unspecified type of radiation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9178",
      "description": "Exposure to unspecified type of radiation at farm While engaged in other specified activities"
    },
    {
      "name": "W9179",
      "description": "Exposure to unspecified type of radiation at farm During unspecified activity"
    },
    {
      "name": "W918",
      "description": "Exposure to unspecified type of radiation at other specified places"
    },
    {
      "name": "W9180",
      "description": "Exposure to unspecified type of radiation at other specified places While engaged in sports activity"
    },
    {
      "name": "W9181",
      "description": "Exposure to unspecified type of radiation at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9182",
      "description": "Exposure to unspecified type of radiation at other specified places While working for income"
    },
    {
      "name": "W9183",
      "description": "Exposure to unspecified type of radiation at other specified places While engaged in other types of work"
    },
    {
      "name": "W9184",
      "description": "Exposure to unspecified type of radiation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9188",
      "description": "Exposure to unspecified type of radiation at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9189",
      "description": "Exposure to unspecified type of radiation at other specified places During unspecified activity"
    },
    {
      "name": "W919",
      "description": "Exposure to unspecified type of radiation at unspecified place"
    },
    {
      "name": "W9190",
      "description": "Exposure to unspecified type of radiation at unspecified place While engaged in sports activity"
    },
    {
      "name": "W9191",
      "description": "Exposure to unspecified type of radiation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9192",
      "description": "Exposure to unspecified type of radiation at unspecified place While working for income"
    },
    {
      "name": "W9193",
      "description": "Exposure to unspecified type of radiation at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9194",
      "description": "Exposure to unspecified type of radiation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9198",
      "description": "Exposure to unspecified type of radiation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9199",
      "description": "Exposure to unspecified type of radiation at unspecified place During unspecified activity"
    },
    {
      "name": "W920",
      "description": "Exposure to excessive heat of man-made origin at home"
    },
    {
      "name": "W9200",
      "description": "Exposure to excessive heat of man-made origin at home While engaged in sports activity"
    },
    {
      "name": "W9201",
      "description": "Exposure to excessive heat of man-made origin at home While engaged in leisure activity"
    },
    {
      "name": "W9202",
      "description": "Exposure to excessive heat of man-made origin at home While working for income"
    },
    {
      "name": "W9203",
      "description": "Exposure to excessive heat of man-made origin at home While engaged in other types of work"
    },
    {
      "name": "W9204",
      "description": "Exposure to excessive heat of man-made origin at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9208",
      "description": "Exposure to excessive heat of man-made origin at home While engaged in other specified activities"
    },
    {
      "name": "W9209",
      "description": "Exposure to excessive heat of man-made origin at home During unspecified activity"
    },
    {
      "name": "W921",
      "description": "Exposure to excessive heat of man-made origin at residential institution"
    },
    {
      "name": "W9210",
      "description": "Exposure to excessive heat of man-made origin at residential institution While engaged in sports activity"
    },
    {
      "name": "W9211",
      "description": "Exposure to excessive heat of man-made origin at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9212",
      "description": "Exposure to excessive heat of man-made origin at residential institution While working for income"
    },
    {
      "name": "W9213",
      "description": "Exposure to excessive heat of man-made origin at residential institution While engaged in other types of work"
    },
    {
      "name": "W9214",
      "description": "Exposure to excessive heat of man-made origin at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9218",
      "description": "Exposure to excessive heat of man-made origin at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9219",
      "description": "Exposure to excessive heat of man-made origin at residential institution During unspecified activity"
    },
    {
      "name": "W922",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area"
    },
    {
      "name": "W9220",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9221",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9222",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9223",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9224",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9228",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9229",
      "description": "Exposure to excessive heat of man-made origin at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W923",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area"
    },
    {
      "name": "W9230",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9231",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9232",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While working for income"
    },
    {
      "name": "W9233",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9234",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9238",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9239",
      "description": "Exposure to excessive heat of man-made origin at sports and athletics area During unspecified activity"
    },
    {
      "name": "W924",
      "description": "Exposure to excessive heat of man-made origin at street and highway"
    },
    {
      "name": "W9240",
      "description": "Exposure to excessive heat of man-made origin at street and highway While engaged in sports activity"
    },
    {
      "name": "W9241",
      "description": "Exposure to excessive heat of man-made origin at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9242",
      "description": "Exposure to excessive heat of man-made origin at street and highway While working for income"
    },
    {
      "name": "W9243",
      "description": "Exposure to excessive heat of man-made origin at street and highway While engaged in other types of work"
    },
    {
      "name": "W9244",
      "description": "Exposure to excessive heat of man-made origin at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9248",
      "description": "Exposure to excessive heat of man-made origin at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9249",
      "description": "Exposure to excessive heat of man-made origin at street and highway During unspecified activity"
    },
    {
      "name": "W925",
      "description": "Exposure to excessive heat of man-made origin at trade and service area"
    },
    {
      "name": "W9250",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9251",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9252",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While working for income"
    },
    {
      "name": "W9253",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9254",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9258",
      "description": "Exposure to excessive heat of man-made origin at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9259",
      "description": "Exposure to excessive heat of man-made origin at trade and service area During unspecified activity"
    },
    {
      "name": "W926",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area"
    },
    {
      "name": "W9260",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9261",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9262",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While working for income"
    },
    {
      "name": "W9263",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9264",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9268",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9269",
      "description": "Exposure to excessive heat of man-made origin at industrial and construction area During unspecified activity"
    },
    {
      "name": "W927",
      "description": "Exposure to excessive heat of man-made origin at farm"
    },
    {
      "name": "W9270",
      "description": "Exposure to excessive heat of man-made origin at farm While engaged in sports activity"
    },
    {
      "name": "W9271",
      "description": "Exposure to excessive heat of man-made origin at farm While engaged in leisure activity"
    },
    {
      "name": "W9272",
      "description": "Exposure to excessive heat of man-made origin at farm While working for income"
    },
    {
      "name": "W9273",
      "description": "Exposure to excessive heat of man-made origin at farm While engaged in other types of work"
    },
    {
      "name": "W9274",
      "description": "Exposure to excessive heat of man-made origin at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9278",
      "description": "Exposure to excessive heat of man-made origin at farm While engaged in other specified activities"
    },
    {
      "name": "W9279",
      "description": "Exposure to excessive heat of man-made origin at farm During unspecified activity"
    },
    {
      "name": "W928",
      "description": "Exposure to excessive heat of man-made origin at other specified places"
    },
    {
      "name": "W9280",
      "description": "Exposure to excessive heat of man-made origin at other specified places While engaged in sports activity"
    },
    {
      "name": "W9281",
      "description": "Exposure to excessive heat of man-made origin at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9282",
      "description": "Exposure to excessive heat of man-made origin at other specified places While working for income"
    },
    {
      "name": "W9283",
      "description": "Exposure to excessive heat of man-made origin at other specified places While engaged in other types of work"
    },
    {
      "name": "W9284",
      "description": "Exposure to excessive heat of man-made origin at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9288",
      "description": "Exposure to excessive heat of man-made origin at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9289",
      "description": "Exposure to excessive heat of man-made origin at other specified places During unspecified activity"
    },
    {
      "name": "W929",
      "description": "Exposure to excessive heat of man-made origin at unspecified place"
    },
    {
      "name": "W9290",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While engaged in sports activity "
    },
    {
      "name": "W9291",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9292",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While working for income"
    },
    {
      "name": "W9293",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9294",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9298",
      "description": "Exposure to excessive heat of man-made origin at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9299",
      "description": "Exposure to excessive heat of man-made origin at unspecified place During unspecified activity"
    },
    {
      "name": "W930",
      "description": "Exposure to excessive cold of man-made origin at home"
    },
    {
      "name": "W9300",
      "description": "Exposure to excessive cold of man-made origin at home While engaged in sports activity"
    },
    {
      "name": "W9301",
      "description": "Exposure to excessive cold of man-made origin at home While engaged in leisure activity"
    },
    {
      "name": "W9302",
      "description": "Exposure to excessive cold of man-made origin at home While working for income"
    },
    {
      "name": "W9303",
      "description": "Exposure to excessive cold of man-made origin at home While engaged in other types of work"
    },
    {
      "name": "W9304",
      "description": "Exposure to excessive cold of man-made origin at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9308",
      "description": "Exposure to excessive cold of man-made origin at home While engaged in other specified activities"
    },
    {
      "name": "W9309",
      "description": "Exposure to excessive cold of man-made origin at home During unspecified activity"
    },
    {
      "name": "W931",
      "description": "Exposure to excessive cold of man-made origin at residential institution"
    },
    {
      "name": "W9310",
      "description": "Exposure to excessive cold of man-made origin at residential institution While engaged in sports activity"
    },
    {
      "name": "W9311",
      "description": "Exposure to excessive cold of man-made origin at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9312",
      "description": "Exposure to excessive cold of man-made origin at residential institution While working for income"
    },
    {
      "name": "W9313",
      "description": "Exposure to excessive cold of man-made origin at residential institution While engaged in other types of work"
    },
    {
      "name": "W9314",
      "description": "Exposure to excessive cold of man-made origin at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9318",
      "description": "Exposure to excessive cold of man-made origin at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9319",
      "description": "Exposure to excessive cold of man-made origin at residential institution During unspecified activity"
    },
    {
      "name": "W932",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area"
    },
    {
      "name": "W9320",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9321",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9322",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9323",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9324",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9328",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9329",
      "description": "Exposure to excessive cold of man-made origin at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W933",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area"
    },
    {
      "name": "W9330",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9331",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9332",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While working for income"
    },
    {
      "name": "W9333",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9334",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9338",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9339",
      "description": "Exposure to excessive cold of man-made origin at sports and athletics area During unspecified activity"
    },
    {
      "name": "W934",
      "description": "Exposure to excessive cold of man-made origin at street and highway"
    },
    {
      "name": "W9340",
      "description": "Exposure to excessive cold of man-made origin at street and highway While engaged in sports activity"
    },
    {
      "name": "W9341",
      "description": "Exposure to excessive cold of man-made origin at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9342",
      "description": "Exposure to excessive cold of man-made origin at street and highway While working for income"
    },
    {
      "name": "W9343",
      "description": "Exposure to excessive cold of man-made origin at street and highway While engaged in other types of work"
    },
    {
      "name": "W9344",
      "description": "Exposure to excessive cold of man-made origin at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9348",
      "description": "Exposure to excessive cold of man-made origin at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9349",
      "description": "Exposure to excessive cold of man-made origin at street and highway During unspecified activity"
    },
    {
      "name": "W935",
      "description": "Exposure to excessive cold of man-made origin at trade and service area"
    },
    {
      "name": "W9350",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9351",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9352",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While working for income"
    },
    {
      "name": "W9353",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9354",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9358",
      "description": "Exposure to excessive cold of man-made origin at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9359",
      "description": "Exposure to excessive cold of man-made origin at trade and service area During unspecified activity"
    },
    {
      "name": "W936",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area"
    },
    {
      "name": "W9360",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9361",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9362",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While working for income"
    },
    {
      "name": "W9363",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9364",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9368",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9369",
      "description": "Exposure to excessive cold of man-made origin at industrial and construction area During unspecified activity"
    },
    {
      "name": "W937",
      "description": "Exposure to excessive cold of man-made origin at farm"
    },
    {
      "name": "W9370",
      "description": "Exposure to excessive cold of man-made origin at farm While engaged in sports activity"
    },
    {
      "name": "W9371",
      "description": "Exposure to excessive cold of man-made origin at farm While engaged in leisure activity"
    },
    {
      "name": "W9372",
      "description": "Exposure to excessive cold of man-made origin at farm While working for income"
    },
    {
      "name": "W9373",
      "description": "Exposure to excessive cold of man-made origin at farm While engaged in other types of work"
    },
    {
      "name": "W9374",
      "description": "Exposure to excessive cold of man-made origin at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9378",
      "description": "Exposure to excessive cold of man-made origin at farm While engaged in other specified activities"
    },
    {
      "name": "W9379",
      "description": "Exposure to excessive cold of man-made origin at farm During unspecified activity"
    },
    {
      "name": "W938",
      "description": "Exposure to excessive cold of man-made origin at other specified places"
    },
    {
      "name": "W9380",
      "description": "Exposure to excessive cold of man-made origin at other specified places While engaged in sports activity"
    },
    {
      "name": "W9381",
      "description": "Exposure to excessive cold of man-made origin at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9382",
      "description": "Exposure to excessive cold of man-made origin at other specified places While working for income"
    },
    {
      "name": "W9383",
      "description": "Exposure to excessive cold of man-made origin at other specified places While engaged in other types of work"
    },
    {
      "name": "W9384",
      "description": "Exposure to excessive cold of man-made origin at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9388",
      "description": "Exposure to excessive cold of man-made origin at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9389",
      "description": "Exposure to excessive cold of man-made origin at other specified places During unspecified activity"
    },
    {
      "name": "W939",
      "description": "Exposure to excessive cold of man-made origin at unspecified place"
    },
    {
      "name": "W9390",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While engaged in sports activity"
    },
    {
      "name": "W9391",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9392",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While working for income"
    },
    {
      "name": "W9393",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9394",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9398",
      "description": "Exposure to excessive cold of man-made origin at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9399",
      "description": "Exposure to excessive cold of man-made origin at unspecified place During unspecified activity"
    },
    {
      "name": "W940",
      "description": "Exposure to high and low air pressure and changes in air pressure at home"
    },
    {
      "name": "W9400",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While engaged in sports activity"
    },
    {
      "name": "W9401",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While engaged in leisure activity"
    },
    {
      "name": "W9402",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While working for income"
    },
    {
      "name": "W9403",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While engaged in other types of work"
    },
    {
      "name": "W9404",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9408",
      "description": "Exposure to high and low air pressure and changes in air pressure at home While engaged in other specified activities"
    },
    {
      "name": "W9409",
      "description": "Exposure to high and low air pressure and changes in air pressure at home During unspecified activity"
    },
    {
      "name": "W941",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution"
    },
    {
      "name": "W9410",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While engaged in sports activity"
    },
    {
      "name": "W9411",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9412",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While working for income"
    },
    {
      "name": "W9413",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While engaged in other types of work"
    },
    {
      "name": "W9414",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9418",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9419",
      "description": "Exposure to high and low air pressure and changes in air pressure at residential institution During unspecified activity"
    },
    {
      "name": "W942",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area"
    },
    {
      "name": "W9420",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9421",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9422",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9423",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9424",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9428",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9429",
      "description": "Exposure to high and low air pressure and changes in air pressure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W943",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area"
    },
    {
      "name": "W9430",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9431",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9432",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While working for income"
    },
    {
      "name": "W9433",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9434",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9438",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9439",
      "description": "Exposure to high and low air pressure and changes in air pressure at sports and athletics area During unspecified activity"
    },
    {
      "name": "W944",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway"
    },
    {
      "name": "W9440",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While engaged in sports activity"
    },
    {
      "name": "W9441",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9442",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While working for income"
    },
    {
      "name": "W9443",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While engaged in other types of work"
    },
    {
      "name": "W9444",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9448",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9449",
      "description": "Exposure to high and low air pressure and changes in air pressure at street and highway During unspecified activity"
    },
    {
      "name": "W945",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area"
    },
    {
      "name": "W9450",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9451",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9452",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While working for income"
    },
    {
      "name": "W9453",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9454",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9458",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9459",
      "description": "Exposure to high and low air pressure and changes in air pressure at trade and service area During unspecified activity"
    },
    {
      "name": "W946",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area"
    },
    {
      "name": "W9460",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9461",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9462",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While working for income"
    },
    {
      "name": "W9463",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9464",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9468",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9469",
      "description": "Exposure to high and low air pressure and changes in air pressure at industrial and construction area During unspecified activity"
    },
    {
      "name": "W947",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm"
    },
    {
      "name": "W9470",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While engaged in sports activity"
    },
    {
      "name": "W9471",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While engaged in leisure activity"
    },
    {
      "name": "W9472",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While working for income"
    },
    {
      "name": "W9473",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While engaged in other types of work"
    },
    {
      "name": "W9474",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9478",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm While engaged in other specified activities"
    },
    {
      "name": "W9479",
      "description": "Exposure to high and low air pressure and changes in air pressure at farm During unspecified activity"
    },
    {
      "name": "W948",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places"
    },
    {
      "name": "W9480",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While engaged in sports activity"
    },
    {
      "name": "W9481",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9482",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While working for income"
    },
    {
      "name": "W9483",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While engaged in other types of work"
    },
    {
      "name": "W9484",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9488",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9489",
      "description": "Exposure to high and low air pressure and changes in air pressure at other specified places During unspecified activity"
    },
    {
      "name": "W949",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place"
    },
    {
      "name": "W9490",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While engaged in sports activity"
    },
    {
      "name": "W9491",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9492",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While working for income"
    },
    {
      "name": "W9493",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9494",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9498",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9499",
      "description": "Exposure to high and low air pressure and changes in air pressure at unspecified place During unspecified activity"
    },
    {
      "name": "W990",
      "description": "Exposure to other and unspecified man-made environmental factors at home"
    },
    {
      "name": "W9900",
      "description": "Exposure to other and unspecified man-made environmental factors at home While engaged in sports activity"
    },
    {
      "name": "W9901",
      "description": "Exposure to other and unspecified man-made environmental factors at home While engaged in leisure activity"
    },
    {
      "name": "W9902",
      "description": "Exposure to other and unspecified man-made environmental factors at home While working for income"
    },
    {
      "name": "W9903",
      "description": "Exposure to other and unspecified man-made environmental factors at home While engaged in other types of work"
    },
    {
      "name": "W9904",
      "description": "Exposure to other and unspecified man-made environmental factors at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9908",
      "description": "Exposure to other and unspecified man-made environmental factors at home While engaged in other specified activities"
    },
    {
      "name": "W9909",
      "description": "Exposure to other and unspecified man-made environmental factors at home During unspecified activity"
    },
    {
      "name": "W991",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution"
    },
    {
      "name": "W9910",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While engaged in sports activity"
    },
    {
      "name": "W9911",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While engaged in leisure activity"
    },
    {
      "name": "W9912",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While working for income"
    },
    {
      "name": "W9913",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While engaged in other types of work"
    },
    {
      "name": "W9914",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9918",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution While engaged in other specified activities"
    },
    {
      "name": "W9919",
      "description": "Exposure to other and unspecified man-made environmental factors at residential institution During unspecified activity"
    },
    {
      "name": "W992",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area"
    },
    {
      "name": "W9920",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "W9921",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "W9922",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While working for income"
    },
    {
      "name": "W9923",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "W9924",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9928",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "W9929",
      "description": "Exposure to other and unspecified man-made environmental factors at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "W993",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area"
    },
    {
      "name": "W9930",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "W9931",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "W9932",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While working for income"
    },
    {
      "name": "W9933",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "W9934",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9938",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "W9939",
      "description": "Exposure to other and unspecified man-made environmental factors at sports and athletics area During unspecified activity"
    },
    {
      "name": "W994",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway"
    },
    {
      "name": "W9940",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While engaged in sports activity"
    },
    {
      "name": "W9941",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While engaged in leisure activity"
    },
    {
      "name": "W9942",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While working for income"
    },
    {
      "name": "W9943",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While engaged in other types of work"
    },
    {
      "name": "W9944",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9948",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway While engaged in other specified activities"
    },
    {
      "name": "W9949",
      "description": "Exposure to other and unspecified man-made environmental factors at street and highway During unspecified activity"
    },
    {
      "name": "W995",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area"
    },
    {
      "name": "W9950",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While engaged in sports activity"
    },
    {
      "name": "W9951",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While engaged in leisure activity"
    },
    {
      "name": "W9952",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While working for income"
    },
    {
      "name": "W9953",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While engaged in other types of work"
    },
    {
      "name": "W9954",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9958",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area While engaged in other specified activities"
    },
    {
      "name": "W9959",
      "description": "Exposure to other and unspecified man-made environmental factors at trade and service area During unspecified activity"
    },
    {
      "name": "W996",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area"
    },
    {
      "name": "W9960",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "W9961",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "W9962",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While working for income"
    },
    {
      "name": "W9963",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "W9964",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9968",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "W9969",
      "description": "Exposure to other and unspecified man-made environmental factors at industrial and construction area During unspecified activity"
    },
    {
      "name": "W997",
      "description": "Exposure to other and unspecified man-made environmental factors at farm"
    },
    {
      "name": "W9970",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While engaged in sports activity"
    },
    {
      "name": "W9971",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While engaged in leisure activity"
    },
    {
      "name": "W9972",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While working for income"
    },
    {
      "name": "W9973",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While engaged in other types of work"
    },
    {
      "name": "W9974",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9978",
      "description": "Exposure to other and unspecified man-made environmental factors at farm While engaged in other specified activities"
    },
    {
      "name": "W9979",
      "description": "Exposure to other and unspecified man-made environmental factors at farm During unspecified activity"
    },
    {
      "name": "W998",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places"
    },
    {
      "name": "W9980",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While engaged in sports activity"
    },
    {
      "name": "W9981",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While engaged in leisure activity"
    },
    {
      "name": "W9982",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While working for income"
    },
    {
      "name": "W9983",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While engaged in other types of work"
    },
    {
      "name": "W9984",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9988",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places While engaged in other specified activities"
    },
    {
      "name": "W9989",
      "description": "Exposure to other and unspecified man-made environmental factors at other specified places During unspecified activity"
    },
    {
      "name": "W999",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place"
    },
    {
      "name": "W9990",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While engaged in sports activity"
    },
    {
      "name": "W9991",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While engaged in leisure activity"
    },
    {
      "name": "W9992",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While working for income"
    },
    {
      "name": "W9993",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While engaged in other types of work"
    },
    {
      "name": "W9994",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "W9998",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place While engaged in other specified activities"
    },
    {
      "name": "W9999",
      "description": "Exposure to other and unspecified man-made environmental factors at unspecified place During unspecified activity"
    },
    {
      "name": "X000",
      "description": "Exposure to uncontrolled fire in building or structure at home"
    },
    {
      "name": "X0000",
      "description": "Exposure to uncontrolled fire in building or structure at home While engaged in sports activity"
    },
    {
      "name": "X0001",
      "description": "Exposure to uncontrolled fire in building or structure at home While engaged in leisure activity"
    },
    {
      "name": "X0002",
      "description": "Exposure to uncontrolled fire in building or structure at home While working for income"
    },
    {
      "name": "X0003",
      "description": "Exposure to uncontrolled fire in building or structure at home While engaged in other types of work"
    },
    {
      "name": "X0004",
      "description": "Exposure to uncontrolled fire in building or structure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0008",
      "description": "Exposure to uncontrolled fire in building or structure at home While engaged in other specified activities"
    },
    {
      "name": "X0009",
      "description": "Exposure to uncontrolled fire in building or structure at home During unspecified activity"
    },
    {
      "name": "X001",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution"
    },
    {
      "name": "X0010",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While engaged in sports activity"
    },
    {
      "name": "X0011",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0012",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While working for income"
    },
    {
      "name": "X0013",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While engaged in other types of work"
    },
    {
      "name": "X0014",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0018",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0019",
      "description": "Exposure to uncontrolled fire in building or structure at residential institution During unspecified activity"
    },
    {
      "name": "X002",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area"
    },
    {
      "name": "X0020",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0021",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0022",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0023",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0024",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0028",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0029",
      "description": "Exposure to uncontrolled fire in building or structure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X003",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area"
    },
    {
      "name": "X0030",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0031",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0032",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While working for income"
    },
    {
      "name": "X0033",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0034",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0038",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0039",
      "description": "Exposure to uncontrolled fire in building or structure at sports and athletics area During unspecified activity"
    },
    {
      "name": "X004",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway"
    },
    {
      "name": "X0040",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While engaged in sports activity"
    },
    {
      "name": "X0041",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0042",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While working for income"
    },
    {
      "name": "X0043",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While engaged in other types of work"
    },
    {
      "name": "X0044",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0048",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0049",
      "description": "Exposure to uncontrolled fire in building or structure at street and highway During unspecified activity"
    },
    {
      "name": "X005",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area"
    },
    {
      "name": "X0050",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0051",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0052",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While working for income"
    },
    {
      "name": "X0053",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0054",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0058",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0059",
      "description": "Exposure to uncontrolled fire in building or structure at trade and service area During unspecified activity"
    },
    {
      "name": "X006",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area"
    },
    {
      "name": "X0060",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0061",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0062",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While working for income"
    },
    {
      "name": "X0063",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0064",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0068",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0069",
      "description": "Exposure to uncontrolled fire in building or structure at industrial and construction area During unspecified activity"
    },
    {
      "name": "X007",
      "description": "Exposure to uncontrolled fire in building or structure at farm"
    },
    {
      "name": "X0070",
      "description": "Exposure to uncontrolled fire in building or structure at farm While engaged in sports activity"
    },
    {
      "name": "X0071",
      "description": "Exposure to uncontrolled fire in building or structure at farm While engaged in leisure activity"
    },
    {
      "name": "X0072",
      "description": "Exposure to uncontrolled fire in building or structure at farm While working for income"
    },
    {
      "name": "X0073",
      "description": "Exposure to uncontrolled fire in building or structure at farm While engaged in other types of work"
    },
    {
      "name": "X0074",
      "description": "Exposure to uncontrolled fire in building or structure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0078",
      "description": "Exposure to uncontrolled fire in building or structure at farm While engaged in other specified activities"
    },
    {
      "name": "X0079",
      "description": "Exposure to uncontrolled fire in building or structure at farm During unspecified activity"
    },
    {
      "name": "X008",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places"
    },
    {
      "name": "X0080",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While engaged in sports activity"
    },
    {
      "name": "X0081",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0082",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While working for income"
    },
    {
      "name": "X0083",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While engaged in other types of work"
    },
    {
      "name": "X0084",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0088",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0089",
      "description": "Exposure to uncontrolled fire in building or structure at other specified places During unspecified activity"
    },
    {
      "name": "X009",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place"
    },
    {
      "name": "X0090",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0091",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0092",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While working for income"
    },
    {
      "name": "X0093",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0094",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0098",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0099",
      "description": "Exposure to uncontrolled fire in building or structure at unspecified place During unspecified activity"
    },
    {
      "name": "X010",
      "description": "Exposure to uncontrolled fire, not in building or structure at home"
    },
    {
      "name": "X0100",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While engaged in sports activity"
    },
    {
      "name": "X0101",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While engaged in leisure activity"
    },
    {
      "name": "X0102",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While working for income"
    },
    {
      "name": "X0103",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While engaged in other types of work"
    },
    {
      "name": "X0104",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0108",
      "description": "Exposure to uncontrolled fire, not in building or structure at home While engaged in other specified activities"
    },
    {
      "name": "X0109",
      "description": "Exposure to uncontrolled fire, not in building or structure at home During unspecified activity"
    },
    {
      "name": "X011",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution"
    },
    {
      "name": "X0110",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While engaged in sports activity"
    },
    {
      "name": "X0111",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0112",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While working for income"
    },
    {
      "name": "X0113",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While engaged in other types of work"
    },
    {
      "name": "X0114",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0118",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0119",
      "description": "Exposure to uncontrolled fire, not in building or structure at residential institution During unspecified activity"
    },
    {
      "name": "X012",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area"
    },
    {
      "name": "X0120",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0121",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0122",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0123",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0124",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0128",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0129",
      "description": "Exposure to uncontrolled fire, not in building or structure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X013",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area"
    },
    {
      "name": "X0130",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0131",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0132",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While working for income"
    },
    {
      "name": "X0133",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0134",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0138",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0139",
      "description": "Exposure to uncontrolled fire, not in building or structure at sports and athletics area During unspecified activity"
    },
    {
      "name": "X014",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway"
    },
    {
      "name": "X0140",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While engaged in sports activity"
    },
    {
      "name": "X0141",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0142",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While working for income"
    },
    {
      "name": "X0143",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While engaged in other types of work"
    },
    {
      "name": "X0144",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0148",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0149",
      "description": "Exposure to uncontrolled fire, not in building or structure at street and highway During unspecified activity"
    },
    {
      "name": "X015",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area"
    },
    {
      "name": "X0150",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0151",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0152",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While working for income"
    },
    {
      "name": "X0153",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0154",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0158",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0159",
      "description": "Exposure to uncontrolled fire, not in building or structure at trade and service area During unspecified activity"
    },
    {
      "name": "X016",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area"
    },
    {
      "name": "X0160",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0161",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0162",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While working for income"
    },
    {
      "name": "X0163",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0164",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0168",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0169",
      "description": "Exposure to uncontrolled fire, not in building or structure at industrial and construction area During unspecified activity"
    },
    {
      "name": "X017",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm"
    },
    {
      "name": "X0170",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While engaged in sports activity"
    },
    {
      "name": "X0171",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While engaged in leisure activity"
    },
    {
      "name": "X0172",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While working for income"
    },
    {
      "name": "X0173",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While engaged in other types of work"
    },
    {
      "name": "X0174",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0178",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm While engaged in other specified activities"
    },
    {
      "name": "X0179",
      "description": "Exposure to uncontrolled fire, not in building or structure at farm During unspecified activity"
    },
    {
      "name": "X018",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places"
    },
    {
      "name": "X0180",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While engaged in sports activity"
    },
    {
      "name": "X0181",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0182",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While working for income"
    },
    {
      "name": "X0183",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While engaged in other types of work"
    },
    {
      "name": "X0184",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0188",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0189",
      "description": "Exposure to uncontrolled fire, not in building or structure at other specified places During unspecified activity"
    },
    {
      "name": "X019",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place"
    },
    {
      "name": "X0190",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0191",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0192",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While working for income"
    },
    {
      "name": "X0193",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0194",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0198",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0199",
      "description": "Exposure to uncontrolled fire, not in building or structure at unspecified place During unspecified activity"
    },
    {
      "name": "X020",
      "description": "Exposure to controlled fire in building or structure at home"
    },
    {
      "name": "X0200",
      "description": "Exposure to controlled fire in building or structure at home While engaged in sports activity"
    },
    {
      "name": "X0201",
      "description": "Exposure to controlled fire in building or structure at home While engaged in leisure activity"
    },
    {
      "name": "X0202",
      "description": "Exposure to controlled fire in building or structure at home While working for income"
    },
    {
      "name": "X0203",
      "description": "Exposure to controlled fire in building or structure at home While engaged in other types of work"
    },
    {
      "name": "X0204",
      "description": "Exposure to controlled fire in building or structure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0208",
      "description": "Exposure to controlled fire in building or structure at home While engaged in other specified activities"
    },
    {
      "name": "X0209",
      "description": "Exposure to controlled fire in building or structure at home During unspecified activity"
    },
    {
      "name": "X021",
      "description": "Exposure to controlled fire in building or structure at residential institution"
    },
    {
      "name": "X0210",
      "description": "Exposure to controlled fire in building or structure at residential institution While engaged in sports activity"
    },
    {
      "name": "X0211",
      "description": "Exposure to controlled fire in building or structure at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0212",
      "description": "Exposure to controlled fire in building or structure at residential institution While working for income"
    },
    {
      "name": "X0213",
      "description": "Exposure to controlled fire in building or structure at residential institution While engaged in other types of work"
    },
    {
      "name": "X0214",
      "description": "Exposure to controlled fire in building or structure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0218",
      "description": "Exposure to controlled fire in building or structure at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0219",
      "description": "Exposure to controlled fire in building or structure at residential institution During unspecified activity"
    },
    {
      "name": "X022",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area"
    },
    {
      "name": "X0220",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0221",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0222",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0223",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0224",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0228",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0229",
      "description": "Exposure to controlled fire in building or structure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X023",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area"
    },
    {
      "name": "X0230",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0231",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0232",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While working for income"
    },
    {
      "name": "X0233",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0234",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0238",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0239",
      "description": "Exposure to controlled fire in building or structure at sports and athletics area During unspecified activity"
    },
    {
      "name": "X024",
      "description": "Exposure to controlled fire in building or structure at street and highway"
    },
    {
      "name": "X0240",
      "description": "Exposure to controlled fire in building or structure at street and highway While engaged in sports activity"
    },
    {
      "name": "X0241",
      "description": "Exposure to controlled fire in building or structure at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0242",
      "description": "Exposure to controlled fire in building or structure at street and highway While working for income"
    },
    {
      "name": "X0243",
      "description": "Exposure to controlled fire in building or structure at street and highway While engaged in other types of work"
    },
    {
      "name": "X0244",
      "description": "Exposure to controlled fire in building or structure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0248",
      "description": "Exposure to controlled fire in building or structure at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0249",
      "description": "Exposure to controlled fire in building or structure at street and highway During unspecified activity"
    },
    {
      "name": "X025",
      "description": "Exposure to controlled fire in building or structure at trade and service area"
    },
    {
      "name": "X0250",
      "description": "Exposure to controlled fire in building or structure at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0251",
      "description": "Exposure to controlled fire in building or structure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0252",
      "description": "Exposure to controlled fire in building or structure at trade and service area While working for income"
    },
    {
      "name": "X0253",
      "description": "Exposure to controlled fire in building or structure at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0254",
      "description": "Exposure to controlled fire in building or structure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0258",
      "description": "Exposure to controlled fire in building or structure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0259",
      "description": "Exposure to controlled fire in building or structure at trade and service area During unspecified activity"
    },
    {
      "name": "X026",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area"
    },
    {
      "name": "X0260",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0261",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0262",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While working for income"
    },
    {
      "name": "X0263",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0264",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0268",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0269",
      "description": "Exposure to controlled fire in building or structure at industrial and construction area During unspecified activity"
    },
    {
      "name": "X027",
      "description": "Exposure to controlled fire in building or structure at farm"
    },
    {
      "name": "X0270",
      "description": "Exposure to controlled fire in building or structure at farm While engaged in sports activity"
    },
    {
      "name": "X0271",
      "description": "Exposure to controlled fire in building or structure at farm While engaged in leisure activity"
    },
    {
      "name": "X0272",
      "description": "Exposure to controlled fire in building or structure at farm While working for income"
    },
    {
      "name": "X0273",
      "description": "Exposure to controlled fire in building or structure at farm While engaged in other types of work"
    },
    {
      "name": "X0274",
      "description": "Exposure to controlled fire in building or structure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0278",
      "description": "Exposure to controlled fire in building or structure at farm While engaged in other specified activities"
    },
    {
      "name": "X0279",
      "description": "Exposure to controlled fire in building or structure at farm During unspecified activity"
    },
    {
      "name": "X028",
      "description": "Exposure to controlled fire in building or structure at other specified places"
    },
    {
      "name": "X0280",
      "description": "Exposure to controlled fire in building or structure at other specified places While engaged in sports activity"
    },
    {
      "name": "X0281",
      "description": "Exposure to controlled fire in building or structure at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0282",
      "description": "Exposure to controlled fire in building or structure at other specified places While working for income"
    },
    {
      "name": "X0283",
      "description": "Exposure to controlled fire in building or structure at other specified places While engaged in other types of work"
    },
    {
      "name": "X0284",
      "description": "Exposure to controlled fire in building or structure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0288",
      "description": "Exposure to controlled fire in building or structure at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0289",
      "description": "Exposure to controlled fire in building or structure at other specified places During unspecified activity"
    },
    {
      "name": "X029",
      "description": "Exposure to controlled fire in building or structure at unspecified place"
    },
    {
      "name": "X0290",
      "description": "Exposure to controlled fire in building or structure at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0291",
      "description": "Exposure to controlled fire in building or structure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0292",
      "description": "Exposure to controlled fire in building or structure at unspecified place While working for income"
    },
    {
      "name": "X0293",
      "description": "Exposure to controlled fire in building or structure at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0294",
      "description": "Exposure to controlled fire in building or structure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0298",
      "description": "Exposure to controlled fire in building or structure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0299",
      "description": "Exposure to controlled fire in building or structure at unspecified place During unspecified activity"
    },
    {
      "name": "X030",
      "description": "Exposure to controlled fire, not in building or structure at home"
    },
    {
      "name": "X0300",
      "description": "Exposure to controlled fire, not in building or structure at home While engaged in sports activity"
    },
    {
      "name": "X0301",
      "description": "Exposure to controlled fire, not in building or structure at home While engaged in leisure activity"
    },
    {
      "name": "X0302",
      "description": "Exposure to controlled fire, not in building or structure at home While working for income"
    },
    {
      "name": "X0303",
      "description": "Exposure to controlled fire, not in building or structure at home While engaged in other types of work"
    },
    {
      "name": "X0304",
      "description": "Exposure to controlled fire, not in building or structure at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0308",
      "description": "Exposure to controlled fire, not in building or structure at home While engaged in other specified activities"
    },
    {
      "name": "X0309",
      "description": "Exposure to controlled fire, not in building or structure at home During unspecified activity"
    },
    {
      "name": "X031",
      "description": "Exposure to controlled fire, not in building or structure at residential institution"
    },
    {
      "name": "X0310",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While engaged in sports activity"
    },
    {
      "name": "X0311",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0312",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While working for income"
    },
    {
      "name": "X0313",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While engaged in other types of work"
    },
    {
      "name": "X0314",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0318",
      "description": "Exposure to controlled fire, not in building or structure at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0319",
      "description": "Exposure to controlled fire, not in building or structure at residential institution During unspecified activity"
    },
    {
      "name": "X032",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area"
    },
    {
      "name": "X0320",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0321",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0322",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0323",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0324",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0328",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0329",
      "description": "Exposure to controlled fire, not in building or structure at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X033",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area"
    },
    {
      "name": "X0330",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0331",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0332",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While working for income"
    },
    {
      "name": "X0333",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0334",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0338",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0339",
      "description": "Exposure to controlled fire, not in building or structure at sports and athletics area During unspecified activity"
    },
    {
      "name": "X034",
      "description": "Exposure to controlled fire, not in building or structure at street and highway"
    },
    {
      "name": "X0340",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While engaged in sports activity"
    },
    {
      "name": "X0341",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0342",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While working for income"
    },
    {
      "name": "X0343",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While engaged in other types of work"
    },
    {
      "name": "X0344",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0348",
      "description": "Exposure to controlled fire, not in building or structure at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0349",
      "description": "Exposure to controlled fire, not in building or structure at street and highway During unspecified activity"
    },
    {
      "name": "X035",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area"
    },
    {
      "name": "X0350",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0351",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0352",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While working for income"
    },
    {
      "name": "X0353",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0354",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0358",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0359",
      "description": "Exposure to controlled fire, not in building or structure at trade and service area During unspecified activity"
    },
    {
      "name": "X036",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area"
    },
    {
      "name": "X0360",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0361",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0362",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While working for income"
    },
    {
      "name": "X0363",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0364",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0368",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0369",
      "description": "Exposure to controlled fire, not in building or structure at industrial and construction area During unspecified activity"
    },
    {
      "name": "X037",
      "description": "Exposure to controlled fire, not in building or structure at farm"
    },
    {
      "name": "X0370",
      "description": "Exposure to controlled fire, not in building or structure at farm While engaged in sports activity"
    },
    {
      "name": "X0371",
      "description": "Exposure to controlled fire, not in building or structure at farm While engaged in leisure activity"
    },
    {
      "name": "X0372",
      "description": "Exposure to controlled fire, not in building or structure at farm While working for income"
    },
    {
      "name": "X0373",
      "description": "Exposure to controlled fire, not in building or structure at farm While engaged in other types of work"
    },
    {
      "name": "X0374",
      "description": "Exposure to controlled fire, not in building or structure at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0378",
      "description": "Exposure to controlled fire, not in building or structure at farm While engaged in other specified activities"
    },
    {
      "name": "X0379",
      "description": "Exposure to controlled fire, not in building or structure at farm During unspecified activity"
    },
    {
      "name": "X038",
      "description": "Exposure to controlled fire, not in building or structure at other specified places"
    },
    {
      "name": "X0380",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While engaged in sports activity"
    },
    {
      "name": "X0381",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0382",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While working for income"
    },
    {
      "name": "X0383",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While engaged in other types of work"
    },
    {
      "name": "X0384",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0388",
      "description": "Exposure to controlled fire, not in building or structure at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0389",
      "description": "Exposure to controlled fire, not in building or structure at other specified places During unspecified activity"
    },
    {
      "name": "X039",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place"
    },
    {
      "name": "X0390",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0391",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0392",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While working for income"
    },
    {
      "name": "X0393",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0394",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0398",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0399",
      "description": "Exposure to controlled fire, not in building or structure at unspecified place During unspecified activity"
    },
    {
      "name": "X040",
      "description": "Exposure to ignition of highly flammable material at home"
    },
    {
      "name": "X0400",
      "description": "Exposure to ignition of highly flammable material at home While engaged in sports activity"
    },
    {
      "name": "X0401",
      "description": "Exposure to ignition of highly flammable material at home While engaged in leisure activity"
    },
    {
      "name": "X0402",
      "description": "Exposure to ignition of highly flammable material at home While working for income"
    },
    {
      "name": "X0403",
      "description": "Exposure to ignition of highly flammable material at home While engaged in other types of work"
    },
    {
      "name": "X0404",
      "description": "Exposure to ignition of highly flammable material at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0408",
      "description": "Exposure to ignition of highly flammable material at home While engaged in other specified activities"
    },
    {
      "name": "X0409",
      "description": "Exposure to ignition of highly flammable material at home During unspecified activity"
    },
    {
      "name": "X041",
      "description": "Exposure to ignition of highly flammable material at residential institution"
    },
    {
      "name": "X0410",
      "description": "Exposure to ignition of highly flammable material at residential institution While engaged in sports activity"
    },
    {
      "name": "X0411",
      "description": "Exposure to ignition of highly flammable material at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0412",
      "description": "Exposure to ignition of highly flammable material at residential institution While working for income"
    },
    {
      "name": "X0413",
      "description": "Exposure to ignition of highly flammable material at residential institution While engaged in other types of work"
    },
    {
      "name": "X0414",
      "description": "Exposure to ignition of highly flammable material at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0418",
      "description": "Exposure to ignition of highly flammable material at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0419",
      "description": "Exposure to ignition of highly flammable material at residential institution During unspecified activity"
    },
    {
      "name": "X042",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area"
    },
    {
      "name": "X0420",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0421",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0422",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0423",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0424",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0428",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0429",
      "description": "Exposure to ignition of highly flammable material at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X043",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area"
    },
    {
      "name": "X0430",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0431",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0432",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While working for income"
    },
    {
      "name": "X0433",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0434",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0438",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0439",
      "description": "Exposure to ignition of highly flammable material at sports and athletics area During unspecified activity"
    },
    {
      "name": "X044",
      "description": "Exposure to ignition of highly flammable material at street and highway"
    },
    {
      "name": "X0440",
      "description": "Exposure to ignition of highly flammable material at street and highway While engaged in sports activity"
    },
    {
      "name": "X0441",
      "description": "Exposure to ignition of highly flammable material at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0442",
      "description": "Exposure to ignition of highly flammable material at street and highway While working for income"
    },
    {
      "name": "X0443",
      "description": "Exposure to ignition of highly flammable material at street and highway While engaged in other types of work"
    },
    {
      "name": "X0444",
      "description": "Exposure to ignition of highly flammable material at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0448",
      "description": "Exposure to ignition of highly flammable material at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0449",
      "description": "Exposure to ignition of highly flammable material at street and highway During unspecified activity"
    },
    {
      "name": "X045",
      "description": "Exposure to ignition of highly flammable material at trade and service area"
    },
    {
      "name": "X0450",
      "description": "Exposure to ignition of highly flammable material at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0451",
      "description": "Exposure to ignition of highly flammable material at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0452",
      "description": "Exposure to ignition of highly flammable material at trade and service area  While working for income"
    },
    {
      "name": "X0453",
      "description": "Exposure to ignition of highly flammable material at trade and service area  While engaged in other types of work"
    },
    {
      "name": "X0454",
      "description": "Exposure to ignition of highly flammable material at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0458",
      "description": "Exposure to ignition of highly flammable material at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0459",
      "description": "Exposure to ignition of highly flammable material at trade and service area During unspecified activity"
    },
    {
      "name": "X046",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area"
    },
    {
      "name": "X0460",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0461",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0462",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While working for income"
    },
    {
      "name": "X0463",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0464",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0468",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0469",
      "description": "Exposure to ignition of highly flammable material at industrial and construction area During unspecified activity"
    },
    {
      "name": "X047",
      "description": "Exposure to ignition of highly flammable material at farm"
    },
    {
      "name": "X0470",
      "description": "Exposure to ignition of highly flammable material at farm While engaged in sports activity"
    },
    {
      "name": "X0471",
      "description": "Exposure to ignition of highly flammable material at farm While engaged in leisure activity"
    },
    {
      "name": "X0472",
      "description": "Exposure to ignition of highly flammable material at farm While working for income"
    },
    {
      "name": "X0473",
      "description": "Exposure to ignition of highly flammable material at farm While engaged in other types of work"
    },
    {
      "name": "X0474",
      "description": "Exposure to ignition of highly flammable material at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0478",
      "description": "Exposure to ignition of highly flammable material at farm While engaged in other specified activities"
    },
    {
      "name": "X0479",
      "description": "Exposure to ignition of highly flammable material at farm During unspecified activity"
    },
    {
      "name": "X048",
      "description": "Exposure to ignition of highly flammable material at other specified places"
    },
    {
      "name": "X0480",
      "description": "Exposure to ignition of highly flammable material at other specified places While engaged in sports activity"
    },
    {
      "name": "X0481",
      "description": "Exposure to ignition of highly flammable material at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0482",
      "description": "Exposure to ignition of highly flammable material at other specified places While working for income"
    },
    {
      "name": "X0483",
      "description": "Exposure to ignition of highly flammable material at other specified places While engaged in other types of work"
    },
    {
      "name": "X0484",
      "description": "Exposure to ignition of highly flammable material at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0488",
      "description": "Exposure to ignition of highly flammable material at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0489",
      "description": "Exposure to ignition of highly flammable material at other specified places During unspecified activity"
    },
    {
      "name": "X049",
      "description": "Exposure to ignition of highly flammable material at unspecified place"
    },
    {
      "name": "X0490",
      "description": "Exposure to ignition of highly flammable material at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0491",
      "description": "Exposure to ignition of highly flammable material at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0492",
      "description": "Exposure to ignition of highly flammable material at unspecified place While working for income"
    },
    {
      "name": "X0493",
      "description": "Exposure to ignition of highly flammable material at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0494",
      "description": "Exposure to ignition of highly flammable material at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0498",
      "description": "Exposure to ignition of highly flammable material at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0499",
      "description": "Exposure to ignition of highly flammable material at unspecified place During unspecified activity"
    },
    {
      "name": "X050",
      "description": "Exposure to ignition of melting of nightwear at home"
    },
    {
      "name": "X0500",
      "description": "Exposure to ignition of melting of nightwear at home While engaged in sports activity"
    },
    {
      "name": "X0501",
      "description": "Exposure to ignition of melting of nightwear at home While engaged in leisure activity"
    },
    {
      "name": "X0502",
      "description": "Exposure to ignition of melting of nightwear at home While working for income"
    },
    {
      "name": "X0503",
      "description": "Exposure to ignition of melting of nightwear at home While engaged in other types of work"
    },
    {
      "name": "X0504",
      "description": "Exposure to ignition of melting of nightwear at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0508",
      "description": "Exposure to ignition of melting of nightwear at home While engaged in other specified activities"
    },
    {
      "name": "X0509",
      "description": "Exposure to ignition of melting of nightwear at home During unspecified activity"
    },
    {
      "name": "X051",
      "description": "Exposure to ignition of melting of nightwear at residential institution"
    },
    {
      "name": "X0510",
      "description": "Exposure to ignition of melting of nightwear at residential institution While engaged in sports activity"
    },
    {
      "name": "X0511",
      "description": "Exposure to ignition of melting of nightwear at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0512",
      "description": "Exposure to ignition of melting of nightwear at residential institution While working for income"
    },
    {
      "name": "X0513",
      "description": "Exposure to ignition of melting of nightwear at residential institution While engaged in other types of work"
    },
    {
      "name": "X0514",
      "description": "Exposure to ignition of melting of nightwear at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0518",
      "description": "Exposure to ignition of melting of nightwear at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0519",
      "description": "Exposure to ignition of melting of nightwear at residential institution During unspecified activity"
    },
    {
      "name": "X052",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area"
    },
    {
      "name": "X0520",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0521",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0522",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0523",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0524",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0528",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0529",
      "description": "Exposure to ignition of melting of nightwear at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X053",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area"
    },
    {
      "name": "X0530",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0531",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0532",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While working for income"
    },
    {
      "name": "X0533",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0534",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0538",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0539",
      "description": "Exposure to ignition of melting of nightwear at sports and athletics area During unspecified activity"
    },
    {
      "name": "X054",
      "description": "Exposure to ignition of melting of nightwear at street and highway"
    },
    {
      "name": "X0540",
      "description": "Exposure to ignition of melting of nightwear at street and highway While engaged in sports activity"
    },
    {
      "name": "X0541",
      "description": "Exposure to ignition of melting of nightwear at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0542",
      "description": "Exposure to ignition of melting of nightwear at street and highway While working for income"
    },
    {
      "name": "X0543",
      "description": "Exposure to ignition of melting of nightwear at street and highway While engaged in other types of work"
    },
    {
      "name": "X0544",
      "description": "Exposure to ignition of melting of nightwear at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0548",
      "description": "Exposure to ignition of melting of nightwear at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0549",
      "description": "Exposure to ignition of melting of nightwear at street and highway During unspecified activity"
    },
    {
      "name": "X055",
      "description": "Exposure to ignition of melting of nightwear at trade and service area"
    },
    {
      "name": "X0550",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While engaged in sports activity "
    },
    {
      "name": "X0551",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0552",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While working for income"
    },
    {
      "name": "X0553",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0554",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0558",
      "description": "Exposure to ignition of melting of nightwear at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0559",
      "description": "Exposure to ignition of melting of nightwear at trade and service area During unspecified activity"
    },
    {
      "name": "X056",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area"
    },
    {
      "name": "X0560",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0561",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0562",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While working for income"
    },
    {
      "name": "X0563",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0564",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0568",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0569",
      "description": "Exposure to ignition of melting of nightwear at industrial and construction area During unspecified activity"
    },
    {
      "name": "X057",
      "description": "Exposure to ignition of melting of nightwear at farm"
    },
    {
      "name": "X0570",
      "description": "Exposure to ignition of melting of nightwear at farm While engaged in sports activity"
    },
    {
      "name": "X0571",
      "description": "Exposure to ignition of melting of nightwear at farm While engaged in leisure activity"
    },
    {
      "name": "X0572",
      "description": "Exposure to ignition of melting of nightwear at farm While working for income"
    },
    {
      "name": "X0573",
      "description": "Exposure to ignition of melting of nightwear at farm While engaged in other types of work"
    },
    {
      "name": "X0574",
      "description": "Exposure to ignition of melting of nightwear at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0578",
      "description": "Exposure to ignition of melting of nightwear at farm While engaged in other specified activities"
    },
    {
      "name": "X0579",
      "description": "Exposure to ignition of melting of nightwear at farm During unspecified activity"
    },
    {
      "name": "X058",
      "description": "Exposure to ignition of melting of nightwear at other specified places"
    },
    {
      "name": "X0580",
      "description": "Exposure to ignition of melting of nightwear at other specified places While engaged in sports activity"
    },
    {
      "name": "X0581",
      "description": "Exposure to ignition of melting of nightwear at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0582",
      "description": "Exposure to ignition of melting of nightwear at other specified places While working for income"
    },
    {
      "name": "X0583",
      "description": "Exposure to ignition of melting of nightwear at other specified places While engaged in other types of work"
    },
    {
      "name": "X0584",
      "description": "Exposure to ignition of melting of nightwear at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0588",
      "description": "Exposure to ignition of melting of nightwear at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0589",
      "description": "Exposure to ignition of melting of nightwear at other specified places During unspecified activity"
    },
    {
      "name": "X059",
      "description": "Exposure to ignition of melting of nightwear at unspecified place"
    },
    {
      "name": "X0590",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0591",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0592",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While working for income"
    },
    {
      "name": "X0593",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0594",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0598",
      "description": "Exposure to ignition of melting of nightwear at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0599",
      "description": "Exposure to ignition of melting of nightwear at unspecified place During unspecified activity"
    },
    {
      "name": "X060",
      "description": "Exposure to ignition of melting of other clothing and apparel at home"
    },
    {
      "name": "X0600",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While engaged in sports activity"
    },
    {
      "name": "X0601",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While engaged in leisure activity"
    },
    {
      "name": "X0602",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While working for income"
    },
    {
      "name": "X0603",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While engaged in other types of work"
    },
    {
      "name": "X0604",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0608",
      "description": "Exposure to ignition of melting of other clothing and apparel at home While engaged in other specified activities"
    },
    {
      "name": "X0609",
      "description": "Exposure to ignition of melting of other clothing and apparel at home During unspecified activity"
    },
    {
      "name": "X061",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution"
    },
    {
      "name": "X0610",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While engaged in sports activity"
    },
    {
      "name": "X0611",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0612",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While working for income"
    },
    {
      "name": "X0613",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While engaged in other types of work"
    },
    {
      "name": "X0614",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0618",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0619",
      "description": "Exposure to ignition of melting of other clothing and apparel at residential institution During unspecified activity"
    },
    {
      "name": "X062",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area"
    },
    {
      "name": "X0620",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0621",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0622",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0623",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0624",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0628",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0629",
      "description": "Exposure to ignition of melting of other clothing and apparel at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X063",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area"
    },
    {
      "name": "X0630",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0631",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0632",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While working for income"
    },
    {
      "name": "X0633",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0634",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0638",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0639",
      "description": "Exposure to ignition of melting of other clothing and apparel at sports and athletics area During unspecified activity"
    },
    {
      "name": "X064",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway"
    },
    {
      "name": "X0640",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While engaged in sports activity"
    },
    {
      "name": "X0641",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0642",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While working for income"
    },
    {
      "name": "X0643",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While engaged in other types of work"
    },
    {
      "name": "X0644",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0648",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0649",
      "description": "Exposure to ignition of melting of other clothing and apparel at street and highway During unspecified activity"
    },
    {
      "name": "X065",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area"
    },
    {
      "name": "X0650",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0651",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0652",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While working for income"
    },
    {
      "name": "X0653",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0654",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0658",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0659",
      "description": "Exposure to ignition of melting of other clothing and apparel at trade and service area During unspecified activity"
    },
    {
      "name": "X066",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area"
    },
    {
      "name": "X0660",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0661",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0662",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While working for income"
    },
    {
      "name": "X0663",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0664",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0668",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0669",
      "description": "Exposure to ignition of melting of other clothing and apparel at industrial and construction area During unspecified activity"
    },
    {
      "name": "X067",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm"
    },
    {
      "name": "X0670",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While engaged in sports activity"
    },
    {
      "name": "X0671",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While engaged in leisure activity"
    },
    {
      "name": "X0672",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While working for income"
    },
    {
      "name": "X0673",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While engaged in other types of work"
    },
    {
      "name": "X0674",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0678",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm While engaged in other specified activities"
    },
    {
      "name": "X0679",
      "description": "Exposure to ignition of melting of other clothing and apparel at farm During unspecified activity"
    },
    {
      "name": "X068",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places"
    },
    {
      "name": "X0680",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While engaged in sports activity"
    },
    {
      "name": "X0681",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0682",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While working for income"
    },
    {
      "name": "X0683",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While engaged in other types of work"
    },
    {
      "name": "X0684",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0688",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0689",
      "description": "Exposure to ignition of melting of other clothing and apparel at other specified places During unspecified activity"
    },
    {
      "name": "X069",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place"
    },
    {
      "name": "X0690",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0691",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0692",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While working for income"
    },
    {
      "name": "X0693",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0694",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0698",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0699",
      "description": "Exposure to ignition of melting of other clothing and apparel at unspecified place During unspecified activity"
    },
    {
      "name": "X080",
      "description": "Exposure to other specified smoke, fire and flames at home"
    },
    {
      "name": "X0800",
      "description": "Exposure to other specified smoke, fire and flames at home While engaged in sports activity"
    },
    {
      "name": "X0801",
      "description": "Exposure to other specified smoke, fire and flames at home While engaged in leisure activity"
    },
    {
      "name": "X0802",
      "description": "Exposure to other specified smoke, fire and flames at home While working for income"
    },
    {
      "name": "X0803",
      "description": "Exposure to other specified smoke, fire and flames at home While engaged in other types of work"
    },
    {
      "name": "X0804",
      "description": "Exposure to other specified smoke, fire and flames at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0808",
      "description": "Exposure to other specified smoke, fire and flames at home While engaged in other specified activities"
    },
    {
      "name": "X0809",
      "description": "Exposure to other specified smoke, fire and flames at home During unspecified activity"
    },
    {
      "name": "X081",
      "description": "Exposure to other specified smoke, fire and flames at residential institution"
    },
    {
      "name": "X0810",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While engaged in sports activity"
    },
    {
      "name": "X0811",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0812",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While working for income"
    },
    {
      "name": "X0813",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While engaged in other types of work"
    },
    {
      "name": "X0814",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0818",
      "description": "Exposure to other specified smoke, fire and flames at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0819",
      "description": "Exposure to other specified smoke, fire and flames at residential institution During unspecified activity"
    },
    {
      "name": "X082",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area"
    },
    {
      "name": "X0820",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0821",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0822",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0823",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0824",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0828",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0829",
      "description": "Exposure to other specified smoke, fire and flames at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X083",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area"
    },
    {
      "name": "X0830",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0831",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0832",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While working for income"
    },
    {
      "name": "X0833",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0834",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0838",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0839",
      "description": "Exposure to other specified smoke, fire and flames at sports and athletics area During unspecified activity"
    },
    {
      "name": "X084",
      "description": "Exposure to other specified smoke, fire and flames at street and highway"
    },
    {
      "name": "X0840",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While engaged in sports activity"
    },
    {
      "name": "X0841",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0842",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While working for income"
    },
    {
      "name": "X0843",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While engaged in other types of work"
    },
    {
      "name": "X0844",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0848",
      "description": "Exposure to other specified smoke, fire and flames at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0849",
      "description": "Exposure to other specified smoke, fire and flames at street and highway During unspecified activity"
    },
    {
      "name": "X085",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area"
    },
    {
      "name": "X0850",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0851",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0852",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While working for income"
    },
    {
      "name": "X0853",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0854",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0858",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0859",
      "description": "Exposure to other specified smoke, fire and flames at trade and service area During unspecified activity"
    },
    {
      "name": "X086",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area"
    },
    {
      "name": "X0860",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0861",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0862",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While working for income"
    },
    {
      "name": "X0863",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0864",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0868",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0869",
      "description": "Exposure to other specified smoke, fire and flames at industrial and construction area During unspecified activity"
    },
    {
      "name": "X087",
      "description": "Exposure to other specified smoke, fire and flames at farm"
    },
    {
      "name": "X0870",
      "description": "Exposure to other specified smoke, fire and flames at farm While engaged in sports activity"
    },
    {
      "name": "X0871",
      "description": "Exposure to other specified smoke, fire and flames at farm While engaged in leisure activity"
    },
    {
      "name": "X0872",
      "description": "Exposure to other specified smoke, fire and flames at farm While working for income"
    },
    {
      "name": "X0873",
      "description": "Exposure to other specified smoke, fire and flames at farm While engaged in other types of work"
    },
    {
      "name": "X0874",
      "description": "Exposure to other specified smoke, fire and flames at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0878",
      "description": "Exposure to other specified smoke, fire and flames at farm While engaged in other specified activities"
    },
    {
      "name": "X0879",
      "description": "Exposure to other specified smoke, fire and flames at farm During unspecified activity"
    },
    {
      "name": "X088",
      "description": "Exposure to other specified smoke, fire and flames at other specified places"
    },
    {
      "name": "X0880",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While engaged in sports activity"
    },
    {
      "name": "X0881",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0882",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While working for income"
    },
    {
      "name": "X0883",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While engaged in other types of work"
    },
    {
      "name": "X0884",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0888",
      "description": "Exposure to other specified smoke, fire and flames at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0889",
      "description": "Exposure to other specified smoke, fire and flames at other specified places During unspecified activity"
    },
    {
      "name": "X089",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place"
    },
    {
      "name": "X0890",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0891",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0892",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While working for income"
    },
    {
      "name": "X0893",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0894",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0898",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0899",
      "description": "Exposure to other specified smoke, fire and flames at unspecified place During unspecified activity"
    },
    {
      "name": "X090",
      "description": "Exposure to unspecified smoke, fire and flames at home"
    },
    {
      "name": "X0900",
      "description": "Exposure to unspecified smoke, fire and flames at home While engaged in sports activity"
    },
    {
      "name": "X0901",
      "description": "Exposure to unspecified smoke, fire and flames at home While engaged in leisure activity"
    },
    {
      "name": "X0902",
      "description": "Exposure to unspecified smoke, fire and flames at home While working for income"
    },
    {
      "name": "X0903",
      "description": "Exposure to unspecified smoke, fire and flames at home While engaged in other types of work"
    },
    {
      "name": "X0904",
      "description": "Exposure to unspecified smoke, fire and flames at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0908",
      "description": "Exposure to unspecified smoke, fire and flames at home While engaged in other specified activities"
    },
    {
      "name": "X0909",
      "description": "Exposure to unspecified smoke, fire and flames at home During unspecified activity"
    },
    {
      "name": "X091",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution"
    },
    {
      "name": "X0910",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While engaged in sports activity"
    },
    {
      "name": "X0911",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While engaged in leisure activity"
    },
    {
      "name": "X0912",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While working for income"
    },
    {
      "name": "X0913",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While engaged in other types of work"
    },
    {
      "name": "X0914",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0918",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution While engaged in other specified activities"
    },
    {
      "name": "X0919",
      "description": "Exposure to unspecified smoke, fire and flames at residential institution During unspecified activity"
    },
    {
      "name": "X092",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area"
    },
    {
      "name": "X0920",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X0921",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X0922",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X0923",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X0924",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0928",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X0929",
      "description": "Exposure to unspecified smoke, fire and flames at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X093",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area"
    },
    {
      "name": "X0930",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X0931",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X0932",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While working for income"
    },
    {
      "name": "X0933",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X0934",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0938",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X0939",
      "description": "Exposure to unspecified smoke, fire and flames at sports and athletics area During unspecified activity"
    },
    {
      "name": "X094",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway"
    },
    {
      "name": "X0940",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While engaged in sports activity"
    },
    {
      "name": "X0941",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While engaged in leisure activity"
    },
    {
      "name": "X0942",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While working for income"
    },
    {
      "name": "X0943",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While engaged in other types of work"
    },
    {
      "name": "X0944",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0948",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway While engaged in other specified activities"
    },
    {
      "name": "X0949",
      "description": "Exposure to unspecified smoke, fire and flames at street and highway During unspecified activity"
    },
    {
      "name": "X095",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area"
    },
    {
      "name": "X0950",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While engaged in sports activity"
    },
    {
      "name": "X0951",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X0952",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While working for income"
    },
    {
      "name": "X0953",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While engaged in other types of work"
    },
    {
      "name": "X0954",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0958",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X0959",
      "description": "Exposure to unspecified smoke, fire and flames at trade and service area During unspecified activity"
    },
    {
      "name": "X096",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area"
    },
    {
      "name": "X0960",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X0961",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X0962",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While working for income"
    },
    {
      "name": "X0963",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X0964",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0968",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X0969",
      "description": "Exposure to unspecified smoke, fire and flames at industrial and construction area During unspecified activity"
    },
    {
      "name": "X097",
      "description": "Exposure to unspecified smoke, fire and flames at farm"
    },
    {
      "name": "X0970",
      "description": "Exposure to unspecified smoke, fire and flames at farm While engaged in sports activity"
    },
    {
      "name": "X0971",
      "description": "Exposure to unspecified smoke, fire and flames at farm While engaged in leisure activity"
    },
    {
      "name": "X0972",
      "description": "Exposure to unspecified smoke, fire and flames at farm While working for income"
    },
    {
      "name": "X0973",
      "description": "Exposure to unspecified smoke, fire and flames at farm While engaged in other types of work"
    },
    {
      "name": "X0974",
      "description": "Exposure to unspecified smoke, fire and flames at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0978",
      "description": "Exposure to unspecified smoke, fire and flames at farm While engaged in other specified activities"
    },
    {
      "name": "X0979",
      "description": "Exposure to unspecified smoke, fire and flames at farm During unspecified activity"
    },
    {
      "name": "X098",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places"
    },
    {
      "name": "X0980",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While engaged in sports activity"
    },
    {
      "name": "X0981",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While engaged in leisure activity"
    },
    {
      "name": "X0982",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While working for income"
    },
    {
      "name": "X0983",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While engaged in other types of work"
    },
    {
      "name": "X0984",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0988",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places While engaged in other specified activities"
    },
    {
      "name": "X0989",
      "description": "Exposure to unspecified smoke, fire and flames at other specified places During unspecified activity"
    },
    {
      "name": "X099",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place"
    },
    {
      "name": "X0990",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While engaged in sports activity"
    },
    {
      "name": "X0991",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X0992",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While working for income"
    },
    {
      "name": "X0993",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While engaged in other types of work"
    },
    {
      "name": "X0994",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X0998",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X0999",
      "description": "Exposure to unspecified smoke, fire and flames at unspecified place During unspecified activity"
    },
    {
      "name": "X100",
      "description": "Contact with hot drinks, food, fats and cooking oils at home"
    },
    {
      "name": "X1000",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While engaged in sports activity"
    },
    {
      "name": "X1001",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While engaged in leisure activity"
    },
    {
      "name": "X1002",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While working for income"
    },
    {
      "name": "X1003",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While engaged in other types of work"
    },
    {
      "name": "X1004",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1008",
      "description": "Contact with hot drinks, food, fats and cooking oils at home While engaged in other specified activities"
    },
    {
      "name": "X1009",
      "description": "Contact with hot drinks, food, fats and cooking oils at home During unspecified activity"
    },
    {
      "name": "X101",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution"
    },
    {
      "name": "X1010",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While engaged in sports activity"
    },
    {
      "name": "X1011",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1012",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While working for income"
    },
    {
      "name": "X1013",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While engaged in other types of work"
    },
    {
      "name": "X1014",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1018",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1019",
      "description": "Contact with hot drinks, food, fats and cooking oils at residential institution During unspecified activity"
    },
    {
      "name": "X102",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area"
    },
    {
      "name": "X1020",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1021",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1022",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1023",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1024",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1028",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1029",
      "description": "Contact with hot drinks, food, fats and cooking oils at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X103",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area"
    },
    {
      "name": "X1030",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1031",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1032",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While working for income"
    },
    {
      "name": "X1033",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1034",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1038",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1039",
      "description": "Contact with hot drinks, food, fats and cooking oils at sports and athletics area During unspecified activity"
    },
    {
      "name": "X104",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway"
    },
    {
      "name": "X1040",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While engaged in sports activity"
    },
    {
      "name": "X1041",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1042",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While working for income"
    },
    {
      "name": "X1043",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While engaged in other types of work"
    },
    {
      "name": "X1044",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1048",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1049",
      "description": "Contact with hot drinks, food, fats and cooking oils at street and highway During unspecified activity"
    },
    {
      "name": "X105",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area"
    },
    {
      "name": "X1050",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1051",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1052",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While working for income"
    },
    {
      "name": "X1053",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1054",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1058",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1059",
      "description": "Contact with hot drinks, food, fats and cooking oils at trade and service area During unspecified activity"
    },
    {
      "name": "X106",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area"
    },
    {
      "name": "X1060",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1061",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1062",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While working for income"
    },
    {
      "name": "X1063",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1064",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1068",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1069",
      "description": "Contact with hot drinks, food, fats and cooking oils at industrial and construction area During unspecified activity"
    },
    {
      "name": "X107",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm"
    },
    {
      "name": "X1070",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While engaged in sports activity"
    },
    {
      "name": "X1071",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While engaged in leisure activity"
    },
    {
      "name": "X1072",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While working for income"
    },
    {
      "name": "X1073",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While engaged in other types of work"
    },
    {
      "name": "X1074",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1078",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm While engaged in other specified activities"
    },
    {
      "name": "X1079",
      "description": "Contact with hot drinks, food, fats and cooking oils at farm During unspecified activity"
    },
    {
      "name": "X108",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places"
    },
    {
      "name": "X1080",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While engaged in sports activity"
    },
    {
      "name": "X1081",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1082",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While working for income"
    },
    {
      "name": "X1083",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While engaged in other types of work"
    },
    {
      "name": "X1084",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1088",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1089",
      "description": "Contact with hot drinks, food, fats and cooking oils at other specified places During unspecified activity"
    },
    {
      "name": "X109",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place"
    },
    {
      "name": "X1090",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1091",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1092",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While working for income"
    },
    {
      "name": "X1093",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1094",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1098",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1099",
      "description": "Contact with hot drinks, food, fats and cooking oils at unspecified place During unspecified activity"
    },
    {
      "name": "X110",
      "description": "Contact with hot tap-water at home"
    },
    {
      "name": "X1100",
      "description": "Contact with hot tap-water at home While engaged in sports activity"
    },
    {
      "name": "X1101",
      "description": "Contact with hot tap-water at home While engaged in leisure activity"
    },
    {
      "name": "X1102",
      "description": "Contact with hot tap-water at home While working for income"
    },
    {
      "name": "X1103",
      "description": "Contact with hot tap-water at home While engaged in other types of work"
    },
    {
      "name": "X1104",
      "description": "Contact with hot tap-water at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1108",
      "description": "Contact with hot tap-water at home While engaged in other specified activities"
    },
    {
      "name": "X1109",
      "description": "Contact with hot tap-water at home During unspecified activity"
    },
    {
      "name": "X111",
      "description": "Contact with hot tap-water at residential institution"
    },
    {
      "name": "X1110",
      "description": "Contact with hot tap-water at residential institution While engaged in sports activity"
    },
    {
      "name": "X1111",
      "description": "Contact with hot tap-water at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1112",
      "description": "Contact with hot tap-water at residential institution While working for income"
    },
    {
      "name": "X1113",
      "description": "Contact with hot tap-water at residential institution While engaged in other types of work"
    },
    {
      "name": "X1114",
      "description": "Contact with hot tap-water at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1118",
      "description": "Contact with hot tap-water at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1119",
      "description": "Contact with hot tap-water at residential institution During unspecified activity"
    },
    {
      "name": "X112",
      "description": "Contact with hot tap-water at school,other institution and public administrative area"
    },
    {
      "name": "X1120",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1121",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1122",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1123",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1124",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1128",
      "description": "Contact with hot tap-water at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1129",
      "description": "Contact with hot tap-water at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X113",
      "description": "Contact with hot tap-water at sports and athletics area"
    },
    {
      "name": "X1130",
      "description": "Contact with hot tap-water at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1131",
      "description": "Contact with hot tap-water at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1132",
      "description": "Contact with hot tap-water at sports and athletics area While working for income"
    },
    {
      "name": "X1133",
      "description": "Contact with hot tap-water at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1134",
      "description": "Contact with hot tap-water at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1138",
      "description": "Contact with hot tap-water at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1139",
      "description": "Contact with hot tap-water at sports and athletics area During unspecified activity"
    },
    {
      "name": "X114",
      "description": "Contact with hot tap-water at street and highway"
    },
    {
      "name": "X1140",
      "description": "Contact with hot tap-water at street and highway While engaged in sports activity"
    },
    {
      "name": "X1141",
      "description": "Contact with hot tap-water at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1142",
      "description": "Contact with hot tap-water at street and highway While working for income"
    },
    {
      "name": "X1143",
      "description": "Contact with hot tap-water at street and highway While engaged in other types of work"
    },
    {
      "name": "X1144",
      "description": "Contact with hot tap-water at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1148",
      "description": "Contact with hot tap-water at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1149",
      "description": "Contact with hot tap-water at street and highway During unspecified activity"
    },
    {
      "name": "X115",
      "description": "Contact with hot tap-water at trade and service area"
    },
    {
      "name": "X1150",
      "description": "Contact with hot tap-water at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1151",
      "description": "Contact with hot tap-water at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1152",
      "description": "Contact with hot tap-water at trade and service area While working for income"
    },
    {
      "name": "X1153",
      "description": "Contact with hot tap-water at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1154",
      "description": "Contact with hot tap-water at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1158",
      "description": "Contact with hot tap-water at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1159",
      "description": "Contact with hot tap-water at trade and service area During unspecified activity"
    },
    {
      "name": "X116",
      "description": "Contact with hot tap-water at industrial and construction area"
    },
    {
      "name": "X1160",
      "description": "Contact with hot tap-water at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1161",
      "description": "Contact with hot tap-water at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1162",
      "description": "Contact with hot tap-water at industrial and construction area While working for income"
    },
    {
      "name": "X1163",
      "description": "Contact with hot tap-water at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1164",
      "description": "Contact with hot tap-water at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1168",
      "description": "Contact with hot tap-water at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1169",
      "description": "Contact with hot tap-water at industrial and construction area During unspecified activity"
    },
    {
      "name": "X117",
      "description": "Contact with hot tap-water at farm"
    },
    {
      "name": "X1170",
      "description": "Contact with hot tap-water at farm While engaged in sports activity"
    },
    {
      "name": "X1171",
      "description": "Contact with hot tap-water at farm While engaged in leisure activity"
    },
    {
      "name": "X1172",
      "description": "Contact with hot tap-water at farm While working for income"
    },
    {
      "name": "X1173",
      "description": "Contact with hot tap-water at farm While engaged in other types of work"
    },
    {
      "name": "X1174",
      "description": "Contact with hot tap-water at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1178",
      "description": "Contact with hot tap-water at farm While engaged in other specified activities"
    },
    {
      "name": "X1179",
      "description": "Contact with hot tap-water at farm During unspecified activity"
    },
    {
      "name": "X118",
      "description": "Contact with hot tap-water at other specified places"
    },
    {
      "name": "X1180",
      "description": "Contact with hot tap-water at other specified places While engaged in sports activity"
    },
    {
      "name": "X1181",
      "description": "Contact with hot tap-water at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1182",
      "description": "Contact with hot tap-water at other specified places While working for income"
    },
    {
      "name": "X1183",
      "description": "Contact with hot tap-water at other specified places While engaged in other types of work"
    },
    {
      "name": "X1184",
      "description": "Contact with hot tap-water at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1188",
      "description": "Contact with hot tap-water at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1189",
      "description": "Contact with hot tap-water at other specified places During unspecified activity"
    },
    {
      "name": "X119",
      "description": "Contact with hot tap-water at unspecified place"
    },
    {
      "name": "X1190",
      "description": "Contact with hot tap-water at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1191",
      "description": "Contact with hot tap-water at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1192",
      "description": "Contact with hot tap-water at unspecified place While working for income"
    },
    {
      "name": "X1193",
      "description": "Contact with hot tap-water at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1194",
      "description": "Contact with hot tap-water at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1198",
      "description": "Contact with hot tap-water at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1199",
      "description": "Contact with hot tap-water at unspecified place During unspecified activity"
    },
    {
      "name": "X120",
      "description": "Contact with other hot fluids at home"
    },
    {
      "name": "X1200",
      "description": "Contact with other hot fluids at home While engaged in sports activity"
    },
    {
      "name": "X1201",
      "description": "Contact with other hot fluids at home While engaged in leisure activity"
    },
    {
      "name": "X1202",
      "description": "Contact with other hot fluids at home While working for income"
    },
    {
      "name": "X1203",
      "description": "Contact with other hot fluids at home While engaged in other types of work"
    },
    {
      "name": "X1204",
      "description": "Contact with other hot fluids at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1208",
      "description": "Contact with other hot fluids at home While engaged in other specified activities"
    },
    {
      "name": "X1209",
      "description": "Contact with other hot fluids at home During unspecified activity"
    },
    {
      "name": "X121",
      "description": "Contact with other hot fluids at residential institution"
    },
    {
      "name": "X1210",
      "description": "Contact with other hot fluids at residential institution While engaged in sports activity"
    },
    {
      "name": "X1211",
      "description": "Contact with other hot fluids at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1212",
      "description": "Contact with other hot fluids at residential institution While working for income"
    },
    {
      "name": "X1213",
      "description": "Contact with other hot fluids at residential institution While engaged in other types of work"
    },
    {
      "name": "X1214",
      "description": "Contact with other hot fluids at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1218",
      "description": "Contact with other hot fluids at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1219",
      "description": "Contact with other hot fluids at residential institution During unspecified activity"
    },
    {
      "name": "X122",
      "description": "Contact with other hot fluids at school,other institution and public administrative area"
    },
    {
      "name": "X1220",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1221",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1222",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1223",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1224",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1228",
      "description": "Contact with other hot fluids at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1229",
      "description": "Contact with other hot fluids at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X123",
      "description": "Contact with other hot fluids at sports and athletics area"
    },
    {
      "name": "X1230",
      "description": "Contact with other hot fluids at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1231",
      "description": "Contact with other hot fluids at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1232",
      "description": "Contact with other hot fluids at sports and athletics area While working for income"
    },
    {
      "name": "X1233",
      "description": "Contact with other hot fluids at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1234",
      "description": "Contact with other hot fluids at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1238",
      "description": "Contact with other hot fluids at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1239",
      "description": "Contact with other hot fluids at sports and athletics area During unspecified activity"
    },
    {
      "name": "X124",
      "description": "Contact with other hot fluids at street and highway"
    },
    {
      "name": "X1240",
      "description": "Contact with other hot fluids at street and highway While engaged in sports activity"
    },
    {
      "name": "X1241",
      "description": "Contact with other hot fluids at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1242",
      "description": "Contact with other hot fluids at street and highway While working for income"
    },
    {
      "name": "X1243",
      "description": "Contact with other hot fluids at street and highway While engaged in other types of work"
    },
    {
      "name": "X1244",
      "description": "Contact with other hot fluids at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1248",
      "description": "Contact with other hot fluids at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1249",
      "description": "Contact with other hot fluids at street and highway During unspecified activity"
    },
    {
      "name": "X125",
      "description": "Contact with other hot fluids at trade and service area"
    },
    {
      "name": "X1250",
      "description": "Contact with other hot fluids at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1251",
      "description": "Contact with other hot fluids at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1252",
      "description": "Contact with other hot fluids at trade and service area While working for income"
    },
    {
      "name": "X1253",
      "description": "Contact with other hot fluids at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1254",
      "description": "Contact with other hot fluids at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1258",
      "description": "Contact with other hot fluids at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1259",
      "description": "Contact with other hot fluids at trade and service area During unspecified activity"
    },
    {
      "name": "X126",
      "description": "Contact with other hot fluids at industrial and construction area"
    },
    {
      "name": "X1260",
      "description": "Contact with other hot fluids at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1261",
      "description": "Contact with other hot fluids at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1262",
      "description": "Contact with other hot fluids at industrial and construction area While working for income"
    },
    {
      "name": "X1263",
      "description": "Contact with other hot fluids at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1264",
      "description": "Contact with other hot fluids at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1268",
      "description": "Contact with other hot fluids at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1269",
      "description": "Contact with other hot fluids at industrial and construction area During unspecified activity"
    },
    {
      "name": "X127",
      "description": "Contact with other hot fluids at farm"
    },
    {
      "name": "X1270",
      "description": "Contact with other hot fluids at farm While engaged in sports activity"
    },
    {
      "name": "X1271",
      "description": "Contact with other hot fluids at farm While engaged in leisure activity"
    },
    {
      "name": "X1272",
      "description": "Contact with other hot fluids at farm While working for income"
    },
    {
      "name": "X1273",
      "description": "Contact with other hot fluids at farm While engaged in other types of work"
    },
    {
      "name": "X1274",
      "description": "Contact with other hot fluids at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1278",
      "description": "Contact with other hot fluids at farm While engaged in other specified activities"
    },
    {
      "name": "X1279",
      "description": "Contact with other hot fluids at farm During unspecified activity"
    },
    {
      "name": "X128",
      "description": "Contact with other hot fluids at other specified places"
    },
    {
      "name": "X1280",
      "description": "Contact with other hot fluids at other specified places While engaged in sports activity"
    },
    {
      "name": "X1281",
      "description": "Contact with other hot fluids at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1282",
      "description": "Contact with other hot fluids at other specified places While working for income"
    },
    {
      "name": "X1283",
      "description": "Contact with other hot fluids at other specified places While engaged in other types of work"
    },
    {
      "name": "X1284",
      "description": "Contact with other hot fluids at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1288",
      "description": "Contact with other hot fluids at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1289",
      "description": "Contact with other hot fluids at other specified places During unspecified activity"
    },
    {
      "name": "X129",
      "description": "Contact with other hot fluids at unspecified place"
    },
    {
      "name": "X1290",
      "description": "Contact with other hot fluids at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1291",
      "description": "Contact with other hot fluids at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1292",
      "description": "Contact with other hot fluids at unspecified place While working for income"
    },
    {
      "name": "X1293",
      "description": "Contact with other hot fluids at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1294",
      "description": "Contact with other hot fluids at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1298",
      "description": "Contact with other hot fluids at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1299",
      "description": "Contact with other hot fluids at unspecified place During unspecified activity"
    },
    {
      "name": "X130",
      "description": "Contact with steam and hot vapours at home"
    },
    {
      "name": "X1300",
      "description": "Contact with steam and hot vapours at home While engaged in sports activity"
    },
    {
      "name": "X1301",
      "description": "Contact with steam and hot vapours at home While engaged in leisure activity"
    },
    {
      "name": "X1302",
      "description": "Contact with steam and hot vapours at home While working for income"
    },
    {
      "name": "X1303",
      "description": "Contact with steam and hot vapours at home While engaged in other types of work"
    },
    {
      "name": "X1304",
      "description": "Contact with steam and hot vapours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1308",
      "description": "Contact with steam and hot vapours at home While engaged in other specified activities"
    },
    {
      "name": "X1309",
      "description": "Contact with steam and hot vapours at home During unspecified activity"
    },
    {
      "name": "X131",
      "description": "Contact with steam and hot vapours at residential institution"
    },
    {
      "name": "X1310",
      "description": "Contact with steam and hot vapours at residential institution While engaged in sports activity"
    },
    {
      "name": "X1311",
      "description": "Contact with steam and hot vapours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1312",
      "description": "Contact with steam and hot vapours at residential institution While working for income"
    },
    {
      "name": "X1313",
      "description": "Contact with steam and hot vapours at residential institution While engaged in other types of work"
    },
    {
      "name": "X1314",
      "description": "Contact with steam and hot vapours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1318",
      "description": "Contact with steam and hot vapours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1319",
      "description": "Contact with steam and hot vapours at residential institution During unspecified activity"
    },
    {
      "name": "X132",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area"
    },
    {
      "name": "X1320",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1321",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1322",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1323",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1324",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1328",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1329",
      "description": "Contact with steam and hot vapours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X133",
      "description": "Contact with steam and hot vapours at sports and athletics area"
    },
    {
      "name": "X1330",
      "description": "Contact with steam and hot vapours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1331",
      "description": "Contact with steam and hot vapours at sports and athletics area While engaged in leisure activity "
    },
    {
      "name": "X1332",
      "description": "Contact with steam and hot vapours at sports and athletics area While working for income"
    },
    {
      "name": "X1333",
      "description": "Contact with steam and hot vapours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1334",
      "description": "Contact with steam and hot vapours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1338",
      "description": "Contact with steam and hot vapours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1339",
      "description": "Contact with steam and hot vapours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X134",
      "description": "Contact with steam and hot vapours at street and highway"
    },
    {
      "name": "X1340",
      "description": "Contact with steam and hot vapours at street and highway While engaged in sports activity"
    },
    {
      "name": "X1341",
      "description": "Contact with steam and hot vapours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1342",
      "description": "Contact with steam and hot vapours at street and highway While working for income"
    },
    {
      "name": "X1343",
      "description": "Contact with steam and hot vapours at street and highway While engaged in other types of work"
    },
    {
      "name": "X1344",
      "description": "Contact with steam and hot vapours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1348",
      "description": "Contact with steam and hot vapours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1349",
      "description": "Contact with steam and hot vapours at street and highway During unspecified activity"
    },
    {
      "name": "X135",
      "description": "Contact with steam and hot vapours at trade and service area"
    },
    {
      "name": "X1350",
      "description": "Contact with steam and hot vapours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1351",
      "description": "Contact with steam and hot vapours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1352",
      "description": "Contact with steam and hot vapours at trade and service area While working for income"
    },
    {
      "name": "X1353",
      "description": "Contact with steam and hot vapours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1354",
      "description": "Contact with steam and hot vapours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1358",
      "description": "Contact with steam and hot vapours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1359",
      "description": "Contact with steam and hot vapours at trade and service area During unspecified activity"
    },
    {
      "name": "X136",
      "description": "Contact with steam and hot vapours at industrial and construction area"
    },
    {
      "name": "X1360",
      "description": "Contact with steam and hot vapours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1361",
      "description": "Contact with steam and hot vapours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1362",
      "description": "Contact with steam and hot vapours at industrial and construction area While working for income"
    },
    {
      "name": "X1363",
      "description": "Contact with steam and hot vapours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1364",
      "description": "Contact with steam and hot vapours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1368",
      "description": "Contact with steam and hot vapours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1369",
      "description": "Contact with steam and hot vapours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X137",
      "description": "Contact with steam and hot vapours at farm"
    },
    {
      "name": "X1370",
      "description": "Contact with steam and hot vapours at farm While engaged in sports activity"
    },
    {
      "name": "X1371",
      "description": "Contact with steam and hot vapours at farm While engaged in leisure activity"
    },
    {
      "name": "X1372",
      "description": "Contact with steam and hot vapours at farm While working for income"
    },
    {
      "name": "X1373",
      "description": "Contact with steam and hot vapours at farm While engaged in other types of work"
    },
    {
      "name": "X1374",
      "description": "Contact with steam and hot vapours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1378",
      "description": "Contact with steam and hot vapours at farm While engaged in other specified activities"
    },
    {
      "name": "X1379",
      "description": "Contact with steam and hot vapours at farm During unspecified activity"
    },
    {
      "name": "X138",
      "description": "Contact with steam and hot vapours at other specified places"
    },
    {
      "name": "X1380",
      "description": "Contact with steam and hot vapours at other specified places While engaged in sports activity"
    },
    {
      "name": "X1381",
      "description": "Contact with steam and hot vapours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1382",
      "description": "Contact with steam and hot vapours at other specified places While working for income"
    },
    {
      "name": "X1383",
      "description": "Contact with steam and hot vapours at other specified places While engaged in other types of work"
    },
    {
      "name": "X1384",
      "description": "Contact with steam and hot vapours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1388",
      "description": "Contact with steam and hot vapours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1389",
      "description": "Contact with steam and hot vapours at other specified places During unspecified activity"
    },
    {
      "name": "X139",
      "description": "Contact with steam and hot vapours at unspecified place"
    },
    {
      "name": "X1390",
      "description": "Contact with steam and hot vapours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1391",
      "description": "Contact with steam and hot vapours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1392",
      "description": "Contact with steam and hot vapours at unspecified place While working for income"
    },
    {
      "name": "X1393",
      "description": "Contact with steam and hot vapours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1394",
      "description": "Contact with steam and hot vapours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1398",
      "description": "Contact with steam and hot vapours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1399",
      "description": "Contact with steam and hot vapours at unspecified place During unspecified activity"
    },
    {
      "name": "X140",
      "description": "Contact with hot air and gases at home"
    },
    {
      "name": "X1400",
      "description": "Contact with hot air and gases at home While engaged in sports activity"
    },
    {
      "name": "X1401",
      "description": "Contact with hot air and gases at home While engaged in leisure activity"
    },
    {
      "name": "X1402",
      "description": "Contact with hot air and gases at home While working for income"
    },
    {
      "name": "X1403",
      "description": "Contact with hot air and gases at home While engaged in other types of work"
    },
    {
      "name": "X1404",
      "description": "Contact with hot air and gases at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1408",
      "description": "Contact with hot air and gases at home While engaged in other specified activities"
    },
    {
      "name": "X1409",
      "description": "Contact with hot air and gases at home During unspecified activity"
    },
    {
      "name": "X141",
      "description": "Contact with hot air and gases at residential institution"
    },
    {
      "name": "X1410",
      "description": "Contact with hot air and gases at residential institution While engaged in sports activity"
    },
    {
      "name": "X1411",
      "description": "Contact with hot air and gases at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1412",
      "description": "Contact with hot air and gases at residential institution While working for income"
    },
    {
      "name": "X1413",
      "description": "Contact with hot air and gases at residential institution While engaged in other types of work"
    },
    {
      "name": "X1414",
      "description": "Contact with hot air and gases at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1418",
      "description": "Contact with hot air and gases at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1419",
      "description": "Contact with hot air and gases at residential institution During unspecified activity"
    },
    {
      "name": "X142",
      "description": "Contact with hot air and gases at school,other institution and public administrative area"
    },
    {
      "name": "X1420",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1421",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1422",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1423",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1424",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1428",
      "description": "Contact with hot air and gases at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1429",
      "description": "Contact with hot air and gases at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X143",
      "description": "Contact with hot air and gases at sports and athletics area"
    },
    {
      "name": "X1430",
      "description": "Contact with hot air and gases at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1431",
      "description": "Contact with hot air and gases at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1432",
      "description": "Contact with hot air and gases at sports and athletics area While working for income"
    },
    {
      "name": "X1433",
      "description": "Contact with hot air and gases at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1434",
      "description": "Contact with hot air and gases at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1438",
      "description": "Contact with hot air and gases at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1439",
      "description": "Contact with hot air and gases at sports and athletics area During unspecified activity"
    },
    {
      "name": "X144",
      "description": "Contact with hot air and gases at street and highway"
    },
    {
      "name": "X1440",
      "description": "Contact with hot air and gases at street and highway While engaged in sports activity"
    },
    {
      "name": "X1441",
      "description": "Contact with hot air and gases at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1442",
      "description": "Contact with hot air and gases at street and highway While working for income"
    },
    {
      "name": "X1443",
      "description": "Contact with hot air and gases at street and highway While engaged in other types of work"
    },
    {
      "name": "X1444",
      "description": "Contact with hot air and gases at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1448",
      "description": "Contact with hot air and gases at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1449",
      "description": "Contact with hot air and gases at street and highway During unspecified activity"
    },
    {
      "name": "X145",
      "description": "Contact with hot air and gases at trade and service area"
    },
    {
      "name": "X1450",
      "description": "Contact with hot air and gases at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1451",
      "description": "Contact with hot air and gases at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1452",
      "description": "Contact with hot air and gases at trade and service area While working for income"
    },
    {
      "name": "X1453",
      "description": "Contact with hot air and gases at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1454",
      "description": "Contact with hot air and gases at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1458",
      "description": "Contact with hot air and gases at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1459",
      "description": "Contact with hot air and gases at trade and service area During unspecified activity"
    },
    {
      "name": "X146",
      "description": "Contact with hot air and gases at industrial and construction area"
    },
    {
      "name": "X1460",
      "description": "Contact with hot air and gases at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1461",
      "description": "Contact with hot air and gases at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1462",
      "description": "Contact with hot air and gases at industrial and construction area While working for income"
    },
    {
      "name": "X1463",
      "description": "Contact with hot air and gases at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1464",
      "description": "Contact with hot air and gases at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1468",
      "description": "Contact with hot air and gases at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1469",
      "description": "Contact with hot air and gases at industrial and construction area During unspecified activity"
    },
    {
      "name": "X147",
      "description": "Contact with hot air and gases at farm"
    },
    {
      "name": "X1470",
      "description": "Contact with hot air and gases at farm While engaged in sports activity"
    },
    {
      "name": "X1471",
      "description": "Contact with hot air and gases at farm While engaged in leisure activity"
    },
    {
      "name": "X1472",
      "description": "Contact with hot air and gases at farm While working for income"
    },
    {
      "name": "X1473",
      "description": "Contact with hot air and gases at farm While engaged in other types of work"
    },
    {
      "name": "X1474",
      "description": "Contact with hot air and gases at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1478",
      "description": "Contact with hot air and gases at farm While engaged in other specified activities"
    },
    {
      "name": "X1479",
      "description": "Contact with hot air and gases at farm During unspecified activity"
    },
    {
      "name": "X148",
      "description": "Contact with hot air and gases at other specified places"
    },
    {
      "name": "X1480",
      "description": "Contact with hot air and gases at other specified places While engaged in sports activity"
    },
    {
      "name": "X1481",
      "description": "Contact with hot air and gases at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1482",
      "description": "Contact with hot air and gases at other specified places While working for income"
    },
    {
      "name": "X1483",
      "description": "Contact with hot air and gases at other specified places While engaged in other types of work"
    },
    {
      "name": "X1484",
      "description": "Contact with hot air and gases at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1488",
      "description": "Contact with hot air and gases at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1489",
      "description": "Contact with hot air and gases at other specified places During unspecified activity"
    },
    {
      "name": "X149",
      "description": "Contact with hot air and gases at unspecified place"
    },
    {
      "name": "X1490",
      "description": "Contact with hot air and gases at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1491",
      "description": "Contact with hot air and gases at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1492",
      "description": "Contact with hot air and gases at unspecified place While working for income"
    },
    {
      "name": "X1493",
      "description": "Contact with hot air and gases at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1494",
      "description": "Contact with hot air and gases at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1498",
      "description": "Contact with hot air and gases at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1499",
      "description": "Contact with hot air and gases at unspecified place During unspecified activity"
    },
    {
      "name": "X150",
      "description": "Contact with hot household appliances at home"
    },
    {
      "name": "X1500",
      "description": "Contact with hot household appliances at home While engaged in sports activity"
    },
    {
      "name": "X1501",
      "description": "Contact with hot household appliances at home While engaged in leisure activity"
    },
    {
      "name": "X1502",
      "description": "Contact with hot household appliances at home While working for income"
    },
    {
      "name": "X1503",
      "description": "Contact with hot household appliances at home While engaged in other types of work"
    },
    {
      "name": "X1504",
      "description": "Contact with hot household appliances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1508",
      "description": "Contact with hot household appliances at home While engaged in other specified activities"
    },
    {
      "name": "X1509",
      "description": "Contact with hot household appliances at home During unspecified activity"
    },
    {
      "name": "X151",
      "description": "Contact with hot household appliances at residential institution"
    },
    {
      "name": "X1510",
      "description": "Contact with hot household appliances at residential institution While engaged in sports activity"
    },
    {
      "name": "X1511",
      "description": "Contact with hot household appliances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1512",
      "description": "Contact with hot household appliances at residential institution While working for income"
    },
    {
      "name": "X1513",
      "description": "Contact with hot household appliances at residential institution While engaged in other types of work"
    },
    {
      "name": "X1514",
      "description": "Contact with hot household appliances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1518",
      "description": "Contact with hot household appliances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1519",
      "description": "Contact with hot household appliances at residential institution During unspecified activity"
    },
    {
      "name": "X152",
      "description": "Contact with hot household appliances at school,other institution and public administrative area"
    },
    {
      "name": "X1520",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1521",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1522",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1523",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1524",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1528",
      "description": "Contact with hot household appliances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1529",
      "description": "Contact with hot household appliances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X153",
      "description": "Contact with hot household appliances at sports and athletics area"
    },
    {
      "name": "X1530",
      "description": "Contact with hot household appliances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1531",
      "description": "Contact with hot household appliances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1532",
      "description": "Contact with hot household appliances at sports and athletics area While working for income"
    },
    {
      "name": "X1533",
      "description": "Contact with hot household appliances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1534",
      "description": "Contact with hot household appliances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1538",
      "description": "Contact with hot household appliances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1539",
      "description": "Contact with hot household appliances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X154",
      "description": "Contact with hot household appliances at street and highway"
    },
    {
      "name": "X1540",
      "description": "Contact with hot household appliances at street and highway While engaged in sports activity"
    },
    {
      "name": "X1541",
      "description": "Contact with hot household appliances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1542",
      "description": "Contact with hot household appliances at street and highway While working for income"
    },
    {
      "name": "X1543",
      "description": "Contact with hot household appliances at street and highway While engaged in other types of work"
    },
    {
      "name": "X1544",
      "description": "Contact with hot household appliances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1548",
      "description": "Contact with hot household appliances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1549",
      "description": "Contact with hot household appliances at street and highway During unspecified activity"
    },
    {
      "name": "X155",
      "description": "Contact with hot household appliances at trade and service area"
    },
    {
      "name": "X1550",
      "description": "Contact with hot household appliances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1551",
      "description": "Contact with hot household appliances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1552",
      "description": "Contact with hot household appliances at trade and service area While working for income"
    },
    {
      "name": "X1553",
      "description": "Contact with hot household appliances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1554",
      "description": "Contact with hot household appliances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1558",
      "description": "Contact with hot household appliances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1559",
      "description": "Contact with hot household appliances at trade and service area During unspecified activity"
    },
    {
      "name": "X156",
      "description": "Contact with hot household appliances at industrial and construction area"
    },
    {
      "name": "X1560",
      "description": "Contact with hot household appliances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1561",
      "description": "Contact with hot household appliances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1562",
      "description": "Contact with hot household appliances at industrial and construction area While working for income"
    },
    {
      "name": "X1563",
      "description": "Contact with hot household appliances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1564",
      "description": "Contact with hot household appliances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1568",
      "description": "Contact with hot household appliances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1569",
      "description": "Contact with hot household appliances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X157",
      "description": "Contact with hot household appliances at farm"
    },
    {
      "name": "X1570",
      "description": "Contact with hot household appliances at farm While engaged in sports activity"
    },
    {
      "name": "X1571",
      "description": "Contact with hot household appliances at farm While engaged in leisure activity"
    },
    {
      "name": "X1572",
      "description": "Contact with hot household appliances at farm While working for income"
    },
    {
      "name": "X1573",
      "description": "Contact with hot household appliances at farm While engaged in other types of work"
    },
    {
      "name": "X1574",
      "description": "Contact with hot household appliances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1578",
      "description": "Contact with hot household appliances at farm While engaged in other specified activities"
    },
    {
      "name": "X1579",
      "description": "Contact with hot household appliances at farm During unspecified activity"
    },
    {
      "name": "X158",
      "description": "Contact with hot household appliances at other specified places"
    },
    {
      "name": "X1580",
      "description": "Contact with hot household appliances at other specified places While engaged in sports activity"
    },
    {
      "name": "X1581",
      "description": "Contact with hot household appliances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1582",
      "description": "Contact with hot household appliances at other specified places While working for income"
    },
    {
      "name": "X1583",
      "description": "Contact with hot household appliances at other specified places While engaged in other types of work"
    },
    {
      "name": "X1584",
      "description": "Contact with hot household appliances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1588",
      "description": "Contact with hot household appliances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1589",
      "description": "Contact with hot household appliances at other specified places During unspecified activity"
    },
    {
      "name": "X159",
      "description": "Contact with hot household appliances at unspecified place"
    },
    {
      "name": "X1590",
      "description": "Contact with hot household appliances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1591",
      "description": "Contact with hot household appliances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1592",
      "description": "Contact with hot household appliances at unspecified place While working for income"
    },
    {
      "name": "X1593",
      "description": "Contact with hot household appliances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1594",
      "description": "Contact with hot household appliances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1598",
      "description": "Contact with hot household appliances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1599",
      "description": "Contact with hot household appliances at unspecified place During unspecified activity"
    },
    {
      "name": "X160",
      "description": "Contact with hot heating appliances, radiators and pipes at home"
    },
    {
      "name": "X1600",
      "description": "Contact with hot heating appliances, radiators and pipes at home While engaged in sports activity"
    },
    {
      "name": "X1601",
      "description": "Contact with hot heating appliances, radiators and pipes at home While engaged in leisure activity"
    },
    {
      "name": "X1602",
      "description": "Contact with hot heating appliances, radiators and pipes at home While working for income"
    },
    {
      "name": "X1603",
      "description": "Contact with hot heating appliances, radiators and pipes at home While engaged in other types of work"
    },
    {
      "name": "X1604",
      "description": "Contact with hot heating appliances, radiators and pipes at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1608",
      "description": "Contact with hot heating appliances, radiators and pipes at home While engaged in other specified activities"
    },
    {
      "name": "X1609",
      "description": "Contact with hot heating appliances, radiators and pipes at home During unspecified activity"
    },
    {
      "name": "X161",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution"
    },
    {
      "name": "X1610",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While engaged in sports activity"
    },
    {
      "name": "X1611",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1612",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While working for income"
    },
    {
      "name": "X1613",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While engaged in other types of work"
    },
    {
      "name": "X1614",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1618",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1619",
      "description": "Contact with hot heating appliances, radiators and pipes at residential institution During unspecified activity"
    },
    {
      "name": "X162",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area"
    },
    {
      "name": "X1620",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1621",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1622",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1623",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1624",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1628",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1629",
      "description": "Contact with hot heating appliances, radiators and pipes at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X163",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area"
    },
    {
      "name": "X1630",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1631",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1632",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While working for income"
    },
    {
      "name": "X1633",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1634",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1638",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1639",
      "description": "Contact with hot heating appliances, radiators and pipes at sports and athletics area During unspecified activity"
    },
    {
      "name": "X164",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway"
    },
    {
      "name": "X1640",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While engaged in sports activity"
    },
    {
      "name": "X1641",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1642",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While working for income"
    },
    {
      "name": "X1643",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While engaged in other types of work"
    },
    {
      "name": "X1644",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1648",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1649",
      "description": "Contact with hot heating appliances, radiators and pipes at street and highway During unspecified activity"
    },
    {
      "name": "X165",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area"
    },
    {
      "name": "X1650",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1651",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1652",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While working for income"
    },
    {
      "name": "X1653",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1654",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1658",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1659",
      "description": "Contact with hot heating appliances, radiators and pipes at trade and service area During unspecified activity"
    },
    {
      "name": "X166",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area"
    },
    {
      "name": "X1660",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1661",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1662",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While working for income"
    },
    {
      "name": "X1663",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1664",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1668",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1669",
      "description": "Contact with hot heating appliances, radiators and pipes at industrial and construction area During unspecified activity"
    },
    {
      "name": "X167",
      "description": "Contact with hot heating appliances, radiators and pipes at farm"
    },
    {
      "name": "X1670",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While engaged in sports activity"
    },
    {
      "name": "X1671",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While engaged in leisure activity"
    },
    {
      "name": "X1672",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While working for income"
    },
    {
      "name": "X1673",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While engaged in other types of work"
    },
    {
      "name": "X1674",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1678",
      "description": "Contact with hot heating appliances, radiators and pipes at farm While engaged in other specified activities"
    },
    {
      "name": "X1679",
      "description": "Contact with hot heating appliances, radiators and pipes at farm During unspecified activity"
    },
    {
      "name": "X168",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places"
    },
    {
      "name": "X1680",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While engaged in sports activity"
    },
    {
      "name": "X1681",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1682",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While working for income"
    },
    {
      "name": "X1683",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While engaged in other types of work"
    },
    {
      "name": "X1684",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1688",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1689",
      "description": "Contact with hot heating appliances, radiators and pipes at other specified places During unspecified activity"
    },
    {
      "name": "X169",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place"
    },
    {
      "name": "X1690",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1691",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1692",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While working for income"
    },
    {
      "name": "X1693",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1694",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1698",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1699",
      "description": "Contact with hot heating appliances, radiators and pipes at unspecified place During unspecified activity"
    },
    {
      "name": "X170",
      "description": "Contact with hot engines, machinery and tools at home"
    },
    {
      "name": "X1700",
      "description": "Contact with hot engines, machinery and tools at home While engaged in sports activity"
    },
    {
      "name": "X1701",
      "description": "Contact with hot engines, machinery and tools at home While engaged in leisure activity"
    },
    {
      "name": "X1702",
      "description": "Contact with hot engines, machinery and tools at home While working for income"
    },
    {
      "name": "X1703",
      "description": "Contact with hot engines, machinery and tools at home While engaged in other types of work"
    },
    {
      "name": "X1704",
      "description": "Contact with hot engines, machinery and tools at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1708",
      "description": "Contact with hot engines, machinery and tools at home While engaged in other specified activities"
    },
    {
      "name": "X1709",
      "description": "Contact with hot engines, machinery and tools at home During unspecified activity"
    },
    {
      "name": "X171",
      "description": "Contact with hot engines, machinery and tools at residential institution"
    },
    {
      "name": "X1710",
      "description": "Contact with hot engines, machinery and tools at residential institution While engaged in sports activity"
    },
    {
      "name": "X1711",
      "description": "Contact with hot engines, machinery and tools at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1712",
      "description": "Contact with hot engines, machinery and tools at residential institution While working for income"
    },
    {
      "name": "X1713",
      "description": "Contact with hot engines, machinery and tools at residential institution  While engaged in other types of work"
    },
    {
      "name": "X1714",
      "description": "Contact with hot engines, machinery and tools at residential institution  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1718",
      "description": "Contact with hot engines, machinery and tools at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1719",
      "description": "Contact with hot engines, machinery and tools at residential institution  During unspecified activity"
    },
    {
      "name": "X172",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area"
    },
    {
      "name": "X1720",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1721",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1722",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1723",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1724",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1728",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1729",
      "description": "Contact with hot engines, machinery and tools at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X173",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area"
    },
    {
      "name": "X1730",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1731",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1732",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area While working for income"
    },
    {
      "name": "X1733",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1734",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1738",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area  While engaged in other specified activities"
    },
    {
      "name": "X1739",
      "description": "Contact with hot engines, machinery and tools at sports and athletics area During unspecified activity"
    },
    {
      "name": "X174",
      "description": "Contact with hot engines, machinery and tools at street and highway"
    },
    {
      "name": "X1740",
      "description": "Contact with hot engines, machinery and tools at street and highway While engaged in sports activity"
    },
    {
      "name": "X1741",
      "description": "Contact with hot engines, machinery and tools at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1742",
      "description": "Contact with hot engines, machinery and tools at street and highway While working for income"
    },
    {
      "name": "X1743",
      "description": "Contact with hot engines, machinery and tools at street and highway While engaged in other types of work"
    },
    {
      "name": "X1744",
      "description": "Contact with hot engines, machinery and tools at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1748",
      "description": "Contact with hot engines, machinery and tools at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1749",
      "description": "Contact with hot engines, machinery and tools at street and highway During unspecified activity"
    },
    {
      "name": "X175",
      "description": "Contact with hot engines, machinery and tools at trade and service area"
    },
    {
      "name": "X1750",
      "description": "Contact with hot engines, machinery and tools at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1751",
      "description": "Contact with hot engines, machinery and tools at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1752",
      "description": "Contact with hot engines, machinery and tools at trade and service area While working for income"
    },
    {
      "name": "X1753",
      "description": "Contact with hot engines, machinery and tools at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1754",
      "description": "Contact with hot engines, machinery and tools at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1758",
      "description": "Contact with hot engines, machinery and tools at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1759",
      "description": "Contact with hot engines, machinery and tools at trade and service area During unspecified activity"
    },
    {
      "name": "X176",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area"
    },
    {
      "name": "X1760",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1761",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1762",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While working for income"
    },
    {
      "name": "X1763",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1764",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1768",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1769",
      "description": "Contact with hot engines, machinery and tools at industrial and construction area During unspecified activity"
    },
    {
      "name": "X177",
      "description": "Contact with hot engines, machinery and tools at farm"
    },
    {
      "name": "X1770",
      "description": "Contact with hot engines, machinery and tools at farm While engaged in sports activity"
    },
    {
      "name": "X1771",
      "description": "Contact with hot engines, machinery and tools at farm While engaged in leisure activity"
    },
    {
      "name": "X1772",
      "description": "Contact with hot engines, machinery and tools at farm While working for income"
    },
    {
      "name": "X1773",
      "description": "Contact with hot engines, machinery and tools at farm While engaged in other types of work"
    },
    {
      "name": "X1774",
      "description": "Contact with hot engines, machinery and tools at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1778",
      "description": "Contact with hot engines, machinery and tools at farm While engaged in other specified activities"
    },
    {
      "name": "X1779",
      "description": "Contact with hot engines, machinery and tools at farm During unspecified activity"
    },
    {
      "name": "X178",
      "description": "Contact with hot engines, machinery and tools at other specified places"
    },
    {
      "name": "X1780",
      "description": "Contact with hot engines, machinery and tools at other specified places While engaged in sports activity"
    },
    {
      "name": "X1781",
      "description": "Contact with hot engines, machinery and tools at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1782",
      "description": "Contact with hot engines, machinery and tools at other specified places While working for income"
    },
    {
      "name": "X1783",
      "description": "Contact with hot engines, machinery and tools at other specified places While engaged in other types of work"
    },
    {
      "name": "X1784",
      "description": "Contact with hot engines, machinery and tools at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1788",
      "description": "Contact with hot engines, machinery and tools at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1789",
      "description": "Contact with hot engines, machinery and tools at other specified places During unspecified activity"
    },
    {
      "name": "X179",
      "description": "Contact with hot engines, machinery and tools at unspecified place"
    },
    {
      "name": "X1790",
      "description": "Contact with hot engines, machinery and tools at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1791",
      "description": "Contact with hot engines, machinery and tools at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1792",
      "description": "Contact with hot engines, machinery and tools at unspecified place While working for income"
    },
    {
      "name": "X1793",
      "description": "Contact with hot engines, machinery and tools at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1794",
      "description": "Contact with hot engines, machinery and tools at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1798",
      "description": "Contact with hot engines, machinery and tools at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1799",
      "description": "Contact with hot engines, machinery and tools at unspecified place During unspecified activity"
    },
    {
      "name": "X180",
      "description": "Contact with other hot metals at home"
    },
    {
      "name": "X1800",
      "description": "Contact with other hot metals at home While engaged in sports activity"
    },
    {
      "name": "X1801",
      "description": "Contact with other hot metals at home While engaged in leisure activity"
    },
    {
      "name": "X1802",
      "description": "Contact with other hot metals at home While working for income"
    },
    {
      "name": "X1803",
      "description": "Contact with other hot metals at home While engaged in other types of work"
    },
    {
      "name": "X1804",
      "description": "Contact with other hot metals at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1808",
      "description": "Contact with other hot metals at home While engaged in other specified activities"
    },
    {
      "name": "X1809",
      "description": "Contact with other hot metals at home During unspecified activity"
    },
    {
      "name": "X181",
      "description": "Contact with other hot metals at residential institution"
    },
    {
      "name": "X1810",
      "description": "Contact with other hot metals at residential institution While engaged in sports activity"
    },
    {
      "name": "X1811",
      "description": "Contact with other hot metals at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1812",
      "description": "Contact with other hot metals at residential institution While working for income"
    },
    {
      "name": "X1813",
      "description": "Contact with other hot metals at residential institution While engaged in other types of work"
    },
    {
      "name": "X1814",
      "description": "Contact with other hot metals at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1818",
      "description": "Contact with other hot metals at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1819",
      "description": "Contact with other hot metals at residential institution During unspecified activity"
    },
    {
      "name": "X182",
      "description": "Contact with other hot metals at school,other institution and public administrative area"
    },
    {
      "name": "X1820",
      "description": "Contact with other hot metals at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1821",
      "description": "Contact with other hot metals at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1822",
      "description": "Contact with other hot metals at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1823",
      "description": "Contact with other hot metals at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1824",
      "description": "Contact with other hot metals at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1828",
      "description": "Contact with other hot metals at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1829",
      "description": "Contact with other hot metals at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X183",
      "description": "Contact with other hot metals at sports and athletics area"
    },
    {
      "name": "X1830",
      "description": "Contact with other hot metals at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1831",
      "description": "Contact with other hot metals at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1832",
      "description": "Contact with other hot metals at sports and athletics area While working for income"
    },
    {
      "name": "X1833",
      "description": "Contact with other hot metals at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1834",
      "description": "Contact with other hot metals at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1838",
      "description": "Contact with other hot metals at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1839",
      "description": "Contact with other hot metals at sports and athletics area During unspecified activity"
    },
    {
      "name": "X184",
      "description": "Contact with other hot metals at street and highway"
    },
    {
      "name": "X1840",
      "description": "Contact with other hot metals at street and highway While engaged in sports activity"
    },
    {
      "name": "X1841",
      "description": "Contact with other hot metals at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1842",
      "description": "Contact with other hot metals at street and highway While working for income"
    },
    {
      "name": "X1843",
      "description": "Contact with other hot metals at street and highway While engaged in other types of work"
    },
    {
      "name": "X1844",
      "description": "Contact with other hot metals at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1848",
      "description": "Contact with other hot metals at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1849",
      "description": "Contact with other hot metals at street and highway During unspecified activity"
    },
    {
      "name": "X185",
      "description": "Contact with other hot metals at trade and service area"
    },
    {
      "name": "X1850",
      "description": "Contact with other hot metals at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1851",
      "description": "Contact with other hot metals at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1852",
      "description": "Contact with other hot metals at trade and service area While working for income"
    },
    {
      "name": "X1853",
      "description": "Contact with other hot metals at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1854",
      "description": "Contact with other hot metals at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1858",
      "description": "Contact with other hot metals at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1859",
      "description": "Contact with other hot metals at trade and service area During unspecified activity"
    },
    {
      "name": "X186",
      "description": "Contact with other hot metals at industrial and construction area"
    },
    {
      "name": "X1860",
      "description": "Contact with other hot metals at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1861",
      "description": "Contact with other hot metals at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1862",
      "description": "Contact with other hot metals at industrial and construction area While working for income"
    },
    {
      "name": "X1863",
      "description": "Contact with other hot metals at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1864",
      "description": "Contact with other hot metals at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1868",
      "description": "Contact with other hot metals at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1869",
      "description": "Contact with other hot metals at industrial and construction area During unspecified activity"
    },
    {
      "name": "X187",
      "description": "Contact with other hot metals at farm"
    },
    {
      "name": "X1870",
      "description": "Contact with other hot metals at farm While engaged in sports activity"
    },
    {
      "name": "X1871",
      "description": "Contact with other hot metals at farm While engaged in leisure activity"
    },
    {
      "name": "X1872",
      "description": "Contact with other hot metals at farm While working for income"
    },
    {
      "name": "X1873",
      "description": "Contact with other hot metals at farm While engaged in other types of work"
    },
    {
      "name": "X1874",
      "description": "Contact with other hot metals at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1878",
      "description": "Contact with other hot metals at farm While engaged in other specified activities"
    },
    {
      "name": "X1879",
      "description": "Contact with other hot metals at farm During unspecified activity"
    },
    {
      "name": "X188",
      "description": "Contact with other hot metals at other specified places"
    },
    {
      "name": "X1880",
      "description": "Contact with other hot metals at other specified places While engaged in sports activity"
    },
    {
      "name": "X1881",
      "description": "Contact with other hot metals at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1882",
      "description": "Contact with other hot metals at other specified places While working for income"
    },
    {
      "name": "X1883",
      "description": "Contact with other hot metals at other specified places While engaged in other types of work"
    },
    {
      "name": "X1884",
      "description": "Contact with other hot metals at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1888",
      "description": "Contact with other hot metals at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1889",
      "description": "Contact with other hot metals at other specified places During unspecified activity"
    },
    {
      "name": "X189",
      "description": "Contact with other hot metals at unspecified place"
    },
    {
      "name": "X1890",
      "description": "Contact with other hot metals at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1891",
      "description": "Contact with other hot metals at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1892",
      "description": "Contact with other hot metals at unspecified place While working for income"
    },
    {
      "name": "X1893",
      "description": "Contact with other hot metals at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1894",
      "description": "Contact with other hot metals at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1898",
      "description": "Contact with other hot metals at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1899",
      "description": "Contact with other hot metals at unspecified place During unspecified activity"
    },
    {
      "name": "X190",
      "description": "Contact with other and unspecified heat and hot substances at home"
    },
    {
      "name": "X1900",
      "description": "Contact with other and unspecified heat and hot substances at home While engaged in sports activity"
    },
    {
      "name": "X1901",
      "description": "Contact with other and unspecified heat and hot substances at home While engaged in leisure activity"
    },
    {
      "name": "X1902",
      "description": "Contact with other and unspecified heat and hot substances at home While working for income"
    },
    {
      "name": "X1903",
      "description": "Contact with other and unspecified heat and hot substances at home While engaged in other types of work"
    },
    {
      "name": "X1904",
      "description": "Contact with other and unspecified heat and hot substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1908",
      "description": "Contact with other and unspecified heat and hot substances at home While engaged in other specified activities"
    },
    {
      "name": "X1909",
      "description": "Contact with other and unspecified heat and hot substances at home During unspecified activity"
    },
    {
      "name": "X191",
      "description": "Contact with other and unspecified heat and hot substances at residential institution"
    },
    {
      "name": "X1910",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X1911",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X1912",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While working for income"
    },
    {
      "name": "X1913",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X1914",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1918",
      "description": "Contact with other and unspecified heat and hot substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X1919",
      "description": "Contact with other and unspecified heat and hot substances at residential institution During unspecified activity"
    },
    {
      "name": "X192",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area"
    },
    {
      "name": "X1920",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X1921",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X1922",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X1923",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X1924",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1928",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X1929",
      "description": "Contact with other and unspecified heat and hot substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X193",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area"
    },
    {
      "name": "X1930",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X1931",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X1932",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While working for income"
    },
    {
      "name": "X1933",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X1934",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1938",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X1939",
      "description": "Contact with other and unspecified heat and hot substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X194",
      "description": "Contact with other and unspecified heat and hot substances at street and highway"
    },
    {
      "name": "X1940",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X1941",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X1942",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While working for income"
    },
    {
      "name": "X1943",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X1944",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1948",
      "description": "Contact with other and unspecified heat and hot substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X1949",
      "description": "Contact with other and unspecified heat and hot substances at street and highway During unspecified activity"
    },
    {
      "name": "X195",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area"
    },
    {
      "name": "X1950",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X1951",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X1952",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While working for income"
    },
    {
      "name": "X1953",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X1954",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1958",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X1959",
      "description": "Contact with other and unspecified heat and hot substances at trade and service area During unspecified activity"
    },
    {
      "name": "X196",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area"
    },
    {
      "name": "X1960",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X1961",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X1962",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While working for income"
    },
    {
      "name": "X1963",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X1964",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1968",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X1969",
      "description": "Contact with other and unspecified heat and hot substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X197",
      "description": "Contact with other and unspecified heat and hot substances at farm"
    },
    {
      "name": "X1970",
      "description": "Contact with other and unspecified heat and hot substances at farm While engaged in sports activity"
    },
    {
      "name": "X1971",
      "description": "Contact with other and unspecified heat and hot substances at farm While engaged in leisure activity"
    },
    {
      "name": "X1972",
      "description": "Contact with other and unspecified heat and hot substances at farm While working for income"
    },
    {
      "name": "X1973",
      "description": "Contact with other and unspecified heat and hot substances at farm While engaged in other types of work"
    },
    {
      "name": "X1974",
      "description": "Contact with other and unspecified heat and hot substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1978",
      "description": "Contact with other and unspecified heat and hot substances at farm While engaged in other specified activities"
    },
    {
      "name": "X1979",
      "description": "Contact with other and unspecified heat and hot substances at farm During unspecified activity"
    },
    {
      "name": "X198",
      "description": "Contact with other and unspecified heat and hot substances at other specified places"
    },
    {
      "name": "X1980",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X1981",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X1982",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While working for income"
    },
    {
      "name": "X1983",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X1984",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1988",
      "description": "Contact with other and unspecified heat and hot substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X1989",
      "description": "Contact with other and unspecified heat and hot substances at other specified places During unspecified activity"
    },
    {
      "name": "X199",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place"
    },
    {
      "name": "X1990",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X1991",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X1992",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While working for income"
    },
    {
      "name": "X1993",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X1994",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X1998",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X1999",
      "description": "Contact with other and unspecified heat and hot substances at unspecified place During unspecified activity"
    },
    {
      "name": "X200",
      "description": "Contact with venomous snakes and lizards at home"
    },
    {
      "name": "X2000",
      "description": "Contact with venomous snakes and lizards at home While engaged in sports activity"
    },
    {
      "name": "X2001",
      "description": "Contact with venomous snakes and lizards at home While engaged in leisure activity"
    },
    {
      "name": "X2002",
      "description": "Contact with venomous snakes and lizards at home While working for income"
    },
    {
      "name": "X2003",
      "description": "Contact with venomous snakes and lizards at home While engaged in other types of work"
    },
    {
      "name": "X2004",
      "description": "Contact with venomous snakes and lizards at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2008",
      "description": "Contact with venomous snakes and lizards at home While engaged in other specified activities"
    },
    {
      "name": "X2009",
      "description": "Contact with venomous snakes and lizards at home During unspecified activity"
    },
    {
      "name": "X201",
      "description": "Contact with venomous snakes and lizards at residential institution"
    },
    {
      "name": "X2010",
      "description": "Contact with venomous snakes and lizards at residential institution While engaged in sports activity"
    },
    {
      "name": "X2011",
      "description": "Contact with venomous snakes and lizards at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2012",
      "description": "Contact with venomous snakes and lizards at residential institution While working for income"
    },
    {
      "name": "X2013",
      "description": "Contact with venomous snakes and lizards at residential institution While engaged in other types of work"
    },
    {
      "name": "X2014",
      "description": "Contact with venomous snakes and lizards at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2018",
      "description": "Contact with venomous snakes and lizards at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2019",
      "description": "Contact with venomous snakes and lizards at residential institution During unspecified activity"
    },
    {
      "name": "X202",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area"
    },
    {
      "name": "X2020",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2021",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2022",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2023",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2024",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2028",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2029",
      "description": "Contact with venomous snakes and lizards at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X203",
      "description": "Contact with venomous snakes and lizards at sports and athletics area"
    },
    {
      "name": "X2030",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2031",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2032",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While working for income"
    },
    {
      "name": "X2033",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2034",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2038",
      "description": "Contact with venomous snakes and lizards at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2039",
      "description": "Contact with venomous snakes and lizards at sports and athletics area During unspecified activity"
    },
    {
      "name": "X204",
      "description": "Contact with venomous snakes and lizards at street and highway"
    },
    {
      "name": "X2040",
      "description": "Contact with venomous snakes and lizards at street and highway While engaged in sports activity"
    },
    {
      "name": "X2041",
      "description": "Contact with venomous snakes and lizards at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2042",
      "description": "Contact with venomous snakes and lizards at street and highway While working for income"
    },
    {
      "name": "X2043",
      "description": "Contact with venomous snakes and lizards at street and highway While engaged in other types of work"
    },
    {
      "name": "X2044",
      "description": "Contact with venomous snakes and lizards at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2048",
      "description": "Contact with venomous snakes and lizards at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2049",
      "description": "Contact with venomous snakes and lizards at street and highway During unspecified activity"
    },
    {
      "name": "X205",
      "description": "Contact with venomous snakes and lizards at trade and service area"
    },
    {
      "name": "X2050",
      "description": "Contact with venomous snakes and lizards at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2051",
      "description": "Contact with venomous snakes and lizards at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2052",
      "description": "Contact with venomous snakes and lizards at trade and service area While working for income"
    },
    {
      "name": "X2053",
      "description": "Contact with venomous snakes and lizards at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2054",
      "description": "Contact with venomous snakes and lizards at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2058",
      "description": "Contact with venomous snakes and lizards at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2059",
      "description": "Contact with venomous snakes and lizards at trade and service area During unspecified activity"
    },
    {
      "name": "X206",
      "description": "Contact with venomous snakes and lizards at industrial and construction area"
    },
    {
      "name": "X2060",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2061",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2062",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While working for income"
    },
    {
      "name": "X2063",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2064",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2068",
      "description": "Contact with venomous snakes and lizards at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2069",
      "description": "Contact with venomous snakes and lizards at industrial and construction area During unspecified activity"
    },
    {
      "name": "X207",
      "description": "Contact with venomous snakes and lizards at farm"
    },
    {
      "name": "X2070",
      "description": "Contact with venomous snakes and lizards at farm While engaged in sports activity"
    },
    {
      "name": "X2071",
      "description": "Contact with venomous snakes and lizards at farm While engaged in leisure activity"
    },
    {
      "name": "X2072",
      "description": "Contact with venomous snakes and lizards at farm While working for income"
    },
    {
      "name": "X2073",
      "description": "Contact with venomous snakes and lizards at farm While engaged in other types of work"
    },
    {
      "name": "X2074",
      "description": "Contact with venomous snakes and lizards at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2078",
      "description": "Contact with venomous snakes and lizards at farm While engaged in other specified activities"
    },
    {
      "name": "X2079",
      "description": "Contact with venomous snakes and lizards at farm During unspecified activity"
    },
    {
      "name": "X208",
      "description": "Contact with venomous snakes and lizards at other specified places"
    },
    {
      "name": "X2080",
      "description": "Contact with venomous snakes and lizards at other specified places While engaged in sports activity"
    },
    {
      "name": "X2081",
      "description": "Contact with venomous snakes and lizards at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2082",
      "description": "Contact with venomous snakes and lizards at other specified places While working for income"
    },
    {
      "name": "X2083",
      "description": "Contact with venomous snakes and lizards at other specified places While engaged in other types of work"
    },
    {
      "name": "X2084",
      "description": "Contact with venomous snakes and lizards at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2088",
      "description": "Contact with venomous snakes and lizards at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2089",
      "description": "Contact with venomous snakes and lizards at other specified places During unspecified activity"
    },
    {
      "name": "X209",
      "description": "Contact with venomous snakes and lizards at unspecified place"
    },
    {
      "name": "X2090",
      "description": "Contact with venomous snakes and lizards at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2091",
      "description": "Contact with venomous snakes and lizards at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2092",
      "description": "Contact with venomous snakes and lizards at unspecified place While working for income"
    },
    {
      "name": "X2093",
      "description": "Contact with venomous snakes and lizards at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2094",
      "description": "Contact with venomous snakes and lizards at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2098",
      "description": "Contact with venomous snakes and lizards at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2099",
      "description": "Contact with venomous snakes and lizards at unspecified place During unspecified activity"
    },
    {
      "name": "X210",
      "description": "Contact with venomous spiders at home"
    },
    {
      "name": "X2100",
      "description": "Contact with venomous spiders at home While engaged in sports activity"
    },
    {
      "name": "X2101",
      "description": "Contact with venomous spiders at home While engaged in leisure activity"
    },
    {
      "name": "X2102",
      "description": "Contact with venomous spiders at home While working for income"
    },
    {
      "name": "X2103",
      "description": "Contact with venomous spiders at home While engaged in other types of work"
    },
    {
      "name": "X2104",
      "description": "Contact with venomous spiders at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2108",
      "description": "Contact with venomous spiders at home While engaged in other specified activities"
    },
    {
      "name": "X2109",
      "description": "Contact with venomous spiders at home During unspecified activity"
    },
    {
      "name": "X211",
      "description": "Contact with venomous spiders at residential institution"
    },
    {
      "name": "X2110",
      "description": "Contact with venomous spiders at residential institution While engaged in sports activity"
    },
    {
      "name": "X2111",
      "description": "Contact with venomous spiders at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2112",
      "description": "Contact with venomous spiders at residential institution While working for income"
    },
    {
      "name": "X2113",
      "description": "Contact with venomous spiders at residential institution While engaged in other types of work"
    },
    {
      "name": "X2114",
      "description": "Contact with venomous spiders at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2118",
      "description": "Contact with venomous spiders at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2119",
      "description": "Contact with venomous spiders at residential institution During unspecified activity"
    },
    {
      "name": "X212",
      "description": "Contact with venomous spiders at school,other institution and public administrative area"
    },
    {
      "name": "X2120",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2121",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2122",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2123",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2124",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2128",
      "description": "Contact with venomous spiders at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2129",
      "description": "Contact with venomous spiders at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X213",
      "description": "Contact with venomous spiders at sports and athletics area"
    },
    {
      "name": "X2130",
      "description": "Contact with venomous spiders at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2131",
      "description": "Contact with venomous spiders at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2132",
      "description": "Contact with venomous spiders at sports and athletics area While working for income"
    },
    {
      "name": "X2133",
      "description": "Contact with venomous spiders at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2134",
      "description": "Contact with venomous spiders at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2138",
      "description": "Contact with venomous spiders at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2139",
      "description": "Contact with venomous spiders at sports and athletics area During unspecified activity"
    },
    {
      "name": "X214",
      "description": "Contact with venomous spiders at street and highway"
    },
    {
      "name": "X2140",
      "description": "Contact with venomous spiders at street and highway While engaged in sports activity"
    },
    {
      "name": "X2141",
      "description": "Contact with venomous spiders at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2142",
      "description": "Contact with venomous spiders at street and highway While working for income"
    },
    {
      "name": "X2143",
      "description": "Contact with venomous spiders at street and highway While engaged in other types of work"
    },
    {
      "name": "X2144",
      "description": "Contact with venomous spiders at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2148",
      "description": "Contact with venomous spiders at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2149",
      "description": "Contact with venomous spiders at street and highway During unspecified activity"
    },
    {
      "name": "X215",
      "description": "Contact with venomous spiders at trade and service area"
    },
    {
      "name": "X2150",
      "description": "Contact with venomous spiders at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2151",
      "description": "Contact with venomous spiders at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2152",
      "description": "Contact with venomous spiders at trade and service area While working for income"
    },
    {
      "name": "X2153",
      "description": "Contact with venomous spiders at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2154",
      "description": "Contact with venomous spiders at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2158",
      "description": "Contact with venomous spiders at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2159",
      "description": "Contact with venomous spiders at trade and service area During unspecified activity"
    },
    {
      "name": "X216",
      "description": "Contact with venomous spiders at industrial and construction area"
    },
    {
      "name": "X2160",
      "description": "Contact with venomous spiders at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2161",
      "description": "Contact with venomous spiders at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2162",
      "description": "Contact with venomous spiders at industrial and construction area While working for income"
    },
    {
      "name": "X2163",
      "description": "Contact with venomous spiders at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2164",
      "description": "Contact with venomous spiders at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2168",
      "description": "Contact with venomous spiders at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2169",
      "description": "Contact with venomous spiders at industrial and construction area During unspecified activity"
    },
    {
      "name": "X217",
      "description": "Contact with venomous spiders at farm"
    },
    {
      "name": "X2170",
      "description": "Contact with venomous spiders at farm While engaged in sports activity"
    },
    {
      "name": "X2171",
      "description": "Contact with venomous spiders at farm While engaged in leisure activity"
    },
    {
      "name": "X2172",
      "description": "Contact with venomous spiders at farm While working for income"
    },
    {
      "name": "X2173",
      "description": "Contact with venomous spiders at farm While engaged in other types of work"
    },
    {
      "name": "X2174",
      "description": "Contact with venomous spiders at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2178",
      "description": "Contact with venomous spiders at farm While engaged in other specified activities"
    },
    {
      "name": "X2179",
      "description": "Contact with venomous spiders at farm During unspecified activity"
    },
    {
      "name": "X218",
      "description": "Contact with venomous spiders at other specified places"
    },
    {
      "name": "X2180",
      "description": "Contact with venomous spiders at other specified places While engaged in sports activity"
    },
    {
      "name": "X2181",
      "description": "Contact with venomous spiders at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2182",
      "description": "Contact with venomous spiders at other specified places While working for income"
    },
    {
      "name": "X2183",
      "description": "Contact with venomous spiders at other specified places While engaged in other types of work"
    },
    {
      "name": "X2184",
      "description": "Contact with venomous spiders at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2188",
      "description": "Contact with venomous spiders at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2189",
      "description": "Contact with venomous spiders at other specified places During unspecified activity"
    },
    {
      "name": "X219",
      "description": "Contact with venomous spiders at unspecified place"
    },
    {
      "name": "X2190",
      "description": "Contact with venomous spiders at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2191",
      "description": "Contact with venomous spiders at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2192",
      "description": "Contact with venomous spiders at unspecified place While working for income"
    },
    {
      "name": "X2193",
      "description": "Contact with venomous spiders at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2194",
      "description": "Contact with venomous spiders at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2198",
      "description": "Contact with venomous spiders at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2199",
      "description": "Contact with venomous spiders at unspecified place During unspecified activity"
    },
    {
      "name": "X220",
      "description": "Contact with scorpions at home"
    },
    {
      "name": "X2200",
      "description": "Contact with scorpions at home While engaged in sports activity"
    },
    {
      "name": "X2201",
      "description": "Contact with scorpions at home While engaged in leisure activity"
    },
    {
      "name": "X2202",
      "description": "Contact with scorpions at home While working for income"
    },
    {
      "name": "X2203",
      "description": "Contact with scorpions at home While engaged in other types of work"
    },
    {
      "name": "X2204",
      "description": "Contact with scorpions at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2208",
      "description": "Contact with scorpions at home While engaged in other specified activities"
    },
    {
      "name": "X2209",
      "description": "Contact with scorpions at home During unspecified activity"
    },
    {
      "name": "X221",
      "description": "Contact with scorpions at residential institution"
    },
    {
      "name": "X2210",
      "description": "Contact with scorpions at residential institution While engaged in sports activity"
    },
    {
      "name": "X2211",
      "description": "Contact with scorpions at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2212",
      "description": "Contact with scorpions at residential institution While working for income"
    },
    {
      "name": "X2213",
      "description": "Contact with scorpions at residential institution While engaged in other types of work"
    },
    {
      "name": "X2214",
      "description": "Contact with scorpions at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2218",
      "description": "Contact with scorpions at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2219",
      "description": "Contact with scorpions at residential institution During unspecified activity"
    },
    {
      "name": "X222",
      "description": "Contact with scorpions at school,other institution and public administrative area"
    },
    {
      "name": "X2220",
      "description": "Contact with scorpions at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2221",
      "description": "Contact with scorpions at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2222",
      "description": "Contact with scorpions at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2223",
      "description": "Contact with scorpions at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2224",
      "description": "Contact with scorpions at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2228",
      "description": "Contact with scorpions at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2229",
      "description": "Contact with scorpions at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X223",
      "description": "Contact with scorpions at sports and athletics area"
    },
    {
      "name": "X2230",
      "description": "Contact with scorpions at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2231",
      "description": "Contact with scorpions at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2232",
      "description": "Contact with scorpions at sports and athletics area While working for income"
    },
    {
      "name": "X2233",
      "description": "Contact with scorpions at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2234",
      "description": "Contact with scorpions at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2238",
      "description": "Contact with scorpions at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2239",
      "description": "Contact with scorpions at sports and athletics area During unspecified activity"
    },
    {
      "name": "X224",
      "description": "Contact with scorpions at street and highway"
    },
    {
      "name": "X2240",
      "description": "Contact with scorpions at street and highway While engaged in sports activity"
    },
    {
      "name": "X2241",
      "description": "Contact with scorpions at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2242",
      "description": "Contact with scorpions at street and highway While working for income"
    },
    {
      "name": "X2243",
      "description": "Contact with scorpions at street and highway While engaged in other types of work"
    },
    {
      "name": "X2244",
      "description": "Contact with scorpions at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2248",
      "description": "Contact with scorpions at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2249",
      "description": "Contact with scorpions at street and highway During unspecified activity"
    },
    {
      "name": "X225",
      "description": "Contact with scorpions at trade and service area"
    },
    {
      "name": "X2250",
      "description": "Contact with scorpions at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2251",
      "description": "Contact with scorpions at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2252",
      "description": "Contact with scorpions at trade and service area While working for income"
    },
    {
      "name": "X2253",
      "description": "Contact with scorpions at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2254",
      "description": "Contact with scorpions at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2258",
      "description": "Contact with scorpions at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2259",
      "description": "Contact with scorpions at trade and service area During unspecified activity"
    },
    {
      "name": "X226",
      "description": "Contact with scorpions at industrial and construction area"
    },
    {
      "name": "X2260",
      "description": "Contact with scorpions at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2261",
      "description": "Contact with scorpions at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2262",
      "description": "Contact with scorpions at industrial and construction area While working for income"
    },
    {
      "name": "X2263",
      "description": "Contact with scorpions at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2264",
      "description": "Contact with scorpions at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2268",
      "description": "Contact with scorpions at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2269",
      "description": "Contact with scorpions at industrial and construction area During unspecified activity"
    },
    {
      "name": "X227",
      "description": "Contact with scorpions at farm"
    },
    {
      "name": "X2270",
      "description": "Contact with scorpions at farm While engaged in sports activity"
    },
    {
      "name": "X2271",
      "description": "Contact with scorpions at farm While engaged in leisure activity"
    },
    {
      "name": "X2272",
      "description": "Contact with scorpions at farm While working for income"
    },
    {
      "name": "X2273",
      "description": "Contact with scorpions at farm While engaged in other types of work"
    },
    {
      "name": "X2274",
      "description": "Contact with scorpions at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2278",
      "description": "Contact with scorpions at farm While engaged in other specified activities"
    },
    {
      "name": "X2279",
      "description": "Contact with scorpions at farm During unspecified activity"
    },
    {
      "name": "X228",
      "description": "Contact with scorpions at other specified places"
    },
    {
      "name": "X2280",
      "description": "Contact with scorpions at other specified places While engaged in sports activity"
    },
    {
      "name": "X2281",
      "description": "Contact with scorpions at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2282",
      "description": "Contact with scorpions at other specified places While working for income"
    },
    {
      "name": "X2283",
      "description": "Contact with scorpions at other specified places While engaged in other types of work"
    },
    {
      "name": "X2284",
      "description": "Contact with scorpions at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2288",
      "description": "Contact with scorpions at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2289",
      "description": "Contact with scorpions at other specified places During unspecified activity"
    },
    {
      "name": "X229",
      "description": "Contact with scorpions at unspecified place"
    },
    {
      "name": "X2290",
      "description": "Contact with scorpions at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2291",
      "description": "Contact with scorpions at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2292",
      "description": "Contact with scorpions at unspecified place While working for income"
    },
    {
      "name": "X2293",
      "description": "Contact with scorpions at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2294",
      "description": "Contact with scorpions at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2298",
      "description": "Contact with scorpions at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2299",
      "description": "Contact with scorpions at unspecified place During unspecified activity"
    },
    {
      "name": "X230",
      "description": "Contact with hornets, wasps and bees at home"
    },
    {
      "name": "X2300",
      "description": "Contact with hornets, wasps and bees at home While engaged in sports activity"
    },
    {
      "name": "X2301",
      "description": "Contact with hornets, wasps and bees at home While engaged in leisure activity"
    },
    {
      "name": "X2302",
      "description": "Contact with hornets, wasps and bees at home While working for income"
    },
    {
      "name": "X2303",
      "description": "Contact with hornets, wasps and bees at home While engaged in other types of work"
    },
    {
      "name": "X2304",
      "description": "Contact with hornets, wasps and bees at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2308",
      "description": "Contact with hornets, wasps and bees at home While engaged in other specified activities"
    },
    {
      "name": "X2309",
      "description": "Contact with hornets, wasps and bees at home During unspecified activity"
    },
    {
      "name": "X231",
      "description": "Contact with hornets, wasps and bees at residential institution"
    },
    {
      "name": "X2310",
      "description": "Contact with hornets, wasps and bees at residential institution While engaged in sports activity"
    },
    {
      "name": "X2311",
      "description": "Contact with hornets, wasps and bees at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2312",
      "description": "Contact with hornets, wasps and bees at residential institution While working for income"
    },
    {
      "name": "X2313",
      "description": "Contact with hornets, wasps and bees at residential institution While engaged in other types of work"
    },
    {
      "name": "X2314",
      "description": "Contact with hornets, wasps and bees at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2318",
      "description": "Contact with hornets, wasps and bees at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2319",
      "description": "Contact with hornets, wasps and bees at residential institution During unspecified activity"
    },
    {
      "name": "X232",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area"
    },
    {
      "name": "X2320",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2321",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2322",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2323",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2324",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2328",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2329",
      "description": "Contact with hornets, wasps and bees at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X233",
      "description": "Contact with hornets, wasps and bees at sports and athletics area"
    },
    {
      "name": "X2330",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2331",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2332",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While working for income"
    },
    {
      "name": "X2333",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2334",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2338",
      "description": "Contact with hornets, wasps and bees at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2339",
      "description": "Contact with hornets, wasps and bees at sports and athletics area During unspecified activity"
    },
    {
      "name": "X234",
      "description": "Contact with hornets, wasps and bees at street and highway"
    },
    {
      "name": "X2340",
      "description": "Contact with hornets, wasps and bees at street and highway While engaged in sports activity"
    },
    {
      "name": "X2341",
      "description": "Contact with hornets, wasps and bees at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2342",
      "description": "Contact with hornets, wasps and bees at street and highway While working for income"
    },
    {
      "name": "X2343",
      "description": "Contact with hornets, wasps and bees at street and highway While engaged in other types of work"
    },
    {
      "name": "X2344",
      "description": "Contact with hornets, wasps and bees at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2348",
      "description": "Contact with hornets, wasps and bees at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2349",
      "description": "Contact with hornets, wasps and bees at street and highway During unspecified activity"
    },
    {
      "name": "X235",
      "description": "Contact with hornets, wasps and bees at trade and service area"
    },
    {
      "name": "X2350",
      "description": "Contact with hornets, wasps and bees at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2351",
      "description": "Contact with hornets, wasps and bees at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2352",
      "description": "Contact with hornets, wasps and bees at trade and service area While working for income"
    },
    {
      "name": "X2353",
      "description": "Contact with hornets, wasps and bees at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2354",
      "description": "Contact with hornets, wasps and bees at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2358",
      "description": "Contact with hornets, wasps and bees at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2359",
      "description": "Contact with hornets, wasps and bees at trade and service area During unspecified activity"
    },
    {
      "name": "X236",
      "description": "Contact with hornets, wasps and bees at industrial and construction area"
    },
    {
      "name": "X2360",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2361",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2362",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While working for income"
    },
    {
      "name": "X2363",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2364",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2368",
      "description": "Contact with hornets, wasps and bees at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2369",
      "description": "Contact with hornets, wasps and bees at industrial and construction area During unspecified activity"
    },
    {
      "name": "X237",
      "description": "Contact with hornets, wasps and bees at farm"
    },
    {
      "name": "X2370",
      "description": "Contact with hornets, wasps and bees at farm While engaged in sports activity"
    },
    {
      "name": "X2371",
      "description": "Contact with hornets, wasps and bees at farm While engaged in leisure activity"
    },
    {
      "name": "X2372",
      "description": "Contact with hornets, wasps and bees at farm While working for income"
    },
    {
      "name": "X2373",
      "description": "Contact with hornets, wasps and bees at farm While engaged in other types of work"
    },
    {
      "name": "X2374",
      "description": "Contact with hornets, wasps and bees at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2378",
      "description": "Contact with hornets, wasps and bees at farm While engaged in other specified activities"
    },
    {
      "name": "X2379",
      "description": "Contact with hornets, wasps and bees at farm During unspecified activity"
    },
    {
      "name": "X238",
      "description": "Contact with hornets, wasps and bees at other specified places"
    },
    {
      "name": "X2380",
      "description": "Contact with hornets, wasps and bees at other specified places While engaged in sports activity"
    },
    {
      "name": "X2381",
      "description": "Contact with hornets, wasps and bees at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2382",
      "description": "Contact with hornets, wasps and bees at other specified places While working for income"
    },
    {
      "name": "X2383",
      "description": "Contact with hornets, wasps and bees at other specified places While engaged in other types of work"
    },
    {
      "name": "X2384",
      "description": "Contact with hornets, wasps and bees at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2388",
      "description": "Contact with hornets, wasps and bees at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2389",
      "description": "Contact with hornets, wasps and bees at other specified places During unspecified activity"
    },
    {
      "name": "X239",
      "description": "Contact with hornets, wasps and bees at unspecified place"
    },
    {
      "name": "X2390",
      "description": "Contact with hornets, wasps and bees at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2391",
      "description": "Contact with hornets, wasps and bees at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2392",
      "description": "Contact with hornets, wasps and bees at unspecified place While working for income"
    },
    {
      "name": "X2393",
      "description": "Contact with hornets, wasps and bees at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2394",
      "description": "Contact with hornets, wasps and bees at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2398",
      "description": "Contact with hornets, wasps and bees at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2399",
      "description": "Contact with hornets, wasps and bees at unspecified place During unspecified activity"
    },
    {
      "name": "X240",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home"
    },
    {
      "name": "X2400",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While engaged in sports activity"
    },
    {
      "name": "X2401",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While engaged in leisure activity"
    },
    {
      "name": "X2402",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While working for income"
    },
    {
      "name": "X2403",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While engaged in other types of work"
    },
    {
      "name": "X2404",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2408",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home While engaged in other specified activities"
    },
    {
      "name": "X2409",
      "description": "Contact with centipedes and venomous millipedes (tropical) at home During unspecified activity"
    },
    {
      "name": "X241",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution"
    },
    {
      "name": "X2410",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While engaged in sports activity"
    },
    {
      "name": "X2411",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2412",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While working for income"
    },
    {
      "name": "X2413",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While engaged in other types of work"
    },
    {
      "name": "X2414",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2418",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2419",
      "description": "Contact with centipedes and venomous millipedes (tropical) at residential institution During unspecified activity"
    },
    {
      "name": "X242",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area"
    },
    {
      "name": "X2420",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2421",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2422",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2423",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2424",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2428",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2429",
      "description": "Contact with centipedes and venomous millipedes (tropical) at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X243",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area"
    },
    {
      "name": "X2430",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2431",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2432",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While working for income"
    },
    {
      "name": "X2433",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2434",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2438",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2439",
      "description": "Contact with centipedes and venomous millipedes (tropical) at sports and athletics area During unspecified activity"
    },
    {
      "name": "X244",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway"
    },
    {
      "name": "X2440",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While engaged in sports activity"
    },
    {
      "name": "X2441",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2442",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While working for income"
    },
    {
      "name": "X2443",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While engaged in other types of work"
    },
    {
      "name": "X2444",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2448",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2449",
      "description": "Contact with centipedes and venomous millipedes (tropical) at street and highway During unspecified activity"
    },
    {
      "name": "X245",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area"
    },
    {
      "name": "X2450",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2451",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2452",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While working for income"
    },
    {
      "name": "X2453",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2454",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2458",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2459",
      "description": "Contact with centipedes and venomous millipedes (tropical) at trade and service area During unspecified activity"
    },
    {
      "name": "X246",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area"
    },
    {
      "name": "X2460",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2461",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2462",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While working for income"
    },
    {
      "name": "X2463",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2464",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2468",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2469",
      "description": "Contact with centipedes and venomous millipedes (tropical) at industrial and construction area During unspecified activity"
    },
    {
      "name": "X247",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm"
    },
    {
      "name": "X2470",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While engaged in sports activity"
    },
    {
      "name": "X2471",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While engaged in leisure activity"
    },
    {
      "name": "X2472",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While working for income"
    },
    {
      "name": "X2473",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While engaged in other types of work"
    },
    {
      "name": "X2474",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2478",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm While engaged in other specified activities"
    },
    {
      "name": "X2479",
      "description": "Contact with centipedes and venomous millipedes (tropical) at farm During unspecified activity"
    },
    {
      "name": "X248",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places"
    },
    {
      "name": "X2480",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While engaged in sports activity"
    },
    {
      "name": "X2481",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2482",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While working for income"
    },
    {
      "name": "X2483",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While engaged in other types of work"
    },
    {
      "name": "X2484",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2488",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2489",
      "description": "Contact with centipedes and venomous millipedes (tropical) at other specified places During unspecified activity"
    },
    {
      "name": "X249",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place"
    },
    {
      "name": "X2490",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2491",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2492",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While working for income"
    },
    {
      "name": "X2493",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2494",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2498",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2499",
      "description": "Contact with centipedes and venomous millipedes (tropical) at unspecified place During unspecified activity"
    },
    {
      "name": "X250",
      "description": "Contact with other specified venomous arthropods at home"
    },
    {
      "name": "X2500",
      "description": "Contact with other specified venomous arthropods at home While engaged in sports activity"
    },
    {
      "name": "X2501",
      "description": "Contact with other specified venomous arthropods at home While engaged in leisure activity"
    },
    {
      "name": "X2502",
      "description": "Contact with other specified venomous arthropods at home While working for income"
    },
    {
      "name": "X2503",
      "description": "Contact with other specified venomous arthropods at home While engaged in other types of work"
    },
    {
      "name": "X2504",
      "description": "Contact with other specified venomous arthropods at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2508",
      "description": "Contact with other specified venomous arthropods at home While engaged in other specified activities"
    },
    {
      "name": "X2509",
      "description": "Contact with other specified venomous arthropods at home During unspecified activity"
    },
    {
      "name": "X251",
      "description": "Contact with other specified venomous arthropods at residential institution"
    },
    {
      "name": "X2510",
      "description": "Contact with other specified venomous arthropods at residential institution While engaged in sports activity"
    },
    {
      "name": "X2511",
      "description": "Contact with other specified venomous arthropods at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2512",
      "description": "Contact with other specified venomous arthropods at residential institution While working for income"
    },
    {
      "name": "X2513",
      "description": "Contact with other specified venomous arthropods at residential institution While engaged in other types of work"
    },
    {
      "name": "X2514",
      "description": "Contact with other specified venomous arthropods at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2518",
      "description": "Contact with other specified venomous arthropods at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2519",
      "description": "Contact with other specified venomous arthropods at residential institution During unspecified activity"
    },
    {
      "name": "X252",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area"
    },
    {
      "name": "X2520",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2521",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2522",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2523",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2524",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2528",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2529",
      "description": "Contact with other specified venomous arthropods at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X253",
      "description": "Contact with other specified venomous arthropods at sports and athletics area"
    },
    {
      "name": "X2530",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2531",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2532",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While working for income"
    },
    {
      "name": "X2533",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2534",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2538",
      "description": "Contact with other specified venomous arthropods at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2539",
      "description": "Contact with other specified venomous arthropods at sports and athletics area During unspecified activity"
    },
    {
      "name": "X254",
      "description": "Contact with other specified venomous arthropods at street and highway"
    },
    {
      "name": "X2540",
      "description": "Contact with other specified venomous arthropods at street and highway While engaged in sports activity"
    },
    {
      "name": "X2541",
      "description": "Contact with other specified venomous arthropods at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2542",
      "description": "Contact with other specified venomous arthropods at street and highway While working for income"
    },
    {
      "name": "X2543",
      "description": "Contact with other specified venomous arthropods at street and highway While engaged in other types of work"
    },
    {
      "name": "X2544",
      "description": "Contact with other specified venomous arthropods at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2548",
      "description": "Contact with other specified venomous arthropods at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2549",
      "description": "Contact with other specified venomous arthropods at street and highway During unspecified activity"
    },
    {
      "name": "X255",
      "description": "Contact with other specified venomous arthropods at trade and service area"
    },
    {
      "name": "X2550",
      "description": "Contact with other specified venomous arthropods at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2551",
      "description": "Contact with other specified venomous arthropods at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2552",
      "description": "Contact with other specified venomous arthropods at trade and service area While working for income"
    },
    {
      "name": "X2553",
      "description": "Contact with other specified venomous arthropods at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2554",
      "description": "Contact with other specified venomous arthropods at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2558",
      "description": "Contact with other specified venomous arthropods at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2559",
      "description": "Contact with other specified venomous arthropods at trade and service area During unspecified activity"
    },
    {
      "name": "X256",
      "description": "Contact with other specified venomous arthropods at industrial and construction area"
    },
    {
      "name": "X2560",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2561",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2562",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While working for income"
    },
    {
      "name": "X2563",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2564",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2568",
      "description": "Contact with other specified venomous arthropods at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2569",
      "description": "Contact with other specified venomous arthropods at industrial and construction area During unspecified activity"
    },
    {
      "name": "X257",
      "description": "Contact with other specified venomous arthropods at farm"
    },
    {
      "name": "X2570",
      "description": "Contact with other specified venomous arthropods at farm While engaged in sports activity"
    },
    {
      "name": "X2571",
      "description": "Contact with other specified venomous arthropods at farm While engaged in leisure activity"
    },
    {
      "name": "X2572",
      "description": "Contact with other specified venomous arthropods at farm While working for income"
    },
    {
      "name": "X2573",
      "description": "Contact with other specified venomous arthropods at farm While engaged in other types of work"
    },
    {
      "name": "X2574",
      "description": "Contact with other specified venomous arthropods at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2578",
      "description": "Contact with other specified venomous arthropods at farm While engaged in other specified activities"
    },
    {
      "name": "X2579",
      "description": "Contact with other specified venomous arthropods at farm During unspecified activity"
    },
    {
      "name": "X258",
      "description": "Contact with other specified venomous arthropods at other specified places"
    },
    {
      "name": "X2580",
      "description": "Contact with other specified venomous arthropods at other specified places While engaged in sports activity"
    },
    {
      "name": "X2581",
      "description": "Contact with other specified venomous arthropods at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2582",
      "description": "Contact with other specified venomous arthropods at other specified places While working for income"
    },
    {
      "name": "X2583",
      "description": "Contact with other specified venomous arthropods at other specified places While engaged in other types of work"
    },
    {
      "name": "X2584",
      "description": "Contact with other specified venomous arthropods at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2588",
      "description": "Contact with other specified venomous arthropods at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2589",
      "description": "Contact with other specified venomous arthropods at other specified places During unspecified activity"
    },
    {
      "name": "X259",
      "description": "Contact with other specified venomous arthropods at unspecified place"
    },
    {
      "name": "X2590",
      "description": "Contact with other specified venomous arthropods at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2591",
      "description": "Contact with other specified venomous arthropods at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2592",
      "description": "Contact with other specified venomous arthropods at unspecified place While working for income"
    },
    {
      "name": "X2593",
      "description": "Contact with other specified venomous arthropods at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2594",
      "description": "Contact with other specified venomous arthropods at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2598",
      "description": "Contact with other specified venomous arthropods at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2599",
      "description": "Contact with other specified venomous arthropods at unspecified place During unspecified activity"
    },
    {
      "name": "X260",
      "description": "Contact with venomous marine animals and plants at home"
    },
    {
      "name": "X2600",
      "description": "Contact with venomous marine animals and plants at home While engaged in sports activity"
    },
    {
      "name": "X2601",
      "description": "Contact with venomous marine animals and plants at home While engaged in leisure activity"
    },
    {
      "name": "X2602",
      "description": "Contact with venomous marine animals and plants at home While working for income"
    },
    {
      "name": "X2603",
      "description": "Contact with venomous marine animals and plants at home While engaged in other types of work"
    },
    {
      "name": "X2604",
      "description": "Contact with venomous marine animals and plants at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2608",
      "description": "Contact with venomous marine animals and plants at home While engaged in other specified activities"
    },
    {
      "name": "X2609",
      "description": "Contact with venomous marine animals and plants at home During unspecified activity"
    },
    {
      "name": "X261",
      "description": "Contact with venomous marine animals and plants at residential institution"
    },
    {
      "name": "X2610",
      "description": "Contact with venomous marine animals and plants at residential institution While engaged in sports activity"
    },
    {
      "name": "X2611",
      "description": "Contact with venomous marine animals and plants at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2612",
      "description": "Contact with venomous marine animals and plants at residential institution While working for income"
    },
    {
      "name": "X2613",
      "description": "Contact with venomous marine animals and plants at residential institution While engaged in other types of work"
    },
    {
      "name": "X2614",
      "description": "Contact with venomous marine animals and plants at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2618",
      "description": "Contact with venomous marine animals and plants at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2619",
      "description": "Contact with venomous marine animals and plants at residential institution During unspecified activity"
    },
    {
      "name": "X262",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area"
    },
    {
      "name": "X2620",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2621",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2622",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2623",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2624",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2628",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2629",
      "description": "Contact with venomous marine animals and plants at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X263",
      "description": "Contact with venomous marine animals and plants at sports and athletics area"
    },
    {
      "name": "X2630",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2631",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2632",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While working for income"
    },
    {
      "name": "X2633",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2634",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2638",
      "description": "Contact with venomous marine animals and plants at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2639",
      "description": "Contact with venomous marine animals and plants at sports and athletics area During unspecified activity"
    },
    {
      "name": "X264",
      "description": "Contact with venomous marine animals and plants at street and highway"
    },
    {
      "name": "X2640",
      "description": "Contact with venomous marine animals and plants at street and highway While engaged in sports activity"
    },
    {
      "name": "X2641",
      "description": "Contact with venomous marine animals and plants at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2642",
      "description": "Contact with venomous marine animals and plants at street and highway While working for income"
    },
    {
      "name": "X2643",
      "description": "Contact with venomous marine animals and plants at street and highway While engaged in other types of work"
    },
    {
      "name": "X2644",
      "description": "Contact with venomous marine animals and plants at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2648",
      "description": "Contact with venomous marine animals and plants at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2649",
      "description": "Contact with venomous marine animals and plants at street and highway During unspecified activity"
    },
    {
      "name": "X265",
      "description": "Contact with venomous marine animals and plants at trade and service area"
    },
    {
      "name": "X2650",
      "description": "Contact with venomous marine animals and plants at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2651",
      "description": "Contact with venomous marine animals and plants at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2652",
      "description": "Contact with venomous marine animals and plants at trade and service area While working for income"
    },
    {
      "name": "X2653",
      "description": "Contact with venomous marine animals and plants at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2654",
      "description": "Contact with venomous marine animals and plants at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2658",
      "description": "Contact with venomous marine animals and plants at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2659",
      "description": "Contact with venomous marine animals and plants at trade and service area During unspecified activity"
    },
    {
      "name": "X266",
      "description": "Contact with venomous marine animals and plants at industrial and construction area"
    },
    {
      "name": "X2660",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2661",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2662",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While working for income"
    },
    {
      "name": "X2663",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2664",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2668",
      "description": "Contact with venomous marine animals and plants at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2669",
      "description": "Contact with venomous marine animals and plants at industrial and construction area During unspecified activity"
    },
    {
      "name": "X267",
      "description": "Contact with venomous marine animals and plants at farm"
    },
    {
      "name": "X2670",
      "description": "Contact with venomous marine animals and plants at farm While engaged in sports activity"
    },
    {
      "name": "X2671",
      "description": "Contact with venomous marine animals and plants at farm While engaged in leisure activity"
    },
    {
      "name": "X2672",
      "description": "Contact with venomous marine animals and plants at farm While working for income"
    },
    {
      "name": "X2673",
      "description": "Contact with venomous marine animals and plants at farm While engaged in other types of work"
    },
    {
      "name": "X2674",
      "description": "Contact with venomous marine animals and plants at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2678",
      "description": "Contact with venomous marine animals and plants at farm While engaged in other specified activities"
    },
    {
      "name": "X2679",
      "description": "Contact with venomous marine animals and plants at farm During unspecified activity"
    },
    {
      "name": "X268",
      "description": "Contact with venomous marine animals and plants at other specified places"
    },
    {
      "name": "X2680",
      "description": "Contact with venomous marine animals and plants at other specified places While engaged in sports activity"
    },
    {
      "name": "X2681",
      "description": "Contact with venomous marine animals and plants at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2682",
      "description": "Contact with venomous marine animals and plants at other specified places While working for income"
    },
    {
      "name": "X2683",
      "description": "Contact with venomous marine animals and plants at other specified places While engaged in other types of work"
    },
    {
      "name": "X2684",
      "description": "Contact with venomous marine animals and plants at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2688",
      "description": "Contact with venomous marine animals and plants at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2689",
      "description": "Contact with venomous marine animals and plants at other specified places During unspecified activity"
    },
    {
      "name": "X269",
      "description": "Contact with venomous marine animals and plants at unspecified place"
    },
    {
      "name": "X2690",
      "description": "Contact with venomous marine animals and plants at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2691",
      "description": "Contact with venomous marine animals and plants at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2692",
      "description": "Contact with venomous marine animals and plants at unspecified place While working for income"
    },
    {
      "name": "X2693",
      "description": "Contact with venomous marine animals and plants at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2694",
      "description": "Contact with venomous marine animals and plants at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2698",
      "description": "Contact with venomous marine animals and plants at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2699",
      "description": "Contact with venomous marine animals and plants at unspecified place During unspecified activity"
    },
    {
      "name": "X270",
      "description": "Contact with other specified venomous animals at home"
    },
    {
      "name": "X2700",
      "description": "Contact with other specified venomous animals at home While engaged in sports activity"
    },
    {
      "name": "X2701",
      "description": "Contact with other specified venomous animals at home While engaged in leisure activity"
    },
    {
      "name": "X2702",
      "description": "Contact with other specified venomous animals at home While working for income"
    },
    {
      "name": "X2703",
      "description": "Contact with other specified venomous animals at home While engaged in other types of work"
    },
    {
      "name": "X2704",
      "description": "Contact with other specified venomous animals at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2708",
      "description": "Contact with other specified venomous animals at home While engaged in other specified activities"
    },
    {
      "name": "X2709",
      "description": "Contact with other specified venomous animals at home During unspecified activity"
    },
    {
      "name": "X271",
      "description": "Contact with other specified venomous animals at residential institution"
    },
    {
      "name": "X2710",
      "description": "Contact with other specified venomous animals at residential institution While engaged in sports activity"
    },
    {
      "name": "X2711",
      "description": "Contact with other specified venomous animals at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2712",
      "description": "Contact with other specified venomous animals at residential institution While working for income"
    },
    {
      "name": "X2713",
      "description": "Contact with other specified venomous animals at residential institution While engaged in other types of work"
    },
    {
      "name": "X2714",
      "description": "Contact with other specified venomous animals at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2718",
      "description": "Contact with other specified venomous animals at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2719",
      "description": "Contact with other specified venomous animals at residential institution During unspecified activity"
    },
    {
      "name": "X272",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area"
    },
    {
      "name": "X2720",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2721",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2722",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2723",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2724",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2728",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2729",
      "description": "Contact with other specified venomous animals at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X273",
      "description": "Contact with other specified venomous animals at sports and athletics area"
    },
    {
      "name": "X2730",
      "description": "Contact with other specified venomous animals at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2731",
      "description": "Contact with other specified venomous animals at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2732",
      "description": "Contact with other specified venomous animals at sports and athletics area While working for income"
    },
    {
      "name": "X2733",
      "description": "Contact with other specified venomous animals at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2734",
      "description": "Contact with other specified venomous animals at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2738",
      "description": "Contact with other specified venomous animals at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2739",
      "description": "Contact with other specified venomous animals at sports and athletics area During unspecified activity"
    },
    {
      "name": "X274",
      "description": "Contact with other specified venomous animals at street and highway"
    },
    {
      "name": "X2740",
      "description": "Contact with other specified venomous animals at street and highway While engaged in sports activity"
    },
    {
      "name": "X2741",
      "description": "Contact with other specified venomous animals at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2742",
      "description": "Contact with other specified venomous animals at street and highway While working for income"
    },
    {
      "name": "X2743",
      "description": "Contact with other specified venomous animals at street and highway While engaged in other types of work"
    },
    {
      "name": "X2744",
      "description": "Contact with other specified venomous animals at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2748",
      "description": "Contact with other specified venomous animals at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2749",
      "description": "Contact with other specified venomous animals at street and highway During unspecified activity"
    },
    {
      "name": "X275",
      "description": "Contact with other specified venomous animals at trade and service area"
    },
    {
      "name": "X2750",
      "description": "Contact with other specified venomous animals at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2751",
      "description": "Contact with other specified venomous animals at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2752",
      "description": "Contact with other specified venomous animals at trade and service area While working for income"
    },
    {
      "name": "X2753",
      "description": "Contact with other specified venomous animals at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2754",
      "description": "Contact with other specified venomous animals at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2758",
      "description": "Contact with other specified venomous animals at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2759",
      "description": "Contact with other specified venomous animals at trade and service area During unspecified activity"
    },
    {
      "name": "X276",
      "description": "Contact with other specified venomous animals at industrial and construction area"
    },
    {
      "name": "X2760",
      "description": "Contact with other specified venomous animals at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2761",
      "description": "Contact with other specified venomous animals at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2762",
      "description": "Contact with other specified venomous animals at industrial and construction area While working for income"
    },
    {
      "name": "X2763",
      "description": "Contact with other specified venomous animals at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2764",
      "description": "Contact with other specified venomous animals at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2768",
      "description": "Contact with other specified venomous animals at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2769",
      "description": "Contact with other specified venomous animals at industrial and construction area During unspecified activity"
    },
    {
      "name": "X277",
      "description": "Contact with other specified venomous animals at farm"
    },
    {
      "name": "X2770",
      "description": "Contact with other specified venomous animals at farm While engaged in sports activity"
    },
    {
      "name": "X2771",
      "description": "Contact with other specified venomous animals at farm While engaged in leisure activity"
    },
    {
      "name": "X2772",
      "description": "Contact with other specified venomous animals at farm While working for income"
    },
    {
      "name": "X2773",
      "description": "Contact with other specified venomous animals at farm While engaged in other types of work"
    },
    {
      "name": "X2774",
      "description": "Contact with other specified venomous animals at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2778",
      "description": "Contact with other specified venomous animals at farm While engaged in other specified activities"
    },
    {
      "name": "X2779",
      "description": "Contact with other specified venomous animals at farm During unspecified activity"
    },
    {
      "name": "X278",
      "description": "Contact with other specified venomous animals at other specified places"
    },
    {
      "name": "X2780",
      "description": "Contact with other specified venomous animals at other specified places While engaged in sports activity"
    },
    {
      "name": "X2781",
      "description": "Contact with other specified venomous animals at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2782",
      "description": "Contact with other specified venomous animals at other specified places While working for income"
    },
    {
      "name": "X2783",
      "description": "Contact with other specified venomous animals at other specified places While engaged in other types of work"
    },
    {
      "name": "X2784",
      "description": "Contact with other specified venomous animals at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2788",
      "description": "Contact with other specified venomous animals at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2789",
      "description": "Contact with other specified venomous animals at other specified places During unspecified activity"
    },
    {
      "name": "X279",
      "description": "Contact with other specified venomous animals at unspecified place"
    },
    {
      "name": "X2790",
      "description": "Contact with other specified venomous animals at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2791",
      "description": "Contact with other specified venomous animals at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2792",
      "description": "Contact with other specified venomous animals at unspecified place While working for income"
    },
    {
      "name": "X2793",
      "description": "Contact with other specified venomous animals at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2794",
      "description": "Contact with other specified venomous animals at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2798",
      "description": "Contact with other specified venomous animals at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2799",
      "description": "Contact with other specified venomous animals at unspecified place During unspecified activity"
    },
    {
      "name": "X280",
      "description": "Contact with other specified venomous plants at home"
    },
    {
      "name": "X2800",
      "description": "Contact with other specified venomous plants at home While engaged in sports activity"
    },
    {
      "name": "X2801",
      "description": "Contact with other specified venomous plants at home While engaged in leisure activity"
    },
    {
      "name": "X2802",
      "description": "Contact with other specified venomous plants at home While working for income"
    },
    {
      "name": "X2803",
      "description": "Contact with other specified venomous plants at home While engaged in other types of work"
    },
    {
      "name": "X2804",
      "description": "Contact with other specified venomous plants at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2808",
      "description": "Contact with other specified venomous plants at home While engaged in other specified activities"
    },
    {
      "name": "X2809",
      "description": "Contact with other specified venomous plants at home During unspecified activity"
    },
    {
      "name": "X281",
      "description": "Contact with other specified venomous plants at residential institution"
    },
    {
      "name": "X2810",
      "description": "Contact with other specified venomous plants at residential institution While engaged in sports activity"
    },
    {
      "name": "X2811",
      "description": "Contact with other specified venomous plants at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2812",
      "description": "Contact with other specified venomous plants at residential institution While working for income"
    },
    {
      "name": "X2813",
      "description": "Contact with other specified venomous plants at residential institution While engaged in other types of work"
    },
    {
      "name": "X2814",
      "description": "Contact with other specified venomous plants at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2818",
      "description": "Contact with other specified venomous plants at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2819",
      "description": "Contact with other specified venomous plants at residential institution During unspecified activity"
    },
    {
      "name": "X282",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area"
    },
    {
      "name": "X2820",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2821",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2822",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2823",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2824",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2828",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2829",
      "description": "Contact with other specified venomous plants at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X283",
      "description": "Contact with other specified venomous plants at sports and athletics area"
    },
    {
      "name": "X2830",
      "description": "Contact with other specified venomous plants at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2831",
      "description": "Contact with other specified venomous plants at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2832",
      "description": "Contact with other specified venomous plants at sports and athletics area While working for income"
    },
    {
      "name": "X2833",
      "description": "Contact with other specified venomous plants at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2834",
      "description": "Contact with other specified venomous plants at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2838",
      "description": "Contact with other specified venomous plants at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2839",
      "description": "Contact with other specified venomous plants at sports and athletics area During unspecified activity"
    },
    {
      "name": "X284",
      "description": "Contact with other specified venomous plants at street and highway"
    },
    {
      "name": "X2840",
      "description": "Contact with other specified venomous plants at street and highway While engaged in sports activity"
    },
    {
      "name": "X2841",
      "description": "Contact with other specified venomous plants at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2842",
      "description": "Contact with other specified venomous plants at street and highway While working for income"
    },
    {
      "name": "X2843",
      "description": "Contact with other specified venomous plants at street and highway While engaged in other types of work"
    },
    {
      "name": "X2844",
      "description": "Contact with other specified venomous plants at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2848",
      "description": "Contact with other specified venomous plants at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2849",
      "description": "Contact with other specified venomous plants at street and highway During unspecified activity"
    },
    {
      "name": "X285",
      "description": "Contact with other specified venomous plants at trade and service area"
    },
    {
      "name": "X2850",
      "description": "Contact with other specified venomous plants at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2851",
      "description": "Contact with other specified venomous plants at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2852",
      "description": "Contact with other specified venomous plants at trade and service area While working for income"
    },
    {
      "name": "X2853",
      "description": "Contact with other specified venomous plants at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2854",
      "description": "Contact with other specified venomous plants at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2858",
      "description": "Contact with other specified venomous plants at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2859",
      "description": "Contact with other specified venomous plants at trade and service area During unspecified activity"
    },
    {
      "name": "X286",
      "description": "Contact with other specified venomous plants at industrial and construction area"
    },
    {
      "name": "X2860",
      "description": "Contact with other specified venomous plants at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2861",
      "description": "Contact with other specified venomous plants at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2862",
      "description": "Contact with other specified venomous plants at industrial and construction area While working for income"
    },
    {
      "name": "X2863",
      "description": "Contact with other specified venomous plants at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2864",
      "description": "Contact with other specified venomous plants at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2868",
      "description": "Contact with other specified venomous plants at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2869",
      "description": "Contact with other specified venomous plants at industrial and construction area During unspecified activity"
    },
    {
      "name": "X287",
      "description": "Contact with other specified venomous plants at farm"
    },
    {
      "name": "X2870",
      "description": "Contact with other specified venomous plants at farm While engaged in sports activity"
    },
    {
      "name": "X2871",
      "description": "Contact with other specified venomous plants at farm While engaged in leisure activity"
    },
    {
      "name": "X2872",
      "description": "Contact with other specified venomous plants at farm While working for income"
    },
    {
      "name": "X2873",
      "description": "Contact with other specified venomous plants at farm While engaged in other types of work"
    },
    {
      "name": "X2874",
      "description": "Contact with other specified venomous plants at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2878",
      "description": "Contact with other specified venomous plants at farm While engaged in other specified activities"
    },
    {
      "name": "X2879",
      "description": "Contact with other specified venomous plants at farm During unspecified activity"
    },
    {
      "name": "X288",
      "description": "Contact with other specified venomous plants at other specified places"
    },
    {
      "name": "X2880",
      "description": "Contact with other specified venomous plants at other specified places While engaged in sports activity"
    },
    {
      "name": "X2881",
      "description": "Contact with other specified venomous plants at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2882",
      "description": "Contact with other specified venomous plants at other specified places While working for income"
    },
    {
      "name": "X2883",
      "description": "Contact with other specified venomous plants at other specified places While engaged in other types of work"
    },
    {
      "name": "X2884",
      "description": "Contact with other specified venomous plants at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2888",
      "description": "Contact with other specified venomous plants at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2889",
      "description": "Contact with other specified venomous plants at other specified places During unspecified activity"
    },
    {
      "name": "X289",
      "description": "Contact with other specified venomous plants at unspecified place"
    },
    {
      "name": "X2890",
      "description": "Contact with other specified venomous plants at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2891",
      "description": "Contact with other specified venomous plants at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2892",
      "description": "Contact with other specified venomous plants at unspecified place While working for income"
    },
    {
      "name": "X2893",
      "description": "Contact with other specified venomous plants at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2894",
      "description": "Contact with other specified venomous plants at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2898",
      "description": "Contact with other specified venomous plants at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2899",
      "description": "Contact with other specified venomous plants at unspecified place During unspecified activity"
    },
    {
      "name": "X290",
      "description": "Contact with unspecified venomous animal or plant at home"
    },
    {
      "name": "X2900",
      "description": "Contact with unspecified venomous animal or plant at home While engaged in sports activity"
    },
    {
      "name": "X2901",
      "description": "Contact with unspecified venomous animal or plant at home While engaged in leisure activity"
    },
    {
      "name": "X2902",
      "description": "Contact with unspecified venomous animal or plant at home While working for income"
    },
    {
      "name": "X2903",
      "description": "Contact with unspecified venomous animal or plant at home While engaged in other types of work"
    },
    {
      "name": "X2904",
      "description": "Contact with unspecified venomous animal or plant at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2908",
      "description": "Contact with unspecified venomous animal or plant at home While engaged in other specified activities"
    },
    {
      "name": "X2909",
      "description": "Contact with unspecified venomous animal or plant at home During unspecified activity"
    },
    {
      "name": "X291",
      "description": "Contact with unspecified venomous animal or plant at residential institution"
    },
    {
      "name": "X2910",
      "description": "Contact with unspecified venomous animal or plant at residential institution While engaged in sports activity"
    },
    {
      "name": "X2911",
      "description": "Contact with unspecified venomous animal or plant at residential institution While engaged in leisure activity"
    },
    {
      "name": "X2912",
      "description": "Contact with unspecified venomous animal or plant at residential institution While working for income"
    },
    {
      "name": "X2913",
      "description": "Contact with unspecified venomous animal or plant at residential institution While engaged in other types of work"
    },
    {
      "name": "X2914",
      "description": "Contact with unspecified venomous animal or plant at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2918",
      "description": "Contact with unspecified venomous animal or plant at residential institution While engaged in other specified activities"
    },
    {
      "name": "X2919",
      "description": "Contact with unspecified venomous animal or plant at residential institution During unspecified activity"
    },
    {
      "name": "X292",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area"
    },
    {
      "name": "X2920",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X2921",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X2922",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X2923",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X2924",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2928",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X2929",
      "description": "Contact with unspecified venomous animal or plant at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X293",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area"
    },
    {
      "name": "X2930",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X2931",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X2932",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While working for income"
    },
    {
      "name": "X2933",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X2934",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2938",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X2939",
      "description": "Contact with unspecified venomous animal or plant at sports and athletics area During unspecified activity"
    },
    {
      "name": "X294",
      "description": "Contact with unspecified venomous animal or plant at street and highway"
    },
    {
      "name": "X2940",
      "description": "Contact with unspecified venomous animal or plant at street and highway While engaged in sports activity"
    },
    {
      "name": "X2941",
      "description": "Contact with unspecified venomous animal or plant at street and highway While engaged in leisure activity"
    },
    {
      "name": "X2942",
      "description": "Contact with unspecified venomous animal or plant at street and highway While working for income"
    },
    {
      "name": "X2943",
      "description": "Contact with unspecified venomous animal or plant at street and highway While engaged in other types of work"
    },
    {
      "name": "X2944",
      "description": "Contact with unspecified venomous animal or plant at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2948",
      "description": "Contact with unspecified venomous animal or plant at street and highway While engaged in other specified activities"
    },
    {
      "name": "X2949",
      "description": "Contact with unspecified venomous animal or plant at street and highway During unspecified activity"
    },
    {
      "name": "X295",
      "description": "Contact with unspecified venomous animal or plant at trade and service area"
    },
    {
      "name": "X2950",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While engaged in sports activity"
    },
    {
      "name": "X2951",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X2952",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While working for income"
    },
    {
      "name": "X2953",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While engaged in other types of work"
    },
    {
      "name": "X2954",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2958",
      "description": "Contact with unspecified venomous animal or plant at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X2959",
      "description": "Contact with unspecified venomous animal or plant at trade and service area During unspecified activity"
    },
    {
      "name": "X296",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area"
    },
    {
      "name": "X2960",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X2961",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X2962",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While working for income"
    },
    {
      "name": "X2963",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X2964",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2968",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X2969",
      "description": "Contact with unspecified venomous animal or plant at industrial and construction area During unspecified activity"
    },
    {
      "name": "X297",
      "description": "Contact with unspecified venomous animal or plant at farm"
    },
    {
      "name": "X2970",
      "description": "Contact with unspecified venomous animal or plant at farm While engaged in sports activity"
    },
    {
      "name": "X2971",
      "description": "Contact with unspecified venomous animal or plant at farm While engaged in leisure activity"
    },
    {
      "name": "X2972",
      "description": "Contact with unspecified venomous animal or plant at farm While working for income"
    },
    {
      "name": "X2973",
      "description": "Contact with unspecified venomous animal or plant at farm While engaged in other types of work"
    },
    {
      "name": "X2974",
      "description": "Contact with unspecified venomous animal or plant at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2978",
      "description": "Contact with unspecified venomous animal or plant at farm While engaged in other specified activities"
    },
    {
      "name": "X2979",
      "description": "Contact with unspecified venomous animal or plant at farm During unspecified activity"
    },
    {
      "name": "X298",
      "description": "Contact with unspecified venomous animal or plant at other specified places"
    },
    {
      "name": "X2980",
      "description": "Contact with unspecified venomous animal or plant at other specified places While engaged in sports activity"
    },
    {
      "name": "X2981",
      "description": "Contact with unspecified venomous animal or plant at other specified places While engaged in leisure activity"
    },
    {
      "name": "X2982",
      "description": "Contact with unspecified venomous animal or plant at other specified places While working for income"
    },
    {
      "name": "X2983",
      "description": "Contact with unspecified venomous animal or plant at other specified places While engaged in other types of work"
    },
    {
      "name": "X2984",
      "description": "Contact with unspecified venomous animal or plant at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2988",
      "description": "Contact with unspecified venomous animal or plant at other specified places While engaged in other specified activities"
    },
    {
      "name": "X2989",
      "description": "Contact with unspecified venomous animal or plant at other specified places During unspecified activity"
    },
    {
      "name": "X299",
      "description": "Contact with unspecified venomous animal or plant at unspecified place"
    },
    {
      "name": "X2990",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While engaged in sports activity"
    },
    {
      "name": "X2991",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X2992",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While working for income"
    },
    {
      "name": "X2993",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While engaged in other types of work"
    },
    {
      "name": "X2994",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X2998",
      "description": "Contact with unspecified venomous animal or plant at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X2999",
      "description": "Contact with unspecified venomous animal or plant at unspecified place During unspecified activity"
    },
    {
      "name": "X300",
      "description": "Exposure to excessive natural heat at home"
    },
    {
      "name": "X3000",
      "description": "Exposure to excessive natural heat at home While engaged in sports activity"
    },
    {
      "name": "X3001",
      "description": "Exposure to excessive natural heat at home  While engaged in leisure activity"
    },
    {
      "name": "X3002",
      "description": "Exposure to excessive natural heat at home While working for income"
    },
    {
      "name": "X3003",
      "description": "Exposure to excessive natural heat at home While engaged in other types of work"
    },
    {
      "name": "X3004",
      "description": "Exposure to excessive natural heat at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3008",
      "description": "Exposure to excessive natural heat at home While engaged in other specified activities"
    },
    {
      "name": "X3009",
      "description": "Exposure to excessive natural heat at home During unspecified activity"
    },
    {
      "name": "X301",
      "description": "Exposure to excessive natural heat at residential institution"
    },
    {
      "name": "X3010",
      "description": "Exposure to excessive natural heat at residential institution While engaged in sports activity"
    },
    {
      "name": "X3011",
      "description": "Exposure to excessive natural heat at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3012",
      "description": "Exposure to excessive natural heat at residential institution While working for income"
    },
    {
      "name": "X3013",
      "description": "Exposure to excessive natural heat at residential institution While engaged in other types of work"
    },
    {
      "name": "X3014",
      "description": "Exposure to excessive natural heat at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3018",
      "description": "Exposure to excessive natural heat at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3019",
      "description": "Exposure to excessive natural heat at residential institution During unspecified activity"
    },
    {
      "name": "X302",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area"
    },
    {
      "name": "X3020",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3021",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3022",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3023",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3024",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3028",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3029",
      "description": "Exposure to excessive natural heat at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X303",
      "description": "Exposure to excessive natural heat at sports and athletics area"
    },
    {
      "name": "X3030",
      "description": "Exposure to excessive natural heat at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3031",
      "description": "Exposure to excessive natural heat at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3032",
      "description": "Exposure to excessive natural heat at sports and athletics area While working for income"
    },
    {
      "name": "X3033",
      "description": "Exposure to excessive natural heat at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3034",
      "description": "Exposure to excessive natural heat at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3038",
      "description": "Exposure to excessive natural heat at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3039",
      "description": "Exposure to excessive natural heat at sports and athletics area During unspecified activity"
    },
    {
      "name": "X304",
      "description": "Exposure to excessive natural heat at street and highway"
    },
    {
      "name": "X3040",
      "description": "Exposure to excessive natural heat at street and highway While engaged in sports activity"
    },
    {
      "name": "X3041",
      "description": "Exposure to excessive natural heat at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3042",
      "description": "Exposure to excessive natural heat at street and highway While working for income"
    },
    {
      "name": "X3043",
      "description": "Exposure to excessive natural heat at street and highway While engaged in other types of work"
    },
    {
      "name": "X3044",
      "description": "Exposure to excessive natural heat at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3048",
      "description": "Exposure to excessive natural heat at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3049",
      "description": "Exposure to excessive natural heat at street and highway During unspecified activity"
    },
    {
      "name": "X305",
      "description": "Exposure to excessive natural heat at trade and service area"
    },
    {
      "name": "X3050",
      "description": "Exposure to excessive natural heat at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3051",
      "description": "Exposure to excessive natural heat at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3052",
      "description": "Exposure to excessive natural heat at trade and service area While working for income"
    },
    {
      "name": "X3053",
      "description": "Exposure to excessive natural heat at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3054",
      "description": "Exposure to excessive natural heat at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3058",
      "description": "Exposure to excessive natural heat at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3059",
      "description": "Exposure to excessive natural heat at trade and service area During unspecified activity"
    },
    {
      "name": "X306",
      "description": "Exposure to excessive natural heat at industrial and construction area"
    },
    {
      "name": "X3060",
      "description": "Exposure to excessive natural heat at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3061",
      "description": "Exposure to excessive natural heat at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3062",
      "description": "Exposure to excessive natural heat at industrial and construction area While working for income"
    },
    {
      "name": "X3063",
      "description": "Exposure to excessive natural heat at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3064",
      "description": "Exposure to excessive natural heat at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3068",
      "description": "Exposure to excessive natural heat at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3069",
      "description": "Exposure to excessive natural heat at industrial and construction area During unspecified activity"
    },
    {
      "name": "X307",
      "description": "Exposure to excessive natural heat at farm"
    },
    {
      "name": "X3070",
      "description": "Exposure to excessive natural heat at farm While engaged in sports activity"
    },
    {
      "name": "X3071",
      "description": "Exposure to excessive natural heat at farm While engaged in leisure activity"
    },
    {
      "name": "X3072",
      "description": "Exposure to excessive natural heat at farm While working for income"
    },
    {
      "name": "X3073",
      "description": "Exposure to excessive natural heat at farm While engaged in other types of work"
    },
    {
      "name": "X3074",
      "description": "Exposure to excessive natural heat at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3078",
      "description": "Exposure to excessive natural heat at farm While engaged in other specified activities"
    },
    {
      "name": "X3079",
      "description": "Exposure to excessive natural heat at farm During unspecified activity"
    },
    {
      "name": "X308",
      "description": "Exposure to excessive natural heat at other specified places"
    },
    {
      "name": "X3080",
      "description": "Exposure to excessive natural heat at other specified places While engaged in sports activity"
    },
    {
      "name": "X3081",
      "description": "Exposure to excessive natural heat at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3082",
      "description": "Exposure to excessive natural heat at other specified places While working for income"
    },
    {
      "name": "X3083",
      "description": "Exposure to excessive natural heat at other specified places While engaged in other types of work"
    },
    {
      "name": "X3084",
      "description": "Exposure to excessive natural heat at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3088",
      "description": "Exposure to excessive natural heat at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3089",
      "description": "Exposure to excessive natural heat at other specified places During unspecified activity"
    },
    {
      "name": "X309",
      "description": "Exposure to excessive natural heat at unspecified place"
    },
    {
      "name": "X3090",
      "description": "Exposure to excessive natural heat at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3091",
      "description": "Exposure to excessive natural heat at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3092",
      "description": "Exposure to excessive natural heat at unspecified place While working for income"
    },
    {
      "name": "X3093",
      "description": "Exposure to excessive natural heat at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3094",
      "description": "Exposure to excessive natural heat at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3098",
      "description": "Exposure to excessive natural heat at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3099",
      "description": "Exposure to excessive natural heat at unspecified place During unspecified activity"
    },
    {
      "name": "X310",
      "description": "Exposure to excessive natural cold at home"
    },
    {
      "name": "X3100",
      "description": "Exposure to excessive natural cold at home While engaged in sports activity"
    },
    {
      "name": "X3101",
      "description": "Exposure to excessive natural cold at home While engaged in leisure activity"
    },
    {
      "name": "X3102",
      "description": "Exposure to excessive natural cold at home While working for income"
    },
    {
      "name": "X3103",
      "description": "Exposure to excessive natural cold at home While engaged in other types of work"
    },
    {
      "name": "X3104",
      "description": "Exposure to excessive natural cold at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3108",
      "description": "Exposure to excessive natural cold at home While engaged in other specified activities"
    },
    {
      "name": "X3109",
      "description": "Exposure to excessive natural cold at home During unspecified activity"
    },
    {
      "name": "X311",
      "description": "Exposure to excessive natural cold at residential institution"
    },
    {
      "name": "X3110",
      "description": "Exposure to excessive natural cold at residential institution While engaged in sports activity"
    },
    {
      "name": "X3111",
      "description": "Exposure to excessive natural cold at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3112",
      "description": "Exposure to excessive natural cold at residential institution While working for income"
    },
    {
      "name": "X3113",
      "description": "Exposure to excessive natural cold at residential institution While engaged in other types of work"
    },
    {
      "name": "X3114",
      "description": "Exposure to excessive natural cold at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3118",
      "description": "Exposure to excessive natural cold at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3119",
      "description": "Exposure to excessive natural cold at residential institution During unspecified activity"
    },
    {
      "name": "X312",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area"
    },
    {
      "name": "X3120",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3121",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3122",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3123",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3124",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3128",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3129",
      "description": "Exposure to excessive natural cold at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X313",
      "description": "Exposure to excessive natural cold at sports and athletics area"
    },
    {
      "name": "X3130",
      "description": "Exposure to excessive natural cold at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3131",
      "description": "Exposure to excessive natural cold at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3132",
      "description": "Exposure to excessive natural cold at sports and athletics area While working for income"
    },
    {
      "name": "X3133",
      "description": "Exposure to excessive natural cold at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3134",
      "description": "Exposure to excessive natural cold at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3138",
      "description": "Exposure to excessive natural cold at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3139",
      "description": "Exposure to excessive natural cold at sports and athletics area During unspecified activity"
    },
    {
      "name": "X314",
      "description": "Exposure to excessive natural cold at street and highway"
    },
    {
      "name": "X3140",
      "description": "Exposure to excessive natural cold at street and highway While engaged in sports activity"
    },
    {
      "name": "X3141",
      "description": "Exposure to excessive natural cold at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3142",
      "description": "Exposure to excessive natural cold at street and highway While working for income"
    },
    {
      "name": "X3143",
      "description": "Exposure to excessive natural cold at street and highway While engaged in other types of work"
    },
    {
      "name": "X3144",
      "description": "Exposure to excessive natural cold at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3148",
      "description": "Exposure to excessive natural cold at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3149",
      "description": "Exposure to excessive natural cold at street and highway During unspecified activity"
    },
    {
      "name": "X315",
      "description": "Exposure to excessive natural cold at trade and service area"
    },
    {
      "name": "X3150",
      "description": "Exposure to excessive natural cold at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3151",
      "description": "Exposure to excessive natural cold at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3152",
      "description": "Exposure to excessive natural cold at trade and service area While working for income"
    },
    {
      "name": "X3153",
      "description": "Exposure to excessive natural cold at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3154",
      "description": "Exposure to excessive natural cold at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3158",
      "description": "Exposure to excessive natural cold at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3159",
      "description": "Exposure to excessive natural cold at trade and service area During unspecified activity"
    },
    {
      "name": "X316",
      "description": "Exposure to excessive natural cold at industrial and construction area"
    },
    {
      "name": "X3160",
      "description": "Exposure to excessive natural cold at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3161",
      "description": "Exposure to excessive natural cold at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3162",
      "description": "Exposure to excessive natural cold at industrial and construction area While working for income"
    },
    {
      "name": "X3163",
      "description": "Exposure to excessive natural cold at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3164",
      "description": "Exposure to excessive natural cold at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3168",
      "description": "Exposure to excessive natural cold at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3169",
      "description": "Exposure to excessive natural cold at industrial and construction area During unspecified activity"
    },
    {
      "name": "X317",
      "description": "Exposure to excessive natural cold at farm"
    },
    {
      "name": "X3170",
      "description": "Exposure to excessive natural cold at farm While engaged in sports activity"
    },
    {
      "name": "X3171",
      "description": "Exposure to excessive natural cold at farm While engaged in leisure activity"
    },
    {
      "name": "X3172",
      "description": "Exposure to excessive natural cold at farm While working for income"
    },
    {
      "name": "X3173",
      "description": "Exposure to excessive natural cold at farm While engaged in other types of work"
    },
    {
      "name": "X3174",
      "description": "Exposure to excessive natural cold at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3178",
      "description": "Exposure to excessive natural cold at farm While engaged in other specified activities"
    },
    {
      "name": "X3179",
      "description": "Exposure to excessive natural cold at farm During unspecified activity"
    },
    {
      "name": "X318",
      "description": "Exposure to excessive natural cold at other specified places"
    },
    {
      "name": "X3180",
      "description": "Exposure to excessive natural cold at other specified places While engaged in sports activity"
    },
    {
      "name": "X3181",
      "description": "Exposure to excessive natural cold at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3182",
      "description": "Exposure to excessive natural cold at other specified places While working for income"
    },
    {
      "name": "X3183",
      "description": "Exposure to excessive natural cold at other specified places While engaged in other types of work"
    },
    {
      "name": "X3184",
      "description": "Exposure to excessive natural cold at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3188",
      "description": "Exposure to excessive natural cold at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3189",
      "description": "Exposure to excessive natural cold at other specified places During unspecified activity"
    },
    {
      "name": "X319",
      "description": "Exposure to excessive natural cold at unspecified place"
    },
    {
      "name": "X3190",
      "description": "Exposure to excessive natural cold at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3191",
      "description": "Exposure to excessive natural cold at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3192",
      "description": "Exposure to excessive natural cold at unspecified place While working for income"
    },
    {
      "name": "X3193",
      "description": "Exposure to excessive natural cold at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3194",
      "description": "Exposure to excessive natural cold at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3198",
      "description": "Exposure to excessive natural cold at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3199",
      "description": "Exposure to excessive natural cold at unspecified place During unspecified activity"
    },
    {
      "name": "X320",
      "description": "Exposure to sunlight at home"
    },
    {
      "name": "X3200",
      "description": "Exposure to sunlight at home While engaged in sports activity"
    },
    {
      "name": "X3201",
      "description": "Exposure to sunlight at home While engaged in leisure activity"
    },
    {
      "name": "X3202",
      "description": "Exposure to sunlight at home While working for income"
    },
    {
      "name": "X3203",
      "description": "Exposure to sunlight at home While engaged in other types of work"
    },
    {
      "name": "X3204",
      "description": "Exposure to sunlight at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3208",
      "description": "Exposure to sunlight at home While engaged in other specified activities"
    },
    {
      "name": "X3209",
      "description": "Exposure to sunlight at home During unspecified activity"
    },
    {
      "name": "X321",
      "description": "Exposure to sunlight at residential institution"
    },
    {
      "name": "X3210",
      "description": "Exposure to sunlight at residential institution While engaged in sports activity"
    },
    {
      "name": "X3211",
      "description": "Exposure to sunlight at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3212",
      "description": "Exposure to sunlight at residential institution While working for income"
    },
    {
      "name": "X3213",
      "description": "Exposure to sunlight at residential institution While engaged in other types of work"
    },
    {
      "name": "X3214",
      "description": "Exposure to sunlight at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3218",
      "description": "Exposure to sunlight at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3219",
      "description": "Exposure to sunlight at residential institution During unspecified activity"
    },
    {
      "name": "X322",
      "description": "Exposure to sunlight at school,other institution and public administrative area"
    },
    {
      "name": "X3220",
      "description": "Exposure to sunlight at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3221",
      "description": "Exposure to sunlight at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3222",
      "description": "Exposure to sunlight at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3223",
      "description": "Exposure to sunlight at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3224",
      "description": "Exposure to sunlight at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3228",
      "description": "Exposure to sunlight at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3229",
      "description": "Exposure to sunlight at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X323",
      "description": "Exposure to sunlight at sports and athletics area"
    },
    {
      "name": "X3230",
      "description": "Exposure to sunlight at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3231",
      "description": "Exposure to sunlight at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3232",
      "description": "Exposure to sunlight at sports and athletics area While working for income"
    },
    {
      "name": "X3233",
      "description": "Exposure to sunlight at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3234",
      "description": "Exposure to sunlight at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3238",
      "description": "Exposure to sunlight at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3239",
      "description": "Exposure to sunlight at sports and athletics area During unspecified activity"
    },
    {
      "name": "X324",
      "description": "Exposure to sunlight at street and highway"
    },
    {
      "name": "X3240",
      "description": "Exposure to sunlight at street and highway While engaged in sports activity"
    },
    {
      "name": "X3241",
      "description": "Exposure to sunlight at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3242",
      "description": "Exposure to sunlight at street and highway While working for income"
    },
    {
      "name": "X3243",
      "description": "Exposure to sunlight at street and highway While engaged in other types of work"
    },
    {
      "name": "X3244",
      "description": "Exposure to sunlight at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3248",
      "description": "Exposure to sunlight at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3249",
      "description": "Exposure to sunlight at street and highway During unspecified activity"
    },
    {
      "name": "X325",
      "description": "Exposure to sunlight at trade and service area"
    },
    {
      "name": "X3250",
      "description": "Exposure to sunlight at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3251",
      "description": "Exposure to sunlight at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3252",
      "description": "Exposure to sunlight at trade and service area While working for income"
    },
    {
      "name": "X3253",
      "description": "Exposure to sunlight at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3254",
      "description": "Exposure to sunlight at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3258",
      "description": "Exposure to sunlight at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3259",
      "description": "Exposure to sunlight at trade and service area During unspecified activity"
    },
    {
      "name": "X326",
      "description": "Exposure to sunlight at industrial and construction area"
    },
    {
      "name": "X3260",
      "description": "Exposure to sunlight at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3261",
      "description": "Exposure to sunlight at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3262",
      "description": "Exposure to sunlight at industrial and construction area While working for income"
    },
    {
      "name": "X3263",
      "description": "Exposure to sunlight at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3264",
      "description": "Exposure to sunlight at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3268",
      "description": "Exposure to sunlight at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3269",
      "description": "Exposure to sunlight at industrial and construction area During unspecified activity"
    },
    {
      "name": "X327",
      "description": "Exposure to sunlight at farm"
    },
    {
      "name": "X3270",
      "description": "Exposure to sunlight at farm While engaged in sports activity"
    },
    {
      "name": "X3271",
      "description": "Exposure to sunlight at farm While engaged in leisure activity"
    },
    {
      "name": "X3272",
      "description": "Exposure to sunlight at farm While working for income"
    },
    {
      "name": "X3273",
      "description": "Exposure to sunlight at farm While engaged in other types of work"
    },
    {
      "name": "X3274",
      "description": "Exposure to sunlight at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3278",
      "description": "Exposure to sunlight at farm While engaged in other specified activities"
    },
    {
      "name": "X3279",
      "description": "Exposure to sunlight at farm During unspecified activity"
    },
    {
      "name": "X328",
      "description": "Exposure to sunlight at other specified places"
    },
    {
      "name": "X3280",
      "description": "Exposure to sunlight at other specified places While engaged in sports activity"
    },
    {
      "name": "X3281",
      "description": "Exposure to sunlight at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3282",
      "description": "Exposure to sunlight at other specified places While working for income"
    },
    {
      "name": "X3283",
      "description": "Exposure to sunlight at other specified places While engaged in other types of work"
    },
    {
      "name": "X3284",
      "description": "Exposure to sunlight at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3288",
      "description": "Exposure to sunlight at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3289",
      "description": "Exposure to sunlight at other specified places During unspecified activity"
    },
    {
      "name": "X329",
      "description": "Exposure to sunlight at unspecified place"
    },
    {
      "name": "X3290",
      "description": "Exposure to sunlight at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3291",
      "description": "Exposure to sunlight at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3292",
      "description": "Exposure to sunlight at unspecified place While working for income"
    },
    {
      "name": "X3293",
      "description": "Exposure to sunlight at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3294",
      "description": "Exposure to sunlight at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3298",
      "description": "Exposure to sunlight at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3299",
      "description": "Exposure to sunlight at unspecified place During unspecified activity"
    },
    {
      "name": "X330",
      "description": "Victim of lightning at home"
    },
    {
      "name": "X3300",
      "description": "Victim of lightning at home While engaged in sports activity"
    },
    {
      "name": "X3301",
      "description": "Victim of lightning at home While engaged in leisure activity"
    },
    {
      "name": "X3302",
      "description": "Victim of lightning at home While working for income"
    },
    {
      "name": "X3303",
      "description": "Victim of lightning at home While engaged in other types of work"
    },
    {
      "name": "X3304",
      "description": "Victim of lightning at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3308",
      "description": "Victim of lightning at home While engaged in other specified activities"
    },
    {
      "name": "X3309",
      "description": "Victim of lightning at home During unspecified activity"
    },
    {
      "name": "X331",
      "description": "Victim of lightning at residential institution"
    },
    {
      "name": "X3310",
      "description": "Victim of lightning at residential institution While engaged in sports activity"
    },
    {
      "name": "X3311",
      "description": "Victim of lightning at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3312",
      "description": "Victim of lightning at residential institution While working for income"
    },
    {
      "name": "X3313",
      "description": "Victim of lightning at residential institution While engaged in other types of work"
    },
    {
      "name": "X3314",
      "description": "Victim of lightning at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3318",
      "description": "Victim of lightning at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3319",
      "description": "Victim of lightning at residential institution During unspecified activity"
    },
    {
      "name": "X332",
      "description": "Victim of lightning at school,other institution and public administrative area"
    },
    {
      "name": "X3320",
      "description": "Victim of lightning at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3321",
      "description": "Victim of lightning at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3322",
      "description": "Victim of lightning at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3323",
      "description": "Victim of lightning at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3324",
      "description": "Victim of lightning at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3328",
      "description": "Victim of lightning at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3329",
      "description": "Victim of lightning at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X333",
      "description": "Victim of lightning at sports and athletics area"
    },
    {
      "name": "X3330",
      "description": "Victim of lightning at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3331",
      "description": "Victim of lightning at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3332",
      "description": "Victim of lightning at sports and athletics area While working for income"
    },
    {
      "name": "X3333",
      "description": "Victim of lightning at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3334",
      "description": "Victim of lightning at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3338",
      "description": "Victim of lightning at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3339",
      "description": "Victim of lightning at sports and athletics area During unspecified activity"
    },
    {
      "name": "X334",
      "description": "Victim of lightning at street and highway"
    },
    {
      "name": "X3340",
      "description": "Victim of lightning at street and highway While engaged in sports activity"
    },
    {
      "name": "X3341",
      "description": "Victim of lightning at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3342",
      "description": "Victim of lightning at street and highway While working for income"
    },
    {
      "name": "X3343",
      "description": "Victim of lightning at street and highway While engaged in other types of work"
    },
    {
      "name": "X3344",
      "description": "Victim of lightning at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3348",
      "description": "Victim of lightning at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3349",
      "description": "Victim of lightning at street and highway During unspecified activity"
    },
    {
      "name": "X335",
      "description": "Victim of lightning at trade and service area"
    },
    {
      "name": "X3350",
      "description": "Victim of lightning at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3351",
      "description": "Victim of lightning at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3352",
      "description": "Victim of lightning at trade and service area While working for income"
    },
    {
      "name": "X3353",
      "description": "Victim of lightning at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3354",
      "description": "Victim of lightning at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3358",
      "description": "Victim of lightning at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3359",
      "description": "Victim of lightning at trade and service area During unspecified activity"
    },
    {
      "name": "X336",
      "description": "Victim of lightning at industrial and construction area"
    },
    {
      "name": "X3360",
      "description": "Victim of lightning at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3361",
      "description": "Victim of lightning at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3362",
      "description": "Victim of lightning at industrial and construction area While working for income"
    },
    {
      "name": "X3363",
      "description": "Victim of lightning at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3364",
      "description": "Victim of lightning at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3368",
      "description": "Victim of lightning at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3369",
      "description": "Victim of lightning at industrial and construction area During unspecified activity"
    },
    {
      "name": "X337",
      "description": "Victim of lightning at farm"
    },
    {
      "name": "X3370",
      "description": "Victim of lightning at farm While engaged in sports activity"
    },
    {
      "name": "X3371",
      "description": "Victim of lightning at farm While engaged in leisure activity"
    },
    {
      "name": "X3372",
      "description": "Victim of lightning at farm While working for income"
    },
    {
      "name": "X3373",
      "description": "Victim of lightning at farm While engaged in other types of work"
    },
    {
      "name": "X3374",
      "description": "Victim of lightning at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3378",
      "description": "Victim of lightning at farm While engaged in other specified activities"
    },
    {
      "name": "X3379",
      "description": "Victim of lightning at farm During unspecified activity"
    },
    {
      "name": "X338",
      "description": "Victim of lightning at other specified places"
    },
    {
      "name": "X3380",
      "description": "Victim of lightning at other specified places While engaged in sports activity"
    },
    {
      "name": "X3381",
      "description": "Victim of lightning at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3382",
      "description": "Victim of lightning at other specified places While working for income"
    },
    {
      "name": "X3383",
      "description": "Victim of lightning at other specified places While engaged in other types of work"
    },
    {
      "name": "X3384",
      "description": "Victim of lightning at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3388",
      "description": "Victim of lightning at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3389",
      "description": "Victim of lightning at other specified places During unspecified activity"
    },
    {
      "name": "X339",
      "description": "Victim of lightning at unspecified place"
    },
    {
      "name": "X3390",
      "description": "Victim of lightning at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3391",
      "description": "Victim of lightning at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3392",
      "description": "Victim of lightning at unspecified place While working for income"
    },
    {
      "name": "X3393",
      "description": "Victim of lightning at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3394",
      "description": "Victim of lightning at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3398",
      "description": "Victim of lightning at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3399",
      "description": "Victim of lightning at unspecified place During unspecified activity"
    },
    {
      "name": "X340",
      "description": "Victim of cataclysmic earth movement caused by earthquake"
    },
    {
      "name": "X3400",
      "description": "Victim of cataclysmic earth movement caused by earthquake while engaged in sports activity"
    },
    {
      "name": "X3401",
      "description": "Victim of cataclysmic earth movement caused by earthquake while engaged in leisure activity"
    },
    {
      "name": "X3402",
      "description": "Victim of cataclysmic earth movement caused by earthquake while working for income"
    },
    {
      "name": "X3403",
      "description": "Victim of cataclysmic earth movement caused by earthquake while engaged in other types of work"
    },
    {
      "name": "X3404",
      "description": "Victim of cataclysmic earth movement caused by earthquake while resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3408",
      "description": "Victim of cataclysmic earth movement caused by earthquake while engaged in other specified activities"
    },
    {
      "name": "X3409",
      "description": "Victim of cataclysmic earth movement caused by earthquake during unspecified activity"
    },
    {
      "name": "X341",
      "description": "Victim of earthquake at residential institution"
    },
    {
      "name": "X3410",
      "description": "Victim of earthquake at residential institution While engaged in sports activity"
    },
    {
      "name": "X3411",
      "description": "Victim of earthquake at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3412",
      "description": "Victim of earthquake at residential institution While working for income"
    },
    {
      "name": "X3413",
      "description": "Victim of earthquake at residential institution While engaged in other types of work"
    },
    {
      "name": "X3414",
      "description": "Victim of earthquake at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3418",
      "description": "Victim of earthquake at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3419",
      "description": "Victim of earthquake at residential institution During unspecified activity"
    },
    {
      "name": "X342",
      "description": "Victim of earthquake at school,other institution and public administrative area"
    },
    {
      "name": "X3420",
      "description": "Victim of earthquake at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3421",
      "description": "Victim of earthquake at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3422",
      "description": "Victim of earthquake at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3423",
      "description": "Victim of earthquake at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3424",
      "description": "Victim of earthquake at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3428",
      "description": "Victim of earthquake at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3429",
      "description": "Victim of earthquake at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X343",
      "description": "Victim of earthquake at sports and athletics area"
    },
    {
      "name": "X3430",
      "description": "Victim of earthquake at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3431",
      "description": "Victim of earthquake at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3432",
      "description": "Victim of earthquake at sports and athletics area While working for income"
    },
    {
      "name": "X3433",
      "description": "Victim of earthquake at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3434",
      "description": "Victim of earthquake at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3438",
      "description": "Victim of earthquake at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3439",
      "description": "Victim of earthquake at sports and athletics area During unspecified activity"
    },
    {
      "name": "X344",
      "description": "Victim of earthquake at street and highway"
    },
    {
      "name": "X3440",
      "description": "Victim of earthquake at street and highway While engaged in sports activity"
    },
    {
      "name": "X3441",
      "description": "Victim of earthquake at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3442",
      "description": "Victim of earthquake at street and highway While working for income"
    },
    {
      "name": "X3443",
      "description": "Victim of earthquake at street and highway While engaged in other types of work"
    },
    {
      "name": "X3444",
      "description": "Victim of earthquake at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3448",
      "description": "Victim of earthquake at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3449",
      "description": "Victim of earthquake at street and highway During unspecified activity"
    },
    {
      "name": "X345",
      "description": "Victim of earthquake at trade and service area"
    },
    {
      "name": "X3450",
      "description": "Victim of earthquake at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3451",
      "description": "Victim of earthquake at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3452",
      "description": "Victim of earthquake at trade and service area While working for income"
    },
    {
      "name": "X3453",
      "description": "Victim of earthquake at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3454",
      "description": "Victim of earthquake at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3458",
      "description": "Victim of earthquake at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3459",
      "description": "Victim of earthquake at trade and service area During unspecified activity"
    },
    {
      "name": "X346",
      "description": "Victim of earthquake at industrial and construction area"
    },
    {
      "name": "X3460",
      "description": "Victim of earthquake at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3461",
      "description": "Victim of earthquake at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3462",
      "description": "Victim of earthquake at industrial and construction area While working for income"
    },
    {
      "name": "X3463",
      "description": "Victim of earthquake at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3464",
      "description": "Victim of earthquake at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3468",
      "description": "Victim of earthquake at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3469",
      "description": "Victim of earthquake at industrial and construction area During unspecified activity"
    },
    {
      "name": "X347",
      "description": "Victim of earthquake at farm"
    },
    {
      "name": "X3470",
      "description": "Victim of earthquake at farm While engaged in sports activity"
    },
    {
      "name": "X3471",
      "description": "Victim of earthquake at farm While engaged in leisure activity"
    },
    {
      "name": "X3472",
      "description": "Victim of earthquake at farm While working for income"
    },
    {
      "name": "X3473",
      "description": "Victim of earthquake at farm While engaged in other types of work"
    },
    {
      "name": "X3474",
      "description": "Victim of earthquake at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3478",
      "description": "Victim of earthquake at farm While engaged in other specified activities"
    },
    {
      "name": "X3479",
      "description": "Victim of earthquake at farm During unspecified activity"
    },
    {
      "name": "X348",
      "description": "Victim of earthquake at other specified places"
    },
    {
      "name": "X3480",
      "description": "Victim of earthquake at other specified places While engaged in sports activity"
    },
    {
      "name": "X3481",
      "description": "Victim of earthquake at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3482",
      "description": "Victim of earthquake at other specified places While working for income"
    },
    {
      "name": "X3483",
      "description": "Victim of earthquake at other specified places While engaged in other types of work"
    },
    {
      "name": "X3484",
      "description": "Victim of earthquake at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3488",
      "description": "Victim of earthquake at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3489",
      "description": "Victim of earthquake at other specified places During unspecified activity"
    },
    {
      "name": "X349",
      "description": "Victim of earthquake at unspecified place"
    },
    {
      "name": "X3490",
      "description": "Victim of earthquake at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3491",
      "description": "Victim of earthquake at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3492",
      "description": "Victim of earthquake at unspecified place While working for income"
    },
    {
      "name": "X3493",
      "description": "Victim of earthquake at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3494",
      "description": "Victim of earthquake at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3498",
      "description": "Victim of earthquake at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3499",
      "description": "Victim of earthquake at unspecified place During unspecified activity"
    },
    {
      "name": "X350",
      "description": "Victim of volcanic eruption at home"
    },
    {
      "name": "X3500",
      "description": "Victim of volcanic eruption at home While engaged in sports activity"
    },
    {
      "name": "X3501",
      "description": "Victim of volcanic eruption at home While engaged in leisure activity"
    },
    {
      "name": "X3502",
      "description": "Victim of volcanic eruption at home While working for income"
    },
    {
      "name": "X3503",
      "description": "Victim of volcanic eruption at home While engaged in other types of work"
    },
    {
      "name": "X3504",
      "description": "Victim of volcanic eruption at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3508",
      "description": "Victim of volcanic eruption at home While engaged in other specified activities"
    },
    {
      "name": "X3509",
      "description": "Victim of volcanic eruption at home During unspecified activity"
    },
    {
      "name": "X351",
      "description": "Victim of volcanic eruption at residential institution"
    },
    {
      "name": "X3510",
      "description": "Victim of volcanic eruption at residential institution While engaged in sports activity"
    },
    {
      "name": "X3511",
      "description": "Victim of volcanic eruption at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3512",
      "description": "Victim of volcanic eruption at residential institution While working for income"
    },
    {
      "name": "X3513",
      "description": "Victim of volcanic eruption at residential institution While engaged in other types of work"
    },
    {
      "name": "X3514",
      "description": "Victim of volcanic eruption at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3518",
      "description": "Victim of volcanic eruption at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3519",
      "description": "Victim of volcanic eruption at residential institution During unspecified activity"
    },
    {
      "name": "X352",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area"
    },
    {
      "name": "X3520",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3521",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3522",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3523",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3524",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3528",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3529",
      "description": "Victim of volcanic eruption at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X353",
      "description": "Victim of volcanic eruption at sports and athletics area"
    },
    {
      "name": "X3530",
      "description": "Victim of volcanic eruption at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3531",
      "description": "Victim of volcanic eruption at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3532",
      "description": "Victim of volcanic eruption at sports and athletics area While working for income"
    },
    {
      "name": "X3533",
      "description": "Victim of volcanic eruption at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3534",
      "description": "Victim of volcanic eruption at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3538",
      "description": "Victim of volcanic eruption at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3539",
      "description": "Victim of volcanic eruption at sports and athletics area During unspecified activity"
    },
    {
      "name": "X354",
      "description": "Victim of volcanic eruption at street and highway"
    },
    {
      "name": "X3540",
      "description": "Victim of volcanic eruption at street and highway While engaged in sports activity"
    },
    {
      "name": "X3541",
      "description": "Victim of volcanic eruption at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3542",
      "description": "Victim of volcanic eruption at street and highway While working for income"
    },
    {
      "name": "X3543",
      "description": "Victim of volcanic eruption at street and highway While engaged in other types of work"
    },
    {
      "name": "X3544",
      "description": "Victim of volcanic eruption at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3548",
      "description": "Victim of volcanic eruption at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3549",
      "description": "Victim of volcanic eruption at street and highway During unspecified activity"
    },
    {
      "name": "X355",
      "description": "Victim of volcanic eruption at trade and service area"
    },
    {
      "name": "X3550",
      "description": "Victim of volcanic eruption at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3551",
      "description": "Victim of volcanic eruption at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3552",
      "description": "Victim of volcanic eruption at trade and service area While working for income"
    },
    {
      "name": "X3553",
      "description": "Victim of volcanic eruption at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3554",
      "description": "Victim of volcanic eruption at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3558",
      "description": "Victim of volcanic eruption at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3559",
      "description": "Victim of volcanic eruption at trade and service area During unspecified activity"
    },
    {
      "name": "X356",
      "description": "Victim of volcanic eruption at industrial and construction area"
    },
    {
      "name": "X3560",
      "description": "Victim of volcanic eruption at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3561",
      "description": "Victim of volcanic eruption at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3562",
      "description": "Victim of volcanic eruption at industrial and construction area While working for income"
    },
    {
      "name": "X3563",
      "description": "Victim of volcanic eruption at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3564",
      "description": "Victim of volcanic eruption at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3568",
      "description": "Victim of volcanic eruption at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3569",
      "description": "Victim of volcanic eruption at industrial and construction area During unspecified activity"
    },
    {
      "name": "X357",
      "description": "Victim of volcanic eruption at farm"
    },
    {
      "name": "X3570",
      "description": "Victim of volcanic eruption at farm While engaged in sports activity"
    },
    {
      "name": "X3571",
      "description": "Victim of volcanic eruption at farm While engaged in leisure activity"
    },
    {
      "name": "X3572",
      "description": "Victim of volcanic eruption at farm While working for income"
    },
    {
      "name": "X3573",
      "description": "Victim of volcanic eruption at farm While engaged in other types of work"
    },
    {
      "name": "X3574",
      "description": "Victim of volcanic eruption at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3578",
      "description": "Victim of volcanic eruption at farm While engaged in other specified activities"
    },
    {
      "name": "X3579",
      "description": "Victim of volcanic eruption at farm During unspecified activity"
    },
    {
      "name": "X358",
      "description": "Victim of volcanic eruption at other specified places"
    },
    {
      "name": "X3580",
      "description": "Victim of volcanic eruption at other specified places While engaged in sports activity"
    },
    {
      "name": "X3581",
      "description": "Victim of volcanic eruption at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3582",
      "description": "Victim of volcanic eruption at other specified places While working for income"
    },
    {
      "name": "X3583",
      "description": "Victim of volcanic eruption at other specified places While engaged in other types of work"
    },
    {
      "name": "X3584",
      "description": "Victim of volcanic eruption at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3588",
      "description": "Victim of volcanic eruption at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3589",
      "description": "Victim of volcanic eruption at other specified places During unspecified activity"
    },
    {
      "name": "X359",
      "description": "Victim of volcanic eruption at unspecified place"
    },
    {
      "name": "X3590",
      "description": "Victim of volcanic eruption at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3591",
      "description": "Victim of volcanic eruption at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3592",
      "description": "Victim of volcanic eruption at unspecified place While working for income"
    },
    {
      "name": "X3593",
      "description": "Victim of volcanic eruption at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3594",
      "description": "Victim of volcanic eruption at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3598",
      "description": "Victim of volcanic eruption at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3599",
      "description": "Victim of volcanic eruption at unspecified place During unspecified activity"
    },
    {
      "name": "X360",
      "description": "Victim of avalanche, landslide and other earth movements at home"
    },
    {
      "name": "X3600",
      "description": "Victim of avalanche, landslide and other earth movements at home While engaged in sports activity"
    },
    {
      "name": "X3601",
      "description": "Victim of avalanche, landslide and other earth movements at home While engaged in leisure activity"
    },
    {
      "name": "X3602",
      "description": "Victim of avalanche, landslide and other earth movements at home While working for income"
    },
    {
      "name": "X3603",
      "description": "Victim of avalanche, landslide and other earth movements at home While engaged in other types of work"
    },
    {
      "name": "X3604",
      "description": "Victim of avalanche, landslide and other earth movements at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3608",
      "description": "Victim of avalanche, landslide and other earth movements at home While engaged in other specified activities"
    },
    {
      "name": "X3609",
      "description": "Victim of avalanche, landslide and other earth movements at home During unspecified activity"
    },
    {
      "name": "X361",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution"
    },
    {
      "name": "X3610",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While engaged in sports activity"
    },
    {
      "name": "X3611",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3612",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While working for income"
    },
    {
      "name": "X3613",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While engaged in other types of work"
    },
    {
      "name": "X3614",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3618",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3619",
      "description": "Victim of avalanche, landslide and other earth movements at residential institution During unspecified activity"
    },
    {
      "name": "X362",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area"
    },
    {
      "name": "X3620",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3621",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3622",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3623",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3624",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3628",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3629",
      "description": "Victim of avalanche, landslide and other earth movements at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X363",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area"
    },
    {
      "name": "X3630",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3631",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3632",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While working for income"
    },
    {
      "name": "X3633",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3634",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3638",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3639",
      "description": "Victim of avalanche, landslide and other earth movements at sports and athletics area During unspecified activity"
    },
    {
      "name": "X364",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway"
    },
    {
      "name": "X3640",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While engaged in sports activity"
    },
    {
      "name": "X3641",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3642",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While working for income"
    },
    {
      "name": "X3643",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While engaged in other types of work"
    },
    {
      "name": "X3644",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3648",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3649",
      "description": "Victim of avalanche, landslide and other earth movements at street and highway During unspecified activity"
    },
    {
      "name": "X365",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area"
    },
    {
      "name": "X3650",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3651",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3652",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While working for income"
    },
    {
      "name": "X3653",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3654",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3658",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3659",
      "description": "Victim of avalanche, landslide and other earth movements at trade and service area During unspecified activity"
    },
    {
      "name": "X366",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area"
    },
    {
      "name": "X3660",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3661",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3662",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While working for income"
    },
    {
      "name": "X3663",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3664",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3668",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3669",
      "description": "Victim of avalanche, landslide and other earth movements at industrial and construction area During unspecified activity"
    },
    {
      "name": "X367",
      "description": "Victim of avalanche, landslide and other earth movements at farm"
    },
    {
      "name": "X3670",
      "description": "Victim of avalanche, landslide and other earth movements at farm While engaged in sports activity"
    },
    {
      "name": "X3671",
      "description": "Victim of avalanche, landslide and other earth movements at farm While engaged in leisure activity"
    },
    {
      "name": "X3672",
      "description": "Victim of avalanche, landslide and other earth movements at farm While working for income"
    },
    {
      "name": "X3673",
      "description": "Victim of avalanche, landslide and other earth movements at farm While engaged in other types of work"
    },
    {
      "name": "X3674",
      "description": "Victim of avalanche, landslide and other earth movements at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3678",
      "description": "Victim of avalanche, landslide and other earth movements at farm While engaged in other specified activities"
    },
    {
      "name": "X3679",
      "description": "Victim of avalanche, landslide and other earth movements at farm During unspecified activity"
    },
    {
      "name": "X368",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places"
    },
    {
      "name": "X3680",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While engaged in sports activity"
    },
    {
      "name": "X3681",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3682",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While working for income"
    },
    {
      "name": "X3683",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While engaged in other types of work"
    },
    {
      "name": "X3684",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3688",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3689",
      "description": "Victim of avalanche, landslide and other earth movements at other specified places During unspecified activity"
    },
    {
      "name": "X369",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place"
    },
    {
      "name": "X3690",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3691",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3692",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While working for income"
    },
    {
      "name": "X3693",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3694",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3698",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3699",
      "description": "Victim of avalanche, landslide and other earth movements at unspecified place During unspecified activity"
    },
    {
      "name": "X370",
      "description": "Victim of cataclysmic  storm  at home"
    },
    {
      "name": "X3700",
      "description": "Victim of cataclysmic storm at home While engaged in sports activity"
    },
    {
      "name": "X3701",
      "description": "Victim of cataclysmic storm at home While engaged in leisure activity"
    },
    {
      "name": "X3702",
      "description": "Victim of cataclysmic storm at home While working for income"
    },
    {
      "name": "X3703",
      "description": "Victim of cataclysmic storm at home While engaged in other types of work"
    },
    {
      "name": "X3704",
      "description": "Victim of cataclysmic storm at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3708",
      "description": "Victim of cataclysmic storm at home While engaged in other specified activities"
    },
    {
      "name": "X3709",
      "description": "Victim of cataclysmic storm at home During unspecified activity"
    },
    {
      "name": "X371",
      "description": "Victim of cataclysmic storm at residential institution"
    },
    {
      "name": "X3710",
      "description": "Victim of cataclysmic storm at residential institution While engaged in sports activity"
    },
    {
      "name": "X3711",
      "description": "Victim of cataclysmic storm at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3712",
      "description": "Victim of cataclysmic storm at residential institution While working for income"
    },
    {
      "name": "X3713",
      "description": "Victim of cataclysmic storm at residential institution While engaged in other types of work"
    },
    {
      "name": "X3714",
      "description": "Victim of cataclysmic storm at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3718",
      "description": "Victim of cataclysmic storm at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3719",
      "description": "Victim of cataclysmic storm at residential institution During unspecified activity"
    },
    {
      "name": "X372",
      "description": "Victim of cataclysmic strom at school,other institution and public administrative area"
    },
    {
      "name": "X3720",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3721",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3722",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3723",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3724",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3728",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3729",
      "description": "Victim of cataclysmic storm at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X373",
      "description": "Victim of cataclysmic storm at sports and athletics area"
    },
    {
      "name": "X3730",
      "description": "Victim of cataclysmic storm at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3731",
      "description": "Victim of cataclysmic storm at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3732",
      "description": "Victim of cataclysmic storm at sports and athletics area While working for income"
    },
    {
      "name": "X3733",
      "description": "Victim of cataclysmic storm at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3734",
      "description": "Victim of cataclysmic storm at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3738",
      "description": "Victim of cataclysmic storm at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3739",
      "description": "Victim of cataclysmic storm at sports and athletics area During unspecified activity"
    },
    {
      "name": "X374",
      "description": "Victim of cataclysmic storm at street and highway"
    },
    {
      "name": "X3740",
      "description": "Victim of cataclysmic storm at street and highway While engaged in sports activity"
    },
    {
      "name": "X3741",
      "description": "Victim of cataclysmic storm at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3742",
      "description": "Victim of cataclysmic storm at street and highway While working for income"
    },
    {
      "name": "X3743",
      "description": "Victim of cataclysmic storm at street and highway While engaged in other types of work"
    },
    {
      "name": "X3744",
      "description": "Victim of cataclysmic storm at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3748",
      "description": "Victim of cataclysmic storm at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3749",
      "description": "Victim of cataclysmic storm at street and highway During unspecified activity"
    },
    {
      "name": "X375",
      "description": "Victim of cataclysmic storm at trade and service area"
    },
    {
      "name": "X3750",
      "description": "Victim of cataclysmic storm at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3751",
      "description": "Victim of cataclysmic storm at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3752",
      "description": "Victim of cataclysmic storm at trade and service area While working for income"
    },
    {
      "name": "X3753",
      "description": "Victim of cataclysmic storm at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3754",
      "description": "Victim of cataclysmic storm at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3758",
      "description": "Victim of cataclysmic storm at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3759",
      "description": "Victim of cataclysmic storm at trade and service area During unspecified activity"
    },
    {
      "name": "X376",
      "description": "Victim of cataclysmic storm at industrial and construction area"
    },
    {
      "name": "X3760",
      "description": "Victim of cataclysmic storm at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3761",
      "description": "Victim of cataclysmic storm at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3762",
      "description": "Victim of cataclysmic storm at industrial and construction area While working for income"
    },
    {
      "name": "X3763",
      "description": "Victim of cataclysmic storm at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3764",
      "description": "Victim of cataclysmic storm at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3768",
      "description": "Victim of cataclysmic storm at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3769",
      "description": "Victim of cataclysmic storm at industrial and construction area During unspecified activity"
    },
    {
      "name": "X377",
      "description": "Victim of cataclysmic storm at farm"
    },
    {
      "name": "X3770",
      "description": "Victim of cataclysmic storm at farm While engaged in sports activity"
    },
    {
      "name": "X3771",
      "description": "Victim of cataclysmic storm at farm While engaged in leisure activity"
    },
    {
      "name": "X3772",
      "description": "Victim of cataclysmic storm at farm While working for income"
    },
    {
      "name": "X3773",
      "description": "Victim of cataclysmic storm at farm While engaged in other types of work"
    },
    {
      "name": "X3774",
      "description": "Victim of cataclysmic storm at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3778",
      "description": "Victim of cataclysmic storm at farm While engaged in other specified activities"
    },
    {
      "name": "X3779",
      "description": "Victim of cataclysmic storm at farm During unspecified activity"
    },
    {
      "name": "X378",
      "description": "Victim of cataclysmic storm at other specified places"
    },
    {
      "name": "X3780",
      "description": "Victim of cataclysmic storm at other specified places While engaged in sports activity"
    },
    {
      "name": "X3781",
      "description": "Victim of cataclysmic storm at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3782",
      "description": "Victim of cataclysmic storm at other specified places While working for income"
    },
    {
      "name": "X3783",
      "description": "Victim of cataclysmic storm at other specified places While engaged in other types of work"
    },
    {
      "name": "X3784",
      "description": "Victim of cataclysmic storm at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3788",
      "description": "Victim of cataclysmic storm at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3789",
      "description": "Victim of cataclysmic storm at other specified places During unspecified activity"
    },
    {
      "name": "X379",
      "description": "Victim of cataclysmic storm at unspecified place"
    },
    {
      "name": "X3790",
      "description": "Victim of cataclysmic strom at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3791",
      "description": "Victim of cataclysmic strom at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3792",
      "description": "Victim of cataclysmic strom at unspecified place While working for income"
    },
    {
      "name": "X3793",
      "description": "Victim of cataclysmic strom at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3794",
      "description": "Victim of cataclysmic strom at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3798",
      "description": "Victim of cataclysmic strom at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3799",
      "description": "Victim of cataclysmic strom at unspecified place During unspecified activity"
    },
    {
      "name": "X380",
      "description": "Victim of flood at home"
    },
    {
      "name": "X3800",
      "description": "Victim of flood at home While engaged in sports activity"
    },
    {
      "name": "X3801",
      "description": "Victim of flood at home While engaged in leisure activity"
    },
    {
      "name": "X3802",
      "description": "Victim of flood at home While working for income"
    },
    {
      "name": "X3803",
      "description": "Victim of flood at home While engaged in other types of work"
    },
    {
      "name": "X3804",
      "description": "Victim of flood at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3808",
      "description": "Victim of flood at home While engaged in other specified activities"
    },
    {
      "name": "X3809",
      "description": "Victim of flood at home During unspecified activity"
    },
    {
      "name": "X381",
      "description": "Victim of flood at residential institution"
    },
    {
      "name": "X3810",
      "description": "Victim of flood at residential institution While engaged in sports activity"
    },
    {
      "name": "X3811",
      "description": "Victim of flood at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3812",
      "description": "Victim of flood at residential institution While working for income"
    },
    {
      "name": "X3813",
      "description": "Victim of flood at residential institution While engaged in other types of work"
    },
    {
      "name": "X3814",
      "description": "Victim of flood at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3818",
      "description": "Victim of flood at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3819",
      "description": "Victim of flood at residential institution During unspecified activity"
    },
    {
      "name": "X382",
      "description": "Victim of flood at school,other institution and public administrative area"
    },
    {
      "name": "X3820",
      "description": "Victim of flood at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3821",
      "description": "Victim of flood at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3822",
      "description": "Victim of flood at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3823",
      "description": "Victim of flood at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3824",
      "description": "Victim of flood at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3828",
      "description": "Victim of flood at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3829",
      "description": "Victim of flood at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X383",
      "description": "Victim of flood at sports and athletics area"
    },
    {
      "name": "X3830",
      "description": "Victim of flood at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3831",
      "description": "Victim of flood at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3832",
      "description": "Victim of flood at sports and athletics area While working for income"
    },
    {
      "name": "X3833",
      "description": "Victim of flood at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3834",
      "description": "Victim of flood at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3838",
      "description": "Victim of flood at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3839",
      "description": "Victim of flood at sports and athletics area During unspecified activity"
    },
    {
      "name": "X384",
      "description": "Victim of flood at street and highway"
    },
    {
      "name": "X3840",
      "description": "Victim of flood at street and highway While engaged in sports activity"
    },
    {
      "name": "X3841",
      "description": "Victim of flood at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3842",
      "description": "Victim of flood at street and highway While working for income"
    },
    {
      "name": "X3843",
      "description": "Victim of flood at street and highway While engaged in other types of work"
    },
    {
      "name": "X3844",
      "description": "Victim of flood at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3848",
      "description": "Victim of flood at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3849",
      "description": "Victim of flood at street and highway During unspecified activity"
    },
    {
      "name": "X385",
      "description": "Victim of flood at trade and service area"
    },
    {
      "name": "X3850",
      "description": "Victim of flood at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3851",
      "description": "Victim of flood at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3852",
      "description": "Victim of flood at trade and service area While working for income"
    },
    {
      "name": "X3853",
      "description": "Victim of flood at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3854",
      "description": "Victim of flood at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3858",
      "description": "Victim of flood at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3859",
      "description": "Victim of flood at trade and service area During unspecified activity"
    },
    {
      "name": "X386",
      "description": "Victim of flood at industrial and construction area"
    },
    {
      "name": "X3860",
      "description": "Victim of flood at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3861",
      "description": "Victim of flood at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3862",
      "description": "Victim of flood at industrial and construction area While working for income"
    },
    {
      "name": "X3863",
      "description": "Victim of flood at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3864",
      "description": "Victim of flood at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3868",
      "description": "Victim of flood at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3869",
      "description": "Victim of flood at industrial and construction area During unspecified activity"
    },
    {
      "name": "X387",
      "description": "Victim of flood at farm"
    },
    {
      "name": "X3870",
      "description": "Victim of flood at farm While engaged in sports activity"
    },
    {
      "name": "X3871",
      "description": "Victim of flood at farm While engaged in leisure activity"
    },
    {
      "name": "X3872",
      "description": "Victim of flood at farm While working for income"
    },
    {
      "name": "X3873",
      "description": "Victim of flood at farm While engaged in other types of work"
    },
    {
      "name": "X3874",
      "description": "Victim of flood at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3878",
      "description": "Victim of flood at farm While engaged in other specified activities"
    },
    {
      "name": "X3879",
      "description": "Victim of flood at farm During unspecified activity"
    },
    {
      "name": "X388",
      "description": "Victim of flood at other specified places"
    },
    {
      "name": "X3880",
      "description": "Victim of flood at other specified places While engaged in sports activity"
    },
    {
      "name": "X3881",
      "description": "Victim of flood at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3882",
      "description": "Victim of flood at other specified places While working for income"
    },
    {
      "name": "X3883",
      "description": "Victim of flood at other specified places While engaged in other types of work"
    },
    {
      "name": "X3884",
      "description": "Victim of flood at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3888",
      "description": "Victim of flood at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3889",
      "description": "Victim of flood at other specified places During unspecified activity"
    },
    {
      "name": "X389",
      "description": "Victim of flood at unspecified place"
    },
    {
      "name": "X3890",
      "description": "Victim of flood at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3891",
      "description": "Victim of flood at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3892",
      "description": "Victim of flood at unspecified place While working for income"
    },
    {
      "name": "X3893",
      "description": "Victim of flood at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3894",
      "description": "Victim of flood at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3898",
      "description": "Victim of flood at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3899",
      "description": "Victim of flood at unspecified place During unspecified activity"
    },
    {
      "name": "X390",
      "description": "Exposure to other and unspecified forces of nature at home"
    },
    {
      "name": "X3900",
      "description": "Exposure to other and unspecified forces of nature at home While engaged in sports activity"
    },
    {
      "name": "X3901",
      "description": "Exposure to other and unspecified forces of nature at home While engaged in leisure activity"
    },
    {
      "name": "X3902",
      "description": "Exposure to other and unspecified forces of nature at home While working for income"
    },
    {
      "name": "X3903",
      "description": "Exposure to other and unspecified forces of nature at home While engaged in other types of work"
    },
    {
      "name": "X3904",
      "description": "Exposure to other and unspecified forces of nature at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3908",
      "description": "Exposure to other and unspecified forces of nature at home While engaged in other specified activities"
    },
    {
      "name": "X3909",
      "description": "Exposure to other and unspecified forces of nature at home During unspecified activity"
    },
    {
      "name": "X391",
      "description": "Exposure to other and unspecified forces of nature at residential institution"
    },
    {
      "name": "X3910",
      "description": "Exposure to other and unspecified forces of nature at residential institution While engaged in sports activity"
    },
    {
      "name": "X3911",
      "description": "Exposure to other and unspecified forces of nature at residential institution While engaged in leisure activity"
    },
    {
      "name": "X3912",
      "description": "Exposure to other and unspecified forces of nature at residential institution While working for income"
    },
    {
      "name": "X3913",
      "description": "Exposure to other and unspecified forces of nature at residential institution While engaged in other types of work"
    },
    {
      "name": "X3914",
      "description": "Exposure to other and unspecified forces of nature at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3918",
      "description": "Exposure to other and unspecified forces of nature at residential institution While engaged in other specified activities"
    },
    {
      "name": "X3919",
      "description": "Exposure to other and unspecified forces of nature at residential institution During unspecified activity"
    },
    {
      "name": "X392",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area"
    },
    {
      "name": "X3920",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X3921",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X3922",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X3923",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X3924",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3928",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X3929",
      "description": "Exposure to other and unspecified forces of nature at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X393",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area"
    },
    {
      "name": "X3930",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X3931",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X3932",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While working for income"
    },
    {
      "name": "X3933",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X3934",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3938",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X3939",
      "description": "Exposure to other and unspecified forces of nature at sports and athletics area During unspecified activity"
    },
    {
      "name": "X394",
      "description": "Exposure to other and unspecified forces of nature at street and highway"
    },
    {
      "name": "X3940",
      "description": "Exposure to other and unspecified forces of nature at street and highway While engaged in sports activity"
    },
    {
      "name": "X3941",
      "description": "Exposure to other and unspecified forces of nature at street and highway While engaged in leisure activity"
    },
    {
      "name": "X3942",
      "description": "Exposure to other and unspecified forces of nature at street and highway While working for income"
    },
    {
      "name": "X3943",
      "description": "Exposure to other and unspecified forces of nature at street and highway While engaged in other types of work"
    },
    {
      "name": "X3944",
      "description": "Exposure to other and unspecified forces of nature at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3948",
      "description": "Exposure to other and unspecified forces of nature at street and highway While engaged in other specified activities"
    },
    {
      "name": "X3949",
      "description": "Exposure to other and unspecified forces of nature at street and highway During unspecified activity"
    },
    {
      "name": "X395",
      "description": "Exposure to other and unspecified forces of nature at trade and service area"
    },
    {
      "name": "X3950",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While engaged in sports activity"
    },
    {
      "name": "X3951",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X3952",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While working for income"
    },
    {
      "name": "X3953",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While engaged in other types of work"
    },
    {
      "name": "X3954",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3958",
      "description": "Exposure to other and unspecified forces of nature at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X3959",
      "description": "Exposure to other and unspecified forces of nature at trade and service area During unspecified activity"
    },
    {
      "name": "X396",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area"
    },
    {
      "name": "X3960",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X3961",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X3962",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While working for income"
    },
    {
      "name": "X3963",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X3964",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3968",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X3969",
      "description": "Exposure to other and unspecified forces of nature at industrial and construction area During unspecified activity"
    },
    {
      "name": "X397",
      "description": "Exposure to other and unspecified forces of nature at farm"
    },
    {
      "name": "X3970",
      "description": "Exposure to other and unspecified forces of nature at farm While engaged in sports activity"
    },
    {
      "name": "X3971",
      "description": "Exposure to other and unspecified forces of nature at farm While engaged in leisure activity"
    },
    {
      "name": "X3972",
      "description": "Exposure to other and unspecified forces of nature at farm While working for income"
    },
    {
      "name": "X3973",
      "description": "Exposure to other and unspecified forces of nature at farm While engaged in other types of work"
    },
    {
      "name": "X3974",
      "description": "Exposure to other and unspecified forces of nature at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3978",
      "description": "Exposure to other and unspecified forces of nature at farm While engaged in other specified activities"
    },
    {
      "name": "X3979",
      "description": "Exposure to other and unspecified forces of nature at farm During unspecified activity"
    },
    {
      "name": "X398",
      "description": "Exposure to other and unspecified forces of nature at other specified places"
    },
    {
      "name": "X3980",
      "description": "Exposure to other and unspecified forces of nature at other specified places While engaged in sports activity"
    },
    {
      "name": "X3981",
      "description": "Exposure to other and unspecified forces of nature at other specified places While engaged in leisure activity"
    },
    {
      "name": "X3982",
      "description": "Exposure to other and unspecified forces of nature at other specified places While working for income"
    },
    {
      "name": "X3983",
      "description": "Exposure to other and unspecified forces of nature at other specified places While engaged in other types of work"
    },
    {
      "name": "X3984",
      "description": "Exposure to other and unspecified forces of nature at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3988",
      "description": "Exposure to other and unspecified forces of nature at other specified places While engaged in other specified activities"
    },
    {
      "name": "X3989",
      "description": "Exposure to other and unspecified forces of nature at other specified places During unspecified activity"
    },
    {
      "name": "X399",
      "description": "Exposure to other and unspecified forces of nature at unspecified place"
    },
    {
      "name": "X3990",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While engaged in sports activity"
    },
    {
      "name": "X3991",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X3992",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While working for income"
    },
    {
      "name": "X3993",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While engaged in other types of work"
    },
    {
      "name": "X3994",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X3998",
      "description": "Exposure to other and unspecified forces of nature at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X3999",
      "description": "Exposure to other and unspecified forces of nature at unspecified place During unspecified activity"
    },
    {
      "name": "X400",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home"
    },
    {
      "name": "X4000",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in sports activity"
    },
    {
      "name": "X4001",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in leisure activity"
    },
    {
      "name": "X4002",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While working for income"
    },
    {
      "name": "X4003",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in other types of work"
    },
    {
      "name": "X4004",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4008",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in other specified activities"
    },
    {
      "name": "X4009",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home During unspecified activity"
    },
    {
      "name": "X401",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution"
    },
    {
      "name": "X4010",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in sports activity"
    },
    {
      "name": "X4011",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4012",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While working for income"
    },
    {
      "name": "X4013",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in other types of work"
    },
    {
      "name": "X4014",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4018",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4019",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution During unspecified activity"
    },
    {
      "name": "X402",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area"
    },
    {
      "name": "X4020",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4021",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4022",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4023",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4024",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4028",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4029",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X403",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area"
    },
    {
      "name": "X4030",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4031",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4032",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While working for income"
    },
    {
      "name": "X4033",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4034",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4038",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4039",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area During unspecified activity"
    },
    {
      "name": "X404",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway"
    },
    {
      "name": "X4040",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in sports activity"
    },
    {
      "name": "X4041",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4042",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While working for income"
    },
    {
      "name": "X4043",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in other types of work"
    },
    {
      "name": "X4044",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4048",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4049",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway During unspecified activity"
    },
    {
      "name": "X405",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area"
    },
    {
      "name": "X4050",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4051",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4052",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While working for income"
    },
    {
      "name": "X4053",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4054",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4058",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4059",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area During unspecified activity"
    },
    {
      "name": "X406",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area"
    },
    {
      "name": "X4060",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4061",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4062",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While working for income"
    },
    {
      "name": "X4063",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4064",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4068",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4069",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area During unspecified activity"
    },
    {
      "name": "X407",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm"
    },
    {
      "name": "X4070",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in sports activity"
    },
    {
      "name": "X4071",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in leisure activity"
    },
    {
      "name": "X4072",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While working for income"
    },
    {
      "name": "X4073",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in other types of work"
    },
    {
      "name": "X4074",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4078",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in other specified activities"
    },
    {
      "name": "X4079",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm During unspecified activity"
    },
    {
      "name": "X408",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places"
    },
    {
      "name": "X4080",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in sports activity"
    },
    {
      "name": "X4081",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4082",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While working for income"
    },
    {
      "name": "X4083",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in other types of work"
    },
    {
      "name": "X4084",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4088",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4089",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places During unspecified activity"
    },
    {
      "name": "X409",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place"
    },
    {
      "name": "X4090",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4091",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4092",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While working for income"
    },
    {
      "name": "X4093",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4094",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4098",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4099",
      "description": "Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place During unspecified activity"
    },
    {
      "name": "X410",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home"
    },
    {
      "name": "X4100",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in sports activity"
    },
    {
      "name": "X4101",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in leisure activity"
    },
    {
      "name": "X4102",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While working for income"
    },
    {
      "name": "X4103",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in other types of work"
    },
    {
      "name": "X4104",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4108",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in other specified activities"
    },
    {
      "name": "X4109",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home During unspecified activity"
    },
    {
      "name": "X411",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution"
    },
    {
      "name": "X4110",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in sports activity"
    },
    {
      "name": "X4111",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4112",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While working for income"
    },
    {
      "name": "X4113",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in other types of work"
    },
    {
      "name": "X4114",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4118",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4119",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution During unspecified activity"
    },
    {
      "name": "X412",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area"
    },
    {
      "name": "X4120",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4121",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4122",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4123",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4124",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4128",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4129",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X413",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area"
    },
    {
      "name": "X4130",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4131",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4132",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While working for income"
    },
    {
      "name": "X4133",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4134",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4138",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4139",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area During unspecified activity"
    },
    {
      "name": "X414",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway"
    },
    {
      "name": "X4140",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in sports activity"
    },
    {
      "name": "X4141",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4142",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While working for income"
    },
    {
      "name": "X4143",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in other types of work"
    },
    {
      "name": "X4144",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4148",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4149",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway During unspecified activity"
    },
    {
      "name": "X415",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area"
    },
    {
      "name": "X4150",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4151",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4152",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While working for income"
    },
    {
      "name": "X4153",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4154",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4158",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4159",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area During unspecified activity"
    },
    {
      "name": "X416",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area"
    },
    {
      "name": "X4160",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4161",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4162",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While working for income"
    },
    {
      "name": "X4163",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4164",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4168",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4169",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area During unspecified activity"
    },
    {
      "name": "X417",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm"
    },
    {
      "name": "X4170",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in sports activity"
    },
    {
      "name": "X4171",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in leisure activity"
    },
    {
      "name": "X4172",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While working for income"
    },
    {
      "name": "X4173",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in other types of work"
    },
    {
      "name": "X4174",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4178",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in other specified activities"
    },
    {
      "name": "X4179",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm During unspecified activity"
    },
    {
      "name": "X418",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places"
    },
    {
      "name": "X4180",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in sports activity"
    },
    {
      "name": "X4181",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4182",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While working for income"
    },
    {
      "name": "X4183",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in other types of work"
    },
    {
      "name": "X4184",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4188",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4189",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places During unspecified activity"
    },
    {
      "name": "X419",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place"
    },
    {
      "name": "X4190",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4191",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4192",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While working for income"
    },
    {
      "name": "X4193",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4194",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4198",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4199",
      "description": "Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place During unspecified activity"
    },
    {
      "name": "X420",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home"
    },
    {
      "name": "X4200",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in sports activity"
    },
    {
      "name": "X4201",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in leisure activity"
    },
    {
      "name": "X4202",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While working for income"
    },
    {
      "name": "X4203",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in other types of work"
    },
    {
      "name": "X4204",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4208",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in other specified activities"
    },
    {
      "name": "X4209",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home During unspecified activity"
    },
    {
      "name": "X421",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution"
    },
    {
      "name": "X4210",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in sports activity"
    },
    {
      "name": "X4211",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4212",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While working for income"
    },
    {
      "name": "X4213",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in other types of work"
    },
    {
      "name": "X4214",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4218",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4219",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution During unspecified activity"
    },
    {
      "name": "X422",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area"
    },
    {
      "name": "X4220",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4221",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4222",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4223",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4224",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4228",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4229",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X423",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area"
    },
    {
      "name": "X4230",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4231",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4232",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While working for income"
    },
    {
      "name": "X4233",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4234",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4238",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4239",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area During unspecified activity"
    },
    {
      "name": "X424",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway"
    },
    {
      "name": "X4240",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in sports activity"
    },
    {
      "name": "X4241",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4242",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While working for income"
    },
    {
      "name": "X4243",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in other types of work"
    },
    {
      "name": "X4244",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4248",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4249",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway During unspecified activity"
    },
    {
      "name": "X425",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area"
    },
    {
      "name": "X4250",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4251",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4252",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While working for income"
    },
    {
      "name": "X4253",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4254",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4258",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4259",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area During unspecified activity"
    },
    {
      "name": "X426",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area"
    },
    {
      "name": "X4260",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4261",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4262",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While working for income"
    },
    {
      "name": "X4263",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4264",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4268",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4269",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area During unspecified activity"
    },
    {
      "name": "X427",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm"
    },
    {
      "name": "X4270",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in sports activity"
    },
    {
      "name": "X4271",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in leisure activity"
    },
    {
      "name": "X4272",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While working for income"
    },
    {
      "name": "X4273",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in other types of work"
    },
    {
      "name": "X4274",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4278",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in other specified activities"
    },
    {
      "name": "X4279",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm During unspecified activity"
    },
    {
      "name": "X428",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places"
    },
    {
      "name": "X4280",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in sports activity"
    },
    {
      "name": "X4281",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4282",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While working for income"
    },
    {
      "name": "X4283",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in other types of work"
    },
    {
      "name": "X4284",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4288",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4289",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places During unspecified activity"
    },
    {
      "name": "X429",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place"
    },
    {
      "name": "X4290",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4291",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4292",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While working for income"
    },
    {
      "name": "X4293",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4294",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4298",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4299",
      "description": "Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place During unspecified activity"
    },
    {
      "name": "X430",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home"
    },
    {
      "name": "X4300",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While engaged in sports activity"
    },
    {
      "name": "X4301",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While engaged in leisure activity"
    },
    {
      "name": "X4302",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While working for income"
    },
    {
      "name": "X4303",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While engaged in othe types of work"
    },
    {
      "name": "X4304",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4308",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home While engaged in othe specified activities"
    },
    {
      "name": "X4309",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at home During unspecified activity"
    },
    {
      "name": "X431",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution"
    },
    {
      "name": "X4310",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While engaged in sports activity"
    },
    {
      "name": "X4311",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4312",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While working for income"
    },
    {
      "name": "X4313",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While engaged in othe types of work"
    },
    {
      "name": "X4314",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4318",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution While engaged in othe specified activities"
    },
    {
      "name": "X4319",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at residential institution During unspecified activity"
    },
    {
      "name": "X432",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area"
    },
    {
      "name": "X4320",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4321",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4322",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While working for income"
    },
    {
      "name": "X4323",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While engaged in othe types of work"
    },
    {
      "name": "X4324",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4328",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area While engaged in othe specified activities"
    },
    {
      "name": "X4329",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at school,othe institution and public administrative area During unspecified activity"
    },
    {
      "name": "X433",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area"
    },
    {
      "name": "X4330",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4331",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4332",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While working for income"
    },
    {
      "name": "X4333",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While engaged in othe types of work"
    },
    {
      "name": "X4334",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4338",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area While engaged in othe specified activities"
    },
    {
      "name": "X4339",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at sports and athletics area During unspecified activity"
    },
    {
      "name": "X434",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway"
    },
    {
      "name": "X4340",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While engaged in sports activity"
    },
    {
      "name": "X4341",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4342",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While working for income"
    },
    {
      "name": "X4343",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While engaged in othe types of work"
    },
    {
      "name": "X4344",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4348",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway While engaged in othe specified activities"
    },
    {
      "name": "X4349",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at street and highway During unspecified activity"
    },
    {
      "name": "X435",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area"
    },
    {
      "name": "X4350",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4351",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4352",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While working for income"
    },
    {
      "name": "X4353",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While engaged in othe types of work"
    },
    {
      "name": "X4354",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4358",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area While engaged in othe specified activities"
    },
    {
      "name": "X4359",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at trade and service area During unspecified activity"
    },
    {
      "name": "X436",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area"
    },
    {
      "name": "X4360",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4361",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4362",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While working for income"
    },
    {
      "name": "X4363",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While engaged in othe types of work"
    },
    {
      "name": "X4364",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4368",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area While engaged in othe specified activities"
    },
    {
      "name": "X4369",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at industrial and construction area During unspecified activity"
    },
    {
      "name": "X437",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm"
    },
    {
      "name": "X4370",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While engaged in sports activity"
    },
    {
      "name": "X4371",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While engaged in leisure activity"
    },
    {
      "name": "X4372",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While working for income"
    },
    {
      "name": "X4373",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While engaged in othe types of work"
    },
    {
      "name": "X4374",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4378",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm While engaged in othe specified activities"
    },
    {
      "name": "X4379",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at farm During unspecified activity"
    },
    {
      "name": "X438",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places"
    },
    {
      "name": "X4380",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While engaged in sports activity"
    },
    {
      "name": "X4381",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While engaged in leisure activity"
    },
    {
      "name": "X4382",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While working for income"
    },
    {
      "name": "X4383",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While engaged in othe types of work"
    },
    {
      "name": "X4384",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4388",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places While engaged in othe specified activities"
    },
    {
      "name": "X4389",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at othe specified places During unspecified activity"
    },
    {
      "name": "X439",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place"
    },
    {
      "name": "X4390",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4391",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4392",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While working for income"
    },
    {
      "name": "X4393",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While engaged in othe types of work"
    },
    {
      "name": "X4394",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While resting, sleeping, eating or engaging in othe vital activities"
    },
    {
      "name": "X4398",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place While engaged in othe specified activities"
    },
    {
      "name": "X4399",
      "description": "Accidental poisoning by and exposure to othe drugs acting on the autonomic nervous system at unspecified place During unspecified activity"
    },
    {
      "name": "X440",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home"
    },
    {
      "name": "X4400",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in sports activity"
    },
    {
      "name": "X4401",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in leisure activity"
    },
    {
      "name": "X4402",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While working for income"
    },
    {
      "name": "X4403",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in other types of work"
    },
    {
      "name": "X4404",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4408",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in other specified activities"
    },
    {
      "name": "X4409",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home During unspecified activity"
    },
    {
      "name": "X441",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution"
    },
    {
      "name": "X4410",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X4411",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution  While engaged in leisure activity"
    },
    {
      "name": "X4412",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While working for income"
    },
    {
      "name": "X4413",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X4414",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4418",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4419",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution During unspecified activity"
    },
    {
      "name": "X442",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area"
    },
    {
      "name": "X4420",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4421",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4422",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4423",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4424",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4428",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4429",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X443",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area"
    },
    {
      "name": "X4430",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4431",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4432",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While working for income"
    },
    {
      "name": "X4433",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4434",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4438",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4439",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X444",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway"
    },
    {
      "name": "X4440",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X4441",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4442",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While working for income"
    },
    {
      "name": "X4443",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X4444",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4448",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4449",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway During unspecified activity"
    },
    {
      "name": "X445",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area "
    },
    {
      "name": "X4450",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4451",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4452",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While working for income"
    },
    {
      "name": "X4453",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4454",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4458",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4459",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area During unspecified activity"
    },
    {
      "name": "X446",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area"
    },
    {
      "name": "X4460",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4461",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4462",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While working for income"
    },
    {
      "name": "X4463",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4464",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4468",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4469",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X447",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm"
    },
    {
      "name": "X4470",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in sports activity"
    },
    {
      "name": "X4471",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in leisure activity"
    },
    {
      "name": "X4472",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While working for income"
    },
    {
      "name": "X4473",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in other types of work"
    },
    {
      "name": "X4474",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4478",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in other specified activities"
    },
    {
      "name": "X4479",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm During unspecified activity"
    },
    {
      "name": "X448",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places"
    },
    {
      "name": "X4480",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X4481",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4482",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While working for income"
    },
    {
      "name": "X4483",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X4484",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4488",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4489",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places During unspecified activity"
    },
    {
      "name": "X449",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place"
    },
    {
      "name": "X4490",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4491",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4492",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While working for income"
    },
    {
      "name": "X4493",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4494",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While resting, sleeping, eating or engaging in other vital"
    },
    {
      "name": "X4498",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4499",
      "description": "Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place During unspecified activity"
    },
    {
      "name": "X450",
      "description": "Accidental poisoning by and exposure to alcohol at home"
    },
    {
      "name": "X4500",
      "description": "Accidental poisoning by and exposure to alcohol at home While engaged in sports activity"
    },
    {
      "name": "X4501",
      "description": "Accidental poisoning by and exposure to alcohol at home While engaged in leisure activity"
    },
    {
      "name": "X4502",
      "description": "Accidental poisoning by and exposure to alcohol at home While working for income"
    },
    {
      "name": "X4503",
      "description": "Accidental poisoning by and exposure to alcohol at home While engaged in other types of work"
    },
    {
      "name": "X4504",
      "description": "Accidental poisoning by and exposure to alcohol at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4508",
      "description": "Accidental poisoning by and exposure to alcohol at home While engaged in other specified activities"
    },
    {
      "name": "X4509",
      "description": "Accidental poisoning by and exposure to alcohol at home During unspecified activity"
    },
    {
      "name": "X451",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution"
    },
    {
      "name": "X4510",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While engaged in sports activity"
    },
    {
      "name": "X4511",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4512",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While working for income"
    },
    {
      "name": "X4513",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While engaged in other types of work"
    },
    {
      "name": "X4514",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4518",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4519",
      "description": "Accidental poisoning by and exposure to alcohol at residential institution During unspecified activity"
    },
    {
      "name": "X452",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area"
    },
    {
      "name": "X4520",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4521",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4522",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4523",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4524",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4528",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4529",
      "description": "Accidental poisoning by and exposure to alcohol at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X453",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area"
    },
    {
      "name": "X4530",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4531",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4532",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While working for income"
    },
    {
      "name": "X4533",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4534",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4538",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4539",
      "description": "Accidental poisoning by and exposure to alcohol at sports and athletics area During unspecified activity"
    },
    {
      "name": "X454",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway"
    },
    {
      "name": "X4540",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While engaged in sports activity"
    },
    {
      "name": "X4541",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4542",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While working for income"
    },
    {
      "name": "X4543",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While engaged in other types of work"
    },
    {
      "name": "X4544",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4548",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4549",
      "description": "Accidental poisoning by and exposure to alcohol at street and highway During unspecified activity"
    },
    {
      "name": "X455",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area"
    },
    {
      "name": "X4550",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4551",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4552",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While working for income"
    },
    {
      "name": "X4553",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4554",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4558",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4559",
      "description": "Accidental poisoning by and exposure to alcohol at trade and service area During unspecified activity"
    },
    {
      "name": "X456",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area"
    },
    {
      "name": "X4560",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4561",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4562",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While working for income"
    },
    {
      "name": "X4563",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4564",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4568",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4569",
      "description": "Accidental poisoning by and exposure to alcohol at industrial and construction area During unspecified activity"
    },
    {
      "name": "X457",
      "description": "Accidental poisoning by and exposure to alcohol at farm"
    },
    {
      "name": "X4570",
      "description": "Accidental poisoning by and exposure to alcohol at farm While engaged in sports activity"
    },
    {
      "name": "X4571",
      "description": "Accidental poisoning by and exposure to alcohol at farm While engaged in leisure activity"
    },
    {
      "name": "X4572",
      "description": "Accidental poisoning by and exposure to alcohol at farm While working for income"
    },
    {
      "name": "X4573",
      "description": "Accidental poisoning by and exposure to alcohol at farm While engaged in other types of work"
    },
    {
      "name": "X4574",
      "description": "Accidental poisoning by and exposure to alcohol at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4578",
      "description": "Accidental poisoning by and exposure to alcohol at farm While engaged in other specified activities"
    },
    {
      "name": "X4579",
      "description": "Accidental poisoning by and exposure to alcohol at farm During unspecified activity"
    },
    {
      "name": "X458",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places"
    },
    {
      "name": "X4580",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While engaged in sports activity"
    },
    {
      "name": "X4581",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4582",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While working for income"
    },
    {
      "name": "X4583",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While engaged in other types of work"
    },
    {
      "name": "X4584",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4588",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4589",
      "description": "Accidental poisoning by and exposure to alcohol at other specified places During unspecified activity"
    },
    {
      "name": "X459",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place"
    },
    {
      "name": "X4590",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4591",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4592",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While working for income"
    },
    {
      "name": "X4593",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4594",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4598",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4599",
      "description": "Accidental poisoning by and exposure to alcohol at unspecified place During unspecified activity"
    },
    {
      "name": "X460",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home"
    },
    {
      "name": "X4600",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While engaged in sports activity"
    },
    {
      "name": "X4601",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While engaged in leisure activity"
    },
    {
      "name": "X4602",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While working for income"
    },
    {
      "name": "X4603",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While engaged in other types of work"
    },
    {
      "name": "X4604",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4608",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home While engaged in other specified activities"
    },
    {
      "name": "X4609",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at home During unspecified activity"
    },
    {
      "name": "X461",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution"
    },
    {
      "name": "X4610",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While engaged in sports activity"
    },
    {
      "name": "X4611",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4612",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While working for income"
    },
    {
      "name": "X4613",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While engaged in other types of work"
    },
    {
      "name": "X4614",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4618",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4619",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at residential institution During unspecified activity"
    },
    {
      "name": "X462",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area"
    },
    {
      "name": "X4620",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4621",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4622",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4623",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4624",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4628",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4629",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X463",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area"
    },
    {
      "name": "X4630",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4631",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4632",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While working for income"
    },
    {
      "name": "X4633",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4634",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4638",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4639",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X464",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway"
    },
    {
      "name": "X4640",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While engaged in sports activity"
    },
    {
      "name": "X4641",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4642",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While working for income"
    },
    {
      "name": "X4643",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While engaged in other types of work"
    },
    {
      "name": "X4644",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4648",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4649",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at street and highway During unspecified activity"
    },
    {
      "name": "X465",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area"
    },
    {
      "name": "X4650",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4651",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4652",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While working for income"
    },
    {
      "name": "X4653",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4654",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4658",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4659",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at trade and service area During unspecified activity"
    },
    {
      "name": "X466",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area"
    },
    {
      "name": "X4660",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4661",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4662",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While working for income"
    },
    {
      "name": "X4663",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4664",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4668",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4669",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X467",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm"
    },
    {
      "name": "X4670",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While engaged in sports activity"
    },
    {
      "name": "X4671",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While engaged in leisure activity"
    },
    {
      "name": "X4672",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While working for income"
    },
    {
      "name": "X4673",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While engaged in other types of work"
    },
    {
      "name": "X4674",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4678",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm While engaged in other specified activities"
    },
    {
      "name": "X4679",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at farm During unspecified activity"
    },
    {
      "name": "X468",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places"
    },
    {
      "name": "X4680",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While engaged in sports activity"
    },
    {
      "name": "X4681",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4682",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While working for income"
    },
    {
      "name": "X4683",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While engaged in other types of work"
    },
    {
      "name": "X4684",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4688",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4689",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at other specified places During unspecified activity"
    },
    {
      "name": "X469",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place"
    },
    {
      "name": "X4690",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4691",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4692",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While working for income"
    },
    {
      "name": "X4693",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4694",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4698",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4699",
      "description": "Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vopours at unspecified place During unspecified activity"
    },
    {
      "name": "X470",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home"
    },
    {
      "name": "X4700",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While engaged in sports activity"
    },
    {
      "name": "X4701",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While engaged in leisure activity"
    },
    {
      "name": "X4702",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While working for income"
    },
    {
      "name": "X4703",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While engaged in other types of work"
    },
    {
      "name": "X4704",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4708",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home While engaged in other specified activities"
    },
    {
      "name": "X4709",
      "description": "Accidental poisoning by and exposure to other gases and vapours at home During unspecified activity"
    },
    {
      "name": "X471",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution"
    },
    {
      "name": "X4710",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While engaged in sports activity"
    },
    {
      "name": "X4711",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4712",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While working for income"
    },
    {
      "name": "X4713",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While engaged in other types of work"
    },
    {
      "name": "X4714",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4718",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4719",
      "description": "Accidental poisoning by and exposure to other gases and vapours at residential institution During unspecified activity"
    },
    {
      "name": "X472",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area"
    },
    {
      "name": "X4720",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4721",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4722",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4723",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4724",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4728",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4729",
      "description": "Accidental poisoning by and exposure to other gases and vapours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X473",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area"
    },
    {
      "name": "X4730",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4731",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4732",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While working for income"
    },
    {
      "name": "X4733",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4734",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4738",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4739",
      "description": "Accidental poisoning by and exposure to other gases and vapours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X474",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway"
    },
    {
      "name": "X4740",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While engaged in sports activity"
    },
    {
      "name": "X4741",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4742",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While working for income"
    },
    {
      "name": "X4743",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While engaged in other types of work"
    },
    {
      "name": "X4744",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4748",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4749",
      "description": "Accidental poisoning by and exposure to other gases and vapours at street and highway During unspecified activity"
    },
    {
      "name": "X475",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area"
    },
    {
      "name": "X4750",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4751",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4752",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While working for income"
    },
    {
      "name": "X4753",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4754",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4758",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4759",
      "description": "Accidental poisoning by and exposure to other gases and vapours at trade and service area During unspecified activity"
    },
    {
      "name": "X476",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area"
    },
    {
      "name": "X4760",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4761",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4762",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While working for income"
    },
    {
      "name": "X4763",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4764",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4768",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4769",
      "description": "Accidental poisoning by and exposure to other gases and vapours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X477",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm"
    },
    {
      "name": "X4770",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While engaged in sports activity"
    },
    {
      "name": "X4771",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While engaged in leisure activity"
    },
    {
      "name": "X4772",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While working for income"
    },
    {
      "name": "X4773",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While engaged in other types of work"
    },
    {
      "name": "X4774",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4778",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm While engaged in other specified activities"
    },
    {
      "name": "X4779",
      "description": "Accidental poisoning by and exposure to other gases and vapours at farm During unspecified activity"
    },
    {
      "name": "X478",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places"
    },
    {
      "name": "X4780",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While engaged in sports activity"
    },
    {
      "name": "X4781",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4782",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While working for income"
    },
    {
      "name": "X4783",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While engaged in other types of work"
    },
    {
      "name": "X4784",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4788",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4789",
      "description": "Accidental poisoning by and exposure to other gases and vapours at other specified places During unspecified activity"
    },
    {
      "name": "X479",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place"
    },
    {
      "name": "X4790",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4791",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4792",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While working for income"
    },
    {
      "name": "X4793",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4794",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4798",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4799",
      "description": "Accidental poisoning by and exposure to other gases and vapours at unspecified place During unspecified activity"
    },
    {
      "name": "X480",
      "description": "Accidental poisoning by and exposure to pesticides at home"
    },
    {
      "name": "X4800",
      "description": "Accidental poisoning by and exposure to pesticides at home While engaged in sports activity"
    },
    {
      "name": "X4801",
      "description": "Accidental poisoning by and exposure to pesticides at home While engaged in leisure activity"
    },
    {
      "name": "X4802",
      "description": "Accidental poisoning by and exposure to pesticides at home While working for income"
    },
    {
      "name": "X4803",
      "description": "Accidental poisoning by and exposure to pesticides at home While engaged in other types of work"
    },
    {
      "name": "X4804",
      "description": "Accidental poisoning by and exposure to pesticides at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4808",
      "description": "Accidental poisoning by and exposure to pesticides at home While engaged in other specified activities"
    },
    {
      "name": "X4809",
      "description": "Accidental poisoning by and exposure to pesticides at home During unspecified activity"
    },
    {
      "name": "X481",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution"
    },
    {
      "name": "X4810",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While engaged in sports activity"
    },
    {
      "name": "X4811",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4812",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While working for income"
    },
    {
      "name": "X4813",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While engaged in other types of work"
    },
    {
      "name": "X4814",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4818",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4819",
      "description": "Accidental poisoning by and exposure to pesticides at residential institution During unspecified activity"
    },
    {
      "name": "X482",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area"
    },
    {
      "name": "X4820",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4821",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4822",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4823",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4824",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4828",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4829",
      "description": "Accidental poisoning by and exposure to pesticides at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X483",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area"
    },
    {
      "name": "X4830",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4831",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4832",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While working for income"
    },
    {
      "name": "X4833",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4834",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4838",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4839",
      "description": "Accidental poisoning by and exposure to pesticides at sports and athletics area During unspecified activity"
    },
    {
      "name": "X484",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway"
    },
    {
      "name": "X4840",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While engaged in sports activity"
    },
    {
      "name": "X4841",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4842",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While working for income"
    },
    {
      "name": "X4843",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While engaged in other types of work"
    },
    {
      "name": "X4844",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4848",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4849",
      "description": "Accidental poisoning by and exposure to pesticides at street and highway During unspecified activity"
    },
    {
      "name": "X485",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area"
    },
    {
      "name": "X4850",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4851",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4852",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While working for income"
    },
    {
      "name": "X4853",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4854",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4858",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4859",
      "description": "Accidental poisoning by and exposure to pesticides at trade and service area During unspecified activity"
    },
    {
      "name": "X486",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area"
    },
    {
      "name": "X4860",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4861",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4862",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While working for income"
    },
    {
      "name": "X4863",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4864",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4868",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4869",
      "description": "Accidental poisoning by and exposure to pesticides at industrial and construction area During unspecified activity"
    },
    {
      "name": "X487",
      "description": "Accidental poisoning by and exposure to pesticides at farm"
    },
    {
      "name": "X4870",
      "description": "Accidental poisoning by and exposure to pesticides at farm While engaged in sports activity"
    },
    {
      "name": "X4871",
      "description": "Accidental poisoning by and exposure to pesticides at farm While engaged in leisure activity"
    },
    {
      "name": "X4872",
      "description": "Accidental poisoning by and exposure to pesticides at farm While working for income"
    },
    {
      "name": "X4873",
      "description": "Accidental poisoning by and exposure to pesticides at farm While engaged in other types of work"
    },
    {
      "name": "X4874",
      "description": "Accidental poisoning by and exposure to pesticides at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4878",
      "description": "Accidental poisoning by and exposure to pesticides at farm While engaged in other specified activities"
    },
    {
      "name": "X4879",
      "description": "Accidental poisoning by and exposure to pesticides at farm During unspecified activity"
    },
    {
      "name": "X488",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places"
    },
    {
      "name": "X4880",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While engaged in sports activity"
    },
    {
      "name": "X4881",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4882",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While working for income"
    },
    {
      "name": "X4883",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While engaged in other types of work"
    },
    {
      "name": "X4884",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4888",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4889",
      "description": "Accidental poisoning by and exposure to pesticides at other specified places During unspecified activity"
    },
    {
      "name": "X489",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place"
    },
    {
      "name": "X4890",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4891",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4892",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While working for income"
    },
    {
      "name": "X4893",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4894",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4898",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4899",
      "description": "Accidental poisoning by and exposure to pesticides at unspecified place During unspecified activity"
    },
    {
      "name": "X490",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home"
    },
    {
      "name": "X4900",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While engaged in sports activity"
    },
    {
      "name": "X4901",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While engaged in leisure activity"
    },
    {
      "name": "X4902",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While working for income"
    },
    {
      "name": "X4903",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While engaged in other types of work"
    },
    {
      "name": "X4904",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4908",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home While engaged in other specified activities"
    },
    {
      "name": "X4909",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at home During unspecified activity"
    },
    {
      "name": "X491",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution"
    },
    {
      "name": "X4910",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X4911",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X4912",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While working for income"
    },
    {
      "name": "X4913",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X4914",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4918",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X4919",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at residential institution During unspecified activity"
    },
    {
      "name": "X492",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area"
    },
    {
      "name": "X4920",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X4921",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X4922",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X4923",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X4924",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4928",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X4929",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X493",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area"
    },
    {
      "name": "X4930",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X4931",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X4932",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While working for income"
    },
    {
      "name": "X4933",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X4934",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4938",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X4939",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X494",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway"
    },
    {
      "name": "X4940",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X4941",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X4942",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While working for income"
    },
    {
      "name": "X4943",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X4944",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4948",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X4949",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at street and highway During unspecified activity"
    },
    {
      "name": "X495",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area"
    },
    {
      "name": "X4950",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X4951",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X4952",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While working for income"
    },
    {
      "name": "X4953",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X4954",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4958",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X4959",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at trade and service area During unspecified activity"
    },
    {
      "name": "X496",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area"
    },
    {
      "name": "X4960",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X4961",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X4962",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While working for income"
    },
    {
      "name": "X4963",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X4964",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4968",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X4969",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X497",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm"
    },
    {
      "name": "X4970",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While engaged in sports activity"
    },
    {
      "name": "X4971",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While engaged in leisure activity"
    },
    {
      "name": "X4972",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While working for income"
    },
    {
      "name": "X4973",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While engaged in other types of work"
    },
    {
      "name": "X4974",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4978",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm While engaged in other specified activities"
    },
    {
      "name": "X4979",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at farm During unspecified activity"
    },
    {
      "name": "X498",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places"
    },
    {
      "name": "X4980",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X4981",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X4982",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While working for income"
    },
    {
      "name": "X4983",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X4984",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4988",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X4989",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at other specified places During unspecified activity"
    },
    {
      "name": "X499",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place"
    },
    {
      "name": "X4990",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X4991",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X4992",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While working for income"
    },
    {
      "name": "X4993",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X4994",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X4998",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X4999",
      "description": "Accidental poisoning by and exposure to other and unspeecified chemicals and noxious substances at unspecified place During unspecified activity"
    },
    {
      "name": "X500",
      "description": "Overexertion and strenuous or reptitive movements at home"
    },
    {
      "name": "X5000",
      "description": "Overexertion and strenuous or reptitive movements at home While engaged in sports activity"
    },
    {
      "name": "X5001",
      "description": "Overexertion and strenuous or reptitive movements at home While engaged in leisure activity"
    },
    {
      "name": "X5002",
      "description": "Overexertion and strenuous or reptitive movements at home While working for income"
    },
    {
      "name": "X5003",
      "description": "Overexertion and strenuous or reptitive movements at home While engaged in other types of work"
    },
    {
      "name": "X5004",
      "description": "Overexertion and strenuous or reptitive movements at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5008",
      "description": "Overexertion and strenuous or reptitive movements at home While engaged in other specified activities"
    },
    {
      "name": "X5009",
      "description": "Overexertion and strenuous or reptitive movements at home During unspecified activity"
    },
    {
      "name": "X501",
      "description": "Overexertion and strenuous or reptitive movements at residential institution"
    },
    {
      "name": "X5010",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While engaged in sports activity"
    },
    {
      "name": "X5011",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5012",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While working for income"
    },
    {
      "name": "X5013",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While engaged in other types of work"
    },
    {
      "name": "X5014",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5018",
      "description": "Overexertion and strenuous or reptitive movements at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5019",
      "description": "Overexertion and strenuous or reptitive movements at residential institution During unspecified activity"
    },
    {
      "name": "X502",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area"
    },
    {
      "name": "X5020",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5021",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5022",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5023",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5024",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5028",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5029",
      "description": "Overexertion and strenuous or reptitive movements at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X503",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area"
    },
    {
      "name": "X5030",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5031",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5032",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While working for income"
    },
    {
      "name": "X5033",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5034",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5038",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5039",
      "description": "Overexertion and strenuous or reptitive movements at sports and athletics area During unspecified activity"
    },
    {
      "name": "X504",
      "description": "Overexertion and strenuous or reptitive movements at street and highway"
    },
    {
      "name": "X5040",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While engaged in sports activity"
    },
    {
      "name": "X5041",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5042",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While working for income"
    },
    {
      "name": "X5043",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While engaged in other types of work"
    },
    {
      "name": "X5044",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5048",
      "description": "Overexertion and strenuous or repetitive movements at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5049",
      "description": "Overexertion and strenuous or repetitive movements at street and highway During unspecified activity"
    },
    {
      "name": "X505",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area"
    },
    {
      "name": "X5050",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5051",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5052",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While working for income"
    },
    {
      "name": "X5053",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5054",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5058",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5059",
      "description": "Overexertion and strenuous or repetitive movements at trade and service area During unspecified activity"
    },
    {
      "name": "X506",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area"
    },
    {
      "name": "X5060",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5061",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5062",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While working for income"
    },
    {
      "name": "X5063",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5064",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5068",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5069",
      "description": "Overexertion and strenuous or repetitive movements at industrial and construction area During unspecified activity"
    },
    {
      "name": "X507",
      "description": "Overexertion and strenuous or repetitive movements at farm"
    },
    {
      "name": "X5070",
      "description": "Overexertion and strenuous or repetitive movements at farm While engaged in sports activity"
    },
    {
      "name": "X5071",
      "description": "Overexertion and strenuous or repetitive movements at farm While engaged in leisure activity"
    },
    {
      "name": "X5072",
      "description": "Overexertion and strenuous or repetitive movements at farm While working for income"
    },
    {
      "name": "X5073",
      "description": "Overexertion and strenuous or repetitive movements at farm While engaged in other types of work"
    },
    {
      "name": "X5074",
      "description": "Overexertion and strenuous or repetitive movements at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5078",
      "description": "Overexertion and strenuous or repetitive movements at farm While engaged in other specified activities"
    },
    {
      "name": "X5079",
      "description": "Overexertion and strenuous or repetitive movements at farm During unspecified activity"
    },
    {
      "name": "X508",
      "description": "Overexertion and strenuous or repetitive movements at other specified places"
    },
    {
      "name": "X5080",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While engaged in sports activity"
    },
    {
      "name": "X5081",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5082",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While working for income"
    },
    {
      "name": "X5083",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While engaged in other types of work"
    },
    {
      "name": "X5084",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5088",
      "description": "Overexertion and strenuous or repetitive movements at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5089",
      "description": "Overexertion and strenuous or repetitive movements at other specified places During unspecified activity"
    },
    {
      "name": "X509",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place"
    },
    {
      "name": "X5090",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5091",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5092",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While working for income"
    },
    {
      "name": "X5093",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5094",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5098",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5099",
      "description": "Overexertion and strenuous or repetitive movements at unspecified place During unspecified activity"
    },
    {
      "name": "X510",
      "description": "Travel and motion at home"
    },
    {
      "name": "X5100",
      "description": "Travel and motion at home While engaged in sports activity"
    },
    {
      "name": "X5101",
      "description": "Travel and motion at home While engaged in leisure activity"
    },
    {
      "name": "X5102",
      "description": "Travel and motion at home While working for income"
    },
    {
      "name": "X5103",
      "description": "Travel and motion at home While engaged in other types of work"
    },
    {
      "name": "X5104",
      "description": "Travel and motion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5108",
      "description": "Travel and motion at home While engaged in other specified activities"
    },
    {
      "name": "X5109",
      "description": "Travel and motion at home During unspecified activity"
    },
    {
      "name": "X511",
      "description": "Travel and motion at residential institution"
    },
    {
      "name": "X5110",
      "description": "Travel and motion at residential institution While engaged in sports activity"
    },
    {
      "name": "X5111",
      "description": "Travel and motion at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5112",
      "description": "Travel and motion at residential institution While working for income"
    },
    {
      "name": "X5113",
      "description": "Travel and motion at residential institution While engaged in other types of work"
    },
    {
      "name": "X5114",
      "description": "Travel and motion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5118",
      "description": "Travel and motion at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5119",
      "description": "Travel and motion at residential institution During unspecified activity"
    },
    {
      "name": "X512",
      "description": "Travel and motion at school,other institution and public administrative area"
    },
    {
      "name": "X5120",
      "description": "Travel and motion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5121",
      "description": "Travel and motion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5122",
      "description": "Travel and motion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5123",
      "description": "Travel and motion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5124",
      "description": "Travel and motion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5128",
      "description": "Travel and motion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5129",
      "description": "Travel and motion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X513",
      "description": "Travel and motion at sports and athletics area"
    },
    {
      "name": "X5130",
      "description": "Travel and motion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5131",
      "description": "Travel and motion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5132",
      "description": "Travel and motion at sports and athletics area While working for income"
    },
    {
      "name": "X5133",
      "description": "Travel and motion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5134",
      "description": "Travel and motion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5138",
      "description": "Travel and motion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5139",
      "description": "Travel and motion at sports and athletics area During unspecified activity"
    },
    {
      "name": "X514",
      "description": "Travel and motion at street and highway"
    },
    {
      "name": "X5140",
      "description": "Travel and motion at street and highway While engaged in sports activity"
    },
    {
      "name": "X5141",
      "description": "Travel and motion at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5142",
      "description": "Travel and motion at street and highway While working for income"
    },
    {
      "name": "X5143",
      "description": "Travel and motion at street and highway While engaged in other types of work"
    },
    {
      "name": "X5144",
      "description": "Travel and motion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5148",
      "description": "Travel and motion at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5149",
      "description": "Travel and motion at street and highway During unspecified activity"
    },
    {
      "name": "X515",
      "description": "Travel and motion at trade and service area"
    },
    {
      "name": "X5150",
      "description": "Travel and motion at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5151",
      "description": "Travel and motion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5152",
      "description": "Travel and motion at trade and service area While working for income"
    },
    {
      "name": "X5153",
      "description": "Travel and motion at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5154",
      "description": "Travel and motion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5158",
      "description": "Travel and motion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5159",
      "description": "Travel and motion at trade and service area During unspecified activity"
    },
    {
      "name": "X516",
      "description": "Travel and motion at industrial and construction area"
    },
    {
      "name": "X5160",
      "description": "Travel and motion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5161",
      "description": "Travel and motion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5162",
      "description": "Travel and motion at industrial and construction area While working for income"
    },
    {
      "name": "X5163",
      "description": "Travel and motion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5164",
      "description": "Travel and motion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5168",
      "description": "Travel and motion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5169",
      "description": "Travel and motion at industrial and construction area During unspecified activity"
    },
    {
      "name": "X517",
      "description": "Travel and motion at farm"
    },
    {
      "name": "X5170",
      "description": "Travel and motion at farm While engaged in sports activity"
    },
    {
      "name": "X5171",
      "description": "Travel and motion at farm While engaged in leisure activity"
    },
    {
      "name": "X5172",
      "description": "Travel and motion at farm While working for income"
    },
    {
      "name": "X5173",
      "description": "Travel and motion at farm While engaged in other types of work"
    },
    {
      "name": "X5174",
      "description": "Travel and motion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5178",
      "description": "Travel and motion at farm While engaged in other specified activities"
    },
    {
      "name": "X5179",
      "description": "Travel and motion at farm During unspecified activity"
    },
    {
      "name": "X518",
      "description": "Travel and motion at other specified places"
    },
    {
      "name": "X5180",
      "description": "Travel and motion at other specified places While engaged in sports activity"
    },
    {
      "name": "X5181",
      "description": "Travel and motion at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5182",
      "description": "Travel and motion at other specified places While working for income"
    },
    {
      "name": "X5183",
      "description": "Travel and motion at other specified places While engaged in other types of work"
    },
    {
      "name": "X5184",
      "description": "Travel and motion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5188",
      "description": "Travel and motion at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5189",
      "description": "Travel and motion at other specified places During unspecified activity"
    },
    {
      "name": "X519",
      "description": "Travel and motion at unspecified place"
    },
    {
      "name": "X5190",
      "description": "Travel and motion at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5191",
      "description": "Travel and motion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5192",
      "description": "Travel and motion at unspecified place While working for income"
    },
    {
      "name": "X5193",
      "description": "Travel and motion at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5194",
      "description": "Travel and motion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5198",
      "description": "Travel and motion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5199",
      "description": "Travel and motion at unspecified place During unspecified activity"
    },
    {
      "name": "X520",
      "description": "Prolonged stay in weightless environment at home"
    },
    {
      "name": "X5200",
      "description": "Prolonged stay in weightless environment at home While engaged in sports activity"
    },
    {
      "name": "X5201",
      "description": "Prolonged stay in weightless environment at home While engaged in leisure activity"
    },
    {
      "name": "X5202",
      "description": "Prolonged stay in weightless environment at home While working for income"
    },
    {
      "name": "X5203",
      "description": "Prolonged stay in weightless environment at home While engaged in other types of work"
    },
    {
      "name": "X5204",
      "description": "Prolonged stay in weightless environment at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5208",
      "description": "Prolonged stay in weightless environment at home While engaged in other specified activities"
    },
    {
      "name": "X5209",
      "description": "Prolonged stay in weightless environment at home During unspecified activity"
    },
    {
      "name": "X521",
      "description": "Prolonged stay in weightless environment at residential institution"
    },
    {
      "name": "X5210",
      "description": "Prolonged stay in weightless environment at residential institution While engaged in sports activity"
    },
    {
      "name": "X5211",
      "description": "Prolonged stay in weightless environment at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5212",
      "description": "Prolonged stay in weightless environment at residential institution While working for income"
    },
    {
      "name": "X5213",
      "description": "Prolonged stay in weightless environment at residential institution While engaged in other types of work"
    },
    {
      "name": "X5214",
      "description": "Prolonged stay in weightless environment at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5218",
      "description": "Prolonged stay in weightless environment at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5219",
      "description": "Prolonged stay in weightless environment at residential institution During unspecified activity"
    },
    {
      "name": "X522",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area"
    },
    {
      "name": "X5220",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5221",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5222",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5223",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5224",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5228",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5229",
      "description": "Prolonged stay in weightless environment at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X523",
      "description": "Prolonged stay in weightless environment at sports and athletics area"
    },
    {
      "name": "X5230",
      "description": "Prolonged stay in weightless environment at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5231",
      "description": "Prolonged stay in weightless environment at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5232",
      "description": "Prolonged stay in weightless environment at sports and athletics area While working for income"
    },
    {
      "name": "X5233",
      "description": "Prolonged stay in weightless environment at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5234",
      "description": "Prolonged stay in weightless environment at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5238",
      "description": "Prolonged stay in weightless environment at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5239",
      "description": "Prolonged stay in weightless environment at sports and athletics area During unspecified activity"
    },
    {
      "name": "X524",
      "description": "Prolonged stay in weightless environment at street and highway"
    },
    {
      "name": "X5240",
      "description": "Prolonged stay in weightless environment at street and highway While engaged in sports activity"
    },
    {
      "name": "X5241",
      "description": "Prolonged stay in weightless environment at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5242",
      "description": "Prolonged stay in weightless environment at street and highway While working for income"
    },
    {
      "name": "X5243",
      "description": "Prolonged stay in weightless environment at street and highway While engaged in other types of work"
    },
    {
      "name": "X5244",
      "description": "Prolonged stay in weightless environment at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5248",
      "description": "Prolonged stay in weightless environment at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5249",
      "description": "Prolonged stay in weightless environment at street and highway During unspecified activity"
    },
    {
      "name": "X525",
      "description": "Prolonged stay in weightless environment at trade and service area"
    },
    {
      "name": "X5250",
      "description": "Prolonged stay in weightless environment at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5251",
      "description": "Prolonged stay in weightless environment at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5252",
      "description": "Prolonged stay in weightless environment at trade and service area While working for income"
    },
    {
      "name": "X5253",
      "description": "Prolonged stay in weightless environment at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5254",
      "description": "Prolonged stay in weightless environment at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5258",
      "description": "Prolonged stay in weightless environment at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5259",
      "description": "Prolonged stay in weightless environment at trade and service area During unspecified activity"
    },
    {
      "name": "X526",
      "description": "Prolonged stay in weightless environment at industrial and construction area"
    },
    {
      "name": "X5260",
      "description": "Prolonged stay in weightless environment at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5261",
      "description": "Prolonged stay in weightless environment at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5262",
      "description": "Prolonged stay in weightless environment at industrial and construction area While working for income"
    },
    {
      "name": "X5263",
      "description": "Prolonged stay in weightless environment at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5264",
      "description": "Prolonged stay in weightless environment at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5268",
      "description": "Prolonged stay in weightless environment at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5269",
      "description": "Prolonged stay in weightless environment at industrial and construction area During unspecified activity"
    },
    {
      "name": "X527",
      "description": "Prolonged stay in weightless environment at farm"
    },
    {
      "name": "X5270",
      "description": "Prolonged stay in weightless environment at farm While engaged in sports activity"
    },
    {
      "name": "X5271",
      "description": "Prolonged stay in weightless environment at farm While engaged in leisure activity"
    },
    {
      "name": "X5272",
      "description": "Prolonged stay in weightless environment at farm While working for income"
    },
    {
      "name": "X5273",
      "description": "Prolonged stay in weightless environment at farm While engaged in other types of work"
    },
    {
      "name": "X5274",
      "description": "Prolonged stay in weightless environment at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5278",
      "description": "Prolonged stay in weightless environment at farm While engaged in other specified activities"
    },
    {
      "name": "X5279",
      "description": "Prolonged stay in weightless environment at farm During unspecified activity"
    },
    {
      "name": "X528",
      "description": "Prolonged stay in weightless environment at other specified places"
    },
    {
      "name": "X5280",
      "description": "Prolonged stay in weightless environment at other specified places While engaged in sports activity"
    },
    {
      "name": "X5281",
      "description": "Prolonged stay in weightless environment at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5282",
      "description": "Prolonged stay in weightless environment at other specified places While working for income"
    },
    {
      "name": "X5283",
      "description": "Prolonged stay in weightless environment at other specified places While engaged in other types of work"
    },
    {
      "name": "X5284",
      "description": "Prolonged stay in weightless environment at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5288",
      "description": "Prolonged stay in weightless environment at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5289",
      "description": "Prolonged stay in weightless environment at other specified places During unspecified activity"
    },
    {
      "name": "X529",
      "description": "Prolonged stay in weightless environment at unspecified place"
    },
    {
      "name": "X5290",
      "description": "Prolonged stay in weightless environment at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5291",
      "description": "Prolonged stay in weightless environment at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5292",
      "description": "Prolonged stay in weightless environment at unspecified place While working for income"
    },
    {
      "name": "X5293",
      "description": "Prolonged stay in weightless environment at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5294",
      "description": "Prolonged stay in weightless environment at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5298",
      "description": "Prolonged stay in weightless environment at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5299",
      "description": "Prolonged stay in weightless environment at unspecified place During unspecified activity"
    },
    {
      "name": "X530",
      "description": "Lack of food at home"
    },
    {
      "name": "X5300",
      "description": "Lack of food at home While engaged in sports activity"
    },
    {
      "name": "X5301",
      "description": "Lack of food at home While engaged in leisure activity"
    },
    {
      "name": "X5302",
      "description": "Lack of food at home While working for income"
    },
    {
      "name": "X5303",
      "description": "Lack of food at home While engaged in other types of work"
    },
    {
      "name": "X5304",
      "description": "Lack of food at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5308",
      "description": "Lack of food at home While engaged in other specified activities"
    },
    {
      "name": "X5309",
      "description": "Lack of food at home During unspecified activity"
    },
    {
      "name": "X531",
      "description": "Lack of food at residential institution"
    },
    {
      "name": "X5310",
      "description": "Lack of food at residential institution While engaged in sports activity"
    },
    {
      "name": "X5311",
      "description": "Lack of food at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5312",
      "description": "Lack of food at residential institution While working for income"
    },
    {
      "name": "X5313",
      "description": "Lack of food at residential institution While engaged in other types of work"
    },
    {
      "name": "X5314",
      "description": "Lack of food at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5318",
      "description": "Lack of food at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5319",
      "description": "Lack of food at residential institution During unspecified activity"
    },
    {
      "name": "X532",
      "description": "Lack of food at school,other institution and public administrative area"
    },
    {
      "name": "X5320",
      "description": "Lack of food at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5321",
      "description": "Lack of food at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5322",
      "description": "Lack of food at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5323",
      "description": "Lack of food at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5324",
      "description": "Lack of food at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5328",
      "description": "Lack of food at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5329",
      "description": "Lack of food at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X533",
      "description": "Lack of food at sports and athletics area"
    },
    {
      "name": "X5330",
      "description": "Lack of food at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5331",
      "description": "Lack of food at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5332",
      "description": "Lack of food at sports and athletics area While working for income"
    },
    {
      "name": "X5333",
      "description": "Lack of food at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5334",
      "description": "Lack of food at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5338",
      "description": "Lack of food at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5339",
      "description": "Lack of food at sports and athletics area During unspecified activity"
    },
    {
      "name": "X534",
      "description": "Lack of food at street and highway"
    },
    {
      "name": "X5340",
      "description": "Lack of food at street and highway While engaged in sports activity"
    },
    {
      "name": "X5341",
      "description": "Lack of food at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5342",
      "description": "Lack of food at street and highway While working for income"
    },
    {
      "name": "X5343",
      "description": "Lack of food at street and highway While engaged in other types of work"
    },
    {
      "name": "X5344",
      "description": "Lack of food at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5348",
      "description": "Lack of food at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5349",
      "description": "Lack of food at street and highway During unspecified activity"
    },
    {
      "name": "X535",
      "description": "Lack of food at trade and service area"
    },
    {
      "name": "X5350",
      "description": "Lack of food at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5351",
      "description": "Lack of food at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5352",
      "description": "Lack of food at trade and service area While working for income"
    },
    {
      "name": "X5353",
      "description": "Lack of food at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5354",
      "description": "Lack of food at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5358",
      "description": "Lack of food at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5359",
      "description": "Lack of food at trade and service area During unspecified activity"
    },
    {
      "name": "X536",
      "description": "Lack of food at industrial and construction area"
    },
    {
      "name": "X5360",
      "description": "Lack of food at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5361",
      "description": "Lack of food at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5362",
      "description": "Lack of food at industrial and construction area While working for income"
    },
    {
      "name": "X5363",
      "description": "Lack of food at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5364",
      "description": "Lack of food at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5368",
      "description": "Lack of food at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5369",
      "description": "Lack of food at industrial and construction area During unspecified activity"
    },
    {
      "name": "X537",
      "description": "Lack of food at farm"
    },
    {
      "name": "X5370",
      "description": "Lack of food at farm While engaged in sports activity"
    },
    {
      "name": "X5371",
      "description": "Lack of food at farm While engaged in leisure activity"
    },
    {
      "name": "X5372",
      "description": "Lack of food at farm While working for income"
    },
    {
      "name": "X5373",
      "description": "Lack of food at farm While engaged in other types of work"
    },
    {
      "name": "X5374",
      "description": "Lack of food at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5378",
      "description": "Lack of food at farm While engaged in other specified activities"
    },
    {
      "name": "X5379",
      "description": "Lack of food at farm During unspecified activity"
    },
    {
      "name": "X538",
      "description": "Lack of food at other specified places"
    },
    {
      "name": "X5380",
      "description": "Lack of food at other specified places While engaged in sports activity"
    },
    {
      "name": "X5381",
      "description": "Lack of food at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5382",
      "description": "Lack of food at other specified places While working for income"
    },
    {
      "name": "X5383",
      "description": "Lack of food at other specified places While engaged in other types of work"
    },
    {
      "name": "X5384",
      "description": "Lack of food at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5388",
      "description": "Lack of food at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5389",
      "description": "Lack of food at other specified places During unspecified activity"
    },
    {
      "name": "X539",
      "description": "Lack of food at unspecified place"
    },
    {
      "name": "X5390",
      "description": "Lack of food at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5391",
      "description": "Lack of food at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5392",
      "description": "Lack of food at unspecified place While working for income"
    },
    {
      "name": "X5393",
      "description": "Lack of food at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5394",
      "description": "Lack of food at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5398",
      "description": "Lack of food at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5399",
      "description": "Lack of food at unspecified place During unspecified activity"
    },
    {
      "name": "X540",
      "description": "Lack of water at home"
    },
    {
      "name": "X5400",
      "description": "Lack of water at home While engaged in sports activity"
    },
    {
      "name": "X5401",
      "description": "Lack of water at home While engaged in leisure activity"
    },
    {
      "name": "X5402",
      "description": "Lack of water at home While working for income"
    },
    {
      "name": "X5403",
      "description": "Lack of water at home While engaged in other types of work"
    },
    {
      "name": "X5404",
      "description": "Lack of water at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5408",
      "description": "Lack of water at home While engaged in other specified activities"
    },
    {
      "name": "X5409",
      "description": "Lack of water at home During unspecified activity"
    },
    {
      "name": "X541",
      "description": "Lack of water at residential institution"
    },
    {
      "name": "X5410",
      "description": "Lack of water at residential institution While engaged in sports activity"
    },
    {
      "name": "X5411",
      "description": "Lack of water at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5412",
      "description": "Lack of water at residential institution While working for income"
    },
    {
      "name": "X5413",
      "description": "Lack of water at residential institution While engaged in other types of work"
    },
    {
      "name": "X5414",
      "description": "Lack of water at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5418",
      "description": "Lack of water at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5419",
      "description": "Lack of water at residential institution During unspecified activity"
    },
    {
      "name": "X542",
      "description": "Lack of water at school,other institution and public administrative area"
    },
    {
      "name": "X5420",
      "description": "Lack of water at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5421",
      "description": "Lack of water at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5422",
      "description": "Lack of water at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5423",
      "description": "Lack of water at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5424",
      "description": "Lack of water at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5428",
      "description": "Lack of water at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5429",
      "description": "Lack of water at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X543",
      "description": "Lack of water at sports and athletics area"
    },
    {
      "name": "X5430",
      "description": "Lack of water at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5431",
      "description": "Lack of water at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5432",
      "description": "Lack of water at sports and athletics area While working for income"
    },
    {
      "name": "X5433",
      "description": "Lack of water at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5434",
      "description": "Lack of water at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5438",
      "description": "Lack of water at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5439",
      "description": "Lack of water at sports and athletics area During unspecified activity"
    },
    {
      "name": "X544",
      "description": "Lack of water at street and highway"
    },
    {
      "name": "X5440",
      "description": "Lack of water at street and highway While engaged in sports activity"
    },
    {
      "name": "X5441",
      "description": "Lack of water at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5442",
      "description": "Lack of water at street and highway While working for income"
    },
    {
      "name": "X5443",
      "description": "Lack of water at street and highway While engaged in other types of work"
    },
    {
      "name": "X5444",
      "description": "Lack of water at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5448",
      "description": "Lack of water at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5449",
      "description": "Lack of water at street and highway During unspecified activity"
    },
    {
      "name": "X545",
      "description": "Lack of water at trade and service area"
    },
    {
      "name": "X5450",
      "description": "Lack of water at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5451",
      "description": "Lack of water at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5452",
      "description": "Lack of water at trade and service area While working for income"
    },
    {
      "name": "X5453",
      "description": "Lack of water at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5454",
      "description": "Lack of water at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5458",
      "description": "Lack of water at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5459",
      "description": "Lack of water at trade and service area During unspecified activity"
    },
    {
      "name": "X546",
      "description": "Lack of water at industrial and construction area"
    },
    {
      "name": "X5460",
      "description": "Lack of water at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5461",
      "description": "Lack of water at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5462",
      "description": "Lack of water at industrial and construction area While working for income"
    },
    {
      "name": "X5463",
      "description": "Lack of water at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5464",
      "description": "Lack of water at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5468",
      "description": "Lack of water at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5469",
      "description": "Lack of water at industrial and construction area During unspecified activity"
    },
    {
      "name": "X547",
      "description": "Lack of water at farm"
    },
    {
      "name": "X5470",
      "description": "Lack of water at farm While engaged in sports activity"
    },
    {
      "name": "X5471",
      "description": "Lack of water at farm While engaged in leisure activity"
    },
    {
      "name": "X5472",
      "description": "Lack of water at farm While working for income"
    },
    {
      "name": "X5473",
      "description": "Lack of water at farm While engaged in other types of work"
    },
    {
      "name": "X5474",
      "description": "Lack of water at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5478",
      "description": "Lack of water at farm While engaged in other specified activities"
    },
    {
      "name": "X5479",
      "description": "Lack of water at farm During unspecified activity"
    },
    {
      "name": "X548",
      "description": "Lack of water at other specified places"
    },
    {
      "name": "X5480",
      "description": "Lack of water at other specified places While engaged in sports activity"
    },
    {
      "name": "X5481",
      "description": "Lack of water at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5482",
      "description": "Lack of water at other specified places While working for income"
    },
    {
      "name": "X5483",
      "description": "Lack of water at other specified places While engaged in other types of work"
    },
    {
      "name": "X5484",
      "description": "Lack of water at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5488",
      "description": "Lack of water at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5489",
      "description": "Lack of water at other specified places During unspecified activity"
    },
    {
      "name": "X549",
      "description": "Lack of water at unspecified place"
    },
    {
      "name": "X5490",
      "description": "Lack of water at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5491",
      "description": "Lack of water at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5492",
      "description": "Lack of water at unspecified place While working for income"
    },
    {
      "name": "X5493",
      "description": "Lack of water at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5494",
      "description": "Lack of water at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5498",
      "description": "Lack of water at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5499",
      "description": "Lack of water at unspecified place During unspecified activity"
    },
    {
      "name": "X570",
      "description": "Unspecified privation at home"
    },
    {
      "name": "X5700",
      "description": "Unspecified privation at home While engaged in sports activity"
    },
    {
      "name": "X5701",
      "description": "Unspecified privation at home While engaged in leisure activity"
    },
    {
      "name": "X5702",
      "description": "Unspecified privation at home While working for income"
    },
    {
      "name": "X5703",
      "description": "Unspecified privation at home While engaged in other types of work"
    },
    {
      "name": "X5704",
      "description": "Unspecified privation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5708",
      "description": "Unspecified privation at home While engaged in other specified activities"
    },
    {
      "name": "X5709",
      "description": "Unspecified privation at home During unspecified activity"
    },
    {
      "name": "X571",
      "description": "Unspecified privation at residential institution"
    },
    {
      "name": "X5710",
      "description": "Unspecified privation at residential institution While engaged in sports activity"
    },
    {
      "name": "X5711",
      "description": "Unspecified privation at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5712",
      "description": "Unspecified privation at residential institution While working for income"
    },
    {
      "name": "X5713",
      "description": "Unspecified privation at residential institution While engaged in other types of work"
    },
    {
      "name": "X5714",
      "description": "Unspecified privation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5718",
      "description": "Unspecified privation at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5719",
      "description": "Unspecified privation at residential institution During unspecified activity"
    },
    {
      "name": "X572",
      "description": "Unspecified privation at school,other institution and public administrative area"
    },
    {
      "name": "X5720",
      "description": "Unspecified privation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5721",
      "description": "Unspecified privation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5722",
      "description": "Unspecified privation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5723",
      "description": "Unspecified privation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5724",
      "description": "Unspecified privation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5728",
      "description": "Unspecified privation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5729",
      "description": "Unspecified privation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X573",
      "description": "Unspecified privation at sports and athletics area"
    },
    {
      "name": "X5730",
      "description": "Unspecified privation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5731",
      "description": "Unspecified privation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5732",
      "description": "Unspecified privation at sports and athletics area While working for income"
    },
    {
      "name": "X5733",
      "description": "Unspecified privation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5734",
      "description": "Unspecified privation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5738",
      "description": "Unspecified privation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5739",
      "description": "Unspecified privation at sports and athletics area During unspecified activity"
    },
    {
      "name": "X574",
      "description": "Unspecified privation at street and highway"
    },
    {
      "name": "X5740",
      "description": "Unspecified privation at street and highway While engaged in sports activity"
    },
    {
      "name": "X5741",
      "description": "Unspecified privation at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5742",
      "description": "Unspecified privation at street and highway While working for income"
    },
    {
      "name": "X5743",
      "description": "Unspecified privation at street and highway While engaged in other types of work"
    },
    {
      "name": "X5744",
      "description": "Unspecified privation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5748",
      "description": "Unspecified privation at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5749",
      "description": "Unspecified privation at street and highway During unspecified activity"
    },
    {
      "name": "X575",
      "description": "Unspecified privation at trade and service area"
    },
    {
      "name": "X5750",
      "description": "Unspecified privation at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5751",
      "description": "Unspecified privation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5752",
      "description": "Unspecified privation at trade and service area While working for income"
    },
    {
      "name": "X5753",
      "description": "Unspecified privation at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5754",
      "description": "Unspecified privation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5758",
      "description": "Unspecified privation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5759",
      "description": "Unspecified privation at trade and service area During unspecified activity"
    },
    {
      "name": "X576",
      "description": "Unspecified privation at industrial and construction area"
    },
    {
      "name": "X5760",
      "description": "Unspecified privation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5761",
      "description": "Unspecified privation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5762",
      "description": "Unspecified privation at industrial and construction area While working for income"
    },
    {
      "name": "X5763",
      "description": "Unspecified privation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5764",
      "description": "Unspecified privation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5768",
      "description": "Unspecified privation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5769",
      "description": "Unspecified privation at industrial and construction area During unspecified activity"
    },
    {
      "name": "X577",
      "description": "Unspecified privation at farm"
    },
    {
      "name": "X5770",
      "description": "Unspecified privation at farm While engaged in sports activity"
    },
    {
      "name": "X5771",
      "description": "Unspecified privation at farm While engaged in leisure activity"
    },
    {
      "name": "X5772",
      "description": "Unspecified privation at farm While working for income"
    },
    {
      "name": "X5773",
      "description": "Unspecified privation at farm While engaged in other types of work"
    },
    {
      "name": "X5774",
      "description": "Unspecified privation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5778",
      "description": "Unspecified privation at farm While engaged in other specified activities"
    },
    {
      "name": "X5779",
      "description": "Unspecified privation at farm During unspecified activity"
    },
    {
      "name": "X578",
      "description": "Unspecified privation at other specified places"
    },
    {
      "name": "X5780",
      "description": "Unspecified privation at other specified places While engaged in sports activity"
    },
    {
      "name": "X5781",
      "description": "Unspecified privation at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5782",
      "description": "Unspecified privation at other specified places While working for income"
    },
    {
      "name": "X5783",
      "description": "Unspecified privation at other specified places While engaged in other types of work"
    },
    {
      "name": "X5784",
      "description": "Unspecified privation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5788",
      "description": "Unspecified privation at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5789",
      "description": "Unspecified privation at other specified places During unspecified activity"
    },
    {
      "name": "X579",
      "description": "Unspecified privation at unspecified place"
    },
    {
      "name": "X5790",
      "description": "Unspecified privation at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5791",
      "description": "Unspecified privation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5792",
      "description": "Unspecified privation at unspecified place While working for income"
    },
    {
      "name": "X5793",
      "description": "Unspecified privation at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5794",
      "description": "Unspecified privation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5798",
      "description": "Unspecified privation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5799",
      "description": "Unspecified privation at unspecified place During unspecified activity"
    },
    {
      "name": "X580",
      "description": "Exposure to other specified factors at home"
    },
    {
      "name": "X5800",
      "description": "Exposure to other specified factors at home While engaged in sports activity"
    },
    {
      "name": "X5801",
      "description": "Exposure to other specified factors at home While engaged in leisure activity"
    },
    {
      "name": "X5802",
      "description": "Exposure to other specified factors at home While working for income"
    },
    {
      "name": "X5803",
      "description": "Exposure to other specified factors at home While engaged in other types of work"
    },
    {
      "name": "X5804",
      "description": "Exposure to other specified factors at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5808",
      "description": "Exposure to other specified factors at home While engaged in other specified activities"
    },
    {
      "name": "X5809",
      "description": "Exposure to other specified factors at home During unspecified activity"
    },
    {
      "name": "X581",
      "description": "Exposure to other specified factors at residential institution"
    },
    {
      "name": "X5810",
      "description": "Exposure to other specified factors at residential institution While engaged in sports activity"
    },
    {
      "name": "X5811",
      "description": "Exposure to other specified factors at residential institution While engaged in leisure activity"
    },
    {
      "name": "X5812",
      "description": "Exposure to other specified factors at residential institution While working for income"
    },
    {
      "name": "X5813",
      "description": "Exposure to other specified factors at residential institution While engaged in other types of work"
    },
    {
      "name": "X5814",
      "description": "Exposure to other specified factors at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5818",
      "description": "Exposure to other specified factors at residential institution While engaged in other specified activities"
    },
    {
      "name": "X5819",
      "description": "Exposure to other specified factors at residential institution During unspecified activity"
    },
    {
      "name": "X582",
      "description": "Exposure to other specified factors at school,other institution and public administrative area"
    },
    {
      "name": "X5820",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X5821",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X5822",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X5823",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X5824",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5828",
      "description": "Exposure to other specified factors at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X5829",
      "description": "Exposure to other specified factors at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X583",
      "description": "Exposure to other specified factors at sports and athletics area"
    },
    {
      "name": "X5830",
      "description": "Exposure to other specified factors at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X5831",
      "description": "Exposure to other specified factors at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X5832",
      "description": "Exposure to other specified factors at sports and athletics area While working for income"
    },
    {
      "name": "X5833",
      "description": "Exposure to other specified factors at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X5834",
      "description": "Exposure to other specified factors at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5838",
      "description": "Exposure to other specified factors at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X5839",
      "description": "Exposure to other specified factors at sports and athletics area During unspecified activity"
    },
    {
      "name": "X584",
      "description": "Exposure to other specified factors at street and highway"
    },
    {
      "name": "X5840",
      "description": "Exposure to other specified factors at street and highway While engaged in sports activity"
    },
    {
      "name": "X5841",
      "description": "Exposure to other specified factors at street and highway While engaged in leisure activity"
    },
    {
      "name": "X5842",
      "description": "Exposure to other specified factors at street and highway While working for income"
    },
    {
      "name": "X5843",
      "description": "Exposure to other specified factors at street and highway While engaged in other types of work"
    },
    {
      "name": "X5844",
      "description": "Exposure to other specified factors at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5848",
      "description": "Exposure to other specified factors at street and highway While engaged in other specified activities"
    },
    {
      "name": "X5849",
      "description": "Exposure to other specified factors at street and highway During unspecified activity"
    },
    {
      "name": "X585",
      "description": "Exposure to other specified factors at trade and service area"
    },
    {
      "name": "X5850",
      "description": "Exposure to other specified factors at trade and service area While engaged in sports activity"
    },
    {
      "name": "X5851",
      "description": "Exposure to other specified factors at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X5852",
      "description": "Exposure to other specified factors at trade and service area While working for income"
    },
    {
      "name": "X5853",
      "description": "Exposure to other specified factors at trade and service area While engaged in other types of work"
    },
    {
      "name": "X5854",
      "description": "Exposure to other specified factors at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5858",
      "description": "Exposure to other specified factors at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X5859",
      "description": "Exposure to other specified factors at trade and service area During unspecified activity"
    },
    {
      "name": "X586",
      "description": "Exposure to other specified factors at industrial and construction area"
    },
    {
      "name": "X5860",
      "description": "Exposure to other specified factors at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X5861",
      "description": "Exposure to other specified factors at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X5862",
      "description": "Exposure to other specified factors at industrial and construction area While working for income"
    },
    {
      "name": "X5863",
      "description": "Exposure to other specified factors at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X5864",
      "description": "Exposure to other specified factors at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5868",
      "description": "Exposure to other specified factors at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X5869",
      "description": "Exposure to other specified factors at industrial and construction area During unspecified activity"
    },
    {
      "name": "X587",
      "description": "Exposure to other specified factors at farm"
    },
    {
      "name": "X5870",
      "description": "Exposure to other specified factors at farm While engaged in sports activity"
    },
    {
      "name": "X5871",
      "description": "Exposure to other specified factors at farm While engaged in leisure activity"
    },
    {
      "name": "X5872",
      "description": "Exposure to other specified factors at farm While working for income"
    },
    {
      "name": "X5873",
      "description": "Exposure to other specified factors at farm While engaged in other types of work"
    },
    {
      "name": "X5874",
      "description": "Exposure to other specified factors at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5878",
      "description": "Exposure to other specified factors at farm While engaged in other specified activities"
    },
    {
      "name": "X5879",
      "description": "Exposure to other specified factors at farm During unspecified activity"
    },
    {
      "name": "X588",
      "description": "Exposure to other specified factors at other specified places"
    },
    {
      "name": "X5880",
      "description": "Exposure to other specified factors at other specified places While engaged in sports activity"
    },
    {
      "name": "X5881",
      "description": "Exposure to other specified factors at other specified places While engaged in leisure activity"
    },
    {
      "name": "X5882",
      "description": "Exposure to other specified factors at other specified places While working for income"
    },
    {
      "name": "X5883",
      "description": "Exposure to other specified factors at other specified places While engaged in other types of work"
    },
    {
      "name": "X5884",
      "description": "Exposure to other specified factors at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5888",
      "description": "Exposure to other specified factors at other specified places While engaged in other specified activities"
    },
    {
      "name": "X5889",
      "description": "Exposure to other specified factors at other specified places During unspecified activity"
    },
    {
      "name": "X589",
      "description": "Exposure to other specified factors at unspecified place"
    },
    {
      "name": "X5890",
      "description": "Exposure to other specified factors at unspecified place While engaged in sports activity"
    },
    {
      "name": "X5891",
      "description": "Exposure to other specified factors at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X5892",
      "description": "Exposure to other specified factors at unspecified place While working for income"
    },
    {
      "name": "X5893",
      "description": "Exposure to other specified factors at unspecified place While engaged in other types of work"
    },
    {
      "name": "X5894",
      "description": "Exposure to other specified factors at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X5898",
      "description": "Exposure to other specified factors at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X5899",
      "description": "Exposure to other specified factors at unspecified place During unspecified activity"
    },
    {
      "name": "X590",
      "description": "Exposure to unspecified factor causing fracture causing fracture"
    },
    {
      "name": "X5900",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home "
    },
    {
      "name": "X59001",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home While engaged in leisure activity"
    },
    {
      "name": "X59002",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home While working for income"
    },
    {
      "name": "X59003",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home While engaged in other types of work"
    },
    {
      "name": "X59004",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59008",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home While engaged in other specified activities"
    },
    {
      "name": "X59009",
      "description": "Exposure to unspecified factor causing fracture causing fracture at home During unspecified activity"
    },
    {
      "name": "X5901",
      "description": "Exposure to unspecified factor causing fracture at residential institution"
    },
    {
      "name": "X59010",
      "description": "Exposure to unspecified factor causing fracture at residential institution While engaged in sports activity"
    },
    {
      "name": "X59011",
      "description": "Exposure to unspecified factor causing fracture at residential institution While engaged in leisure activity"
    },
    {
      "name": "X59012",
      "description": "Exposure to unspecified factor causing fracture at residential institution While working for income"
    },
    {
      "name": "X59013",
      "description": "Exposure to unspecified factor causing fracture at residential institution While engaged in other types of work"
    },
    {
      "name": "X59014",
      "description": "Exposure to unspecified factor causing fracture at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59018",
      "description": "Exposure to unspecified factor causing fracture at residential institution While engaged in other specified activities"
    },
    {
      "name": "X59019",
      "description": "Exposure to unspecified factor causing fracture at residential institution During unspecified activity"
    },
    {
      "name": "X5902",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area"
    },
    {
      "name": "X59020",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X59021",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X59022",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X59023",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X59024",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59028",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X59029",
      "description": "Exposure to unspecified factor causing fracture at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X5903",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area"
    },
    {
      "name": "X59030",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X59031",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X59032",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While working for income"
    },
    {
      "name": "X59033",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X59034",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59038",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X59039",
      "description": "Exposure to unspecified factor causing fracture at sports and athletics area During unspecified activity"
    },
    {
      "name": "X5904",
      "description": "Exposure to unspecified factor causing fracture at street and highway"
    },
    {
      "name": "X59040",
      "description": "Exposure to unspecified factor causing fracture at street and highway While engaged in sports activity"
    },
    {
      "name": "X59041",
      "description": "Exposure to unspecified factor causing fracture at street and highway While engaged in leisure activity"
    },
    {
      "name": "X59042",
      "description": "Exposure to unspecified factor causing fracture at street and highway While working for income"
    },
    {
      "name": "X59043",
      "description": "Exposure to unspecified factor causing fracture at street and highway While engaged in other types of work"
    },
    {
      "name": "X59044",
      "description": "Exposure to unspecified factor causing fracture at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59048",
      "description": "Exposure to unspecified factor causing fracture at street and highway While engaged in other specified activities"
    },
    {
      "name": "X59049",
      "description": "Exposure to unspecified factor causing fracture at street and highway During unspecified activity"
    },
    {
      "name": "X5905",
      "description": "Exposure to unspecified factor causing fracture at trade and service area"
    },
    {
      "name": "X59050",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While engaged in sports activity"
    },
    {
      "name": "X59051",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X59052",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While working for income"
    },
    {
      "name": "X59053",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While engaged in other types of work"
    },
    {
      "name": "X59054",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59058",
      "description": "Exposure to unspecified factor causing fracture at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X59059",
      "description": "Exposure to unspecified factor causing fracture at trade and service area During unspecified activity"
    },
    {
      "name": "X5906",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area"
    },
    {
      "name": "X59060",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X59061",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X59062",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While working for income"
    },
    {
      "name": "X59063",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X59064",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59068",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X59069",
      "description": "Exposure to unspecified factor causing fracture at industrial and construction area During unspecified activity"
    },
    {
      "name": "X5907",
      "description": "Exposure to unspecified factor causing fracture at farm"
    },
    {
      "name": "X59070",
      "description": "Exposure to unspecified factor causing fracture at farm While engaged in sports activity"
    },
    {
      "name": "X59071",
      "description": "Exposure to unspecified factor causing fracture at farm While engaged in leisure activity"
    },
    {
      "name": "X59072",
      "description": "Exposure to unspecified factor causing fracture at farm While working for income"
    },
    {
      "name": "X59073",
      "description": "Exposure to unspecified factor causing fracture at farm While engaged in other types of work"
    },
    {
      "name": "X59074",
      "description": "Exposure to unspecified factor causing fracture at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59078",
      "description": "Exposure to unspecified factor causing fracture at farm While engaged in other specified activities"
    },
    {
      "name": "X59079",
      "description": "Exposure to unspecified factor causing fracture at farm During unspecified activity"
    },
    {
      "name": "X5908",
      "description": "Exposure to unspecified factor causing fracture at other specified places"
    },
    {
      "name": "X59080",
      "description": "Exposure to unspecified factor causing fracture at other specified places While engaged in sports activity"
    },
    {
      "name": "X59081",
      "description": "Exposure to unspecified factor causing fracture at other specified places While engaged in leisure activity"
    },
    {
      "name": "X59082",
      "description": "Exposure to unspecified factor causing fracture at other specified places While working for income"
    },
    {
      "name": "X59083",
      "description": "Exposure to unspecified factor causing fracture at other specified places While engaged in other types of work"
    },
    {
      "name": "X59084",
      "description": "Exposure to unspecified factor causing fracture at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59088",
      "description": "Exposure to unspecified factor causing fracture at other specified places While engaged in other specified activities"
    },
    {
      "name": "X59089",
      "description": "Exposure to unspecified factor causing fracture at other specified places During unspecified activity"
    },
    {
      "name": "X599",
      "description": "Exposure to unspecified factor causing other and unspecified injury"
    },
    {
      "name": "X5990",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home"
    },
    {
      "name": "X59900",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While engaged in sports activity"
    },
    {
      "name": "X59901",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While engaged in leisure activity"
    },
    {
      "name": "X59902",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While working for income"
    },
    {
      "name": "X59903",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While engaged in other types of work"
    },
    {
      "name": "X59904",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59908",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home While engaged in other specified activities"
    },
    {
      "name": "X59909",
      "description": "Exposure to unspecified factor causing other and unspecified injury at home During unspecified activity"
    },
    {
      "name": "X5991",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution"
    },
    {
      "name": "X59910",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While engaged in sports activity"
    },
    {
      "name": "X59911",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While engaged in leisure activity"
    },
    {
      "name": "X59912",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While working for income"
    },
    {
      "name": "X59913",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While engaged in other types of work"
    },
    {
      "name": "X59914",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59918",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution While engaged in other specified activities"
    },
    {
      "name": "X59919",
      "description": "Exposure to unspecified factor causing other and unspecified injury at residential institution During unspecified activity"
    },
    {
      "name": "X5992",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area"
    },
    {
      "name": "X59920",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X59921",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X59922",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While working for income"
    },
    {
      "name": "X59923",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X59924",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59928",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X59929",
      "description": "Exposure to unspecified factor causing other and unspecified injury at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X5993",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area"
    },
    {
      "name": "X59930",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X59931",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X59932",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While working for income"
    },
    {
      "name": "X59933",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X59934",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59938",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X59939",
      "description": "Exposure to unspecified factor causing other and unspecified injury at sports and athletics area During unspecified activity"
    },
    {
      "name": "X5994",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway"
    },
    {
      "name": "X59940",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While engaged in sports activity"
    },
    {
      "name": "X59941",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While engaged in leisure activity"
    },
    {
      "name": "X59942",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While working for income"
    },
    {
      "name": "X59943",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While engaged in other types of work"
    },
    {
      "name": "X59944",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59948",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway While engaged in other specified activities"
    },
    {
      "name": "X59949",
      "description": "Exposure to unspecified factor causing other and unspecified injury at street and highway During unspecified activity"
    },
    {
      "name": "X5995",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area"
    },
    {
      "name": "X59950",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While engaged in sports activity"
    },
    {
      "name": "X59951",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X59952",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While working for income"
    },
    {
      "name": "X59953",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While engaged in other types of work"
    },
    {
      "name": "X59954",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59958",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X59959",
      "description": "Exposure to unspecified factor causing other and unspecified injury at trade and service area During unspecified activity"
    },
    {
      "name": "X5996",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area"
    },
    {
      "name": "X59960",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X59961",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X59962",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While working for income"
    },
    {
      "name": "X59963",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X59964",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59968",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X59969",
      "description": "Exposure to unspecified factor causing other and unspecified injury at industrial and construction area During unspecified activity"
    },
    {
      "name": "X5997",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm"
    },
    {
      "name": "X59970",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While engaged in sports activity"
    },
    {
      "name": "X59971",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While engaged in leisure activity"
    },
    {
      "name": "X59972",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While working for income"
    },
    {
      "name": "X59973",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While engaged in other types of work"
    },
    {
      "name": "X59974",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59978",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm While engaged in other specified activities"
    },
    {
      "name": "X59979",
      "description": "Exposure to unspecified factor causing other and unspecified injury at farm During unspecified activity"
    },
    {
      "name": "X5998",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places"
    },
    {
      "name": "X59980",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While engaged in sports activity"
    },
    {
      "name": "X59981",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While engaged in leisure activity"
    },
    {
      "name": "X59982",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While working for income"
    },
    {
      "name": "X59983",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While engaged in other types of work"
    },
    {
      "name": "X59984",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59988",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places While engaged in other specified activities"
    },
    {
      "name": "X59989",
      "description": "Exposure to unspecified factor causing other and unspecified injury at other specified places During unspecified activity"
    },
    {
      "name": "X5999",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place"
    },
    {
      "name": "X59990",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While engaged in sports activity"
    },
    {
      "name": "X59991",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X59992",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While working for income"
    },
    {
      "name": "X59993",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While engaged in other types of work"
    },
    {
      "name": "X59994",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X59998",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X59999",
      "description": "Exposure to unspecified factor causing other and unspecified injury at unspecified place During unspecified activity"
    },
    {
      "name": "X600",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home"
    },
    {
      "name": "X6000",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in sports activity"
    },
    {
      "name": "X6001",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in leisure activity"
    },
    {
      "name": "X6002",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While working for income"
    },
    {
      "name": "X6003",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in other types of work"
    },
    {
      "name": "X6004",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6008",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home While engaged in other specified activities"
    },
    {
      "name": "X6009",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at home During unspecified activity"
    },
    {
      "name": "X601",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution"
    },
    {
      "name": "X6010",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in sports activity"
    },
    {
      "name": "X6011",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6012",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While working for income"
    },
    {
      "name": "X6013",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in other types of work"
    },
    {
      "name": "X6014",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6018",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6019",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at residential institution During unspecified activity"
    },
    {
      "name": "X602",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area"
    },
    {
      "name": "X6020",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6021",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6022",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6023",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6024",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6028",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6029",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X603",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area"
    },
    {
      "name": "X6030",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in sports activity "
    },
    {
      "name": "X6031",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6032",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While working for income"
    },
    {
      "name": "X6033",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6034",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6038",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6039",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at sports and athletics area During unspecified activity"
    },
    {
      "name": "X604",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway"
    },
    {
      "name": "X6040",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in sports activity"
    },
    {
      "name": "X6041",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6042",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While working for income"
    },
    {
      "name": "X6043",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in other types of work"
    },
    {
      "name": "X6044",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6048",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6049",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at street and highway During unspecified activity"
    },
    {
      "name": "X605",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area"
    },
    {
      "name": "X6050",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6051",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6052",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While working for income"
    },
    {
      "name": "X6053",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6054",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6058",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6059",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at trade and service area During unspecified activity"
    },
    {
      "name": "X606",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area"
    },
    {
      "name": "X6060",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6061",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6062",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While working for income"
    },
    {
      "name": "X6063",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6064",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6068",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6069",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at industrial and construction area During unspecified activity"
    },
    {
      "name": "X607",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm"
    },
    {
      "name": "X6070",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in sports activity"
    },
    {
      "name": "X6071",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in leisure activity"
    },
    {
      "name": "X6072",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While working for income"
    },
    {
      "name": "X6073",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in other types of work"
    },
    {
      "name": "X6074",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6078",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm While engaged in other specified activities"
    },
    {
      "name": "X6079",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at farm During unspecified activity"
    },
    {
      "name": "X608",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places"
    },
    {
      "name": "X6080",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in sports activity"
    },
    {
      "name": "X6081",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6082",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While working for income"
    },
    {
      "name": "X6083",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in other types of work"
    },
    {
      "name": "X6084",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6088",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6089",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at other specified places During unspecified activity"
    },
    {
      "name": "X609",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place"
    },
    {
      "name": "X6090",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6091",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6092",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While working for income"
    },
    {
      "name": "X6093",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6094",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6098",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6099",
      "description": "Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics at unspecified place During unspecified activity"
    },
    {
      "name": "X610",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home"
    },
    {
      "name": "X6100",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in sports activity"
    },
    {
      "name": "X6101",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in leisure activity"
    },
    {
      "name": "X6102",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While working for income"
    },
    {
      "name": "X6103",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in other types of work"
    },
    {
      "name": "X6104",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6108",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home While engaged in other specified activities"
    },
    {
      "name": "X6109",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at home During unspecified activity"
    },
    {
      "name": "X611",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution"
    },
    {
      "name": "X6110",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in sports activity"
    },
    {
      "name": "X6111",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution  While engaged in leisure activity"
    },
    {
      "name": "X6112",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While working for income"
    },
    {
      "name": "X6113",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in other types of work"
    },
    {
      "name": "X6114",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6118",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6119",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at residential institution During unspecified activity"
    },
    {
      "name": "X612",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area"
    },
    {
      "name": "X6120",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6121",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6122",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6123",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6124",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While resting, sleeping, eating or engaging in othe"
    },
    {
      "name": "X6128",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6129",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X613",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area"
    },
    {
      "name": "X6130",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6131",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6132",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While working for income"
    },
    {
      "name": "X6133",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6134",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6138",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6139",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at sports and athletics area During unspecified activity"
    },
    {
      "name": "X614",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway"
    },
    {
      "name": "X6140",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in sports activity"
    },
    {
      "name": "X6141",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6142",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While working for income"
    },
    {
      "name": "X6143",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in other types of work"
    },
    {
      "name": "X6144",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6148",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6149",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at street and highway During unspecified activity"
    },
    {
      "name": "X615",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area"
    },
    {
      "name": "X6150",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6151",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6152",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While working for income"
    },
    {
      "name": "X6153",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6154",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6158",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6159",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at trade and service area During unspecified activity"
    },
    {
      "name": "X616",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area"
    },
    {
      "name": "X6160",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6161",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6162",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While working for income"
    },
    {
      "name": "X6163",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6164",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6168",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6169",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at industrial and construction area During unspecified activity"
    },
    {
      "name": "X617",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm"
    },
    {
      "name": "X6170",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in sports activity"
    },
    {
      "name": "X6171",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in leisure activity"
    },
    {
      "name": "X6172",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While working for income"
    },
    {
      "name": "X6173",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in other types of work"
    },
    {
      "name": "X6174",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6178",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm While engaged in other specified activities"
    },
    {
      "name": "X6179",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at farm During unspecified activity"
    },
    {
      "name": "X618",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places"
    },
    {
      "name": "X6180",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in sports activity"
    },
    {
      "name": "X6181",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6182",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While working for income"
    },
    {
      "name": "X6183",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in other types of work"
    },
    {
      "name": "X6184",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6188",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6189",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at other specified places During unspecified activity"
    },
    {
      "name": "X619",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place"
    },
    {
      "name": "X6190",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6191",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6192",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While working for income"
    },
    {
      "name": "X6193",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6194",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6198",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6199",
      "description": "Intentional self-poisoning by and exposure to antiepileptic,sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified at unspecified place During unspecified activity"
    },
    {
      "name": "X620",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home"
    },
    {
      "name": "X6200",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in sports activity"
    },
    {
      "name": "X6201",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in leisure activity"
    },
    {
      "name": "X6202",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While working for income"
    },
    {
      "name": "X6203",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in other types of work"
    },
    {
      "name": "X6204",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6208",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home While engaged in other specified activities"
    },
    {
      "name": "X6209",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at home During unspecified activity"
    },
    {
      "name": "X621",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution"
    },
    {
      "name": "X6210",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in sports activity"
    },
    {
      "name": "X6211",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6212",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While working for income"
    },
    {
      "name": "X6213",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in other types of work"
    },
    {
      "name": "X6214",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6218",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6219",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at residential institution During unspecified activity"
    },
    {
      "name": "X622",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area"
    },
    {
      "name": "X6220",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6221",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6222",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6223",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6224",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6228",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6229",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X623",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area"
    },
    {
      "name": "X6230",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6231",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6232",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While working for income"
    },
    {
      "name": "X6233",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6234",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6238",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6239",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at sports and athletics area During unspecified activity"
    },
    {
      "name": "X624",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway"
    },
    {
      "name": "X6240",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in sports activity"
    },
    {
      "name": "X6241",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6242",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While working for income"
    },
    {
      "name": "X6243",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in other types of work"
    },
    {
      "name": "X6244",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6248",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6249",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at street and highway During unspecified activity"
    },
    {
      "name": "X625",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area"
    },
    {
      "name": "X6250",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6251",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6252",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While working for income"
    },
    {
      "name": "X6253",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6254",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6258",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6259",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at trade and service area During unspecified activity"
    },
    {
      "name": "X626",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area"
    },
    {
      "name": "X6260",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6261",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6262",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While working for income"
    },
    {
      "name": "X6263",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6264",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6268",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6269",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at industrial and construction area During unspecified activity"
    },
    {
      "name": "X627",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm"
    },
    {
      "name": "X6270",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in sports activity"
    },
    {
      "name": "X6271",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in leisure activity"
    },
    {
      "name": "X6272",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While working for income"
    },
    {
      "name": "X6273",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in other types of work"
    },
    {
      "name": "X6274",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6278",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm While engaged in other specified activities"
    },
    {
      "name": "X6279",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at farm During unspecified activity"
    },
    {
      "name": "X628",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places"
    },
    {
      "name": "X6280",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in sports activity"
    },
    {
      "name": "X6281",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6282",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While working for income"
    },
    {
      "name": "X6283",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in other types of work"
    },
    {
      "name": "X6284",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6288",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6289",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at other specified places During unspecified activity"
    },
    {
      "name": "X629",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place"
    },
    {
      "name": "X6290",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6291",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6292",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While working for income"
    },
    {
      "name": "X6293",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6294",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6298",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6299",
      "description": "Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified at unspecified place During unspecified activity"
    },
    {
      "name": "X630",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home"
    },
    {
      "name": "X6300",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While engaged in sports activity"
    },
    {
      "name": "X6301",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While engaged in leisure activity"
    },
    {
      "name": "X6302",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While working for income"
    },
    {
      "name": "X6303",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While engaged in other types of work"
    },
    {
      "name": "X6304",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6308",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home While engaged in other specified activities"
    },
    {
      "name": "X6309",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at home During unspecified activity"
    },
    {
      "name": "X631",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution"
    },
    {
      "name": "X6310",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While engaged in sports activity"
    },
    {
      "name": "X6311",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6312",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While working for income"
    },
    {
      "name": "X6313",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While engaged in other types of work"
    },
    {
      "name": "X6314",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6318",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6319",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at residential institution During unspecified activity"
    },
    {
      "name": "X632",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area"
    },
    {
      "name": "X6320",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6321",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6322",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6323",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6324",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6328",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6329",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X633",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area"
    },
    {
      "name": "X6330",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6331",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6332",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While working for income"
    },
    {
      "name": "X6333",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6334",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6338",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6339",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at sports and athletics area During unspecified activity"
    },
    {
      "name": "X634",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway"
    },
    {
      "name": "X6340",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While engaged in sports activity"
    },
    {
      "name": "X6341",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6342",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While working for income"
    },
    {
      "name": "X6343",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While engaged in other types of work"
    },
    {
      "name": "X6344",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6348",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6349",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at street and highway During unspecified activity"
    },
    {
      "name": "X635",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area"
    },
    {
      "name": "X6350",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6351",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6352",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While working for income"
    },
    {
      "name": "X6353",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6354",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6358",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6359",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at trade and service area During unspecified activity"
    },
    {
      "name": "X636",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area"
    },
    {
      "name": "X6360",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6361",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6362",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While working for income"
    },
    {
      "name": "X6363",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6364",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6368",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6369",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at industrial and construction area During unspecified activity"
    },
    {
      "name": "X637",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm"
    },
    {
      "name": "X6370",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While engaged in sports activity"
    },
    {
      "name": "X6371",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While engaged in leisure activity"
    },
    {
      "name": "X6372",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While working for income"
    },
    {
      "name": "X6373",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While engaged in other types of work"
    },
    {
      "name": "X6374",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6378",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm While engaged in other specified activities"
    },
    {
      "name": "X6379",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at farm During unspecified activity"
    },
    {
      "name": "X638",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places"
    },
    {
      "name": "X6380",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While engaged in sports activity"
    },
    {
      "name": "X6381",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6382",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While working for income"
    },
    {
      "name": "X6383",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While engaged in other types of work"
    },
    {
      "name": "X6384",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6388",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6389",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at other specified places During unspecified activity"
    },
    {
      "name": "X639",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place"
    },
    {
      "name": "X6390",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6391",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6392",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While working for income"
    },
    {
      "name": "X6393",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6394",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6398",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6399",
      "description": "Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system at unspecified place During unspecified activity"
    },
    {
      "name": "X640",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home"
    },
    {
      "name": "X6400",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in sports activity"
    },
    {
      "name": "X6401",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in leisure activity"
    },
    {
      "name": "X6402",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While working for income"
    },
    {
      "name": "X6403",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in other types of work"
    },
    {
      "name": "X6404",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6408",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home While engaged in other specified activities"
    },
    {
      "name": "X6409",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at home During unspecified activity"
    },
    {
      "name": "X641",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution"
    },
    {
      "name": "X6410",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X6411",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6412",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While working for income"
    },
    {
      "name": "X6413",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X6414",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6418",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6419",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at residential institution During unspecified activity"
    },
    {
      "name": "X642",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area"
    },
    {
      "name": "X6420",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6421",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6422",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6423",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6424",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6428",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6429",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X643",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area"
    },
    {
      "name": "X6430",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6431",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6432",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While working for income"
    },
    {
      "name": "X6433",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6434",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6438",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6439",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X644",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway"
    },
    {
      "name": "X6440",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X6441",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6442",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While working for income"
    },
    {
      "name": "X6443",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X6444",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6448",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway  While engaged in other specified activities"
    },
    {
      "name": "X6449",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at street and highway During unspecified activity"
    },
    {
      "name": "X645",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area"
    },
    {
      "name": "X6450",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6451",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6452",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While working for income"
    },
    {
      "name": "X6453",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6454",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6458",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6459",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at trade and service area During unspecified activity"
    },
    {
      "name": "X646",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area"
    },
    {
      "name": "X6460",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6461",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6462",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While working for income"
    },
    {
      "name": "X6463",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6464",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6468",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6469",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X647",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm"
    },
    {
      "name": "X6470",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in sports activity"
    },
    {
      "name": "X6471",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in leisure activity"
    },
    {
      "name": "X6472",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While working for income"
    },
    {
      "name": "X6473",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in other types of work"
    },
    {
      "name": "X6474",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6478",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm While engaged in other specified activities"
    },
    {
      "name": "X6479",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at farm During unspecified activity"
    },
    {
      "name": "X648",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places"
    },
    {
      "name": "X6480",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X6481",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6482",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While working for income"
    },
    {
      "name": "X6483",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X6484",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6488",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6489",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at other specified places During unspecified activity"
    },
    {
      "name": "X649",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place"
    },
    {
      "name": "X6490",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6491",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6492",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While working for income"
    },
    {
      "name": "X6493",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6494",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6498",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6499",
      "description": "Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances at unspecified place During unspecified activity"
    },
    {
      "name": "X650",
      "description": "Intentional self-poisoning by and exposure to alcohol at home"
    },
    {
      "name": "X6500",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While engaged in sports activity"
    },
    {
      "name": "X6501",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While engaged in leisure activity"
    },
    {
      "name": "X6502",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While working for income"
    },
    {
      "name": "X6503",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While engaged in other types of work"
    },
    {
      "name": "X6504",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6508",
      "description": "Intentional self-poisoning by and exposure to alcohol at home While engaged in other specified activities"
    },
    {
      "name": "X6509",
      "description": "Intentional self-poisoning by and exposure to alcohol at home During unspecified activity"
    },
    {
      "name": "X651",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution"
    },
    {
      "name": "X6510",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While engaged in sports activity"
    },
    {
      "name": "X6511",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6512",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While working for income"
    },
    {
      "name": "X6513",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While engaged in other types of work"
    },
    {
      "name": "X6514",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6518",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6519",
      "description": "Intentional self-poisoning by and exposure to alcohol at residential institution During unspecified activity"
    },
    {
      "name": "X652",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area"
    },
    {
      "name": "X6520",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6521",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6522",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6523",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6524",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6528",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6529",
      "description": "Intentional self-poisoning by and exposure to alcohol at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X653",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area"
    },
    {
      "name": "X6530",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6531",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6532",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While working for income"
    },
    {
      "name": "X6533",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6534",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6538",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6539",
      "description": "Intentional self-poisoning by and exposure to alcohol at sports and athletics area During unspecified activity"
    },
    {
      "name": "X654",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway"
    },
    {
      "name": "X6540",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While engaged in sports activity"
    },
    {
      "name": "X6541",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6542",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While working for income"
    },
    {
      "name": "X6543",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While engaged in other types of work"
    },
    {
      "name": "X6544",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6548",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6549",
      "description": "Intentional self-poisoning by and exposure to alcohol at street and highway During unspecified activity"
    },
    {
      "name": "X655",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area"
    },
    {
      "name": "X6550",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6551",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6552",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While working for income"
    },
    {
      "name": "X6553",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6554",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6558",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6559",
      "description": "Intentional self-poisoning by and exposure to alcohol at trade and service area During unspecified activity"
    },
    {
      "name": "X656",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area"
    },
    {
      "name": "X6560",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6561",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6562",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While working for income"
    },
    {
      "name": "X6563",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6564",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6568",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6569",
      "description": "Intentional self-poisoning by and exposure to alcohol at industrial and construction area During unspecified activity"
    },
    {
      "name": "X657",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm"
    },
    {
      "name": "X6570",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While engaged in sports activity"
    },
    {
      "name": "X6571",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While engaged in leisure activity"
    },
    {
      "name": "X6572",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While working for income"
    },
    {
      "name": "X6573",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While engaged in other types of work"
    },
    {
      "name": "X6574",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6578",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm While engaged in other specified activities"
    },
    {
      "name": "X6579",
      "description": "Intentional self-poisoning by and exposure to alcohol at farm During unspecified activity"
    },
    {
      "name": "X658",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places"
    },
    {
      "name": "X6580",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While engaged in sports activity"
    },
    {
      "name": "X6581",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6582",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While working for income"
    },
    {
      "name": "X6583",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While engaged in other types of work"
    },
    {
      "name": "X6584",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6588",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6589",
      "description": "Intentional self-poisoning by and exposure to alcohol at other specified places During unspecified activity"
    },
    {
      "name": "X659",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place"
    },
    {
      "name": "X6590",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6591",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6592",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While working for income"
    },
    {
      "name": "X6593",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6594",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6598",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6599",
      "description": "Intentional self-poisoning by and exposure to alcohol at unspecified place During unspecified activity"
    },
    {
      "name": "X660",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home"
    },
    {
      "name": "X6600",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While engaged in sports activity"
    },
    {
      "name": "X6601",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While engaged in leisure activity"
    },
    {
      "name": "X6602",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While working for income"
    },
    {
      "name": "X6603",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While engaged in other types of work"
    },
    {
      "name": "X6604",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6608",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home While engaged in other specified activities"
    },
    {
      "name": "X6609",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at home During unspecified activity"
    },
    {
      "name": "X661",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution"
    },
    {
      "name": "X6610",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While engaged in sports activity"
    },
    {
      "name": "X6611",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6612",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While working for income"
    },
    {
      "name": "X6613",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While engaged in other types of work"
    },
    {
      "name": "X6614",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6618",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6619",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at residential institution During unspecified activity"
    },
    {
      "name": "X662",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area"
    },
    {
      "name": "X6620",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6621",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6622",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6623",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6624",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6628",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6629",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X663",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area"
    },
    {
      "name": "X6630",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6631",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6632",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While working for income"
    },
    {
      "name": "X6633",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6634",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6638",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6639",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X664",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway"
    },
    {
      "name": "X6640",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While engaged in sports activity"
    },
    {
      "name": "X6641",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6642",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While working for income"
    },
    {
      "name": "X6643",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While engaged in other types of work"
    },
    {
      "name": "X6644",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6648",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6649",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at street and highway During unspecified activity"
    },
    {
      "name": "X665",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area"
    },
    {
      "name": "X6650",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6651",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6652",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While working for income"
    },
    {
      "name": "X6653",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6654",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6658",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6659",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at trade and service area During unspecified activity"
    },
    {
      "name": "X666",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area"
    },
    {
      "name": "X6660",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6661",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6662",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While working for income"
    },
    {
      "name": "X6663",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6664",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6668",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6669",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X667",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm"
    },
    {
      "name": "X6670",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While engaged in sports activity"
    },
    {
      "name": "X6671",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While engaged in leisure activity"
    },
    {
      "name": "X6672",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While working for income"
    },
    {
      "name": "X6673",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While engaged in other types of work"
    },
    {
      "name": "X6674",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6678",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm While engaged in other specified activities"
    },
    {
      "name": "X6679",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at farm During unspecified activity"
    },
    {
      "name": "X668",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places"
    },
    {
      "name": "X6680",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While engaged in sports activity"
    },
    {
      "name": "X6681",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6682",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While working for income"
    },
    {
      "name": "X6683",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While engaged in other types of work"
    },
    {
      "name": "X6684",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6688",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6689",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at other specified places During unspecified activity"
    },
    {
      "name": "X669",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place"
    },
    {
      "name": "X6690",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6691",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6692",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While working for income"
    },
    {
      "name": "X6693",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6694",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6698",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6699",
      "description": "Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours at unspecified place During unspecified activity"
    },
    {
      "name": "X670",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home"
    },
    {
      "name": "X6700",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While engaged in sports activity"
    },
    {
      "name": "X6701",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While engaged in leisure activity"
    },
    {
      "name": "X6702",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While working for income"
    },
    {
      "name": "X6703",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While engaged in other types of work"
    },
    {
      "name": "X6704",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6708",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home While engaged in other specified activities"
    },
    {
      "name": "X6709",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at home During unspecified activity"
    },
    {
      "name": "X671",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution"
    },
    {
      "name": "X6710",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While engaged in sports activity"
    },
    {
      "name": "X6711",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6712",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While working for income"
    },
    {
      "name": "X6713",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While engaged in other types of work"
    },
    {
      "name": "X6714",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6718",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6719",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at residential institution During unspecified activity"
    },
    {
      "name": "X672",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area"
    },
    {
      "name": "X6720",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6721",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6722",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6723",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6724",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6728",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6729",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X673",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area"
    },
    {
      "name": "X6730",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6731",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6732",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While working for income"
    },
    {
      "name": "X6733",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6734",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6738",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6739",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X674",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway"
    },
    {
      "name": "X6740",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While engaged in sports activity"
    },
    {
      "name": "X6741",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6742",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While working for income"
    },
    {
      "name": "X6743",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While engaged in other types of work"
    },
    {
      "name": "X6744",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6748",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6749",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at street and highway During unspecified activity"
    },
    {
      "name": "X675",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area"
    },
    {
      "name": "X6750",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6751",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6752",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While working for income"
    },
    {
      "name": "X6753",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6754",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6758",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6759",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at trade and service area During unspecified activity"
    },
    {
      "name": "X676",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area"
    },
    {
      "name": "X6760",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6761",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6762",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While working for income"
    },
    {
      "name": "X6763",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6764",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6768",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6769",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X677",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm"
    },
    {
      "name": "X6770",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While engaged in sports activity"
    },
    {
      "name": "X6771",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While engaged in leisure activity"
    },
    {
      "name": "X6772",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While working for income"
    },
    {
      "name": "X6773",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While engaged in other types of work"
    },
    {
      "name": "X6774",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6778",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm While engaged in other specified activities"
    },
    {
      "name": "X6779",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at farm During unspecified activity"
    },
    {
      "name": "X678",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places"
    },
    {
      "name": "X6780",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While engaged in sports activity"
    },
    {
      "name": "X6781",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6782",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While working for income"
    },
    {
      "name": "X6783",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While engaged in other types of work"
    },
    {
      "name": "X6784",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6788",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6789",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at other specified places During unspecified activity"
    },
    {
      "name": "X679",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place"
    },
    {
      "name": "X6790",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6791",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6792",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While working for income"
    },
    {
      "name": "X6793",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6794",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6798",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6799",
      "description": "Intentional self-poisoning by and exposure to other gases and vapours at unspecified place During unspecified activity"
    },
    {
      "name": "X680",
      "description": "Intentional self-poisoning by and exposure to pesticides at home"
    },
    {
      "name": "X6800",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While engaged in sports activity"
    },
    {
      "name": "X6801",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While engaged in leisure activity"
    },
    {
      "name": "X6802",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While working for income"
    },
    {
      "name": "X6803",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While engaged in other types of work"
    },
    {
      "name": "X6804",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6808",
      "description": "Intentional self-poisoning by and exposure to pesticides at home While engaged in other specified activities"
    },
    {
      "name": "X6809",
      "description": "Intentional self-poisoning by and exposure to pesticides at home During unspecified activity"
    },
    {
      "name": "X681",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution"
    },
    {
      "name": "X6810",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While engaged in sports activity"
    },
    {
      "name": "X6811",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6812",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While working for income"
    },
    {
      "name": "X6813",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While engaged in other types of work"
    },
    {
      "name": "X6814",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6818",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6819",
      "description": "Intentional self-poisoning by and exposure to pesticides at residential institution During unspecified activity"
    },
    {
      "name": "X682",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area"
    },
    {
      "name": "X6820",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6821",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6822",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6823",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6824",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6828",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6829",
      "description": "Intentional self-poisoning by and exposure to pesticides at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X683",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area"
    },
    {
      "name": "X6830",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6831",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6832",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While working for income"
    },
    {
      "name": "X6833",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6834",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6838",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6839",
      "description": "Intentional self-poisoning by and exposure to pesticides at sports and athletics area During unspecified activity"
    },
    {
      "name": "X684",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway"
    },
    {
      "name": "X6840",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While engaged in sports activity"
    },
    {
      "name": "X6841",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6842",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While working for income"
    },
    {
      "name": "X6843",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While engaged in other types of work"
    },
    {
      "name": "X6844",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6848",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6849",
      "description": "Intentional self-poisoning by and exposure to pesticides at street and highway During unspecified activity"
    },
    {
      "name": "X685",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area"
    },
    {
      "name": "X6850",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6851",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6852",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While working for income"
    },
    {
      "name": "X6853",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6854",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6858",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6859",
      "description": "Intentional self-poisoning by and exposure to pesticides at trade and service area During unspecified activity"
    },
    {
      "name": "X686",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area"
    },
    {
      "name": "X6860",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6861",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6862",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While working for income"
    },
    {
      "name": "X6863",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6864",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6868",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6869",
      "description": "Intentional self-poisoning by and exposure to pesticides at industrial and construction area During unspecified activity"
    },
    {
      "name": "X687",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm"
    },
    {
      "name": "X6870",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While engaged in sports activity"
    },
    {
      "name": "X6871",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While engaged in leisure activity"
    },
    {
      "name": "X6872",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While working for income"
    },
    {
      "name": "X6873",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While engaged in other types of work"
    },
    {
      "name": "X6874",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6878",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm While engaged in other specified activities"
    },
    {
      "name": "X6879",
      "description": "Intentional self-poisoning by and exposure to pesticides at farm During unspecified activity"
    },
    {
      "name": "X688",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places"
    },
    {
      "name": "X6880",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While engaged in sports activity"
    },
    {
      "name": "X6881",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6882",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While working for income"
    },
    {
      "name": "X6883",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While engaged in other types of work"
    },
    {
      "name": "X6884",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6888",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6889",
      "description": "Intentional self-poisoning by and exposure to pesticides at other specified places During unspecified activity"
    },
    {
      "name": "X689",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place"
    },
    {
      "name": "X6890",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6891",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6892",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While working for income"
    },
    {
      "name": "X6893",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6894",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6898",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6899",
      "description": "Intentional self-poisoning by and exposure to pesticides at unspecified place During unspecified activity"
    },
    {
      "name": "X690",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home"
    },
    {
      "name": "X6900",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While engaged in sports activity"
    },
    {
      "name": "X6901",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While engaged in leisure activity"
    },
    {
      "name": "X6902",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While working for income"
    },
    {
      "name": "X6903",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While engaged in other types of work"
    },
    {
      "name": "X6904",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6908",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home While engaged in other specified activities"
    },
    {
      "name": "X6909",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at home During unspecified activity"
    },
    {
      "name": "X691",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution"
    },
    {
      "name": "X6910",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X6911",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X6912",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While working for income"
    },
    {
      "name": "X6913",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While engaged in other types of work "
    },
    {
      "name": "X6914",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6918",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X6919",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at residential institution During unspecified activity"
    },
    {
      "name": "X692",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area"
    },
    {
      "name": "X6920",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X6921",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X6922",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X6923",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X6924",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6928",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X6929",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X693",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area"
    },
    {
      "name": "X6930",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X6931",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X6932",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While working for income"
    },
    {
      "name": "X6933",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X6934",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6938",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X6939",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X694",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway"
    },
    {
      "name": "X6940",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X6941",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X6942",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While working for income"
    },
    {
      "name": "X6943",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X6944",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6948",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X6949",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at street and highway During unspecified activity"
    },
    {
      "name": "X695",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area"
    },
    {
      "name": "X6950",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X6951",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X6952",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While working for income"
    },
    {
      "name": "X6953",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X6954",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6958",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X6959",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at trade and service area During unspecified activity"
    },
    {
      "name": "X696",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area"
    },
    {
      "name": "X6960",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X6961",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X6962",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While working for income"
    },
    {
      "name": "X6963",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X6964",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6968",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X6969",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X697",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm"
    },
    {
      "name": "X6970",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While engaged in sports activity"
    },
    {
      "name": "X6971",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While engaged in leisure activity"
    },
    {
      "name": "X6972",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While working for income"
    },
    {
      "name": "X6973",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While engaged in other types of work"
    },
    {
      "name": "X6974",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6978",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm While engaged in other specified activities"
    },
    {
      "name": "X6979",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at farm During unspecified activity"
    },
    {
      "name": "X698",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places"
    },
    {
      "name": "X6980",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X6981",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X6982",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While working for income"
    },
    {
      "name": "X6983",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X6984",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6988",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X6989",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at other specified places During unspecified activity"
    },
    {
      "name": "X699",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place"
    },
    {
      "name": "X6990",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X6991",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X6992",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While working for income"
    },
    {
      "name": "X6993",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X6994",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X6998",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X6999",
      "description": "Intentional self-poisoning by and exposure to other and unsepcified chemicals and noxious substances at unspecified place During unspecified activity"
    },
    {
      "name": "X700",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home"
    },
    {
      "name": "X7000",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While engaged in sports activity"
    },
    {
      "name": "X7001",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While engaged in leisure activity"
    },
    {
      "name": "X7002",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While working for income"
    },
    {
      "name": "X7003",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While engaged in other types of work"
    },
    {
      "name": "X7004",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7008",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home While engaged in other specified activities"
    },
    {
      "name": "X7009",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at home During unspecified activity"
    },
    {
      "name": "X701",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution"
    },
    {
      "name": "X7010",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While engaged in sports activity"
    },
    {
      "name": "X7011",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7012",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While working for income"
    },
    {
      "name": "X7013",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While engaged in other types of work"
    },
    {
      "name": "X7014",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7018",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7019",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at residential institution During unspecified activity"
    },
    {
      "name": "X702",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area"
    },
    {
      "name": "X7020",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7021",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7022",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7023",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7024",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7028",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7029",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X703",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area"
    },
    {
      "name": "X7030",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7031",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7032",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While working for income"
    },
    {
      "name": "X7033",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7034",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7038",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7039",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at sports and athletics area During unspecified activity"
    },
    {
      "name": "X704",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway"
    },
    {
      "name": "X7040",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While engaged in sports activity"
    },
    {
      "name": "X7041",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7042",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While working for income"
    },
    {
      "name": "X7043",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While engaged in other types of work"
    },
    {
      "name": "X7044",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7048",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7049",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at street and highway During unspecified activity"
    },
    {
      "name": "X705",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area"
    },
    {
      "name": "X7050",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7051",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7052",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While working for income"
    },
    {
      "name": "X7053",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7054",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7058",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7059",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at trade and service area During unspecified activity"
    },
    {
      "name": "X706",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area"
    },
    {
      "name": "X7060",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7061",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7062",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While working for income"
    },
    {
      "name": "X7063",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7064",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7068",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7069",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at industrial and construction area During unspecified activity"
    },
    {
      "name": "X707",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm"
    },
    {
      "name": "X7070",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While engaged in sports activity"
    },
    {
      "name": "X7071",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While engaged in leisure activity"
    },
    {
      "name": "X7072",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While working for income"
    },
    {
      "name": "X7073",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While engaged in other types of work"
    },
    {
      "name": "X7074",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7078",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm While engaged in other specified activities"
    },
    {
      "name": "X7079",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at farm During unspecified activity"
    },
    {
      "name": "X708",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places"
    },
    {
      "name": "X7080",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While engaged in sports activity"
    },
    {
      "name": "X7081",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7082",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While working for income"
    },
    {
      "name": "X7083",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While engaged in other types of work"
    },
    {
      "name": "X7084",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7088",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7089",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at other specified places During unspecified activity"
    },
    {
      "name": "X709",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place"
    },
    {
      "name": "X7090",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7091",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7092",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While working for income"
    },
    {
      "name": "X7093",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7094",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7098",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7099",
      "description": "Intentional self-harm by hanging, strangulation and suffocation at unspecified place During unspecified activity"
    },
    {
      "name": "X710",
      "description": "Intentional self-harm by drowing and submersion at home"
    },
    {
      "name": "X7100",
      "description": "Intentional self-harm by drowing and submersion at home While engaged in sports activity"
    },
    {
      "name": "X7101",
      "description": "Intentional self-harm by drowing and submersion at home While engaged in leisure activity"
    },
    {
      "name": "X7102",
      "description": "Intentional self-harm by drowing and submersion at home While working for income"
    },
    {
      "name": "X7103",
      "description": "Intentional self-harm by drowing and submersion at home While engaged in other types of work"
    },
    {
      "name": "X7104",
      "description": "Intentional self-harm by drowing and submersion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7108",
      "description": "Intentional self-harm by drowing and submersion at home While engaged in other specified activities"
    },
    {
      "name": "X7109",
      "description": "Intentional self-harm by drowing and submersion at home During unspecified activity"
    },
    {
      "name": "X711",
      "description": "Intentional self-harm by drowing and submersion at residential institution"
    },
    {
      "name": "X7110",
      "description": "Intentional self-harm by drowing and submersion at residential institution While engaged in sports activity"
    },
    {
      "name": "X7111",
      "description": "Intentional self-harm by drowing and submersion at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7112",
      "description": "Intentional self-harm by drowing and submersion at residential institution While working for income"
    },
    {
      "name": "X7113",
      "description": "Intentional self-harm by drowing and submersion at residential institution While engaged in other types of work"
    },
    {
      "name": "X7114",
      "description": "Intentional self-harm by drowing and submersion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7118",
      "description": "Intentional self-harm by drowing and submersion at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7119",
      "description": "Intentional self-harm by drowing and submersion at residential institution During unspecified activity"
    },
    {
      "name": "X712",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area"
    },
    {
      "name": "X7120",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7121",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7122",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7123",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7124",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7128",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7129",
      "description": "Intentional self-harm by drowning and submersion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X713",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area"
    },
    {
      "name": "X7130",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7131",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7132",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While working for income"
    },
    {
      "name": "X7133",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7134",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7138",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7139",
      "description": "Intentional self-harm by drowning and submersion at sports and athletics area During unspecified activity"
    },
    {
      "name": "X714",
      "description": "Intentional self-harm by drowning and submersion at street and highway"
    },
    {
      "name": "X7140",
      "description": "Intentional self-harm by drowning and submersion at street and highway While engaged in sports activity"
    },
    {
      "name": "X7141",
      "description": "Intentional self-harm by drowning and submersion at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7142",
      "description": "Intentional self-harm by drowning and submersion at street and highway While working for income"
    },
    {
      "name": "X7143",
      "description": "Intentional self-harm by drowning and submersion at street and highway While engaged in other types of work"
    },
    {
      "name": "X7144",
      "description": "Intentional self-harm by drowning and submersion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7148",
      "description": "Intentional self-harm by drowning and submersion at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7149",
      "description": "Intentional self-harm by drowning and submersion at street and highway During unspecified activity"
    },
    {
      "name": "X715",
      "description": "Intentional self-harm by drowning and submersion at trade and service area"
    },
    {
      "name": "X7150",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7151",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7152",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While working for income"
    },
    {
      "name": "X7153",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7154",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7158",
      "description": "Intentional self-harm by drowning and submersion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7159",
      "description": "Intentional self-harm by drowning and submersion at trade and service area During unspecified activity"
    },
    {
      "name": "X716",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area"
    },
    {
      "name": "X7160",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7161",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7162",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While working for income"
    },
    {
      "name": "X7163",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7164",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7168",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7169",
      "description": "Intentional self-harm by drowning and submersion at industrial and construction area During unspecified activity"
    },
    {
      "name": "X717",
      "description": "Intentional self-harm by drowning and submersion at farm"
    },
    {
      "name": "X7170",
      "description": "Intentional self-harm by drowning and submersion at farm While engaged in sports activity"
    },
    {
      "name": "X7171",
      "description": "Intentional self-harm by drowning and submersion at farm While engaged in leisure activity"
    },
    {
      "name": "X7172",
      "description": "Intentional self-harm by drowning and submersion at farm While working for income"
    },
    {
      "name": "X7173",
      "description": "Intentional self-harm by drowning and submersion at farm While engaged in other types of work"
    },
    {
      "name": "X7174",
      "description": "Intentional self-harm by drowning and submersion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7178",
      "description": "Intentional self-harm by drowning and submersion at farm While engaged in other specified activities"
    },
    {
      "name": "X7179",
      "description": "Intentional self-harm by drowning and submersion at farm During unspecified activity"
    },
    {
      "name": "X718",
      "description": "Intentional self-harm by drowning and submersion at other specified places"
    },
    {
      "name": "X7180",
      "description": "Intentional self-harm by drowning and submersion at other specified places While engaged in sports activity"
    },
    {
      "name": "X7181",
      "description": "Intentional self-harm by drowning and submersion at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7182",
      "description": "Intentional self-harm by drowning and submersion at other specified places While working for income"
    },
    {
      "name": "X7183",
      "description": "Intentional self-harm by drowning and submersion at other specified places While engaged in other types of work"
    },
    {
      "name": "X7184",
      "description": "Intentional self-harm by drowning and submersion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7188",
      "description": "Intentional self-harm by drowning and submersion at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7189",
      "description": "Intentional self-harm by drowning and submersion at other specified places During unspecified activity"
    },
    {
      "name": "X719",
      "description": "Intentional self-harm by drowning and submersion at unspecified place"
    },
    {
      "name": "X7190",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7191",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7192",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While working for income"
    },
    {
      "name": "X7193",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7194",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7198",
      "description": "Intentional self-harm by drowning and submersion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7199",
      "description": "Intentional self-harm by drowning and submersion at unspecified place During unspecified activity"
    },
    {
      "name": "X720",
      "description": "Intentional self-harm by handgun discharge at home"
    },
    {
      "name": "X7200",
      "description": "Intentional self-harm by handgun discharge at home While engaged in sports activity"
    },
    {
      "name": "X7201",
      "description": "Intentional self-harm by handgun discharge at home While engaged in leisure activity"
    },
    {
      "name": "X7202",
      "description": "Intentional self-harm by handgun discharge at home While working for income"
    },
    {
      "name": "X7203",
      "description": "Intentional self-harm by handgun discharge at home While engaged in other types of work"
    },
    {
      "name": "X7204",
      "description": "Intentional self-harm by handgun discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7208",
      "description": "Intentional self-harm by handgun discharge at home While engaged in other specified activities"
    },
    {
      "name": "X7209",
      "description": "Intentional self-harm by handgun discharge at home During unspecified activity"
    },
    {
      "name": "X721",
      "description": "Intentional self-harm by handgun discharge at residential institution"
    },
    {
      "name": "X7210",
      "description": "Intentional self-harm by handgun discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X7211",
      "description": "Intentional self-harm by handgun discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7212",
      "description": "Intentional self-harm by handgun discharge at residential institution While working for income"
    },
    {
      "name": "X7213",
      "description": "Intentional self-harm by handgun discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X7214",
      "description": "Intentional self-harm by handgun discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7218",
      "description": "Intentional self-harm by handgun discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7219",
      "description": "Intentional self-harm by handgun discharge at residential institution During unspecified activity"
    },
    {
      "name": "X722",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area"
    },
    {
      "name": "X7220",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7221",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7222",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7223",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7224",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7228",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7229",
      "description": "Intentional self-harm by handgun discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X723",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area"
    },
    {
      "name": "X7230",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7231",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7232",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While working for income"
    },
    {
      "name": "X7233",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7234",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7238",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7239",
      "description": "Intentional self-harm by handgun discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X724",
      "description": "Intentional self-harm by handgun discharge at street and highway"
    },
    {
      "name": "X7240",
      "description": "Intentional self-harm by handgun discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X7241",
      "description": "Intentional self-harm by handgun discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7242",
      "description": "Intentional self-harm by handgun discharge at street and highway While working for income"
    },
    {
      "name": "X7243",
      "description": "Intentional self-harm by handgun discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X7244",
      "description": "Intentional self-harm by handgun discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7248",
      "description": "Intentional self-harm by handgun discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7249",
      "description": "Intentional self-harm by handgun discharge at street and highway During unspecified activity"
    },
    {
      "name": "X725",
      "description": "Intentional self-harm by handgun discharge at trade and service area"
    },
    {
      "name": "X7250",
      "description": "Intentional self-harm by handgun discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7251",
      "description": "Intentional self-harm by handgun discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7252",
      "description": "Intentional self-harm by handgun discharge at trade and service area While working for income"
    },
    {
      "name": "X7253",
      "description": "Intentional self-harm by handgun discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7254",
      "description": "Intentional self-harm by handgun discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7258",
      "description": "Intentional self-harm by handgun discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7259",
      "description": "Intentional self-harm by handgun discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X726",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area"
    },
    {
      "name": "X7260",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7261",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7262",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While working for income"
    },
    {
      "name": "X7263",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7264",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7268",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7269",
      "description": "Intentional self-harm by handgun discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X727",
      "description": "Intentional self-harm by handgun discharge at farm"
    },
    {
      "name": "X7270",
      "description": "Intentional self-harm by handgun discharge at farm While engaged in sports activity"
    },
    {
      "name": "X7271",
      "description": "Intentional self-harm by handgun discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X7272",
      "description": "Intentional self-harm by handgun discharge at farm While working for income"
    },
    {
      "name": "X7273",
      "description": "Intentional self-harm by handgun discharge at farm While engaged in other types of work"
    },
    {
      "name": "X7274",
      "description": "Intentional self-harm by handgun discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7278",
      "description": "Intentional self-harm by handgun discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X7279",
      "description": "Intentional self-harm by handgun discharge at farm During unspecified activity"
    },
    {
      "name": "X728",
      "description": "Intentional self-harm by handgun discharge at other specified places"
    },
    {
      "name": "X7280",
      "description": "Intentional self-harm by handgun discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X7281",
      "description": "Intentional self-harm by handgun discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7282",
      "description": "Intentional self-harm by handgun discharge at other specified places While working for income"
    },
    {
      "name": "X7283",
      "description": "Intentional self-harm by handgun discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X7284",
      "description": "Intentional self-harm by handgun discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7288",
      "description": "Intentional self-harm by handgun discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7289",
      "description": "Intentional self-harm by handgun discharge at other specified places During unspecified activity"
    },
    {
      "name": "X729",
      "description": "Intentional self-harm by handgun discharge at unspecified place"
    },
    {
      "name": "X7290",
      "description": "Intentional self-harm by handgun discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7291",
      "description": "Intentional self-harm by handgun discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7292",
      "description": "Intentional self-harm by handgun discharge at unspecified place While working for income"
    },
    {
      "name": "X7293",
      "description": "Intentional self-harm by handgun discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7294",
      "description": "Intentional self-harm by handgun discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7298",
      "description": "Intentional self-harm by handgun discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7299",
      "description": "Intentional self-harm by handgun discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X730",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home"
    },
    {
      "name": "X7300",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While engaged in sports activity"
    },
    {
      "name": "X7301",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While engaged in leisure activity"
    },
    {
      "name": "X7302",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While working for income"
    },
    {
      "name": "X7303",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While engaged in other types of work"
    },
    {
      "name": "X7304",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7308",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home While engaged in other specified activities"
    },
    {
      "name": "X7309",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at home During unspecified activity"
    },
    {
      "name": "X731",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution"
    },
    {
      "name": "X7310",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X7311",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7312",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While working for income"
    },
    {
      "name": "X7313",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X7314",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7318",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7319",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at residential institution During unspecified activity"
    },
    {
      "name": "X732",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area"
    },
    {
      "name": "X7320",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7321",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7322",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7323",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7324",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7328",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7329",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X733",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area"
    },
    {
      "name": "X7330",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7331",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7332",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While working for income"
    },
    {
      "name": "X7333",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7334",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7338",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7339",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X734",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway"
    },
    {
      "name": "X7340",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X7341",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7342",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While working for income"
    },
    {
      "name": "X7343",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X7344",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7348",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7349",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at street and highway During unspecified activity"
    },
    {
      "name": "X735",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area"
    },
    {
      "name": "X7350",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7351",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7352",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While working for income"
    },
    {
      "name": "X7353",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7354",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7358",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7359",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X736",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area"
    },
    {
      "name": "X7360",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7361",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7362",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While working for income"
    },
    {
      "name": "X7363",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7364",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7368",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7369",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X737",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm"
    },
    {
      "name": "X7370",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While engaged in sports activity"
    },
    {
      "name": "X7371",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X7372",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While working for income"
    },
    {
      "name": "X7373",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While engaged in other types of work"
    },
    {
      "name": "X7374",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7378",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X7379",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at farm During unspecified activity"
    },
    {
      "name": "X738",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places"
    },
    {
      "name": "X7380",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X7381",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7382",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While working for income"
    },
    {
      "name": "X7383",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X7384",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7388",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7389",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at other specified places During unspecified activity"
    },
    {
      "name": "X739",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place"
    },
    {
      "name": "X7390",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7391",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7392",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While working for income"
    },
    {
      "name": "X7393",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7394",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7398",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7399",
      "description": "Intentional self-harm by rifle, shotgun and larger firearm discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X740",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home"
    },
    {
      "name": "X7400",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While engaged in sports activity"
    },
    {
      "name": "X7401",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While engaged in leisure activity"
    },
    {
      "name": "X7402",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While working for income"
    },
    {
      "name": "X7403",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While engaged in other types of work"
    },
    {
      "name": "X7404",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7408",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home While engaged in other specified activities"
    },
    {
      "name": "X7409",
      "description": "Intentional self-harm by other and unspecified firearm discharge at home During unspecified activity"
    },
    {
      "name": "X741",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution"
    },
    {
      "name": "X7410",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X7411",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7412",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While working for income"
    },
    {
      "name": "X7413",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X7414",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7418",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7419",
      "description": "Intentional self-harm by other and unspecified firearm discharge at residential institution During unspecified activity"
    },
    {
      "name": "X742",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area"
    },
    {
      "name": "X7420",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7421",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7422",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7423",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7424",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7428",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7429",
      "description": "Intentional self-harm by other and unspecified firearm discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X743",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area"
    },
    {
      "name": "X7430",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7431",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7432",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While working for income"
    },
    {
      "name": "X7433",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7434",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7438",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area While engaged in other specified activities "
    },
    {
      "name": "X7439",
      "description": "Intentional self-harm by other and unspecified firearm discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X744",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway"
    },
    {
      "name": "X7440",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X7441",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7442",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While working for income"
    },
    {
      "name": "X7443",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X7444",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7448",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7449",
      "description": "Intentional self-harm by other and unspecified firearm discharge at street and highway During unspecified activity"
    },
    {
      "name": "X745",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area"
    },
    {
      "name": "X7450",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7451",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7452",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While working for income"
    },
    {
      "name": "X7453",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7454",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7458",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7459",
      "description": "Intentional self-harm by other and unspecified firearm discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X746",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area"
    },
    {
      "name": "X7460",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7461",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7462",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While working for income"
    },
    {
      "name": "X7463",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7464",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7468",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7469",
      "description": "Intentional self-harm by other and unspecified firearm discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X747",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm"
    },
    {
      "name": "X7470",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While engaged in sports activity"
    },
    {
      "name": "X7471",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X7472",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While working for income"
    },
    {
      "name": "X7473",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While engaged in other types of work"
    },
    {
      "name": "X7474",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7478",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X7479",
      "description": "Intentional self-harm by other and unspecified firearm discharge at farm During unspecified activity"
    },
    {
      "name": "X748",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places"
    },
    {
      "name": "X7480",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X7481",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7482",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While working for income"
    },
    {
      "name": "X7483",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X7484",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7488",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7489",
      "description": "Intentional self-harm by other and unspecified firearm discharge at other specified places During unspecified activity"
    },
    {
      "name": "X749",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place"
    },
    {
      "name": "X7490",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7491",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7492",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While working for income"
    },
    {
      "name": "X7493",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7494",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7498",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7499",
      "description": "Intentional self-harm by other and unspecified firearm discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X750",
      "description": "Intentional self-harm by explosive material at home"
    },
    {
      "name": "X7500",
      "description": "Intentional self-harm by explosive material at home While engaged in sports activity"
    },
    {
      "name": "X7501",
      "description": "Intentional self-harm by explosive material at home While engaged in leisure activity"
    },
    {
      "name": "X7502",
      "description": "Intentional self-harm by explosive material at home While working for income"
    },
    {
      "name": "X7503",
      "description": "Intentional self-harm by explosive material at home While engaged in other types of work"
    },
    {
      "name": "X7504",
      "description": "Intentional self-harm by explosive material at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7508",
      "description": "Intentional self-harm by explosive material at home While engaged in other specified activities"
    },
    {
      "name": "X7509",
      "description": "Intentional self-harm by explosive material at home During unspecified activity"
    },
    {
      "name": "X751",
      "description": "Intentional self-harm by explosive material at residential institution"
    },
    {
      "name": "X7510",
      "description": "Intentional self-harm by explosive material at residential institution While engaged in sports activity"
    },
    {
      "name": "X7511",
      "description": "Intentional self-harm by explosive material at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7512",
      "description": "Intentional self-harm by explosive material at residential institution While working for income"
    },
    {
      "name": "X7513",
      "description": "Intentional self-harm by explosive material at residential institution While engaged in other types of work"
    },
    {
      "name": "X7514",
      "description": "Intentional self-harm by explosive material at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7518",
      "description": "Intentional self-harm by explosive material at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7519",
      "description": "Intentional self-harm by explosive material at residential institution During unspecified activity"
    },
    {
      "name": "X752",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area"
    },
    {
      "name": "X7520",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7521",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7522",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7523",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7524",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7528",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7529",
      "description": "Intentional self-harm by explosive material at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X753",
      "description": "Intentional self-harm by explosive material at sports and athletics area"
    },
    {
      "name": "X7530",
      "description": "Intentional self-harm by explosive material at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7531",
      "description": "Intentional self-harm by explosive material at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7532",
      "description": "Intentional self-harm by explosive material at sports and athletics area While working for income"
    },
    {
      "name": "X7533",
      "description": "Intentional self-harm by explosive material at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7534",
      "description": "Intentional self-harm by explosive material at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7538",
      "description": "Intentional self-harm by explosive material at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7539",
      "description": "Intentional self-harm by explosive material at sports and athletics area During unspecified activity"
    },
    {
      "name": "X754",
      "description": "Intentional self-harm by explosive material at street and highway"
    },
    {
      "name": "X7540",
      "description": "Intentional self-harm by explosive material at street and highway While engaged in sports activity"
    },
    {
      "name": "X7541",
      "description": "Intentional self-harm by explosive material at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7542",
      "description": "Intentional self-harm by explosive material at street and highway While working for income"
    },
    {
      "name": "X7543",
      "description": "Intentional self-harm by explosive material at street and highway While engaged in other types of work"
    },
    {
      "name": "X7544",
      "description": "Intentional self-harm by explosive material at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7548",
      "description": "Intentional self-harm by explosive material at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7549",
      "description": "Intentional self-harm by explosive material at street and highway During unspecified activity"
    },
    {
      "name": "X755",
      "description": "Intentional self-harm by explosive material at trade and service area"
    },
    {
      "name": "X7550",
      "description": "Intentional self-harm by explosive material at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7551",
      "description": "Intentional self-harm by explosive material at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7552",
      "description": "Intentional self-harm by explosive material at trade and service area While working for income"
    },
    {
      "name": "X7553",
      "description": "Intentional self-harm by explosive material at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7554",
      "description": "Intentional self-harm by explosive material at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7558",
      "description": "Intentional self-harm by explosive material at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7559",
      "description": "Intentional self-harm by explosive material at trade and service area During unspecified activity"
    },
    {
      "name": "X756",
      "description": "Intentional self-harm by explosive material at industrial and construction area"
    },
    {
      "name": "X7560",
      "description": "Intentional self-harm by explosive material at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7561",
      "description": "Intentional self-harm by explosive material at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7562",
      "description": "Intentional self-harm by explosive material at industrial and construction area While working for income"
    },
    {
      "name": "X7563",
      "description": "Intentional self-harm by explosive material at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7564",
      "description": "Intentional self-harm by explosive material at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7568",
      "description": "Intentional self-harm by explosive material at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7569",
      "description": "Intentional self-harm by explosive material at industrial and construction area During unspecified activity"
    },
    {
      "name": "X757",
      "description": "Intentional self-harm by explosive material at farm"
    },
    {
      "name": "X7570",
      "description": "Intentional self-harm by explosive material at farm While engaged in sports activity"
    },
    {
      "name": "X7571",
      "description": "Intentional self-harm by explosive material at farm While engaged in leisure activity"
    },
    {
      "name": "X7572",
      "description": "Intentional self-harm by explosive material at farm While working for income"
    },
    {
      "name": "X7573",
      "description": "Intentional self-harm by explosive material at farm While engaged in other types of work"
    },
    {
      "name": "X7574",
      "description": "Intentional self-harm by explosive material at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7578",
      "description": "Intentional self-harm by explosive material at farm While engaged in other specified activities"
    },
    {
      "name": "X7579",
      "description": "Intentional self-harm by explosive material at farm During unspecified activity"
    },
    {
      "name": "X758",
      "description": "Intentional self-harm by explosive material at other specified places"
    },
    {
      "name": "X7580",
      "description": "Intentional self-harm by explosive material at other specified places While engaged in sports activity"
    },
    {
      "name": "X7581",
      "description": "Intentional self-harm by explosive material at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7582",
      "description": "Intentional self-harm by explosive material at other specified places While working for income"
    },
    {
      "name": "X7583",
      "description": "Intentional self-harm by explosive material at other specified places While engaged in other types of work"
    },
    {
      "name": "X7584",
      "description": "Intentional self-harm by explosive material at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7588",
      "description": "Intentional self-harm by explosive material at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7589",
      "description": "Intentional self-harm by explosive material at other specified places During unspecified activity"
    },
    {
      "name": "X759",
      "description": "Intentional self-harm by explosive material at unspecified place"
    },
    {
      "name": "X7590",
      "description": "Intentional self-harm by explosive material at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7591",
      "description": "Intentional self-harm by explosive material at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7592",
      "description": "Intentional self-harm by explosive material at unspecified place While working for income"
    },
    {
      "name": "X7593",
      "description": "Intentional self-harm by explosive material at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7594",
      "description": "Intentional self-harm by explosive material at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7598",
      "description": "Intentional self-harm by explosive material at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7599",
      "description": "Intentional self-harm by explosive material at unspecified place During unspecified activity"
    },
    {
      "name": "X760",
      "description": "Intentional self-harm by smoke, fire and flames at home"
    },
    {
      "name": "X7600",
      "description": "Intentional self-harm by smoke, fire and flames at home While engaged in sports activity"
    },
    {
      "name": "X7601",
      "description": "Intentional self-harm by smoke, fire and flames at home While engaged in leisure activity"
    },
    {
      "name": "X7602",
      "description": "Intentional self-harm by smoke, fire and flames at home While working for income"
    },
    {
      "name": "X7603",
      "description": "Intentional self-harm by smoke, fire and flames at home While engaged in other types of work"
    },
    {
      "name": "X7604",
      "description": "Intentional self-harm by smoke, fire and flames at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7608",
      "description": "Intentional self-harm by smoke, fire and flames at home While engaged in other specified activities"
    },
    {
      "name": "X7609",
      "description": "Intentional self-harm by smoke, fire and flames at home During unspecified activity"
    },
    {
      "name": "X761",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution"
    },
    {
      "name": "X7610",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While engaged in sports activity"
    },
    {
      "name": "X7611",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7612",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While working for income"
    },
    {
      "name": "X7613",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While engaged in other types of work"
    },
    {
      "name": "X7614",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7618",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7619",
      "description": "Intentional self-harm by smoke, fire and flames at residential institution During unspecified activity"
    },
    {
      "name": "X762",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area"
    },
    {
      "name": "X7620",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7621",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7622",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7623",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7624",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7628",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7629",
      "description": "Intentional self-harm by smoke, fire and flames at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X763",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area"
    },
    {
      "name": "X7630",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7631",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7632",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While working for income"
    },
    {
      "name": "X7633",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7634",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7638",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7639",
      "description": "Intentional self-harm by smoke, fire and flames at sports and athletics area During unspecified activity"
    },
    {
      "name": "X764",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway"
    },
    {
      "name": "X7640",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While engaged in sports activity"
    },
    {
      "name": "X7641",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7642",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While working for income"
    },
    {
      "name": "X7643",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While engaged in other types of work"
    },
    {
      "name": "X7644",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7648",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7649",
      "description": "Intentional self-harm by smoke, fire and flames at street and highway During unspecified activity"
    },
    {
      "name": "X765",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area"
    },
    {
      "name": "X7650",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7651",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7652",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While working for income"
    },
    {
      "name": "X7653",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7654",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7658",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7659",
      "description": "Intentional self-harm by smoke, fire and flames at trade and service area During unspecified activity"
    },
    {
      "name": "X766",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area"
    },
    {
      "name": "X7660",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7661",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7662",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While working for income"
    },
    {
      "name": "X7663",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7664",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7668",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7669",
      "description": "Intentional self-harm by smoke, fire and flames at industrial and construction area During unspecified activity"
    },
    {
      "name": "X767",
      "description": "Intentional self-harm by smoke, fire and flames at farm"
    },
    {
      "name": "X7670",
      "description": "Intentional self-harm by smoke, fire and flames at farm While engaged in sports activity"
    },
    {
      "name": "X7671",
      "description": "Intentional self-harm by smoke, fire and flames at farm While engaged in leisure activity"
    },
    {
      "name": "X7672",
      "description": "Intentional self-harm by smoke, fire and flames at farm While working for income"
    },
    {
      "name": "X7673",
      "description": "Intentional self-harm by smoke, fire and flames at farm While engaged in other types of work"
    },
    {
      "name": "X7674",
      "description": "Intentional self-harm by smoke, fire and flames at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7678",
      "description": "Intentional self-harm by smoke, fire and flames at farm While engaged in other specified activities"
    },
    {
      "name": "X7679",
      "description": "Intentional self-harm by smoke, fire and flames at farm During unspecified activity"
    },
    {
      "name": "X768",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places"
    },
    {
      "name": "X7680",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While engaged in sports activity"
    },
    {
      "name": "X7681",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7682",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While working for income"
    },
    {
      "name": "X7683",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While engaged in other types of work"
    },
    {
      "name": "X7684",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7688",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7689",
      "description": "Intentional self-harm by smoke, fire and flames at other specified places During unspecified activity"
    },
    {
      "name": "X769",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place"
    },
    {
      "name": "X7690",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7691",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7692",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While working for income"
    },
    {
      "name": "X7693",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7694",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7698",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7699",
      "description": "Intentional self-harm by smoke, fire and flames at unspecified place During unspecified activity"
    },
    {
      "name": "X770",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home"
    },
    {
      "name": "X7700",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While engaged in sports activity"
    },
    {
      "name": "X7701",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While engaged in leisure activity"
    },
    {
      "name": "X7702",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While working for income"
    },
    {
      "name": "X7703",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While engaged in other types of work"
    },
    {
      "name": "X7704",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7708",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home While engaged in other specified activities"
    },
    {
      "name": "X7709",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at home During unspecified activity"
    },
    {
      "name": "X771",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution"
    },
    {
      "name": "X7710",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While engaged in sports activity"
    },
    {
      "name": "X7711",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7712",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While working for income"
    },
    {
      "name": "X7713",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While engaged in other types of work"
    },
    {
      "name": "X7714",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7718",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7719",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at residential institution During unspecified activity"
    },
    {
      "name": "X772",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area"
    },
    {
      "name": "X7720",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7721",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7722",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7723",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7724",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7728",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7729",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X773",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area"
    },
    {
      "name": "X7730",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7731",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7732",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While working for income"
    },
    {
      "name": "X7733",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7734",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7738",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7739",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at sports and athletics area During unspecified activity"
    },
    {
      "name": "X774",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway"
    },
    {
      "name": "X7740",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While engaged in sports activity"
    },
    {
      "name": "X7741",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7742",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While working for income"
    },
    {
      "name": "X7743",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While engaged in other types of work"
    },
    {
      "name": "X7744",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7748",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7749",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at street and highway During unspecified activity"
    },
    {
      "name": "X775",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area"
    },
    {
      "name": "X7750",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7751",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7752",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While working for income"
    },
    {
      "name": "X7753",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7754",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7758",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7759",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at trade and service area During unspecified activity"
    },
    {
      "name": "X776",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area"
    },
    {
      "name": "X7760",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7761",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7762",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While working for income"
    },
    {
      "name": "X7763",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7764",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7768",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7769",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at industrial and construction area During unspecified activity"
    },
    {
      "name": "X777",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm"
    },
    {
      "name": "X7770",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While engaged in sports activity"
    },
    {
      "name": "X7771",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While engaged in leisure activity"
    },
    {
      "name": "X7772",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While working for income"
    },
    {
      "name": "X7773",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While engaged in other types of work"
    },
    {
      "name": "X7774",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7778",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm While engaged in other specified activities"
    },
    {
      "name": "X7779",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at farm During unspecified activity"
    },
    {
      "name": "X778",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places"
    },
    {
      "name": "X7780",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While engaged in sports activity"
    },
    {
      "name": "X7781",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7782",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While working for income"
    },
    {
      "name": "X7783",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While engaged in other types of work"
    },
    {
      "name": "X7784",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7788",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7789",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at other specified places During unspecified activity"
    },
    {
      "name": "X779",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place"
    },
    {
      "name": "X7790",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7791",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7792",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While working for income"
    },
    {
      "name": "X7793",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7794",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7798",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7799",
      "description": "Intentional self-harm by steam, hot vapours and hot objects at unspecified place During unspecified activity"
    },
    {
      "name": "X780",
      "description": "Intentional self-harm by sharp object at home"
    },
    {
      "name": "X7800",
      "description": "Intentional self-harm by sharp object at home While engaged in sports activity"
    },
    {
      "name": "X7801",
      "description": "Intentional self-harm by sharp object at home While engaged in leisure activity"
    },
    {
      "name": "X7802",
      "description": "Intentional self-harm by sharp object at home While working for income"
    },
    {
      "name": "X7803",
      "description": "Intentional self-harm by sharp object at home While engaged in other types of work"
    },
    {
      "name": "X7804",
      "description": "Intentional self-harm by sharp object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7808",
      "description": "Intentional self-harm by sharp object at home While engaged in other specified activities"
    },
    {
      "name": "X7809",
      "description": "Intentional self-harm by sharp object at home During unspecified activity"
    },
    {
      "name": "X781",
      "description": "Intentional self-harm by sharp object at residential institution"
    },
    {
      "name": "X7810",
      "description": "Intentional self-harm by sharp object at residential institution While engaged in sports activity"
    },
    {
      "name": "X7811",
      "description": "Intentional self-harm by sharp object at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7812",
      "description": "Intentional self-harm by sharp object at residential institution While working for income"
    },
    {
      "name": "X7813",
      "description": "Intentional self-harm by sharp object at residential institution While engaged in other types of work"
    },
    {
      "name": "X7814",
      "description": "Intentional self-harm by sharp object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7818",
      "description": "Intentional self-harm by sharp object at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7819",
      "description": "Intentional self-harm by sharp object at residential institution During unspecified activity"
    },
    {
      "name": "X782",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area"
    },
    {
      "name": "X7820",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7821",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7822",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7823",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7824",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7828",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7829",
      "description": "Intentional self-harm by sharp object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X783",
      "description": "Intentional self-harm by sharp object at sports and athletics area"
    },
    {
      "name": "X7830",
      "description": "Intentional self-harm by sharp object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7831",
      "description": "Intentional self-harm by sharp object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7832",
      "description": "Intentional self-harm by sharp object at sports and athletics area While working for income"
    },
    {
      "name": "X7833",
      "description": "Intentional self-harm by sharp object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7834",
      "description": "Intentional self-harm by sharp object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7838",
      "description": "Intentional self-harm by sharp object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7839",
      "description": "Intentional self-harm by sharp object at sports and athletics area During unspecified activity"
    },
    {
      "name": "X784",
      "description": "Intentional self-harm by sharp object at street and highway"
    },
    {
      "name": "X7840",
      "description": "Intentional self-harm by sharp object at street and highway While engaged in sports activity"
    },
    {
      "name": "X7841",
      "description": "Intentional self-harm by sharp object at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7842",
      "description": "Intentional self-harm by sharp object at street and highway While working for income"
    },
    {
      "name": "X7843",
      "description": "Intentional self-harm by sharp object at street and highway While engaged in other types of work"
    },
    {
      "name": "X7844",
      "description": "Intentional self-harm by sharp object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7848",
      "description": "Intentional self-harm by sharp object at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7849",
      "description": "Intentional self-harm by sharp object at street and highway During unspecified activity"
    },
    {
      "name": "X785",
      "description": "Intentional self-harm by sharp object at trade and service area"
    },
    {
      "name": "X7850",
      "description": "Intentional self-harm by sharp object at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7851",
      "description": "Intentional self-harm by sharp object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7852",
      "description": "Intentional self-harm by sharp object at trade and service area While working for income"
    },
    {
      "name": "X7853",
      "description": "Intentional self-harm by sharp object at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7854",
      "description": "Intentional self-harm by sharp object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7858",
      "description": "Intentional self-harm by sharp object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7859",
      "description": "Intentional self-harm by sharp object at trade and service area During unspecified activity"
    },
    {
      "name": "X786",
      "description": "Intentional self-harm by sharp object at industrial and construction area"
    },
    {
      "name": "X7860",
      "description": "Intentional self-harm by sharp object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7861",
      "description": "Intentional self-harm by sharp object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7862",
      "description": "Intentional self-harm by sharp object at industrial and construction area While working for income"
    },
    {
      "name": "X7863",
      "description": "Intentional self-harm by sharp object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7864",
      "description": "Intentional self-harm by sharp object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7868",
      "description": "Intentional self-harm by sharp object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7869",
      "description": "Intentional self-harm by sharp object at industrial and construction area During unspecified activity"
    },
    {
      "name": "X787",
      "description": "Intentional self-harm by sharp object at farm"
    },
    {
      "name": "X7870",
      "description": "Intentional self-harm by sharp object at farm While engaged in sports activity"
    },
    {
      "name": "X7871",
      "description": "Intentional self-harm by sharp object at farm While engaged in leisure activity"
    },
    {
      "name": "X7872",
      "description": "Intentional self-harm by sharp object at farm While working for income"
    },
    {
      "name": "X7873",
      "description": "Intentional self-harm by sharp object at farm While engaged in other types of work"
    },
    {
      "name": "X7874",
      "description": "Intentional self-harm by sharp object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7878",
      "description": "Intentional self-harm by sharp object at farm While engaged in other specified activities"
    },
    {
      "name": "X7879",
      "description": "Intentional self-harm by sharp object at farm During unspecified activity"
    },
    {
      "name": "X788",
      "description": "Intentional self-harm by sharp object at other specified places"
    },
    {
      "name": "X7880",
      "description": "Intentional self-harm by sharp object at other specified places While engaged in sports activity"
    },
    {
      "name": "X7881",
      "description": "Intentional self-harm by sharp object at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7882",
      "description": "Intentional self-harm by sharp object at other specified places While working for income"
    },
    {
      "name": "X7883",
      "description": "Intentional self-harm by sharp object at other specified places While engaged in other types of work"
    },
    {
      "name": "X7884",
      "description": "Intentional self-harm by sharp object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7888",
      "description": "Intentional self-harm by sharp object at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7889",
      "description": "Intentional self-harm by sharp object at other specified places During unspecified activity"
    },
    {
      "name": "X789",
      "description": "Intentional self-harm by sharp object at unspecified place"
    },
    {
      "name": "X7890",
      "description": "Intentional self-harm by sharp object at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7891",
      "description": "Intentional self-harm by sharp object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7892",
      "description": "Intentional self-harm by sharp object at unspecified place While working for income"
    },
    {
      "name": "X7893",
      "description": "Intentional self-harm by sharp object at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7894",
      "description": "Intentional self-harm by sharp object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7898",
      "description": "Intentional self-harm by sharp object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7899",
      "description": "Intentional self-harm by sharp object at unspecified place During unspecified activity"
    },
    {
      "name": "X790",
      "description": "Intentional self-harm by blunt  object at home"
    },
    {
      "name": "X7900",
      "description": "Intentional self-harm by blunt  object at home While engaged in sports activity"
    },
    {
      "name": "X7901",
      "description": "Intentional self-harm by blunt  object at home While engaged in leisure activity"
    },
    {
      "name": "X7902",
      "description": "Intentional self-harm by blunt  object at home While working for income"
    },
    {
      "name": "X7903",
      "description": "Intentional self-harm by blunt  object at home While engaged in other types of work"
    },
    {
      "name": "X7904",
      "description": "Intentional self-harm by blunt  object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7908",
      "description": "Intentional self-harm by blunt  object at home While engaged in other specified activities"
    },
    {
      "name": "X7909",
      "description": "Intentional self-harm by blunt  object at home During unspecified activity"
    },
    {
      "name": "X791",
      "description": "Intentional self-harm by blunt  object at residential institution"
    },
    {
      "name": "X7910",
      "description": "Intentional self-harm by blunt  object at residential institution While engaged in sports activity"
    },
    {
      "name": "X7911",
      "description": "Intentional self-harm by blunt  object at residential institution While engaged in leisure activity"
    },
    {
      "name": "X7912",
      "description": "Intentional self-harm by blunt  object at residential institution While working for income"
    },
    {
      "name": "X7913",
      "description": "Intentional self-harm by blunt  object at residential institution While engaged in other types of work"
    },
    {
      "name": "X7914",
      "description": "Intentional self-harm by blunt  object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7918",
      "description": "Intentional self-harm by blunt  object at residential institution While engaged in other specified activities"
    },
    {
      "name": "X7919",
      "description": "Intentional self-harm by blunt  object at residential institution During unspecified activity"
    },
    {
      "name": "X792",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area"
    },
    {
      "name": "X7920",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X7921",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X7922",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X7923",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X7924",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7928",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X7929",
      "description": "Intentional self-harm by blunt  object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X793",
      "description": "Intentional self-harm by blunt  object at sports and athletics area"
    },
    {
      "name": "X7930",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X7931",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X7932",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While working for income"
    },
    {
      "name": "X7933",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X7934",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7938",
      "description": "Intentional self-harm by blunt  object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X7939",
      "description": "Intentional self-harm by blunt  object at sports and athletics area During unspecified activity"
    },
    {
      "name": "X794",
      "description": "Intentional self-harm by blunt  object at street and highway"
    },
    {
      "name": "X7940",
      "description": "Intentional self-harm by blunt  object at street and highway While engaged in sports activity"
    },
    {
      "name": "X7941",
      "description": "Intentional self-harm by blunt  object at street and highway While engaged in leisure activity"
    },
    {
      "name": "X7942",
      "description": "Intentional self-harm by blunt  object at street and highway While working for income"
    },
    {
      "name": "X7943",
      "description": "Intentional self-harm by blunt  object at street and highway While engaged in other types of work"
    },
    {
      "name": "X7944",
      "description": "Intentional self-harm by blunt  object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7948",
      "description": "Intentional self-harm by blunt  object at street and highway While engaged in other specified activities"
    },
    {
      "name": "X7949",
      "description": "Intentional self-harm by blunt  object at street and highway During unspecified activity"
    },
    {
      "name": "X795",
      "description": "Intentional self-harm by blunt  object at trade and service area"
    },
    {
      "name": "X7950",
      "description": "Intentional self-harm by blunt  object at trade and service area While engaged in sports activity"
    },
    {
      "name": "X7951",
      "description": "Intentional self-harm by blunt  object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X7952",
      "description": "Intentional self-harm by blunt  object at trade and service area While working for income"
    },
    {
      "name": "X7953",
      "description": "Intentional self-harm by blunt  object at trade and service area While engaged in other types of work"
    },
    {
      "name": "X7954",
      "description": "Intentional self-harm by blunt  object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7958",
      "description": "Intentional self-harm by blunt  object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X7959",
      "description": "Intentional self-harm by blunt  object at trade and service area During unspecified activity"
    },
    {
      "name": "X796",
      "description": "Intentional self-harm by blunt  object at industrial and construction area"
    },
    {
      "name": "X7960",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X7961",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X7962",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While working for income"
    },
    {
      "name": "X7963",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X7964",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7968",
      "description": "Intentional self-harm by blunt  object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X7969",
      "description": "Intentional self-harm by blunt  object at industrial and construction area During unspecified activity"
    },
    {
      "name": "X797",
      "description": "Intentional self-harm by blunt  object at farm"
    },
    {
      "name": "X7970",
      "description": "Intentional self-harm by blunt  object at farm While engaged in sports activity"
    },
    {
      "name": "X7971",
      "description": "Intentional self-harm by blunt  object at farm While engaged in leisure activity"
    },
    {
      "name": "X7972",
      "description": "Intentional self-harm by blunt  object at farm While working for income"
    },
    {
      "name": "X7973",
      "description": "Intentional self-harm by blunt  object at farm While engaged in other types of work"
    },
    {
      "name": "X7974",
      "description": "Intentional self-harm by blunt  object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7978",
      "description": "Intentional self-harm by blunt  object at farm While engaged in other specified activities"
    },
    {
      "name": "X7979",
      "description": "Intentional self-harm by blunt  object at farm During unspecified activity"
    },
    {
      "name": "X798",
      "description": "Intentional self-harm by blunt  object at other specified places"
    },
    {
      "name": "X7980",
      "description": "Intentional self-harm by blunt  object at other specified places While engaged in sports activity"
    },
    {
      "name": "X7981",
      "description": "Intentional self-harm by blunt  object at other specified places While engaged in leisure activity"
    },
    {
      "name": "X7982",
      "description": "Intentional self-harm by blunt  object at other specified places While working for income"
    },
    {
      "name": "X7983",
      "description": "Intentional self-harm by blunt  object at other specified places While engaged in other types of work"
    },
    {
      "name": "X7984",
      "description": "Intentional self-harm by blunt  object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7988",
      "description": "Intentional self-harm by blunt  object at other specified places While engaged in other specified activities"
    },
    {
      "name": "X7989",
      "description": "Intentional self-harm by blunt  object at other specified places During unspecified activity"
    },
    {
      "name": "X799",
      "description": "Intentional self-harm by blunt  object at unspecified place"
    },
    {
      "name": "X7990",
      "description": "Intentional self-harm by blunt  object at unspecified place While engaged in sports activity"
    },
    {
      "name": "X7991",
      "description": "Intentional self-harm by blunt  object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X7992",
      "description": "Intentional self-harm by blunt  object at unspecified place While working for income"
    },
    {
      "name": "X7993",
      "description": "Intentional self-harm by blunt  object at unspecified place While engaged in other types of work"
    },
    {
      "name": "X7994",
      "description": "Intentional self-harm by blunt  object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X7998",
      "description": "Intentional self-harm by blunt  object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X7999",
      "description": "Intentional self-harm by blunt  object at unspecified place During unspecified activity"
    },
    {
      "name": "X800",
      "description": "Intentional self-harm by jumping  from a high place at home"
    },
    {
      "name": "X8000",
      "description": "Intentional self-harm by jumping  from a high place at home While engaged in sports activity"
    },
    {
      "name": "X8001",
      "description": "Intentional self-harm by jumping  from a high place at home While engaged in leisure activity"
    },
    {
      "name": "X8002",
      "description": "Intentional self-harm by jumping  from a high place at home While working for income"
    },
    {
      "name": "X8003",
      "description": "Intentional self-harm by jumping  from a high place at home While engaged in other types of work"
    },
    {
      "name": "X8004",
      "description": "Intentional self-harm by jumping  from a high place at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8008",
      "description": "Intentional self-harm by jumping  from a high place at home While engaged in other specified activities"
    },
    {
      "name": "X8009",
      "description": "Intentional self-harm by jumping  from a high place at home During unspecified activity"
    },
    {
      "name": "X801",
      "description": "Intentional self-harm by jumping  from a high place at residential institution"
    },
    {
      "name": "X8010",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While engaged in sports activity"
    },
    {
      "name": "X8011",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8012",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While working for income"
    },
    {
      "name": "X8013",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While engaged in other types of work"
    },
    {
      "name": "X8014",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8018",
      "description": "Intentional self-harm by jumping  from a high place at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8019",
      "description": "Intentional self-harm by jumping  from a high place at residential institution During unspecified activity"
    },
    {
      "name": "X802",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area"
    },
    {
      "name": "X8020",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8021",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8022",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8023",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8024",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8028",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8029",
      "description": "Intentional self-harm by jumping  from a high place at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X803",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area"
    },
    {
      "name": "X8030",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8031",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8032",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While working for income"
    },
    {
      "name": "X8033",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8034",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8038",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8039",
      "description": "Intentional self-harm by jumping  from a high place at sports and athletics area During unspecified activity"
    },
    {
      "name": "X804",
      "description": "Intentional self-harm by jumping  from a high place at street and highway"
    },
    {
      "name": "X8040",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While engaged in sports activity"
    },
    {
      "name": "X8041",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8042",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While working for income"
    },
    {
      "name": "X8043",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While engaged in other types of work"
    },
    {
      "name": "X8044",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8048",
      "description": "Intentional self-harm by jumping  from a high place at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8049",
      "description": "Intentional self-harm by jumping  from a high place at street and highway During unspecified activity"
    },
    {
      "name": "X805",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area"
    },
    {
      "name": "X8050",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8051",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8052",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While working for income"
    },
    {
      "name": "X8053",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8054",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8058",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8059",
      "description": "Intentional self-harm by jumping  from a high place at trade and service area During unspecified activity"
    },
    {
      "name": "X806",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area"
    },
    {
      "name": "X8060",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8061",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8062",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While working for income"
    },
    {
      "name": "X8063",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8064",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8068",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8069",
      "description": "Intentional self-harm by jumping  from a high place at industrial and construction area During unspecified activity"
    },
    {
      "name": "X807",
      "description": "Intentional self-harm by jumping  from a high place at farm"
    },
    {
      "name": "X8070",
      "description": "Intentional self-harm by jumping  from a high place at farm While engaged in sports activity"
    },
    {
      "name": "X8071",
      "description": "Intentional self-harm by jumping  from a high place at farm While engaged in leisure activity"
    },
    {
      "name": "X8072",
      "description": "Intentional self-harm by jumping  from a high place at farm While working for income"
    },
    {
      "name": "X8073",
      "description": "Intentional self-harm by jumping  from a high place at farm While engaged in other types of work"
    },
    {
      "name": "X8074",
      "description": "Intentional self-harm by jumping  from a high place at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8078",
      "description": "Intentional self-harm by jumping  from a high place at farm While engaged in other specified activities"
    },
    {
      "name": "X8079",
      "description": "Intentional self-harm by jumping  from a high place at farm During unspecified activity"
    },
    {
      "name": "X808",
      "description": "Intentional self-harm by jumping  from a high place at other specified places"
    },
    {
      "name": "X8080",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While engaged in sports activity"
    },
    {
      "name": "X8081",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8082",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While working for income"
    },
    {
      "name": "X8083",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While engaged in other types of work"
    },
    {
      "name": "X8084",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8088",
      "description": "Intentional self-harm by jumping  from a high place at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8089",
      "description": "Intentional self-harm by jumping  from a high place at other specified places During unspecified activity"
    },
    {
      "name": "X809",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place"
    },
    {
      "name": "X8090",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8091",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8092",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While working for income"
    },
    {
      "name": "X8093",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8094",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8098",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8099",
      "description": "Intentional self-harm by jumping  from a high place at unspecified place During unspecified activity"
    },
    {
      "name": "X810",
      "description": "Intentional self-harm by jumping or lying before moving object at home"
    },
    {
      "name": "X8100",
      "description": "Intentional self-harm by jumping or lying before moving object at home While engaged in sports activity"
    },
    {
      "name": "X8101",
      "description": "Intentional self-harm by jumping or lying before moving object at home While engaged in leisure activity"
    },
    {
      "name": "X8102",
      "description": "Intentional self-harm by jumping or lying before moving object at home While working for income"
    },
    {
      "name": "X8103",
      "description": "Intentional self-harm by jumping or lying before moving object at home While engaged in other types of work"
    },
    {
      "name": "X8104",
      "description": "Intentional self-harm by jumping or lying before moving object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8108",
      "description": "Intentional self-harm by jumping or lying before moving object at home While engaged in other specified activities"
    },
    {
      "name": "X8109",
      "description": "Intentional self-harm by jumping or lying before moving object at home During unspecified activity"
    },
    {
      "name": "X811",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution"
    },
    {
      "name": "X8110",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While engaged in sports activity"
    },
    {
      "name": "X8111",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8112",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While working for income"
    },
    {
      "name": "X8113",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While engaged in other types of work"
    },
    {
      "name": "X8114",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8118",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8119",
      "description": "Intentional self-harm by jumping or lying before moving object at residential institution During unspecified activity"
    },
    {
      "name": "X812",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area"
    },
    {
      "name": "X8120",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8121",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8122",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8123",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8124",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8128",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8129",
      "description": "Intentional self-harm by jumping or lying before moving object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X813",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area"
    },
    {
      "name": "X8130",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8131",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8132",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While working for income"
    },
    {
      "name": "X8133",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8134",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8138",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8139",
      "description": "Intentional self-harm by jumping or lying before moving object at sports and athletics area During unspecified activity"
    },
    {
      "name": "X814",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway"
    },
    {
      "name": "X8140",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While engaged in sports activity"
    },
    {
      "name": "X8141",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8142",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While working for income"
    },
    {
      "name": "X8143",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While engaged in other types of work"
    },
    {
      "name": "X8144",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8148",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8149",
      "description": "Intentional self-harm by jumping or lying before moving object at street and highway During unspecified activity"
    },
    {
      "name": "X815",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area"
    },
    {
      "name": "X8150",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8151",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8152",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While working for income"
    },
    {
      "name": "X8153",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8154",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8158",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8159",
      "description": "Intentional self-harm by jumping or lying before moving object at trade and service area During unspecified activity"
    },
    {
      "name": "X816",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area"
    },
    {
      "name": "X8160",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8161",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8162",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While working for income"
    },
    {
      "name": "X8163",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8164",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8168",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8169",
      "description": "Intentional self-harm by jumping or lying before moving object at industrial and construction area During unspecified activity"
    },
    {
      "name": "X817",
      "description": "Intentional self-harm by jumping or lying before moving object at farm"
    },
    {
      "name": "X8170",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While engaged in sports activity"
    },
    {
      "name": "X8171",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While engaged in leisure activity"
    },
    {
      "name": "X8172",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While working for income"
    },
    {
      "name": "X8173",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While engaged in other types of work"
    },
    {
      "name": "X8174",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8178",
      "description": "Intentional self-harm by jumping or lying before moving object at farm While engaged in other specified activities"
    },
    {
      "name": "X8179",
      "description": "Intentional self-harm by jumping or lying before moving object at farm During unspecified activity"
    },
    {
      "name": "X818",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places"
    },
    {
      "name": "X8180",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While engaged in sports activity"
    },
    {
      "name": "X8181",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8182",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While working for income"
    },
    {
      "name": "X8183",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While engaged in other types of work"
    },
    {
      "name": "X8184",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8188",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8189",
      "description": "Intentional self-harm by jumping or lying before moving object at other specified places During unspecified activity"
    },
    {
      "name": "X819",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place"
    },
    {
      "name": "X8190",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8191",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8192",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While working for income"
    },
    {
      "name": "X8193",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8194",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8198",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8199",
      "description": "Intentional self-harm by jumping or lying before moving object at unspecified place During unspecified activity"
    },
    {
      "name": "X820",
      "description": "Intentional self-harm by crashing of motor vehicle at home"
    },
    {
      "name": "X8200",
      "description": "Intentional self-harm by crashing of motor vehicle at home While engaged in sports activity"
    },
    {
      "name": "X8201",
      "description": "Intentional self-harm by crashing of motor vehicle at home While engaged in leisure activity"
    },
    {
      "name": "X8202",
      "description": "Intentional self-harm by crashing of motor vehicle at home While working for income"
    },
    {
      "name": "X8203",
      "description": "Intentional self-harm by crashing of motor vehicle at home While engaged in other types of work"
    },
    {
      "name": "X8204",
      "description": "Intentional self-harm by crashing of motor vehicle at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8208",
      "description": "Intentional self-harm by crashing of motor vehicle at home While engaged in other specified activities"
    },
    {
      "name": "X8209",
      "description": "Intentional self-harm by crashing of motor vehicle at home During unspecified activity"
    },
    {
      "name": "X821",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution"
    },
    {
      "name": "X8210",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While engaged in sports activity"
    },
    {
      "name": "X8211",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8212",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While working for income"
    },
    {
      "name": "X8213",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While engaged in other types of work"
    },
    {
      "name": "X8214",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8218",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8219",
      "description": "Intentional self-harm by crashing of motor vehicle at residential institution During unspecified activity"
    },
    {
      "name": "X822",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area"
    },
    {
      "name": "X8220",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8221",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8222",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8223",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8224",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8228",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8229",
      "description": "Intentional self-harm by crashing of motor vehicle at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X823",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area"
    },
    {
      "name": "X8230",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8231",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8232",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While working for income"
    },
    {
      "name": "X8233",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8234",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8238",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8239",
      "description": "Intentional self-harm by crashing of motor vehicle at sports and athletics area During unspecified activity"
    },
    {
      "name": "X824",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway"
    },
    {
      "name": "X8240",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While engaged in sports activity"
    },
    {
      "name": "X8241",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8242",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While working for income"
    },
    {
      "name": "X8243",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While engaged in other types of work"
    },
    {
      "name": "X8244",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8248",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8249",
      "description": "Intentional self-harm by crashing of motor vehicle at street and highway During unspecified activity"
    },
    {
      "name": "X825",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area"
    },
    {
      "name": "X8250",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8251",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8252",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While working for income"
    },
    {
      "name": "X8253",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8254",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8258",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8259",
      "description": "Intentional self-harm by crashing of motor vehicle at trade and service area During unspecified activity"
    },
    {
      "name": "X826",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area"
    },
    {
      "name": "X8260",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8261",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8262",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While working for income"
    },
    {
      "name": "X8263",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8264",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8268",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8269",
      "description": "Intentional self-harm by crashing of motor vehicle at industrial and construction area During unspecified activity"
    },
    {
      "name": "X827",
      "description": "Intentional self-harm by crashing of motor vehicle at farm"
    },
    {
      "name": "X8270",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While engaged in sports activity"
    },
    {
      "name": "X8271",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While engaged in leisure activity"
    },
    {
      "name": "X8272",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While working for income"
    },
    {
      "name": "X8273",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While engaged in other types of work"
    },
    {
      "name": "X8274",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8278",
      "description": "Intentional self-harm by crashing of motor vehicle at farm While engaged in other specified activities"
    },
    {
      "name": "X8279",
      "description": "Intentional self-harm by crashing of motor vehicle at farm During unspecified activity"
    },
    {
      "name": "X828",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places"
    },
    {
      "name": "X8280",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While engaged in sports activity"
    },
    {
      "name": "X8281",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8282",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While working for income"
    },
    {
      "name": "X8283",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While engaged in other types of work"
    },
    {
      "name": "X8284",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8288",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8289",
      "description": "Intentional self-harm by crashing of motor vehicle at other specified places During unspecified activity"
    },
    {
      "name": "X829",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place"
    },
    {
      "name": "X8290",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8291",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8292",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While working for income"
    },
    {
      "name": "X8293",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8294",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8298",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8299",
      "description": "Intentional self-harm by crashing of motor vehicle at unspecified place During unspecified activity"
    },
    {
      "name": "X830",
      "description": "Intentional self-harm by other specifiecd means at home"
    },
    {
      "name": "X8300",
      "description": "Intentional self-harm by other specifiecd means at home While engaged in sports activity"
    },
    {
      "name": "X8301",
      "description": "Intentional self-harm by other specifiecd means at home While engaged in leisure activity"
    },
    {
      "name": "X8302",
      "description": "Intentional self-harm by other specifiecd means at home While working for income"
    },
    {
      "name": "X8303",
      "description": "Intentional self-harm by other specifiecd means at home While engaged in other types of work"
    },
    {
      "name": "X8304",
      "description": "Intentional self-harm by other specifiecd means at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8308",
      "description": "Intentional self-harm by other specifiecd means at home While engaged in other specified activities"
    },
    {
      "name": "X8309",
      "description": "Intentional self-harm by other specifiecd means at home During unspecified activity"
    },
    {
      "name": "X831",
      "description": "Intentional self-harm by other specifiecd means at residential institution"
    },
    {
      "name": "X8310",
      "description": "Intentional self-harm by other specifiecd means at residential institution While engaged in sports activity"
    },
    {
      "name": "X8311",
      "description": "Intentional self-harm by other specifiecd means at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8312",
      "description": "Intentional self-harm by other specifiecd means at residential institution While working for income"
    },
    {
      "name": "X8313",
      "description": "Intentional self-harm by other specifiecd means at residential institution While engaged in other types of work"
    },
    {
      "name": "X8314",
      "description": "Intentional self-harm by other specifiecd means at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8318",
      "description": "Intentional self-harm by other specifiecd means at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8319",
      "description": "Intentional self-harm by other specifiecd means at residential institution During unspecified activity"
    },
    {
      "name": "X832",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area"
    },
    {
      "name": "X8320",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8321",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8322",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8323",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8324",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8328",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8329",
      "description": "Intentional self-harm by other specifiecd means at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X833",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area"
    },
    {
      "name": "X8330",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8331",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8332",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While working for income"
    },
    {
      "name": "X8333",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8334",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8338",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8339",
      "description": "Intentional self-harm by other specifiecd means at sports and athletics area During unspecified activity"
    },
    {
      "name": "X834",
      "description": "Intentional self-harm by other specifiecd means at street and highway"
    },
    {
      "name": "X8340",
      "description": "Intentional self-harm by other specifiecd means at street and highway While engaged in sports activity"
    },
    {
      "name": "X8341",
      "description": "Intentional self-harm by other specifiecd means at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8342",
      "description": "Intentional self-harm by other specifiecd means at street and highway While working for income"
    },
    {
      "name": "X8343",
      "description": "Intentional self-harm by other specifiecd means at street and highway While engaged in other types of work"
    },
    {
      "name": "X8344",
      "description": "Intentional self-harm by other specifiecd means at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8348",
      "description": "Intentional self-harm by other specifiecd means at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8349",
      "description": "Intentional self-harm by other specifiecd means at street and highway During unspecified activity"
    },
    {
      "name": "X835",
      "description": "Intentional self-harm by other specifiecd means at trade and service area"
    },
    {
      "name": "X8350",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8351",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8352",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While working for income"
    },
    {
      "name": "X8353",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8354",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8358",
      "description": "Intentional self-harm by other specifiecd means at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8359",
      "description": "Intentional self-harm by other specifiecd means at trade and service area During unspecified activity"
    },
    {
      "name": "X836",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area"
    },
    {
      "name": "X8360",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8361",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8362",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While working for income"
    },
    {
      "name": "X8363",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8364",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8368",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8369",
      "description": "Intentional self-harm by other specifiecd means at industrial and construction area During unspecified activity"
    },
    {
      "name": "X837",
      "description": "Intentional self-harm by other specifiecd means at farm"
    },
    {
      "name": "X8370",
      "description": "Intentional self-harm by other specifiecd means at farm While engaged in sports activity"
    },
    {
      "name": "X8371",
      "description": "Intentional self-harm by other specifiecd means at farm While engaged in leisure activity"
    },
    {
      "name": "X8372",
      "description": "Intentional self-harm by other specifiecd means at farm While working for income"
    },
    {
      "name": "X8373",
      "description": "Intentional self-harm by other specifiecd means at farm While engaged in other types of work"
    },
    {
      "name": "X8374",
      "description": "Intentional self-harm by other specifiecd means at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8378",
      "description": "Intentional self-harm by other specifiecd means at farm While engaged in other specified activities"
    },
    {
      "name": "X8379",
      "description": "Intentional self-harm by other specifiecd means at farm During unspecified activity"
    },
    {
      "name": "X838",
      "description": "Intentional self-harm by other specifiecd means at other specified places"
    },
    {
      "name": "X8380",
      "description": "Intentional self-harm by other specifiecd means at other specified places While engaged in sports activity"
    },
    {
      "name": "X8381",
      "description": "Intentional self-harm by other specifiecd means at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8382",
      "description": "Intentional self-harm by other specifiecd means at other specified places While working for income"
    },
    {
      "name": "X8383",
      "description": "Intentional self-harm by other specifiecd means at other specified places While engaged in other types of work"
    },
    {
      "name": "X8384",
      "description": "Intentional self-harm by other specifiecd means at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8388",
      "description": "Intentional self-harm by other specifiecd means at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8389",
      "description": "Intentional self-harm by other specifiecd means at other specified places During unspecified activity"
    },
    {
      "name": "X839",
      "description": "Intentional self-harm by other specifiecd means at unspecified place"
    },
    {
      "name": "X8390",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8391",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8392",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While working for income"
    },
    {
      "name": "X8393",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8394",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8398",
      "description": "Intentional self-harm by other specifiecd means at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8399",
      "description": "Intentional self-harm by other specifiecd means at unspecified place During unspecified activity"
    },
    {
      "name": "X840",
      "description": "Intentional self-harm by unspecified means at home"
    },
    {
      "name": "X8400",
      "description": "Intentional self-harm by unspecified means at home While engaged in sports activity"
    },
    {
      "name": "X8401",
      "description": "Intentional self-harm by unspecified means at home While engaged in leisure activity"
    },
    {
      "name": "X8402",
      "description": "Intentional self-harm by unspecified means at home While working for income"
    },
    {
      "name": "X8403",
      "description": "Intentional self-harm by unspecified means at home While engaged in other types of work"
    },
    {
      "name": "X8404",
      "description": "Intentional self-harm by unspecified means at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8408",
      "description": "Intentional self-harm by unspecified means at home While engaged in other specified activities"
    },
    {
      "name": "X8409",
      "description": "Intentional self-harm by unspecified means at home During unspecified activity"
    },
    {
      "name": "X841",
      "description": "Intentional self-harm by unspecified means at residential institution"
    },
    {
      "name": "X8410",
      "description": "Intentional self-harm by unspecified means at residential institution While engaged in sports activity"
    },
    {
      "name": "X8411",
      "description": "Intentional self-harm by unspecified means at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8412",
      "description": "Intentional self-harm by unspecified means at residential institution While working for income"
    },
    {
      "name": "X8413",
      "description": "Intentional self-harm by unspecified means at residential institution While engaged in other types of work"
    },
    {
      "name": "X8414",
      "description": "Intentional self-harm by unspecified means at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8418",
      "description": "Intentional self-harm by unspecified means at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8419",
      "description": "Intentional self-harm by unspecified means at residential institution During unspecified activity"
    },
    {
      "name": "X842",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area"
    },
    {
      "name": "X8420",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8421",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8422",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8423",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8424",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8428",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8429",
      "description": "Intentional self-harm by unspecified means at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X843",
      "description": "Intentional self-harm by unspecified means at sports and athletics area"
    },
    {
      "name": "X8430",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8431",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8432",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While working for income"
    },
    {
      "name": "X8433",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8434",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8438",
      "description": "Intentional self-harm by unspecified means at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8439",
      "description": "Intentional self-harm by unspecified means at sports and athletics area During unspecified activity"
    },
    {
      "name": "X844",
      "description": "Intentional self-harm by unspecified means at street and highway"
    },
    {
      "name": "X8440",
      "description": "Intentional self-harm by unspecified means at street and highway While engaged in sports activity"
    },
    {
      "name": "X8441",
      "description": "Intentional self-harm by unspecified means at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8442",
      "description": "Intentional self-harm by unspecified means at street and highway While working for income"
    },
    {
      "name": "X8443",
      "description": "Intentional self-harm by unspecified means at street and highway While engaged in other types of work"
    },
    {
      "name": "X8444",
      "description": "Intentional self-harm by unspecified means at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8448",
      "description": "Intentional self-harm by unspecified means at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8449",
      "description": "Intentional self-harm by unspecified means at street and highway During unspecified activity"
    },
    {
      "name": "X845",
      "description": "Intentional self-harm by unspecified means at trade and service area"
    },
    {
      "name": "X8450",
      "description": "Intentional self-harm by unspecified means at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8451",
      "description": "Intentional self-harm by unspecified means at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8452",
      "description": "Intentional self-harm by unspecified means at trade and service area While working for income"
    },
    {
      "name": "X8453",
      "description": "Intentional self-harm by unspecified means at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8454",
      "description": "Intentional self-harm by unspecified means at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8458",
      "description": "Intentional self-harm by unspecified means at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8459",
      "description": "Intentional self-harm by unspecified means at trade and service area During unspecified activity"
    },
    {
      "name": "X846",
      "description": "Intentional self-harm by unspecified means at industrial and construction area"
    },
    {
      "name": "X8460",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8461",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8462",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While working for income"
    },
    {
      "name": "X8463",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8464",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8468",
      "description": "Intentional self-harm by unspecified means at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8469",
      "description": "Intentional self-harm by unspecified means at industrial and construction area During unspecified activity"
    },
    {
      "name": "X847",
      "description": "Intentional self-harm by unspecified means at farm"
    },
    {
      "name": "X8470",
      "description": "Intentional self-harm by unspecified means at farm While engaged in sports activity"
    },
    {
      "name": "X8471",
      "description": "Intentional self-harm by unspecified means at farm While engaged in leisure activity"
    },
    {
      "name": "X8472",
      "description": "Intentional self-harm by unspecified means at farm While working for income"
    },
    {
      "name": "X8473",
      "description": "Intentional self-harm by unspecified means at farm While engaged in other types of work"
    },
    {
      "name": "X8474",
      "description": "Intentional self-harm by unspecified means at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8478",
      "description": "Intentional self-harm by unspecified means at farm While engaged in other specified activities"
    },
    {
      "name": "X8479",
      "description": "Intentional self-harm by unspecified means at farm During unspecified activity"
    },
    {
      "name": "X848",
      "description": "Intentional self-harm by unspecified means at other specified places"
    },
    {
      "name": "X8480",
      "description": "Intentional self-harm by unspecified means at other specified places While engaged in sports activity"
    },
    {
      "name": "X8481",
      "description": "Intentional self-harm by unspecified means at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8482",
      "description": "Intentional self-harm by unspecified means at other specified places While working for income"
    },
    {
      "name": "X8483",
      "description": "Intentional self-harm by unspecified means at other specified places While engaged in other types of work"
    },
    {
      "name": "X8484",
      "description": "Intentional self-harm by unspecified means at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8488",
      "description": "Intentional self-harm by unspecified means at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8489",
      "description": "Intentional self-harm by unspecified means at other specified places During unspecified activity"
    },
    {
      "name": "X849",
      "description": "Intentional self-harm by unspecified means at unspecified place"
    },
    {
      "name": "X8490",
      "description": "Intentional self-harm by unspecified means at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8491",
      "description": "Intentional self-harm by unspecified means at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8492",
      "description": "Intentional self-harm by unspecified means at unspecified place While working for income"
    },
    {
      "name": "X8493",
      "description": "Intentional self-harm by unspecified means at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8494",
      "description": "Intentional self-harm by unspecified means at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8498",
      "description": "Intentional self-harm by unspecified means at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8499",
      "description": "Intentional self-harm by unspecified means at unspecified place During unspecified activity"
    },
    {
      "name": "X850",
      "description": "Assault by drugs, medicaments and biological substances at home"
    },
    {
      "name": "X8500",
      "description": "Assault by drugs, medicaments and biological substances at home While engaged in sports activity"
    },
    {
      "name": "X8501",
      "description": "Assault by drugs, medicaments and biological substances at home While engaged in leisure activity"
    },
    {
      "name": "X8502",
      "description": "Assault by drugs, medicaments and biological substances at home While working for income"
    },
    {
      "name": "X8503",
      "description": "Assault by drugs, medicaments and biological substances at home While engaged in other types of work"
    },
    {
      "name": "X8504",
      "description": "Assault by drugs, medicaments and biological substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8508",
      "description": "Assault by drugs, medicaments and biological substances at home While engaged in other specified activities"
    },
    {
      "name": "X8509",
      "description": "Assault by drugs, medicaments and biological substances at home During unspecified activity"
    },
    {
      "name": "X851",
      "description": "Assault by drugs, medicaments and biological substances at residential institution"
    },
    {
      "name": "X8510",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X8511",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8512",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While working for income"
    },
    {
      "name": "X8513",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X8514",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8518",
      "description": "Assault by drugs, medicaments and biological substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8519",
      "description": "Assault by drugs, medicaments and biological substances at residential institution During unspecified activity"
    },
    {
      "name": "X852",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area"
    },
    {
      "name": "X8520",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8521",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8522",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8523",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8524",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8528",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8529",
      "description": "Assault by drugs, medicaments and biological substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X853",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area"
    },
    {
      "name": "X8530",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8531",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8532",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While working for income"
    },
    {
      "name": "X8533",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8534",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8538",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8539",
      "description": "Assault by drugs, medicaments and biological substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X854",
      "description": "Assault by drugs, medicaments and biological substances at street and highway"
    },
    {
      "name": "X8540",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X8541",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8542",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While working for income"
    },
    {
      "name": "X8543",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X8544",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8548",
      "description": "Assault by drugs, medicaments and biological substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8549",
      "description": "Assault by drugs, medicaments and biological substances at street and highway During unspecified activity"
    },
    {
      "name": "X855",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area"
    },
    {
      "name": "X8550",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8551",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8552",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While working for income"
    },
    {
      "name": "X8553",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8554",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8558",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8559",
      "description": "Assault by drugs, medicaments and biological substances at trade and service area During unspecified activity"
    },
    {
      "name": "X856",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area"
    },
    {
      "name": "X8560",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8561",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8562",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While working for income"
    },
    {
      "name": "X8563",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8564",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8568",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8569",
      "description": "Assault by drugs, medicaments and biological substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X857",
      "description": "Assault by drugs, medicaments and biological substances at farm"
    },
    {
      "name": "X8570",
      "description": "Assault by drugs, medicaments and biological substances at farm While engaged in sports activity"
    },
    {
      "name": "X8571",
      "description": "Assault by drugs, medicaments and biological substances at farm While engaged in leisure activity"
    },
    {
      "name": "X8572",
      "description": "Assault by drugs, medicaments and biological substances at farm While working for income"
    },
    {
      "name": "X8573",
      "description": "Assault by drugs, medicaments and biological substances at farm While engaged in other types of work"
    },
    {
      "name": "X8574",
      "description": "Assault by drugs, medicaments and biological substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8578",
      "description": "Assault by drugs, medicaments and biological substances at farm While engaged in other specified activities"
    },
    {
      "name": "X8579",
      "description": "Assault by drugs, medicaments and biological substances at farm During unspecified activity"
    },
    {
      "name": "X858",
      "description": "Assault by drugs, medicaments and biological substances at other specified places"
    },
    {
      "name": "X8580",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X8581",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8582",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While working for income"
    },
    {
      "name": "X8583",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X8584",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8588",
      "description": "Assault by drugs, medicaments and biological substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8589",
      "description": "Assault by drugs, medicaments and biological substances at other specified places During unspecified activity"
    },
    {
      "name": "X859",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place"
    },
    {
      "name": "X8590",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8591",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8592",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While working for income"
    },
    {
      "name": "X8593",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8594",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8598",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8599",
      "description": "Assault by drugs, medicaments and biological substances at unspecified place During unspecified activity"
    },
    {
      "name": "X860",
      "description": "Assault by corrosive substance at home"
    },
    {
      "name": "X8600",
      "description": "Assault by corrosive substance at home While engaged in sports activity"
    },
    {
      "name": "X8601",
      "description": "Assault by corrosive substance at home While engaged in leisure activity"
    },
    {
      "name": "X8602",
      "description": "Assault by corrosive substance at home While working for income"
    },
    {
      "name": "X8603",
      "description": "Assault by corrosive substance at home While engaged in other types of work"
    },
    {
      "name": "X8604",
      "description": "Assault by corrosive substance at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8608",
      "description": "Assault by corrosive substance at home While engaged in other specified activities"
    },
    {
      "name": "X8609",
      "description": "Assault by corrosive substance at home During unspecified activity"
    },
    {
      "name": "X861",
      "description": "Assault by corrosive substance at residential institution"
    },
    {
      "name": "X8610",
      "description": "Assault by corrosive substance at residential institution While engaged in sports activity"
    },
    {
      "name": "X8611",
      "description": "Assault by corrosive substance at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8612",
      "description": "Assault by corrosive substance at residential institution While working for income"
    },
    {
      "name": "X8613",
      "description": "Assault by corrosive substance at residential institution While engaged in other types of work"
    },
    {
      "name": "X8614",
      "description": "Assault by corrosive substance at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8618",
      "description": "Assault by corrosive substance at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8619",
      "description": "Assault by corrosive substance at residential institution During unspecified activity"
    },
    {
      "name": "X862",
      "description": "Assault by corrosive substance at school,other institution and public administrative area"
    },
    {
      "name": "X8620",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8621",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8622",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8623",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8624",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8628",
      "description": "Assault by corrosive substance at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8629",
      "description": "Assault by corrosive substance at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X863",
      "description": "Assault by corrosive substance at sports and athletics area"
    },
    {
      "name": "X8630",
      "description": "Assault by corrosive substance at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8631",
      "description": "Assault by corrosive substance at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8632",
      "description": "Assault by corrosive substance at sports and athletics area While working for income"
    },
    {
      "name": "X8633",
      "description": "Assault by corrosive substance at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8634",
      "description": "Assault by corrosive substance at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8638",
      "description": "Assault by corrosive substance at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8639",
      "description": "Assault by corrosive substance at sports and athletics area During unspecified activity"
    },
    {
      "name": "X864",
      "description": "Assault by corrosive substance at street and highway"
    },
    {
      "name": "X8640",
      "description": "Assault by corrosive substance at street and highway While engaged in sports activity"
    },
    {
      "name": "X8641",
      "description": "Assault by corrosive substance at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8642",
      "description": "Assault by corrosive substance at street and highway While working for income"
    },
    {
      "name": "X8643",
      "description": "Assault by corrosive substance at street and highway While engaged in other types of work"
    },
    {
      "name": "X8644",
      "description": "Assault by corrosive substance at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8648",
      "description": "Assault by corrosive substance at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8649",
      "description": "Assault by corrosive substance at street and highway During unspecified activity"
    },
    {
      "name": "X865",
      "description": "Assault by corrosive substance at trade and service area"
    },
    {
      "name": "X8650",
      "description": "Assault by corrosive substance at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8651",
      "description": "Assault by corrosive substance at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8652",
      "description": "Assault by corrosive substance at trade and service area While working for income"
    },
    {
      "name": "X8653",
      "description": "Assault by corrosive substance at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8654",
      "description": "Assault by corrosive substance at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8658",
      "description": "Assault by corrosive substance at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8659",
      "description": "Assault by corrosive substance at trade and service area During unspecified activity"
    },
    {
      "name": "X866",
      "description": "Assault by corrosive substance at industrial and construction area"
    },
    {
      "name": "X8660",
      "description": "Assault by corrosive substance at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8661",
      "description": "Assault by corrosive substance at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8662",
      "description": "Assault by corrosive substance at industrial and construction area While working for income"
    },
    {
      "name": "X8663",
      "description": "Assault by corrosive substance at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8664",
      "description": "Assault by corrosive substance at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8668",
      "description": "Assault by corrosive substance at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8669",
      "description": "Assault by corrosive substance at industrial and construction area During unspecified activity"
    },
    {
      "name": "X867",
      "description": "Assault by corrosive substance at farm"
    },
    {
      "name": "X8670",
      "description": "Assault by corrosive substance at farm While engaged in sports activity"
    },
    {
      "name": "X8671",
      "description": "Assault by corrosive substance at farm While engaged in leisure activity"
    },
    {
      "name": "X8672",
      "description": "Assault by corrosive substance at farm While working for income"
    },
    {
      "name": "X8673",
      "description": "Assault by corrosive substance at farm While engaged in other types of work"
    },
    {
      "name": "X8674",
      "description": "Assault by corrosive substance at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8678",
      "description": "Assault by corrosive substance at farm While engaged in other specified activities"
    },
    {
      "name": "X8679",
      "description": "Assault by corrosive substance at farm During unspecified activity"
    },
    {
      "name": "X868",
      "description": "Assault by corrosive substance at other specified places"
    },
    {
      "name": "X8680",
      "description": "Assault by corrosive substance at other specified places While engaged in sports activity"
    },
    {
      "name": "X8681",
      "description": "Assault by corrosive substance at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8682",
      "description": "Assault by corrosive substance at other specified places While working for income"
    },
    {
      "name": "X8683",
      "description": "Assault by corrosive substance at other specified places While engaged in other types of work"
    },
    {
      "name": "X8684",
      "description": "Assault by corrosive substance at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8688",
      "description": "Assault by corrosive substance at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8689",
      "description": "Assault by corrosive substance at other specified places During unspecified activity"
    },
    {
      "name": "X869",
      "description": "Assault by corrosive substance at unspecified place"
    },
    {
      "name": "X8690",
      "description": "Assault by corrosive substance at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8691",
      "description": "Assault by corrosive substance at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8692",
      "description": "Assault by corrosive substance at unspecified place While working for income"
    },
    {
      "name": "X8693",
      "description": "Assault by corrosive substance at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8694",
      "description": "Assault by corrosive substance at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8698",
      "description": "Assault by corrosive substance at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8699",
      "description": "Assault by corrosive substance at unspecified place During unspecified activity"
    },
    {
      "name": "X870",
      "description": "Assault by pesticides at home"
    },
    {
      "name": "X8700",
      "description": "Assault by pesticides at home While engaged in sports activity"
    },
    {
      "name": "X8701",
      "description": "Assault by pesticides at home While engaged in leisure activity"
    },
    {
      "name": "X8702",
      "description": "Assault by pesticides at home While working for income"
    },
    {
      "name": "X8703",
      "description": "Assault by pesticides at home While engaged in other types of work"
    },
    {
      "name": "X8704",
      "description": "Assault by pesticides at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8708",
      "description": "Assault by pesticides at home While engaged in other specified activities"
    },
    {
      "name": "X8709",
      "description": "Assault by pesticides at home During unspecified activity"
    },
    {
      "name": "X871",
      "description": "Assault by pesticides at residential institution"
    },
    {
      "name": "X8710",
      "description": "Assault by pesticides at residential institution While engaged in sports activity"
    },
    {
      "name": "X8711",
      "description": "Assault by pesticides at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8712",
      "description": "Assault by pesticides at residential institution While working for income"
    },
    {
      "name": "X8713",
      "description": "Assault by pesticides at residential institution While engaged in other types of work"
    },
    {
      "name": "X8714",
      "description": "Assault by pesticides at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8718",
      "description": "Assault by pesticides at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8719",
      "description": "Assault by pesticides at residential institution During unspecified activity"
    },
    {
      "name": "X872",
      "description": "Assault by pesticides at school,other institution and public administrative area"
    },
    {
      "name": "X8720",
      "description": "Assault by pesticides at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8721",
      "description": "Assault by pesticides at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8722",
      "description": "Assault by pesticides at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8723",
      "description": "Assault by pesticides at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8724",
      "description": "Assault by pesticides at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8728",
      "description": "Assault by pesticides at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8729",
      "description": "Assault by pesticides at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X873",
      "description": "Assault by pesticides at sports and athletics area"
    },
    {
      "name": "X8730",
      "description": "Assault by pesticides at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8731",
      "description": "Assault by pesticides at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8732",
      "description": "Assault by pesticides at sports and athletics area While working for income"
    },
    {
      "name": "X8733",
      "description": "Assault by pesticides at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8734",
      "description": "Assault by pesticides at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8738",
      "description": "Assault by pesticides at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8739",
      "description": "Assault by pesticides at sports and athletics area During unspecified activity"
    },
    {
      "name": "X874",
      "description": "Assault by pesticides at street and highway"
    },
    {
      "name": "X8740",
      "description": "Assault by pesticides at street and highway While engaged in sports activity"
    },
    {
      "name": "X8741",
      "description": "Assault by pesticides at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8742",
      "description": "Assault by pesticides at street and highway While working for income"
    },
    {
      "name": "X8743",
      "description": "Assault by pesticides at street and highway While engaged in other types of work"
    },
    {
      "name": "X8744",
      "description": "Assault by pesticides at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8748",
      "description": "Assault by pesticides at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8749",
      "description": "Assault by pesticides at street and highway During unspecified activity"
    },
    {
      "name": "X875",
      "description": "Assault by pesticides at trade and service area"
    },
    {
      "name": "X8750",
      "description": "Assault by pesticides at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8751",
      "description": "Assault by pesticides at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8752",
      "description": "Assault by pesticides at trade and service area While working for income"
    },
    {
      "name": "X8753",
      "description": "Assault by pesticides at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8754",
      "description": "Assault by pesticides at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8758",
      "description": "Assault by pesticides at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8759",
      "description": "Assault by pesticides at trade and service area During unspecified activity"
    },
    {
      "name": "X876",
      "description": "Assault by pesticides at industrial and construction area"
    },
    {
      "name": "X8760",
      "description": "Assault by pesticides at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8761",
      "description": "Assault by pesticides at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8762",
      "description": "Assault by pesticides at industrial and construction area While working for income"
    },
    {
      "name": "X8763",
      "description": "Assault by pesticides at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8764",
      "description": "Assault by pesticides at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8768",
      "description": "Assault by pesticides at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8769",
      "description": "Assault by pesticides at industrial and construction area During unspecified activity"
    },
    {
      "name": "X877",
      "description": "Assault by pesticides at farm"
    },
    {
      "name": "X8770",
      "description": "Assault by pesticides at farm While engaged in sports activity"
    },
    {
      "name": "X8771",
      "description": "Assault by pesticides at farm While engaged in leisure activity"
    },
    {
      "name": "X8772",
      "description": "Assault by pesticides at farm While working for income"
    },
    {
      "name": "X8773",
      "description": "Assault by pesticides at farm While engaged in other types of work"
    },
    {
      "name": "X8774",
      "description": "Assault by pesticides at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8778",
      "description": "Assault by pesticides at farm While engaged in other specified activities"
    },
    {
      "name": "X8779",
      "description": "Assault by pesticides at farm During unspecified activity"
    },
    {
      "name": "X878",
      "description": "Assault by pesticides at other specified places"
    },
    {
      "name": "X8780",
      "description": "Assault by pesticides at other specified places While engaged in sports activity"
    },
    {
      "name": "X8781",
      "description": "Assault by pesticides at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8782",
      "description": "Assault by pesticides at other specified places While working for income"
    },
    {
      "name": "X8783",
      "description": "Assault by pesticides at other specified places While engaged in other types of work"
    },
    {
      "name": "X8784",
      "description": "Assault by pesticides at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8788",
      "description": "Assault by pesticides at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8789",
      "description": "Assault by pesticides at other specified places During unspecified activity"
    },
    {
      "name": "X879",
      "description": "Assault by pesticides at unspecified place"
    },
    {
      "name": "X8790",
      "description": "Assault by pesticides at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8791",
      "description": "Assault by pesticides at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8792",
      "description": "Assault by pesticides at unspecified place While working for income"
    },
    {
      "name": "X8793",
      "description": "Assault by pesticides at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8794",
      "description": "Assault by pesticides at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8798",
      "description": "Assault by pesticides at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8799",
      "description": "Assault by pesticides at unspecified place During unspecified activity"
    },
    {
      "name": "X880",
      "description": "Assault by gases and vapours at home"
    },
    {
      "name": "X8800",
      "description": "Assault by gases and vapours at home While engaged in sports activity"
    },
    {
      "name": "X8801",
      "description": "Assault by gases and vapours at home While engaged in leisure activity"
    },
    {
      "name": "X8802",
      "description": "Assault by gases and vapours at home While working for income"
    },
    {
      "name": "X8803",
      "description": "Assault by gases and vapours at home While engaged in other types of work"
    },
    {
      "name": "X8804",
      "description": "Assault by gases and vapours at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8808",
      "description": "Assault by gases and vapours at home While engaged in other specified activities"
    },
    {
      "name": "X8809",
      "description": "Assault by gases and vapours at home During unspecified activity"
    },
    {
      "name": "X881",
      "description": "Assault by gases and vapours at residential institution"
    },
    {
      "name": "X8810",
      "description": "Assault by gases and vapours at residential institution While engaged in sports activity"
    },
    {
      "name": "X8811",
      "description": "Assault by gases and vapours at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8812",
      "description": "Assault by gases and vapours at residential institution While working for income"
    },
    {
      "name": "X8813",
      "description": "Assault by gases and vapours at residential institution While engaged in other types of work"
    },
    {
      "name": "X8814",
      "description": "Assault by gases and vapours at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8818",
      "description": "Assault by gases and vapours at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8819",
      "description": "Assault by gases and vapours at residential institution During unspecified activity"
    },
    {
      "name": "X882",
      "description": "Assault by gases and vapours at school,other institution and public administrative area"
    },
    {
      "name": "X8820",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8821",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8822",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8823",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8824",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8828",
      "description": "Assault by gases and vapours at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8829",
      "description": "Assault by gases and vapours at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X883",
      "description": "Assault by gases and vapours at sports and athletics area"
    },
    {
      "name": "X8830",
      "description": "Assault by gases and vapours at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8831",
      "description": "Assault by gases and vapours at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8832",
      "description": "Assault by gases and vapours at sports and athletics area While working for income"
    },
    {
      "name": "X8833",
      "description": "Assault by gases and vapours at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8834",
      "description": "Assault by gases and vapours at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8838",
      "description": "Assault by gases and vapours at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8839",
      "description": "Assault by gases and vapours at sports and athletics area During unspecified activity"
    },
    {
      "name": "X884",
      "description": "Assault by gases and vapours at street and highway"
    },
    {
      "name": "X8840",
      "description": "Assault by gases and vapours at street and highway While engaged in sports activity"
    },
    {
      "name": "X8841",
      "description": "Assault by gases and vapours at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8842",
      "description": "Assault by gases and vapours at street and highway While working for income"
    },
    {
      "name": "X8843",
      "description": "Assault by gases and vapours at street and highway While engaged in other types of work"
    },
    {
      "name": "X8844",
      "description": "Assault by gases and vapours at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8848",
      "description": "Assault by gases and vapours at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8849",
      "description": "Assault by gases and vapours at street and highway During unspecified activity"
    },
    {
      "name": "X885",
      "description": "Assault by gases and vapours at trade and service area"
    },
    {
      "name": "X8850",
      "description": "Assault by gases and vapours at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8851",
      "description": "Assault by gases and vapours at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8852",
      "description": "Assault by gases and vapours at trade and service area While working for income"
    },
    {
      "name": "X8853",
      "description": "Assault by gases and vapours at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8854",
      "description": "Assault by gases and vapours at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8858",
      "description": "Assault by gases and vapours at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8859",
      "description": "Assault by gases and vapours at trade and service area During unspecified activity"
    },
    {
      "name": "X886",
      "description": "Assault by gases and vapours at industrial and construction area"
    },
    {
      "name": "X8860",
      "description": "Assault by gases and vapours at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8861",
      "description": "Assault by gases and vapours at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8862",
      "description": "Assault by gases and vapours at industrial and construction area While working for income"
    },
    {
      "name": "X8863",
      "description": "Assault by gases and vapours at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8864",
      "description": "Assault by gases and vapours at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8868",
      "description": "Assault by gases and vapours at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8869",
      "description": "Assault by gases and vapours at industrial and construction area During unspecified activity"
    },
    {
      "name": "X887",
      "description": "Assault by gases and vapours at farm"
    },
    {
      "name": "X8870",
      "description": "Assault by gases and vapours at farm While engaged in sports activity"
    },
    {
      "name": "X8871",
      "description": "Assault by gases and vapours at farm While engaged in leisure activity"
    },
    {
      "name": "X8872",
      "description": "Assault by gases and vapours at farm While working for income"
    },
    {
      "name": "X8873",
      "description": "Assault by gases and vapours at farm While engaged in other types of work"
    },
    {
      "name": "X8874",
      "description": "Assault by gases and vapours at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8878",
      "description": "Assault by gases and vapours at farm While engaged in other specified activities"
    },
    {
      "name": "X8879",
      "description": "Assault by gases and vapours at farm During unspecified activity"
    },
    {
      "name": "X888",
      "description": "Assault by gases and vapours at other specified places"
    },
    {
      "name": "X8880",
      "description": "Assault by gases and vapours at other specified places While engaged in sports activity"
    },
    {
      "name": "X8881",
      "description": "Assault by gases and vapours at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8882",
      "description": "Assault by gases and vapours at other specified places While working for income"
    },
    {
      "name": "X8883",
      "description": "Assault by gases and vapours at other specified places While engaged in other types of work"
    },
    {
      "name": "X8884",
      "description": "Assault by gases and vapours at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8888",
      "description": "Assault by gases and vapours at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8889",
      "description": "Assault by gases and vapours at other specified places During unspecified activity"
    },
    {
      "name": "X889",
      "description": "Assault by gases and vapours at unspecified place"
    },
    {
      "name": "X8890",
      "description": "Assault by gases and vapours at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8891",
      "description": "Assault by gases and vapours at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8892",
      "description": "Assault by gases and vapours at unspecified place While working for income"
    },
    {
      "name": "X8893",
      "description": "Assault by gases and vapours at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8894",
      "description": "Assault by gases and vapours at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8898",
      "description": "Assault by gases and vapours at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8899",
      "description": "Assault by gases and vapours at unspecified place During unspecified activity"
    },
    {
      "name": "X890",
      "description": "Assault by other specified chemicals and noxious substances at home"
    },
    {
      "name": "X8900",
      "description": "Assault by other specified chemicals and noxious substances at home While engaged in sports activity"
    },
    {
      "name": "X8901",
      "description": "Assault by other specified chemicals and noxious substances at home While engaged in leisure activity"
    },
    {
      "name": "X8902",
      "description": "Assault by other specified chemicals and noxious substances at home While working for income"
    },
    {
      "name": "X8903",
      "description": "Assault by other specified chemicals and noxious substances at home While engaged in other types of work"
    },
    {
      "name": "X8904",
      "description": "Assault by other specified chemicals and noxious substances at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8908",
      "description": "Assault by other specified chemicals and noxious substances at home While engaged in other specified activities"
    },
    {
      "name": "X8909",
      "description": "Assault by other specified chemicals and noxious substances at home During unspecified activity"
    },
    {
      "name": "X891",
      "description": "Assault by other specified chemicals and noxious substances at residential institution"
    },
    {
      "name": "X8910",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While engaged in sports activity"
    },
    {
      "name": "X8911",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While engaged in leisure activity"
    },
    {
      "name": "X8912",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While working for income"
    },
    {
      "name": "X8913",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While engaged in other types of work"
    },
    {
      "name": "X8914",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8918",
      "description": "Assault by other specified chemicals and noxious substances at residential institution While engaged in other specified activities"
    },
    {
      "name": "X8919",
      "description": "Assault by other specified chemicals and noxious substances at residential institution During unspecified activity"
    },
    {
      "name": "X892",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area"
    },
    {
      "name": "X8920",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X8921",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X8922",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X8923",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X8924",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8928",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X8929",
      "description": "Assault by other specified chemicals and noxious substances at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X893",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area"
    },
    {
      "name": "X8930",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X8931",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X8932",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While working for income"
    },
    {
      "name": "X8933",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X8934",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8938",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X8939",
      "description": "Assault by other specified chemicals and noxious substances at sports and athletics area During unspecified activity"
    },
    {
      "name": "X894",
      "description": "Assault by other specified chemicals and noxious substances at street and highway"
    },
    {
      "name": "X8940",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While engaged in sports activity"
    },
    {
      "name": "X8941",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While engaged in leisure activity"
    },
    {
      "name": "X8942",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While working for income"
    },
    {
      "name": "X8943",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While engaged in other types of work"
    },
    {
      "name": "X8944",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8948",
      "description": "Assault by other specified chemicals and noxious substances at street and highway While engaged in other specified activities"
    },
    {
      "name": "X8949",
      "description": "Assault by other specified chemicals and noxious substances at street and highway During unspecified activity"
    },
    {
      "name": "X895",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area"
    },
    {
      "name": "X8950",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While engaged in sports activity"
    },
    {
      "name": "X8951",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X8952",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While working for income"
    },
    {
      "name": "X8953",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While engaged in other types of work"
    },
    {
      "name": "X8954",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8958",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X8959",
      "description": "Assault by other specified chemicals and noxious substances at trade and service area During unspecified activity"
    },
    {
      "name": "X896",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area"
    },
    {
      "name": "X8960",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X8961",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X8962",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While working for income"
    },
    {
      "name": "X8963",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X8964",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8968",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X8969",
      "description": "Assault by other specified chemicals and noxious substances at industrial and construction area During unspecified activity"
    },
    {
      "name": "X897",
      "description": "Assault by other specified chemicals and noxious substances at farm"
    },
    {
      "name": "X8970",
      "description": "Assault by other specified chemicals and noxious substances at farm While engaged in sports activity"
    },
    {
      "name": "X8971",
      "description": "Assault by other specified chemicals and noxious substances at farm While engaged in leisure activity"
    },
    {
      "name": "X8972",
      "description": "Assault by other specified chemicals and noxious substances at farm While working for income"
    },
    {
      "name": "X8973",
      "description": "Assault by other specified chemicals and noxious substances at farm While engaged in other types of work"
    },
    {
      "name": "X8974",
      "description": "Assault by other specified chemicals and noxious substances at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8978",
      "description": "Assault by other specified chemicals and noxious substances at farm While engaged in other specified activities"
    },
    {
      "name": "X8979",
      "description": "Assault by other specified chemicals and noxious substances at farm During unspecified activity"
    },
    {
      "name": "X898",
      "description": "Assault by other specified chemicals and noxious substances at other specified places"
    },
    {
      "name": "X8980",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While engaged in sports activity"
    },
    {
      "name": "X8981",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While engaged in leisure activity"
    },
    {
      "name": "X8982",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While working for income"
    },
    {
      "name": "X8983",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While engaged in other types of work"
    },
    {
      "name": "X8984",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8988",
      "description": "Assault by other specified chemicals and noxious substances at other specified places While engaged in other specified activities"
    },
    {
      "name": "X8989",
      "description": "Assault by other specified chemicals and noxious substances at other specified places During unspecified activity"
    },
    {
      "name": "X899",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place"
    },
    {
      "name": "X8990",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While engaged in sports activity"
    },
    {
      "name": "X8991",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X8992",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While working for income"
    },
    {
      "name": "X8993",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While engaged in other types of work"
    },
    {
      "name": "X8994",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X8998",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X8999",
      "description": "Assault by other specified chemicals and noxious substances at unspecified place During unspecified activity"
    },
    {
      "name": "X900",
      "description": "Assault by unspecified chemical or noxious substance at home"
    },
    {
      "name": "X9000",
      "description": "Assault by unspecified chemical or noxious substance at home While engaged in sports activity"
    },
    {
      "name": "X9001",
      "description": "Assault by unspecified chemical or noxious substance at home While engaged in leisure activity"
    },
    {
      "name": "X9002",
      "description": "Assault by unspecified chemical or noxious substance at home While working for income"
    },
    {
      "name": "X9003",
      "description": "Assault by unspecified chemical or noxious substance at home While engaged in other types of work"
    },
    {
      "name": "X9004",
      "description": "Assault by unspecified chemical or noxious substance at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9008",
      "description": "Assault by unspecified chemical or noxious substance at home While engaged in other specified activities"
    },
    {
      "name": "X9009",
      "description": "Assault by unspecified chemical or noxious substance at home During unspecified activity"
    },
    {
      "name": "X901",
      "description": "Assault by unspecified chemical or noxious substance at residential institution"
    },
    {
      "name": "X9010",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While engaged in sports activity"
    },
    {
      "name": "X9011",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9012",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While working for income"
    },
    {
      "name": "X9013",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While engaged in other types of work"
    },
    {
      "name": "X9014",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9018",
      "description": "Assault by unspecified chemical or noxious substance at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9019",
      "description": "Assault by unspecified chemical or noxious substance at residential institution During unspecified activity"
    },
    {
      "name": "X902",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area"
    },
    {
      "name": "X9020",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9021",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9022",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9023",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9024",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9028",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9029",
      "description": "Assault by unspecified chemical or noxious substance at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X903",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area"
    },
    {
      "name": "X9030",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9031",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9032",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While working for income"
    },
    {
      "name": "X9033",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9034",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9038",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9039",
      "description": "Assault by unspecified chemical or noxious substance at sports and athletics area During unspecified activity"
    },
    {
      "name": "X904",
      "description": "Assault by unspecified chemical or noxious substance at street and highway"
    },
    {
      "name": "X9040",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While engaged in sports activity"
    },
    {
      "name": "X9041",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9042",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While working for income"
    },
    {
      "name": "X9043",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While engaged in other types of work"
    },
    {
      "name": "X9044",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9048",
      "description": "Assault by unspecified chemical or noxious substance at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9049",
      "description": "Assault by unspecified chemical or noxious substance at street and highway During unspecified activity"
    },
    {
      "name": "X905",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area"
    },
    {
      "name": "X9050",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9051",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9052",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While working for income"
    },
    {
      "name": "X9053",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9054",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9058",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9059",
      "description": "Assault by unspecified chemical or noxious substance at trade and service area During unspecified activity"
    },
    {
      "name": "X906",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area"
    },
    {
      "name": "X9060",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9061",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9062",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While working for income"
    },
    {
      "name": "X9063",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9064",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9068",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9069",
      "description": "Assault by unspecified chemical or noxious substance at industrial and construction area During unspecified activity"
    },
    {
      "name": "X907",
      "description": "Assault by unspecified chemical or noxious substance at farm"
    },
    {
      "name": "X9070",
      "description": "Assault by unspecified chemical or noxious substance at farm While engaged in sports activity"
    },
    {
      "name": "X9071",
      "description": "Assault by unspecified chemical or noxious substance at farm While engaged in leisure activity"
    },
    {
      "name": "X9072",
      "description": "Assault by unspecified chemical or noxious substance at farm While working for income"
    },
    {
      "name": "X9073",
      "description": "Assault by unspecified chemical or noxious substance at farm While engaged in other types of work"
    },
    {
      "name": "X9074",
      "description": "Assault by unspecified chemical or noxious substance at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9078",
      "description": "Assault by unspecified chemical or noxious substance at farm While engaged in other specified activities"
    },
    {
      "name": "X9079",
      "description": "Assault by unspecified chemical or noxious substance at farm During unspecified activity"
    },
    {
      "name": "X908",
      "description": "Assault by unspecified chemical or noxious substance at other specified places"
    },
    {
      "name": "X9080",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While engaged in sports activity"
    },
    {
      "name": "X9081",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9082",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While working for income"
    },
    {
      "name": "X9083",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While engaged in other types of work"
    },
    {
      "name": "X9084",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9088",
      "description": "Assault by unspecified chemical or noxious substance at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9089",
      "description": "Assault by unspecified chemical or noxious substance at other specified places During unspecified activity"
    },
    {
      "name": "X909",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place"
    },
    {
      "name": "X9090",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9091",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9092",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While working for income"
    },
    {
      "name": "X9093",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9094",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9098",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9099",
      "description": "Assault by unspecified chemical or noxious substance at unspecified place During unspecified activity"
    },
    {
      "name": "X910",
      "description": "Assault by hanging, strangulation and suffocation at home"
    },
    {
      "name": "X9100",
      "description": "Assault by hanging, strangulation and suffocation at home While engaged in sports activity"
    },
    {
      "name": "X9101",
      "description": "Assault by hanging, strangulation and suffocation at home While engaged in leisure activity"
    },
    {
      "name": "X9102",
      "description": "Assault by hanging, strangulation and suffocation at home While working for income"
    },
    {
      "name": "X9103",
      "description": "Assault by hanging, strangulation and suffocation at home While engaged in other types of work"
    },
    {
      "name": "X9104",
      "description": "Assault by hanging, strangulation and suffocation at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9108",
      "description": "Assault by hanging, strangulation and suffocation at home While engaged in other specified activities"
    },
    {
      "name": "X9109",
      "description": "Assault by hanging, strangulation and suffocation at home During unspecified activity"
    },
    {
      "name": "X911",
      "description": "Assault by hanging, strangulation and suffocation at residential institution"
    },
    {
      "name": "X9110",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While engaged in sports activity"
    },
    {
      "name": "X9111",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9112",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While working for income"
    },
    {
      "name": "X9113",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While engaged in other types of work"
    },
    {
      "name": "X9114",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9118",
      "description": "Assault by hanging, strangulation and suffocation at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9119",
      "description": "Assault by hanging, strangulation and suffocation at residential institution During unspecified activity"
    },
    {
      "name": "X912",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area"
    },
    {
      "name": "X9120",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9121",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9122",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9123",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9124",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9128",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9129",
      "description": "Assault by hanging, strangulation and suffocation at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X913",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area"
    },
    {
      "name": "X9130",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9131",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9132",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While working for income"
    },
    {
      "name": "X9133",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9134",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9138",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9139",
      "description": "Assault by hanging, strangulation and suffocation at sports and athletics area During unspecified activity"
    },
    {
      "name": "X914",
      "description": "Assault by hanging, strangulation and suffocation at street and highway"
    },
    {
      "name": "X9140",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While engaged in sports activity"
    },
    {
      "name": "X9141",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9142",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While working for income"
    },
    {
      "name": "X9143",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While engaged in other types of work"
    },
    {
      "name": "X9144",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9148",
      "description": "Assault by hanging, strangulation and suffocation at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9149",
      "description": "Assault by hanging, strangulation and suffocation at street and highway During unspecified activity"
    },
    {
      "name": "X915",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area"
    },
    {
      "name": "X9150",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9151",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9152",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While working for income"
    },
    {
      "name": "X9153",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9154",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9158",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9159",
      "description": "Assault by hanging, strangulation and suffocation at trade and service area During unspecified activity"
    },
    {
      "name": "X916",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area"
    },
    {
      "name": "X9160",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9161",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9162",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While working for income"
    },
    {
      "name": "X9163",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9164",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9168",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9169",
      "description": "Assault by hanging, strangulation and suffocation at industrial and construction area During unspecified activity"
    },
    {
      "name": "X917",
      "description": "Assault by hanging, strangulation and suffocation at farm"
    },
    {
      "name": "X9170",
      "description": "Assault by hanging, strangulation and suffocation at farm While engaged in sports activity"
    },
    {
      "name": "X9171",
      "description": "Assault by hanging, strangulation and suffocation at farm While engaged in leisure activity"
    },
    {
      "name": "X9172",
      "description": "Assault by hanging, strangulation and suffocation at farm While working for income"
    },
    {
      "name": "X9173",
      "description": "Assault by hanging, strangulation and suffocation at farm While engaged in other types of work"
    },
    {
      "name": "X9174",
      "description": "Assault by hanging, strangulation and suffocation at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9178",
      "description": "Assault by hanging, strangulation and suffocation at farm While engaged in other specified activities"
    },
    {
      "name": "X9179",
      "description": "Assault by hanging, strangulation and suffocation at farm During unspecified activity"
    },
    {
      "name": "X918",
      "description": "Assault by hanging, strangulation and suffocation at other specified places"
    },
    {
      "name": "X9180",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While engaged in sports activity"
    },
    {
      "name": "X9181",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9182",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While working for income"
    },
    {
      "name": "X9183",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While engaged in other types of work"
    },
    {
      "name": "X9184",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9188",
      "description": "Assault by hanging, strangulation and suffocation at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9189",
      "description": "Assault by hanging, strangulation and suffocation at other specified places During unspecified activity"
    },
    {
      "name": "X919",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place"
    },
    {
      "name": "X9190",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9191",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9192",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place While working for income"
    },
    {
      "name": "X9193",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9194",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9198",
      "description": "Assault by hanging, strangulation and suffocation C32074at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9199",
      "description": "Assault by hanging, strangulation and suffocation at unspecified place During unspecified activity"
    },
    {
      "name": "X920",
      "description": "Assault by drowning and submersion at home"
    },
    {
      "name": "X9200",
      "description": "Assault by drowning and submersion at home While engaged in sports activity"
    },
    {
      "name": "X9201",
      "description": "Assault by drowning and submersion at home While engaged in leisure activity"
    },
    {
      "name": "X9202",
      "description": "Assault by drowning and submersion at home While working for income"
    },
    {
      "name": "X9203",
      "description": "Assault by drowning and submersion at home While engaged in other types of work"
    },
    {
      "name": "X9204",
      "description": "Assault by drowning and submersion at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9208",
      "description": "Assault by drowning and submersion at home While engaged in other specified activities"
    },
    {
      "name": "X9209",
      "description": "Assault by drowning and submersion at home During unspecified activity"
    },
    {
      "name": "X921",
      "description": "Assault by drowning and submersion at residential institution"
    },
    {
      "name": "X9210",
      "description": "Assault by drowning and submersion at residential institution While engaged in sports activity"
    },
    {
      "name": "X9211",
      "description": "Assault by drowning and submersion at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9212",
      "description": "Assault by drowning and submersion at residential institution While working for income"
    },
    {
      "name": "X9213",
      "description": "Assault by drowning and submersion at residential institution While engaged in other types of work"
    },
    {
      "name": "X9214",
      "description": "Assault by drowning and submersion at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9218",
      "description": "Assault by drowning and submersion at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9219",
      "description": "Assault by drowning and submersion at residential institution During unspecified activity"
    },
    {
      "name": "X922",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area"
    },
    {
      "name": "X9220",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9221",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9222",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9223",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9224",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9228",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9229",
      "description": "Assault by drowning and submersion at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X923",
      "description": "Assault by drowning and submersion at sports and athletics area"
    },
    {
      "name": "X9230",
      "description": "Assault by drowning and submersion at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9231",
      "description": "Assault by drowning and submersion at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9232",
      "description": "Assault by drowning and submersion at sports and athletics area While working for income"
    },
    {
      "name": "X9233",
      "description": "Assault by drowning and submersion at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9234",
      "description": "Assault by drowning and submersion at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9238",
      "description": "Assault by drowning and submersion at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9239",
      "description": "Assault by drowning and submersion at sports and athletics area During unspecified activity"
    },
    {
      "name": "X924",
      "description": "Assault by drowning and submersion at street and highway"
    },
    {
      "name": "X9240",
      "description": "Assault by drowning and submersion at street and highway While engaged in sports activity"
    },
    {
      "name": "X9241",
      "description": "Assault by drowning and submersion at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9242",
      "description": "Assault by drowning and submersion at street and highway While working for income"
    },
    {
      "name": "X9243",
      "description": "Assault by drowning and submersion at street and highway While engaged in other types of work"
    },
    {
      "name": "X9244",
      "description": "Assault by drowning and submersion at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9248",
      "description": "Assault by drowning and submersion at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9249",
      "description": "Assault by drowning and submersion at street and highway During unspecified activity"
    },
    {
      "name": "X925",
      "description": "Assault by drowning and submersion at trade and service area"
    },
    {
      "name": "X9250",
      "description": "Assault by drowning and submersion at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9251",
      "description": "Assault by drowning and submersion at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9252",
      "description": "Assault by drowning and submersion at trade and service area While working for income"
    },
    {
      "name": "X9253",
      "description": "Assault by drowning and submersion at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9254",
      "description": "Assault by drowning and submersion at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9258",
      "description": "Assault by drowning and submersion at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9259",
      "description": "Assault by drowning and submersion at trade and service area During unspecified activity"
    },
    {
      "name": "X926",
      "description": "Assault by drowning and submersion at industrial and construction area"
    },
    {
      "name": "X9260",
      "description": "Assault by drowning and submersion at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9261",
      "description": "Assault by drowning and submersion at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9262",
      "description": "Assault by drowning and submersion at industrial and construction area While working for income"
    },
    {
      "name": "X9263",
      "description": "Assault by drowning and submersion at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9264",
      "description": "Assault by drowning and submersion at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9268",
      "description": "Assault by drowning and submersion at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9269",
      "description": "Assault by drowning and submersion at industrial and construction area During unspecified activity"
    },
    {
      "name": "X927",
      "description": "Assault by drowning and submersion at farm"
    },
    {
      "name": "X9270",
      "description": "Assault by drowning and submersion at farm While engaged in sports activity"
    },
    {
      "name": "X9271",
      "description": "Assault by drowning and submersion at farm While engaged in leisure activity"
    },
    {
      "name": "X9272",
      "description": "Assault by drowning and submersion at farm While working for income"
    },
    {
      "name": "X9273",
      "description": "Assault by drowning and submersion at farm While engaged in other types of work"
    },
    {
      "name": "X9274",
      "description": "Assault by drowning and submersion at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9278",
      "description": "Assault by drowning and submersion at farm While engaged in other specified activities"
    },
    {
      "name": "X9279",
      "description": "Assault by drowning and submersion at farm During unspecified activity"
    },
    {
      "name": "X928",
      "description": "Assault by drowning and submersion at other specified places"
    },
    {
      "name": "X9280",
      "description": "Assault by drowning and submersion at other specified places While engaged in sports activity"
    },
    {
      "name": "X9281",
      "description": "Assault by drowning and submersion at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9282",
      "description": "Assault by drowning and submersion at other specified places While working for income"
    },
    {
      "name": "X9283",
      "description": "Assault by drowning and submersion at other specified places While engaged in other types of work"
    },
    {
      "name": "X9284",
      "description": "Assault by drowning and submersion at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9288",
      "description": "Assault by drowning and submersion at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9289",
      "description": "Assault by drowning and submersion at other specified places During unspecified activity"
    },
    {
      "name": "X929",
      "description": "Assault by drowning and submersion at unspecified place"
    },
    {
      "name": "X9290",
      "description": "Assault by drowning and submersion at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9291",
      "description": "Assault by drowning and submersion at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9292",
      "description": "Assault by drowning and submersion at unspecified place While working for income"
    },
    {
      "name": "X9293",
      "description": "Assault by drowning and submersion at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9294",
      "description": "Assault by drowning and submersion at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9298",
      "description": "Assault by drowning and submersion at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9299",
      "description": "Assault by drowning and submersion at unspecified place During unspecified activity"
    },
    {
      "name": "X930",
      "description": "Assault by handgun discharge at home"
    },
    {
      "name": "X9300",
      "description": "Assault by handgun discharge at home While engaged in sports activity"
    },
    {
      "name": "X9301",
      "description": "Assault by handgun discharge at home While engaged in leisure activity"
    },
    {
      "name": "X9302",
      "description": "Assault by handgun discharge at home While working for income"
    },
    {
      "name": "X9303",
      "description": "Assault by handgun discharge at home While engaged in other types of work"
    },
    {
      "name": "X9304",
      "description": "Assault by handgun discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9308",
      "description": "Assault by handgun discharge at home While engaged in other specified activities"
    },
    {
      "name": "X9309",
      "description": "Assault by handgun discharge at home During unspecified activity"
    },
    {
      "name": "X931",
      "description": "Assault by handgun discharge at residential institution"
    },
    {
      "name": "X9310",
      "description": "Assault by handgun discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X9311",
      "description": "Assault by handgun discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9312",
      "description": "Assault by handgun discharge at residential institution While working for income"
    },
    {
      "name": "X9313",
      "description": "Assault by handgun discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X9314",
      "description": "Assault by handgun discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9318",
      "description": "Assault by handgun discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9319",
      "description": "Assault by handgun discharge at residential institution During unspecified activity"
    },
    {
      "name": "X932",
      "description": "Assault by handgun discharge at school,other institution and public administrative area"
    },
    {
      "name": "X9320",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9321",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9322",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9323",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9324",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9328",
      "description": "Assault by handgun discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9329",
      "description": "Assault by handgun discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X933",
      "description": "Assault by handgun discharge at sports and athletics area"
    },
    {
      "name": "X9330",
      "description": "Assault by handgun discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9331",
      "description": "Assault by handgun discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9332",
      "description": "Assault by handgun discharge at sports and athletics area While working for income"
    },
    {
      "name": "X9333",
      "description": "Assault by handgun discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9334",
      "description": "Assault by handgun discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9338",
      "description": "Assault by handgun discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9339",
      "description": "Assault by handgun discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X934",
      "description": "Assault by handgun discharge at street and highway"
    },
    {
      "name": "X9340",
      "description": "Assault by handgun discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X9341",
      "description": "Assault by handgun discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9342",
      "description": "Assault by handgun discharge at street and highway While working for income"
    },
    {
      "name": "X9343",
      "description": "Assault by handgun discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X9344",
      "description": "Assault by handgun discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9348",
      "description": "Assault by handgun discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9349",
      "description": "Assault by handgun discharge at street and highway During unspecified activity"
    },
    {
      "name": "X935",
      "description": "Assault by handgun discharge at trade and service area"
    },
    {
      "name": "X9350",
      "description": "Assault by handgun discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9351",
      "description": "Assault by handgun discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9352",
      "description": "Assault by handgun discharge at trade and service area While working for income"
    },
    {
      "name": "X9353",
      "description": "Assault by handgun discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9354",
      "description": "Assault by handgun discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9358",
      "description": "Assault by handgun discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9359",
      "description": "Assault by handgun discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X936",
      "description": "Assault by handgun discharge at industrial and construction area"
    },
    {
      "name": "X9360",
      "description": "Assault by handgun discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9361",
      "description": "Assault by handgun discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9362",
      "description": "Assault by handgun discharge at industrial and construction area While working for income"
    },
    {
      "name": "X9363",
      "description": "Assault by handgun discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9364",
      "description": "Assault by handgun discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9368",
      "description": "Assault by handgun discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9369",
      "description": "Assault by handgun discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X937",
      "description": "Assault by handgun discharge at farm"
    },
    {
      "name": "X9370",
      "description": "Assault by handgun discharge at farm While engaged in sports activity"
    },
    {
      "name": "X9371",
      "description": "Assault by handgun discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X9372",
      "description": "Assault by handgun discharge at farm While working for income"
    },
    {
      "name": "X9373",
      "description": "Assault by handgun discharge at farm While engaged in other types of work"
    },
    {
      "name": "X9374",
      "description": "Assault by handgun discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9378",
      "description": "Assault by handgun discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X9379",
      "description": "Assault by handgun discharge at farm During unspecified activity"
    },
    {
      "name": "X938",
      "description": "Assault by handgun discharge at other specified places"
    },
    {
      "name": "X9380",
      "description": "Assault by handgun discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X9381",
      "description": "Assault by handgun discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9382",
      "description": "Assault by handgun discharge at other specified places While working for income"
    },
    {
      "name": "X9383",
      "description": "Assault by handgun discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X9384",
      "description": "Assault by handgun discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9388",
      "description": "Assault by handgun discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9389",
      "description": "Assault by handgun discharge at other specified places During unspecified activity"
    },
    {
      "name": "X939",
      "description": "Assault by handgun discharge at unspecified place"
    },
    {
      "name": "X9390",
      "description": "Assault by handgun discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9391",
      "description": "Assault by handgun discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9392",
      "description": "Assault by handgun discharge at unspecified place While working for income"
    },
    {
      "name": "X9393",
      "description": "Assault by handgun discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9394",
      "description": "Assault by handgun discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9398",
      "description": "Assault by handgun discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9399",
      "description": "Assault by handgun discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X940",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home"
    },
    {
      "name": "X9400",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While engaged in sports activity"
    },
    {
      "name": "X9401",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While engaged in leisure activity"
    },
    {
      "name": "X9402",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While working for income"
    },
    {
      "name": "X9403",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While engaged in other types of work"
    },
    {
      "name": "X9404",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9408",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home While engaged in other specified activities"
    },
    {
      "name": "X9409",
      "description": "Assault by rifle, shotgun and larger firearm discharge at home During unspecified activity"
    },
    {
      "name": "X941",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution"
    },
    {
      "name": "X9410",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X9411",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9412",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While working for income"
    },
    {
      "name": "X9413",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X9414",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9418",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9419",
      "description": "Assault by rifle, shotgun and larger firearm discharge at residential institution During unspecified activity"
    },
    {
      "name": "X942",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area"
    },
    {
      "name": "X9420",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9421",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9422",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9423",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9424",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9428",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9429",
      "description": "Assault by rifle, shotgun and larger firearm discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X943",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area"
    },
    {
      "name": "X9430",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9431",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9432",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While working for income"
    },
    {
      "name": "X9433",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9434",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9438",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9439",
      "description": "Assault by rifle, shotgun and larger firearm discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X944",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway"
    },
    {
      "name": "X9440",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X9441",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9442",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While working for income"
    },
    {
      "name": "X9443",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X9444",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9448",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9449",
      "description": "Assault by rifle, shotgun and larger firearm discharge at street and highway During unspecified activity"
    },
    {
      "name": "X945",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area"
    },
    {
      "name": "X9450",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9451",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9452",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While working for income"
    },
    {
      "name": "X9453",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9454",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9458",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9459",
      "description": "Assault by rifle, shotgun and larger firearm discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X946",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area"
    },
    {
      "name": "X9460",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9461",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9462",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While working for income"
    },
    {
      "name": "X9463",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9464",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9468",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9469",
      "description": "Assault by rifle, shotgun and larger firearm discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X947",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm"
    },
    {
      "name": "X9470",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While engaged in sports activity"
    },
    {
      "name": "X9471",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X9472",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While working for income"
    },
    {
      "name": "X9473",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While engaged in other types of work"
    },
    {
      "name": "X9474",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9478",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X9479",
      "description": "Assault by rifle, shotgun and larger firearm discharge at farm During unspecified activity"
    },
    {
      "name": "X948",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places"
    },
    {
      "name": "X9480",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X9481",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9482",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While working for income"
    },
    {
      "name": "X9483",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X9484",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9488",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9489",
      "description": "Assault by rifle, shotgun and larger firearm discharge at other specified places During unspecified activity"
    },
    {
      "name": "X949",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place"
    },
    {
      "name": "X9490",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9491",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9492",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While working for income"
    },
    {
      "name": "X9493",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9494",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9498",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9499",
      "description": "Assault by rifle, shotgun and larger firearm discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X950",
      "description": "Assault by other and unspecified firearm discharge at home"
    },
    {
      "name": "X9500",
      "description": "Assault by other and unspecified firearm discharge at home While engaged in sports activity"
    },
    {
      "name": "X9501",
      "description": "Assault by other and unspecified firearm discharge at home While engaged in leisure activity"
    },
    {
      "name": "X9502",
      "description": "Assault by other and unspecified firearm discharge at home While working for income"
    },
    {
      "name": "X9503",
      "description": "Assault by other and unspecified firearm discharge at home While engaged in other types of work"
    },
    {
      "name": "X9504",
      "description": "Assault by other and unspecified firearm discharge at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9508",
      "description": "Assault by other and unspecified firearm discharge at home While engaged in other specified activities"
    },
    {
      "name": "X9509",
      "description": "Assault by other and unspecified firearm discharge at home During unspecified activity"
    },
    {
      "name": "X951",
      "description": "Assault by other and unspecified firearm discharge at residential institution"
    },
    {
      "name": "X9510",
      "description": "Assault by other and unspecified firearm discharge at residential institution While engaged in sports activity"
    },
    {
      "name": "X9511",
      "description": "Assault by other and unspecified firearm discharge at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9512",
      "description": "Assault by other and unspecified firearm discharge at residential institution While working for income"
    },
    {
      "name": "X9513",
      "description": "Assault by other and unspecified firearm discharge at residential institution While engaged in other types of work"
    },
    {
      "name": "X9514",
      "description": "Assault by other and unspecified firearm discharge at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9518",
      "description": "Assault by other and unspecified firearm discharge at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9519",
      "description": "Assault by other and unspecified firearm discharge at residential institution During unspecified activity"
    },
    {
      "name": "X952",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area"
    },
    {
      "name": "X9520",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9521",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9522",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9523",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9524",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9528",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9529",
      "description": "Assault by other and unspecified firearm discharge at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X953",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area"
    },
    {
      "name": "X9530",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9531",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9532",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While working for income"
    },
    {
      "name": "X9533",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9534",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9538",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9539",
      "description": "Assault by other and unspecified firearm discharge at sports and athletics area During unspecified activity"
    },
    {
      "name": "X954",
      "description": "Assault by other and unspecified firearm discharge at street and highway"
    },
    {
      "name": "X9540",
      "description": "Assault by other and unspecified firearm discharge at street and highway While engaged in sports activity"
    },
    {
      "name": "X9541",
      "description": "Assault by other and unspecified firearm discharge at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9542",
      "description": "Assault by other and unspecified firearm discharge at street and highway While working for income"
    },
    {
      "name": "X9543",
      "description": "Assault by other and unspecified firearm discharge at street and highway While engaged in other types of work"
    },
    {
      "name": "X9544",
      "description": "Assault by other and unspecified firearm discharge at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9548",
      "description": "Assault by other and unspecified firearm discharge at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9549",
      "description": "Assault by other and unspecified firearm discharge at street and highway During unspecified activity"
    },
    {
      "name": "X955",
      "description": "Assault by other and unspecified firearm discharge at trade and service area"
    },
    {
      "name": "X9550",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9551",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9552",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While working for income"
    },
    {
      "name": "X9553",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9554",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9558",
      "description": "Assault by other and unspecified firearm discharge at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9559",
      "description": "Assault by other and unspecified firearm discharge at trade and service area During unspecified activity"
    },
    {
      "name": "X956",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area"
    },
    {
      "name": "X9560",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9561",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9562",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While working for income"
    },
    {
      "name": "X9563",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9564",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9568",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9569",
      "description": "Assault by other and unspecified firearm discharge at industrial and construction area During unspecified activity"
    },
    {
      "name": "X957",
      "description": "Assault by other and unspecified firearm discharge at farm"
    },
    {
      "name": "X9570",
      "description": "Assault by other and unspecified firearm discharge at farm While engaged in sports activity"
    },
    {
      "name": "X9571",
      "description": "Assault by other and unspecified firearm discharge at farm While engaged in leisure activity"
    },
    {
      "name": "X9572",
      "description": "Assault by other and unspecified firearm discharge at farm While working for income"
    },
    {
      "name": "X9573",
      "description": "Assault by other and unspecified firearm discharge at farm While engaged in other types of work"
    },
    {
      "name": "X9574",
      "description": "Assault by other and unspecified firearm discharge at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9578",
      "description": "Assault by other and unspecified firearm discharge at farm While engaged in other specified activities"
    },
    {
      "name": "X9579",
      "description": "Assault by other and unspecified firearm discharge at farm During unspecified activity"
    },
    {
      "name": "X958",
      "description": "Assault by other and unspecified firearm discharge at other specified places"
    },
    {
      "name": "X9580",
      "description": "Assault by other and unspecified firearm discharge at other specified places While engaged in sports activity"
    },
    {
      "name": "X9581",
      "description": "Assault by other and unspecified firearm discharge at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9582",
      "description": "Assault by other and unspecified firearm discharge at other specified places While working for income"
    },
    {
      "name": "X9583",
      "description": "Assault by other and unspecified firearm discharge at other specified places While engaged in other types of work"
    },
    {
      "name": "X9584",
      "description": "Assault by other and unspecified firearm discharge at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9588",
      "description": "Assault by other and unspecified firearm discharge at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9589",
      "description": "Assault by other and unspecified firearm discharge at other specified places During unspecified activity"
    },
    {
      "name": "X959",
      "description": "Assault by other and unspecified firearm discharge at unspecified place"
    },
    {
      "name": "X9590",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9591",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9592",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While working for income"
    },
    {
      "name": "X9593",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9594",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9598",
      "description": "Assault by other and unspecified firearm discharge at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9599",
      "description": "Assault by other and unspecified firearm discharge at unspecified place During unspecified activity"
    },
    {
      "name": "X960",
      "description": "Assault by explosive material at home"
    },
    {
      "name": "X9600",
      "description": "Assault by explosive material at home While engaged in sports activity"
    },
    {
      "name": "X9601",
      "description": "Assault by explosive material at home While engaged in leisure activity"
    },
    {
      "name": "X9602",
      "description": "Assault by explosive material at home While working for income"
    },
    {
      "name": "X9603",
      "description": "Assault by explosive material at home While engaged in other types of work"
    },
    {
      "name": "X9604",
      "description": "Assault by explosive material at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9608",
      "description": "Assault by explosive material at home While engaged in other specified activities"
    },
    {
      "name": "X9609",
      "description": "Assault by explosive material at home During unspecified activity"
    },
    {
      "name": "X961",
      "description": "Assault by explosive material at residential institution"
    },
    {
      "name": "X9610",
      "description": "Assault by explosive material at residential institution While engaged in sports activity"
    },
    {
      "name": "X9611",
      "description": "Assault by explosive material at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9612",
      "description": "Assault by explosive material at residential institution While working for income"
    },
    {
      "name": "X9613",
      "description": "Assault by explosive material at residential institution While engaged in other types of work"
    },
    {
      "name": "X9614",
      "description": "Assault by explosive material at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9618",
      "description": "Assault by explosive material at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9619",
      "description": "Assault by explosive material at residential institution During unspecified activity"
    },
    {
      "name": "X962",
      "description": "Assault by explosive material at school,other institution and public administrative area"
    },
    {
      "name": "X9620",
      "description": "Assault by explosive material at school,other institution and public administrative area  While engaged in sports activity"
    },
    {
      "name": "X9621",
      "description": "Assault by explosive material at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9622",
      "description": "Assault by explosive material at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9623",
      "description": "Assault by explosive material at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9624",
      "description": "Assault by explosive material at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9628",
      "description": "Assault by explosive material at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9629",
      "description": "Assault by explosive material at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X963",
      "description": "Assault by explosive material at sports and athletics area"
    },
    {
      "name": "X9630",
      "description": "Assault by explosive material at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9631",
      "description": "Assault by explosive material at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9632",
      "description": "Assault by explosive material at sports and athletics area While working for income"
    },
    {
      "name": "X9633",
      "description": "Assault by explosive material at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9634",
      "description": "Assault by explosive material at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9638",
      "description": "Assault by explosive material at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9639",
      "description": "Assault by explosive material at sports and athletics area During unspecified activity"
    },
    {
      "name": "X964",
      "description": "Assault by explosive material at street and highway"
    },
    {
      "name": "X9640",
      "description": "Assault by explosive material at street and highway While engaged in sports activity"
    },
    {
      "name": "X9641",
      "description": "Assault by explosive material at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9642",
      "description": "Assault by explosive material at street and highway While working for income"
    },
    {
      "name": "X9643",
      "description": "Assault by explosive material at street and highway While engaged in other types of work"
    },
    {
      "name": "X9644",
      "description": "Assault by explosive material at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9648",
      "description": "Assault by explosive material at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9649",
      "description": "Assault by explosive material at street and highway During unspecified activity"
    },
    {
      "name": "X965",
      "description": "Assault by explosive material at trade and service area"
    },
    {
      "name": "X9650",
      "description": "Assault by explosive material at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9651",
      "description": "Assault by explosive material at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9652",
      "description": "Assault by explosive material at trade and service area While working for income"
    },
    {
      "name": "X9653",
      "description": "Assault by explosive material at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9654",
      "description": "Assault by explosive material at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9658",
      "description": "Assault by explosive material at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9659",
      "description": "Assault by explosive material at trade and service area During unspecified activity"
    },
    {
      "name": "X966",
      "description": "Assault by explosive material at industrial and construction area"
    },
    {
      "name": "X9660",
      "description": "Assault by explosive material at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9661",
      "description": "Assault by explosive material at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9662",
      "description": "Assault by explosive material at industrial and construction area While working for income"
    },
    {
      "name": "X9663",
      "description": "Assault by explosive material at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9664",
      "description": "Assault by explosive material at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9668",
      "description": "Assault by explosive material at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9669",
      "description": "Assault by explosive material at industrial and construction area During unspecified activity"
    },
    {
      "name": "X967",
      "description": "Assault by explosive material at farm"
    },
    {
      "name": "X9670",
      "description": "Assault by explosive material at farm While engaged in sports activity"
    },
    {
      "name": "X9671",
      "description": "Assault by explosive material at farm While engaged in leisure activity"
    },
    {
      "name": "X9672",
      "description": "Assault by explosive material at farm While working for income"
    },
    {
      "name": "X9673",
      "description": "Assault by explosive material at farm While engaged in other types of work"
    },
    {
      "name": "X9674",
      "description": "Assault by explosive material at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9678",
      "description": "Assault by explosive material at farm While engaged in other specified activities"
    },
    {
      "name": "X9679",
      "description": "Assault by explosive material at farm During unspecified activity"
    },
    {
      "name": "X968",
      "description": "Assault by explosive material at other specified places"
    },
    {
      "name": "X9680",
      "description": "Assault by explosive material at other specified places While engaged in sports activity"
    },
    {
      "name": "X9681",
      "description": "Assault by explosive material at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9682",
      "description": "Assault by explosive material at other specified places While working for income"
    },
    {
      "name": "X9683",
      "description": "Assault by explosive material at other specified places While engaged in other types of work"
    },
    {
      "name": "X9684",
      "description": "Assault by explosive material at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9688",
      "description": "Assault by explosive material at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9689",
      "description": "Assault by explosive material at other specified places During unspecified activity"
    },
    {
      "name": "X969",
      "description": "Assault by explosive material at unspecified place"
    },
    {
      "name": "X9690",
      "description": "Assault by explosive material at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9691",
      "description": "Assault by explosive material at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9692",
      "description": "Assault by explosive material at unspecified place While working for income"
    },
    {
      "name": "X9693",
      "description": "Assault by explosive material at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9694",
      "description": "Assault by explosive material at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9698",
      "description": "Assault by explosive material at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9699",
      "description": "Assault by explosive material at unspecified place During unspecified activity"
    },
    {
      "name": "X970",
      "description": "Assault by smoke, fire and flames at home"
    },
    {
      "name": "X9700",
      "description": "Assault by smoke, fire and flames at home While engaged in sports activity"
    },
    {
      "name": "X9701",
      "description": "Assault by smoke, fire and flames at home While engaged in leisure activity"
    },
    {
      "name": "X9702",
      "description": "Assault by smoke, fire and flames at home While working for income"
    },
    {
      "name": "X9703",
      "description": "Assault by smoke, fire and flames at home While engaged in other types of work"
    },
    {
      "name": "X9704",
      "description": "Assault by smoke, fire and flames at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9708",
      "description": "Assault by smoke, fire and flames at home While engaged in other specified activities"
    },
    {
      "name": "X9709",
      "description": "Assault by smoke, fire and flames at home During unspecified activity"
    },
    {
      "name": "X971",
      "description": "Assault by smoke, fire and flames at residential institution"
    },
    {
      "name": "X9710",
      "description": "Assault by smoke, fire and flames at residential institution While engaged in sports activity"
    },
    {
      "name": "X9711",
      "description": "Assault by smoke, fire and flames at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9712",
      "description": "Assault by smoke, fire and flames at residential institution While working for income"
    },
    {
      "name": "X9713",
      "description": "Assault by smoke, fire and flames at residential institution While engaged in other types of work"
    },
    {
      "name": "X9714",
      "description": "Assault by smoke, fire and flames at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9718",
      "description": "Assault by smoke, fire and flames at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9719",
      "description": "Assault by smoke, fire and flames at residential institution During unspecified activity"
    },
    {
      "name": "X972",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area"
    },
    {
      "name": "X9720",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9721",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9722",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9723",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9724",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9728",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9729",
      "description": "Assault by smoke, fire and flames at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X973",
      "description": "Assault by smoke, fire and flames at sports and athletics area"
    },
    {
      "name": "X9730",
      "description": "Assault by smoke, fire and flames at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9731",
      "description": "Assault by smoke, fire and flames at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9732",
      "description": "Assault by smoke, fire and flames at sports and athletics area While working for income"
    },
    {
      "name": "X9733",
      "description": "Assault by smoke, fire and flames at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9734",
      "description": "Assault by smoke, fire and flames at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9738",
      "description": "Assault by smoke, fire and flames at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9739",
      "description": "Assault by smoke, fire and flames at sports and athletics area During unspecified activity"
    },
    {
      "name": "X974",
      "description": "Assault by smoke, fire and flames at street and highway"
    },
    {
      "name": "X9740",
      "description": "Assault by smoke, fire and flames at street and highway While engaged in sports activity"
    },
    {
      "name": "X9741",
      "description": "Assault by smoke, fire and flames at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9742",
      "description": "Assault by smoke, fire and flames at street and highway While working for income"
    },
    {
      "name": "X9743",
      "description": "Assault by smoke, fire and flames at street and highway While engaged in other types of work"
    },
    {
      "name": "X9744",
      "description": "Assault by smoke, fire and flames at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9748",
      "description": "Assault by smoke, fire and flames at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9749",
      "description": "Assault by smoke, fire and flames at street and highway During unspecified activity"
    },
    {
      "name": "X975",
      "description": "Assault by smoke, fire and flames at trade and service area"
    },
    {
      "name": "X9750",
      "description": "Assault by smoke, fire and flames at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9751",
      "description": "Assault by smoke, fire and flames at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9752",
      "description": "Assault by smoke, fire and flames at trade and service area While working for income"
    },
    {
      "name": "X9753",
      "description": "Assault by smoke, fire and flames at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9754",
      "description": "Assault by smoke, fire and flames at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9758",
      "description": "Assault by smoke, fire and flames at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9759",
      "description": "Assault by smoke, fire and flames at trade and service area During unspecified activity"
    },
    {
      "name": "X976",
      "description": "Assault by smoke, fire and flames at industrial and construction area"
    },
    {
      "name": "X9760",
      "description": "Assault by smoke, fire and flames at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9761",
      "description": "Assault by smoke, fire and flames at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9762",
      "description": "Assault by smoke, fire and flames at industrial and construction area While working for income"
    },
    {
      "name": "X9763",
      "description": "Assault by smoke, fire and flames at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9764",
      "description": "Assault by smoke, fire and flames at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9768",
      "description": "Assault by smoke, fire and flames at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9769",
      "description": "Assault by smoke, fire and flames at industrial and construction area During unspecified activity"
    },
    {
      "name": "X977",
      "description": "Assault by smoke, fire and flames at farm"
    },
    {
      "name": "X9770",
      "description": "Assault by smoke, fire and flames at farm While engaged in sports activity"
    },
    {
      "name": "X9771",
      "description": "Assault by smoke, fire and flames at farm While engaged in leisure activity"
    },
    {
      "name": "X9772",
      "description": "Assault by smoke, fire and flames at farm While working for income"
    },
    {
      "name": "X9773",
      "description": "Assault by smoke, fire and flames at farm While engaged in other types of work"
    },
    {
      "name": "X9774",
      "description": "Assault by smoke, fire and flames at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9778",
      "description": "Assault by smoke, fire and flames at farm While engaged in other specified activities"
    },
    {
      "name": "X9779",
      "description": "Assault by smoke, fire and flames at farm During unspecified activity"
    },
    {
      "name": "X978",
      "description": "Assault by smoke, fire and flames at other specified places"
    },
    {
      "name": "X9780",
      "description": "Assault by smoke, fire and flames at other specified places While engaged in sports activity"
    },
    {
      "name": "X9781",
      "description": "Assault by smoke, fire and flames at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9782",
      "description": "Assault by smoke, fire and flames at other specified places While working for income"
    },
    {
      "name": "X9783",
      "description": "Assault by smoke, fire and flames at other specified places While engaged in other types of work"
    },
    {
      "name": "X9784",
      "description": "Assault by smoke, fire and flames at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9788",
      "description": "Assault by smoke, fire and flames at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9789",
      "description": "Assault by smoke, fire and flames at other specified places During unspecified activity"
    },
    {
      "name": "X979",
      "description": "Assault by smoke, fire and flames at unspecified place"
    },
    {
      "name": "X9790",
      "description": "Assault by smoke, fire and flames at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9791",
      "description": "Assault by smoke, fire and flames at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9792",
      "description": "Assault by smoke, fire and flames at unspecified place While working for income"
    },
    {
      "name": "X9793",
      "description": "Assault by smoke, fire and flames at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9794",
      "description": "Assault by smoke, fire and flames at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9798",
      "description": "Assault by smoke, fire and flames at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9799",
      "description": "Assault by smoke, fire and flames at unspecified place During unspecified activity"
    },
    {
      "name": "X980",
      "description": "Assault by steam, hot vapours and hot objects at home"
    },
    {
      "name": "X9800",
      "description": "Assault by steam, hot vapours and hot objects at home While engaged in sports activity"
    },
    {
      "name": "X9801",
      "description": "Assault by steam, hot vapours and hot objects at home While engaged in leisure activity"
    },
    {
      "name": "X9802",
      "description": "Assault by steam, hot vapours and hot objects at home While working for income"
    },
    {
      "name": "X9803",
      "description": "Assault by steam, hot vapours and hot objects at home While engaged in other types of work"
    },
    {
      "name": "X9804",
      "description": "Assault by steam, hot vapours and hot objects at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9808",
      "description": "Assault by steam, hot vapours and hot objects at home While engaged in other specified activities"
    },
    {
      "name": "X9809",
      "description": "Assault by steam, hot vapours and hot objects at home During unspecified activity"
    },
    {
      "name": "X981",
      "description": "Assault by steam, hot vapours and hot objects at residential institution"
    },
    {
      "name": "X9810",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While engaged in sports activity"
    },
    {
      "name": "X9811",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9812",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While working for income"
    },
    {
      "name": "X9813",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While engaged in other types of work"
    },
    {
      "name": "X9814",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9818",
      "description": "Assault by steam, hot vapours and hot objects at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9819",
      "description": "Assault by steam, hot vapours and hot objects at residential institution During unspecified activity"
    },
    {
      "name": "X982",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area"
    },
    {
      "name": "X9820",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9821",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9822",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9823",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9824",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9828",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9829",
      "description": "Assault by steam, hot vapours and hot objects at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X983",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area"
    },
    {
      "name": "X9830",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9831",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9832",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While working for income"
    },
    {
      "name": "X9833",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9834",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9838",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9839",
      "description": "Assault by steam, hot vapours and hot objects at sports and athletics area During unspecified activity"
    },
    {
      "name": "X984",
      "description": "Assault by steam, hot vapours and hot objects at street and highway"
    },
    {
      "name": "X9840",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While engaged in sports activity"
    },
    {
      "name": "X9841",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9842",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While working for income"
    },
    {
      "name": "X9843",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While engaged in other types of work"
    },
    {
      "name": "X9844",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9848",
      "description": "Assault by steam, hot vapours and hot objects at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9849",
      "description": "Assault by steam, hot vapours and hot objects at street and highway During unspecified activity"
    },
    {
      "name": "X985",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area"
    },
    {
      "name": "X9850",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9851",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9852",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While working for income"
    },
    {
      "name": "X9853",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9854",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9858",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9859",
      "description": "Assault by steam, hot vapours and hot objects at trade and service area During unspecified activity"
    },
    {
      "name": "X986",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area"
    },
    {
      "name": "X9860",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9861",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9862",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While working for income"
    },
    {
      "name": "X9863",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9864",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9868",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9869",
      "description": "Assault by steam, hot vapours and hot objects at industrial and construction area During unspecified activity"
    },
    {
      "name": "X987",
      "description": "Assault by steam, hot vapours and hot objects at farm"
    },
    {
      "name": "X9870",
      "description": "Assault by steam, hot vapours and hot objects at farm While engaged in sports activity"
    },
    {
      "name": "X9871",
      "description": "Assault by steam, hot vapours and hot objects at farm While engaged in leisure activity"
    },
    {
      "name": "X9872",
      "description": "Assault by steam, hot vapours and hot objects at farm While working for income"
    },
    {
      "name": "X9873",
      "description": "Assault by steam, hot vapours and hot objects at farm While engaged in other types of work"
    },
    {
      "name": "X9874",
      "description": "Assault by steam, hot vapours and hot objects at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9878",
      "description": "Assault by steam, hot vapours and hot objects at farm While engaged in other specified activities"
    },
    {
      "name": "X9879",
      "description": "Assault by steam, hot vapours and hot objects at farm During unspecified activity"
    },
    {
      "name": "X988",
      "description": "Assault by steam, hot vapours and hot objects at other specified places"
    },
    {
      "name": "X9880",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While engaged in sports activity"
    },
    {
      "name": "X9881",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9882",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While working for income"
    },
    {
      "name": "X9883",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While engaged in other types of work"
    },
    {
      "name": "X9884",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9888",
      "description": "Assault by steam, hot vapours and hot objects at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9889",
      "description": "Assault by steam, hot vapours and hot objects at other specified places During unspecified activity"
    },
    {
      "name": "X989",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place"
    },
    {
      "name": "X9890",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9891",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9892",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While working for income"
    },
    {
      "name": "X9893",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9894",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9898",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9899",
      "description": "Assault by steam, hot vapours and hot objects at unspecified place During unspecified activity"
    },
    {
      "name": "X990",
      "description": "Assault by sharp object at home"
    },
    {
      "name": "X9900",
      "description": "Assault by sharp object at home While engaged in sports activity"
    },
    {
      "name": "X9901",
      "description": "Assault by sharp object at home While engaged in leisure activity"
    },
    {
      "name": "X9902",
      "description": "Assault by sharp object at home While working for income"
    },
    {
      "name": "X9903",
      "description": "Assault by sharp object at home While engaged in other types of work"
    },
    {
      "name": "X9904",
      "description": "Assault by sharp object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9908",
      "description": "Assault by sharp object at home While engaged in other specified activities"
    },
    {
      "name": "X9909",
      "description": "Assault by sharp object at home During unspecified activity"
    },
    {
      "name": "X991",
      "description": "Assault by sharp object at residential institution"
    },
    {
      "name": "X9910",
      "description": "Assault by sharp object at residential institution While engaged in sports activity"
    },
    {
      "name": "X9911",
      "description": "Assault by sharp object at residential institution While engaged in leisure activity"
    },
    {
      "name": "X9912",
      "description": "Assault by sharp object at residential institution While working for income"
    },
    {
      "name": "X9913",
      "description": "Assault by sharp object at residential institution While engaged in other types of work"
    },
    {
      "name": "X9914",
      "description": "Assault by sharp object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9918",
      "description": "Assault by sharp object at residential institution While engaged in other specified activities"
    },
    {
      "name": "X9919",
      "description": "Assault by sharp object at residential institution During unspecified activity"
    },
    {
      "name": "X992",
      "description": "Assault by sharp object at school,other institution and public administrative area"
    },
    {
      "name": "X9920",
      "description": "Assault by sharp object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "X9921",
      "description": "Assault by sharp object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "X9922",
      "description": "Assault by sharp object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "X9923",
      "description": "Assault by sharp object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "X9924",
      "description": "Assault by sharp object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9928",
      "description": "Assault by sharp object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "X9929",
      "description": "Assault by sharp object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "X993",
      "description": "Assault by sharp object at sports and athletics area"
    },
    {
      "name": "X9930",
      "description": "Assault by sharp object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "X9931",
      "description": "Assault by sharp object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "X9932",
      "description": "Assault by sharp object at sports and athletics area While working for income"
    },
    {
      "name": "X9933",
      "description": "Assault by sharp object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "X9934",
      "description": "Assault by sharp object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9938",
      "description": "Assault by sharp object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "X9939",
      "description": "Assault by sharp object at sports and athletics area During unspecified activity"
    },
    {
      "name": "X994",
      "description": "Assault by sharp object at street and highway"
    },
    {
      "name": "X9940",
      "description": "Assault by sharp object at street and highway While engaged in sports activity"
    },
    {
      "name": "X9941",
      "description": "Assault by sharp object at street and highway While engaged in leisure activity"
    },
    {
      "name": "X9942",
      "description": "Assault by sharp object at street and highway While working for income"
    },
    {
      "name": "X9943",
      "description": "Assault by sharp object at street and highway While engaged in other types of work"
    },
    {
      "name": "X9944",
      "description": "Assault by sharp object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9948",
      "description": "Assault by sharp object at street and highway While engaged in other specified activities"
    },
    {
      "name": "X9949",
      "description": "Assault by sharp object at street and highway During unspecified activity"
    },
    {
      "name": "X995",
      "description": "Assault by sharp object at trade and service area"
    },
    {
      "name": "X9950",
      "description": "Assault by sharp object at trade and service area While engaged in sports activity"
    },
    {
      "name": "X9951",
      "description": "Assault by sharp object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "X9952",
      "description": "Assault by sharp object at trade and service area While working for income"
    },
    {
      "name": "X9953",
      "description": "Assault by sharp object at trade and service area While engaged in other types of work"
    },
    {
      "name": "X9954",
      "description": "Assault by sharp object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9958",
      "description": "Assault by sharp object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "X9959",
      "description": "Assault by sharp object at trade and service area During unspecified activity"
    },
    {
      "name": "X996",
      "description": "Assault by sharp object at industrial and construction area"
    },
    {
      "name": "X9960",
      "description": "Assault by sharp object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "X9961",
      "description": "Assault by sharp object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "X9962",
      "description": "Assault by sharp object at industrial and construction area While working for income"
    },
    {
      "name": "X9963",
      "description": "Assault by sharp object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "X9964",
      "description": "Assault by sharp object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9968",
      "description": "Assault by sharp object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "X9969",
      "description": "Assault by sharp object at industrial and construction area During unspecified activity"
    },
    {
      "name": "X997",
      "description": "Assault by sharp object at farm"
    },
    {
      "name": "X9970",
      "description": "Assault by sharp object at farm While engaged in sports activity"
    },
    {
      "name": "X9971",
      "description": "Assault by sharp object at farm While engaged in leisure activity"
    },
    {
      "name": "X9972",
      "description": "Assault by sharp object at farm While working for income"
    },
    {
      "name": "X9973",
      "description": "Assault by sharp object at farm While engaged in other types of work"
    },
    {
      "name": "X9974",
      "description": "Assault by sharp object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9978",
      "description": "Assault by sharp object at farm While engaged in other specified activities"
    },
    {
      "name": "X9979",
      "description": "Assault by sharp object at farm During unspecified activity"
    },
    {
      "name": "X998",
      "description": "Assault by sharp object at other specified places"
    },
    {
      "name": "X9980",
      "description": "Assault by sharp object at other specified places While engaged in sports activity"
    },
    {
      "name": "X9981",
      "description": "Assault by sharp object at other specified places While engaged in leisure activity"
    },
    {
      "name": "X9982",
      "description": "Assault by sharp object at other specified places While working for income"
    },
    {
      "name": "X9983",
      "description": "Assault by sharp object at other specified places While engaged in other types of work"
    },
    {
      "name": "X9984",
      "description": "Assault by sharp object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9988",
      "description": "Assault by sharp object at other specified places While engaged in other specified activities"
    },
    {
      "name": "X9989",
      "description": "Assault by sharp object at other specified places During unspecified activity"
    },
    {
      "name": "X999",
      "description": "Assault by sharp object at unspecified place"
    },
    {
      "name": "X9990",
      "description": "Assault by sharp object at unspecified place While engaged in sports activity"
    },
    {
      "name": "X9991",
      "description": "Assault by sharp object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "X9992",
      "description": "Assault by sharp object at unspecified place While working for income"
    },
    {
      "name": "X9993",
      "description": "Assault by sharp object at unspecified place While engaged in other types of work"
    },
    {
      "name": "X9994",
      "description": "Assault by sharp object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "X9998",
      "description": "Assault by sharp object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "X9999",
      "description": "Assault by sharp object at unspecified place During unspecified activity"
    },
    {
      "name": "Y000",
      "description": "Assault by blunt object at home"
    },
    {
      "name": "Y0000",
      "description": "Assault by blunt object at home While engaged in sports activity"
    },
    {
      "name": "Y0001",
      "description": "Assault by blunt object at home While engaged in leisure activity"
    },
    {
      "name": "Y0002",
      "description": "Assault by blunt object at home While working for income"
    },
    {
      "name": "Y0003",
      "description": "Assault by blunt object at home While engaged in other types of work"
    },
    {
      "name": "Y0004",
      "description": "Assault by blunt object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0008",
      "description": "Assault by blunt object at home While engaged in other specified activities"
    },
    {
      "name": "Y0009",
      "description": "Assault by blunt object at home During unspecified activity"
    },
    {
      "name": "Y001",
      "description": "Assault by blunt object at residential institution"
    },
    {
      "name": "Y0010",
      "description": "Assault by blunt object at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0011",
      "description": "Assault by blunt object at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0012",
      "description": "Assault by blunt object at residential institution While working for income"
    },
    {
      "name": "Y0013",
      "description": "Assault by blunt object at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0014",
      "description": "Assault by blunt object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0018",
      "description": "Assault by blunt object at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0019",
      "description": "Assault by blunt object at residential institution During unspecified activity"
    },
    {
      "name": "Y002",
      "description": "Assault by blunt object at school,other institution and public administrative area"
    },
    {
      "name": "Y0020",
      "description": "Assault by blunt object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0021",
      "description": "Assault by blunt object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0022",
      "description": "Assault by blunt object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0023",
      "description": "Assault by blunt object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0024",
      "description": "Assault by blunt object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0028",
      "description": "Assault by blunt object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0029",
      "description": "Assault by blunt object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y003",
      "description": "Assault by blunt object at sports and athletics area"
    },
    {
      "name": "Y0030",
      "description": "Assault by blunt object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0031",
      "description": "Assault by blunt object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0032",
      "description": "Assault by blunt object at sports and athletics area While working for income"
    },
    {
      "name": "Y0033",
      "description": "Assault by blunt object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0034",
      "description": "Assault by blunt object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0038",
      "description": "Assault by blunt object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0039",
      "description": "Assault by blunt object at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y004",
      "description": "Assault by blunt object at street and highway"
    },
    {
      "name": "Y0040",
      "description": "Assault by blunt object at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0041",
      "description": "Assault by blunt object at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0042",
      "description": "Assault by blunt object at street and highway While working for income"
    },
    {
      "name": "Y0043",
      "description": "Assault by blunt object at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0044",
      "description": "Assault by blunt object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0048",
      "description": "Assault by blunt object at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0049",
      "description": "Assault by blunt object at street and highway During unspecified activity"
    },
    {
      "name": "Y005",
      "description": "Assault by blunt object at trade and service area"
    },
    {
      "name": "Y0050",
      "description": "Assault by blunt object at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0051",
      "description": "Assault by blunt object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0052",
      "description": "Assault by blunt object at trade and service area While working for income"
    },
    {
      "name": "Y0053",
      "description": "Assault by blunt object at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0054",
      "description": "Assault by blunt object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0058",
      "description": "Assault by blunt object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0059",
      "description": "Assault by blunt object at trade and service area During unspecified activity"
    },
    {
      "name": "Y006",
      "description": "Assault by blunt object at industrial and construction area"
    },
    {
      "name": "Y0060",
      "description": "Assault by blunt object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0061",
      "description": "Assault by blunt object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0062",
      "description": "Assault by blunt object at industrial and construction area While working for income"
    },
    {
      "name": "Y0063",
      "description": "Assault by blunt object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0064",
      "description": "Assault by blunt object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0068",
      "description": "Assault by blunt object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0069",
      "description": "Assault by blunt object at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y007",
      "description": "Assault by blunt object at farm"
    },
    {
      "name": "Y0070",
      "description": "Assault by blunt object at farm While engaged in sports activity"
    },
    {
      "name": "Y0071",
      "description": "Assault by blunt object at farm While engaged in leisure activity"
    },
    {
      "name": "Y0072",
      "description": "Assault by blunt object at farm While working for income"
    },
    {
      "name": "Y0073",
      "description": "Assault by blunt object at farm While engaged in other types of work"
    },
    {
      "name": "Y0074",
      "description": "Assault by blunt object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0078",
      "description": "Assault by blunt object at farm While engaged in other specified activities"
    },
    {
      "name": "Y0079",
      "description": "Assault by blunt object at farm During unspecified activity"
    },
    {
      "name": "Y008",
      "description": "Assault by blunt object at other specified places"
    },
    {
      "name": "Y0080",
      "description": "Assault by blunt object at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0081",
      "description": "Assault by blunt object at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0082",
      "description": "Assault by blunt object at other specified places While working for income"
    },
    {
      "name": "Y0083",
      "description": "Assault by blunt object at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0084",
      "description": "Assault by blunt object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0088",
      "description": "Assault by blunt object at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0089",
      "description": "Assault by blunt object at other specified places During unspecified activity"
    },
    {
      "name": "Y009",
      "description": "Assault by blunt object at unspecified place"
    },
    {
      "name": "Y0090",
      "description": "Assault by blunt object at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0091",
      "description": "Assault by blunt object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0092",
      "description": "Assault by blunt object at unspecified place While working for income"
    },
    {
      "name": "Y0093",
      "description": "Assault by blunt object at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0094",
      "description": "Assault by blunt object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0098",
      "description": "Assault by blunt object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0099",
      "description": "Assault by blunt object at unspecified place During unspecified activity"
    },
    {
      "name": "Y010",
      "description": "Assault by pushing from high place at home"
    },
    {
      "name": "Y0100",
      "description": "Assault by pushing from high place at home While engaged in sports activity"
    },
    {
      "name": "Y0101",
      "description": "Assault by pushing from high place at home While engaged in leisure activity"
    },
    {
      "name": "Y0102",
      "description": "Assault by pushing from high place at home While working for income"
    },
    {
      "name": "Y0103",
      "description": "Assault by pushing from high place at home While engaged in other types of work"
    },
    {
      "name": "Y0104",
      "description": "Assault by pushing from high place at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0108",
      "description": "Assault by pushing from high place at home While engaged in other specified activities"
    },
    {
      "name": "Y0109",
      "description": "Assault by pushing from high place at home During unspecified activity"
    },
    {
      "name": "Y011",
      "description": "Assault by pushing from high place at residential institution"
    },
    {
      "name": "Y0110",
      "description": "Assault by pushing from high place at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0111",
      "description": "Assault by pushing from high place at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0112",
      "description": "Assault by pushing from high place at residential institution While working for income"
    },
    {
      "name": "Y0113",
      "description": "Assault by pushing from high place at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0114",
      "description": "Assault by pushing from high place at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0118",
      "description": "Assault by pushing from high place at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0119",
      "description": "Assault by pushing from high place at residential institution During unspecified activity"
    },
    {
      "name": "Y012",
      "description": "Assault by pushing from high place at school,other institution and public administrative area"
    },
    {
      "name": "Y0120",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0121",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0122",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0123",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0124",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0128",
      "description": "Assault by pushing from high place at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0129",
      "description": "Assault by pushing from high place at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y013",
      "description": "Assault by pushing from high place at sports and athletics area"
    },
    {
      "name": "Y0130",
      "description": "Assault by pushing from high place at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0131",
      "description": "Assault by pushing from high place at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0132",
      "description": "Assault by pushing from high place at sports and athletics area While working for income"
    },
    {
      "name": "Y0133",
      "description": "Assault by pushing from high place at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0134",
      "description": "Assault by pushing from high place at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0138",
      "description": "Assault by pushing from high place at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0139",
      "description": "Assault by pushing from high place at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y014",
      "description": "Assault by pushing from high place at street and highway"
    },
    {
      "name": "Y0140",
      "description": "Assault by pushing from high place at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0141",
      "description": "Assault by pushing from high place at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0142",
      "description": "Assault by pushing from high place at street and highway While working for income"
    },
    {
      "name": "Y0143",
      "description": "Assault by pushing from high place at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0144",
      "description": "Assault by pushing from high place at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0148",
      "description": "Assault by pushing from high place at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0149",
      "description": "Assault by pushing from high place at street and highway During unspecified activity"
    },
    {
      "name": "Y015",
      "description": "Assault by pushing from high place at trade and service area"
    },
    {
      "name": "Y0150",
      "description": "Assault by pushing from high place at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0151",
      "description": "Assault by pushing from high place at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0152",
      "description": "Assault by pushing from high place at trade and service area While working for income"
    },
    {
      "name": "Y0153",
      "description": "Assault by pushing from high place at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0154",
      "description": "Assault by pushing from high place at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0158",
      "description": "Assault by pushing from high place at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0159",
      "description": "Assault by pushing from high place at trade and service area During unspecified activity"
    },
    {
      "name": "Y016",
      "description": "Assault by pushing from high place at industrial and construction area"
    },
    {
      "name": "Y0160",
      "description": "Assault by pushing from high place at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0161",
      "description": "Assault by pushing from high place at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0162",
      "description": "Assault by pushing from high place at industrial and construction area While working for income"
    },
    {
      "name": "Y0163",
      "description": "Assault by pushing from high place at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0164",
      "description": "Assault by pushing from high place at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0168",
      "description": "Assault by pushing from high place at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0169",
      "description": "Assault by pushing from high place at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y017",
      "description": "Assault by pushing from high place at farm"
    },
    {
      "name": "Y0170",
      "description": "Assault by pushing from high place at farm While engaged in sports activity"
    },
    {
      "name": "Y0171",
      "description": "Assault by pushing from high place at farm While engaged in leisure activity"
    },
    {
      "name": "Y0172",
      "description": "Assault by pushing from high place at farm While working for income"
    },
    {
      "name": "Y0173",
      "description": "Assault by pushing from high place at farm While engaged in other types of work"
    },
    {
      "name": "Y0174",
      "description": "Assault by pushing from high place at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0178",
      "description": "Assault by pushing from high place at farm While engaged in other specified activities"
    },
    {
      "name": "Y0179",
      "description": "Assault by pushing from high place at farm During unspecified activity"
    },
    {
      "name": "Y018",
      "description": "Assault by pushing from high place at other specified places"
    },
    {
      "name": "Y0180",
      "description": "Assault by pushing from high place at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0181",
      "description": "Assault by pushing from high place at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0182",
      "description": "Assault by pushing from high place at other specified places While working for income"
    },
    {
      "name": "Y0183",
      "description": "Assault by pushing from high place at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0184",
      "description": "Assault by pushing from high place at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0188",
      "description": "Assault by pushing from high place at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0189",
      "description": "Assault by pushing from high place at other specified places During unspecified activity"
    },
    {
      "name": "Y019",
      "description": "Assault by pushing from high place at unspecified place"
    },
    {
      "name": "Y0190",
      "description": "Assault by pushing from high place at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0191",
      "description": "Assault by pushing from high place at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0192",
      "description": "Assault by pushing from high place at unspecified place While working for income"
    },
    {
      "name": "Y0193",
      "description": "Assault by pushing from high place at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0194",
      "description": "Assault by pushing from high place at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0198",
      "description": "Assault by pushing from high place at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0199",
      "description": "Assault by pushing from high place at unspecified place During unspecified activity"
    },
    {
      "name": "Y020",
      "description": "Assault by pushing or placing victim before moving object at home"
    },
    {
      "name": "Y0200",
      "description": "Assault by pushing or placing victim before moving object at home While engaged in sports activity"
    },
    {
      "name": "Y0201",
      "description": "Assault by pushing or placing victim before moving object at home While engaged in leisure activity"
    },
    {
      "name": "Y0202",
      "description": "Assault by pushing or placing victim before moving object at home While working for income"
    },
    {
      "name": "Y0203",
      "description": "Assault by pushing or placing victim before moving object at home While engaged in other types of work"
    },
    {
      "name": "Y0204",
      "description": "Assault by pushing or placing victim before moving object at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0208",
      "description": "Assault by pushing or placing victim before moving object at home While engaged in other specified activities"
    },
    {
      "name": "Y0209",
      "description": "Assault by pushing or placing victim before moving object at home During unspecified activity"
    },
    {
      "name": "Y021",
      "description": "Assault by pushing or placing victim before moving object at residential institution"
    },
    {
      "name": "Y0210",
      "description": "Assault by pushing or placing victim before moving object at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0211",
      "description": "Assault by pushing or placing victim before moving object at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0212",
      "description": "Assault by pushing or placing victim before moving object at residential institution While working for income"
    },
    {
      "name": "Y0213",
      "description": "Assault by pushing or placing victim before moving object at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0214",
      "description": "Assault by pushing or placing victim before moving object at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0218",
      "description": "Assault by pushing or placing victim before moving object at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0219",
      "description": "Assault by pushing or placing victim before moving object at residential institution During unspecified activity"
    },
    {
      "name": "Y022",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area"
    },
    {
      "name": "Y0220",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0221",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0222",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0223",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0224",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0228",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0229",
      "description": "Assault by pushing or placing victim before moving object at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y023",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area"
    },
    {
      "name": "Y0230",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0231",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0232",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While working for income"
    },
    {
      "name": "Y0233",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0234",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0238",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0239",
      "description": "Assault by pushing or placing victim before moving object at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y024",
      "description": "Assault by pushing or placing victim before moving object at street and highway"
    },
    {
      "name": "Y0240",
      "description": "Assault by pushing or placing victim before moving object at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0241",
      "description": "Assault by pushing or placing victim before moving object at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0242",
      "description": "Assault by pushing or placing victim before moving object at street and highway While working for income"
    },
    {
      "name": "Y0243",
      "description": "Assault by pushing or placing victim before moving object at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0244",
      "description": "Assault by pushing or placing victim before moving object at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0248",
      "description": "Assault by pushing or placing victim before moving object at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0249",
      "description": "Assault by pushing or placing victim before moving object at street and highway During unspecified activity"
    },
    {
      "name": "Y025",
      "description": "Assault by pushing or placing victim before moving object at trade and service area"
    },
    {
      "name": "Y0250",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0251",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0252",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While working for income"
    },
    {
      "name": "Y0253",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0254",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0258",
      "description": "Assault by pushing or placing victim before moving object at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0259",
      "description": "Assault by pushing or placing victim before moving object at trade and service area During unspecified activity"
    },
    {
      "name": "Y026",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area"
    },
    {
      "name": "Y0260",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0261",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0262",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While working for income"
    },
    {
      "name": "Y0263",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0264",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0268",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0269",
      "description": "Assault by pushing or placing victim before moving object at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y027",
      "description": "Assault by pushing or placing victim before moving object at farm"
    },
    {
      "name": "Y0270",
      "description": "Assault by pushing or placing victim before moving object at farm While engaged in sports activity"
    },
    {
      "name": "Y0271",
      "description": "Assault by pushing or placing victim before moving object at farm While engaged in leisure activity"
    },
    {
      "name": "Y0272",
      "description": "Assault by pushing or placing victim before moving object at farm While working for income"
    },
    {
      "name": "Y0273",
      "description": "Assault by pushing or placing victim before moving object at farm While engaged in other types of work"
    },
    {
      "name": "Y0274",
      "description": "Assault by pushing or placing victim before moving object at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0278",
      "description": "Assault by pushing or placing victim before moving object at farm While engaged in other specified activities"
    },
    {
      "name": "Y0279",
      "description": "Assault by pushing or placing victim before moving object at farm During unspecified activity"
    },
    {
      "name": "Y028",
      "description": "Assault by pushing or placing victim before moving object at other specified places"
    },
    {
      "name": "Y0280",
      "description": "Assault by pushing or placing victim before moving object at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0281",
      "description": "Assault by pushing or placing victim before moving object at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0282",
      "description": "Assault by pushing or placing victim before moving object at other specified places While working for income"
    },
    {
      "name": "Y0283",
      "description": "Assault by pushing or placing victim before moving object at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0284",
      "description": "Assault by pushing or placing victim before moving object at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0288",
      "description": "Assault by pushing or placing victim before moving object at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0289",
      "description": "Assault by pushing or placing victim before moving object at other specified places During unspecified activity"
    },
    {
      "name": "Y029",
      "description": "Assault by pushing or placing victim before moving object at unspecified place"
    },
    {
      "name": "Y0290",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0291",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0292",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While working for income"
    },
    {
      "name": "Y0293",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0294",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0298",
      "description": "Assault by pushing or placing victim before moving object at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0299",
      "description": "Assault by pushing or placing victim before moving object at unspecified place During unspecified activity"
    },
    {
      "name": "Y030",
      "description": "Assault by crashing of motor vehicle at home"
    },
    {
      "name": "Y0300",
      "description": "Assault by crashing of motor vehicle at home While engaged in sports activity"
    },
    {
      "name": "Y0301",
      "description": "Assault by crashing of motor vehicle at home While engaged in leisure activity"
    },
    {
      "name": "Y0302",
      "description": "Assault by crashing of motor vehicle at home While working for income"
    },
    {
      "name": "Y0303",
      "description": "Assault by crashing of motor vehicle at home While engaged in other types of work"
    },
    {
      "name": "Y0304",
      "description": "Assault by crashing of motor vehicle at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0308",
      "description": "Assault by crashing of motor vehicle at home While engaged in other specified activities"
    },
    {
      "name": "Y0309",
      "description": "Assault by crashing of motor vehicle at home During unspecified activity"
    },
    {
      "name": "Y031",
      "description": "Assault by crashing of motor vehicle at residential institution"
    },
    {
      "name": "Y0310",
      "description": "Assault by crashing of motor vehicle at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0311",
      "description": "Assault by crashing of motor vehicle at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0312",
      "description": "Assault by crashing of motor vehicle at residential institution While working for income"
    },
    {
      "name": "Y0313",
      "description": "Assault by crashing of motor vehicle at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0314",
      "description": "Assault by crashing of motor vehicle at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0318",
      "description": "Assault by crashing of motor vehicle at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0319",
      "description": "Assault by crashing of motor vehicle at residential institution During unspecified activity"
    },
    {
      "name": "Y032",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area"
    },
    {
      "name": "Y0320",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0321",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0322",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0323",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0324",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0328",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0329",
      "description": "Assault by crashing of motor vehicle at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y033",
      "description": "Assault by crashing of motor vehicle at sports and athletics area"
    },
    {
      "name": "Y0330",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0331",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0332",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While working for income"
    },
    {
      "name": "Y0333",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0334",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0338",
      "description": "Assault by crashing of motor vehicle at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0339",
      "description": "Assault by crashing of motor vehicle at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y034",
      "description": "Assault by crashing of motor vehicle at street and highway"
    },
    {
      "name": "Y0340",
      "description": "Assault by crashing of motor vehicle at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0341",
      "description": "Assault by crashing of motor vehicle at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0342",
      "description": "Assault by crashing of motor vehicle at street and highway While working for income"
    },
    {
      "name": "Y0343",
      "description": "Assault by crashing of motor vehicle at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0344",
      "description": "Assault by crashing of motor vehicle at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0348",
      "description": "Assault by crashing of motor vehicle at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0349",
      "description": "Assault by crashing of motor vehicle at street and highway During unspecified activity"
    },
    {
      "name": "Y035",
      "description": "Assault by crashing of motor vehicle at trade and service area"
    },
    {
      "name": "Y0350",
      "description": "Assault by crashing of motor vehicle at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0351",
      "description": "Assault by crashing of motor vehicle at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0352",
      "description": "Assault by crashing of motor vehicle at trade and service area While working for income"
    },
    {
      "name": "Y0353",
      "description": "Assault by crashing of motor vehicle at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0354",
      "description": "Assault by crashing of motor vehicle at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0358",
      "description": "Assault by crashing of motor vehicle at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0359",
      "description": "Assault by crashing of motor vehicle at trade and service area During unspecified activity"
    },
    {
      "name": "Y036",
      "description": "Assault by crashing of motor vehicle at industrial and construction area"
    },
    {
      "name": "Y0360",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0361",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0362",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While working for income"
    },
    {
      "name": "Y0363",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0364",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0368",
      "description": "Assault by crashing of motor vehicle at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0369",
      "description": "Assault by crashing of motor vehicle at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y037",
      "description": "Assault by crashing of motor vehicle at farm"
    },
    {
      "name": "Y0370",
      "description": "Assault by crashing of motor vehicle at farm While engaged in sports activity"
    },
    {
      "name": "Y0371",
      "description": "Assault by crashing of motor vehicle at farm While engaged in leisure activity"
    },
    {
      "name": "Y0372",
      "description": "Assault by crashing of motor vehicle at farm While working for income"
    },
    {
      "name": "Y0373",
      "description": "Assault by crashing of motor vehicle at farm While engaged in other types of work"
    },
    {
      "name": "Y0374",
      "description": "Assault by crashing of motor vehicle at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0378",
      "description": "Assault by crashing of motor vehicle at farm While engaged in other specified activities"
    },
    {
      "name": "Y0379",
      "description": "Assault by crashing of motor vehicle at farm During unspecified activity"
    },
    {
      "name": "Y038",
      "description": "Assault by crashing of motor vehicle at other specified places"
    },
    {
      "name": "Y0380",
      "description": "Assault by crashing of motor vehicle at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0381",
      "description": "Assault by crashing of motor vehicle at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0382",
      "description": "Assault by crashing of motor vehicle at other specified places While working for income"
    },
    {
      "name": "Y0383",
      "description": "Assault by crashing of motor vehicle at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0384",
      "description": "Assault by crashing of motor vehicle at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0388",
      "description": "Assault by crashing of motor vehicle at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0389",
      "description": "Assault by crashing of motor vehicle at other specified places During unspecified activity"
    },
    {
      "name": "Y039",
      "description": "Assault by crashing of motor vehicle at unspecified place"
    },
    {
      "name": "Y0390",
      "description": "Assault by crashing of motor vehicle at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0391",
      "description": "Assault by crashing of motor vehicle at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0392",
      "description": "Assault by crashing of motor vehicle at unspecified place While working for income"
    },
    {
      "name": "Y0393",
      "description": "Assault by crashing of motor vehicle at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0394",
      "description": "Assault by crashing of motor vehicle at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0398",
      "description": "Assault by crashing of motor vehicle at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0399",
      "description": "Assault by crashing of motor vehicle at unspecified place During unspecified activity"
    },
    {
      "name": "Y040",
      "description": "Assault by bodily force at home"
    },
    {
      "name": "Y0400",
      "description": "Assault by bodily force at home While engaged in sports activity"
    },
    {
      "name": "Y0401",
      "description": "Assault by bodily force at home While engaged in leisure activity"
    },
    {
      "name": "Y0402",
      "description": "Assault by bodily force at home While working for income"
    },
    {
      "name": "Y0403",
      "description": "Assault by bodily force at home While engaged in other types of work"
    },
    {
      "name": "Y0404",
      "description": "Assault by bodily force at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0408",
      "description": "Assault by bodily force at home While engaged in other specified activities"
    },
    {
      "name": "Y0409",
      "description": "Assault by bodily force at home During unspecified activity"
    },
    {
      "name": "Y041",
      "description": "Assault by bodily force at residential institution"
    },
    {
      "name": "Y0410",
      "description": "Assault by bodily force at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0411",
      "description": "Assault by bodily force at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0412",
      "description": "Assault by bodily force at residential institution While working for income"
    },
    {
      "name": "Y0413",
      "description": "Assault by bodily force at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0414",
      "description": "Assault by bodily force at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0418",
      "description": "Assault by bodily force at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0419",
      "description": "Assault by bodily force at residential institution During unspecified activity"
    },
    {
      "name": "Y042",
      "description": "Assault by bodily force at school,other institution and public administrative area"
    },
    {
      "name": "Y0420",
      "description": "Assault by bodily force at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0421",
      "description": "Assault by bodily force at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0422",
      "description": "Assault by bodily force at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0423",
      "description": "Assault by bodily force at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0424",
      "description": "Assault by bodily force at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0428",
      "description": "Assault by bodily force at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0429",
      "description": "Assault by bodily force at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y043",
      "description": "Assault by bodily force at sports and athletics area"
    },
    {
      "name": "Y0430",
      "description": "Assault by bodily force at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0431",
      "description": "Assault by bodily force at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0432",
      "description": "Assault by bodily force at sports and athletics area While working for income"
    },
    {
      "name": "Y0433",
      "description": "Assault by bodily force at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0434",
      "description": "Assault by bodily force at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0438",
      "description": "Assault by bodily force at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0439",
      "description": "Assault by bodily force at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y044",
      "description": "Assault by bodily force at street and highway"
    },
    {
      "name": "Y0440",
      "description": "Assault by bodily force at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0441",
      "description": "Assault by bodily force at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0442",
      "description": "Assault by bodily force at street and highway While working for income"
    },
    {
      "name": "Y0443",
      "description": "Assault by bodily force at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0444",
      "description": "Assault by bodily force at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0448",
      "description": "Assault by bodily force at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0449",
      "description": "Assault by bodily force at street and highway During unspecified activity"
    },
    {
      "name": "Y045",
      "description": "Assault by bodily force at trade and service area"
    },
    {
      "name": "Y0450",
      "description": "Assault by bodily force at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0451",
      "description": "Assault by bodily force at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0452",
      "description": "Assault by bodily force at trade and service area While working for income"
    },
    {
      "name": "Y0453",
      "description": "Assault by bodily force at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0454",
      "description": "Assault by bodily force at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0458",
      "description": "Assault by bodily force at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0459",
      "description": "Assault by bodily force at trade and service area During unspecified activity"
    },
    {
      "name": "Y046",
      "description": "Assault by bodily force at industrial and construction area"
    },
    {
      "name": "Y0460",
      "description": "Assault by bodily force at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0461",
      "description": "Assault by bodily force at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0462",
      "description": "Assault by bodily force at industrial and construction area While working for income"
    },
    {
      "name": "Y0463",
      "description": "Assault by bodily force at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0464",
      "description": "Assault by bodily force at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0468",
      "description": "Assault by bodily force at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0469",
      "description": "Assault by bodily force at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y047",
      "description": "Assault by bodily force at farm"
    },
    {
      "name": "Y0470",
      "description": "Assault by bodily force at farm While engaged in sports activity"
    },
    {
      "name": "Y0471",
      "description": "Assault by bodily force at farm While engaged in leisure activity"
    },
    {
      "name": "Y0472",
      "description": "Assault by bodily force at farm While working for income"
    },
    {
      "name": "Y0473",
      "description": "Assault by bodily force at farm While engaged in other types of work"
    },
    {
      "name": "Y0474",
      "description": "Assault by bodily force at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0478",
      "description": "Assault by bodily force at farm While engaged in other specified activities"
    },
    {
      "name": "Y0479",
      "description": "Assault by bodily force at farm During unspecified activity"
    },
    {
      "name": "Y048",
      "description": "Assault by bodily force at other specified places"
    },
    {
      "name": "Y0480",
      "description": "Assault by bodily force at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0481",
      "description": "Assault by bodily force at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0482",
      "description": "Assault by bodily force at other specified places While working for income"
    },
    {
      "name": "Y0483",
      "description": "Assault by bodily force at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0484",
      "description": "Assault by bodily force at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0488",
      "description": "Assault by bodily force at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0489",
      "description": "Assault by bodily force at other specified places During unspecified activity"
    },
    {
      "name": "Y049",
      "description": "Assault by bodily force at unspecified place"
    },
    {
      "name": "Y0490",
      "description": "Assault by bodily force at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0491",
      "description": "Assault by bodily force at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0492",
      "description": "Assault by bodily force at unspecified place While working for income"
    },
    {
      "name": "Y0493",
      "description": "Assault by bodily force at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0494",
      "description": "Assault by bodily force at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0498",
      "description": "Assault by bodily force at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0499",
      "description": "Assault by bodily force at unspecified place During unspecified activity"
    },
    {
      "name": "Y050",
      "description": "Sexual assault by bodily force at home"
    },
    {
      "name": "Y0500",
      "description": "Sexual assault by bodily force at home While engaged in sports activity"
    },
    {
      "name": "Y0501",
      "description": "Sexual assault by bodily force at home While engaged in leisure activity"
    },
    {
      "name": "Y0502",
      "description": "Sexual assault by bodily force at home While working for income"
    },
    {
      "name": "Y0503",
      "description": "Sexual assault by bodily force at home While engaged in other types of work"
    },
    {
      "name": "Y0504",
      "description": "Sexual assault by bodily force at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0508",
      "description": "Sexual assault by bodily force at home While engaged in other specified activities"
    },
    {
      "name": "Y0509",
      "description": "Sexual assault by bodily force at home During unspecified activity"
    },
    {
      "name": "Y051",
      "description": "Sexual assault by bodily force at residential institution"
    },
    {
      "name": "Y0510",
      "description": "Sexual assault by bodily force at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0511",
      "description": "Sexual assault by bodily force at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0512",
      "description": "Sexual assault by bodily force at residential institution While working for income"
    },
    {
      "name": "Y0513",
      "description": "Sexual assault by bodily force at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0514",
      "description": "Sexual assault by bodily force at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0518",
      "description": "Sexual assault by bodily force at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0519",
      "description": "Sexual assault by bodily force at residential institution During unspecified activity"
    },
    {
      "name": "Y052",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area"
    },
    {
      "name": "Y0520",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0521",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0522",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0523",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0524",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0528",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0529",
      "description": "Sexual assault by bodily force at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y053",
      "description": "Sexual assault by bodily force at sports and athletics area"
    },
    {
      "name": "Y0530",
      "description": "Sexual assault by bodily force at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0531",
      "description": "Sexual assault by bodily force at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0532",
      "description": "Sexual assault by bodily force at sports and athletics area While working for income"
    },
    {
      "name": "Y0533",
      "description": "Sexual assault by bodily force at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0534",
      "description": "Sexual assault by bodily force at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0538",
      "description": "Sexual assault by bodily force at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0539",
      "description": "Sexual assault by bodily force at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y054",
      "description": "Sexual assault by bodily force at street and highway"
    },
    {
      "name": "Y0540",
      "description": "Sexual assault by bodily force at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0541",
      "description": "Sexual assault by bodily force at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0542",
      "description": "Sexual assault by bodily force at street and highway While working for income"
    },
    {
      "name": "Y0543",
      "description": "Sexual assault by bodily force at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0544",
      "description": "Sexual assault by bodily force at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0548",
      "description": "Sexual assault by bodily force at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0549",
      "description": "Sexual assault by bodily force at street and highway During unspecified activity"
    },
    {
      "name": "Y055",
      "description": "Sexual assault by bodily force at trade and service area"
    },
    {
      "name": "Y0550",
      "description": "Sexual assault by bodily force at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0551",
      "description": "Sexual assault by bodily force at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0552",
      "description": "Sexual assault by bodily force at trade and service area While working for income"
    },
    {
      "name": "Y0553",
      "description": "Sexual assault by bodily force at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0554",
      "description": "Sexual assault by bodily force at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0558",
      "description": "Sexual assault by bodily force at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0559",
      "description": "Sexual assault by bodily force at trade and service area During unspecified activity"
    },
    {
      "name": "Y056",
      "description": "Sexual assault by bodily force at industrial and construction area"
    },
    {
      "name": "Y0560",
      "description": "Sexual assault by bodily force at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0561",
      "description": "Sexual assault by bodily force at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0562",
      "description": "Sexual assault by bodily force at industrial and construction area While working for income"
    },
    {
      "name": "Y0563",
      "description": "Sexual assault by bodily force at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0564",
      "description": "Sexual assault by bodily force at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0568",
      "description": "Sexual assault by bodily force at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0569",
      "description": "Sexual assault by bodily force at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y057",
      "description": "Sexual assault by bodily force at farm"
    },
    {
      "name": "Y0570",
      "description": "Sexual assault by bodily force at farm While engaged in sports activity"
    },
    {
      "name": "Y0571",
      "description": "Sexual assault by bodily force at farm While engaged in leisure activity"
    },
    {
      "name": "Y0572",
      "description": "Sexual assault by bodily force at farm While working for income"
    },
    {
      "name": "Y0573",
      "description": "Sexual assault by bodily force at farm While engaged in other types of work"
    },
    {
      "name": "Y0574",
      "description": "Sexual assault by bodily force at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0578",
      "description": "Sexual assault by bodily force at farm While engaged in other specified activities"
    },
    {
      "name": "Y0579",
      "description": "Sexual assault by bodily force at farm During unspecified activity"
    },
    {
      "name": "Y058",
      "description": "Sexual assault by bodily force at other specified places"
    },
    {
      "name": "Y0580",
      "description": "Sexual assault by bodily force at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0581",
      "description": "Sexual assault by bodily force at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0582",
      "description": "Sexual assault by bodily force at other specified places While working for income"
    },
    {
      "name": "Y0583",
      "description": "Sexual assault by bodily force at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0584",
      "description": "Sexual assault by bodily force at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0588",
      "description": "Sexual assault by bodily force at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0589",
      "description": "Sexual assault by bodily force at other specified places During unspecified activity"
    },
    {
      "name": "Y059",
      "description": "Sexual assault by bodily force at unspecified place"
    },
    {
      "name": "Y0590",
      "description": "Sexual assault by bodily force at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0591",
      "description": "Sexual assault by bodily force at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0592",
      "description": "Sexual assault by bodily force at unspecified place While working for income"
    },
    {
      "name": "Y0593",
      "description": "Sexual assault by bodily force at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0594",
      "description": "Sexual assault by bodily force at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0598",
      "description": "Sexual assault by bodily force at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0599",
      "description": "Sexual assault by bodily force at unspecified place During unspecified activity"
    },
    {
      "name": "Y060",
      "description": "Neglect and abandonment By spouse or partner"
    },
    {
      "name": "Y0600",
      "description": "Neglect and abandonment By spouse or partner at home "
    },
    {
      "name": "Y06000",
      "description": "Neglect and abandonment By spouse or partner at home  While engaged in sports activity"
    },
    {
      "name": "Y06001",
      "description": "Neglect and abandonment By spouse or partner at home  While engaged in leisure activity"
    },
    {
      "name": "Y06002",
      "description": "Neglect and abandonment By spouse or partner at home  While working for income"
    },
    {
      "name": "Y06003",
      "description": "Neglect and abandonment By spouse or partner at home  While engaged in other types of work"
    },
    {
      "name": "Y06004",
      "description": "Neglect and abandonment By spouse or partner at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06008",
      "description": "Neglect and abandonment By spouse or partner at home  While engaged in other specified activities"
    },
    {
      "name": "Y06009",
      "description": "Neglect and abandonment By spouse or partner at home  During unspecified activity"
    },
    {
      "name": "Y0601",
      "description": "Neglect and abandonment By spouse or partner at residential institution "
    },
    {
      "name": "Y06010",
      "description": "Neglect and abandonment By spouse or partner at residential institution While engaged in sports activity"
    },
    {
      "name": "Y06011",
      "description": "Neglect and abandonment By spouse or partner at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y06012",
      "description": "Neglect and abandonment By spouse or partner at residential institution While working for income"
    },
    {
      "name": "Y06013",
      "description": "Neglect and abandonment By spouse or partner at residential institution While engaged in other types of work"
    },
    {
      "name": "Y06014",
      "description": "Neglect and abandonment By spouse or partner at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06018",
      "description": "Neglect and abandonment By spouse or partner at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y06019",
      "description": "Neglect and abandonment By spouse or partner at residential institution During unspecified activity"
    },
    {
      "name": "Y0602",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area "
    },
    {
      "name": "Y06020",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y06021",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y06022",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y06023",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y06024",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06028",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y06029",
      "description": "Neglect and abandonment By spouse or partner at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0603",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area"
    },
    {
      "name": "Y06030",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y06031",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y06032",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While working for income"
    },
    {
      "name": "Y06033",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y06034",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06038",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y06039",
      "description": "Neglect and abandonment By spouse or partner at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0604",
      "description": "Neglect and abandonment By spouse or partner at street and highway"
    },
    {
      "name": "Y06040",
      "description": "Neglect and abandonment By spouse or partner at street and highway While engaged in sports activity"
    },
    {
      "name": "Y06041",
      "description": "Neglect and abandonment By spouse or partner at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y06042",
      "description": "Neglect and abandonment By spouse or partner at street and highway While working for income"
    },
    {
      "name": "Y06043",
      "description": "Neglect and abandonment By spouse or partner at street and highway While engaged in other types of work"
    },
    {
      "name": "Y06044",
      "description": "Neglect and abandonment By spouse or partner at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06048",
      "description": "Neglect and abandonment By spouse or partner at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y06049",
      "description": "Neglect and abandonment By spouse or partner at street and highway During unspecified activity"
    },
    {
      "name": "Y0605",
      "description": "Neglect and abandonment By spouse or partner at trade and service area "
    },
    {
      "name": "Y06050",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y06051",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y06052",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While working for income"
    },
    {
      "name": "Y06053",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y06054",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06058",
      "description": "Neglect and abandonment By spouse or partner at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y06059",
      "description": "Neglect and abandonment By spouse or partner at trade and service area During unspecified activity"
    },
    {
      "name": "Y0606",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area "
    },
    {
      "name": "Y06060",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y06061",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y06062",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While working for income"
    },
    {
      "name": "Y06063",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y06064",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06068",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y06069",
      "description": "Neglect and abandonment By spouse or partner at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0607",
      "description": "Neglect and abandonment By spouse or partner at farm "
    },
    {
      "name": "Y06070",
      "description": "Neglect and abandonment By spouse or partner at farm While engaged in sports activity"
    },
    {
      "name": "Y06071",
      "description": "Neglect and abandonment By spouse or partner at farm While engaged in leisure activity"
    },
    {
      "name": "Y06072",
      "description": "Neglect and abandonment By spouse or partner at farm While working for income"
    },
    {
      "name": "Y06073",
      "description": "Neglect and abandonment By spouse or partner at farm While engaged in other types of work"
    },
    {
      "name": "Y06074",
      "description": "Neglect and abandonment By spouse or partner at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06078",
      "description": "Neglect and abandonment By spouse or partner at farm While engaged in other specified activities"
    },
    {
      "name": "Y06079",
      "description": "Neglect and abandonment By spouse or partner at farm During unspecified activity"
    },
    {
      "name": "Y0608",
      "description": "Neglect and abandonment By spouse or partner at other specified places"
    },
    {
      "name": "Y06080",
      "description": "Neglect and abandonment By spouse or partner at other specified places While engaged in sports activity"
    },
    {
      "name": "Y06081",
      "description": "Neglect and abandonment By spouse or partner at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y06082",
      "description": "Neglect and abandonment By spouse or partner at other specified places While working for income"
    },
    {
      "name": "Y06083",
      "description": "Neglect and abandonment By spouse or partner at other specified places While engaged in other types of work"
    },
    {
      "name": "Y06084",
      "description": "Neglect and abandonment By spouse or partner at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06088",
      "description": "Neglect and abandonment By spouse or partner at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y06089",
      "description": "Neglect and abandonment By spouse or partner at other specified places During unspecified activity"
    },
    {
      "name": "Y0609",
      "description": "Neglect and abandonment By spouse or partner at unspecified place"
    },
    {
      "name": "Y06090",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y06091",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y06092",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While working for income"
    },
    {
      "name": "Y06093",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y06094",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06098",
      "description": "Neglect and abandonment By spouse or partner at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y06099",
      "description": "Neglect and abandonment By spouse or partner at unspecified place During unspecified activity"
    },
    {
      "name": "Y061",
      "description": "Neglect and abandonment By parent"
    },
    {
      "name": "Y0610",
      "description": "Neglect and abandonment By parent at home"
    },
    {
      "name": "Y06100",
      "description": "Neglect and abandonment By parent at home  While engaged in sports activity"
    },
    {
      "name": "Y06101",
      "description": "Neglect and abandonment By parent at home  While engaged in leisure activity"
    },
    {
      "name": "Y06102",
      "description": "Neglect and abandonment By parent at home  While working for income"
    },
    {
      "name": "Y06103",
      "description": "Neglect and abandonment By parent at home  While engaged in other types of work"
    },
    {
      "name": "Y06104",
      "description": "Neglect and abandonment By parent at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06108",
      "description": "Neglect and abandonment By parent at home  While engaged in other specified activities"
    },
    {
      "name": "Y06109",
      "description": "Neglect and abandonment By parent at home  During unspecified activity"
    },
    {
      "name": "Y0611",
      "description": "Neglect and abandonment By parent at residential institution "
    },
    {
      "name": "Y06110",
      "description": "Neglect and abandonment By parent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y06111",
      "description": "Neglect and abandonment By parent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y06112",
      "description": "Neglect and abandonment By parent at residential institution While working for income"
    },
    {
      "name": "Y06113",
      "description": "Neglect and abandonment By parent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y06114",
      "description": "Neglect and abandonment By parent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06118",
      "description": "Neglect and abandonment By parent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y06119",
      "description": "Neglect and abandonment By parent at residential institution During unspecified activity"
    },
    {
      "name": "Y0612",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area "
    },
    {
      "name": "Y06120",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y06121",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y06122",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y06123",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y06124",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06128",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y06129",
      "description": "Neglect and abandonment By parent at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0613",
      "description": "Neglect and abandonment By parent at sports and athletics area"
    },
    {
      "name": "Y06130",
      "description": "Neglect and abandonment By parent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y06131",
      "description": "Neglect and abandonment By parent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y06132",
      "description": "Neglect and abandonment By parent at sports and athletics area While working for income"
    },
    {
      "name": "Y06133",
      "description": "Neglect and abandonment By parent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y06134",
      "description": "Neglect and abandonment By parent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06138",
      "description": "Neglect and abandonment By parent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y06139",
      "description": "Neglect and abandonment By parent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0614",
      "description": "Neglect and abandonment By parent at street and highway"
    },
    {
      "name": "Y06140",
      "description": "Neglect and abandonment By parent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y06141",
      "description": "Neglect and abandonment By parent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y06142",
      "description": "Neglect and abandonment By parent at street and highway While working for income"
    },
    {
      "name": "Y06143",
      "description": "Neglect and abandonment By parent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y06144",
      "description": "Neglect and abandonment By parent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06148",
      "description": "Neglect and abandonment By parent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y06149",
      "description": "Neglect and abandonment By parent at street and highway During unspecified activity"
    },
    {
      "name": "Y0615",
      "description": "Neglect and abandonment By parent at trade and service area "
    },
    {
      "name": "Y06150",
      "description": "Neglect and abandonment By parent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y06151",
      "description": "Neglect and abandonment By parent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y06152",
      "description": "Neglect and abandonment By parent at trade and service area While working for income"
    },
    {
      "name": "Y06153",
      "description": "Neglect and abandonment By parent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y06154",
      "description": "Neglect and abandonment By parent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06158",
      "description": "Neglect and abandonment By parent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y06159",
      "description": "Neglect and abandonment By parent at trade and service area During unspecified activity"
    },
    {
      "name": "Y0616",
      "description": "Neglect and abandonment By parent at industrial and construction area "
    },
    {
      "name": "Y06160",
      "description": "Neglect and abandonment By parent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y06161",
      "description": "Neglect and abandonment By parent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y06162",
      "description": "Neglect and abandonment By parent at industrial and construction area While working for income"
    },
    {
      "name": "Y06163",
      "description": "Neglect and abandonment By parent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y06164",
      "description": "Neglect and abandonment By parent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06168",
      "description": "Neglect and abandonment By parent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y06169",
      "description": "Neglect and abandonment By parent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0617",
      "description": "Neglect and abandonment By parent at farm "
    },
    {
      "name": "Y06170",
      "description": "Neglect and abandonment By parent at farm While engaged in sports activity"
    },
    {
      "name": "Y06171",
      "description": "Neglect and abandonment By parent at farm While engaged in leisure activity"
    },
    {
      "name": "Y06172",
      "description": "Neglect and abandonment By parent at farm While working for income"
    },
    {
      "name": "Y06173",
      "description": "Neglect and abandonment By parent at farm While engaged in other types of work"
    },
    {
      "name": "Y06174",
      "description": "Neglect and abandonment By parent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06178",
      "description": "Neglect and abandonment By parent at farm While engaged in other specified activities"
    },
    {
      "name": "Y06179",
      "description": "Neglect and abandonment By parent at farm During unspecified activity"
    },
    {
      "name": "Y0618",
      "description": "Neglect and abandonment By parent at other specified places"
    },
    {
      "name": "Y06180",
      "description": "Neglect and abandonment By parent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y06181",
      "description": "Neglect and abandonment By parent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y06182",
      "description": "Neglect and abandonment By parent at other specified places While working for income"
    },
    {
      "name": "Y06183",
      "description": "Neglect and abandonment By parent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y06184",
      "description": "Neglect and abandonment By parent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06188",
      "description": "Neglect and abandonment By parent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y06189",
      "description": "Neglect and abandonment By parent at other specified places During unspecified activity"
    },
    {
      "name": "Y0619",
      "description": "Neglect and abandonment By parent at unspecified place"
    },
    {
      "name": "Y06190",
      "description": "Neglect and abandonment By parent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y06191",
      "description": "Neglect and abandonment By parent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y06192",
      "description": "Neglect and abandonment By parent at unspecified place While working for income"
    },
    {
      "name": "Y06193",
      "description": "Neglect and abandonment By parent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y06194",
      "description": "Neglect and abandonment By parent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06198",
      "description": "Neglect and abandonment By parent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y06199",
      "description": "Neglect and abandonment By parent at unspecified place During unspecified activity"
    },
    {
      "name": "Y062",
      "description": "Neglect and abandonment By acquaintance or friend"
    },
    {
      "name": "Y0620",
      "description": "Neglect and abandonment By acquaintance or friend at home"
    },
    {
      "name": "Y06200",
      "description": "Neglect and abandonment By acquaintance or friend at home  While engaged in sports activity"
    },
    {
      "name": "Y06201",
      "description": "Neglect and abandonment By acquaintance or friend at home  While engaged in leisure activity"
    },
    {
      "name": "Y06202",
      "description": "Neglect and abandonment By acquaintance or friend at home  While working for income"
    },
    {
      "name": "Y06203",
      "description": "Neglect and abandonment By acquaintance or friend at home  While engaged in other types of work"
    },
    {
      "name": "Y06204",
      "description": "Neglect and abandonment By acquaintance or friend at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06208",
      "description": "Neglect and abandonment By acquaintance or friend at home  While engaged in other specified activities"
    },
    {
      "name": "Y06209",
      "description": "Neglect and abandonment By acquaintance or friend at home  During unspecified activity"
    },
    {
      "name": "Y0621",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution "
    },
    {
      "name": "Y06210",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While engaged in sports activity"
    },
    {
      "name": "Y06211",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y06212",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While working for income"
    },
    {
      "name": "Y06213",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While engaged in other types of work"
    },
    {
      "name": "Y06214",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06218",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y06219",
      "description": "Neglect and abandonment By acquaintance or friend at residential institution During unspecified activity"
    },
    {
      "name": "Y0622",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area "
    },
    {
      "name": "Y06220",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y06221",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y06222",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y06223",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y06224",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06228",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y06229",
      "description": "Neglect and abandonment By acquaintance or friend at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0623",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area"
    },
    {
      "name": "Y06230",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y06231",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y06232",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While working for income"
    },
    {
      "name": "Y06233",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y06234",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06238",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y06239",
      "description": "Neglect and abandonment By acquaintance or friend at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0624",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway"
    },
    {
      "name": "Y06240",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While engaged in sports activity"
    },
    {
      "name": "Y06241",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y06242",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While working for income"
    },
    {
      "name": "Y06243",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While engaged in other types of work"
    },
    {
      "name": "Y06244",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06248",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y06249",
      "description": "Neglect and abandonment By acquaintance or friend at street and highway During unspecified activity"
    },
    {
      "name": "Y0625",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area "
    },
    {
      "name": "Y06250",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y06251",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y06252",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While working for income"
    },
    {
      "name": "Y06253",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y06254",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06258",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y06259",
      "description": "Neglect and abandonment By acquaintance or friend at trade and service area During unspecified activity"
    },
    {
      "name": "Y0626",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area "
    },
    {
      "name": "Y06260",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y06261",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y06262",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While working for income"
    },
    {
      "name": "Y06263",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y06264",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06268",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y06269",
      "description": "Neglect and abandonment By acquaintance or friend at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0627",
      "description": "Neglect and abandonment By acquaintance or friend at farm "
    },
    {
      "name": "Y06270",
      "description": "Neglect and abandonment By acquaintance or friend at farm While engaged in sports activity"
    },
    {
      "name": "Y06271",
      "description": "Neglect and abandonment By acquaintance or friend at farm While engaged in leisure activity"
    },
    {
      "name": "Y06272",
      "description": "Neglect and abandonment By acquaintance or friend at farm While working for income"
    },
    {
      "name": "Y06273",
      "description": "Neglect and abandonment By acquaintance or friend at farm While engaged in other types of work"
    },
    {
      "name": "Y06274",
      "description": "Neglect and abandonment By acquaintance or friend at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06278",
      "description": "Neglect and abandonment By acquaintance or friend at farm While engaged in other specified activities"
    },
    {
      "name": "Y06279",
      "description": "Neglect and abandonment By acquaintance or friend at farm During unspecified activity"
    },
    {
      "name": "Y0628",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places"
    },
    {
      "name": "Y06280",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While engaged in sports activity"
    },
    {
      "name": "Y06281",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y06282",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While working for income"
    },
    {
      "name": "Y06283",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While engaged in other types of work"
    },
    {
      "name": "Y06284",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06288",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y06289",
      "description": "Neglect and abandonment By acquaintance or friend at other specified places During unspecified activity"
    },
    {
      "name": "Y0629",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place"
    },
    {
      "name": "Y06290",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y06291",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y06292",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While working for income"
    },
    {
      "name": "Y06293",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y06294",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06298",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y06299",
      "description": "Neglect and abandonment By acquaintance or friend at unspecified place During unspecified activity"
    },
    {
      "name": "Y068",
      "description": "Neglect and abandonment By other specified persons"
    },
    {
      "name": "Y0680",
      "description": "Neglect and abandonment By other specified persons at home"
    },
    {
      "name": "Y06800",
      "description": "Neglect and abandonment By other specified persons at home  While engaged in sports activity"
    },
    {
      "name": "Y06801",
      "description": "Neglect and abandonment By other specified persons at home  While engaged in leisure activity"
    },
    {
      "name": "Y06802",
      "description": "Neglect and abandonment By other specified persons at home  While working for income"
    },
    {
      "name": "Y06803",
      "description": "Neglect and abandonment By other specified persons at home  While engaged in other types of work"
    },
    {
      "name": "Y06804",
      "description": "Neglect and abandonment By other specified persons at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06808",
      "description": "Neglect and abandonment By other specified persons at home  While engaged in other specified activities"
    },
    {
      "name": "Y06809",
      "description": "Neglect and abandonment By other specified persons at home  During unspecified activity"
    },
    {
      "name": "Y0681",
      "description": "Neglect and abandonment By other specified persons at residential institution "
    },
    {
      "name": "Y06810",
      "description": "Neglect and abandonment By other specified persons at residential institution While engaged in sports activity"
    },
    {
      "name": "Y06811",
      "description": "Neglect and abandonment By other specified persons at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y06812",
      "description": "Neglect and abandonment By other specified persons at residential institution While working for income"
    },
    {
      "name": "Y06813",
      "description": "Neglect and abandonment By other specified persons at residential institution While engaged in other types of work"
    },
    {
      "name": "Y06814",
      "description": "Neglect and abandonment By other specified persons at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06818",
      "description": "Neglect and abandonment By other specified persons at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y06819",
      "description": "Neglect and abandonment By other specified persons at residential institution During unspecified activity"
    },
    {
      "name": "Y0682",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area "
    },
    {
      "name": "Y06820",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y06821",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y06822",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y06823",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y06824",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06828",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y06829",
      "description": "Neglect and abandonment By other specified persons at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0683",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area"
    },
    {
      "name": "Y06830",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y06831",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y06832",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While working for income"
    },
    {
      "name": "Y06833",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y06834",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06838",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y06839",
      "description": "Neglect and abandonment By other specified persons at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0684",
      "description": "Neglect and abandonment By other specified persons at street and highway"
    },
    {
      "name": "Y06840",
      "description": "Neglect and abandonment By other specified persons at street and highway While engaged in sports activity"
    },
    {
      "name": "Y06841",
      "description": "Neglect and abandonment By other specified persons at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y06842",
      "description": "Neglect and abandonment By other specified persons at street and highway While working for income"
    },
    {
      "name": "Y06843",
      "description": "Neglect and abandonment By other specified persons at street and highway While engaged in other types of work"
    },
    {
      "name": "Y06844",
      "description": "Neglect and abandonment By other specified persons at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06848",
      "description": "Neglect and abandonment By other specified persons at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y06849",
      "description": "Neglect and abandonment By other specified persons at street and highway During unspecified activity"
    },
    {
      "name": "Y0685",
      "description": "Neglect and abandonment By other specified persons at trade and service area "
    },
    {
      "name": "Y06850",
      "description": "Neglect and abandonment By other specified persons at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y06851",
      "description": "Neglect and abandonment By other specified persons at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y06852",
      "description": "Neglect and abandonment By other specified persons at trade and service area While working for income"
    },
    {
      "name": "Y06853",
      "description": "Neglect and abandonment By other specified persons at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y06854",
      "description": "Neglect and abandonment By other specified persons at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06858",
      "description": "Neglect and abandonment By other specified persons at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y06859",
      "description": "Neglect and abandonment By other specified persons at trade and service area During unspecified activity"
    },
    {
      "name": "Y0686",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area "
    },
    {
      "name": "Y06860",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y06861",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y06862",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While working for income"
    },
    {
      "name": "Y06863",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y06864",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06868",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y06869",
      "description": "Neglect and abandonment By other specified persons at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0687",
      "description": "Neglect and abandonment By other specified persons at farm "
    },
    {
      "name": "Y06870",
      "description": "Neglect and abandonment By other specified persons at farm While engaged in sports activity"
    },
    {
      "name": "Y06871",
      "description": "Neglect and abandonment By other specified persons at farm While engaged in leisure activity"
    },
    {
      "name": "Y06872",
      "description": "Neglect and abandonment By other specified persons at farm While working for income"
    },
    {
      "name": "Y06873",
      "description": "Neglect and abandonment By other specified persons at farm While engaged in other types of work"
    },
    {
      "name": "Y06874",
      "description": "Neglect and abandonment By other specified persons at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06878",
      "description": "Neglect and abandonment By other specified persons at farm While engaged in other specified activities"
    },
    {
      "name": "Y06879",
      "description": "Neglect and abandonment By other specified persons at farm During unspecified activity"
    },
    {
      "name": "Y0688",
      "description": "Neglect and abandonment By other specified persons at other specified places"
    },
    {
      "name": "Y06880",
      "description": "Neglect and abandonment By other specified persons at other specified places While engaged in sports activity"
    },
    {
      "name": "Y06881",
      "description": "Neglect and abandonment By other specified persons at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y06882",
      "description": "Neglect and abandonment By other specified persons at other specified places While working for income"
    },
    {
      "name": "Y06883",
      "description": "Neglect and abandonment By other specified persons at other specified places While engaged in other types of work"
    },
    {
      "name": "Y06884",
      "description": "Neglect and abandonment By other specified persons at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06888",
      "description": "Neglect and abandonment By other specified persons at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y06889",
      "description": "Neglect and abandonment By other specified persons at other specified places During unspecified activity"
    },
    {
      "name": "Y0689",
      "description": "Neglect and abandonment By other specified persons at unspecified place"
    },
    {
      "name": "Y06890",
      "description": "Neglect and abandonment By other specified persons at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y06891",
      "description": "Neglect and abandonment By other specified persons at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y06892",
      "description": "Neglect and abandonment By other specified persons at unspecified place While working for income"
    },
    {
      "name": "Y06893",
      "description": "Neglect and abandonment By other specified persons at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y06894",
      "description": "Neglect and abandonment By other specified persons at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06898",
      "description": "Neglect and abandonment By other specified persons at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y06899",
      "description": "Neglect and abandonment By other specified persons at unspecified place During unspecified activity"
    },
    {
      "name": "Y069",
      "description": "Neglect and abandonment By unspecified person"
    },
    {
      "name": "Y0690",
      "description": "Neglect and abandonment By unspecified person at home"
    },
    {
      "name": "Y06900",
      "description": "Neglect and abandonment By unspecified person at home  While engaged in sports activity"
    },
    {
      "name": "Y06901",
      "description": "Neglect and abandonment By unspecified person at home  While engaged in leisure activity"
    },
    {
      "name": "Y06902",
      "description": "Neglect and abandonment By unspecified person at home  While working for income"
    },
    {
      "name": "Y06903",
      "description": "Neglect and abandonment By unspecified person at home  While engaged in other types of work"
    },
    {
      "name": "Y06904",
      "description": "Neglect and abandonment By unspecified person at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06908",
      "description": "Neglect and abandonment By unspecified person at home  While engaged in other specified activities"
    },
    {
      "name": "Y06909",
      "description": "Neglect and abandonment By unspecified person at home  During unspecified activity"
    },
    {
      "name": "Y0691",
      "description": "Neglect and abandonment By unspecified person at residential institution "
    },
    {
      "name": "Y06910",
      "description": "Neglect and abandonment By unspecified person at residential institution While engaged in sports activity"
    },
    {
      "name": "Y06911",
      "description": "Neglect and abandonment By unspecified person at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y06912",
      "description": "Neglect and abandonment By unspecified person at residential institution While working for income"
    },
    {
      "name": "Y06913",
      "description": "Neglect and abandonment By unspecified person at residential institution While engaged in other types of work"
    },
    {
      "name": "Y06914",
      "description": "Neglect and abandonment By unspecified person at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06918",
      "description": "Neglect and abandonment By unspecified person at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y06919",
      "description": "Neglect and abandonment By unspecified person at residential institution During unspecified activity"
    },
    {
      "name": "Y0692",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area "
    },
    {
      "name": "Y06920",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y06921",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y06922",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y06923",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y06924",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06928",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y06929",
      "description": "Neglect and abandonment By unspecified person at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0693",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area"
    },
    {
      "name": "Y06930",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y06931",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y06932",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While working for income"
    },
    {
      "name": "Y06933",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y06934",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06938",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y06939",
      "description": "Neglect and abandonment By unspecified person at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0694",
      "description": "Neglect and abandonment By unspecified person at street and highway"
    },
    {
      "name": "Y06940",
      "description": "Neglect and abandonment By unspecified person at street and highway While engaged in sports activity"
    },
    {
      "name": "Y06941",
      "description": "Neglect and abandonment By unspecified person at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y06942",
      "description": "Neglect and abandonment By unspecified person at street and highway While working for income"
    },
    {
      "name": "Y06943",
      "description": "Neglect and abandonment By unspecified person at street and highway While engaged in other types of work"
    },
    {
      "name": "Y06944",
      "description": "Neglect and abandonment By unspecified person at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06948",
      "description": "Neglect and abandonment By unspecified person at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y06949",
      "description": "Neglect and abandonment By unspecified person at street and highway During unspecified activity"
    },
    {
      "name": "Y0695",
      "description": "Neglect and abandonment By unspecified person at trade and service area "
    },
    {
      "name": "Y06950",
      "description": "Neglect and abandonment By unspecified person at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y06951",
      "description": "Neglect and abandonment By unspecified person at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y06952",
      "description": "Neglect and abandonment By unspecified person at trade and service area While working for income"
    },
    {
      "name": "Y06953",
      "description": "Neglect and abandonment By unspecified person at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y06954",
      "description": "Neglect and abandonment By unspecified person at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06958",
      "description": "Neglect and abandonment By unspecified person at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y06959",
      "description": "Neglect and abandonment By unspecified person at trade and service area During unspecified activity"
    },
    {
      "name": "Y0696",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area "
    },
    {
      "name": "Y06960",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y06961",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y06962",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While working for income"
    },
    {
      "name": "Y06963",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y06964",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06968",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y06969",
      "description": "Neglect and abandonment By unspecified person at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0697",
      "description": "Neglect and abandonment By unspecified person at farm "
    },
    {
      "name": "Y06970",
      "description": "Neglect and abandonment By unspecified person at farm While engaged in sports activity"
    },
    {
      "name": "Y06971",
      "description": "Neglect and abandonment By unspecified person at farm While engaged in leisure activity"
    },
    {
      "name": "Y06972",
      "description": "Neglect and abandonment By unspecified person at farm While working for income"
    },
    {
      "name": "Y06973",
      "description": "Neglect and abandonment By unspecified person at farm While engaged in other types of work"
    },
    {
      "name": "Y06974",
      "description": "Neglect and abandonment By unspecified person at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06978",
      "description": "Neglect and abandonment By unspecified person at farm While engaged in other specified activities"
    },
    {
      "name": "Y06979",
      "description": "Neglect and abandonment By unspecified person at farm During unspecified activity"
    },
    {
      "name": "Y0698",
      "description": "Neglect and abandonment By unspecified person at other specified places"
    },
    {
      "name": "Y06980",
      "description": "Neglect and abandonment By unspecified person at other specified places While engaged in sports activity"
    },
    {
      "name": "Y06981",
      "description": "Neglect and abandonment By unspecified person at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y06982",
      "description": "Neglect and abandonment By unspecified person at other specified places While working for income"
    },
    {
      "name": "Y06983",
      "description": "Neglect and abandonment By unspecified person at other specified places While engaged in other types of work"
    },
    {
      "name": "Y06984",
      "description": "Neglect and abandonment By unspecified person at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06988",
      "description": "Neglect and abandonment By unspecified person at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y06989",
      "description": "Neglect and abandonment By unspecified person at other specified places During unspecified activity"
    },
    {
      "name": "Y0699",
      "description": "Neglect and abandonment By unspecified person at unspecified place"
    },
    {
      "name": "Y06990",
      "description": "Neglect and abandonment By unspecified person at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y06991",
      "description": "Neglect and abandonment By unspecified person at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y06992",
      "description": "Neglect and abandonment By unspecified person at unspecified place While working for income"
    },
    {
      "name": "Y06993",
      "description": "Neglect and abandonment By unspecified person at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y06994",
      "description": "Neglect and abandonment By unspecified person at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y06998",
      "description": "Neglect and abandonment By unspecified person at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y06999",
      "description": "Neglect and abandonment By unspecified person at unspecified place During unspecified activity"
    },
    {
      "name": "Y070",
      "description": "Other maltreatment syndromes By spouse or partner"
    },
    {
      "name": "Y0700",
      "description": "Other maltreatment syndromes By spouse or partner at home"
    },
    {
      "name": "Y07000",
      "description": "Other maltreatment syndromes By spouse or partner at home  While engaged in sports activity"
    },
    {
      "name": "Y07001",
      "description": "Other maltreatment syndromes By spouse or partner at home  While engaged in leisure activity"
    },
    {
      "name": "Y07002",
      "description": "Other maltreatment syndromes By spouse or partner at home  While working for income"
    },
    {
      "name": "Y07003",
      "description": "Other maltreatment syndromes By spouse or partner at home  While engaged in other types of work"
    },
    {
      "name": "Y07004",
      "description": "Other maltreatment syndromes By spouse or partner at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07008",
      "description": "Other maltreatment syndromes By spouse or partner at home  While engaged in other specified activities"
    },
    {
      "name": "Y07009",
      "description": "Other maltreatment syndromes By spouse or partner at home  During unspecified activity"
    },
    {
      "name": "Y0701",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution "
    },
    {
      "name": "Y07010",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07011",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07012",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While working for income"
    },
    {
      "name": "Y07013",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07014",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07018",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07019",
      "description": "Other maltreatment syndromes By spouse or partner at residential institution During unspecified activity"
    },
    {
      "name": "Y0702",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area "
    },
    {
      "name": "Y07020",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07021",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07022",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07023",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07024",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07028",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07029",
      "description": "Other maltreatment syndromes By spouse or partner at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0703",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area"
    },
    {
      "name": "Y07030",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07031",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07032",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While working for income"
    },
    {
      "name": "Y07033",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07034",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07038",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07039",
      "description": "Other maltreatment syndromes By spouse or partner at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0704",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway"
    },
    {
      "name": "Y07040",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07041",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07042",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While working for income"
    },
    {
      "name": "Y07043",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07044",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07048",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07049",
      "description": "Other maltreatment syndromes By spouse or partner at street and highway During unspecified activity"
    },
    {
      "name": "Y0705",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area "
    },
    {
      "name": "Y07050",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07051",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07052",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While working for income"
    },
    {
      "name": "Y07053",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07054",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07058",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07059",
      "description": "Other maltreatment syndromes By spouse or partner at trade and service area During unspecified activity"
    },
    {
      "name": "Y0706",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area "
    },
    {
      "name": "Y07060",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07061",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07062",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While working for income"
    },
    {
      "name": "Y07063",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07064",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07068",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07069",
      "description": "Other maltreatment syndromes By spouse or partner at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0707",
      "description": "Other maltreatment syndromes By spouse or partner at farm "
    },
    {
      "name": "Y07070",
      "description": "Other maltreatment syndromes By spouse or partner at farm While engaged in sports activity"
    },
    {
      "name": "Y07071",
      "description": "Other maltreatment syndromes By spouse or partner at farm While engaged in leisure activity"
    },
    {
      "name": "Y07072",
      "description": "Other maltreatment syndromes By spouse or partner at farm While working for income"
    },
    {
      "name": "Y07073",
      "description": "Other maltreatment syndromes By spouse or partner at farm While engaged in other types of work"
    },
    {
      "name": "Y07074",
      "description": "Other maltreatment syndromes By spouse or partner at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07078",
      "description": "Other maltreatment syndromes By spouse or partner at farm While engaged in other specified activities"
    },
    {
      "name": "Y07079",
      "description": "Other maltreatment syndromes By spouse or partner at farm During unspecified activity"
    },
    {
      "name": "Y0708",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places"
    },
    {
      "name": "Y07080",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07081",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07082",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While working for income"
    },
    {
      "name": "Y07083",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07084",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07088",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07089",
      "description": "Other maltreatment syndromes By spouse or partner at other specified places During unspecified activity"
    },
    {
      "name": "Y0709",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place"
    },
    {
      "name": "Y07090",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07091",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07092",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While working for income"
    },
    {
      "name": "Y07093",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07094",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07098",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07099",
      "description": "Other maltreatment syndromes By spouse or partner at unspecified place During unspecified activity"
    },
    {
      "name": "Y071",
      "description": "Other maltreatment syndromes By parent"
    },
    {
      "name": "Y0710",
      "description": "Other maltreatment syndromes By parent at home"
    },
    {
      "name": "Y07100",
      "description": "Other maltreatment syndromes By parent at home  While engaged in sports activity"
    },
    {
      "name": "Y07101",
      "description": "Other maltreatment syndromes By parent at home  While engaged in leisure activity"
    },
    {
      "name": "Y07102",
      "description": "Other maltreatment syndromes By parent at home  While working for income"
    },
    {
      "name": "Y07103",
      "description": "Other maltreatment syndromes By parent at home  While engaged in other types of work"
    },
    {
      "name": "Y07104",
      "description": "Other maltreatment syndromes By parent at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07108",
      "description": "Other maltreatment syndromes By parent at home  While engaged in other specified activities"
    },
    {
      "name": "Y07109",
      "description": "Other maltreatment syndromes By parent at home  During unspecified activity"
    },
    {
      "name": "Y0711",
      "description": "Other maltreatment syndromes By parent at residential institution "
    },
    {
      "name": "Y07110",
      "description": "Other maltreatment syndromes By parent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07111",
      "description": "Other maltreatment syndromes By parent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07112",
      "description": "Other maltreatment syndromes By parent at residential institution While working for income"
    },
    {
      "name": "Y07113",
      "description": "Other maltreatment syndromes By parent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07114",
      "description": "Other maltreatment syndromes By parent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07118",
      "description": "Other maltreatment syndromes By parent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07119",
      "description": "Other maltreatment syndromes By parent at residential institution During unspecified activity"
    },
    {
      "name": "Y0712",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area "
    },
    {
      "name": "Y07120",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07121",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07122",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07123",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07124",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07128",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07129",
      "description": "Other maltreatment syndromes By parent at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0713",
      "description": "Other maltreatment syndromes By parent at sports and athletics area"
    },
    {
      "name": "Y07130",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07131",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07132",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While working for income"
    },
    {
      "name": "Y07133",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07134",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07138",
      "description": "Other maltreatment syndromes By parent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07139",
      "description": "Other maltreatment syndromes By parent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0714",
      "description": "Other maltreatment syndromes By parent at street and highway"
    },
    {
      "name": "Y07140",
      "description": "Other maltreatment syndromes By parent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07141",
      "description": "Other maltreatment syndromes By parent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07142",
      "description": "Other maltreatment syndromes By parent at street and highway While working for income"
    },
    {
      "name": "Y07143",
      "description": "Other maltreatment syndromes By parent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07144",
      "description": "Other maltreatment syndromes By parent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07148",
      "description": "Other maltreatment syndromes By parent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07149",
      "description": "Other maltreatment syndromes By parent at street and highway During unspecified activity"
    },
    {
      "name": "Y0715",
      "description": "Other maltreatment syndromes By parent at trade and service area "
    },
    {
      "name": "Y07150",
      "description": "Other maltreatment syndromes By parent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07151",
      "description": "Other maltreatment syndromes By parent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07152",
      "description": "Other maltreatment syndromes By parent at trade and service area While working for income"
    },
    {
      "name": "Y07153",
      "description": "Other maltreatment syndromes By parent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07154",
      "description": "Other maltreatment syndromes By parent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07158",
      "description": "Other maltreatment syndromes By parent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07159",
      "description": "Other maltreatment syndromes By parent at trade and service area During unspecified activity"
    },
    {
      "name": "Y0716",
      "description": "Other maltreatment syndromes By parent at industrial and construction area "
    },
    {
      "name": "Y07160",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07161",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07162",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While working for income"
    },
    {
      "name": "Y07163",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07164",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07168",
      "description": "Other maltreatment syndromes By parent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07169",
      "description": "Other maltreatment syndromes By parent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0717",
      "description": "Other maltreatment syndromes By parent at farm "
    },
    {
      "name": "Y07170",
      "description": "Other maltreatment syndromes By parent at farm While engaged in sports activity"
    },
    {
      "name": "Y07171",
      "description": "Other maltreatment syndromes By parent at farm While engaged in leisure activity"
    },
    {
      "name": "Y07172",
      "description": "Other maltreatment syndromes By parent at farm While working for income"
    },
    {
      "name": "Y07173",
      "description": "Other maltreatment syndromes By parent at farm While engaged in other types of work"
    },
    {
      "name": "Y07174",
      "description": "Other maltreatment syndromes By parent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07178",
      "description": "Other maltreatment syndromes By parent at farm While engaged in other specified activities"
    },
    {
      "name": "Y07179",
      "description": "Other maltreatment syndromes By parent at farm During unspecified activity"
    },
    {
      "name": "Y0718",
      "description": "Other maltreatment syndromes By parent at other specified places"
    },
    {
      "name": "Y07180",
      "description": "Other maltreatment syndromes By parent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07181",
      "description": "Other maltreatment syndromes By parent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07182",
      "description": "Other maltreatment syndromes By parent at other specified places While working for income"
    },
    {
      "name": "Y07183",
      "description": "Other maltreatment syndromes By parent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07184",
      "description": "Other maltreatment syndromes By parent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07188",
      "description": "Other maltreatment syndromes By parent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07189",
      "description": "Other maltreatment syndromes By parent at other specified places During unspecified activity"
    },
    {
      "name": "Y0719",
      "description": "Other maltreatment syndromes By parent at unspecified place"
    },
    {
      "name": "Y07190",
      "description": "Other maltreatment syndromes By parent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07191",
      "description": "Other maltreatment syndromes By parent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07192",
      "description": "Other maltreatment syndromes By parent at unspecified place While working for income"
    },
    {
      "name": "Y07193",
      "description": "Other maltreatment syndromes By parent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07194",
      "description": "Other maltreatment syndromes By parent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07198",
      "description": "Other maltreatment syndromes By parent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07199",
      "description": "Other maltreatment syndromes By parent at unspecified place During unspecified activity"
    },
    {
      "name": "Y072",
      "description": "Other maltreatment syndromes By acquaintance or friend"
    },
    {
      "name": "Y0720",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home"
    },
    {
      "name": "Y07200",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While engaged in sports activity"
    },
    {
      "name": "Y07201",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While engaged in leisure activity"
    },
    {
      "name": "Y07202",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While working for income"
    },
    {
      "name": "Y07203",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While engaged in other types of work"
    },
    {
      "name": "Y07204",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07208",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  While engaged in other specified activities"
    },
    {
      "name": "Y07209",
      "description": "Other maltreatment syndromes By acquaintance or friend  at home  During unspecified activity"
    },
    {
      "name": "Y0721",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution "
    },
    {
      "name": "Y07210",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07211",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07212",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While working for income"
    },
    {
      "name": "Y07213",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07214",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07218",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07219",
      "description": "Other maltreatment syndromes By acquaintance or friend  at residential institution During unspecified activity"
    },
    {
      "name": "Y0722",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area "
    },
    {
      "name": "Y07220",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07221",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07222",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07223",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07224",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07228",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07229",
      "description": "Other maltreatment syndromes By acquaintance or friend  at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0723",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area"
    },
    {
      "name": "Y07230",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07231",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07232",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While working for income"
    },
    {
      "name": "Y07233",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07234",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07238",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07239",
      "description": "Other maltreatment syndromes By acquaintance or friend  at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0724",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway"
    },
    {
      "name": "Y07240",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07241",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07242",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While working for income"
    },
    {
      "name": "Y07243",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07244",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07248",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07249",
      "description": "Other maltreatment syndromes By acquaintance or friend  at street and highway During unspecified activity"
    },
    {
      "name": "Y0725",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area "
    },
    {
      "name": "Y07250",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07251",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07252",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While working for income"
    },
    {
      "name": "Y07253",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07254",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07258",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07259",
      "description": "Other maltreatment syndromes By acquaintance or friend  at trade and service area During unspecified activity"
    },
    {
      "name": "Y0726",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area "
    },
    {
      "name": "Y07260",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07261",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07262",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While working for income"
    },
    {
      "name": "Y07263",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07264",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07268",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07269",
      "description": "Other maltreatment syndromes By acquaintance or friend  at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0727",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm "
    },
    {
      "name": "Y07270",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While engaged in sports activity"
    },
    {
      "name": "Y07271",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While engaged in leisure activity"
    },
    {
      "name": "Y07272",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While working for income"
    },
    {
      "name": "Y07273",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While engaged in other types of work"
    },
    {
      "name": "Y07274",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07278",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm While engaged in other specified activities"
    },
    {
      "name": "Y07279",
      "description": "Other maltreatment syndromes By acquaintance or friend  at farm During unspecified activity"
    },
    {
      "name": "Y0728",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places"
    },
    {
      "name": "Y07280",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07281",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07282",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While working for income"
    },
    {
      "name": "Y07283",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07284",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07288",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07289",
      "description": "Other maltreatment syndromes By acquaintance or friend  at other specified places During unspecified activity"
    },
    {
      "name": "Y0729",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place"
    },
    {
      "name": "Y07290",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07291",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07292",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While working for income"
    },
    {
      "name": "Y07293",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07294",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07298",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07299",
      "description": "Other maltreatment syndromes By acquaintance or friend  at unspecified place During unspecified activity"
    },
    {
      "name": "Y073",
      "description": "Other maltreatment syndromes By official authorities"
    },
    {
      "name": "Y0730",
      "description": "Other maltreatment syndromes By official authorities at home"
    },
    {
      "name": "Y07300",
      "description": "Other maltreatment syndromes By official authorities at home  While engaged in sports activity"
    },
    {
      "name": "Y07301",
      "description": "Other maltreatment syndromes By official authorities at home  While engaged in leisure activity"
    },
    {
      "name": "Y07302",
      "description": "Other maltreatment syndromes By official authorities at home  While working for income"
    },
    {
      "name": "Y07303",
      "description": "Other maltreatment syndromes By official authorities at home  While engaged in other types of work"
    },
    {
      "name": "Y07304",
      "description": "Other maltreatment syndromes By official authorities at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07308",
      "description": "Other maltreatment syndromes By official authorities at home  While engaged in other specified activities"
    },
    {
      "name": "Y07309",
      "description": "Other maltreatment syndromes By official authorities at home  During unspecified activity"
    },
    {
      "name": "Y0731",
      "description": "Other maltreatment syndromes By official authorities at residential institution "
    },
    {
      "name": "Y07310",
      "description": "Other maltreatment syndromes By official authorities at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07311",
      "description": "Other maltreatment syndromes By official authorities at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07312",
      "description": "Other maltreatment syndromes By official authorities at residential institution While working for income"
    },
    {
      "name": "Y07313",
      "description": "Other maltreatment syndromes By official authorities at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07314",
      "description": "Other maltreatment syndromes By official authorities at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07318",
      "description": "Other maltreatment syndromes By official authorities at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07319",
      "description": "Other maltreatment syndromes By official authorities at residential institution During unspecified activity"
    },
    {
      "name": "Y0732",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area "
    },
    {
      "name": "Y07320",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07321",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07322",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07323",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07324",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07328",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07329",
      "description": "Other maltreatment syndromes By official authorities at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0733",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area"
    },
    {
      "name": "Y07330",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07331",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07332",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While working for income"
    },
    {
      "name": "Y07333",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07334",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07338",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07339",
      "description": "Other maltreatment syndromes By official authorities at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0734",
      "description": "Other maltreatment syndromes By official authorities at street and highway"
    },
    {
      "name": "Y07340",
      "description": "Other maltreatment syndromes By official authorities at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07341",
      "description": "Other maltreatment syndromes By official authorities at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07342",
      "description": "Other maltreatment syndromes By official authorities at street and highway While working for income"
    },
    {
      "name": "Y07343",
      "description": "Other maltreatment syndromes By official authorities at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07344",
      "description": "Other maltreatment syndromes By official authorities at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07348",
      "description": "Other maltreatment syndromes By official authorities at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07349",
      "description": "Other maltreatment syndromes By official authorities at street and highway During unspecified activity"
    },
    {
      "name": "Y0735",
      "description": "Other maltreatment syndromes By official authorities at trade and service area "
    },
    {
      "name": "Y07350",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07351",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07352",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While working for income"
    },
    {
      "name": "Y07353",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07354",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07358",
      "description": "Other maltreatment syndromes By official authorities at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07359",
      "description": "Other maltreatment syndromes By official authorities at trade and service area During unspecified activity"
    },
    {
      "name": "Y0736",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area "
    },
    {
      "name": "Y07360",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07361",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07362",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While working for income"
    },
    {
      "name": "Y07363",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07364",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07368",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07369",
      "description": "Other maltreatment syndromes By official authorities at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0737",
      "description": "Other maltreatment syndromes By official authorities at farm "
    },
    {
      "name": "Y07370",
      "description": "Other maltreatment syndromes By official authorities at farm While engaged in sports activity"
    },
    {
      "name": "Y07371",
      "description": "Other maltreatment syndromes By official authorities at farm While engaged in leisure activity"
    },
    {
      "name": "Y07372",
      "description": "Other maltreatment syndromes By official authorities at farm While working for income"
    },
    {
      "name": "Y07373",
      "description": "Other maltreatment syndromes By official authorities at farm While engaged in other types of work"
    },
    {
      "name": "Y07374",
      "description": "Other maltreatment syndromes By official authorities at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07378",
      "description": "Other maltreatment syndromes By official authorities at farm While engaged in other specified activities"
    },
    {
      "name": "Y07379",
      "description": "Other maltreatment syndromes By official authorities at farm During unspecified activity"
    },
    {
      "name": "Y0738",
      "description": "Other maltreatment syndromes By official authorities at other specified places"
    },
    {
      "name": "Y07380",
      "description": "Other maltreatment syndromes By official authorities at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07381",
      "description": "Other maltreatment syndromes By official authorities at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07382",
      "description": "Other maltreatment syndromes By official authorities at other specified places While working for income"
    },
    {
      "name": "Y07383",
      "description": "Other maltreatment syndromes By official authorities at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07384",
      "description": "Other maltreatment syndromes By official authorities at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07388",
      "description": "Other maltreatment syndromes By official authorities at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07389",
      "description": "Other maltreatment syndromes By official authorities at other specified places During unspecified activity"
    },
    {
      "name": "Y0739",
      "description": "Other maltreatment syndromes By official authorities at unspecified place"
    },
    {
      "name": "Y07390",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07391",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07392",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While working for income"
    },
    {
      "name": "Y07393",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07394",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07398",
      "description": "Other maltreatment syndromes By official authorities at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07399",
      "description": "Other maltreatment syndromes By official authorities at unspecified place During unspecified activity"
    },
    {
      "name": "Y078",
      "description": "Other maltreatment syndromes By official authorities"
    },
    {
      "name": "Y0780",
      "description": "Other maltreatment syndromes By other specified persons at home"
    },
    {
      "name": "Y07800",
      "description": "Other maltreatment syndromes By other specified persons at home  While engaged in sports activity"
    },
    {
      "name": "Y07801",
      "description": "Other maltreatment syndromes By other specified persons at home  While engaged in leisure activity"
    },
    {
      "name": "Y07802",
      "description": "Other maltreatment syndromes By other specified persons at home  While working for income"
    },
    {
      "name": "Y07803",
      "description": "Other maltreatment syndromes By other specified persons at home  While engaged in other types of work"
    },
    {
      "name": "Y07804",
      "description": "Other maltreatment syndromes By other specified persons at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07808",
      "description": "Other maltreatment syndromes By other specified persons at home  While engaged in other specified activities"
    },
    {
      "name": "Y07809",
      "description": "Other maltreatment syndromes By other specified persons at home  During unspecified activity"
    },
    {
      "name": "Y0781",
      "description": "Other maltreatment syndromes By other specified persons at residential institution "
    },
    {
      "name": "Y07810",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07811",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07812",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While working for income"
    },
    {
      "name": "Y07813",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07814",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07818",
      "description": "Other maltreatment syndromes By other specified persons at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07819",
      "description": "Other maltreatment syndromes By other specified persons at residential institution During unspecified activity"
    },
    {
      "name": "Y0782",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area "
    },
    {
      "name": "Y07820",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07821",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07822",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07823",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07824",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07828",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07829",
      "description": "Other maltreatment syndromes By other specified persons at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0783",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area"
    },
    {
      "name": "Y07830",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07831",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07832",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While working for income"
    },
    {
      "name": "Y07833",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07834",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07838",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07839",
      "description": "Other maltreatment syndromes By other specified persons at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0784",
      "description": "Other maltreatment syndromes By other specified persons at street and highway"
    },
    {
      "name": "Y07840",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07841",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07842",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While working for income"
    },
    {
      "name": "Y07843",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07844",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07848",
      "description": "Other maltreatment syndromes By other specified persons at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07849",
      "description": "Other maltreatment syndromes By other specified persons at street and highway During unspecified activity"
    },
    {
      "name": "Y0785",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area "
    },
    {
      "name": "Y07850",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07851",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07852",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While working for income"
    },
    {
      "name": "Y07853",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07854",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07858",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07859",
      "description": "Other maltreatment syndromes By other specified persons at trade and service area During unspecified activity"
    },
    {
      "name": "Y0786",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area "
    },
    {
      "name": "Y07860",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07861",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07862",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While working for income"
    },
    {
      "name": "Y07863",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07864",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07868",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07869",
      "description": "Other maltreatment syndromes By other specified persons at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0787",
      "description": "Other maltreatment syndromes By other specified persons at farm "
    },
    {
      "name": "Y07870",
      "description": "Other maltreatment syndromes By other specified persons at farm While engaged in sports activity"
    },
    {
      "name": "Y07871",
      "description": "Other maltreatment syndromes By other specified persons at farm While engaged in leisure activity"
    },
    {
      "name": "Y07872",
      "description": "Other maltreatment syndromes By other specified persons at farm While working for income"
    },
    {
      "name": "Y07873",
      "description": "Other maltreatment syndromes By other specified persons at farm While engaged in other types of work"
    },
    {
      "name": "Y07874",
      "description": "Other maltreatment syndromes By other specified persons at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07878",
      "description": "Other maltreatment syndromes By other specified persons at farm While engaged in other specified activities"
    },
    {
      "name": "Y07879",
      "description": "Other maltreatment syndromes By other specified persons at farm During unspecified activity"
    },
    {
      "name": "Y0788",
      "description": "Other maltreatment syndromes By other specified persons at other specified places"
    },
    {
      "name": "Y07880",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07881",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07882",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While working for income"
    },
    {
      "name": "Y07883",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07884",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07888",
      "description": "Other maltreatment syndromes By other specified persons at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07889",
      "description": "Other maltreatment syndromes By other specified persons at other specified places During unspecified activity"
    },
    {
      "name": "Y0789",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place"
    },
    {
      "name": "Y07890",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07891",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07892",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While working for income"
    },
    {
      "name": "Y07893",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07894",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07898",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07899",
      "description": "Other maltreatment syndromes By other specified persons at unspecified place During unspecified activity"
    },
    {
      "name": "Y079",
      "description": "Other maltreatment syndromes By unspecified person"
    },
    {
      "name": "Y0790",
      "description": "Other maltreatment syndromes By unspecified person at home"
    },
    {
      "name": "Y07900",
      "description": "Other maltreatment syndromes By unspecified person at home  While engaged in sports activity"
    },
    {
      "name": "Y07901",
      "description": "Other maltreatment syndromes By unspecified person at home  While engaged in leisure activity"
    },
    {
      "name": "Y07902",
      "description": "Other maltreatment syndromes By unspecified person at home  While working for income"
    },
    {
      "name": "Y07903",
      "description": "Other maltreatment syndromes By unspecified person at home  While engaged in other types of work"
    },
    {
      "name": "Y07904",
      "description": "Other maltreatment syndromes By unspecified person at home  While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07908",
      "description": "Other maltreatment syndromes By unspecified person at home  While engaged in other specified activities"
    },
    {
      "name": "Y07909",
      "description": "Other maltreatment syndromes By unspecified person at home  During unspecified activity"
    },
    {
      "name": "Y0791",
      "description": "Other maltreatment syndromes By unspecified person at residential institution "
    },
    {
      "name": "Y07910",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While engaged in sports activity"
    },
    {
      "name": "Y07911",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y07912",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While working for income"
    },
    {
      "name": "Y07913",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While engaged in other types of work"
    },
    {
      "name": "Y07914",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07918",
      "description": "Other maltreatment syndromes By unspecified person at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y07919",
      "description": "Other maltreatment syndromes By unspecified person at residential institution During unspecified activity"
    },
    {
      "name": "Y0792",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area "
    },
    {
      "name": "Y07920",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y07921",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y07922",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While working for income"
    },
    {
      "name": "Y07923",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y07924",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07928",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y07929",
      "description": "Other maltreatment syndromes By unspecified person at school, other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y0793",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area"
    },
    {
      "name": "Y07930",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y07931",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y07932",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While working for income"
    },
    {
      "name": "Y07933",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y07934",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07938",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y07939",
      "description": "Other maltreatment syndromes By unspecified person at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y0794",
      "description": "Other maltreatment syndromes By unspecified person at street and highway"
    },
    {
      "name": "Y07940",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While engaged in sports activity"
    },
    {
      "name": "Y07941",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y07942",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While working for income"
    },
    {
      "name": "Y07943",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While engaged in other types of work"
    },
    {
      "name": "Y07944",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07948",
      "description": "Other maltreatment syndromes By unspecified person at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y07949",
      "description": "Other maltreatment syndromes By unspecified person at street and highway During unspecified activity"
    },
    {
      "name": "Y0795",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area "
    },
    {
      "name": "Y07950",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y07951",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y07952",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While working for income"
    },
    {
      "name": "Y07953",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y07954",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07958",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y07959",
      "description": "Other maltreatment syndromes By unspecified person at trade and service area During unspecified activity"
    },
    {
      "name": "Y0796",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area "
    },
    {
      "name": "Y07960",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y07961",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y07962",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While working for income"
    },
    {
      "name": "Y07963",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y07964",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07968",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y07969",
      "description": "Other maltreatment syndromes By unspecified person at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y0797",
      "description": "Other maltreatment syndromes By unspecified person at farm "
    },
    {
      "name": "Y07970",
      "description": "Other maltreatment syndromes By unspecified person at farm While engaged in sports activity"
    },
    {
      "name": "Y07971",
      "description": "Other maltreatment syndromes By unspecified person at farm While engaged in leisure activity"
    },
    {
      "name": "Y07972",
      "description": "Other maltreatment syndromes By unspecified person at farm While working for income"
    },
    {
      "name": "Y07973",
      "description": "Other maltreatment syndromes By unspecified person at farm While engaged in other types of work"
    },
    {
      "name": "Y07974",
      "description": "Other maltreatment syndromes By unspecified person at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07978",
      "description": "Other maltreatment syndromes By unspecified person at farm While engaged in other specified activities"
    },
    {
      "name": "Y07979",
      "description": "Other maltreatment syndromes By unspecified person at farm During unspecified activity"
    },
    {
      "name": "Y0798",
      "description": "Other maltreatment syndromes By unspecified person at other specified places"
    },
    {
      "name": "Y07980",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While engaged in sports activity"
    },
    {
      "name": "Y07981",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y07982",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While working for income"
    },
    {
      "name": "Y07983",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While engaged in other types of work"
    },
    {
      "name": "Y07984",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07988",
      "description": "Other maltreatment syndromes By unspecified person at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y07989",
      "description": "Other maltreatment syndromes By unspecified person at other specified places During unspecified activity"
    },
    {
      "name": "Y0799",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place"
    },
    {
      "name": "Y07990",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y07991",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y07992",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While working for income"
    },
    {
      "name": "Y07993",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y07994",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y07998",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y07999",
      "description": "Other maltreatment syndromes By unspecified person at unspecified place During unspecified activity"
    },
    {
      "name": "Y080",
      "description": "Assault by other specified means at home"
    },
    {
      "name": "Y0800",
      "description": "Assault by other specified means at home While engaged in sports activity"
    },
    {
      "name": "Y0801",
      "description": "Assault by other specified means at home While engaged in leisure activity"
    },
    {
      "name": "Y0802",
      "description": "Assault by other specified means at home While working for income"
    },
    {
      "name": "Y0803",
      "description": "Assault by other specified means at home While engaged in other types of work"
    },
    {
      "name": "Y0804",
      "description": "Assault by other specified means at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0808",
      "description": "Assault by other specified means at home While engaged in other specified activities"
    },
    {
      "name": "Y0809",
      "description": "Assault by other specified means at home During unspecified activity"
    },
    {
      "name": "Y081",
      "description": "Assault by other specified means at residential institution"
    },
    {
      "name": "Y0810",
      "description": "Assault by other specified means at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0811",
      "description": "Assault by other specified means at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0812",
      "description": "Assault by other specified means at residential institution While working for income"
    },
    {
      "name": "Y0813",
      "description": "Assault by other specified means at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0814",
      "description": "Assault by other specified means at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0818",
      "description": "Assault by other specified means at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0819",
      "description": "Assault by other specified means at residential institution During unspecified activity"
    },
    {
      "name": "Y082",
      "description": "Assault by other specified means at school,other institution and public administrative area"
    },
    {
      "name": "Y0820",
      "description": "Assault by other specified means at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0821",
      "description": "Assault by other specified means at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0822",
      "description": "Assault by other specified means at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0823",
      "description": "Assault by other specified means at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0824",
      "description": "Assault by other specified means at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0828",
      "description": "Assault by other specified means at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0829",
      "description": "Assault by other specified means at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y083",
      "description": "Assault by other specified means at sports and athletics area"
    },
    {
      "name": "Y0830",
      "description": "Assault by other specified means at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0831",
      "description": "Assault by other specified means at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0832",
      "description": "Assault by other specified means at sports and athletics area While working for income"
    },
    {
      "name": "Y0833",
      "description": "Assault by other specified means at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0834",
      "description": "Assault by other specified means at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0838",
      "description": "Assault by other specified means at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0839",
      "description": "Assault by other specified means at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y084",
      "description": "Assault by other specified means at street and highway"
    },
    {
      "name": "Y0840",
      "description": "Assault by other specified means at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0841",
      "description": "Assault by other specified means at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0842",
      "description": "Assault by other specified means at street and highway While working for income"
    },
    {
      "name": "Y0843",
      "description": "Assault by other specified means at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0844",
      "description": "Assault by other specified means at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0848",
      "description": "Assault by other specified means at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0849",
      "description": "Assault by other specified means at street and highway During unspecified activity"
    },
    {
      "name": "Y085",
      "description": "Assault by other specified means at trade and service area"
    },
    {
      "name": "Y0850",
      "description": "Assault by other specified means at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0851",
      "description": "Assault by other specified means at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0852",
      "description": "Assault by other specified means at trade and service area While working for income"
    },
    {
      "name": "Y0853",
      "description": "Assault by other specified means at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0854",
      "description": "Assault by other specified means at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0858",
      "description": "Assault by other specified means at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0859",
      "description": "Assault by other specified means at trade and service area During unspecified activity"
    },
    {
      "name": "Y086",
      "description": "Assault by other specified means at industrial and construction area"
    },
    {
      "name": "Y0860",
      "description": "Assault by other specified means at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0861",
      "description": "Assault by other specified means at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0862",
      "description": "Assault by other specified means at industrial and construction area While working for income"
    },
    {
      "name": "Y0863",
      "description": "Assault by other specified means at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0864",
      "description": "Assault by other specified means at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0868",
      "description": "Assault by other specified means at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0869",
      "description": "Assault by other specified means at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y087",
      "description": "Assault by other specified means at farm"
    },
    {
      "name": "Y0870",
      "description": "Assault by other specified means at farm While engaged in sports activity"
    },
    {
      "name": "Y0871",
      "description": "Assault by other specified means at farm While engaged in leisure activity"
    },
    {
      "name": "Y0872",
      "description": "Assault by other specified means at farm While working for income"
    },
    {
      "name": "Y0873",
      "description": "Assault by other specified means at farm While engaged in other types of work"
    },
    {
      "name": "Y0874",
      "description": "Assault by other specified means at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0878",
      "description": "Assault by other specified means at farm While engaged in other specified activities"
    },
    {
      "name": "Y0879",
      "description": "Assault by other specified means at farm During unspecified activity"
    },
    {
      "name": "Y088",
      "description": "Assault by other specified means at other specified places"
    },
    {
      "name": "Y0880",
      "description": "Assault by other specified means at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0881",
      "description": "Assault by other specified means at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0882",
      "description": "Assault by other specified means at other specified places While working for income"
    },
    {
      "name": "Y0883",
      "description": "Assault by other specified means at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0884",
      "description": "Assault by other specified means at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0888",
      "description": "Assault by other specified means at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0889",
      "description": "Assault by other specified means at other specified places During unspecified activity"
    },
    {
      "name": "Y089",
      "description": "Assault by other specified means at unspecified place"
    },
    {
      "name": "Y0890",
      "description": "Assault by other specified means at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0891",
      "description": "Assault by other specified means at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0892",
      "description": "Assault by other specified means at unspecified place While working for income"
    },
    {
      "name": "Y0893",
      "description": "Assault by other specified means at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0894",
      "description": "Assault by other specified means at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0898",
      "description": "Assault by other specified means at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0899",
      "description": "Assault by other specified means at unspecified place During unspecified activity"
    },
    {
      "name": "Y090",
      "description": "Assault by unspecified means at home"
    },
    {
      "name": "Y0900",
      "description": "Assault by unspecified means at home While engaged in sports activity"
    },
    {
      "name": "Y0901",
      "description": "Assault by unspecified means at home While engaged in leisure activity"
    },
    {
      "name": "Y0902",
      "description": "Assault by unspecified means at home While working for income"
    },
    {
      "name": "Y0903",
      "description": "Assault by unspecified means at home While engaged in other types of work"
    },
    {
      "name": "Y0904",
      "description": "Assault by unspecified means at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0908",
      "description": "Assault by unspecified means at home While engaged in other specified activities"
    },
    {
      "name": "Y0909",
      "description": "Assault by unspecified means at home During unspecified activity"
    },
    {
      "name": "Y091",
      "description": "Assault by unspecified means at residential institution"
    },
    {
      "name": "Y0910",
      "description": "Assault by unspecified means at residential institution While engaged in sports activity"
    },
    {
      "name": "Y0911",
      "description": "Assault by unspecified means at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y0912",
      "description": "Assault by unspecified means at residential institution While working for income"
    },
    {
      "name": "Y0913",
      "description": "Assault by unspecified means at residential institution While engaged in other types of work"
    },
    {
      "name": "Y0914",
      "description": "Assault by unspecified means at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0918",
      "description": "Assault by unspecified means at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y0919",
      "description": "Assault by unspecified means at residential institution During unspecified activity"
    },
    {
      "name": "Y092",
      "description": "Assault by unspecified means at school,other institution and public administrative area"
    },
    {
      "name": "Y0920",
      "description": "Assault by unspecified means at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y0921",
      "description": "Assault by unspecified means at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y0922",
      "description": "Assault by unspecified means at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y0923",
      "description": "Assault by unspecified means at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y0924",
      "description": "Assault by unspecified means at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0928",
      "description": "Assault by unspecified means at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y0929",
      "description": "Assault by unspecified means at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y093",
      "description": "Assault by unspecified means at sports and athletics area"
    },
    {
      "name": "Y0930",
      "description": "Assault by unspecified means at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y0931",
      "description": "Assault by unspecified means at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y0932",
      "description": "Assault by unspecified means at sports and athletics area While working for income"
    },
    {
      "name": "Y0933",
      "description": "Assault by unspecified means at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y0934",
      "description": "Assault by unspecified means at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0938",
      "description": "Assault by unspecified means at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y0939",
      "description": "Assault by unspecified means at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y094",
      "description": "Assault by unspecified means at street and highway"
    },
    {
      "name": "Y0940",
      "description": "Assault by unspecified means at street and highway While engaged in sports activity"
    },
    {
      "name": "Y0941",
      "description": "Assault by unspecified means at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y0942",
      "description": "Assault by unspecified means at street and highway While working for income"
    },
    {
      "name": "Y0943",
      "description": "Assault by unspecified means at street and highway While engaged in other types of work"
    },
    {
      "name": "Y0944",
      "description": "Assault by unspecified means at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0948",
      "description": "Assault by unspecified means at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y0949",
      "description": "Assault by unspecified means at street and highway During unspecified activity"
    },
    {
      "name": "Y095",
      "description": "Assault by unspecified means at trade and service area"
    },
    {
      "name": "Y0950",
      "description": "Assault by unspecified means at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y0951",
      "description": "Assault by unspecified means at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y0952",
      "description": "Assault by unspecified means at trade and service area While working for income"
    },
    {
      "name": "Y0953",
      "description": "Assault by unspecified means at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y0954",
      "description": "Assault by unspecified means at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0958",
      "description": "Assault by unspecified means at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y0959",
      "description": "Assault by unspecified means at trade and service area During unspecified activity"
    },
    {
      "name": "Y096",
      "description": "Assault by unspecified means at industrial and construction area"
    },
    {
      "name": "Y0960",
      "description": "Assault by unspecified means at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y0961",
      "description": "Assault by unspecified means at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y0962",
      "description": "Assault by unspecified means at industrial and construction area While working for income"
    },
    {
      "name": "Y0963",
      "description": "Assault by unspecified means at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y0964",
      "description": "Assault by unspecified means at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0968",
      "description": "Assault by unspecified means at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y0969",
      "description": "Assault by unspecified means at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y097",
      "description": "Assault by unspecified means at farm"
    },
    {
      "name": "Y0970",
      "description": "Assault by unspecified means at farm While engaged in sports activity"
    },
    {
      "name": "Y0971",
      "description": "Assault by unspecified means at farm While engaged in leisure activity"
    },
    {
      "name": "Y0972",
      "description": "Assault by unspecified means at farm While working for income"
    },
    {
      "name": "Y0973",
      "description": "Assault by unspecified means at farm While engaged in other types of work"
    },
    {
      "name": "Y0974",
      "description": "Assault by unspecified means at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0978",
      "description": "Assault by unspecified means at farm While engaged in other specified activities"
    },
    {
      "name": "Y0979",
      "description": "Assault by unspecified means at farm During unspecified activity"
    },
    {
      "name": "Y098",
      "description": "Assault by unspecified means at other specified places"
    },
    {
      "name": "Y0980",
      "description": "Assault by unspecified means at other specified places While engaged in sports activity"
    },
    {
      "name": "Y0981",
      "description": "Assault by unspecified means at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y0982",
      "description": "Assault by unspecified means at other specified places While working for income"
    },
    {
      "name": "Y0983",
      "description": "Assault by unspecified means at other specified places While engaged in other types of work"
    },
    {
      "name": "Y0984",
      "description": "Assault by unspecified means at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0988",
      "description": "Assault by unspecified means at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y0989",
      "description": "Assault by unspecified means at other specified places During unspecified activity"
    },
    {
      "name": "Y099",
      "description": "Assault by unspecified means at unspecified place"
    },
    {
      "name": "Y0990",
      "description": "Assault by unspecified means at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y0991",
      "description": "Assault by unspecified means at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y0992",
      "description": "Assault by unspecified means at unspecified place While working for income"
    },
    {
      "name": "Y0993",
      "description": "Assault by unspecified means at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y0994",
      "description": "Assault by unspecified means at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y0998",
      "description": "Assault by unspecified means at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y0999",
      "description": "Assault by unspecified means at unspecified place During unspecified activity"
    },
    {
      "name": "Y100",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home"
    },
    {
      "name": "Y1000",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1001",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1002",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While working for income"
    },
    {
      "name": "Y1003",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1004",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1008",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1009",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y101",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution"
    },
    {
      "name": "Y1010",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1011",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1012",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1013",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1014",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1018",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1019",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y102",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1020",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1021",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1022",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1023",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1024",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1028",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1029",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y103",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1030",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1031",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1032",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1033",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1034",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1038",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1039",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y104",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway"
    },
    {
      "name": "Y1040",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1041",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1042",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1043",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1044",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1048",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1049",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y105",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area"
    },
    {
      "name": "Y1050",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1051",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1052",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1053",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1054",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1058",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1059",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y106",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1060",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1061",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1062",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1063",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1064",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1068",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1069",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y107",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm"
    },
    {
      "name": "Y1070",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1071",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1072",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1073",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1074",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1078",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1079",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y108",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places"
    },
    {
      "name": "Y1080",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1081",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1082",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1083",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1084",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1088",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1089",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y109",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place"
    },
    {
      "name": "Y1090",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1091",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1092",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1093",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1094",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1098",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1099",
      "description": "Poisoning by and exposure to nonopioid analgesics, antipyretics and antirteumatics, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y110",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home"
    },
    {
      "name": "Y1100",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1101",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1102",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While working for income"
    },
    {
      "name": "Y1103",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1104",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1108",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1109",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y111",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution"
    },
    {
      "name": "Y1110",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1111",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1112",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1113",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1114",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1118",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1119",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y112",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1120",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1121",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1122",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1123",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1124",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in o"
    },
    {
      "name": "Y1128",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1129",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y113",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1130",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1131",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1132",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1133",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1134",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1138",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1139",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y114",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway"
    },
    {
      "name": "Y1140",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1141",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1142",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1143",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1144",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1148",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1149",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y115",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area"
    },
    {
      "name": "Y1150",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1151",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1152",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1153",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1154",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1158",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1159",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y116",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1160",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1161",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1162",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1163",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1164",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1168",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1169",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y117",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm"
    },
    {
      "name": "Y1170",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1171",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1172",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1173",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1174",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1178",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1179",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y118",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places"
    },
    {
      "name": "Y1180",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1181",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1182",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1183",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1184",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1188",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1189",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y119",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place"
    },
    {
      "name": "Y1190",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1191",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1192",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1193",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1194",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1198",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1199",
      "description": "Poisoning by and exposure to antiepileptic, sedative-hypnotic,antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y120",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home"
    },
    {
      "name": "Y1200",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1201",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1202",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While working for income"
    },
    {
      "name": "Y1203",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1204",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1208",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1209",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y121",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution"
    },
    {
      "name": "Y1210",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1211",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1212",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1213",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1214",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1218",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1219",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y122",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1220",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1221",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1222",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1223",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1224",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1228",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1229",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y123",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1230",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1231",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1232",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1233",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1234",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1238",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1239",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y124",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway"
    },
    {
      "name": "Y1240",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1241",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1242",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1243",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1244",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1248",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1249",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y125",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area"
    },
    {
      "name": "Y1250",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1251",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1252",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1253",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1254",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1258",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1259",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y126",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1260",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1261",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1262",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1263",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1264",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1268",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1269",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y127",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm"
    },
    {
      "name": "Y1270",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1271",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1272",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1273",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1274",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1278",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1279",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y128",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places"
    },
    {
      "name": "Y1280",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1281",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1282",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1283",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1284",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1288",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1289",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y129",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place"
    },
    {
      "name": "Y1290",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1291",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1292",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1293",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1294",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1298",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1299",
      "description": "Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y130",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home"
    },
    {
      "name": "Y1300",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1301",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1302",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While working for income"
    },
    {
      "name": "Y1303",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1304",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1308",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1309",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y131",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution"
    },
    {
      "name": "Y1310",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution  While engaged in sports activity"
    },
    {
      "name": "Y1311",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1312",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1313",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1314",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1318",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1319",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y132",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1320",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1321",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1322",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1323",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area  While engaged in other types of work"
    },
    {
      "name": "Y1324",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1328",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1329",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y133",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1330",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1331",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1332",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1333",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1334",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1338",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1339",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y134",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway"
    },
    {
      "name": "Y1340",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1341",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1342",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1343",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1344",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1348",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1349",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y135",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area"
    },
    {
      "name": "Y1350",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1351",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1352",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1353",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1354",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1358",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1359",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y136",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1360",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1361",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1362",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1363",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1364",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1368",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1369",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y137",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm"
    },
    {
      "name": "Y1370",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1371",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1372",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1373",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1374",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1378",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1379",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y138",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places"
    },
    {
      "name": "Y1380",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1381",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1382",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1383",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1384",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1388",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1389",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y139",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place"
    },
    {
      "name": "Y1390",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1391",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1392",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1393",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1394",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1398",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1399",
      "description": "Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y140",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home"
    },
    {
      "name": "Y1400",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1401",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1402",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While working for income"
    },
    {
      "name": "Y1403",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1404",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1408",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1409",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y141",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution"
    },
    {
      "name": "Y1410",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1411",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1412",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1413",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1414",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1418",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1419",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y142",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1420",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1421",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1422",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1423",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1424",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1428",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1429",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y143",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1430",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1431",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1432",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1433",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1434",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1438",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1439",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y144",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway"
    },
    {
      "name": "Y1440",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1441",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1442",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1443",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1444",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1448",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1449",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y145",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area"
    },
    {
      "name": "Y1450",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1451",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1452",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1453",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1454",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1458",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1459",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y146",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1460",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1461",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1462",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1463",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1464",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1468",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1469",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y147",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm"
    },
    {
      "name": "Y1470",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1471",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1472",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1473",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While engaged in other types of work "
    },
    {
      "name": "Y1474",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1478",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1479",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y148",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places"
    },
    {
      "name": "Y1480",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1481",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1482",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1483",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1484",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1488",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1489",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y149",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place"
    },
    {
      "name": "Y1490",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1491",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1492",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1493",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1494",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1498",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1499",
      "description": "Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y150",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home"
    },
    {
      "name": "Y1500",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1501",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1502",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While working for income"
    },
    {
      "name": "Y1503",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1504",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1508",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1509",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y151",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution"
    },
    {
      "name": "Y1510",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1511",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1512",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1513",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1514",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1518",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1519",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y152",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1520",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1521",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1522",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1523",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1524",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1528",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1529",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y153",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1530",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1531",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1532",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1533",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1534",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1538",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1539",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y154",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway"
    },
    {
      "name": "Y1540",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1541",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1542",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1543",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1544",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1548",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1549",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y155",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area"
    },
    {
      "name": "Y1550",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1551",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1552",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1553",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1554",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1558",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area  While engaged in other specified activities"
    },
    {
      "name": "Y1559",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y156",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1560",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1561",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1562",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1563",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1564",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1568",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1569",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y157",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm"
    },
    {
      "name": "Y1570",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1571",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1572",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1573",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1574",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1578",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1579",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y158",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places"
    },
    {
      "name": "Y1580",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1581",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1582",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1583",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1584",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1588",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1589",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y159",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place"
    },
    {
      "name": "Y1590",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1591",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1592",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1593",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1594",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1598",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1599",
      "description": "Poisoning by and exposure to alcohol, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y160",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home"
    },
    {
      "name": "Y1600",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1601",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1602",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While working for income"
    },
    {
      "name": "Y1603",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1604",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1608",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1609",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y161",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution"
    },
    {
      "name": "Y1610",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1611",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1612",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1613",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1614",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1618",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1619",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y162",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1620",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1621",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1622",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1623",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1624",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1628",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1629",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y163",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1630",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1631",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1632",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1633",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1634",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1638",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1639",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y164",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway"
    },
    {
      "name": "Y1640",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1641",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1642",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1643",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1644",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1648",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1649",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y165",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area"
    },
    {
      "name": "Y1650",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1651",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1652",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1653",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1654",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1658",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1659",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y166",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1660",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1661",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1662",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1663",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1664",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1668",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1669",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y167",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm"
    },
    {
      "name": "Y1670",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1671",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1672",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1673",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1674",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1678",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1679",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y168",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places"
    },
    {
      "name": "Y1680",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1681",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1682",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1683",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1684",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1688",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1689",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y169",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place"
    },
    {
      "name": "Y1690",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1691",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1692",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1693",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1694",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1698",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1699",
      "description": "Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y170",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home"
    },
    {
      "name": "Y1700",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1701",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1702",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While working for income"
    },
    {
      "name": "Y1703",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1704",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1708",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1709",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y171",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution"
    },
    {
      "name": "Y1710",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1711",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1712",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1713",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1714",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1718",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1719",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y172",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1720",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1721",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1722",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1723",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1724",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1728",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1729",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y173",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1730",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1731",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1732",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1733",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1734",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1738",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1739",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y174",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway"
    },
    {
      "name": "Y1740",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1741",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1742",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1743",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1744",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1748",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1749",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y175",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area"
    },
    {
      "name": "Y1750",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1751",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1752",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1753",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1754",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1758",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1759",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y176",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1760",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1761",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1762",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1763",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1764",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1768",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1769",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y177",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm"
    },
    {
      "name": "Y1770",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1771",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1772",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1773",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1774",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1778",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1779",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y178",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places"
    },
    {
      "name": "Y1780",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1781",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1782",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1783",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1784",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1788",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1789",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y179",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place"
    },
    {
      "name": "Y1790",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1791",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1792",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1793",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1794",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1798",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1799",
      "description": "Poisoning by and exposure to other gases and vapours, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y180",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home"
    },
    {
      "name": "Y1800",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1801",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1802",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While working for income"
    },
    {
      "name": "Y1803",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1804",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1808",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1809",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y181",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution"
    },
    {
      "name": "Y1810",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1811",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1812",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1813",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1814",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1818",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1819",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y182",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1820",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1821",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1822",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1823",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1824",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1828",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1829",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y183",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1830",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1831",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1832",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1833",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1834",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1838",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1839",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y184",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway"
    },
    {
      "name": "Y1840",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1841",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1842",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1843",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1844",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1848",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1849",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y185",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area"
    },
    {
      "name": "Y1850",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1851",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1852",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1853",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1854",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1858",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1859",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y186",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1860",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1861",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1862",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1863",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1864",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1868",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1869",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y187",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm"
    },
    {
      "name": "Y1870",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1871",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1872",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1873",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1874",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1878",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1879",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y188",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places"
    },
    {
      "name": "Y1880",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1881",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1882",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1883",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1884",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1888",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1889",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y189",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place"
    },
    {
      "name": "Y1890",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1891",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1892",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1893",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1894",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1898",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1899",
      "description": "Poisoning by and exposure to pesticides, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y190",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home"
    },
    {
      "name": "Y1900",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y1901",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y1902",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While working for income"
    },
    {
      "name": "Y1903",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y1904",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1908",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y1909",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y191",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution"
    },
    {
      "name": "Y1910",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y1911",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y1912",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y1913",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y1914",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1918",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y1919",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y192",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y1920",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y1921",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y1922",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y1923",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y1924",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1928",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y1929",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y193",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y1930",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y1931",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y1932",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y1933",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y1934",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1938",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y1939",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y194",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway"
    },
    {
      "name": "Y1940",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y1941",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y1942",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y1943",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y1944",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1948",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y1949",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y195",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area"
    },
    {
      "name": "Y1950",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y1951",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y1952",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y1953",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y1954",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1958",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y1959",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y196",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y1960",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y1961",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y1962",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y1963",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y1964",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1968",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y1969",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y197",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm"
    },
    {
      "name": "Y1970",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y1971",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y1972",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While working for income"
    },
    {
      "name": "Y1973",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y1974",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1978",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y1979",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y198",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places"
    },
    {
      "name": "Y1980",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y1981",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y1982",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y1983",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y1984",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1988",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y1989",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y199",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place"
    },
    {
      "name": "Y1990",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y1991",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y1992",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y1993",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y1994",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y1998",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y1999",
      "description": "Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y200",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home"
    },
    {
      "name": "Y2000",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2001",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2002",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While working for income"
    },
    {
      "name": "Y2003",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2004",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2008",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2009",
      "description": "Hanging, strangulation and suffocation, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y201",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution"
    },
    {
      "name": "Y2010",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2011",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2012",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2013",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2014",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2018",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2019",
      "description": "Hanging, strangulation and suffocation, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y202",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2020",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2021",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2022",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2023",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2024",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2028",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2029",
      "description": "Hanging, strangulation and suffocation, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y203",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2030",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2031",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2032",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2033",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2034",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2038",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2039",
      "description": "Hanging, strangulation and suffocation, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y204",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway"
    },
    {
      "name": "Y2040",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2041",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2042",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2043",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2044",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2048",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2049",
      "description": "Hanging, strangulation and suffocation, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y205",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area"
    },
    {
      "name": "Y2050",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2051",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2052",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2053",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2054",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2058",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2059",
      "description": "Hanging, strangulation and suffocation, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y206",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2060",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2061",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2062",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2063",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2064",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2068",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2069",
      "description": "Hanging, strangulation and suffocation, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y207",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm"
    },
    {
      "name": "Y2070",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2071",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2072",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2073",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2074",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2078",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2079",
      "description": "Hanging, strangulation and suffocation, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y208",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places"
    },
    {
      "name": "Y2080",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2081",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2082",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2083",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2084",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2088",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2089",
      "description": "Hanging, strangulation and suffocation, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y209",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place"
    },
    {
      "name": "Y2090",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2091",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2092",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2093",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2094",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2098",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2099",
      "description": "Hanging, strangulation and suffocation, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y210",
      "description": "Drowning and submersion, undetermined intent at home"
    },
    {
      "name": "Y2100",
      "description": "Drowning and submersion, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2101",
      "description": "Drowning and submersion, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2102",
      "description": "Drowning and submersion, undetermined intent at home While working for income"
    },
    {
      "name": "Y2103",
      "description": "Drowning and submersion, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2104",
      "description": "Drowning and submersion, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2108",
      "description": "Drowning and submersion, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2109",
      "description": "Drowning and submersion, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y211",
      "description": "Drowning and submersion, undetermined intent at residential institution"
    },
    {
      "name": "Y2110",
      "description": "Drowning and submersion, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2111",
      "description": "Drowning and submersion, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2112",
      "description": "Drowning and submersion, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2113",
      "description": "Drowning and submersion, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2114",
      "description": "Drowning and submersion, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2118",
      "description": "Drowning and submersion, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2119",
      "description": "Drowning and submersion, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y212",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2120",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2121",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2122",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2123",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2124",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2128",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2129",
      "description": "Drowning and submersion, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y213",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2130",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2131",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2132",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2133",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2134",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2138",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2139",
      "description": "Drowning and submersion, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y214",
      "description": "Drowning and submersion, undetermined intent at street and highway"
    },
    {
      "name": "Y2140",
      "description": "Drowning and submersion, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2141",
      "description": "Drowning and submersion, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2142",
      "description": "Drowning and submersion, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2143",
      "description": "Drowning and submersion, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2144",
      "description": "Drowning and submersion, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2148",
      "description": "Drowning and submersion, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2149",
      "description": "Drowning and submersion, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y215",
      "description": "Drowning and submersion, undetermined intent at trade and service area"
    },
    {
      "name": "Y2150",
      "description": "Drowning and submersion, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2151",
      "description": "Drowning and submersion, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2152",
      "description": "Drownitng and submersion, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2153",
      "description": "Drownitng and submersion, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2154",
      "description": "Drownitng and submersion, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2158",
      "description": "Drownitng and submersion, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2159",
      "description": "Drownitng and submersion, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y216",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2160",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2161",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2162",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2163",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2164",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2168",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2169",
      "description": "Drownitng and submersion, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y217",
      "description": "Drownitng and submersion, undetermined intent at farm"
    },
    {
      "name": "Y2170",
      "description": "Drownitng and submersion, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2171",
      "description": "Drownitng and submersion, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2172",
      "description": "Drownitng and submersion, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2173",
      "description": "Drownitng and submersion, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2174",
      "description": "Drownitng and submersion, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2178",
      "description": "Drownitng and submersion, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2179",
      "description": "Drownitng and submersion, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y218",
      "description": "Drownitng and submersion, undetermined intent at other specified places"
    },
    {
      "name": "Y2180",
      "description": "Drownitng and submersion, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2181",
      "description": "Drownitng and submersion, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2182",
      "description": "Drownitng and submersion, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2183",
      "description": "Drownitng and submersion, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2184",
      "description": "Drownitng and submersion, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2188",
      "description": "Drownitng and submersion, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2189",
      "description": "Drownitng and submersion, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y219",
      "description": "Drownitng and submersion, undetermined intent at unspecified place"
    },
    {
      "name": "Y2190",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2191",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2192",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2193",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2194",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2198",
      "description": "Drownitng and submersion, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2199",
      "description": "Drownitng and submersion, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y220",
      "description": "Handgun discharge, undetermined intent at home"
    },
    {
      "name": "Y2200",
      "description": "Handgun discharge, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2201",
      "description": "Handgun discharge, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2202",
      "description": "Handgun discharge, undetermined intent at home While working for income"
    },
    {
      "name": "Y2203",
      "description": "Handgun discharge, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2204",
      "description": "Handgun discharge, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2208",
      "description": "Handgun discharge, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2209",
      "description": "Handgun discharge, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y221",
      "description": "Handgun discharge, undetermined intent at residential institution"
    },
    {
      "name": "Y2210",
      "description": "Handgun discharge, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2211",
      "description": "Handgun discharge, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2212",
      "description": "Handgun discharge, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2213",
      "description": "Handgun discharge, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2214",
      "description": "Handgun discharge, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2218",
      "description": "Handgun discharge, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2219",
      "description": "Handgun discharge, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y222",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2220",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2221",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2222",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2223",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2224",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2228",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2229",
      "description": "Handgun discharge, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y223",
      "description": "Handgun discharge, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2230",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2231",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2232",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2233",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2234",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2238",
      "description": "Handgun discharge, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2239",
      "description": "Handgun discharge, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y224",
      "description": "Handgun discharge, undetermined intent at street and highway"
    },
    {
      "name": "Y2240",
      "description": "Handgun discharge, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2241",
      "description": "Handgun discharge, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2242",
      "description": "Handgun discharge, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2243",
      "description": "Handgun discharge, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2244",
      "description": "Handgun discharge, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2248",
      "description": "Handgun discharge, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2249",
      "description": "Handgun discharge, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y225",
      "description": "Handgun discharge, undetermined intent at trade and service area"
    },
    {
      "name": "Y2250",
      "description": "Handgun discharge, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2251",
      "description": "Handgun discharge, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2252",
      "description": "Handgun discharge, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2253",
      "description": "Handgun discharge, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2254",
      "description": "Handgun discharge, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2258",
      "description": "Handgun discharge, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2259",
      "description": "Handgun discharge, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y226",
      "description": "Handgun discharge, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2260",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2261",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2262",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2263",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2264",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2268",
      "description": "Handgun discharge, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2269",
      "description": "Handgun discharge, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y227",
      "description": "Handgun discharge, undetermined intent at farm"
    },
    {
      "name": "Y2270",
      "description": "Handgun discharge, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2271",
      "description": "Handgun discharge, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2272",
      "description": "Handgun discharge, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2273",
      "description": "Handgun discharge, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2274",
      "description": "Handgun discharge, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2278",
      "description": "Handgun discharge, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2279",
      "description": "Handgun discharge, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y228",
      "description": "Handgun discharge, undetermined intent at other specified places"
    },
    {
      "name": "Y2280",
      "description": "Handgun discharge, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2281",
      "description": "Handgun discharge, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2282",
      "description": "Handgun discharge, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2283",
      "description": "Handgun discharge, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2284",
      "description": "Handgun discharge, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2288",
      "description": "Handgun discharge, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2289",
      "description": "Handgun discharge, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y229",
      "description": "Handgun discharge, undetermined intent at unspecified place"
    },
    {
      "name": "Y2290",
      "description": "Handgun discharge, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2291",
      "description": "Handgun discharge, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2292",
      "description": "Handgun discharge, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2293",
      "description": "Handgun discharge, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2294",
      "description": "Handgun discharge, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2298",
      "description": "Handgun discharge, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2299",
      "description": "Handgun discharge, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y230",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home"
    },
    {
      "name": "Y2300",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2301",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2302",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While working for income"
    },
    {
      "name": "Y2303",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2304",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2308",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2309",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y231",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution"
    },
    {
      "name": "Y2310",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2311",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2312",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2313",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2314",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2318",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2319",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y232",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2320",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2321",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2322",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2323",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2324",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2328",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2329",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y233",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2330",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2331",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2332",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2333",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2334",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2338",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2339",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y234",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway"
    },
    {
      "name": "Y2340",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2341",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2342",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2343",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2344",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2348",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2349",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y235",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area"
    },
    {
      "name": "Y2350",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2351",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2352",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2353",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2354",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2358",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2359",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y236",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2360",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2361",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2362",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2363",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2364",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2368",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2369",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y237",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm"
    },
    {
      "name": "Y2370",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2371",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2372",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2373",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2374",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2378",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2379",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y238",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places"
    },
    {
      "name": "Y2380",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2381",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2382",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2383",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2384",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2388",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2389",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y239",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place"
    },
    {
      "name": "Y2390",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2391",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2392",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2393",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2394",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2398",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2399",
      "description": "Rifle, shotgun and larger firearm discharge, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y240",
      "description": "Other and unspecified firearm discharge, undetermined intent at home"
    },
    {
      "name": "Y2400",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2401",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2402",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While working for income"
    },
    {
      "name": "Y2403",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2404",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2408",
      "description": "Other and unspecified firearm discharge, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2409",
      "description": "Other and unspecified firearm discharge, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y241",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution"
    },
    {
      "name": "Y2410",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2411",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2412",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2413",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2414",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2418",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2419",
      "description": "Other and unspecified firearm discharge, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y242",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2420",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2421",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2422",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2423",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2424",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2428",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2429",
      "description": "Other and unspecified firearm discharge, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y243",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2430",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2431",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2432",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2433",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2434",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2438",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2439",
      "description": "Other and unspecified firearm discharge, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y244",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway"
    },
    {
      "name": "Y2440",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2441",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2442",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2443",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2444",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2448",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2449",
      "description": "Other and unspecified firearm discharge, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y245",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area"
    },
    {
      "name": "Y2450",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2451",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2452",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2453",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2454",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2458",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2459",
      "description": "Other and unspecified firearm discharge, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y246",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2460",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2461",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2462",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2463",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2464",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2468",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2469",
      "description": "Other and unspecified firearm discharge, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y247",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm"
    },
    {
      "name": "Y2470",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2471",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2472",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2473",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2474",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2478",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2479",
      "description": "Other and unspecified firearm discharge, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y248",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places"
    },
    {
      "name": "Y2480",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2481",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2482",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2483",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2484",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2488",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2489",
      "description": "Other and unspecified firearm discharge, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y249",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place"
    },
    {
      "name": "Y2490",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2491",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2492",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2493",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2494",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2498",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2499",
      "description": "Other and unspecified firearm discharge, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y250",
      "description": "Contact with explosive material, undetermined intent at home"
    },
    {
      "name": "Y2500",
      "description": "Contact with explosive material, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2501",
      "description": "Contact with explosive material, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2502",
      "description": "Contact with explosive material, undetermined intent at home While working for income"
    },
    {
      "name": "Y2503",
      "description": "Contact with explosive material, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2504",
      "description": "Contact with explosive material, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2508",
      "description": "Contact with explosive material, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2509",
      "description": "Contact with explosive material, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y251",
      "description": "Contact with explosive material, undetermined intent at residential institution"
    },
    {
      "name": "Y2510",
      "description": "Contact with explosive material, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2511",
      "description": "Contact with explosive material, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2512",
      "description": "Contact with explosive material, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2513",
      "description": "Contact with explosive material, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2514",
      "description": "Contact with explosive material, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2518",
      "description": "Contact with explosive material, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2519",
      "description": "Contact with explosive material, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y252",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2520",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2521",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2522",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2523",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2524",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2528",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2529",
      "description": "Contact with explosive material, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y253",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2530",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2531",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2532",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2533",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2534",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2538",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2539",
      "description": "Contact with explosive material, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y254",
      "description": "Contact with explosive material, undetermined intent at street and highway"
    },
    {
      "name": "Y2540",
      "description": "Contact with explosive material, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2541",
      "description": "Contact with explosive material, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2542",
      "description": "Contact with explosive material, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2543",
      "description": "Contact with explosive material, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2544",
      "description": "Contact with explosive material, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2548",
      "description": "Contact with explosive material, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2549",
      "description": "Contact with explosive material, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y255",
      "description": "Contact with explosive material, undetermined intent at trade and service area"
    },
    {
      "name": "Y2550",
      "description": "Contact with explosive material, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2551",
      "description": "Contact with explosive material, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2552",
      "description": "Contact with explosive material, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2553",
      "description": "Contact with explosive material, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2554",
      "description": "Contact with explosive material, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2558",
      "description": "Contact with explosive material, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2559",
      "description": "Contact with explosive material, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y256",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2560",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2561",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2562",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2563",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2564",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2568",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2569",
      "description": "Contact with explosive material, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y257",
      "description": "Contact with explosive material, undetermined intent at farm"
    },
    {
      "name": "Y2570",
      "description": "Contact with explosive material, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2571",
      "description": "Contact with explosive material, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2572",
      "description": "Contact with explosive material, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2573",
      "description": "Contact with explosive material, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2574",
      "description": "Contact with explosive material, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2578",
      "description": "Contact with explosive material, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2579",
      "description": "Contact with explosive material, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y258",
      "description": "Contact with explosive material, undetermined intent at other specified places"
    },
    {
      "name": "Y2580",
      "description": "Contact with explosive material, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2581",
      "description": "Contact with explosive material, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2582",
      "description": "Contact with explosive material, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2583",
      "description": "Contact with explosive material, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2584",
      "description": "Contact with explosive material, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2588",
      "description": "Contact with explosive material, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2589",
      "description": "Contact with explosive material, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y259",
      "description": "Contact with explosive material, undetermined intent at unspecified place"
    },
    {
      "name": "Y2590",
      "description": "Contact with explosive material, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2591",
      "description": "Contact with explosive material, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2592",
      "description": "Contact with explosive material, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2593",
      "description": "Contact with explosive material, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2594",
      "description": "Contact with explosive material, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2598",
      "description": "Contact with explosive material, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2599",
      "description": "Contact with explosive material, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y260",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home"
    },
    {
      "name": "Y2600",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2601",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2602",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While working for income"
    },
    {
      "name": "Y2603",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2604",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2608",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2609",
      "description": "Exposure to smoke, fire and flames, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y261",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution"
    },
    {
      "name": "Y2610",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2611",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2612",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2613",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2614",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2618",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2619",
      "description": "Exposure to smoke, fire and flames, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y262",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2620",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2621",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2622",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2623",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2624",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2628",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2629",
      "description": "Exposure to smoke, fire and flames, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y263",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2630",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2631",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2632",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2633",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2634",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2638",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2639",
      "description": "Exposure to smoke, fire and flames, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y264",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway"
    },
    {
      "name": "Y2640",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2641",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2642",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2643",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2644",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2648",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2649",
      "description": "Exposure to smoke, fire and flames, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y265",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area"
    },
    {
      "name": "Y2650",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2651",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2652",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2653",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2654",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2658",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2659",
      "description": "Exposure to smoke, fire and flames, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y266",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2660",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2661",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2662",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2663",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2664",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2668",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2669",
      "description": "Exposure to smoke, fire and flames, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y267",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm"
    },
    {
      "name": "Y2670",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2671",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2672",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2673",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2674",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2678",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2679",
      "description": "Exposure to smoke, fire and flames, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y268",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places"
    },
    {
      "name": "Y2680",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2681",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2682",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2683",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2684",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2688",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2689",
      "description": "Exposure to smoke, fire and flames, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y269",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place"
    },
    {
      "name": "Y2690",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2691",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2692",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2693",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2694",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2698",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2699",
      "description": "Exposure to smoke, fire and flames, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y270",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home"
    },
    {
      "name": "Y2700",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2701",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2702",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While working for income"
    },
    {
      "name": "Y2703",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2704",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2708",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2709",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y271",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution"
    },
    {
      "name": "Y2710",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2711",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2712",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2713",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2714",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2718",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2719",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y272",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2720",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2721",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2722",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2723",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2724",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2728",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2729",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y273",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2730",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2731",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2732",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2733",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2734",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2738",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2739",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y274",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway"
    },
    {
      "name": "Y2740",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2741",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2742",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2743",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2744",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2748",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2749",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y275",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area"
    },
    {
      "name": "Y2750",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2751",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2752",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2753",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2754",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2758",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2759",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y276",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2760",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2761",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2762",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2763",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2764",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2768",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2769",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y277",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm"
    },
    {
      "name": "Y2770",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2771",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2772",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2773",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2774",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2778",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2779",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y278",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places"
    },
    {
      "name": "Y2780",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2781",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2782",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2783",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2784",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2788",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2789",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y279",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place"
    },
    {
      "name": "Y2790",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2791",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2792",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2793",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2794",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2798",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2799",
      "description": "Contact with steam, hot vapours and hot objects, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y280",
      "description": "Contact with sharp object, undetermined intent at home"
    },
    {
      "name": "Y2800",
      "description": "Contact with sharp object, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2801",
      "description": "Contact with sharp object, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2802",
      "description": "Contact with sharp object, undetermined intent at home While working for income"
    },
    {
      "name": "Y2803",
      "description": "Contact with sharp object, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2804",
      "description": "Contact with sharp object, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2808",
      "description": "Contact with sharp object, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2809",
      "description": "Contact with sharp object, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y281",
      "description": "Contact with sharp object, undetermined intent at residential institution"
    },
    {
      "name": "Y2810",
      "description": "Contact with sharp object, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2811",
      "description": "Contact with sharp object, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2812",
      "description": "Contact with sharp object, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2813",
      "description": "Contact with sharp object, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2814",
      "description": "Contact with sharp object, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2818",
      "description": "Contact with sharp object, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2819",
      "description": "Contact with sharp object, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y282",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2820",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2821",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2822",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2823",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2824",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2828",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area  While engaged in other specified activities"
    },
    {
      "name": "Y2829",
      "description": "Contact with sharp object, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y283",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2830",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2831",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2832",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2833",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2834",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2838",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2839",
      "description": "Contact with sharp object, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y284",
      "description": "Contact with sharp object, undetermined intent at street and highway"
    },
    {
      "name": "Y2840",
      "description": "Contact with sharp object, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2841",
      "description": "Contact with sharp object, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2842",
      "description": "Contact with sharp object, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2843",
      "description": "Contact with sharp object, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2844",
      "description": "Contact with sharp object, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2848",
      "description": "Contact with sharp object, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2849",
      "description": "Contact with sharp object, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y285",
      "description": "Contact with sharp object, undetermined intent at trade and service area"
    },
    {
      "name": "Y2850",
      "description": "Contact with sharp object, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2851",
      "description": "Contact with sharp object, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2852",
      "description": "Contact with sharp object, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2853",
      "description": "Contact with sharp object, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2854",
      "description": "Contact with sharp object, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2858",
      "description": "Contact with sharp object, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2859",
      "description": "Contact with sharp object, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y286",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2860",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2861",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2862",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2863",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2864",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2868",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2869",
      "description": "Contact with sharp object, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y287",
      "description": "Contact with sharp object, undetermined intent at farm"
    },
    {
      "name": "Y2870",
      "description": "Contact with sharp object, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2871",
      "description": "Contact with sharp object, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2872",
      "description": "Contact with sharp object, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2873",
      "description": "Contact with sharp object, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2874",
      "description": "Contact with sharp object, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2878",
      "description": "Contact with sharp object, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2879",
      "description": "Contact with sharp object, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y288",
      "description": "Contact with sharp object, undetermined intent at other specified places"
    },
    {
      "name": "Y2880",
      "description": "Contact with sharp object, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2881",
      "description": "Contact with sharp object, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2882",
      "description": "Contact with sharp object, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2883",
      "description": "Contact with sharp object, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2884",
      "description": "Contact with sharp object, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2888",
      "description": "Contact with sharp object, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2889",
      "description": "Contact with sharp object, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y289",
      "description": "Contact with sharp object, undetermined intent at unspecified place"
    },
    {
      "name": "Y2890",
      "description": "Contact with sharp object, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2891",
      "description": "Contact with sharp object, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2892",
      "description": "Contact with sharp object, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2893",
      "description": "Contact with sharp object, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2894",
      "description": "Contact with sharp object, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2898",
      "description": "Contact with sharp object, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2899",
      "description": "Contact with sharp object, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y290",
      "description": "Contact with blunt object, undetermined intent at home"
    },
    {
      "name": "Y2900",
      "description": "Contact with blunt object, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y2901",
      "description": "Contact with blunt object, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y2902",
      "description": "Contact with blunt object, undetermined intent at home While working for income"
    },
    {
      "name": "Y2903",
      "description": "Contact with blunt object, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y2904",
      "description": "Contact with blunt object, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2908",
      "description": "Contact with blunt object, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y2909",
      "description": "Contact with blunt object, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y291",
      "description": "Contact with blunt object, undetermined intent at residential institution"
    },
    {
      "name": "Y2910",
      "description": "Contact with blunt object, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y2911",
      "description": "Contact with blunt object, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y2912",
      "description": "Contact with blunt object, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y2913",
      "description": "Contact with blunt object, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y2914",
      "description": "Contact with blunt object, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2918",
      "description": "Contact with blunt object, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y2919",
      "description": "Contact with blunt object, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y292",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y2920",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y2921",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y2922",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y2923",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y2924",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2928",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y2929",
      "description": "Contact with blunt object, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y293",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y2930",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y2931",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y2932",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y2933",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y2934",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2938",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y2939",
      "description": "Contact with blunt object, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y294",
      "description": "Contact with blunt object, undetermined intent at street and highway"
    },
    {
      "name": "Y2940",
      "description": "Contact with blunt object, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y2941",
      "description": "Contact with blunt object, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y2942",
      "description": "Contact with blunt object, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y2943",
      "description": "Contact with blunt object, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y2944",
      "description": "Contact with blunt object, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2948",
      "description": "Contact with blunt object, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y2949",
      "description": "Contact with blunt object, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y295",
      "description": "Contact with blunt object, undetermined intent at trade and service area"
    },
    {
      "name": "Y2950",
      "description": "Contact with blunt object, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y2951",
      "description": "Contact with blunt object, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y2952",
      "description": "Contact with blunt object, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y2953",
      "description": "Contact with blunt object, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y2954",
      "description": "Contact with blunt object, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2958",
      "description": "Contact with blunt object, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y2959",
      "description": "Contact with blunt object, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y296",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y2960",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y2961",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y2962",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y2963",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y2964",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2968",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y2969",
      "description": "Contact with blunt object, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y297",
      "description": "Contact with blunt object, undetermined intent at farm"
    },
    {
      "name": "Y2970",
      "description": "Contact with blunt object, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y2971",
      "description": "Contact with blunt object, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y2972",
      "description": "Contact with blunt object, undetermined intent at farm While working for income"
    },
    {
      "name": "Y2973",
      "description": "Contact with blunt object, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y2974",
      "description": "Contact with blunt object, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2978",
      "description": "Contact with blunt object, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y2979",
      "description": "Contact with blunt object, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y298",
      "description": "Contact with blunt object, undetermined intent at other specified places"
    },
    {
      "name": "Y2980",
      "description": "Contact with blunt object, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y2981",
      "description": "Contact with blunt object, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y2982",
      "description": "Contact with blunt object, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y2983",
      "description": "Contact with blunt object, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y2984",
      "description": "Contact with blunt object, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2988",
      "description": "Contact with blunt object, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y2989",
      "description": "Contact with blunt object, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y299",
      "description": "Contact with blunt object, undetermined intent at unspecified place"
    },
    {
      "name": "Y2990",
      "description": "Contact with blunt object, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y2991",
      "description": "Contact with blunt object, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y2992",
      "description": "Contact with blunt object, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y2993",
      "description": "Contact with blunt object, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y2994",
      "description": "Contact with blunt object, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y2998",
      "description": "Contact with blunt object, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y2999",
      "description": "Contact with blunt object, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y300",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home"
    },
    {
      "name": "Y3000",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y3001",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y3002",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While working for income"
    },
    {
      "name": "Y3003",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y3004",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3008",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y3009",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y301",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution"
    },
    {
      "name": "Y3010",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y3011",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y3012",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y3013",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y3014",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3018",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y3019",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y302",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y3020",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y3021",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y3022",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y3023",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y3024",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3028",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y3029",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y303",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y3030",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y3031",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y3032",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y3033",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y3034",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3038",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y3039",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y304",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway"
    },
    {
      "name": "Y3040",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y3041",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y3042",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y3043",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y3044",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3048",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y3049",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y305",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area"
    },
    {
      "name": "Y3050",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y3051",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y3052",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y3053",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y3054",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3058",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y3059",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y306",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y3060",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y3061",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y3062",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y3063",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y3064",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3068",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y3069",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y307",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm"
    },
    {
      "name": "Y3070",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y3071",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y3072",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While working for income"
    },
    {
      "name": "Y3073",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y3074",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3078",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y3079",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y308",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places"
    },
    {
      "name": "Y3080",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y3081",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y3082",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y3083",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y3084",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3088",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y3089",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y309",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place"
    },
    {
      "name": "Y3090",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y3091",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y3092",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y3093",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y3094",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3098",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y3099",
      "description": "Falling, jumping or pushed from a high place, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y310",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home"
    },
    {
      "name": "Y3100",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y3101",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y3102",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While working for income"
    },
    {
      "name": "Y3103",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y3104",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3108",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y3109",
      "description": "Falling, lying or running before or into moving object, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y311",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution"
    },
    {
      "name": "Y3110",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y3111",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y3112",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y3113",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y3114",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3118",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y3119",
      "description": "Falling, lying or running before or into moving object, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y312",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y3120",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y3121",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y3122",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y3123",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y3124",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3128",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y3129",
      "description": "Falling, lying or running before or into moving object, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y313",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y3130",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y3131",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y3132",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y3133",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y3134",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3138",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y3139",
      "description": "Falling, lying or running before or into moving object, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y314",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway"
    },
    {
      "name": "Y3140",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y3141",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y3142",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y3143",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y3144",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3148",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y3149",
      "description": "Falling, lying or running before or into moving object, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y315",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area"
    },
    {
      "name": "Y3150",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y3151",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y3152",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y3153",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y3154",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3158",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y3159",
      "description": "Falling, lying or running before or into moving object, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y316",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y3160",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y3161",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y3162",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y3163",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y3164",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3168",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y3169",
      "description": "Falling, lying or running before or into moving object, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y317",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm"
    },
    {
      "name": "Y3170",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y3171",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y3172",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While working for income"
    },
    {
      "name": "Y3173",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y3174",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3178",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y3179",
      "description": "Falling, lying or running before or into moving object, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y318",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places"
    },
    {
      "name": "Y3180",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y3181",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y3182",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y3183",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y3184",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3188",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y3189",
      "description": "Falling, lying or running before or into moving object, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y319",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place"
    },
    {
      "name": "Y3190",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y3191",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y3192",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y3193",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y3194",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3198",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y3199",
      "description": "Falling, lying or running before or into moving object, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y320",
      "description": "Crashing of motor vehicle, undetermined intent at home"
    },
    {
      "name": "Y3200",
      "description": "Crashing of motor vehicle, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y3201",
      "description": "Crashing of motor vehicle, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y3202",
      "description": "Crashing of motor vehicle, undetermined intent at home While working for income"
    },
    {
      "name": "Y3203",
      "description": "Crashing of motor vehicle, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y3204",
      "description": "Crashing of motor vehicle, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3208",
      "description": "Crashing of motor vehicle, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y3209",
      "description": "Crashing of motor vehicle, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y321",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution"
    },
    {
      "name": "Y3210",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y3211",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y3212",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y3213",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y3214",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3218",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y3219",
      "description": "Crashing of motor vehicle, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y322",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y3220",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y3221",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y3222",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y3223",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y3224",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3228",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y3229",
      "description": "Crashing of motor vehicle, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y323",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y3230",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y3231",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y3232",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y3233",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y3234",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3238",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y3239",
      "description": "Crashing of motor vehicle, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y324",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway"
    },
    {
      "name": "Y3240",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y3241",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y3242",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y3243",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y3244",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3248",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y3249",
      "description": "Crashing of motor vehicle, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y325",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area"
    },
    {
      "name": "Y3250",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y3251",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y3252",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y3253",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y3254",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3258",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y3259",
      "description": "Crashing of motor vehicle, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y326",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y3260",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y3261",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y3262",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y3263",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y3264",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3268",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y3269",
      "description": "Crashing of motor vehicle, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y327",
      "description": "Crashing of motor vehicle, undetermined intent at farm"
    },
    {
      "name": "Y3270",
      "description": "Crashing of motor vehicle, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y3271",
      "description": "Crashing of motor vehicle, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y3272",
      "description": "Crashing of motor vehicle, undetermined intent at farm While working for income"
    },
    {
      "name": "Y3273",
      "description": "Crashing of motor vehicle, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y3274",
      "description": "Crashing of motor vehicle, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3278",
      "description": "Crashing of motor vehicle, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y3279",
      "description": "Crashing of motor vehicle, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y328",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places"
    },
    {
      "name": "Y3280",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y3281",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y3282",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y3283",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y3284",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3288",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y3289",
      "description": "Crashing of motor vehicle, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y329",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place"
    },
    {
      "name": "Y3290",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y3291",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y3292",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y3293",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y3294",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3298",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y3299",
      "description": "Crashing of motor vehicle, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y330",
      "description": "Other specified events, undetermined intent at home"
    },
    {
      "name": "Y3300",
      "description": "Other specified events, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y3301",
      "description": "Other specified events, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y3302",
      "description": "Other specified events, undetermined intent at home While working for income"
    },
    {
      "name": "Y3303",
      "description": "Other specified events, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y3304",
      "description": "Other specified events, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3308",
      "description": "Other specified events, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y3309",
      "description": "Other specified events, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y331",
      "description": "Other specified events, undetermined intent at residential institution"
    },
    {
      "name": "Y3310",
      "description": "Other specified events, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y3311",
      "description": "Other specified events, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y3312",
      "description": "Other specified events, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y3313",
      "description": "Other specified events, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y3314",
      "description": "Other specified events, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3318",
      "description": "Other specified events, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y3319",
      "description": "Other specified events, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y332",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y3320",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y3321",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y3322",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y3323",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y3324",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3328",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y3329",
      "description": "Other specified events, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y333",
      "description": "Other specified events, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y3330",
      "description": "Other specified events, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y3331",
      "description": "Other specified events, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y3332",
      "description": "Other specified events, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y3333",
      "description": "Other specified events, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y3334",
      "description": "Other specified events, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3338",
      "description": "Other specified events, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y3339",
      "description": "Other specified events, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y334",
      "description": "Other specified events, undetermined intent at street and highway"
    },
    {
      "name": "Y3340",
      "description": "Other specified events, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y3341",
      "description": "Other specified events, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y3342",
      "description": "Other specified events, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y3343",
      "description": "Other specified events, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y3344",
      "description": "Other specified events, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3348",
      "description": "Other specified events, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y3349",
      "description": "Other specified events, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y335",
      "description": "Other specified events, undetermined intent at trade and service area"
    },
    {
      "name": "Y3350",
      "description": "Other specified events, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y3351",
      "description": "Other specified events, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y3352",
      "description": "Other specified events, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y3353",
      "description": "Other specified events, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y3354",
      "description": "Other specified events, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3358",
      "description": "Other specified events, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y3359",
      "description": "Other specified events, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y336",
      "description": "Other specified events, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y3360",
      "description": "Other specified events, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y3361",
      "description": "Other specified events, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y3362",
      "description": "Other specified events, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y3363",
      "description": "Other specified events, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y3364",
      "description": "Other specified events, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3368",
      "description": "Other specified events, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y3369",
      "description": "Other specified events, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y337",
      "description": "Other specified events, undetermined intent at farm"
    },
    {
      "name": "Y3370",
      "description": "Other specified events, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y3371",
      "description": "Other specified events, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y3372",
      "description": "Other specified events, undetermined intent at farm While working for income"
    },
    {
      "name": "Y3373",
      "description": "Other specified events, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y3374",
      "description": "Other specified events, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3378",
      "description": "Other specified events, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y3379",
      "description": "Other specified events, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y338",
      "description": "Other specified events, undetermined intent at other specified places"
    },
    {
      "name": "Y3380",
      "description": "Other specified events, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y3381",
      "description": "Other specified events, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y3382",
      "description": "Other specified events, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y3383",
      "description": "Other specified events, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y3384",
      "description": "Other specified events, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3388",
      "description": "Other specified events, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y3389",
      "description": "Other specified events, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y339",
      "description": "Other specified events, undetermined intent at unspecified place"
    },
    {
      "name": "Y3390",
      "description": "Other specified events, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y3391",
      "description": "Other specified events, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y3392",
      "description": "Other specified events, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y3393",
      "description": "Other specified events, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y3394",
      "description": "Other specified events, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3398",
      "description": "Other specified events, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y3399",
      "description": "Other specified events, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y340",
      "description": "Unspecified event, undetermined intent at home"
    },
    {
      "name": "Y3400",
      "description": "Unspecified event, undetermined intent at home While engaged in sports activity"
    },
    {
      "name": "Y3401",
      "description": "Unspecified event, undetermined intent at home While engaged in leisure activity"
    },
    {
      "name": "Y3402",
      "description": "Unspecified event, undetermined intent at home While working for income"
    },
    {
      "name": "Y3403",
      "description": "Unspecified event, undetermined intent at home While engaged in other types of work"
    },
    {
      "name": "Y3404",
      "description": "Unspecified event, undetermined intent at home While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3408",
      "description": "Unspecified event, undetermined intent at home While engaged in other specified activities"
    },
    {
      "name": "Y3409",
      "description": "Unspecified event, undetermined intent at home During unspecified activity"
    },
    {
      "name": "Y341",
      "description": "Unspecified event, undetermined intent at residential institution"
    },
    {
      "name": "Y3410",
      "description": "Unspecified event, undetermined intent at residential institution While engaged in sports activity"
    },
    {
      "name": "Y3411",
      "description": "Unspecified event, undetermined intent at residential institution While engaged in leisure activity"
    },
    {
      "name": "Y3412",
      "description": "Unspecified event, undetermined intent at residential institution While working for income"
    },
    {
      "name": "Y3413",
      "description": "Unspecified event, undetermined intent at residential institution While engaged in other types of work"
    },
    {
      "name": "Y3414",
      "description": "Unspecified event, undetermined intent at residential institution While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3418",
      "description": "Unspecified event, undetermined intent at residential institution While engaged in other specified activities"
    },
    {
      "name": "Y3419",
      "description": "Unspecified event, undetermined intent at residential institution During unspecified activity"
    },
    {
      "name": "Y342",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area"
    },
    {
      "name": "Y3420",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While engaged in sports activity"
    },
    {
      "name": "Y3421",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While engaged in leisure activity"
    },
    {
      "name": "Y3422",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While working for income"
    },
    {
      "name": "Y3423",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While engaged in other types of work"
    },
    {
      "name": "Y3424",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3428",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area While engaged in other specified activities"
    },
    {
      "name": "Y3429",
      "description": "Unspecified event, undetermined intent at school,other institution and public administrative area During unspecified activity"
    },
    {
      "name": "Y343",
      "description": "Unspecified event, undetermined intent at sports and athletics area"
    },
    {
      "name": "Y3430",
      "description": "Unspecified event, undetermined intent at sports and athletics area While engaged in sports activity"
    },
    {
      "name": "Y3431",
      "description": "Unspecified event, undetermined intent at sports and athletics area While engaged in leisure activity"
    },
    {
      "name": "Y3432",
      "description": "Unspecified event, undetermined intent at sports and athletics area While working for income"
    },
    {
      "name": "Y3433",
      "description": "Unspecified event, undetermined intent at sports and athletics area While engaged in other types of work"
    },
    {
      "name": "Y3434",
      "description": "Unspecified event, undetermined intent at sports and athletics area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3438",
      "description": "Unspecified event, undetermined intent at sports and athletics area While engaged in other specified activities"
    },
    {
      "name": "Y3439",
      "description": "Unspecified event, undetermined intent at sports and athletics area During unspecified activity"
    },
    {
      "name": "Y344",
      "description": "Unspecified event, undetermined intent at street and highway"
    },
    {
      "name": "Y3440",
      "description": "Unspecified event, undetermined intent at street and highway While engaged in sports activity"
    },
    {
      "name": "Y3441",
      "description": "Unspecified event, undetermined intent at street and highway While engaged in leisure activity"
    },
    {
      "name": "Y3442",
      "description": "Unspecified event, undetermined intent at street and highway While working for income"
    },
    {
      "name": "Y3443",
      "description": "Unspecified event, undetermined intent at street and highway While engaged in other types of work"
    },
    {
      "name": "Y3444",
      "description": "Unspecified event, undetermined intent at street and highway While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3448",
      "description": "Unspecified event, undetermined intent at street and highway While engaged in other specified activities"
    },
    {
      "name": "Y3449",
      "description": "Unspecified event, undetermined intent at street and highway During unspecified activity"
    },
    {
      "name": "Y345",
      "description": "Unspecified event, undetermined intent at trade and service area"
    },
    {
      "name": "Y3450",
      "description": "Unspecified event, undetermined intent at trade and service area While engaged in sports activity"
    },
    {
      "name": "Y3451",
      "description": "Unspecified event, undetermined intent at trade and service area While engaged in leisure activity"
    },
    {
      "name": "Y3452",
      "description": "Unspecified event, undetermined intent at trade and service area While working for income"
    },
    {
      "name": "Y3453",
      "description": "Unspecified event, undetermined intent at trade and service area While engaged in other types of work"
    },
    {
      "name": "Y3454",
      "description": "Unspecified event, undetermined intent at trade and service area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3458",
      "description": "Unspecified event, undetermined intent at trade and service area While engaged in other specified activities"
    },
    {
      "name": "Y3459",
      "description": "Unspecified event, undetermined intent at trade and service area During unspecified activity"
    },
    {
      "name": "Y346",
      "description": "Unspecified event, undetermined intent at industrial and construction area"
    },
    {
      "name": "Y3460",
      "description": "Unspecified event, undetermined intent at industrial and construction area While engaged in sports activity"
    },
    {
      "name": "Y3461",
      "description": "Unspecified event, undetermined intent at industrial and construction area While engaged in leisure activity"
    },
    {
      "name": "Y3462",
      "description": "Unspecified event, undetermined intent at industrial and construction area While working for income"
    },
    {
      "name": "Y3463",
      "description": "Unspecified event, undetermined intent at industrial and construction area While engaged in other types of work"
    },
    {
      "name": "Y3464",
      "description": "Unspecified event, undetermined intent at industrial and construction area While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3468",
      "description": "Unspecified event, undetermined intent at industrial and construction area While engaged in other specified activities"
    },
    {
      "name": "Y3469",
      "description": "Unspecified event, undetermined intent at industrial and construction area During unspecified activity"
    },
    {
      "name": "Y347",
      "description": "Unspecified event, undetermined intent at farm"
    },
    {
      "name": "Y3470",
      "description": "Unspecified event, undetermined intent at farm While engaged in sports activity"
    },
    {
      "name": "Y3471",
      "description": "Unspecified event, undetermined intent at farm While engaged in leisure activity"
    },
    {
      "name": "Y3472",
      "description": "Unspecified event, undetermined intent at farm While working for income"
    },
    {
      "name": "Y3473",
      "description": "Unspecified event, undetermined intent at farm While engaged in other types of work"
    },
    {
      "name": "Y3474",
      "description": "Unspecified event, undetermined intent at farm While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3478",
      "description": "Unspecified event, undetermined intent at farm While engaged in other specified activities"
    },
    {
      "name": "Y3479",
      "description": "Unspecified event, undetermined intent at farm During unspecified activity"
    },
    {
      "name": "Y348",
      "description": "Unspecified event, undetermined intent at other specified places"
    },
    {
      "name": "Y3480",
      "description": "Unspecified event, undetermined intent at other specified places While engaged in sports activity"
    },
    {
      "name": "Y3481",
      "description": "Unspecified event, undetermined intent at other specified places While engaged in leisure activity"
    },
    {
      "name": "Y3482",
      "description": "Unspecified event, undetermined intent at other specified places While working for income"
    },
    {
      "name": "Y3483",
      "description": "Unspecified event, undetermined intent at other specified places While engaged in other types of work"
    },
    {
      "name": "Y3484",
      "description": "Unspecified event, undetermined intent at other specified places While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3488",
      "description": "Unspecified event, undetermined intent at other specified places While engaged in other specified activities"
    },
    {
      "name": "Y3489",
      "description": "Unspecified event, undetermined intent at other specified places During unspecified activity"
    },
    {
      "name": "Y349",
      "description": "Unspecified event, undetermined intent at unspecified place"
    },
    {
      "name": "Y3490",
      "description": "Unspecified event, undetermined intent at unspecified place While engaged in sports activity"
    },
    {
      "name": "Y3491",
      "description": "Unspecified event, undetermined intent at unspecified place While engaged in leisure activity"
    },
    {
      "name": "Y3492",
      "description": "Unspecified event, undetermined intent at unspecified place While working for income"
    },
    {
      "name": "Y3493",
      "description": "Unspecified event, undetermined intent at unspecified place While engaged in other types of work"
    },
    {
      "name": "Y3494",
      "description": "Unspecified event, undetermined intent at unspecified place While resting, sleeping, eating or engaging in other vital activities"
    },
    {
      "name": "Y3498",
      "description": "Unspecified event, undetermined intent at unspecified place While engaged in other specified activities"
    },
    {
      "name": "Y3499",
      "description": "Unspecified event, undetermined intent at unspecified place During unspecified activity"
    },
    {
      "name": "Y350",
      "description": "Legal intervention involving firearm discharge"
    },
    {
      "name": "Y351",
      "description": "Legal intervention involving explosives"
    },
    {
      "name": "Y352",
      "description": "Legal intervention involving gas"
    },
    {
      "name": "Y353",
      "description": "Legal intervention involving blunt objects"
    },
    {
      "name": "Y354",
      "description": "Legal intervention involving sharp objects"
    },
    {
      "name": "Y355",
      "description": "Legal execution"
    },
    {
      "name": "Y356",
      "description": "Legal intervention involving other specified means"
    },
    {
      "name": "Y357",
      "description": "Legal intervention, means unspecified"
    },
    {
      "name": "Y360",
      "description": "War operations involving explosion of marine weapons"
    },
    {
      "name": "Y361",
      "description": "War operations involving destruction of aircraft"
    },
    {
      "name": "Y362",
      "description": "War operations involving other explosions and feagments"
    },
    {
      "name": "Y363",
      "description": "War operations involving fires, conflagrations and hot substances"
    },
    {
      "name": "Y364",
      "description": "War operations involving firearm discharge and other forms of conventional warfare"
    },
    {
      "name": "Y365",
      "description": "War operations involving nuclear weapons"
    },
    {
      "name": "Y366",
      "description": "War operations involving biological weapons"
    },
    {
      "name": "Y367",
      "description": "War operations involving chemical weapons and other forms of unconventional warfare"
    },
    {
      "name": "Y368",
      "description": "War operations occurring after cessation of hostillities"
    },
    {
      "name": "Y369",
      "description": "War operations, unspecified"
    },
    {
      "name": "Y400",
      "description": "Systemic antibiotics by Penicillins"
    },
    {
      "name": "Y401",
      "description": "Systemic antibiotics by Cefalosporins and other β–lactam antibiotics"
    },
    {
      "name": "Y402",
      "description": "Systemic antibiotics by Chloramphenicol group"
    },
    {
      "name": "Y403",
      "description": "Systemic antibiotics by Macrolides"
    },
    {
      "name": "Y404",
      "description": "Systemic antibiotics by Tetracyclines"
    },
    {
      "name": "Y405",
      "description": "Systemic antibiotics by Aminoglycosides"
    },
    {
      "name": "Y406",
      "description": "Systemic antibiotics by Rifamycins"
    },
    {
      "name": "Y407",
      "description": "Systemic antibiotics by Antifungal antibiotics, systemically used"
    },
    {
      "name": "Y408",
      "description": "Systemic antibiotics Other systemic antibiotics"
    },
    {
      "name": "Y409",
      "description": "Systemic antibiotic, unspecified"
    },
    {
      "name": "Y410",
      "description": "Other systemic anti-infectives and antiparasitics by Sulfonamides"
    },
    {
      "name": "Y411",
      "description": "Other systemic anti-infectives and antiparasitics by Antimycobacterial drugs"
    },
    {
      "name": "Y412",
      "description": "Other systemic anti-infectives and antiparasitics by Antimalarials and drugs acting on other blood protozoa"
    },
    {
      "name": "Y413",
      "description": "Other systemic anti-infectives and antiparasitics by Other antiprotozoal drugs"
    },
    {
      "name": "Y414",
      "description": "Other systemic anti-infectives and antiparasitics by Anthelminthics"
    },
    {
      "name": "Y415",
      "description": "Other systemic anti-infectives and antiparasitics by Antiviral drugs"
    },
    {
      "name": "Y418",
      "description": "Other specified systemic anti-infectives and antiparasitics"
    },
    {
      "name": "Y419",
      "description": "Systemic anti-infective and antiparasitic, unspecified"
    },
    {
      "name": "Y420",
      "description": "Glucocorticoids and synthetic analogues"
    },
    {
      "name": "Y421",
      "description": "Thyroid hormones and substitutes"
    },
    {
      "name": "Y422",
      "description": "Antithyroid drugs"
    },
    {
      "name": "Y423",
      "description": "Insulin and oral hypoglycaemic [antidiabetic] drugs"
    },
    {
      "name": "Y424",
      "description": "Oral contraceptives"
    },
    {
      "name": "Y425",
      "description": "Other estrogens and progestogens"
    },
    {
      "name": "Y426",
      "description": "Antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified"
    },
    {
      "name": "Y427",
      "description": "Androgens and anabolic congeners"
    },
    {
      "name": "Y428",
      "description": "Other and unspecified hormones and their synthetic substitutes"
    },
    {
      "name": "Y429",
      "description": "Other and unspecified hormone antagonists"
    },
    {
      "name": "Y430",
      "description": "Antiallergic and antiemetic drugs"
    },
    {
      "name": "Y431",
      "description": "Antineoplastic antimetabolites"
    },
    {
      "name": "Y432",
      "description": "Antineoplastic natural products"
    },
    {
      "name": "Y433",
      "description": "Other antineoplastic drugs"
    },
    {
      "name": "Y434",
      "description": "Immunosuppressive agents"
    },
    {
      "name": "Y435",
      "description": "Acidifying and alkalizing agents"
    },
    {
      "name": "Y436",
      "description": "Enzymes, not elsewhere classified"
    },
    {
      "name": "Y438",
      "description": "Other primarily systemic agents, not elsewhere classified"
    },
    {
      "name": "Y439",
      "description": "Primarily systemic agent, unspecified"
    },
    {
      "name": "Y440",
      "description": "Iron preparations and other anti-hypochromic-anaemia preparations"
    },
    {
      "name": "Y441",
      "description": "Vitamin B12, folic acid and other anti-megaloblastic-anaemia preparations"
    },
    {
      "name": "Y442",
      "description": "Anticoagulants"
    },
    {
      "name": "Y443",
      "description": "Anticoagulant antagonists, vitamin K and other coagulants"
    },
    {
      "name": "Y444",
      "description": "Antithrombotic drugs [platelet-aggregation inhibitors]"
    },
    {
      "name": "Y445",
      "description": "Thrombolytic drugs"
    },
    {
      "name": "Y446",
      "description": "Natural blood and blood products"
    },
    {
      "name": "Y447",
      "description": "Plasma substitutes"
    },
    {
      "name": "Y449",
      "description": "Other and unspecified agents affecting blood constituents"
    },
    {
      "name": "Y450",
      "description": "Opioids and related analgesics"
    },
    {
      "name": "Y451",
      "description": "Salicylates"
    },
    {
      "name": "Y452",
      "description": "Propionic acid derivatives"
    },
    {
      "name": "Y453",
      "description": "Other nonsteroidal anti-inflammatory drugs [NSAID]"
    },
    {
      "name": "Y454",
      "description": "Antirheumatics"
    },
    {
      "name": "Y455",
      "description": "4-Aminophenol derivatives"
    },
    {
      "name": "Y458",
      "description": "Other analgesics, antipyretics and anti-inflammatory drugs"
    },
    {
      "name": "Y459",
      "description": "Analgesic, antipyretic and anti-inflammatory drug, unspecified"
    },
    {
      "name": "Y460",
      "description": "Antiepileptics and antiparkinsonism drugs by Succinimides"
    },
    {
      "name": "Y461",
      "description": "Antiepileptics and antiparkinsonism drugs by Oxazolidinediones"
    },
    {
      "name": "Y462",
      "description": "Antiepileptics and antiparkinsonism drugs by Hydantoin derivatives"
    },
    {
      "name": "Y463",
      "description": "Antiepileptics and antiparkinsonism drugs by Deoxybarbiturates"
    },
    {
      "name": "Y464",
      "description": "Antiepileptics and antiparkinsonism drugs by Iminostilbenes"
    },
    {
      "name": "Y465",
      "description": "Antiepileptics and antiparkinsonism drugs by Valproic acid"
    },
    {
      "name": "Y466",
      "description": "Antiepileptics and antiparkinsonism drugs by Other and unspecified antiepiltptics"
    },
    {
      "name": "Y467",
      "description": "Antiepileptics and antiparkinsonism drugs by Antiparkinsonism drugs"
    },
    {
      "name": "Y468",
      "description": "Antiepileptics and antiparkinsonism drugs by Antispasticity drugs"
    },
    {
      "name": "Y470",
      "description": "Sedatives, hypnotics and antianxiety drugs by Barbiturates, not elsewhere classified"
    },
    {
      "name": "Y471",
      "description": "Sedatives, hypnotics and antianxiety drugs by Benzodiazepines"
    },
    {
      "name": "Y472",
      "description": "Sedatives, hypnotics and antianxiety drugs by Cloral derivatives"
    },
    {
      "name": "Y473",
      "description": "Sedatives, hypnotics and antianxiety drugs by Paraldehyde"
    },
    {
      "name": "Y474",
      "description": "Sedatives, hypnotics and antianxiety drugs by Bromine compounds"
    },
    {
      "name": "Y475",
      "description": "Sedatives, hypnotics and antianxiety drugs by Mixed sedatives and hypnotics, not elsewhere classified"
    },
    {
      "name": "Y478",
      "description": "Sedatives, hypnotics and antianxiety drugs by Other sedatives, hypnotics and antianxiety drugs"
    },
    {
      "name": "Y479",
      "description": "Sedative, hypnotic and antianxiety drug, unspecified"
    },
    {
      "name": "Y480",
      "description": "Inhaled anaesthetics"
    },
    {
      "name": "Y481",
      "description": "Parenteral anaesthetics"
    },
    {
      "name": "Y482",
      "description": "Other and unspecified general anaesthetics"
    },
    {
      "name": "Y483",
      "description": "Local anaesthetics"
    },
    {
      "name": "Y484",
      "description": "Anaesthetic, unspecified"
    },
    {
      "name": "Y485",
      "description": "Therapeutic gases"
    },
    {
      "name": "Y490",
      "description": "Tricyclic and tetracyclic antidepressants"
    },
    {
      "name": "Y491",
      "description": "Monoamine-oxidase-inhibitor antidepressants"
    },
    {
      "name": "Y492",
      "description": "Other and unspecified antidepressants"
    },
    {
      "name": "Y493",
      "description": "Phenothiazine antipsychotics and neuroleptics"
    },
    {
      "name": "Y494",
      "description": "Butyrophenone and thioxanthene neuroleptics"
    },
    {
      "name": "Y495",
      "description": "Other antipsychotics and neuroleptics"
    },
    {
      "name": "Y496",
      "description": "Psychodysleptics [hallucinogens]"
    },
    {
      "name": "Y497",
      "description": "Psychostimulants with abuse potential"
    },
    {
      "name": "Y498",
      "description": "Other psychotropic drugs, not elsewhere classified"
    },
    {
      "name": "Y499",
      "description": "Psychotropic drug, unspecified"
    },
    {
      "name": "Y500",
      "description": "Analeptics"
    },
    {
      "name": "Y501",
      "description": "Opioid receptor antagonists"
    },
    {
      "name": "Y502",
      "description": "Methylxanthines, not eleswhere classified"
    },
    {
      "name": "Y508",
      "description": "Other central nervous system stimulants"
    },
    {
      "name": "Y509",
      "description": "Central nervous system stimulant, unspecified"
    },
    {
      "name": "Y510",
      "description": "Anticholinesterase agents"
    },
    {
      "name": "Y511",
      "description": "Other parasympathomimetics [cholinergics]"
    },
    {
      "name": "Y512",
      "description": "Ganglionic blocking drugs, not elsewhere classified"
    },
    {
      "name": "Y513",
      "description": "Other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics, not elsewhere classified"
    },
    {
      "name": "Y514",
      "description": "Predominantly α-adrenoreceptor agonists, not elsewhere classified"
    },
    {
      "name": "Y515",
      "description": "Predominantly β-adrenoreceptor agonists, not elsewhere classified"
    },
    {
      "name": "Y516",
      "description": "α-Adrenoreceptor antagonists, not elsewhere classified"
    },
    {
      "name": "Y517",
      "description": "β-Adrenoreceptor antagonists, not elsewhere classified"
    },
    {
      "name": "Y518",
      "description": "Centrally acting and adrenergic-neuron-blocking agents, not elsewhere  classified"
    },
    {
      "name": "Y519",
      "description": "Other and unspecified drugs primarily affecting the autonomic nervous system"
    },
    {
      "name": "Y520",
      "description": "Cardiac-stimulant glycosides and drugs of similar action"
    },
    {
      "name": "Y521",
      "description": "Calcium-channel blockers"
    },
    {
      "name": "Y522",
      "description": "Other antidysrhythmic drugs, not elsewhere classified"
    },
    {
      "name": "Y523",
      "description": "Coronary vasodilators, not elsewhere classified"
    },
    {
      "name": "Y524",
      "description": "Angiotensin-converting-enzyme inhibitors"
    },
    {
      "name": "Y525",
      "description": "Other antihypertensive drugs, not elsewhere classified"
    },
    {
      "name": "Y526",
      "description": "Antihyperlipidaemic and antiarteriosclerotic drugs"
    },
    {
      "name": "Y527",
      "description": "Peripheral vasodilators"
    },
    {
      "name": "Y528",
      "description": "Antivaricose drugs, including sclerosing agents"
    },
    {
      "name": "Y529",
      "description": "Other and unspecified agents primarily affecting the cardiovascular system"
    },
    {
      "name": "Y530",
      "description": "Histamine H2-receptor antagonists"
    },
    {
      "name": "Y531",
      "description": "Other antacids and anti-gastric-secretion drugs"
    },
    {
      "name": "Y532",
      "description": "Stimulant laxatives"
    },
    {
      "name": "Y533",
      "description": "Saline and osmotic laxatives"
    },
    {
      "name": "Y534",
      "description": "Other laxatives"
    },
    {
      "name": "Y535",
      "description": "Digestants"
    },
    {
      "name": "Y536",
      "description": "Antidiarrhoeal drugs"
    },
    {
      "name": "Y537",
      "description": "Emetics"
    },
    {
      "name": "Y538",
      "description": "Other agents primarily affecting the gastrointestinal system"
    },
    {
      "name": "Y539",
      "description": "Agent primarily affecting the gastrointestinal system, unspecified"
    },
    {
      "name": "Y540",
      "description": "Mineralocorticoids"
    },
    {
      "name": "Y541",
      "description": "Mineralocorticoid antagonists [aldosterone antagonists]"
    },
    {
      "name": "Y542",
      "description": "Carbonic-anhydrase inhibitors"
    },
    {
      "name": "Y543",
      "description": "Binzothiadiazine derivatives"
    },
    {
      "name": "Y544",
      "description": "Loop [high-ceiling] diuretics"
    },
    {
      "name": "Y545",
      "description": "Other diuretics"
    },
    {
      "name": "Y546",
      "description": "Electrolytic, caloric and water-balance agents"
    },
    {
      "name": "Y547",
      "description": "Agents affecting calcification"
    },
    {
      "name": "Y548",
      "description": "Agents affecting uric acid metabolism"
    },
    {
      "name": "Y549",
      "description": "Mineral salts, not elsewhere classified"
    },
    {
      "name": "Y550",
      "description": "Oxytocic drugs"
    },
    {
      "name": "Y551",
      "description": "Skeletal muscle relaxants [neuromuscular blocking agents]"
    },
    {
      "name": "Y552",
      "description": "Other and unspecified agents primarily acting on muscles"
    },
    {
      "name": "Y553",
      "description": "Antitussives"
    },
    {
      "name": "Y554",
      "description": "Expectorants"
    },
    {
      "name": "Y555",
      "description": "Anti-common-cold drugs"
    },
    {
      "name": "Y556",
      "description": "Antiasthmatics, not elsewhere classified"
    },
    {
      "name": "Y557",
      "description": "Other and unspecified agents primarily acting on the respiratory system"
    },
    {
      "name": "Y560",
      "description": "Local antifungal, anti-infective and anti-inflammatory drugs. not elsewhere classified"
    },
    {
      "name": "Y561",
      "description": "Antipruritics"
    },
    {
      "name": "Y562",
      "description": "Local astringents and local detergents"
    },
    {
      "name": "Y563",
      "description": "Emollients, demulcents and protectants"
    },
    {
      "name": "Y564",
      "description": "Keratolytics, keratoplastics and other hair treatment drugs and preparations"
    },
    {
      "name": "Y565",
      "description": "Ophthalmological drugs and preparations"
    },
    {
      "name": "Y566",
      "description": "Otorhinolaryngological drugs and preparations"
    },
    {
      "name": "Y567",
      "description": "Dental drugs, topically applied"
    },
    {
      "name": "Y568",
      "description": "Other topical agents"
    },
    {
      "name": "Y569",
      "description": "Topical agent, unspecified"
    },
    {
      "name": "Y570",
      "description": "Appetite depressants [anorectics]"
    },
    {
      "name": "Y571",
      "description": "Lipotropic drugs"
    },
    {
      "name": "Y572",
      "description": "Antidotes and chelating agents, not elsewhere classified"
    },
    {
      "name": "Y573",
      "description": "Alcohol deterrents"
    },
    {
      "name": "Y574",
      "description": "Pharmaceutical excipients"
    },
    {
      "name": "Y575",
      "description": "X-ray contrast media"
    },
    {
      "name": "Y576",
      "description": "Other diagnostic agents"
    },
    {
      "name": "Y577",
      "description": "Vitamins, not elsewhere classified"
    },
    {
      "name": "Y578",
      "description": "Other drugs and medicaments"
    },
    {
      "name": "Y579",
      "description": "Drug or medicament, unspecified"
    },
    {
      "name": "Y580",
      "description": "BCG vaccine"
    },
    {
      "name": "Y581",
      "description": "Typhoid and paratyphoid vaccine"
    },
    {
      "name": "Y582",
      "description": "Cholera vaccine"
    },
    {
      "name": "Y583",
      "description": "Plague vaccine"
    },
    {
      "name": "Y584",
      "description": "Tetanus vaccine"
    },
    {
      "name": "Y585",
      "description": "Diphtheria vaccine"
    },
    {
      "name": "Y586",
      "description": "Pertussis vaccine, including combinations with a pertussis component"
    },
    {
      "name": "Y588",
      "description": "Mixed bacterial vaccines, except combinations with a pertussis component"
    },
    {
      "name": "Y589",
      "description": "Other and unspecified bacterial vaccines"
    },
    {
      "name": "Y590",
      "description": "Viral vaccines"
    },
    {
      "name": "Y591",
      "description": "Rickettsial vaccines"
    },
    {
      "name": "Y592",
      "description": "Protozoal vaccines"
    },
    {
      "name": "Y593",
      "description": "Immunoglobulin"
    },
    {
      "name": "Y598",
      "description": "Other specified vaccines and biological substances"
    },
    {
      "name": "Y599",
      "description": "Vaccine or biological substance, unspecified"
    },
    {
      "name": "Y600",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During surgical operation"
    },
    {
      "name": "Y601",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During infusion or transfusion"
    },
    {
      "name": "Y602",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During kidney dialysis or other perfusion"
    },
    {
      "name": "Y603",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During injection or immunization"
    },
    {
      "name": "Y604",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During endoscopic examination"
    },
    {
      "name": "Y605",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During heart catheterization"
    },
    {
      "name": "Y606",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During aspiration, puncture and other catheterization"
    },
    {
      "name": "Y607",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During administration of enema"
    },
    {
      "name": "Y608",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During other surgical and medical care"
    },
    {
      "name": "Y609",
      "description": "Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care, During unspecified surgical and medical care"
    },
    {
      "name": "Y610",
      "description": "Foreign object accidentally left in body during surgical and medical care, During surgical operation"
    },
    {
      "name": "Y611",
      "description": "Foreign object accidentally left in body during surgical and medical care, During infusion or transfusion"
    },
    {
      "name": "Y612",
      "description": "Foreign object accidentally left in body during surgical and medical care, During kidney dialysis or other perfusion"
    },
    {
      "name": "Y613",
      "description": "Foreign object accidentally left in body during surgical and medical care, During injection or immunization"
    },
    {
      "name": "Y614",
      "description": "Foreign object accidentally left in body during surgical and medical care, During endoscopic examination"
    },
    {
      "name": "Y615",
      "description": "Foreign object accidentally left in body during surgical and medical care, During heart catheterization"
    },
    {
      "name": "Y616",
      "description": "Foreign object accidentally left in body during surgical and medical care, During aspiration, puncture and other catheterization"
    },
    {
      "name": "Y617",
      "description": "Foreign object accidentally left in body during surgical and medical care, During removal of catheter or packing"
    },
    {
      "name": "Y618",
      "description": "Foreign object accidentally left in body during surgical and medical care, During other surgical and medical care"
    },
    {
      "name": "Y619",
      "description": "Foreign object accidentally left in body during surgical and medical care, During unspecified surgical and medical care"
    },
    {
      "name": "Y620",
      "description": "Failure of sterile precautions during surgical and medical care, During surgical operation"
    },
    {
      "name": "Y621",
      "description": "Failure of sterile precautions during surgical and medical care, During infusion or transfusion"
    },
    {
      "name": "Y622",
      "description": "Failure of sterile precautions during surgical and medical care, During kidney dialysis or other perfusion"
    },
    {
      "name": "Y623",
      "description": "Failure of sterile precautions during surgical and medical care, During injection or immunization"
    },
    {
      "name": "Y624",
      "description": "Failure of sterile precautions during surgical and medical care, During endoscopic examination"
    },
    {
      "name": "Y625",
      "description": "Failure of sterile precautions during surgical and medical care, During heart catheterization"
    },
    {
      "name": "Y626",
      "description": "Failure of sterile precautions during surgical and medical care, During aspiration, puncture and other catheterization"
    },
    {
      "name": "Y628",
      "description": "Failure of sterile precautions during surgical and medical care, During other surgical and medical care"
    },
    {
      "name": "Y629",
      "description": "Failure of sterile precautions during surgical and medical care, During unspecified surgical and medical care"
    },
    {
      "name": "Y630",
      "description": "Failure in dosage during surgical and medical care excessive amount of blood or other fluid given during transfusion or infusion"
    },
    {
      "name": "Y631",
      "description": "Failure in dosage during surgical and medical care incorrect dilution of fluid used during infusion"
    },
    {
      "name": "Y632",
      "description": "Failure in dosage during surgical and medical care overdose of radiation given during therapy"
    },
    {
      "name": "Y633",
      "description": "Failure in dosage during surgical and medical care iadvertent exposure of patient ot radiation during medical care"
    },
    {
      "name": "Y634",
      "description": "Failure in dosage during surgical and medical care failure in dosage in electroshock or insulin-shock therapy"
    },
    {
      "name": "Y635",
      "description": "Failure in dosage during surgical and medical care inappropriate temperatuer in local application and packing"
    },
    {
      "name": "Y636",
      "description": "Failure in dosage during surgical and medical care nonadministration of necessary drug, medicament or biological substance"
    },
    {
      "name": "Y638",
      "description": "Failure in dosage during surgical and medical care failure in dosage during other surgical and medical care"
    },
    {
      "name": "Y639",
      "description": "Failure in dosage during surgical and medical care failure in dosage during unspecified surgical and medical care"
    },
    {
      "name": "Y640",
      "description": "Contaminated medical or biological substance, transfused or infused"
    },
    {
      "name": "Y641",
      "description": "Contaminated medical or biological substance, injected or used for immunization"
    },
    {
      "name": "Y648",
      "description": "Contaminated medical or biological substance administered by other means"
    },
    {
      "name": "Y649",
      "description": "Contaminated medical or biological substance administered by unspecified means"
    },
    {
      "name": "Y650",
      "description": "Mismatched blood used in transfusion"
    },
    {
      "name": "Y651",
      "description": "Wrong fluid used in infusion"
    },
    {
      "name": "Y652",
      "description": "Failure in suture or ligature during surgical operation"
    },
    {
      "name": "Y653",
      "description": "Endotracheal tube wrongly placed during anaesthetic procedure"
    },
    {
      "name": "Y654",
      "description": "Failure to introduce or to remove other tube or instrument"
    },
    {
      "name": "Y655",
      "description": "Performance of inappropriate operation"
    },
    {
      "name": "Y658",
      "description": "Other specified misadventures during surgical and medical care"
    },
    {
      "name": "Y700",
      "description": "Anaesthesiology devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y701",
      "description": "Anaesthesiology devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y702",
      "description": "Anaesthesiology devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y703",
      "description": "Anaesthesiology devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y708",
      "description": "Anaesthesiology devices associated with adverse incidents at miscellaneous devices, not elsewhere classified"
    },
    {
      "name": "Y710",
      "description": "Cardiovascular devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y711",
      "description": "Cardiovascular devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y712",
      "description": "Cardiovascular devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y713",
      "description": "Cardiovascular devices associated with adverse incidents at Surgical instruments, materials and devices (including sutrues)"
    },
    {
      "name": "Y718",
      "description": "Cardiovascular devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y720",
      "description": "Otorhinolaryngological devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y721",
      "description": "Otorhinolaryngological devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y722",
      "description": "Otorhinolaryngological devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y723",
      "description": "Otorhinolaryngological devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y728",
      "description": "Otorhinolaryngological devices associated with adverse incidents at miscellaneous devices, not elsewhere classified"
    },
    {
      "name": "Y730",
      "description": "Gastroenterology and urology devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y731",
      "description": "Gastroenterology and urology devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y732",
      "description": "Gastroenterology and urology devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y733",
      "description": "Gastroenterology and urology devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y738",
      "description": "Gastroenterology and urology devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y740",
      "description": "General hospital and personal-use  devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y741",
      "description": "General hospital and personal-use  devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y742",
      "description": "General hospital and personal-use  devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y743",
      "description": "General hospital and personal-use  devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y748",
      "description": "General hospital and personal-use  devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y750",
      "description": "Neurological devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y751",
      "description": "Neurological devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y752",
      "description": "Neurological devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y753",
      "description": "Neurological devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y758",
      "description": "Neurological devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y760",
      "description": "Obstetric and gynaecological devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y761",
      "description": "Obstetric and gynaecological devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y762",
      "description": "Obstetric and gynaecological devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y763",
      "description": "Obstetric and gynaecological devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y768",
      "description": "Obstetric and gynaecological devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y770",
      "description": "Ophthalmic devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y771",
      "description": "Ophthalmic devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y772",
      "description": "Ophthalmic devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y773",
      "description": "Ophthalmic devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y778",
      "description": "Ophthalmic devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y780",
      "description": "Radiological  devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y781",
      "description": "Radiological  devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y782",
      "description": "Radiological  devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y783",
      "description": "Radiological  devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y788",
      "description": "Radiological  devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y790",
      "description": "Orthopaedic   devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y791",
      "description": "Orthopaedic   devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y792",
      "description": "Orthopaedic   devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y793",
      "description": "Orthopaedic   devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y798",
      "description": "Orthopaedic   devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y800",
      "description": "Physical medicine  devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y801",
      "description": "Physical medicine  devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y802",
      "description": "Physical medicine  devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y803",
      "description": "Physical medicine  devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y808",
      "description": "Physical medicine  devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y810",
      "description": "General-and plastic-surgery  devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y811",
      "description": "General-and plastic-surgery  devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y812",
      "description": "General-and plastic-surgery  devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y813",
      "description": "General-and plastic-surgery  devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y818",
      "description": "General-and plastic-surgery  devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y820",
      "description": "Other and unspecified medical devices associated with adverse incidents at Diagnostic and monitoring devices"
    },
    {
      "name": "Y821",
      "description": "Other and unspecified medical devices associated with adverse incidents at Therapeutic (nonsurgical) and rehabilitative devices"
    },
    {
      "name": "Y822",
      "description": "Other and unspecified medical devices associated with adverse incidents at Prosthetic and other implants, materials an accessory devices"
    },
    {
      "name": "Y823",
      "description": "Other and unspecified medical devices associated with adverse incidents at Surgical instruments, materials and devices (including sutures)"
    },
    {
      "name": "Y828",
      "description": "Other and unspecified medical devices associated with adverse incidents at miscellaneous devices,not elsewhere classified"
    },
    {
      "name": "Y830",
      "description": "Surgical operation with transplant of whole organ"
    },
    {
      "name": "Y831",
      "description": "Surgical operation with implant of artificial internal device"
    },
    {
      "name": "Y832",
      "description": "Surgical operation with anastomosis, bypass or graft"
    },
    {
      "name": "Y833",
      "description": "Surgical operation with formation of external stoma"
    },
    {
      "name": "Y834",
      "description": "Other reconstructive surgery"
    },
    {
      "name": "Y835",
      "description": "Amputation of limb(s)"
    },
    {
      "name": "Y836",
      "description": "Removal of other organ (partial) (total)"
    },
    {
      "name": "Y838",
      "description": "Other surgical procedures"
    },
    {
      "name": "Y839",
      "description": "Surgical procedure, unspecified"
    },
    {
      "name": "Y840",
      "description": "Cardiac catheterization"
    },
    {
      "name": "Y841",
      "description": "Kidney dialysis"
    },
    {
      "name": "Y842",
      "description": "Radiological procedure and radiotherapy"
    },
    {
      "name": "Y843",
      "description": "Shock therapy"
    },
    {
      "name": "Y844",
      "description": "Aspiration of fluid"
    },
    {
      "name": "Y845",
      "description": "Insertion of gastric or duodenal sound"
    },
    {
      "name": "Y846",
      "description": "Urinary catheterization"
    },
    {
      "name": "Y847",
      "description": "Blood-sampling"
    },
    {
      "name": "Y848",
      "description": "Other medical procedures"
    },
    {
      "name": "Y849",
      "description": "Medical procedure, unspecified"
    },
    {
      "name": "Y850",
      "description": "Sequelae of motor-vehicle accident"
    },
    {
      "name": "Y859",
      "description": "Sequelae of other and unspecified transport accidents"
    },
    {
      "name": "Y86",
      "description": "Sequelae of other accidents"
    },
    {
      "name": "Y870",
      "description": "Sequelae of intentional self-harm"
    },
    {
      "name": "Y871",
      "description": "Sequelae of assault"
    },
    {
      "name": "Y872",
      "description": "Sequelae of events of undetermined intent"
    },
    {
      "name": "Y880",
      "description": "Sequelae of adverse effects caused by drugs, medicaments and biological substances in therapeutic use"
    },
    {
      "name": "Y881",
      "description": "Sequelae of misadventures to patients during surgical and medical procedures"
    },
    {
      "name": "Y882",
      "description": "Sequelae of adverse incidents associared with medical devices in diagnostic and therapeutic use"
    },
    {
      "name": "Y883",
      "description": "Sequelae of surgical and medical procedures as the cause of abnormal reaction of the patient, or of later complication without mention of misadventure at the time of the procedure"
    },
    {
      "name": "Y890",
      "description": "Sequelae of legal intervention"
    },
    {
      "name": "Y891",
      "description": "Sequelae of war operations"
    },
    {
      "name": "Y899",
      "description": "Sequelae of unspecified external cause"
    },
    {
      "name": "Y900",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of less than 20 mg/100 ml"
    },
    {
      "name": "Y901",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 20-39 mg/100 ml"
    },
    {
      "name": "Y902",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 40-59 mg/100 ml"
    },
    {
      "name": "Y903",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 60-79 mg/100 ml"
    },
    {
      "name": "Y904",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 80-99 mg/100 ml"
    },
    {
      "name": "Y905",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 100-119 mg/100 ml"
    },
    {
      "name": "Y906",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 120-199 mg/100 ml"
    },
    {
      "name": "Y907",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 200-239 mg/100 ml"
    },
    {
      "name": "Y908",
      "description": "Evidence of alcohol involvement determined by blood alcohol level of 240 mg/100 ml or more"
    },
    {
      "name": "Y909",
      "description": "Evidence of alcohol involvement determined by presence of alcohol in blood, level not specified"
    },
    {
      "name": "Y910",
      "description": "Mild alcohol intoxication"
    },
    {
      "name": "Y911",
      "description": "Moderate alcohol intoxication"
    },
    {
      "name": "Y912",
      "description": "Severe alcohol intoxication"
    },
    {
      "name": "Y913",
      "description": "Very severe alcohol intoxication"
    },
    {
      "name": "Y919",
      "description": "Alcohol involvement, not otherwise specified"
    },
    {
      "name": "Y95",
      "description": "Nosocomial condition"
    },
    {
      "name": "Y96",
      "description": "Work-related condition"
    },
    {
      "name": "Y97",
      "description": "Environmental -pollution-related condition"
    },
    {
      "name": "Y98",
      "description": "Lifestyle-related condition"
    },
    {
      "name": "Z000",
      "description": "General medical examination"
    },
    {
      "name": "Z001",
      "description": "Routine child health examination"
    },
    {
      "name": "Z002",
      "description": "Examination for period of rapid growth in childhood"
    },
    {
      "name": "Z003",
      "description": "Examination for adolescent development state"
    },
    {
      "name": "Z004",
      "description": "General psychiatric examination, not elsewhere classified"
    },
    {
      "name": "Z005",
      "description": "Examination of potential donor of organ and tissue"
    },
    {
      "name": "Z006",
      "description": "Examination for normal comparison and control in clincal research programme"
    },
    {
      "name": "Z008",
      "description": "Other general examinations"
    },
    {
      "name": "Z010",
      "description": "Examination of eyes and vision"
    },
    {
      "name": "Z011",
      "description": "Examination of ears and hearing"
    },
    {
      "name": "Z012",
      "description": "Dental examination"
    },
    {
      "name": "Z013",
      "description": "Examination of blood pressure"
    },
    {
      "name": "Z014",
      "description": "Gynaecological examination (general)(routine)"
    },
    {
      "name": "Z015",
      "description": "Diagnostic skin and sensitization tests"
    },
    {
      "name": "Z016",
      "description": "Radiological examination, not elsewhere classified"
    },
    {
      "name": "Z017",
      "description": "Laboratory examination"
    },
    {
      "name": "Z018",
      "description": "Other specifiad special examinations"
    },
    {
      "name": "Z019",
      "description": "Special examination, unspecified"
    },
    {
      "name": "Z020",
      "description": "Examination for admission to educational institution"
    },
    {
      "name": "Z021",
      "description": "Pre-employment examination"
    },
    {
      "name": "Z022",
      "description": "Examination for admission to residential institution"
    },
    {
      "name": "Z023",
      "description": "Examination for recruitment to armed forces"
    },
    {
      "name": "Z024",
      "description": "Examination for driving licence"
    },
    {
      "name": "Z025",
      "description": "Examination for participation in sport"
    },
    {
      "name": "Z026",
      "description": "Examination for insurance purposes"
    },
    {
      "name": "Z027",
      "description": "Issue of medical certificate"
    },
    {
      "name": "Z028",
      "description": "Other examinations for administrative purposes"
    },
    {
      "name": "Z029",
      "description": "Examination for administrative purposes, unspecified"
    },
    {
      "name": "Z030",
      "description": "Observation for suspected tuberculosis"
    },
    {
      "name": "Z031",
      "description": "Observation for suspected malignant neoplasm"
    },
    {
      "name": "Z032",
      "description": "Observation for suspected mental and behavioural disorders"
    },
    {
      "name": "Z033",
      "description": "Observation for suspected nervous system disorder"
    },
    {
      "name": "Z034",
      "description": "Observation for suspected myocardial infarction"
    },
    {
      "name": "Z035",
      "description": "Observation for other suspected cardiovascular diseases"
    },
    {
      "name": "Z036",
      "description": "Observation for suspected toxic effect from ingested substance"
    },
    {
      "name": "Z038",
      "description": "Observation for other suspected diseases and conditions"
    },
    {
      "name": "Z039",
      "description": "Observation for suspected disease or condition, unspecified"
    },
    {
      "name": "Z040",
      "description": "Blood-alcohol and blood-drug test"
    },
    {
      "name": "Z041",
      "description": "Examination and observation following transport accident"
    },
    {
      "name": "Z042",
      "description": "Examination and observation following work accident"
    },
    {
      "name": "Z043",
      "description": "Examination and observation following other accident"
    },
    {
      "name": "Z044",
      "description": "Examination and observation following alleged rape and seduction"
    },
    {
      "name": "Z045",
      "description": "Examination and observation following other inflicted injury"
    },
    {
      "name": "Z046",
      "description": "General psychiatric examination, requested by authority"
    },
    {
      "name": "Z048",
      "description": "Examination and observation for other specified reasons"
    },
    {
      "name": "Z049",
      "description": "Examination and observation for unspecified reason"
    },
    {
      "name": "Z080",
      "description": "Follow-up examination after surgery for malignant neoplasm"
    },
    {
      "name": "Z081",
      "description": "Follow-up examination after radiotherapy for malignant neoplasm"
    },
    {
      "name": "Z082",
      "description": "Follow-up examination after chemotherapy for malignant neoplasm"
    },
    {
      "name": "Z087",
      "description": "Follow-up examination after combined treatment for malignant neoplasm"
    },
    {
      "name": "Z088",
      "description": "Follow-up examination after other treatment for malignant neoplasm"
    },
    {
      "name": "Z089",
      "description": "Follow-up examination after unspecified treatment for malignant neoplasm"
    },
    {
      "name": "Z090",
      "description": "Follow-up examination after surgery for other conditions"
    },
    {
      "name": "Z091",
      "description": "Follow-up examination after radiotherapy for other conditions"
    },
    {
      "name": "Z092",
      "description": "Follow-up examination after chemotherapy for other conditions"
    },
    {
      "name": "Z093",
      "description": "Follow-up examination after psychotherapy"
    },
    {
      "name": "Z094",
      "description": "Follow-up examination after treatment of fracture"
    },
    {
      "name": "Z097",
      "description": "Follow-up examination after combined treatment for other conditions"
    },
    {
      "name": "Z098",
      "description": "Follow-up examination after other treatment for other conditions"
    },
    {
      "name": "Z099",
      "description": "Follow-up examination after unspecified treatment for other conditions"
    },
    {
      "name": "Z100",
      "description": "Occupational health examination"
    },
    {
      "name": "Z101",
      "description": "Routine general health check-up of inhabitants of institutions"
    },
    {
      "name": "Z102",
      "description": "Routine general health check-up of armed forces"
    },
    {
      "name": "Z103",
      "description": "Routine general health check-up of sports teams"
    },
    {
      "name": "Z108",
      "description": "Routine general health check-up of other defined subpopulations"
    },
    {
      "name": "Z110",
      "description": "Special screening examination for intestinal infectious diseases"
    },
    {
      "name": "Z111",
      "description": "Special screening examination for respiratory tuberculosis"
    },
    {
      "name": "Z112",
      "description": "Special screening examination for other bacterial diseases"
    },
    {
      "name": "Z113",
      "description": "Special screening examination for infections with a predominantly sexual mode of transmission"
    },
    {
      "name": "Z114",
      "description": "Special screening examination for human immunodeficiency virus [HIV]"
    },
    {
      "name": "Z115",
      "description": "Special screening examination for other viral diseases"
    },
    {
      "name": "Z116",
      "description": "Special screening examination for other protozoal diseases and helminthiases"
    },
    {
      "name": "Z118",
      "description": "Special screening examination for other infectious and parasitic diseases"
    },
    {
      "name": "Z119",
      "description": "Special screening examination for infections and parasitic diseases, unspecified"
    },
    {
      "name": "Z120",
      "description": "Special screening examination for neoplasm of stomach"
    },
    {
      "name": "Z121",
      "description": "Special screening examination for neoplasm of intestinal tract"
    },
    {
      "name": "Z122",
      "description": "Special screening examination for neoplasm of respiratory organs"
    },
    {
      "name": "Z123",
      "description": "Special screening examination for neoplasm of breast"
    },
    {
      "name": "Z124",
      "description": "Special screening examination for neoplasm of cervix"
    },
    {
      "name": "Z125",
      "description": "Special screening examination for neoplasm of prostate"
    },
    {
      "name": "Z126",
      "description": "Special screening examination for neoplasm of bladder"
    },
    {
      "name": "Z128",
      "description": "Special screening examination for neoplasms of other sites"
    },
    {
      "name": "Z129",
      "description": "Special screening examination for neoplasm, unspecified"
    },
    {
      "name": "Z130",
      "description": "Special screening examination for diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism"
    },
    {
      "name": "Z131",
      "description": "Special screening examination for diabetes mellitus"
    },
    {
      "name": "Z132",
      "description": "Special screening examination for nutritional disorders"
    },
    {
      "name": "Z133",
      "description": "Special screening examination for mental and behavioural disorders"
    },
    {
      "name": "Z134",
      "description": "Special screening examination for certain developmental disorders in childhood"
    },
    {
      "name": "Z135",
      "description": "Special screening examination for eye and ear disorders"
    },
    {
      "name": "Z136",
      "description": "Special screening examination for cardiovascular disorders"
    },
    {
      "name": "Z137",
      "description": "Special screening examination for congenital malformations, deformations and chromosomal abnormalities"
    },
    {
      "name": "Z138",
      "description": "Special screening examination for other specified diseases and disorders"
    },
    {
      "name": "Z139",
      "description": "Special screening examination, unspecified"
    },
    {
      "name": "Z200",
      "description": "Contact with and exposure to intestinal infectious diseases"
    },
    {
      "name": "Z201",
      "description": "Contact with and exposure to tuberculosis"
    },
    {
      "name": "Z202",
      "description": "Contact with and exposure to infections with a predominantly sexual mode of transmission"
    },
    {
      "name": "Z203",
      "description": "Contact with and exposure to rabies"
    },
    {
      "name": "Z204",
      "description": "Contact with and exposure to rubella"
    },
    {
      "name": "Z205",
      "description": "Contact with and exposure to viral hepatitis"
    },
    {
      "name": "Z206",
      "description": "Contact with and exposure to human immunodeficiency virus [HIV]"
    },
    {
      "name": "Z207",
      "description": "Contact with and exposure to pediculosis, acariasis and other infestations"
    },
    {
      "name": "Z208",
      "description": "Contact with and exposure to other communicable diseases"
    },
    {
      "name": "Z209",
      "description": "Contact with and exposure to unspecified communicable disease"
    },
    {
      "name": "Z21",
      "description": "Asymptomatic human immunodeficiency virus [HIV] infection status"
    },
    {
      "name": "Z220",
      "description": "Carrier of typhoid"
    },
    {
      "name": "Z221",
      "description": "Carrier of other intestinal infectious diseases"
    },
    {
      "name": "Z222",
      "description": "Carrier of diphtheria"
    },
    {
      "name": "Z223",
      "description": "Carrier of other specified bacterial diseases"
    },
    {
      "name": "Z224",
      "description": "Carrier of infectious with a predominantly sexual mode of transmission "
    },
    {
      "name": "Z226",
      "description": "Carrier of human T-lymphotropic virus type 1 [HTLV-1]"
    },
    {
      "name": "Z228",
      "description": "Carrier of other infectious diseases"
    },
    {
      "name": "Z229",
      "description": "Carrier of infectious disease, unsepcifeid"
    },
    {
      "name": "Z230",
      "description": "Need for immunization against cholera alone"
    },
    {
      "name": "Z231",
      "description": "Need for immunization against typhoid-paratyphoid alone [TAB]"
    },
    {
      "name": "Z232",
      "description": "Need for immunization against tuberculosis [BCG]"
    },
    {
      "name": "Z233",
      "description": "Need for immunization against plague"
    },
    {
      "name": "Z234",
      "description": "Need for immunization against tularaemia"
    },
    {
      "name": "Z235",
      "description": "Need for immunization against tetanus alone"
    },
    {
      "name": "Z236",
      "description": "Need for immunization against diphteria alone"
    },
    {
      "name": "Z237",
      "description": "Need for immunization against pertussis alone"
    },
    {
      "name": "Z238",
      "description": "Need for immunization against other single bacterial diseases"
    },
    {
      "name": "Z240",
      "description": "Need for immunization against poliomyelitis"
    },
    {
      "name": "Z241",
      "description": "Need for immunization against arthropod-borne viral encephalitis"
    },
    {
      "name": "Z242",
      "description": "Need for immunization against rabies"
    },
    {
      "name": "Z243",
      "description": "Need for immunization against yellow fever"
    },
    {
      "name": "Z244",
      "description": "Need for immunization against measles alone"
    },
    {
      "name": "Z245",
      "description": "Need for immunization against rubella alone"
    },
    {
      "name": "Z246",
      "description": "Need for immunization against viral hepatitis"
    },
    {
      "name": "Z250",
      "description": "Need for immunization against mumps alone"
    },
    {
      "name": "Z251",
      "description": "Need for immunization against influenza"
    },
    {
      "name": "Z258",
      "description": "Need for immunization against other specified single viral diseases"
    },
    {
      "name": "Z260",
      "description": "Need for immunization against leishamaniasis"
    },
    {
      "name": "Z268",
      "description": "Need for immunization against other specified single infectious diseases"
    },
    {
      "name": "Z269",
      "description": "Need for immunization against unspecifid infectious dieseae"
    },
    {
      "name": "Z270",
      "description": "Need for immunization against cholera with typhoid-paratyphoid [cholera + TAB]"
    },
    {
      "name": "Z271",
      "description": "Need for immunization against diphtheria-tetanus-pertussis, combined [DTP]"
    },
    {
      "name": "Z272",
      "description": "Need for immunization against diphtheria-tetanus-pertussis with typhoid-paratyphoid [DTP + TAB]\n"
    },
    {
      "name": "Z273",
      "description": "Need for immunization against diphtheria-tetanus-pertussis with polimyelitis [DTP + polio]"
    },
    {
      "name": "Z274",
      "description": "Need for immunization against measles-mumps-rubella [MMR]"
    },
    {
      "name": "Z278",
      "description": "Need for immunization against other combinatious of infectious diseases"
    },
    {
      "name": "Z279",
      "description": "Need for immunization against unspecified combinatious of infectious diseases"
    },
    {
      "name": "Z280",
      "description": "Immunization not carried out because of contraindication"
    },
    {
      "name": "Z281",
      "description": "Immunization not carried out because of patient's decision for reasons of belief or group pressure"
    },
    {
      "name": "Z282",
      "description": "Immunization not carried out because of patient's decision for other and unsepcified reasons"
    },
    {
      "name": "Z288",
      "description": "Immunization not carried out for other reasons"
    },
    {
      "name": "Z289",
      "description": "Immunization not carried out for unspecified reason"
    },
    {
      "name": "Z290",
      "description": "Isolation"
    },
    {
      "name": "Z291",
      "description": "Prophylactic immunotherapy"
    },
    {
      "name": "Z292",
      "description": "Other prophylactic chemotherapy"
    },
    {
      "name": "Z298",
      "description": "Other specified prophylactic measures"
    },
    {
      "name": "Z299",
      "description": "Prophylactic measure, unsepcified"
    },
    {
      "name": "Z300",
      "description": "General counselling and advice on contraception"
    },
    {
      "name": "Z301",
      "description": "Insertion of (intrauterine) contraceptive device"
    },
    {
      "name": "Z302",
      "description": "Sterilization"
    },
    {
      "name": "Z303",
      "description": "Menstrual extraction"
    },
    {
      "name": "Z304",
      "description": "Surveillance of contraceptive drugs"
    },
    {
      "name": "Z305",
      "description": "Surveillance of (intrauterine) contraceptive device"
    },
    {
      "name": "Z308",
      "description": "Other contraceptive mangement"
    },
    {
      "name": "Z309",
      "description": "Contraceptive mangemeant, unspecified"
    },
    {
      "name": "Z310",
      "description": "Tuboplasty or vasoplasty after previous sterilization"
    },
    {
      "name": "Z311",
      "description": "Artificial insemination"
    },
    {
      "name": "Z312",
      "description": "In vitro fertilization"
    },
    {
      "name": "Z313",
      "description": "Other assisted fertilization methods"
    },
    {
      "name": "Z314",
      "description": "Procreative investigation and testing"
    },
    {
      "name": "Z315",
      "description": "Genetic counselling"
    },
    {
      "name": "Z316",
      "description": "General counselling and advice on procreation"
    },
    {
      "name": "Z318",
      "description": "Other procreative management"
    },
    {
      "name": "Z319",
      "description": "Procreative management,unspecified"
    },
    {
      "name": "Z320",
      "description": "Pregnancy, not (yet) confirmed"
    },
    {
      "name": "Z321",
      "description": "Pregnancy confirmed"
    },
    {
      "name": "Z33",
      "description": "Pregnant state, incidental"
    },
    {
      "name": "Z340",
      "description": "Supervision of normal first pregnancy"
    },
    {
      "name": "Z348",
      "description": "Supervision of other normal pregnancy"
    },
    {
      "name": "Z349",
      "description": "Supervision of normal pregnancy, unspecified"
    },
    {
      "name": "Z350",
      "description": "Supervision of pregnancy with history of infertility"
    },
    {
      "name": "Z351",
      "description": "Supervision of pregnancy with history of abortive outcome"
    },
    {
      "name": "Z352",
      "description": "Supervision of pregnancy with other poor reproductive or obstetric history"
    },
    {
      "name": "Z353",
      "description": "Supervision of pregnancy with history of insufficient antenatal care"
    },
    {
      "name": "Z354",
      "description": "Supervision of pregnancy with grand multiparity"
    },
    {
      "name": "Z355",
      "description": "Supervision of elderly primigravida"
    },
    {
      "name": "Z356",
      "description": "Supervision of very young primigravida"
    },
    {
      "name": "Z357",
      "description": "Supervision of high-risk pregnancy due to social problems"
    },
    {
      "name": "Z358",
      "description": "Supervision of other high-risk pregnancies"
    },
    {
      "name": "Z359",
      "description": "Supervision of high-risk pregnancy, unspecified"
    },
    {
      "name": "Z360",
      "description": "Antenatal screening for chromosomal anomalies"
    },
    {
      "name": "Z361",
      "description": "Antenatal screening for raised alphafetoprotein level"
    },
    {
      "name": "Z362",
      "description": "Other antenatal screening based on amniocentesis"
    },
    {
      "name": "Z363",
      "description": "Antenatal screening for malformations using ultrasound and other physical methods"
    },
    {
      "name": "Z364",
      "description": "Antenatal screening for fetal growth retardation using ultrasound and other physicla methods"
    },
    {
      "name": "Z365",
      "description": "Antenatal screening for isoimmunization"
    },
    {
      "name": "Z368",
      "description": "Other antenatal screening"
    },
    {
      "name": "Z369",
      "description": "Antenatal screening, unspecified"
    },
    {
      "name": "Z370",
      "description": "Single live birth"
    },
    {
      "name": "Z371",
      "description": "Single stillbirth"
    },
    {
      "name": "Z372",
      "description": "Twins, both liveborn"
    },
    {
      "name": "Z373",
      "description": "Twins, one liveborn and one stillborn"
    },
    {
      "name": "Z374",
      "description": "Twins, both stillborn"
    },
    {
      "name": "Z375",
      "description": "Other multiple births, all liveborn"
    },
    {
      "name": "Z376",
      "description": "Other multiple births, some liveborn"
    },
    {
      "name": "Z377",
      "description": "Other multiple births, all stillborn"
    },
    {
      "name": "Z379",
      "description": "Outcome of delivery, unspecified"
    },
    {
      "name": "Z380",
      "description": "Singleton, born in hospital"
    },
    {
      "name": "Z381",
      "description": "Singleton, born outside hospital"
    },
    {
      "name": "Z382",
      "description": "Singleton, unsepcified as to place of birth"
    },
    {
      "name": "Z383",
      "description": "Twin, born in hospital"
    },
    {
      "name": "Z384",
      "description": "Twin, born outside hospital"
    },
    {
      "name": "Z385",
      "description": "Twin, unsepcified as to place of birth"
    },
    {
      "name": "Z386",
      "description": "Other multiple,born in hospital"
    },
    {
      "name": "Z387",
      "description": "Other multiple, born outside hospital"
    },
    {
      "name": "Z388",
      "description": "Other multiple, unspecified as to place of birth"
    },
    {
      "name": "Z390",
      "description": "Care and examination immediately after delivery"
    },
    {
      "name": "Z391",
      "description": "Care and examination of lactating mother"
    },
    {
      "name": "Z392",
      "description": "Routine postpartum follow-up"
    },
    {
      "name": "Z400",
      "description": "Prophylactic surgery for risk-factors related to malignant neoplasms"
    },
    {
      "name": "Z408",
      "description": "Other prophylactic surgery"
    },
    {
      "name": "Z409",
      "description": "Prophylactic surgery, unspecified"
    },
    {
      "name": "Z410",
      "description": "Hair transplant"
    },
    {
      "name": "Z411",
      "description": "Other plastic surgery for unacceptable cosmetic apperance"
    },
    {
      "name": "Z412",
      "description": "Routine and ritual circumcision"
    },
    {
      "name": "Z413",
      "description": "Ear piercing"
    },
    {
      "name": "Z418",
      "description": "Other procedures for purposes other than remedying health state "
    },
    {
      "name": "Z419",
      "description": "Procedures for purposes other than remedying health state, unspecified"
    },
    {
      "name": "Z420",
      "description": "Follow-up care involving plastic surgery of head and neck"
    },
    {
      "name": "Z421",
      "description": "Follow-up care involving plastic surgery of breast"
    },
    {
      "name": "Z422",
      "description": "Follow-up care involving plastic surgery of other parts of trunk"
    },
    {
      "name": "Z423",
      "description": "Follow-up care involving plastic surgery of upper extremity"
    },
    {
      "name": "Z424",
      "description": "Follow-up care involving plastic surgery of lower extremity"
    },
    {
      "name": "Z428",
      "description": "Follow-up care involving plastic surgery of other body part"
    },
    {
      "name": "Z429",
      "description": "Follow-up care involving plastic surgery, unspecified"
    },
    {
      "name": "Z430",
      "description": "Attention to tracheostomy"
    },
    {
      "name": "Z431",
      "description": "Attention to gastrostomy"
    },
    {
      "name": "Z432",
      "description": "Attention to ileostomy"
    },
    {
      "name": "Z433",
      "description": "Attention to colostomy"
    },
    {
      "name": "Z434",
      "description": "Attention to other artificial openings of digestive tract"
    },
    {
      "name": "Z435",
      "description": "Attention to cystostomy"
    },
    {
      "name": "Z436",
      "description": "Attention to other artificial openings of urinary tract"
    },
    {
      "name": "Z437",
      "description": "Attention to artificial vagina"
    },
    {
      "name": "Z438",
      "description": "Attention to other artificial openings"
    },
    {
      "name": "Z439",
      "description": "Attention to unsepcified artificial opening"
    },
    {
      "name": "Z440",
      "description": "Fitting and adjustment of artificial arm (complete)(partial)"
    },
    {
      "name": "Z441",
      "description": "Fitting and adjustment of artificial leg  (complete)(partial)"
    },
    {
      "name": "Z442",
      "description": "Fitting and adjustment of artificial eye"
    },
    {
      "name": "Z443",
      "description": "Fitting and adjustment of external breast prosthesis"
    },
    {
      "name": "Z448",
      "description": "Fitting and adjustment of other external prosthetic devices"
    },
    {
      "name": "Z449",
      "description": "Fitting and adjustment of unspecified external prosthetic device"
    },
    {
      "name": "Z450",
      "description": "Adjustment and management of cardiac device"
    },
    {
      "name": "Z451",
      "description": "Adjustment and management of infusion pump"
    },
    {
      "name": "Z452",
      "description": "Adjustment and management of vascular access device"
    },
    {
      "name": "Z453",
      "description": "Adjustment and management of implanted hearing device"
    },
    {
      "name": "Z458",
      "description": "Adjustment and management of other implanted devices"
    },
    {
      "name": "Z459",
      "description": "Adjustment and management of unspecified implanted device"
    },
    {
      "name": "Z460",
      "description": "Fitting and adjustment of spectacles and contact lenses"
    },
    {
      "name": "Z461",
      "description": "Fitting and adjustment of hearing aid"
    },
    {
      "name": "Z462",
      "description": "Fitting and adjustment of other devices related to nervous system and special senses"
    },
    {
      "name": "Z463",
      "description": "Fitting and adjustment of dental prosthetic device"
    },
    {
      "name": "Z464",
      "description": "Fitting and adjustment of orthodontic device"
    },
    {
      "name": "Z465",
      "description": "Fitting and adjustment of ileostomy and other intestinal appliances"
    },
    {
      "name": "Z466",
      "description": "Fitting and adjustment of urinary device"
    },
    {
      "name": "Z467",
      "description": "Fitting and adjustment of orthopaedic device"
    },
    {
      "name": "Z468",
      "description": "Fitting and adjustment of other specified devices"
    },
    {
      "name": "Z469",
      "description": "Fitting and adjustment of unspecified device"
    },
    {
      "name": "Z470",
      "description": "Follow-up care involving ramoval of fracture plate and other internal fixation device"
    },
    {
      "name": "Z478",
      "description": "Other specified orthopaedic follow-up care"
    },
    {
      "name": "Z479",
      "description": "Orthopaedic follow-up care, unspecified"
    },
    {
      "name": "Z480",
      "description": "Attention to surgical dressings and sutures"
    },
    {
      "name": "Z488",
      "description": "Other specified surgical follow-up care"
    },
    {
      "name": "Z489",
      "description": "Surgical follow-up care, unspecified"
    },
    {
      "name": "Z490",
      "description": "Preparatory care for dialysis"
    },
    {
      "name": "Z491",
      "description": "Extracorporeal dialysis"
    },
    {
      "name": "Z492",
      "description": "Other dialysis"
    },
    {
      "name": "Z500",
      "description": "Cardiac rehabilitation"
    },
    {
      "name": "Z501",
      "description": "Other physicla therapy"
    },
    {
      "name": "Z502",
      "description": "Alcohol rehabilitation"
    },
    {
      "name": "Z503",
      "description": "Drug rehabilitation"
    },
    {
      "name": "Z504",
      "description": "Psychotherapy, not elsewhere classified"
    },
    {
      "name": "Z505",
      "description": "Speech therapy"
    },
    {
      "name": "Z506",
      "description": "Orthoptic training"
    },
    {
      "name": "Z507",
      "description": "Occupational therapy and vocational rehabilitation, not elsewhere classified"
    },
    {
      "name": "Z508",
      "description": "Care involving use of other rehabilitation procedures"
    },
    {
      "name": "Z509",
      "description": "Care involving use of rehabilitation procedure, unspecified"
    },
    {
      "name": "Z510",
      "description": "Radiotherapy session"
    },
    {
      "name": "Z511",
      "description": "Chemotherapy session for neoplasm"
    },
    {
      "name": "Z512",
      "description": "Other chemotherapy"
    },
    {
      "name": "Z513",
      "description": "Blood transfusion (without reported diagnosis)"
    },
    {
      "name": "Z514",
      "description": "Preparatory care for subsequent treatment, not elsewhere classified"
    },
    {
      "name": "Z515",
      "description": "Palliative care"
    },
    {
      "name": "Z516",
      "description": "Desensitization to allergens"
    },
    {
      "name": "Z518",
      "description": "Other specified medical care"
    },
    {
      "name": "Z519",
      "description": "Medical care, unspecified"
    },
    {
      "name": "Z520",
      "description": "Blood donor"
    },
    {
      "name": "Z521",
      "description": "Skin donor"
    },
    {
      "name": "Z522",
      "description": "Bone donor"
    },
    {
      "name": "Z523",
      "description": "Bone marrow dornor"
    },
    {
      "name": "Z524",
      "description": "Kidney dornor"
    },
    {
      "name": "Z525",
      "description": "Cornea dornor"
    },
    {
      "name": "Z526",
      "description": "Liver donor  "
    },
    {
      "name": "Z527",
      "description": "Heart donor  "
    },
    {
      "name": "Z528",
      "description": "Dornor of other organs and tissues"
    },
    {
      "name": "Z529",
      "description": "Dornor of unspecified organ or tissue"
    },
    {
      "name": "Z530",
      "description": "Procedure not carried out because of contraindication"
    },
    {
      "name": "Z531",
      "description": "Procedure not carried out because of patient's decision for reasons of belief or group pressure"
    },
    {
      "name": "Z532",
      "description": "Procedure not carried out because of patient's decision for other and unspecified reasons"
    },
    {
      "name": "Z538",
      "description": "Procedure not carried out for other reasons"
    },
    {
      "name": "Z539",
      "description": "Procedure not carried out for unspecified reason"
    },
    {
      "name": "Z540",
      "description": "Convalescence following surgery"
    },
    {
      "name": "Z541",
      "description": "Convalescence following radiotherapy"
    },
    {
      "name": "Z542",
      "description": "Convalescence following chemotherapy"
    },
    {
      "name": "Z543",
      "description": "Convalescence following psychotherapy"
    },
    {
      "name": "Z544",
      "description": "Convalescence following treatment of fracture"
    },
    {
      "name": "Z547",
      "description": "Convalescence following combined treatment"
    },
    {
      "name": "Z548",
      "description": "Convalescence following other treatment"
    },
    {
      "name": "Z549",
      "description": "Convalescence following unsepcified treatment"
    },
    {
      "name": "Z550",
      "description": "Illiteracy and low-level literacy"
    },
    {
      "name": "Z551",
      "description": "Schooling unavailable and unattainable"
    },
    {
      "name": "Z552",
      "description": "Failed examinations"
    },
    {
      "name": "Z553",
      "description": "Underachievement in school"
    },
    {
      "name": "Z554",
      "description": "Educational maladjustment and discord with teachers and classmates"
    },
    {
      "name": "Z558",
      "description": "Other problems related to education and literacy"
    },
    {
      "name": "Z559",
      "description": "Problem related to education and literacy, unspecified"
    },
    {
      "name": "Z560",
      "description": "Unemployment, unspecified"
    },
    {
      "name": "Z561",
      "description": "Change of job"
    },
    {
      "name": "Z562",
      "description": "Threat of job loss"
    },
    {
      "name": "Z563",
      "description": "Stressful work schedule"
    },
    {
      "name": "Z564",
      "description": "Discord with boss and workmates"
    },
    {
      "name": "Z565",
      "description": "Uncongenial work"
    },
    {
      "name": "Z566",
      "description": "Other physical and mental strain related to work"
    },
    {
      "name": "Z567",
      "description": "Other and unspecified problems related to employment"
    },
    {
      "name": "Z570",
      "description": "Occupational exposure to noise"
    },
    {
      "name": "Z571",
      "description": "Occupational exposure to radiation"
    },
    {
      "name": "Z572",
      "description": "Occupational exposure to dust"
    },
    {
      "name": "Z573",
      "description": "Occupational exposure to other air contaminatns"
    },
    {
      "name": "Z574",
      "description": "Occupational exposure to toxic agents in agriculture"
    },
    {
      "name": "Z575",
      "description": "Occupational exposure to toxic agents in other industries"
    },
    {
      "name": "Z576",
      "description": "Occupational exposure to exterme temperature"
    },
    {
      "name": "Z577",
      "description": "Occupational exposure to vibration"
    },
    {
      "name": "Z578",
      "description": "Occupational exposure to other risk-factors"
    },
    {
      "name": "Z579",
      "description": "Occupational exposure to unspecified risk-factor"
    },
    {
      "name": "Z580",
      "description": "Exposure to noise"
    },
    {
      "name": "Z581",
      "description": "Exposure to air pollution"
    },
    {
      "name": "Z582",
      "description": "Exposure to water pollution"
    },
    {
      "name": "Z583",
      "description": "Exposure to soil pollution"
    },
    {
      "name": "Z584",
      "description": "Exposure to radiation"
    },
    {
      "name": "Z585",
      "description": "Exposure to other pollution"
    },
    {
      "name": "Z586",
      "description": "Indequate drinking-water supply"
    },
    {
      "name": "Z587",
      "description": "Exposure to tobacco smoke"
    },
    {
      "name": "Z588",
      "description": "Other problems related to physical environment"
    },
    {
      "name": "Z589",
      "description": "Problem related to physical environment, unspecified"
    },
    {
      "name": "Z590",
      "description": "Homelessness"
    },
    {
      "name": "Z591",
      "description": "Inadequate housing"
    },
    {
      "name": "Z592",
      "description": "Discord with neighbours, lodgers and landlord"
    },
    {
      "name": "Z593",
      "description": "Problems related to living in residential institution"
    },
    {
      "name": "Z594",
      "description": "Lack of adequate food"
    },
    {
      "name": "Z595",
      "description": "Extreme poverty"
    },
    {
      "name": "Z596",
      "description": "Low income"
    },
    {
      "name": "Z597",
      "description": "Insufficient social insurance and welfare support"
    },
    {
      "name": "Z598",
      "description": "Other problems related to housing and economic circumstances"
    },
    {
      "name": "Z599",
      "description": "Problems related to housing and economic circumstances, unspecified"
    },
    {
      "name": "Z600",
      "description": "Problems of adjustment to life-cycle transitions"
    },
    {
      "name": "Z601",
      "description": "Atypical parenting situation"
    },
    {
      "name": "Z602",
      "description": "Living alone"
    },
    {
      "name": "Z603",
      "description": "Acculturation difficulty"
    },
    {
      "name": "Z604",
      "description": "Social exclusion and rejection"
    },
    {
      "name": "Z605",
      "description": "Target of perceived adverse discrimination and presecution"
    },
    {
      "name": "Z608",
      "description": "Other problems related to social environment"
    },
    {
      "name": "Z609",
      "description": "Problem related to social environment, unspecified"
    },
    {
      "name": "Z610",
      "description": "Loss of love relationship in childhood"
    },
    {
      "name": "Z611",
      "description": "Removal from home in childhood"
    },
    {
      "name": "Z612",
      "description": "Altered pattern of family relationships in childhood"
    },
    {
      "name": "Z613",
      "description": "Events resulting in loos of self-esteem in childhood"
    },
    {
      "name": "Z614",
      "description": "Problems related to alleged sexual abuse of child by person within primary support group"
    },
    {
      "name": "Z615",
      "description": "Problems related to alleged sexual abuse of child by person outside primary support group"
    },
    {
      "name": "Z616",
      "description": "Problems related to alleged physical abuse of child"
    },
    {
      "name": "Z617",
      "description": "Personal frightening experience in childhood"
    },
    {
      "name": "Z618",
      "description": "Other negative life events in childhood"
    },
    {
      "name": "Z619",
      "description": "Negative life events in chilhood, unspecified"
    },
    {
      "name": "Z620",
      "description": "Inadequate parental supervision and control"
    },
    {
      "name": "Z621",
      "description": "Parental overprotection"
    },
    {
      "name": "Z622",
      "description": "Institutional upbringing"
    },
    {
      "name": "Z623",
      "description": "Hostility towards and scapegoating of child"
    },
    {
      "name": "Z624",
      "description": "Emotional neglect of child"
    },
    {
      "name": "Z625",
      "description": "Other problems related to neglect in upbringing"
    },
    {
      "name": "Z626",
      "description": "Inappropriate parental pressure and other abnormal qualities of upbringing"
    },
    {
      "name": "Z628",
      "description": "Other specified problems related to upbringing"
    },
    {
      "name": "Z629",
      "description": "Problem related to upbringing, unspecified"
    },
    {
      "name": "Z630",
      "description": "Problems in relationship wiht spouse or partner"
    },
    {
      "name": "Z631",
      "description": "Problems in relationship wiht parents and in-laws"
    },
    {
      "name": "Z632",
      "description": "Inadequate family support"
    },
    {
      "name": "Z633",
      "description": "Absence of family member"
    },
    {
      "name": "Z634",
      "description": "Disapperance and death of family member"
    },
    {
      "name": "Z635",
      "description": "Disruption of family by separation and divorce"
    },
    {
      "name": "Z636",
      "description": "Dependent relative needing care at home"
    },
    {
      "name": "Z637",
      "description": "Other stressful life events affecting family and household"
    },
    {
      "name": "Z638",
      "description": "Other specified problems related to primary support group"
    },
    {
      "name": "Z639",
      "description": "Problems related to primary support group, unspecified"
    },
    {
      "name": "Z640",
      "description": "Problems related to unwanted pregnancy"
    },
    {
      "name": "Z641",
      "description": "Problems related to multiparity"
    },
    {
      "name": "Z642",
      "description": "Seeking and accepting physical, nutritional and chemical interventions know to be hazardous and harmful"
    },
    {
      "name": "Z643",
      "description": "Seeking and accepting behavioural and psychological interventions know to be hazardous and harmful"
    },
    {
      "name": "Z644",
      "description": "Discord with counsellors"
    },
    {
      "name": "Z650",
      "description": "Conviction in civil and criminal proceedings without imprisonment"
    },
    {
      "name": "Z651",
      "description": "Imprisonment and other incarceration"
    },
    {
      "name": "Z652",
      "description": "Problems related to release from prison"
    },
    {
      "name": "Z653",
      "description": "Problems related to other legal circumstances"
    },
    {
      "name": "Z654",
      "description": "Victim of crime and terrorism"
    },
    {
      "name": "Z655",
      "description": "Exposure to disaster, war and other hostilities"
    },
    {
      "name": "Z658",
      "description": "Other specified problems related to psychosocial circumstances"
    },
    {
      "name": "Z659",
      "description": "Problem related to unspecified psychosocial circumstances"
    },
    {
      "name": "Z700",
      "description": "Counselling related to sexual attitude"
    },
    {
      "name": "Z701",
      "description": "Counselling related to patient's sexual behaviour and orientation"
    },
    {
      "name": "Z702",
      "description": "Counselling related to sexual behaviour and orientation of third party"
    },
    {
      "name": "Z703",
      "description": "Counselling related to combined concerns regarding sexual attitude, behaviour and orientation"
    },
    {
      "name": "Z708",
      "description": "Other sex counselling"
    },
    {
      "name": "Z709",
      "description": "Sex counselling, unspecified"
    },
    {
      "name": "Z710",
      "description": "Person consulting on behalf of another person"
    },
    {
      "name": "Z711",
      "description": "Person with feared complaint in whom no diagnosis is made"
    },
    {
      "name": "Z712",
      "description": "Person consulting for explanation of investigation findings"
    },
    {
      "name": "Z713",
      "description": "Dietary counselling and surveillance"
    },
    {
      "name": "Z714",
      "description": "Alcohol abuse counselling and surveillance"
    },
    {
      "name": "Z715",
      "description": "Drug abuse counselling and surveillance"
    },
    {
      "name": "Z716",
      "description": "Tobacco abuse counselling"
    },
    {
      "name": "Z717",
      "description": "Human immunodeficiency virus [HIV] counselling"
    },
    {
      "name": "Z718",
      "description": "Other specified counselling"
    },
    {
      "name": "Z719",
      "description": "Counselling, unspecified"
    },
    {
      "name": "Z720",
      "description": "Tobacco use"
    },
    {
      "name": "Z721",
      "description": "Alcohol use"
    },
    {
      "name": "Z722",
      "description": "Drug use"
    },
    {
      "name": "Z723",
      "description": "Lack of physical exercise"
    },
    {
      "name": "Z724",
      "description": "Inappropriate diet and eating habits"
    },
    {
      "name": "Z725",
      "description": "High-risk sexual behaviour"
    },
    {
      "name": "Z726",
      "description": "Gambling and betting"
    },
    {
      "name": "Z728",
      "description": "Other problems related to lifestyle"
    },
    {
      "name": "Z729",
      "description": "Problems related to lifestyle, unspecified"
    },
    {
      "name": "Z730",
      "description": "Burn-out"
    },
    {
      "name": "Z731",
      "description": "Accentutation of personality traits"
    },
    {
      "name": "Z732",
      "description": "Lack of relaxation and leisure"
    },
    {
      "name": "Z733",
      "description": "Stress, not elsewhere classified"
    },
    {
      "name": "Z734",
      "description": "Inadequate social skills, not elsewhere classified"
    },
    {
      "name": "Z735",
      "description": "Social role conflict, not elsewhere classified"
    },
    {
      "name": "Z736",
      "description": "Limitation of activites due to disability"
    },
    {
      "name": "Z738",
      "description": "Other problems related to life-management difficulty"
    },
    {
      "name": "Z739",
      "description": "Problem related to life-management difficulty, unspecified"
    },
    {
      "name": "Z740",
      "description": "Need for assistance due to reduced mobility"
    },
    {
      "name": "Z741",
      "description": "Need for assistance with personal care"
    },
    {
      "name": "Z742",
      "description": "Need for assistance at home and no other household member able to render care"
    },
    {
      "name": "Z743",
      "description": "Need for continuous supervision"
    },
    {
      "name": "Z748",
      "description": "Other problems related to care-provider dependency"
    },
    {
      "name": "Z749",
      "description": "Problems related to care-provider dependency, unspecified"
    },
    {
      "name": "Z750",
      "description": "Medical services not available in home"
    },
    {
      "name": "Z751",
      "description": "Person awaiting admission to adequate facility elsewhere"
    },
    {
      "name": "Z752",
      "description": "Other waiting period for investigation and treatment"
    },
    {
      "name": "Z753",
      "description": "Unavailability and inaccessibility of health-care facilities"
    },
    {
      "name": "Z754",
      "description": "Unavailability and inaccessibility of other helping agencies"
    },
    {
      "name": "Z755",
      "description": "Holiday relief care"
    },
    {
      "name": "Z758",
      "description": "Other problems related to medical facilities and other health care"
    },
    {
      "name": "Z759",
      "description": "Unspecified  problem related to medical facilities and other health care"
    },
    {
      "name": "Z760",
      "description": "Issue of repeat prescription"
    },
    {
      "name": "Z761",
      "description": "Health supervision and care of foundling"
    },
    {
      "name": "Z762",
      "description": "Health supervision and care of other healthy infant and child"
    },
    {
      "name": "Z763",
      "description": "Health person accompanying sick person"
    },
    {
      "name": "Z764",
      "description": "Other boarder in health-care facility"
    },
    {
      "name": "Z765",
      "description": "Malingerer [conscious simulation]"
    },
    {
      "name": "Z768",
      "description": "Persons encountering health services in other specified circumstances"
    },
    {
      "name": "Z769",
      "description": "Person encountering health services in unspecified circumstances"
    },
    {
      "name": "Z800",
      "description": "Family history of malignant neoplasm of digestive organs"
    },
    {
      "name": "Z801",
      "description": "Family history of malignant neoplasm of trachea, bronchus and lung"
    },
    {
      "name": "Z802",
      "description": "Family history of malignant neoplasm of other respiratory and intrathoracic organs"
    },
    {
      "name": "Z803",
      "description": "Family history of malignant neoplasm of breast"
    },
    {
      "name": "Z804",
      "description": "Family history of malignant neoplasm of genital organs"
    },
    {
      "name": "Z805",
      "description": "Family history of malignant neoplasm of urinary tract"
    },
    {
      "name": "Z806",
      "description": "Family history of leukaemia"
    },
    {
      "name": "Z807",
      "description": "Family history of other malignant neoplasms of lymphoid, haematopoietic and related tissues"
    },
    {
      "name": "Z808",
      "description": "Family history of malignant neoplasm of other organs or systems"
    },
    {
      "name": "Z809",
      "description": "Family history of malignant neoplasm, unspecified"
    },
    {
      "name": "Z810",
      "description": "Family history of mental retardation"
    },
    {
      "name": "Z811",
      "description": "Family history of alcohol abuse"
    },
    {
      "name": "Z812",
      "description": "Family history of tobacco abuse"
    },
    {
      "name": "Z813",
      "description": "Family history of other psychoactive substance abuse"
    },
    {
      "name": "Z814",
      "description": "Family history of other substance abuse"
    },
    {
      "name": "Z818",
      "description": "Family history of othe mental and behavioural disorders"
    },
    {
      "name": "Z820",
      "description": "Family history of epilepsy and other diseases of the nervous system"
    },
    {
      "name": "Z821",
      "description": "Family history of blindness and visual loss"
    },
    {
      "name": "Z822",
      "description": "Family history of deafness and hearing loss"
    },
    {
      "name": "Z823",
      "description": "Family history of stroke"
    },
    {
      "name": "Z824",
      "description": "Family history of ischaemic heart disease and other diseases of the circulatory system"
    },
    {
      "name": "Z825",
      "description": "Family history of asthma and other chronic lower respiratory diseases"
    },
    {
      "name": "Z826",
      "description": "Family history of arthritis and other diseases of the musculoskeletal system and connective tissue"
    },
    {
      "name": "Z827",
      "description": "Family history of congenital malformations  deformations and chromosomal abnormalities"
    },
    {
      "name": "Z828",
      "description": "Family history of other disabilities and chronic diseases leading to disablement, not elsewhere classified"
    },
    {
      "name": "Z830",
      "description": "Family history of human immunodeficiency virus [HIV] disease"
    },
    {
      "name": "Z831",
      "description": "Family history of other infectious and parasitic diseases"
    },
    {
      "name": "Z832",
      "description": "Family history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism"
    },
    {
      "name": "Z833",
      "description": "Family history of diabetes mellitus"
    },
    {
      "name": "Z834",
      "description": "Family history of other endocrine, nutritional and metabolic diseases"
    },
    {
      "name": "Z835",
      "description": "Family history of eye and ear disorders"
    },
    {
      "name": "Z836",
      "description": "Family history of diseases of the respiratory system"
    },
    {
      "name": "Z837",
      "description": "Family history of diseases of the digestive system"
    },
    {
      "name": "Z840",
      "description": "Family history of diseases of the skin and subcutaneous tissue"
    },
    {
      "name": "Z841",
      "description": "Family history of disorders of kidney and ureter"
    },
    {
      "name": "Z842",
      "description": "Family history of other diseases of the genitourinary system"
    },
    {
      "name": "Z843",
      "description": "Family history of consanguinity"
    },
    {
      "name": "Z848",
      "description": "Family history of other specified conditions"
    },
    {
      "name": "Z850",
      "description": "Personal history of malignant neoplasm of digestive organs"
    },
    {
      "name": "Z851",
      "description": "Personal history of malignant neoplasm of trachea, bronchus and lung"
    },
    {
      "name": "Z852",
      "description": "Personal history of malignant neoplasm of other respiratory and intrathoracic organs"
    },
    {
      "name": "Z853",
      "description": "Personal history of malignant neoplasm of breast"
    },
    {
      "name": "Z854",
      "description": "Personal history of malignant neoplasm of genital organs"
    },
    {
      "name": "Z855",
      "description": "Personal history of malignant neoplasm of urinary tract"
    },
    {
      "name": "Z856",
      "description": "Personal history of leukaemia"
    },
    {
      "name": "Z857",
      "description": "Personal history of other malignant neoplasm of lymphoid, haematopoietic and related tissues"
    },
    {
      "name": "Z858",
      "description": "Personal history of malignant neoplasm of other organs and systems"
    },
    {
      "name": "Z859",
      "description": "Personal history of malignant neoplasm, unspecified"
    },
    {
      "name": "Z860",
      "description": "Personal history of other neoplasms"
    },
    {
      "name": "Z861",
      "description": "Personal history of infections and parasitic diseases"
    },
    {
      "name": "Z862",
      "description": "Personal history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism"
    },
    {
      "name": "Z863",
      "description": "Personal history of endocrine, nutritional and metabolic diseases"
    },
    {
      "name": "Z864",
      "description": "Personal history of psychoactive substance abuse"
    },
    {
      "name": "Z865",
      "description": "Personal history of other mental and behavioural disorders"
    },
    {
      "name": "Z866",
      "description": "Personal history of diseases of the nervous system and sense organs"
    },
    {
      "name": "Z867",
      "description": "Personal history of diseases of the circulatory system"
    },
    {
      "name": "Z870",
      "description": "Personal history of diseases of the respiratory system"
    },
    {
      "name": "Z871",
      "description": "Personal history of diseases of the digestive system"
    },
    {
      "name": "Z872",
      "description": "Personal history of diseases of the skin and subcutaneous tissue"
    },
    {
      "name": "Z873",
      "description": "Personal history of diseases of the musculoskeletal system and connective tissue"
    },
    {
      "name": "Z874",
      "description": "Personal history of diseases of the genitourinary system"
    },
    {
      "name": "Z875",
      "description": "Personal history of complications of pergnancy, childbirth and the puerperium"
    },
    {
      "name": "Z876",
      "description": "Personal history of certain conditions arising in the perinatal period"
    },
    {
      "name": "Z877",
      "description": "Personal history of congenital malformations, deformations and chromosomal abnormalities"
    },
    {
      "name": "Z878",
      "description": "Personal history of other specified conditions"
    },
    {
      "name": "Z880",
      "description": "Personal history of allergy to penicillin"
    },
    {
      "name": "Z881",
      "description": "Personal history of allergy to other antibiotic agents"
    },
    {
      "name": "Z882",
      "description": "Personal history of allergy to sulfonamides"
    },
    {
      "name": "Z883",
      "description": "Personal history of allergy to other anti-infective agents"
    },
    {
      "name": "Z884",
      "description": "Personal history of allergy to anaesthetic agent"
    },
    {
      "name": "Z885",
      "description": "Personal history of allergy to narcotic agent"
    },
    {
      "name": "Z886",
      "description": "Personal history of allergy to analgesic agent"
    },
    {
      "name": "Z887",
      "description": "Personal history of allergy to serum and vaccine"
    },
    {
      "name": "Z888",
      "description": "Personal history of allergy to other drugs, medicaments and biological substances"
    },
    {
      "name": "Z889",
      "description": "Personal history of allergy to unspecified drugs, medicaments and biological substances"
    },
    {
      "name": "Z890",
      "description": "Acquired absence of finger(s) [including thumb], unilateral"
    },
    {
      "name": "Z891",
      "description": "Acquired absence of hand and wrist"
    },
    {
      "name": "Z8920",
      "description": "Loss of upper limb, forequarter, postoperative or acquired"
    },
    {
      "name": "Z8921",
      "description": "Loss of upper limb, shoulder disarticulation, postoperative or acquired"
    },
    {
      "name": "Z8922",
      "description": "Loss of upper limb above elbow (AE), postoperative or acquired"
    },
    {
      "name": "Z8923",
      "description": "Loss of upper limb, elbow disarticulation, postoperative or acquired"
    },
    {
      "name": "Z8924",
      "description": "Loss of upper limb below elbow (BE), postoperative or acquired"
    },
    {
      "name": "Z8929",
      "description": "Loss of upper limb above wrist, unspecified, postoperative or acquired"
    },
    {
      "name": "Z893",
      "description": "Acquired absence of both upper limbs [any level]"
    },
    {
      "name": "Z8941",
      "description": "Loss of ankle, ankle disarticulation, postoperative or acquired"
    },
    {
      "name": "Z8942",
      "description": "Loss of tarsal, Syme amputation, postoperative or acquired"
    },
    {
      "name": "Z8943",
      "description": "Loss of tarsal, Boyd amputation, postoperative or acquired"
    },
    {
      "name": "Z8944",
      "description": "Loss of tarsal, Pirogoff amputation,  postoperative or acquired"
    },
    {
      "name": "Z8945",
      "description": "Loss of foot, postoperative or acquired"
    },
    {
      "name": "Z8946",
      "description": "Loss of great toe,postoperative or acquired"
    },
    {
      "name": "Z8947",
      "description": "Loss of other toe(s), postoperative or acquired"
    },
    {
      "name": "Z8951",
      "description": "Loss of leg, knee disarticulation, postoperative or acquired"
    },
    {
      "name": "Z8952",
      "description": "Loss of leg below knee (BK), postoperative or acquired"
    },
    {
      "name": "Z8961",
      "description": "Loss of leg above knee, hemipelvictomy,postoperative or acquired"
    },
    {
      "name": "Z8962",
      "description": "Loss of leg above knee, hindquarter, postoperative or acquired"
    },
    {
      "name": "Z8963",
      "description": "Loss of leg above knee, hip disarticulation, postoperative or acquired"
    },
    {
      "name": "Z8964",
      "description": "Loss of leg above knee, AK amputation,postoperative or acquired"
    },
    {
      "name": "Z897",
      "description": "Acquired absence of both lower limbs [any level, except toes alone]"
    },
    {
      "name": "Z898",
      "description": "Acquired absence of upper and lower limbs [any level]"
    },
    {
      "name": "Z899",
      "description": "Acquired absence of limb, unspecified"
    },
    {
      "name": "Z900",
      "description": "Acquired absence of part of head and neck"
    },
    {
      "name": "Z901",
      "description": "Acquired absence of breast(s)"
    },
    {
      "name": "Z902",
      "description": "Acquired absence of lung [part of]"
    },
    {
      "name": "Z903",
      "description": "Acquired absence of part of stomach"
    },
    {
      "name": "Z904",
      "description": "Acquired absence of other parts of digestive tract"
    },
    {
      "name": "Z905",
      "description": "Acquired absence of kidney"
    },
    {
      "name": "Z906",
      "description": "Acquired absence of other parts of urinary tract"
    },
    {
      "name": "Z907",
      "description": "Acquired absence of genital organ(s)"
    },
    {
      "name": "Z908",
      "description": "Acquired absence of other organs"
    },
    {
      "name": "Z910",
      "description": "Personal history of allergy, other than to drugs and biological substances"
    },
    {
      "name": "Z911",
      "description": "Personal history of noncompliance with medical treatment and regimen"
    },
    {
      "name": "Z912",
      "description": "Personal history of poor personal hygiene"
    },
    {
      "name": "Z913",
      "description": "Personal history of unhealthy sleep-wake schedule\n"
    },
    {
      "name": "Z914",
      "description": "Personal history of psychological trauma, not elsewhere classified"
    },
    {
      "name": "Z915",
      "description": "Personal history of self-harm"
    },
    {
      "name": "Z916",
      "description": "Personal history of other physical trauma"
    },
    {
      "name": "Z917",
      "description": "Personal history of female genital mutilation"
    },
    {
      "name": "Z918",
      "description": "Personal history of other specified risk-factors, not elsewhere classified"
    },
    {
      "name": "Z920",
      "description": "Personal history of contraception"
    },
    {
      "name": "Z921",
      "description": "Personal history of long-term(current) use of anticoagulants"
    },
    {
      "name": "Z922",
      "description": "Personal history of long-term (current) use of other medicaments"
    },
    {
      "name": "Z923",
      "description": "Personal history of irradiation"
    },
    {
      "name": "Z924",
      "description": "Personal history of major surgery, not elsewhere classified"
    },
    {
      "name": "Z925",
      "description": "Personal history of rehabilitation measures"
    },
    {
      "name": "Z926",
      "description": "Personal history of chemotherapy for neoplastic disease"
    },
    {
      "name": "Z928",
      "description": "Personal history of other medical treatment"
    },
    {
      "name": "Z929",
      "description": "Personal history of medical treatment, unspecified"
    },
    {
      "name": "Z930",
      "description": "Tracheostomy status"
    },
    {
      "name": "Z931",
      "description": "Gastrostomy status"
    },
    {
      "name": "Z932",
      "description": "lleostomy status"
    },
    {
      "name": "Z933",
      "description": "Colostomy status"
    },
    {
      "name": "Z934",
      "description": "Other artificial openings of gastrointestinal tract status"
    },
    {
      "name": "Z935",
      "description": "Cystostomy status"
    },
    {
      "name": "Z936",
      "description": "Other artificial openings of urinary tract status"
    },
    {
      "name": "Z938",
      "description": "Other artificial opening status"
    },
    {
      "name": "Z939",
      "description": "Artificial opening status, unspecified"
    },
    {
      "name": "Z940",
      "description": "Kidney transplant status"
    },
    {
      "name": "Z941",
      "description": "Heart transplant status"
    },
    {
      "name": "Z942",
      "description": "Lung transplant status"
    },
    {
      "name": "Z943",
      "description": "Heart and lungs transplant status"
    },
    {
      "name": "Z944",
      "description": "Liver transplant status"
    },
    {
      "name": "Z945",
      "description": "Skin transplant status"
    },
    {
      "name": "Z946",
      "description": "Bone transplant status"
    },
    {
      "name": "Z947",
      "description": "Corneal transplant status"
    },
    {
      "name": "Z948",
      "description": "Other transplanted organ and tissue status"
    },
    {
      "name": "Z949",
      "description": "Transplanted  organ and tissue status, unspecified"
    },
    {
      "name": "Z950",
      "description": "Presence of electronic cardiac devices"
    },
    {
      "name": "Z951",
      "description": "Presence of aortocoronary bypass graft"
    },
    {
      "name": "Z952",
      "description": "Presence of prosthetic heart valve"
    },
    {
      "name": "Z953",
      "description": "Presence of xenogenic heart valve"
    },
    {
      "name": "Z954",
      "description": "Presence of other heart-valve replacement"
    },
    {
      "name": "Z955",
      "description": "Presence of coronary angioplasty implant and graft"
    },
    {
      "name": "Z958",
      "description": "Presence of other cardiac and vascular implants and grafts"
    },
    {
      "name": "Z959",
      "description": "Presence of cardiac and vascular implant and graft, unspecified"
    },
    {
      "name": "Z960",
      "description": "Presence of urogenital implants"
    },
    {
      "name": "Z961",
      "description": "Presence of intraocular lens"
    },
    {
      "name": "Z962",
      "description": "Presence of otological and audiological implants"
    },
    {
      "name": "Z963",
      "description": "Presence of artificial larynx"
    },
    {
      "name": "Z964",
      "description": "Presence of endocrine implants"
    },
    {
      "name": "Z965",
      "description": "Presence of tooth-root and mandibular implants"
    },
    {
      "name": "Z9660",
      "description": "Presence of shoulder joint prosthesis"
    },
    {
      "name": "Z9661",
      "description": "Presence of elbow joint prosthesis"
    },
    {
      "name": "Z9662",
      "description": "Presence of wrist joint prosthesis"
    },
    {
      "name": "Z9663",
      "description": "Presence of prosthesis of joint of hand"
    },
    {
      "name": "Z9664",
      "description": "Presence of hip joint prosthesis"
    },
    {
      "name": "Z9665",
      "description": "Presence of knee joint prosthesis"
    },
    {
      "name": "Z9666",
      "description": "Presence of ankle joint prosthesis"
    },
    {
      "name": "Z9668",
      "description": "Presence of other orthopaedic joint prosthesis"
    },
    {
      "name": "Z9669",
      "description": "Presence of orthopaedic joint prosthesis , unspecified"
    },
    {
      "name": "Z967",
      "description": "Presence of other bone and tendon implants"
    },
    {
      "name": "Z968",
      "description": "Presence of other specified functional implants"
    },
    {
      "name": "Z969",
      "description": "Presence of functional implant, unspecified"
    },
    {
      "name": "Z970",
      "description": "Presence of artificial eye"
    },
    {
      "name": "Z971",
      "description": "Presence of artificial limb (complete)(partial)"
    },
    {
      "name": "Z972",
      "description": "Presence of dental prosthetic device (complete)(partial)"
    },
    {
      "name": "Z973",
      "description": "Presence of spectacles and contact lenses"
    },
    {
      "name": "Z974",
      "description": "Presence of external hearing-aid"
    },
    {
      "name": "Z975",
      "description": "Presence of (intrauterine) contraceptive device"
    },
    {
      "name": "Z978",
      "description": "Presence of other specified devices"
    },
    {
      "name": "Z980",
      "description": "Intestinal bypass and anastomosis status"
    },
    {
      "name": "Z981",
      "description": "Arthrodesis status"
    },
    {
      "name": "Z982",
      "description": "Presence of cerebrospinal fluid drainage device"
    },
    {
      "name": "Z988",
      "description": "Other specified postsurgical states"
    },
    {
      "name": "Z990",
      "description": "Dependence on aspirator"
    },
    {
      "name": "Z991",
      "description": "Dependence on respirator"
    },
    {
      "name": "Z992",
      "description": "Dependence on renal dialysis"
    },
    {
      "name": "Z993",
      "description": "Dependence on wheelchair"
    },
    {
      "name": "Z994",
      "description": "Dependence on artificial heart"
    },
    {
      "name": "Z998",
      "description": "Dependence on other enabling machines and devices"
    },
    {
      "name": "Z999",
      "description": "Dependence on unspecified enabling machine and device"
    }
   ]