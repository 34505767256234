import axios from 'axios';
import config from 'react-global-configuration';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, hospCode, hospmain, category, startDate, endDate, page, asProvider } = dataRequest;

      const patients = await axios.get(`${config.get('ROOT_API')}/v1/billing/patientvisitswithprices`, {
        headers: { Authorization: `Bearer ${idToken}` },
        params: {
          hospCode: asProvider ? hospCode : hospmain,
          hospmain: asProvider ? hospmain : hospCode,
          category,
          startDate,
          endDate,
          page,
        },
      });

      return resolve({
        billlingPatients: patients.data.result,
        FetchingStatus: false,
      });
    }, 200);
  });
};
