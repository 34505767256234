import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import orm from '../../models/index';

//actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefersAccept } from '../../actions/ReferAccept';
import { fetchRefersReject } from '../../actions/ReferReject';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';
import { fetchReferNotificationsRead } from '../../actions/ReferNotificationsRead';
import { contactDoctor, nurseRead } from '../../actions/Refers/ReferIn';
import { referrals } from '../../actions/Refers/Referrals';
import { fetchPttype } from '../../actions/Refers/ReferForm';

//react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//functions
import { ThaiDateTime, ThaiDate } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';
import {
  valueToTextAcuityLevel,
  valueToTextDepartment,
  valueToTextHospType,
  valueToTextSendReason,
  valueToTextPriority,
} from '../../functions/ReferForm';

// components
import ReferAcceptDialog from '../Utility/ReferAcceptDialog';
import ReferRejectDialog from '../Utility/ReferRejectDialog';

// assets
import { printAppointment } from '../../assets/pdf/printAppointment';

// constants
import { PENDING, RECORDED_BY_DEST } from '../../constants/Refers/referralStatus';

//mui
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CardActions } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

const session = orm.session();

function VisitsRefer({
  bid,
  appData,
  appProperty,
  fetchRefersAccept,
  fetchRefersReject,
  fetchPatientsVisitsDetailsSet,
  fetchReferNotificationsRead,
}) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [RefreshRefer, setRefreshRefer] = useState(false);
  const [DialogAcceptOpen, setDialogAcceptOpen] = useState(false);
  const [DialogRejectOpen, setDialogRejectOpen] = useState(false);
  const [contactDate, setContactDate] = useState(new Date());
  const [contactTime, setContactTime] = useState(new Date());
  const [selectedReferral, setSelectedReferral] = useState({});
  const [contactOpen, setContactOpen] = useState(false);
  const [reason, setReason] = useState([]);
  const [cid, setCid] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    handleFetchPttype({ hospCode: '11535', idToken: appData.idToken });
    setCid(appData?.emrData?.patients?.bid);
  }, []);

  useEffect(() => {
    cid &&
      handleFetchReferrals({
        idToken: appData.idToken,
        cid,
      });
  }, [cid, appData?.emrData]);

  useEffect(() => {
    if (bid !== bidData && !RefreshRefer) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
    if (RefreshRefer) {
      setRefreshReferFc(false);
      setTimeout(() => {
        setvisitDataFc(bid);
      }, 500);
    }
  });

  useEffect(() => {
    appData.referrals.length > 0 && setvisitData({ visitsData: [{ referrals: appData.referrals }] });
  }, [appData.referrals]);

  useEffect(() => {
    if (visitData && visitData.visitsData && visitData.visitsData.length > 0) {
      const unReadReferrals = visitData.visitsData[0].referrals?.filter(referral => !('readDateTime' in referral));

      unReadReferrals.length > 0 && nurseReadTimeStamp(bidData);
    }
  }, [bidData]);

  const nurseReadTimeStamp = async visitBid => {
    const data = {
      visitBid,
      idToken: appData.idToken,
      dateTime: new Date(),
    };

    await nurseRead(data);
  };

  const onReasonChange = (e, id) => {
    setReason({
      [id]: e.target.value,
    });
  };

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setRefreshReferFc = value => {
    setRefreshRefer(value);
  };

  const handleContactModal = referItems => {
    setSelectedReferral(referItems);
    setContactDate(new Date());
    setContactTime(new Date());
    setContactOpen(true);
  };

  const setvisitDataFc = async bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    if (visits) {
      for (var val of visits) {
        session.Visits.upsert(val);
      }
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    if (visitsData.length > 0) {
      for (let val2 of visitsData[0].referrals) {
        if (val2) {
          NotifyRead(val2.bid);
        }
      }
    }
  };

  const handleFetchReferrals = ({ idToken, cid }) => {
    dispatch(
      referrals({
        idToken,
        cid,
      })
    );
  };

  const NotifyRead = bid => {
    const { update, getByIndex } = useIndexedDB('notifications');

    if (bid) {
      let userId =
        appData.loginData.username.substring(4, 5) +
        appData.loginData.username.substring(2, 3) +
        appData.loginData.username.substring(1, 2) +
        appData.loginData.username.substring(5, 6) +
        appData.loginData.username.substring(3, 4) +
        appData.loginData.username.substring(6, 7) +
        appData.loginData.username.substring(7, 8) +
        appData.loginData.username.substring(10, 11) +
        appData.loginData.username.substring(9, 10) +
        appData.loginData.username.substring(8, 9) +
        appData.loginData.username.substring(0, 1);

      let keyId = bid + userId;

      getByIndex('keyId', keyId).then(NotifyFromDB => {
        if (NotifyFromDB) {
          update({
            id: NotifyFromDB.id,
            keyId: NotifyFromDB.keyId,
            key1: NotifyFromDB.key1,
            key2: NotifyFromDB.key2,
            notifyDateTime: NotifyFromDB.notifyDateTime,
            notifyType: NotifyFromDB.notifyType,
            notifyLink: NotifyFromDB.notifyLink,
            datas: NotifyFromDB.datas,
            notifyRead: true,
          });

          if (NotifyFromDB.notifyRead === false) {
            let dataRequest = {
              idToken: appData.idToken,
              bid: bid,
              hospCode: appData.loginData.hospCode,
            };

            fetchReferNotificationsRead(dataRequest);
          }
        }
      });
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl({
      [id]: event.currentTarget,
    });
  };

  const handleClose = id => {
    setAnchorEl({
      [id]: null,
    });
  };

  const DialogAcceptOpenFc = id => {
    setDialogAcceptOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogAcceptCloseFc = id => {
    setDialogAcceptOpen({
      [id]: false,
    });
  };

  const DialogRejectOpenFc = id => {
    setDialogRejectOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogRejectCloseFc = id => {
    setDialogRejectOpen({
      [id]: false,
    });
  };

  const ReferAcceptSubmit = (appointmentData, id, bid, cid, visitBid) => {
    if (
      !appointmentData?.reason ||
      appointmentData?.reason === '' ||
      appointmentData?.reason === undefined ||
      appointmentData?.reason === null
    ) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการตอบรับ !');
    } else {
      if (bid) {
        let dataRequest = {
          ...appointmentData,
          idToken: appData.idToken,
          bid: bid,
        };

        let res = fetchRefersAccept(dataRequest);

        res.then(
          function (v) {
            // ccccc
            RefreshPtData();
            DialogAcceptCloseFc(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  };

  const ReferRejectSubmit = (id, bid, cid, visitBid) => {
    if (!reason[id] || reason[id] === '' || reason[id] === undefined || reason[id] === null) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !');
    } else {
      if (bid) {
        let dataRequest = {
          idToken: appData.idToken,
          bid: bid,
          reason: reason[id],
        };

        let res = fetchRefersReject(dataRequest);

        res.then(
          function (v) {
            // ccccc
            RefreshPtData();
            DialogRejectCloseFc(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  };

  const RefreshPtData = () => {
    let dataRequest = {
      idToken: appData.idToken,
      cid,
      hn: '',
      hospCode: appData.loginData.hospCode,
      limit: appProperty.visitLimit,
    };

    let res = fetchPatientsVisitsDetailsSet(dataRequest);
    res.then(
      function (v) {
        setRefreshReferFc(true);
      },
      function (e) {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
        }
      }
    );
  };

  const handleContactDoctor = async () => {
    const data = {
      idToken: appData.idToken,
      bid: selectedReferral.bid,
      dateTime: dayjs(contactDate).format('YYYY/MM/DD') + ' : ' + dayjs(contactTime).format('HH:mm:ss'),
    };

    await contactDoctor(data);
    RefreshPtData();
    setContactOpen(false);
  };

  const viewAppointmentPdf = ({ referData, appData }) => {
    const ptName = `${appData?.emrData?.patients?.data?.fullname?.title || ''} ${
      appData?.emrData?.patients?.data?.fullname?.firstName || ''
    } ${appData?.emrData?.patients?.data?.fullname?.lastName || ''}`;
    const appointmentData = {
      cid: referData?.cid || appData?.emrData?.patients?.bid || appData?.emrData?.patients?.cid,
      hn: referData?.hn || appData?.emrData?.patients?.hn,
      ptName,
      doctorName: referData?.appointmentDoctorName,
      appointmentDate: ThaiDate(referData?.appointmentDateTimeStart),
      appointmentTime:
        dayjs(referData?.appointmentDateTimeStart)?.format('HH:mm') +
        ' - ' +
        dayjs(referData?.appointmentDateTimeEnd)?.format('HH:mm'),
      clinicName: referData?.appointmentClinicName,
      icd10: referData?.icd10?.toString(),
      pttypeName: appData?.pttypeList?.filter(item => item?.bid === referData?.pttype)[0]?.name,
      reason: referData?.receiveReason,
      userName: referData?.toHospName || referData?.toHospital?.hospName,
    };
    printAppointment(appointmentData);
  };

  const handleFetchPttype = ({ hospCode, idToken }) => {
    dispatch(fetchPttype({ hospCode, idToken }));
  };
  const dateFormat = date => {
    if (!date) {
      return '-';
    }
    const dateLocalString = new Date(date || '').toLocaleString('th-TH', {
      timeZone: 'UTC',
    });
    return dateLocalString;
  };
  return (
    <div>
      {visitData && (
        <div>
          {visitData?.visitsData?.map((items, index) => (
            <div key={index}>
              {items?.referrals
                ?.filter(referItems => {
                  if (referItems.visitBid) {
                    return referItems.visitBid === bid;
                  } else {
                    return referItems.bid === bid;
                  }
                })
                ?.map((referItems, refIndex) => (
                  <>
                    <Card
                      variant='outlined'
                      className={`${
                        (referItems?.status === '' ||
                          referItems?.status?.toUpperCase() === 'PENDING' ||
                          referItems?.status?.toUpperCase() === 'RECORDED_BY_DEST') &&
                        'margin-bottom-8px referrals-card'
                      } ${
                        referItems?.status?.toUpperCase() === 'REJECTED' && 'margin-bottom-8px referrals-card-rejected'
                      } ${
                        referItems?.status?.toUpperCase() === 'ACCEPTED' && 'margin-bottom-8px referrals-card-complete'
                      }`}
                      key={refIndex}
                    >
                      <CardHeader
                        className='card-header'
                        title={`Referral ID : ${referItems?.bid ? referItems?.bid : ''}`}
                        action={
                          <div>
                            {appData?.permission?.actionRefer &&
                              (referItems?.toHospCode === appData?.loginData?.hospCode &&
                              referItems?.status?.toUpperCase() === PENDING ? (
                                <div>
                                  <IconButton aria-label='settings' onClick={e => handleClick(e, refIndex)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    key={refIndex}
                                    id={'refer-action-menu' + refIndex}
                                    anchorEl={anchorEl[refIndex]}
                                    keepMounted
                                    open={Boolean(anchorEl[refIndex])}
                                    onClose={() => handleClose(refIndex)}
                                    className='refer-action-menu'
                                  >
                                    <MenuItem onClick={() => DialogAcceptOpenFc(refIndex)}>
                                      Accept (ยอมรับ Refer)
                                    </MenuItem>
                                    <MenuItem onClick={() => DialogRejectOpenFc(refIndex)}>
                                      Reject (ปฏิเสธ Refer)
                                    </MenuItem>
                                  </Menu>

                                  <ReferAcceptDialog
                                    open={Boolean(DialogAcceptOpen[refIndex])}
                                    cid={referItems?.cid}
                                    ptname={
                                      appData?.emrData?.patients?.data?.fullname?.title ||
                                      '' +
                                        ' ' +
                                        appData?.emrData?.patients?.data?.fullname?.firstName +
                                        ' ' +
                                        appData?.emrData?.patients?.data?.fullname?.lastName
                                    }
                                    hospName={referItems?.fromHospName}
                                    clinicName={referItems?.destinationClinic}
                                    appointmentDateTime={referItems?.appointmentDateTime}
                                    onClose={() => DialogAcceptCloseFc(refIndex)}
                                    onSubmit={appointmentData =>
                                      ReferAcceptSubmit(appointmentData, refIndex, referItems.bid, items.cid, items.bid)
                                    }
                                  />

                                  <ReferRejectDialog
                                    open={Boolean(DialogRejectOpen[refIndex])}
                                    cid={referItems?.cid}
                                    ptname={
                                      appData?.emrData?.patients?.data?.fullname?.title ||
                                      '' +
                                        ' ' +
                                        appData?.emrData?.patients?.data?.fullname?.firstName +
                                        ' ' +
                                        appData?.emrData?.patients?.data?.fullname?.lastName
                                    }
                                    hospName={referItems?.fromHospName}
                                    reason={reason[refIndex]}
                                    onClose={() => DialogRejectCloseFc(refIndex)}
                                    onChange={e => onReasonChange(e, refIndex)}
                                    onSubmit={() => ReferRejectSubmit(refIndex, referItems.bid, items.cid, items.bid)}
                                  />
                                </div>
                              ) : (
                                referItems?.status?.toUpperCase() === 'ACCEPTED' && (
                                  <IconButton onClick={e => viewAppointmentPdf({ referData: referItems, appData })}>
                                    <PrintIcon />
                                  </IconButton>
                                )
                              ))}
                          </div>
                        }
                      />
                      <CardContent className='font-size-14px refer-text-custom'>
                        <Row>
                          <Col lg={12}>
                            <h6>สถานะ : {referItems.status}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <h6>วันที่ส่งตัว : {ThaiDateTime(referItems.referDateTime)}</h6>
                          </Col>
                          <Col lg={6}>
                            <h6>วันหมดอายุ : {referItems.expireDateTime && ThaiDate(referItems.expireDateTime)} </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <h6>
                              รพ. ต้นทาง : {referItems.fromHospCode}{' '}
                              {shortHospName(referItems.fromHospName || referItems?.fromHospital?.hospName)}
                            </h6>
                          </Col>
                          <Col lg={6}>
                            <h6>
                              รพ. ปลายทาง : {referItems.toHospCode}{' '}
                              {shortHospName(referItems.toHospName || referItems?.toHospital?.hospName)}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>สิทธิการรักษา : {referItems.pttype || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>ICD10 : {referItems.icd10?.map(item => `${item} `)}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>การวินิจฉัยขั้นต้น : {referItems.prediagnosis || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>
                              เหตุผลการส่งต่อ :{' '}
                              {referItems.sendReason !== '99'
                                ? valueToTextSendReason(referItems.sendReason)
                                : referItems.sendReasonOther}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>ประเภทการส่งตัว : {referItems.referType || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>ประเภทโรงพยาบาล : {valueToTextHospType(referItems.hospType) || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>
                              สาขาที่ปรึกษา :{' '}
                              {referItems.department !== '74964007'
                                ? valueToTextDepartment(referItems.department)
                                : referItems.departmentOther}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>ระดับความเร่งด่วน : {valueToTextPriority(referItems.priority) || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>ความรุนแรงของผู้ป่วย : {valueToTextAcuityLevel(referItems.acuityLevel) || ''}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>รูปหนังสือรับผิดชอบค่าใช้จ่าย : </Col>
                          {referItems.attachments &&
                            referItems.attachments.length > 0 &&
                            referItems.attachments.map(
                              file =>
                                file && (
                                  <Col lg={12}>
                                    <Link
                                      href={`https://ipfs.vajira.evernetwork.io/ipfs/${file.ipfs_file_hash}`}
                                      target='_blank'
                                    >
                                      - {file.ipfs_file_name}
                                    </Link>
                                  </Col>
                                )
                            )}
                        </Row>
                        <Row>
                          <Col lg={12}>รูปเพิ่มเติมอื่น ๆ : </Col>
                          {referItems.letterAttachments &&
                            referItems.letterAttachments.length > 0 &&
                            referItems.letterAttachments.map(
                              file =>
                                file && (
                                  <Col lg={12}>
                                    <Link
                                      href={`https://ipfs.vajira.evernetwork.io/ipfs/${file.ipfs_file_hash}`}
                                      target='_blank'
                                    >
                                      - {file.ipfs_file_name}
                                    </Link>
                                  </Col>
                                )
                            )}
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <h6>หมายเหตุ : {referItems.note || '-'}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <h6>นัดหมาย : {dateFormat(referItems?.appointmentDateTimeStart)}</h6>
                            <h6>ชื่อคลินิก : {referItems?.appointmentClinicName || '-'}</h6>
                            <h6>แพทย์ : {referItems?.appointmentDoctorName || '-'}</h6>
                            <h6>วันที่ อนุมัติ/ปฏิเสธ : {dateFormat(referItems?.acceptRejectDateTime)}</h6>
                          </Col>
                          <Col lg={6}>
                            <h6>
                              นัดหมาย :
                              {referItems?.appointmentDateTime &&
                                referItems.appointmentDateTime.map(a => ThaiDateTime(a)).join(',')}
                            </h6>
                            <h6>ชื่อคลินิก : {referItems?.destinationClinic || '-'}</h6>
                          </Col>
                        </Row>
                        {referItems.requestText && (
                          <Row>
                            <Col lg={12}>
                              <ExpansionPanel className='MuiAppBar-color-custom '>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Typography>REQUEST TEXT :</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography variant='caption'>
                                    {referItems.requestText && (
                                      <div style={{ paddingLeft: '8px' }}>
                                        {referItems.requestText.split('\r').map((item2, index2) => (
                                          <div key={index2}>
                                            <h6>
                                              {index2 + 1}. {item2}
                                            </h6>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <hr />
                            </Col>
                          </Row>
                        )}

                        {referItems.otherText && (
                          <Row>
                            <Col lg={12}>
                              <ExpansionPanel className='MuiAppBar-color-custom '>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Typography>OTHER TEXT :</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography variant='caption'>
                                    {referItems.otherText && (
                                      <div style={{ paddingLeft: '8px' }}>
                                        {referItems.otherText.split('\r').map((item2, index2) => (
                                          <div key={index2}>
                                            <h6>
                                              {index2 + 1}. {item2}
                                            </h6>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <hr />
                            </Col>
                          </Row>
                        )}

                        {referItems.treatmentText && (
                          <Row>
                            <Col lg={12}>
                              <ExpansionPanel className='MuiAppBar-color-custom '>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Typography>TREATMENT :</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography variant='caption'>
                                    {referItems.treatmentText && (
                                      <div style={{ paddingLeft: '8px' }}>
                                        {referItems.treatmentText.split('\r').map((item2, index2) => (
                                          <div key={index2}>
                                            <h6>
                                              {index2 + 1}. {item2}
                                            </h6>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <hr />
                            </Col>
                          </Row>
                        )}

                        {referItems.pmh && (
                          <Row>
                            <Col lg={12}>
                              <ExpansionPanel className='MuiAppBar-color-custom '>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Typography>Past medical history :</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography variant='caption'>
                                    {referItems.pmh && (
                                      <div style={{ paddingLeft: '8px' }}>
                                        {referItems.pmh.split('\r').map((item2, index2) => (
                                          <div key={index2}>
                                            <h6>
                                              {index2 + 1}. {item2}
                                            </h6>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <hr />
                            </Col>
                          </Row>
                        )}

                        {referItems.hpi && (
                          <Row>
                            <Col lg={12}>
                              <ExpansionPanel className='MuiAppBar-color-custom '>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Typography>History of present illness :</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography variant='caption'>
                                    {referItems.hpi && (
                                      <div style={{ paddingLeft: '8px' }}>
                                        {referItems.hpi.split('\r').map((item2, index2) => (
                                          <div key={index2}>
                                            <h6>
                                              {index2 + 1}. {item2}
                                            </h6>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <hr />
                            </Col>
                          </Row>
                        )}

                        {localStorage.getItem('from-page') === 'refer-in' &&
                          referItems?.status?.toUpperCase() !== RECORDED_BY_DEST &&
                          appData?.permission?.actionRefer && (
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                              <Button
                                disabled={referItems.contactDoctorDateTime}
                                onClick={e => handleContactModal(referItems)}
                                variant='contained'
                                style={{ marginRight: '10px' }}
                                color='primary'
                              >
                                ติดต่อแพทย์แล้ว
                              </Button>
                            </CardActions>
                          )}
                      </CardContent>
                    </Card>
                    <Dialog
                      open={contactOpen}
                      onClose={e => setContactOpen(false)}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                    >
                      <DialogTitle>
                        <Typography align='left'>ติดต่อแพทย์แล้ว</Typography>
                      </DialogTitle>
                      <DialogContent>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          autoOk
                          variant='inline'
                          format='dd/MM/yyyy'
                          margin='normal'
                          label='วันท่ี :'
                          onChange={e => setContactDate(e)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          value={contactDate}
                        />
                        <TimePicker
                          showTodayButton
                          todayLabel='now'
                          label='เวลา :'
                          value={contactTime}
                          onChange={e => setContactTime(e)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button color='primary' variant='contained' onClick={e => handleContactDoctor(e)}>
                          ตกลง
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
    fetchRefersAccept: dataRequest => dispatch(fetchRefersAccept(dataRequest)),
    fetchRefersReject: dataRequest => dispatch(fetchRefersReject(dataRequest)),
    fetchReferNotificationsRead: dataRequest => dispatch(fetchReferNotificationsRead(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsRefer));
