export function StrEncrypt(st, key) {
  let CryptoJS = require('crypto-js');

  let key2 = key + 'cGVyamVy';

  let Encrypted = '';

  if (st && st !== '') {
    let b64 = CryptoJS.AES.encrypt(st, key2).toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    Encrypted = e64.toString(CryptoJS.enc.Hex);
  } else {
    Encrypted = '';
  }

  return Encrypted;
}

export function StrDecrypt(st, key) {
  let CryptoJS = require('crypto-js');

  let key2 = key + 'cGVyamVy';

  let Decrypt = '';

  if (st && st !== '') {
    let reb64 = CryptoJS.enc.Hex.parse(st);
    let bytes = reb64.toString(CryptoJS.enc.Base64);
    let decrypt = CryptoJS.AES.decrypt(bytes, key2);
    Decrypt = decrypt.toString(CryptoJS.enc.Utf8);
  } else {
    Decrypt = '';
  }

  return Decrypt;
}

export function shortHospName(str) {
  if (str) {
    str = str.replace('โรงพยาบาล', 'รพ.');
    str = str.replace('ส่งเสริมสุขภาพตำบล', 'สต.');
    str = str.replace('สำนักงานสาธารณสุขจังหวัด', 'สสจ.');
    str = str.replace('สำนักงานสาธารณสุขอำเภอ', 'สสอ.');
    str = str.replace('โรงพยาบาลศูนย์', 'รพศ.');
    str = str.replace('โรงพยาบาลชุมชน', 'รพช.');
    str = str.replace('สถานีอนามัย', 'สอ.');
    str = str.replace('ศูนย์สุขภาพชุมชน', 'ศสช.');
  }
  let newStr = str;
  return newStr;
}

const BILLING_STATUSES = {
  PROCESSING: { text: 'รอส่งเรียกเก็บ', color: '#F57F17' },
  PENDING: { text: 'รอผู้จ่ายตรวจสอบ', color: '#0D47A1' },
  ACCEPTED: { text: 'ผู้จ่ายตกลงจ่าย', color: '#64DD17' },
  REJECTED: { text: 'ผู้จ่ายไม่ตกลงจ่าย', color: '#E64A19' },
  EDITED: { text: 'แก้ไขแล้ว', color: '#00BFA5' },
  COMPLETE: { text: 'จบการเรียกเก็บ', color: '#64DD17' },
  NULL: { text: '-', color: null },
};

export const getBillingStatusConfig = billingStatus => {
  return billingStatus ? BILLING_STATUSES[billingStatus.toUpperCase()] : BILLING_STATUSES['NULL'];
};

export const formatNum = num => Number(num).toFixed(2);

export const sameStatus = (billingStatusFromDb, compare) => {
  return billingStatusFromDb.toUpperCase() === compare;
};

export const statusInArray = (billingStatusFromDb, compareArray) => {
  return compareArray.includes(billingStatusFromDb.toUpperCase());
};

export const calculateAge = birthday => {
  // birthday is a date
  const ageDifMs = Date.now() - new Date(birthday)?.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
