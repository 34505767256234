import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import NavigationMain from '../components/NavigationMain';
import ContainerMain from '../components/ContainerMain';

const Index = () => (
  <div style={{ background: 'linear-gradient(180deg, #EAEFF6 0%, #F3F5F9 50%)', minHeight:'100vh' }}>
    <Route render={props => <NavigationMain {...props} />} />
    <Route render={props => <ContainerMain {...props} />} />
  </div>
);

export default withRouter(Index);
