import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

// constants
import { ADMIN, USER, roles, SUPERADMIN } from '../../../constants/Users/userRole';

//style
import { UserFormContainer, Form, useStyles, styleTabs } from './UserForm.style';

//actions
import { addUser, editUser } from '../../../actions/UserManagement';
import { fetchHospitalList } from '../../../actions/Commons';

// components
import { ListboxComponent } from '../../Utility/ListboxComponent';
import TabPanel from '../../Utility/TabPanel';

//mui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chip, FormControlLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, Grid, RadioGroup, Radio, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const UserForm = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, watch, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState({});
  const [members, setMembers] = useState([]);
  const [tab, setTab] = useState(0);

  const { location } = props;
  const user = location?.state?.user;

  const { appData } = useSelector(state => state);
  const { idToken, hospitalList, alert, loginData, permission } = appData;

  const ActionBtn = () => {
    return (
      <div className='action'>
        <Button
          disabled={loading}
          className='cancel'
          variant='contained'
          onClick={() => history.push('/user-management')}
          style={{color:'#3F98D2', backgroundColor:'white',border:'1px solid #3F98D2'}}
        >
          ยกเลิก
        </Button>
        <Button disabled={loading} type='submit' style={{backgroundColor:'#3F98D2',color:'white'}} variant='contained'>
          ยืนยัน
        </Button>
      </div>
    );
  };

  useEffect(() => {
    // if user role equal super admin fetch all of hospital or else fetch only user members hospital
    handleFetchHospitalList({
      idToken,
      fetchHospListByMember: loginData?.role?.toUpperCase() === SUPERADMIN ? false : true,
    });
  }, []);

  useEffect(() => {
    if (alert.type === 'success') {
      !permission?.userManagement && swal('อัพเดทข้อมูลเรียบร้อย', 'กรุณาล็อกอินใหม่อีกครั้ง', 'success');
      history.push('/user-management');
    }
  }, [idToken, alert]);

  useEffect(() => {
    let tmpMembers = [];
    user?.members?.map(item => tmpMembers.push({ key: uuid(), hospCode: item }));

    setMembers(tmpMembers);
  }, [user]);

  const handleFetchHospitalList = ({ idToken, fetchHospListByMember }) => {
    dispatch(fetchHospitalList({ idToken, fetchHospListByMember }));
  };

  const onSubmit = _user => {
    setLoading(true);

    if (user) {
      const body = {
        username: _user.username,
        password: _user.password,
        hospCode: user.hospCode !== selectedHospital.hospCode ? selectedHospital.hospCode : '',
        role: user.role !== _user.role ? _user.role : '',
        title: user.title !== _user.title ? _user.title : '',
        firstName: user.firstName !== _user.firstName ? _user.firstName : '',
        lastName: user.lastName !== _user.lastName ? _user.lastName : '',
        citizenship: _user.citizenship,
        empCode: _user.empCode,
        members: members?.map(item => item?.hospCode),
      };

      dispatch(editUser({ idToken, body }));
    } else {
      const body = {
        username: _user.username,
        password: _user.password,
        hospCode: selectedHospital?.hospCode,
        role: _user.role,
        title: _user.title,
        firstName: _user.firstName,
        lastName: _user.lastName,
        citizenship: _user.citizenship,
        empCode: _user.empCode,
        members: members?.map(item => item?.hospCode),
      };

      dispatch(addUser({ idToken, body }));
    }

    setLoading(false);
  };

  const onUpdatePassword = password => {
    if (password.newPassword !== password.confirmationPassword) return;

    setLoading(true);

    const body = {
      username: user?.username,
      password: password?.newPassword,
    };

    dispatch(editUser({ idToken, body }));

    setLoading(false);
  };

  const handleDeleteHospCodeList = chipToDelete => {
    setMembers(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const hideIdCardAndEmpCode = () => {
    if (watch('title', 'นาย') === 'รพ.' || watch('title', 'นาย') === 'สสจ.' || watch('title', 'นาย') === 'สสอ.') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <UserFormContainer>
      <Form elevation={2}>
        <Grid container spacing={1}>
          <Grid item xs={!user ? 12 : 6}>
            <h4 style={{color:'gray'}}>{user ? 'แก้ไขผู้ใช้งาน' : 'ผู้ใช้งานใหม่'}</h4>
          </Grid>
          {user && (
            <Grid item xs={6}>
              <Tabs value={tab} onChange={(e, idx) => setTab(idx)} indicatorColor='primary'>
                <Tab label='Profile' />
                <Tab label='Password' />
              </Tabs>
            </Grid>
          )}
        </Grid>

        {/* SECTION: profile tab */}
        <TabPanel value={tab} index={0}>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='รหัสผู้ใช้งาน'
                  variant='outlined'
                  disabled={loading || user}
                />
              }
              control={control}
              name='username'
              defaultValue={user?.username || ''}
            />

            {!user && (
              <Controller
                as={
                  <TextField
                    required
                    className='input'
                    label='รหัสผ่าน'
                    variant='outlined'
                    disabled={loading}
                    type='password'
                  />
                }
                control={control}
                name='password'
              />
            )}

            {hospitalList?.[0] && (
              <Autocomplete
                disableListWrap
                loading={!hospitalList[0]}
                ListboxComponent={ListboxComponent}
                options={
                  loginData?.role?.toUpperCase() === SUPERADMIN
                    ? hospitalList
                    : hospitalList?.filter(
                        item => item?.hospCode === loginData?.hospCode || loginData?.members?.includes(item?.hospCode)
                      )
                }
                defaultValue={
                  user && {
                    hospCode: user?.hospCode,
                    hospName: hospitalList?.find(item => item.hospCode === user?.hospCode)?.hospName,
                  }
                }
                onChange={(option, value) => setSelectedHospital(value)}
                getOptionLabel={option => `${option.hospCode} : ${option.hospName}`}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    margin='normal'
                    label='โรงพยาบาล'
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {!hospitalList[0] ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}

            {permission?.userManagement && (
              <Controller
                as={<TextField select className='input' label='สิทธิ์' variant='outlined' disabled={loading} />}
                control={control}
                name='role'
                defaultValue={user?.role?.toUpperCase() || USER}
              >
                {roles
                  .filter(item => (loginData?.role?.toUpperCase() === SUPERADMIN ? item : item?.value !== SUPERADMIN))
                  .map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Controller>
            )}

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Controller
                  as={<TextField select fullWidth className='input' variant='outlined' />}
                  control={control}
                  name='title'
                  defaultValue={user?.fullName?.title || 'นาย'}
                >
                  {[
                    { label: 'รพ.', value: 'รพ.' },
                    { label: 'นาย', value: 'นาย' },
                    { label: 'นาง', value: 'นาง' },
                    { label: 'น.ส.', value: 'น.ส.' },
                    { label: 'สสจ.', value: 'สสจ.' },
                    { label: 'สสอ.', value: 'สสอ.' },
                  ]
                    .filter(item => (permission?.userManagement ? item : item?.value !== 'รพ.'))
                    .filter(item => (loginData?.role?.toUpperCase() === SUPERADMIN ? item : item?.value !== 'สสจ.'))
                    .map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Controller>
              </Grid>
              <Grid item xs={8} sm={hideIdCardAndEmpCode() ? 10 : 4}>
                <Controller
                  as={<TextField fullWidth required className='input' label='ชื่อ' variant='outlined' />}
                  control={control}
                  name='firstName'
                  defaultValue={user?.fullName?.firstName || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      hidden={hideIdCardAndEmpCode()}
                      required={hideIdCardAndEmpCode() ? false : true}
                      className='input'
                      label='นามสกุล'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='lastName'
                  defaultValue={user?.fullName?.lastName || ''}
                />
              </Grid>
            </Grid>

            <Controller
              as={<RadioGroup row hidden={hideIdCardAndEmpCode()} />}
              control={control}
              name='code__type'
              defaultValue={user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1'}
            >
              <FormControlLabel value='1' control={<Radio style={{color:'#3F98D2'}} />} label='รหัสบัตรประชาชน' />
              <FormControlLabel value='2' control={<Radio style={{color:'#3F98D2'}} />} label='รหัสพนักงาน' />
            </Controller>

            <Controller
              as={
                <TextField
                  required={
                    hideIdCardAndEmpCode()
                      ? false
                      : watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') ===
                        '1'
                  }
                  hidden={
                    hideIdCardAndEmpCode()
                      ? true
                      : watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') !==
                        '1'
                  }
                  className='input'
                  label='รหัสบัตรประชาชน'
                  variant='outlined'
                  type='number'
                  autoComplete='off'
                />
              }
              control={control}
              name='citizenship'
              defaultValue={user?.citizenship || ''}
              rules={{ maxLength: 13, minLength: 13 }}
            />

            {(errors.citizenship?.type === 'maxLength' || errors.citizenship?.type === 'minLength') && (
              <Typography color='error'>กรุณาระบุรหัสบัตรประชาชน 13 หลักให้ถูกต้อง</Typography>
            )}

            <Controller
              as={
                <TextField
                  required={
                    hideIdCardAndEmpCode()
                      ? false
                      : watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') ===
                        '2'
                  }
                  hidden={
                    hideIdCardAndEmpCode()
                      ? true
                      : watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') !==
                        '2'
                  }
                  className='input'
                  label='รหัสพนักงาน'
                  variant='outlined'
                  type='number'
                />
              }
              control={control}
              name='empCode'
              defaultValue={user?.empCode || ''}
            />

            {permission?.addUserMember && watch('role', USER)?.toUpperCase() === ADMIN && (
              <>
                <div className={classes.chipWrapper}>
                  {members?.map(item => (
                    <Chip
                      label={item.hospCode}
                      size='small'
                      color='secondary'
                      variant='outlined'
                      onDelete={e => handleDeleteHospCodeList(item)}
                      className={classes.chip}
                    />
                  ))}
                </div>

                <Autocomplete
                  onChange={(option, value) =>
                    value && setMembers([...members, { key: uuid(), hospCode: value.hospCode }])
                  }
                  ListboxComponent={ListboxComponent}
                  // TODO: maybe must take out provincial heath office account after know list of provincial office hospCode
                  options={hospitalList?.filter(item => item?.hospCode !== selectedHospital?.hospCode)}
                  getOptionLabel={option => `${option?.hospCode} : ${option?.hospName}`}
                  renderInput={params => (
                    <TextField {...params} fullWidth variant='outlined' margin='normal' label='รายการ รพ. ที่ดูแล' />
                  )}
                />
              </>
            )}

            <div className='alert-container'>{alert.show && <Alert severity={alert.type}>{alert.msg}</Alert>}</div>

            <ActionBtn />
          </form>
        </TabPanel>

        {/* SECTION: password tab */}
        <TabPanel value={tab} index={1}>
          <form className='form' onSubmit={handleSubmit(onUpdatePassword)}>
            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='รหัสผ่านใหม่'
                  variant='outlined'
                  disabled={loading}
                  type='password'
                />
              }
              control={control}
              name='newPassword'
              rules={{
                minLength: 6,
              }}
            />

            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='ยืนยันรหัสผ่าน'
                  variant='outlined'
                  disabled={loading}
                  type='password'
                />
              }
              control={control}
              name='confirmationPassword'
            />

            {watch('newPassword', '') &&
              watch('confirmationPassword', '') &&
              (watch('confirmationPassword', '') !== watch('newPassword', '') ||
                watch('newPassword', '')?.length < 6 ||
                watch('confirmationPassword', '')?.length < 6) && (
                <Typography color='error'>
                  การยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน หรือรหัสผ่านน้อยกว่า 6 ตัวอักษร
                </Typography>
              )}

            <ActionBtn />
          </form>
        </TabPanel>

        {loading && <LinearProgress />}
      </Form>
    </UserFormContainer>
  );
};

export default UserForm;
