import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Modal, Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Row, Col, Table, Badge } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { ThaiDate, ThaiDateTime } from '../../functions/FuncDateTimes';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBillingItemsByPatient } from '../../actions/BillingsCharge';
import { Status } from '../BillingsCharge/styles';
import { getBillingStatusConfig, statusInArray } from '../../functions/FuncPerjer';
import { Container, Smodal } from './styles';
import Button from '@material-ui/core/Button';
import updateFinalPrice from '../../api/Billings/updateFinalPrice';
import rejectBillingItem from '../../api/Billings/rejectBillingItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const PatientBilling = props => {
  const classes = useStyles();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [comments, setComments] = useState('');
  const dispatch = useDispatch();
  const { appData } = useSelector(state => state);
  const { idToken, hospCode, billlingPatients, patientData } = appData;
  const { category, monthIndex, selectedHosp, cid } = props.match.params;
  const startDate = new Date(
    new Date(new Date().getFullYear(), Number(monthIndex) - 1, 1).setHours(0, 0, 0, 0)
  ).toISOString();
  const endDate = new Date(
    new Date(new Date().getFullYear(), Number(monthIndex), 0).setHours(23, 59, 59, 999)
  ).toISOString();
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    dispatch(
      fetchBillingItemsByPatient({
        idToken,
        cid,
        hospCode: asProvider ? hospCode : selectedHosp,
        hospmain: asProvider ? selectedHosp : hospCode,
        category,
        startDate,
        endDate,
      })
    );
  }, [dispatch, idToken, cid, hospCode, selectedHosp, category, startDate, endDate, asProvider]);

  const providerCanComment = item => {
    const status = item.billingStatus;
    if (status) {
      return asProvider && statusInArray(status, ['PROCESSING', 'REJECTED', 'PROCESSING_EDITED']);
    } else return false;
  };

  const payerCanReject = item => {
    const status = item.billingStatus;
    if (status) return !asProvider && statusInArray(status, ['PENDING']);
    else return false;
  };

  const handleModalSubmit = async modal => {
    if (asProvider) {
      if (!newPrice || !comments) {
        alert('กรุณากรอกข้อมูลให้ครบ');
        return;
      }
    } else {
      if (!comments) {
        alert('กรุณากรอกข้อมูลให้ครบ');
        return;
      }
    }

    const options = {
      idToken,
      author: hospCode,
      billingItemId: modal._id,
      message: comments,
      newPrice,
    };

    try {
      if (asProvider && (modal.billingStatus === 'PROCESSING' || 'REJECTED' || 'PROCESSING_EDITED')) {
        await updateFinalPrice(options);
      } else if (!asProvider && modal.billingStatus === 'PENDING') {
        await rejectBillingItem(options);
      } else {
        console.log('other case');
      }

      dispatch(
        fetchBillingItemsByPatient({
          idToken,
          cid,
          hospCode: asProvider ? hospCode : selectedHosp,
          hospmain: asProvider ? selectedHosp : hospCode,
          category,
          startDate,
          endDate,
        })
      );
    } catch (error) {
      throw new Error(error);
    }
    setModal(false);
  };

  return (
    <Container>
      <div>
        <Card className='patient-data patient-data-primary'>
          {patientData && (
            <CardContent className='patient-data patient-data-primary'>
              <Row className='margin-bottom-4px'>
                <Col lg={2}>
                  <Row>
                    <Col style={{ maxWidth: '145px' }}>
                      {patientData.data.hn && patientData.data.sex === '1' && (
                        <div
                          className='rounded img-thumbnail img-fluid pt-image'
                          style={{
                            backgroundImage: `url('/images/hospital-patient.png')`,
                          }}
                        />
                      )}
                      {patientData.data.hn && patientData.data.sex !== '1' && (
                        <div
                          className='rounded img-thumbnail img-fluid pt-image'
                          style={{
                            backgroundImage: `url('/images/hospital-patient-f.png')`,
                          }}
                        />
                      )}
                    </Col>
                    {props.width <= 991 && (
                      <Col style={{ paddingLeft: '0px' }}>
                        <h5>CID {patientData.data.hn && patientData.bid}</h5>
                        <h5>
                          ชื่อ-สกุล{' '}
                          {patientData.data.hn &&
                            patientData.data.fullname.title +
                              patientData.data.fullname.firstName +
                              '  ' +
                              patientData.data.fullname.lastName}
                        </h5>
                        <div>HN {patientData.data.hn && patientData.data.hn[0].hn}</div>
                        <div>
                          วันเกิด {patientData.data.hn && ThaiDate(patientData.data.DOB)} | อายุ{' '}
                          {patientData.data.hn && patientData.data.age} ปี
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>

                {props.width > 991 && (
                  <Col lg={10}>
                    <Row>
                      <Col lg={4}>
                        <h5>CID {patientData.data.hn && patientData.bid}</h5>
                      </Col>
                      <Col lg={6}>
                        <h5>
                          ชื่อ-สกุล{' '}
                          {patientData.data.hn &&
                            patientData.data.fullname.title +
                              patientData.data.fullname.firstName +
                              '  ' +
                              patientData.data.fullname.lastName}
                        </h5>
                      </Col>
                      <Col lg={2}>
                        <h5>เพศ {patientData.data.hn && patientData.data.sexName}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>HN {patientData.data.hn && patientData.data.hn[0].hn}</Col>
                      <Col lg={4}>วันเกิด {patientData.data.hn && ThaiDate(patientData.data.DOB)}</Col>
                      <Col lg={4}>อายุ {patientData.data.hn && patientData.data.age} ปี</Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        ที่อยู่ {patientData.data.hn && patientData.data.address.houseNumber + ' '}
                        {patientData.data.address.moopart && 'หมู ' + patientData.data.address.moopart + ' '}
                        {patientData.data.address.road && 'ถ. ' + patientData.data.address.road + ' '}
                        {patientData.data.hn && patientData.data.address.addressPart}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>เชื้อชาติ {patientData.data.hn && patientData.data.citizenship}</Col>
                      <Col lg={4}>สัญชาติ {patientData.data.hn && patientData.data.nationality}</Col>
                      <Col lg={4}>ศาสนา {patientData.data.hn && patientData.data.religion}</Col>
                    </Row>
                    <Row>
                      <Col lg={6}>เบอร์โทร {patientData.data.hn && patientData.data.contactInfo.mobileNumber}</Col>
                      <Col lg={6}>
                        เบอร์โทรผู้ติดต่อ {patientData.data.hn && patientData.data.emergencyInfo.mobileNumber}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>ผู้ติดต่อ {patientData.data.hn && patientData.data.emergencyInfo.familyName}</Col>
                      <Col lg={9}>
                        ที่อยู่ผู้ติดต่อ {patientData.data.hn && patientData.data.emergencyInfo.address.addressPart}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={12}>
                  <h4
                    className={
                      patientData.data.condition.ccPersistDisease !== '' && patientData.data.condition.ccPersistDisease
                        ? 'font-color-alert'
                        : ''
                    }
                  >
                    โรคประจำตัว {patientData.data.hn && patientData.data.condition.ccPersistDisease}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h4
                    className={
                      patientData.data.drugAllergies !== '' && patientData.data.drugAllergies ? 'font-color-alert' : ''
                    }
                  >
                    <div className='float-left' style={{ marginRight: '4px' }}>
                      แพ้ยา{' '}
                    </div>
                    {patientData.data.drugAllergies.map((patientData, index) => (
                      <div key={index} className='float-left' style={{ marginRight: '4px' }}>
                        {patientData.genericname} ,
                      </div>
                    ))}
                  </h4>
                </Col>
              </Row>
              <Row>
                {props.width <= 991 && (
                  <Col lg={12}>
                    <Row
                      style={{
                        marginLeft: '1px',
                        marginRight: '1px',
                        marginTop: '4px',
                      }}
                    >
                      <ExpansionPanel style={{ width: '100%' }} className='MuiAppBar-color-custom '>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon className='app-text-color' />}
                          aria-controls='panel2a-content'
                          id='panel2a-header'
                        >
                          <Typography>ประวัติเพิ่มเติม</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              ที่อยู่ {patientData.data.hn && patientData.data.address.houseNumber + ' '}
                              {patientData.data.address.moopart && 'หมู่ ' + patientData.data.address.moopart + ' '}
                              {patientData.data.address.road && 'ถ. ' + patientData.data.address.road + ' '}
                              {patientData.data.hn && patientData.data.address.addressPart}
                            </Grid>

                            <Grid item xs={4}>
                              เชื้อชาติ {patientData.data.hn && patientData.data.citizenship}
                            </Grid>
                            <Grid item xs={4}>
                              สัญชาติ {patientData.data.hn && patientData.data.nationality}
                            </Grid>
                            <Grid item xs={4}>
                              ศาสนา {patientData.data.hn && patientData.data.religion}
                            </Grid>

                            <Grid item xs={12}>
                              เบอร์โทร {patientData.data.hn && patientData.data.contactInfo.mobileNumber}
                            </Grid>

                            <Grid item xs={12}>
                              ผู้ติดต่อ {patientData.data.hn && patientData.data.emergencyInfo.familyName}
                            </Grid>
                            <Grid item xs={12}>
                              เบอร์โทรผู้ติดต่อ {patientData.data.hn && patientData.data.emergencyInfo.mobileNumber}
                            </Grid>
                            <Grid item xs={12}>
                              ที่อยู่ผู้ติดต่อ{' '}
                              {patientData.data.hn && patientData.data.emergencyInfo.address.addressPart}
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Row>
                  </Col>
                )}
              </Row>
            </CardContent>
          )}
        </Card>
        {billlingPatients &&
          billlingPatients.docs.map(data => (
            <Row>
              <Col md={12}>
                <div className='drugList'>
                  <Row style={{ marginBottom: '0.75em' }}>
                    <Col md={9}>
                      <h4 style={{ margin: 0 }}>
                        {asProvider ? 'เรียกเก็บ ' : 'ตามจ่าย '}
                        <strong>- {ThaiDateTime(data.visitDateTime)}</strong>
                      </h4>
                    </Col>
                    <Col md={3} className='text-right'>
                      <p>
                        <strong>สถานะ:</strong>{' '}
                        {
                          <Status color={getBillingStatusConfig(data.billing && data.billing.status).color}>
                            {data.billing ? getBillingStatusConfig(data.billing && data.billing.status).text : null}
                          </Status>
                        }
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <strong>VN:</strong> {data.vn} &emsp;
                      <br />
                      <strong>แผนก:</strong> {data.spcltyName} &emsp;
                      <strong>สิทธิย่อย:</strong> {data.pttypeName} - {data.pttypeno}
                      <br />
                      <strong>ICD10:</strong>{' '}
                      {data.diagnoses && data.diagnoses.length > 0
                        ? (() => {
                            let result = [<br />];
                            data.diagnoses.forEach(item => {
                              result.push(`${item.icd10} - ${item.icd10Name}`);
                              result.push(<br />);
                            });
                            return result;
                          })()
                        : '-'}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Table className='drugItems' responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Icode</th>
                            <th>ชื่อรายการ</th>
                            <th
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              ราคาตามจริง
                            </th>
                            <th
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              ราคาคำนวณ
                            </th>
                            <th
                              style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              ราคาเรียกเก็บ
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.billingItems
                            ? data.billingItems.map((item, index) => (
                                <tr
                                  className={(() => {
                                    if (item.billingStatus === 'REJECTED') {
                                      return 'bg-danger';
                                    }
                                    // else if (this.state.duplicateIcodes[data._id] && this.state.duplicateIcodes[data._id].has(item.icode)) {
                                    //     return "bg-warning";
                                    // }
                                  })()}
                                >
                                  <td>
                                    <p>{index + 1}</p>
                                  </td>
                                  <td>
                                    <p>{item.icode}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {item.drugNondugFullName}{' '}
                                      {(() => {
                                        let result = [];
                                        if (item.calcCapExclude) {
                                          result.push(
                                            <Badge
                                              style={{
                                                backgroundColor: 'orange',
                                              }}
                                            >
                                              พิเศษ
                                            </Badge>
                                          );
                                        }
                                        if (item.payment_status === 'Y') {
                                          result.push(
                                            ' ',
                                            <Badge
                                              style={{
                                                backgroundColor: '#F1FF3C',
                                              }}
                                            >
                                              จ่ายแล้ว
                                            </Badge>
                                          );
                                        }
                                        if (item.calcItemRule) {
                                          result.push(
                                            ' ',
                                            <Badge
                                              style={{
                                                backgroundColor: '#589636',
                                              }}
                                            >
                                              ตามตกลง
                                            </Badge>
                                          );
                                        }
                                        return result;
                                      })()}
                                    </p>
                                    {item.comments && item.comments.length > 0 ? (
                                      <p className='billing-comments'>
                                        <strong>หมายเหตุ: </strong>
                                        {item.comments.map((comment, index) => {
                                          return index !== item.comments.length - 1
                                            ? `${comment.message}, `
                                            : comment.message;
                                        })}
                                      </p>
                                    ) : null}
                                  </td>
                                  <td>
                                    <p style={{ textAlign: 'right' }}>
                                      {Number(item.sumPrice)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                    </p>
                                  </td>
                                  <td>
                                    <p style={{ textAlign: 'right' }}>
                                      {item.calcPrice || item.calcPrice === 0
                                        ? Number(String(item.calcPrice))
                                            .toFixed(2)
                                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                        : '-'}
                                    </p>
                                  </td>
                                  <td>
                                    <p style={{ textAlign: 'right' }}>
                                      <strong>
                                        {item.finalPrice || item.finalPrice === 0
                                          ? Number(String(item.finalPrice))
                                              .toFixed(2)
                                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                          : '-'}
                                      </strong>
                                    </p>
                                  </td>
                                  <td className='text-right'>
                                    {(() => {
                                      if (data.billing) {
                                        if (providerCanComment(item)) {
                                          return (
                                            <IconButton color='primary' onClick={() => setModal(item)}>
                                              <EditIcon />
                                            </IconButton>
                                          );
                                        } else if (payerCanReject(item)) {
                                          return (
                                            <IconButton color='secondary' onClick={() => setModal(item)}>
                                              <HighlightOffIcon />
                                            </IconButton>
                                          );
                                        }
                                      }
                                    })()}
                                  </td>
                                </tr>
                              ))
                            : ''}
                          <tr style={{ backgroundColor: '#f5f5f5' }}>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: 'right' }}>
                              <p style={{ fontWeight: 'bold' }}>รวม</p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>
                                {(() => {
                                  let total = 0;
                                  if (data.billingItems) data.billingItems.forEach(x => (total += +x.sumPrice));
                                  let totalstr = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                  return !isNaN(total) ? totalstr : '-';
                                })()}
                              </p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>
                                {(() => {
                                  let total = 0;
                                  if (data.billingItems) data.billingItems.forEach(x => (total += +x.calcPrice));
                                  let totalstr = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                  return !isNaN(total) ? totalstr : '-';
                                })()}
                              </p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p style={{ fontWeight: 'bold' }}>
                                {(() => {
                                  let total = 0;
                                  if (data.billingItems) data.billingItems.forEach(x => (total += +x.finalPrice));
                                  let totalstr = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                  return !isNaN(total) ? totalstr : '-';
                                })()}
                              </p>
                            </td>
                            <td></td>
                          </tr>

                          <tr style={{ backgroundColor: '#f5f5f5' }}>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: 'right' }}>
                              <p style={{ fontWeight: 'bold' }}>
                                {data.billing ? `ยอดรวมสุทธิ${data.billing.cap ? ` (${data.billing.cap})` : ''}` : ''}
                              </p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>
                                {data.billing
                                  ? data.billing.actualTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  : ''}
                              </p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p>
                                {data.billing
                                  ? data.billing.calcTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  : ''}
                              </p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <p style={{ fontWeight: 'bold' }}>
                                {data.billing
                                  ? data.billing.finalTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  : ''}
                              </p>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
      </div>
      <>
        <Modal centered className='modal-edit' show={modal} onHide={() => setModal(false)} id='billingItemModal'>
          <Smodal>
            <Modal.Header>
              <Modal.Title>แก้ไขรายการ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className='fix'>
                <Col sm={12} className='billing-item-overview'>
                  <i className='fa fa-medkit'></i>
                  <table>
                    <tbody>
                      <tr>
                        <td>รหัส icode:</td>
                        <td>
                          <strong>{modal.icode}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>รายการ:</td>
                        <td>
                          <strong>{modal.drugNondugFullName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>ราคาตามจริง:</td>
                        <td>{`฿ ${modal.sumPrice}`}</td>
                      </tr>
                      <tr>
                        <td>ราคาคำนวณ:</td>
                        <td style={{ fontWeight: 'bold' }}>{`฿ ${modal.calcPrice}`}</td>
                      </tr>
                      <tr>
                        <td>หมายเหตุ:</td>
                        <td>
                          {modal.comments && modal.comments.length > 0
                            ? modal.comments.map((comment, index) => {
                                return index !== modal.comments.length - 1 ? `${comment.message}, ` : comment.message;
                              })
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col sm={12} className='billing-item-overview'>
                  <Form>
                    {asProvider && (
                      <FormGroup as={Row} controlId='formHorizontalEmail'>
                        <FormLabel sm={3}>ราคาเรียกเก็บ</FormLabel>
                        <Col sm={4}>
                          <FormControl
                            type='text'
                            name='newPrice'
                            value={newPrice}
                            onChange={e => setNewPrice(e.target.value)}
                            defaultValue={modal.newPrice ? modal.newPrice : null}
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup as={Row} controlId='formHorizontalEmail'>
                      <FormLabel sm={3}>เพิ่มหมายเหตุ</FormLabel>
                      <Col>
                        <FormControl
                          componentClass='textarea'
                          rows='3'
                          name='comments'
                          value={comments}
                          onChange={e => setComments(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              {asProvider ? (
                <Button
                  color='primary'
                  variant='outlined'
                  className={classes.button}
                  onClick={() => handleModalSubmit(modal)}
                >
                  บันทึก
                </Button>
              ) : (
                <Button
                  color='secondary'
                  variant='outlined'
                  className={classes.button}
                  onClick={() => handleModalSubmit(modal)}
                >
                  ปฏิเสธรายการนี้
                </Button>
              )}

              <Button variant='outlined' onClick={() => setModal(false)}>
                ยกเลิก
              </Button>
            </Modal.Footer>
          </Smodal>
        </Modal>
      </>
    </Container>
  );
};

export default PatientBilling;
