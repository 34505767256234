import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../fonts/vfs_fonts';

// functions
import { valueToTextSendReason } from '../../functions/ReferForm';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
};

const locales = 'th-TH';
const options = {
  dateStyle: 'short',
  timeStyle: 'short',
};

const getAppointmentDateTime = referral => {
  if (referral?.appointmentDateTimeStart || referral?.appointmentDateTimeEnd) {
    return `${new Date(referral?.appointmentDateTimeStart).toLocaleString(locales, options)} - ${new Date(
      referral?.appointmentDateTimeEnd
    ).toLocaleString(locales, options)}`;
  } else if (referral?.appointmentDateTime?.[0]) {
    new Date(referral?.appointmentDateTime?.[0]).toLocaleString(locales, options);
  } else {
    return '-';
  }
};

export const printReferral = data => {
  const {
    patient: { cid, age, address, fullname, mobileNumber },
    diagnoses,
    referrals,
  } = data;

  var docDefinition = {
    watermark: { text: 'ประสานงานผ่านระบบ V-refer', opacity: 0.1 },
    content:
      referrals?.length > 0
        ? referrals?.map((referral, index) => [
            {
              text: `เอกสารสำหรับส่งตัวผู้ป่วยเพื่อเข้ารับการรักษาต่อ`,
              style: 'header',
              margin: [0, 0, 0, 5],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'จาก ', style: 'bold' },
                    referral?.fromHospital?.hospName || referral?.fromHospName || '-',
                  ],
                  width: 'auto',
                },
                {},
                {
                  text: [{ text: 'Refer No ', style: 'bold' }, referral?.bid || '-'],
                  width: 'auto',
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'ถึง ', style: 'bold' },
                    referral?.toHospital?.hospName || referral?.toHospName || '-',
                  ],
                  width: 'auto',
                },
              ],
            },
            {
              columns: [
                {
                  text: [{ text: 'วันที่นัดตรวจ ', style: 'bold', width: 'auto' }, getAppointmentDateTime(referral)],
                },
                {
                  text: [{ text: 'ที่ ', style: 'bold' }, referral?.appointmentClinicName || '-'],
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'ขอส่งตัวผู้ป่วยชื่อ ', style: 'bold', width: 'auto' },
                    `${fullname.title || ''} ${fullname.firstName || ''} ${fullname.lastName || ''}`,
                  ],
                },
                {
                  text: [
                    { text: 'อายุ', style: 'bold', width: 'auto' },
                    ` ${age} `,
                    { text: 'ปี', style: 'bold', width: 'auto' },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  text: [{ text: 'เลขประจำตัวประชาชน ', style: 'bold', width: 'auto' }, cid || ''],
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'บ้านเลขที่ ', style: 'bold' },
                    ` ${address?.houseNumber || address?.addressPart || ' - '}`,
                  ],
                },
                {
                  text: [{ text: 'หมู่ที่ ', style: 'bold' }, ` ${address?.moopart || ' - '}`],
                },
                {
                  text: [{ text: 'ถนน ', style: 'bold' }, ` ${address?.road || ' - '}`],
                },
              ],
            },
            {
              columns: [
                {
                  text: [{ text: 'แขวง / ตำบล ', style: 'bold' }, ` ${address?.tmbpart || '-'}`],
                },
                {
                  text: [{ text: 'เขต', width: 'auto', style: 'bold' }, ` ${address?.amppart || '-'}`],
                },
                {
                  text: [{ text: 'จังหวัด', width: 'auto', style: 'bold' }, ` ${address?.chwpart || '-'}`],
                },
                {
                  text: [{ text: 'โทร', width: 'auto', style: 'bold' }, `  ${mobileNumber || '-'}`],
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'โรคประจำตัว ', style: 'bold' },
                    { text: '', style: 'smallText' },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  text: [{ text: 'ประวัติการเจ็บป่วย ' }],
                  style: 'bold',
                },
              ],
            },
            {
              columns: [
                {
                  text: [{ text: referral?.note || '' }],
                  style: ['smallText', 'marginLeft'],
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'ผลการชันสูตร ', style: 'bold' },
                    { text: '', style: 'smallText' },
                  ],
                },
              ],
            },
            {
              text: [{ text: 'การวินิจฉัยเบื้องต้น ', style: 'bold' }],
            },
            {
              text:
                diagnoses?.map(
                  (diagnose, index) =>
                    `${diagnose?.icd10} ${diagnose?.icd10Name} ${index !== diagnoses.length - 1 ? ' + ' : ''} `
                ) || [],
              style: ['smallText', 'marginLeft'],
            },
            {
              columns: [
                {
                  text: [{ text: 'การรักษาที่ได้รับแล้ว ' }, ''],
                  style: 'bold',
                },
              ],
            },
            {
              columns: [
                [
                  {
                    text: [
                      { text: 'สาเหตุที่ส่งต่อครั้งนี้ ', style: 'bold' },
                      referral.sendReason !== '99'
                        ? valueToTextSendReason(referral.sendReason)
                        : referral.sendReasonOther || ' - ',
                    ],
                  },
                ],
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: 'หนังสือส่งตัวนี้ใช้ได้ตั้งแต่วันท่ี ', style: 'bold' },
                    ` ${
                      referral?.referDateTime ? new Date(referral?.referDateTime).toLocaleDateString(locales) : ' - '
                    }`,
                  ],
                },
                {
                  text: [
                    { text: 'ถึงวันที่ ', style: 'bold' },
                    ` ${
                      referral?.expireDateTime ? new Date(referral?.expireDateTime).toLocaleDateString(locales) : '-'
                    }`,
                  ],
                },
              ],
            },
            {
              text: `ลงชื่อ ${
                referral?.doctorName || '...........................................................................'
              } แพทย์ผู้ส่ง`,
              alignment: 'right',
              style: ['marginTopLg'],
              margin: referral?.doctorName
                ? [0, 0, -5 * ((referral?.doctorName.length - 1) / 2) + 65, 0]
                : [0, 0, 0, 0],
            },
            {
              text: `${
                referral?.medicalLicenseNumber
                  ? '( ว ' + referral?.medicalLicenseNumber + ' )'
                  : '( ว.................................................................... )'
              }`,
              alignment: 'right',
              margin: referral?.medicalLicenseNumber
                ? [0, 5, -5 * ((referral?.medicalLicenseNumber.length - 1) / 2) + 85, 0]
                : [0, 5, 28, 0],
            },
            {
              columns: [
                {
                  text: new Date(referral?.referDateTime).toLocaleDateString(locales, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }),
                  style: 'normalText',
                },
              ],
              alignment: 'right',
              margin: [0, 5, 45, 0],
            },
            {
              text: 'สำหรับแจ้งผลการตอบกลับ',
              style: ['bold', 'center', 'marginTopLg'],
            },
            {
              text: 'ตามที่ท่านได้ส่งผู้ป่วยมาเพื่อดำเนินการตามประสงค์นั้น ขอส่งรายละเอียดเพื่อทราบดังนี้',
            },
            {
              text: 'ผลการชันสูตรที่สำคัญ ..................................................................................................................................................................................................................................',
              style: 'marginTop',
            },
            {
              text: 'การวินิจฉัยโรค ..............................................................................................................................................................................................................................................',
              style: 'marginTop',
            },
            {
              text: 'การรักษาที่ให้ ................................................................................................................................................................................................................................................',
              style: 'marginTop',
            },
            {
              text: 'ขอให้คลินิกดำเนินการต่อไปนี้ ......................................................................................................................................................................................................................',
              style: 'marginTop',
            },
            {
              text: '(ลงชื่อ) .................................................................................... แพทย์',
              alignment: 'right',
              style: ['marginTopLg'],
            },
            {
              text: `( ..................................................................... )`,
              alignment: 'right',
              margin: [0, 5, 24, 0],
            },
            {
              text: 'วันท่ี ................../........................./.....................',
              alignment: 'right',
              margin: [0, 5, 28, 0],
            },
            {
              text: [
                { text: 'หมายเหตุ: ใบส่งตัวใช้ได้ครั้งต่อครั้ง สำหรับการรักษาครั้งต่อไปต้องขอใบใหม่ทุกครั้ง ' },
                {
                  text: 'OPD case เรียกเก็บเงินที่',
                  decoration: 'underline',
                },
                { text: referral?.fromHospital?.hospName || referral?.fromHospName || '-' },
              ],
              style: ['marginTopLg', 'smallText'],
            },
            {
              text: ':กรณีผู้ป่วยในเรียกเก็บเงินจากสำนักงานหลักประกันสุขภาพถ้วนหน้าแห่งชาติ',
              style: ['marginLeft', 'smallText'],
            },
            {
              text: ':กรณีโรคบางโรคไม่ครอบคลุม 30 บาท ผู้ป่วยต้องเรียกค่าใช้จ่ายเอง..........................................ผู้ป่วยเซ็นรับทราบ',
              style: ['marginLeft', 'smallText'],
            },
            {
              text: ':ไม่รับผิดชอบยานอกบัญชียาหลักแห่งชาติ รับผิดชอบค่าใช้จ่ายเฉพาะโรคที่ระบุในใบส่งตัวเท่านั้น',
              style: ['marginLeft', 'smallText'],
            },
          ])
        : [],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: 12,
    },
    styles: {
      logoText: { bold: true, fontSize: 10, margin: [10, 0, 0, 0] },
      header: { bold: true, alignment: 'center', fontSize: 16 },
      bold: { bold: true },
      center: { alignment: 'center' },
      marginTop: { margin: [0, 5, 0, 0] },
      marginTopLg: { margin: [0, 10, 0, 0] },
      marginLeft: { margin: [10, 0, 0, 0] },
      smallText: { fontSize: 10 },
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
  };
  pdfMake.createPdf(docDefinition).open();
};
