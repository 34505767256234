import { APPDATA } from '../../constants/appData';
import SearchVisit from '../../api/Refers/ReferForm/SearchVisit';
import Submit from '../../api/Refers/ReferForm/Submit';
import GetVisit from '../../api/Refers/ReferForm/GetVisit';
import FetchPttype from '../../api/Refers/ReferForm/FetchPttype';

export function searchVisit({ hn, cid, vstdate, idToken }) {
  return {
    type: APPDATA,
    payload: SearchVisit({ hn, cid, vstdate, idToken }),
  };
}

export function getVisit({ hn, vstdate, vsttimeId, vn, cliniclct, idToken }) {
  return {
    type: APPDATA,
    payload: GetVisit({ hn, vstdate, vsttimeId, vn, cliniclct, idToken }),
  };
}

export function submit({ idToken, body, attachments, letterAttachments }) {
  return {
    type: APPDATA,
    payload: Submit({ idToken, body, attachments, letterAttachments }),
  };
}

export function fetchPttype({ hospCode, idToken }) {
  return {
    type: APPDATA,
    payload: FetchPttype({ hospCode, idToken }),
  };
}
