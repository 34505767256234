import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
// import MomentUtils from '@date-io/moment'
import DateFnsUtils from '@date-io/date-fns';
// import LuxonUtils from '@date-io/luxon'

import './index.css';
import './scss-styles/theme.scss';
import './scss-styles/main.scss';
import 'bootstrap/scss/bootstrap.scss';

const store = configureStore();

const AppWithRouter = ({ store }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<AppWithRouter store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          alert('New content is available, please refresh.');
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
