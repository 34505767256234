import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientVisitsWithPrices } from '../../../actions/PatientVisitsWithPrices';
import { getBillingStatusConfig, formatNum } from '../../../functions/FuncPerjer';
import { ThaiDate } from '../../../functions/FuncDateTimes';
import { Container, TableRow, TableCell, Status } from '../styles';
import queryString from 'query-string';
import moment from 'moment';

const ChargePatients = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { appData } = useSelector(state => state);
  const { idToken, hospCode, FetchingStatus, billlingPatients } = appData;
  const { category, monthIndex, selectedHosp } = props.match.params;
  const startDate = new Date(
    new Date(new Date().getFullYear(), Number(monthIndex) - 1, 1).setHours(0, 0, 0, 0)
  ).toISOString();
  const endDate = new Date(
    new Date(new Date().getFullYear(), Number(monthIndex), 0).setHours(23, 59, 59, 999)
  ).toISOString();
  const { categoryName } = queryString.parse(props.location.search);
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    dispatch(
      fetchPatientVisitsWithPrices({
        idToken,
        startDate,
        endDate,
        hospCode,
        hospmain: selectedHosp,
        category,
        page,
        asProvider,
      })
    );
  }, [dispatch, startDate, endDate, category, hospCode, idToken, selectedHosp, page, asProvider]);

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5>
            {asProvider ? 'เรียกเก็บ' : 'ตามจ่าย'} - {ThaiDate(moment([moment().year(), monthIndex - 1])).substr(2, 10)}
          </h5>
        </div>
        {!FetchingStatus && billlingPatients && (
          <div className='table-head'>
            <h4>
              <b>{categoryName}</b>
            </h4>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>วันที่</TableCell>
              <TableCell align='right'>CID</TableCell>
              <TableCell align='center'>ชื่อ</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿) </TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus &&
              billlingPatients &&
              billlingPatients.docs.map(row => (
                <TableRow
                  key={row._id}
                  onClick={() => {
                    history.push(`patients/${row.cid}?&category=${categoryName}&year=${new Date().getFullYear()}`);
                  }}
                >
                  <TableCell align='center' component='th' scope='row'>
                    {new Date(row.latestVisitDateTime).toLocaleDateString('th-TH', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </TableCell>
                  <TableCell align='right'>{row.cid}</TableCell>
                  <TableCell align='center'>
                    {row.patient.data.fullname.firstName} {row.patient.data.fullname.lastName}
                  </TableCell>
                  <TableCell align='right'>{formatNum(row.totalActualPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalCalcPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalFinalPrice)}</TableCell>
                  <TableCell align='right'>
                    <Status color={getBillingStatusConfig(row.status).color}>
                      {getBillingStatusConfig(row.status).text}
                    </Status>
                  </TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className='pagination-table'>
          <Pagination page={page} count={billlingPatients?.totalPages} onChange={(e, value) => setPage(value)} />
        </div>
      </Container>
    </>
  );
};

export default ChargePatients;
