import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from 'react-global-configuration';
import { DBConfig } from './indexedDB/DBConfig';
import { initDB } from 'react-indexed-db';

// routes
import Routes from './routes/index';

// actions
import { appPropertySet } from './actions/AppProperty';

// mui
import { MuiThemeProvider } from '@material-ui/core';

initDB(DBConfig);

let configuration = {};

if (process.env.NODE_ENV === 'development') {
  configuration = window.__ENV__.ROOT_CONFIG_DEV;
  configuration.ROOT_API = process.env.REACT_APP_ROOT_API;
} else {
  configuration = window.__ENV__.ROOT_CONFIG;
  configuration.ROOT_API = process.env.REACT_APP_ROOT_API;
}
config.set(configuration);

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 'light',
      activeArticleId: 1,
      newReferral: false,
    };
    this.toggleTheme = this.toggleTheme.bind(this);
    this.setAppData = this.setAppData.bind(this);
  }

  toggleTheme() {
    const theme = this.state.theme === 'light' ? 'dark' : 'light';
    document.documentElement.classList.add('color-theme-in-transition');
    this.setState({ theme });
    document.documentElement.setAttribute('theme', theme);

    window.setTimeout(() => {
      document.documentElement.classList.remove('color-theme-in-transition');
    }, 1000);
  }

  componentDidMount() {
    const theme = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';
    document.documentElement.classList.add('color-theme-in-transition');
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('theme', theme);
    window.setTimeout(() => {
      document.documentElement.classList.remove('color-theme-in-transition');
    }, 1000);

    const appPropertyData = {
      ROOT_API: config.get('ROOT_API'),
      mailHost: config.get('mailHost'),
      visitLimit: config.get('visitLimit'),
      ScKey: config.get('ScKey'),
      theme: localStorage.getItem('theme'),
      FetchingStatus: false,
    };

    this.setAppData(appPropertyData);
  }

  setAppData(appPropertyData) {
    this.props.appPropertySet(appPropertyData);
  }

  render() {
    return (
      <MuiThemeProvider>
        <Routes />
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
