import { APPDATA } from '../../constants/appData';
import SearchPatient from '../../api/Refers/ReferInForm/SearchPatient';
import Submit from '../../api/Refers/ReferInForm/Submit';
import FetchPttype from '../../api/Refers/ReferInForm/FetchPttype';

export function searchPatient({ cid, idToken }) {
  return {
    type: APPDATA,
    payload: SearchPatient({ cid, idToken }),
  };
}

export function submit({ idToken, body, attachments, letterAttachments }) {
  return {
    type: APPDATA,
    payload: Submit({ idToken, body, attachments, letterAttachments }),
  };
}

export function fetchPttype({ hospCode, idToken }) {
  return {
    type: APPDATA,
    payload: FetchPttype({ hospCode, idToken }),
  };
}
