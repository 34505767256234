import axios from 'axios';
import config from 'react-global-configuration';

export default async ({ idToken, file }) => {
  const url = `${config.get('ROOT_API')}/v1/referOutForm/upload`;

  const formData = new FormData();
  file.map(item => formData.append('files', item));

  return await axios
    .post(url, formData, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log('axios error');
      return [];
    });
};
