import { APPDATA } from '../../constants/appData';
import ContactDoctor from '../../api/Refers/ReferIn/ContactDoctor';
import NurseRead from '../../api/Refers/ReferIn/NurseRead';

export function contactDoctor({ idToken, bid, dateTime }) {
  return {
    type: APPDATA,
    payload: ContactDoctor({ idToken, bid, dateTime }),
  };
}

export function nurseRead({ idToken, visitBid, dateTime }) {
  return {
    type: APPDATA,
    payload: NurseRead({ idToken, visitBid, dateTime }),
  };
}
