import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';

export default function ReferAcceptDialog(props) {
  const { control, handleSubmit } = useForm();

  const [appointmentDate, setAppointmentDate] = useState(
    props.appointmentDateTime?.length > 0 ? new Date(props.appointmentDateTime[0]) : new Date()
  );
  const [appointmentTimeStart, setAppointmentTimeStart] = useState(
    props.appointmentDateTime?.length > 0 ? dayjs(appointmentDate) : dayjs(new Date()).set('minute', 30)
  );
  const [appointmentTimeEnd, setAppointmentTimeEnd] = useState(dayjs());

  useEffect(() => {
    const appointmentDateTime =
      props.appointmentDateTime?.length > 0 ? new Date(props.appointmentDateTime[0]) : new Date();
    setAppointmentDate(appointmentDateTime);
    setAppointmentTimeStart(
      props.appointmentDateTime?.length > 0 ? dayjs(appointmentDateTime) : dayjs(appointmentDateTime).set('minute', 30)
    );
  }, [props.open, props.appointmentDateTime]);
  useEffect(() => {
    setAppointmentTimeEnd(dayjs(appointmentTimeStart).add(30, 'minute'));
  }, [appointmentTimeStart]);

  const onSubmit = referData => {
    const appointmentData = {
      ...referData,
      appointmentDateTimeStart:
        dayjs(appointmentDate).format('YYYY/MM/DD') + ' : ' + dayjs(appointmentTimeStart).format('HH:mm:ss'),
      appointmentDateTimeEnd:
        dayjs(appointmentDate).format('YYYY/MM/DD') + ' : ' + dayjs(appointmentTimeEnd).format('HH:mm:ss'),
    };

    props.onSubmit(appointmentData);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        style: { borderRadius: '16px' },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <CheckCircleIcon
            style={{
              color: '#4CAF50',
              marginRight: '16px',
              backgroundColor: '#EDF7ED',
              borderRadius: '100px',
              boxShadow: '0 0 0 3px #EDF7ED',
            }}
          />
          ยืนยันการรับ Refer
          {props.appointmentDateTime?.length > 0 && <InfoIcon />}
          <IconButton className='float-right' onClick={() => props.onClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {props.cid && <DialogContentText id='alert-dialog-description'>CID : {props.cid}</DialogContentText>}
          {props.ptname && (
            <DialogContentText id='alert-dialog-description'>ผู้ป่วย : {props.ptname}</DialogContentText>
          )}
          {props.hospName && (
            <DialogContentText id='alert-dialog-description'>จาก : {props.hospName}</DialogContentText>
          )}

          <DialogContentText>
            <Controller
              as={<TextField required fullWidth variant='outlined' label='ชื่อคลินิก' />}
              control={control}
              name='appointmentClinicName'
              defaultValue={props.clinicName}
            />
          </DialogContentText>

          <DialogContentText>
            <Controller
              as={<TextField required fullWidth variant='outlined' label='ชื่อแพทย์ที่นัด' />}
              control={control}
              name='appointmentDoctorName'
              defaultValue={props.doctorName}
            />
          </DialogContentText>

          <DialogContentText>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              autoOk
              inputVariant='outlined'
              format='dd/MM/yyyy'
              label='วันท่ีนัด :'
              onChange={e => setAppointmentDate(e)}
              value={appointmentDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </DialogContentText>

          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TimePicker
                  disableToolbar
                  fullWidth
                  ampm={false}
                  inputVariant='outlined'
                  minutesStep={30}
                  variant='dialog'
                  label='ตั้งแต่เวลา :'
                  value={appointmentTimeStart}
                  onChange={e => setAppointmentTimeStart(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  readOnly
                  disableToolbar
                  fullWidth
                  ampm={false}
                  inputVariant='outlined'
                  minutesStep={30}
                  variant='dialog'
                  label='ถึงเวลา :'
                  value={appointmentTimeEnd}
                />
              </Grid>
            </Grid>
          </DialogContentText>

          <DialogContentText>
            <Controller
              as={<TextField required fullWidth multiline rows={3} variant='outlined' label='เหตุผล' />}
              control={control}
              name='reason'
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type='submit' style={{ backgroundColor: '#27A06D', color: 'white' }} variant='contained' fullWidth>
            ยืนยันการรับ Refer
          </Button>
        </DialogActions>
        <DialogActions>
          <Button onClick={() => props.onClose()} style={{ backgroundColor: 'white', color: '#3F98D2' }} fullWidth>
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
