import React, { useEffect, useState } from 'react';

export default function EmergencyBadge({ priority }) {
  const [colorSet, setColorSet] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    switch (priority) {
      case '1':
        setColorSet('refer-type-icon-1');
        setText('Life threatening');
        break;
      case '2':
        setColorSet('refer-type-icon-2');
        setText('Emergency');
        break;
      case '3':
        setColorSet('refer-type-icon-3');
        setText('Urgent');
        break;
      case '4':
        setColorSet('refer-type-icon-4');
        setText('Acute');
        break;
      case '5':
        setColorSet('refer-type-icon-5');
        setText('Non acute');
        break;
      default:
        setColorSet('');
        setText('');
    }
  }, [priority]);

  return priority ? (
    <div className={colorSet} style={{ padding: '4px' }}>
      {text}
    </div>
  ) : (
    <div />
  );
}
