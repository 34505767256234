import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../fonts/vfs_fonts';
import bahttext from './BAHTTEXT.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
};
function thaiNumber(num) {
  var array = {
    1: '๑',
    2: '๒',
    3: '๓',
    4: '๔',
    5: '๕',
    6: '๖',
    7: '๗',
    8: '๘',
    9: '๙',
    0: '๐',
  };
  var str = num.toString();
  for (var val in array) {
    str = str.split(val).join(array[val]);
  }
  return str;
}

function printUC(data, hosname, datepdf) {
  var thismonth = thaiNumber(new Date().toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var billmonthandyear = thaiNumber(datepdf.toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var totalpatient = thaiNumber(data.totalPatients);
  var finalprice = thaiNumber(
    Number(data.totalFinalPrice)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
  var bahttexteiei = bahttext(data.totalFinalPrice);
  var docDefinition = {
    content: [
      {
        stack: [
          '\n\n\nที่  สป ๐๐๓๒.๒/…………..                                                                              โรงพยาบาลสมุทรปราการ',
          { text: '๗๑ ถนนจักกะพาก อำเภอเมืองฯ', style: 'subheader1' },
          { text: 'จังหวัดสมุทรปราการ ๑๐๒๗๐', style: 'subheader2' },
        ],
        style: 'header',
      },
      {
        text: thismonth,
        style: 'date',
      },
      {
        text: 'เรื่อง ขอเบิกค่าบริการทางการแพทย์ผู้ป่วยโครงการบัตรประกันสุขภาพถ้วนหน้า',
        style: 'subject',
      },
      { text: 'เรียน ผู้อำนวยการ' + hosname + '', style: 'to' },
      {
        text: 'โรงพยาบาลสมุทรปราการ ขอเบิกเงินค่ารักษาพยาบาลผู้ป่วยโครงการบัตรประกันสุขภาพถ้วนหน้า ของ',
        style: 'tab',
      },
      {
        text:
          hosname +
          '  ที่มารับบริการ ณ โรงพยาบาลสมุทรปราการ จังหวัดสมุทรปราการประจำเดือน ' +
          billmonthandyear +
          ' จำนวน  ' +
          totalpatient +
          '  ราย เป็นจำนวนเงิน ' +
          finalprice +
          ' บาท (' +
          bahttexteiei +
          ')',
        style: 'main',
      },
      {
        text: 'โปรดสั่งจ่ายค่ารักษาพยาบาลผ่าน   ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร   สาขาสมุทรปราการ',
        style: 'tab',
      },
      {
        text: '"ชื่อบัญชี โรงพยาบาลสมุทรปราการ (บัตรประกันสุขภาพ)" เลขที่ ๐๘๐-๒-๑๐๔๓๕-๕',
        style: 'main2',
      },
      {
        text: 'จึงเรียนมาเพื่อโปรดทราบและพิจารณาดำเนินการต่อไป',
        style: 'tab',
      },
      { text: '\n\nขอแสดงความนับถือ', style: 'main4' },
      { text: '\n\n(นายปฏิวัติ วงศ์งาม)', style: 'main5' },
      { text: 'รองผู้อำนวยการฝ่ายการแพทย์คนที่ ๒', style: 'main6' },
      {
        text: 'ปฏิบัติราชการแทนผู้อำนวยการโรงพยาบาลสมุทรปราการ',
        style: 'main7',
      },

      {
        stack: [
          '\n\n\n\n\n\nศูนย์ประกันสุขภาพ',
          {
            text:
              'โทร. ๐-๒๑๗๓-๘๓๖๑-๕  ต่อ ๓๒๑๕                                                                              ...................../  ร่าง',
          },
          {
            text:
              'โทรสาร. ๐-๒๑๗๓-๘๓๖๗                                                                                           ...................../ พิมพ์',
          },
          {
            text:
              'สำเนาส่ง ฝ่ายบัญชี                                                                                                    ...................../ ตรวจ',
          },
        ],
        style: 'footer',
      },
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: 16,
    },
    styles: {
      header: { alignment: 'left' },
      subheader1: { alignment: 'right' },
      subheader2: { alignment: 'right', margin: [0, 0, 11, 0] },
      date: { alignment: 'right', margin: [0, 0, 122, 0] },
      tab: { margin: [60, 0, 0, 0] },
      main4: { alignment: 'center' },
      main5: { alignment: 'center' },
      main6: { alignment: 'center' },
      main7: { alignment: 'center' },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}

export default printUC;
